import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
  getRolessuccess: false,
  createRoleSuccess: false,
  editRoleSuccess: false,
  deleteRoleSuccess: false,
  error: false,
  message: null,
  roles: null,
}

const getRolesRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    getRolessuccess: false,
    error: false,
    message: null,
  }
}

const getRolesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    getRolessuccess: true,
    message: action.message,
    roles: action.data,
    cntRoleData: action.cntRoleData
  }
}

const getRolesError = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    getRolessuccess: false,
    error: true, 
    message: action.error 
  }
}

const createRoleRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    createRoleSuccess: false,
    error: false,
    message: action.message,
  }
}

const createRoleSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    createRoleSuccess: true,
    message: action.message
  }
}

const createRoleError = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    createRoleSuccess: false,
    error: true,
    message: action.error 
  }
}

const editRoleRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    editRoleSuccess: false,
    error: false,
    message: action.message,
  }
}

const editRoleSuccess = (state = INITIAL_STATE, action) => {
  let updatedRoles = state.roles.map(role => (role._id === action.role._id ? {...role, ...action.role} : role))
  return {
    ...state,
    editRoleSuccess: true,
    message: action.message,
    roles: updatedRoles
  }
}

const editRoleError = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    editRoleSuccess: false,
    error: true,
    message: action.error 
  }
}

const deleteRoleRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteRoleSuccess: false,
    error: false,
    message: action.message,
  }
}

const deleteRoleSuccess = (state = INITIAL_STATE, action) => {
  let updatedRoles = state.roles.filter(function (role){return role._id !== action.role._id})
  return {
    ...state,
    deleteRoleSuccess: true,
    message: action.message,
    roles: updatedRoles
  }
}

const deleteRoleError = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    deleteRoleSuccess: false,
    error: true,
    message: action.error 
  }
}

const HANDLERS = {
  [Constants.GET_ROLES_SUCCESS]: getRolesSuccess,
  [Constants.GET_ROLES_ERROR]: getRolesError,
  [Constants.GET_ROLES_REQUEST]: getRolesRequest,
  [Constants.CREATE_ROLE_REQUEST]: createRoleRequest,
  [Constants.CREATE_ROLE_SUCCESS]: createRoleSuccess,
  [Constants.CREATE_ROLE_ERROR]: createRoleError,
  [Constants.EDIT_ROLE_REQUEST]: editRoleRequest,
  [Constants.EDIT_ROLE_SUCCESS]: editRoleSuccess,
  [Constants.EDIT_ROLE_ERROR]: editRoleError,
  [Constants.DELETE_ROLE_REQUEST]: deleteRoleRequest,
  [Constants.DELETE_ROLE_SUCCESS]: deleteRoleSuccess,
  [Constants.DELETE_ROLE_ERROR]: deleteRoleError,
}

export default createReducer(INITIAL_STATE, HANDLERS)
