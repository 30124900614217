import { createReducer } from 'reduxsauce';
import { Constants } from '../Actions';

const INITIAL_STATE = {
    error: false,
    message: null,
    getIntegrationByIdSuccess: false,
    integrationData: {},
    googleWorkspaceLogs: null,
    googleWorkSpaceViewLogsSuccess: false,
    groups: null,
    syncModeValue: '',
    updateGoogleManagementListSuccess: false,
    getSyncLogsHealthStatusSuccess: false,
    loading: false,
    checkWhetherGroupNameExistInIllumeetSuccess: false,
    groupDoesExist: false,
    groupId: null,
    actionType: null,
    getGroupsForGoogleWorkspaceSuccess: false
};

const googleWorkspaceGetWidgetRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        getIntegrationByIdSuccess: false,
        integrationData: {}
    };
};

const googleWorkspaceGetWidgetSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        getIntegrationByIdSuccess: true,
        integrationData: action.data
    };
};

const googleWorkspaceGetWidgetError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.message,
        getIntegrationByIdSuccess: false,
        integrationData: {}
    };
};

const googleWorkspaceGetLogsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        googleWorkSpaceViewLogsSuccess: false,
        googleWorkspaceLogs: null,
        message: '',
        totalLogsCount: 0,
    };
};
const googleWorkspaceGetLogsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        googleWorkSpaceViewLogsSuccess: true,
        googleWorkspaceLogs: action.logs,
        message: action.message,
        totalLogsCount: action.totalLogsCount
    };
};
const googleWorkspaceGetLogsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        googleWorkSpaceViewLogsSuccess: false,
        googleWorkspaceLogs: null,
        message: action.message,
        totalLogsCount: 0,
    };
};

const getGroupsForGoogleWorkspaceRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        getGroupsForGoogleWorkspaceSuccess: false,
        getGroupsForGoogleWorkspaceRequest: true,
        message: '',
        groups: null,
        totalNumberOfGroups: 0,
    };
};

const getGroupsForGoogleWorkspaceSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        getGroupsForGoogleWorkspaceSuccess: true,
        getGroupsForGoogleWorkspaceRequest: false,
        groups: action.groups,
        message: action.message,
        totalNumberOfGroups: action.totalNumberOfGroups
    };
};

const getGroupsForGoogleWorkspaceError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        getGroupsForGoogleWorkspaceSuccess: false,
        message: action.message,
        groups: null,
        totalNumberOfGroups: null
    };
};

const setGetGroupsForGoogleWorkspaceSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getGroupsForGoogleWorkspaceSuccess: action.data,
    };
};
const setUpdateGoogleManagementListSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        updateGoogleManagementListSuccess: action.data,
    };
};

const updateGroupsFromGoogleWorkspaceRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        loading: true,
        updateGroupsFromGoogleWorkspaceSuccess: false,
        actionType: action.type,
        message: '',
    };
};
const updateGroupsFromGoogleWorkspaceSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        loading: false,
        updateGroupsFromGoogleWorkspaceSuccess: true,
        message: action.message,
    };
};
const updateGroupsFromGoogleWorkspaceError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        loading: false,
        updateGroupsFromGoogleWorkspaceSuccess: false,
        actionType: action.type,
        message: action.message,
    };
};

const updateGoogleManagementListRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        updateGoogleManagementListSuccess: false,
        message: null
    };
};
const updateGoogleManagementListSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        updateGoogleManagementListSuccess: true,
        message: action.message
    };
};
const updateGoogleManagementListError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        updateGoogleManagementListSuccess: false,
        message: action.message
    };
};

const updateGoogleWorkspaceCustomFieldsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        updateGoogleWorkspaceCustomFieldsSuccess: false,
        message: null
    };
};

const updateGoogleWorkspaceCustomFieldsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        updateGoogleWorkspaceCustomFieldsSuccess: true,
        message: action.message
    };
};

const updateGoogleWorkspaceCustomFieldsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        updateGoogleWorkspaceCustomFieldsSuccess: false,
        message: action.message
    };
};

const handleSyncModeRedux = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        syncModeLocal: action.syncMode
    };
};

const updateGoogleWorkspaceSyncModeRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        updateGoogleWorkspaceSyncModeSuccess: false,
        message: action.message,
        syncModeValue: action.syncMode
    };
};

const updateGoogleWorkspaceSyncModeSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        updateGoogleWorkspaceSyncModeSuccess: true,
        message: action.message
    };
};

const updateGoogleWorkspaceSyncModeError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        updateGoogleWorkspaceSyncModeSuccess: false,
        message: action.message
    };
};

const getSyncLogsHealthStatusRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        healthStatus: action.healthStatus,
        getSyncLogsHealthStatusSuccess: false,
    };
};

const getSyncLogsHealthStatusSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        healthStatus: action.healthStatus,
        getSyncLogsHealthStatusSuccess: true,
        lastUpdatedAttendee: action.lastUpdatedAttendee
    };
};

const getSyncLogsHealthStatusError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        healthStatus: null,
        getSyncLogsHealthStatusSuccess: false
    };
};


const checkWhetherGroupNameExistInIllumeetRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        checkWhetherGroupNameExistInIllumeetSuccess: false,
        groupDoesExist: false,
        groupId: null
    };
};

const checkWhetherGroupNameExistInIllumeetSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        checkWhetherGroupNameExistInIllumeetSuccess: true,
        groupDoesExist: action.groupDoesExist,
        groupId: action.groupId
    };
};

const checkWhetherGroupNameExistInIllumeetError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        checkWhetherGroupNameExistInIllumeetSuccess: false,
        groupDoesExist: false,
        groupId: null
    };
};

//#region Sync USERS
const syncUsers = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true,
        actionType: action.type,
        message: null
    };
};
const syncUsersSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        actionType: action.type
        // googleUsers: action.data.googleUsers,
        // totalCount: action.data.totalCount
    };
};
const syncUsersFailure = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        message: action.error,
        actionType: action.type
    };
};
//#endregion

//#region GET USERS
const getGoogleUsers = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true,
        message: null
    };
};
const getGoogleUsersSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        googleUsers: action.data.googleUsers,
        totalCount: action.data.totalCount
    };
};
const getGoogleUsersFailure = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: false,
        message: action.error,
        actionType: action.type
    };
};
//#endregion

const HANDLERS = {
    [Constants.GOOGLE_WORKSPACE_GET_WIDGET_REQUEST]: googleWorkspaceGetWidgetRequest,
    [Constants.GOOGLE_WORKSPACE_GET_WIDGET_SUCCESS]: googleWorkspaceGetWidgetSuccess,
    [Constants.GOOGLE_WORKSPACE_GET_WIDGET_ERROR]: googleWorkspaceGetWidgetError,
    [Constants.GOOGLE_WORKSPACE_GET_LOGS_REQUEST]: googleWorkspaceGetLogsRequest,
    [Constants.GOOGLE_WORKSPACE_GET_LOGS_SUCCESS]: googleWorkspaceGetLogsSuccess,
    [Constants.GOOGLE_WORKSPACE_GET_LOGS_ERROR]: googleWorkspaceGetLogsError,
    [Constants.GET_GROUPS_FOR_GOOGLE_WORKSPACE_REQUEST]: getGroupsForGoogleWorkspaceRequest,
    [Constants.GET_GROUPS_FOR_GOOGLE_WORKSPACE_SUCCESS]: getGroupsForGoogleWorkspaceSuccess,
    [Constants.GET_GROUPS_FOR_GOOGLE_WORKSPACE_ERROR]: getGroupsForGoogleWorkspaceError,
    [Constants.UPDATE_GROUPS_FROM_GOOGLE_WORKSPACE_REQUEST]: updateGroupsFromGoogleWorkspaceRequest,
    [Constants.UPDATE_GROUPS_FROM_GOOGLE_WORKSPACE_SUCCESS]: updateGroupsFromGoogleWorkspaceSuccess,
    [Constants.UPDATE_GROUPS_FROM_GOOGLE_WORKSPACE_ERROR]: updateGroupsFromGoogleWorkspaceError,
    [Constants.UPDATE_GOOGLE_MANAGEMENT_LIST_REQUEST]: updateGoogleManagementListRequest,
    [Constants.UPDATE_GOOGLE_MANAGEMENT_LIST_SUCCESS]: updateGoogleManagementListSuccess,
    [Constants.UPDATE_GOOGLE_MANAGEMENT_LIST_ERROR]: updateGoogleManagementListError,
    [Constants.UPDATE_GOOGLE_WORKSPACE_CUSTOM_FIELDS_REQUEST]: updateGoogleWorkspaceCustomFieldsRequest,
    [Constants.UPDATE_GOOGLE_WORKSPACE_CUSTOM_FIELDS_SUCCESS]: updateGoogleWorkspaceCustomFieldsSuccess,
    [Constants.UPDATE_GOOGLE_WORKSPACE_CUSTOM_FIELDS_ERROR]: updateGoogleWorkspaceCustomFieldsError,
    [Constants.HANDLE_SYNC_MODE_REDUX]: handleSyncModeRedux,
    [Constants.UPDATE_GOOGLE_WORKSPACE_SYNC_MODE_REQUEST]: updateGoogleWorkspaceSyncModeRequest,
    [Constants.UPDATE_GOOGLE_WORKSPACE_SYNC_MODE_SUCCESS]: updateGoogleWorkspaceSyncModeSuccess,
    [Constants.UPDATE_GOOGLE_WORKSPACE_SYNC_MODE_ERROR]: updateGoogleWorkspaceSyncModeError,
    [Constants.GET_SYNC_LOGS_HEALTH_STATUS_REQUEST]: getSyncLogsHealthStatusRequest,
    [Constants.GET_SYNC_LOGS_HEALTH_STATUS_SUCCESS]: getSyncLogsHealthStatusSuccess,
    [Constants.GET_SYNC_LOGS_HEALTH_STATUS_ERROR]: getSyncLogsHealthStatusError,

    [Constants.GET_GOOGLE_USERS]: getGoogleUsers,
    [Constants.GET_GOOGLE_USERS_SUCCESS]: getGoogleUsersSuccess,
    [Constants.GET_GOOGLE_USERS_FAILURE]: getGoogleUsersFailure,

    [Constants.SYNC_USERS]: syncUsers,
    [Constants.SYNC_USERS_SUCCESS]: syncUsersSuccess,
    [Constants.SYNC_USERS_FAILURE]: syncUsersFailure,

    [Constants.CHECK_WHETHER_GROUP_NAME_EXIST_IN_ILLUMEET_REQUEST]: checkWhetherGroupNameExistInIllumeetRequest,
    [Constants.CHECK_WHETHER_GROUP_NAME_EXIST_IN_ILLUMEET_SUCCESS]: checkWhetherGroupNameExistInIllumeetSuccess,
    [Constants.CHECK_WHETHER_GROUP_NAME_EXIST_IN_ILLUMEET_ERROR]: checkWhetherGroupNameExistInIllumeetError,
    [Constants.SET_GET_GROUPS_FOR_GOOGLE_WORKSPACE_SUCCESS]: setGetGroupsForGoogleWorkspaceSuccess,
    [Constants.SET_UPDATE_GOOGLE_MANAGEMENT_LIST_SUCCESS]: setUpdateGoogleManagementListSuccess,

};

export default createReducer(INITIAL_STATE, HANDLERS);
