import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Helmet } from 'react-helmet'
import { get, cloneDeep } from 'lodash'
import { NotificationContainer } from 'react-notifications'

import { connect } from 'react-redux'
import { getTemplateLib, updateLibTemplateById } from '../../Redux/API'

/*custom components */
import PageToolBar from '../../Components/PageToolBar'
import Loader from '../../Components/Loader/loader'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
/*default ui configuration*/
import '../../sass/main.scss'
import { createNotification } from '../../helpers'
import getUnLayerConfig from './config'

class EditLibTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoader: false,
      templateName: '',
    }
  }

  componentDidMount() {
    const apiUrl = process.env.REACT_APP_API_URL
    const eventId = get(this.props, 'match.params.eid')
    const templateId = get(this.props, 'match.params.templateId')
    const setState = this.setState.bind(this)
    this.props.eventRequest(eventId)
    window.scrollTo(0, 0)
    window.unlayer.init(getUnLayerConfig(apiUrl, eventId))
    window.unlayer.addEventListener('design:updated', function (updates) {
      window.unlayer.exportHtml(function (data) {
        const json = data.design
        const html = data.html
        setState({ json, html })
      })
    })

    getTemplateLib({})
      .then(async (result) => { 
        if (
          result.data &&
          result.data.templates &&
          result.data.templates.length
        ) {
          let temp = result.data.templates.filter(
            (obj) => obj._id === templateId,
          )
          if (temp.length) {
            let templates = {}
            templates['json'] = temp[0].json
            templates['html'] = temp[0].html
            //templates['templateName'] = temp[0].templateName;
            window.unlayer.loadDesign(templates.json)
            this.setState({
              ...templates,
              templateNameLib: temp[0].templateName,
              html: temp[0].html,
              json: temp[0].json,
            })
          }
        }
      })
      .catch(console.log)
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevEvent = get(prevProps, 'events.event')
    const event = get(this.props, 'events.event')
    if (prevEvent !== event) {
      this.setState({ event })
    }
  }

  onChange = (e) => {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  saveTemplateToLib = () => {
    let { json, html, templateNameLib } = this.state
    const data = { json, html, templateName: templateNameLib }
    const templateId = get(this.props, 'match.params.templateId')
    if (html && json && templateNameLib) {
      updateLibTemplateById(templateId, data)
        .then((result) => {
          if (result.data.success) {
            createNotification(
              'success',
              'Template has been deleted successfully',
            )
          } else {
            createNotification('error', result.data.message)
          }
        })
        .catch(console.log)
    } else {
      createNotification('error', 'Please enter the template name first.')
    }
  }

  render() {
    const { view, showLoader, templateNameLib, saveTempDisabled } = this.state
    const templateId = get(this.props, 'match.params.templateId')

    return (
      <div className="editingPageWrapper">
        <Helmet>
          <title>IllumeetXP | Page Design </title>
        </Helmet>
        {showLoader && <Loader />}
        {
          <div className="editingDashboard">
            {!view && (
              <PageToolBar
                showIcons={false}
                showPreviewOption={false}
                showSaveBtn={false}
                showAddToLib={templateId}
                goBackEnable={true}
                addToLibBtnLabel={'Update Template'}
                saveTemplateToLib={this.saveTemplateToLib}
                saveTempDisabled={saveTempDisabled}
              />
            )}
            <div className="templateName templateNameField formSection pt-0">
              <form>
                <div className="field-group fieldFroup20 inputField">
                  <label>Template Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="templateNameLib"
                    placeholder={'Please enter template name'}
                    value={templateNameLib}
                    maxLength="25"
                    onChange={this.onChange}
                  />
                </div>
              </form>
            </div>
            <div id="pageDesignEditor" style={{ height: '100vh' }}>
              {''}
            </div>
          </div>
        }
        <NotificationContainer />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.Events,
  }
}

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(EditLibTemplate),
)
