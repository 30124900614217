import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
    getReportRequest : ['data'],
    getReportSuccess : [ 'message', 'report'],
    getReportError : ['error'],
    getPublishedEventsRequest : ['data'],
    getPublishedEventsSuccess : [ 'message', 'events'],
    getPublishedEventsError : ['error'],
    getReportChallengesRequest: ['data'],
    getReportChallengesSuccess: ['message', 'challenges', 'totalResults'],
    getReportChallengesError: ['error'],
})

export const Constants = Types
export default Creators