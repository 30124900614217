import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
   inviteUserRequest: ['data'],
   inviteUserSuccess: ['message','user'],
   inviteUserError: ['error'],
   editUserPermissionRequest: ['data'],
   editUserPermissionSuccess: ['message','user'],
   editUserPermissionError: ['error'],
   getUsersListRequest: ['data'],
   listUsersSuccess: ['users','totalResults', 'message', 'filterCnt','filterByManager'],
   deleteUserRequest: ['data'],
   deleteUserSuccess: ['message','user'],
   deleteUserError: ['error'],
   changeUserRoleRequest: ['data'],
   changeUserRoleSuccess: ['message','user'],
   changeUserRoleError: ['error'],
   changeUserStatusRequest: ['data'],
   changeUserStatusSuccess: ['message','user'],
   changeUserStatusError: ['error'],
   searchUserRequest: ['data'],
   searchUserSuccess: ['users', 'totalResults','message'],
   searchUserError: ['error'],
   getUserDetailsRequest: ['data'],
   getUserDetailsSuccess: ['data', 'message'],
   getUserDetailsError: ['error'],
   changeLangForUserRequest:['data'],
   changeLangForUserError:['message'],
   changeLangForUserSuccess:['codeToChangeLang'],
   handleLang:['langState'],
   newLangStateForRedux: ['languageCode'],
   assignManagerRequest: ['data'],
   assignManagerSuccess: ['message'],
   assignManagerError: ['error'],
   allUserListRequest: ['data'],
   allUserListSuccess: ['allUserList'],
   allUserListError: ['error'],
   getUsersByAcceptStRequest: ['data'],
   getUsersByAcceptStSuccess: ['users'],
   getUsersByAcceptStError: ['message'],
   getActiveUsersForCompanyRequest: ['data'],
   getActiveUsersForCompanySuccess: ['users'],
   getActiveUsersForCompanyError: ['message']
})

export const Constants = Types
export default Creators
