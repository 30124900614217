import React, { Component, useRef } from 'react';
import { default as ReactSelect } from 'react-select';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from '../../Redux/Actions';
import Loader from '../../Components/Loader/loader';
import { components } from 'react-select';
import { createNotification } from '../../helpers';
import { NotificationContainer } from 'react-notifications';
import '../../sass/main.scss';
import { Button } from '@material-ui/core';
import 'react-responsive-modal/styles.css';
import { Multiselect } from 'multiselect-react-dropdown';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import Avatar from '@material-ui/core/Avatar';
import { styled } from '@material-ui/core/styles';
import userImg from '../../assets/images/icons/user.svg';
import ImgCrop from '../../Pages/demo/MyProfile/Crop';
import { Modal } from 'react-responsive-modal';
import userImageIcon from '../../assets/images/icons/userImageIcon.svg';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@material-ui/core/MenuItem';
import UploadFromFilesPopUp from 'Common/UploadFromFilesPopUp';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const customStyles = {
  option: (provided, state, styles) => ({
    ...provided,
    ...styles,
    backgroundColor: state.isSelected ? '#EDECF5' : 'none',
    position: 'relative',
    borderRadius: 4,
    marginBottom: 5,
  }),
  control: (styles) => ({
    ...styles,
    minHeight: 48,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingTop: '8px',
    paddingBottom: '8px',
  }),
  menu: (styles) => ({
    ...styles,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};
class ContactModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      loading: false,
      open: this.props.importOpen ? this.props.importOpen : false,
      csvImport: '',
      // ProfileImage: process.env.REACT_APP_IMAGE_URL + 'user.png',
      ProfileImage: '',
      profilePic: '',
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      page_no: this.props.page_no ? this.props.page_no : 1,
      groupId: this.props.group_id ? this.props.group_id : '',
      group_id: [],
      totalResults: '',
      cropOpen: false,
      cropPic: '',
      confirmImage: '',
      uploadImage: '',
      arrayOfAudience: [],
      customFieldsForContact: {},
      fileNameInS3: "",
      destinationFolder: "", 
      uploadFromFiles: false,
      buttonState: false,
      parentForPopup: "contactsImages" 
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  componentDidMount() {
    if (!this.props.group_id) {
      this.groupRequest();
      this.handleLoading();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.contacts.contactSuccess &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('success', this.props.contacts.message);
      let st = prevState.loading ? this.setState({ loading: false }) : null;
      this.setState({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        open: false,
        group_id: '',
      });
    }

    if (
      this.props.groups.success &&
      this.props.groups.message !== prevProps.groups.message
    ) {
      this.setState({
        loading: false,
      });
    }

    if (
      this.props.contacts.contactError &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('error', this.props.contacts.message, '');
      let st = prevState.loading ? this.setState({ loading: false }) : null;
    }
  }

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      const imageFile = event.target.files[0];

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        createNotification('error', 'Please select valid image.');
        return false;
      }

      this.setState({ confirmImage: event.target.files[0], cropOpen: true });

      reader.readAsDataURL(event.target.files[0]);
    }
  };


  handleChoosedFile = (file) => { 
    console.log("file~~~~~~~~~~~~~~",file)
    this.setState(
      {
        s3File: file,
        confirmImage: file,
        destinationFolder: "contactsImages",
        fileNameInS3: file.fileNameInS3,
        uploadFromFiles: false,
        displayS3Image: true,
        cropOpen: true
      }
    );

  };

  handleFileChosen = (file) =>{
    console.log("file----handleFileChosen---->>>>",file)
    this.setState({fileNameInS3:file?.fileNameInS3})
  }

  uploadFromFiles = () => {
    this.setState({
      uploadFromFiles: true,
      buttonState: false
    });
  };

  handleCloseFileModulePopup = () => {
    this.setState({
      uploadFromFiles: false,
      buttonState: true
    });
  };

  isValid = () => {
    const {
      email,
      group_id,
      arrayOfAudience,
      customFieldsForContact,
    } = this.state;
    let error = {};
    let formIsValid = true;
    const regexTest =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const customFields = this.props.groups?.customFields?.map((item) => item);
    const regexUrl =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

    if (!this.props.group_id) {
      if (arrayOfAudience.length === 0) {
        formIsValid = false;
        error['group_id'] = this.props.users.languageStateForRedux?.validations?.selectAudience;
      }
    }

    if (email === '' || email.trim().length === 0) {
      formIsValid = false;
      error['email'] = this.props.users.languageStateForRedux?.validations?.enterEmail;
    }
    if (email && regexTest.test(email.trim()) === false) {
      formIsValid = false;
      error['email'] = this.props.users.languageStateForRedux?.validations?.emailAdd;
    }
    if (customFields) {
      for (let data of customFields && customFields) {
        let contactValidData = {};
        contactValidData[data._id] = customFieldsForContact[data._id];
        const { fieldData } = data;
        const currentFieldData = customFieldsForContact[data._id];
        const { fieldType } = fieldData || {};
        if (formIsValid === true && fieldType === 'email') {
          if (
            currentFieldData !== undefined &&
            currentFieldData?.trim().length !== 0 &&
            !currentFieldData !== ''
          ) {
            if (regexTest.test(currentFieldData) === false) {
              formIsValid = false;
              error['validEmail'] = `Please enter a valid email`;
            }
          }
        }

        if (formIsValid === true && fieldType === 'url') {
          if (
            currentFieldData !== undefined &&
            currentFieldData?.trim().length !== 0 &&
            !currentFieldData !== ''
          ) {
            if (regexUrl.test(currentFieldData) === false) {
              formIsValid = false;
              error['validUrl'] = `Please enter a valid URL`;
            }
          }
        }
      }
    }

    this.setState({ errors: error });
    return formIsValid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isValid()) {
      const {
        firstName,
        lastName,
        email,
        company,
        profilePic,
        page_no,
        group_id,
        arrayOfAudience,
      } = this.state;

      arrayOfAudience.map((data) => {
        group_id.push(data.value);
      });

      const customField = this.props.groups.customFields?.map(
        (item) => item._id,
      );
      let customFieldData = {};
      if (customField) {
        for (const item of customField) {
          customFieldData[item] = this.state.customFieldsForContact[item];
        }
      }

      let newObj = {};
      for (const element in customFieldData) {
        newObj[element] = Array.isArray(customFieldData[element])
          ? customFieldData[element]
          : customFieldData[element]?.trim();
      }

      let groupIds = this.props.group_id ? [this.props.group_id] : group_id;
      if (profilePic !== '') {
        const formData = new FormData();
        formData.append('email', email.trim());
        formData.append('page_no', page_no);
        formData.append('group_id', groupIds);
        formData.append('profilePic', profilePic);
        formData.append('customFieldData', JSON.stringify(newObj));
        this.props.contactRequest(formData);

        this.handleLoading();
      } else {
        const requestbody = {
          email: email.trim(),
          page_no,
          group_id: groupIds,
          permission: 'Create Audience',
          customFieldData: newObj,
          lngCode: this.props.users.newLangState
        };
        this.props.contactRequest(requestbody);

        this.handleLoading();
      }
    }
  };

  onRemove = (selectedList, removedItem) => {
    let groupArray = [];
    selectedList.map((data) => {
      groupArray.push(data.id);
    });

    let group =
      groupArray.length > 0
        ? this.setState({ group_id: groupArray }, function () { })
        : null;
  };

  onCloseModal = () => {
    this.setState({ cropOpen: false });
  };

  getData = (val) => {
    this.setState({ cropPic: val });
  };

  saveCroppedImage = () => {
    this.setState({
      ProfileImage: this.state.cropPic,
      profilePic: this.state.cropPic,
      cropOpen: false,
    });
  };

  checkProfileImageTypeAndGetText = (input) => {
    if (('File' in window && input instanceof File) || input.includes('http')) {
      return this.props.users.languageStateForRedux?.buttons?.attendees?.change;
    } else {
      return this.props.users.languageStateForRedux?.buttons?.attendees?.photo;
    }
  };

  checkIfRemoveBtnNeeded = (input) => {
    if (('File' in window && input instanceof File) || input.includes('http')) {
      return true;
    } else {
      return false;
    }
  };

  handleRemoveProfileImage = () => {
    this.setState({
      ProfileImage: '',
      imageRemoved: true,
    });
  };

  groupRequest = () => {
    try {
      const { searchText, page_no, itemCount, sort_by, order } = this.state;
      const requestBody = {
        searchText: '',
        limit: '',
        page_no: 1,
        sort_by: 'updatedAt',
        order: 'desc',
        permission: 'View Audience',
      };
      this.props.groupRequest(requestBody);
    } catch (error) {
      createNotification('error', 'Something went wrong in audience listing');
    }
  };

  onSelect = (selectedList, selectedItem) => {
    let groupArray = [];
    selectedList.map((data) => {
      groupArray.push(data.id);
    });
    let group =
      groupArray.length > 0
        ? this.setState({ group_id: groupArray }, function () { })
        : null;
  };

  handleChangeMultiselect = (selected) => {
    this.setState({
      arrayOfAudience: selected,
    });
  };

  handleCustomFieldChange = (e, q) => {
    this.setState({
      [e.target.name]: {
        value: e.target.value,
        type: e.target.type,
      },
      customFieldsForContact: {
        ...this.state.customFieldsForContact,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeCheck = (event) => {
    const { value, checked } = event.target;
    let checkValue = this.state.customFieldsForContact[event.target.name]
      ? this.state.customFieldsForContact[event.target.name]
      : [];
    if (checked) {
      let checkValues = [];
      checkValues.push(event.target.value);
      checkValue = checkValue.concat(checkValues);

      this.setState({
        [event.target.name]: {
          value: event.target.value,
          type: event.target.type,
        },
        customFieldsForContact: {
          ...this.state.customFieldsForContact,
          [event.target.name]: checkValue,
        },
      });
    } else {
      let check = checkValue?.filter((event) => event !== value);
      if (check.length === 0) {
        let customFieldsForContact = this.state.customFieldsForContact;
        delete customFieldsForContact[event.target.name];
        this.setState({
          customFieldsForContact,
        });
      } else {
        this.setState({
          customFieldsForContact: {
            ...this.state.customFieldsForContact,
            [event.target.name]: check,
          },
        });
      }
    }
  };

  optionsList = (val) => {
    let temp;
    switch (val) {
      case 'Random no': temp = this.props.users.languageStateForRedux?.audienceTab?.randomNo;
        return temp;
      case 'contact no': temp = this.props.users.languageStateForRedux?.audienceTab?.contactNo;
        return temp;
      case 'meeting date': temp = this.props.users.languageStateForRedux?.audienceTab?.meetingDate;
        return temp;

      default: temp = val;
        break;
    }
  };

  render() {
    const {
      errors,
      customFieldsForContact,
      loading,
      ProfileImage,
      firstName,
      lastName,
      email,
      company,
      group_id,
    } = this.state;
    let options = [];
    {
      this.props.groups.groups &&
        this.props.groups.groups.map((data, index) => {
          options.push({ value: data._id, label: data.groupName });
        });
    }

    const styles = {
      contantImage: {
        backgroundColor: '#eeeeee',
        width: theme.spacing(8),
        height: theme.spacing(8),
      },

      avatar: {
        // backgroundColor: '#FF4170',
        width: theme.spacing(4.75),
        height: theme.spacing(4.75),
      },
      AddAvatar: {
        // backgroundColor: '#FF4170',
        width: theme.spacing(10),
        height: theme.spacing(10),
      },
    };

    const inputFile = React.createRef(null);

    const onButtonClick = () => {
      inputFile.current.value = null;
      inputFile.current.click();
    };

    return (
      <ThemeProvider theme={theme}>
        <div>
          <Grid
            component="form"
            // onSubmit={this.handleSubmit}
            container
            className="modal-wrapper"
          >
            <Grid item xs={12} pb={4}>
              <Box component="p" mb={1} className="subtitle">
                {this.props.users.languageStateForRedux?.audienceTab?.addContact}
              </Box>
              {this.props.users.languageStateForRedux?.audienceTab?.addContactModalDesc}
            </Grid>
            <Grid item xs={12}>
              <Box component="div" pb={4}>
                <Grid container spacing={2.1} alignItems="center">
                  <Grid item>
                    <Avatar style={styles.AddAvatar}>
                      <img
                        style={
                          ProfileImage
                            ? { height: '100%', width: '100%' }
                            : { maxHeight: '40px', maxWidth: '40px' }
                        }
                        alt=""
                        src={
                          ProfileImage
                            ? window.URL.createObjectURL(ProfileImage)
                            : userImageIcon
                        }
                      />
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Stack direction="row" spacing={1}>
                      <Button
                        onClick={onButtonClick}
                        variant="outlined"
                        color="primary"
                      >
                        {this.checkProfileImageTypeAndGetText(ProfileImage)}
                      </Button>
                      <input
                        type="file"
                        id="profileImg"
                        ref={inputFile}
                        name="filename"
                        onChange={this.onImageChange}
                        accept="image/*"
                        style={{ display: 'none' }}
                      />
                      {this.checkIfRemoveBtnNeeded(ProfileImage) ? (
                        <Button
                          onClick={this.handleRemoveProfileImage}
                          variant="outlined"
                          color="secondary"
                        >
                          {this.props.users.languageStateForRedux?.buttons?.attendees?.change}
                        </Button>
                      ) : null}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>

              {!this.props.group_id && (
                <Box component="div" className="field-group inputField" pb={2}>
                  <label>
                    {this.props.users.languageStateForRedux?.overviewTab?.audience} <span className="required">*</span>
                  </label>
                  <ReactSelect
                    styles={customStyles}
                    className="custom-multi-select"
                    options={options}
                    placeholder={this.props.users.languageStateForRedux?.common?.select}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    onChange={this.handleChangeMultiselect}
                    allowSelectAll={true}
                    value={this.state.arrayOfAudience}
                  />

                  <p className="error_mesage">
                    {' '}
                    {options.length === 0
                      ? 'Please add audience first'
                      : errors.group_id}{' '}
                  </p>
                </Box>
              )}

              <Box component="div" className="inputField" pb={2}>
                <label>
                  {this.props.users.languageStateForRedux?.column_names?.teams?.email} <span className="required">*</span>
                </label>
                <input
                  type="text"
                  class="form-control small"
                  placeholder={this.props.users.languageStateForRedux?.formfields?.attendees?.kioskModalPlaceholder}
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                />
                <span className="error_mesage"> {errors.email} </span>
              </Box>
              {this.props.groups.customFields &&
                Array.isArray(this.props.groups.customFields) &&
                this.props.groups.customFields.length > 0 &&
                this.props.groups.customFields.map((customField) => {
                  return (
                    <Box
                      key={customField._id}
                      component="div"
                      className="inputField"
                      pb={2}
                    >
                      <Box component="label" display="flex" alignItems="center">
                        <Box component="span">
                          {customField.fieldData.fieldLabel}
                        </Box>
                      </Box>
                      {customField.fieldData.fieldType !== 'dropdown' &&
                        customField.fieldData.fieldType !== 'checkbox' && (
                          <input
                            placeholder={
                              customField.fieldData.fieldType !== 'date'
                                ? `Enter ${customField.fieldData.fieldLabel}`
                                : null
                            }
                            className="form-control small"
                            name={customField._id}
                            value={
                              this.state.customFieldsForContact &&
                              this.state.customFieldsForContact[customField._id]
                            }
                            type={customField.fieldData.fieldType}
                            onChange={this.handleCustomFieldChange}
                          />
                        )}

                      <span className="required">
                        {' '}
                        {errors[customField._id] &&
                          `Please enter the ${customField.fieldData.fieldLabel}`}{' '}
                      </span>
                      {customField.fieldData.fieldType === 'email' && (
                        <span className="required"> {errors.validEmail} </span>
                      )}
                      {customField.fieldData.fieldType === 'url' && (
                        <span className="required"> {errors.validUrl} </span>
                      )}

                      {customField.fieldData.fieldType === 'dropdown' && (
                        <FormControl size="small" fullWidth>
                          <Select
                            type="text"
                            value={
                              this.state.customFieldsForContact &&
                              this.state.customFieldsForContact[customField._id]
                            }
                            onChange={this.handleCustomFieldChange}
                            placeholder={
                              customField.fieldData.fieldType !== 'date'
                                ? `Enter ${customField.fieldData.fieldLabel}`
                                : ``
                            }
                            className="form-control select small"
                            name={customField._id}
                            style={{ padding: '0px 0px' }}
                            MenuProps={MenuProps}
                            displayEmpty
                          >
                            <MenuItem value="" disabled hidden>
                              {`Select a ${customField.fieldData.fieldLabel}`}
                            </MenuItem>
                            {customField?.fieldData?.listValue?.map(
                              (data, id) => (
                                <MenuItem key={id} value={data}>
                                  {data}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                        </FormControl>
                      )}
                      {customField?.fieldData?.fieldType === 'checkbox' &&
                        customField.fieldData?.checkboxValue?.map((item) => (
                          <label>
                            <input
                              type="checkbox"
                              name={customField._id}
                              value={item}
                              checked={
                                customFieldsForContact[
                                  customField._id
                                ]?.indexOf(item) > -1
                                  ? true
                                  : false
                              }
                              onChange={this.handleChangeCheck}
                            />{' '}
                            {item}
                          </label>
                        ))}
                    </Box>
                  );
                })}
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.handleSubmit}
                  >
                    {this.props.users.languageStateForRedux?.audienceTab?.createContact}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>

          <NotificationContainer />
          {loading ? <Loader /> : null}
        </div>
        {/* crop image popup */}
        <Modal
          open={this.state.cropOpen}
          onClose={this.onCloseModal}
          center
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal change-profile-image',
          }}
        >
          <Grid component="form" container px={1.5}>
            <Grid item mt={0} xs={12} pb={4}>
              <Box component="p" mb={0} className="subtitle">
                {this.props.users.languageStateForRedux?.audienceTab?.profilePicture}
              </Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.audienceTab?.pictureModalDesc}
              </p>
            </Grid>
            {/*  <ImgCrop
              profilePic={this.getData}
              pic={this.state.uploadImage}
              uploadImage={this.state.confirmImage}
              langState={this.props.users.languageStateForRedux}
            /> */}
            <ImgCrop
              s3File={this.state.s3File}
              profilePic={this.getData}
              pic={this.state.uploadImage}
              uploadImage={this.state.confirmImage}
              langState={this.props.users.languageStateForRedux}
              handleFileChosen={this.handleFileChosen}
            />
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    onClick={this.onCloseModal}
                    variant="contained"
                    // color="primary"
                    disableElevation
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                  <Button
                    onClick={() => this.saveCroppedImage()}
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    {this.props.users.languageStateForRedux?.common?.save}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        {/* crop image close */}

        <UploadFromFilesPopUp
          uploadFromFiles={this.state.uploadFromFiles}
          buttonState={this.state.buttonState}
          handleCloseUploadFromFileModule={this.handleCloseFileModulePopup}
          handleChoosedFile={this.handleChoosedFile}
          parentForPopup={this.state.parentForPopup}
        />
        
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  contacts: state.Contacts,
  groups: state.Groups,
  users: state.Users
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      groupRequest: Actions.groupRequest,
      contactRequest: Actions.contactRequest,
      listContactRequest: Actions.listContactRequest,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(ContactModal);
