import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { createNotification } from 'helpers';
import { S3 } from 'aws-sdk';
//import uniqid from 'uniqid';
const URL = process.env.REACT_APP_API_URL;
class BaseAPI {
  API!: AxiosInstance;
  showAlert: Boolean = true;
  createRequest(config: AxiosRequestConfig) {
    this.API = axios.create(config);
    this.API.interceptors.response.use(this.onSuccess, this.onError);
    return this.API;
  }
  constructor() {
    const user = JSON.parse(localStorage.getItem('user_details') || '');
    if (!user) {
      console.error('USER NOT FOUND');
    } else {
      this.API = axios.create({
        baseURL: URL,
        headers: {
          token: `bearer ${user.token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      this.API.interceptors.response.use(this.onSuccess, this.onError);
    }
  }
  private onSuccess = (response: any) => {
    if (this.showAlert && response.data && response.data.message) {
      createNotification(
        response.data.success ? 'success' : 'error',
        response.data.message,
      );
    }
    return response;
  };
  private onError = (error: any) => {
    const response = error.response;
    if (this.showAlert && response.data && response.data.message) {
      createNotification('error', response.data.message);
    }
    if (response.status === 401) {
      window.location.href = '/';
    }
    return response;
  };
  uploadFilesWithObject = (
    files: FileList | File[],
    dataObject: Object,
    uploadPath: string,
  ) => {
    const formData = new FormData();

    // Append files
    if (files instanceof FileList) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
    } else if (Array.isArray(files)) {
      for (const file of files) {
        formData.append('files', file);
      }
    } else {
      throw new Error('Invalid file format');
    }

    // Append the JavaScript object
    for (const key in dataObject) {
      if (dataObject.hasOwnProperty(key)) {
        formData.append(key, dataObject[key as keyof {}]);
      }
    }

    return this.API.post(uploadPath, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  // uploadToS3Bucket = async (files: Array<File>, folderPath: string) => {
  //   const s3 = new S3({
  //     accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
  //     secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
  //   });

  //   let arr = [];
  //   for (const file of files) {
  //     arr.push({
  //       params: {
  //         Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
  //         Key: `${folderPath}/${file.name}-${uniqid()}.${
  //           file.type.split('/')[1]
  //         }`,
  //         Body: file,
  //       },
  //       options: {
  //         params: { Metadata: { name: file.name } },
  //       },
  //     });
  //   }

  //   const res = await Promise.all(
  //     arr.map((param: any) => s3.upload(param.params, param.options).promise()),
  //   );
  //   return res.map((result) => ({ result }));
  // };
}
export default BaseAPI;
