import React, { Component } from 'react';
import { withRouter } from "react-router";
import '../../sass/main.scss';
import Button from '@material-ui/core/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from '../../Redux/Actions';
import Loader from '../../Components/Loader/loader';
import { createNotification, redirectTo } from '../../helpers';
import { NotificationContainer } from "react-notifications";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import gapiClient from 'gapi-client';
import moment1 from 'moment-timezone';
import TimePicker from 'react-time-picker';
import { GetTimezoneAPI } from '../../Redux/API';

class UpdateEventForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      Questionvalue: 0,
      Tabvalue: 0,
      theme: '',
      title: 'Update Event',
      pageTitle: "Update Event",
      time: moment().format('HH:mm'),
      endTime: moment().format('HH:mm'),
      currentTime: moment().format('HH:mm'),
      openTime: moment().format('HH:mm'),
      initialDate: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      openDate: new Date(),
      eventName: '',
      eventDomain: '',
      location: '',
      errors: {},
      loading: false,
      _id: 0,
      eventId: 0,
      lat: 0,
      lng: 0,
      oldTime: moment().format('HH:mm'),
      messageBwOpenTime: '',
      timerClock: 0,
      replyToEmail: '',
      timezone: '',
      timezones: [],
      emailSenderName: ''
    };
  }

  async componentDidMount() {
    if (this.props.events) {
      if (this.props.events.success) {
        const events = this.props.events.event;

        this.setState({
          eventName: events.eventName,
          eventDomain: events.eventDomain,
          location: events.location,
          time: events.time,
          endTime: events.endTime,
          openTime: events.openTime,
          oldTime: events.time,
          startDate: new Date(events.startDate),
          endDate: new Date(events.endDate ? events.endDate : events.startDate),
          openDate: new Date(events.openDate ? events.openDate : events.startDate),
          _id: events._id,
          messageBwOpenTime: events.messageBwOpenTime,
          timerClock: events.timerClock,
          eventType: events.eventType,
          replyToEmail: events.replyToEmail,
          timezone: events.timezone,
          emailSenderName: events.emailSenderName
        });
      }
    }

    GetTimezoneAPI().then((result) => {
      if (result.data.status === 200) {
        this.setState({ timezones: result.data.timezone });
      } else {
        createNotification('error', result.data.message);
      }
    }).catch(console.log);

    //await redirectTo(this.props.history,'contact',this.props.location.pathname)
  }

  onChangeDate = (date) => {
    this.setState({ startDate: date });
  };
  onChangeEndDate = (date) => {
    this.setState({ endDate: date });
  };
  onChangeOpenDate = (date) => {
    this.setState({ openDate: date });
  };


  onTimeChange = (time) => {
    this.setState({ time: time });
  };
  onEndTimeChange = (time) => {
    this.setState({ endTime: time });
  };
  onOpenTimeChange = (time) => {
    this.setState({ openTime: time });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleLoading = () => {

    let loading = this.state.loading;
    this.setState({
      loading: !loading
    });
  };


  isValid = () => {

    const { eventName, location, startDate, endDate, time, endTime, openTime, openDate, replyToEmail, timezone, emailSenderName } = this.state;
    let error = {};
    let formIsValid = true;
    const timeRegex = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
    const regexTest = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

    const currentDa = moment().format('MM/DD/YYYY');
    const cDate = `${currentDa}00:00:00`;
    const currentDateOnly = moment(cDate).format('X');
    const currentDate = moment().format('X');

    const startDate1 = moment(startDate).format('MM/DD/YYYY');
    const startD = `${startDate1} ${time}`;
    const startDOnly = `${startDate1} '00:00:00'`;
    const startDateSeconds = moment(startD).format('X');
    const startDateOnly = moment(startDOnly).format('X');

    const endDate1 = moment(endDate).format('MM/DD/YYYY');
    const endD = `${endDate1} ${endTime}`;
    const endDOnly = `${endDate1} '00:00:00'`;
    const endDateSeconds = moment(endD).format('X');
    const endDateOnly = moment(endDOnly).format('X');

    const openDate1 = moment(openDate).format('MM/DD/YYYY');
    const openD = `${openDate1} ${openTime}`;
    const openDOnly = `${openDate1} '00:00:00'`;
    const openDateSeconds = moment(openD).format('X');
    const openDateOnly = moment(openDOnly).format('X');

    if (startDateOnly < currentDateOnly) {
      formIsValid = false;
      error['startDate'] = "*Please enter start date  greater than current date ";
    } else if (startDateSeconds < currentDate) {
      formIsValid = false;
      error['time'] = "*Please enter start time greater than current time.";
    }

    if (startDateOnly > endDateOnly) {
      formIsValid = false;
      error['startDate'] = "*Please enter start date  less than end date ";
    } else if (startDateSeconds > endDateSeconds) {
      formIsValid = false;
      error['endTime'] = "*Please enter end time less than start time.";
    }

    if (openDateOnly > startDateOnly || openDateOnly > endDateOnly) {
      formIsValid = false;
      error['openDate'] = "*Please enter opening date  less than start date and end date";
    } else if (openDateSeconds > startDateSeconds || openDateSeconds > endDateSeconds) {
      formIsValid = false;
      error['opentime'] = "*Please enter opening time less than start time.";
    }

    if (replyToEmail === '' || replyToEmail.trim().length === 0) {
      formIsValid = false;
      error['replyToEmail'] = "*Please enter reply to email.";
    }
    if (replyToEmail && regexTest.test(replyToEmail) === false) {
      formIsValid = false;
      error['replyToEmail'] = "*Please enter a valid email address.";
    }

    if (eventName === '' || eventName.trim().length === 0) {
      formIsValid = false;
      error['eventName'] = "*Please enter event name.";
    }
    if (eventName && eventName.trim().length > 25) {
      formIsValid = false;
      error['eventName'] = "*Only 30 Character is allowed.";
    }

    if (location === '' || location.trim().length === 0) {
      formIsValid = false;
      error['location'] = "*Please enter location.";
    }
    if (startDate === '') {
      formIsValid = false;
      error['startDate'] = "*Please select start date.";
    }
    if (endDate === '') {
      formIsValid = false;
      error['endDate'] = "*Please select end date.";
    }

    if (time === '' || time.trim().length === 0) {
      formIsValid = false;
      error['time'] = "*Please select time.";
    }
    if (endTime === '' || endTime.trim().length === 0) {
      formIsValid = false;
      error['endTime'] = "*Please select end time.";
    }
    if (openTime === '' || openTime.trim().length === 0) {
      formIsValid = false;
      error['time'] = "*Please select opening.";
    }
    if (openTime && timeRegex.test(openTime) === false) {
      formIsValid = false;
      error['openTime'] = "*Please select valid opening time";
    }
    if (openDate === '') {
      formIsValid = false;
      error['openDate'] = "*Please select open date.";
    }
    if (timezone === '' || timezone.trim().length === 0) {
      formIsValid = false;
      error['timezone'] = "*Please select timezone.";
    }
    if (emailSenderName === '' || emailSenderName.trim().length === 0) {
      formIsValid = false;
      error['emailSenderName'] = "*Please enter email sender name.";
    }
    if (emailSenderName && emailSenderName.trim().length > 20) {
      formIsValid = false;
      error['emailSenderName'] = "*Only 20 Character is allowed.";
    }

    this.setState({ errors: error });
    return formIsValid;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.isValid()) {
      const { eventName, location, startDate, endDate, time, _id, lat, lng, endTime, openTime, openDate, messageBwOpenTime, timerClock, replyToEmail, timezone, emailSenderName } = this.state;
      const requestbody = { eventName, location, startDate, endDate, time, id: _id, lat, lng, endTime, messageBwOpenTime, openDate, openTime, timerClock, replyToEmail, timezone, emailSenderName };
      this.props.updateEvent(requestbody);
      this.handleLoading();
      // this.props.startLoading();
    }
  };

  componentDidUpdate(prevProps, prevState) {

    if (this.props.events) {
      if (this.props.events.updateSuccess === true && this.props.events.message !== prevProps.events.message) {

        createNotification('success', this.props.events.message);
        setTimeout(() => {
          let st = (prevState.loading ? this.setState({ loading: false }) : null); 
          redirectTo(this.props.history, `project-type-edit/${this.props.events.event._id}`, this.props.location.pathname);
        }, 3000);

      }

      if (this.props.events.error && this.props.events.error !== prevProps.events.error) {
        createNotification('error', this.props.events.message, '');
        this.handleLoading();
      }
    }
  }

  handleChangeLocation = (location) => {
    this.setState({ location: location });
  };
  handleSelect = location => {
    this.setState({ location });

    geocodeByAddress(location)
      .then(results => getLatLng(results[0]))
      .then(latLng => {

        this.setState({ lat: latLng.lat, lng: latLng.lng });
      })
      .catch(error => console.error('Error', error));
  };

  addEventToCalendars = (eventData, date) => {

    let startTime = moment(`${eventData.startDate}:00.000Z`)
      .add("8", "hours")
      .format();
    let endTime = moment(`${eventData.endDate}:00.000Z`)
      .add("8", "hours").add("30", "minutes")
      .format();

    var gapi = window.gapi;

    var CLIENT_ID = "903336222688-uc69d0bdho6rfe2p2ogv4fb70t62t0db.apps.googleusercontent.com";
    var API_KEY = "AIzaSyCwfhH0AzwGKXvw6qbiiVpvzcZmpoW3-VI";
    var SCOPES = "https://www.googleapis.com/auth/calendar";
    var DISCOVERY_DOCS = [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    ];

    gapi.load("client:auth2", () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      });

      var lp = gapi.client.load('calendar', 'V3');

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          var event = {
            summary: `${eventData.title} - ${moment(`${eventData.startDate}`, [
              "HH:mm",
            ]).format("hh:mm A")} PST`,
            // location: "illumeet square",
            description: `${eventData.google_meet}`,
            start: {
              dateTime: startTime,
              timeZone: moment1.tz.guess(),
            },
            end: {
              dateTime: endTime,
              timeZone: moment1.tz.guess(),
            },

            reminders: {
              useDefault: false,
              overrides: [
                { method: "email", minutes: 24 * 60 },
                { method: "popup", minutes: 10 },
              ],
            },
          };

          var request = gapi.client.calendar.events.insert({
            calendarId: "primary",
            resource: event,
          });

          request.execute((event) => {

            if (event.status === "confirmed") {
              createNotification("success", "Event added to google calendar.");
            } else {
              createNotification(
                "danger",
                "Unable to add event to calendar. Please try again later."
              );
            }
            window.open(event.htmlLink);
          });
        });
    });
  };

  render() {
    const { startDate, endDate, time, eventDomain, eventName, location, errors, loading, initialDate, endTime, openDate, openTime, messageBwOpenTime, timerClock, replyToEmail, timezone, timezones, emailSenderName } = this.state;
    const endD = (endDate !== null && endDate !== undefined) ? moment(endDate).format('MM/DD/YYYY') : moment(startDate).format('MM/DD/YYYY');
    const startDate1 = moment(startDate).format('MM/DD/YYYY');
    const startD = startDate1 + ' ' + time;
    const openDate1 = moment(openDate).format('MM/DD/YYYY');
    const openD = openDate1 + ' ' + openTime;
    
    return ( 
      <div className="recipientsPage">
        <div className="rsvpEventfieldBox eventDetailsStepsSec">
          <div className="formSection p-0">
            <form onSubmit={this.handleSubmit}>
              <div className="completeRegisterboxes">
                <div className="eventFullInput CRCircularBox">
                  <div className="field-group inputField">
                    <label>Event Name</label>
                    <input type="text" className="form-control" name="eventName" value={eventName} maxLength="25" onChange={this.handleChange} />
                    <p className="error_mesage"> {errors.eventName} </p>
                  </div>
                  <div class="field-group inputField">
                    <label>Event Domain Name</label>
                    <div className="editableDomainName">
                      <input type="text" class="form-control emailLoweCase" name="eventDomain" value={eventDomain} onChange={this.handleChange} readOnly /> <span>.illumeetxp.com</span>
                      <p className="error_mesage"> {errors.eventDomain} </p>
                    </div>
                  </div>

                  <div className="field-group inputField">
                    <label>Location</label>
                    <PlacesAutocomplete
                      value={location}
                      onChange={this.handleChangeLocation}
                      onSelect={this.handleSelect}
                    >{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div className="loc1">
                        <input
                          {...getInputProps({
                            placeholder: 'Search Places ...',
                            className: 'location-search-input '
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    </PlacesAutocomplete>
                    <p className="error_mesage"> {errors.location} </p>
                  </div>
                  <div class="field-group inputField">
                    <label>Opening Date </label>
                    <div class="formDate formIcon">
                      <DatePicker
                        selected={openDate}
                        onChange={this.onChangeOpenDate}
                        startDate={openDate}
                        minDate={initialDate}
                        shouldCloseOnSelect={true}
                      />
                      <p className="error_mesage"> {errors.openDate} </p>
                    </div>
                  </div>



                  <div class="field-group inputField ">
                    <label>Opening Time</label>
                    <div class="formTime formIcon">

                      <TimePicker
                        onChange={this.onOpenTimeChange}
                        value={openTime}
                        format='h:m a'
                        //minTime={new Date()}
                        disableClock={true}
                      />
                      <p className="error_mesage"> {errors.openTime} </p>
                    </div>

                  </div>

                  <div class="field-group inputField timeClock">
                    <label>Clock Timer</label>
                    <div class="formTime formIcon">

                      <select class="form-control" name="timerClock" value={timerClock} onChange={this.handleChange}>
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </select>
                      <p className="error_mesage"> {errors.timerClock} </p>
                    </div>

                  </div>
                  <div className="field_group75Both">
                    <div className="field-group inputField">
                      <label>Start Date </label>
                      <div className="formDate formIcon">
                        <DatePicker
                          selected={startDate}
                          onChange={this.onChangeDate}
                          startDate={startDate}
                          endDate={endDate}
                          minDate={initialDate}
                          shouldCloseOnSelect={true}
                        />

                        <p className="error_mesage"> {errors.startDate} </p>
                      </div>
                    </div>
                    <div className="field-group inputField ">
                      <label>Start Time</label>
                      <div className="formTime formIcon">

                        <TimePicker
                          onChange={this.onTimeChange}
                          value={time}
                          format='h:m a'
                          //minTime={new Date()}
                          disableClock={true}
                        />
                        <p className="error_mesage"> {errors.time} </p>
                      </div>
                    </div>

                    <div className="field-group inputField">
                      <label>End Date</label>

                      <div className="formDate formIcon">
                        <DatePicker
                          selected={endDate}
                          onChange={this.onChangeEndDate}
                          minDate={startDate}
                          shouldCloseOnSelect={true}
                        />
                        <p className="error_mesage"> {errors.endDate} </p>
                      </div>
                    </div>

                    <div class="field-group inputField ">
                      <label>End Time</label>
                      <div class="formTime formIcon">

                        <TimePicker
                          onChange={this.onEndTimeChange}
                          value={endTime}
                          format='h:m a'
                          minTime={new Date(startD)}
                          disableClock={true}
                        />
                        <p className="error_mesage"> {errors.endTime} </p>
                      </div>

                    </div>
                  </div>
                  <div className='field_group25Both'>
                    <div class="field-group inputField field_group75">
                      <label>Message between opening time and start time</label>
                      <textarea type="text" class="form-control" name="messageBwOpenTime" maxLength="200" onChange={this.handleChange} value={messageBwOpenTime}>{messageBwOpenTime}</textarea>

                    </div>
                  </div>

                  <div class="field-group inputField">
                    <label>Reply to email</label>
                    <input type="text" class="form-control" name="replyToEmail" value={replyToEmail} maxLength="40" onChange={this.handleChange} />
                    <p className="error_mesage"> {errors.replyToEmail} </p>
                  </div>

                  <div class="field-group inputField">
                    <label>Email Sender Name</label>
                    <input type="text" class="form-control" name="emailSenderName" value={emailSenderName} maxLength="40" onChange={this.handleChange} />
                    <p className="error_mesage"> {errors.emailSenderName} </p>
                  </div>

                  <div class="field-group inputField timeClock">
                    <label>Select Time Zone</label>
                    <div class="formTime formIcon">
                      <select class="form-control" name="timezone" onChange={this.handleChange} value={timezone}>
                        <option value={false}>Select timezone</option>
                        {timezones && timezones.length > 0 && timezones.map((data) =>
                          <option value={data.timezone}>{data.timezone}</option>
                        )}
                      </select>
                      <p className="error_mesage"> {errors.timezone} </p>
                    </div>
                  </div>



                </div>
                <div className="submitForm justify-content-center">
                  <Button variant="contained" className="themeBlueButton" color="primary" type="submit">
                    Update Event
                  </Button>
                </div>
              </div>


            </form>
          </div>

        </div>



        <NotificationContainer />
        {loading ?
          <Loader />
          : null}

      </div>




    );
  }
}


const mapStateToProps = state => ({
  events: state.Events
});

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      updateEvent: Actions.updateEventsRequest,
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch
  );
export default connect(mapStateToProps, mapActionsToProps)(withRouter(UpdateEventForm));