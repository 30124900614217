import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'
class ApexChart extends React.Component {
  constructor(props) {
    super(props) 
    let max = Math.max(...this.props.sendArray)
    let max3 = Math.max(...this.props.sendArray)
    let fmax = max > max3 ? max : max3 
    this.state = {
      series: [
        {
          name: 'Bounced Email',
          data: this.props.totalBounced,
        },
        {
          name: 'Send Emails',
          data: this.props.sendArray,
        },
        {
          name: 'Reject Emails',
          data: this.props.rejectArray,
        },
      ],
      legend: {
        position: 'bottom',
      },
      options: {
        chart: {
          height: 350,
          type: 'area',
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#fb6239', '#b8a7f9', '#5c34f1'],
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        title: {
          text: 'Emails sent and bounced',
          align: 'center',
          margin: 20,
          style: {
            fontSize: '20px',
            fontWeight: '400',
            fontFamily: 'Rubik',
            color: '#222025',
          },
        },
        xaxis: {
          type: 'string',
          categories: [
            'Today',
            'Last 7 days',
            'Last 30 days',
            'Last 60 days',
            'Last 90 days',
          ],
          title: {
            text: 'Days',
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
        yaxis: {
          title: {
            text: 'Count',
          },
          tickAmount: 4,
          min: 1,
          max: fmax,
          axisBorder: {
            show: true,
            color: 'rgb(243, 243, 243)',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    }
  }

  componentDidMount() {
    let max = Math.max(...this.props.sendArray)
    let max3 = Math.max(...this.props.sendArray)
    let fmax = max > max3 ? max : max3
    this.setState({
      series: [
        {
          name: 'Bounced Email',
          data: this.props.totalBounced,
        },
        {
          name: 'Send Emails',
          data: this.props.sendArray,
        },
        {
          name: 'Reject Emails',
          data: this.props.rejectArray,
        },
      ],
      legend: {
        position: 'bottom',
      },
      options: {
        chart: {
          height: 350,
          type: 'area',
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#fb6239', '#b8a7f9', '#5c34f1'],
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        title: {
          text: 'Emails sent and bounced',
          align: 'center',
          margin: 20,
          style: {
            fontSize: '20px',
            fontWeight: '400',
            fontFamily: 'Rubik',
            color: '#222025',
          },
        },
        xaxis: {
          type: 'string',
          categories: [
            'Today',
            'Last 7 days',
            'Last 30 days',
            'Last 60 days',
            'Last 90 days',
          ],
          title: {
            text: 'Days',
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
        yaxis: {
          title: {
            text: 'Count',
          },
          tickAmount: 4,
          min: 1,
          max: fmax,
          axisBorder: {
            show: true,
            color: 'rgb(243, 243, 243)',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    })
  }

  componentDidUpdate(prevProps) { 
    if (
      prevProps.sendArray !== this.props.sendArray ||
      prevProps.totalBounced !== this.props.totalBounced ||
      prevProps.rejectArray !== this.props.rejectArray
    ) {
      let max = Math.max(...this.props.sendArray)
      let max3 = Math.max(...this.props.sendArray)
      let fmax = max > max3 ? max : max3
      this.setState({
        series: [
          {
            name: 'Bounced Email',
            data: this.props.totalBounced,
          },
          {
            name: 'Send Emails',
            data: this.props.sendArray,
          },
          {
            name: 'Reject Emails',
            data: this.props.rejectArray,
          },
        ],
        legend: {
          position: 'bottom',
        },
        options: {
          chart: {
            height: 350,
            type: 'area',
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#fb6239', '#b8a7f9', '#5c34f1'],
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          title: {
            text: 'Emails sent and bounced',
            align: 'center',
            margin: 20,
            style: {
              fontSize: '20px',
              fontWeight: '400',
              fontFamily: 'Rubik',
              color: '#222025',
            },
          },
          xaxis: {
            type: 'string',
            categories: [
              'Today',
              'Last 7 days',
              'Last 30 days',
              'Last 60 days',
              'Last 90 days',
            ],
            title: {
              text: 'Days',
            },
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
          yaxis: {
            title: {
              text: 'Count',
            },
            tickAmount: 4,
            min: 1,
            max: fmax,
            axisBorder: {
              show: true,
              color: 'rgb(243, 243, 243)',
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      })
    }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={350}
        />
      </div>
    )
  }
}

export default ApexChart
