/**
 * Component for managing accounts.
 * @description This component displays a list of accounts with various functionalities like search, sorting, deletion, and adding new accounts.
 * It also provides options to manage custom fields associated with accounts.
 *
 * @component
 * @param {ProjectsList} projectsList - List of projects.
 * @returns {JSX.Element} - Returns the JSX for the Accounts component.
 */

import React, { SyntheticEvent, useEffect, useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Grid from '@mui/material/Grid';
import { theme } from '../../../theme/theme';
import MoreIcon from '../../../../src/assets/images/icons/more.svg';
import EditIcon from '../../../../src/assets/images/icons/edit.svg';
import SearchIcon from '../../../../src/assets/images/icons/search.svg';
import '../../../sass/main.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  Account,
  AccountsState,
  ContactCf,
  Project,
} from 'Redux/Reducers/opportunity/accounts.reducer';
import { Actions } from 'Redux/Actions';
import PaginationFunctional, {
  handleEndCountForPagination,
} from 'Pages/ProjectDashboard/Integration/AppIntegration/googleWorkspaceComponents/PaginationFunctional';
import Loader from 'Components/Loader/loader';
import ManageCustomFields from '../CustomFields/ManageCustomFields';
import {
  CustomField,
  CustomFields_State,
} from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { ProjectsList } from 'Pages/AIChatbot/Tabs/General/GeneralDashboard';
import { useHistory, useLocation } from 'react-router';
import { Stack, Box } from '@mui/material';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import { getSystemLevelPermissions } from '../../../helpers/common';
import OpportunityAccountsAPI from 'Redux/API/opportunity/opportunityAccountsAPI';
import ImportModal from '../CommonComponents/ImportModal';
import AccountView from './AccountView';
import AddOrEditAccount from './AddOrEditAccount';
import { createNotification } from 'helpers';
import { formatDateField } from 'helpers/common';
import CustomFieldFilterDropDown from 'Common/CustomFieldFilterDropDown';
import FilterByDraft from '../Common/FilterByDraft';
import FilterByLastImported from '../Common/FilterByLastImported';
import CustomTooltip from 'Components/CustomHTMLTooltip';
import FilterDropDown from 'Common/FilterDropDown';
import { sourceFilterArr } from '../Opportunity/Opportunity';
import ViewLayout from '../Common/ViewLayout';

export type FetchAccountsQueryParams = {
  pageNo: number;
  limit: number;
  sortBy: string;
  sortOrder: string;
  searchText: string;
  from: string;
};

type AccountsProps = {
  projectsList: ProjectsList;
  handleTabChange: (
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    searchText: string,
  ) => void;
};

export type SxProps = {
  backgroundColor: string;
  boxShadow: string;
  borderRadius: number;
  marginTop: number;
};

export default function Accounts({ handleTabChange }: AccountsProps) {
  const [allowed, setAllowed] = useState<any>({});
  const location = useLocation<any>();
  const dispatch = useDispatch();
  const {
    accounts,
    totalCount,
    loading,
    createOpportunityAccountSuccess,
    deleteOpportunityAccountSuccess,
    isLoading: isAccountsLoading,
    filterCount,
  } = useSelector((state: any) => state.OpportunityAccounts) as AccountsState;
  const { customFields, isLoading: isCustomFieldsLoading } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;
  const anchorRef = useRef(null);
  const anchorRefAction = useRef(null);
  const [openSettingsDropdown, setOpenSettingsDropdown] = useState(false);
  const [openActionDropDown, setOpenActionDropdown] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [searchTextTemp, setSearchTextTemp] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [startCount, setStartCount] = useState<number>(1);
  const [endCount, setEndCount] = useState<number>(10);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [showManageCustomFields, setShowManageCustomFields] =
    useState<boolean>(false);
  const [accountToBeEdited, setAccountToBeEdited] = useState<
    Account | undefined
  >({} as Account);
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] =
    useState<boolean>(false);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const initialRender = useRef(true);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [filterByCustomField, setFilterByCustomField] = useState<string[]>([]);
  const [filterByStatus, setFilterByStatus] = useState<string[]>([]);
  const [filterByLastImported, setFilterByLastImported] = useState<boolean>(false);
  const [filterBySource, setFilterBySource] = useState<string[]>([]);

  const queryParams: FetchAccountsQueryParams = {
    pageNo,
    limit,
    sortBy,
    sortOrder,
    searchText,
    from: 'viewAccount',
  };

  useEffect(() => {
    setAllowed(getSystemLevelPermissions());
    fetchAllOpportunityCustomField();
    fetchAllTheActiveUsers();
  }, []);

  useEffect(() => {
    if (createOpportunityAccountSuccess) {
      setSelectedCheckboxes([]);
    }
    if (deleteOpportunityAccountSuccess) {
      setSelectedCheckboxes([]);
    }
  }, [createOpportunityAccountSuccess, deleteOpportunityAccountSuccess]);

  useEffect(() => {
    fetchAllOpportunityAccounts();
  }, [pageNo, limit, sortBy, sortOrder, searchText, filterByCustomField, filterByStatus, filterByLastImported, filterBySource]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      handleEndCountForPagination(totalCount, limit, pageNo, setEndCount);
    }
  }, [totalCount]);

  useEffect(() => {
    if (location.state) {
      if (location.state.comingFromOtherTab === true) {
        handleAddOrUpdateNewAccount(location.state.account, true);
      } else {
        setSearchText(location.state as string);
        setSearchTextTemp(location.state as string);
      }
    }
  }, [location.state]);

  /**
   * Fetches all custom fields related to the current tab type.
   * Dispatches an action to fetch custom fields based on the current tab type.
   * @function fetchAllOpportunityCustomField
   * @description Fetches all custom fields related to the current tab type.
   * @return {void}
   */
  const fetchAllOpportunityCustomField = () => {
    const currentTab = location.pathname.split('/').at(-1);
    const data = {
      customFieldType: currentTab,
      from: 'viewAccount',
    };
    dispatch(Actions.fetchAllOpportunityCustomFieldRequest(data));
  };

  const fetchAllTheActiveUsers = () => {
    try {
      dispatch(Actions.getActiveUsersForCompanyRequest({}));
    } catch (error) {
      console.log('error', error);
    }
  };

  /**
   * Fetches all opportunity accounts based on the provided query parameters.
   * Dispatches an action to fetch opportunity accounts.
   * @function fetchAllOpportunityAccounts
   * @description Fetches all opportunity accounts based on the provided query parameters.
   * @return {void}
   */
  const fetchAllOpportunityAccounts = () => {
    const bodyParamsForfiltering = {
      filterByCustomField,
      filterByStatus,
      filterByLastImported,
      filterBySource
    };

    const data = {
      queryParams,
      bodyParams: bodyParamsForfiltering,
    };
    // const data = queryParams;
    dispatch(Actions.fetchAllOpportunityAccountsRequest(data));
  };

  /**
   * Toggles the visibility of the settings dropdown.
   * Updates the state to toggle the visibility of the settings dropdown.
   * @function handleToggleSettings
   * @description Toggles the visibility of the settings dropdown.
   * @return {void}
   */
  const handleToggleSettings = () => {
    setOpenSettingsDropdown((prev) => !prev);
  };

  /**
   * Toggles the visibility of the action dropdown.
   * Updates the state to toggle the visibility of the action dropdown.
   * @function handleToggleAction
   * @description Toggles the visibility of the action dropdown.
   * @return {void}
   */
  const handleToggleAction = () => {
    setOpenActionDropdown((prevOpen) => !prevOpen);
  };

  /**
   * Closes both the settings and action dropdowns.
   * Updates the state to close both the settings and action dropdowns.
   * @function handleCloseDropdowns
   * @description Closes both the settings and action dropdowns.
   * @return {void}
   */
  const handleCloseDropdowns = () => {
    setOpenSettingsDropdown(false);
    setOpenActionDropdown(false);
  };

  /**
   * Handles the keydown event for the list.
   * Closes the settings dropdown if the Tab key is pressed.
   * @function handleListKeyDown
   * @param {React.KeyboardEvent<HTMLUListElement>} event - The keyboard event object.
   * @description Handles the keydown event for the list.
   * @return {void}
   */
  function handleListKeyDown(event: React.KeyboardEvent<HTMLUListElement>) {
    if (event.key === 'Tab') {
      setOpenSettingsDropdown(false);
    }
  }

  /**
   * Handles the change event for the search text input.
   * Updates the search text temporary state.
   * @function handleSearchText
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event object.
   * @description Handles the change event for the search text input.
   * @return {void}
   */
  const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchTextTemp(e.target.value);
  };

  /**
   * Handles the form submit event for searching.
   * Updates the search text state and resets pagination and selected checkboxes.
   * @function handleSearchTextSubmit
   * @param {React.FormEvent<HTMLFormElement>} e - The form submit event object.
   * @description Handles the form submit event for searching.
   * @return {void}
   */
  const handleSearchTextSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchText(searchTextTemp);
    setPageNo(1); // pagination
    setStartCount(1); // pagination
    setSelectedCheckboxes([]);
  };

  /**
   * Handles the checkbox selection event.
   * Updates the selected checkboxes state based on the checkbox status.
   * @function handleSelectCheckBox
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e - The mouse event object.
   * @param {string} accountId - The ID of the account.
   * @description Handles the checkbox selection event.
   * @return {void}
   */
  const handleSelectCheckBox = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    accountId: string,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      setSelectedCheckboxes([...selectedCheckboxes, accountId]);
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((id) => id !== accountId),
      );
    }
  };

  /**
   * Handles the select all checkboxes event.
   * Updates the selected checkboxes state to select or deselect all checkboxes.
   * @function handleSelectAllCheckboxes
   * @param {React.MouseEvent<HTMLButtonElement>} e - The mouse event object.
   * @description Handles the select all checkboxes event.
   * @return {void}
   */
  const handleSelectAllCheckboxes = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      let arr: string[] = [];
      accounts.forEach((account) => arr.push(account._id));
      setSelectedCheckboxes(arr);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  /**
   * Handles the delete action for selected accounts.
   * Dispatches an action to delete selected accounts.
   * @function handleDelete
   * @description Handles the delete action for selected accounts.
   * @return {void}
   */
  const handleDelete = () => {
    const data = {
      bodyParams: {
        ids: selectedCheckboxes,
      },
      queryParams: queryParams,
    };
    dispatch(Actions.deleteOpportunityAccountRequest(data));
    handleCloseDropdowns();
    closeDeleteConfirmationModel();
  };

  /**
   * Handles the sorting event for the accounts table.
   * Updates the sort criteria and resets selected checkboxes.
   * @function handleSorting
   * @param {string} sort_by - The field to sort by.
   * @description Handles the sorting event for the accounts table.
   * @return {void}
   */
  const handleSorting = (sort_by: string) => {
    setSortBy(sort_by);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSelectedCheckboxes([]);
  };

  /**
   * Handles the addition or update of a new account.
   * Sets the account to be edited if in edit mode and shows the add new modal.
   * @function handleAddOrUpdateNewAccount
   * @param {Account} account - The account data.
   * @param {boolean} isBeingEdited - Indicates if the account is being edited.
   * @description Handles the addition or update of a new account.
   * @return {void}
   */
  const handleAddOrUpdateNewAccount = (
    account?: Account,
    isBeingEdited: boolean = false,
  ) => {
    // if (isBeingEdited && allowed['editAccount']) {
    if (isBeingEdited) {
      setAccountToBeEdited(account);
      location.state?.comingFromOtherTab
        ? setShowAddNew(true)
        : setShowViewModal(true);
    } else if (!isBeingEdited) {
      setAccountToBeEdited(account);
      setShowAddNew(true);
    }
  };

  /**
   * Opens the manage custom fields modal.
   * Sets the state to show the manage custom fields modal.
   * @function handleOpenManageCustomFields
   * @description Opens the manage custom fields modal.
   * @return {void}
   */
  const handleOpenManageCustomFields = () => {
    setShowManageCustomFields(true);
  };

  /**
   * Closes the manage custom fields modal.
   * Sets the state to hide the manage custom fields modal.
   * @function handleCloseManageCustomFields
   * @description Closes the manage custom fields modal.
   * @return {void}
   */
  const handleCloseManageCustomFields = () => {
    setShowManageCustomFields(false);
  };

  /**
   * Resets the add new modal for account.
   * Sets the account to be edited to an empty object.
   * @function resetAddNewModalAccount
   * @description Resets the add new modal for account.
   * @return {void}
   */
  const resetAddNewModalAccount = () => {
    setAccountToBeEdited({} as Account);
  };

  const closeDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(false);
  };

  const handleOpenDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(true);
  };

  const handleExport = async (onlyExportSelected: boolean) => {
    type exportData = {
      queryParams: FetchAccountsQueryParams;
      bodyParams?: {
        ids?: string[];
        timezone: string;
      };
    };
    let data: exportData = {
      queryParams,
      bodyParams: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
    if (onlyExportSelected) {
      data = {
        queryParams,
        bodyParams: {
          ids: selectedCheckboxes,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      };
    }

    const opportunityAccountsAPI = new OpportunityAccountsAPI();
    // @ts-ignore
    const res = await opportunityAccountsAPI.export(data);
    if (res.data.csvData) {
      const url = window.URL.createObjectURL(new Blob([res?.data?.csvData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Accounts.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleClickOpenImportModal = (doOpenImportModal: boolean) => {
    setOpenImportModal(doOpenImportModal);
  };

  const handleOpenImportModal = (isOpen: boolean) => {
    setOpenImportModal(isOpen);
  };

  const showRestOfPages = (cfs: string[]) => {
    return cfs?.slice(1, cfs.length);
  };

  const closeViewModal = () => {
    setShowViewModal(false);
  };

  const showRestOfProjects = (projects: Project[]) => {
    let projectsLength = projects.length;
    return projects.slice(1, projectsLength);
  };

  return (
    <Box className="Opportunity-Account">
      {!showViewModal && !showAddNew ? (
        <Box p={3}>
          <Box py={0} className='tab-container'>
            <Grid container spacing={2} mb={3} className="top-filter-area">
              <Grid item xs>
                <Grid container spacing={1}>
                  <Grid item>
                    <FilterByDraft
                      filterByStatus={filterByStatus}
                      setFilterByStatus={setFilterByStatus}
                      filterCount={filterCount}
                    />
                  </Grid>
                  <Grid item>
                    <FilterByLastImported
                      filterByLastImported={filterByLastImported}
                      setFilterByLastImported={setFilterByLastImported}
                      filterCount={filterCount}
                    />
                  </Grid>
                  <FilterDropDown
                    dropdownArray={sourceFilterArr}
                    filterBy={filterBySource}
                    setFilterBy={setFilterBySource}
                    filtering="source"
                    filterCount={filterCount}
                    totalCount={totalCount}
                  />
                </Grid>
              </Grid>

              <Grid item>
                <Grid container spacing={1.25} className="team-dash-right">
                  <Grid item className="">
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      <CustomFieldFilterDropDown
                        dropdownArray={customFields}
                        filterBy={filterByCustomField}
                        setFilterBy={setFilterByCustomField}
                      />
                      <form onSubmit={handleSearchTextSubmit}>
                        <TextField
                          name="searchTextTemp"
                          placeholder="Search..."
                          className="search"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img alt="" src={SearchIcon} />
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleSearchText}
                          value={searchTextTemp}
                        />
                      </form>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <div>
                      <Button
                        variant="outlined"
                        color="primary"
                        disableElevation
                        ref={anchorRefAction}
                        aria-controls={
                          openActionDropDown ? 'menu-list-grow' : undefined
                        }
                        aria-haspopup="true"
                        onClick={handleToggleAction}
                        endIcon={<ArrowDropDownIcon />}
                      >
                        Actions
                      </Button>
                      <Popper
                        open={openActionDropDown}
                        style={{ top: 10, zIndex: 999 }}
                        placement="bottom-start"
                        transition
                        anchorEl={anchorRefAction.current}
                        role={undefined}
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Fade {...TransitionProps} timeout={250}>
                            <Box
                              sx={
                                // @ts-ignore
                                {
                                  backgroundColor: theme.palette.common.white,
                                  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                  borderRadius: '4px',
                                  marginTop: '8px',
                                } as SxProps
                              }
                            >
                              <ClickAwayListener
                                onClickAway={handleCloseDropdowns}
                              >
                                <MenuList
                                  autoFocusItem={openActionDropDown}
                                  id="menu-list-grow"
                                  onKeyDown={handleListKeyDown}
                                >
                                  {allowed['deleteAccount'] && (
                                    <MenuItem
                                      onClick={() =>
                                        handleOpenDeleteConfirmationModel()
                                      }
                                      disabled={!selectedCheckboxes.length}
                                      style={{
                                        color: theme.palette.secondary.main,
                                      }}
                                    >
                                      Delete
                                    </MenuItem>
                                  )}
                                  {allowed['viewAccount'] && (
                                    <>
                                      <MenuItem
                                        onClick={() => handleExport(true)}
                                        disabled={!selectedCheckboxes.length}
                                      >
                                        Export
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => handleExport(false)}
                                        disabled={selectedCheckboxes.length > 0}
                                      >
                                        Export All
                                      </MenuItem>
                                    </>
                                  )}
                                  {allowed['createAccount'] && (
                                    <MenuItem
                                      onClick={() =>
                                        handleClickOpenImportModal(true)
                                      }
                                    >
                                      Import
                                    </MenuItem>
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Box>
                          </Fade>
                        )}
                      </Popper>
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <Button
                        variant="outlined"
                        color="primary"
                        disableElevation
                        ref={anchorRef}
                        aria-controls={
                          openSettingsDropdown ? 'menu-list-grow' : undefined
                        }
                        aria-haspopup="true"
                        onClick={handleToggleSettings}
                        endIcon={<ArrowDropDownIcon />}
                      >
                        Settings
                      </Button>
                      <Popper
                        open={openSettingsDropdown}
                        style={{ top: 10, zIndex: 999 }}
                        placement="bottom-start"
                        transition
                        anchorEl={anchorRef.current}
                        role={undefined}
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Fade {...TransitionProps} timeout={250}>
                            <Box
                              sx={
                                // @ts-ignore
                                {
                                  backgroundColor: theme.palette.common.white,
                                  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                  borderRadius: '4px',
                                  marginTop: '8px',
                                } as SxProps
                              }
                            >
                              <ClickAwayListener
                                onClickAway={handleCloseDropdowns}
                              >
                                <MenuList
                                  autoFocusItem={openSettingsDropdown}
                                  id="menu-list-grow"
                                  onKeyDown={handleListKeyDown}
                                >
                                  {allowed['accountCustomFields'] && (
                                    <MenuItem
                                      onClick={handleOpenManageCustomFields}
                                    >
                                      Custom Fields
                                    </MenuItem>
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Box>
                          </Fade>
                        )}
                      </Popper>
                    </div>
                  </Grid>
                  <Grid item>
                    {allowed['createAccount'] && (
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => handleAddOrUpdateNewAccount()}
                      >
                        Add New
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <TableContainer
              className="list-table"
              style={{ maxHeight: 'calc(100vh - 380px)' }}
            >
              <Table className="table">
                <TableHead
                  style={{
                    position: 'sticky',
                    top: '0',
                    backgroundColor: '#f6f6fc',
                    zIndex: 2,
                  }}
                >
                  <TableRow>
                    <TableCell className="checkbox sticky-cell">
                      <Checkbox
                        color="primary"
                        onClick={handleSelectAllCheckboxes}
                        checked={
                          selectedCheckboxes.length
                            ? selectedCheckboxes.length === accounts.length
                            : false
                        }
                      />
                    </TableCell>
                    <TableCell
                      className={
                        'long-text sticky-cell ' +
                        (sortBy === 'name'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('name')}
                      style={{ left: 50 }}
                    >
                      Account Name
                    </TableCell>
                    <TableCell
                      className={
                        'text ' +
                        (sortBy === 'projectId'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('project.name')}
                    >
                      Projects
                    </TableCell>
                    <TableCell
                      className={
                        'text ' +
                        (sortBy === 'source'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('source')}
                    >
                      Source
                    </TableCell>
                    <TableCell
                      className={
                        'text ' +
                        (sortBy === 'status'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('status')}
                    >
                      Status
                    </TableCell>
                    {customFields.map((cf) => {
                      return (
                        <TableCell
                          key={cf._id}
                          className={
                            'custom-field ' +
                            (sortBy === `customFields.${cf._id}`
                              ? sortOrder === 'asc'
                                ? 'sort-asc'
                                : 'sort-des'
                              : '')
                          }
                          onClick={() =>
                            handleSorting(
                              `customFieldsWithProperValues.${cf._id}`,
                            )
                          }
                        >
                          {cf.fieldData.fieldName}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      className={
                        'long-text ' +
                        (sortBy === 'updatedAt'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('updatedAt')}
                    >
                      Updated on
                    </TableCell>
                    <TableCell className="options">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(accounts) && accounts.length > 0 ? (
                    accounts.map((account) => {
                      return (
                        <TableRow key={account._id}>
                          <TableCell
                            className="checkbox sticky-cell"
                            style={{ left: 1 }}
                          >
                            <Checkbox
                              color="primary"
                              onClick={(e) =>
                                handleSelectCheckBox(e, account._id)
                              }
                              checked={selectedCheckboxes.includes(account._id)}
                            />
                          </TableCell>
                          <TableCell
                            className="long-text sticky-cell link"
                            onClick={() =>
                              handleAddOrUpdateNewAccount(account, true)
                            }
                            style={{ left: 50 }}
                          >
                            {' '}
                            {account?.name || '-'}
                          </TableCell>
                          <TableCell className="text">
                            <Stack
                              direction={'row'}
                              alignItems={'center'}
                              spacing={1}
                            >
                              <span
                                className="long-text"
                                style={{ maxWidth: '80%' }}
                              >
                                {' '}
                                {account?.projects &&
                                  account?.projects?.length > 0
                                  ? account?.projects[0]?.eventName
                                  : '-'}
                              </span>
                              {account?.projects?.length > 1 && (
                                <div className="detail-popup">
                                  <span className="count">
                                    +{account?.projects.length - 1}
                                  </span>
                                  <div className="info">
                                    {showRestOfProjects(account?.projects).map(
                                      (project: Project, i: number) => {
                                        return (
                                          <div key={i}>
                                            <p>{project.eventName}</p>
                                          </div>
                                        );
                                      },
                                    )}
                                  </div>
                                </div>
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell className="text">
                            {account?.source === 'aiAgent'
                              ? 'AI Agent'
                              : account?.source === 'imported'
                                ? 'Imported'
                                : 'Manually Added'}
                          </TableCell>
                          <TableCell className="text">
                            {account?.status === 'draft' ? 'Draft' : ''}
                          </TableCell>
                          {customFields.map((cf: CustomField) => {
                            let value = account?.customFieldsWithProperValues?.[
                              cf._id as string
                            ] as string;

                            return Array.isArray(
                              account?.customFieldsWithProperValues?.[
                              cf._id as string
                              ],
                            ) ? (
                              <TableCell key={cf._id as string} className="text">
                                <Stack
                                  direction="row"
                                  alignItems={'center'}
                                  spacing={1}
                                >
                                  <span
                                    className={`long-text ${cf.fieldData.fieldType === 'user' && 'link'
                                      }`}
                                    style={{ maxWidth: '80%' }}
                                    onClick={() => {
                                      if (
                                        cf.fieldData.fieldType === 'user' &&
                                        account?.customFields?.[cf._id as string]
                                      ) {
                                        window.open(
                                          `mailto:${(
                                            account
                                              ?.customFieldsWithProperValues?.[
                                            cf._id as string
                                            ] as string[]
                                          )?.[0]
                                          }`,
                                        );
                                      }
                                    }}
                                  >
                                    {' '}
                                    {account?.customFieldsWithProperValues?.[
                                      cf._id as string
                                    ] &&
                                      Array.isArray(
                                        account?.customFieldsWithProperValues?.[
                                        cf._id as string
                                        ],
                                      )
                                      ? (
                                        account?.customFieldsWithProperValues?.[
                                        cf._id as string
                                        ] as string[]
                                      )[0]
                                      : '-'}
                                  </span>
                                  {account?.customFieldsWithProperValues?.[
                                    cf._id as string
                                  ] &&
                                    Array.isArray(
                                      account?.customFieldsWithProperValues?.[
                                      cf._id as string
                                      ],
                                    ) &&
                                    (
                                      account?.customFieldsWithProperValues?.[
                                      cf._id as string
                                      ] as string[]
                                    ).length
                                    ? (
                                      account?.customFieldsWithProperValues?.[
                                      cf._id as string
                                      ] as string[]
                                    )?.length > 1 && (
                                      <div className="detail-popup">
                                        <span className="count">
                                          +
                                          {(
                                            account
                                              ?.customFieldsWithProperValues?.[
                                            cf._id as string
                                            ] as string[]
                                          )?.length - 1}
                                        </span>
                                        <div className="info">
                                          {showRestOfPages(
                                            account
                                              ?.customFieldsWithProperValues?.[
                                            cf._id as string
                                            ] as string[],
                                          )?.map(
                                            (cfValue: String, i: number) => {
                                              return (
                                                <div
                                                  key={i}
                                                  className={`${cf.fieldData.fieldType ===
                                                    'user' && 'link'
                                                    }`}
                                                  style={{ zIndex: 999999 }}
                                                  onClick={() => {
                                                    if (
                                                      cf.fieldData.fieldType ===
                                                      'user' &&
                                                      account?.customFields?.[
                                                      cf._id as string
                                                      ]
                                                    ) {
                                                      window.open(
                                                        `mailto:${cfValue}`,
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <p>{cfValue}</p>
                                                </div>
                                              );
                                            },
                                          )}
                                        </div>
                                      </div>
                                    )
                                    : '-'}
                                </Stack>
                              </TableCell>
                            ) : (
                              <TableCell
                                className={`custom-field ${allowed['viewContacts'] &&
                                  [
                                    'contact',
                                    'url',
                                    'email',
                                    'facebook',
                                    'instagram',
                                    'x',
                                    'pinterest',
                                    'linkedin',
                                    'phone'
                                  ].includes(cf.fieldData.fieldType) &&
                                  (account?.customFieldsWithProperValues?.[
                                    cf._id as string
                                  ] as string) &&
                                  'link'
                                  }`}
                                key={cf._id}
                                onClick={(e) => {
                                  if (
                                    account?.customFieldsWithProperValues?.[
                                    cf._id as string
                                    ] as string
                                  ) {
                                    if (cf.fieldData.fieldType === 'contact') {
                                      if (allowed['viewContacts']) {
                                        handleTabChange(
                                          e,
                                          '3',
                                          account?.customFieldsWithProperValues?.[
                                          cf._id as string
                                          ] as string,
                                        );
                                      } else {
                                        createNotification(
                                          'error',
                                          "Sorry! You don't have permission to view this contact",
                                        );
                                      }
                                    } else if (
                                      [
                                        'url',
                                        'facebook',
                                        'instagram',
                                        'x',
                                        'pinterest',
                                        'linkedin',
                                      ].includes(cf.fieldData.fieldType)
                                    ) {
                                      let url = account
                                        ?.customFieldsWithProperValues?.[
                                        cf._id as string
                                      ] as string;
                                      if (!/^https?:\/\//i.test(url)) {
                                        // If not, prepend 'https://'
                                        url = 'https://' + url;
                                      }
                                      window.open(url);
                                    } else if (
                                      cf.fieldData.fieldType === 'email'
                                    ) {
                                      window.open(
                                        `mailto:${account?.customFieldsWithProperValues?.[
                                        cf._id as string
                                        ] as string
                                        }`,
                                      );
                                    } else if (cf.fieldData.fieldType === 'phone') {
                                      window.open(`tel:${account
                                        ?.customFieldsWithProperValues?.[
                                        cf._id as string
                                      ] as string}`);
                                    }
                                  }
                                }}
                              >
                                {cf.fieldData.fieldType === 'contact' ? (
                                  (account?.customFieldsWithProperValues?.[
                                    cf._id as string
                                  ] as string) || '-'
                                ) : cf.fieldData.fieldType === 'date' && value ? (
                                  formatDateField(account, cf, customFields)
                                ) : cf.fieldData.fieldType === 'boolean' ? (
                                  account?.customFieldsWithProperValues?.[
                                    cf._id as string
                                  ]?.toString() === 'true' ? (
                                    'True'
                                  ) : account?.customFieldsWithProperValues?.[
                                    cf._id as string
                                  ]?.toString() === 'false' ? (
                                    'False'
                                  ) : (
                                    '-'
                                  )
                                ) : cf.fieldData.fieldType === 'longText' &&
                                  account?.customFieldsWithProperValues?.[
                                  cf._id as string
                                  ] ? (
                                  <CustomTooltip
                                    interactive
                                    title={
                                      <Box className="upgrade-tooltip long-description">
                                        <p>
                                          {
                                            account
                                              ?.customFieldsWithProperValues?.[
                                            cf._id as string
                                            ] as string
                                          }
                                        </p>
                                      </Box>
                                    }
                                    placement="bottom-start"
                                    arrow={false}
                                  >
                                    <span>
                                      {
                                        account?.customFieldsWithProperValues?.[
                                        cf._id as string
                                        ] as string
                                      }
                                    </span>
                                  </CustomTooltip>
                                ) : (
                                  (account?.customFieldsWithProperValues?.[
                                    cf._id as string
                                  ] as string) || '-'
                                )}
                              </TableCell>
                            );
                          })}

                          <TableCell className="long-text">
                            {' '}
                            {moment(account.updatedAt).format(
                              'MMM, Do YYYY, h:mm A',
                            )}
                          </TableCell>
                          <TableCell className="options" align="center">
                            {' '}
                            {allowed['editAccount'] && (
                              <div
                                onClick={() =>
                                  handleAddOrUpdateNewAccount(account, false)
                                }
                              >
                                <img alt="Edit" src={EditIcon} />{' '}
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                        There are no records to display
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <PaginationFunctional
              startCount={accounts.length === 0 ? 0 : startCount}
              setStartCount={setStartCount}
              endCount={accounts.length === 0 ? 0 : endCount}
              limit={limit}
              setLimit={setLimit}
              page_no={pageNo}
              setPage_no={setPageNo}
              totalItemsCount={totalCount}
              listItemsFn={() => { }}
            />
          </Box>
        </Box>
      ) : showAddNew ? (
        <Box p={3}>
          <Box py={0} className='tab-container'>
            <AddOrEditAccount
              usedFor="Account"
              setShowAddNew={setShowAddNew}
              queryParams={queryParams}
              toBeEdited={accountToBeEdited as Account}
              resetAddNew={resetAddNewModalAccount}
            />
          </Box>
        </Box>
      ) : (
        <ViewLayout
          closeViewModal={closeViewModal}
          accountToBeEdited={accountToBeEdited}
          setShowAddNew={setShowAddNew}
          setShowViewModal={setShowViewModal}
          handleTabChangeFromMainLayout={handleTabChange}
          type="account"
        />
      )}

      {showManageCustomFields && (
        <ManageCustomFields
          handleCloseManageCustomFields={handleCloseManageCustomFields}
          showManageCustomFields={showManageCustomFields}
          handleOpenManageCustomFields={handleOpenManageCustomFields}
        />
      )}
      {loading && <Loader />}
      <ConfirmationAlertPopUp
        openModal={openDeleteConfirmationModel}
        closeModalFunc={closeDeleteConfirmationModel}
        title={'Delete Confirmation!'}
        text={'Are you sure you want to delete it?'}
        confirmationButtonText={'Delete'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={handleDelete}
      />
      <ImportModal
        open={openImportModal}
        tab={'accounts'}
        handleOpen={handleOpenImportModal}
        fetchAllOpportunityData={fetchAllOpportunityAccounts}
      />
      {(isCustomFieldsLoading || isAccountsLoading) && <Loader />}
    </Box>
  );
}
