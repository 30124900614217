import React, { Component } from 'react'
import '../../sass/main.scss'
import EditingBack from '../../assets/images/backPage.png'
import Brandlogo from '../../assets/images/dashboardLogo.png'
import { Button } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import dotIcon from '../../assets/images/dots_icon.png'
import footerLogo from '../../assets/images/footer__logo.png'
import desktopBlack from '../../assets/images/desktop-black.png'
import desktopBlue from '../../assets/images/desktop-blue.png'
import tabletBlack from '../../assets/images/tablet-black.png'
import tabletBlue from '../../assets/images/tablet-blue.png'
import mobileblack from '../../assets/images/mobile-black.png'
import mobileblue from '../../assets/images/mobile-blue.png'
import facebookIcon from '../../assets/images/facebook.png'
import linkedinIcon from '../../assets/images/linkedin.png'
import twitterIcon from '../../assets/images/twitter.png'
import pinterestIcon from '../../assets/images/pinterest.png'
import Profile from '../../assets/images/undraw_profile.svg'
import Library2 from '../../assets/images/illuflip_lib.png'
import Library1 from '../../assets/images/illujoin-meeting.png'
import MagzineSlider1 from '../../assets/images/slider-image-1.png'
import likeBlank from '../../assets/images/heart-blank.png'
import likeFill from '../../assets/images/heart-fill.png'
import RegistrationEditing from '../RegisterEditing'

class EventHomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Questionvalue: 0,
      Tabvalue: 0,
      theme: '',
      isSidebarOpen: false,
    }
  }
  registerSidebarOpen = () => {
    this.setState((prevState) => ({ isSidebarOpen: !prevState.isSidebarOpen }))
  }
  handleChange = (event, newValue) => {
    this.setState({ Tabvalue: newValue })
  }

  handleChangeIndex = (index) => {
    this.setState({ Tabvalue: index })
  }

  render() {
    const { isSidebarOpen } = this.state
    function TabPanel(props) {
      const { children, Tabvalue, value, index, ...other } = props
     
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      )
    }

    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    }

    function a11yProps(index) {
      return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
      }
    }
    const { Tabvalue, theme } = this.state

    return (
      <div className="editingPageWrapper">
        <div className="editingDashboard">
          <div className="editingHeader">
            <div className="editingHeading">
              <h3>
                <a href="#">
                  <img src={EditingBack} alt="" />
                </a>
                Editing
              </h3>
            </div>
            <div class="renposive_selector mobile-None">
              <ul>
                <li>
                  <a href="#">
                    <img class="black_icon" src={desktopBlack} />
                    <img class="blue_icon" src={desktopBlue} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img class="black_icon" src={tabletBlack} />
                    <img class="blue_icon" src={tabletBlue} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img class="black_icon" src={mobileblack} />
                    <img class="blue_icon" src={mobileblue} />
                  </a>
                </li>
              </ul>
            </div>
            <div className="editingSubmitView">
              <a href="#" className="mobile-None backup">
                Backup
              </a>
              <Button className="outlineButton mobile-None">
                View As Guest
              </Button>
              <Button className="saveButton mobile-None">Save</Button>
              <a className="MobileSaveButton desktop-None">
                <i className="fa fa-check"></i>
              </a>
            </div>
          </div>
          <div className="editingDashboardContent">
            <div className="cloudEyeStoreSec">
              <span>
                <i className="fa fa-cloud"></i>
              </span>
              <span>
                <i className="fa fa-eye"></i>
              </span>
            </div>
            <div className="registorEditiorSection">
              <div class="banner_section formBanner">
                <div class="banner_content ">
                  <div class="banner_heading">
                    <h2>Put Your Heading Here</h2>
                  </div>
                  <div class="banner_form">
                    <h4>Sign In</h4>
                    <div className="formSection p-0">
                      <form>
                        <div class="field-group inputField fullWidthInput">
                          <label>Email</label>
                          <input
                            placeholder=""
                            type="text"
                            class="form-control"
                          />
                        </div>
                        <div class="field-group inputField fullWidthInput">
                          <label>Password</label>
                          <div class="paswrd_input">
                            <input
                              placeholder=""
                              type="password"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="field-group inputField text-center fullWidthInput">
                          <Button type="button" className="themeBlueButton">
                            Save
                          </Button>
                        </div>
                        <div class="field-group inputField text-center fullWidthInput mb-0">
                          <a class="privacy_setng" href="#">
                            Privacy Setting
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="brandProfileInfo">
                <div className="brandLogo">
                  <img src={Brandlogo} alt="" />
                </div>
                <div className="userPicture">
                  <img src={Profile} alt="" />
                </div>
              </div>
              <div className="intro_vedioMainSection">
                <div class="vedioOverlyContent">
                  <div class="vedioDescription">
                    <h2>Watch Intro Video</h2>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore veritatis et
                      quasi architecto beatae vitae dicta sunt explicabo. Nemo
                      enim ipsam voluptatem quia voluptas sit aspernatur aut
                      odit aut fugit,{' '}
                    </p>
                  </div>
                  <div className="vedioPortion">
                    <div class="vid_wrapper">
                      <video class="video" poster="img/video__poster.png">
                        <source
                          src="../assets/images/vid-work.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div class="playpause"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="meetingSchedulerSection">
                <div className="schedulerList">
                  <h3>Scheduler</h3>
                </div>
                <div className="schedulerList">
                  <h3>Meeting Agenda</h3>
                </div>
              </div>
              <div className="library_section libraryBeforeSec">
                <h2 className="illu_heading text-right">illuflip library</h2>
                <div className="illuflip_lib_sec">
                  <div className="illuflip_image text-center">
                    <img className="img-fluid" src={Library1} alt="" />
                  </div>
                </div>
              </div>
              <div className="library_section">
                <div className="illuflip_lib_sec">
                  <div className="illuflip_image text-center">
                    <h2 className="illu_heading text-right">
                      illuflip library
                    </h2>
                    <img className="img-fluid" src={Library2} alt="" />
                  </div>
                </div>
              </div>
              <div className="magzine_carousel">
                <div class="magzine_item">
                  <div className="magzineImage">
                    <img src={MagzineSlider1} alt="" className="img-fluid" />
                  </div>
                  <div className="magzineSliderContent">
                    <div className="McontentHeader">
                      <div className="magzinecategory">
                        <span>Fashion</span>
                      </div>
                      <div className="magzineResponse">
                        <p>
                          <img src={likeBlank} alt="" />
                          <img src={likeFill} alt="" />
                        </p>
                        <p>
                          <i class="fa fa-share"></i>
                        </p>
                      </div>
                    </div>
                    <div className="McontentText">
                      <p>V Magzine</p>
                      <h3>Sexi Magazine | Halloween Edition 2019</h3>
                    </div>
                  </div>
                </div>
                <div class="magzine_item">
                  <div className="magzineImage">
                    <img src={MagzineSlider1} alt="" className="img-fluid" />
                  </div>
                  <div className="magzineSliderContent">
                    <div className="McontentHeader">
                      <div className="magzinecategory">
                        <span>Fashion</span>
                      </div>
                      <div className="magzineResponse">
                        <p>
                          <img src={likeBlank} alt="" />
                          <img src={likeFill} alt="" />
                        </p>
                        <p>
                          <i class="fa fa-share"></i>
                        </p>
                      </div>
                    </div>
                    <div className="McontentText">
                      <p>V Magzine</p>
                      <h3>Sexi Magazine | Halloween Edition 2019</h3>
                    </div>
                  </div>
                </div>
                <div class="magzine_item">
                  <div className="magzineImage">
                    <img src={MagzineSlider1} alt="" className="img-fluid" />
                  </div>
                  <div className="magzineSliderContent">
                    <div className="McontentHeader">
                      <div className="magzinecategory">
                        <span>Fashion</span>
                      </div>
                      <div className="magzineResponse">
                        <p>
                          <img src={likeBlank} alt="" />
                          <img src={likeFill} alt="" />
                        </p>
                        <p>
                          <i class="fa fa-share"></i>
                        </p>
                      </div>
                    </div>
                    <div className="McontentText">
                      <p>V Magzine</p>
                      <h3>Sexi Magazine | Halloween Edition 2019</h3>
                    </div>
                  </div>
                </div>
                <div class="magzine_item">
                  <div className="magzineImage">
                    <img src={MagzineSlider1} alt="" className="img-fluid" />
                  </div>
                  <div className="magzineSliderContent">
                    <div className="McontentHeader">
                      <div className="magzinecategory">
                        <span>Fashion</span>
                      </div>
                      <div className="magzineResponse">
                        <p>
                          <img src={likeBlank} alt="" />
                          <img src={likeFill} alt="" />
                        </p>
                        <p>
                          <i class="fa fa-share"></i>
                        </p>
                      </div>
                    </div>
                    <div className="McontentText">
                      <p>V Magzine</p>
                      <h3>Sexi Magazine | Halloween Edition 2019</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`registerEditor ${isSidebarOpen ? 'Open' : ''}`}>
              <div className="asideTabs">
                <Tabs
                  value={Tabvalue}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Layout" {...a11yProps(0)} />
                  <Tab label="Theme" {...a11yProps(1)} />
                </Tabs>
                <SwipeableViews
                  index={Tabvalue}
                  onChangeIndex={this.handleChangeIndex}
                >
                  <TabPanel value={Tabvalue} index={0}>
                    <form>
                      <div class="branding_form_wrap">
                        <div class="form_group">
                          <label>Upload Logo</label>
                          <div class="upload-btn-wrapper">
                            <button class="btn">
                              <span>Browse</span> to choose a file
                            </button>
                            <input type="file" name="myfile" />
                          </div>
                        </div>
                        <div class="form_group">
                          <label>Background Color</label>
                          <div id="app" class="colo_pik_wrap">
                            <div class="color_piker">
                              <div class="input-group color-picker">
                                <input
                                  type="text"
                                  class="form-control"
                                  value="#5B33F1"
                                />
                                <span class="input-group-addon color-picker-container">
                                  <span
                                    class="current-color"
                                    style={{
                                      backgroundColor: 'rgb(91, 51, 241)',
                                    }}
                                  ></span>
                                </span>
                              </div>
                            </div>
                            <div class="dots_icon">
                              <img src={dotIcon} />
                            </div>
                          </div>
                        </div>
                        <div class="form_group">
                          <label>Upload Background Image</label>
                          <div class="upload-btn-wrapper">
                            <button class="btn">
                              <span>Browse</span> to choose a file
                            </button>
                            <input type="file" name="myfile" />
                          </div>
                        </div>
                        <div class="form_group inputField">
                          <label>Select Font</label>
                          <div class="selectButton">
                            <select class="form-control" id="">
                              <option>Rubik</option>
                              <option>Montserrat</option>
                              <option>Raleway</option>
                              <option>Arial</option>
                            </select>
                          </div>
                        </div>
                        <div class="form_group">
                          <label>Upload Video</label>
                          <div class="upload-btn-wrapper">
                            <button class="btn">
                              <span>Browse</span> to choose a file
                            </button>
                            <input type="file" name="myfile" />
                          </div>
                        </div>
                        <div class="form_group">
                          <label>Connect Social Links</label>
                          <div class="add_social_link">
                            <ul>
                              <li class="socail_input">
                                <span>
                                  <img src={facebookIcon} />
                                </span>
                                <input placeholder="" type="text" />
                              </li>
                              <li class="socail_input">
                                <span>
                                  <img src={linkedinIcon} />
                                </span>
                                <input placeholder="" type="text" />
                              </li>
                              <li class="socail_input">
                                <span>
                                  <img src={twitterIcon} />
                                </span>
                                <input placeholder="" type="text" />
                              </li>
                              <li class="socail_input">
                                <span>
                                  <img src={pinterestIcon} />
                                </span>
                                <input placeholder="" type="text" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </form>
                  </TabPanel>
                  <TabPanel value={Tabvalue} index={1}>
                    <p className="text-center">Coming Soon</p>
                  </TabPanel>
                </SwipeableViews>
              </div>
              <div
                className="mobileSlideSidebar openSidebarIcon desktop-None"
                onClick={this.registerSidebarOpen}
              >
                <i className="fa fa-chevron-left"></i>
              </div>
            </div>
          </div>
        </div>
        <footer class="footer_main">
          <div class="footer_logo">
            <img src={footerLogo} alt="" />
          </div>
        </footer>
      </div>
    )
  }
}

export default EventHomePage
