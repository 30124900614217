import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
    success: false,
    error: false,
    message: null,
    permissions: null,
}

const permissionRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    error: false,
    message: null,
  }
}

const permissionSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: true,
    message: action.message,
    permissions: action.data,
  }
}

const permissionError = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    success: false,
    error: true, 
    message: action.error 
  }
}

const HANDLERS = {
  [Constants.PERMISSION_SUCCESS]: permissionSuccess,
  [Constants.PERMISSION_ERROR]: permissionError,
  [Constants.PERMISSION_REQUEST]: permissionRequest,
}

export default createReducer(INITIAL_STATE, HANDLERS)
