import React, { Component } from 'react';
import { getProjectIdFromUrl } from '../../../helpers/common';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from '../../../Redux/Actions';
import Grid from '@mui/material/Grid'
import Box from '@material-ui/core/Box'
import Chart from './chart'


export class OverviewAttendeesChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dateArray: [],
            attendeesArray: []
        }
    }

    async componentDidMount() {
        this.getAttendeesChartData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.attendees.getAttendeesChartDataSuccess === true && prevProps.attendees.getAttendeesChartDataSuccess === false && this.props.attendees.message !== prevProps.attendees.message) {
            if (this.props.attendees.attendeesChartData.dateArray && this.props.attendees.attendeesChartData.attendeesArray) {
                this.setState({ 
                    dateArray: this.props.attendees.attendeesChartData.dateArray ,
                    attendeesArray: this.props.attendees.attendeesChartData.attendeesArray 
                })
            }
        }
    }

    getAttendeesChartData = () => {
        const projectId = getProjectIdFromUrl()
        let requestbody = {
            projectId: projectId,
            permission: 'View Project'
        }
        this.props.getAttendeesChartDataRequest(requestbody)
    }

    render() {
        const {dateArray, attendeesArray} = this.state

        return (
            <Grid item xs="12" className="dashboard-table-card">
                <Box
                    className=""
                    sx={{
                        backgroundColor: '#ffffff',
                        border: '1px solid #EDECF5',
                        borderRadius: '8px',
                    }}
                >
                    <Box
                        component={Grid}
                        container
                        spacing={1}
                        px={3}
                        py={2}
                        alignItems="center"
                        className="group-info-header"
                    >
                       
                        <Grid item>
                            <span className="paragraph bold">
                            {this.props.users.languageStateForRedux?.overviewTab?.attendeesChart}
                            </span>
                        </Grid>
                    </Box>
                    <Box
                        sx={{ borderTop: '1px solid #eeeeee' }}
                        py={1}
                        px={1}
                    >
                        <Chart
                          attendeesArray={attendeesArray}
                          dateArray={dateArray}
                        />
                    </Box>
                </Box>
            </Grid>

        )
    }
}

const mapStateToProps = state => ({
    attendees: state.Attendees,
    events: state.Events,
    users: state.Users,
});

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getAttendeesChartDataRequest: Actions.getAttendeesChartDataRequest
        },
        dispatch
    );
export default connect(mapStateToProps, mapActionsToProps)(OverviewAttendeesChart);

