import React from 'react'
import { CustomField } from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Checkbox, OutlinedInput } from '@material-ui/core';
import { CustomFieldsObj } from '../Opportunity/AddOrEditOpportunity';

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
        marginTop: 4,
      },
    },
  };

type Props = {
    cf: CustomField,
    customFieldsObj: CustomFieldsObj,
    handleCustomFieldsObj: (e: any, id: string, fieldType?: string, isMultiSelect?: boolean) => void
}

export default function DropdownForListDataTypeCF({cf, customFieldsObj, handleCustomFieldsObj}: Props) {
  return (
    (
        cf.fieldData.isMultiSelect == false ?
          <FormControl size="small" fullWidth>
            <Select
              type="text"
              className="form-control select small"
              placeholder="select"
              value={(customFieldsObj?.[cf._id as string] as string[])?.[0] || ''}
              name="fieldType"
              onChange={(e) =>
                handleCustomFieldsObj(e, cf._id as string, cf.fieldData.fieldType, cf.fieldData.isMultiSelect)
              }
              style={{ padding: '4px 0px' }}
              MenuProps={MenuProps}
              displayEmpty
              renderValue={(selected) => {
                return (selected as string) || 'Select';
              }}
            >
              <MenuItem value="" disabled hidden>
                Select
              </MenuItem>
              {Array.isArray(cf.fieldData.dropdownValues) &&
                cf.fieldData.dropdownValues.length > 0 &&
                cf.fieldData.dropdownValues.map(
                  (dropdownValue) => {
                    return (
                      <MenuItem
                        key={dropdownValue}
                        value={dropdownValue}
                      >
                        {dropdownValue}
                      </MenuItem>
                    );
                  },
                )}
            </Select>
          </FormControl>
          :
          <FormControl size="small" fullWidth>
            <Select
              type="text"
              className="form-control select small"
              // placeholder="select"
              value={
                typeof customFieldsObj?.[cf._id as string] === 'string' ?
                (customFieldsObj?.[cf._id as string] !== "" ? [customFieldsObj?.[cf._id as string]] : [] ):
                  (customFieldsObj?.[cf._id as string] || [])
              }
              name="fieldType"
              onChange={(e) =>
                handleCustomFieldsObj(e, cf._id as string)
              }
              style={{ padding: '4px 0px' }}
              MenuProps={MenuProps}
              displayEmpty
              multiple
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if ((selected as string[])?.length === 0) {
                  return 'Select';
                }
                return (selected as string[])?.join(', ') || '';
              }}
            >

              {Array.isArray(cf.fieldData.dropdownValues) &&
                cf.fieldData.dropdownValues.length > 0 &&
                cf.fieldData.dropdownValues.map(
                  (dropdownValue) => {
                    return (
                      <MenuItem
                        key={dropdownValue}
                        value={dropdownValue}
                      >
                        <Checkbox color='primary' checked={(customFieldsObj?.[cf._id as string] as string)?.includes(dropdownValue) || false} />
                        {dropdownValue}
                      </MenuItem>
                    );
                  },
                )}
            </Select>
          </FormControl>
      )
  )
}
