import React, { useEffect, useState ,useRef} from 'react';
import Grid from '@mui/material/Grid';
import Button from '@material-ui/core/Button';
import { Stack } from '@mui/material';
// @ts-ignore
import { Helmet } from 'react-helmet';
// @ts-ignore
import { NotificationContainer } from 'react-notifications';
import Box from '@mui/material/Box';
import { theme } from '../../../theme/theme';
// @ts-ignore
import { Modal } from 'react-responsive-modal';
import Loader from '../../../Components/Loader/loader';
import { pink } from '@material-ui/core/colors';
import BackArrow from '../../../assets/images/icons/backArrowWhite.svg';
import PageIcon from '../../../assets/images/icons/pageIconSmall.svg';
import Avatar from '@material-ui/core/Avatar';
import { Actions } from 'Redux/Actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomField,
  CustomFields_State,
} from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { useParams, useHistory } from 'react-router-dom';
import getUnLayerOpportunityConfig from './OpportunityConfig';
import { UserData } from '../../../helpers/common';
import axios from 'axios';
import { createNotification } from '../../../helpers';
import Settings from '../../../assets/images/icons/settings.svg';
import { OpportunityState } from 'Redux/Reducers/opportunity/opportunities.reducer';

const styles = {
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
};


declare global {
  interface Window {
    unlayer: {
      init(arg0: Object): unknown;
      addEventListener(arg0: string, arg1: (updates: any) => void): unknown;
      exportHtml(arg0: (data: any) => void): unknown;
      loadDesign: (json?: any) => void;
    };
  }
}


export default function OpportunityTransactionEmailEditor(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const hasRendered = useRef(false);
  const [html, setHtml] = useState<string>('');
  const [json, setJson] = useState<object>({});
  const [templateId, setTemplateId] = useState<string>('');
  const [testEmailModal, setTestEmailModal] = useState<boolean>(false);
  const [testEmail, setTestEmail] = useState<string>('');
  const [senderName, setSenderName] = useState<string>('');
  const [replyTo, setReplyTo] = useState<string>('');
  const [emailSubject, setEmailSubject] = useState<string>('');
  const [openSettingsPage, setOpenSettingsPage] = useState<boolean>(false);
  const [templateArray, setTemplateArray] = useState<any[]>([]);
  const [errors, setErrors] = useState<object>({});
  const { customFields } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;
  const {
    templates,
    isLoading,
  } = useSelector(
    (state: any) => state.OpportunityOpportunities,
  ) as OpportunityState;

  useEffect(() => {
    const { state } = props.location || {};
    const temp = state?.temp || {};

    if (temp) {
      if (temp._id) setTemplateId(temp._id);
    }

    fetchAllOpportunityCustomField();
    listTransactionEmail();
  }, []);

  
  useEffect(() => {
    if (templateArray.length > 0 && !hasRendered.current) {
      renderTemplate();
      hasRendered.current = true;
    }
  }, [templateArray]);

  useEffect(() => {
    const { state } = props.location || {};
    const temp = state?.temp || {};
    const filteredArray = templates.filter(item => item._id === temp._id);
    setTemplateArray(filteredArray);

    if (filteredArray.length > 0) {
      setSenderName(filteredArray[0].senderName);
      setReplyTo(filteredArray[0].replyTo);
      setEmailSubject(filteredArray[0].emailSubject);
      setHtml(filteredArray[0].html);
      setJson(filteredArray[0].json);
    }
  }, [props.location, templates]);


  const fetchAllOpportunityCustomField = () => {
    const data = {
      customFieldType: "opportunity",
      from:"viewOpportunity"
    };
    dispatch(Actions.fetchAllOpportunityCustomFieldRequest(data));
  };
  const renderTemplate = () => {
    window.scrollTo(0, 0);
    window.unlayer.init((getUnLayerOpportunityConfig(customFields)));
    window.unlayer.addEventListener('design:updated', function (updates) {
      window.unlayer.exportHtml(function (data) {
        const json = data.design;
        const html = data.html;
        setJson(json);
        setHtml(html);
      });
    });
    if (Object.keys(json)?.length > 0) {
      window.unlayer.loadDesign(json);
    } else {
      window.unlayer.loadDesign();
    }
  };



  const gobackFromEditor = () => {
    history.push({
      pathname: '/opportunity/opportunity',
      state: { transactionEmailsComp: true },
    });
  };

  const updateTransactionEmail = (from?: string) => {
    const data = {
      templateId: templateId,
      json: json,
      html: html,
      emailSubject: emailSubject,
      replyTo: replyTo,
      senderName: senderName,
      ...(from && { from: from }) // Conditionally include 'from' prop if 'from' is provided
    };

    dispatch(Actions.updateTransactionEmailRequest(data));

    setSenderName('');
    setReplyTo('');
    setEmailSubject('');
  };
  const listTransactionEmail = () => {
    dispatch(Actions.listTransactionEmailRequest());
  };

  const saveAndNext = () => {
    updateTransactionEmail();
    gobackFromEditor();
  };

  const sendTestEmail = () => {
    setTestEmailModal(true);
  };

  const handleCloseTestEmailModal = () => {
    setTestEmailModal(false);
  };

  const closeOpenSettingsPage = () => {
    setOpenSettingsPage(false);
    setErrors({})
  };
  const callUpdateTransactionEmail = () => {
    if (isValid()) {
      updateTransactionEmail("setting");
      setOpenSettingsPage(false);
      listTransactionEmail();
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'testEmail':
        setTestEmail(value);
        break;
      case 'senderName':
        setSenderName(value);
        break;
      case 'replyTo':
        setReplyTo(value);
        break;
      case 'emailSubject':
        setEmailSubject(value);
        break;
      default:
        break;
    }
  };

  const sendTestTransactionEmail = () => {
    const testTransMailData = {
      email: testEmail,
      templateId: templateId,
    };
    try {
      if (testEmail == '') {
        throw Error('Test email id is required');
      }
      const users: any = UserData(); // Using 'any' type here
      const URL = process.env.REACT_APP_API_URL;
      if (!users) {
        throw new Error('User data is null');
      }
      axios
        .post(`${URL}/opportunityManagement/sendTestEmailForOpportunity`, testTransMailData, {
          headers: {
            token: `bearer ${users.token}`,
            'Content-Type': 'application/json',
          },
        })
        .then((data) => {
          if (data.data.status == 200) {
            createNotification(
              'success',
              data.data ? data.data.message : 'Test mail send successfully.',
            );
          } else {
            createNotification('error', data.data.error);
          }
        })
        .catch((err) => {
          createNotification('error', 'Something went wrong');
        });
    } catch (err) {
      createNotification('error', 'Something went wrong');
    }
  };

  const openSettings = () => {
    setSenderName(templateArray[0].senderName);
    setReplyTo(templateArray[0].replyTo);
    setEmailSubject(templateArray[0].emailSubject);
    setOpenSettingsPage(true);
  };

  interface ErrorObject {
    senderName?: string;
    replyTo?: string;
    emailSubject?: string;
  }

  const isValid = () => {
    try {
      let formIsValid = true;
      let error: ErrorObject = {};

      if (
        !emailSubject ||
        emailSubject.trim().length === 0
      ) {
        formIsValid = false;
        error.emailSubject = 'Please enter the email subject';
      }

      setErrors(error);
      return formIsValid;
    } catch (error) {
      createNotification('error', 'Something went wrong');
      // Handle error
      return false; // You may want to return false here if an error occurs
    }
  };



  return (

    <div className="editingPageWrapper">
      <div className="unlayer-header">
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Button
                className="back-button"
                variant="text"
                onClick={gobackFromEditor}
              >
                <img alt="" src={BackArrow} />
              </Button>
              <Avatar style={styles.pink}>
                <img alt="" src={PageIcon} />
              </Avatar>
              <h3 className="page-title">
              </h3>
            </Grid>
          </Grid>
          <Grid item className="buttons">
            <Stack spacing={1} direction="row">
              <>
                <Box display={{ xs: 'block', lg: 'none' }}>
                  <Button onClick={openSettings} variant="text">
                    <img alt="" src={Settings} />
                  </Button>
                </Box>
                <Box display={{ xs: 'none', lg: 'block' }}>
                  <Button
                    onClick={openSettings}
                    startIcon={<img alt="" src={Settings} />}
                    variant="text"
                  >
                    settings
                  </Button>
                </Box>
              </>
              <Button variant="outlined"
                onClick={() => sendTestEmail()}
              >
                send test Mail
              </Button>
              <Button
                variant="contained"
                onClick={() => updateTransactionEmail()}
              >
                save
              </Button>
              <Button
                variant="contained"
                onClick={() => saveAndNext()}
              >
                save & Close
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </div>
      <Helmet>
        <title>IllumeetXP | Page Design </title>
      </Helmet>
      {
        <div className="editingDashboard">
          <div id="opportunityEditor" style={{ height: 'calc(100vh - 60px)' }}>
            {''}
          </div>
        </div>
      }
      <NotificationContainer />
      <Modal
        open={testEmailModal}
        onClose={handleCloseTestEmailModal}
        classNames={{
          overlay: '',
          modal: 'Invite-User-Modal',
        }}
        center
      >
        <Grid component="form" container className="modal-wrapper">
          <Grid item xs={12} pb={3}>
            <Box component="p" mb={1} className="subtitle">
              send Mail
            </Box>
            <p className="small-subtitle">
              Please provide a valid email address, and we will send a test email for your review.
            </p>
          </Grid>
          <Grid item xs={12} pb={4}>
            <Box component="div" className="inputField">
              <label>Email</label>
              <input
                type="text"
                className="form-control small"
                placeholder={''}
                value={testEmail}
                name='testEmail'
                onChange={handleChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="end">
              <Stack spacing={1} direction="row" >
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={sendTestTransactionEmail}
                >
                  send test Mail
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleCloseTestEmailModal}
                >
                  cancel
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal
              open={openSettingsPage}
              onClose={closeOpenSettingsPage}
              classNames={{
                overlay: '',
                modal: 'Invite-User-Modal',
              }}
              center
            >
              <Grid container className="modal-wrapper">
                <Grid item xs={12}  >
                  <>
                  <Box component="div" className="inputField" pb={2}>
                    <label>Email Subject<span className="required">*</span></label>
                    <input
                      type="text"
                      className="form-control small"
                      placeholder=""
                      name="emailSubject"
                      value={emailSubject}
                      onChange={handleChange}
                    />
                     <span className="error_mesage required">
                            {' '}
                            {errors?.emailSubject}{' '}
                          </span>
                  </Box>
                  <Box component="div" className="inputField" pb={2}>
                    <label>Sender Name </label>
                    <input
                      type="text"
                      className="form-control small"
                      placeholder=""
                      name="senderName"
                      value={senderName}
                      onChange={handleChange}
                    />
                  </Box>
                  <Box component="div" className="inputField" pb={4}>
                    <label>Reply To </label>
                    <input
                      type="text"
                      className="form-control small"
                      placeholder=""
                      name="replyTo"
                      value={replyTo}
                      onChange={handleChange}
                    />
                  </Box>
                  <Box display={'flex'} justifyContent={'end'}>
                    <Button
                      variant="contained"
                      color='primary'
                      disableElevation
                      onClick={() => callUpdateTransactionEmail()}
                    >
                      {' '}
                      save setting
                    </Button>
                  </Box>
                    </>
              </Grid>
              </Grid>
            </Modal>
      {isLoading && <Loader />}
    </div>
  );
}

