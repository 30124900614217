import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
    searchProjectNotificationRequest: ['data'],
    searchProjectNotificationSuccess: ['notifications', 'totalResults', 'message'],
    searchProjectNotificationError: ['error'],
    listProjectNotificationsRequest: ['data'],
    listProjectNotificationsSuccess: [ 'notifications', 'message', 'totalResults','totalResults1','filterByStatus'],
    listProjectNotificationsError: ['error'],
    projectNotificationRequest: ['data'],
    projectNotificationSuccess: ['notification', 'message'],
    projectNotificationError: ['error'],
    deleteProjectNotificationRequest: ['data'],
    deleteProjectNotificationSuccess: ['message'],
    deleteProjectNotificationError: ['error'],
    editProjectNotificationRequest: ['data'],
    editProjectNotificationSuccess: ['message', 'notification'],
    editProjectNotificationError: ['error'],
    updateProjectNotificationRequest: ['data', 'id'],
    updateProjectNotificationSuccess: ['message'],
    updateProjectNotificationError: ['error'],
    replicateProjectNotificationRequest: ['data'],
    replicateProjectNotificationSuccess: ['message'],
    replicateProjectNotificationError: ['error'],

})

export const Constants = Types
export default Creators