import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const PrivateUserRoute = ({ component: Component, ...rest }) => {
  useLocation()
  let userData = localStorage.getItem('user_details')
  JSON.parse(userData)

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('user_role') &&
        localStorage.getItem('user_role') !== 'undefined' ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
               pathname: '/',
             // pathname: 'https://www.illumeetxp.com/',
              // state: { from: props.location}
            }}
          />
        )
      }
    />
  )
}

export default PrivateUserRoute
