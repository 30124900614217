import React, { useState, useEffect, useRef } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { theme } from '../../../../theme/theme';
import '../../../../assets/styles/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'Redux/Actions';
import { useLocation } from 'react-router';
import {
  OpportunityState,
  OpportunityStates_State  
} from 'Redux/Reducers/opportunity/opportunityStates.reducer';

// MenuProps for Select component
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};

// Interface for error messages
type Errors = {
  name?: string;  
  stateType?: string;
  description?: string;
};

// Props for AddOrUpdateCustomField component
type AddOrUpdateCustomFieldProps = {
  handleCloseAddNewOpportunityStateModal: () => void;
  openAddNewOpportunityStateModal: boolean;
  isBeingEdited: boolean;
  opportunityStateToEdit: OpportunityState;
};

// Default Opportunity State value
const opportunityStateDefaultValue: OpportunityState = {
  name: '',
  stateType: '' ,
  description: ''
};

export default function AddOrUpdateOpportunityState({
  handleCloseAddNewOpportunityStateModal,
  openAddNewOpportunityStateModal,
  isBeingEdited,
  opportunityStateToEdit,
}: AddOrUpdateCustomFieldProps) {
  const firstRender1 = useRef(true);
  const firstRender2 = useRef(true);
  const dispatch = useDispatch();
  const opportunityStateRedux= useSelector(
    (state: any) => state.OpportunityStates,
  ) as OpportunityStates_State;

  const [opportunityState, setOpportunityState] = useState<OpportunityState>(opportunityStateDefaultValue as OpportunityState)
  const [errors, setErrors] = useState<Errors>({} as Errors);
  const [closeAfterSaving, setCloseAfterSaving] = useState<boolean>(false);

  useEffect(() => {
    if (isBeingEdited) {
      setOpportunityState(opportunityStateToEdit);
    }
  }, []);

  useEffect(() => {
    if (firstRender1.current) {
      firstRender1.current = false;
    } else {
      if (opportunityStateRedux.createOpportunityStateSuccess) {
        closeAfterSaving && handleCloseAddNewOpportunityStateModal();
        setOpportunityState(opportunityStateDefaultValue);
      }
    }
  }, [opportunityStateRedux.createOpportunityStateSuccess, closeAfterSaving]);

  useEffect(() => {
    if (firstRender2.current) {
      firstRender2.current = false;
    } else {
      if (opportunityStateRedux.updateOpportunityStateSuccess) {
        closeAfterSaving && handleCloseAddNewOpportunityStateModal();
        setOpportunityState(opportunityStateDefaultValue);
      }
    }
  }, [opportunityStateRedux.updateOpportunityStateSuccess, closeAfterSaving]);

  const handleChange = (e: SelectChangeEvent<OpportunityState>) => {
    let cf = { ...opportunityState };    

    setOpportunityState({
      ...cf,
      [e.target.name]: e.target.value,
    } as OpportunityState);
  };

  const createOrUpdateOpportunityState = () => {
    // Dispatch the appropriate action based on isBeingEdited flag 
    if (isBeingEdited) {
      dispatch(Actions.updateOpportunityStateRequest(opportunityState));
    } else {
      dispatch(Actions.createOpportunityStateRequest(opportunityState));
    }
  };

  /**
   * Validates the input data for creating or updating a custom field.
   * @function isValid
   * @returns {boolean} - Indicates whether the input data is valid or not.
   * @description This function checks if the custom field name is entered and if required data for list or checkbox fields is provided.
   */
  const isValid = () => {
    let isValid = true;
    let errorsObj: Errors = {} as Errors;
    if (!opportunityState.name) {
      isValid = false;
      errorsObj.name = 'name is required';
    }
    if (!opportunityState.stateType) {
      isValid = false;
      errorsObj.stateType = 'stateType is required';
    }
    setErrors(errorsObj);
    return isValid;
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={openAddNewOpportunityStateModal}
        onClose={handleCloseAddNewOpportunityStateModal}
        classNames={{
          overlay: '',
          modal: 'Invite-User-Modal',
        }}
        center
      >
        <Grid component="form" container className="modal-wrapper">
          <Grid item xs={12} pb={4}>
            <Box component="p" mb={1} className="subsecondary">
              {!isBeingEdited ? 'Add New State' : 'Edit State'}
            </Box>
            <p className="small-subtitle">
              {`You can add a new state by adding name and its type.`}
            </p>
          </Grid>
          <Grid item xs={12} pb={4}>
            <Box component="div" className="inputField" pb={4}>
              <label>Name</label>
              <input
                type="text"
                className="form-control small"
                placeholder="Please enter the name"
                name={'name'}
                value={opportunityState?.name}
                onChange={handleChange}
                maxLength={60}
              />
              <span className="error_mesage"> {errors?.name} </span>
            </Box>
            <Box component="div" className="inputField" pb={4}>
              <label>Type</label>
              <FormControl size="small" fullWidth>
                <Select
                  type="text"
                  className="form-control select small"
                  placeholder="select"
                  style={{ padding: '4px 0px' }}
                  MenuProps={MenuProps}
                  displayEmpty
                  // @ts-ignore
                  value={opportunityState?.stateType}
                  onChange={handleChange}
                  name="stateType"
                >
                  <MenuItem value="" disabled hidden>
                    Select
                  </MenuItem>
                  <MenuItem value="Initial">Initial</MenuItem>
                  <MenuItem value="Qualification">Qualification</MenuItem>
                  <MenuItem value="Contact">Contact</MenuItem>
                  <MenuItem value="Engagement">Engagement</MenuItem>
                  <MenuItem value="Proposal">Proposal</MenuItem>
                  <MenuItem value="Negotiation">Negotiation</MenuItem>
                  <MenuItem value="Approval">Approval</MenuItem>   
                  <MenuItem value="Closed-Won">Closed-Won</MenuItem>
                  <MenuItem value="Closed-Lost">Closed-Lost</MenuItem>
                  <MenuItem value="Closed-No Decision">Closed-No Decision</MenuItem>
                  <MenuItem value="Hold">Hold</MenuItem>
                  <MenuItem value="Review">Review</MenuItem>
                  <MenuItem value="Follow-Up">Follow-Up</MenuItem>
                  <MenuItem value="Implementation">Implementation</MenuItem>
                  <MenuItem value="Custom">Custom</MenuItem>                  
                </Select>
              </FormControl>
              <span className="error_mesage"> {errors?.stateType} </span>
            </Box>   
            <Box component="div" className="inputField" pb={4}>
              <label>Description</label>
              <textarea
                rows={4}
                className="form-control"
                value={opportunityState?.description}
                name="description"
                onChange={handleChange}
                placeholder="Please enter the description"
              ></textarea>
              <span className="error_mesage"> {errors?.description} </span>
            </Box>        
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="end">
              <Stack spacing={1} direction="row">
                {!isBeingEdited && (
                  <Button
                    variant="outlined"
                    color="primary"
                    disableElevation
                    onClick={() => {
                      if (isValid()) {
                        setCloseAfterSaving(false);
                        createOrUpdateOpportunityState();
                      }
                    }}
                  >
                    Save & Add New
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    if (isValid()) {
                      setCloseAfterSaving(true);
                      createOrUpdateOpportunityState();
                    }
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </ThemeProvider>
  );
}
