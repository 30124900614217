import { Constants } from 'Redux/Actions';
import { createReducer } from 'reduxsauce';

export interface GoogleWorkspceState {
  success: boolean;
  message: string;
  loading: boolean;
  actionType: string;
  latestAttendees: any[];
  orgUnits: OrgUnit[];
}

export interface OrgUnit {
  name: string;
  orgUnitId: string;
  parentOrgUnitId: string;
  createdAt: string;
  description: string;
  isSyncActive: boolean;
  children: OrgUnit[];
}
const INITIAL_STATE: GoogleWorkspceState = {
  orgUnits: [],
  loading: false,
  success: false,
  message: '',
  actionType: '',
  latestAttendees: [],
};

//#region Google ORG Units
const getGoogleOrgUnits = (
  state: GoogleWorkspceState = INITIAL_STATE,
): GoogleWorkspceState => {
  return {
    ...state,
    success: false,
    loading: true,
    message: '',
  };
};

const getGoogleOrgUnitsSuccess = (
  state: GoogleWorkspceState = INITIAL_STATE,
  action: any,
): GoogleWorkspceState => {
  return {
    ...state,
    success: true,
    loading: false,
    actionType: action.type,
    orgUnits: action.data.orgUnits,
    latestAttendees: action.data.latestAttendees,
  };
};

const getGoogleOrgUnitsFailure = (
  state: GoogleWorkspceState = INITIAL_STATE,
  action: any,
): GoogleWorkspceState => {
  return { ...state, success: false, loading: false, message: action.error };
};
//#endregion

//#region Sync ORG Units Manually
const getOrgUnitsManually = (
  state: GoogleWorkspceState = INITIAL_STATE,
  action: any,
): GoogleWorkspceState => {
  return {
    ...state,
    success: false,
    loading: true,
    actionType: action.type,
    message: '',
  };
};

const getOrgUnitsManuallySuccess = (
  state: GoogleWorkspceState = INITIAL_STATE,
  action: any,
): GoogleWorkspceState => {
  return {
    ...state,
    success: true,
    loading: false,
    actionType: action.type,
    orgUnits: action.orgUnits,
  };
};

const getOrgUnitsManuallyFailure = (
  state: GoogleWorkspceState = INITIAL_STATE,
  action: any,
): GoogleWorkspceState => {
  return { ...state, success: false, loading: false, message: action.error };
};
//#endregion

//#region Save Google ORG Units info
const saveGoogleWorksapceInfo = (
  state: GoogleWorkspceState = INITIAL_STATE,
): GoogleWorkspceState => {
  return {
    ...state,
    success: false,
    loading: true,
    message: '',
  };
};

const saveGoogleWorksapceInfoSuccess = (
  state: GoogleWorkspceState = INITIAL_STATE,
  action: any,
): GoogleWorkspceState => {
  return {
    ...state,
    success: true,
    loading: false,
    actionType: action.type,
  };
};

const saveGoogleWorksapceInfoFailure = (
  state: GoogleWorkspceState = INITIAL_STATE,
  action: any,
): GoogleWorkspceState => {
  return { ...state, success: false, loading: false, message: action.error };
};
//#endregion

const HANDLERS = {
  [Constants.GET_GOOGLE_ORG_UNITS]: getGoogleOrgUnits,
  [Constants.GET_GOOGLE_ORG_UNITS_SUCCESS]: getGoogleOrgUnitsSuccess,
  [Constants.GET_GOOGLE_ORG_UNITS_FAILURE]: getGoogleOrgUnitsFailure,

  [Constants.GET_ORG_UNITS_MANUALLY]: getOrgUnitsManually,
  [Constants.GET_ORG_UNITS_MANUALLY_SUCCESS]: getOrgUnitsManuallySuccess,
  [Constants.GET_ORG_UNITS_MANUALLY_FAILURE]: getOrgUnitsManuallyFailure,

  [Constants.SAVE_GOOGLE_WORKSPACE_INFO]: saveGoogleWorksapceInfo,
  [Constants.SAVE_GOOGLE_WORKSPACE_INFO_SUCCESS]:
    saveGoogleWorksapceInfoSuccess,
  [Constants.SAVE_GOOGLE_WORKSPACE_INFO_FAILURE]:
    saveGoogleWorksapceInfoFailure,
};
export default createReducer(INITIAL_STATE, HANDLERS);
