import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions' 

const INITIAL_STATE = {
  success: false,
  error: false,
  listSuccess: false,
  listError: false,
  memberSuccess: false,
  memberError: false,
  updateSuccess: false,
  updateError: false,
  searchSuccess: false,
  searchError: false,
  message: null,
  totalResults: 0,
}

const memberSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, success: true, message: action.message }
}

const memberError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const memberRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    memberSuccess: false,
    memberError: false,
    updateSuccess: false,
    updateError: false,
    message: null,
    totalResults: 0,
    searchSuccess: false,
    searchError: false,
  }
}

const listMemberSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    listSuccess: true,
    listProjectUsersSuccess: true,
    members: action.members,
    totalResults: action.totalResults,
    message: action.message,
    filterCnt: action.filterCnt
  }
}

const listMemberError = (state = INITIAL_STATE, action) => {
  return { ...state, listError: true, message: action.error }
}

const listMemberRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    memberSuccess: false,
    memberError: false,
    updateSuccess: false,
    updateError: false,
    message: null,
    totalResults: 0,
    searchSuccess: false,
    searchError: false,
  }
}
const listProjectUsersRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    listProjectUsersSuccess: false,
    members: [],
    totalResults: 0,
    filterCnt: 0,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    memberSuccess: false,
    memberError: false,
    updateSuccess: false,
    updateError: false,
    message: null,
    searchSuccess: false,
    searchError: false,
  }
}
const listProjectUsersSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    listProjectUsersSuccess: true,
    message: action.message,
    members: action.members,
    totalResults: action.totalResults ? action.totalResults: 0,
    filterCnt: action.filterCnt,
    success: false,
    error: false,
    listSuccess: true,
    listError: false,
    memberSuccess: false,
    memberError: false,
    updateSuccess: false,
    updateError: false, 
    searchSuccess: false,
    searchError: false,
  }
}

const updateMemberSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, updateSuccess: true, message: action.message }
}

const updateMemberError = (state = INITIAL_STATE, action) => {
  return { ...state, updateError: true, message: action.error }
}

const updateMemberRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    memberSuccess: false,
    memberError: false,
    updateSuccess: false,
    updateError: false,
    message: null,
    totalResults: 0,
    searchSuccess: false,
    searchError: false,
  }
}

const deleteMemberSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, success: true, message: action.message }
}

const deleteMemberError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const deleteMemberRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    memberSuccess: false,
    memberError: false,
    updateSuccess: false,
    updateError: false,
    message: null,
    totalResults: 0,
    searchSuccess: false,
    searchError: false,
  }
}

const searchMemberSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    listSuccess: true,
    members: action.members,
    totalResults: action.totalResults,
    message: action.message,
  }
}

const searchMemberError = (state = INITIAL_STATE, action) => {
  return { ...state, listError: true, message: action.error }
}

const searchMemberRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    memberSuccess: false,
    memberError: false,
    updateSuccess: false,
    updateError: false,
    message: null,
    totalResults: 0,
    searchSuccess: false,
    searchError: false,
  }
}

const invitationSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, updateSuccess: true, message: action.message }
}

const invitationError = (state = INITIAL_STATE, action) => {
  return { ...state, updateError: true, message: action.error }
}

const invitationRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    memberSuccess: false,
    memberError: false,
    updateSuccess: false,
    updateError: false,
    message: null,
    totalResults: 0,
    searchSuccess: false,
    searchError: false,
  }
}

const HANDLERS = {
  [Constants.MEMBER_SUCCESS]: memberSuccess,
  [Constants.MEMBER_ERROR]: memberError,
  [Constants.MEMBER_REQUEST]: memberRequest,
  [Constants.LIST_MEMBER_SUCCESS]: listMemberSuccess,
  [Constants.LIST_MEMBER_ERROR]: listMemberError,
  [Constants.LIST_MEMBER_REQUEST]: listMemberRequest,
  [Constants.LIST_PROJECT_USERS_REQUEST]: listProjectUsersRequest,
  [Constants.LIST_PROJECT_USERS_SUCCESS]: listProjectUsersSuccess,
  [Constants.UPDATE_MEMBER_SUCCESS]: updateMemberSuccess,
  [Constants.UPDATE_MEMBER_ERROR]: updateMemberError,
  [Constants.UPDATE_MEMBER_REQUEST]: updateMemberRequest,
  [Constants.DELETE_MEMBER_SUCCESS]: deleteMemberSuccess,
  [Constants.DELETE_MEMBER_ERROR]: deleteMemberError,
  [Constants.DELETE_MEMBER_REQUEST]: deleteMemberRequest,
  [Constants.SEARCH_MEMBER_SUCCESS]: searchMemberSuccess,
  [Constants.SEARCH_MEMBER_ERROR]: searchMemberError,
  [Constants.SEARCH_MEMBER_REQUEST]: searchMemberRequest,
  [Constants.INVITATION_SUCCESS]: invitationSuccess,
  [Constants.INVITATION_ERROR]: invitationError,
  [Constants.INVITATION_REQUEST]: invitationRequest,
}

export default createReducer(INITIAL_STATE, HANDLERS)
