import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
    exportAccessCodeRequest: ['data'],
    exportAccessCodeSuccess: ['message'],
    exportAccessCodeError: ['error'],
    listAccessCodeRequest: ['data'],
    listAccessCodeSuccess: ['message', 'accessCode', 'totalResults','filterByStatus','filterBySource'],
    listAccessCodeError: ['error'],
    addAccessCodeRequest: ['data'],
    addAccessCodeSuccess: ['message'],
    addAccessCodeError: ['error'],
    editAccessCodeRequest: ['data'],
    editAccessCodeSuccess: ['message'],
    editAccessCodeError: ['error'],
    deleteAccessCodeRequest: ['data'],
    deleteAccessCodeSuccess: ['message'],
    deleteAccessCodeError: ['error'],
    changeAccessCodeRequest: ['data'],
    changeAccessCodeSuccess: ['message'],
    changeAccessCodeError: ['error'],
    importAccessCodeRequest: ['data'],
    importAccessCodeSuccess: ['message',"uniqueAccesscodeList","importAccessCodeId"],
    importAccessCodeValidation: ['message', 'duplicateAccessCodeInCsv', 'invalidAccessCodeRejection', 'alreadyExistInDb',  'rejectedAccessCodes'],
    importAccessCodeError: ['error'],
    setNewAccessCodeExpiryTime: ['data']
})

export const Constants = Types
export default Creators