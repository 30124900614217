import React, { Component } from 'react';
import DashboardLayout from '../../Layouts/DashboardLayout';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from '../../Redux/Actions';
import TeamProject from '../Dashboard/TeamDashboard';
import { Link } from 'react-router-dom';
import ManagePage from './ManagePage/ManagePage';
import NotificationsDashboard from '../Dashboard/Notications/NotificationsDashboard';
import { Stack, Grid } from '@mui/material';
import Button from '@material-ui/core/Button';
import { ThemeProvider } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { theme } from '../../theme/theme';
import BackIcon from '../../assets/images/icons/back.svg';
import Loader from '../../Components/Loader/loader';
import urlImg from '../../assets/images/icons/urlWhite.svg';
import moment from 'moment';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ReactComponent as OverviewIcon } from '../../assets/images/icons/overview.svg';
import { ReactComponent as ManagePagesIcon } from '../../assets/images/icons/managePages.svg';
import { ReactComponent as CampaignsIcon } from '../../assets/images/icons/campaigns.svg';
import { ReactComponent as AgendaIcon } from '../../assets/images/icons/agenda.svg';
import { ReactComponent as NotificationsIcon } from '../../assets/images/icons/notifications.svg';
import { ReactComponent as AccessCodeIcon } from '../../assets/images/icons/accessCode.svg';
import { ReactComponent as TeamIcon } from '../../assets/images/icons/team.svg';
import { ReactComponent as AttendeesIcon } from '../../assets/images/icons/attendees.svg';
import { ReactComponent as IntegrationIcon } from '../../assets/images/icons/integration.svg';
import { ReactComponent as MeetingsIcon } from '../../assets/images/icons/meetings.svg';
import { ReactComponent as GamificationIcon } from '../../assets/images/icons/gamification.svg';
import EventDomainIcon from '../../assets/images/icons/link-purple.svg';
import DateIcon from '../../assets/images/icons/date.svg';
import LocationIcon from '../../assets/images/icons/location.svg';
import TimeZoneIcon from '../../assets/images/icons/TimeZone.svg';
import AttendeesPulpleIcon from '../../assets/images/icons/Attendees-purple.svg';
import EmailSenderIcon from '../../assets/images/icons/EmailSender.svg';
import ReplytoIcon from '../../assets/images/icons/Replyto.svg';
import TagsIcon from '../../assets/images/icons/Tags.svg';
import TimerIcon from '../../assets/images/icons/timer.svg';
import groupDetailIcon from '../../assets/images/icons/early-access-group.svg';
import lockIcon from '../../assets/images/icons/lock.svg';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Attendees from './Attendees/Attendees';
import { projectIcon, projectIconColor, projectType } from './congif';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Agenda from '../ProjectDashboard/Agenda/Agenda';
import AccessCode from '../ProjectDashboard/AccessCodes/AccessCodes';
import Campaigns from './Campaigns/Campaigns';
import axios from 'axios';
import { createNotification } from '../../helpers';
import { Modal } from 'react-responsive-modal';
import { NotificationContainer } from 'react-notifications';
import { getEventURL } from '../../helpers/common';
import { getProjectIdFromUrl } from '../../helpers/common';
import 'moment-timezone';
import Gamification from './Gamification/Gamification';
import OfficialTimeClock from '../Report/officialTimeClock';
import OverviewSummaryCards from './OverviewReport/overviewSummaryCards';
import OverviewCampaignChart from './OverviewReport/overviewCampaignChart';
import RecentAttendees from './OverviewReport/recentAttendees';
import OverviewAttendeesChart from './OverviewReport/overviewAttendeesChart';
import tease from 'tease';
import Integration from './Integration/Integration';
import Tooltip from '@material-ui/core/Tooltip';
import ConfirmationAlertPopUp from '../../Common/ConfirmationAlertPopUp';
import CustomTooltip from '../../Components/CustomHTMLTooltip';
import Meetings from './Meetings/Meetings';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { languageCode } from '../../helpers/common';
import { stubString } from 'lodash';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 8,
    right: 8,
    padding: '0 4px',
    borderRadius: 4,
    width: 24,
    height: 22,
  },
}));

const styles = {
  pink: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: 8,
  },
  customTabList: {
    minHeight: 42,
  },
  customTab: {
    minHeight: 42,
    padding: '12px 16px',
    color: '#000056',
  },
  disabledTab: {
    backgroundColor: '#e0e0e0',
  },
};

const URL = process.env.REACT_APP_API_URL;

class ProjectDashboard extends Component {
  constructor(props) {
    super(props);
    this.navigationValue = {
      overview: '1',
      'manage-pages': '2',
      campaigns: '3',
      agenda: '4',
      notifications: '5',
      'access-code': '6',
      team: '7',
      attendees: '8',
      integration: '9',
      meetings: '10',
      'gamification/challenges': '11',
      'gamification/leaderboard': '11',
      'gamification/configuration': '11',
    };
    this.permData = {};
    this.unloader = true;
    this.tabs = [
      'overview',
      'manage-pages',
      'campaigns',
      'agenda',
      'notifications',
      'access-code',
      'team',
      'attendees',
      'integration',
      'meetings',
      'gamification',
    ];
    const userData = localStorage.getItem('user_details');
    const user = JSON.parse(userData);
    let seg = window.location.href.split('/');
    let mid = seg[seg.length - 2];
    if (window.location.href.includes('gamification')) {
      mid = seg[seg.length - 3];
    }
    this.state = {
      hash: '',
      userData: {},
      errors: {},
      activeTab: '',
      loading: false,
      activeTabIndex: this.tabs.indexOf(mid),
      perms: user.permissions,
      eventName: '',
      allowed: {},
      open: false,
      value: (this.tabs.indexOf(mid) + 1).toString(),
      actionPerformed: '',
      isPublishedState: false,
      isUnPublishedState: false,
      editCampaign: false,
      pageData: '',
      prevPageView: '',
      servicePlanData: {},
      showNotAllowedPopUp: false,
      projectTypeId:'',
      lngCode: 'en',
      eventId: ''
    };
  }

  /**
   * @function componentDidMount
   * @description - this is the default function and called at first
   *
   */
  componentDidMount() {
    const eventId = getProjectIdFromUrl();
    this.getProjectRequest();
    this.handleLoading();

    const userData = JSON.parse(localStorage.getItem('user_details'));
    this.setState({
      profileName: `${userData.name}`,
      eventId
    });
    const data = this.props.history.location;
    this.setState({ pageData: data.state, prevPageView: data.state2 });
    this.findIfAllowedFromSuperadminRequest();


    this.unlisten = this.props.history.listen((location, action) => {
      // navigateToValue detection when URL changes
      let currentModule = location.pathname.split('/')[2];
      for (const module in this.navigationValue) {
        if (module === currentModule) {
          this.setState({
            navigateToValue: this.navigationValue[module],
          });
        }
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  findIfAllowedFromSuperadminRequest = () => {
    let userDetails = JSON.parse(localStorage.getItem('user_details'));
    let servicePlanId = userDetails.servicePlanId;
    const projectId = getProjectIdFromUrl();
    let reqData = {
      servicePlanId,
      projectId,
    };
    this.props.findIfAllowedFromSuperadminRequest(reqData);
  };

  getPreparePermission(state) {
    let perms = {};
    if (Array.isArray(state)) {
      state.forEach((data) => {
        perms[data.permission_name] = true;
      });
    }
    return perms;
  }

  /**
   * @function setAutoLogin
   * @description -function is called to get user details and login by hash passed from wix
   *
   */
  async getProjectRequest() {
    const arr = window.location.pathname.split('/');
    const eventId = arr[arr.length - 1];
    const event = this.props.getProjectRequest(eventId);
    this.handleLoading();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props?.history?.location?.state?.hasLeaderboardOpenedFromAttendees
    ) {
      this.navigate('gamification/leaderboard', 'addGamificationXP', '11');
    }
    const seg = window.location.href.split('/');
    let mid = seg[seg.length - 2];
    if (window.location.href.includes('gamification')) {
      mid = seg[seg.length - 3];
    }
    this.state.activeTabIndex = this.tabs.indexOf(mid);
    this.state.value = (this.tabs.indexOf(mid) + 1).toString();

    if (
      this.props.events.updateLngSuccess &&
      !prevProps.events.updateLngSuccess &&
      this.props.events.message !== prevProps.events.message
    ) {
      this.setState({
        loading: false,
      });
      //this.props.handleLngCodeRedux(languageCode(this.props.events.lngCode));
    }


    if (
      this.props.project.getProjectSuccess &&
      !prevProps.project.getProjectSuccess &&
      this.props.project.message !== prevProps.project.message &&
      this.unloader === true
    ) {
      this.setState({
        loading: false,
        lngCode: this.props.users.newLangState
      });
      let userDetails = JSON.parse(localStorage.getItem('user_details'));
      this.props.newLangStateForRedux(userDetails.language);
     
      this.unloader = false;
      this.permData = this.getPreparePermission(
        this.props.project.project.permissions,
      );
      let isExists = false;
      let editProject = false;
      let allowed = {};
      if (this.permData) {
        Object.keys(this.permData).forEach((data) => {
          if (['create project'].indexOf(data.toLowerCase()) !== -1) {
            isExists = true;
            allowed['create project'] = true;
          }
          if (['edit project'].indexOf(data.toLowerCase()) !== -1) {
            editProject = true;
            allowed['edit project'] = true;
          }
          if (['view project'].indexOf(data.toLowerCase()) !== -1) {
            allowed['view project'] = true;
          }
          if (['view pages'].indexOf(data.toLocaleLowerCase()) !== -1) {
            allowed['view pages'] = true;
          }
          if (['view campaigns'].indexOf(data.toLocaleLowerCase()) !== -1) {
            allowed['view campaigns'] = true;
          }
          if (['view sessions'].indexOf(data.toLocaleLowerCase()) !== -1) {
            allowed['view sessions'] = true;
          }
          if (['view notifications'].indexOf(data.toLocaleLowerCase()) !== -1) {
            allowed['view notifications'] = true;
          }
          if (
            ['manage access codes'].indexOf(data.toLocaleLowerCase()) !== -1
          ) {
            allowed['access code'] = true;
          }
          if (['view members'].indexOf(data.toLocaleLowerCase()) !== -1) {
            allowed['view members'] = true;
          }
          if (['view attendees'].indexOf(data.toLocaleLowerCase()) !== -1) {
            allowed['view attendees'] = true;
          }
          if (['view accesscodes'].indexOf(data.toLocaleLowerCase()) !== -1) {
            allowed['view accesscodes'] = true;
          }
          if (['webhooks'].indexOf(data.toLocaleLowerCase()) !== -1) {
            allowed['webhooks'] = true;
          }
          if (['app integrations'].indexOf(data.toLocaleLowerCase()) !== -1) {
            allowed['app integrations'] = true;
          }
          if (['view gamification'].indexOf(data.toLocaleLowerCase()) !== -1) {
            allowed['view gamification'] = true;
          }
          if (
            ['publish/unpublish project'].indexOf(data.toLocaleLowerCase()) !==
            -1
          ) {
            allowed['publish/unpublish project'] = true;
          }

          if (['viewAllMeetings'].indexOf(data) !== -1) {
            allowed['view meeting'] = true;
          } 

          if (['viewMyTeamMeetings'].indexOf(data) !== -1) {
            allowed['view meeting'] = true;
          }

          if (['viewMyOwnMeetings'].indexOf(data) !== -1) {
            allowed['view meeting'] = true;
          }
        });

        this.state.redirect = isExists;
        this.state.editProject = editProject;
        this.state.allowed = allowed;
        this.setState({
          timezone: this.props.project.project.timezone,
        });
      }
      
      this.setState({
        isPublishedState: this.props.project.project.isPublished,
        isUnPublishedState: !this.props.project.project.isPublished,
      });
      
    }

    if (
      this.props?.servicePlan?.findIfAllowedFromSuperadminSuccess &&
      !prevProps?.servicePlan?.findIfAllowedFromSuperadminSuccess
    ) {
      this.setState({
        servicePlanData: this.props?.servicePlan?.servicePlanDetails,
      });
      this.checkIfModuleIsAllowedInServicePlan();
    }
  }

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
      page_no: 1,
    });
  };

  checkIfModuleIsAllowedInServicePlan = () => {
    const currentLocation = window.location.href;
    const obj = {
      'project-dashboard/manage-pages': 'addPagesXP',
      'project-dashboard/campaigns': 'addCampaignsXP',
      'project-dashboard/agenda': 'addAgendaXP',
      'project-dashboard/notifications': 'addNotificationsXP',
      'project-dashboard/team': 'addTeamXP',
      'project-dashboard/attendees': 'addAttendeesXP',
      'project-dashboard/integration': 'addIntegrationXP',
      'project-dashboard/access-code': 'addAccessCodeXP',
      'project-dashboard/gamification': 'addGamificationXP',
      'project-dashboard/meetings': 'addMeetingsXP',
    };

    for (const module in obj) {
      if (currentLocation.includes(module)) {

        if (!this.props?.servicePlan?.servicePlanDetails[obj[module]]) {

          window.location.href =
            'https://www.illumeetxp.com/upgrade?location=campaigns';
        } else {
          this.loadPage();
        }
      }
    }
    if (currentLocation.includes('overview')) {
      this.setState(
        {
          navigateToValue: '1',
        },
        () => {
          this.props.history.push(
            `/project-dashboard/overview/${this.props.match.params.eventId}`,
          );
        },
      );
    } else if (currentLocation.includes('meetings')) {
      this.setState(
        {
          navigateToValue: '10',
        },
        () => {
          this.props.history.push(
            `/project-dashboard/meetings/${this.props.match.params.eventId}`,
          );
        },
      );
    }
  };

  loadPage = () => {
    let path = this.props.history.location.pathname;
    let currentPath = path.split('/')[2];

    if (currentPath === 'gamification') {
      let gamificationTab = path.split('/')[3];
      currentPath = `gamification/${gamificationTab}`;
    }
    this.setState({
      navigateToValue: this.navigationValue[currentPath],
      value: this.navigationValue[currentPath],
      activeTabIndex: this.navigationValue[currentPath],
    });
  };

  unPublishProject = async () => {
    this.setState({
      loading: true,
    });
    const body = {
      eventId: this.props.project.project ? this.props.project.project._id : '',
      isPublished: false,
    };
    if (body.eventId != '') {
      const URL = process.env.REACT_APP_API_URL;
      const users = await this.UserData();
      const response = await axios
        .post(`${URL}/events/publishUnpublish`, body, {
          headers: {
            token: `bearer ${users.token}`,
            'Content-Type': 'application/json',
            'check-grant': `${body.eventId}-Publish/Unpublish Project`,
          },
        })
        .then((data) => {
          this.closeConfirmModal();
          if (data.data.status == 200) {
            if (data.data.event.isPublished == true) {
              this.setState({
                isPublishedState: true,
                isUnPublishedState: false,
              });
            } else {
              this.setState({
                isPublishedState: false,
                isUnPublishedState: true,
              });
            }
            createNotification(
              'success',
              data.data
                ? this.props.users.languageStateForRedux?.messages?.unPublishProject
                : this.props.users.languageStateForRedux?.messages?.something
            );
          } else {
            createNotification('error', data.data.error);
          }
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {

        });
    } else {
      createNotification('error', 'Something went wrong!');
    }
  };

  openConfirmModal1 = async () => {
    this.setState({
      open: true,
      actionPerformed: 'un-publish',
    });
  };
  openConfirmModal = async () => {
    this.setState({
      open: true,
      actionPerformed: 'publish',
    });
  };

  publishProject = async () => {
    this.setState({
      loading: true,
    });
    const body = {
      eventId: this.props.project.project ? this.props.project.project._id : '',
      isPublished: true,
    };
    if (body.eventId != '') {
      const URL = process.env.REACT_APP_API_URL;
      const users = await this.UserData();
      const response = await axios
        .post(`${URL}/events/publishUnpublish`, body, {
          headers: {
            token: `bearer ${users.token}`,
            'Content-Type': 'application/json',
            'check-grant': `${body.eventId}-Publish/Unpublish Project`,
          },
        })
        .then((data) => {
          this.closeConfirmModal();
          if (data.data.status == 200) {
            if (data.data.event.isPublished == true) {
              this.setState({
                isPublishedState: true,
                isUnPublishedState: false,
              });
            } else {
              this.setState({
                isPublishedState: false,
                isUnPublishedState: true,
              });
            }
            createNotification(
              'success',
              data.data ? this.props.users.languageStateForRedux?.messages?.publishProject: this.props.users.languageStateForRedux?.messages?.something);
          } else {
            createNotification('error', data.data.error);
          }
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          return err;
        });
    } else {
      createNotification('error', 'Something went wrong!');
    }
  };

  closeConfirmModal = async () => {
    this.setState({
      open: false,
    });
  };

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  UserData = async () => {
    const currentPath = window.location.pathname;
    if (currentPath.includes('member')) {
      return JSON.parse(localStorage.getItem('member_details'));
    } else {
      return JSON.parse(localStorage.getItem('user_details'));
    }
  };

  async getProjectTemplate() {
    const projectId = await getProjectIdFromUrl();
    const users = await this.UserData();
    try {
      const response = await axios.get(
        `${URL}/getProjectTemplate?projectId=${projectId}`,
        {
          headers: {
            token: `bearer ${users.token}`,
          },
        },
      );
      if (response.data && response.data.status == 200) {
        let loginPage = response.data.templates.filter(
          (template) => template.isLoginPage,
        );
        this.setState({
          template: response.data.templates,
          events: response.data.events,
          loginPage,
        });
      } else {
        throw Error(response.data.error);
      }
    } catch (err) {
      createNotification('error', err.message);
      setTimeout(() => {
        window.location.href = `/my-events`;
      }, 1500);
    }
  }

  hideTransactionalReport = () => {
    this.props.viewTransactionalReport(false);
  };

  navigate = (module, addModuleXp, value) => {
    let { servicePlanData } = this.state;
    if (module === 'overview') {
      this.setState(
        {
          navigateToValue: '1',
        },
        () => {
          this.props.handleNavigationFromEditorToCreateMeeting(false)
          this.props.history.push(
            `/project-dashboard/${module}/${this.props.match.params.eventId}`,
          );
        },
      );
    }
    else {
      if (servicePlanData.plan_name && servicePlanData.plan_name !== "") {
        if (servicePlanData[addModuleXp] === true) {
          this.setState(
            {
              navigateToValue: value,
              activeTabIndex: value,
            },
            () => {
              this.props.handleNavigationFromEditorToCreateMeeting(false)
              this.props.history.push({
                pathname: `/project-dashboard/${module}/${this.props.match.params.eventId}`,
              });
            },
          );
        } else {
          let path = this.props.history.location.pathname;
          let currentPath = path.split('/')[2];
          this.setState({
            navigateToValue: this.navigationValue[currentPath],
            value: this.navigationValue[currentPath],
            activeTabIndex: this.navigationValue[currentPath],
          });
        }
      } else {
        this.setState(
          {
            navigateToValue: value,
            activeTabIndex: value,
          },
          () => {
            this.props.handleNavigationFromEditorToCreateMeeting(false)
            this.props.history.push({
              pathname: `/project-dashboard/${module}/${this.props.match.params.eventId}`,
            });
          },
        );
      }
    }
  };

  closeNotAllowedModal = () => {
    this.setState({
      showNotAllowedPopUp: false,
    });
  };

  getLabelForTabs = (label, module) => {
    return (
      <Stack direction="row" spacing={1} sx={{ mr: 0 }}>
        <Box>{label}</Box>
        {this.state.servicePlanData[module] === false && (
          <CustomTooltip
            interactive={true}
            title={
              <Box className="upgrade-tooltip">
                <h5>Upgrade your Plan</h5>
                <p>
                  This feature is not a part of you current plan. <br />
                  <a
                    href="https://www.illumeetxp.com/upgrade?location=campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here
                  </a>{' '}
                  for more info
                </p>
              </Box>
            }
            placement="bottom"
            arrow={true}
          >
            <img className="lock" alt="disabled" src={lockIcon} />
          </CustomTooltip>
        )}
      </Stack>
    );
  };


  render() {
    let {
      redirect,
      editProject,
      activeTabIndex,
      loading,
      allowed,
      pageData,
      prevPageView,
      navigateToValue,
    } = this.state;
    const { project } = this.props.project;
    return (
      <DashboardLayout title="Settings" pageTitle="Settings">
        <div className="dashboard-containor">
          <ThemeProvider theme={theme}>
            <div className="project-header">
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  <Grid
                    container
                    spacing={{ xs: 1, sm: 0 }}
                    alignItems={{ lg: 'start', xl: 'center' }}
                  >
                    <Grid item>
                      <Box ml={{ sm: -8 }} mt={{ xs: 0.25, sm: 1.5 }}>
                        <Link
                          to={{
                            pathname: `/my-events`,
                            query: { pageData, prevPageView },
                          }}
                        >
                          <Button className="back-button" variant="text">
                            <img alt="" src={BackIcon} />
                          </Button>
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Stack direction={'row'} alignItems="center" spacing={1}>
                        <Avatar
                          style={styles.pink}
                          className={
                            'project-icon ' + this.props.project &&
                            this.props.project.project &&
                            projectIconColor(
                              this.props.project.project.projectTypeId,
                            )
                          }
                        >
                          <img
                            alt=""
                            src={
                              this.props.project &&
                              this.props.project.project &&
                              projectIcon(
                                this.props.project.project.projectTypeId,
                              )
                            }
                          />
                        </Avatar>
                        <Box
                          display={{ sm: 'none', xs: 'block' }}
                          className="project-details"
                        >
                          <Box component={'h3'} mb={0} className="project-name">
                            {this.props.project &&
                              this.props.project.project &&
                              this.props.project.project.eventName}
                          </Box>
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs className="project-details">
                      <Box display={{ xs: 'none', sm: 'block' }}>
                        <h3 className="project-name">
                          {this.props.project &&
                            this.props.project.project &&
                            this.props.project.project.eventName}
                        </h3>
                      </Box>
                      <Grid container spacing={{ xs: 0, sm: 1, lg: 2 }}>
                        <Grid item className="project-type">
                          {this.props.project &&
                            this.props.project.project &&
                            projectType(
                              this.props.project.project.projectTypeId,
                              this.props.users.languageStateForRedux?.projectTypes
                            )}
                        </Grid>
                        <Grid
                          item
                          className="page-url"
                          display={'flex'}
                          alignItems={'center'}
                        >
                          <FiberManualRecordIcon
                            style={{ fontSize: '8px', marginRight: '12px' }}
                          />
                          <Box
                            className="long-url"
                            maxWidth={{
                              xs: '300px',
                              sm: '350px',
                              md: '140px',
                              lg: '350px',
                              xl: '500px',
                            }}
                          >
                            <Tooltip
                              title={
                                project &&
                                project.eventName &&
                                getEventURL(
                                  project.eventDomain,
                                  project.vanityDomain?.domainName,
                                  project.vanityDomain?.isActive,
                                  project.vanityDomain?.cloudFrontDistribution
                                    ?.enabled,
                                )
                              }
                              placement="bottom-start"
                              arrow
                            >
                              <a
                                target="_blank"
                                href={
                                  project &&
                                  project.eventDomain &&
                                  getEventURL(
                                    project.eventDomain,
                                    project.vanityDomain?.domainName,
                                    project.vanityDomain?.isActive,
                                    project.vanityDomain?.cloudFrontDistribution
                                      ?.enabled,
                                  )
                                }
                                rel="noreferrer"
                              >
                                {project &&
                                  project.eventDomain &&
                                  getEventURL(
                                    project.eventDomain,
                                    project.vanityDomain?.domainName,
                                    project.vanityDomain?.isActive,
                                    project.vanityDomain?.cloudFrontDistribution
                                      ?.enabled,
                                  )}
                              </a>
                            </Tooltip>
                          </Box>
                        </Grid>
                        <Grid item className="project-status">
                          <FiberManualRecordIcon
                            mr={2}
                            style={{ fontSize: '8px', marginRight: '12px' }}
                          />

                          <span>
                            {this.props.users.languageStateForRedux?.common?.time }
                            {this.props.project && this.props.project?.project && (
                              <>
                                <OfficialTimeClock
                                  timezone={this.props.project.project.timezone}
                                />
                                {` (${moment()
                                  .tz(this.props.project.project.timezone)
                                  .format('z')})`}
                              </>
                            )}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {allowed['publish/unpublish project'] && (
                  <Grid item className="buttons">
                    <Stack spacing={2} direction="row" alignItems={'baseline'} className="field-group inputField ">
                      <ButtonGroup style={{ minWidth: 'max-content' }} variant="contained" disableElevation>
                        <Button
                          color="secondary"
                          onClick={this.openConfirmModal1}
                          disabled={
                            this.state.isUnPublishedState ? 'disabled' : ''
                          }
                        >
                          {this.props.users.languageStateForRedux?.common?.unPublish}
                        </Button>
                        <Button
                          color="primary"
                          onClick={this.openConfirmModal}
                          disabled={
                            this.state.isPublishedState ? 'disabled' : ''
                          }
                        >
                          {this.props.users.languageStateForRedux?.common?.publish}
                        </Button>
                      </ButtonGroup>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </div>
            <div className="project-navigation">
              <TabContext value={navigateToValue} defaultIndex={activeTabIndex}>
                <Box
                  pl={{ md: 2 }}
                  sx={{ borderBottom: 1, borderColor: 'divider' }}
                >
                  <TabList
                    style={styles.customTabList}
                    value={activeTabIndex}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    onChange={this.handleChange}
                    aria-label="lab API tabs example"
                  >
                    {allowed['view project'] && (
                      <Tab
                        onClick={() => this.navigate('overview', null, '1')}
                        style={styles.customTab}
                        icon={<OverviewIcon />}
                        iconPosition="start"
                        label={this.props.users.languageStateForRedux?.tabs?.overview}
                        value={'1'}
                      ></Tab>
                    )}
                    {allowed['view pages'] && (
                      <Tab
                        className={
                          this.state.servicePlanData['addPagesXP'] === false &&
                          'disabled-tab'
                        }
                        onClick={() =>
                          this.navigate('manage-pages', 'addPagesXP', '2')
                        }
                        style={{ ...styles.customTab }}
                        icon={<ManagePagesIcon />}
                        iconPosition="start"
                        value={'2'}
                        label={this.getLabelForTabs(this.props.users.languageStateForRedux?.tabs?.managePages,
                          'addPagesXP',
                        )}
                      >
                        {' '}
                      </Tab>
                    )}
                    {allowed['view campaigns'] && (
                      <Tab
                        className={
                          this.state.servicePlanData['addCampaignsXP'] ===
                          false && 'disabled-tab'
                        }
                        onClick={() =>
                          this.navigate('campaigns', 'addCampaignsXP', '3')
                        }
                        style={{ ...styles.customTab }}
                        icon={<CampaignsIcon />}
                        iconPosition="start"
                        label={this.getLabelForTabs(this.props.users.languageStateForRedux?.tabs?.campaigns,
                          'addCampaignsXP',
                        )}
                        value={'3'}
                      >
                        {' '}
                      </Tab>
                    )}
                    {allowed['view sessions'] && (
                      <Tab
                        className={
                          this.state.servicePlanData['addAgendaXP'] === false &&
                          'disabled-tab'
                        }
                        onClick={() =>
                          this.navigate('agenda', 'addAgendaXP', '4')
                        }
                        style={{ ...styles.customTab }}
                        icon={<AgendaIcon />}
                        iconPosition="start"
                        label={this.getLabelForTabs(this.props.users.languageStateForRedux?.tabs?.agenda, 'addAgendaXP')}
                        value={'4'}
                      >
                        {' '}
                      </Tab>
                    )}
                    {allowed['view notifications'] && (
                      <Tab
                        className={
                          this.state.servicePlanData['addNotificationsXP'] ===
                          false && 'disabled-tab'
                        }
                        onClick={() =>
                          this.navigate(
                            'notifications',
                            'addNotificationsXP',
                            '5',
                          )
                        }
                        style={{ ...styles.customTab }}
                        icon={<NotificationsIcon />}
                        iconPosition="start"
                        label={this.getLabelForTabs(this.props.users.languageStateForRedux?.tabs?.notifications,
                          'addNotificationsXP',
                        )}
                        value={'5'}
                      >
                        {' '}
                      </Tab>
                    )}
                    {
                      allowed['view accesscodes'] && (

                        this.props?.project.isAccessCode === true && (
                          <Tab
                            className={
                              this.state.servicePlanData['addAccessCodeXP'] ===
                              false && 'disabled-tab'
                            }
                            onClick={() =>
                              this.navigate('access-code', 'addAccessCodeXP', '6')
                            } // To be added in service plan(superadmin)
                            style={{ ...styles.customTab }}
                            icon={<AccessCodeIcon />}
                            iconPosition="start"
                            label={this.getLabelForTabs(this.props.users.languageStateForRedux?.tabs?.accessCodes,
                              'addAccessCodeXP',
                            )}
                            value={'6'}
                          >
                            {' '}
                          </Tab>
                        )

                      )}
                    {allowed['view attendees'] && (
                      <Tab
                        className={
                          this.state.servicePlanData['addAttendeesXP'] ===
                          false && 'disabled-tab'
                        }
                        onClick={() => {
                          this.hideTransactionalReport();
                          this.navigate('attendees', 'addAttendeesXP', '8');
                        }}
                        style={{ ...styles.customTab }}
                        icon={<AttendeesIcon />}
                        iconPosition="start"
                        label={this.getLabelForTabs(this.props.users.languageStateForRedux?.tabs?.attendees,
                          'addAttendeesXP',
                        )}
                        value={'8'}
                      >
                        {' '}
                      </Tab>
                    )}
                    {allowed['view members'] && (
                      <Tab
                        className={
                          this.state.servicePlanData['addTeamXP'] === false &&
                          'disabled-tab'
                        }
                        onClick={() => this.navigate('team', 'addTeamXP', '7')}
                        style={{ ...styles.customTab }}
                        icon={<TeamIcon />}
                        iconPosition="start"
                        label={this.getLabelForTabs(this.props.users.languageStateForRedux?.tabs?.teams, 'addTeamXP')}
                        value={'7'}
                      >
                        {' '}
                      </Tab>
                    )}

                    {(allowed['webhooks'] || allowed['app integrations']) && (
                      <Tab
                        className={
                          this.state.servicePlanData['addIntegrationXP'] ===
                          false && 'disabled-tab'
                        }
                        onClick={() =>
                          this.navigate('integration', 'addIntegrationXP', '9')
                        }
                        style={{ ...styles.customTab }}
                        icon={<IntegrationIcon />}
                        iconPosition="start"
                        label={this.getLabelForTabs(this.props.users.languageStateForRedux?.tabs?.integration,
                          'addIntegrationXP',
                        )}
                        value={'9'}
                      >
                        {' '}
                      </Tab>
                    )}
                    {allowed['view meeting'] &&  this.props.project.project?.projectTypeId && this.props.project.project.projectTypeId[0] === "61b1cac4a72cac2a825c4917" && (
                     
                     <Tab
                      className={
                        this.state.servicePlanData['addMeetingsXP'] ===
                        false && 'disabled-tab'
                      }
                        onClick={() => this.navigate('meetings', 'addMeetingsXP', '10')}
                        style={{ ...styles.customTab }}
                        icon={<MeetingsIcon />}
                        iconPosition="start"
                        label={this.getLabelForTabs(this.props.users.languageStateForRedux?.tabs?.meetings, 'addMeetingsXP'
                      )}
                        value={'10'}
                      >
                        {' '}
                      </Tab> 
                   )}                                                           

                    {allowed['view gamification'] && (
                      <Tab
                        className={
                          this.state.servicePlanData['addGamificationXP'] ===
                          false && 'disabled-tab'
                        }
                        onClick={() =>
                          this.navigate(
                            'gamification/challenges',
                            'addGamificationXP',
                            '11',
                          )
                        }
                        style={{ ...styles.customTab }}
                        icon={<GamificationIcon />}
                        iconPosition="start"
                        label={this.getLabelForTabs(this.props.users.languageStateForRedux?.tabs?.gamification,
                          'addGamificationXP',
                        )}
                        value={'11'}
                      >
                        {' '}
                      </Tab>
                    )}
                  </TabList>
                </Box>
                <Box sx={{}}>
                  <TabPanel value="1">
                    <div className="tab-container">
                      <Grid container spacing={2}>
                        <Grid item xs="12" md="6" lg="4" xl="3">
                          <div class="tabpanel_content1">
                            {this.props.project && this.props.project.project && (
                              <React.Fragment>
                                <div class="block_head1">
                                  <h5>{this.props.users.languageStateForRedux?.overviewTab?.projectDetails}</h5>
                                {editProject && (
                                  
                                    <h6>
                                    <Link to={{ pathname: `/edit-project/${this.props.project.project._id}`, state: { lngCode: this.props.users.newLangState}, }}>
                                         {this.props.users.languageStateForRedux?.overviewTab?.edit}
                                       </Link>{' '}
                                     </h6>
                                  )}
                                </div>
                                <ul class="block_para1">
                                  <Box
                                    component="li"
                                    alignItems="start"
                                    sx={{ display: 'flex' }}
                                    className="block_list"
                                  >
                                    <img alt="" src={DateIcon} />
                                    <h5 className="small-subtitle">
                                      <strong>{this.props.users.languageStateForRedux?.overviewTab?.startDateTime}</strong> <br />
                                      <span></span>
                                      {moment
                                        .tz(
                                          this.props.project.project.startDate,
                                          this.props.project.project.timezone,
                                        )
                                        .format('dddd, Do MMM, YYYY, h:mm A')}
                                    </h5>
                                  </Box>
                                  <Box
                                    component="li"
                                    alignItems="start"
                                    sx={{ display: 'flex' }}
                                    className="block_list"
                                  >
                                    <img alt="" src={DateIcon} />
                                    <h5 className="small-subtitle">
                                      <strong>{this.props.users.languageStateForRedux?.overviewTab?.endDateTime}</strong> <br />
                                      <span>
                                        {moment
                                          .tz(
                                            this.props.project.project.endDate,
                                            this.props.project.project.timezone,
                                          )
                                          .format('dddd, Do MMM, YYYY, h:mm A')}
                                      </span>
                                    </h5>
                                  </Box>

                                  {/* earlyAccess */}
                                  {typeof this.props.project.project
                                    .earlyAccess === 'object' &&
                                    Object.keys(
                                      this.props.project.project.earlyAccess,
                                    ).length > 0 &&
                                    this.props.project.project.earlyAccess
                                      .accessType && (
                                      <Box
                                        component="li"
                                        alignItems="end"
                                        justifyContent={'space-between'}
                                        sx={{ display: 'flex' }}
                                        className="block_list"
                                      >
                                        <Box
                                          component="div"
                                          alignItems="start"
                                          sx={{ display: 'flex' }}
                                        >
                                          <img alt="" src={TimerIcon} />
                                          <h5 className="small-subtitle mb-0">
                                            <strong>
                                            {this.props.users.languageStateForRedux?.overviewTab?.earlyAccessFor}{' '}
                                              {this.props.project.project
                                                .earlyAccess.accessType
                                                ? this.props.project.project.earlyAccess.accessType
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                this.props.project.project.earlyAccess.accessType.slice(
                                                  1,
                                                )
                                                : '--'}
                                              :
                                            </strong>{' '}
                                            <br />
                                            <span>
                                              {moment
                                                .tz(
                                                  this.props.project.project
                                                    .earlyAccess
                                                    .accessDetails[0]
                                                    .accessDate,
                                                  this.props.project.project
                                                    .timezone,
                                                )
                                                .format(
                                                  'dddd, Do MMM, YYYY, h:mm A',
                                                )}
                                            </span>
                                          </h5>
                                        </Box>
                                        <Box className="early-access-detail">
                                          {/* if earlyaccess type is all */}
                                          {this.props.project.project
                                            .earlyAccess.accessType ===
                                            'all' ? (
                                            <>
                                              <span className="count">
                                                {Array.isArray(
                                                  this.props.project.project
                                                    .earlyAccess
                                                    .accessDetails[0]
                                                    .accessPages,
                                                ) &&
                                                  this.props.project.project
                                                    .earlyAccess.accessDetails[0]
                                                    .accessPages.length > 1
                                                  ? this.props.project.project
                                                    .earlyAccess
                                                    .accessDetails[0]
                                                    .accessPages.length +'  '+
                                                    (this.props.users.languageStateForRedux?.overviewTab?.pages)
                                                  : this.props.project.project
                                                    .earlyAccess
                                                    .accessDetails[0]
                                                    .accessPages.length +
                                                    this.props.users.languageStateForRedux?.overviewTab?.page}
                                              </span>
                                              <div className="page-info">
                                                {this.props.project.project.earlyAccess.accessDetails[0].accessPages.map(
                                                  (page) => {
                                                    return (
                                                      <p className="small-subtitle primary-text">
                                                        {page.pageName}
                                                      </p>
                                                    );
                                                  },
                                                )}
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              {/* early access for groups */}
                                              <span className="count">
                                                {Array.isArray(
                                                  this.props.project.project
                                                    .earlyAccess.accessDetails,
                                                ) &&
                                                  this.props.project.project
                                                    .earlyAccess.accessDetails
                                                    .length > 1
                                                  ? this.props.project.project
                                                    .earlyAccess.accessDetails
                                                    .length + this.props.users.languageStateForRedux?.overviewTab?.groups
                                                  : this.props.project.project
                                                    .earlyAccess.accessDetails
                                                    .length + '  '+ (this.props.users.languageStateForRedux?.overviewTab?.group)}
                                              </span>
                                              <Box className="group-info">
                                                <Box
                                                  component={Grid}
                                                  container
                                                  spacing={1}
                                                  px={3}
                                                  py={2}
                                                  alignItems="center"
                                                  className="group-info-header"
                                                >
                                                  <Grid item>
                                                    <Box
                                                      component={'img'}
                                                      mt={0}
                                                      alt="icon"
                                                      src={groupDetailIcon}
                                                    />
                                                  </Grid>
                                                  <Grid item>
                                                    <p className="paragraph bold">
                                                    {this.props.users.languageStateForRedux?.formfields?.overview?.headingOnHover}
                                                    </p>
                                                  </Grid>
                                                </Box>
                                                <Box
                                                  className="info-table"
                                                  sx={{
                                                    height: 'auto !important',
                                                  }}
                                                  component={TableContainer}
                                                >
                                                  <Table stickyHeader>
                                                    <TableHead>
                                                      <TableRow>
                                                        <TableCell className="tableHeadCell">
                                                          {this.props.users.languageStateForRedux?.formfields?.groups?.name}
                                                        </TableCell>
                                                        <TableCell
                                                          className="tableHeadCell"
                                                          align="left"
                                                        >
                                                          {this.props.users.languageStateForRedux?.formfields?.overview?.timeOnHover}
                                                        </TableCell>
                                                        <TableCell
                                                          className="tableHeadCell"
                                                          align="center"
                                                        >
                                                        {this.props.users.languageStateForRedux?.overviewTab?.pages}
                                                        </TableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      {this.props.project.project.earlyAccess.accessDetails.map(
                                                        (
                                                          accessDetailForGroups,
                                                        ) => {
                                                          return (
                                                            <TableRow
                                                              key={
                                                                accessDetailForGroups.groupId
                                                              }
                                                            >
                                                              <TableCell
                                                                className="tableBodyCell"
                                                                scope="row"
                                                              >
                                                                {
                                                                  accessDetailForGroups
                                                                    ?.groupId
                                                                    ?.attendeesGroupName
                                                                }
                                                              </TableCell>
                                                              <TableCell
                                                                className="tableBodyCell"
                                                                align="left"
                                                              >
                                                                {moment
                                                                  .tz(
                                                                    accessDetailForGroups.accessDate,
                                                                    this.props
                                                                      .project
                                                                      .project
                                                                      .timezone,
                                                                  )
                                                                  .format(
                                                                    'MMM, Do, YYYY, h:mm a',
                                                                  )}
                                                              </TableCell>
                                                              <TableCell
                                                                className="tableBodyCell pages"
                                                                align="center"
                                                              >
                                                                <span>
                                                                  <span className="count">
                                                                    +
                                                                    {
                                                                      accessDetailForGroups
                                                                        .accessPages
                                                                        .length
                                                                    }
                                                                  </span>
                                                                  <div className="page-info">
                                                                    {accessDetailForGroups.accessPages.map(
                                                                      (
                                                                        accessPage,
                                                                      ) => {
                                                                        return (
                                                                          <p className="small-subtitle primary-text">
                                                                            {
                                                                              accessPage.pageName
                                                                            }
                                                                          </p>
                                                                        );
                                                                      },
                                                                    )}
                                                                  </div>
                                                                </span>
                                                              </TableCell>
                                                            </TableRow>
                                                          );
                                                        },
                                                      )}
                                                    </TableBody>
                                                  </Table>
                                                </Box>
                                              </Box>
                                            </>
                                          )}
                                        </Box>
                                      </Box>
                                    )}
                                  {/* ---Group info end--- */}

                                  <Box
                                    component="li"
                                    alignItems="start"
                                    sx={{ display: 'flex' }}
                                    className="block_list"
                                  >
                                    <img alt="" src={LocationIcon} />
                                    <h5 className="small-subtitle">
                                      <strong>{this.props.users.languageStateForRedux?.overviewTab?.loc}</strong> <br />
                                      <span>
                                        {this.props.project.project.location}
                                      </span>
                                    </h5>
                                  </Box>
                                  <Box
                                    component="li"
                                    alignItems="start"
                                    sx={{ display: 'flex' }}
                                    className="block_list"
                                  >
                                    <img alt="" src={TimeZoneIcon} />
                                    <h5 className="small-subtitle">
                                      <strong>{this.props.users.languageStateForRedux?.overviewTab?.timeZone}</strong> <br />
                                      <span>
                                        {' '}
                                        {tease.dst(
                                          this.props.project.project.timezone,
                                        ) !== undefined
                                          ? `(UTC${tease.dst(
                                            this.props.project.project
                                              .timezone,
                                          )})`
                                          : ''}{' '}
                                        {this.props.project.project.timezone}
                                      </span>
                                    </h5>
                                  </Box>
                                  <Box
                                    component="li"
                                    alignItems="start"
                                    sx={{ display: 'flex' }}
                                    className="block_list"
                                  >
                                    <img alt="" src={EventDomainIcon} />
                                    <h5 className="small-subtitle">
                                      <strong>{this.props.users.languageStateForRedux?.overviewTab?.domain}</strong>
                                      <br />
                                      <Tooltip
                                        title={
                                          project &&
                                          project.eventDomain &&
                                          getEventURL(
                                            project.eventDomain,
                                            project.vanityDomain?.domainName,
                                            project.vanityDomain?.isActive,
                                            project.vanityDomain
                                              ?.cloudFrontDistribution?.enabled,
                                          )
                                        }
                                        placement="bottom-start"
                                        arrow
                                      >
                                        <span>
                                          <a
                                            target="_blank"
                                            href={
                                              project &&
                                              project.eventDomain &&
                                              getEventURL(
                                                project.eventDomain,
                                                project.vanityDomain?.domainName,
                                                project.vanityDomain?.isActive,
                                                project.vanityDomain
                                                  ?.cloudFrontDistribution
                                                  ?.enabled,
                                              )
                                            }
                                            rel="noreferrer"
                                          >
                                            {project &&
                                              project.eventDomain &&
                                              getEventURL(
                                                project.eventDomain,
                                                project.vanityDomain?.domainName,
                                                project.vanityDomain?.isActive,
                                                project.vanityDomain
                                                  ?.cloudFrontDistribution
                                                  ?.enabled,
                                              )}
                                          </a>
                                        </span>
                                      </Tooltip>
                                    </h5>
                                  </Box>
                                  <Box
                                    component="li"
                                    alignItems="start"
                                    sx={{ display: 'flex' }}
                                    className="block_list"
                                  >
                                    <img alt="" src={AttendeesPulpleIcon} />
                                    <h5 className="small-subtitle">
                                      <strong>{this.props.users.languageStateForRedux?.overviewTab?.expAttendees}</strong>{' '}
                                      <br />
                                      <span>
                                        {this.props.project.project.attendees}
                                      </span>
                                    </h5>
                                  </Box>
                                  <Box
                                    component="li"
                                    alignItems="start"
                                    sx={{ display: 'flex' }}
                                    className="block_list"
                                  >
                                    <img alt="" src={ReplytoIcon} />
                                    <h5 className="small-subtitle">
                                      <strong>{this.props.users.languageStateForRedux?.overviewTab?.replyTo}</strong> <br />
                                      <Tooltip
                                        title={
                                          this.props.project.project
                                            .replyToEmail
                                        }
                                        placement="bottom-start"
                                      >
                                        <span>
                                          {
                                            this.props.project.project
                                              .replyToEmail
                                          }
                                        </span>
                                      </Tooltip>
                                    </h5>
                                  </Box>
                                  <Box
                                    component="li"
                                    alignItems="start"
                                    sx={{ display: 'flex' }}
                                    className="block_list"
                                  >
                                    <img alt="" src={EmailSenderIcon} />
                                    <h5 className="small-subtitle">
                                      <strong>{this.props.users.languageStateForRedux?.overviewTab?.emailSenderName}</strong> <br />
                                      <span>
                                        {
                                          this.props.project.project
                                            .emailSenderName
                                        }
                                      </span>
                                    </h5>
                                  </Box>
                                  <Box
                                    component="li"
                                    alignItems="start"
                                    sx={{ display: 'flex' }}
                                    className="block_list"
                                  >
                                    <img alt="" src={TagsIcon} />
                                    <h5 className="small-subtitle">
                                      <strong>{this.props.users.languageStateForRedux?.overviewTab?.tags}</strong> <br />
                                      {this.props.project.project.eventTags && (
                                        <React.Fragment>
                                          {this.props.project.project.eventTags.map(
                                            (tag) =>
                                              tag ? (
                                                <span className="tags">
                                                  {`#${tag}`}{' '}
                                                </span>
                                              ) : (
                                                ''
                                              ),
                                          )}
                                        </React.Fragment>
                                      )}
                                    </h5>
                                  </Box>
                                </ul>
                              </React.Fragment>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs="12" md="6" lg="4" xl="6">
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <OverviewSummaryCards
                                project={this.props.project}
                              />
                            </Grid>
                            {allowed['view attendees'] && (
                              <Grid item xs={12}>
                                <OverviewAttendeesChart  />
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <OverviewCampaignChart  />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs="12" md="6" lg="4" xl="3">
                          {allowed['view attendees'] && (
                            <RecentAttendees project={this.props.project} lngCode={this.props.users.newLangState} />
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </TabPanel>
                  <TabPanel value="2">
                    <div className="tab-container">
                      <ManagePage
                        project={this.props.project}
                        editorData={{
                          projectId: this.props.project.project
                            ? this.props.project.project._id
                            : '',

                          templateId:
                            this.props.project.project &&
                              this.props.project.project.projectTemplateId
                                ?.length > 0
                              ? this.props.project.project.projectTemplateId[0]
                              : '',
                        }}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value="3">
                    <div className="tab-container">
                      <Campaigns
                        project={this.props.project}
                        editCampaign={this.state.editCampaign}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value="4">
                    <div className="tab-container">
                      <Agenda project={this.props.project}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value="5">
                    <div className="tab-container">
                      <NotificationsDashboard project={this.props.project} />
                    </div>
                  </TabPanel>
                  <TabPanel value="6">
                    <div className="tab-container">
                      <AccessCode project={this.props.project} />
                    </div>
                  </TabPanel>
                  <TabPanel value="7">
                    <div className="tab-container">
                      <TeamProject project={this.props.project}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value="8">
                    <div className="tab-container">
                      <Attendees project={this.props.project} />
                    </div>
                  </TabPanel>
                  <TabPanel value="9">
                    <div className="tab-container">
                      <Integration project={this.props.project} allowed={allowed} />
                    </div>
                  </TabPanel>
                  <TabPanel value="10">
                    <div className="tab-container">
                      <Meetings project={this.props.project}
                        lngCode={this.state.lngCode} />
                    </div>
                  </TabPanel>
                  <TabPanel value="11">
                    <div className="tab-container">
                      <Gamification project={this.props.project} />
                    </div>
                  </TabPanel>
                </Box>
              </TabContext>
            </div>

            <Modal
              open={this.state.open}
              closeOnOverlayClick={false}
              onClose={this.closeConfirmModal}
              classNames={{
                overlay: '',
                modal: 'Invite-User-Modal',
              }}
              center
            >
              <Grid container spacing={3} className="modal-wrapper">
                <Grid item xs={12}>
                  <Box component="p" mb={1} className="subtitle">
                    {' '}
                    {this.state.actionPerformed === 'publish'? this.props.users.languageStateForRedux?.common?.publishConfirmation:this.props.users.languageStateForRedux?.common?.unPublishConfirmation}
                  </Box>
                  <p className="small-subtitle">
                    {this.props.users.languageStateForRedux?.messages?.sure} {this.state.actionPerformed === 'publish'? this.props.users.languageStateForRedux?.common?.publish:this.props.users.languageStateForRedux?.common?.unPublish} {this.props.users.languageStateForRedux?.messages?.thisProject}</p>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="end">
                    <Stack spacing={1} direction="row" fullWidth>
                      {this.state.actionPerformed == 'publish' && (
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={this.publishProject}
                        >
                          {this.props.users.languageStateForRedux?.common?.publish}
                        </Button>
                      )}

                      {this.state.actionPerformed == 'un-publish' && (
                        <Button
                          variant="contained"
                          color="secondary"
                          disableElevation
                          onClick={this.unPublishProject}
                        >
                          {this.props.users.languageStateForRedux?.common?.unPublish}
                        </Button>
                      )}
                      <Button
                        variant="outlined"
                        disableElevation
                        onClick={this.closeConfirmModal}
                      >
                        {this.props.users.languageStateForRedux?.common?.cancel}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Modal>
          </ThemeProvider>
          <NotificationContainer />
          {loading || this.props.users.isLoading ? <Loader /> : null}
        </div>
        <ConfirmationAlertPopUp
          openModal={this.state.showNotAllowedPopUp}
          closeModalFunc={this.closeNotAllowedModal}
          title="Upgrade Plan"
          text={`Your current pricing plan does not allow to perform this operation.`}
          confirmationButtonText="Upgrade"
          confirmationButtonColor="primary"
          closeButtonText="Cancel"
          functionality={() => { }}
          showFunctionButton={false}
        />
      </DashboardLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.Projects,
  members: state.Members,
  events: state.Events,
  servicePlan: state.ServicePlan,
  agenda: state.Agenda,
  users: state.Users,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getProjectRequest: Actions.getProjectRequest,
      viewTransactionalReport: Actions.viewTransactionalReport,
      findIfAllowedFromSuperadminRequest:
        Actions.findIfAllowedFromSuperadminRequest,
      updateLngForEvent: Actions.updateLngCodeRequest,
      handleNavigationFromEditorToCreateMeeting:
      Actions.handleNavigationFromEditorToCreateMeeting,
      newLangStateForRedux: Actions.newLangStateForRedux,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(ProjectDashboard);
