import { call, put } from 'redux-saga/effects';
import { Actions } from '../../Actions';
import OpportunityAudienceAPI from 'Redux/API/opportunity/opportunityAudienceAPI';

import { removeStorage } from 'helpers/common';

function* fetchAllOpportunityAudience(action) {
  const opportunityAudienceAPI = new OpportunityAudienceAPI();
  const response = yield call(opportunityAudienceAPI.fetchAllOpportunityAudience, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.fetchAllOpportunityAudienceSuccess(
          response.data.audience,
          response.data.message,
          response.data.totalAudience,

        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.fetchAllOpportunityAudienceError(response.data.error));
      removeStorage();
    } else {
      yield put(Actions.fetchAllOpportunityAudienceError(response.data.error));
    }
  } else {
    yield put(Actions.fetchAllOpportunityAudienceError(response.data.error));
  }
}

function* deleteOpportunityAudience(action) {
  const opportunityAudienceAPI = new OpportunityAudienceAPI();
  const response = yield call(opportunityAudienceAPI.deleteOpportunityAudience, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.deleteOpportunityAudienceSuccess(
          response.data.audience,
          response.data.message,
          response.data.totalAudience,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.deleteOpportunityAudienceError(response.data.error));
      removeStorage();
    } else {
      yield put(Actions.deleteOpportunityAudienceError(response.data.error));
    }
  } else {
    yield put(Actions.deleteOpportunityAudienceError(response.data.error));
  }
}

function* createOpportunityAudience(action) {
  const opportunityAudienceAPI = new OpportunityAudienceAPI();
  const response = yield call(opportunityAudienceAPI.createOpportunityAudience, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.createOpportunityAudienceSuccess(
          response.data.audience,
          response.data.message,
          response.data.totalAudience,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.createOpportunityAudienceError(response.data.error));
      removeStorage();
    } else {
      yield put(Actions.createOpportunityAudienceError(response.data.message));
    }
  } else {
    yield put(Actions.createOpportunityAudienceError(response.data.message));
  }
}

function* editOpportunityAudience(action) {
  const opportunityAudienceAPI = new OpportunityAudienceAPI();
  const response = yield call(opportunityAudienceAPI.editOpportunityAudience, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.editOpportunityAudienceSuccess(
          response.data.audience,
          response.data.message,
          response.data.totalAudience,
        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.editOpportunityAudienceError(response.data.error));
      removeStorage();
    } else {
      yield put(Actions.editOpportunityAudienceError(response.data.error));
    }
  } else {
    yield put(Actions.editOpportunityAudienceError(response.data.error));
  }
}

function* assignContactList(action) {
  const opportunityAudienceAPI = new OpportunityAudienceAPI();
  const response = yield call(opportunityAudienceAPI.assignContactList, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.assignContactListSuccess(
          response.data.unassignContact,
          response.data.totalUnassignContact,
          response.data.message,

        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.assignContactListError(response.data.error));
      removeStorage();
    } else {
      yield put(Actions.assignContactListError(response.data.error));
    }
  } else {
    yield put(Actions.assignContactListError(response.data.error));
  }
}

function* getAllOpportunityGroups(action) {
  const opportunityAudienceAPI = new OpportunityAudienceAPI();
  const response = yield call(opportunityAudienceAPI.getAllOpportunityGroups, action.data);
  if (response.status === 200) {

    if (response.data.success) {
      yield put(
        Actions.getAllOpportunityGroupsSuccess(
          response.data.groups,
          response.data.totalGroups,
          response.data.message,

        ),
      );
    } else if (response.data.status === 403) {
      yield put(Actions.getAllOpportunityGroupsError(response.data.error));
      removeStorage();
    } else {
      yield put(Actions.getAllOpportunityGroupsError(response.data.error));
    }
  } else {
    yield put(Actions.getAllOpportunityGroupsError(response.data.error));
  }
}


const opportunityAudienceSaga = {
  getAllOpportunityGroups,
  assignContactList,
  fetchAllOpportunityAudience,
  deleteOpportunityAudience,
  editOpportunityAudience,
  createOpportunityAudience,
};

export default opportunityAudienceSaga;