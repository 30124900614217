import React, { Component } from 'react'
import 'react-responsive-modal/styles.css'
import { withRouter } from 'react-router'
import { redirectTo } from '../../helpers'
import { Button } from '@material-ui/core'

class ImportStatusModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      loading: false,
    }
  }

  render() {
    const { errors, loading } = this.state
     
    return (
      <div>
        <h2>Import Status</h2>
        <div className="rsvpEventfieldBox">
          <div className="formSection p-0">
            <form>
              <div class="field-group inputField fullWidthInput">
                <div className="importContactss">
                  <label> Imported contacts: &nbsp;</label>
                  <p>{this.props.imported || 0} </p>
                </div>
              </div>
              <div class="field-group inputField fullWidthInput">
                <div className="importContactss">
                  <label> Rejected contacts: &nbsp;</label>
                  <p>{this.props.rejected || 0} </p>
                </div>
              </div>
              {this.props.rejected && this.props.rejected > 0 ? (
                <div className="editProfileBtn submitForm justify-content-center mt-4">
                  <Button
                    type="button"
                    className="themeBlueButton"
                    onClick={() =>
                      redirectTo(
                        this.props.history,
                        'importlogs',
                        this.props.location.pathname,
                      )
                    }
                  >
                    View Rejected Report
                  </Button>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ImportStatusModal)
