import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
  success: false,
  token: null,
  error: false,
  message: null,
  user: null,
  isSubscribed: null,
  subscriptionError: null,
  forgotMsg: null,
  resetMsg: null,
}

const success = (state = INITIAL_STATE, action) => {
  return { ...state, success: true, token: action.token, user: action.user }
}

const error = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const request = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isSubscribed: false,
    subscriptionError: false,
    forgotMsg: null,
    resetMsg: null,
    resetSuccess: false,
    resetError: false,
    forgotError: false,
    forgotSuccess: false,
    success: false,
    error: false,
    message: null,
  }
}

const subscriptionError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    message: action.error,
    isSubscribed: false,
    subscriptionError: true,
  }
}

const forgotPasswordSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, forgotSuccess: true, forgotMsg: action.message }
}

const forgotPasswordError = (state = INITIAL_STATE, action) => {
  return { ...state, forgotError: true, forgotMsg: action.error }
}

const forgotPasswordRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isSubscribed: false,
    subscriptionError: false,
    forgotMsg: null,
    resetMsg: null,
    resetSuccess: false,
    resetError: false,
    forgotError: false,
    forgotSuccess: false,
    success: false,
    error: false,
    message: null,
  }
}

const resetPasswordSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, resetSuccess: true, resetMsg: action.message }
}

const resetPasswordError = (state = INITIAL_STATE, action) => {
  return { ...state, resetError: true, resetMsg: action.error }
}

const resetPasswordRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isSubscribed: false,
    subscriptionError: false,
    forgotMsg: null,
    resetMsg: null,
    resetSuccess: false,
    resetError: false,
    forgotError: false,
    forgotSuccess: false,
    success: false,
    error: false,
    message: null,
  }
}

const HANDLERS = {
  [Constants.LOGIN_SUCCESS]: success,
  [Constants.LOGIN_FAILURE]: error,
  [Constants.LOGIN_REQUEST]: request,
  [Constants.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
  [Constants.FORGOT_PASSWORD_ERROR]: forgotPasswordError,
  [Constants.FORGOT_PASSWORD_REQUEST]: forgotPasswordRequest,
  [Constants.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Constants.RESET_PASSWORD_ERROR]: resetPasswordError,
  [Constants.RESET_PASSWORD_REQUEST]: resetPasswordRequest,

  [Constants.SUBSCRIPTION_ERROR]: subscriptionError,
}

export default createReducer(INITIAL_STATE, HANDLERS)
