import { Button } from '@material-ui/core';
import { Box } from '@mui/material';
import { FilterCount as FilterCountForAccounts} from 'Redux/Reducers/opportunity/accounts.reducer';
import { FilterCount as FilterCountForContacts} from 'Redux/Reducers/opportunity/contacts.reducer';
import { FilterCount as FilterCountForOpportunities} from 'Redux/Reducers/opportunity/opportunities.reducer';
import React from 'react';
import { theme } from 'theme/theme';

type Props = {
    filterByLastImported: boolean,
    setFilterByLastImported: React.Dispatch<React.SetStateAction<boolean>>,
    filterCount: FilterCountForContacts | FilterCountForAccounts | FilterCountForOpportunities
}

function FilterByLastImported({filterByLastImported, setFilterByLastImported, filterCount}: Props) {

  return (
    <Box
      component={Button}
      variant="contained"
      sx={{
        //@ts-ignore
        backgroundColor: theme.palette.background.light,
        color: theme.palette.common.black,
        textTransform: 'capitalize !important',
        fontWeight: '400',
        '&:hover, &.active': {
          backgroundColor: '#000056 !important',
          color: theme.palette.primary.contrastText,
        },
      }}
      className={filterByLastImported ? 'active' : ''}
      size="small"
      disableElevation
      onClick={() => setFilterByLastImported(prev => !prev)}
    >
      Last Imported ({filterCount?.lastImportedAll || 0})
    </Box>
  );
}

export default FilterByLastImported;
