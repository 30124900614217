export const addPageAndPageUrl = (pageName, pageUrl) => {
    return {
        type: "ADD_PAGE_AND_PAGE_URL",
        payload: {
            pageName,
            pageUrl
        }
    }
}

const INITIAL_STATE = {
    pageName : "",
    pageUrl: ""
}

const ManagePageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "ADD_PAGE_AND_PAGE_URL":
            return {
                ...state,
                pageName : action.payload.pageName,
                pageUrl: action.payload.pageUrl,
            }

        default: return state
            break;
    }
}

export default ManagePageReducer