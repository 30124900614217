import { theme } from 'theme/theme'

export const styles = {
    customTabList: {
      padding: '0px 25px 0',
      background: '#000056',
    },
    customTab: {
      minHeight: 42,
      padding: '12px 16px',
      background: '#000056',
      color: '#ffffff',
    },
    customTabPanel: {
      height: 'calc(100vh - 148px)',
      overflow: 'auto',
    },

    pink: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      width: theme.spacing(5),
      height: theme.spacing(5),
    },

    blue: {
      color: theme.palette.primary,
      backgroundColor: theme.palette.primary.main,
      fontSize: '14px',
      width: theme.spacing(4.25),
      height: theme.spacing(4.25),
    },
  }

  export const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
        marginTop: 4,
      },
    },
  }