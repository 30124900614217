import { Box, colors, Grid, Stack, styled } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { IconButton, Tooltip } from '@material-ui/core';
import Badge from '@mui/material/Badge';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { theme } from '../../../theme/theme';
import {
  Opportunity
} from 'Redux/Reducers/opportunity/opportunities.reducer';
import {
  Account
} from 'Redux/Reducers/opportunity/accounts.reducer';
import OpportunityView from '../Opportunity/OpportunityView/OpportunityView';
import CommentsAndAttachments from 'Pages/Opportunity/Common/CommentsAndAttachments/CommentsAndAttachments';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import {
  CommentSection,
  ViewType
} from 'Redux/Reducers/opportunity/opportunityCommon.reducer';
import AccountView from '../Accounts/AccountView';
import { Contact } from 'Redux/Reducers/opportunity/contacts.reducer';
import ContactDetails from '../Contacts/ContactsView/ContactDetails';
import ContactsViewLayout from '../Contacts/ContactsView/ContactsViewLayout';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 8,
    right: 8,
    // border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    borderRadius: '4px',
    width: 24,
    height: 22,
  },
}));

const styles = {
  avatar: {
    backgroundColor: '#FF4170',
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: 8,
  },
  customTabList: {
    minHeight: 42,
  },
  customTab: {
    minHeight: 42,
  },
  customSubTab: {
    minHeight: 42,
    color: '#000056',
    borderBottom: '3px solid #ffffff',
  },
};

type Props = {
  closeViewModal: () => void;
  opportunityToBeEdited?: Opportunity | undefined;
  accountToBeEdited?: Account | undefined;
  contactToBeEdited?: Contact | undefined;
  setShowAddNew: React.Dispatch<React.SetStateAction<boolean>>;
  setShowViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleTabChangeFromMainLayout: (
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    searchText: string,
  ) => void;
  type: ViewType;
};

function ViewLayout({
  closeViewModal,
  opportunityToBeEdited,
  accountToBeEdited,
  contactToBeEdited,
  setShowAddNew,
  setShowViewModal,
  handleTabChangeFromMainLayout,
  type
}: Props) {
  const [tabValue, setTabValue] = React.useState('1');
  const [openGroupbar, setGroupbarOpen] = React.useState(false);
  const [section, setSection] = React.useState<CommentSection>("comments");
  const [commentSectionOpened, setCommentSectionOpened] = React.useState(false);

  const handleDrawerOpen = () => {
    setGroupbarOpen(true);
    setCommentSectionOpened(true);
  };

  const handleDrawerClose = () => {
    setGroupbarOpen(false);
  };

  const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setTabValue(newValue);
  };

  const changeSection = (clickedSection: CommentSection) => {
    if (section == clickedSection) {
      openGroupbar ? handleDrawerClose() : handleDrawerOpen();
    }
    setSection(clickedSection);
  };

  return (
    <Box>
      <Grid container spacing={1} flexWrap={'nowrap'}>
        <Grid item xs>

          {type == 'opportunity' ?
            <Box p={3} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 145px)' }}>
              <Box py={0} className='tab-container'>
                <OpportunityView
                  closeViewModal={closeViewModal}
                  opportunityToBeEdited={opportunityToBeEdited}
                  setShowAddNew={setShowAddNew}
                  setShowViewModal={setShowViewModal}
                  handleTabChange={handleTabChangeFromMainLayout}
                  commentSectionOpened={commentSectionOpened}
                />
              </Box>
            </Box>
            : type == 'account' ?
              <Box p={3} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 145px)' }}>
                <Box py={0} className='tab-container'>
                  <AccountView
                    closeViewModal={closeViewModal}
                    accountToBeEdited={accountToBeEdited}
                    setShowAddNew={setShowAddNew}
                    setShowViewModal={setShowViewModal}
                    handleTabChange={handleTabChangeFromMainLayout}
                  />
                </Box>
              </Box>
              :
              <Box px={1.25} py={4.5} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 145px)' }}>
                
                  <ContactsViewLayout
                    handleTabChangeFromMainLayout={handleTabChangeFromMainLayout}
                    closeViewModal={closeViewModal}
                    setShowAddNew={setShowAddNew}
                    setShowViewModal={setShowViewModal}
                    contactToBeEdited={contactToBeEdited as Contact}
                  />
              </Box>
          }
        </Grid>
        <Grid item >
          <Stack direction={'row'} justifyContent={'end'} className={'collapsible-sidebar'}>
            <Box className={`sidebar-content right ${openGroupbar && 'show'}`}
              style={
                openGroupbar ?
                  { width: '400px', transition: 'width 0.05s' }
                  : { width: '0', minWidth: 'unset' }
              }
            >
              {openGroupbar &&
                <CommentsAndAttachments
                  id={
                    type == 'opportunity' ? opportunityToBeEdited?._id :
                      type == 'account' ? accountToBeEdited?._id :
                        contactToBeEdited?._id
                  }
                  type={type}
                  section={section}
                />
              }
            </Box>
            <Box className="sidebar-tabs" p={1}>
              <Stack spacing={1}>
                <Tooltip placement='right-start' enterDelay={800} arrow title={openGroupbar ? "Show less information" : "Expand to see Comments"}>

                  <IconButton color='primary' onClick={openGroupbar ? handleDrawerClose : handleDrawerOpen} >
                    {openGroupbar ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton>
                </Tooltip>
                <IconButton color='primary' className={`tab-buttons ${openGroupbar && section == "comments" && "active"}`} onClick={() => changeSection('comments')} >
                  <CommentOutlinedIcon />
                </IconButton>
                <IconButton color='primary' className={`tab-buttons ${openGroupbar && section == "attachments" && "active"}`} onClick={() => changeSection('attachments')} >
                  <AttachFileOutlinedIcon />
                </IconButton>
              </Stack>
            </Box>
          </Stack>

        </Grid>

      </Grid>



    </Box>
  );
}

export default ViewLayout;
