import { createReducer } from 'reduxsauce';
import { Constants } from '../Actions';

const INITIAL_STATE = {
    error: false,
    message: null,
    customFields: [],
    customField: {},
    getCustomFieldSuccess: false,
    addCustomFieldSuccess: false,
    deleteCustomFieldSuccess: false,
    updateCustomFieldSuccess: false,
    getAttendeesSuccess: false,
    addAttendeesSuccess: false,
    deleteAttendeesSuccess: false,
    editAttendeeSuccess: false,
    checkedInHistoryAttendeeSuccess: false,
    searchAttendeesSuccess: false,
    sortFilterAttendeesSuccess: false,
    importAttendeesSuccess: false,
    importAttendeesValidation: false,
    importAttendeesError: false,
    duplicateEmailsInCsv: 0,
    invalidEmailsRejection: 0,
    alreadyExistInDb: 0,
    groupNotExists: 0,
    rejectedEmails: 0,
    attendees: [],
    attendeesFound: [],
    totalAttendeesRecords: 0,
    filterCnt: {},
    enabledStatusSuccess: false,
    actionAssignSuccess: false,
    actionCheckinSuccess: false,
    attendeesGroups: [],
    getAttendeesGroupsSuccess: false,
    addAttendeesGroupsSuccess: false,
    deleteAttendeesGroupsSuccess: false,
    editAttendeesGroupsSuccess: false,
    sortAttendeesSuccess: false,
    totalSortRecords: 0,
    sortData: [],
    listOfUnassignedAttendees: [],
    listUnassignedAttendeesSuccess: false,
    listOfAssignedAttendees: [],
    listAssignedAttendeesSuccess: false,
    assignAttendeesSuccess: false,
    searchAttendeesOfGroupSuccess: false,
    searchAttendeesOfUnassignGroupSuccess: false,
    totalResultsAssignedAttendees: 0,
    totalResultsUnssignedAttendees: 0,
    downloadCsvSuccess: false,
    exportAttendeesSuccess: false,
    exportCheckInHistorySuccess: false,
    fileCSV: "",

    badgesTemplates: [],
    listBadgesTemplateSuccess: false,
    allBadgesTemplates: [],
    listAllBadgesTemplateSuccess: false,

    transactionalEmailTemplates: [],
    listTransactionalEmailTemplatesSuccess: false,
    allTransactionalEmailTemplates: [],
    listAllTransactionalEmailTemplatesSuccess: false,

    getAttendeesChartDataSuccess: false,
    attendeesChartData: {},
    isReportComp: false,
    assignAccessCodeSuccess: false,
    getUnassignAccessCode: [],
    totalAccessCode: 0,
    unUsedAccessCodeSuccess: false,
    uniqueAttendees: 0,
    totalGroupsToListInGroupsTab: null,
    importAttendeesId: 0,
    searchAttendeeGroup: [],
    filterByStatus: {},
};

const assignAccessCodeRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        assignAccessCodeSuccess: false,
        error: false,
        message: null,
    };
};

const assignAccessCodeSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        assignAccessCodeSuccess: true,
        error: false,
        message: action.message,
    };
};

const assignAccessCodeError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        assignAccessCodeSuccess: false,
        error: true,
        message: action.error
    };
};

const unUsedAccessCodeRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        unUsedAccessCodeSuccess: false,
        error: false,
        message: null,
        getUnassignAccessCode: [],
    };
};

const unUsedAccessCodeSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        unUsedAccessCodeSuccess: true,
        error: false,
        message: action.message,
        getUnassignAccessCode: action.getUnassignAccessCode,
        totalAccessCode: action.totalAccessCode,
    };
};

const unUsedAccessCodeError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        unUsedAccessCodeSuccess: false,
        error: true,
        message: action.error,
        getUnassignAccessCode: [],
        totalAccessCode: 0
    };
};

const customFieldsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getCustomFieldSuccess: true,
        error: false,
        message: action.message,
        customFields: action.customFields,
        googleCutomFields: action.googleCutomFields
    };
};

const customFieldsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getCustomFieldSuccess: false,
        error: true,
        message: action.error,
    };
};

const customFieldsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getCustomFieldSuccess: false,
        error: false,
        message: null,
        addCustomFieldSuccess: false,
    };
};

const addCustomFieldsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addCustomFieldSuccess: false,
        error: false,
        message: null,
    };
};

const addCustomFieldsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addCustomFieldSuccess: true,
        error: false,
        message: action.message,
    };
};
const addCustomFieldsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addCustomFieldSuccess: false,
        error: true,
        message: action.error,
    };
};

const deleteCustomFieldsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteCustomFieldSuccess: false,
        error: false,
        message: null,
    };
};
const deleteCustomFieldsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteCustomFieldSuccess: true,
        error: false,
        message: action.message,
    };
};
const deleteCustomFieldsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteCustomFieldSuccess: false,
        error: true,
        message: action.error,
    };
};

const updateCustomFieldRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        updateCustomFieldSuccess: false,
        error: false,
        message: null,
    };
};
const updateCustomFieldSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        updateCustomFieldSuccess: true,
        error: false,
        message: action.message,
        customField: action.customField,
    };
};
const updateCustomFieldError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        updateCustomFieldSuccess: false,
        error: true,
        message: action.error,
    };
};

const listAttendeesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getAttendeesSuccess: false,
        error: false,
        message: null,
        attendees: [],
        getAttendeesGroupsSuccess: false,
    };
};

const listAttendeesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getAttendeesSuccess: true,
        error: false,
        message: action.message,
        attendees: action.data,
        totalAttendeesRecords: action.totalRecords,
        getAttendeesGroupsSuccess: false,
    };
};
const listAttendeesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getAttendeesSuccess: false,
        error: true,
        message: action.error,
        getAttendeesGroupsSuccess: false,
    };
};

const addAttendeesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addAttendeesSuccess: false,
        error: false,
        message: null,
    };
};
const addAttendeesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addAttendeesSuccess: true,
        error: false,
        message: action.message,
        data: action.data,
    };
};
const addAttendeesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addAttendeesSuccess: false,
        error: true,
        message: action.error,
    };
};

const deleteAttendeesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteAttendeesSuccess: false,
        error: false,
        addAttendeesSuccess: false,
        getAttendeesGroupsSuccess: false,
        sortFilterAttendeesSuccess: false,
    };
};
const deleteAttendeesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteAttendeesSuccess: true,
        error: false,
        message: action.message,
        addAttendeesSuccess: false,
        getAttendeesGroupsSuccess: false,
        sortFilterAttendeesSuccess: false,
    };
};

const deleteAttendeesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteAttendeesSuccess: false,
        error: true,
        message: action.error,
        addAttendeesSuccess: false,
        getAttendeesGroupsSuccess: false,
        sortFilterAttendeesSuccess: false,
    };
};

const downloadCsvRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        downloadCsvSuccess: false,
        error: false,
        message: null,
    };
};
const downloadCsvSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        downloadCsvSuccess: true,
        error: false,
        message: action.message
    };
};

const downloadCsvError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        downloadCsvSuccess: false,
        error: true,
        message: action.error
    };
};

const exportAttendeesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        exportAttendeesSuccess: false,
        error: false,
        message: null,
    };
};

const exportAttendeesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        exportAttendeesSuccess: true,
        error: false,
        message: action.message
    };
};

const exportAttendeesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        exportAttendeesSuccess: false,
        error: true,
        message: action.error
    };
};

const exportCheckInHistoryRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        exportCheckInHistorySuccess: false,
        error: false,
        message: null,
    };
};

const exportCheckInHistorySuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        exportCheckInHistorySuccess: true,
        error: false,
        message: action.message
    };
};

const exportCheckInHistoryError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        exportCheckInHistorySuccess: false,
        error: true,
        message: action.error
    };
};

const editAttendeeRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        editAttendeeSuccess: false,
        error: false,
        message: null,
        getAttendeesGroupsSuccess: false,
        sortFilterAttendeesSuccess: false,
    };
};
const editAttendeeSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        editAttendeeSuccess: true,
        error: false,
        message: action.message,
        getAttendeesGroupsSuccess: false,
        sortFilterAttendeesSuccess: false,
    };
};
const editAttendeeError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        editAttendeeSuccess: false,
        error: true,
        message: action.error,
        getAttendeesGroupsSuccess: false,
        sortFilterAttendeesSuccess: false,
    };
};

const checkedInHistoryAttendeeRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        checkedInHistoryAttendeeSuccess: false,
        error: false,
        message: null,
        getAttendeesGroupsSuccess: false,
        sortFilterAttendeesSuccess: false,
    };
};
const checkedInHistoryAttendeeSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        checkedInHistoryAttendeeSuccess: true,
        error: false,
        message: action.message,
        getAttendeesGroupsSuccess: false,
        sortFilterAttendeesSuccess: false,
    };
};
const checkedInHistoryAttendeeError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        checkedInHistoryAttendeeSuccess: false,
        error: true,
        message: action.error,
        getAttendeesGroupsSuccess: false,
        sortFilterAttendeesSuccess: false,
    };
};

const searchAttendeesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        searchAttendeesSuccess: false,
        error: false,
        message: null
    };
};

const searchAttendeesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        searchAttendeesSuccess: true,
        error: false,
        message: action.message,
        attendees: action.data,
        totalAttendeesRecords: action.totalRecords
    };
};

const searchAttendeesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        searchAttendeesSuccess: false,
        error: true,
        message: action.error
    };
};

const sortFilterAttendeesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        sortFilterAttendeesSuccess: false,
        error: false,
        message: null,
        deleteAttendeesSuccess: false,
        editAttendeeSuccess: false,
        getCustomFieldSuccess: false,
    };
};

const sortFilterAttendeesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        sortFilterAttendeesSuccess: true,
        deleteAttendeesSuccess: false,
        error: false,
        message: action.message,
        attendees: action.data,
        totalAttendeesRecords: action.totalRecords,
        filterCnt: action.filterCnt,
        editAttendeeSuccess: false,
        getCustomFieldSuccess: false,
    };
};

const sortFilterAttendeesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        sortFilterAttendeesSuccess: false,
        deleteAttendeesSuccess: false,
        error: true,
        message: action.error,
        editAttendeeSuccess: false,
        getCustomFieldSuccess: false,
    };
};

const enabledStatusRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        enabledStatusSuccess: false,
        error: false,
        message: null
    };
};
const enabledStatusSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        enabledStatusSuccess: true,
        error: false,
        message: action.message,
    };
};
const enabledStatusError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        enabledStatusSuccess: false,
        error: true,
        message: action.error,
    };
};
const actionAssignRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        actionAssignSuccess: false,
        error: false,
        message: null,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        enabledStatusSuccess: false,
    };
};
const actionAssignSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        actionAssignSuccess: true,
        error: false,
        message: action.message,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        enabledStatusSuccess: false,
    };
};
const actionAssignError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        actionAssignSuccess: false,
        error: true,
        message: action.error,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        enabledStatusSuccess: false,
    };
};

const actionCheckinRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        actionCheckinSuccess: false,
        error: false,
        message: null,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        enabledStatusSuccess: false,
    };
};
const actionCheckinSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        actionCheckinSuccess: true,
        error: false,
        message: action.message,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        enabledStatusSuccess: false,
    };
};
const actionCheckinError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        actionCheckinSuccess: false,
        error: true,
        message: action.error,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        enabledStatusSuccess: false,
    };
};



const listAttendeesGroupsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getAttendeesGroupsSuccess: false,
        error: false,
        message: null,
        attendeesGroups: [],
        addAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        deleteAttendeesGroupsSuccess: false,
        editGroupNameSuccess: false,
        totalGroupsToListInGroupsTab: null
    };
};
const listAttendeesGroupsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getAttendeesGroupsSuccess: true,
        error: false,
        message: action.message,
        attendeesGroups: action.data,
        addAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        deleteAttendeesGroupsSuccess: false,
        editGroupNameSuccess: false,
        listAssignedAttendeesSuccess: false,
        totalGroupsToListInGroupsTab: action.totalGroupsToListInGroupsTab
    };
};
const listAttendeesGroupsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getAttendeesGroupsSuccess: false,
        error: true,
        message: action.error,
        attendeesGroups: [],
        addAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        deleteAttendeesGroupsSuccess: false,
        editGroupNameSuccess: false,
    };
};

const addAttendeesGroupsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addAttendeesGroupsSuccess: false,
        error: false,
        message: null,
    };
};

const addAttendeesGroupsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addAttendeesGroupsSuccess: true,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        error: false,
        message: action.message,
    };
};
const addAttendeesGroupsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addAttendeesGroupsSuccess: false,
        error: true,
        message: action.error,
    };
};
const deleteAttendeesGroupsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteAttendeesGroupsSuccess: false,
        error: false,
        message: null,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,

    };
};
const deleteAttendeesGroupsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteAttendeesGroupsSuccess: true,
        error: false,
        message: action.message,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
    };
};
const deleteAttendeesGroupsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteAttendeesGroupsSuccess: false,
        error: true,
        message: action.error,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
    };
};

const sortAttendeesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        sortAttendeesSuccess: false,
        error: false,
        message: null,
    };
};
const sortAttendeesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        sortAttendeesSuccess: true,
        error: false,
        message: action.message,
        attendees: action.data,
        totalSortRecords: action.totalRecords,
    };
};
const sortAttendeesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        sortAttendeesSuccess: false,
        error: true,
        message: action.error,
    };
};

const listUnassignedAttendeesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listUnassignedAttendeesSuccess: false,
        error: false,
        message: null,
        totalResultsUnssignedAttendees: 0,
        listAssignedAttendeesSuccess: false,
        assignAttendeesSuccess: false,
        searchAttendeesOfUnassignGroupSuccess: false,
    };
};

const listUnassignedAttendeesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listUnassignedAttendeesSuccess: true,
        error: false,
        message: action.message,
        totalResultsUnssignedAttendees: action.totalResults,
        listOfUnassignedAttendees: action.data,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listAssignedAttendeesSuccess: false,
        assignAttendeesSuccess: false,
        searchAttendeesOfUnassignGroupSuccess: false,
    };
};
const listUnassignedAttendeesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listUnassignedAttendeesSuccess: false,
        error: true,
        message: action.message,
        totalResultsUnssignedAttendees: 0,
        listAssignedAttendeesSuccess: false,
        assignAttendeesSuccess: false,
        searchAttendeesOfUnassignGroupSuccess: false,
    };
};

const listAssignedAttendeesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listAssignedAttendeesSuccess: false,
        error: false,
        message: null,
        filterByStatus: {},
        totalResultsAssignedAttendees: 0,
        listOfAssignedAttendees: [],
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        unAssignAttendeesSuccess: false,
        searchAttendeesOfGroupSuccess: false,
    };
};

const listAssignedAttendeesSuccess = (state = INITIAL_STATE, action) => {
    console.log("action---->", action);
    return {
        ...state,
        listAssignedAttendeesSuccess: true,
        error: false,
        message: action.message,
        totalResultsAssignedAttendees: action.totalResults,
        listOfAssignedAttendees: action.data,
        filterByStatus: action.filterByStatus,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        unAssignAttendeesSuccess: false,
        searchAttendeesOfGroupSuccess: false,
    };
};
const listAssignedAttendeesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listAssignedAttendeesSuccess: false,
        error: true,
        message: action.error,
        filterByStatus: {},
        totalResultsAssignedAttendees: 0,
        listOfAssignedAttendees: [],
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        unAssignAttendeesSuccess: false,
        searchAttendeesOfGroupSuccess: false,
    };
};

const editAttendeesGroupNameRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        editGroupNameSuccess: false,
        listAssignedAttendeesSuccess: false,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        unAssignAttendeesSuccess: false,
    };
};

const editAttendeesGroupNameSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        editGroupNameSuccess: true,
        listAssignedAttendeesSuccess: false,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        unAssignAttendeesSuccess: false,
    };
};

const editAttendeesGroupNameError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        editGroupNameSuccess: false,
        listAssignedAttendeesSuccess: false,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        unAssignAttendeesSuccess: false,
    };
};

const unassignAttendeesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        unAssignAttendeesSuccess: false,
        error: false,
        message: null,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        listAssignedAttendeesSuccess: false,
        searchAttendeesOfUnassignGroupSuccess: false,
    };
};

const unassignAttendeesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        unAssignAttendeesSuccess: true,
        error: false,
        message: action.message,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        listAssignedAttendeesSuccess: false,
        searchAttendeesOfUnassignGroupSuccess: false,
    };
};

const unassignAttendeesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        unAssignAttendeesSuccess: false,
        error: true,
        message: action.error,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        searchAttendeesOfUnassignGroupSuccess: false,
    };
};

const assignAttendeesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        assignAttendeesSuccess: false,
        error: false,
        message: null,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        listAssignedAttendeesSuccess: false,
        searchAttendeesOfUnassignGroupSuccess: false,
    };
};
const assignAttendeesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        assignAttendeesSuccess: true,
        error: false,
        message: action.message,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        listAssignedAttendeesSuccess: false,
        searchAttendeesOfUnassignGroupSuccess: false,
    };
};

const assignAttendeesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        assignAttendeesSuccess: false,
        error: true,
        message: action.error,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        listAssignedAttendeesSuccess: false,
        searchAttendeesOfUnassignGroupSuccess: false,
    };
};

const searchAttendeesOfGroupRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        searchAttendeesOfGroupSuccess: false,
        searchAttendeeGroup: [],
        message: null,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        listAssignedAttendeesSuccess: false,
        assignAttendeesSuccess: false,
    };
};

const searchAttendeesOfGroupSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        searchAttendeesOfGroupSuccess: true,
        searchAttendeeGroup: action.data,
        message: action.message,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        listAssignedAttendeesSuccess: false,
        assignAttendeesSuccess: false,
    };
};

const searchAttendeesOfGroupError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        searchAttendeesOfGroupSuccess: false,
        searchAttendeeGroup: [],
        message: action.message,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        listAssignedAttendeesSuccess: false,
        assignAttendeesSuccess: false,
    };
};

const searchAttendeesOfUnassignGroupRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        searchAttendeesOfUnassignGroupSuccess: false,
        error: false,
        listOfUnassignedAttendees: [],
        message: null,
        searchAttendeesOfGroupSuccess: false,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        listAssignedAttendeesSuccess: false,
        assignAttendeesSuccess: false,
    };
};

const searchAttendeesOfUnassignGroupSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        searchAttendeesOfUnassignGroupSuccess: true,
        error: false,
        listOfUnassignedAttendees: action.data,
        message: action.message,
        searchAttendeesOfGroupSuccess: false,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        listAssignedAttendeesSuccess: false,
        assignAttendeesSuccess: false,
    };
};

const searchAttendeesOfUnassignGroupError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        searchAttendeesOfUnassignGroupSuccess: false,
        error: true,
        listOfUnassignedAttendees: [],
        message: action.message,
        searchAttendeesOfGroupSuccess: false,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        listAssignedAttendeesSuccess: false,
        assignAttendeesSuccess: false,
    };
};

const searchAttendeesGroupsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        searchAttendeesGroupsSuccess: false,
        error: false,
        attendeesGroups: [],
        message: null,
        searchAttendeesOfGroupSuccess: false,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        listAssignedAttendeesSuccess: false,
        assignAttendeesSuccess: false,
    };
};

const searchAttendeesGroupsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        searchAttendeesGroupsSuccess: true,
        error: false,
        attendeesGroups: action.data,
        message: action.message,
        searchAttendeesOfGroupSuccess: false,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        listAssignedAttendeesSuccess: false,
        assignAttendeesSuccess: false,
    };
};

const searchAttendeesGroupsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        searchAttendeesGroupsSuccess: false,
        error: true,
        attendeesGroups: [],
        message: action.error,
        searchAttendeesOfGroupSuccess: false,
        getAttendeesGroupsSuccess: false,
        getAttendeesSuccess: false,
        getCustomFieldSuccess: false,
        listUnassignedAttendeesSuccess: false,
        listAssignedAttendeesSuccess: false,
        assignAttendeesSuccess: false,
    };
};

const importAttendeesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        importAttendeesSuccess: false,
        importAttendeesValidation: false,
        importAttendeesError: false,
        duplicateEmailsInCsv: 0,
        invalidEmailsRejection: 0,
        alreadyExistInDb: 0,
        groupNotExists: 0,
        rejectedEmails: 0,
        error: false,
        message: null,
        uniqueAttendees: 0,
        importAttendeesId: 0
    };
};

const importAttendeesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        importAttendeesSuccess: true,
        error: false,
        message: action.message,
        uniqueAttendees: action.uniqueAttendees,
        importAttendeesId: action?.importAttendeesId
    };
};

const importAttendeesValidation = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        importAttendeesValidation: true,
        error: false,
        message: action.message,
        duplicateEmailsInCsv: action.duplicateEmailsInCsv,
        invalidEmailsRejection: action.invalidEmailsRejection,
        alreadyExistInDb: action.alreadyExistInDb,
        groupNotExists: action.groupNotExists,
        rejectedEmails: action.rejectedEmails,
    };
};

const importAttendeesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        importAttendeesError: true,
        error: true,
        message: action.error,
        importAttendeesId: 0
    };
};

const listBadgesTemplateRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listBadgesTemplateSuccess: false,
        error: false,
        message: null,
        badgesTemplates: []
    };
};


const listBadgesTemplateSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listBadgesTemplateSuccess: true,
        error: false,
        message: action.message,
        badgesTemplates: action.templates,
    };
};


const listBadgesTemplateError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listBadgesTemplateSuccess: false,
        error: true,
        message: action.message,
        badgesTemplates: []
    };
};

const listAllBadgesTemplateRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listAllBadgesTemplateSuccess: false,
        error: false,
        message: null,
        allBadgesTemplates: []
    };
};

const listAllBadgesTemplateSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listAllBadgesTemplateSuccess: true,
        error: false,
        message: action.message,
        allBadgesTemplates: action.templates,
    };
};

const listAllBadgesTemplateError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listAllBadgesTemplateSuccess: false,
        error: true,
        message: action.message,
        allBadgesTemplates: []
    };
};

const listTransactionalEmailTemplatesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listTransactionalEmailTemplatesSuccess: false,
        error: false,
        message: null,
        transactionalEmailTemplates: []
    };
};


const listTransactionalEmailTemplatesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listTransactionalEmailTemplatesSuccess: true,
        error: false,
        message: action.message,
        transactionalEmailTemplates: action.templates,
    };
};

const listTransactionalEmailTemplatesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listTransactionalEmailTemplatesSuccess: false,
        error: true,
        message: action.message,
        transactionalEmailTemplates: []
    };
};

const listAllTransactionalEmailTemplatesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listAllTransactionalEmailTemplatesSuccess: false,
        error: false,
        message: null,
        allTransactionalEmailTemplates: []
    };
};
const listAllTransactionalEmailTemplatesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listAllTransactionalEmailTemplatesSuccess: true,
        error: false,
        message: action.message,
        allTransactionalEmailTemplates: action.templates,
    };
};

const listAllTransactionalEmailTemplatesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listAllTransactionalEmailTemplatesSuccess: false,
        error: true,
        message: action.message,
        allTransactionalEmailTemplates: []
    };
};

const getAttendeesChartDataRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getAttendeesChartDataSuccess: false,
        error: false,
        message: null,
        attendeesChartData: []
    };
};
const getAttendeesChartDataSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getAttendeesChartDataSuccess: true,
        error: false,
        message: action.message,
        attendeesChartData: action.data
    };
};

const getAttendeesChartDataError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getAttendeesChartDataSuccess: false,
        error: true,
        message: action.message,
        attendeesChartData: []
    };
};

const viewTransactionalReport = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isReportComp: action.data
    };
};

const HANDLERS = {
    [Constants.UN_USED_ACCESS_CODE_REQUEST]: unUsedAccessCodeRequest,
    [Constants.UN_USED_ACCESS_CODE_SUCCESS]: unUsedAccessCodeSuccess,
    [Constants.UN_USED_ACCESS_CODE_ERROR]: unUsedAccessCodeError,
    [Constants.ASSIGN_ACCESS_CODE_REQUEST]: assignAccessCodeRequest,
    [Constants.ASSIGN_ACCESS_CODE_SUCCESS]: assignAccessCodeSuccess,
    [Constants.ASSIGN_ACCESS_CODE_ERROR]: assignAccessCodeError,
    [Constants.CUSTOM_FIELDS_REQUEST]: customFieldsRequest,
    [Constants.CUSTOM_FIELDS_ERROR]: customFieldsError,
    [Constants.CUSTOM_FIELDS_SUCCESS]: customFieldsSuccess,
    [Constants.ADD_CUSTOM_FIELDS_REQUEST]: addCustomFieldsRequest,
    [Constants.ADD_CUSTOM_FIELDS_ERROR]: addCustomFieldsError,
    [Constants.ADD_CUSTOM_FIELDS_SUCCESS]: addCustomFieldsSuccess,
    [Constants.DELETE_CUSTOM_FIELDS_REQUEST]: deleteCustomFieldsRequest,
    [Constants.DELETE_CUSTOM_FIELDS_SUCCESS]: deleteCustomFieldsSuccess,
    [Constants.DELETE_CUSTOM_FIELDS_ERROR]: deleteCustomFieldsError,
    [Constants.UPDATE_CUSTOM_FIELD_REQUEST]: updateCustomFieldRequest,
    [Constants.UPDATE_CUSTOM_FIELD_ERROR]: updateCustomFieldError,
    [Constants.UPDATE_CUSTOM_FIELD_SUCCESS]: updateCustomFieldSuccess,
    [Constants.LIST_ATTENDEES_REQUEST]: listAttendeesRequest,
    [Constants.LIST_ATTENDEES_SUCCESS]: listAttendeesSuccess,
    [Constants.LIST_ATTENDEES_ERROR]: listAttendeesError,
    [Constants.ADD_ATTENDEES_REQUEST]: addAttendeesRequest,
    [Constants.ADD_ATTENDEES_SUCCESS]: addAttendeesSuccess,
    [Constants.ADD_ATTENDEES_ERROR]: addAttendeesError,
    [Constants.DELETE_ATTENDEES_REQUEST]: deleteAttendeesRequest,
    [Constants.DELETE_ATTENDEES_SUCCESS]: deleteAttendeesSuccess,
    [Constants.DELETE_ATTENDEES_ERROR]: deleteAttendeesError,
    [Constants.EDIT_ATTENDEE_REQUEST]: editAttendeeRequest,
    [Constants.EDIT_ATTENDEE_SUCCESS]: editAttendeeSuccess,
    [Constants.EDIT_ATTENDEE_ERROR]: editAttendeeError,
    [Constants.CHECKED_IN_HISTORY_ATTENDEE_REQUEST]: checkedInHistoryAttendeeRequest,
    [Constants.CHECKED_IN_HISTORY_ATTENDEE_SUCCESS]: checkedInHistoryAttendeeSuccess,
    [Constants.CHECKED_IN_HISTORY_ATTENDEE_ERROR]: checkedInHistoryAttendeeError,
    [Constants.SEARCH_ATTENDEES_REQUEST]: searchAttendeesRequest,
    [Constants.SEARCH_ATTENDEES_SUCCESS]: searchAttendeesSuccess,
    [Constants.SEARCH_ATTENDEES_ERROR]: searchAttendeesError,
    [Constants.SORT_FILTER_ATTENDEES_REQUEST]: sortFilterAttendeesRequest,
    [Constants.SORT_FILTER_ATTENDEES_SUCCESS]: sortFilterAttendeesSuccess,
    [Constants.SORT_FILTER_ATTENDEES_ERROR]: sortFilterAttendeesError,
    [Constants.ENABLED_STATUS_REQUEST]: enabledStatusRequest,
    [Constants.ENABLED_STATUS_SUCCESS]: enabledStatusSuccess,
    [Constants.ENABLED_STATUS_ERROR]: enabledStatusError,
    [Constants.ACTION_ASSIGN_REQUEST]: actionAssignRequest,
    [Constants.ACTION_ASSIGN_SUCCESS]: actionAssignSuccess,
    [Constants.ACTION_ASSIGN_ERROR]: actionAssignError,
    [Constants.ACTION_CHECKIN_REQUEST]: actionCheckinRequest,
    [Constants.ACTION_CHECKIN_SUCCESS]: actionCheckinSuccess,
    [Constants.ACTION_CHECKIN_ERROR]: actionCheckinError,
    [Constants.LIST_ATTENDEES_GROUPS_REQUEST]: listAttendeesGroupsRequest,
    [Constants.LIST_ATTENDEES_GROUPS_SUCCESS]: listAttendeesGroupsSuccess,
    [Constants.LIST_ATTENDEES_GROUPS_ERROR]: listAttendeesGroupsError,
    [Constants.ADD_ATTENDEES_GROUPS_REQUEST]: addAttendeesGroupsRequest,
    [Constants.ADD_ATTENDEES_GROUPS_SUCCESS]: addAttendeesGroupsSuccess,
    [Constants.ADD_ATTENDEES_GROUPS_ERROR]: addAttendeesGroupsError,
    [Constants.DELETE_ATTENDEES_GROUPS_REQUEST]: deleteAttendeesGroupsRequest,
    [Constants.DELETE_ATTENDEES_GROUPS_SUCCESS]: deleteAttendeesGroupsSuccess,
    [Constants.DELETE_ATTENDEES_GROUPS_ERROR]: deleteAttendeesGroupsError,
    [Constants.SORT_ATTENDEES_REQUEST]: sortAttendeesRequest,
    [Constants.SORT_ATTENDEES_SUCCESS]: sortAttendeesSuccess,
    [Constants.SORT_ATTENDEES_ERROR]: sortAttendeesError,
    [Constants.LIST_UNASSIGNED_ATTENDEES_REQUEST]: listUnassignedAttendeesRequest,
    [Constants.LIST_UNASSIGNED_ATTENDEES_SUCCESS]: listUnassignedAttendeesSuccess,
    [Constants.LIST_UNASSIGNED_ATTENDEES_ERROR]: listUnassignedAttendeesError,
    [Constants.LIST_ASSIGNED_ATTENDEES_REQUEST]: listAssignedAttendeesRequest,
    [Constants.LIST_ASSIGNED_ATTENDEES_SUCCESS]: listAssignedAttendeesSuccess,
    [Constants.LIST_ASSIGNED_ATTENDEES_ERROR]: listAssignedAttendeesError,
    [Constants.UNASSIGN_ATTENDEES_REQUEST]: unassignAttendeesRequest,
    [Constants.UNASSIGN_ATTENDEES_SUCCESS]: unassignAttendeesSuccess,
    [Constants.UNASSIGN_ATTENDEES_ERROR]: unassignAttendeesError,
    [Constants.ASSIGN_ATTENDEES_REQUEST]: assignAttendeesRequest,
    [Constants.ASSIGN_ATTENDEES_SUCCESS]: assignAttendeesSuccess,
    [Constants.ASSIGN_ATTENDEES_ERROR]: assignAttendeesError,
    [Constants.SEARCH_ATTENDEES_OF_GROUP_REQUEST]: searchAttendeesOfGroupRequest,
    [Constants.SEARCH_ATTENDEES_OF_GROUP_SUCCESS]: searchAttendeesOfGroupSuccess,
    [Constants.SEARCH_ATTENDEES_OF_GROUP_ERROR]: searchAttendeesOfGroupError,
    [Constants.SEARCH_ATTENDEES_OF_UNASSIGN_GROUP_REQUEST]: searchAttendeesOfUnassignGroupRequest,
    [Constants.SEARCH_ATTENDEES_OF_UNASSIGN_GROUP_SUCCESS]: searchAttendeesOfUnassignGroupSuccess,
    [Constants.SEARCH_ATTENDEES_OF_UNASSIGN_GROUP_ERROR]: searchAttendeesOfUnassignGroupError,
    [Constants.SEARCH_ATTENDEES_GROUPS_REQUEST]: searchAttendeesGroupsRequest,
    [Constants.SEARCH_ATTENDEES_GROUPS_SUCCESS]: searchAttendeesGroupsSuccess,
    [Constants.SEARCH_ATTENDEES_GROUPS_ERROR]: searchAttendeesGroupsError,
    [Constants.EDIT_ATTENDEES_GROUP_NAME_REQUEST]: editAttendeesGroupNameRequest,
    [Constants.EDIT_ATTENDEES_GROUP_NAME_SUCCESS]: editAttendeesGroupNameSuccess,
    [Constants.EDIT_ATTENDEES_GROUP_NAME_ERROR]: editAttendeesGroupNameError,
    [Constants.DOWNLOAD_CSV_REQUEST]: downloadCsvRequest,
    [Constants.DOWNLOAD_CSV_SUCCESS]: downloadCsvSuccess,
    [Constants.DOWNLOAD_CSV_ERROR]: downloadCsvError,
    [Constants.EXPORT_ATTENDEES_REQUEST]: exportAttendeesRequest,
    [Constants.EXPORT_ATTENDEES_SUCCESS]: exportAttendeesSuccess,
    [Constants.EXPORT_ATTENDEES_ERROR]: exportAttendeesError,
    [Constants.EXPORT_CHECK_IN_HISTORY_REQUEST]: exportCheckInHistoryRequest,
    [Constants.EXPORT_CHECK_IN_HISTORY_SUCCESS]: exportCheckInHistorySuccess,
    [Constants.EXPORT_CHECK_IN_HISTORY_ERROR]: exportCheckInHistoryError,
    [Constants.IMPORT_ATTENDEES_REQUEST]: importAttendeesRequest,
    [Constants.IMPORT_ATTENDEES_SUCCESS]: importAttendeesSuccess,
    [Constants.IMPORT_ATTENDEES_VALIDATION]: importAttendeesValidation,
    [Constants.IMPORT_ATTENDEES_ERROR]: importAttendeesError,

    [Constants.LIST_TRANSACTIONAL_EMAIL_TEMPLATES_REQUEST]: listTransactionalEmailTemplatesRequest,
    [Constants.LIST_TRANSACTIONAL_EMAIL_TEMPLATES_SUCCESS]: listTransactionalEmailTemplatesSuccess,
    [Constants.LIST_TRANSACTIONAL_EMAIL_TEMPLATES_ERROR]: listTransactionalEmailTemplatesError,

    [Constants.LIST_ALL_TRANSACTIONAL_EMAIL_TEMPLATES_REQUEST]: listAllTransactionalEmailTemplatesRequest,
    [Constants.LIST_ALL_TRANSACTIONAL_EMAIL_TEMPLATES_SUCCESS]: listAllTransactionalEmailTemplatesSuccess,
    [Constants.LIST_ALL_TRANSACTIONAL_EMAIL_TEMPLATES_ERROR]: listAllTransactionalEmailTemplatesError,

    [Constants.LIST_BADGES_TEMPLATE_REQUEST]: listBadgesTemplateRequest,
    [Constants.LIST_BADGES_TEMPLATE_SUCCESS]: listBadgesTemplateSuccess,
    [Constants.LIST_BADGES_TEMPLATE_ERROR]: listBadgesTemplateError,
    // [Constants.LIST_ALL_BADGES_TEMPLATE_REQUEST]: listAllBadgesTemplateRequest,
    // [Constants.LIST_ALL_BADGES_TEMPLATE_SUCCESS]: listAllBadgesTemplateSuccess,
    // [Constants.LIST_BADGES_TEMPLATES_ERROR]: listAllBadgesTemplateError,


    [Constants.GET_ATTENDEES_CHART_DATA_REQUEST]: getAttendeesChartDataRequest,
    [Constants.GET_ATTENDEES_CHART_DATA_SUCCESS]: getAttendeesChartDataSuccess,
    [Constants.GET_ATTENDEES_CHART_DATA_ERROR]: getAttendeesChartDataError,
    [Constants.VIEW_TRANSACTIONAL_REPORT]: viewTransactionalReport,
};

export default createReducer(INITIAL_STATE, HANDLERS);