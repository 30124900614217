import React, { Component } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../../../theme/theme'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../../Redux/Actions'
import Button from '@material-ui/core/Button'
import '../../../sass/main.scss'
import { Modal } from 'react-responsive-modal'
import { Stack } from '@mui/material'
import Grid from '@mui/material/Grid'
import Box from '@material-ui/core/Box'
import Loader from '../../../Components/Loader/loader'
import { ACCESS_CODE_EXPERING_PERIOD } from '../../../constants/constants'
import { createNotification } from '../../../helpers'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@material-ui/core'
import moment from 'moment'
import accessTimeIcon from '../../../assets/images/icons/access-time.svg'
import analytics from 'Pages/Analytics/analytics'

class AccessCode extends Component {
  constructor(props) {
    super(props)
    this.checkBoxIdsArr = []
    this.state = {
      loading: false,
      accessCodeList: [],
      remainingHours: '',
      accessCode: '',
      attendeesEmail: '',
      addAccessCode: false,
      errors: '',
    }
  }

  componentDidMount = () => {
    this.setState(
      {
        attendeeId: this.props?.attendeeId,
        projectId: this.props?.projectId,
        attendeesEmail: this.props?.attendeeEmail,
      },
      () => this.getAccessCode(),
    )
  }

  componentDidUpdate = (prevProps, prevState) => {

    if (this.props.profile.getAccessCodeSuccess === true && this.props.profile.getAccessCodeSuccess !==
      prevProps.profile.getAccessCodeSuccess && (this.props.profile.remainingHours === "Expired1" || this.props.profile.remainingHours === "Disabled1" || this.props.profile.remainingHours === "No access code")) {
      // need to stop show toster for now might be need in futur
      // createNotification('error', this.props.profile.message)
    }

    if (
      this.props.profile.getAccessCodeSuccess !==
        prevProps.profile.getAccessCodeSuccess &&
      this.props.profile.getAccessCodeSuccess === true
    ) {
      this.setState({
        accessCodeList: this.props.profile.accessCode,
        remainingHours: this.props.profile.remainingHours,
        loading: false,
      })
    }

    if (
      this.props.profile.assignAccessCodeProfileSuccess !==
        prevProps.profile.assignAccessCodeProfileSuccess &&
      this.props.profile.assignAccessCodeProfileSuccess === true
    ) {
      createNotification('success', this.props.profile.message)
      this.setState(
        {
          loading: false,
          addAccessCode: false,
          accessCode: '',
          errors: '',
          remainingHours: '',
        },
        () => this.getAccessCode(),
      )
    }

    if (
      this.props.profile.error !== prevProps.profile.error &&
      this.props.profile.error === true
    ) {
      createNotification('error', this.props.profile.message)
      this.setState({
        loading: false,
      })
    }
  }

  getAccessCode = () => {
    const { projectId, attendeeId } = this.state
    const body = {
      projectId,
      attendeeId,
    }
    this.props.getAccessCode(body)
    this.handleLoading()
  }

  isValid = () => {
    try {
      const { accessCode } = this.state
      let formIsValid = true
      let error = {}

      if (
        accessCode.trim().length === 0 ||
        accessCode === '' ||
        accessCode === undefined
      ) {
        formIsValid = false
        error['accessCode'] = 'Enter access code'
      }
      this.setState({
        errors: error,
      })
      return formIsValid
    } catch (error) {
      createNotification('error', 'something went wrong')
    }
  }

  assignAccessCodeProfile = () => {
    if (this.isValid()) {
      const { attendeesEmail, accessCode, projectId, attendeeId } = this.state
      const body = {
        attendeesEmail,
        accessCode,
        projectId,
        attendeeId,
      }
      this.props.assignAccessCodeProfile(body)
      this.handleLoading()
    }
  }

  copyAccessCode = (copyText, id, length) => {
    navigator.clipboard.writeText(copyText)
    for (let key in this.state) {
      if (key.includes('copyText')) {
        if (key !== `copyText${id}`) {
          this.setState({
            [key]: false,
          })
        }
      }
    }
    this.setState({
      [`copyText${id}`]: true,
    })
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  addAccessCode = () => {
    this.setState({
      addAccessCode: true,
    })
  }

  closeAddAccessCode = () => {
    this.setState({
      addAccessCode: false,
      accessCode: '',
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    const { accessCodeList, remainingHours } = this.state
    return (
      <ThemeProvider theme={theme}>
        <Box className="access-code" px={1} py={0.8}>
          <Box>
            <Grid container justifyContent={'center'} spacing={3}>
              <Grid item xs={12} md>
                <Box
                  p={1}
                  className=""
                  sx={{
                    border: '1px solid #9A92FF',
                    borderRadius: '8px',
                  }}
                >
                  <Grid
                    container
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    direction="row"
                    spacing={2}
                  >
                    <Grid item>
                      <Stack direction="row" alignItems="center" spacing={1.5}>
                        <Box component={'img'} alt="" src={accessTimeIcon} />

                        <Stack className="">
                          <Box className="subtitle">REMAINING ACCESS TIME</Box>
                          <Box className="paragraph secondary-text cap">
                            {remainingHours ? remainingHours : ''}
                          </Box>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item>
                      {remainingHours !== 'Lifetime' ? (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.addAccessCode}
                        >
                          Add New Code
                        </Button>
                      ) : (
                        <Tooltip
                          title="Attendee have lifetime access code"
                          placement="bottom"
                          arrow
                        >
                          <Button style={{color: '#00000042', backgroundColor: '#d5d5d5'}} disableRipple variant="contained">
                            Add New Code
                          </Button>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <TableContainer className="list-table">
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="long-text sticky-cell"
                      style={{ left: '0px', backgroundColor: '#ffffff' }}
                    >
                      Access Code
                    </TableCell>
                    <TableCell
                      className="sticky-cell copy"
                      style={{ left: '250px', backgroundColor: '#ffffff' }}
                    ></TableCell>
                    <TableCell className="status">Status</TableCell>
                    <TableCell className="date-time">Assigned on</TableCell>
                    <TableCell className="short-text">
                      Expiring Period
                    </TableCell>
                  </TableRow>
                </TableHead>
                {Array.isArray(accessCodeList) && accessCodeList?.length > 0 ? (
                  <>
                    <TableBody>
                      {Array.isArray(accessCodeList) &&
                        accessCodeList.length &&
                        accessCodeList.map((accessCode, i) => {
                          return (
                            <TableRow>
                              <TableCell
                                className="long-text sticky-cell"
                                style={{
                                  left: '0px',
                                  backgroundColor: '#ffffff',
                                }}
                              >
                                {accessCode.accessCode
                                  ? accessCode.accessCode
                                  : ''}{' '}
                              </TableCell>
                              <TableCell
                                className="sticky-cell secondary-text copy"
                                style={{
                                  left: '250px',
                                  backgroundColor: '#ffffff',
                                }}
                                onClick={() =>
                                  this.copyAccessCode(
                                    accessCode.accessCode,
                                    accessCode._id,
                                    accessCodeList.length,
                                  )
                                }
                              >
                                <Button
                                  className="copy"
                                  variant="text"
                                  style={{
                                    padding: '0',
                                    textTransform: 'capitalize',
                                    color: '#5141E7',
                                    fontSize:
                                      this.state[`copyText${accessCode._id}`] &&
                                      '14px',
                                  }}
                                >
                                  {' '}
                                  {this.state[`copyText${accessCode._id}`]
                                    ? 'Copied'
                                    : 'Copy'}{' '}
                                </Button>
                              </TableCell>

                              <TableCell className="status">
                                <span
                                  className={
                                    accessCode.status === 'expired'
                                      ? 'table-status light'
                                      : accessCode.status === 'disabled'
                                      ? 'table-status danger'
                                      : accessCode.status === 'assigned'
                                      ? 'table-status warning'
                                      : accessCode.status === 'active'
                                      ? 'table-status success'
                                      : 'table-status primary'
                                  }
                                >
                                  {accessCode.status ? accessCode.status : ''}{' '}
                                </span>
                              </TableCell>

                              <TableCell className="date-time">
                                {accessCode.assignedOn
                                  ? moment(accessCode.assignedOn).format(
                                      'ddd, MMM, Do YYYY, h:mm A',
                                    )
                                  : ''}{' '}
                              </TableCell>

                              <TableCell className="short-text">
                                {accessCode.expringPeriod &&
                                accessCode.expringPeriod ===
                                  ACCESS_CODE_EXPERING_PERIOD
                                  ? 'Lifetime'
                                  : accessCode.expringPeriod}{' '}
                                {accessCode.expringPeriod &&
                                accessCode.expringPeriod !==
                                  ACCESS_CODE_EXPERING_PERIOD
                                  ? 'Hours'
                                  : ''}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }}>
                        There are no records to display
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Modal
          open={this.state.addAccessCode}
          onClose={this.closeAddAccessCode}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid item xs={12} pb={4}>
              <Box component="p" mb={0} className="subtitle">
                Add Access Code
              </Box>
              <Box className='small-subtitle'>
              Access code is necessary to access the event page
              </Box>
            </Grid>
            <Grid item xs={12} pb={4}>
              <Box component="div" className="inputField">
                <label>
                  Access Code <span className="required">*</span>
                </label>
                <input
                  type="text"
                  class="form-control small"
                  value={this.state.accessCode}
                  placeholder="Paste or enter access code here"
                  name="accessCode"
                  onChange={this.handleChange}
                />
                <span className="error_mesage required">
                  {' '}
                  {this.state.errors?.accessCode}{' '}
                </span>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.assignAccessCodeProfile}
                  >
                    ADD
                  </Button>
                  <Button variant="outlined" onClick={this.closeAddAccessCode}>
                    Cancel
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        {this.state.loading ? <Loader /> : null}
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  project: state.Projects,
  profile: state.Profile,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getAccessCode: Actions.getAccessCodeRequest,
      assignAccessCodeProfile: Actions.assignAccessCodeProfileRequest,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withRouter(AccessCode))
