import Stack from '@mui/material/Stack';
import { Box, Grid } from '@mui/material';
import AddFile from '../../../../../src/assets/images/icons/attach_file_add.svg';
import { ChangeEvent, useEffect, useState, useRef } from 'react';
import {
  OpportunityCommon_State,
  CommentsAndAttachments as CommentsAndAttachmentsType,
  Attachment,
  CommentSection,
  ViewType,
} from 'Redux/Reducers/opportunity/opportunityCommon.reducer';
import { Actions } from 'Redux/Actions';
import { useDispatch, useSelector } from 'react-redux';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { bytesToSize, downloadFile } from 'helpers/common';
import { Url } from 'constants/constants';
import { LinearProgress } from '@material-ui/core';
import { XpUsersWebSocket_State } from 'Redux/Reducers/xpUsersWebSocket.reducer';

type Props = {
  id: string | undefined;
  type: ViewType;
  commentsAndAttachments: CommentsAndAttachmentsType[];
  getIcon: (fileType: string, fileExtension: string) => string;
  getIconForNewAttachment: (type: string) => string;
  openDeleteAttachmentConfirmPopup: (
    commentId: string,
    attachmentId: string,
  ) => void;
};

function Attachments({
  id,
  type,
  commentsAndAttachments,
  getIcon,
  getIconForNewAttachment,
  openDeleteAttachmentConfirmPopup,
}: Props) {
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const [newAttachments, setNewAttachments] = useState<Attachment[]>([]);
  const [progressMap, setProgressMap] = useState({});

  const { userDetails: loggedInUser } = useSelector(
    (state: any) => state.Users,
  );

  const {    
    createCommentAndAttachmentSuccess
  } = useSelector(
    (state: any) => state.OpportunityCommon,
  ) as OpportunityCommon_State;

  const { message: xpUsersWebSocketMessage } = useSelector(
    (state: any) => state.XpUsersWebSocket,
  ) as XpUsersWebSocket_State;

  const uploadAttachment = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleFilesUpload = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      if (id) {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('type', type);

        let allFiles = [...e.target.files];
        allFiles.map((file: File) => {
          formData.append('attachments', file);
        });

        setNewAttachments(allFiles);

        // dispatch(Actions.createCommentAndAttachmentRequest(formData));
        dispatch(Actions.uploadAttachmentToCloudRequest(formData));
      }
    }
  };

  useEffect(() => {
    if (
      xpUsersWebSocketMessage.message == `opp_${type}_uploading_attachment` &&
      xpUsersWebSocketMessage.type == type &&
      xpUsersWebSocketMessage.id == id
    ) {
      if (
        xpUsersWebSocketMessage.progress >
        (progressMap[xpUsersWebSocketMessage.fileId] || 0)
      ) {
        setProgressMap((prev) => {
          return {
            ...prev,
            [xpUsersWebSocketMessage.fileId]: xpUsersWebSocketMessage.progress,
          };
        });
      }
    }

    if (
      xpUsersWebSocketMessage.message == `opp_${type}_attachments_uploaded` &&
      xpUsersWebSocketMessage.type == type &&
      xpUsersWebSocketMessage.id == id
    ) {
      dispatch(
        Actions.createCommentAndAttachmentRequest({
          type,
          id,
          attachments: xpUsersWebSocketMessage.uploadedFiles,
        }),
      );
      dispatch(Actions.setXpUsersWebSocketConnectionMessage({}));
    }
  }, [xpUsersWebSocketMessage]);

  useEffect(() => {
    if (createCommentAndAttachmentSuccess) {
      setNewAttachments([]);
      setProgressMap({});
    }
  }, [createCommentAndAttachmentSuccess]);

  return (
    <Box p={2} sx={{ overflow: 'auto' }}>
      <Grid container spacing={0.5} className="attachments">
        <Grid item xs={12}>
          <Box className="attachment-file upload" onClick={uploadAttachment}>
            <Box display={'flex'} alignItems={'center'}>
              <Box className="file-icon" p={1}>
                <img src={AddFile} alt="add-file" />
              </Box>
              <Box>
                <Box className="file-name secondary-text">Add New File</Box>
                <Box className="file-size">10 Mb Max.</Box>
              </Box>
            </Box>
            <input
              type="file"
              id="file"
              multiple
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={(e) => handleFilesUpload(e)}
            />
          </Box>
        </Grid>
        {commentsAndAttachments.length > 0 &&
          commentsAndAttachments.map((data: CommentsAndAttachmentsType) => (
            <>
              {Array.isArray(data?.attachments) &&
                data.attachments.length > 0 &&
                data.attachments.map((attachment: Attachment) => (
                  <Grid item xs={12}>
                    <Box className="attachment-file">
                      <Box display={'flex'} alignItems={'center'}>
                        <Box className="file-icon">
                          <img
                            src={getIcon(
                              attachment.fileType,
                              attachment.fileExtension,
                            )}
                            alt="file-icon"
                          />
                        </Box>
                        <Box>
                          <Box className="file-name">{attachment.fileName}</Box>
                          <Box className="file-size">{attachment.fileSize}</Box>
                        </Box>
                      </Box>
                      <Box className="options">
                        <MoreVertIcon
                          style={{ fontSize: '18px' }}
                          className="options-button"
                        />
                        <div className="options-list">
                          <div
                            onClick={() =>
                              downloadFile(
                                `${Url.opportunityAttachmentUrl}/${attachment.fileNameInS3}`,
                                attachment.fileName,
                              )
                            }
                          >
                            Download
                          </div>
                          {loggedInUser._id == data.userData._id && (
                            <div
                              style={{ color: '#FF4170' }}
                              onClick={() =>
                                openDeleteAttachmentConfirmPopup(
                                  data._id,
                                  attachment._id,
                                )
                              }
                            >
                              Delete
                            </div>
                          )}
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                ))}
            </>
          ))}
        {newAttachments.length > 0 &&
          newAttachments.map((attachment, i) => (
            <Grid item xs={12}>
              <Box className="attachment-file">
                <Box display={'flex'} alignItems={'center'}>
                  <Box className="file-icon">
                    <img
                      src={getIconForNewAttachment(attachment.type)}
                      alt="file-icon"
                    />
                  </Box>
                  <Box>
                    <Box className="file-name">{attachment.name}</Box>
                    <Box className="file-size">
                      {bytesToSize(attachment.size)}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <LinearProgress
                  style={{ width: '100%' }}
                  variant="determinate"
                  value={progressMap[attachment.name] | 0}
                />
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

export default Attachments;
