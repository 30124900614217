import React, { Component } from 'react';
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from '../../../theme/theme'; 
import { withRouter } from 'react-router'
import axios from 'axios';
import { createNotification } from '../../../helpers';
import ReactHtmlParser from 'react-html-parser';
import Loader from '../../../Components/Loader/loader'
import { permissions } from '../../../constants/constants'
import { UserData } from 'helpers/common';
import { getMeetingTemplateAPI } from 'Redux/API';

// API URL from environment variables
const URL = process.env.REACT_APP_API_URL  
 /**
 * @class MeetingEmailPreviewForReport
 * @extends Component
 * @description Renders a component to preview meeting emails for reports.
 */
class MeetingEmailPreviewForReport extends Component {
    /**
     * Constructor for MeetingEmailPreviewForReport component.
     * @constructor
     * @param {Object} props - The properties passed to the component.
     */
    constructor(props) {
        super(props) 
        this.state = {
            html: ""
        }
    }  

    /**
     * Lifecycle method called after the component mounts.
     * Fetches meeting email preview based on provided parameters.
     * @memberof MeetingEmailPreviewForReport
     * @method componentDidMount
     */
    componentDidMount() { 
        let eventId = this.props.match.params.eventId;
        let email = this.props.match.params.email;
        let params = this.props.location;
        let meetingId = new URLSearchParams(params.search).get("meetingId")  
        this.getMeetingEmailPerview(eventId, meetingId,email); 
    } 

     /**
     * Fetches meeting email preview using the API.
     * @memberof MeetingEmailPreviewForReport
     * @method getMeetingEmailPreview
     * @param {string} eventId - The event ID.
     * @param {string} meetingId - The meeting ID.
     * @param {string} email - The email for which the preview is generated.
     */
    getMeetingEmailPerview(eventId, meetingId,email){
        try {
            let params = {
                meetingType: 'Project',
                meetingId: meetingId,
                eventId: eventId,
                email : email
            };
            getMeetingTemplateAPI(params)
            .then((data) => {
                if (data.data.status == 200) {
                    this.setState({
                        html: data.data.meetingTemplate[0]
                    });
                }
            })
        } catch (err) {
            createNotification("error", err.message);
        }
    }  

    /**
     * Renders the MeetingEmailPreviewForReport component.
     * @memberof MeetingEmailPreviewForReport
     * @method render
     * @returns {JSX.Element} - The JSX element representing the component.
     */
    render() {
        const { html } = this.state; 
        return (  
            <ThemeProvider theme={theme}>
               {html ?  
                    <div style={{pointerEvents:'none'}}>
                        {ReactHtmlParser(html)}
                    </div>
                :
                    <Loader />
               }
            </ThemeProvider>
        )
    }
}

export default withRouter(MeetingEmailPreviewForReport);

