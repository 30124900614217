const getUnLayerEmailConfig = (
  apiUrl = '',
  eventId = '',
  customToolFileds = [],
  formTemplates = [],
  qrCodeTypeDynamic = [{}],
  qrCodeSessionOption = [{}],
  qrCodeSizeDropdown = [{ 'label': "200X100", 'value': 200 }], 
  customFiledsForAudience =[],
  qrCodeAttendeeDynamic = [{ 'label': "Choose One", 'value': "" }, { 'label': "Email", 'value': "{{{attendeeEmail}}}" }, { 'label': "Group", 'value': "{{{attendeeGroup}}}" }],
  sessionDynamic = [{ 'label': "Choose One", 'value': "" }],
  customFieldDynamic = {},
  audienceDynamicCustomField= {},
  pageId = '',
  eventStartDate = "",
  cssUnlyaerPlayground = "",
) => {
  customFieldDynamic['email'] = {
    name: "Email",
    value: "{{{attendeeEmail}}}"
  }
  customFieldDynamic['image'] = {
    name: "Image",
    value: "{{{attendeeImage}}}"
  }
  customFieldDynamic['group'] = {
    name: "Group",
    value: "{{{attendeeGroup}}}"
  }
  if (customToolFileds && customToolFileds.length > 0) {
    for (var x = 0; x < customToolFileds.length; x++) {
      customFieldDynamic[customToolFileds[x]?.fieldData?.fieldName] = {
        name: customToolFileds[x].fieldData?.fieldLabel,
        value: "{{{" + customToolFileds[x]?.fieldData?.fieldName + "}}}"
      }
      qrCodeAttendeeDynamic.push({ 'label': customToolFileds[x].fieldData?.fieldLabel, 'value': "{{{" + customToolFileds[x].fieldData.fieldName + "}}}" })
    }
  }

  audienceDynamicCustomField['email'] = {
    name: "Email",
    value: "{{{contactEmail}}}"
  }

  // custom fields for audience contact
  if (customFiledsForAudience && customFiledsForAudience.length > 0) {
    for (var x = 0; x < customFiledsForAudience.length; x++) {
      audienceDynamicCustomField[customFiledsForAudience[x]?.fieldData?.fieldName] = {
        name: customFiledsForAudience[x].fieldData?.fieldLabel,
        value: "{{{" + customFiledsForAudience[x]?.fieldData?.fieldName + "}}}"
      }
    }
  }

  return {
    id: 'emailEditor',
    projectId: 21377,
    displayMode: 'web',
    mergeTags: {
      session: {
        name: "Session",
        mergeTags: {
          title: {
            name: "Title",
            value: "{{{sessionTitle}}}"
          },
          date: {
            name: "Date",
            value: "{{{sessionDate}}}"
          },
          duration: {
            name: "Duration",
            value: "{{{sessionDuration}}}"
          },
          location: {
            name: "Location",
            value: "{{{sessionLocation}}}"
          },
          navigateTo: {
            name: "Navigate to",
            value: "{{{sessionNavigateTo}}}"
          },
          description: {
            name: "Description",
            value: "{{{sessionDescription}}}"
          },
          tracks: {
            name: "Tracks",
            value: "{{{sessionTracks}}}"
          },
          image: {
            name: "Image",
            value: "{{{sessionImage}}}"
          },
          speakerName: {
            name: "Speakers Block",
            value: "{{{speakersBlock}}}"
          },
          sessionStartTime: {
            name: "Session Start Time",
            value: "{{{sessionStartTime}}}"
          },
          sessionEndTime: {
            name: "Session End Time",
            value: "{{{sessionEndTime}}}"
          },
          sessionAddToCalendar: {
            name: "Add to calendar",
            value: "{{{sessionAddToCalendar}}}" 
          },
        }
      },
      audienceName: {
        name: "Audience Contact",
        mergeTags: audienceDynamicCustomField,
      },
      customeField: {
        name: "Attendee",
        mergeTags: customFieldDynamic,
      },
      sessions: {
        name: 'Sessions',
        rules: {
          repeat: {
            name: 'Repeat for Each Sessions',
            before: '{{#sessions}}',
            after: '{{/sessions}}',
          },
        },
      },
      sessionsGroupByDate: {
        name: 'Session Group By Date',
        rules: {
          repeat: {
            name: 'Repeat for Each Session Group By Date',
            before: '{{#sessionsGroupByDate}}',
            after: '{{/sessionsGroupByDate}}',
          },
        },
      },
    },
    tools: {
      form: {
        enabled: false,
      },
      'custom#product_tool': {
        data: {
          products: formTemplates,
          customFields: customToolFileds,
          eventId: eventId,
          pageId: pageId,
          eventStartDate: eventStartDate,
        },
        properties: {
          productLibrary: {
            editor: {
              data: {
                products: formTemplates,
                customFields: customToolFileds,
                eventId: eventId,
                pageId: pageId,
                eventStartDate: eventStartDate,
              },
            },
          },
          addNewFieldLibrary: {
            editor: {
              data: {
                customFields: customToolFileds,
              },
            },
          }, 

          sessionDynamicOptions: {
            editor: {
              data: {
                options: sessionDynamic,
              },
            },
          },

          QrCodeType: {
            editor: {
              data: {
                options: qrCodeTypeDynamic,
              },
            },
          },

          QrCodeAttendeesDropdown: {
            editor: {
              data: {
                options: qrCodeAttendeeDynamic,
              },
            },
          },

          QrCodeSessionDropdown: {
            editor: {
              data: {
                options: qrCodeSessionOption,
              },
            },
          },
          
          QrCodeSizeDropdown : {
            editor: {
              data: {
                options:  qrCodeSizeDropdown ,
              },
            },
          },

        },
      },
    },
    customCSS: [
      cssUnlyaerPlayground,
      'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/custom.css'
    ],
    customJS: [
      //common for live/Dev or local
      'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/QrCodeLibrary/qrcode.js',
      //live/Dev env
      'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/campaingEmails/customToolProductItemsCampaigns.js',
      'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/campaingEmails/customToolDefaultShowProperties.js',
      'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/campaingEmails/customQRCodeShowProperties.js',
      'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/campaingEmails/customToolForQRCodeCt.js',
      'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/campaingEmails/custom.js',
      //Local env 
      /*  window.location.protocol + '//' + window.location.host + '/campaingEmails/customToolProductItemsCampaigns.js',
      window.location.protocol + '//' + window.location.host + '/campaingEmails/customToolDefaultShowProperties.js',
      window.location.protocol + '//' + window.location.host + '/campaingEmails/customQRCodeShowProperties.js',
      window.location.protocol + '//' + window.location.host + '/campaingEmails/customToolForQRCodeCt.js',
      window.location.protocol + '//' + window.location.host + '/campaingEmails/custom.js',   */
    ],
  }
}
export default getUnLayerEmailConfig
