import { createActions } from 'reduxsauce'

const {Types, Creators } = createActions({
    connectGoogleSignInRequest: ['data'],
    connectGoogleSignInSuccess: ['url'],
    connectGoogleSignInError: [''],
    checkGoogleSignInValidTokenRequest: ['data'],
    checkGoogleSignInValidTokenSuccess: ['isValid'],
    checkGoogleSignInValidTokenError: ['message'],
})

export const Constants = Types
export default Creators