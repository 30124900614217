import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import { Modal } from 'react-responsive-modal';
import Button from '@material-ui/core/Button';
import successCheck from '../assets/images/icons/success-check.svg';
import WarningIcon from '@material-ui/icons/Warning';

class ConfirmationAlertPopUpPrint extends Component {

    render(props) {
        return (
            <>
                <Modal
                    open={this.props.openModal}
                    onClose={this.props.notSuccess ? () => { } : this.props.closeModalFunc}
                    closeOnOverlayClick={false}
                    classNames={{
                        overlay: this.props.notSuccess ? 'disabled-overlay' : '',
                        modal: `Invite-User-Modal ${this.props.notSuccess ? 'modal-default-close-none' : ''}`,

                    }} 
                    center 
                    keyboard = {false}
                    showCloseIcon={false}
                >
                    <Grid
                        component="form"
                        container
                        alignItems="center"
                        justifyContent="center"
                        pb={{ xs: 2, md: 3, lg: 0, }}
                    >
                        <Grid item xs={12} pb={2.6}>
                            <Box textAlign="center" mt={{ xs: 0, md: 0, lg: -2.5 }}>
                                {this.props.notSuccess ? <WarningIcon style={{ fontSize: '75px', color: "red", marginBottom: "40px", marginTop: "20px" }} /> :
                                    <img alt="" src={successCheck} />}
                            </Box>
                            <Box
                                component="p"
                                textAlign="center"
                                mb={1}
                                className="secondary"
                            >
                                {this.props.title}
                            </Box>
                            <Box component="div" textAlign="center" className="paragaph" dangerouslySetInnerHTML={{ __html: this.props.text }}>

                            </Box>
                        </Grid>
                        {this.props.showButton && <Grid item xs={12}>
                            <Grid
                                component="div"
                                container
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item>
                                    <Stack spacing={1} direction="row" fullWidth>
                                        {
                                            <>
                                                <Button
                                                    variant="contained"
                                                    color={this.props.confirmationButtonColor}
                                                    disableElevation
                                                    onClick={() => this.props.functionality()}
                                                    className='progress-button'
                                                >
                                                    {this.props.confirmationButtonText}
                                                </Button>
                                            </>
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>}

                    </Grid>
                </Modal>
            </>
        );
    }
}

export default ConfirmationAlertPopUpPrint;
