import {
  ForwardRefRenderFunction,
  ReactNode,
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
} from '@material-ui/core';
import { Box, Stack } from '@mui/material';

import SendIcon from '@material-ui/icons/Send';
import ChatBot from '../../assets/images/icons/chat-bot-avatar.svg';
import { Avatar, ClickAwayListener, Fade, Popper } from '@material-ui/core';
import { theme } from 'theme/theme';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import CloseIcon from '@material-ui/icons/Close';
import '../ChatWidget/style.css';
import { ChatInterfaceType, Color } from 'Redux/Reducers/aiChatbot.reducer';
import { spacing } from 'react-select/dist/declarations/src/theme';
import { Modes } from 'Pages/AIChatbot/EmbedCodePopUp';
interface Props {
  messages?: Message[];
  headerComponent?: ReactNode;
  ref?: Ref<any>;
  widgetStyle: string;
  onUssrMessage?: (
    text: string,
    sender: 'user' | 'assistant',
    id: string,
  ) => void;
  onToggle?: (isOpen: boolean) => void;
  chatInterface?: ChatInterfaceType;
  for?: string;
}
interface FeedBack {
  _id?: string;
  type: string;
  from?: string;
  response?: any;
  comment?: string;
}
export interface Message {
  _id?: string;
  id: string;
  text: string;
  prompt?: string;
  sender: 'user' | 'assistant';
  feedback?: FeedBack;
}
export interface WidgetActions {
  addMessage(text: string, sender: string, id: string): void;
  updateMessageById(id: string, newText: string): void;
}

const rgbToHex = (color?: Color) => {
  if (color?.r && color?.g && color?.b) {
    let { r, g, b } = color;
    // Convert each RGB component to hexadecimal
    var redHex = r.toString(16).padStart(2, '0');
    var greenHex = g.toString(16).padStart(2, '0');
    var blueHex = b.toString(16).padStart(2, '0');

    // Concatenate the hex values
    var hexColor = '#' + redHex + greenHex + blueHex;

    return hexColor.toUpperCase(); // Convert to uppercase for consistency
  }
};

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const Widget: ForwardRefRenderFunction<WidgetActions, Props> = (props, ref) => {
  const styles = {
    chatAvatar: {
      // backgroundColor: '#5141e7',
      backgroundColor: rgbToHex(props.chatInterface?.bubbleButtonColor),
      color: '#ffffff',
      width: theme.spacing(7.5),
      height: theme.spacing(7.5),
      fontSize: '16px',
      cursor: 'pointer',
    },
  };

  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');

  const [openChatWidget, setOpenChatWidget] = useState(false);
  const anchorRefChatWidget = useRef(null);
  const chatScroll = useRef<HTMLElement>(null);

  useEffect(() => {
    if (props.messages) {
      setMessages([...props.messages]);
    }
  }, [props.messages]);
  const scrollToBottom = () => {
    if (chatScroll?.current) {
      chatScroll.current.scrollTo({
        top: chatScroll.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const addMessage = (
    text: string,
    sender: 'user' | 'assistant',
    id: string = '',
  ) => {
    setMessages((prevMessages) => [...prevMessages, { id: id, text, sender }]);
    setTimeout(() => {
      scrollToBottom();
    }, 200);
  };

  const updateMessageById = (id: string, newText: string) => {
    //   console.log('ALL MESSAGES', messages);
    //  console.log(' MESSAGE ID', id);
    setMessages((prevMessages) => {
      const existingMessageIndex = prevMessages.findIndex((m) => m.id === id);
      //     console.log('I N D E X', index);

      scrollToBottom();

      if (existingMessageIndex > -1) {
        //  console.log('Updating Message', prevMessages[index]);
        prevMessages[existingMessageIndex].text = newText.replace(
          /(^")|("$)/g,
          '',
        );
        return [...prevMessages];
      } else {
        //  console.log('Adding Message.. ');
        return [
          ...prevMessages,
          {
            id: id,
            text: newText,
            sender: 'assistant',
          },
        ];
      }
    });
  };
  useImperativeHandle(ref, () => ({
    addMessage,
    updateMessageById,
  }));
  const handleUserMessage = () => {
    if (newMessage?.trim().length) {
      const id = '_' + Math.random().toString(36).substr(2, 9);
      addMessage(newMessage, 'user', id);
      if (props.onUssrMessage) {
        props.onUssrMessage(newMessage, 'user', id);
      }
      setNewMessage('');
    }
  };

  const handleChatWidgetToggle = () => {
    if (props.onToggle) {
      props.onToggle(openChatWidget);
    }
    setOpenChatWidget((prevOpen) => !prevOpen);
  };

  const ChatWidgetHandleClose = (event: any) => {
    setOpenChatWidget(false);
  };

  const header = () => {
    return (
      <Box
        pt={2.25}
        // mb={2.25}
        px={2}
        sx={{
          backgroundColor: rgbToHex(props.chatInterface?.bubbleButtonColor),
        }}
      >
        <Stack
          className="tab-title"
          alignItems={'center'}
          direction={'row'}
          // justifyContent={'space-between'}
          style={getCustomStyle('headerFontStyle', props.chatInterface)}
        >
          {props.chatInterface?.showProfilePic &&
            (props.chatInterface?.profilePic?.keyInS3 ? (
              <Box className="chatbot-avatar" mr={1}>
                <img
                  alt=""
                  src={
                    IMAGE_URL + '/' + props.chatInterface?.profilePic?.keyInS3
                  }
                />
              </Box>
            ) : (
              <Box className="chatbot-avatar" mr={1}>
                <img alt="" src={ChatBot} />
              </Box>
            ))}

          {/* props.chatInterface?.profilePic?.keyInS3 ? (
              
            ) : (
              
            )} */}
          {/* </Box> */}
          <Box
            sx={{
              color: rgbToHex(props.chatInterface?.displayNameColor),
            }}
          >
            {props.chatInterface?.displayName}
          </Box>
        </Stack>
      </Box>
    );
  };

  const renderChatWindow = () => {
    return (
      <>
        <Box className="chat-window">
          {/* @ts-ignore */}
          <Stack
            spacing={
              getCustomStyle('agentMessage', props.chatInterface)?.spacing +
              'px'
            }
            className="chat-history"
            pb={
              getCustomStyle('agentMessage', props.chatInterface)?.spacing +
              'px'
            }
            ref={chatScroll}
          >
            {messages.map((message, ind) => (
              <Box
                className={`${
                  message.sender === 'user' ? 'client' : 'bot'
                } chat-bubble`}
                key={'mes_id_' + ind}
              >
                <Box
                  className="message"
                  style={
                    message.sender === 'user'
                      ? getCustomStyle('userMessage', props.chatInterface)
                      : getCustomStyle('agentMessage', props.chatInterface)
                  }
                >
                  {message.text}
                </Box>
                {message.sender !== 'user'}
              </Box>
            ))}
          </Stack>
        </Box>

        <Box
          className="chat-input"
          sx={{
            background: rgbToHex(props.chatInterface?.chatWindowBgColor),
          }}
        >
          <FormControl
            component={'form'}
            onSubmit={(e) => {
              e.preventDefault();
              handleUserMessage();
            }}
            fullWidth
          >
            <InputBase
              id="standard-adornment-password"
              type="text"
              placeholder="Type a message..."
              value={newMessage}
              autoComplete="off"
              onChange={(e) => setNewMessage(e.target.value)}
              style={getCustomStyle('chatInput', props.chatInterface)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    style={{
                      color: rgbToHex(props.chatInterface?.chatInputTextColor),
                    }}
                    aria-label="send"
                    size="medium"
                    type="submit"
                  >
                    <SendIcon className="send-icon" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </>
    );
  };
  const renderInlineChatWidget = () => {
    return (
      <Box className="ai-chat-widget">
        <Box
          className="chat-widget-preview"
          sx={getCustomStyle('chatWindow', props.chatInterface)}
        >
          <Box className="chat-window">
            {/* @ts-ignore */}
            <Stack
              spacing={
                getCustomStyle('agentMessage', props.chatInterface)?.spacing +
                'px'
              }
              className="chat-history"
              pb={
                getCustomStyle('agentMessage', props.chatInterface)?.spacing +
                'px'
              }
              ref={chatScroll}
            >
              {messages.map((message, ind) => (
                <Box
                  className={`${
                    message.sender === 'user' ? 'client' : 'bot'
                  } chat-bubble`}
                  key={'mes_id_' + ind}
                >
                  <Box
                    className="message"
                    style={
                      message.sender === 'user'
                        ? getCustomStyle('userMessage', props.chatInterface)
                        : getCustomStyle('agentMessage', props.chatInterface)
                    }
                  >
                    {message.text}
                  </Box>
                  {message.sender !== 'user'}
                </Box>
              ))}
            </Stack>
          </Box>

          <Box
            className="chat-input"
            sx={{
              background: rgbToHex(props.chatInterface?.chatWindowBgColor),
            }}
          >
            <FormControl
              component={'form'}
              onSubmit={(e) => {
                e.preventDefault();
                handleUserMessage();
              }}
              fullWidth
            >
              <InputBase
                id="standard-adornment-password"
                type="text"
                placeholder="Type a message..."
                value={newMessage}
                autoComplete="off"
                onChange={(e) => setNewMessage(e.target.value)}
                style={getCustomStyle('chatInput', props.chatInterface)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      style={{
                        color: rgbToHex(
                          props.chatInterface?.chatInputTextColor,
                        ),
                      }}
                      aria-label="send"
                      size="medium"
                      type="submit"
                    >
                      <SendIcon className="send-icon" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        </Box>
      </Box>
    );
  };
  const getCustomStyle = (
    type: string,
    chatInterface: ChatInterfaceType | undefined,
  ): any => {
    let style = {};
    switch (type) {
      case 'agentMessage':
        if (chatInterface?.agentMessage) {
          const { borderRadius, padding, font, spacing } =
            chatInterface?.agentMessage;
          style = {
            backgroundColor: rgbToHex(props.chatInterface?.botMessageColor),
            color: rgbToHex(props.chatInterface?.botMessageTextColor),
            borderRadius: `${borderRadius?.topLeft}px ${borderRadius?.topRight}px ${borderRadius?.bottomRight}px ${borderRadius.bottomLeft}px`,
            padding: `${padding?.top}px ${padding?.right}px ${padding?.bottom}px ${padding?.left}px`,
            fontSize: `${font?.size}px`,
            spacing: spacing,
          };
          return style;
        }

        break;

      case 'userMessage':
        if (chatInterface?.userMessage) {
          const { borderRadius, spacing } = chatInterface?.userMessage;
          //@ts-ignore
          const { padding } = chatInterface?.agentMessage;
          style = {
            backgroundColor: rgbToHex(props.chatInterface?.userMessageColor),
            color: rgbToHex(props.chatInterface?.userMessageTextColor),
            borderRadius: `${borderRadius?.topLeft}px ${borderRadius?.topRight}px ${borderRadius?.bottomRight}px ${borderRadius.bottomLeft}px`,
            padding: `${padding?.top}px ${padding?.right}px ${padding?.bottom}px ${padding?.left}px`,
            fontSize: `${chatInterface.agentMessage?.font?.size}px`,
            spacing: spacing,
          };

          return style;
        }

        break;
      case 'chatInput':
        if (chatInterface?.chatInput) {
          const { borderRadius, border, font, padding } =
            chatInterface.chatInput;
          style = {
            color: rgbToHex(chatInterface?.chatInputTextColor),
            border: `${border?.size}px ${border?.state} ${rgbToHex(
              border?.color,
            )}`,
            borderRadius: `${borderRadius.topLeft}px ${borderRadius.topRight}px ${borderRadius.bottomRight}px ${borderRadius.bottomLeft}px`,
            // border: '1px solid',
            padding: `${padding?.top}px ${padding?.right}px ${padding?.bottom}px ${padding?.left}px`,
            fontSize: `${font?.size}px`,
          };
        }
        return style;
      case 'chatWindow':
        if (chatInterface?.chatWindow) {
          const { padding, borderRadius } = chatInterface.chatWindow;

          // case 'chatWindowPadding':
          //   let chatWindowPadding = `${chatInterface?.chatWindow.padding?.top}px ${chatInterface?.chatWindow.padding?.right}px ${chatInterface?.chatWindow.padding?.bottom}px ${chatInterface?.chatWindow.padding?.left}px`;
          //   return chatWindowPadding;

          style = {
            padding: `${padding?.top}px ${padding?.right}px ${padding?.bottom}px ${padding?.left}px`,
            background: rgbToHex(chatInterface?.chatWindowBgColor),
            borderRadius: `${borderRadius.topLeft}px ${borderRadius.topRight}px ${borderRadius.bottomRight}px ${borderRadius.bottomLeft}px`,
          };
        }
        return style;
      case 'headerFontStyle':
        style = {
          fontSize: `${chatInterface?.headerStyle?.font.size}px`,
        };
        return style;

      default:
        return style;
    }
  };

  const renderWidget = () => {
    return (
      <Box
        className={`ai-chat-widget ${
          props.chatInterface?.theme === 'dark' ? 'dark' : ''
        }`}
      >
        <Box
          className="chat-widget-preview"
          sx={getCustomStyle('chatWindow', props.chatInterface)}
        >
          {props.headerComponent || header()}
          {/* @ts-ignore */}
          {renderChatWindow()}
        </Box>
      </Box>
    );
  };
  if (props.widgetStyle === Modes.Inline) {
    return renderInlineChatWidget();
  }
  return (
    <>
      {props.for === 'preview' ? (
        <>
          <Box mb={2} className="floating-chat-window" sx={{width: "auto"}}>{renderWidget()}</Box>

          <Stack
            direction={'row'}
            justifyContent={`${
              props.chatInterface?.bubbleButtonAlignment === 'left'
                ? 'start'
                : 'end'
            }`}
          >
            <Avatar className="chat-icon" style={styles.chatAvatar}>
              {props.chatInterface?.chatIcon?.keyInS3 ? (
                <img
                  alt=""
                  src={IMAGE_URL + '/' + props.chatInterface?.chatIcon?.keyInS3}
                  width="35px"
                />
              ) : (
                <ChatBubbleIcon />
              )}
            </Avatar>
          </Stack>
        </>
      ) : (
        <Box
          className={`floating-chat-widget ${
            props.chatInterface?.bubbleButtonAlignment === 'left' ? 'left' : ''
          }`}
        >
          <Avatar
            className="chat-icon"
            style={styles.chatAvatar}
            ref={anchorRefChatWidget}
            aria-controls={openChatWidget ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleChatWidgetToggle}
          >
            {
              // openChatWidget ? (
              //   <CloseIcon />
              // ) :
              props.chatInterface?.chatIcon?.keyInS3 ? (
                <img
                  alt=""
                  src={IMAGE_URL + '/' + props.chatInterface?.chatIcon?.keyInS3}
                  width="35px"
                />
              ) : (
                <ChatBubbleIcon />
              )
            }
          </Avatar>

          <Popper
            open={openChatWidget}
            className="chat-popup"
            style={{ zIndex: 999 }}
            // placement="top-end"
            placement={`${
              props.chatInterface?.bubbleButtonAlignment === 'left'
                ? 'top-start'
                : 'top-end'
            }`}
            transition
            anchorEl={anchorRefChatWidget.current}
            role={undefined}
            disablePortal={false}
            modifiers={{
              preventOverflow: {
                enabled: true,
                boundariesElement: 'viewport',
              },
            }}
          >
            {({ TransitionProps, placement }) => (
              <Fade {...TransitionProps} timeout={250}>
                <Box
                  sx={{
                    // backgroundColor: theme.palette.common.white,
                    // boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                    // borderRadius: '12px',
                    marginBottom: '10px',
                  }}
                >
                  <ClickAwayListener onClickAway={ChatWidgetHandleClose}>
                    <Box className="floating-chat-window">{renderWidget()}</Box>
                  </ClickAwayListener>
                </Box>
              </Fade>
            )}
          </Popper>
        </Box>
      )}
    </>
  );
};
const ChatWidget = forwardRef<WidgetActions, Props>(Widget);
export default ChatWidget;
