import React, { Component } from 'react'
import '../../sass/main.scss'
import EditingBack from '../../assets/images/backPage.png'
import footerLogo from '../../assets/images/footer__logo.png'
import TemplateScreen1 from '../../assets/images/template-1.png'
import mailBlue from '../../assets/images/mail-blue.png'
import ImageOnly from '../../assets/images/image.png'
import HtmlCustom from '../../assets/images/code.png'
import PlainText from '../../assets/images/align-left.png'
import pmsBlank from '../../assets/images/clipboard.png'

class ChooseEmailTemplate extends Component {
  render() {
    return (
      <div className="editingPageWrapper">
        <div className="editingDashboard">
          <div className="editingHeader">
            <div className="editingHeading">
              <h3>
                <a href="#">
                  <img src={EditingBack} alt="" />
                </a>
                Choose an Email Template
              </h3>
            </div>
          </div>
          <div className="editingDashboardContent">
            <div className="EmailTemplatesArea">
              <div className="EmailTemplateList">
                <div className="EmailTemplateImage">
                  <img src={TemplateScreen1} alt="" />
                </div>
                <div className="EmailTemplateContent">
                  <h3>Multi-Ticket Types Confirmation Template</h3>
                  <p>Edited on Mar 9, 2020 at11:22am EDT</p>
                </div>
              </div>
              <div className="EmailTemplateList">
                <div className="EmailTemplateImage">
                  <img src={TemplateScreen1} alt="" />
                </div>
                <div className="EmailTemplateContent">
                  <h3>Multi-Ticket Types Confirmation Template</h3>
                  <p>Edited on Mar 9, 2020 at11:22am EDT</p>
                </div>
              </div>
              <div className="EmailTemplateList">
                <div className="EmailTemplateImage">
                  <img src={TemplateScreen1} alt="" />
                </div>
                <div className="EmailTemplateContent">
                  <h3>Multi-Ticket Types Confirmation Template</h3>
                  <p>Edited on Mar 9, 2020 at11:22am EDT</p>
                </div>
              </div>
              <div className="EmailTemplateList">
                <div className="EmailTemplateImage">
                  <img src={TemplateScreen1} alt="" />
                </div>
                <div className="EmailTemplateContent">
                  <h3>Multi-Ticket Types Confirmation Template</h3>
                  <p>Edited on Mar 9, 2020 at11:22am EDT</p>
                </div>
              </div>
              <div className="EmailTemplateList">
                <div className="EmailTemplateImage">
                  <img src={TemplateScreen1} alt="" />
                </div>
                <div className="EmailTemplateContent">
                  <h3>Multi-Ticket Types Confirmation Template</h3>
                  <p>Edited on Mar 9, 2020 at11:22am EDT</p>
                </div>
              </div>
              <div className="EmailTemplateList">
                <div className="EmailTemplateImage">
                  <img src={TemplateScreen1} alt="" />
                </div>
                <div className="EmailTemplateContent">
                  <h3>Multi-Ticket Types Confirmation Template</h3>
                  <p>Edited on Mar 9, 2020 at11:22am EDT</p>
                </div>
              </div>
              <div className="EmailTemplateList">
                <div className="EmailTemplateImage">
                  <img src={TemplateScreen1} alt="" />
                </div>
                <div className="EmailTemplateContent">
                  <h3>Multi-Ticket Types Confirmation Template</h3>
                  <p>Edited on Mar 9, 2020 at11:22am EDT</p>
                </div>
              </div>
              <div className="EmailTemplateList">
                <div className="EmailTemplateImage">
                  <img src={TemplateScreen1} alt="" />
                </div>
                <div className="EmailTemplateContent">
                  <h3>Multi-Ticket Types Confirmation Template</h3>
                  <p>Edited on Mar 9, 2020 at11:22am EDT</p>
                </div>
              </div>
            </div>
            <div className="templateSequance">
              <h2>Pre-Made Event Sequence Templates</h2>
              <div className="templateSeqArea">
                <div className="templateSeqBoxen">
                  <div className="pms_heading">
                    <img src={mailBlue} alt="icon" />
                    <h6>Save the Date</h6>
                  </div>
                  <div className="pms_details">
                    <p>
                      Say it loud! Say it proud! Give everyone the heads up on
                      your awesome event
                    </p>
                  </div>
                </div>
                <div className="templateSeqBoxen">
                  <div className="pms_heading">
                    <img src={mailBlue} alt="icon" />
                    <h6>Invite</h6>
                  </div>
                  <div className="pms_details">
                    <p>
                      Let your party people know where and when you'll bring
                      down the house
                    </p>
                  </div>
                </div>
                <div className="templateSeqBoxen">
                  <div className="pms_heading">
                    <img src={mailBlue} alt="icon" />
                    <h6>Reminder</h6>
                  </div>
                  <div className="pms_details">
                    <p>
                      No one left behind! Let everyone know your event is
                      approaching
                    </p>
                  </div>
                </div>
                <div className="templateSeqBoxen">
                  <div className="pms_heading">
                    <img src={mailBlue} alt="icon" />
                    <h6>Day of</h6>
                  </div>
                  <div className="pms_details">
                    <p>
                      One last reminder to get your attendees ready for the time
                      of their lives
                    </p>
                  </div>
                </div>
                <div className="templateSeqBoxen">
                  <div className="pms_heading">
                    <img src={mailBlue} alt="icon" />
                    <h6>Thanks you</h6>
                  </div>
                  <div className="pms_details">
                    <p>
                      Show your attendees some love with a post-event thank you
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="templateSequance">
              <h2>Pre-Made Event Sequence Templates</h2>
              <div className="templateSeqArea">
                <div className="templateSeqBoxen">
                  <div className="pms_heading">
                    <img src={ImageOnly} alt="icon" />
                    <h6>Image Only</h6>
                  </div>
                  <div className="pms_details">
                    <p>
                      A picture is worth a thousand words. Embed your event
                      poster or send your guests a photo
                    </p>
                  </div>
                </div>
                <div className="templateSeqBoxen">
                  <div className="pms_heading">
                    <img src={HtmlCustom} alt="icon" />
                    <h6>Custom HTML</h6>
                  </div>
                  <div className="pms_details">
                    <p>
                      Got a HTML snippet? Use this to embed it into Splash email
                      drafts
                    </p>
                  </div>
                </div>
                <div className="templateSeqBoxen">
                  <div className="pms_heading">
                    <img src={PlainText} alt="icon" />
                    <h6>Plain Text</h6>
                  </div>
                  <div className="pms_details">
                    <p>Get your info across with a crisp, text-only message</p>
                  </div>
                </div>
                <div className="templateSeqBoxen">
                  <div className="pms_heading">
                    <img src={pmsBlank} alt="icon" />
                    <h6>Blank</h6>
                  </div>
                  <div className="pms_details">
                    <p>
                      Start from scratch. Add and arrange email elements as you
                      like and see your email come alive
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer_main">
          <div className="footer_logo">
            <img src={footerLogo} alt="" />
          </div>
        </footer>
      </div>
    )
  }
}

export default ChooseEmailTemplate
