import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
    success: false,
    token: null,
    error: false,
    message: null,
    user: null,
    isSubscribed: null,
    subscriptionError: null,
    forgotMsg: null,
    resetMsg: null,
  }

/**
 * @function autoLoginSuccess
 * @description -this is reducer used to return response on success 
 * @return {object}
*/   
const autoLoginSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, success: true, token: action.token, user: action.user }
} 

/**
 * @function autoLoginRequest
 * @description -this is request for autoLogin 
 * @return {object}
*/ 
const autoLoginRequest = (state = INITIAL_STATE, action) => {
    return {
      ...state,
      isSubscribed: false,
      subscriptionError: false,
      forgotMsg: null,
      resetMsg: null,
      resetSuccess: false,
      resetError: false,
      forgotError: false,
      forgotSuccess: false,
      success: false,
      error: false,
      message: null,
    }
}

/**
 * @Const HANDLERS
 * @description -Handlers are used in react to create reducer
 * @return {object}
*/ 
const HANDLERS = {
  [Constants.AUTO_LOGIN_REQUEST]: autoLoginRequest,
  [Constants.AUTO_LOGIN_SUCCESS]: autoLoginSuccess,
} 
export default createReducer(INITIAL_STATE, HANDLERS)
