import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
    enableDisableApplicationIntegrationRequest: ['data'],
    enableDisableApplicationIntegrationSuccess: ['message', 'appName', 'activeStatus'],
    enableDisableApplicationIntegrationError: ['error'],
    checkApplicationIntegrationEnabledRequest: ['data'],
    checkApplicationIntegrationEnabledSuccess: ['apps'],
    checkApplicationIntegrationEnabledError: ['error'],
})

export const Constants = Types
export default Creators