import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//@ts-ignore
import { Box, Button, Grid, IconButton } from '@material-ui/core';
import { Stack } from '@mui/material';
import AppSettings from 'assets/images/icons/app-settings.svg';
import { Actions } from 'Redux/Actions';
import { App, AppsState } from 'Redux/Reducers/integrations/apps.Reducer';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import WidgetConfigurationModal, {
  WidgetModalProps,
} from './TawkTo/widgetConfigurationModal';

import GoogleWorkSpaceConfiguration from './googleWorkspaceComponents/googleWorkSpaceConfiguration';
import Loader from 'Components/Loader/loader';
import { createNotification } from 'helpers';

export interface WorkspaceWidgetModalProps {
  eventId: string;
  showWorkspaceModal: boolean;
  propertyId?: string;
  widgetId?: string;
  integrationId: string;
  onCloseWorkspace: () => void;
}

const modalDefaultData = {
  showModal: false,
  integrationId: '',
  eventId: '',
  onClose: () => {},
};

const workspaceModalDefaultData = {
  showWorkspaceModal: false,
  integrationId: '',
  eventId: '',
  onCloseWorkspace: () => {},
};
const AppIntegrations: React.FC<{}> = () => {
  const state = useSelector((store: any) => store.AppsIntegration) as AppsState;
  const events = useSelector((store: any) => store.Events) as any;
  const users = useSelector((store: any) => store.Users) as any;
  const [modal, setModal] = useState<WidgetModalProps>(modalDefaultData);
  const [workspaceModal, setWorkspaceModal] =
    useState<WorkspaceWidgetModalProps>(workspaceModalDefaultData);
  const dispatch = useDispatch();
  const { eventId } = useParams<{ eventId: string }>();
  const googlePopupRef = useRef<Window | null>(null);
  useEffect(() => {
    if (state.actionType === 'CONNECT_GOOGLE_WORKSPACE_SUCCESS') {
      if (state.googleOAuthUrl) {
        if (googlePopupRef && googlePopupRef.current) {
          googlePopupRef.current.location.href = state.googleOAuthUrl;
        }
      } else {
        dispatch(Actions.getAppIntegrations(eventId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.actionType, state.googleOAuthUrl]);
  useEffect(() => {
    dispatch(Actions.getAppIntegrations(eventId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const handleSignIn = async () => {
    try {
      //  const popup = window.open(state.googleOAuthUrl, '_blank');
      const width = 600;
      const height = 800;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;
      googlePopupRef.current = window.open(
        undefined,
        'Google Sign In',
        `width=${width},height=${height},left=${left},top=${top}`,
      );

      const handleMessage = (event: MessageEvent) => {
        if (event.source === googlePopupRef.current) {
          const responseData = event.data;
          createNotification('success', responseData.message);
          window.removeEventListener('message', handleMessage);
          googlePopupRef?.current?.close();
          dispatch(Actions.getAppIntegrations(eventId));
        }
      };
      // Listen for messages from the OAuth window
      window.addEventListener('message', handleMessage);
    } catch (error) {
      // Handle the error
    }
  };

  const onAppConnect = (app: App, action: string = '') => {
    let integration: any = {
      integrationId: app.integrationId,
      eventId: eventId,
      name: app.name,
      isActive: !app.isActive,
      type: app.type,
      siteUrl: '',
      lngCode: users.lngCode
    };
    if (app.name === 'tawkto') {
      dispatch(Actions.tawkToCreateProperty(integration));
    } else if (app.name === 'googleWorkspace') {
      delete integration.siteUrl;

      if (action) {
        integration['action'] = action;
      }
      if (!app.isActive || action === 'change account') {
        handleSignIn();
      }
      dispatch(Actions.connectGoogleWorkspace(integration));
    }
  };
  const onClose = () => {
    setModal(modalDefaultData);
  };
  const onCloseWorkspace = () => {
    setWorkspaceModal(workspaceModalDefaultData);
  };
  const onChangeGoogleAccount = () => {
    const app = state.apps.find((ap) => ap.name === 'googleWorkspace');
    if (app) {
      onAppConnect(app, 'change account');
    }
  };
  const showModal = (app: App) => {
    if (app.name === 'tawkto') {
      setModal({
        integrationId: app.integrationId,
        eventId: eventId,
        showModal: true,
        onClose: onClose,
      });
    } else if (app.name === 'googleWorkspace') {
      setWorkspaceModal({
        integrationId: app.integrationId,
        eventId: eventId,
        showWorkspaceModal: true,
        onCloseWorkspace: onCloseWorkspace,
      });
    }
  };

  return (
    <div>
      <Box>
        <Box mb={3} className="subsecondary">
        {users.languageStateForRedux?.column_names?.integration?.appIntegration}
        </Box>
        {state.loading && <Loader />}
        <Grid container spacing={3}>
          {state.apps.map((app: App, ind: number) => (
            <Grid item key={`app_` + ind}>
              <Box className="integrated-apps">
                <Stack
                  direction={'row'}
                  // justifyContent="space-between"
                  justifyContent="center"
                  alignItems={'start'}
                  mb={1.5}
                >
                  {/* <CustomSwitch
                    color={'primary'}
                    name={undefined}
                    onChange={(e: any) => onConnectClick(e, app)}
                    checked={app.isActive || false}
                  /> */}
                  <Box mt={1.5}>
                    <img className="app-icon" alt="app icon" src={app.icon} />
                  </Box>

                  <Box px={1.5} py={1.4} position="absolute" right="12px">
                    <IconButton
                      disabled={!app.isActive}
                      onClick={() => showModal(app)}
                      size="small"
                    >
                      <img
                        alt=""
                        style={{ opacity: app.isActive ? '1' : '0.3' }}
                        src={AppSettings}
                      />
                    </IconButton>
                  </Box>
                </Stack>
                <Box>
                  <Box mb={1.5} className="app-title">
                    {app.type}
                  </Box>
                  <Box
                    mb={1.5}
                    className="app-detail"
                    dangerouslySetInnerHTML={{ __html: app.description }}
                  ></Box>
                </Box>
                <Box mt={3} mb={1.5} className="app-detail">
                  {app.isActive ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={state.loading}
                      size="small"
                      onClick={() => onAppConnect(app)}
                    >
                      {users.languageStateForRedux?.buttons?.integration?.disconnect}
                    </Button>
                  ) : app.name === 'googleWorkspace' ? (
                    <div
                      className="google-sign-in-button"
                      onClick={() => onAppConnect(app)}
                    >
                      <span className="icon"></span>{' '}
                      <span className="buttonText">{users.languageStateForRedux?.buttons?.integration?.google}</span>
                    </div>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={state.loading}
                      size="small"
                      onClick={() => onAppConnect(app)}
                    >
                      {users.languageStateForRedux?.buttons?.integration?.connect}
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        {modal.showModal && <WidgetConfigurationModal {...modal} />}
        {workspaceModal.showWorkspaceModal && (
          <GoogleWorkSpaceConfiguration
            onChangeGoogleAccount={onChangeGoogleAccount}
            {...workspaceModal}
          />
        )}
      </Box>
    </div>
  );
};
export default AppIntegrations;
