import React, { Component } from 'react';
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from '../../../theme/theme';
import '../../../assets/styles/bootstrap.min.css';
import BackArrow from '../../../../src/assets/images/icons/backArrow.svg';
import TamplateThum from '../../../../src/assets/images/tamplate-thum.png';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Sidebar from '../../../Components/Sidebar'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux';
import { Actions } from '../../../Redux/Actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import { get } from 'lodash';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '../../../../src/assets/images/icons/search.svg';
import { Modal } from 'react-responsive-modal';
import Loader from '../../../Components/Loader/loader'

import { DeleteMyTemplateAPI } from '../../../Redux/API'
import { createNotification } from 'helpers';

const URL = process.env.REACT_APP_API_URL

const UserData = () => {
    const currentPath = window.location.pathname
    if (currentPath.includes('member')) {
        return JSON.parse(localStorage.getItem('member_details'))
    } else {
        return JSON.parse(localStorage.getItem('user_details'))
    }
}

export class SelectPageTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            eventId: "",
            templateId: "blank",
            pageTemplates: [],
            pageName: "",
            pageNameUrl: "",
            searchText: '',
            type: 'Default',
            count: 0,
            openDeleteModal: false,
            loading: false,
        }
    }

    componentDidMount() {
        const templateId = get(this.props, 'match.params.pid')
        const pageName = get(this.props, 'match.params.pageName')
        const pageNameUrl = get(this.props, 'match.params.pageNameUrl')

        this.setState({
            pageName: pageName,
            pageNameUrl: pageNameUrl,
        })

        if (this.props.projects && this.props.project.project) {
            this.setState({
                eventId: this.props.project.project._id
            });
            localStorage.setItem("eventId", this.props.project.project._id);

            this.props.getProjectRequest(this.props.project.project._id)

        }
        else {
            this.props.getProjectRequest(localStorage.getItem("eventId"))
            this.setState({
                eventId: localStorage.getItem("eventId")
            })
        }
        this.getTemplates()
        this.setState({ loading: true })
    }

    getTemplates = async () => {
        const users = UserData()
        const response = await axios.get(`${URL}/listDefaultPages?pageType=${this.state.type}&searchText=${this.state.searchText}`, {
            headers: {
                token: `bearer ${users.token}`,
                'Content-Type': 'application/json',
            },
        })

        if (response.status === 200) {
            if (response.data.pages && Array.isArray(response.data.pages)) {
                this.setState({
                    pageTemplates: response.data.pages,
                    count: response.data.filterByType,
                    // loading:false
                }, () => this.setState({ loading: false }))
            }
        }
    }

    async openUnlayerEditor(pageTemplateId) {
        const { pageName, pageNameUrl, eventId } = this.state
        const body = {
            pageType: "Project",
            pageName: pageName,
            pageUrl: pageNameUrl,
            eventId: eventId
        }

        if (pageTemplateId === 'blank') {
            body.html = '';
            body.json = {};
        } else {
            body.pageTemplate = pageTemplateId
        }
        const users = UserData();
        const response = await axios.post(`${URL}/createPage`, body, {
            headers: {
                token: `bearer ${users.token}`,
                'Content-Type': 'application/json',
                'check-grant': `${eventId}-Create Page`
            }
        })

        if (response.status === 200) {
            if (response.data.status === 200) {
                this.props.history.push({
                    pathname: `/project-page/pid/${this.state.eventId}/tid/${response.data.pages._id}/pn/${this.state.pageName}/purl/${this.state.pageNameUrl}`,
                    state: { hasRenderedBySelectPageTemplate: true, lngCode: this.props.users.newLangState },
                })
            } else if (response.data.status == 403) {
                window.location.href = "/my-events";
            } else {
                createNotification("error", response.data.message)
            }
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.setState({
            loading: true
        })
        this.getTemplates()
    }


    handleCloseDeleteModal = () => {
        this.setState({
            openDeleteModal: false
        })
    }

    deleteMyTemplate = async () => {
        this.setState({loading: true})
        await DeleteMyTemplateAPI({ id: this.state.deleteId }).then((result) => {
            if (result?.data?.status == 200) {
                this.getTemplates()
                this.setState({
                    openDeleteModal: false
                });
            }
        })
    }

    handleOpenDeleteModal = (template) => {
        this.setState({
            openDeleteModal: true,
            deleteId: template._id,
        })
    }

    openPreview = (template) => {
        this.props.history.push({
            pathname: `/project-dashboard/preview-page/${this.state.eventId}`,
            state: { template: template },
            search: `?pagepath=${template.path}&sentFrom=selectPageTemplates&pageName=${this.state.pageName}&pageUrl=${this.state.pageNameUrl}&pageType=${template.pageType}&pageTemplateId=${template._id}&templateEventId=${template.eventId}`,
        })
    }

    render() {
        const { pageTemplates } = this.state
        return (
            <ThemeProvider theme={theme}>
                <div className="dashboardMainPage">

                    <Sidebar />
                    <div className="dashboardContentArea">
                        <Box my={{ xs: 3, md: 4 }} mx={{ xs: 2, sm: 3, md: 4, lg: 6, xl: 7.5 }} className="">
                            <Grid container alignItems={'center'} spacing={{ xs: 1, md: 2, lg: 3 }} mb={{ xs: 2, md: 3, lg: 5 }}>
                                <Grid item ml={{ xs: 1, sm: 0, md: -1, lg: -2, xl: -4 }} >
                                    <Box component={Button} className='back-button' variant="outlined">
                                        <Link to={`/add-new-page/${this.state.eventId}`}>
                                            <Button className='back-button' variant="outlined">
                                                <img alt="" src={BackArrow} />
                                            </Button>
                                        </Link>
                                    </Box>
                                </Grid>
                                <Grid item >
                                    <Box component={'span'} className='subtitle'>
                                    {this.props.users.languageStateForRedux?.actions?.managePages?.selectTemp}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mb={2.3} className="top-filter-area">
                                <Grid item ml={{ xs: 1, sm: 0, md: 4, lg: 5, xl: 7 }}>
                                    <Grid container spacing={1} >
                                        <Grid item>
                                            <Box
                                                component={Button}
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: theme.palette.background.light,
                                                    color: theme.palette.common.black,
                                                    textTransform: 'capitalize !important',
                                                    fontWeight: '400',

                                                    '&:hover, &.active': {
                                                        backgroundColor: '#000056 !important',
                                                        color: theme.palette.primary.contrastText,
                                                    }
                                                }}
                                                className={this.state.type === 'Default' ? 'active' : null}
                                                size='small'
                                                aria-haspopup="true"
                                                onClick={() => {
                                                    this.setState({
                                                        type: 'Default',
                                                        loading: true
                                                    }, () => this.getTemplates())
                                                }}
                                            >
                                                {this.props.users.languageStateForRedux?.buttons?.managePages?.allTemp} ({this.state.count.Default ? this.state.count.Default : 0})
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box
                                                component={Button}
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: theme.palette.background.light,
                                                    color: theme.palette.common.black,
                                                    textTransform: 'capitalize !important',
                                                    fontWeight: '400',

                                                    '&:hover, &.active': {
                                                        backgroundColor: '#000056 !important',
                                                        color: theme.palette.primary.contrastText,
                                                    }
                                                }}
                                                size='small'
                                                aria-haspopup="true"
                                                className={this.state.type === 'UserDefined' ? 'active' : null}
                                                onClick={() => {
                                                    this.setState({
                                                        type: 'UserDefined',
                                                        loading: true
                                                    }, () => this.getTemplates())
                                                }}
                                            >
                                                {this.props.users.languageStateForRedux?.buttons?.managePages?.myTemp} ({this.state.count.UserDefined ? this.state.count.UserDefined : 0})
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={1.25} className="team-dash-right">
                                        <Grid item className="">
                                            <form onSubmit={this.handleSearch}>
                                                <TextField
                                                    placeholder={this.props.users.languageStateForRedux?.common?.search}
                                                    className="search"
                                                    size="small"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <img alt="" src={SearchIcon} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    name="searchText"
                                                    value={this.state.searchText}
                                                    onChange={this.handleChange}
                                                />
                                            </form>
                                        </Grid>
                                        <Grid item>
                                            <FormControl variant="">
                                                {/* <Select
                                                    className="sort"
                                                    labelId="sort-simple-select-outlined-label"
                                                    id="sort-simple-select-outlined"
                                                    defaultValue="Popular First"
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >

                                                    <MenuItem value="Popular First" selected>Popular First</MenuItem>
                                                    <MenuItem value="Featured">Featured</MenuItem>
                                                    <MenuItem value="Most used">Most used</MenuItem>
                                                </Select> */}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs ml={{ xs: 0, sm: 0, md: 4, lg: 5, xl: 7 }} className='tamplate-wrapper'>
                                    <Grid container justifyContent={{ xs: 'center', sm: 'start' }} columnSpacing={{ xs: 2, md: 3, lg: 3, xl: 4.75 }} rowSpacing={{ xs: 2, md: 3, lg: 3, xl: 3.75 }} >
                                        {this.state.type === 'Default' && <Grid item >
                                            <div className='tamplate' onClick={() => this.openUnlayerEditor("blank")} >
                                                <div className='tamplate-thum' style={{ background: `url(${TamplateThum}) center no-repeat` }}></div>
                                                <div className='tamplate-name'>
                                                    <p>{this.props.users.languageStateForRedux?.actions?.managePages?.blank}</p>
                                                </div>
                                            </div>
                                        </Grid>}

                                        {Array.isArray(pageTemplates) && pageTemplates.length > 0 ? pageTemplates.map((template, index) => {
                                            return (
                                                <Grid item >
                                                    <div className='tamplate' key={index} >
                                                        <div className='tamplate-thum' style={{ background: `url(${template.pageThumb}) center no-repeat` }}>
                                                            {template.pageType === 'UserDefined' ?
                                                                <iframe scrolling="no" style={{ marginTop: '-20px' }} className='page-preview' src={
                                                                    `/project-dashboard/preview-page/${this.state.eventId}?pagepath=${template._id}&myTemp=true`
                                                                } title=''></iframe>
                                                                :
                                                                template.pageThumb !== "" && template.pageThumb !== undefined && <img style={{ width: '100%', height: '100%', }} alt='thumbnail' src={`${process.env.REACT_APP_IMAGE_URL}/thumbnails/${template.pageThumb}`} />}
                                                        </div>
                                                        <Grid container className='tamplate-action' direction="column" alignItems="center" justifyContent={'center'}>
                                                            {this.state.type === 'UserDefined' && <Box className='delete' onClick={() => this.handleOpenDeleteModal(template)}>{this.props.users.languageStateForRedux?.buttons?.teams?.remove}</Box>}
                                                            <Stack spacing={1} direction="column" fullWidth>
                                                                <Button fullWidth className='white-btn' variant='contained' onClick={() => this.openPreview(template)}>{this.props.users.languageStateForRedux?.unlayer?.preview}</Button>
                                                                <Button fullWidth variant='contained' onClick={() => this.openUnlayerEditor(template._id)} color='primary'>{this.props.users.languageStateForRedux?.common?.useTemplate}</Button>
                                                            </Stack>
                                                        </Grid>
                                                        <div className='tamplate-name'>
                                                            <p>{template.pageName}</p>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            )
                                        })
                                            : this.state.type === 'UserDefined' ?

                                                <Grid container >
                                                    <Grid item xs>
                                                        <h5 style={{ textAlign: 'center', marginTop: '100px' }}>
                                                            {' '}
                                                            There are no records to display
                                                        </h5>
                                                    </Grid>
                                                </Grid>

                                                : null
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>

                        <Modal
                            open={this.state.openDeleteModal} onClose={this.handleCloseDeleteModal}
                            classNames={{
                                overlay: '',
                                modal: 'Invite-User-Modal',
                            }}
                            center
                        >
                            <Box component={Grid} container className="" px={1.5} >
                                <Box component={Grid} mt={-1} item xs={12} pb={3}>
                                    <Box component="p" mb={1} className="subtitle">
                                        {' '}
                                        {this.props.users.languageStateForRedux?.common?.confirmDelete}!
                                    </Box>
                                    <p className="small-subtitle">{this.props.users.languageStateForRedux?.common?.deleteMsg}</p>
                                </Box>
                                <Grid item xs={12} >
                                    <Grid container justifyContent="end">
                                        <Stack spacing={1} direction="row" fullWidth>
                                            <Button variant="contained" color="secondary" disableElevation onClick={this.deleteMyTemplate}>{this.props.users.languageStateForRedux?.common?.delete}</Button>
                                            <Button variant="outlined" disableElevation onClick={this.handleCloseDeleteModal}>{this.props.users.languageStateForRedux?.common?.cancel}</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Modal>
                    </div>
                </div>
                {this.state.loading ? <Loader /> : null}
            </ThemeProvider>
        )
    }
}

const mapStateToProps = state => ({
    project: state.Projects,
    pageInfo: state.ManagePage,
    events: state.Events,
    users: state.Users
});

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getProjectRequest: Actions.getProjectRequest,
        },
        dispatch
    );
export default connect(mapStateToProps, mapActionsToProps)(withRouter(SelectPageTemplate));

