import { useEffect, forwardRef, useImperativeHandle } from 'react';
import { useState } from 'react';
import { Box, Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import { Stack } from '@mui/material';
import Avatar from '@material-ui/core/Avatar';
import ViewLogs from './ViewLogs';
import { styles } from './common';
import WorkspaceSyncModes from './WorkspaceSyncModes';
import OrgUnitView from './OrgUnitsView';
import { Actions } from 'Redux/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectIdFromUrl } from 'helpers/common';
import ViewUsers from './ViewUsers';
import CustomHTMLTooltip from 'Components/CustomHTMLTooltip';
import SyncIcon from '@material-ui/icons/Sync';
import moment from 'moment';

const WorkSpace = forwardRef((props, ref) => {
    const dispatch = useDispatch();

    const googleWorkspaceState = useSelector(store => store.GoogleWorkspace);
    const timezone = useSelector(store => store.Projects.project?.timezone);

    const { healthStatus } = googleWorkspaceState;

    const [user, setUser] = useState({});
    const [GoogleDashboardViewLog, setGoogleDashboardViewLog] = useState(false);
    const [integrationData, setIntegrationData] = useState(null);
    const [syncMode, setSyncMode] = useState('');
    const [showUsersList, setShowUsersList] = useState(false);

    //  const [orgUnits, setOrgUnits] = userSelector([]);
    const googleOrgUnitState = useSelector((store) => store.GoogleOrgUnit);
    const [orgUnits, setOrgUnits] = useState([]);
    const [orgUnitCount, setOrgUnitCounts] = useState({ total: 0, selected: 0 });
    useEffect(() => {
        const projectId = getProjectIdFromUrl();
        setUser(props.integrationData?.appData?.user);
        setIntegrationData(props.integrationData);
        setSyncMode(props.integrationData?.appData?.syncMode);
        dispatch(Actions.getSyncLogsHealthStatusRequest({ integrationId: props.integrationData?._id, projectId }));

        setUser(props.integrationData?.appData?.user);

    }, [dispatch, props.integrationData]);

    useEffect(() => {
        const createOrgUnitTree = (OUs) => {
            const hashTable = {};
            const result = [];

            // First pass: create a node for each org unit and add it to the hash table
            for (const orgUnit of OUs) {
                const node = {
                    ...orgUnit,
                    children: [],
                };
                hashTable[node.orgUnitId] = node;
            }

            // Second pass: link each node to its parent
            for (const orgUnit of OUs) {
                const node = hashTable[orgUnit.orgUnitId];
                const parent = hashTable[orgUnit.parentOrgUnitId];
                if (parent?.children) {
                    parent.children.push(node);
                } else {
                    result.push(node);
                }
            }

            return result;
        };
        setOrgUnitCounts({
            total: googleOrgUnitState.orgUnits.length,
            selected: googleOrgUnitState.orgUnits.filter((o) => o.isSyncActive)?.length || 0
        });
        const orgUnitTree = createOrgUnitTree(googleOrgUnitState.orgUnits);

        setOrgUnits(orgUnitTree);
    }, [googleOrgUnitState.orgUnits]);

    useEffect(() => {
        dispatch(Actions.getGoogleOrgUnits({ integrationId: props.integrationData?._id, projectId: props.integrationData.eventId }));
        if (googleOrgUnitState.actionType === "GET_ORG_UNITS_MANUALLY_FAILURE") {
            dispatch(Actions.googleWorkspaceGetWidgetRequest(props.integrationData?._id));
        }
    }, [dispatch, googleOrgUnitState.actionType, props.integrationData?._id, props.integrationData.eventId]);

    const handleGoogleDashboardViewLog = () => {
        setGoogleDashboardViewLog(true);
    };


    const onApplyChanges = () => {
        props.setIsDirty(false);
        updateSyncMode(); // updating the sync mode
        if (syncMode === "syncByOrgUnit") {
            let orgUnitsActiveStatuses = [];
            function findActiveOUs(oUnits) {
                const statuses = oUnits.map((o) => {
                    if (o.children.length) {
                        findActiveOUs(o.children);
                    }
                    return { name: o.name, orgUnitId: o.orgUnitId, isSyncActive: o.isSyncActive, orgUnitPath: o.orgUnitPath };
                });
                orgUnitsActiveStatuses = [...orgUnitsActiveStatuses, ...statuses];
            }
            findActiveOUs(orgUnits);
            let data = { orgUnitsActiveStatuses, integrationId: props.integrationData?._id };
            dispatch(Actions.saveGoogleWorkspaceInfo(data));
        }
    };

    const updateSyncMode = () => {
        dispatch(Actions.updateGoogleWorkspaceSyncModeRequest({ syncModeValue: syncMode, integrationId: props.integrationId }));
    };

    useEffect(() => {
        if (googleWorkspaceState.updateGoogleWorkspaceSyncModeSuccess) {
            dispatch(Actions.googleWorkspaceGetWidgetRequest(props.integrationId));  // Getting the updated integrationData
        }
    }, [dispatch, googleWorkspaceState.updateGoogleWorkspaceSyncModeSuccess, props.integrationId]);

    useEffect(() => {
        if (googleWorkspaceState.googleWorkspaceGetWidgetSuccess) {
            setUser(googleWorkspaceState?.integrationData?.appData?.user);
            setIntegrationData(googleWorkspaceState?.integrationData); // Setting the updated integrationData
        }
    }, [googleWorkspaceState.googleWorkspaceGetWidgetSuccess]);

    useImperativeHandle(ref, () => ({
        onApplyChanges
    }));
    const syncUsers = () => {
        dispatch(Actions.syncUsers({ integrationId: props.integrationId }));
    };

    useEffect(() => {
        if (googleWorkspaceState.actionType === "SYNC_USERS_SUCCESS") {
            dispatch(Actions.googleWorkspaceGetWidgetRequest(props.integrationId));  // Getting the updated integrationData after syncing last update of users
        }
    }, [googleWorkspaceState.actionType])
    
    useEffect(() => {
        if (googleWorkspaceState.actionType === "GET_ORG_UNITS_MANUALLY_SUCCESS") {
            dispatch(Actions.googleWorkspaceGetWidgetRequest(props.integrationId));  // Getting the updated Org Units List after syncing last update of users
        }
    }, [googleWorkspaceState.actionType])
    
    return (
        <Box mt={1}>
            {/* ---header--- */}
            <Box
                pb={3}
                pt={2.5}
                mb={1}
                // sx={{ borderBottom: '1px solid #EDECF5' }}
                className="top-filter-area"
            >
                <Grid container spacing={2}>
                    <Grid item md>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                        >
                            <Grid item>
                                <Box
                                    component="h4"
                                    className="subsecondary"
                                    sx={{
                                        marginRight: 50,
                                        marginBottom: 0,
                                    }}
                                >
                                    Google Workspace
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            spacing={1.25}
                            className="team-dash-right"
                        >
                            <Grid item>
                                {!GoogleDashboardViewLog ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        onClick={onApplyChanges}
                                    >
                                        Apply Changes
                                    </Button>
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {/* section-1 */}
            {!GoogleDashboardViewLog && !showUsersList ? (
                <>
                    <Box
                        px={2.5}
                        className=""
                        sx={{
                            border: '1px solid #EAEAEA',
                            borderRadius: '8px',
                        }}
                    >
                        <Box
                            px={1}
                            pt={3}
                            pb={3.75}
                            sx={{ borderBottom: '1px solid #EDECF5' }}
                        >
                            <Grid
                                container
                                spacing={2}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                            >
                                <Grid item xs={12} md={7} xl={8}>
                                    <Box className="paragraph bold">
                                        Google Admin Account
                                    </Box>
                                    <Box className="small-subtitle">
                                        The Account used to update user and
                                        organizational unit data from google
                                        workspace
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={5} xl={4}>
                                    <Grid
                                        container
                                        spacing={5}
                                        justifyContent="end"
                                        alignItems={'center'}
                                    >
                                        <Grid item xs={6}>
                                            <Stack
                                                direction={'row'}
                                                spacing={1}
                                                alignItems={'center'}
                                            >
                                                <Avatar style={styles.blue}>
                                                    <img src={user?.picture} alt='user' />
                                                </Avatar>

                                                <Box>{user?.name}</Box>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                                            <Button
                                                variant="text"
                                                color="primary"
                                                disableElevation
                                                onClick={props.onChangeGoogleAccount}
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                Change Account
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box
                            px={1}
                            pt={3}
                            pb={3.75}
                            sx={{ borderBottom: '1px solid #EDECF5' }}
                        >
                            <Grid
                                container
                                spacing={2}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                            >
                                <Grid item xs={12} md={7} xl={8}>
                                    <Box className="paragraph bold">
                                        Health Status
                                    </Box>
                                    <Box className="small-subtitle">
                                        Health of the last user update and
                                        logs for past update
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={5} xl={4}>
                                    <Grid
                                        container
                                        spacing={5}
                                        justifyContent="end"
                                        alignItems={'center'}
                                    >
                                        <Grid item xs={6}>
                                            <span className={`table-status ${healthStatus !== undefined ? (healthStatus ? 'success' : 'danger') : ''}`}>
                                                {healthStatus ? "GOOD" : "BAD"}
                                            </span>
                                        </Grid>
                                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                                            <Button
                                                variant="text"
                                                color="primary"
                                                disableElevation
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}
                                                onClick={
                                                    handleGoogleDashboardViewLog
                                                }
                                            >
                                                View Logs
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box
                            px={1}
                            pt={3}
                            pb={3.75}
                            sx={{ borderBottom: '1px solid #EDECF5' }}
                        >
                            <Grid
                                container
                                spacing={2}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                            >
                                <Grid item xs={12} md={7} xl={8}>
                                    <Box className="paragraph bold">
                                        Last Update of users
                                    </Box>
                                    <Box className="small-subtitle">view
                                        Last update of the user and their
                                        personal details
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={5} xl={4}>
                                    <Grid
                                        container
                                        spacing={5}
                                        justifyContent="end"
                                        alignItems={'center'}
                                    >
                                        <Grid item xs={6}>
                                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                <span className="primary-text">
                                                    {integrationData?.appData?.lastUpdatedAttendee &&
                                                        moment.tz(new Date(integrationData?.appData?.lastUpdatedAttendee), timezone).format('MMM DD YYYY hh:mm a')
                                                    }
                                                </span>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                                            <Button style={{ textTransform: 'capitalize', }}
                                              startIcon={<SyncIcon/>}
                                              variant="text"
                                              disableRipple
                                              size='small'
                                              onClick={() => syncUsers()}
                                              color={'primary'}
                                            >
                                              Sync Now
                                            </Button>
                                            <CustomHTMLTooltip interactive arrow title={
                                                googleOrgUnitState.latestAttendees && <>
                                                    <Box className='tooltip-popup' sx={{ maxWidth: '250px !important', maxHeight: '300px', height: 'auto' }} >
                                                        {
                                                            googleOrgUnitState.latestAttendees.map((attendee, i) => <div key={'atn_' + i}>
                                                                <p style={{ maxWidth: '250px !important' }} className='bold'>{attendee.name}</p>
                                                                <p style={{ maxWidth: '250px !important' }}>{attendee.email}</p>
                                                            </div>)
                                                        }
                                                        <Button
                                                            variant="text"
                                                            color="primary"
                                                            fullWidth
                                                            disableElevation
                                                            onClick={() => setShowUsersList(true)}
                                                            style={{
                                                                textTransform: 'capitalize',
                                                                textAlign: "center"
                                                            }}
                                                        >
                                                            Show ALL
                                                        </Button>
                                                    </Box>

                                                </>
                                            }>
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    disableElevation
                                                    onClick={() => setShowUsersList(true)}
                                                    style={{
                                                        textTransform: 'capitalize',
                                                    }}
                                                >
                                                    View Users
                                                </Button>

                                            </CustomHTMLTooltip>

                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box
                            px={1}
                            pt={3}
                            pb={3.75}
                            sx={{ borderBottom: '1px solid #EDECF5' }}
                        >
                            <Grid
                                container
                                spacing={2}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                            >
                                <Grid item xs={12} md={7} xl={8}>
                                    <Box className="paragraph bold">
                                        Last Update of the organizational unit
                                        list
                                    </Box>
                                    <Box className="small-subtitle">
                                        Last update of the google workspace
                                        organizational units
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={5} xl={4}>
                                    <Grid
                                        container
                                        spacing={5}
                                        justifyContent="end"
                                        alignItems={'center'}
                                    >
                                        <Grid item xs={6}>
                                            <span className="primary-text">
                                                {
                                                    integrationData?.appData?.lastOrgUnitsSyncOn
                                                    &&  moment.tz(new Date(integrationData?.appData?.lastOrgUnitsSyncOn), timezone).format('MMM DD YYYY hh:mm a')
                                                }
                                            </span>
                                        </Grid>
                                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                                            <Button
                                                variant="text"
                                                color="primary"
                                                disableElevation
                                                onClick={() => dispatch(Actions.getOrgUnitsManually({ integrationId: props.integrationData?._id }))}
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                Update Org Units List
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    {/* ---radio options--- */}
                    <Box
                        p={3}
                        mt={2}
                        className=""
                        sx={{
                            border: '1px solid #EAEAEA',
                            borderRadius: '8px',
                        }}
                    >

                        {integrationData?.appData && <WorkspaceSyncModes setIsDirty={props.setIsDirty} orgUnitCount={orgUnitCount} setSyncMode={setSyncMode} syncMode={syncMode} />}
                        {/* ---accordion table--- */}
                        {syncMode === "syncByOrgUnit" && <Box>
                            <OrgUnitView orgUnits={orgUnits} setIsDirty={props.setIsDirty} setOrgUnits={setOrgUnits} />
                        </Box>}
                    </Box>
                </>
            ) : null}

            {/* ---view logs--- */}
            {GoogleDashboardViewLog ? (
                <ViewLogs setGoogleDashboardViewLog={setGoogleDashboardViewLog} integrationId={props.integrationId} />
            ) : null}
            {/* ---view logs end--- */}

            {showUsersList &&
                <ViewUsers setShowUsersList={setShowUsersList} projectId={integrationData.eventId} integrationId={props.integrationId} />
            }

        </Box>
    );
});

export default WorkSpace;