import React, { Component } from 'react';
import { theme } from '../../../theme/theme';
import { ThemeProvider } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button } from '@material-ui/core';
import BackArrow from '../../../assets/images/icons/backArrowWhite.svg';
import PageIcon from '../../../assets/images/icons/pageIconSmall.svg';
import { Stack } from '@mui/material';
import Avatar from '@material-ui/core/Avatar';
import CustomSwitch from '../../../Components/customSwitch';
import axios from 'axios';
import { get } from 'lodash';
import { createNotification } from '../../../helpers';
import { NotificationContainer } from 'react-notifications';
import Loader from '../../../Components/Loader/loader';
import { isValid } from 'date-fns';
import { Actions } from '../../../Redux/Actions';
import { connect } from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Checkbox from '@material-ui/core/Checkbox';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { bindActionCreators } from 'redux';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { languageCode } from '../../../helpers/common';
import { UserData } from '../../../helpers/common';
import {
  getEventURL,
  getHostName,
  getProjectIdFromUrl,
} from '../../../helpers/common';
import GoogleWorkspace from '../../../assets/images/icons/Google_G_Logo.svg';
import { permissions } from '../../../constants/constants';

const URL = process.env.REACT_APP_API_URL;

const styles = {
  pink: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
};
export class ManagePageSettings extends Component {
  constructor(props) {
    super(props);
    this.anchorRef = React.createRef();
    this.groupsMultiSelectArray = [];
    this.state = {
      pageDetails: {},
      loading: false,
      errors: {},
      openMultiSelectForGroups: false,
      isGroupMultiSelectShow: false,
      listOfGroups: [],
      pageVisibilityOption: '',
      responseData: {},
      pageName: '',
      path: '',
      eventDomain: '',
      vanityDomain: null,
      visible: false,
      disable: true,
      isDisabledfieldKioskPage: false,
    };
  }

  componentDidMount() {
    const users = UserData();
    this.props.handleLang(languageCode(users.language));
    this.props.newLangStateForRedux(users.language);
    let eventId = this.props.match.params.pid;
    const templateId = get(this.props, 'match.params.tid');
    const pageName = get(this.props, 'match.params.pn');
    const pageUrl = get(this.props, 'match.params.purl');
    const path = get(this.props, 'match.params.purl');
    this.setState(
      {
        eventId,
        templateId,
        pageName,
        pageUrl,
        path,
        pageNameBeforeOpeningSettings: pageName,
        pagePathBeforeOpeningSettings: path,
      },
      () => {
        this.getPageData(templateId);
        this.getProjectRequest(eventId);
      },
    );
    this.listgroups();
    this.findIfAllowedFromSuperadminRequest();
  }

  getProjectRequest(eventId) {
    this.props.getProjectRequest(eventId);
  }
  componentDidUpdate = (prevProps) => {
    if (
      this.props.attendees.getAttendeesGroupsSuccess === true &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        listOfGroups: this.props.attendees.attendeesGroups,
      });
    }
    if (
      this.state.responseData?.groupsVisibility &&
      this.props.attendees.getAttendeesGroupsSuccess
    ) {
      this.groupsMultiSelectArray =
        this.state.responseData?.groupsVisibility.filter((gid) =>
          this.state.listOfGroups.some((s) => s._id === gid),
        );
    }
    if (
      this.props.projects.getProjectSuccess === true &&
      !prevProps.projects.getProjectSuccess
    ) {
      //this.props.handleLang(languageCode(this.props.users.newLangState))
      //this.props.handleLngCodeRedux(languageCode(this.props.location.state.lngCode));
      this.setState({
        eventDomain: this.props?.projects?.project?.eventDomain,
        vanityDomain: this.props?.project?.project?.vanityDomain,
      });
    }
  };

  listgroups = () => {
    try {
      let eventId = this.props.match.params.pid;
      const body = {
        projectId: eventId,
        page_no2: 1,
        searchText: '',
      };
      if (eventId) {
        this.props.listAttendeesGroups(body);
        this.handleLoading();
      } else {
        createNotification('error', 'project Id is missing');
      }
    } catch (error) {
      createNotification('error', 'Something went wrong');
    }
  };

  UserData = async () => {
    const currentPath = window.location.pathname;
    if (currentPath.includes('member')) {
      return JSON.parse(localStorage.getItem('member_details'));
    } else {
      return JSON.parse(localStorage.getItem('user_details'));
    }
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  getPageData = async (templateId) => {
    this.handleLoading();
    try {
      const users = await this.UserData();
      const response = await axios.get(`${URL}/getPageById/${templateId}`, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      });
      this.setState({
        pageDetails: response.data.Page,
      });

      if (response.data.Page.pageType === 'Kiosk') {
        this.setState({
          isDisabledfieldKioskPage: true,
        });
      }

      if (response.data.message === 'Pages sccessfully loaded') {
        this.setState({
          responseData: response.data.Page,
        });

        if (
          Array.isArray(response.data.Page.groupsVisibility) &&
          response.data.Page.groupsVisibility.length > 0
        ) {
          this.setState({
            pageVisibilityOption: 'Groups',
            isGroupMultiSelectShow: true,
          });
        }
        if (response.data.Page.isPrivate === false) {
          this.setState({
            isGroupMultiSelectShow: false,
          });
        }
        if (response.data.Page.isPrivate === true) {
          if (
            Array.isArray(response.data.Page.groupsVisibility) &&
            response.data.Page.groupsVisibility.length === 0
          ) {
            this.setState({
              isGroupMultiSelectShow: false,
              pageVisibilityOption: 'Attendees',
            });
          } else if (!Array.isArray(response.data.Page.groupsVisibility)) {
            this.setState({
              isGroupMultiSelectShow: false,
              pageVisibilityOption: 'Attendees',
            });
          }
        }
        if (this.state.loading) {
          this.setState({
            loading: false,
          });
        }
      }
      if (response?.data?.page?.isLoginPage) {
        this.state.formType = 'loginform';
      }
      return response;
    } catch (err) {

      if (this.state.loading) {
        this.setState({
          loading: false,
        });
      }
      return err;
    }
  };

  handleChange = (e) => {
    this.setState({
      pageDetails: {
        ...this.state.pageDetails,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleURLChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handlePageNameChange = (e) => {
    this.setState({
      pageName: e.target.value,
      path: e.target.value.replace(/\s+/g, '-').toLowerCase(),
    });
  };

  handlePageVisibilityChange = (e) => {
    if (e.target.value === 'Public') {
      this.setState({
        pageDetails: {
          ...this.state.pageDetails,
          isPrivate: false,
          groupsVisibility: [],
        },
        isGroupMultiSelectShow: false,
      });
      this.groupsMultiSelectArray = [];
    } else if (e.target.value === 'Groups' || e.target.value === 'Attendees') {
      this.setState(
        {
          pageDetails: {
            ...this.state.pageDetails,
            isPrivate: true,
          },
        },
        () => {
          if (e.target.value === 'Groups') {
            this.groupsMultiSelectArray =
              this.state.responseData.groupsVisibility;
            this.setState({
              pageDetails: {
                ...this.state.pageDetails,
                groupsVisibility: this.groupsMultiSelectArray,
              },
              isGroupMultiSelectShow: true,
            });
          }
        },
      );
    } 

    if (e.target.value === 'Attendees') {
      this.setState({
        pageDetails: {
          ...this.state.pageDetails,
          isPrivate: true,
          groupsVisibility: [],
        },
        isGroupMultiSelectShow: false,
        groupsVisibility: [],
      });
      this.groupsMultiSelectArray = [];
    }
    this.setState({
      pageVisibilityOption: e.target.value,
    });
  };

  toggleDropdownVisibility = () => {
    const { visible, disable } = this.state;
    if (this.state.pageDetails.pageType == 'Kiosk') {
      this.setState({ visible: false });

      document.getElementById('demo-simple-select-disabled').disable();
    }
    //Part 1: Check if the dropdown is NOT set to disabled before allowing the UI to open dropdown menu.
    // if(!disable) {
    //   this.setState({ visible: !visible });
    // }
  };

  handleCustomSwitchChange = (e) => {
    this.setState({
      pageDetails: {
        ...this.state.pageDetails,
        searchEnginesIndex: e.target.checked,
      },
    });
  };

  handleCustomSwitchChangeKiosk = (e) => {
    this.setState({
      pageDetails: {
        ...this.state.pageDetails,
        kioskConfirmationToggle: e.target.checked,
      },
    });
  };

  handleCustomSwitchChangeAutoCheckInKiosk = (e) => {
    this.setState({
      pageDetails: {
        ...this.state.pageDetails,
        kioskAutoCheckInToggle: e.target.checked,
      },
    });
  };

  handleCustomSwitchPrintBadge = (e) => {
    this.setState({
      pageDetails: {
        ...this.state.pageDetails,
        kioskPrintBadgeToggle: e.target.checked,
      },
    });
  };

  saveSettings = (e) => {
    e.preventDefault();
    this.updatePageAPIRequest();
  };

  updatePageAPIRequest = async () => {
    if (this.isValid()) {
      this.handleLoading();
      const requestBody = {
        ...this.state.pageDetails,
        pageName: this.state.pageName,
        path: this.state.path,
        eventId: this.state.eventId,
        requestCheck: "editPageSettings",
      };
      try {
        const users = await this.UserData();
        const res = await axios.patch(
          `${URL}/updatePage/${this.state.templateId}`,
          requestBody,
          {
            headers: {
              token: `bearer ${users.token}`,
              'Content-Type': 'application/json',
            },
          },
        );
        if (res.data.message === 'Page updated successfully') {
          createNotification('success', 'Page updated successfully');
          this.getPageData(this.state.templateId);

          setTimeout(() => {
            this.goBack();
          }, 500);
        } else if (res.data.status == 402) {
          createNotification('error', res.data.message);
          this.setState({
            pageName: this.state.pageNameBeforeOpeningSettings,
            path: this.state.pagePathBeforeOpeningSettings
          });
        } else {
          createNotification('error', res.data.message);
        }

        if (this.state.loading) {
          this.handleLoading();
        }
        return res;
      } catch (err) {
        if (this.state.loading) {
          this.handleLoading();
        }
        return err;
      }
    }
  };

  isValid = () => {
    let isValid = true;
    let error = {};
    if (this.state.pageName === '' || this.state.pageName === undefined) {
      isValid = false;
      error['pageName'] = this.props.users.languageStateForRedux?.validations?.enterPageName;
    }
    if (this.state.path === '' || this.state.path === undefined) {
      isValid = false;
      error['path'] = this.props.users.languageStateForRedux?.validations?.enterUrlPath;
    }
    this.setState({
      errors: error,
    });
    return isValid;
  };

  goBack = () => {
    this.props.history.push({
      pathname: `/project-page/update-page/pid/${this.state.eventId}/tid/${this.state.templateId}/pn/${this.state.pageName}/purl/${this.state.path}`,
      state: { lngCode: this.props.users.newLangState },
    });
  };

  handleCloseMultiSelectForGroups = () => {
    this.setState({
      openMultiSelectForGroups: false,
    });
  };

  handleOpenMultiSelectForGroups = () => {
    this.setState({
      openMultiSelectForGroups: true,
    });
  };

  handleToggleMultiSelectGroups = () => {
    this.setState({
      openMultiSelectForGroups: !this.state.openMultiSelectForGroups,
    });
  };

  handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      this.setState({
        openMultiSelectForGroups: false,
      });
    }
  };

  handleMultiSelectForGroups = (e) => {
    const { value, checked } = e.target;
    this.groupsMultiSelectArray = this.state.pageDetails?.groupsVisibility;
    if (checked) {
      this.groupsMultiSelectArray.push(value);
    } else {
      const indx = this.groupsMultiSelectArray.findIndex((v) => v === value);
      this.groupsMultiSelectArray.splice(indx, 1);
    }
    this.setState({
      pageDetails: {
        ...this.state.pageDetails,
        groupsVisibility: this.groupsMultiSelectArray,
      },
    });
  };

  isChecked = (groupId) => {
    if (this.groupsMultiSelectArray.includes(groupId)) {
      return true;
    } else {
      return false;
    }
  };

  findIfAllowedFromSuperadminRequest = () => {
    let userDetails = JSON.parse(localStorage.getItem('user_details'));
    let servicePlanId = userDetails.servicePlanId;
    const projectId = getProjectIdFromUrl();
    let reqData = {
      servicePlanId,
      projectId,
    };
    this.props.findIfAllowedFromSuperadminRequest(reqData);
  };

  handleInputNameBlur = () => {
    const { pageName, path } = this.state;
    const ifLastLetterisSpace = /\s$/;
    if (ifLastLetterisSpace.test(pageName)) {
      this.setState({
        pageName: pageName.trim(),
        path: path.slice(0, -1),
      });
    }
  };

  render() {
    const { pageDetails, listOfGroups, isDisabledfieldKioskPage } = this.state;
    const isSearchEnginesIndexChecked =
      this.state.pageDetails.searchEnginesIndex;
    const iskioskConfirmationChecked =
      this.state.pageDetails.kioskConfirmationToggle;
    const iskioskAutoCheckInChecked =
      this.state.pageDetails.kioskAutoCheckInToggle;
    const iskioskPrintBadgeChecked =
      this.state.pageDetails.kioskPrintBadgeToggle;
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Box className="unlayer-header" mb={4}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center">
                  <Button className="back-button" variant="text">
                    <img alt="" src={BackArrow} onClick={this.goBack} />
                  </Button>
                  <Avatar style={styles.pink}>
                    <img alt="" src={PageIcon} />
                  </Avatar>
                  <h3 className="page-title">{this.props.users.languageStateForRedux?.unlayer?.settings} </h3>
                </Grid>
              </Grid>
              <Grid item className="buttons">
                <Stack spacing={1} direction="row">
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={this.saveSettings}
                  >
                    {' '}
                    {this.props.users.languageStateForRedux?.unlayer?.save}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Grid container pb={4} alignItems="center" justifyContent={'center'}>
            <Grid item xs lg={10} xl={8}>
              <Grid container rowSpacing={{ xs: 2, xl: 2.5 }} columnSpacing={5}>
                <Grid item xs={12} md={6}>
                  <Box component="div" className="inputField">
                    <label>
                      {this.props.users.languageStateForRedux?.actions?.managePages?.newPageSubhead} <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control small"
                      name="pageName"
                      value={this.state.pageName}
                      onChange={this.handlePageNameChange}
                      placeholder=""
                      disabled={isDisabledfieldKioskPage}
                      onBlur={this.handleInputNameBlur}
                    />
                    <span className="error_mesage required">
                      {' '}
                      {this.state.errors.pageName}{' '}
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box component="div" className="inputField">
                    <label>
                      {this.props.users.languageStateForRedux?.unlayer?.modalSettings?.url}<span className="required">*</span>
                    </label>
                    <Box component="div" mb={4} className="inputField">
                      <OutlinedInput
                        notched={false}
                        size="medium"
                        fullWidth
                        id="outlined-adornment-amount"
                        name="path"
                        value={this.state.path}
                        disabled={isDisabledfieldKioskPage}
                        onChange={this.handleURLChange}
                        startAdornment={
                          <Box
                            className="paragraph"
                            style={{
                              backgroundColor: '#EDECF5',
                              padding: '4px 13px',
                              borderRadius: '4px',
                              marginRight: '10px',
                            }}
                          >
                            {getEventURL(
                              this.state.eventDomain,
                              this.state.vanityDomain?.domainName,
                              this.state.vanityDomain?.isActive,
                              this.state.vanityDomain?.cloudFrontDistribution
                                ?.enabled,
                            )}
                          </Box>
                        }
                      />
                    </Box>
                    <span className="error_mesage required">
                      {' '}
                      {this.state.errors.path}{' '}
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box component="div" className="inputField">
                    <label>{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.title}</label>
                    <input
                      type="text"
                      className="form-control small"
                      placeholder=""
                      name="pageTitle"
                      value={this.state.pageDetails.pageTitle}
                      onChange={this.handleChange}
                    />
                    <Box mt={0.5} className="small-subtitle">
                      {this.props.users.languageStateForRedux?.unlayer?.modalSettings?.tileDesc}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box component="div" className="inputField">
                    <label>{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.meta}</label>
                    <input
                      type="text"
                      className="form-control small"
                      placeholder=""
                      name="pageDesc"
                      value={this.state.pageDetails.pageDesc}
                      onChange={this.handleChange}
                    />
                    <Box mt={0.5} className="small-subtitle">
                      {this.props.users.languageStateForRedux?.unlayer?.modalSettings?.pageDesc}
                    </Box>
                  </Box>
                </Grid>
                {isDisabledfieldKioskPage !== true && (
                  <Grid item xs={12} sm={12} md={6}>
                    <Box component="div" className="inputField">
                      <label>{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.let}</label>
                      <span className="paragraph bold text-primary-light">
                        {this.props.users.languageStateForRedux?.unlayer?.modalSettings?.no}
                      </span>
                      <CustomSwitch
                        name="searchEnginesIndex"
                        value={this.state.pageDetails.searchEnginesIndex}
                        checked={isSearchEnginesIndexChecked ? true : false}
                        onChange={this.handleCustomSwitchChange}
                        color={'primary'}
                      />
                      <span className="paragraph bold">{this.props.users.languageStateForRedux?.buttons?.common?.yes}</span>
                    </Box>
                  </Grid>
                )}

                {isDisabledfieldKioskPage === true && (
                  <Grid item xs={12} sm={12} md={12}>
                    <Box component="div" className="inputField">
                      <label>{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.auto}</label>
                      <span className="paragraph bold text-primary-light">
                        {this.props.users.languageStateForRedux?.unlayer?.modalSettings?.no}
                      </span>
                      <CustomSwitch
                        name="kioskAutoCheckInToggle"
                        value={this.state.pageDetails.kioskAutoCheckInToggle}
                        checked={iskioskAutoCheckInChecked ? true : false}
                        onChange={this.handleCustomSwitchChangeAutoCheckInKiosk}
                        color={'primary'}
                      />
                      <span className="paragraph bold">{this.props.users.languageStateForRedux?.buttons?.common?.yes}</span>
                    </Box>
                  </Grid>
                )}

                {isDisabledfieldKioskPage === true && (
                  <Grid item xs={12} sm={12} md={6}>
                    <Box component="div" className="inputField">
                      <label>{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.printBadge}</label>
                      <span className="paragraph bold text-primary-light">
                        {this.props.users.languageStateForRedux?.unlayer?.modalSettings?.no}
                      </span>
                      <CustomSwitch
                        name="kioskPrintBadgeToggle"
                        value={this.state.pageDetails.kioskPrintBadgeToggle}
                        checked={iskioskPrintBadgeChecked ? true : false}
                        onChange={this.handleCustomSwitchPrintBadge}
                        color={'primary'}
                      />
                      <span className="paragraph bold">{this.props.users.languageStateForRedux?.buttons.common.yes}</span>
                    </Box>
                  </Grid>
                )}

                {iskioskPrintBadgeChecked ? (
                  <>
                    {isDisabledfieldKioskPage === true && (
                      <Grid item xs={12} sm={12} md={6}>
                        <Box component="div" className="inputField">
                          <label>{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.showConfirm}</label>
                          <span className="paragraph bold text-primary-light">
                            {this.props.users.languageStateForRedux?.unlayer?.modalSettings?.no}
                          </span>
                          <CustomSwitch
                            name="kioskConfirmationToggle"
                            value={
                              this.state.pageDetails.kioskConfirmationToggle
                            }
                            checked={iskioskConfirmationChecked ? true : false}
                            onChange={this.handleCustomSwitchChangeKiosk}
                            color={'primary'}
                          />
                          <span className="paragraph bold">{this.props.users.languageStateForRedux?.buttons?.common?.yes}</span>
                        </Box>
                      </Grid>
                    )}

                    {isDisabledfieldKioskPage === true && (
                      <Grid item xs={12} md={12}>
                        <Box component="div" className="inputField">
                          <label>{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.kioskTitle}</label>
                          <input
                            type="text"
                            className="form-control small"
                            placeholder=""
                            name="kioskPopupTitle"
                            value={this.props.users.languageStateForRedux?.unlayer?.modalSettings?.kioskPopupTitle}
                            onChange={this.handleChange}
                          />
                          <Box mt={0.5} className="small-subtitle">
                            {this.props.users.languageStateForRedux?.unlayer?.modalSettings?.kioskPopup}
                          </Box>
                        </Box>
                      </Grid>
                    )}

                    {isDisabledfieldKioskPage === true && (
                      <Grid item xs={12}>
                        <Box component="div" className="inputField">
                          <label>{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.popupMsg}</label>
                          <textarea
                            type="code"
                            rows="4"
                            name="kioskMessage"
                            value={this.props.users.languageStateForRedux?.unlayer?.modalSettings?.kioskMessage}
                            onChange={this.handleChange}
                            className="form-control"
                            placeholder={this.props.users.languageStateForRedux?.unlayer?.modalSettings?.messageHolder}
                          />
                        </Box>
                      </Grid>
                    )}

                    {isDisabledfieldKioskPage === true && (
                      <Grid item xs={12} md={12}>
                        <Box component="div" className="inputField">
                          <label>{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.print}</label>
                          <input
                            type="text"
                            className="form-control small"
                            placeholder=""
                            name="printPreviewPopupTitle"
                            value={
                              this.props.users.languageStateForRedux?.unlayer?.modalSettings?.printPreviewPopupTitle
                            }
                            onChange={this.handleChange}
                          />
                          <Box mt={0.5} className="small-subtitle">
                            {this.props.users.languageStateForRedux?.unlayer?.modalSettings?.closePopup}
                          </Box>
                        </Box>
                      </Grid>
                    )}

                    {isDisabledfieldKioskPage === true && (
                      <Grid item xs={12}>
                        <Box component="div" className="inputField">
                          <label>{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.printMsg}</label>
                          <textarea
                            type="code"
                            rows="4"
                            name="printPreviewMessage"
                            value={this.props.users.languageStateForRedux?.unlayer?.modalSettings?.printPreviewMessage} //4
                            onChange={this.handleChange}
                            className="form-control"
                            placeholder={this.props.users.languageStateForRedux?.unlayer?.modalSettings?.prevPop}
                          />
                        </Box>
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    {isDisabledfieldKioskPage === true && (
                      <Grid item xs={12} md={12}>
                        <Box component="div" className="inputField">
                          <label>{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.kioskTitle}</label>
                          <input
                            type="text"
                            className="form-control small"
                            placeholder=""
                            name="kioskNoPrintPopupTitle"
                            value={
                              this.state.pageDetails?.kioskNoPrintPopupTitle
                            }
                            onChange={this.handleChange}
                          />
                          <Box mt={0.5} className="small-subtitle">
                            {this.props.users.languageStateForRedux?.unlayer?.modalSettings?.kioskPopup}
                          </Box>
                        </Box>
                      </Grid>
                    )}

                    {isDisabledfieldKioskPage === true && (
                      <Grid item xs={12}>
                        <Box component="div" className="inputField">
                          <label>{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.popupMsg}</label>
                          <textarea
                            type="code"
                            rows="4"
                            name="kioskNoPrintMessage"
                            value={this.state.pageDetails.kioskNoPrintMessage}
                            onChange={this.handleChange}
                            className="form-control"
                            placeholder={this.props.users.languageStateForRedux?.unlayer?.modalSettings?.messageHolder}
                          />
                        </Box>
                      </Grid>
                    )}
                  </>
                )}

                {this.state.pageDetails.isLoginPage === false &&
                  this.state.pageDetails.pageType !== 'Kiosk' && (
                    <Grid item xs={12} sm={6} md={3}>
                      <Box component="div" className="inputField">
                        <label>{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.pageVisibility}</label>
                        <select
                          type="text"
                          className="form-control small"
                          onChange={this.handlePageVisibilityChange}
                          name="groupsVisibility"
                          value={this.state.pageVisibilityOption}
                        >
                          <option value="Public">{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.public}</option>
                          <option value="Attendees">{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.attendees}</option>
                          <option value="Groups">{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.groups}</option>
                        </select>
                      </Box>
                    </Grid>
                  )}

                {this.state.isGroupMultiSelectShow === true && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Box
                      component="div"
                      style={{ position: 'relative' }}
                      className="inputField"
                    >
                      <label>{this.props.users.languageStateForRedux?.dropdowns?.agenda?.selectGroups}</label>
                      <Button
                        fullWidth
                        variant="outlined"
                        style={{
                          padding: '10px 15px',
                          borderRadius: '7px',
                          justifyContent: 'space-between',
                          textTransform: 'capitalize',
                        }}
                        size="large"
                        disableElevation
                        ref={this.anchorRef}
                        aria-controls={
                          this.state.openMultiSelectForGroups
                            ? 'menu-list-grow'
                            : undefined
                        }
                        aria-haspopup="true"
                        onClick={this.handleToggleMultiSelectGroups}
                        endIcon={<ArrowDropDownIcon />}
                      >
                        {this.groupsMultiSelectArray.length > 1
                          ? `${this.groupsMultiSelectArray.length} Groups Selected`
                          : `${this.groupsMultiSelectArray.length} Group Selected`}
                      </Button>
                      <Popper
                        open={this.state.openMultiSelectForGroups}
                        style={{ right: 0 }}
                        placement="bottom-start"
                        transition
                        anchorEl={this.anchorRef.current}
                        role={undefined}
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Fade {...TransitionProps} timeout={250}>
                            <Box
                              sx={{
                                maxHeight: '230px',
                                overflow: 'scroll',
                                backgroundColor: theme.palette.common.white,
                                boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                borderRadius: '4px',
                                marginTop: '8px',
                              }}
                            >
                              <ClickAwayListener
                                onClickAway={
                                  this.handleCloseMultiSelectForGroups
                                }
                              >
                                <MenuList
                                  autoFocusItem={
                                    this.state.openMultiSelectForGroups
                                  }
                                  id="menu-list-grow"
                                  onKeyDown={this.handleListKeyDown}
                                >
                                  {listOfGroups.filter(
                                    (g) =>
                                      (g.source === 'GoogleWorkspace' &&
                                        g.showToGroup) ||
                                      g.source === 'Manually Added',
                                  ).length ? (
                                    listOfGroups
                                      .filter(
                                        (g) =>
                                          (g.source === 'GoogleWorkspace' &&
                                            g.showToGroup) ||
                                          g.source === 'Manually Added',
                                      )
                                      .map((group, index) => {
                                        return (
                                          <MenuItem
                                            key={'grp_visi_' + index}
                                            className="checkbox-item"
                                          >
                                            <FormControlLabel
                                              control={
                                                <>
                                                  <Checkbox
                                                    style={{
                                                      padding: 0,
                                                      marginRight: '8px',
                                                    }}
                                                    color="primary"
                                                    checked={this.isChecked(
                                                      group._id,
                                                    )}
                                                    value={group._id}
                                                    onChange={
                                                      this
                                                        .handleMultiSelectForGroups
                                                    }
                                                  />
                                                  {group?.showToGroup &&
                                                    group.source ===
                                                    'GoogleWorkspace' && (
                                                      <img
                                                        src={GoogleWorkspace}
                                                        alt="google workspace"
                                                        width="14px"
                                                        style={{
                                                          marginRight: '5px',
                                                        }}
                                                      />
                                                    )}
                                                </>
                                              }
                                              label={group.attendeesGroupName}
                                              style={{ padding: 0, margin: 0 }}
                                            />
                                          </MenuItem>
                                        );
                                      })
                                  ) : (
                                    <MenuItem disabled>
                                      {this.props.users.languageStateForRedux?.unlayer?.modalSettings?.noGroup}
                                    </MenuItem>
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Box>
                          </Fade>
                        )}
                      </Popper>
                    </Box>
                  </Grid>
                )}
                {this.props?.servicePlan?.featuredAllowed?.customCss && (
                  <Grid item xs={12}>
                    <Box component="div" className="inputField">
                      <label>{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.customCSS}</label>
                      <textarea
                        type="code"
                        rows="10"
                        name="customCss"
                        value={this.state.pageDetails.customCss}
                        onChange={this.handleChange}
                        className="form-control"
                        placeholder={this.props.users.languageStateForRedux?.unlayer?.modalSettings?.CSSdesc}
                      />
                    </Box>
                  </Grid>
                )}
                {this.props?.servicePlan?.featuredAllowed?.customJs && (
                  <Grid item xs={12}>
                    <Box component="div" className="inputField">
                      <label>{this.props.users.languageStateForRedux?.unlayer?.modalSettings?.customJS}</label>
                      <textarea
                        type="code"
                        rows={'10'}
                        name="customeJs"
                        value={this.state.pageDetails.customeJs}
                        onChange={this.handleChange}
                        className="form-control"
                        placeholder={this.props.users.languageStateForRedux?.unlayer?.modalSettings?.JSdesc}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {this.state.loading ? <Loader /> : null}
          <NotificationContainer />
        </ThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  attendees: state.Attendees,
  events: state.Events,
  projects: state.Projects,
  servicePlan: state.ServicePlan,
  users: state.Users,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listAttendeesGroups: Actions.listAttendeesGroupsRequest,
      getProjectRequest: Actions.getProjectRequest,
      findIfAllowedFromSuperadminRequest:
        Actions.findIfAllowedFromSuperadminRequest,
      handleLang: Actions.handleLang,
      newLangStateForRedux: Actions.newLangStateForRedux
    },
    dispatch,
  );

export default connect(mapStateToProps, mapActionsToProps)(ManagePageSettings);
