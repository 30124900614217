import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'react-responsive-modal';
import { IconButton } from '@material-ui/core';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import GoogleWorkspaces from '../../../../../assets/images/icons/workspaces.svg';
import PageIcon from '../../../../../assets/images/icons/pageIconSmall.svg';
import GroupManagementIcon from '../../../../../assets/images/icons/GroupManagement.svg';
import MapCustomFieldsIcon from '../../../../../assets/images/icons/Map-Fields.svg';
import Avatar from '@material-ui/core/Avatar';
import WorkSpace from './WorkSpace';
import { styles } from './common';
import GroupManagement from './GroupManageMent';
import MapCustomFields from './MapCustomFields';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'Redux/Actions';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Box, Stack } from '@mui/material';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import { createNotification } from 'helpers';
import { getProjectIdFromUrl } from 'helpers/common';
import SyncIcon from '@material-ui/icons/Sync';

function GoogleWorkSpaceConiguration(props) {
  const [GoogleDashboard, setGoogleDashboard] = useState(true);
  const [integrationData, setIntegrationData] = useState(null);
  const googleWorkspaceState = useSelector((store) => store.GoogleWorkspace);
  const appState = useSelector((store) => store.AppsIntegration);
  const googleOrgUnitState = useSelector((store) => store.GoogleOrgUnit);
  const [isDirty, setIsDirty] = useState(false);
  const [showAlert, setShowhowAlert] = useState(false);
  const [showPermissionsAlert, setShowhowPermissionsAlert] = useState(false);
  const [permissionsFor, setPermissionsFor] = useState("");
  const [syncing, setSyncing] = useState(false);
  const workSpaceRef = useRef(null);
  const dispatch = useDispatch();
  const googlePopupRef = useRef(null);

  useEffect(() => {
    if (props.integrationId) {
      dispatch(Actions.googleWorkspaceGetWidgetRequest(props.integrationId));
    } else {
      console.log("integrationId not passed from the parent element");
    }
  }, [dispatch, props.integrationId]);

  useEffect(() => {
    if (googleWorkspaceState.getIntegrationByIdSuccess) {
      setIntegrationData(googleWorkspaceState.integrationData);
    }
  }, [googleWorkspaceState.getIntegrationByIdSuccess, googleWorkspaceState.integrationData]);

  useEffect(() => {
    if (appState.actionType === 'AUTHORIZE_GOOGLE_WORKSPACE_SUCCESS') {
      if (appState.googleOAuthUrl) {
        if (googlePopupRef && googlePopupRef.current) {
          googlePopupRef.current.location.href = appState.googleOAuthUrl;
        }
      };
    }
  }, [appState.actionType, appState.googleOAuthUrl]);

  useEffect(() => {
    if (googleOrgUnitState.message === "No Permissions") {
      setPermissionsFor("OrgUnits");
      setShowhowPermissionsAlert(true);
    }
    else if (googleWorkspaceState.message === "No Permissions") {
      if ((googleWorkspaceState.actionType === "GET_GOOGLE_USERS_FAILURE")
        || (googleWorkspaceState.actionType === "SYNC_USERS_FAILURE")) {
        setPermissionsFor("Users");
      } else if (googleWorkspaceState.actionType === "UPDATE_GROUPS_FROM_GOOGLE_WORKSPACE_ERROR") {
        setPermissionsFor("Groups");
      }
      setShowhowPermissionsAlert(true);
    }

  }, [googleOrgUnitState.message, googleWorkspaceState.actionType, googleWorkspaceState.message]);

  useEffect(() => {
    if (googleOrgUnitState.loading || googleWorkspaceState.loading) {
      if ((googleOrgUnitState.actionType === "GET_ORG_UNITS_MANUALLY")
        || (googleWorkspaceState.actionType === "SYNC_USERS")
        || (googleWorkspaceState.actionType === "UPDATE_GROUPS_FROM_GOOGLE_WORKSPACE_REQUEST")) {
        setSyncing(true);
      }
    } else {
      setSyncing(false);
    }
  }, [googleOrgUnitState.actionType, googleOrgUnitState.loading, googleWorkspaceState.actionType, googleWorkspaceState.loading]);
  const handleClose = (event) => {
    if (isDirty) {
      setShowhowAlert(true);
    } else {
      setShowhowAlert(false);
      props.onCloseWorkspace();
      setGoogleDashboard(false);
    }
  };

  const sync = () => {
    //setPermissionsFor("");
    const projectId = getProjectIdFromUrl();

    switch (permissionsFor) {
      case "OrgUnits":
        dispatch(Actions.getOrgUnitsManually({ integrationId: props.integrationData?._id }));
        break;
      case "Users":
        dispatch(Actions.syncUsers({ integrationId: props.integrationId }));
        dispatch(Actions.getSyncLogsHealthStatusRequest({ integrationId: props.integrationId, projectId }));
        break;
      case "Groups":
        const body = {
          projectId,
          integrationId: props.integrationId
        };
        dispatch(Actions.updateGroupsFromGoogleWorkspaceRequest(body));
        break;
      default:
        break;
    }
    setPermissionsFor("");
    setShowhowPermissionsAlert(false);
  };
  const onPermissionGrant = () => {
    const data = {
      integrationId: props.integrationId,
      permissionsFor
    };
    dispatch(Actions.authorizeGoogleWorkspace(data));
    try {
      //  const popup = window.open(state.googleOAuthUrl, '_blank');
      const width = 600;
      const height = 800;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;
      googlePopupRef.current = window.open(
        undefined,
        'Google Sign In',
        `width=${width},height=${height},left=${left},top=${top}`,
      );

      const handleMessage = (event) => {
        if (event.source === googlePopupRef.current) {
          const responseData = event.data;
          sync();
          createNotification('success', responseData.message);
          window.removeEventListener('message', handleMessage);
          googlePopupRef?.current?.close();
        }
      };
      // Listen for messages from the OAuth window

      window.addEventListener('message', handleMessage);
      window.addEventListener('beforeunload', (e) => {
        dispatch(Actions.googleWorkspaceGetWidgetRequest(props.integrationId));
        return (e.returnValue = '');
      });

    } catch (error) {
      // Handle the error
    }
  };
  return (
    <Modal
      open={GoogleDashboard}
      onClose={handleClose}
      classNames={{
        overlay: 'full-screen_overlay',
        modal: 'full-screen_modal modal-default-close-none',
      }}
      center
    >
      <Box
        style={{ overflow: 'auto', background: '#ffffff', width: '100%' }}
      >
        <Box className="unlayer-header">
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Grid container alignItems="center">
                <Avatar style={styles.pink}>
                  <img alt="" src={PageIcon} />
                </Avatar>
                <h3 className="page-title">Google Workspace</h3>
              </Grid>
            </Grid>

            {syncing &&
              <Grid item>
                <Stack className='sync' direction="row" alignItems="center" spacing={1} >
                  <Box className='sync-icon'>
                    <SyncIcon style={{ fontSize: '24px' }} />
                  </Box>
                  <Box>
                    Syncing...
                  </Box>
                </Stack>
              </Grid>
            }
            <Grid item>
              <IconButton
                onClick={handleClose}
              >
                <CloseIcon style={{ color: '#ffffff' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <Box className="app-dashboard">
          <Grid container alignItems={'center'} justifyContent={'center'}>
            <Grid item xs lg={12} xl={10}>
              <Box className="container-with-sidebar">
                <Tabs defaultIndex={0}>
                  <Box
                    className="project-sidebar"
                    sx={{ background: 'transparent !important' }}
                  >
                    <TabList>
                      <Tab>
                        {' '}
                        <img src={GoogleWorkspaces} alt="workspace" />{' '}
                        <a to={''}>Google Workspace</a>
                      </Tab>
                      <Tab>
                        {' '}
                        <img src={GroupManagementIcon} alt="group management" />{' '}
                        <a to={''}>Group Management</a>
                      </Tab>
                      <Tab>
                        {' '}
                        <Box component="img" src={MapCustomFieldsIcon} alt="mapcustomfield" />{' '}
                        <a to={''}>Map Custom Fields</a>
                      </Tab>
                    </TabList>
                  </Box>

                  <Box className="container-sidebar-right">

                    <TabPanel>
                      {integrationData && <WorkSpace
                        ref={workSpaceRef}
                        setIsDirty={setIsDirty}
                        onChangeGoogleAccount={props.onChangeGoogleAccount}
                        integrationData={integrationData}
                        integrationId={props.integrationId} />}
                    </TabPanel>
                    {/* 2-- */}
                    <TabPanel>
                      <GroupManagement integrationId={props.integrationId} />
                    </TabPanel>
                    {/* 3-- */}
                    <TabPanel>
                      <MapCustomFields integrationId={props.integrationId} integrationData={integrationData} />
                    </TabPanel>
                  </Box>
                </Tabs>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <ConfirmationAlertPopUp
        openModal={showAlert}
        closeButtonText="Discard Changes"
        text="You have some pending changes."
        functionality={() => {
          workSpaceRef?.current?.onApplyChanges();
          props.onCloseWorkspace();
          setGoogleDashboard(false);
          setShowhowAlert(false);
          setIsDirty(false);
        }}
        closeModalFunc={(ev) => {
          props.onCloseWorkspace();
          setGoogleDashboard(false);
          setIsDirty(false);
        }}
        title="Pending Changes"
        confirmationButtonText="Save Changes" />


      <ConfirmationAlertPopUp
        openModal={showPermissionsAlert}
        closeButtonText="Deny"
        text={`Permissions required to access ${permissionsFor}.
        After clicking grant a popup to Google will open where you can Authorize
        the requested permissions. `}
        functionality={() => {
          setShowhowPermissionsAlert(false);
          onPermissionGrant();
        }}
        closeModalFunc={(ev) => {
          setShowhowPermissionsAlert(false);
          setPermissionsFor("");
        }}
        title="Permissions Required"
        confirmationButtonColor="primary"
        confirmationButtonText="Grant" />

    </Modal>
  );
}

export default GoogleWorkSpaceConiguration;