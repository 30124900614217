import React, { Component } from 'react';
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from '../../../theme/theme';
import NotificationEditor from './notificationEditor';
import { default as ReactSelect } from 'react-select';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import { bindActionCreators } from 'redux';
import { Actions } from '../../../Redux/Actions';
import moment from 'moment';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MoreIcon from '../../../assets/images/icons/more.svg';
import NotificationIcon from '../../../assets/images/icons/notification.svg';
import SearchIcon from '../../../assets/images/icons/search.svg';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createNotification } from '../../../helpers';
import Pagination from 'react-js-pagination';
import { NotificationContainer } from 'react-notifications';
import { BrowserRouter as Router, Routes, Route, Link, withRouter } from 'react-router-dom';
import { Stack } from '@mui/material';
import { Modal } from 'react-responsive-modal';
import AddNotification from './addNotification';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { getProjectIdFromUrl } from '../../../helpers/common';
import ConfirmationAlertPopUp from '../../../Common/ConfirmationAlertPopUp';
import Loader from 'Components/Loader/loader';
import urlImg from '../../../../src/assets/images/icons/navigate-to.svg'
import Tooltip from '@material-ui/core/Tooltip'
import GoogleWorkspace from '../../../../src/assets/images/icons/Google_G_Logo.svg';




const content =
  "<h1 class='ql-align-center bold'><strong>Enter the Notification Heading</strong></h1><p class='ql-align-center'>Enter the notification description.</p>"

const customStyles = {
  option: (provided, state, styles) => ({
    ...provided,
    ...styles,
    backgroundColor: state.isSelected ? '#EDECF5' : 'none',
    position: 'relative',
    borderRadius: 4,
    marginBottom: 5,
  }),
  control: (styles) => ({
    ...styles,
    minHeight: 52,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingTop: '8px',
    paddingBottom: '8px',
  }),
  menu: (styles) => ({
    ...styles,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'
    return { ...provided, opacity, transition }
  },
}

const styles = {
  notiAvatar: {
    backgroundColor: '#16D1FC',
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: 8,
  },
};

class NotificationsDashboard extends Component {
  constructor(props) {
    super(props)
    this.isTotalRecords = true;
    this.permData = {}
    const userData = localStorage.getItem('user_details')
    const user = JSON.parse(userData)
    this.state = {
      totalResults: 0,
      page_no: 1,
      loading: false,
      redirect: true,
      listAllGroup: [],
      isGroupsComp: false,
      buttonAction: "Close Notification",
      itemCount: 10,
      startCount: 1,
      perms: user.permissions,
      searchText: '',
      limit: 10,
      setOpen: false,
      open: false,
      InviteUserModal: false,
      setShowResults: false,
      urlPath: '',
      buttonText: 'Got It',
      editProp: true,
      openDeleteModal: false,
      deleteNotiId: '',
      endCount: '',
      sort_by: '',
      status: 'all',
      order: 'asc',
      totalResults1: [],
      addNew: false,
      viewNotification: false,
      viewNotificationData: [],
      filterByStatus: {},
      sendPopup: false,
      openScheduleModal: false,
      allowed: {},
      openReplicateModal: false,
      notificationIdToReplicate: ''
    }
    // this.BUTTON_ACTION_DROP_DOWN = [
    //   {
    //     key: this.props.users.newLangState === 'en' ? englishData.engData.dropdowns.notifications.closeNot : this.props.users.newLangState === 'es' ? spanishData.spanData.dropdowns.notifications.closeNot : null,
    //     value: 'Close Notification'
    //   },
    //   {
    //     key: this.props.users.newLangState === 'en' ? englishData.engData.dropdowns.notifications.refreshPage : this.props.users.newLangState === 'es' ? spanishData.spanData.dropdowns.notifications.refreshPage : null,
    //     value: 'Refresh Page'
    //   },
    //   {
    //     key: this.props.users.newLangState === 'en' ? englishData.engData.dropdowns.notifications.external : this.props.users.newLangState === 'es' ? spanishData.spanData.dropdowns.notifications.external : null,
    //     value: 'External URL'
    //   },
    // ]
  }

  componentDidMount(prevProps, prevState) {
    const arr = window.location.pathname.split('/')
    const projectId = arr[arr.length - 1]
    this.setState({
      projectId
    }, () => {
      this.listRequest(projectId)
      this.listgroups()
    })

    if (this.state.itemCount < this.state.totalResults) {
      this.setState({
        endCount: parseInt(this.state.itemCount)
      });
    }
    this.setState({
      isTotalRecords: true
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading } = this.state
    if (prevState.itemCount !== this.state.itemCount) {
      if (this.state.itemCount < this.state.totalResults) {
        this.setState({
          endCount: parseInt(this.state.itemCount),
        });
      }
      else {
        this.setState({
          endCount: parseInt(this.props.projectNotifications.totalResults)
        })
      }
    }

    if (this.state.isTotalRecords || (this.props.projectNotifications.message !== prevProps.projectNotifications.message)) {
      this.setState({
        isTotalRecords: false
      }, () => {
        this.setState({
          totalResults: this.props.projectNotifications.totalResults
        }, () => {

          if (this.state.itemCount >= this.state.totalResults) {
            this.setState({
              endCount: parseInt(this.state.totalResults),
            });
          } else if (this.state.itemCount < this.state.totalResults && this.state.page_no === 1) {
            this.setState({
              endCount: parseInt(this.state.itemCount)
            });
          }
        });
        this.props.projectNotifications.success = false
      })
    }

    if (
      this.props.projectNotifications.success === true &&
      this.props.projectNotifications.message !==
      prevProps.projectNotifications.message
    ) {
      // finding if the selected service plan allows to create more notifications
      let userDetails = JSON.parse(localStorage.getItem('user_details'))
      let reqData = {
        servicePlanId: userDetails.servicePlanId,
        projectId: this.state.projectId
      }
      this.props.findIfAllowedFromSuperadminRequest(reqData)
      // finding if the selected service plan allows to create more notifications ends

      this.setState({
        totalResults: this.props?.projectNotifications?.totalResults,
        totalResults1: this.props.projectNotifications?.totalResults1,
        filterByStatus: this.props.projectNotifications?.filterByStatus,
        loading:false
      })
      if (this.state.itemCount >= this.props.projectNotifications.totalResults) {
        this.setState({
          endCount: this.props.projectNotifications.totalResults
        });
      } else {
        this.setState({
          endCount: this.state.totalResults > this.state.itemCount * this.state.page_no ? this.state.itemCount * this.state.page_no : this.state.totalResults,
        });
      }
    }

    if (
      this.props.projectNotifications.addSuccess === true &&
      this.props.projectNotifications.message !==
      prevProps.projectNotifications.message
    ) {
      this.setState({
        totalResults: this.props.projectNotifications?.notiCount,
        page_no: 1,
        startCount: 1,
        loading:false
      })
    }

    if (
      this.props.projectNotifications.searchSuccess === true &&
      this.props.projectNotifications.message !==
      prevProps.projectNotifications.message
    ) {
      createNotification('success', this.props.projectNotifications.message)
      this.setState({ totalResults: this.props.projectNotifications?.notiCount,loading:false })
    }

    if (
      this.props.attendees.getAttendeesGroupsSuccess === true &&
      this.props.attendees.message !==
      prevProps.attendees.message
    ) {
      this.setState({ listAllGroup: this.props.attendees.attendeesGroups })
    }


    if (
      this.props.projectNotifications.updateSuccess === true
      &&
      this.props.projectNotifications.message !==
      prevProps.projectNotifications.message
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null
      this.listRequest()
      if (loading === true) {
        this.handleLoading()
      }
      this.handleCloseDeleteModal()
    }

    if (
      this.props.projectNotifications.deleteSuccess === true &&
      prevProps.projectNotifications.deleteSuccess === false &&
      this.props.projectNotifications.message !==
      prevProps.projectNotifications.message
    ){
      createNotification('success', this.props.projectNotifications.message)
    }
    if (
      this.props.projectNotifications.replicateProjectNotificationSuccess === true &&
      prevProps.projectNotifications.replicateProjectNotificationSuccess === false &&
      this.props.projectNotifications.message !==
      prevProps.projectNotifications.message
    ){
      createNotification('success', this.props.projectNotifications.message)
    }
    if (
      this.props.projectNotifications.replicateProjectNotificationSuccess === true && !prevProps.projectNotifications.replicateProjectNotificationSuccess
      &&
      this.props.projectNotifications.message !==
      prevProps.projectNotifications.message
    ) {
      this.setState({
        notificationIdToReplicate: '',
        openReplicateModal: false
      })
      this.listRequest()
      this.setState({
        loading: false
      })
    }


    if (this.props.project?.project?.permissions) {
      let allowed = {};
      if (this.props.project.project.permissions.find(o => o.permission_name === 'Create Notification')) {
        allowed['Create Notification'] = true
      }
      if (this.props.project.project.permissions.find(o => o.permission_name === 'Edit Notification')) {
        allowed['Edit Notification'] = true
      }
      if (this.props.project.project.permissions.find(o => o.permission_name === 'Delete Notification')) {
        allowed['Delete Notification'] = true
      }
      // if (this.props.project.project.permissions.find(o => o.permission_name === 'Replicate Notification')) {
      //   allowed['Replicate Notification'] = true
      // }
      this.state.allowed = allowed;
    }
  }

  listgroups = () => {
    try {
      const projectId = getProjectIdFromUrl();
      const body = {
        projectId,
        page_no2: 1,
        searchText: ''
      }
      if (projectId) {
        this.props.listAttendeesGroups(body)
      } else {
        createNotification("error", "project Id is missing")
      }
    } catch (error) {
      createNotification("error", "Something went wrong")
    }
  }

  listRequest = (isSearching = false) => {
    const { page_no, limit, projectId, searchText, sort_by, status, sortOrder } = this.state
    let requestBody = {
      projectId,
      page_no,
      limit,
      searchText: isSearching ? searchText.trim() : '',
      sort_by,
      status,
      order: sortOrder,
    }
    this.props.listRequest(requestBody)
    if(!isSearching){
      this.setState({
        searchText: ''
      })
    }
    this.setState({
      loading:true
    })
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  sendPopupHandle = () => {
    this.setState({
      sendPopup: true
    })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSearchSubmit = (e) => {
    e.preventDefault()
    if (this.state.searchText !== '' && this.state.searchText.trim().length > 0) {
      const { searchText, page_no } = this.state
      const requestbody = { searchText, page_no }
      this.listRequest(true)
    } else {
      this.listRequest()
    }
  }

  deleteNotification = () => {
    const { deleteNotiId, projectId } = this.state
    let data = { deleteNotiId, permission: "Delete Notification", projectId , lngCode: this.props.users.newLangState}
    this.props.deleteRequest(data)
    this.setState({
      page_no: 1,
      startCount: 1,
    })
    this.handleLoading()
  }


  handlePageChange = (pageNumber) => {
    const { itemCount, totalResults } = this.state
    this.setState((prevState, props) => ({
      startCount: pageNumber !== 1 ? (itemCount * (pageNumber - 1) + 1) : 1,
      endCount: totalResults > itemCount * pageNumber ? itemCount * pageNumber : totalResults,
      page_no: pageNumber,
      totalResults: this.props.projectNotifications.totalResults
    }), () => {
      this.listRequest()
    });
  }

  onOpenModal = () => {
    this.setState({
      open: true,
      addNew: true
    })
  }

  openViewNotificationModel = (notification) => {
    this.setState({
      viewNotificationData: notification,
      viewNotification: true,
    })
  }

  closeViewNotificationModel = () => {
    this.setState({
      viewNotification: false,
    })
  }

  onCloseAddNotification = () => {
    this.setState({
      sendPopup: false,
    }, () => {
      setTimeout(() => {
        this.setState({
          open: false
        })
      }, 100)
      this.listRequest()
    })
  }

  handleCloseReviewModal = () => {
    this.setState({
      sendPopup: false,
    })
  }

  onCloseModal = () => {
    this.setState({
      open: false,
    })
    this.listRequest()
  }

  handleCloseDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
    });
  }

  handleOpenDeleteModal = (id) => {
    this.setState({
      openDeleteModal: true,
      deleteNotiId: id
    });
  }

  selectItemCount = (e) => {
    this.setState({
      itemCount: e.target.value,
      limit: e.target.value,
      page_no: 1,
      startCount: 1
    }, () =>
      this.listRequest()
    )
  }

  getPageNoFromAddNotification = (page_no) => {
    this.setState({
      page_no
    })
  }


  filterStatus = (status) => {
    this.setState({
      status,
      startCount:1,
      page_no:1
    }, () => this.listRequest())
  }

  sorting = (value) => {
    if (this.state.sort_by === value) {
      if (this.state.sortOrder === "asc") {
        this.setState({
          sortOrder: "desc"
        }, () => this.listRequest()
        )
      } else {
        this.setState({
          sortOrder: "asc"
        }, () => this.listRequest()
        )
      }
    } else {
      this.setState({
        sort_by: value,
        sortOrder: "asc"
      }, () => {
        this.listRequest()
      }
      )
    }
  }

  showSelectedGroup = (selectedGroups) => {
    return this.state.listAllGroup.filter(obj => selectedGroups.includes(obj._id)).map(val => ({
      label: val.attendeesGroupName,
      value: val._id,
    }))
  }

  showRestOfPages = (pages) => {
    return pages.slice(1, pages.length)
  }

  showRestOfGroups = (group) => {
    return group.slice(1, group.length)
  }

  editNotification = (id) => {
    this.props?.history?.push({ pathname: `/update-notifications/${id}/${this.state.projectId}`, state: { openScheduleModal: this.state.openScheduleModal, sendPopup: this.state.sendPopup , lngCode: this.props.users.newLangState }, function: { sendPopupHandle: this.sendPopupHandle, handleLoadingFromParent: this.handleLoading } })
  }

  handleReplicateNotification = () => {
    try {
      let data = {
        notificationId: this.state.notificationIdToReplicate,
        projectId: this.state.projectId,
        lngCode: this.props.users.newLangState
      }
      this.props.replicateNotification(data)
      this.setState({
        loading: true
      })
    } catch (error) {
      return error
    }
  }

  handleOpenReplicateModal = (id) => {
    this.setState({
      openReplicateModal: true,
      notificationIdToReplicate: id
    })
  }

  closeReplicateModal = () => {
    this.setState({
      openReplicateModal: false,
      notificationIdToReplicate: ''
    })
  }

  render() {
    const { notifications } = this.props.projectNotifications
    const { open, loading,
      isGroupsComp,
      startCount,
      endCount,
      viewNotificationData,
      itemCount,
      page_no,
      sortOrder,
      buttonText,
      buttonAction,
      sort_by,
      totalResults1,
      searchText,
      status,
      filterByStatus,
      allowed
    } = this.state

    const styles = {
      color: {
        width: '32px',
        height: '32px',
        borderRadius: '4px',
        position: 'absolute',
        top: '8px',
        left: '8px',
        background: `${viewNotificationData.primaryColor}`,
      },
      swatch: {
        position: 'relative',
      },

      popup: {
        borderColor: `${viewNotificationData.primaryColor}`,
      },

      button: {
        background: `${viewNotificationData.primaryColor}`,
      },
    }

    let sentCount = totalResults1.filter(
      (item) => item.status === 'sent',
    ).length

    let sechudledCount = totalResults1.filter(
      (item) => item.status === 'scheduled',
    ).length

    let draftCount = totalResults1.filter(
      (item) => item.status === 'draft',
    ).length

    let BUTTON_ACTION_DROP_DOWN = [
      {
        key: this.props.users.languageStateForRedux?.dropdowns?.notifications?.closeNot,
        value: 'Close Notification'
      },
      {
        key: this.props.users.languageStateForRedux?.dropdowns?.notifications?.refreshPage,
        value: 'Refresh Page'
      },
      {
        key: this.props.users.languageStateForRedux?.dropdowns?.notifications?.external,
        value: 'External URL'
      },
    ]

    return (
      <ThemeProvider theme={theme}>
        <Grid container spacing={1.25} mb={2} className="top-filter-area">
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Box
                  component={Button}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.background.light,
                    color: theme.palette.common.black,
                    textTransform: 'capitalize !important',
                    fontWeight: '400',
                    '&:hover, &.active': {
                      backgroundColor: '#000056 !important',
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  className={status === 'all' ? 'active' : ''}
                  size="small"
                  disableElevation
                  onClick={() => this.filterStatus('all')}
                >
                  {this.props.users.languageStateForRedux?.column_names?.notifications?.allNotifications} ({filterByStatus.all || 0})
                </Box>
              </Grid>
              <Grid item>
                <Box
                  component={Button}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.background.light,
                    color: theme.palette.common.black,
                    textTransform: 'capitalize !important',
                    fontWeight: '400',
                    '&:hover, &.active': {
                      backgroundColor: '#000056 !important',
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  className={status === 'scheduled' ? 'active' : ''}
                  size="small"
                  disableElevation
                  onClick={() => this.filterStatus('scheduled')}
                >
                  {this.props.users.languageStateForRedux?.common?.scheduled} ({filterByStatus.scheduled || 0})
                </Box>
              </Grid>
              <Grid item>
                <Box
                  component={Button}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.background.light,
                    color: theme.palette.common.black,
                    textTransform: 'capitalize !important',
                    fontWeight: '400',
                    '&:hover, &.active': {
                      backgroundColor: '#000056 !important',
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  className={status === 'sent' ? 'active' : ''}
                  size="small"
                  disableElevation
                  onClick={() => this.filterStatus('sent')}
                >
                  {this.props.users.languageStateForRedux?.common?.sent} ({filterByStatus.sent || 0})
                </Box>
              </Grid>
              <Grid item>
                <Box
                  component={Button}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.background.light,
                    color: theme.palette.common.black,
                    textTransform: 'capitalize !important',
                    fontWeight: '400',
                    '&:hover, &.active': {
                      backgroundColor: '#000056 !important',
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  className={status === 'draft' ? 'active' : ''}
                  size="small"
                  disableElevation
                  onClick={() => this.filterStatus('draft')}
                >
                  {this.props.users.languageStateForRedux?.common?.draft} ({filterByStatus.draft || 0})
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1.25} className="team-dash-right">
              <Grid
                component={'form'}
                item
                onSubmit={this.handleSearchSubmit}
                className=""
              >
                <TextField
                  name="searchText"
                  placeholder={this.props.users.languageStateForRedux?.common?.search}
                  className="search"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img alt="" src={SearchIcon} />
                      </InputAdornment>
                    ),
                  }}
                  value={searchText}
                  onChange={this.handleChange}
                  onSubmit={this.handleSearchSubmit}
                />
              </Grid>

              {allowed['Create Notification'] &&
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.onOpenModal}
                  >
                    {this.props.users.languageStateForRedux?.buttons?.notifications?.add} 
                  </Button>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>

        <TableContainer className="list-table" style={{ maxHeight: 'calc(100vh - 380px)', }}>
          <Table className="table">
            <TableHead style={{ position: 'sticky', top: '0', backgroundColor: '#f6f6fc', zIndex: 2, }}>
              <TableRow>
                <TableCell
                  className={
                    'name-avatar sticky-cell ' +
                    (sort_by === 'title'
                      ? sortOrder === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                  onClick={() => this.sorting('title')}
                >
                  {this.props.users.languageStateForRedux?.column_names?.notifications?.title} 
                </TableCell>
                <TableCell>{this.props.users.languageStateForRedux?.column_names?.notifications?.pagesSelected} </TableCell>
                <TableCell>{this.props.users.languageStateForRedux?.column_names?.notifications?.sendWhere} </TableCell>
                <TableCell className="button-action">{this.props.users.languageStateForRedux?.column_names?.notifications?.clickButtonAction} </TableCell>
                <TableCell
                  className={
                    'status ' +
                    (sort_by === 'status'
                      ? sortOrder === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                  onClick={() => this.sorting('status')}
                >
                  {this.props.users.languageStateForRedux?.common?.status} 
                </TableCell>
                <TableCell
                  className={
                    'date-time ' +
                    (sort_by === 'notificationDate'
                      ? sortOrder === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                  onClick={() => this.sorting('notificationDate')}
                >
                  {this.props.users.languageStateForRedux?.column_names?.notifications?.dateTime} 
                </TableCell>
                <TableCell className="options">{this.props.users.languageStateForRedux?.common?.action}</TableCell>
              </TableRow>
            </TableHead>
            {notifications &&
              Array.isArray(notifications) && this.props.projectNotifications?.listProjectNotificationsSuccess &&
              notifications?.length > 0 ? (
              <TableBody>
                {notifications &&
                  Array.isArray(notifications) &&
                  notifications.map((notification, index) => {
                    return (
                      <TableRow className="notification-titlen" key={index}>
                        <TableCell className="name-avatar sticky-cell">
                          <Grid container spacing={2} alignItems="center">
                            <Grid item>
                              <Avatar style={styles.notiAvatar}>
                                <img alt="" src={NotificationIcon} />
                              </Avatar>
                            </Grid>
                            <Grid item xs className='long-text'>
                              {notification.status !== 'sent' ? (
                                <Link
                                  style={{ textDecoration: 'none' }}
                                  to={`/update-notifications/${notification._id}/${this.state.projectId}`}
                                >
                                  <>
                                    <strong>
                                      {notification?.title?.trim()}
                                    </strong>
                                  </>
                                </Link>
                              ) : (
                                <strong>{notification?.title?.trim()}</strong>
                              )}{' '}
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems={'center'}
                            spacing={1}
                          >
                            {notification?.sendToPagesOption === "Pages" ? (
                              <>
                                <span className='long-text' style={{maxWidth:"80%",}}>{notification?.displayOnPages[0] && notification?.displayOnPages[0]['label']}</span>
                                {notification.displayOnPages.length > 1 &&
                                  <div className="detail-popup">
                                    <span className="count">
                                      +{notification.displayOnPages.length - 1}
                                    </span>
                                    <div className="info">
                                      {this.showRestOfPages(notification?.displayOnPages)?.map((page, i) => {
                                        return (
                                          <div key={i}>
                                           <p>
                                            {page.label}
                                           </p>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                }
                              </>
                            ) : ("All")
                            }
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems={'center'}
                            spacing={1}
                          >
                            {notification?.sendToGroupsOption === "Groups" ? (
                              <> 
                                {notification?.groups[0]?.source === "GoogleWorkspace" && <img src={GoogleWorkspace} alt="google workspace" width="14px" style={{marginRight: "5px"}}/>}
                                <span  className='long-text' style={{maxWidth:"70%",}}>{notification?.groups[0] && notification?.groups[0]['attendeesGroupName']}</span>
                                {notification.groups.length > 1 &&
                                  <div className="detail-popup">
                                    <span className="count">
                                      +{notification.groups.length - 1}
                                    </span>
                                    <div className="info">
                                      {this.showRestOfGroups(notification?.groups)?.map((group, i) => {
                                        return (
                                          <div key={i}>
                                             <span>
                                              {group.source === "GoogleWorkspace" && <img src={GoogleWorkspace} alt="google workspace" width="14px" style={{marginRight: "5px"}}/>}
                                              {group.attendeesGroupName}
                                             </span>

                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                }
                              </>
                            ) : ("All")
                            }
                          </Stack>
                        </TableCell>
                        <TableCell className="button-action">
                          {notification?.buttonAction === "External URL" ?
                           <>
                           {notification?.buttonAction?.trim()}
                           <Tooltip
                             title={notification.externalURL}
                             placement="top"
                             arrow
                           >
                             <Box ml={2} component={'a'} target='_blank' href={notification.externalURL}>
                               <img alt="url-img" src={urlImg} />{' '}
                             </Box>
                           </Tooltip>
                         </>
                         :
                         notification?.buttonAction?.trim()
                          }
                        </TableCell>
                        <TableCell className="status">
                          {' '}
                          <span
                            className={`table-status ${notification.status === 'sent'
                              ? 'success'
                              : notification.status === 'scheduled'
                                ? 'table-status warning'
                                : 'table-status light'
                              }`}
                          >
                            {' '}
                            {notification.status === 'scheduled'
                              ? this.props.users.languageStateForRedux?.common?.scheduled
                              : notification.status === 'sent'
                                ? this.props.users.languageStateForRedux?.common?.sent
                                : this.props.users.languageStateForRedux?.common?.draft}{' '}
                          </span>{' '}
                        </TableCell>
                        <TableCell className="date-time">
                          {moment
                            .tz(
                              notification.notificationDate,
                              this.props.project?.project?.timezone,
                            )
                            .format( 'ddd, MMM, Do YYYY, h:mm A',)}
                        </TableCell>
                        <TableCell className="options">
                          <>
                            {notification.status !== 'sent' && (allowed['Edit Notification'] || allowed['Delete Notification']) &&
                              <>
                                {' '}
                                <div className="options-button">
                                  <img alt="" src={MoreIcon} />{' '}
                                </div>
                                <div className="options-list">
                                  {allowed['Edit Notification'] &&
                                    <div
                                      onClick={() => this.editNotification(notification._id)}
                                    >
                                      {this.props.users.languageStateForRedux?.actions?.notifications?.edit}
                                    </div>
                                  }
                                  {allowed['Delete Notification'] &&
                                    <div
                                      onClick={() =>
                                        this.handleOpenDeleteModal(notification._id)
                                      }
                                      style={{ color: theme.palette.secondary.main, }}
                                    >
                                       {this.props.users.languageStateForRedux?.common?.delete}
                                    </div>
                                  }
                                  {allowed['Create Notification'] && <div
                                    onClick={() =>
                                      this.handleOpenReplicateModal(notification._id)
                                    }
                                  >
                                      {this.props.users.languageStateForRedux?.actions?.notifications?.replicate}
                                  </div>}
                                </div>
                              </>
                            }
                            {notification.status === 'sent' && (
                              <>
                                {' '}
                                <div className="options-button">
                                  <img alt="" src={MoreIcon} />{' '}
                                </div>
                                <div className="options-list">
                                  <div
                                    onClick={() =>
                                      this.openViewNotificationModel(notification)
                                    }
                                  >
                                    {this.props.users.languageStateForRedux?.actions?.notifications?.view}
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            ) : ( !this.state.loading && notifications?.length === 0 && this.props.projectNotifications?.listProjectNotificationsSuccess &&
              (<TableBody>
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }}>
                  {this.props.users.languageStateForRedux?.common?.noRecords}
                  </TableCell>
                </TableRow>
              </TableBody>)
            )}
          </Table>
        </TableContainer>
          {notifications &&
            Array.isArray(notifications) &&
            notifications?.length > 0 && (
              <>
                {!isGroupsComp &&
                  Array.isArray(this.props.projectNotifications.notifications) ? (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    className="tablePagination"
                  >
                    <Grid item>
                      <Box component="span" className="small-subtitle">
                      {this.props.users.languageStateForRedux?.pagination?.showing} {startCount} {this.props.users.languageStateForRedux?.pagination?.to} {endCount} {this.props.users.languageStateForRedux?.pagination?.of} {' '}
                        {this.props.projectNotifications.totalResults}
                      </Box>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center">
                        <Grid item>
                          <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.shows}</span>
                          <select
                            value={itemCount}
                            onChange={this.selectItemCount}
                          >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                          <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.entries}</span>
                        </Grid>
                        <Grid item>
                          <Pagination
                            activePage={this.state.page_no}
                            itemsCountPerPage={this.state.itemCount}
                            totalItemsCount={
                              this.props.projectNotifications.totalResults
                            }
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                            prevPageText={
                              <NavigateBeforeIcon style={{ fontSize: 18 }} />
                            }
                            nextPageText={
                              <NavigateNextIcon style={{ fontSize: 18 }} />
                            }
                            itemClassFirst="first d-none"
                            itemClassLast="last d-none"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </>
            )}

        <Modal
          open={open}
          onClose={this.onCloseModal}
          classNames={{
            overlay: 'add-notification_overlay',
            modal: 'add-notification_modal modal-default-close-none',
          }}
          center
        >
          <AddNotification
            onCloseModal={this.onCloseModal}
            listRequest={this.listRequest}
            limit={this.state.limit}
            sendPopup={this.state.sendPopup}
            sendPopupHandle={this.sendPopupHandle}
            onCloseAddNotification={this.onCloseAddNotification}
            loading={this.state.loading}
            handleLoadingFromParent={this.handleLoading}
            handleCloseReviewModal={this.handleCloseReviewModal}
            lngCode={this.props.users.newLangState}
          />
        </Modal>

        <Modal
          open={this.state.openDeleteModal}
          onClose={this.handleCloseDeleteModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Box component={Grid} container className="" px={1.5}>
            <Box component={Grid} mt={-1} item xs={12} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {' '}
                {this.props.users.languageStateForRedux?.common?.confirmDelete}
              </Box>
              <p className="small-subtitle">{this.props.users.languageStateForRedux?.common?.deleteMsg}</p>
            </Box>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  {
                    <>
                      <Button
                        variant="contained"
                        color="secondary"
                        disableElevation
                        onClick={() => this.deleteNotification()}
                      >
                        {this.props.users.languageStateForRedux?.common?.delete}
                      </Button>
                      <Button
                        variant="outlined"
                        disableElevation
                        onClick={this.handleCloseDeleteModal}
                      >
                        {this.props.users.languageStateForRedux?.common?.cancel}
                      </Button>
                    </>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={this.state.viewNotification}
          onClose={this.closeViewNotificationModel}
          classNames={{
            overlay: 'add-notification_overlay',
            modal: 'add-notification_modal view-notification_modal',
          }}
          center
        >
          <Grid container justifyContent="end" >
            <Grid item xs={12} md={6} lg={8} xl={8} className="add-notification_modal__preview ">
              <div class="notification-wraper ql-snow">
                <div
                  style={styles.popup}
                  className={`notification-popup ql-editor ${viewNotificationData.layoutType}`}
                >
                  <div>{parse(`${viewNotificationData.description}`)}</div>
                  <div className="text-center">
                    <button style={styles.button}>{viewNotificationData.buttonText}</button>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={4} className='add-notification_modal__form'>
              <Box component='div' className='full-screen_modal__form_header'>

                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <p className='subtitle'>{this.state.urlPathname === 'viewNoti' ? 'Edit Notification' : this.props.users.languageStateForRedux?.actions?.notifications?.view}</p>
                  </Grid>
                  <Grid item>
                    <CloseIcon
                      onClick={this.props.onCloseModal}
                      className="modal-close-button"
                    />
                  </Grid>
                </Grid>
              </Box>
              <form >
                <Box component='div' className="add-notification_modal__form_body">
                  <Box px={3} pt={2} pb={4}>
                    <Box component={Grid} rowSpacing={4} columnSpacing={2.75} container className="inputField">
                      <Grid item xs={12}>
                        <label>{this.props.users.languageStateForRedux?.formfields?.notifications.title}<span className='required'>*</span></label>
                        <input
                          type="text"
                          class="form-control"
                          name="title"
                          placeholder=""
                          disabled={true}
                          value={viewNotificationData.title}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <label>{this.props.users.languageStateForRedux?.formfields?.notifications?.layout}</label>
                        <div className="layout__possition">
                          <span
                            className={`layout__possition_center ${viewNotificationData.layoutType === 'center' ? 'active' : null
                              }`}
                          ></span>
                          <span
                            className={`layout__possition_bottom-left ${viewNotificationData.layoutType === 'bottom-left' ? 'active' : null
                              }`}
                          ></span>
                          <span
                            className={`layout__possition_bottom-right ${viewNotificationData.layoutType === 'bottom-right' ? 'active' : null
                              }`}
                          ></span>
                          <span
                            className={`layout__possition_top-left ${viewNotificationData.layoutType === 'top-left' ? 'active' : null
                              }`}
                          ></span>
                          <span
                            className={`layout__possition_top-right ${viewNotificationData.layoutType === 'top-right' ? 'active' : null
                              }`}
                          ></span>
                        </div>
                      </Grid>
                    </Box>
                  </Box>
                  <div>
                    <NotificationEditor
                      description={viewNotificationData.description}
                      notEditable={true}
                    />
                  </div>
                  <Box px={3} pt={2} pb={4}>
                    <Box component={Grid} rowSpacing={4} columnSpacing={2.75} container className="inputField">
                      <Grid item xs={6}>
                        <label>{this.props.users.languageStateForRedux?.formfields?.notifications?.buttonText}</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          value={viewNotificationData.buttonText}
                          name="buttonText"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <label>{this.props.users.languageStateForRedux?.formfields?.notifications?.buttonAction}</label>
                        <select
                          class="form-control"
                          name="buttonAction"
                          value={viewNotificationData.buttonAction}
                          disabled={true}
                        >
                          {BUTTON_ACTION_DROP_DOWN.map((action) => (
                            <option key={action.key} value={action.value}>
                              {action.key}
                            </option>
                          ))}
                        </select>
                      </Grid>
                      {viewNotificationData.buttonAction === 'External URL' ? (
                        <Grid item xs={12}>
                          <label>{this.props.users.languageStateForRedux?.dropdowns?.agenda?.external}</label>
                          <input
                            type="string"
                            class="form-control"
                            placeholder=""
                            value={viewNotificationData.externalURL}
                            disabled={true}
                          />
                        </Grid>
                      ) : null}

                      <Grid item xs={6}>
                        <label>{this.props.users.languageStateForRedux?.formfields?.notifications?.color}</label>
                        <div className="color-swatch">
                          <div style={styles.swatch} >
                            <input
                              type="text"
                              class="form-control"
                              value={viewNotificationData.primaryColor}
                              placeholder=""
                              disabled={true}
                            />
                            <div style={styles.color} />
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <label>{this.props.users.languageStateForRedux?.formfields?.notifications?.sendPages}</label>
                        <select
                          type="text"
                          class="form-control small"
                          value={viewNotificationData.sendToPagesOption}
                          name="sendToPages"
                          disabled={true}
                        >
                          <option>{this.props.users.languageStateForRedux?.common?.all}</option>
                          <option>{this.props.users.languageStateForRedux?.dropdowns?.agenda?.pages}</option>
                        </select>
                      </Grid>
                      {viewNotificationData.sendToPagesOption === "Pages" && <Grid item xs={12}>
                        <label>{this.props.users.languageStateForRedux?.formfields?.notifications?.showSelected} <span className='required'></span></label>
                        <ReactSelect
                          styles={customStyles}
                          className="custom-multi-select"
                          options={viewNotificationData.sendToPagesOption}
                          isMulti
                          isDisabled
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          value={viewNotificationData.displayOnPages}
                        />
                      </Grid>}

                      <Grid item xs={12}>
                        <label>{this.props.users.languageStateForRedux?.formfields?.notifications?.sendGroups}</label>
                        <select
                          type="text"
                          class="form-control small"
                          value={viewNotificationData.sendToGroupsOption}
                          name="sendToGroups"
                          disabled={true}
                        >
                          <option>{this.props.users.languageStateForRedux?.common?.all}</option>
                          <option>{this.props.users.languageStateForRedux?.dropdowns?.agenda?.groups}</option>
                        </select>
                      </Grid>

                      {viewNotificationData.sendToGroupsOption === "Groups" && <Grid item xs={12}>
                        <Box component="div" style={{ position: 'relative', }} className='inputField' >

                          {viewNotificationData.sendToGroupsOption === "Groups" && <Grid item xs={12}>
                            <label>{this.props.users.languageStateForRedux?.dropdowns?.agenda?.selectGroups}</label>
                            <ReactSelect
                              styles={customStyles}
                              className="custom-multi-select"
                              options={viewNotificationData.sendToGroupsOption}
                              isMulti
                              isDisabled
                              closeMenuOnSelect={false}
                              isSearchable={false}
                              value={this.showSelectedGroup(viewNotificationData.groups)}

                            />
                          </Grid>}
                        </Box>
                      </Grid>}
                    </Box>
                  </Box>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Modal>
        <NotificationContainer />
        <ConfirmationAlertPopUp
          openModal={this.state.openReplicateModal}
          closeModalFunc={this.closeReplicateModal}
          title={this.props.users.languageStateForRedux?.common?.confirmReplicate}
          text={this.props.users.languageStateForRedux?.actions?.notifications?.textMsg}
          confirmationButtonText={this.props.users.languageStateForRedux?.common?.replicate}
          confirmationButtonColor="primary"
          closeButtonText={this.props.users.languageStateForRedux?.common?.cancel}
          functionality={this.handleReplicateNotification}
        />
        {this.state.loading ? <Loader /> : null}
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  projectNotifications: state.ProjectNotifications,
  projects: state.Projects,
  events: state.Events,
  attendees: state.Attendees,
  users: state.Users,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listRequest: Actions.listProjectNotificationsRequest,
      deleteRequest: Actions.deleteProjectNotificationRequest,
      searchRequest: Actions.searchProjectNotificationRequest,
      csvRequest: Actions.csvEventsRequest,
      listAttendeesGroups: Actions.listAttendeesGroupsRequest,
      replicateNotification: Actions.replicateProjectNotificationRequest,
      findIfAllowedFromSuperadminRequest: Actions.findIfAllowedFromSuperadminRequest
    },
    dispatch,
  )
export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withRouter(NotificationsDashboard))
