import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  eventsRequest: ['data'],
  eventsSuccess: ['event', 'message'],
  eventsError: ['error'],
  listEventsRequest: ['data'],
  listEventsSuccess: ['totalResults', 'event', 'message', 'allEvents', 'projectTypeCnt', 'eventType'],
  eventsListSuccess: ['totalResults', 'event', 'message', 'allEvents','filterByEvent'],
  listEventsError: ['error'],
  listSingleEventRequest: ['data'],
  listSingleEventSuccess: ['event', 'templates', 'message'],
  listSingleEventError: ['error'],
  eventEmailRequest: ['data'],
  eventEmailSuccess: ['message'],
  eventEmailError: ['error'],
  updateEventsRequest: ['data'],
  updateProjectRequest: ['data'],
  updateEventsSuccess: ['message'],
  updateEventsError: ['error'],
  searchEventsRequest: ['data'],
  searchEventsSuccess: ['totalResults', 'event', 'message'],
  searchEventsError: ['error'],
  csvEventsRequest: ['data'],
  csvEventsSuccess: ['csvFile', 'message'],
  csvEventsError: ['error'],
  emailReportsRequest: ['data'],
  emailReportsSuccess: ['reports', 'message'],
  emailReportsError: ['error'],
  emailDataReportsRequest: ['data'],
  emailDataReportsSuccess: ['reports', 'monthReports', 'message'],
  emailDataReportsError: ['error'],
  emailRegistrationDataReportsRequest: ['data'],
  emailRegistrationDataReportsSuccess: ['reports', 'monthReports', 'message'],
  emailRegistrationDataReportsError: ['error'],
  checkEventDomainRequest: ['data'],
  checkEventDomainSuccess: ['message'],
  checkEventDomainError: ['error'],
  getEventSummaryDetailsRequest: ['data'],
  getEventSummaryDetailsSuccess: ['message', 'summaryDetails'],
  getEventSummaryDetailsError: ['error'],
  deleteProjectRequest: ['data'],
  deleteProjectSuccess: ['message'],
  deleteProjectError: ['message'], 
  replicateProjectRequest: ['data'],
  replicateProjectError: ['message'],  
  replicateProjectSuccess: ['message'], 
  updateLngCodeSuccess: ['message'],
  updateLngCodeRequest: ['data'],
  updateLngCodeError:['error'],
  handleDoContinueState: ['data'], 
  handleSetErrorToFalse: [''],
  handleProjectType: ['projectType'],
  showCommonPopup: ['data']
}) 

export const Constants = Types
export default Creators
