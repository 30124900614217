import { call, put } from 'redux-saga/effects'
import { Actions } from '../../Actions'
import TawkToAPI from '../../API/TawkToAPI'

function* getProperties() {
  const tawkToAPI = new TawkToAPI()
  const response = yield call(tawkToAPI.PropertyList, [])
  if (response.status === 200) {
    if (response.data.status === 200) {
      yield put(
        Actions.tawkToGetPropertiesSuccess(
          response.data.properties,
          response.data.message,
        ),
      )
    } else {
      yield put(Actions.tawkToGetPropertiesFailure(response.data.error))
    }
  } else {
    yield put(Actions.tawkToGetPropertiesFailure(response.data.error))
  }
}

function* createProperty(data) {
  const tawkToAPI = new TawkToAPI()
  const response = yield call(tawkToAPI.createProperty, data)
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.tawkToCreatePropertySuccess(
          response.data.integration,
          response.data.message,
        ),
      )
    } else {
      yield put(Actions.tawkToCreatePropertyFailure(response.data.message))
    }
  } else {
    yield put(Actions.tawkToCreatePropertyFailure(response.data.error))
  }
}

function* getWidget(action) {
  const tawkToAPI = new TawkToAPI()
  const response = yield call(tawkToAPI.getWidget, action)
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.tawkToGetWidgetSuccess(
          response.data.widget,
          response.data.message,
        ),
      )
    } else {
      yield put(Actions.tawkToGetWidgetFailure(response.data.message))
    }
  } else {
    yield put(Actions.tawkToGetWidgetFailure(response.data.error))
  }
}

function* updateWidget(widget) {
  const tawkToAPI = new TawkToAPI()
  const response = yield call(tawkToAPI.updateWidget, widget) 
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.tawkToUpdateWidgetSuccess(
          response.data.widget,
          response.data.message,
        ),
      )
    } else {
      yield put(Actions.tawkToUpdateWidgetFailure(response.data))
    }
  } else {
    yield put(Actions.tawkToUpdateWidgetFailure(response.data))
  }
}
const tawkToSaga = { getProperties, createProperty, getWidget, updateWidget }
export default tawkToSaga
