import React, { Component } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../../theme/theme'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import 'react-multi-carousel/lib/styles.css'
import ProfileImageAndName from './ProfileImageAndName'
import DashboardProfilePassword from './DashboardProfilePassword'
import ProfileLogout from './ProfileLogout'
import PersonalInfo from './PersonalInfo/PersonalInfo'
import axios from 'axios'
const URL = process.env.REACT_APP_API_URL

const styles = {
    customTab: {
        minHeight: 42,
        padding: '12px 16px',
        color: '#FFFFFF',
        textTransform: 'capitalize',
    },
    customTabPanel: {
        padding: 0,
    },

    userAvatar: {
        backgroundColor: '#FF4170',
        color: '#ffffff',
        border: '1px solid #ffffff',
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
}
export class Index extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: '1',
        }
    }

    async componentDidMount() {
        let storage = localStorage.getItem('user_details')
        if (storage && storage !== 'undefined') {
            const user = JSON.parse(storage)
            this.getUserDetails(user._id)
        }
    }

    getUserDetails = async (userId) => {
        const response = await axios.get(`${URL}/getUserById?userId=${userId}`)
        if (response.status === 200) {
            const userDetails = response.data.data
            this.setState({
                userDetails
            })
        }  
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        })
    }

    handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    render() {
        const { userDetails } = this.state
        return (
            <ThemeProvider theme={theme} style={{}}>
                <Box className="site-profile">
                    <ProfileLogout />
                    <Box mt={-28}>
                        {userDetails && <ProfileImageAndName userDetails={userDetails}/>}
                        <TabContext value={this.state.value}>
                            <Box sx={{ borderColor: 'divider' }}>
                                <TabList
                                    style={styles.customTabList}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    onChange={this.handleChange}
                                    aria-label="lab API tabs example"
                                >
                                    <Tab
                                        style={styles.customTab}
                                        label="Personal info"
                                        value="1"
                                    ></Tab>
                                    <Tab style={styles.customTab} label="Password" value="2">
                                        {' '}
                                    </Tab>
                                    <Tab
                                        style={styles.customTab}
                                        disabled
                                        label="Activity"
                                        value="3"
                                    >
                                        {' '}
                                    </Tab>
                                </TabList>
                            </Box>
                            <Box component={Grid} container justifyContent={'center'}>
                                <Grid item xs={12} sm={10} md={6} lg={4} xl={3}>
                                    {userDetails && <TabPanel style={styles.customTabPanel} value="1">
                                        <PersonalInfo userDetails={userDetails} />
                                    </TabPanel>}
                                    <TabPanel style={styles.customTabPanel} value="2">
                                        <DashboardProfilePassword />
                                    </TabPanel>
                                </Grid>
                            </Box>
                        </TabContext>
                    </Box>
                </Box>
            </ThemeProvider>
        )
    }
}

export default Index