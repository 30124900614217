import ProjectIcon from '../../assets/images/icons/step1-icon1.svg';
import ProjectIconInPerson from '../../assets/images/icons/step1-icon2.svg';
import ProjectIconSale from '../../assets/images/icons/step1-icon3.svg';
import ProjectIconRegistration from '../../assets/images/icons/step1-icon4.svg';
import ProjectIconEmail from '../../assets/images/icons/step1-icon5.svg';
import ProjectIconVirtual from '../../assets/images/icons/step1-icon6.svg';

export const projectType = (key,languageCodeForRedux) => {
    switch (key[0]) {
      case "61b1bcf7a72cac2a825c4910":
        return (languageCodeForRedux?.hybrid)
        break;

      case "61b1be73a72cac2a825c4911":
        return (languageCodeForRedux?.inPerson)
        break;
      case "61b1cac4a72cac2a825c4917":
        return (languageCodeForRedux?.sales)
        break;
      case "61b1cae9a72cac2a825c4918":
        return (languageCodeForRedux?.registration)
        break;
      case "61b1cb1ba72cac2a825c4919":
        return (languageCodeForRedux?.email)
        break;
      case "61b1cb4ea72cac2a825c491a":
        return (languageCodeForRedux?.vitual)
        break;


      default:
        break;
    }
  }

  export const  projectIcon = (key) => {
    switch (key[0]) {
      case "61b1bcf7a72cac2a825c4910":
        return ProjectIcon
        break;

      case "61b1be73a72cac2a825c4911":
        return ProjectIconInPerson
        break;
      case "61b1cac4a72cac2a825c4917":
        return ProjectIconSale
        break;
      case "61b1cae9a72cac2a825c4918":
        return ProjectIconRegistration
        break;
      case "61b1cb1ba72cac2a825c4919":
        return ProjectIconEmail
        break;
      case "61b1cb4ea72cac2a825c491a":
        return ProjectIconVirtual
        break;


      default:
        break;
    }
  }

  export const projectIconColor = (key) => {
    switch (key[0]) {
      case "61b1bcf7a72cac2a825c4910":
        return "project-icon-hybrid"
        break;

      case "61b1be73a72cac2a825c4911":
        return "project-icon-in-person"
        break;
      case "61b1cac4a72cac2a825c4917":
        return "project-icon-sale"
        break;
      case "61b1cae9a72cac2a825c4918":
        return "project-icon-registration"
        break;
      case "61b1cb1ba72cac2a825c4919":
        return "project-icon-email"
        break;
      case "61b1cb4ea72cac2a825c491a":
        return "project-icon-virtual"
        break;
      default:
        break;
    }
  }