export const getUnLayerConfig = (apiUrl, eventId) => ({
  id: 'pageDesignEditor',
  displayMode: 'web',
  tools: {
    form: {
      properties: {
        action: {
          editor: {
            data: {
              actions: [
                {
                  label: 'Login',
                  method: 'POST',
                  url: `${apiUrl}/events/${eventId}/login`,
                },
              ],
            },
          },
        },
      },
    },
  },
})
