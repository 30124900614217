import BaseAPI from '../baseAPI';


class OpportunityAudienceAPI extends BaseAPI {
    fetchAllOpportunityAudience = async (params: object) => {
        return this.API('/audience/listAudience', {
            method: 'GET',
            params
        });
    };

    deleteOpportunityAudience = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('/audience/deleteAudience', {
            method: 'DELETE',
            data: bodyParams,
            params: queryParams
        });
    };


    createOpportunityAudience = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('/audience/createAudience', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        });
    };


    editOpportunityAudience = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('/audience/editAudience', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        });
    };

    assignContactList = async (params: object) => {
        return this.API('/opportunityManagement/contacts/getContactsListToAddInOrRemoveFromGroup', {
            method: 'GET',
            params
        });
    };

    getAllOpportunityGroups = async (params: object) => {
        return this.API('/audience/getGroupsForFilter', {
            method: 'GET',
        });
    };

}

export default OpportunityAudienceAPI;