import React, { Component } from 'react'
import '../../sass/main.scss'
import DashboardLayout from '../../Layouts/DashboardLayout'
import Button from '@material-ui/core/Button'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import moment1 from 'moment-timezone'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification, redirectTo } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
// import {
//   KeyboardTimePicker,
// } from '@material-ui/pickers';
import TimePicker from 'react-time-picker'
import { sampleEvent, emailTemplateJson } from './sampleEvent'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import gapiClient from 'gapi-client'
import EventRegistartion from './eventRegistration'
import XpEventPlatform from './xpEventPlatform'
import { Redirect } from 'react-router-dom'

class CreateEvent extends Component {
  constructor(props) {
    super(props)
    const userData = localStorage.getItem('user_details');
    const user = JSON.parse(userData);
    this.state = {
      activeStep: 0,
      Questionvalue: 0,
      Tabvalue: 0,
      theme: '',
      title: 'Create Project',
      pageTitle: 'Create Project',
      eventType: 'registration',
      perms: user.permissions
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleLoading = () => {
    
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  async componentDidMount() {
    navigator.geolocation.getCurrentPosition(function (position) { 
      if (position.coords.latitude) {
        
      }
    })
    this.permData = this.getPreparePermission(this.state.perms);
    let isExists = true;
    if(this.permData){
      Object.keys(this.permData).forEach((data) => { 
        if(['create project'].indexOf(data.toLowerCase()) !== -1){
          isExists = false;
        }
      });

      if(isExists){
        createNotification('error', "Unauthorized to access this resource");
      }

      setTimeout(() => {
        // this.state.redirect = isExists;
        this.setState({redirect: isExists})
      }, 1000);
    }
  }

  getPreparePermission(state){
    let perms = {};
    if(Array.isArray(state)){
      state.forEach((data) => {
        perms[data.permission_name] = true
      })
    }
    return perms;
  }

  handleChangeLocation = (location) => { 
    this.setState({ location: location })
  }
  handleSelect = (location) => {
    this.setState({ location })

    geocodeByAddress(location)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => { 
        this.setState({ lat: latLng.lat, lng: latLng.lng })
      })
      .catch((error) => console.error('Error', error))
  }

  addEventToCalendars = (eventData, date) => { 
    let startTime = moment(`${eventData.startDate}:00.000Z`)
      .add('8', 'hours')
      .format()
    let endTime = moment(`${eventData.endDate}:00.000Z`)
      .add('8', 'hours')
      .add('30', 'minutes')
      .format() 

    var gapi = window.gapi 
    var CLIENT_ID =
      '711204863226-kg8eoih74guqc93bm4rpfchnpe37p3hh.apps.googleusercontent.com'
    var API_KEY = 'yBmczulIMZE2ShABqGi5vPOt'
    var SCOPES = 'https://www.googleapis.com/auth/calendar'
    var DISCOVERY_DOCS = [
      'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
    ]

    gapi.load('client:auth2', () => { 
      gapiClient.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      }) 
    
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          var event = {
            summary: `${eventData.title} - ${moment(`${eventData.startDate}`, [
              'HH:mm',
            ]).format('hh:mm A')} PST`,
            // location: "illumeet square",
            description: `${eventData.google_meet}`,
            start: {
              dateTime: startTime,
              timeZone: moment1.tz.guess(),
            },
            end: {
              dateTime: endTime,
              timeZone: moment1.tz.guess(),
            },
            // recurrence: ["RRULE:FREQ=DAILY;COUNT=2"],
            reminders: {
              useDefault: false,
              overrides: [
                { method: 'email', minutes: 24 * 60 },
                { method: 'popup', minutes: 10 },
              ],
            },
          }
         
          var request = gapiClient.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
          })

          request.execute((event) => { 
            if (event.status == 'confirmed') {
              createNotification('success', 'Event added to google calendar.')
            } else {
              createNotification(
                'danger',
                'Unable to add event to calendar. Please try again later.',
              )
            }
            window.open(event.htmlLink)
          })
        })
    })
  }

  handleEventType = (eventType) => {
    this.setState({ eventType: eventType })
  }

  render() {
    const { loading, eventType, redirect } = this.state

    if(redirect){
      return <Redirect
            to="/my-events"
            />;
    }

    return (
      <DashboardLayout
        title={this.state.title}
        pageTitle={this.state.pageTitle}
      >
        <div className="dashboardMiddleArea">
          <div className="createProjectAccount">
            <div className="projectStepRow createEventMarginTop mt-0">
              <div className="main_steppers">
                <div className="projectCreateSection">
                  <div className="CreatePresentation">
                    <div className="accountTypeTitle">
                      <h1>Start creating your event below:</h1>
                    </div>
                    <div className="stepsButtonAlternate">
                      <button
                        type="button"
                        className={eventType === 'registration' ? 'active' : ''}
                        onClick={() => this.handleEventType('registration')}
                      >
                        Registration
                      </button>
                      <button
                        type="button"
                        className={
                          eventType === 'eventPlatform' ? 'active' : ''
                        }
                        onClick={() => this.handleEventType('eventPlatform')}
                      >
                        XP Event Platform
                      </button>
                    </div>
                    <div className="createPersentSteps">
                      <div className="CPTabs"></div>
                      {eventType && eventType === 'registration' ? (
                        <EventRegistartion />
                      ) : (
                        <XpEventPlatform />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
        {loading ? <Loader /> : null}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  events: state.Events,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      createEvent: Actions.eventsRequest,
      checkEventDomain: Actions.checkEventDomainRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(CreateEvent)
