import React from 'react'

/**
 * @function - convertMsToHM
 * @description - a common function for convert milliseconds to Hours and minuts
 * @param { params} req
 * @returns { object } response Object
 */
export const convertMsToHM = (milliseconds) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  seconds = seconds % 60;
  minutes = seconds >= 30 ? minutes + 1 : minutes;
  minutes = minutes % 60;
  minutes = minutes == 0 ? 60 - 1 : minutes - 1;
  let tdaysToHours = Math.round(hours);
  return `${padTo2Digits(tdaysToHours)}:${padTo2Digits(minutes)}`;
}

const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
}

