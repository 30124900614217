import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
    listMeetingRequest: ['data'],
    listMeetingSuccess: ['message', 'meetingList', 'totalResults','filterByStatus'],
    listMeetingError: ['error'],
    addMeetingRequest: ['data'],
    addMeetingSuccess: ['message', 'meeting'],
    addMeetingError: ['error'],
    handleNavigationFromEditorToCreateMeeting:['data'],
    deleteMeetingRequest: ['data'],
    deleteMeetingSuccess: ['message'],
    deleteMeetingError: ['error'],
    updateMeetingRequest: ['data'],
    updateMeetingSuccess: ['message','updateMeetingData'],
    updateMeetingError: ['error'],
    listAttendeesNotAddedToMeetingRequest: ['data'],
    listAttendeesNotAddedToMeetingSuccess: ['message', 'data', "totalAttendees"],
    listAttendeesNotAddedToMeetingError: ['error'],
    replicateMeetingRequest: ['data'],
    replicateMeetingSuccess: ['message', ],
    replicateMeetingError: ['error'],
    attendeeGroupsForMeetingRequest: ['data'],
    attendeeGroupsForMeetingSuccess: ['message', 'attendeesGroups',"totalAttendeeGroups"],
    attendeeGroupsForMeetingError: ['error'],
    audienceForMeetingRequest: ['data'],
    audienceForMeetingSuccess: ['message', 'audienceData',"totalAudience"],
    audienceForMeetingError: ['error'],
    searchForMeetingRequest: ['data'],
    searchForMeetingSuccess: ['message', 'mergeArray'],
    searchForMeetingError: ['error'],
    getAudienceContactRequest: ['data'],
    getAudienceContactSuccess: ['message', 'contact'],
    getAudienceContactError: ['error'],
    getMeetingTemplateRequest:['data'],
    getMeetingTemplateSuccess:['message', 'meetingTemplate','eventData'],
    getMeetingTemplateError:['error'],
    checkMeetingTitleRequest:['data'],
    checkMeetingTitleSuccess:['message'],
    checkMeetingTitleError:['message'],
    listAllMeetingsRequest : ['data'],
    listAllMeetingsSuccess : [ 'message', 'meetings'],
    listAllMeetingsError : ['error'],
    checkDisabledAttendeeRequest:['data'],
    checkDisabledAttendeeSuccess:['message'],
    checkDisabledAttendeeError:['error'],
    listAllUserRequest: ['data'],
    listAllUserSuccess: ['listAllUser'],
    listAllUserError: ['error'],
    updateSlotsRequest: ['data'],
    updateSlotsSuccess: ['message'],
    updateSlotsError: ['error'],
    getSlotsRequest: ['data'],
    getSlotsSuccess: ['message','getSlots'],
    getSlotsError: ['error'],
})

export const Constants = Types
export default Creators