import {createActions} from "reduxsauce"

const {Types, Creators} = createActions({
    createCommentAndAttachmentRequest: ['data'],
    createCommentAndAttachmentSuccess: ['commentsAndAttachments', 'message'],
    createCommentAndAttachmentError: ['message'],
    uploadAttachmentToCloudRequest: ['data'],
    uploadAttachmentToCloudSuccess: ['message'],
    uploadAttachmentToCloudError: ['message'],
    deleteCommentAndAttachmentRequest: ['data'],
    deleteCommentAndAttachmentSuccess: ['commentsAndAttachments', 'message'],
    deleteCommentAndAttachmentError: ['message'],
    updateCommentAndAttachmentRequest: ['data'],
    updateCommentAndAttachmentSuccess: ['commentsAndAttachments', 'message'],
    updateCommentAndAttachmentError: ['message'],
    fetchCommentAndAttachmentRequest: ['data'],
    fetchCommentAndAttachmentSuccess: ['commentsAndAttachments'],
    fetchCommentAndAttachmentError: ['message'],
    removeAttachmentRequest: ['data'],
    removeAttachmentSuccess: ['commentsAndAttachments'],
    removeAttachmentError: ['message'],
})

export const Constants = Types
export default Creators