import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
import { NotificationContainer } from 'react-notifications'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import '../../sass/main.scss'
import illumeetLogo from '../../assets/images/loginLogo.png'
import { Button } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Shape1 from '../../assets/images/Path_421.png'
import Shape2 from '../../assets/images/Path_422.png'
import Shape3 from '../../assets/images/Path_423.png'
import Shape4 from '../../assets/images/Path_424.png'
import Shape5 from '../../assets/images/Path_425.png'
import { Helmet } from 'react-helmet'

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      errors: {},
      loading: false,
    }
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const login = this.props.login 
    if (
      login &&
      login.forgotSuccess &&
      this.props.login.forgotMsg !== prevProps.login.forgotMsg
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null
      createNotification('success', login.forgotMsg, '')
    } else if (login && login.forgotError && this.state.loading) {
      createNotification('error', login.forgotMsg, '')
      this.handleLoading()
    }
  }

  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  isLoginValid = () => { 
    const { email, password } = this.state
    let error = {}
    let formIsValid = true
    const regexTest = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

    if (email === '' || email.trim().length === 0) {
      formIsValid = false
      error['email'] = '*Please enter email.'
    }
    if (email && regexTest.test(email) === false) { 
      formIsValid = false
      error['email'] = '*Please enter a valid email address.'
    }
    this.setState({ errors: error })
    return formIsValid
  }

  handleSubmit = (e) => {
    e.preventDefault() 
    if (this.isLoginValid()) {
      const { email } = this.state 
      const requestbody = { email }
      this.props.forgotPassword(requestbody)
      this.handleLoading() 
    }
  }

  render() {
    const { email, errors, loading } = this.state
    return (
      <div className="main_page">
        <Helmet>
          <title>IllumeetXP | Forgot Password</title>
        </Helmet>
        <div className="registerPage">
          <div className="leftSidebar">
            <div className="registerSidebarBrand">
              <img src={illumeetLogo} alt="Illumeet Logo" />
              <div className="brandingText">
                <h1>
                  One platform. <br />
                  One process. <br />
                  All your events.
                </h1>
              </div>
            </div>
            <div className="signupShapes">
              <span className="shape-1">
                <img src={Shape1} alt="" />
              </span>
              <span className="shape-2">
                <img src={Shape2} alt="" />
              </span>
              <span className="shape-3">
                <img src={Shape3} alt="" />
              </span>
              <span className="shape-4">
                <img src={Shape4} alt="" />
              </span>
              <span className="shape-5">
                <img src={Shape5} alt="" />
              </span>
            </div>
          </div>
          <div className="registerFormMain">
            <div className="registerFormInner">
              <div className="pageHeading text-center">
                <h1>Forgot Password</h1>
                <p>Please enter your email id to reset password</p>
                {loading ? <Loader /> : null}
              </div>
              <div className="formSection LoginForm">
                <form onSubmit={this.handleSubmit}>
                  <div className="field-group fullWidthInput">
                    <label>Email</label>
                    <input
                      type="text"
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                      className="form-control emailLoweCase"
                    />
                    <p className="error_mesage"> {errors.email} </p>
                  </div>

                  <div className="field-group fullWidthInput">
                    <div className="submitForm">
                      <Button type="submit" className="themeBlueButton">
                        Submit
                      </Button>
                    </div>
                  </div>
                  <div className="field-group fullWidthInput loginHere">
                    <p>
                      Don't have an account ? &nbsp;
                      <a onClick={() => this.props.history.push('/register')}>
                        Register
                      </a>
                    </p>
                  </div>
                  <div className="field-group fullWidthInput loginHere">
                    <p>
                      Have account ? &nbsp;
                      <a onClick={() => this.props.history.push('/')}>Login</a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <NotificationContainer />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  login: state.Login,
  // loading: state.Loader.loaderOne,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      userLogin: Actions.loginRequest,
      forgotPassword: Actions.forgotPasswordRequest,
      // startLoading: Actions.startLoaderOne,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(ForgotPassword)
