import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
    error: false,
    message: null,
    getAgendaSuccess: false,
    agenda: [],
    totalAgendaRecords: null,
    deleteAgendaSuccess: false,
    addAgendaSuccess: false,
    editAgendaSuccess: false,
    totalResults:0,
    replicateAgendaSuccess: false,
    listAgendaSuccess: false,
}

const listAgendaRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getAgendaSuccess: false,
        error: false,
        message: null,
        agenda: [],
        deleteAgendaSuccess : false,
        addAgendaSuccess: false,
        listAgendaSuccess: false,
        totalResults:0,           
    }
}

const listAgendaSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getAgendaSuccess: true,
        error: false,
        message: action.message,
        agenda: action.Agenda,
        totalResults: action.agendaCount,
        deleteAgendaSuccess : false,
        addAgendaSuccess: false,
        listAgendaSuccess: true,
    }
}
const listAgendaError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getAgendaSuccess: false,
        error: true,
        message: action.error,
        deleteAgendaSuccess : false,
        addAgendaSuccess: false,
        listAgendaSuccess: false,
    }
}

const deleteAgendaRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteAgendaSuccess: false,
        error: false,
        getAgendaSuccess: false,
        addAgendaSuccess: false,
    }
}
const deleteAgendaSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteAgendaSuccess: true,
        error: false,
        message: action.message,
        getAgendaSuccess: false,
        addAgendaSuccess: false,
    }
}

const deleteAgendaError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteAgendaSuccess: false,
        error: true,
        message: action.error,
        getAgendaSuccess: false,
        addAgendaSuccess: false,
    }
}

const addAgendaRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addAgendaSuccess: false,
        error: false,
        message: null
    }
}
const addAgendaSuccess = (state = INITIAL_STATE, action) => {
    return {
         ...state,
        addAgendaSuccess: true,
        error: false,
        message: action.message
    }
}
const addAgendaError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addAgendaSuccess: false,
        error: true,
        message: action.error
    }
}

const editAgendaRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        editAgendaSuccess: false,
        error: false,
        message: null,
        getAgendaSuccess: false,
        addAgendaSuccess: false,
        deleteAgendaSuccess: false,
    }
}
const editAgendaSuccess = (state = INITIAL_STATE, action) => {
    return {
         ...state,
        editAgendaSuccess: true,
        error: false,
        message: action.message,
        getAgendaSuccess: false,
        addAgendaSuccess: false,
        deleteAgendaSuccess: false,
    }
}
const editAgendaError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        editAgendaSuccess: false,
        error: true,
        message: action.error,
        getAgendaSuccess: false,
        addAgendaSuccess: false,
        deleteAgendaSuccess: false,
    }
}

const searchAgendaRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        searchAgendaSuccess: false,
        error: false,
        message: null,
        getAgendaSuccess: false,
        addAgendaSuccess: false,
        deleteAgendaSuccess: false,
        totalResults: 0,
        agenda:[],
    }
}

const searchAgendaSuccess = (state = INITIAL_STATE, action) => {
    return {
         ...state,
        searchAgendaSuccess: true,
        agenda:action.agenda,
        error: false,
        message: action.message,
        getAgendaSuccess: true,
        addAgendaSuccess: false,
        deleteAgendaSuccess: false,
    }
}

const searchAgendaError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        searchAgendaSuccess: false,
        error: true,
        message: action.error,
        getAgendaSuccess: false,
        addAgendaSuccess: false,
        deleteAgendaSuccess: false,
        agenda:[],
    }
}

const replicateAgendaRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        replicateAgendaSuccess: false,
        error: false,
        message: null,
        getAgendaSuccess: false,
        addAgendaSuccess: false,
        deleteAgendaSuccess: false,
    }
}

const replicateAgendaSuccess = (state = INITIAL_STATE, action) => {
    return {
         ...state,
        replicateAgendaSuccess: true,
        error: false,
        message: action.message,
        getAgendaSuccess: true,
        addAgendaSuccess: false,
        deleteAgendaSuccess: false,
    }
}

const replicateAgendaError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        replicateAgendaSuccess: false,
        error: true,
        message: action.error,
        getAgendaSuccess: false,
        addAgendaSuccess: false,
        deleteAgendaSuccess: false,
    }
}

const HANDLERS = {
    [Constants.LIST_AGENDA_REQUEST]: listAgendaRequest,
    [Constants.LIST_AGENDA_SUCCESS]: listAgendaSuccess,
    [Constants.LIST_AGENDA_ERROR]: listAgendaError,
    [Constants.DELETE_AGENDA_REQUEST]: deleteAgendaRequest,
    [Constants.DELETE_AGENDA_SUCCESS]: deleteAgendaSuccess,
    [Constants.DELETE_AGENDA_ERROR]: deleteAgendaError,
    [Constants.ADD_AGENDA_REQUEST]: addAgendaRequest,
    [Constants.ADD_AGENDA_SUCCESS]: addAgendaSuccess,
    [Constants.ADD_AGENDA_ERROR]: addAgendaError,
    [Constants.EDIT_AGENDA_REQUEST]: editAgendaRequest,
    [Constants.EDIT_AGENDA_SUCCESS]: editAgendaSuccess,
    [Constants.EDIT_AGENDA_ERROR]: editAgendaError,
    [Constants.SEARCH_AGENDA_REQUEST]: searchAgendaRequest,
    [Constants.SEARCH_AGENDA_SUCCESS]: searchAgendaSuccess,
    [Constants.SEARCH_AGENDA_ERROR]: searchAgendaError,
    [Constants.REPLICATE_AGENDA_REQUEST]: replicateAgendaRequest,
    [Constants.REPLICATE_AGENDA_SUCCESS]: replicateAgendaSuccess,
    [Constants.REPLICATE_AGENDA_ERROR]: replicateAgendaError,
}

export default createReducer(INITIAL_STATE, HANDLERS)
