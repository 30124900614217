import { Button, CircularProgress, IconButton } from '@material-ui/core';
import { Stack, Grid, Box } from '@mui/material';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { SyntheticEvent, useEffect, useState } from 'react';
import {
  CustomField,
  CustomFields,
  CustomFields_State,
} from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { useSelector } from 'react-redux';
import { separateByComma } from 'helpers/common';
import OpportunityContactsAPI from 'Redux/API/opportunity/opportunityContactsAPI';
import { Contact } from 'Redux/Reducers/opportunity/contacts.reducer';
import OpportunityAccountsCustomFieldsAPI from 'Redux/API/opportunity/opportunityCustomFieldsAPI';
import Loader from 'Components/Loader/loader';
import {
  AccountCf,
  ContactCf,
  Opportunity,
  OpportunityState,
} from 'Redux/Reducers/opportunity/opportunities.reducer';
import ContactOrAccountDetailsSideModal from '../../Accounts/ContactOrAccountDetailsSideModal';
import OpportunityAccountsAPI from 'Redux/API/opportunity/opportunityAccountsAPI';
import ChatHistory from '../ChatHistory';
import ReadMore from '../../../../assets/images/icons/informationIcon.svg';
import AIChatbotAPI from 'Redux/API/aiChatbotAPI';
import { Message } from 'Pages/AIChatbot/Tabs/ChatWidget/MessageWindow';
import { getSystemLevelPermissions } from '../../../../helpers/common';
import moment from 'moment';
import { formatDateField } from 'helpers/common';


type Props = {
  closeViewModal: () => void;
  opportunityToBeEdited: Opportunity | undefined;
  setShowAddNew: React.Dispatch<React.SetStateAction<boolean>>;
  setShowViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleTabChange: (
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    searchText: string,
  ) => void;
  commentSectionOpened: boolean
};

function OpportunityView({
  closeViewModal,
  opportunityToBeEdited,
  setShowAddNew,
  setShowViewModal,
  handleTabChange,
  commentSectionOpened
}: Props) {
  const {
    contactsObj,
    accountsObj,
    isLoading: isOpportunitiesLoading,
  } = useSelector(
    (state: any) => state.OpportunityOpportunities,
  ) as OpportunityState;

  const { customFields, isLoading: isCustomFieldsLoading } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;
  const [detailsForToolTip, setDetailsForToolTip] = useState<Contact>(
    {} as Contact,
  );
  const [contactsCustomFields, setContactsCustomFields] =
    useState<CustomFields>({} as CustomFields);
  const [accountsCustomFields, setAccountsCustomFields] =
    useState<CustomFields>({} as CustomFields);
  const [openSideModal, setOpenSideModal] = useState(false);
  const [customFieldsForSideModal, setCustomFieldsForSideModal] =
    useState<any>(null);
  const [modalUsedFor, setModalUsedFor] = useState<'contact' | 'account'>(
    'contact',
  );
  const [titleForSideModal, setTitleForSideModal] = useState<string>('');
  const [summary, setSummary] = useState<string>('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [openChatHistory, setOpenChatHistory] = useState<boolean>(false);
  const [isloading, setIsloading] = useState<boolean>(false);
  const [allowed, setAllowed] = useState<any>({});

  useEffect(() => {
    setAllowed(getSystemLevelPermissions());
    fetchContactsCustomField();
    fetchAccountsCustomField();
    opportunityToBeEdited?.sourceRef && fetchChatHistoryAndSummary();
  }, []);

  const fetchChatHistoryAndSummary = async () => {
    try {
      setIsloading(true);
      const aIChatbotAPI = new AIChatbotAPI();
      let data = {
        chatId: opportunityToBeEdited?.sourceRef,
      };
      const res = await aIChatbotAPI.getChatSummary(data);

      if (res.data.chatSummary?.summary) {
        setIsloading(false);
        setSummary(res.data.chatSummary.summary);
      }
      if (res.data.history) {
        setIsloading(false);
        setMessages(res.data.history);
      }
    } catch (err) {
      setIsloading(false);
    }
  };

  const fetchContactsCustomField = async () => {
    try {
      const opportunityAccountsCustomFieldsAPI =
        new OpportunityAccountsCustomFieldsAPI();
      const data = {
        customFieldType: 'contacts',
        from: 'viewOpportunity',
      };
      const res =
        await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
          data,
        );
      setContactsCustomFields(res.data.customFields);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAccountsCustomField = async () => {
    try {
      const opportunityAccountsCustomFieldsAPI =
        new OpportunityAccountsCustomFieldsAPI();
      const data = {
        customFieldType: 'accounts',
        from: 'viewOpportunity',
      };
      const res =
        await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
          data,
        );
      setAccountsCustomFields(res.data.customFields);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContactById = async (contactId: string) => {
    const opportunityContactsAPI = new OpportunityContactsAPI();
    const res = await opportunityContactsAPI.fetchOpportunityContact({
      id: contactId,
    });
    if (res.data.contact) {
      setDetailsForToolTip(res.data.contact);
    }
  };

  const fetchAccountById = async (accountId: string) => {
    const opportunityAccountsAPI = new OpportunityAccountsAPI();
    const res =
      await opportunityAccountsAPI.fetchAccountWithProperCustomFieldsValue({
        id: accountId,
      });
    if (res.data?.account) {
      setDetailsForToolTip(res.data.account);
    }
  };

  const handleShowSideModal = (
    id: string,
    fieldType: string,
    title: string,
  ) => {
    setTitleForSideModal(title);
    if (fieldType === 'contact') {
      setModalUsedFor('contact');
      openContactDetailsModal(id);
    } else if (fieldType === 'account') {
      setModalUsedFor('account');
      openAccountDetailsModal(id);
    }
  };

  const openContactDetailsModal = (id: string) => {
    setOpenSideModal(true);
    setCustomFieldsForSideModal(contactsCustomFields);
    fetchContactById(id);
  };

  const openAccountDetailsModal = (id: string) => {
    setOpenSideModal(true);
    setCustomFieldsForSideModal(accountsCustomFields);
    fetchAccountById(id);
  };

  const handleCloseSideModal = () => {
    setOpenSideModal(false);
    setDetailsForToolTip({} as Contact);
  };

  const handleCloseChatHistory = () => {
    setOpenChatHistory(false);
  };

  const renderValue = (cf: CustomField, customFieldValue: string | string[]) => {
    if (Array.isArray(customFieldValue)) {
      return separateByComma(customFieldValue)?.map((value: string, index) => (
        <span 
          key={index}
          className={`long-text ${cf.fieldData.fieldType === "user" ? "link" : ""}`}
          onClick={() => {
            if (cf.fieldData.fieldType === "user" && opportunityToBeEdited?.customFields?.[cf._id as string]) {
              window.open(`mailto:${value}`);
            }
          }}
          style={{ display: "block" }}
        >
          {value}
        </span>
      ));
    } else if (cf.fieldData.fieldType === 'date') {
      return formatDateField(opportunityToBeEdited, cf, customFields);
    } else if (cf.fieldData.fieldType === 'boolean') {
      return customFieldValue?.toString() === "true" ? "True" : "False";
    } else {
      return customFieldValue || '-';
    }
  };

  return (
    <>
      <Box className="Opportunity-Account">
        {/* @ts-ignore */}
        <Grid container spacing={2} mb={3} className="top-filter-area">
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton size="small" onClick={closeViewModal}>
                <KeyboardBackspaceIcon color="primary" />
              </IconButton>
              <Box className="paragraph bold">Opportunity view</Box>
            </Stack>
          </Grid>
          <Grid item>
            {/* @ts-ignore */}
            <Grid container spacing={1.25} className="team-dash-right">
              <Grid item>
                {allowed['editOpportunity'] && (
                  <Button
                    onClick={() => {
                      setShowAddNew(true);
                      setShowViewModal(false);
                    }}
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {summary && (
          <Stack spacing={1} className="account-details" mb={3}>
            <Box className="subtitle">AI Summary</Box>
            <Box className="small-subtitle">{summary}</Box>
            <Box
              className="small-subtitle link"
              onClick={() => setOpenChatHistory(true)}
            >
              View Chat History
            </Box>
          </Stack>
        )}
        <Box className="account-details">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={commentSectionOpened ? 12: 6} lg={commentSectionOpened ? 6: 4}>
              <Grid container>
                <Grid item xs={6} md={4} lg={5} xl={3}>
                  <Box className="small-subtitle bold primary-text">
                    Opportunity Name<span className="required">*</span>:
                  </Box>
                </Grid>
                <Grid item xs={6} md>
                  <Box className="small-subtitle primary-text ">
                    {opportunityToBeEdited?.name}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12: 6} lg={commentSectionOpened ? 6: 4}>
              <Grid container>
                <Grid item xs={6} md={4} lg={5} xl={3}>
                  <Box className="small-subtitle bold primary-text">
                    Project:
                  </Box>
                </Grid>
                <Grid item xs>
                  <Stack direction="row" alignItems={'center'} spacing={1}>
                    <span className="small-subtitle primary-text">
                      {opportunityToBeEdited?.project?.name || '-'}
                    </span>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12: 6} lg={commentSectionOpened ? 6: 4}>
              <Grid container>
                <Grid item xs={6} md={4} lg={5} xl={3}>
                  <Box className="small-subtitle bold primary-text">
                    Assigned To:
                  </Box>
                </Grid>
                <Grid item xs={6} md={8} lg={7} xl={9}>
                  <Stack direction="row" alignItems={'center'} spacing={1}>
                    <span className={`small-subtitle primary-text ${opportunityToBeEdited?.assignedTo?.email && "link"}`} onClick={()=> {
                      if(opportunityToBeEdited?.assignedTo?.email){
                        window.open(`mailto:${opportunityToBeEdited?.assignedTo?.email}`)
                      }
                    }}>
                      {opportunityToBeEdited?.assignedTo?.email}
                    </span>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12: 6} lg={commentSectionOpened ? 6: 4}>
              <Grid container>
                <Grid item xs={6} md={4} lg={5} xl={3}>
                  <Box className="small-subtitle bold primary-text">
                    State:
                  </Box>
                </Grid>
                <Grid item xs={6} md={8} lg={7} xl={9}>
                  <Stack direction="row" alignItems={'center'} spacing={1}>
                    <span className="small-subtitle primary-text">
                      {opportunityToBeEdited?.state.name}
                    </span>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12: 6} lg={commentSectionOpened ? 6: 4}>
              <Grid container>
                <Grid item xs={6} md={4} lg={5} xl={3}>
                  <Box className="small-subtitle bold primary-text">
                    Source:
                  </Box>
                </Grid>
                <Grid item xs={6} md={8} lg={7} xl={9}>
                  <Box className="small-subtitle primary-text">
                    {opportunityToBeEdited?.source === 'aiAgent'
                      ? 'AI Agent'
                      : opportunityToBeEdited?.source === 'imported'
                      ? 'Imported'
                      : 'Manually Added'}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {customFields.map((cf: CustomField) => {
              return (
                <Grid item xs={12} key={cf._id}
                sm={
                  cf.fieldData.fieldType === 'longText' ? 12 : 6
                } 
                  lg={
                    cf.fieldData.fieldType === 'longText' ? 12 : 4
                  }
                >
                  <Grid container>
                    <Grid item 
                    xs={
                      cf.fieldData.fieldType === 'longText' ? 12 : 6
                    }  
                      md={
                        cf.fieldData.fieldType === 'longText' ? 12 : 4
                      } 
                        xl={
                          cf.fieldData.fieldType === 'longText' ? 12 : 3
                        }
                    >
                      <Box className="small-subtitle bold primary-text">
                        {cf.fieldData.fieldName}:
                      </Box>
                    </Grid>
                    <Grid item 
                    xs={
                      cf.fieldData.fieldType === 'longText' ? 12 : 6
                    }  
                      md={
                        cf.fieldData.fieldType === 'longText' ? 12 : 8
                      } 
                        xl={
                          cf.fieldData.fieldType === 'longText' ? 12 : 9
                        }
                    >
                      <Stack direction={'row'} spacing={1}>
                        <Box
                          className={`small-subtitle primary-text ${
                            ['account', 'contact', 'url', 'email', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin', 'phone'].includes(cf.fieldData.fieldType)
                              ? 'link'
                              : ''
                          }
                          ${
                            cf.fieldData.fieldType === 'longText' ? '' : ''
                          }`}
                          onClick={(e) => {
                            cf.fieldData.fieldType === 'account' &&
                              // handleTabChange(
                              //   e,
                              //   '1',
                              //   opportunityToBeEdited
                              //     ?.customFieldsWithProperValues?.[
                              //     cf._id as string
                              //   ] as string,
                              // );
                              handleShowSideModal(
                                opportunityToBeEdited?.customFields?.[
                                  cf._id as string
                                ] as
                                  | ContactCf
                                  | AccountCf as unknown as string,
                                cf.fieldData.fieldType,
                                cf.fieldData.fieldName,
                              );
                            cf.fieldData.fieldType === 'contact' &&
                              // handleTabChange(
                              //   e,
                              //   '3',
                              //   opportunityToBeEdited
                              //     ?.customFieldsWithProperValues?.[
                              //     cf._id as string
                              //   ] as string,
                              // );
                              handleShowSideModal(
                                opportunityToBeEdited?.customFields?.[
                                  cf._id as string
                                ] as
                                  | ContactCf
                                  | AccountCf as unknown as string,
                                cf.fieldData.fieldType,
                                cf.fieldData.fieldName,
                              );
                              if(['url', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin'].includes(cf.fieldData.fieldType)) {
                                let url = (opportunityToBeEdited
                                  ?.customFieldsWithProperValues?.[
                                  cf._id as string
                                ] as string)
                                if (!/^https?:\/\//i.test(url)) {
                                  // If not, prepend 'https://'
                                  url ='https://' + url;
                                }
                                window.open(url, "_blank")
                              }
                              if(cf.fieldData.fieldType === 'email'){
                                window.open(`mailto:${opportunityToBeEdited
                                  ?.customFieldsWithProperValues?.[
                                  cf._id as string
                                ] as string}`)
                              } else if(cf.fieldData.fieldType === 'phone'){
                                window.open(`tel:${opportunityToBeEdited
                                  ?.customFieldsWithProperValues?.[
                                  cf._id as string
                                ] as string}`)
                              }
                              
                          }}
                        >
                          {renderValue(cf, opportunityToBeEdited?.customFieldsWithProperValues?.[cf._id as string] as string | string[])}
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          {openSideModal && (
            <ContactOrAccountDetailsSideModal
              open={openSideModal}
              handleClose={handleCloseSideModal}
              details={detailsForToolTip}
              customFields={customFieldsForSideModal}
              usedFor={modalUsedFor}
              title={titleForSideModal}
            />
          )}
        </Box>
      </Box>
      <ChatHistory
        open={openChatHistory}
        handleClose={handleCloseChatHistory}
        messages={messages}
      />
      {(isCustomFieldsLoading || isOpportunitiesLoading || isloading) && (
        <Loader />
      )}
    </>
  );
}

export default OpportunityView;
