import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Helmet } from 'react-helmet'
import { get, cloneDeep } from 'lodash'
import { NotificationContainer } from 'react-notifications'
import { connect } from 'react-redux' 
import {
  updateEventTemplateById,
  getEventTemplates,
  makeTemplateActive,
} from '../../Redux/API'

/*custom components */
import PageToolBar from '../../Components/PageToolBar'
import Loader from '../../Components/Loader/loader'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
import '../../sass/main.scss'
import { createNotification } from '../../helpers'
import getUnLayerConfig from './config'
import { Button } from '@material-ui/core'
import { Modal } from 'react-responsive-modal'
import TestEmailModal from './TestEmailModal'

class TransactionalDesign extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      showLoader: false,
      registrationTemplates: [],
      templateName: 'Template Name',
      selectedRegTemplate: '',
      emailSubject: 'Test Email Subject',
      open: false,
    }
  }

  componentDidMount() {
    const apiUrl = process.env.REACT_APP_API_URL
    const eventId = get(this.props, 'match.params.eid')
    const templateId = get(this.props, 'match.params.tid')
    const type = this.props.type
    const setState = this.setState.bind(this)
    window.scrollTo(0, 0)
    window.unlayer.init(getUnLayerConfig(apiUrl, eventId, type, templateId))
    window.unlayer.addEventListener('design:updated', function (updates) {
      window.unlayer.exportHtml(function (data) {
        const json = data.design
        const html = data.html
        setState({ json, html })
      })
    })

    const params = { type: type.toLowerCase(), templateId }
    getEventTemplates(params).then((result) => {
      if (result.data.success) {
        const transactionalDesign = get(
          result,
          'data.templates.transactionalDesign',
          {},
        )
        const oldTemplates = cloneDeep(transactionalDesign)
        const { json } = transactionalDesign
        this.setState({
          ...transactionalDesign,
          showLoader: false,
          oldTemplates,
        })
        window.unlayer.loadDesign(json)
      }
    })
  }

  saveLayout = () => {
    const { json, html, templateName, emailSubject } = this.state
    const templateId = get(this.props, 'match.params.tid')
    // const eventId = get(this.props, 'match.params.eid')
    // const type = get(this.props, 'type', '').toLowerCase()

    const data = {
      transactionalDesign: { json, html, templateName, emailSubject },
    }
    updateEventTemplateById(templateId, data)
      .then((result) => {
        if (result.data.success) {
          createNotification('success', result.data.message)
          const newTemplate = get(result, 'data.template', {})
          const oldTemplates = cloneDeep(newTemplate)
          this.setState({
            ...newTemplate.transactionalDesign,
            oldTemplates,
          })
        } else {
          createNotification('error', result.data.message)
        }
      })
      .catch(console.log)
  }

  onChange = (e) => {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  makeTemplateActive = () => {
    const eventId = get(this.props, 'match.params.eid')
    const templateId = get(this.props, 'match.params.tid')
    const type = get(this.props, 'type', '')
    makeTemplateActive(eventId, templateId, { type: type.toLowerCase() }).then(
      () => {
        this.setState({ isActive: true })
        createNotification('success', 'Template has been saved as active')
      },
    )
  }

  saveConfirmation = (isActive) => {
    this.setState(
      ({ templateName = '' }) => ({ templateName: templateName.trim() }),
      () => {
        const {
          html,
          templateName,
          oldTemplates, 
          selectedRegTemplate,
        } = this.state
        const oldHtml = get(oldTemplates, 'html', '')
        const oldTemplateName = get(oldTemplates, 'templateName', '')
        if (
          html &&
          (html !== oldHtml ||
            (templateName && templateName !== oldTemplateName) ||
            selectedRegTemplate !== oldTemplates.oldTemplates)
        ) {
          /*confirmAlert({
                    title: 'Confirmation',
                    message: defaultTemplate ? `Default template can't be changed. A copy of this template will be created. Do you want to continue` :
                        'Are you sure want to save the changes',
                    buttons: [
                        {
                            label: 'Cancel'
                        },
                        {
                            label: 'Continue',
                            onClick: () => {
                                this.saveLayout(isActive);
                            }
                        }
                    ]
                });*/
          this.saveLayout(isActive)
        } else {
          const message = !templateName.trim()
            ? `Can't save template with blank name`
            : 'No changes found'
          createNotification('error', message)
        }
      },
    )
  }

  handleRegTemplate = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.getAgenda)
  }

  handleModal = () => {
    this.setState(({ open }) => ({ open: !open }))
  }

  render() {
    const {
      view,
      showLoader,
      templateName,
      isActive,
      emailSubject,
      open,
    } = this.state
    const { type } = this.props
    const templateId = get(this.props, 'match.params.tid')
    const eventId = get(this.props, 'match.params.eid')
    const backTo = `/edit-event-form/eid/${eventId}/tid/${templateId}`

    return (
      <div className="editingPageWrapper">
        <Helmet>
          <title>IllumeetXP | Transactional Design </title>
        </Helmet>
        {showLoader && <Loader />}
        {
          <div className="editingDashboard">
            {!view && (
              <PageToolBar
                showIcons={false}
                onSaveFn={this.saveConfirmation}
                showPreviewOption={false}
                makeActive={this.makeTemplateActive}
                showSaveBtn={type !== 'Login'}
                showActiveBtn={false}
                activeBtnLabel="Make Design Active"
                activeBtnDisabled={isActive || templateId === 'blank'}
                backTo={backTo}
              />
            )}
            <div className="templateName templateNameField formSection pt-0">
              <form>
                <div className="field-group fieldFroup20 inputField">
                  <label>{type} Template</label>
                  <input
                    type="text"
                    className="form-control"
                    name="templateName"
                    value={templateName}
                    maxLength="25"
                    onChange={this.onChange}
                  />
                </div>

                <div className="field-group fieldFroup20 inputField">
                  <label>Email Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    name="emailSubject"
                    value={emailSubject}
                    maxLength="100"
                    onChange={this.onChange}
                  />
                </div>
                <div className="field-group fieldFroup20 inputField">
                  <label> &nbsp; </label>
                  <Button
                    type="button"
                    className="saveButton mobile-None"
                    onClick={this.handleModal}
                  >
                    Send a test email
                  </Button>
                </div>
              </form>
            </div>
            <div id="pageDesignEditor" style={{ height: '100vh' }}>
              {''}
            </div>
          </div>
        }
        <NotificationContainer />
        <Modal open={open} onClose={this.handleModal} center>
          <TestEmailModal
            subject={emailSubject}
            eventId={eventId}
            templateId={templateId}
            handleChange={this.handleChange}
            sendSampleEmail={this.sendSampleEmail}
            cb={this.handleModal}
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  events: state.Events,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(TransactionalDesign),
)
