import BaseAPI from './baseAPI'

class VanityDomainAPI extends BaseAPI {
  createVanityDomain = async (data: any) => {
    return this.API('createVanityDomain', {
      method: 'POST',
      data: data,
    })
  }
  removeVanityDomain = async (eventId: string) => {
    return this.API('removeVanityDomain', {
      method: 'PUT',
      data: { eventId },
    })
  }
}
export default VanityDomainAPI
