import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
  success: false,
  proutes: null,
}

const request = (state = INITIAL_STATE, action) => {
  return { ...state, success: true, proutes: null }
}
const success = (state = INITIAL_STATE, action) => {
  return { ...state, success: true, proutes: action.proutes }
}

const HANDLERS = {
  [Constants.PROUTE_SUCCESS]: success,
  [Constants.PROUTE_REQUEST]: request,
}

export default createReducer(INITIAL_STATE, HANDLERS)
