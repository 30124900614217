import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  //#region Property
  tawkToGetProperties: [],
  tawkToGetPropertiesSuccess: ['payload'],
  tawkToGetPropertiesFailure: ['error'],

  tawkToCreateProperty: ['property'],
  tawkToCreatePropertySuccess: ['integration', 'message'],
  tawkToCreatePropertyFailure: ['error'],
  //#endregion

  //#region Widgets
  tawkToGetWidget: ['query'],
  tawkToGetWidgetSuccess: ['widget', 'message'],
  tawkToGetWidgetFailure: ['error'],

  tawkToUpdateWidget: ['widget'],
  tawkToUpdateWidgetSuccess: ['widget', 'message'],
  tawkToUpdateWidgetFailure: ['error'],
  //#region
  //#region App Integration

  getAppIntegrations: ['eventId'],
  getAppIntegrationsSuccess: ['integrations', 'message'],
  getAppIntegrationsFailure: ['error'],
  //#endregion
});
const Constants = Types;
export {
  Creators as AppIntegrationCreators,
  Constants as AppIntegrationConstants,
};
