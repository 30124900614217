import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'
import { createNotification } from '../helpers'
import { ProgressBarLine } from 'react-progressbar-line'
import EditingBack from '../assets/images/backPage.png'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import NotifyIcon from '../assets/images/notification.png'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../Redux/Actions'
import { redirectTo } from '../helpers'

class MyEvents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isProfileModalVisible: false,
      isNotificationModalVisible: false,
      ProfileImage: process.env.REACT_APP_IMAGE_URL + 'user.png',
      name: '',
      Pic: '',
    }
  }

  componentDidMount() {
    if (this.props.location.pathname.includes('member')) {
      var storage = localStorage.getItem('member_details')
    } else {
      var storage = localStorage.getItem('user_details')
    }

    if (storage && storage !== 'undefined') {
      const user = JSON.parse(storage)  
      let data = { page_no: 1, limit: 10 }
      //this.props.listNotiRequest(data)
      this.setState({
        ProfileImage: process.env.REACT_APP_IMAGE_URL + user.profilePic,
        Pic: user.profilePic,
        name: user.firstName.charAt(0) + user.lastName.charAt(0),
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      let data = { page_no: 1, limit: 10 }
      this.props.listNotiRequest(data)
    }

    if (this.props.notifications) {
      if (
        this.props.notifications.updateSuccess === true &&
        this.props.notifications.message !== prevProps.notifications.message
      ) {
        let data = { page_no: 1, limit: 10 }
        this.props.listNotiRequest(data)
      }

      if (
        this.props.notifications.error &&
        this.props.notifications.error !== prevProps.notifications.error
      ) {
        createNotification('error', this.props.notifications.message, '')
        this.handleLoading()
      }
    }
  }

  UsertoggleBox = () => {
    this.setState((prevState) => ({
      isProfileModalVisible: !prevState.isProfileModalVisible,
    }))
    this.setState({ isNotificationModalVisible: false })
  }
  NotifytoggleBox = () => {
    this.setState((prevState) => ({
      isNotificationModalVisible: !prevState.isNotificationModalVisible,
    }))
    this.setState({ isProfileModalVisible: false })
  }

  handleLogout = () => {
    if (this.props.location.pathname.includes('member')) {
      let store = ['member_role', 'member_details']
      store.forEach((item) => localStorage.removeItem(item))
      localStorage.removeItem('importProgress') 
      createNotification('success', 'You logged out successfully')
      window.location.href = '/'
    } else {
      let store = ['user_role', 'user_details']
      store.forEach((item) => localStorage.removeItem(item))
      localStorage.removeItem('importProgress') 
      createNotification('success', 'You logged out successfully')
      window.location.href = '/'
    }
  }

  notificationRead = (id) => {
    this.props.updateNotiRequest({ id: id, readSt: true })
  }

  render() {
    const {
      isProfileModalVisible,
      isNotificationModalVisible,
      ProfileImage,
      name,
      Pic,
    } = this.state
    const { title, pageTitle, backTo } = this.props
    let profile = '/profile'
    let notifications = '/notifications'
    let account = 'https://www.illumeetxp.com/account/my-account'
    let _blank = '_blank'
    if (this.props.location.pathname.includes('member')) {
      profile = '/member/profile'
      notifications = '/member/notifications'
    
    } 
    return (
      <div>
        <Helmet>
          <title>IllumeetXP | {pageTitle}</title>
        </Helmet>
        <AppBar position="static">
          <Toolbar>
            {this.props.proutes &&
            pageTitle !== 'My Events' &&
            pageTitle !== 'Analytics' &&
            title !== 'Campaigns' &&
            pageTitle !== 'Contact' &&
            pageTitle !== 'Email Campaigns' &&
            pageTitle !== 'Complete Registration' &&
            pageTitle !== 'Audience' ? (
              <Typography className="" variant="h6" noWrap>
                <a
                  onClick={() =>
                    this.props.history.push(
                      backTo || this.props.proutes.proutes,
                    )
                  }
                >
                  <img src={EditingBack} />
                </a>
                {title}
              </Typography>
            ) : title === 'Campaigns' ? (
              <Typography className="" variant="h6" noWrap>
                <a
                  onClick={() =>
                    redirectTo(
                      this.props.history,
                      `email-campaigns/${this.props.match.params.eventId}`,
                      this.props.location.pathname,
                    )
                  }
                >
                  <img src={EditingBack} />
                </a>
                {title}
              </Typography>
            ) : title === 'Design' ? (
              <Typography className="" variant="h6" noWrap>
                <a
                  onClick={() =>
                    redirectTo(
                      this.props.history,
                      `project-type-edit/${this.props.match.params.eventId}`,
                      this.props.location.pathname,
                    )
                  }
                >
                  <img src={EditingBack} />
                </a>
                {title}
              </Typography>
            ) : (
              <Typography className="" variant="h6" noWrap></Typography>
            )}

            {this.props.showProgress && (
              <div className="progessbar_Analytics">
                <ProgressBarLine
                  value={this.props.totalSent || 0}
                  min={0}
                  max={
                    this.props.totalRecord === 0 ? 1 : this.props.totalRecord
                  }
                  strokeWidth={0.3}
                  trailWidth={0.3}
                  styles={{
                    path: {
                      stroke: '#5B33F1',
                    },
                    trail: {
                      stroke: '#e0e0e0',
                    },
                    text: {
                      fill: '#404040',
                      textAlign: 'center',
                      fontSize: '22px',
                    },
                  }}
                />{' '}
                <div className="campaignProgressText">
                  Campaign Progress {this.props.totalSent}/
                  {this.props.totalRecord}
                </div>
              </div>
            )}

            {this.props.showImportProgress && (
              <div className="progessbar_Analytics">
                <ProgressBarLine
                  value={this.props.totalImported || 0}
                  min={0}
                  max={
                    this.props.totalRecord === 0 ? 1 : this.props.totalRecord
                  }
                  strokeWidth={0.3}
                  trailWidth={0.3}
                  styles={{
                    path: {
                      stroke: '#5B33F1',
                    },
                    trail: {
                      stroke: '#e0e0e0',
                    },
                    text: {
                      fill: '#404040',
                      textAlign: 'center',
                      fontSize: '22px',
                    },
                  }}
                />
                <div className="campaignProgressText">
                  Import Progress {this.props.totalImported}/
                  {this.props.totalRecord}
                </div>
              </div>
            )}

            <div className="appBarRightPortion">
              {/* {this.props.notifications && this.props.notifications.total > 0 &&    
                        <IconButton aria-label="show 17 new notifications" color="inherit" className="AppbarNotification mr-2">
                            <Badge badgeContent={this.props.notifications.unreadCount} color="secondary">
                                <img src={NotifyIcon} alt="Notification" className="notificationIcon" onClick={this.NotifytoggleBox} />
                            </Badge> 
                        </IconButton>
                        }
                        {this.props.notifications && this.props.notifications.total > 0 &&
                        <div className={`notificationDropwdown ${isNotificationModalVisible ? "Open" : ""}`}>
                       { this.props.notifications.notifications.map((data,index) => {
                    return data.readSt?
                            <Link key={index} >{data.message}</Link>
                            :<Link key={index} onMouseOver={()=>this.notificationRead(data._id)}>{data.message}</Link>
                         })
                        }
                      
                         <p onClick={()=>redirectTo(this.props.history,'notifications',this.props.location.pathname)}>View all {this.props.notifications.total} Notifications</p>
                        
                        </div>
                        } */}

              {/* <a href={account}  target={_blank}>           */}
              <IconButton
                edge="end"
                color="inherit"
                className="profileBarUser"
                onClick={this.UsertoggleBox}
              >
                {Pic === 'user.png' ? (
                  <p>{name}</p>
                ) : (

                  <img
                    src={ProfileImage}
                    alt="Profile"
                    className="profileImage"
                  />
                )}
              </IconButton>
             {/*  </a> */}
               <div
                className={`profileDropwdown ${
                  isProfileModalVisible ? 'Open' : ''
                }`}
              >

                <a className="dropdown-item"  href={account} target={_blank}>
                  <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  Account Settings
                </a>
                
                {/* <Link className="dropdown-item" to={profile}>
                  <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  Profile Settings
                </Link> */} 
                 
                {/* <Link className="dropdown-item" to={notifications}>
                  <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                  Activity Log
                </Link> */}

                <Link
                  className="dropdown-item"
                  onClick={() => this.handleLogout()}
                >
                  <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                  Logout
                </Link> 
              </div> 
            </div>
          </Toolbar>
        </AppBar>
        <NotificationContainer />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  notifications: state.Notifications,
  proutes: state.Proutes,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listNotiRequest: Actions.listNotiRequest,
      updateNotiRequest: Actions.updateNotiRequest,
    },
    dispatch,
  )
export default withRouter(connect(mapStateToProps, mapActionsToProps)(MyEvents))
