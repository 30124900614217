export const eventColorType = {
    ["61b1bcf7a72cac2a825c4910"]: "project-hybrid",
    ["61b1be73a72cac2a825c4911"]: "project-in-person",
    ["61b1cac4a72cac2a825c4917"]: "project-sale",
    ["61b1cae9a72cac2a825c4918"]: "project-registration",
    ["61b1cb1ba72cac2a825c4919"]: "project-email",
    ["61b1cb4ea72cac2a825c491a"]: "project-virtual",
}
export const eventListColorType = {
    ["61b1bcf7a72cac2a825c4910"]: "#FF4170",
    ["61b1be73a72cac2a825c4911"]: "#5141E7",
    ["61b1cac4a72cac2a825c4917"]: "#0ADDB4",
    ["61b1cae9a72cac2a825c4918"]: "#7D35C1",
    ["61b1cb1ba72cac2a825c4919"]: "#16D1FC",
    ["61b1cb4ea72cac2a825c491a"]: "#F26135",
}

export const stepperIcons = {
    ["61b1bcf7a72cac2a825c4910"]: "step1-icon1.svg",
    ["61b1be73a72cac2a825c4911"]: "step1-icon2.svg",
    ["61b1cac4a72cac2a825c4917"]: "step1-icon3.svg",
    ["61b1cae9a72cac2a825c4918"]: "step1-icon4.svg",
    ["61b1cb1ba72cac2a825c4919"]: "step1-icon5.svg",
    ["61b1cb4ea72cac2a825c491a"]: "step1-icon6.svg",
}