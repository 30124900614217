import React from 'react'
import { Redirect, Route } from 'react-router-dom'
//import { useLocation } from 'react-router-dom'

const PrivateMemberRoute = ({ component: Component, ...rest }) => {
  //const location = useLocation()
  //let userData = localStorage.getItem('member_details')
  //const user = JSON.parse(userData)

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('member_role') &&
        localStorage.getItem('member_role') !== 'undefined' &&
        localStorage.getItem('member_role') === '2' ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              // state: { from: props.location}
            }}
          />
        )
      }
    />
  )
}

export default PrivateMemberRoute
