import React, { Component } from 'react';
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from '../../../theme/theme';
import { withRouter } from 'react-router'
import Loader from '../../../Components/Loader/loader'

export class CampaignPreviewWithExit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            eventId: '',
            templateId: ''
        }
    }

    async componentDidMount() {
        let eventId = this.props.match.params.eventId; 
        let params = this.props.location;
        let templateId = new URLSearchParams(params.search).get("templateId")  
        this.setState({eventId: eventId, templateId: templateId})
    }

    render() {   
        const { eventId, templateId} = this.state;      
        return (
            <ThemeProvider theme={theme}> 
            
                {eventId !== '' && templateId !== '' ? 
                    <> 
                        <iframe className='page-preview'style={{height: '100vh'}} src={
                                `/project-dashboard/preview-campaign/${eventId}?cid=${templateId}`
                        }></iframe>
                    </>
                : 
                    <Loader />
                }
            </ThemeProvider>
        )
    }
}

export default withRouter(CampaignPreviewWithExit);

