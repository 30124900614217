import React, { Component } from 'react'
import DashboardLayout from '../../Layouts/DashboardLayout'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
import { Button } from '@material-ui/core'
import saveTheDateImage from '../../assets/images/countDownImage.png'
import InvitationEmail from '../../assets/images/invitation.jpg'
import ConfirmationEmail from '../../assets/images/confirmation.jpg'
import edittemplateSample from '../../assets/images/edittemplateSample.jpeg'
import Blanktemplate from '../../assets/images/Blanktemplate.png'
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from 'react-html-parser'
import { createNotification, redirectTo } from '../../helpers'
import { countDownHtml, countDownJson } from '../../Components/defaultTemplates'
import { getTemplateLib, updateLibTemplateById } from '../../Redux/API'
import Loader from '../../Components/Loader/loader'

class ChooseEmailtemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Campaigns',
      pageTitle: 'Choose an email template',
      eventId: 0,
      loading: false,
      selectedTemplate: 0,
      //templates: []
    }
  }

  async componentDidMount() {
    if (this.props.match.params.eventId !== '') {
      this.setState({ eventId: this.props.match.params.eventId })
      this.props.eventRequest(this.props.match.params.eventId)
      this.handleLoading()
    }
    getTemplateLib({ type: 'email-campaign' })
      .then(async (result) => {
        if (result.data && result.data.templates)
          this.setState({ templates: result.data.templates })
      })
      .catch(console.log)
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.events) {
      if (
        this.props.events.success === true &&
        this.props.events.message !== prevProps.events.message
      ) {
        let st = prevState.loading ? this.setState({ loading: false }) : null
      }
    }
  }

  editTemplate = () => { 
    if (this.state.selectedTemplate !== 0) {
      redirectTo(
        this.props.history,
        `edit-template-design/${this.state.eventId}/new/${this.state.selectedTemplate}?chooseTempId=${this.state.selectedTemplate}`,
        this.props.location.pathname,
      ) 
    } else {
      createNotification('error', 'Please select template first.')
    }
  }

  selectTemplate = (templateId) => {
    this.setState({ selectedTemplate: templateId })
  }

  deleteTemplate = (templateId) => {
    const payload = { isDeleted: true }
    updateLibTemplateById(templateId, payload)
      .then((result) => {
        if (result.data.success) {
          getTemplateLib({ type: 'email-campaign' })
            .then(async (result) => {
              if (result.data && result.data.templates)
                this.setState({ templates: result.data.templates })
            })
            .catch(console.log)
          createNotification(
            'success',
            'Template has been deleted successfully',
          )
        } else {
          createNotification('error', result.data.message)
        }
      })
      .catch(console.log)
  }

  render() {
    const {
      title,
      pageTitle,
      eventId,
      selectedTemplate,
      templates,
    } = this.state
    return (
      <DashboardLayout title={title} pageTitle={pageTitle}>
        {!templates ? (
          <Loader />
        ) : (
          <div className="dashboardMiddleArea">
            <div className="createProjectAccount projectStepRow mobileBaseMargin20px">
              <div className="projectCreateSection">
                <div className="CreatePresentation">
                  <div className="accountTypeTitle">
                    <h1>Choose email template: </h1>
                  </div>
                  <div className="edittemplateCards">
                    <div className="editTcardList">
                      {/*this.props.events.success && this.props.events.templates && this.props.events.templates.map((data)=>{

                                    return   <div className={selectedTemplate !== 0  && selectedTemplate === data._id?"editTemplateBox EDTActive":"editTemplateBox"} key={data._id}>
                                                <div className="editTemplateInnerB CRCircularBox">
                                                    <h3>{data.templateName}</h3>
                                                    <>{ ReactHtmlParser(ReactHtmlParser(data.emailTemplate)) }</>
                                                </div>
                                                <h3 className="selectTText" onClick={()=>this.selectTemplate(data._id)}>Select</h3>
                                            </div>
                                        })*/}

                      {/* <div className={selectedTemplate !== 0  && selectedTemplate === 'saveTheDate'?"editTemplateBox EDTActive":"editTemplateBox"} key="saveTheDate" onClick={()=>this.selectTemplate('saveTheDate')}>
                                                <div className="editTemplateInnerB CRCircularBox">
                                                    <h3>Save the Date</h3>
                                                    <div className="EditEmailCampaignImg">
                                                        <img src={SaveTheDate} alt="Email Campaign" />
                                                    </div>
                                                </div>
                                                <h3 className="selectTText" onClick={()=>this.selectTemplate('saveTheDate')}>Select</h3>
                                        </div>  */}
                      {/*<div className={selectedTemplate !== 0  && selectedTemplate === 'saveTheDateImage'?"editTemplateBox EDTActive":"editTemplateBox"} key="saveTheDateImage" onClick={()=>this.selectTemplate('saveTheDateImage')}>
                                                <div className="editTemplateInnerB CRCircularBox">
                                                    <h3>Save the Date</h3>
                                                    <div className="EditEmailCampaignImg">
                                                        <img src={SaveTheDate} alt="Email Campaign" />
                                                    </div>
                                                     <>{ ReactHtmlParser(ReactHtmlParser(saveTheDateHtml)) }</>
                                                </div>

                                        </div>  */}

                      {/*  <div className={selectedTemplate !== 0  && selectedTemplate === 'invitation'?"editTemplateBox EDTActive":"editTemplateBox"} key="invitation" onClick={()=>this.selectTemplate('invitation')}>
                                                <div className="editTemplateInnerB CRCircularBox">
                                                    <h3>Invitation</h3>
                                                    <div className="EditEmailCampaignImg">
                                                        <img src={InvitationEmail} alt="Email Campaign" />
                                                    </div>

                                                </div>
                                                <h3 className="selectTText" onClick={()=>this.selectTemplate('invitation')}>Select</h3>
                                        </div>
                                        <div className={selectedTemplate !== 0  && selectedTemplate === 'confirmation'?"editTemplateBox EDTActive":"editTemplateBox"} key="confirmation" onClick={()=>this.selectTemplate('confirmation')}>
                                                <div className="editTemplateInnerB CRCircularBox">
                                                    <h3>Confirmation</h3>
                                                    <div className="EditEmailCampaignImg">
                                                        <img src={ConfirmationEmail} alt="Email Campaign" />
                                                    </div>

                                                </div>
                                                <h3 className="selectTText" onClick={()=>this.selectTemplate('confirmation')}>Select</h3>
                                        </div>
                                        <div className={selectedTemplate !== 0  && selectedTemplate === 'followUp'?"editTemplateBox EDTActive":"editTemplateBox"} key="followUp" onClick={()=>this.selectTemplate('followUp')}>
                                                <div className="editTemplateInnerB CRCircularBox">
                                                    <h3>Follow-Up</h3>
                                                    <div className="EditEmailCampaignImg">
                                                        <img src={SaveTheDate} alt="Email Campaign" />
                                                    </div>

                                                </div>
                                                <h3 className="selectTText" onClick={()=>this.selectTemplate('followUp')}>Select</h3>
                                        </div> */}
                      <div
                        className={
                          selectedTemplate !== 0 && selectedTemplate === 'blank'
                            ? 'editTemplateBox EDTActive'
                            : 'editTemplateBox'
                        }
                        key="followUp"
                      >
                        <div
                          className="editTemplateInnerB CRCircularBox"
                          onClick={() => this.selectTemplate('blank')}
                        >
                          <h3>Blank</h3>
                          <div className="editEmailCampaignBlank">
                            <div className="EcbInnerBox">
                              <span></span>
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                          </div>
                          {/* <>{ ReactHtmlParser(ReactHtmlParser(blankHtml)) }</>  */}
                        </div>
                      </div>

                      <div
                        className={
                          selectedTemplate !== 0 &&
                          selectedTemplate === 'saveTheDate'
                            ? 'editTemplateBox EDTActive'
                            : 'editTemplateBox'
                        }
                        key="followUp"
                      >
                        <div
                          className="editTemplateInnerB CRCircularBox"
                          onClick={() => this.selectTemplate('saveTheDate')}
                        >
                          <h3>Save the date</h3>

                          <div className="EditEmailCampaignImg templateSaveThe">
                            <img src={saveTheDateImage} alt="Email Campaign" />
                          </div>
                          {/* {ReactHtmlParser(ReactHtmlParser(countDownHtml))} */}

                          {/* <>{ ReactHtmlParser(ReactHtmlParser(blankHtml)) }</>  */}
                        </div>
                      </div>

                      {templates &&
                        templates.map((obj) => (
                          <div
                            className={
                              selectedTemplate === obj._id
                                ? 'editTemplateBox EDTActive'
                                : 'editTemplateBox'
                            }
                            key="saveTheDateImage"
                            onClick={() => this.selectTemplate(obj._id)}
                          >
                            <div className="editTemplateInnerB CRCircularBox">
                              <h3>{obj.templateName}</h3>
                              {ReactHtmlParser(obj.html)}
                            </div>
                            <button
                              type="button"
                              className="edit-button"
                              onClick={() => {
                                redirectTo(
                                  this.props.history,
                                  `edit-lib-template/eid/${this.state.eventId}/tid/${obj._id}`,
                                  this.props.location.pathname,
                                )
                              }}
                            >
                              <i class="fa fa-edit"></i>
                            </button>
                            <button
                              type="button"
                              className=" delete-button"
                              onClick={() => this.deleteTemplate(obj._id)}
                            >
                              <i class="fa fa-trash-alt"></i>
                            </button>
                          </div>
                        ))}
                    </div>
                    <div className="submitForm mt-3 justify-content-center">
                      <Button
                        type="button"
                        className="themeBlueButton"
                        onClick={this.editTemplate}
                      >
                        Use Template
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  events: state.Events,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(ChooseEmailtemplate)
