import React, { Component } from 'react'

import urlImg from '../../../assets/images/icons/url.svg';
import addImg from '../../../assets/images/icons/add.svg';
import pagethum1 from '../../../assets/images/page-thum1.png';
import pagethum2 from '../../../assets/images/page-thum2.png';
import Button from '@material-ui/core/Button';

class ManagePage extends Component { 
    constructor(props) {
        super(props);
        this.state = {

        }
    }



    render() {

         return (
            <>
                <div class="manage-page-wrapper row">
                    <div Class="page-card col">
                        <div class="page-card-img" style={{background:`url(${pagethum1})`}}>
                        </div>
                        <div class="page-card-body">
                            <h3 className='page-card-title'>Login Page </h3>
                            <a href="https://softobiz.illumeetxp.com/about-event" className='page-card-url'>
                                https://softobiz.illumeetxp.com/about-event
                                <span>
                                    <img alt="url-img" src={urlImg} />
                                </span>
                            </a>
                            <p className="page-card-status">Last update on Monday, 5 oct, 2021 at 7:45 PM </p>
                            <Button variant="outlined" color="primary" className='mb-2'>
                                PREVIEW & CUSTOMIZE
                            </Button>
                            <Button color="secondary">DELETE PAGE</Button>
                        </div>

                    </div>
                    <div Class="page-card col">
                        <div class="page-card-img" style={{background:`url(${pagethum2})`}}>
                        </div>
                        <div class="page-card-body">
                            <h3 className='page-card-title'>Login Page </h3>
                            <a href="https://softobiz.illumeetxp.com/about-event" className='page-card-url'>
                                https://softobiz.illumeetxp.com/about-event
                                <span>
                                    <img alt="url-img" src={urlImg} />
                                </span>
                            </a>
                            <p className="page-card-status">Last update on Monday, 5 oct, 2021 at 7:45 PM </p>
                            <Button variant="outlined" color="primary" className='mb-2'>
                                PREVIEW & CUSTOMIZE
                            </Button>
                            <Button color="secondary">DELETE PAGE</Button>
                        </div>
                    </div>
                    
                    <div Class="add-page col">
                        <div class="add-icon">
                            <img alt="" src={addImg} />
                            <p>Add New Page</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default (ManagePage);
