
const getUnLayerConfig = (
  apiUrl = '',
  eventId = '',
  formTemplates = [],
  customToolFileds = [],
  customFiledsForAudience =[],
  passwordType = '',
  cssUnlyaerPlayground = "",
  jsUnlyaerPlayground = "",
  eventStartDate = "",
  challengesYt = [],
  pageId = '',
  sessions = [],
  isAccessCode = false,
  timerTimeTypeDynamic = [{}], 
  qrCodeTypeDynamic = [{}],
  qrCodeSessionOption = [{}],
  isSSOEnabled = false,
  qrCodeSizeDropdown = [{ 'label': "200X200", 'value': 200 }],
  eventTimezone = null, 
  eventData=[],
  addToCalenderTimeType = [{}],
  qrCodeAttendeeDynamic = [{ 'label': "Choose One", 'value': "" }, { 'label': "Email", 'value': "{{{attendeeEmailQr}}}" }, { 'label': "Group", 'value': "{{{attendeeGroupQr}}}" }],
  customFieldDynamic = {},
  challengesDynamic = [{ 'label': "Choose One", 'value': "" }],
  sessionDynamic = [{ 'label': "Choose One", 'value': "" }],
  lookupCustomFields = [],
  audienceDynamicCustomField= {},
) => {
  /* For customFieldDynamic start here*/
  customFieldDynamic['email'] = {
    name: "Email",
    value: "{{{attendeeEmail}}}"
  };
  customFieldDynamic['image'] = {
    name: "Image",
    value: "{{{attendeeImage}}}"
  };
  customFieldDynamic['group'] = {
    name: "Group",
    value: "{{{attendeeGroup}}}"
  };

  let fieldData = {
    _id: 'email',
    fieldData: {
      fieldLabel: 'Email',
      fieldName: 'email',
      fieldType: 'text'
    }
  };
  lookupCustomFields.push(fieldData);
  if (customToolFileds && customToolFileds.length > 0) {
    for (var x = 0; x < customToolFileds.length; x++) {
      customFieldDynamic[customToolFileds[x].fieldData.fieldName] = {
        name: customToolFileds[x].fieldData.fieldLabel,
        value: "{{{" + customToolFileds[x].fieldData.fieldName + "}}}"
      };
      qrCodeAttendeeDynamic.push({ 'label': customToolFileds[x].fieldData.fieldLabel, 'value': "{{{" + customToolFileds[x].fieldData.fieldName + "Qr}}}" });
      lookupCustomFields.push(customToolFileds[x]);
    }
  }



  if (challengesYt?.length > 0) {
    for (var x = 0; x < challengesYt.length; x++) {
      const { challengeName, _id } = challengesYt[x];
      challengesDynamic.push({ 'label': challengeName, 'value': _id });
    }
  }

  if (sessions.length > 0) {
    for (var x = 0; x < sessions.length; x++) {
      const { _id, title, dateTime } = sessions[x];
      // sessionDynamic.push({'label': title, 'value':dateTime})
      sessionDynamic.push({ 'label': title, 'value': _id });
    }
  }

  // email for audience contact
  audienceDynamicCustomField['email'] = {
    name: "Email",
    value: "{{{contactEmail}}}"
  };

  // custom fields for audience contact

  if (customFiledsForAudience && customFiledsForAudience.length > 0) {
    for (var x = 0; x < customFiledsForAudience.length; x++) {
      audienceDynamicCustomField[customFiledsForAudience[x]?.fieldData?.fieldName] = {
        name: customFiledsForAudience[x].fieldData?.fieldLabel,
        value: "{{{" + customFiledsForAudience[x]?.fieldData?.fieldName + "}}}"
      }
    }
  }


  return {
    id: 'pageEditor',
    projectId: 21377,
    displayMode: 'web',
    features: {
      pageAnchors: true,
      textEditor: {
        tables: true,
        spellChecker: true,
      },
    },
    mergeTags: {
      session: {
        name: "Session",
        mergeTags: {
          title: {
            name: "Title",
            value: "{{{sessionTitle}}}"
          },
          date: {
            name: "Date",
            value: "{{{sessionDate}}}"
          },
          duration: {
            name: "Duration",
            value: "{{{sessionDuration}}}"
          },
          location: {
            name: "Location",
            value: "{{{sessionLocation}}}"
          },
          navigateTo: {
            name: "Navigate to",
            value: "{{{sessionNavigateTo}}}"
          },
          description: {
            name: "Description",
            value: "{{{sessionDescription}}}"
          },
          tracks: {
            name: "Tracks",
            value: "{{{sessionTracks}}}"
          },
          image: {
            name: "Image",
            value: "{{{sessionImage}}}"
          },
          speakerName: {
            name: "Speakers Block",
            value: "{{{speakersBlock}}}"
          },
          sessionStartTime: {
            name: "Session Start Time",
            value: "{{{sessionStartTime}}}"
          },
          sessionEndTime: {
            name: "Session End Time",
            value: "{{{sessionEndTime}}}"
          },
          sessionAddToCalendarBtn: {
            name: "Add to calendar",
            value: "{{{sessionAddToCalendarBtn}}}"
          },
        }
      },
      audienceName: {
        name: "Audience Contact",
        mergeTags: audienceDynamicCustomField,
      },
      customeField: {
        name: "Attendee",
        mergeTags: customFieldDynamic,
      },
      sessions: {
        name: 'Sessions',
        rules: {
          repeat: {
            name: 'Repeat for Each Sessions',
            before: '{{#sessions}}',
            after: '{{/sessions}}',
          },
        },
      },
      sessionsGroupByDate: {
        name: 'Session Group By Date',
        rules: {
          repeat: {
            name: 'Repeat for Each Session Group By Date',
            before: '{{#sessionsGroupByDate}}',
            after: '{{/sessionsGroupByDate}}',
          },
        },
      },
    },
    tools: {
      form: {
        enabled: false,
      },
      'custom#product_tool': {
        data: {
          products: formTemplates,
          customFields: customToolFileds,
          lookupCustomFields: lookupCustomFields,
          ytChallenges: challengesYt,
          ytOpenChallange: challengesYt,
          eventId: eventId,
          pageId: pageId,
          passwordProtection: passwordType,
          eventStartDate: eventStartDate,
          isAccessCode: isAccessCode,
          isSSOEnabled: isSSOEnabled,
          eventTimezone: eventTimezone, 
          eventData: eventData
        },
        properties: {
          productLibrary: {
            editor: {
              data: {
                products: formTemplates,
                customFields: customToolFileds,
                lookupCustomFields: lookupCustomFields,
                ytChallenges: challengesYt,
                ytOpenChallange: challengesYt,
                eventId: eventId,
                pageId: pageId,
                passwordProtection: passwordType,
                eventStartDate: eventStartDate,
                isAccessCode: isAccessCode,
                isSSOEnabled: isSSOEnabled,
                eventTimezone: eventTimezone, 
                eventData: eventData,
              },
            },
          },
          addNewFieldLibrary: {
            editor: {
              data: {
                customFields: customToolFileds,
              },
            },
          },

          lookupDropDown: {
            editor: {
              data: {
                customFields: lookupCustomFields,
              },
            },
          },

          onOpenLibrary: {
            editor: {
              data: {
                options: challengesDynamic,
              },
            },
          },

          onPlayLibrary: {
            editor: {
              data: {
                options: challengesDynamic,
              },
            },
          },

          onEndLibrary: {
            editor: {
              data: {
                options: challengesDynamic,
              },
            },
          },

          onOpenLibraryV: {
            editor: {
              data: {
                options: challengesDynamic,
              },
            },
          },

          onPlayLibraryV: {
            editor: {
              data: {
                options: challengesDynamic,
              },
            },
          },

          onEndLibraryV: {
            editor: {
              data: {
                options: challengesDynamic,
              },
            },
          },

          timerTimeType: {
            editor: {
              data: {
                options: timerTimeTypeDynamic,
              },
            },
          },
          sessionDynamicOptions: {
            editor: {
              data: {
                options: sessionDynamic,
              },
            },
          },

          QrCodeType: {
            editor: {
              data: {
                options: qrCodeTypeDynamic,
              },
            },
          },

          QrCodeAttendeesDropdown: {
            editor: {
              data: {
                options: qrCodeAttendeeDynamic,
              },
            },
          },

          QrCodeSessionDropdown: {
            editor: {
              data: {
                options: qrCodeSessionOption,
              },
            },
          },
          QrCodeSizeDropdown: {
            editor: {
              data: {
                options: qrCodeSizeDropdown,
              },
            },
          },
          calendarTimeType: {
            editor: {
              data: {
                options: addToCalenderTimeType,
              },
            },
          },
          calendarSessionDynamicOptions: {
            editor: {
              data: {
                options: sessionDynamic,
              },
            },
          },
        },
      },
    },
    customCSS: [
      cssUnlyaerPlayground,
      /*  window.location.protocol + '//' + window.location.host + '/custom.css',  */
      'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/custom.css'
    ],

    customJS: [
      // live files new 
      // 'https://accounts.google.com/gsi/client',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/customToolProductItemsTemplate.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/countDown.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/customToolDefaultShowProperties.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/customLogFormShowProperties.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/customRegisterFormShowProperties.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/customYouTubeToolShowProperties.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/customVimeoToolShowProperties.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/customToolForLogin.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/customToolForRegister.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/customToolForYtct.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/customToolForVimeoCt.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/customCountDownTimerShowProperties.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/customToolForCountDownCt.js', 
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/customQRCodeShowProperties.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/QrCodeLibrary/qrcode.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/customToolForQRCodeCt.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/customLookUpShowProperties.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/customToolForLookUpCt.js',
      // 'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/custom.js', 

      // on local  
      'https://accounts.google.com/gsi/client',
      'https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js',
      'https://momentjs.com/downloads/moment.js',
      window.location.protocol + '//' + window.location.host + '/customToolProductItemsTemplate.js',
      window.location.protocol + '//' + window.location.host + '/countDown.js',
      window.location.protocol + '//' + window.location.host + '/customToolDefaultShowProperties.js',
      window.location.protocol + '//' + window.location.host + '/customLogFormShowProperties.js',
      window.location.protocol + '//' + window.location.host + '/customRegisterFormShowProperties.js',
      window.location.protocol + '//' + window.location.host + '/customYouTubeToolShowProperties.js',
      window.location.protocol + '//' + window.location.host + '/customVimeoToolShowProperties.js',
      window.location.protocol + '//' + window.location.host + '/customToolForLogin.js',
      window.location.protocol + '//' + window.location.host + '/customToolForRegister.js',
      window.location.protocol + '//' + window.location.host + '/customToolForYtct.js',
      window.location.protocol + '//' + window.location.host + '/customToolForVimeoCt.js',
      window.location.protocol + '//' + window.location.host + '/customCountDownTimerShowProperties.js',
      window.location.protocol + '//' + window.location.host + '/customToolForCountDownCt.js',
      window.location.protocol + '//' + window.location.host + '/customQRCodeShowProperties.js',
      window.location.protocol + '//' + window.location.host + '/customLookUpShowProperties.js',
      window.location.protocol + '//' + window.location.host + '/customToolForLookUpCt.js',
      window.location.protocol + '//' + window.location.host + '/customAddToCalendarShowProperties.js',
      window.location.protocol + '//' + window.location.host + '/customToolForAddToCalendarCt.js',
      window.location.protocol + '//' + window.location.host + '/library/qrcode.js',
      'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/QrCodeLibrary/qrcode.js',
      window.location.protocol + '//' + window.location.host + '/customToolForQRCodeCt.js',
      window.location.protocol + '//' + window.location.host + '/custom.js',
    ],
  };
};
export default getUnLayerConfig



