import React, { Component } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@material-ui/core/Button'
import { theme } from '../../theme/theme'

export class ProfileLogout extends Component {
    render() {
        return (
            <>
                <Box
                    px={{ xs: 1, sm: 2, lg: 4 }}
                    pt={{ xs: 1, sm: 2, lg: 4 }}
                    sx={{
                        background: '#5B33F1',
                        height: '255px',
                    }}
                >
                    <Box
                        component={Grid}
                        container
                        spacing={2}
                        mb={2}
                        justifyContent="end"
                    >
                        <Grid item>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{
                                    color: theme.palette.common.white,
                                    borderColor: theme.palette.common.white,
                                }}
                                size={'small'}
                            >
                                Log Out
                            </Button>
                        </Grid>
                    </Box>
                </Box>
            </>
        )
    }
}

export default ProfileLogout