import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
    error: false,
    message: null,
    listCampaignsSuccess: false,
    listAllCampaignsSuccess: false,
    campaigns: [],
    allCampaigns: [],
    totalResults: 0,
    filterByStatus: {},
    checkCampaignSuccess: false,
    listCampaignTemplatesRequest: false,
    filterTemplatesByType: {},
    createCampaignData: {},
    createCampaignSuccess: false,
    updateCampaignSuccess: false,
    campaignDetails: [],
    attendeesOfCampaigns: [],
    attendeesNotAddedToCampaign: [],
    listAttendeesOfCampaignsSuccess: false,
    listAudienceGroupsSuccess: false,
    audienceGroups: [],
    listAttendeesNotAddedToCampaignsSuccess: false,
    sendTestEmailSuccess: false,
    totalCampaignCountsForUser: null,
    listOfAttendeesOfCampaignBackupFromRedux: 0,
    replicateCampaignSuccess: false,
    campaignNameInEditor: '',
    getExcludeAttendeeCountSuccess:false,
    getExcludeCountSuccess:false,
    excludeAttendeeCount: 0,
    excludeCount:0
}

const listCampaignsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        listCampaignsSuccess: false,
        campaigns: [],
        totalResults: 0,
        filterByStatus: {},
        listCampaignTemplatesRequest: false,
        createCampaignData: {},
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const listCampaignsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        listCampaignsSuccess: true,
        campaigns: action.campaigns,
        totalResults: action.totalResults,
        filterByStatus: action.filterByStatus,
        listCampaignTemplatesRequest: false,
        createCampaignData: {},
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const listCampaignsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        listCampaignsSuccess: false,
        totalResults: 0,
        filterByStatus: {},
        listCampaignTemplatesRequest: false,
        createCampaignData: {},
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const listAllCampaignsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        listAllCampaignsSuccess: false,
        allCampaigns: []
    }
}

const listAllCampaignsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        listAllCampaignsSuccess: true,
        allCampaigns: action.campaigns
    }
}

const listAllCampaignsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        listAllCampaignsSuccess: false
    }
}

const checkCampaignRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        checkCampaignSuccess: false,
        listCampaignTemplatesRequest: false,
        createCampaignData: {},
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const checkCampaignSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        filterByStatus: {},
        message: action.message,
        error: false,
        checkCampaignSuccess: true,
        listCampaignTemplatesRequest: false,
        createCampaignData: {},
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const checkCampaignError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: action.message,
        error: true,
        checkCampaignSuccess: false,
        listCampaignTemplatesRequest: false,
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const listCampaignTemplatesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
        filterTemplatesByType: {}
    }
}

const listCampaignTemplatesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        campaignTemplates: action.campaignTemplates,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: true,
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
        filterTemplatesByType: action.filterTemplatesByType
    }
}

const listCampaignTemplatesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.message,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
        filterTemplatesByType: {}
    }
}

const createCampaignRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        createCampaignData: {},
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        updateCampaignSuccess: false,
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const createCampaignSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        createCampaignData: action.data,
        createCampaignSuccess: true,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        updateCampaignSuccess: false,
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const createCampaignError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        createCampaignData: {},
        message: action.error,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        updateCampaignSuccess: false,
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const updateCampaignRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        updateCampaignSuccess: false,
        updateCampaignData: {},
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        getCampaignSuccess: false,
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}


const updateCampaignSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        updateCampaignSuccess: true,
        updateCampaignData: action.data,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        getCampaignSuccess: false,
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const updateCampaignError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        updateCampaignData: {},
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        getCampaignSuccess: false,
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const getCampaignRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        getCampaignSuccess: false,
        campaignDetails: [],
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const getCampaignSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        getCampaignSuccess: true,
        campaignDetails: action.campaign,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const getCampaignError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        campaignDetails: false,
        getCampaignSuccess: false,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const deleteCampaignRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteCampaignSuccess: false,
        error: false,
        message: null,
        getCampaignSuccess: false,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const deleteCampaignSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteCampaignSuccess: true,
        error: false,
        message: action.message,
        getCampaignSuccess: false,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const deleteCampaignError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteCampaignSuccess: false,
        error: true,
        message: action.error,
        getCampaignSuccess: false,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAttendeesOfCampaignsSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const listAttendeesOfCampaignsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        attendeesOfCampaigns: [],
        listAttendeesOfCampaignsSuccess: false,
        deleteCampaignSuccess: false,
        getCampaignSuccess: false,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAudienceGroupsSuccess: false,
        listAttendeesNotAddedToCampaignsSuccess: false,
    }
}

const listAttendeesOfCampaignsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        attendeesOfCampaigns: action.data,
        listAttendeesOfCampaignsSuccess: true,
        deleteCampaignSuccess: false,
        getCampaignSuccess: false,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAudienceGroupsSuccess: false,
        listAttendeesNotAddedToCampaignsSuccess: false,
    }
}

const listAttendeesOfCampaignsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        attendeesOfCampaigns: [],
        listAttendeesOfCampaignsSuccess: false,
        deleteCampaignSuccess: false,
        getCampaignSuccess: false,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAudienceGroupsSuccess: false,
        listAttendeesNotAddedToCampaignsSuccess: false,
    }
}

const listAttendeesNotAddedToCampaignRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        attendeesNotAddedToCampaign: [],
        listAttendeesNotAddedToCampaignsSuccess: false,
        listAttendeesOfCampaignsSuccess: false,
        deleteCampaignSuccess: false,
        getCampaignSuccess: false,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const listAttendeesNotAddedToCampaignSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        attendeesNotAddedToCampaign: action.data,
        listAttendeesNotAddedToCampaignsSuccess: true,
        listAttendeesOfCampaignsSuccess: false,
        deleteCampaignSuccess: false,
        getCampaignSuccess: false,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const listAttendeesNotAddedToCampaignError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        attendeesNotAddedToCampaign: [],
        listAttendeesNotAddedToCampaignsSuccess: false,
        listAttendeesOfCampaignsSuccess: false,
        deleteCampaignSuccess: false,
        getCampaignSuccess: false,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAudienceGroupsSuccess: false,
    }
}

const listAudienceGroupsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        listAudienceGroupsSuccess: false,
        audienceGroups: [],
        listAttendeesOfCampaignsSuccess: false,
        deleteCampaignSuccess: false,
        getCampaignSuccess: false,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAttendeesNotAddedToCampaignsSuccess: false,
    }
}

const listAudienceGroupsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        listAudienceGroupsSuccess: true,
        audienceGroups: action.groups,
        listAttendeesOfCampaignsSuccess: false,
        deleteCampaignSuccess: false,
        getCampaignSuccess: false,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAttendeesNotAddedToCampaignsSuccess: false,
    }
}

const listAudienceGroupsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        listAudienceGroupsSuccess: false,
        audienceGroups: [],
        listAttendeesOfCampaignsSuccess: false,
        deleteCampaignSuccess: false,
        getCampaignSuccess: false,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAttendeesNotAddedToCampaignsSuccess: false,
    }
}

const sendTestEmailRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        sendTestEmailSuccess: false,
        listAudienceGroupsSuccess: false,
        audienceGroups: [],
        listAttendeesOfCampaignsSuccess: false,
        deleteCampaignSuccess: false,
        getCampaignSuccess: false,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAttendeesNotAddedToCampaignsSuccess: false,
    }
}

const sendTestEmailSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        sendTestEmailSuccess: true,
        listAudienceGroupsSuccess: false,
        audienceGroups: [],
        listAttendeesOfCampaignsSuccess: false,
        deleteCampaignSuccess: false,
        getCampaignSuccess: false,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAttendeesNotAddedToCampaignsSuccess: false,
    }
}

const sendTestEmailError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        sendTestEmailSuccess: false,
        listAudienceGroupsSuccess: false,
        audienceGroups: [],
        listAttendeesOfCampaignsSuccess: false,
        deleteCampaignSuccess: false,
        getCampaignSuccess: false,
        updateCampaignSuccess: false,
        createCampaignSuccess: false,
        checkCampaignSuccess: false,
        listCampaignTemplatesSuccess: false,
        listAttendeesNotAddedToCampaignsSuccess: false,
    }
}

const handleListOfAttendeesOfCampaignBackup = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listOfAttendeesOfCampaignBackupFromRedux: action.data,
    }
}

const replicateCampaignRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        replicateCampaignSuccess: false,
        error: false,
        message: '',
    }
}
const replicateCampaignSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        replicateCampaignSuccess: true,
        error: false,
        message: action.message,
    }
}
const replicateCampaignError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        replicateCampaignSuccess: false,
        error: true,
        message: action.error,
    }
}

const handleCampaignNameInEditor = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        campaignNameInEditor: action.name
    }
}


const getExcludeAttendeeCountRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        getExcludeAttendeeCountSuccess: false,
        excludeAttendeeCount: 0,
    }
}

const getExcludeAttendeeCountSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        getExcludeAttendeeCountSuccess: true,
        excludeAttendeeCount: action.excludeAttendeeCount,
    }
}

const getExcludeAttendeeCountError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        getExcludeAttendeeCountSuccess: false,
        excludeAttendeeCount: 0,
    }
}


const getExcludeCountRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        getExcludeCountSuccess: false,
        excludeCount: 0,
    }
}

const getExcludeCountSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        getExcludeCountSuccess: true,
        excludeCount: action.excludeCount,
    }
}

const getExcludeCountError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        getExcludeCountSuccess: false,
        excludeCount: 0,
    }
}


const HANDLERS = {
    [Constants.GET_EXCLUDE_COUNT_ERROR]: getExcludeCountError,
    [Constants.GET_EXCLUDE_COUNT_SUCCESS]: getExcludeCountSuccess,
    [Constants.GET_EXCLUDE_COUNT_REQUEST]: getExcludeCountRequest,
    [Constants.GET_EXCLUDE_ATTENDEE_COUNT_ERROR]: getExcludeAttendeeCountError,
    [Constants.GET_EXCLUDE_ATTENDEE_COUNT_SUCCESS]: getExcludeAttendeeCountSuccess,
    [Constants.GET_EXCLUDE_ATTENDEE_COUNT_REQUEST]: getExcludeAttendeeCountRequest,
    [Constants.SEND_TEST_EMAIL_ERROR]: sendTestEmailError,
    [Constants.SEND_TEST_EMAIL_SUCCESS]: sendTestEmailSuccess,
    [Constants.SEND_TEST_EMAIL_REQUEST]: sendTestEmailRequest,
    [Constants.LIST_ATTENDEES_NOT_ADDED_TO_CAMPAIGN_SUCCESS]: listAttendeesNotAddedToCampaignSuccess,
    [Constants.LIST_ATTENDEES_NOT_ADDED_TO_CAMPAIGN_ERROR]: listAttendeesNotAddedToCampaignError,
    [Constants.LIST_ATTENDEES_NOT_ADDED_TO_CAMPAIGN_REQUEST]: listAttendeesNotAddedToCampaignRequest,
    [Constants.LIST_AUDIENCE_GROUPS_ERROR]: listAudienceGroupsError,
    [Constants.LIST_AUDIENCE_GROUPS_SUCCESS]: listAudienceGroupsSuccess,
    [Constants.LIST_AUDIENCE_GROUPS_REQUEST]: listAudienceGroupsRequest,
    [Constants.LIST_ATTENDEES_OF_CAMPAIGNS_SUCCESS]: listAttendeesOfCampaignsSuccess,
    [Constants.LIST_ATTENDEES_OF_CAMPAIGNS_ERROR]: listAttendeesOfCampaignsError,
    [Constants.LIST_ATTENDEES_OF_CAMPAIGNS_REQUEST]: listAttendeesOfCampaignsRequest,
    [Constants.LIST_CAMPAIGNS_REQUEST]: listCampaignsRequest,
    [Constants.LIST_CAMPAIGNS_SUCCESS]: listCampaignsSuccess,
    [Constants.LIST_CAMPAIGNS_ERROR]: listCampaignsError,
    [Constants.LIST_ALL_CAMPAIGNS_REQUEST]: listAllCampaignsRequest,
    [Constants.LIST_ALL_CAMPAIGNS_SUCCESS]: listAllCampaignsSuccess,
    [Constants.LIST_ALL_CAMPAIGNS_ERROR]: listAllCampaignsError,
    [Constants.CHECK_CAMPAIGN_REQUEST]: checkCampaignRequest,
    [Constants.CHECK_CAMPAIGN_SUCCESS]: checkCampaignSuccess,
    [Constants.CHECK_CAMPAIGN_ERROR]: checkCampaignError,
    [Constants.LIST_CAMPAIGN_TEMPLATES_REQUEST]: listCampaignTemplatesRequest,
    [Constants.LIST_CAMPAIGN_TEMPLATES_SUCCESS]: listCampaignTemplatesSuccess,
    [Constants.LIST_CAMPAIGN_TEMPLATES_ERROR]: listCampaignTemplatesError,
    [Constants.CREATE_CAMPAIGN_REQUEST]: createCampaignRequest,
    [Constants.CREATE_CAMPAIGN_SUCCESS]: createCampaignSuccess,
    [Constants.CREATE_CAMPAIGN_ERROR]: createCampaignError,
    [Constants.UPDATE_CAMPAIGN_REQUEST]: updateCampaignRequest,
    [Constants.UPDATE_CAMPAIGN_SUCCESS]: updateCampaignSuccess,
    [Constants.UPDATE_CAMPAIGN_ERROR]: updateCampaignError,
    [Constants.GET_CAMPAIGN_REQUEST]: getCampaignRequest,
    [Constants.GET_CAMPAIGN_SUCCESS]: getCampaignSuccess,
    [Constants.GET_CAMPAIGN_ERROR]: getCampaignError,
    [Constants.DELETE_CAMPAIGN_REQUEST]: deleteCampaignRequest,
    [Constants.DELETE_CAMPAIGN_SUCCESS]: deleteCampaignSuccess,
    [Constants.DELETE_CAMPAIGN_ERROR]: deleteCampaignError,
    [Constants.HANDLE_LIST_OF_ATTENDEES_OF_CAMPAIGN_BACKUP]: handleListOfAttendeesOfCampaignBackup,
    [Constants.REPLICATE_CAMPAIGN_REQUEST]: replicateCampaignRequest,
    [Constants.REPLICATE_CAMPAIGN_SUCCESS]: replicateCampaignSuccess,
    [Constants.REPLICATE_CAMPAIGN_ERROR]: replicateCampaignError,
    [Constants.HANDLE_CAMPAIGN_NAME_IN_EDITOR]: handleCampaignNameInEditor,
}

export default createReducer(INITIAL_STATE, HANDLERS)