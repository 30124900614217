require("dotenv").config();

export const Url = {
    apiLive: process.env.PROD_VARNISH_API_URL ? process.env.PROD_VARNISH_API_URL : 'https://varnish-api.illumeetxp.com/',
    wixUrl: 'https://www.illumeetxp.com',
    opportunityAttachmentUrl: `${process.env.REACT_APP_IMAGE_URL}/oppAttachments`
};

export const showLogin = process.env.REACT_APP_SHOWLOGIN === "true" ? true : false;
export const accessType = 'adminUserIllumeetxpApp';
export const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
export const defaultThumbnail = 'default-thumbnail.png';
export const ACCESS_CODE_EXPERING_PERIOD = 5000000;

export const permissions = {
    viewGamification: 'View Gamification',
    editGamification: 'Edit Gamification',
    exportGamification: 'Export Gamification',
    addManualPoints: 'Add Manual Points',
    viewAttendees: 'View Attendees',
    addKioskUser: 'addKioskUser',
    createAttendee: 'Create Attendee',
    editAttendee: 'Edit Attendee',
    deleteAttendee: 'Delete Attendee',
    checkInCheckOutAttendee: 'CheckIn & CheckOut Attendee',
    importAttendee: 'Import Attendee',
    exportAttendees: 'Export Attendees',
    editCustomFields: 'Edit Custom Fields',
    editGroups: 'editGroups',
    editTransactionalEmailTemplates: 'Edit Transactional Email Templates',
    resetPassword: 'Reset Password',
    viewOutboundEmailsReports: 'View Outbound Emails Reports',
    assignStatusAttendees: 'Assign Status Attendees',
    editGroups: 'Edit Groups',
    editAccessCodes: 'Edit AccessCodes',
    viewAccessCodes: 'View AccessCodes',
    createAccessCodes: 'Create AccessCodes',
    deleteAccessCodes: 'Delete AccessCodes',
    viewSessions: 'View Sessions',
    deleteSession: "Delete Session",
    createSession: 'Create Session',
    editSession: 'Edit Session',
    viewCampaigns: 'View Campaigns',
    addCampaign: 'Add Campaign',
    deleteCampaign: 'Delete Campaign',
    editCampaign: 'Edit Campaign',
    createCampaign: 'Create Campaign',
    editProject: 'Edit Project',
    viewProject: 'View Project',
    viewMembers: 'View Members',
    editMembers: 'Edit Members',
    webhooks: 'Webhooks',
    viewNotifications: "View Notifications",
    createNotification: "Create Notification",
    editNotification: "Edit Notification",
    deleteNotification: "Delete Notification",
    viewPages: "View Pages",
    deletePage: "Delete Page",
    editPageSettings: "Edit Page Settings",
    editPage: "Edit Page",
    createPage: "Create Page",
    editTransactionalEmailTemplates: "Edit Transactional Email Templates",
    viewMeeting: 'viewMeeting',
    deleteMeeting: 'deleteMeeting',
    editMeeting: 'editMeeting',
    createMeeting: 'createMeeting',
};
export const FILE_SECTION = {
    images: "Images",
    videos: "Videos",
    docs: "Documents"
}

export const FILE_TYPE = {
    folder: "Folder",
    file:"File"
}

