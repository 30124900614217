import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from '../../Redux/Actions';
import Loader from '../../Components/Loader/loader';
import { createNotification } from '../../helpers';
import { NotificationContainer } from 'react-notifications';
import '../../sass/main.scss';
import { Button } from '@material-ui/core';
import 'react-responsive-modal/styles.css';
import { Multiselect } from 'multiselect-react-dropdown';

import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';

import Avatar from '@material-ui/core/Avatar';
import { styled } from '@material-ui/core/styles';
import userImg from '../../assets/images/icons/user.svg';
import ImgCrop from '../../Pages/demo/MyProfile/Crop';
import { Modal } from 'react-responsive-modal';
import userImageIcon from '../../assets/images/icons/userImageIcon.svg';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@material-ui/core/MenuItem';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};

class UpdateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      loading: false,
      profilePic: '',
      group_id: this.props.singleData.groupId
        ? this.props.singleData.groupId
        : [],
      firstName: this.props.singleData.firstName
        ? this.props.singleData.firstName
        : '',
      lastName: this.props.singleData.lastName
        ? this.props.singleData.lastName
        : '',
      email: this.props.singleData.email ? this.props.singleData.email : '',
      company: this.props.singleData.company
        ? this.props.singleData.company
        : '',
      _id: this.props.singleData._id ? this.props.singleData._id : '',
      singleData: this.props.singleData ? this.props.singleData : {},
      ProfileImage: this.props.singleData.profilePic
        ? process.env.REACT_APP_IMAGE_URL +
          '/contactsImages/' +
          this.props.singleData.profilePic
        : '',
      page_no: this.props.page_no ? this.props.page_no : 1,
      options: [
        { name: 'Srigar', id: 1 },
        { name: 'Sam', id: 2 },
      ],
      selectedOptions: [],
      cropOpen: false,
      cropPic: '',
      confirmImage: '',
      uploadImage: '',
      imageRemoved: false,
      customFieldsForContact: this.props.singleData.customFieldData,
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.contacts.updateSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('success', this.props.contacts.message);
      let st = prevState.loading ? this.setState({ loading: false }) : null;
      this.setState({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        open: false,
      });
    }

    if (
      this.props.contacts.updateError === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('error', this.props.contacts.message, '');
      let st = prevState.loading ? this.setState({ loading: false }) : null;
    }

    if (this.state.ProfileImage !== prevState.ProfileImage) {
      if ('File' in window && this.state.ProfileImage instanceof File) {
        this.setState({
          imageRemoved: false,
        });
      }
    }
  }

  componentDidMount() {
    let selectedOptions = [];
    let groupArray = [];

    selectedOptions.push({
      id: this.props.singleData.groupId,
      groupName: this.props.singleData.groupName,
    });
    groupArray.push(this.props.singleData.groupId);
    this.setState({ group_id: groupArray, selectedOptions: selectedOptions });
  }

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      const imageFile = event.target.files[0];
      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        createNotification('error', 'Please select valid image.');
        return false;
      }
      this.setState({
        confirmImage: event.target.files[0],
        cropOpen: true,
        openEditAttendeeModal: false,
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  onCloseModal = () => {
    this.setState({ cropOpen: false });
  };

  getData = (val) => {
    this.setState({ cropPic: val });
  };

  saveCroppedImage = () => {
    this.setState({
      ProfileImage: this.state.cropPic,
      profilePic: this.state.cropPic,
      cropOpen: false,
    });
  };

  isValid = () => {
    const {
      firstName,
      lastName,
      email,
      company,
      group_id,
      customFieldsForContact,
    } = this.state;
    let error = {};
    let formIsValid = true;
    const customFields = this.props?.customFields?.map((item) => item);
    const regexTest = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const regexUrl =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

    if (group_id.length === 0) {
      formIsValid = false;
      error['group_id'] = '*Please select audience.';
    }
    if (email === '' || email.trim().length === 0) {
      formIsValid = false;
      error['email'] = '*Please enter email.';
    }
    if (email && regexTest.test(email) === false) {
      formIsValid = false;
      error['email'] = '*Please enter a valid email address.';
    }
    if (customFields) {
      for (let data of customFields && customFields) {
        let contactValidData = {};
        contactValidData[data._id] = customFieldsForContact[data._id];
        const { fieldData } = data;
        const currentFieldData = customFieldsForContact[data._id];
        const { fieldType } = fieldData || {};
        if (formIsValid === true && fieldType === 'email') {
          if (
            currentFieldData !== undefined &&
            currentFieldData?.trim().length !== 0 &&
            !currentFieldData !== ''
          ) {
            if (regexTest.test(currentFieldData) === false) {
              formIsValid = false;
              error['validEmail'] = `Please enter a valid email`;
            }
          }
        }

        if (formIsValid === true && fieldType === 'url') {
          if (
            currentFieldData !== undefined &&
            currentFieldData?.trim().length !== 0 &&
            !currentFieldData !== ''
          ) {
            if (regexUrl.test(currentFieldData) === false) {
              formIsValid = false;
              error['validUrl'] = `Please enter a valid URL`;
            }
          }
        }
      }
    }

    this.setState({ errors: error });
    return formIsValid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isValid()) {
      const {
        firstName,
        lastName,
        email,
        company,
        profilePic,
        page_no,
        _id,
        group_id,
      } = this.state;

      const customField = this.props.customFields?.map((item) => item._id);
      let customFieldData = {};
      if (customField) {
        for (const item of customField) {
          customFieldData[item] = this.state.customFieldsForContact[item];
        }
      }

      let newObj = {};
      for (const element in customFieldData) {
        newObj[element] = Array.isArray(customFieldData[element])
          ? customFieldData[element]
          : customFieldData[element]?.trim();
      }
      if (profilePic !== '') {
        const formData = new FormData();

        formData.append('firstName', firstName.trim());
        formData.append('lastName', lastName.trim());
        formData.append('email', email.trim());
        formData.append('company', company.trim());
        formData.append('page_no', page_no);
        formData.append('group_id', group_id);
        formData.append('profilePic', profilePic);
        formData.append('id', _id);
        formData.append('customFieldData', JSON.stringify(newObj));
        this.props.updateContactRequest(formData);
      } else {
        let requestbody = {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email: email.trim(),
          company: company.trim(),
          page_no,
          id: _id,
          group_id,
          customFieldData: newObj,
          lngCode: this.props.users.newLangState
        };

        if (this.state.imageRemoved) {
          requestbody = {
            ...requestbody,
            profilePic: '',
          };
        }

        this.props.updateContactRequest(requestbody);
      }

      this.handleLoading();
    }
  };

  onSelect = (selectedList, selectedItem) => {
    let groupArray = [];
    selectedList.map((data) => {
      groupArray.push(data.id);
    });
    let group =
      groupArray.length > 0
        ? this.setState({ group_id: groupArray }, function () {})
        : null;
  };

  onRemove = (selectedList, removedItem) => {
    let groupArray = [];
    selectedList.map((data) => {
      groupArray.push(data.id);
    });

    let group =
      groupArray.length > 0
        ? this.setState({ group_id: groupArray }, function () {})
        : null;
  };

  checkProfileImageType = (input) => {
    if ('File' in window && input instanceof File) {
      return window.URL.createObjectURL(input);
    } else {
      return input;
    }
  };
  checkProfileImageTypeAndGetText = (input) => {
    if (('File' in window && input instanceof File) || input.includes('http')) {
      return this.props.users.languageStateForRedux?.buttons?.attendees?.change;
    } else {
      return this.props.users.languageStateForRedux?.buttons?.attendees?.photo;
    }
  };

  checkIfRemoveBtnNeeded = (input) => {
    if (('File' in window && input instanceof File) || input.includes('http')) {
      return true;
    } else {
      return false;
    }
  };

  handleRemoveProfileImage = () => {
    this.setState({
      ProfileImage: '',
      imageRemoved: true,
    });
  };

  handleCustomFieldChange = (e, q) => {
    this.setState({
      [e.target.name]: {
        value: e.target.value,
        type: e.target.type,
      },
      customFieldsForContact: {
        ...this.state.customFieldsForContact,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeCheck = (event) => {
    const { value, checked } = event.target;
    let checkValue = this.state.customFieldsForContact[event.target.name]
      ? this.state.customFieldsForContact[event.target.name]
      : [];
    if (checked) {
      let checkValues = [];
      checkValues.push(event.target.value);
      checkValue = checkValue.concat(checkValues);

      this.setState({
        [event.target.name]: {
          value: event.target.value,
          type: event.target.type,
        },
        customFieldsForContact: {
          ...this.state.customFieldsForContact,
          [event.target.name]: checkValue,
        },
      });
    } else {
      let check = checkValue?.filter((event) => event !== value);
      if (check.length === 0) {
        let customFieldsForContact = this.state.customFieldsForContact;
        delete customFieldsForContact[event.target.name];
        this.setState({
          customFieldsForContact,
        });
      } else {
        this.setState({
          customFieldsForContact: {
            ...this.state.customFieldsForContact,
            [event.target.name]: check,
          },
        });
      }
    }
  };


  render() {
    const {
      customFieldsForContact,
      errors,
      loading,
      ProfileImage,
      firstName,
      lastName,
      email,
      company,
      group_id,
      selectedOptions,
    } = this.state;
    let options = [];
    {
      this.props.groups &&
        this.props.groups.map((data, index) => {
          options.push({ id: data._id, groupName: data.groupName });
        });
    }

    const styles = {
      contantImage: {
        backgroundColor: '#eeeeee',
        width: theme.spacing(8),
        height: theme.spacing(8),
      },

      avatar: {
        // backgroundColor: '#FF4170',
        width: theme.spacing(4.75),
        height: theme.spacing(4.75),
      },
      AddAvatar: {
        // backgroundColor: '#FF4170',
        width: theme.spacing(10),
        height: theme.spacing(10),
      },
      AddAvatarWhite: {
        backgroundColor: '#FFF',
        width: theme.spacing(10),
        height: theme.spacing(10),
      },
    };

    const inputFile = React.createRef(null);

    const onButtonClick = () => {
      inputFile.current.value = null;
      inputFile.current.click();
    };

    return (
      <ThemeProvider theme={theme}>
        <div>
          <Grid component="form" container className="modal-wrapper">
            <Grid item xs={12} pb={4}>
              <Box component="p" mb={1} className="subtitle">
              {this.props.users.languageStateForRedux?.audienceTab?.updateContact}
              </Box>
              <Box className="small-subtitle">
              {this.props.users.languageStateForRedux?.audienceTab?.updateContactDesc}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box component="div" pb={4}>
                <Grid container spacing={2.1} alignItems="center">
                  <Grid item>
                    <Avatar
                      style={
                        ProfileImage ? styles.AddAvatarWhite : styles.AddAvatar
                      }
                    >
                      <img
                        style={
                          ProfileImage
                            ? { height: '100%', width: '100%' }
                            : { maxHeight: '40px', maxWidth: '40px' }
                        }
                        alt=""
                        src={
                          ProfileImage
                            ? this.checkProfileImageType(ProfileImage)
                            : userImageIcon
                        }
                      />
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Stack direction="row" spacing={1}>
                      <Button
                        onClick={onButtonClick}
                        variant="outlined"
                        color="primary"
                      >
                        {this.checkProfileImageTypeAndGetText(ProfileImage)}
                      </Button>
                      <input
                        type="file"
                        id="profileImg"
                        ref={inputFile}
                        name="filename"
                        onChange={this.onImageChange}
                        accept="image/*"
                        style={{ display: 'none' }}
                      />
                      {this.checkIfRemoveBtnNeeded(ProfileImage) ? (
                        <Button
                          onClick={this.handleRemoveProfileImage}
                          variant="outlined"
                          color="secondary"
                        >
                          {this.props.users.languageStateForRedux?.buttons?.common?.remove}
                        </Button>
                      ) : null}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>

              <Grid container spacing={2}>
              </Grid>

              <Box component="div" className="inputField" pb={2}>
                <label>
                {this.props.users.languageStateForRedux?.column_names?.teams?.email} <span className="required">*</span>
                </label>
                <input
                  type="text"
                  class="form-control small"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  disabled
                  placeholder=""
                />
                <span className="error_mesage"> {errors.email} </span>
              </Box>
              {this.props.customFields &&
                Array.isArray(this.props.customFields) &&
                this.props.customFields.length > 0 &&
                this.props.customFields.map((customField) => {
                  return (
                    <Box
                      key={customField._id}
                      component="div"
                      className="inputField"
                      pb={2}
                    >
                      <Box component="label" display="flex" alignItems="center">
                        <Box component="span">
                          {customField.fieldData.fieldLabel}
                        </Box>
                      </Box>
                      {customField.fieldData.fieldType !== 'dropdown' &&
                        customField.fieldData.fieldType !== 'checkbox' && (
                          <input
                            placeholder={
                              customField.fieldData.fieldType !== 'date'
                                ? `Enter ${customField.fieldData.fieldLabel}`
                                : null
                            }
                            className="form-control small"
                            name={customField._id}
                            value={
                              this.state.customFieldsForContact &&
                              this.state.customFieldsForContact[customField._id]
                            }
                            type={customField.fieldData.fieldType}
                            onChange={this.handleCustomFieldChange}
                          />
                        )}

                      <span className="required">
                        {' '}
                        {errors[customField._id] &&
                          `Please enter the ${customField.fieldData.fieldLabel}`}{' '}
                      </span>
                      {customField.fieldData.fieldType === 'email' && (
                        <span className="required"> {errors.validEmail} </span>
                      )}
                      {customField.fieldData.fieldType === 'url' && (
                        <span className="required"> {errors.validUrl} </span>
                      )}

                      {customField.fieldData.fieldType === 'dropdown' && (
                        <FormControl size="small" fullWidth>
                          <Select
                            type="text"
                            value={
                              this.state.customFieldsForContact &&
                              this.state.customFieldsForContact[customField._id]
                            }
                            onChange={this.handleCustomFieldChange}
                            placeholder={
                              customField.fieldData.fieldType !== 'date'
                                ? `Enter ${customField.fieldData.fieldLabel}`
                                : ``
                            }
                            className="form-control select small"
                            name={customField._id}
                            style={{ padding: '0px 0px' }}
                            MenuProps={MenuProps}
                            displayEmpty
                          >
                            <MenuItem value="" disabled selected hidden>
                              {`Select a ${customField.fieldData.fieldLabel}`}
                            </MenuItem>
                            {customField?.fieldData?.listValue?.map(
                              (data, id) => (
                                <MenuItem key={id} value={data}>
                                  {data}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                        </FormControl>
                      )}
                      {customField?.fieldData?.fieldType === 'checkbox' &&
                        customField.fieldData?.checkboxValue?.map((item) => (
                          <label>
                            <input
                              type="checkbox"
                              name={customField._id}
                              value={item}
                              checked={
                                customFieldsForContact[
                                  customField._id
                                ]?.indexOf(item) > -1
                                  ? true
                                  : false
                              }
                              onChange={this.handleChangeCheck}
                            />{' '}
                            {item}
                          </label>
                        ))}
                    </Box>
                  );
                })}
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.handleSubmit}
                  >
                    {this.props.users.languageStateForRedux?.audienceTab?.updateContact}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>

          <NotificationContainer />
          {loading ? <Loader /> : null}
        </div>
        {/* crop image popup */}
        <Modal
          open={this.state.cropOpen}
          onClose={this.onCloseModal}
          center
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal change-profile-image',
          }}
        >
          <Grid component="form" container px={1.5}>
            <Grid item mt={0} xs={12} pb={4}>
              <Box component="p" mb={0} className="subtitle">
                {this.props.users.languageStateForRedux?.audienceTab?.profilePicture}
              </Box>
              <p className="small-subtitle">
              {this.props.users.languageStateForRedux?.audienceTab?.pictureModalDesc}
              </p>
            </Grid>
            <ImgCrop
              profilePic={this.getData}
              pic={this.state.uploadImage}
              uploadImage={this.state.confirmImage}
              langState={this.props.users.languageStateForRedux}
            />
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    onClick={this.onCloseModal}
                    variant="contained"
                    // color="primary"
                    disableElevation
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => this.saveCroppedImage()}
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    save
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        {/* crop image close */}
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  contacts: state.Contacts,
  users: state.Users
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      updateContactRequest: Actions.updateContactRequest,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(UpdateModal);
