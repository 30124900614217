import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
    error: false,
    message: null,
    totalResults:0,
    listAccessCodeSuccess:false,
    exportAccessCodeSuccess:false,
    accessCode:[],
    addAccessCodeSuccess: false,
    editAccessCodeSuccess:false,
    deleteAccessCodeSuccess:false,
    importAccessCodeSuccess: false,
    importAccessCodeValidation:false,
    importAccessCodeError:false,
    duplicateAccessCodeInCsv: 0,
    invalidAccessCodeRejection: 0,
    alreadyExistInDb: 0,
    rejectedAccessCodes: 0,
    accessCodeDownloadCsvSuccess: false,
    newAccessCodeExpiryTime: null,
    uniqueAccesscodeList:0,
    importAccessCodeId:0
}

const exportAccessCodeRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        exportAccessCodeSuccess: false,
        error: false,
        message: null,
    }
}

const exportAccessCodeSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        exportAccessCodeSuccess: true,
        error: false,
        message: action.message,
    }
}
const exportAccessCodeError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        exportAccessCodeSuccess: false,
        error: true,
        message: action.error,
    }
}

const listAccessCodeRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listAccessCodeSuccess: false,
        error: false,
        message: null,
        accessCode: [],
        totalResults:0,
        filterByStatus:'',
        filterBySource:''
    }
}

const listAccessCodeSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listAccessCodeSuccess: true,
        error: false,
        message: action.message,
        accessCode: action.accessCode,
        totalResults: action.totalResults,
        filterByStatus:action.filterByStatus,
        filterBySource:action.filterBySource
    }
}
const listAccessCodeError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listAccessCodeSuccess: false,
        error: true,
        message: action.error,
    }
}

const addAccessCodeRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addAccessCodeSuccess: false,
        error: false,
        message: null,
    }
}

const addAccessCodeSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addAccessCodeSuccess: true,
        error: false,
        message: action.message,
    }
}

const addAccessCodeError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addAccessCodeSuccess: false,
        error: true,
        message: action.error,
    }
}

const editAccessCodeRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        editAccessCodeSuccess: false,
        error: false,
        message: null,
    }
}

const editAccessCodeSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        editAccessCodeSuccess: true,
        error: false,
        message: action.message,
    }
}

const editAccessCodeError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        editAccessCodeSuccess: false,
        error: true,
        message: action.error,
    }
}

const deleteAccessCodeRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteAccessCodeSuccess: false,
        error: false,
        message: null,
    }
}

const deleteAccessCodeSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteAccessCodeSuccess: true,
        error: false,
        message: action.message,
    }
}

const deleteAccessCodeError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        deleteAccessCodeSuccess: false,
        error: true,
        message: action.error,
    }
}

const changeAccessCodeRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        changeAccessCodeSuccess: false,
        error: false,
        message: null,
    }
}

const changeAccessCodeSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        changeAccessCodeSuccess: true,
        error: false,
        message: action.message,
    }
}

const changeAccessCodeError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        changeAccessCodeSuccess: false,
        error: true,
        message: action.error,
    }
}


const importAccessCodeRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        importAccessCodeSuccess: false,
        importAccessCodeValidation:false,
        importAccessCodeError:false,
        duplicateAccessCodeInCsv: 0,
        invalidAccessCodeRejection: 0,
        alreadyExistInDb: 0,
        rejectedAccessCodes: 0,
        error: false,
        message: null,
        uniqueAccesscodeList:0,
        importAccessCodeId:0
    }
}

const importAccessCodeSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        importAccessCodeSuccess: true,
        error: false,
        message: action.message,
        uniqueAccesscodeList:action.uniqueAccesscodeList,
        importAccessCodeId:action.importAccessCodeId
    }
}

const importAccessCodeError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        importAccessCodeError: true,
        error: true,
        message: action.error,
        uniqueAccesscodeList:0,
        importAccessCodeId:0
    }
}

const importAccessCodeValidation = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        importAccessCodeValidation: true,
        error: false,
        message: action.message,
        duplicateAccessCodeInCsv: action.duplicateAccessCodeInCsv,
        invalidAccessCodeRejection: action.invalidAccessCodeRejection,
        alreadyExistInDb: action.alreadyExistInDb,
        rejectedAccessCodes: action.rejectedAccessCodes,        
    }
}

const setNewAccessCodeExpiryTime = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        newAccessCodeExpiryTime: action.data      
    }
}

const HANDLERS = {
    [Constants.EXPORT_ACCESS_CODE_REQUEST]: exportAccessCodeRequest,
    [Constants.EXPORT_ACCESS_CODE_SUCCESS]: exportAccessCodeSuccess,
    [Constants.EXPORT_ACCESS_CODE_ERROR]: exportAccessCodeError,
    [Constants.IMPORT_ACCESS_CODE_VALIDATION]: importAccessCodeValidation,
    [Constants.IMPORT_ACCESS_CODE_REQUEST]: importAccessCodeRequest,
    [Constants.IMPORT_ACCESS_CODE_SUCCESS]: importAccessCodeSuccess,
    [Constants.IMPORT_ACCESS_CODE_ERROR]: importAccessCodeError,
    [Constants.LIST_ACCESS_CODE_REQUEST]: listAccessCodeRequest,
    [Constants.LIST_ACCESS_CODE_SUCCESS]: listAccessCodeSuccess,
    [Constants.LIST_ACCESS_CODE_ERROR]: listAccessCodeError,
    [Constants.ADD_ACCESS_CODE_REQUEST]: addAccessCodeRequest,
    [Constants.ADD_ACCESS_CODE_SUCCESS]: addAccessCodeSuccess,
    [Constants.ADD_ACCESS_CODE_ERROR]: addAccessCodeError,
    [Constants.EDIT_ACCESS_CODE_REQUEST]: editAccessCodeRequest,
    [Constants.EDIT_ACCESS_CODE_SUCCESS]: editAccessCodeSuccess,
    [Constants.EDIT_ACCESS_CODE_ERROR]: editAccessCodeError,
    [Constants.DELETE_ACCESS_CODE_REQUEST]: deleteAccessCodeRequest,
    [Constants.DELETE_ACCESS_CODE_SUCCESS]: deleteAccessCodeSuccess,
    [Constants.DELETE_ACCESS_CODE_ERROR]: deleteAccessCodeError,
    [Constants.CHANGE_ACCESS_CODE_REQUEST]: changeAccessCodeRequest,
    [Constants.CHANGE_ACCESS_CODE_SUCCESS]: changeAccessCodeSuccess,
    [Constants.CHANGE_ACCESS_CODE_ERROR]: changeAccessCodeError,
    [Constants.SET_NEW_ACCESS_CODE_EXPIRY_TIME]: setNewAccessCodeExpiryTime
}

export default createReducer(INITIAL_STATE, HANDLERS)
