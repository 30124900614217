import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../src/theme/theme';
import SearchIcon from '../../../src/assets/images/icons/search.svg';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import '../../../src/sass/main.scss';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {

    IconButton,
    ButtonGroup,
    Chip,
} from '@material-ui/core';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import DashboardLayout from 'Layouts/DashboardLayout';

import InputAdornment from '@material-ui/core/InputAdornment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import Loader from '../../Components/Loader/loader';
import RenameFolderModal from './CommonComponent/RenameFolderModal';
import DeleteFolderModal from './CommonComponent/DeleteFolderModal';
import GridView from '../../../src/assets/images/icons/icon-grid.svg';
import ListView from '../../../src/assets/images/icons/icon-list.svg';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LinearProgress from '@material-ui/core/LinearProgress';
import newFolderIcon from '../../../src/assets/images/icons/newFolder-icon.svg';
import FolderIcon from '../../../src/assets/images/icons/icon-folder.svg';
import sheetIcon from '../../../src/assets/images/icons/xls-icon.svg';
import VideoIcon from '../../../src/assets/images/icons/Video-icon.svg';
import DocsIcon from '../../../src/assets/images/icons/Doc-icon.svg';
import pdfIcon from '../../../src/assets/images/icons/pdf-icon.svg';
import imgIcon from '../../../src/assets/images/icons/Image-icon.svg';
import imgThum from '../../../src/assets/images/banner_image.png';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Modal } from 'react-responsive-modal';
import { Actions } from '../../Redux/Actions';
import FileCreators from '../../Redux/Actions/files.action';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FILE_SECTION, FILE_TYPE } from "../../constants/constants";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Tooltip from '@material-ui/core/Tooltip';
const IMAGE_URL = process.env.REACT_APP_FILE_MODULE_URL;

const Home = (props) => {
    const dispatch = useDispatch((store) => Actions.FileCreators);
    const fileObject = useSelector((store) => store.Files);
    const urlParams = useParams();
    const location = useLocation();
    const history = useHistory();
    const [FilesListView, setFilesListView] = useState(false);
    const [FilesGridView, setFilesGridView] = useState(true);
    const [folderName, setFolderName] = useState("");
    const [createFolder, setCreateFolder] = useState(false);
    const [foldersList, setFoldersList] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [renameModal, setRenameModal] = useState(false);
    const [deleteFolderModal, setDeleteFolderModal] = useState(false);
    const [folderToDelete, setFolderToDelete] = useState({});
    const [fileToDelete, setFileToDelete] = useState({});
    const [userId, setUserId] = useState(null);
    const [folderToRename, setFolderToRename] = useState({});
    const [fileToRename, setFileToRename] = useState({});
    const [breadCrumb, setBreadCrumb] = useState("");
    const [s3PathState, sets3PathState] = useState("");
    const [isFolderSelected, setIsFolderSelected] = useState(false);
    const [previewFile, setPreviewFile] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [isFolderListEmpty, setIsFolderListEmpty] = useState(false);
    const [isFileListEmpty, setIsFileListEmpty] = useState(false);
    const [limit, setLimit] = useState(12);
    const [foldersPresent, setFoldersPresent] = useState(0);
    const [filesPresent, setFilesPresent] = useState(0);
    const [previewS3File, setPreviewS3File] = useState("");
    const [fileFormat, setFileFormat] = useState("");
    const [sectionChoosed, setSectionChoosed] = useState("");
    const [sectionSelected, setSectionSelected] = useState("");
    const [previewFileName, setPreviewFileName] = useState("");
    const [previewFileExtName, setPreviewFileExtName] = useState("");
    const [jwtToken, setJwtToken] = useState("");
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortOrderForDate, setSortOrderForDate] = useState('asc');
    const [nameFieldActive, setNameFieldActive] = useState(true);
    const [txtContent, setTxtContent] = useState('');
   const [embedUrl, setEmbedUrl] = useState('');

    /**
  * @function useEffect
  * @description React Hook that runs after the component mounts.
  */
    useEffect(() => {
        const userDetails = JSON.parse(localStorage.getItem('user_details'));
        setJwtToken(userDetails.token);
        setUserId(userDetails.companyOwnerId);
    }, []);

    useEffect(() => {
        getFolders();
    }, [urlParams]);

    const getFolders = () => {
        dispatch(FileCreators.getFolderListRequest({
            parent: getFolderIdFromUrl(), searchText: searchText, limit: limit, section: sectionSelected
        }));

    };
    const getFolderIdFromUrl = () => {
        const completeUrlParams = urlParams.folderId === undefined ? "home" : urlParams.folderId;
        if (completeUrlParams === "home") {
            return completeUrlParams;
        }
        else {
            const completeUrlParamsArr = completeUrlParams.split('/');
            const currentFolderId = completeUrlParamsArr[completeUrlParamsArr.length - 1];
            return currentFolderId;
        }

    };

    useEffect(() => {
        if (fileObject.folderListSuccess) {
            setFoldersList(fileObject.foldersList);
            setFileNames(fileObject.filesList);
            setBreadCrumb(fileObject.breadCrumbs);
            setFoldersPresent(fileObject.foldersCount);
            setFilesPresent(fileObject.filesCount);

            if (fileObject.foldersList.length === 0 && fileObject.filesList.length === 0) {
                setIsFolderListEmpty(true);
                setIsFileListEmpty(true);
            }
            else if (fileObject.foldersList.length !== 0 && fileObject.filesList.length !== 0) {
                setIsFileListEmpty(false);
                setIsFolderListEmpty(false);
            }
            else if (fileObject.foldersList.length === 0) {
                setIsFolderListEmpty(true);
                setIsFileListEmpty(false);
            }
            else {
                setIsFileListEmpty(true);
                setIsFolderListEmpty(false);
            }
        }
    }, [fileObject.folderListSuccess]);

    useEffect(() => {
        if (fileObject.downloadFileSuccess) {
            window.location.href = fileObject.url;
        }
    }, [fileObject.downloadFileSuccess]);

    useEffect(() => {
        if (fileObject.renameFolderSuccess || fileObject.deleteFolderSuccess) {

            if (nameFieldActive) {
                dispatch(FileCreators.getFolderListRequest({
                    section: sectionSelected, parent: getFolderIdFromUrl(), searchText: searchText, limit: limit, sortBy: "name", sortOrder: sortOrder
                }));
            }
            else {
                dispatch(FileCreators.getFolderListRequest({
                    section: sectionSelected, parent: getFolderIdFromUrl(), searchText: searchText, limit: limit, sortBy: "createdAt", sortOrder: sortOrderForDate
                }));
            }
        }
    }, [fileObject.renameFolderSuccess, fileObject.deleteFolderSuccess]);


    const handleOpenRenameFolder = (folder) => {
        setRenameModal(true);
        setFolderToRename(folder);
        setIsFolderSelected(true);
    };

    const handleCloseRenameFolder = () => {
        setRenameModal(false);
    };

    const handleUpdateFolderName = (value) => {
        setFolderToRename({
            ...folderToRename,
            name: value
        });
    };

    const handleUpdateFolderSubmit = (folderName, folderId) => {
        dispatch(FileCreators.renameFolderRequest({ name: folderName, id: folderId, type: FILE_TYPE.folder }));
        setRenameModal(false);
    };

    const handleOpenDeleteFolder = (folder) => {
        setDeleteFolderModal(true);
        setFolderToDelete(folder);
        setIsFolderSelected(true);
    };

    const handleCloseDeleteFolder = () => {
        setDeleteFolderModal(false);
    };

    const handleDeleteFolder = (folderId) => {
        dispatch(FileCreators.deleteFolderRequest({ id: folderId, type: FILE_TYPE.folder }));
        setDeleteFolderModal(false);
    };


    const handleOpenRenameFile = (file) => {
        setRenameModal(true);
        setFileToRename(file);
        setIsFolderSelected(false);
    };

    const handleUpdateFileName = (value) => {
        setFileToRename({
            ...fileToRename,
            name: value
        });
    };

    const handleUpdateFileSubmit = (imageName, imageId) => {
        dispatch(FileCreators.renameFolderRequest({ name: imageName, id: imageId, type: FILE_TYPE.file }));
        setRenameModal(false);
    };

    const handleOpenDeleteFile = (file) => {
        setDeleteFolderModal(true);
        setFileToDelete(file);
        if (file.fileExtension === 'mp4' || file.fileExtension === 'mpeg') {
            setSectionChoosed(FILE_SECTION.videos);
        }
        else if (file.fileExtension === 'png' || file.fileExtension === 'jpeg' || file.fileExtension === 'jpg' || file.fileExtension === 'gif') {
            setSectionChoosed(FILE_SECTION.images);
        }
        else {
            setSectionChoosed(FILE_SECTION.docs);
        }
        setIsFolderSelected(false);
    };

    const handleDeleteFile = (fileId) => {
        dispatch(FileCreators.deleteFolderRequest({ id: fileId, type: FILE_TYPE.file, section: sectionChoosed }));
        setDeleteFolderModal(false);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        dispatch(FileCreators.getFolderListRequest({ parent: getFolderIdFromUrl(), searchText: searchText }));

    };
    const handleFilesListView = () => {
        setFilesGridView(false);
        setFilesListView(true);
    };

    const handleFilesGridView = () => {
        setFilesListView(false);
        setFilesGridView(true);
    };

    const handleOpenPreviewFile = (file) => {
        setPreviewFile(true);
        setFileFormat(file.fileExtension);
        setPreviewFileName(file.name);
        setPreviewS3File(file.fileNameInS3);
        setPreviewFileExtName(file.fileExtension);
        fetchData(file)

    };

    const handleClosePreviewFile = () => {
        setPreviewFile(false);
    };

    const handleLoadMoreButton = (num) => {
        setLimit(num);
        dispatch(FileCreators.getFolderListRequest({
            parent: getFolderIdFromUrl(), section: sectionSelected, searchText: searchText, limit: num
        }));
    };

    const handleOpenDownload = (fileNameInS3, extension) => {
        if (extension === 'mp4' || extension === 'mpeg') {
            dispatch(FileCreators.downloadFileRequest({ fileName: fileNameInS3, section: FILE_SECTION.videos }));
        }
        else if (extension === 'png' || extension === 'jpeg' || extension === 'jpg' || extension === 'gif') {
            dispatch(FileCreators.downloadFileRequest({ fileName: fileNameInS3, section: FILE_SECTION.images }));
        }
        else {
            dispatch(FileCreators.downloadFileRequest({ fileName: fileNameInS3, section: FILE_SECTION.docs }));
        }

    };

    const redirectToSubfolder = (folderId, folderName, folderSection) => {
        setSectionSelected(folderSection);
        history.push({
            pathname: location.pathname + '/' + folderId,
            state: { name: folderName, folderId: folderId }
        });
    };

    const handleBackButton = () => {
        const lastIndex = location.pathname.lastIndexOf('/');
        const trimmedPath = location.pathname.substring(0, lastIndex);
        history.push({
            pathname: trimmedPath
        });
    };
    const handleSortByName = () => {
        setNameFieldActive(true);
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        dispatch(FileCreators.getFolderListRequest({
            section: sectionSelected, parent: getFolderIdFromUrl(), searchText: searchText, limit: limit, sortBy: "name", sortOrder: newSortOrder
        }));
    };

    const handleSortByDate = () => {
        setNameFieldActive(false);
        const newSortOrder = sortOrderForDate === 'asc' ? 'desc' : 'asc';
        setSortOrderForDate(newSortOrder);
        dispatch(FileCreators.getFolderListRequest({
            section: sectionSelected, parent: getFolderIdFromUrl(), searchText: searchText, limit: limit, sortBy: "createdAt", sortOrder: newSortOrder
        }));
    };

    const fetchData = async (file) => {
        if (!file) {
          return;
        }
      
        const url = `${IMAGE_URL}/${userId}/Documents/${file.fileNameInS3}?jwt=${jwtToken}`;
      
        if (['pdf', 'docx', 'ppt', 'pptx', 'xlsx', 'xls', 'doc', 'txt'].includes(file.fileExtension)) {
          if (file.fileExtension === 'txt') {
            try {
              const response = await fetch(url);
              const text = await response.text();
              setTxtContent(text);
            } catch (err) {
              console.error('Failed to fetch text file content', err);
            }
          } else {
            const encodedUrl = encodeURIComponent(url);
            const viewerUrl = `https://docs.google.com/viewer?url=${encodedUrl}&embedded=true`;
            setEmbedUrl(viewerUrl);
          }
        }
      };
      
      const getIcon = (fileExtension) => {
        const icons = {
            pdf: pdfIcon,
            doc: DocsIcon,
            docx: DocsIcon,
            txt: DocsIcon,
            jpg: imgIcon,
            jpeg: imgIcon,
            png: imgIcon,
            gif: imgIcon,
            pptx: pdfIcon,
            mp4: VideoIcon,
        };
        return icons[fileExtension] || sheetIcon;
    };

    return (
        <ThemeProvider theme={theme}>
            <Box className="files all-files">
                <Grid container spacing={2.5}>
                    <Grid item xs={12}>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'start'}
                        >
                            <Stack
                                direction={'row'}
                                alignItems={'start'}
                                spacing={1}
                            >
                                {getFolderIdFromUrl() !== "home" && <IconButton onClick={handleBackButton} size='small' style={{ marginTop: '2px' }} variant="text">
                                    <KeyboardBackspaceIcon />
                                </IconButton>}
                                <Box>
                                    <Box className="secondary">{breadCrumb}</Box>
                                    <Box className="small-subtitle">
                                        All Files Listed here
                                    </Box>
                                </Box>
                            </Stack>
                            <Stack
                                direction={'row'}
                                spacing={0.5}
                                alignItems={'center'}
                                className="file-view-buttons"
                            >
                                <Box mr={2}>
                                    <form onSubmit={handleSearch}>
                                        <TextField
                                            name="searchText"
                                            placeholder="Search..."
                                            className="search"
                                            size="small"
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img alt="" src={SearchIcon} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={searchText}
                                            onChange={(e) => { setSearchText(e.target.value); }}
                                        />
                                    </form>
                                </Box>
                                <Button
                                    className={
                                        FilesListView ? 'viewBtn active' : 'viewBtn'
                                    }
                                    onClick={handleFilesListView}
                                >
                                    <img src={ListView} alt="" className="" />
                                </Button>
                                <Button
                                    className={
                                        FilesGridView ? 'viewBtn active' : 'viewBtn'
                                    }
                                    onClick={handleFilesGridView}
                                >
                                    <img src={GridView} alt="" className="" />
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} className={`files-container ${FilesGridView ? 'grid' : 'list'}`}>

                        {!isFolderListEmpty &&
                            <>
                                <Box mb={2} className="files-heading paragraph">
                                    Folders
                                </Box>
                                <Grid container spacing={FilesListView ? 0.5 : 3}>
                                    {FilesListView &&
                                        <Grid item
                                            xs={FilesListView && 12}
                                        >
                                            <Box className={`file-tile list-header`}>
                                                <Grid
                                                    container
                                                    alignItems={FilesListView ? 'center' : 'start'}
                                                    justifyContent="space-between"
                                                >

                                                    <Grid item xs>
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            spacing={1}
                                                        >
                                                            <Box className='file-icon'>

                                                            </Box>
                                                            <Box className='file-details'>
                                                                <Box onClick={handleSortByName} className={nameFieldActive ? (sortOrder === "asc" ? "file-title sort-asc" : "file-title sort-des") : "file-title"} mr={2.75}>
                                                                    Name
                                                                </Box>
                                                                <Box onClick={handleSortByDate} className={!nameFieldActive ? (sortOrderForDate === "asc" ? "small-subtitle date sort-asc" : "small-subtitle date sort-des") : "small-subtitle date "}>
                                                                    Created on
                                                                </Box>
                                                            </Box>
                                                        </Stack>
                                                    </Grid>

                                                    <Grid item>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    }
                                    {foldersList?.map((folder) => {
                                        return (
                                            <Grid item
                                                xs={FilesListView && 12}
                                                className='file-row'
                                            >
                                                <Box onClick={() => redirectToSubfolder(folder._id, folder.name, folder.section)} className={`file-tile`}>
                                                    <Grid
                                                        container
                                                        alignItems={FilesListView ? 'center' : 'start'}
                                                        justifyContent="space-between"
                                                    >
                                                        <Grid item xs>
                                                            <Stack
                                                                direction={'row'}
                                                                alignItems={'center'}
                                                                spacing={1}
                                                            >
                                                                <Box className='file-icon'>
                                                                    <img src={newFolderIcon} />
                                                                </Box>
                                                                <Box className='file-details'>
                                                                    <Box className="file-title">
                                                                        {folder.name}
                                                                    </Box>
                                                                    {FilesListView &&
                                                                        <Box className="small-subtitle date">
                                                                            {moment(folder.createdAt).format('DD-MMMM-YYYY, h:mm A')}
                                                                        </Box>
                                                                    }
                                                                </Box>
                                                            </Stack>
                                                            {!FilesListView &&
                                                                <Box className="small-subtitle">
                                                                    Created on: {moment(folder.createdAt).format('DD-MMMM-YYYY, h:mm A')}
                                                                </Box>
                                                            }
                                                        </Grid>
                                                        <Grid item>
                                                            <Box onClick={(e) => e.stopPropagation()} className="options">
                                                                <IconButton
                                                                    className="options-button"
                                                                    size="small"
                                                                >
                                                                    <MoreHorizIcon
                                                                        style={{
                                                                            color: theme.palette.primary.dark,
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                                <Box className="options-list">
                                                                    <div onClick={() => handleOpenRenameFolder(folder)}>Rename</div>
                                                                    <hr />
                                                                    <div className='required' onClick={() => handleOpenDeleteFolder(folder)}>Delete</div>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </>
                        }

                        {!isFileListEmpty && !isFolderListEmpty &&
                            <Box component={'hr'} mt={3} />
                        }
                        {isFileListEmpty && isFolderListEmpty &&
                            <Stack alignItems={'center'} spacing={1} className="no-files">
                                <Box p={1}>
                                    <img
                                        src={FolderIcon}
                                        alt="Folder Icon"
                                        className=""
                                    />
                                </Box>
                                <Box className='subtitle '>A Place for all your files</Box>
                                <Box className='small-subtitle '>You can upload files right into: Documents, Images and Videos</Box>
                            </Stack>
                        }
                        {!isFileListEmpty &&
                            <>
                                <Box mb={4} className="files-heading paragraph">
                                    Files ({filesPresent})
                                </Box>
                                <Grid container spacing={FilesListView ? 0.5 : 3}>
                                    {FilesListView && isFolderListEmpty &&
                                        <Grid item
                                            xs={FilesListView && 12}
                                        >
                                            <Box className={`file-tile list-header`}>
                                                <Grid
                                                    container
                                                    alignItems={FilesListView ? 'center' : 'start'}
                                                    justifyContent="space-between"
                                                >

                                                    <Grid item xs>
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            spacing={1}
                                                        >
                                                            <Box className='file-icon'>

                                                            </Box>
                                                            <Box className='file-details'>
                                                                <Box onClick={handleSortByName} className={nameFieldActive ? (sortOrder === "asc" ? "file-title sort-asc" : "file-title sort-des") : "file-title"} mr={2.75}>
                                                                    Name
                                                                </Box>
                                                                <Box onClick={handleSortByDate} className={!nameFieldActive ? (sortOrderForDate === "asc" ? "small-subtitle date sort-asc" : "small-subtitle date sort-des") : "small-subtitle date "}>
                                                                    Created on
                                                                </Box>
                                                            </Box>
                                                        </Stack>
                                                    </Grid>

                                                    <Grid item>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    }
                                {fileNames?.map((file) => {
                                    const { fileExtension, fileNameInS3, name, createdAt } = file;
                                    const icon = getIcon(fileExtension);
                                    const isPreviewable = !['xls', 'doc'].includes(fileExtension);

                                    const FileIcon = (
                                        <Box className='file-icon' onClick={isPreviewable ? () => handleOpenPreviewFile(file) : undefined}>
                                            <img src={icon} alt={`${fileExtension} icon`} />
                                        </Box>
                                    );

                                    const FileTitle = isPreviewable ? (
                                        <Box onClick={() => handleOpenPreviewFile(file)} className="file-title">
                                            {name}.{fileExtension}
                                        </Box>
                                    ) : (
                                        <Tooltip title="Sorry! Currently, we can't preview this file" placement="top" arrow>
                                            <Box onClick={(e) => e.stopPropagation()} className="file-title">
                                                {name}.{fileExtension}
                                            </Box>
                                        </Tooltip>
                                    );

                                    return (
                                        <Grid item xs={FilesListView ? 12 : undefined} className='file-row' key={fileNameInS3}>
                                            <Box  onClick={() => handleOpenPreviewFile(file)} className='file-tile'>
                                                <Grid container alignItems={FilesListView ? 'center' : 'start'} justifyContent="space-between">
                                                    <Grid item xs>
                                                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                            {FileIcon}
                                                            <Box className='file-details'>
                                                                {FileTitle}
                                                                <Box className="small-subtitle date">
                                                                    {!FilesListView && 'Created on:'} {moment(createdAt).format('DD-MMMM-YYYY, h:mm A')}
                                                                </Box>
                                                            </Box>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box onClick={(e) => e.stopPropagation()} className="options">
                                                            <IconButton className="options-button" size="small">
                                                                <MoreHorizIcon style={{ color: theme.palette.primary.dark }} />
                                                            </IconButton>
                                                            <Box className="options-list">
                                                                <div onClick={() => handleOpenDownload(fileNameInS3, fileExtension)}>Download</div>
                                                                <hr />
                                                                <div onClick={() => handleOpenRenameFile(file)}>Rename</div>
                                                                <hr />
                                                                <div className='required' onClick={() => handleOpenDeleteFile(file)}>Delete</div>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                                </Grid>
                            </>
                        }
                        {(foldersPresent > limit || filesPresent > limit) &&
                            <Stack direction={"row"} justifyContent={"center"} py={2}>
                                <Button

                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => handleLoadMoreButton(limit + 12)}
                                >
                                    Load More
                                </Button>
                            </Stack>
                        }
                    </Grid>
                </Grid>
            </Box>
            {/* Rename Folder Modal */}
            <RenameFolderModal
                isFolderSelected={isFolderSelected}
                renameModal={renameModal}
                folderToRename={folderToRename}
                fileToRename={fileToRename}
                handleUpdateFileName={handleUpdateFileName}
                handleUpdateFileSubmit={handleUpdateFileSubmit}
                handleCloseRenameFolder={handleCloseRenameFolder}
                handleUpdateFolderName={handleUpdateFolderName}
                handleUpdateFolderSubmit={handleUpdateFolderSubmit}
            />
            {/* Delete Folder Modal */}
            <DeleteFolderModal
                isFolderSelected={isFolderSelected}
                deleteFolderModal={deleteFolderModal}
                folderToDelete={folderToDelete}
                fileToDelete={fileToDelete}
                handleCloseDeleteFolder={handleCloseDeleteFolder}
                handleDeleteFolder={handleDeleteFolder}
                handleDeleteFile={handleDeleteFile}
            />
            <Modal
                open={previewFile}
                onClose={handleClosePreviewFile}
                classNames={{
                    overlay: 'full-screen_overlay',
                    modal: 'full-screen_modal doc-preview',
                }}
                center
            >
                <Grid container justifyContent="end">
                    <Grid item xs={12}>
                        <Box className="file-preview-header" >
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Box ></Box>
                                <Box className='title'>{previewFileName}.{previewFileExtName}</Box>
                                <Box className='button'>
                                    <IconButton onClick={() => handleOpenDownload(previewS3File, previewFileExtName)} size='small'>
                                        <GetAppIcon className='icon' />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </Box>
                        <Box className="file-preview-container">
                            {fileFormat === 'mp4' &&
                                <video controls>
                                    <source src={IMAGE_URL + '/' + userId + '/Videos' + '/' + previewS3File + '?jwt=' + jwtToken} type="video/mp4" />
                                </video>
                            }
                            {(fileFormat === 'png' || fileFormat === 'jpg' || fileFormat === 'jpeg' || fileFormat === 'gif') &&
                                <img src={IMAGE_URL + '/' + userId + '/Images' + '/' + previewS3File + '?jwt=' + jwtToken} />
                            }
                            {(fileFormat === 'pdf' || fileFormat === 'pptx'   || fileFormat === 'xlsx' || fileFormat === 'docx') &&
                                <iframe
                                    src={embedUrl}
                                    ></iframe>
                            }
                             {(fileFormat === 'txt') &&
                                 <Box style={{ backgroundColor: "#ffffff", width: "100%", maxWidth: "80%", height: "70vh" }} p={2}>
                                 {txtContent}
                               </Box>
                            }

                        </Box>
                    </Grid>
                </Grid>
            </Modal>
            {fileObject?.loading && <Loader />}
        </ThemeProvider>
    );

};

export default Home;