import DateTimeDisplay from './DateTimeDisplay';
import Box from '@material-ui/core/Box'

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (        
      <Box justifyContent={{xs:'center', sm:'start'}} className="show-counter">        
        <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
        <p>:</p>
        <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={'Secs'} isDanger={false} />
      </Box>
    );
};

export default ShowCounter;