import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactApexChart from 'react-apexcharts'
class ApexChart extends React.Component {
  constructor(props) {
    super(props)
    
    let max = Math.max(...this.props.attendeesArray)
    let fmax = max+1
    
    this.state = {
      series: [
        {
          name: 'Attendees',
          data: this.props.attendeesArray,
        }
      ],
      legend: {
        position: 'bottom',
      },
      options: {
        chart: {
          height: 350,
          type: 'area',
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#b8a7f9'],
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        markers: {
          size: 5,
        },
        title: {
        },
        xaxis: {
          type: 'string',
          categories: this.props.dateArray,
          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM \'yy',
              day: 'dd MMM',
              hour: 'HH:mm'
            }
          }
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
        yaxis: {
          title: {
            text: 'Attendees',
          },
          tickAmount: 4,
          min: 0,
          max: fmax,
          axisBorder: {
            show: true,
            color: 'rgb(243, 243, 243)',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    }
  }

  componentDidMount() {
    let max = Math.max(...this.props.attendeesArray)
    let fmax = max+1

    this.setState({
      series: [
        {
            name: 'Attendees',
            data: this.props.attendeesArray,
        }
      ],
      legend: {
        position: 'bottom',
      },
      options: {
        chart: {
          height: 350,
          type: 'area',
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#b8a7f9'],
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        markers: {
          size: 5,
        },
        title: {
        },
        xaxis: {
          type: 'string',
          categories: this.props.dateArray,
          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM \'yy',
              day: 'dd MMM',
              hour: 'HH:mm'
            },
          }
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
        yaxis: {
          title: {
            text: 'Attendees',
          },
          tickAmount: 4,
          min: 0,
          max: fmax,
          axisBorder: {
            show: true,
            color: 'rgb(243, 243, 243)',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    })
  }

  componentDidUpdate(prevProps) {
    if(this.props.users.languageStateForRedux !== prevProps.users.languageStateForRedux){
      let max = Math.max(...this.props.attendeesArray)
      let fmax = max+1

      this.setState({
        series: [
            {
                name: 'Attendees',
                data: this.props.attendeesArray,
            }
        ],
        legend: {
          position: 'bottom',
        },
        options: {
          chart: {
            height: 350,
            type: 'area',
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#b8a7f9'],
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          markers: {
            size: 5,
          },
          title: {
          },
          xaxis: {
            type: 'string',
            categories: this.props.dateArray,
            labels: {
              datetimeFormatter: {
                year: 'yyyy',
                month: 'MMM \'yy',
                day: 'dd MMM',
                hour: 'HH:mm'
              }
            }
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
          yaxis: {
            title: {
                text: this.props.users.languageStateForRedux?.unlayer?.modalSettings?.attendees,
            },
            tickAmount: 4,
            min: 0,
            max: fmax,
            axisBorder: {
              show: true,
              color: 'rgb(243, 243, 243)',
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      })
    }
    if (prevProps.attendeesArray !== this.props.attendeesArray) {
      let max = Math.max(...this.props.attendeesArray)
      let fmax = max+1

      this.setState({
        series: [
            {
                name: 'Attendees',
                data: this.props.attendeesArray,
            }
        ],
        legend: {
          position: 'bottom',
        },
        options: {
          chart: {
            height: 350,
            type: 'area',
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#b8a7f9'],
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          markers: {
            size: 5,
          },
          title: {
          },
          xaxis: {
            type: 'string',
            categories: this.props.dateArray,
            labels: {
              datetimeFormatter: {
                year: 'yyyy',
                month: 'MMM \'yy',
                day: 'dd MMM',
                hour: 'HH:mm'
              }
            }
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
          yaxis: {
            title: {
              text: this.props.users.languageStateForRedux?.unlayer?.modalSettings?.attendees,
            },
            tickAmount: 4,
            min: 0,
            max: fmax,
            axisBorder: {
              show: true,
              color: 'rgb(243, 243, 243)',
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      })
    }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={350}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  events: state.Events,
  users: state.Users,
});

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      // listAllCampaignsRequest: Actions.listAllCampaignsRequest,
      // reportDataRequest: Actions.emailDataReportsRequest,
      //updateLngCodeRequest: Actions.updateLngCodeRequest,
    },
    dispatch
  );
export default connect(mapStateToProps, mapActionsToProps)(ApexChart);
