import React, { Component } from 'react'
import DashboardLayout from '../../Layouts/DashboardLayout'
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from '../../Redux/Actions';
import TeamProject from '../Dashboard/TeamDashboard';
import NotificationsDashboard from './Notications/NotificationsDashboard';
import Link from '@material-ui/core/Link';
import ManagePage from './ManagePage/ManagePage';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


import OverviewIcon from '../../assets/images/icons/overview.svg';
import managePagesIcon from '../../assets/images/icons/managePages.svg';
import campaignsIcon from '../../assets/images/icons/campaigns.svg';
import agendaIcon from '../../assets/images/icons/agenda.svg';
import notificationsIcon from '../../assets/images/icons/notifications.svg';
import accessCodeIcon from '../../assets/images/icons/accessCode.svg';
import teamIcon from '../../assets/images/icons/team.svg';
import attendeesIcon from '../../assets/images/icons/attendees.svg';
import integrationIcon from '../../assets/images/icons/integration.svg';
import meetingsIcon from '../../assets/images/icons/meetings.svg';
import gamificationIcon from '../../assets/images/icons/gamification.svg';

import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
class ProjectDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '1',
      setValue: '1',
    }
  }

  handleChange = (event, newValue) => {
   // setValue(newValue);
    this.setState({ setValue: newValue })
  };

  
 // const [value, setValue] = React.useState('1');
  render() {
  
    const {
      value, handleChange, newValue, setValue,
    } = this.state

    const StyledBadge = styled(Badge)(({ theme }) => ({
      '& .MuiBadge-badge': {
        top: 8,
        right: 8,
        // border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        borderRadius: 4,
        width: 24,
        height: 22,
      },
    }));
  
    const styles = {
      customTabList: {
        minHeight: 42,
      },
      customTab: {
        minHeight: 42,
      },
  
    }



    return (
      <DashboardLayout title="Settings" pageTitle="Settings">

        <div className="project-navigation">
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList style={styles.customTabList}
                variant="scrollable"
                scrollButtons="auto" onChange={this.handleChange} aria-label="lab API tabs example">
                <Tab
                  style={styles.customTab}
                  icon={<img alt="" src={OverviewIcon} />}
                  iconPosition="start"
                  label={<StyledBadge badgeContent={0} color="primary"><Box sx={{ mr: 0 }}>Overview</Box></StyledBadge>}
                  value="1" ></Tab>
                <Tab
                  style={styles.customTab}
                  icon={<img alt="" src={managePagesIcon} />}
                  iconPosition="start"
                  label={<StyledBadge badgeContent={3} color="primary"><Box sx={{ mr: 3.5 }}>Manage Pages</Box></StyledBadge>}
                  value="2" > </Tab>
                <Tab
                  style={styles.customTab}
                  icon={<img alt="" src={campaignsIcon} />}
                  iconPosition="start"
                  label={<StyledBadge badgeContent={2} color="primary"><Box sx={{ mr: 3.5 }}>Campaigns</Box></StyledBadge>}
                  value="3" > </Tab>
                <Tab
                  style={styles.customTab}
                  icon={<img alt="" src={agendaIcon} />}
                  iconPosition="start"
                  label={<StyledBadge badgeContent={6} color="secondary"><Box sx={{ mr: 3.5 }}>Agenda</Box></StyledBadge>}
                  value="4" > </Tab>
                <Tab
                  style={styles.customTab}
                  icon={<img alt="" src={notificationsIcon} />}
                  iconPosition="start"
                  label="Notifications"
                  value="5" > </Tab>
                <Tab
                  style={styles.customTab}
                  icon={<img alt="" src={accessCodeIcon} />}
                  iconPosition="start"
                  label="Access Code"
                  value="6" > </Tab>
                <Tab
                  style={styles.customTab}
                  icon={<img alt="" src={teamIcon} />}
                  iconPosition="start"
                  label="Team"
                  value="7" > </Tab>
                <Tab
                  style={styles.customTab}
                  icon={<img alt="" src={attendeesIcon} />}
                  iconPosition="start"
                  label="Attendees"
                  value="8" > </Tab>
                <Tab
                  style={styles.customTab}
                  icon={<img alt="" src={integrationIcon} />}
                  iconPosition="start"
                  label="Integration"
                  value="9" > </Tab>
                <Tab
                  style={styles.customTab}
                  icon={<img alt="" src={meetingsIcon} />}
                  iconPosition="start"
                  label="Meetings"
                  value="10" > </Tab>
                <Tab
                  style={styles.customTab}
                  icon={<img alt="" src={gamificationIcon} />}
                  iconPosition="start"
                  label="Gamification"
                  value="11" > </Tab>
              </TabList>
            </Box>
            <TabPanel value="1">Overview</TabPanel>
            <TabPanel value="2">Manage Pages</TabPanel>
            <TabPanel value="3">Campaigns</TabPanel>
            <TabPanel value="4">Agenda</TabPanel>
            <TabPanel value="5">Notifications</TabPanel>
            <TabPanel value="6">Access Code</TabPanel>
            <TabPanel value="7">Team</TabPanel>
            <TabPanel value="8">Attendees</TabPanel>
            <TabPanel value="9">Integration</TabPanel>
            <TabPanel value="10">Meetings</TabPanel>
            <TabPanel value="11">Gamification</TabPanel>
          </TabContext>
        </div>

        {/* <div className="dashboard-containor">
          <Tabs>


            <TabList >
              <div class="custom_tab">
                <Tab> <div class="custom_subtab"><i class="fas fa-border-all"></i><h5>Overview</h5></div></Tab>
                <Tab> <div class="custom_subtab"><i class="fas fa-file-alt"></i><h5>manage pages</h5></div></Tab>
                <Tab> <div class="custom_subtab"><i class="fas fa-location-arrow"></i><h5>Campaigns</h5></div></Tab>
                <Tab> <div class="custom_subtab"><i class="fas fa-bars"></i><h5>Agenda</h5></div></Tab>
                <Tab> <div class="custom_subtab"><i class="fas fa-bell"></i><h5><Link to={NotificationsDashboard}>Notifications</Link> </h5></div></Tab>
                <Tab> <div class="custom_subtab"><i class="fas fa-lock"></i><h5>Access Code</h5></div></Tab>
                <Tab> <div class="custom_subtab"><i class="fas fa-id-card"></i><h5>Team</h5></div></Tab>
                <Tab> <div class="custom_subtab"><i class="fas fa-user-friends"></i><h5>Attendees</h5></div></Tab>
                <Tab> <div class="custom_subtab"><i class="fas fa-chart-bar"></i><h5>Integration</h5></div></Tab>
                <Tab> <div class="custom_subtab"><i class="fas fa-briefcase"></i><h5>Meetings</h5></div></Tab>
                <Tab> <div class="custom_subtab"><i class="fas fa-star"></i><h5>Gamification</h5></div></Tab>
              </div>
            </TabList>

            <TabPanel >
              <div class="tabpanel_1">
                <div class="tabpanel_1-1">
                  <div class="tabpanel_content1">
                    <div class="block_head1">
                      <h5>Project details</h5>

                      <h6><Link href="edit-project" >
                        edit
                      </Link>
                      </h6>

                    </div>
                    <ul class="block_para1">
                      <li class="block_list"><i class="far fa-calendar-alt"></i><h5>Start Date/Time: Friday, Sep, 17th 2021, 4:15 PM</h5></li>
                      <li class="block_list"><i class="far fa-calendar-alt"></i><h5>End Date/Time: Friday, Sep, 17th 2021, 4:15 PM</h5></li>
                      <li class="block_list"><i class="fas fa-map-marker-alt"></i><h5>Location: India</h5></li>
                      <li class="block_list"><i class="fas fa-globe"></i><h5>Time Zone: UTC + 4</h5></li>
                      <li class="block_list"><i class="fas fa-link"></i><h5>Event Domain: https://softobiz.illumeetxp.com</h5></li>
                      <li class="block_list"><i class="fas fa-user-friends"></i><h5>Expected Attendees 350</h5></li>
                      <li class="block_list"><i class="fas fa-stopwatch"></i><h5>Online Early Access from:Monday, 20th Sep, 2021 - 10:00 AM</h5></li>
                      <li class="block_list"><i class="fas fa-at"></i><h5>Reply to:tejpalverma20@gmail.com</h5></li>
                      <li class="block_list"><i class="far fa-address-card"></i><h5>Email Sender Name:Tejpal Verma</h5></li>
                      <li class="block_list"><i class="fas fa-tags"></i><h5>Tags:</h5></li>
                      <li class="block_list"><h5>#Marketing</h5><h6>#Launch</h6></li>
                    </ul>
                  </div>



                </div>
                <div class="tabpanel_1-2">
                  <div class="tabpanel_1-2a">
                    <div class="tabpanel_block2a">
                      <h5>418</h5>
                      <p>Contacts in 2 campaigns</p>
                    </div>
                    <div class="tabpanel_block2a">
                      <h5>418</h5>
                      <p>Opened Email</p>
                    </div>
                    <div class="tabpanel_block2a">
                      <h5>348</h5>
                      <p>Attendees</p>
                    </div>
                    <div class="tabpanel_block2a">
                      <h5>87%</h5>
                      <p>Attendees</p>
                    </div>
                    <div class="tabpanel_block2a">
                      <h5>256</h5>
                      <p>Attendees</p>
                    </div>
                    <div class="tabpanel_block2a">
                      <h5>72%</h5>
                      <p>Attendees</p>
                    </div>
                  </div>
                </div>
                <div class="tabpanel_1-3">
                  <div class="tabpanel_content3">
                    <div class="block_head3">
                      <h5>Recent Attendees</h5>
                      <h6>view all</h6>

                    </div>
                    <ul class="block_para3">
                      <li class="block_list1"><span>MG</span><h5>Mihai Grapan</h5><i>Today at 10:45 AM</i><p>mihai@illumeet.com</p></li>
                      <li class="block_list1"><span>MG</span><h5>Mihai Grapan</h5><i>Today at 10:45 AM</i><p>mihai@illumeet.com</p></li>
                      <li class="block_list1"><span>MG</span><h5>Mihai Grapan</h5><i>Today at 10:45 AM</i><p>mihai@illumeet.com</p></li>
                      <li class="block_list1"><span>MG</span><h5>Mihai Grapan</h5><i>Today at 10:45 AM</i><p>mihai@illumeet.com</p></li>
                      <li class="block_list1"><span>MG</span><h5>Mihai Grapan</h5><i>Today at 10:45 AM</i><p>mihai@illumeet.com</p></li>
                      <li class="block_list1"><span>MG</span><h5>Mihai Grapan</h5><i>Today at 10:45 AM</i><p>mihai@illumeet.com</p></li>
                      <li class="block_list1"><span>MG</span><h5>Mihai Grapan</h5><i>Today at 10:45 AM</i><p>mihai@illumeet.com</p></li>
                    </ul>
                  </div>



                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <ManagePage />
            </TabPanel>
            <TabPanel>
              <h2>Campaigns</h2>
            </TabPanel>
            <TabPanel>
              <h2>Agenda</h2>
            </TabPanel>
            <TabPanel>
              <NotificationsDashboard />
            </TabPanel>
            <TabPanel>
              <h2>ACCESS CODE</h2>
            </TabPanel>

            <TabPanel>
              <TeamProject />
            </TabPanel>

            <TabPanel>
              <h2>hello</h2>
            </TabPanel>
            <TabPanel>
              <h2>hello</h2>
            </TabPanel>
            <TabPanel>
              <h2>hello</h2>
            </TabPanel>
            <TabPanel>
              <h2>hello</h2>
            </TabPanel>

          </Tabs>
        </div> */}
      </DashboardLayout>
    )

  }

}

const mapStateToProps = state => ({
  events: state.Events,
  projects: state.Projects,

});

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      createEvent: Actions.eventsRequest,
      checkEventDomain: Actions.checkEventDomainRequest,
    },
    dispatch
  );
export default connect(mapStateToProps, mapActionsToProps)(ProjectDashboard);
