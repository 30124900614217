import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import { Button } from '@material-ui/core'
import 'react-responsive-modal/styles.css'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from '../../theme/theme';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import ExportIcon from '../../assets/images/icons/export.svg'
import { ExportToCsv } from 'export-to-csv'
import {
  samplecsv
} from '../../Redux/API'
export class ImportModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      loading: false,
      importOpen: this.props.importOpen ? this.props.importOpen : false,
      csvImport: '',
      customFields:this.props.customFields ? this.props.customFields : [],
      page_no: this.props.page_no ? this.props.page_no : 1,
      group_id: this.props.group_id ? this.props.group_id : null,
      openSuccess:false,
    }
  }

  onOpenImportModal = () => {
    this.setState({ groupOpen: true })
  }

  onCloseImportModal = () => {
    this.setState({ groupOpen: false })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props?.contacts?.importSuccess === true &&
      this.props?.contacts?.message !== prevProps?.contacts?.message
    ) {
      let st = prevState.loading ? 
      this.setState({ 
        loading: false,
      }) : null
    }

    if (
      this.props.contacts.importError &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('error', this.props.contacts.message, '')
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }
  
    if (this.props.contacts.importContactValidation === true && 
      prevProps.contacts.importContactValidation === false && 
      this.props.contacts.message !== prevProps.contacts.message) {
        this.setState({ 
          loading: false ,
        })
      }
    }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  isValidGroup = () => {
    const { csvImport, group_id } = this.state
    let error = {}
    let formIsValid = true
    if (csvImport === '') {
      formIsValid = false
      error['csvImport'] = '*Please select csvfile.'
    }
    this.setState({ errors: error })
    return formIsValid
  }

  handleGroupSubmit = (e) => {
    e.preventDefault()
    if (this.isValidGroup()) {
      const { csvImport, group_id } = this.state
      const formData = new FormData()

      formData.append('page_no', this.props.page_no)
      formData.append('group_id', this.props.group_id)
      formData.append('csvImport', csvImport)
      formData.append('permission', 'Create Audience')
      this.props.importContactRequest(formData)
      this.setState({ groupOpen: false })
      this.handleLoading()
    } 
  }

  onCSVChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0]

      if (!imageFile.name.match(/\.(csv)$/)) {
        createNotification('error', 'Please select valid csv file.')
        return false
      }
      this.setState({ csvImport: event.target.files[0] })
    }
  }

  sampleCsv = () => {
    samplecsv()
      .then((result) => {
        if (result.data.status === 200) {
          const url = window.URL.createObjectURL(
            new Blob([result.data.csvdata]),
          );
  
          const link = document.createElement('a');
  
          link.href = url;
  
          link.setAttribute('download', 'Audience_sample.csv'); //or any other extension
  
          document.body.appendChild(link);
  
          link.click();
        } else {
          createNotification('error', result.data.message)
        }
        this.setState({ loading: false ,downloadExport:false})
      })
      .catch(console.log)
  }

  render() {
    const { errors, loading, group_id ,onCloseImportModal} = this.state
    let options = []
    {
      this.props.groups &&
        this.props.groups.map((data, index) => {
          options.push({ id: data._id, groupName: data.groupName })
        })
    }
    return (
      <ThemeProvider theme={theme}>
         <Grid className='modal-wrapper' container component={'form'} onSubmit={this.handleGroupSubmit} >
          <Grid item xs={12} pb={4}>
            <Box component="p" mb={1} className='subtitle'>{this.props.users.languageStateForRedux?.audienceTab?.importContacts}</Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.audienceTab?.importContactDesc}
              </p>
          </Grid>

          <Grid item xs={12}>

            <Box component="div" className='inputField' pb={4}>
            <label> {this.props.users.languageStateForRedux?.actions?.attendees?.select} <span className="required">*</span></label>
              <input
                className='form-control small'
                type="file"
                id="csvImport"
                name="csvImport"
                onChange={this.onCSVChange}
                accept=".csv"
              />
              <span className="error_mesage"> {errors.csvImport} </span>
              <Button
              onClick={this.sampleCsv}
              size="small"
              variant="text"
              color="primary"
              startIcon={<img alt="" src={ExportIcon} />}
            >
              {this.props.users.languageStateForRedux?.actions?.attendees?.download}
            </Button>
            </Box>
          </Grid>

          <Grid item xs={12} >
            <Stack spacing={1} direction="row" justifyContent="end">
              <Button type="submit" variant="contained" color="primary" > {this.props.users.languageStateForRedux?.audienceTab?.importContacts}</Button>
            </Stack>
          </Grid>

        </Grid> 
        <NotificationContainer />
        {loading ? <Loader /> : null}
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  contacts: state.Contacts,
  users: state.Users
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      importContactRequest: Actions.importContactRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(withRouter(ImportModal))
