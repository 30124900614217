import React, { Component } from 'react'
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

class notificationEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    // content = "<h1 class='ql-align-center bold'><strong>This is notification heading</strong></h1><p class='ql-align-center'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>"
    
    modules = {
        toolbar: {
            container: [
                [{ size: ["small", false, "large", "huge"] }],
                ["bold"],
                [
                    { align: [] },
                    { list: "bullet" },
                ],
                ["link"],
                ["underline", "blockquote"],
            ],
            handlers: { image: this.imageHandler }
        },
        clipboard: { matchVisual: false }
    };

    formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "size",
        "color",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "align"
    ];

    handleChange= (e) => {
        this.props.handleEditorChange(e)
    }

    render() {

        return (

            <>
                <ReactQuill
                    value={this.props.description}
                    theme='snow'
                    modules={this.modules}
                    formats={this.formats}
                    style={{ minHeight: '100px' }}
                    readOnly={this.props.notEditable === true && true}
                    onChange={!this.props.notEditable && this.handleChange}
                />

            </>
        )

    }

}

export default (notificationEditor);
