import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
import DashboardLayout from '../../Layouts/DashboardLayout'
import { NotificationContainer } from 'react-notifications'
import Loader from '../../Components/Loader/loader'
import { Button } from '@material-ui/core'
import { createNotification, redirectTo } from '../../helpers'
import {
  getEmailCampaigns,
  updateEventTemplate,
  searchEmailCampaigns,
  updateSchedule,
  cancelSchedule,
  cloneEmailCampaigns,
} from '../../Redux/API'
import RescheduleModal from '../../Components/RescheduleModal'
import { Modal } from 'react-responsive-modal'
import moment from 'moment'

class CreateEmailCampaign extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Design',
      pageTitle: 'Email Campaigns',
      eventId: '',
      loading: false,
      allTemplates: [],
      mostRecent: [],
      activeData: [],
      tab: 'all',
      searchText: '',
      open: false,
      templateId: '',
      scheduleDate: new Date(),
    }
  }

  componentDidMount() {
    if (this.props.match.params.eventId !== '') {
      this.setState({ eventId: this.props.match.params.eventId })
      this.getRecords(this.props.match.params.eventId)
    }
  }

  getRecords = (eventId) => {
    this.handleLoading()
    getEmailCampaigns(eventId)
      .then((result) => {
        if (result.data.status === 200) {
         
          this.setState({
            allTemplates: result.data.allTemplates,
            mostRecent: result.data.mostRecent,
            activeData: result.data.activeData,
          })
         
        } else {
          createNotification('error', result.data.message)
        }
        this.handleLoading()
      })
      .catch(console.log)
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }
  handleTab = (value) => {
    this.setState({ tab: value }, () => this.getRecords(this.state.eventId))
  }

  deleteRecord = (templateId) => {
    const requestbody = { isDeleted: true }
    updateEventTemplate(templateId, requestbody)
      .then((result) => {
        if (result.data.status === 200) {
          createNotification('success', result.data.message)
          this.getRecords(this.state.eventId)
        } else {
          createNotification('error', result.data.message)
        }
      })
      .catch(console.log)
  }
  updateRecord = (templateId) => {
    const requestbody = { isActive: true, eventId: this.state.eventId }
    updateEventTemplate(templateId, requestbody)
      .then((result) => {
        if (result.data.status === 200) {
          createNotification('success', result.data.message)
          this.getRecords(this.state.eventId)
        } else {
          createNotification('error', result.data.message)
        }
      })
      .catch(console.log)
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { searchText, eventId } = this.state
    this.handleLoading()
    if (searchText !== '') {
      searchEmailCampaigns(eventId, searchText)
        .then((result) => {
          if (result.data.status === 200) {
            //createNotification('success', result.data.message);
            this.setState({
              allTemplates: result.data.allTemplates,
              mostRecent: result.data.mostRecent,
              activeData: result.data.activeData,
            })
           
          } else {
            createNotification('error', result.data.message)
          }
          this.handleLoading()
        })
        .catch(console.log)
    } else {
      getEmailCampaigns(eventId)
        .then((result) => {
          if (result.data.status === 200) {
            //createNotification('success', result.data.message);
            this.setState({
              allTemplates: result.data.allTemplates,
              mostRecent: result.data.mostRecent,
              activeData: result.data.activeData,
            })
           
          } else {
            createNotification('error', result.data.message)
          }
          this.handleLoading()
        })
        .catch(console.log)
    }
  }

  reschedule = (templateId, scheduleDate) => {
    this.setState({
      open: true,
      templateId: templateId,
      scheduleDate: scheduleDate,
    })
  }

  cancelSchedule = (templateId) => {
    this.handleLoading()
    cancelSchedule(templateId)
      .then((result) => {
        if (result.data.status === 200) {
          this.getRecords(this.state.eventId)
          createNotification('success', result.data.message)
        } else {
          createNotification('error', result.data.message)
        }
        this.handleLoading()
      })
      .catch(console.log)
  }

  onCloseModal = () => {
    this.setState({ open: false })
  }

  rescheduleSubmit = (emailDate) => {
    this.handleLoading()
    const { templateId } = this.state
    const requestbody = { scheduleDate: emailDate }

    updateSchedule(templateId, requestbody)
      .then((result) => {
        if (result.data.status === 200) {
          createNotification('success', result.data.message)
        } else {
          createNotification('error', result.data.message)
        }
        this.handleLoading()
      })
      .catch(console.log)
  }

  cloneRecord = (templateId) => {
    this.handleLoading()
    cloneEmailCampaigns(templateId)
      .then((result) => {
        if (result.data.status === 200) {
          this.getRecords(this.state.eventId)
          createNotification('success', result.data.message)
        } else {
          createNotification('error', result.data.message)
        }
        this.handleLoading()
      })
      .catch(console.log)
  }

  tableRecords = (data, index, campaignStatus, show) => {
    const { eventId } = this.state
    return (
      <tr key={index}>
        <td>{data.templateName} </td>

        <td>
          {' '}
          {campaignStatus === 'Completed' && (
            <span
              className="anView"
              onClick={() =>
                redirectTo(
                  this.props.history,
                  `analytics/${eventId}/${data._id}`,
                  this.props.location.pathname,
                )
              }
            >
              View
            </span>
          )}
        </td>
        <td> {campaignStatus} </td>
        <td className={`tableDotMore ${show ? 'Open' : ''}`}>
          <button className="tableDots">
            <span className="tableDotsExpo"></span>
            <span className="tableDotsExpo"></span>
            <span className="tableDotsExpo"></span>
          </button>
          <div className="contacteditPopup">
            {campaignStatus === 'draft' ||
            //campaignStatus === 'Draft'  ||
            campaignStatus === 'Draft' || campaignStatus === 'Completed' ||
            (campaignStatus === 'Pending' &&
              data.emailStatus !== 'Scheduled') ? (
              <>
                <p>
                  {' '}
                  <span
                    onClick={() =>
                      redirectTo(
                        this.props.history,
                        `edit-template-design/${eventId}/edit/${data._id}`,
                        this.props.location.pathname,
                      )
                    }
                  >
                    Edit{' '}
                  </span>
                </p>
              </>
            ) : null}
            {campaignStatus !== 'Completed' &&
            data.emailStatus === 'Scheduled' ? (
              <>
                <p>
                  <span
                    onClick={() =>
                      redirectTo(
                        this.props.history,
                        `reschedule/${eventId}/${data._id}`,
                        this.props.location.pathname,
                      )
                    }
                  >
                    Reschedule
                  </span>
                </p>
                <p>
                  <span
                    onClick={() => {
                      if (window.confirm('Cancel the schedule?')) {
                        this.cancelSchedule(data._id)
                      }
                    }}
                  >
                    Cancel Schedule
                  </span>
                </p>
              </>
            ) : null}
            <p>
              <span
                onClick={() => {
                  if (window.confirm('Delete the Campaign?')) {
                    this.deleteRecord(data._id)
                  }
                }}
              >
                Delete
              </span>
            </p>
            <p>
              <span onClick={() => this.cloneRecord(data._id)}>
                Replicate Campaign
              </span>
            </p>
          </div>
        </td>
      </tr>
    )
  }

  render() {
    const {
      title,
      pageTitle,
      eventId,
      loading,
      tab,
      allTemplates,
      mostRecent,
      searchText,
      activeData,
      open,
      templateId,
      scheduleDate,
    } = this.state
    return (
      <DashboardLayout title={title} pageTitle={pageTitle}>
        <div className="dashboardMiddleArea">
          <div className="createProjectAccount projectStepRow mt-0">
            <div className="projectCreateSection">
              <div className="CreatePresentation">
                <div className="submitForm justify-content-end pr-0">
                  <Button
                    onClick={() =>
                      redirectTo(
                        this.props.history,
                        `choose-email-template/${eventId}`,
                        this.props.location.pathname,
                      )
                    }
                    type="text"
                    className="themeBlueButton"
                  >
                    New Campaign
                  </Button>
                </div>
                {/* <div class="accountTypeTitle">
                        <h1>Email Campaigns:</h1>
                    </div> */}
                {searchText || allTemplates.length ? (
                  <div>
                    <div className="campaignMainBar">
                      <div className="campaignFilter">
                        <ul>
                          <li
                            className={tab === 'all' ? 'active' : null}
                            onClick={() => this.handleTab('all')}
                          >
                            View All
                          </li>
                          <li
                            className={tab === 'recent' ? 'active' : null}
                            onClick={() => this.handleTab('recent')}
                          >
                            Most Recent
                          </li>
                          <li
                            className={tab === 'active' ? 'active' : null}
                            onClick={() => this.handleTab('active')}
                          >
                            Active
                          </li>
                        </ul>
                      </div>
                      <div className="campaignSearchBar">
                        <form
                          className="searchCampaignsForm"
                          onSubmit={this.handleSubmit}
                        >
                          <button type="submit">
                            <i className="fa fa-search"></i>
                          </button>
                          <input
                            type="text"
                            placeholder="Search Campaigns"
                            className="form-control"
                            name="searchText"
                            value={searchText}
                            onChange={this.handleChange}
                          />
                        </form>
                      </div>
                    </div>
                    <div className="rsvpEventfieldBox">
                      <div className="formSection p-0">
                        {/**table start */}
                        <div className="myEventTable eventListBoxes mt-0">
                          <div className="eventTableInner CRCircularBox table-responsive email_campign_design pb-0">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Campaign Name</th>
                                  <th scope="col" className="text-left">
                                    Analytics
                                  </th>
                                  <th scope="col">Campaign Status</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tab === 'all' &&
                                  allTemplates &&
                                  allTemplates.length > 0 &&
                                  allTemplates.map((data, index) => {
                                   
                                    let show = false
                                    let sDate = new Date()
                                    let campaignStatus = data.campaignStatus
                                    let campaignStatusMsg = ''
                                    if (data.emailStatus === 'Scheduled') {
                                      sDate = data.scheduleDate
                                        ? data.scheduleDate
                                        : new Date()
                                      const currentDate = moment(
                                        new Date(),
                                      ).format('X')
                                      const scheduleDate = moment(sDate).format(
                                        'X',
                                      )
                                      if (
                                        data.emailStatus === 'Scheduled' &&
                                        scheduleDate < currentDate
                                      ) {
                                        campaignStatus = 'Completed'
                                      } else if (
                                        data.emailStatus === 'Scheduled' &&
                                        scheduleDate > currentDate
                                      ) {
                                        campaignStatus =
                                          'Pending (Schedule At )' +
                                          moment(data.scheduleDate).format(
                                            'dddd, MMM, Do YYYY, LT',
                                          )
                                      }
                                    }
                                    return this.tableRecords(
                                      data,
                                      index,
                                      campaignStatus,
                                      show,
                                    )
                                  })}
                              </tbody>
                              <tbody>
                                {tab === 'recent' &&
                                  mostRecent &&
                                  mostRecent.length > 0 &&
                                  mostRecent.map((data, index) => {
                                    let show = false
                                    let sDate = new Date()
                                    let campaignStatus = data.campaignStatus
                                    let campaignStatusMsg = ''
                                    if (data.emailStatus === 'Scheduled') {
                                      sDate = data.scheduleDate
                                        ? data.scheduleDate
                                        : new Date()
                                      const currentDate = moment(
                                        new Date(),
                                      ).format('X')
                                      const scheduleDate = moment(sDate).format(
                                        'X',
                                      )
                                      if (
                                        data.emailStatus === 'Scheduled' &&
                                        scheduleDate < currentDate
                                      ) {
                                        campaignStatus = 'Completed'
                                      } else if (
                                        data.emailStatus === 'Scheduled' &&
                                        scheduleDate > currentDate
                                      ) {
                                        campaignStatus =
                                          'Pending (Schedule At )' +
                                          moment(data.scheduleDate).format(
                                            'dddd, MMM, Do YYYY, LT',
                                          )
                                      }
                                    }

                                    return this.tableRecords(
                                      data,
                                      index,
                                      campaignStatus,
                                      show,
                                    )
                                  })}
                              </tbody>
                              <tbody>
                                {tab === 'active' &&
                                  activeData &&
                                  activeData.length > 0 &&
                                  activeData.map((data, index) => {
                                    let show = false
                                    let sDate = new Date()
                                    let campaignStatus = data.campaignStatus
                                    let campaignStatusMsg = ''
                                    if (data.emailStatus === 'Scheduled') {
                                      sDate = data.scheduleDate
                                        ? data.scheduleDate
                                        : new Date()
                                      const currentDate = moment(
                                        new Date(),
                                      ).format('X')
                                      const scheduleDate = moment(sDate).format(
                                        'X',
                                      )
                                      if (
                                        data.emailStatus === 'Scheduled' &&
                                        scheduleDate < currentDate
                                      ) {
                                        campaignStatus = 'Completed'
                                      } else if (
                                        data.emailStatus === 'Scheduled' &&
                                        scheduleDate > currentDate
                                      ) {
                                        campaignStatus =
                                          'Pending (Schedule At )' +
                                          moment(data.scheduleDate).format(
                                            'dddd, MMM, Do YYYY, LT',
                                          )
                                      }
                                    }

                                    return this.tableRecords(
                                      data,
                                      index,
                                      campaignStatus,
                                      show,
                                    )
                                  })}
                              </tbody>
                              <div
                                style={{ float: 'right', paddingTop: '1rem' }}
                              >
                                {allTemplates.length} Results
                              </div>
                            </table>
                            <form>
                              <div className="completeRegisterboxes">
                                <div className="eventFullInput pt-2"></div>
                              </div>
                            </form>
                          </div>
                        </div>

                        {/**table end */}
                        <Modal open={open} onClose={this.onCloseModal} center>
                          <RescheduleModal
                            templateId={templateId}
                            onCloseModal={this.onCloseModal}
                            rescheduleSubmit={this.rescheduleSubmit}
                            scheduleDate={scheduleDate}
                          />
                        </Modal>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={'no-data-found'}>
                    {' '}
                    No Page found, please create one!!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />
        {loading ? <Loader /> : null}
      </DashboardLayout>
    )
  }
}

export default CreateEmailCampaign
