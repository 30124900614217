import React, { Component } from 'react'
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from '../../../theme/theme';
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux';
import { Actions } from '../../../Redux/Actions';
import { connect } from 'react-redux';
import { Stack, Box, Grid } from '@mui/material';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { createNotification, redirectTo } from '../../../helpers';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet'
import { useLocation } from "react-router-dom";
import {
    ButtonGroup,
} from '@material-ui/core'
import TabPanel from '@mui/lab/TabPanel'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Fade from '@material-ui/core/Fade'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import StayPrimaryPortraitIcon from '@material-ui/icons/StayPrimaryPortrait'
import TabContext from '@mui/lab/TabContext'
import {
    IconButton,
} from '@material-ui/core'
import SlideOpened from '../../../../src/assets/images/icons/slide_opened.svg'
import SlideClosed from '../../../../src/assets/images/icons/slide_closed.svg'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import { styled } from '@mui/material/styles'
import Badge from '@mui/material/Badge'
import { eventListColorType } from '../../../../src/Pages/EventList/config'
import 'react-quill/dist/quill.snow.css'
import CreateProjectStep3 from '../../Projects/CreateProjectStep3';
import CreateProject from '../../Projects/CreateProject';
import pagethum from '../../../assets/images/tamplate-thum.png';
import { defaultThumbnail } from '../../../constants/constants';

const URL = process.env.REACT_APP_API_URL

const styles = {
    customTabList: {
        minHeight: 56,
    },
    customTab: {
        minHeight: 56,
        color: '#ffffff',
        padding: '12px 16px',
    },
}
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        top: 8,
        right: 8,
        padding: '0 4px',
        borderRadius: '4px',
        width: 24,
        height: 22,
    },
}))

const isUrlValid = (userInput) => {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if (res == null)
        return false;
    else
        return true;
}

export class ProjectTemplatePreview extends Component {
    constructor(props) {
        super(props)
        this.anchorRef = React.createRef()
        this.state = {
            eventId: "",
            templateId: "blank",
            pageTemplates: [],
            pageName: "",
            pageNameUrl: "",
            preview: {},
            pageTitle: "",
            pageDesc: "",
            customCss: "",
            customeJs: "",
            eventStartDate: "",
            pageNameFromURL: "",
            sidebar: true,
            open: false,
            value: "1",
            viewPort: '',
            tabContent: 'pages',
            thumbnailImage: '',
        }
    }

    UserData = async () => {
        const currentPath = window.location.pathname
        if (currentPath.includes('member')) {
            return JSON.parse(localStorage.getItem('member_details'))
        } else {
            return JSON.parse(localStorage.getItem('user_details'))
        }
    }

    async getPagePerview(pagetemplateEventId) {
        const users = await this.UserData()
        try {
            let url = `${URL}/projectTemplatePreviewApi?eventId=${pagetemplateEventId}`
            const response = await axios.get(url, {
                headers: {
                    token: `bearer ${users.token} `,
                    'Content-Type': 'application/json',
                }
            });
            if (response.data && response.data.status == 200) {
                return response?.data
            } else {
                throw Error(response.data.error)
            }
        } catch (err) {
            createNotification("error", err.message);
        }
    }

    componentDidMount() {
        let projectTemplate = this.props.template
        let sentFrom = this.props.sentFrom
        let pagetemplateEventId = this.props.templateEventId
        this.setState({
            sentFrom,
            pagetemplateEventId,
            projectTemplate,
        }, async () => {
            let pagedetails = await this.getPagePerview(pagetemplateEventId);
           
            let pagethumbnail = ""
            let pageThumb = pagedetails?.pages[0]?.pageThumb
            if (pageThumb !== "" && pageThumb !== undefined && pageThumb !== null) {
                pagethumbnail = pageThumb
            } else {
                pagethumbnail = defaultThumbnail
            }
            this.setState({
                eventDetail: pagedetails?.eventDetail,
                // thumbnailImage: pagedetails?.pages[0].pageThumb,
                thumbnailImage: pagethumbnail,
                preview: pagedetails?.pages[0],
                pageId: pagedetails?.pages[0]?._id,
                pageName: pagedetails?.pages[0]?.pageName,
                pagePath: pagedetails?.pages[0]?.path,
                pageTitle: pagedetails?.pages[0]?.pageTitle,
                pageDesc: pagedetails?.pages[0]?.pageDesc,
                customCss: pagedetails?.pages[0]?.customCss,
                customeJs: pagedetails?.pages[0]?.customeJs,
                eventStartDate: pagedetails?.eventData?.startDate,
                currentPageName: `Page 1: ${pagedetails?.pages[0]?.pageName}`,
                tags: pagedetails?.tags,
                pagedetails
            });
        })

        setTimeout(() => {
            var Startnow = document.getElementById('StartCountDown');
            var SetCountDown = document.getElementById('SetCountDown');
            if (Startnow && Startnow.value !== "false") {
                if (SetCountDown && SetCountDown.value !== "undefined" && SetCountDown.value !== "") {
                    let setCount = SetCountDown.value;
                    let customDate = new Date(setCount).getTime();
                    this.countDown(customDate);
                } else {
                    if (this.state.eventStartDate) {
                        let statDateIs = new Date(this.state.eventStartDate).getTime();
                        this.countDown(statDateIs);
                    }
                }
            }
        }, 0)
    }

    handlePageChange = (pageNumber, pageName, path) => {
        if (this.state.tabContent === 'pages') {
            let pagethumbnail = ""
            let pageThumb = this.state.pagedetails?.pages[pageNumber]?.pageThumb
            if (pageThumb !== "" && pageThumb !== undefined && pageThumb !== null) {
                pagethumbnail = pageThumb
            } else {
                pagethumbnail = defaultThumbnail
            }
            this.setState({
                thumbnailImage: pagethumbnail,
                currentPageName: `Page ${pageNumber + 1}: ${pageName}`,
                pagePath: path,
                open: false,
            })
        } else {
            let campaignthumbnail = ""
            let campThumb = this.state.pagedetails?.campaigns[pageNumber]?.pageThumb
            if (campThumb !== "" && campThumb !== undefined && campThumb !== null) {
                campaignthumbnail = campThumb
            } else {
                campaignthumbnail = defaultThumbnail
            }
            this.setState({
                thumbnailImage: campaignthumbnail,
                currentPageName: `Campaign ${pageNumber + 1}: ${pageName}`,
                campaignTemplateCid: path,
                open: false,
            })
        }
    }

    exitPreview = () => {
        this.props.handleTemplateClose()
    }

    useTemplate = () => {
        this.props.handleTemplateClose()
        this.props.handleProjectTemplate(this.props.template)
    }

    /* Login form code will end here */
    countDown = (eventStartDate) => {
        var StartCountDown = document.getElementById('StartCountDown');
        if (StartCountDown.value !== "false") {
            // The data/time we want to countdown to
            setTimeout(() => {
                var CountDownBackgroundColor = document.getElementById('CountDownBackgroundColor');
                var backgroundColor = "";
                var fontColor = "";
                if (CountDownBackgroundColor && !CountDownBackgroundColor.value == "") {
                    if (CountDownBackgroundColor.value) {
                        backgroundColor = CountDownBackgroundColor.value;
                    } else {
                        backgroundColor = "#281374";
                    }
                }

                var CountDownTextColor = document.getElementById('CountDownTextColor');
                if (CountDownTextColor && !CountDownTextColor.value == "") {
                    if (CountDownTextColor.value) {
                        fontColor = CountDownTextColor.value;
                    } else {
                        fontColor = "#ffffff";
                    }
                } else {
                    fontColor = "#ffffff";
                }
                var CountDownAlign = "";
                var bgcolor = backgroundColor;
                var mainbody = document.getElementById("product-card-body");
                if (mainbody) {
                    if (!document.getElementById("coming-soon-left-top")) {
                        mainbody.insertAdjacentHTML('afterbegin', ' <div class="coming-soon-left-top" id="coming-soon-left-top" style="text-align:' + CountDownAlign + '"> <div class="lauch-div" id="demo"></div></div>');
                    }
                }
                var timerHtml = '<div  class="date-list" style="background-color:' + bgcolor + '; color: ' + fontColor + '" id="days"></div><div class="date-list" style="background-color:' + bgcolor + '; color: ' + fontColor + '" id="hours"></div><div  class="date-list" style="background-color:' + bgcolor + '; color: ' + fontColor + '" id="mins"></div><div  class="date-list" style="background-color:' + bgcolor + '; color: ' + fontColor + '" id="secs"></div>';
                var timerHtmlVar = document.getElementById("demo");

                var countDownDate = new Date(eventStartDate).getTime();
                // Run myfunc every second
                var myfunc = setInterval(function () {
                    var now = new Date().getTime();
                    var timeleft = countDownDate - now;
                    // Calculating the days, hours, minutes and seconds left
                    var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
                    var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
                    var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

                    var demoBody = document.getElementById("demo");
                    if (demoBody) {
                        if (timerHtmlVar) {
                            timerHtmlVar.innerHTML = timerHtml;
                        }
                    }
                    // Result is output to the specific element
                    var timerdays = document.getElementById("days");
                    if (timerdays) {
                        timerdays.innerHTML = days + " <span style='display: block'>DAYS</span> ";
                        document.getElementById("hours").innerHTML = hours + "<span style='display: block'>  HRS</span>  ";
                        document.getElementById("mins").innerHTML = minutes + "<span style='display: block'>  MINS</span>  ";
                        document.getElementById("secs").innerHTML = seconds + "<span style='display: block'>  SECS</span>  ";
                    }
                    // Display the message when countdown is over
                    if (timeleft < 0) {
                        clearInterval(myfunc);
                        document.getElementById("days").innerHTML = "";
                        document.getElementById("hours").innerHTML = "";
                        document.getElementById("mins").innerHTML = "";
                        document.getElementById("secs").innerHTML = "";
                        var TimerBody1 = document.getElementById("coming-soon-left-top");
                        if (TimerBody1) {
                            TimerBody1.remove();
                        }
                    }
                }, 1000);
            }, 0)
        } else {
            var TimerBody = document.getElementById("coming-soon-left-top");
            if (TimerBody) {
                TimerBody.remove();
            }
        }
    }

    handleToggle = () => {
        this.setState({
            open: !this.state.open
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleListKeyDown = (e) => {
        if (e.key === 'Tab') {
            e.preventDefault()
            this.setState({
                open: false
            })
        }
    }

    sidebarToggle = () => {
        this.setState({
            sidebar: !this.state.sidebar
        })
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        })
    }

    handleViewPort = (viewPort) => {
        this.setState({
            viewPort
        })
    }

    setTabContent = (content) => {
        this.setState({
            tabContent: content
        }, () => {
            if (content === 'campaigns') {
                let campaignthumbnail = ""
                let campThumb = this.state.pagedetails?.campaigns[0]?.pageThumb
                if (campThumb !== "" && campThumb !== undefined && campThumb !== null) {
                    campaignthumbnail = campThumb
                } else {
                    campaignthumbnail = defaultThumbnail
                }

                this.setState({
                    thumbnailImage: campaignthumbnail,
                    campaignTemplateEventId: this.state.pagedetails?.campaigns[0]?.eventId,
                    campaignTemplateCid: this.state.pagedetails?.campaigns[0]?._id,
                    currentPageName: `Campaign 1: ${this.state.pagedetails?.campaigns[0]?.campaignName}`,
                })
            } else if (content === 'pages') {
                let pagethumbnail = ""
                let pageThumb = this.state.pagedetails?.pages[0]?.pageThumb
                if (pageThumb !== "" && pageThumb !== undefined && pageThumb !== null) {
                    pagethumbnail = pageThumb
                } else {
                    pagethumbnail = defaultThumbnail
                }
                this.setState({
                    thumbnailImage: pagethumbnail,
                    campaignTemplateEventId: this.state.pagedetails?.pages[0]?.eventId,
                    currentPageName: `Page 1: ${this.state.pagedetails?.pages[0]?.pageName}`,
                    pagePath: this.state.pagedetails?.pages[0]?.path,
                })

            }
        })
    }

    render() {
        let track = 0;
        const { preview, pageTitle, pageDesc, customCss, customeJs, value, sidebar, open, eventDetail, pagedetails, currentPageName, tabContent, tags, campaignTemplateEventId, campaignTemplateCid, pagePath, thumbnailImage } = this.state;
        let htmlContent = <div className={this.state.viewPort} >
            {
                tabContent === 'pages' ?
                    <div className='template-iframe-wrapper'>
                        <iframe className='page-preview' src={
                            `/project-dashboard/preview-page/${this.state.pagetemplateEventId}?pagepath=${pagePath}&headerBarNeeded=false`
                        } title=''></iframe>
                    </div>
                    :
                    <div className='template-iframe-wrapper'>
                        <iframe className='page-preview' src={
                            `/project-dashboard/preview-campaign/${campaignTemplateEventId}?cid=${campaignTemplateCid}`
                        }></iframe>
                    </div>
            }
        </div>

        return (
            <ThemeProvider theme={theme}>
                <Helmet>
                    <title>IllumeetXP | {pageTitle ? pageTitle : ""}</title>
                    <meta name="description" content={pageDesc ? pageDesc : ""} />
                    <style>{customCss ? customCss : ""}</style>
                    <script>{customeJs ? customeJs : ""}</script>
                </Helmet>
                <TabContext value={value}>
                    <Box className="unlayer-header" pb={0} pt={0.125}>
                        <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="baseline"
                            mb={0}
                        >
                            {sidebar ? (
                                <Grid py={2} order={{ xs: 1, md: 0 }} item xs={12} md={3}>
                                    <Box>
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Grid item>
                                                <Box className="paragraph" sx={{ color: '#ffffff' }}>
                                                    Template Preview
                                                </Box>
                                            </Grid>
                                            <Grid item pr={3}>
                                                <IconButton size="small" onClick={this.sidebarToggle}>
                                                    <img alt="" src={SlideOpened} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            ) : (
                                <Grid item>
                                    <Box>
                                        <IconButton size="small" onClick={this.sidebarToggle}>
                                            <img alt="" src={SlideClosed} />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            )}
                            <Box component={Grid} item xs className="buttons" style={{ paddingBottom: '0px', }}>
                                <Grid
                                    container
                                    alignItems={'end'}
                                    justifyContent={'space-between'}
                                >
                                    <Grid item >
                                        <Box className="project-navigation">
                                            <TabList
                                                style={styles.customTabList}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                onChange={this.handleChange}
                                                aria-label="lab API tabs example"
                                            >
                                                <Tab
                                                    style={styles.customTab}
                                                    iconPosition="start"
                                                    label={
                                                        <StyledBadge badgeContent={pagedetails?.pages?.length} color="primary">
                                                            <Box sx={{ mr: 3.5 }}>Pages</Box>
                                                        </StyledBadge>
                                                    }
                                                    value="1"
                                                    onClick={() => this.setTabContent('pages')}
                                                ></Tab>
                                                <Tab
                                                    style={styles.customTab}
                                                    iconPosition="start"
                                                    label={
                                                        <StyledBadge badgeContent={pagedetails?.campaigns?.length} color="primary">
                                                            <Box sx={{ mr: 3.5 }}>Campaigns</Box>
                                                        </StyledBadge>
                                                    }
                                                    value="2"
                                                    onClick={() => this.setTabContent('campaigns')}
                                                >
                                                    {' '}
                                                </Tab>
                                            </TabList>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Stack spacing={1} direction="row" my={1.5}>
                                            <Button variant="outlined" className="MuiButton-outlined" disableElevation onClick={this.exitPreview}>Exit Preview</Button>
                                            <Button variant="contained" disableElevation onClick={this.useTemplate}>
                                                {' '}
                                                Use template
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid container>
                            {sidebar && (
                                <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    sx={{
                                        boxShadow: '0px 0px 24px rgba(0, 0, 86, 0.23)',
                                    }}
                                >
                                    <Box p={3}>
                                        <Stack
                                            className="small-subtitle secondary-text"
                                            direction={'row'}
                                            flexWrap="wrap"
                                            spacing={1}
                                            mb={3}
                                        >
                                            {
                                                Array.isArray(tags) && tags.length > 0 && (
                                                    tags.map((tag, i) => {
                                                        return <Box key={i}>#{tag}</Box>
                                                    })
                                                )
                                            }
                                        </Stack>

                                        <Box mb={3} className="subsecondary">
                                            {this.state.projectTemplate?.projectTemplateTitle}
                                        </Box>
                                        <Box mb={3} color={'#000000'} className="paragraph">
                                            {ReactHtmlParser(this.state.projectTemplate?.description)}
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                            <Grid
                                item
                                xs
                                sx={{
                                    height: 'calc(100vh - 61.5px)',
                                    backgroundColor: '#E5E5E5',
                                }}
                            >
                                <Box p={3}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent={'space-between'}
                                    >
                                        <Grid item>
                                            <Box
                                                component="div"
                                                className="inputField"
                                                sx={{
                                                    position: 'relative',
                                                }}
                                            > {((value === '1' && pagedetails?.pages.length > 0) || (value === '2' && pagedetails?.campaigns.length > 0)) ?
                                                <Button
                                                    fullWidth
                                                    variant="outlined"
                                                    style={{
                                                        padding: '5px 8px',
                                                        borderRadius: '4px',
                                                        justifyContent: 'space-between',
                                                        textTransform: 'capitalize',
                                                        minWidth: '300px'
                                                    }}
                                                    size="small"
                                                    ref={this.anchorRef}
                                                    aria-controls={open ? 'menu-list-grow' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={this.handleToggle}
                                                    endIcon={<ArrowDropDownIcon />}
                                                >
                                                    {currentPageName}
                                                </Button>
                                                :
                                                "There are no templates to display"
                                                }

                                                <Popper
                                                    open={open}
                                                    placement="bottom-start"
                                                    transition
                                                    anchorEl={this.anchorRef.current}
                                                    role={undefined}
                                                    disablePortal
                                                    style={{
                                                        zIndex: '4',
                                                        right: 0,
                                                    }}
                                                >
                                                    {({ TransitionProps, placement }) => (
                                                        <Fade {...TransitionProps} timeout={250}>
                                                            <Box
                                                                sx={{
                                                                    maxHeight: '230px',
                                                                    overflow: 'scroll',
                                                                    backgroundColor: theme.palette.common.white,
                                                                    boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                                                    borderRadius: '4px',
                                                                    marginTop: '2px',
                                                                }}
                                                            >
                                                                <ClickAwayListener onClickAway={this.handleClose}>
                                                                    <MenuList
                                                                        autoFocusItem={open}
                                                                        id="menu-list-grow"
                                                                        onKeyDown={this.handleListKeyDown}
                                                                    >

                                                                        {
                                                                            tabContent === 'pages' ?
                                                                                Array.isArray(pagedetails?.pages) && pagedetails?.pages.length && pagedetails?.pages.map((page, i) => {
                                                                                    return (
                                                                                        <MenuItem key={i} className="checkbox-item" onClick={() => this.handlePageChange(i, page?.pageName, page?.path)}>
                                                                                            Page {i + 1}: {page.pageName}
                                                                                        </MenuItem>
                                                                                    )
                                                                                }) :
                                                                                Array.isArray(pagedetails?.campaigns) && pagedetails?.campaigns.length && pagedetails?.campaigns.map((campaign, i) => {
                                                                                    return (
                                                                                        <MenuItem key={i} className="checkbox-item" onClick={() => this.handlePageChange(i, campaign?.campaignName, campaign?._id)}>
                                                                                            Campaign {i + 1}: {campaign.campaignName}
                                                                                        </MenuItem>
                                                                                    )
                                                                                })
                                                                        }
                                                                    </MenuList>
                                                                </ClickAwayListener>
                                                            </Box>
                                                        </Fade>
                                                    )}
                                                </Popper>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <ButtonGroup
                                                color="primary"
                                                aria-label="outlined primary button group"
                                            >
                                                <Button
                                                    size="small"
                                                    variant={this.state.viewPort === 'mobile-view' ? 'contained' : 'outlined'}
                                                    disableElevation
                                                    onClick={() => this.handleViewPort('mobile-view')}
                                                >
                                                    <StayPrimaryPortraitIcon />
                                                </Button>
                                                <Button
                                                    size="small"
                                                    variant={this.state.viewPort !== 'mobile-view' ? 'contained' : 'outlined'}
                                                    disableElevation
                                                    onClick={() => this.handleViewPort('')}
                                                >
                                                    <DesktopWindowsIcon />
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                    <Box>
                                        <TabPanel value="1">
                                            {htmlContent}
                                        </TabPanel>
                                        <TabPanel value="2">
                                            {htmlContent}
                                        </TabPanel>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </TabContext>
            </ThemeProvider>

        )
    }
}

export default ProjectTemplatePreview