import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  contactRequest: ['data'],
  contactSuccess: ['message'],
  contactError: ['error'],
  listContactRequest: ['data'],
  listContactSuccess: ['contacts', 'totalResults', 'message'],
  listContactError: ['error'],
  listAllContactRequest: ['data'],
  listAllContactSuccess: ['allContactsList', 'message'],
  listAllContactError: ['error'],
  groupContactRequest: ['data'],
  groupContactSuccess: [
    'importContactId',
    'message',
    'copyCount',
    'importlength',
    'copyCheck',
    'importCheck'
  ],
  groupContactError: ['error'],
  importContactRequest: ['data'],
  importContactSuccess: ['importContactId', 'message', 'importedCount'],
  importContactError: ['error'],
  importContactValidation: [
    'message',
    'duplicateContactsInCsv',
    'invalidEmailsRejection',
    'alreadyExistInDb',
    'rejectedEmails',
    'mandetoryMissingInCsv'
  ],
  importGroupContactValidation: [
    'message',
    'duplicateGroupContactsInCsv',
    'invalidGroupEmailsRejection',
    'rejectedGroupEmails',
    'mandetoryMissingInCsvGroup'
  ],
  updateContactRequest: ['data'],
  updateContactSuccess: ['message', 'data'],
  updateContactError: ['error'],
  deleteContactRequest: ['data'],
  deleteContactSuccess: ['message'],
  deleteContactError: ['error'],
  deleteManyContactRequest: ['data'],
  deleteManyContactSuccess: ['message'],
  deleteManyContactError: ['error'],
  assignGroupRequest: ['data'],
  assignGroupSuccess: ['contacts', 'totalResults', 'message'],
  assignGroupError: ['error'],
  searchContactRequest: ['data'],
  searchContactSuccess: ['contacts', 'totalResults', 'message'],
  searchContactError: ['error'],
  csvRequest: ['data'],
  csvSuccess: ['csvFile', 'message'],
  csvError: ['error'],
  xslRequest: ['data'],
  xslSuccess: ['xslFile', 'message'],
  xslError: ['error'],
  createGroupRequest: ['data'],
  createGroupSuccess: ['message'],
  createGroupError: ['error'],
  rejectContactListRequest: ['data'],
  rejectContactListSuccess: ['rejectedList', 'message', 'totalCount'],
  rejectContactListError: ['error'],
})

export const Constants = Types
export default Creators
