import React, { Component } from 'react'
import '../../sass/main.scss'
import EditingBack from '../../assets/images/backPage.png'
import footerLogo from '../../assets/images/footer__logo.png'
import { Button } from '@material-ui/core'
import ProfilePicture from '../../assets/images/rescipientsImage.jpg'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { redirectTo } from '../../helpers'

class EditTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isaddRecipientsPopupOpen: false,
      pageTitle: 'Edit Template',
    }
  }
  addRecipientsPopup = () => {
    this.setState((prevState) => ({
      isaddRecipientsPopupOpen: !prevState.isaddRecipientsPopupOpen,
    }))
  }
  render() {
    const { isaddRecipientsPopupOpen, pageTitle } = this.state
    if (this.props.location.pathname.includes('member')) {
      var projectType = '/member/project-type'
    } else {
      var projectType = '/project-type'
    }
    return (
      <div className="editingPageWrapper">
        <Helmet>
          <title>IllumeetXP | {pageTitle}</title>
        </Helmet>
        <div className="editingDashboard">
          <div className="editingHeader">
            <div className="editingHeading">
              <h3>
                <Link onClick={() => this.props.history.goBack()}>
                  <img src={EditingBack} alt="" />
                </Link>
                Editing
              </h3>
            </div>
            <div className="saveTemplateSubmit">
              <div className="mobile-none">
                <Button
                  className="saveButton mobile-None"
                  onClick={() =>
                    redirectTo(
                      this.props.history,
                      'edit-template-design',
                      this.props.location.pathname,
                    )
                  }
                >
                  Continue
                </Button>
              </div>
              <a className="MobileSaveButton desktop-None">
                <i className="fa fa-check"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="editDashboardContent">
          <div className="editDBSteppers">
            <div class="projectStepRow mb-5">
              <div class="projectSteps">
                <ul class="projectStepColl fourStepColl">
                  <li>
                    <span class="projectProcess singleDot"></span>
                    Event Details
                  </li>
                  <li>
                    <span class="projectProcess"></span>
                    Design
                  </li>
                  <li>
                    <span class="projectProcess"></span>
                    Recipients
                  </li>
                  <li>
                    <span class="projectProcess"></span>
                    Review
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <footer class="footer_main">
          <div class="footer_logo">
            <img src={footerLogo} alt="" />
          </div>
        </footer>
        <div
          className={`mainModalPopup ${isaddRecipientsPopupOpen ? 'Open' : ''}`}
        >
          <div className="mainModalInner">
            <div className="formSection pt-0">
              <form>
                <div className="editProfieInner">
                  <div class="profileImgBox">
                    <img src={ProfilePicture} alt="" />
                    <div class="profileEditButton">
                      <input
                        type="file"
                        id="profileImg"
                        name="filename"
                        accept="image/*"
                      />
                      <span class="buttonText">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.602"
                          height="17.584"
                          viewBox="0 0 17.602 17.584"
                        >
                          <g id="pencil" transform="translate(0 -0.246)">
                            <path
                              id="Path_934"
                              data-name="Path 934"
                              d="M10.87,82.473,1.185,92.158a.386.386,0,0,0-.1.177L.011,96.644a.381.381,0,0,0,.37.474.379.379,0,0,0,.092-.011l4.309-1.074a.381.381,0,0,0,.177-.1l9.686-9.685Zm0,0"
                              transform="translate(0 -79.287)"
                              fill="#fff"
                            ></path>
                            <path
                              id="Path_935"
                              data-name="Path 935"
                              d="M339.423,1.865,338.345.787a1.952,1.952,0,0,0-2.7,0l-1.321,1.321L338.1,5.881l1.321-1.321a1.907,1.907,0,0,0,0-2.7Zm0,0"
                              transform="translate(-322.379)"
                              fill="#fff"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="field-group inputField fullWidthInput">
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="currentPassword"
                    className="form-control"
                  />
                </div>
                <div className="field-group inputField fullWidthInput">
                  <label>Company Name</label>
                  <input
                    type="text"
                    name="currentPassword"
                    className="form-control"
                  />
                </div>
                <div className="field-group inputField fullWidthInput">
                  <label>Email Address</label>
                  <input
                    type="text"
                    name="currentPassword"
                    className="form-control"
                  />
                </div>
                <div className="editProfileBtn submitForm">
                  <div className="field-group-flex">
                    <div className="field-group inputField">
                      <Button
                        type="button"
                        className="themeBlueButton fullWidth"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <button type="button" title="Close" className="closeModal">
              &#10006;
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default EditTemplate
