import Modal from "react-responsive-modal";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import Stack from '@mui/material/Stack';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import SearchIcon from '../assets/images/icons/search.svg';
import Loader from '../Components/Loader/loader';
import imgThum from '../assets/images/banner_image.png';
import newFolderIcon from '../assets/images/icons/newFolder-icon.svg';
import CloseIcon from '@material-ui/icons/Close';
import FileCreators from '../Redux/Actions/files.action';
import { Actions } from '../Redux/Actions';
import { FILE_SECTION, FILE_TYPE } from "../constants/constants";
import { createNotification } from "../helpers";

const IMAGE_URL = process.env.REACT_APP_FILE_MODULE_URL;
const section = FILE_SECTION.images;

const UploadFromFilesPopUp = (props) => {
  const history = useHistory();
  const dispatch = useDispatch((store) => Actions.FileCreators);
  const fileObject = useSelector((store) => store.Files);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(12);
  const [foldersList, setFoldersList] = useState([]);
  const [imageNames, setImageNames] = useState([]);
  const [breadCrumb, setBreadCrumb] = useState("");
  const [foldersPresent, setFoldersPresent] = useState(0);
  const [filesPresent, setFilesPresent] = useState(0);
  const [userId, setUserId] = useState(null);
  const [jwtToken, setJwtToken] = useState("");
  const [button, setButton] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [trackArray, setTrackArray] = useState([]);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem('user_details'));
    setJwtToken(userDetails.token);
    setUserId(userDetails._id);
    getFolders();

  }, []);

  useEffect(() => {
    if (props.buttonState) {
      setButton(false);
      setActiveIndex(-1);
    }
  }, [props.buttonState]);


  const getFolders = () => {
    dispatch(FileCreators.getFolderListRequest({
      section: section, parent: null, limit: limit, searchText: searchText
    }));
  };

  useEffect(() => {
    if (fileObject.folderListSuccess) {
      setFoldersList(fileObject.foldersList);
      setImageNames(fileObject.filesList);
      setBreadCrumb(fileObject.breadCrumbs);
      setFoldersPresent(fileObject.foldersCount);
      setFilesPresent(fileObject.filesCount);


    }
  }, [fileObject.folderListSuccess]);

  useEffect(() => {
    if (fileObject.addImagesSuccess) {
      createNotification('success', 'Image uploaded successfully');
      if (trackArray.length === 0) {
        getFolders();
      }
      else {
        dispatch(FileCreators.getFolderListRequest({
          section: section, parent: trackArray[trackArray.length - 1], limit: limit, searchText: searchText
        }));
      }

    }
  }, [fileObject.addImagesSuccess]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (trackArray.length === 0) {
      getFolders();
    }
    else {
      dispatch(FileCreators.getFolderListRequest({
        section: section, parent: trackArray[trackArray.length - 1], limit: limit, searchText: searchText
      }));
    }

  };

  const handleButton = (index) => {
    setButton(true);
    setActiveIndex(index);
  };

  const handleLoadMoreButton = (num) => {
    setLimit(num);
    dispatch(FileCreators.getFolderListRequest({
      section: section, parent: null, searchText: searchText, limit: num
    }));
  };

  const handleNotification = () => {
    createNotification('error', 'Please choose an image');
  };

  const redirectToSubfolder = (folderId, folderName) => {
    trackArray.push(folderId);
    history.push({
      state: { name: folderName, folderId: folderId }
    });

    dispatch(FileCreators.getFolderListRequest({
      section: section, parent: folderId, limit: limit, searchText: searchText
    }));
  };

  const handleBackButton = () => {
    if (trackArray.length < 1) {
      return false;
    }
    else if (trackArray.length <= 1) {
      setTrackArray([]);
      dispatch(FileCreators.getFolderListRequest({
        section: section, parent: null, limit: limit, searchText: searchText
      }));
    }
    else {
      trackArray.pop();
      dispatch(FileCreators.getFolderListRequest({
        section: section, parent: trackArray[trackArray.length - 1], limit: limit, searchText: searchText
      }));
    }
  };

  const handleUploadForRoot = () => {
    let temp;
    switch (props.parentForPopup) {
      case "Edit Project": return props.chooseFaviconImageClick(null)
      case "Agenda": return props.chooseAgendaImageClick(null)
      case "unlayerProjectPages": return props.chooseUnlayerImageClick(null)
      case "Attendees":return props.chooseAttendeeProfileImageClick(null)
    }

  };
  const handleUploadForSubfolder = () => {
    let temp;
    switch (props.parentForPopup) {
      case "Edit Project": return props.chooseFaviconImageClick(trackArray[trackArray.length - 1]);
      case "Agenda": return props.chooseAgendaImageClick(trackArray[trackArray.length - 1]);
      case "unlayerProjectPages": return props.chooseUnlayerImageClick(trackArray[trackArray.length - 1]);
      case "Attendees":return props.chooseAttendeeProfileImageClick(trackArray[trackArray.length - 1])
    }
  };

  return (
    <div>
      <Modal
        open={props.uploadFromFiles}
        onClose={props.handleCloseUploadFromFileModule}
        classNames={{
          overlay: '',
          modal: 'info-modal tab-modal file-modal modal-default-close-none ',
        }}
        center
      >
        <Box>
          <Stack
            px={3}
            py={2}
            sx={{ color: '#000056', background: '#ffffff' }}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box className="paragraph">Choose an image</Box>
            <Box>
              <IconButton size="small" onClick={props.handleCloseUploadFromFileModule}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Stack>

          <Box px={3}>
            <Grid container spacing={2} mb={3} alignItems={'center'} className="top-filter-area">
              <Grid item xs>
                <Stack
                  direction={'row'}
                  alignItems={'start'}
                  spacing={1}
                >
                  {<IconButton onClick={handleBackButton} size='small' style={{ marginTop: '2px' }} variant="text">
                    <KeyboardBackspaceIcon />
                  </IconButton>}
                  <Box>
                    <Box className="files-heading paragraph">{breadCrumb}</Box>
                  </Box>
                </Stack>

              </Grid>
              <Grid item>
                <Stack direction={'row'} spacing={1}>


                  <form onSubmit={handleSearch}>
                    <TextField
                      name="searchText"
                      placeholder="Search..."
                      className="search"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img alt="" src={SearchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      value={searchText}
                      onChange={(e) => { setSearchText(e.target.value); }}
                    />
                  </form>
                  <Button onClick={() => trackArray.length === 0 ? handleUploadForRoot() : handleUploadForSubfolder()} size="small" variant="contained" color="primary">
                    Upload Image
                  </Button>
                  <input
                    type="file"
                    onChange={props.onFaviconImageChange}
                    accept="image/*"
                    ref={props.chooseFavicon}
                    style={{ display: 'none' }}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={0} className="files choose-file-modal">
              <Grid item xs={12} className="files-container">
                <Grid container spacing={1}>
                  {foldersList?.map((folder) => {
                    return (

                      <Grid item>
                        <Box onClick={() => redirectToSubfolder(folder._id, folder.name)} className="file-tile" title="New Folder">
                          <Grid
                            container
                            alignItems="start"
                            justifyContent="center"
                          >
                            <Grid item>
                              <Stack alignItems={'center'}>
                                <Box>
                                  <img src={newFolderIcon} />
                                </Box>
                                <Box className="file-title">{folder.name}</Box>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>

                    );
                  })}
                </Grid>
                <Box pt={2} mb={2} className="files-heading paragraph">
                  Images ({filesPresent})
                </Box>
                <Grid container spacing={3}>
                  {imageNames?.map((file, index) => {
                    const isActive = index === activeIndex; // assuming activeIndex is the index of the active file
                    return (
                      <Grid item key={index}>
                        <Box
                          onClick={() => handleButton(index)}
                          className={`file-tile with-thumnail ${isActive ? 'active' : ''}`}
                        >
                          <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item xs={12}>
                              <Box className="file-thumnail">
                                <img
                                  className="image driveImages"
                                  src={`${IMAGE_URL}/${userId}/Images/${file.fileNameInS3}?jwt=${jwtToken}`}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs>
                              <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                              >
                                <Box className="file-title">
                                  {file.name}.{file.fileExtension}
                                </Box>
                                <Box className="small-subtitle">{file.size > 1024 * 1024 ? (file.size / (1024 * 1024)).toFixed(2) + ' MB' : (file.size / 1024).toFixed(2) + ' KB'}</Box>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
                {(foldersPresent > limit || filesPresent > limit) &&
                  <Stack direction={"row"} justifyContent={"center"} py={2}>
                    <Button

                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => handleLoadMoreButton(limit + 12)}
                    >
                      Load More
                    </Button>
                  </Stack>
                }
              </Grid>
            </Grid>
            <Stack item alignItems={'end'}>
              <Button onClick={() => (activeIndex !== null && activeIndex !== -1) ? props.handleChoosedFile(imageNames[activeIndex]) : handleNotification()} size="small" variant={button ? "contained" : "outlined"} color="primary">
                Choose Image
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>

      {fileObject.loading && <Loader />}
    </div>
  );
};

export default UploadFromFilesPopUp;