import { Button, Tooltip } from '@material-ui/core';
import { Box, Grid } from '@mui/material';
import Modal from 'react-responsive-modal';
import CloseIcon from '@material-ui/icons/Close';
import { Contact } from 'Redux/Reducers/opportunity/contacts.reducer';
import {
  CustomField,
  CustomFields,
} from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { separateByComma } from 'helpers/common';
import {
  Account,
  ContactCf,
  Project,
} from 'Redux/Reducers/opportunity/accounts.reducer';
import { useHistory } from 'react-router';
import Loader from 'Components/Loader/loader';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { getSystemLevelPermissions } from '../../../helpers/common';
import moment from 'moment';
import { AccountCf } from 'Redux/Reducers/opportunity/opportunities.reducer';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

type Props = {
  open: boolean;
  handleClose: () => void;
  details: Contact | Account;
  customFields: CustomFields;
  usedFor: 'account' | 'contact';
  title: string;
};

function ContactDetailsSideModal({
  open,
  handleClose,
  details,
  customFields,
  usedFor,
  title,
}: Props) {
  const history = useHistory();
  const [allowed, setAllowed] = useState<any>({});
  useEffect(() => {
    setAllowed(getSystemLevelPermissions());
  }, []);

  const handleEdit = () => {
    let transformedCustomFields = {};
    if (Object.keys(details?.customFields).length) {
      transformedCustomFields = Object.fromEntries(
        Object.entries(details?.customFields).map(([key, value]) => {
          if (typeof value === 'object' && !Array.isArray(value)) {
            return [key, value._id];
          }
          return [key, value];
        }),
      );
    }
    const path = usedFor === 'contact' ? 'contacts' : 'accounts';
    const state: {
      comingFromOtherTab: boolean;
      contact?: Contact;
      account?: Account;
    } = {
      comingFromOtherTab: true,
    };
    const detailsCopy = { ...details };
    detailsCopy.customFields = transformedCustomFields;
    usedFor === 'contact'
      ? (state.contact = detailsCopy as Contact)
      : (state.account = detailsCopy as Account);
    history.push(`/opportunity/${path}`, state);
  };

  const customFieldValue = (cf: CustomField) => {
    //for fieldType = contact, where value is an object
    if (cf.fieldData.fieldType === 'contact') {
      return (details as Account)?.customFieldsWithProperValues?.[
        cf._id as string
      ] as ContactCf;
    } else if (cf.fieldData.fieldType === 'account') {
      return (details as Account)?.customFieldsWithProperValues?.[
        cf._id as string
      ] as string;
    }
    //for fieldType = boolean, where value is an array
    else if (
      Array.isArray(details?.customFieldsWithProperValues?.[cf._id as string]) &&
      (details?.customFieldsWithProperValues?.[cf._id as string] as string[])?.length
    ) {
      return separateByComma(
        details?.customFieldsWithProperValues?.[cf._id as string],
      ).map((el: string) => (
        <span
          className={`${cf.fieldData.fieldType === 'user' && 'link'}`}
          onClick={() => {
            if (cf.fieldData.fieldType === 'user') {
              window.open(`mailto:${el}`);
            }
          }}
        >
          {el}
        </span>
      ));
    }
    // for all other fieldType where value is a string
    else {
      return cf.fieldData.fieldType === 'date' &&
        details?.customFields?.[cf._id as string] ? (
        moment(
          new Date(details?.customFields?.[cf._id as string] as string),
        ).format('YYYY-MM-DD')
      ) : (
        <span
          onClick={() => {
            if (
              cf.fieldData.fieldType === 'email' &&
              details?.customFields?.[cf._id as string]
            ) {
              window.open(
                `mailto:${details?.customFields?.[cf._id as string]}`,
              );
            }
            if(['url', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin'].includes(cf.fieldData.fieldType)) {
              let url = (details
                ?.customFieldsWithProperValues?.[
                cf._id as string
              ] as string)
              if (!/^https?:\/\//i.test(url)) {
                // If not, prepend 'https://'
                url ='https://' + url;
              }
              window.open(url, "_blank")
            }
            if(cf.fieldData.fieldType === 'phone'){
              window.open(`tel:${details
                ?.customFieldsWithProperValues?.[
                cf._id as string
              ] as string}`)
            }
          }}
          className={`${
            ['email', 'url','facebook', 'instagram', 'x', 'pinterest', 'linkedin', 'phone'].includes(cf.fieldData.fieldType) && 'link'
          }`}
        >
          {details?.customFields?.[cf._id as string] as string}
        </span>
      );
    }
  };

  const showRestOfProjects = (projects: Project[]) => {
    let projectsLength = projects.length;
    return projects.slice(1, projectsLength);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      classNames={{
        overlay: 'full-screen_overlay',
        modal:
          'full-screen_modal manage-custom-fields modal-default-close-none',
      }}
      center
    >
      {Object.keys(details).length > 0 ? (
        <Grid container justifyContent="end">
          <Grid
            item
            xs={12}
            md={6}
            lg={5}
            xl={4}
            className="full-screen_modal__form"
          >
            <Box component="div" className="full-screen_modal__form_header">
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Box className="subtitle">{title}</Box>
                </Grid>
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      {(usedFor === 'contact'
                        ? allowed['editContacts']
                        : allowed['editAccount']) && (
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={handleEdit}
                        >
                          Edit
                        </Button>
                      )}
                    </Grid>

                    <Grid item>
                      <Tooltip title="Expand View" placement='bottom-end' arrow>
                        <Button
                          variant="outlined"
                          color="primary"
                          disableElevation
                          // onClick={handleEdit}
                          style={{minWidth: '36px', padding: '5px'}}
                        >
                          <FullscreenIcon />
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Box component={Grid} item ml={1}>
                      <CloseIcon
                        onClick={handleClose}
                        className="modal-close-button"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box
              component="div"
              py={1}
              className="full-screen_modal__form_body"
            >
              <Box px={3} pt={2} pb={4}>
                <Box
                  component={Grid}
                  rowSpacing={2}
                  columnSpacing={2}
                  container
                >
                  {usedFor === 'contact' && (
                    <>
                      <Grid item xs={6}>
                        <Box className="small-subtitle primary-text bold">
                          First Name:
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          className="small-subtitle primary-text"
                          display={'flex'}
                          justifyContent={'end'}
                        >
                          {(details as Contact)?.firstName}
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box className="small-subtitle primary-text bold">
                          Middle Name:
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          className="small-subtitle primary-text"
                          display={'flex'}
                          justifyContent={'end'}
                        >
                          {(details as Contact)?.middleName || '-'}
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box className="small-subtitle primary-text bold">
                          last Name:
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          className="small-subtitle primary-text"
                          display={'flex'}
                          justifyContent={'end'}
                        >
                          {(details as Contact)?.lastName || '-'}
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="small-subtitle primary-text bold" >
                          Email:
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          className={`small-subtitle primary-text link`}
                          display={'flex'}
                          justifyContent={'end'}
                          onClick={()=> {
                            (details as Contact)?.email && window.open(`mailto:${(details as Contact)?.email}`)
                          }}
                        >
                          {(details as Contact)?.email || '-'}
                        </Box>
                      </Grid>
                    </>
                  )}

                  {usedFor === 'account' && (
                    <>
                      <Grid item xs={6}>
                        <Box className="small-subtitle primary-text bold">
                          Name:
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          className="small-subtitle primary-text"
                          display={'flex'}
                          justifyContent={'end'}
                        >
                          {(details as Account)?.name || '-'}
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box className="small-subtitle primary-text bold">
                          Source:
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          className="small-subtitle primary-text"
                          display={'flex'}
                          justifyContent={'end'}
                        >
                          {(details as Account)?.source === 'aiAgent'
                            ? 'AI Agent'
                            : details?.source === 'imported'
                            ? 'Imported'
                            : 'Manually Added'}
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box className="small-subtitle primary-text bold">
                          Projects:
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          className="small-subtitle primary-text"
                          display={'flex'}
                          justifyContent={'end'}
                        >
                          {/* {separateByComma((details as Account)?.projects?.map(project => project.eventName))} */}
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            spacing={1}
                          >
                            <span
                              className="long-text"
                              style={{ maxWidth: '80%' }}
                            >
                              {' '}
                              {(details as Account)?.projects &&
                              (details as Account)?.projects?.length > 0
                                ? (details as Account)?.projects[0]?.eventName
                                : ''}
                            </span>
                            {(details as Account)?.projects?.length > 1 && (
                              <div className="detail-popup">
                                <span className="count">
                                  +{(details as Account)?.projects.length - 1}
                                </span>
                                <div className="info">
                                  {showRestOfProjects(
                                    (details as Account)?.projects,
                                  ).map((project: Project, i: number) => {
                                    return (
                                      <div key={i}>
                                        <p>{project.eventName}</p>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </Stack>
                        </Box>
                      </Grid>
                    </>
                  )}

                  {usedFor === 'account' &&
                    Array.isArray(customFields) &&
                    customFields.length > 0 &&
                    customFields.map((cf) => {
                      return (
                        <>
                          <Grid item xs={6}>
                            <Box className="small-subtitle primary-text bold">
                              {cf.fieldData.fieldName}:
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box
                              className="small-subtitle primary-text"
                              display={'flex'}
                              justifyContent={'end'}
                            >
                              {customFieldValue(cf)}
                            </Box>
                          </Grid>
                        </>
                      );
                    })}
                  {usedFor === 'contact' &&
                    Array.isArray(customFields) &&
                    customFields.length > 0 &&
                    customFields.map((cf) => {
                      return (
                        <>
                          <Grid item 
                          xs={
                            cf.fieldData.fieldType === 'longText' ? 12 : 6
                          }
                          >
                            <Box className="small-subtitle primary-text bold">
                              {cf.fieldData.fieldName}:
                            </Box>
                          </Grid>
                          <Grid item 
                          xs={
                            cf.fieldData.fieldType === 'longText' ? 12 : 6
                          }
                          >
                            <Box
                              className="small-subtitle primary-text"
                              display={'flex'}
                              justifyContent={'end'}
                            >
                              {customFieldValue(cf)}
                            </Box>
                          </Grid>
                        </>
                      );
                    })}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Loader />
      )}
    </Modal>
  );
}

export default ContactDetailsSideModal;
