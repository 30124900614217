import React, { useState, useEffect, memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import PageNotFound from '../Components/PageNotFound';
import Loader from '../Components/Loader/loader';
import Pages from '../Pages/demo/pages';
import MyProfile from '../Pages/demo/MyProfile';
import AttendeeQr from '../Pages/demo/AttendeeQr';
import { getAllPagesOfEvent, getChatbotForEventPageAPI } from '../Redux/API';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from '../Redux/Actions';
import { createNotification } from 'helpers';
import EventWidgetContainer from 'Components/ChatWidget/EventWidgetContainer';

//Using memo for stop re-rendering when parent comp's state change
const Routes = memo(({ allPagesPath, eventId, isPublished }) => {
  return (
    <Switch>
      <Route
        exact
        path="/profile/update-profile"
        component={(props) => <MyProfile {...props} eventId={eventId} />}
      />
      <Route
        exact
        path="/profile/security"
        component={(props) => <MyProfile {...props} eventId={eventId} />}
      />
      <Route
        exact
        path="/profile/my-agenda"
        component={(props) => <MyProfile {...props} eventId={eventId} />}
      />
      <Route
        exact
        path="/profile/agenda"
        component={(props) => <MyProfile {...props} eventId={eventId} />}
      />
      <Route
        exact
        path="/profile/gamification"
        component={(props) => <MyProfile {...props} eventId={eventId} />}
      />
      <Route
        exact
        path="/profile/access-code"
        component={(props) => <MyProfile {...props} eventId={eventId} />}
      />
      <Route
        exact
        path="/attendee/qr-code"
        component={(props) => <AttendeeQr {...props} eventId={eventId} />}
      />
      <Route
        exact
        path={`/`}
        component={(props) => {
          return (
            <Pages
              {...props}
              eventId={eventId}
              allPagesPath={allPagesPath}
              isPublished={isPublished}
            />
          );
        }}
      />
      <Route
        exact
        path={`/logout`}
        component={(props) => {
          return (
            <Pages
              {...props}
              eventId={eventId}
              allPagesPath={allPagesPath}
              isPublished={isPublished}
            />
          );
        }}
      />
      {allPagesPath.map((path) => (
        <Route
          key={path.path}
          path={`/${path.path}`}
          component={(props) => {
            return (
              <Pages
                {...props}
                eventId={eventId}
                allPagesPath={allPagesPath}
                isPublished={isPublished}
              />
            );
          }}
        />
      ))}
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
});

const EndUserEventRoutes = (props) => {
  const [showLoader, setShowLoader] = useState(true);
  const [allPagesPath, setAllPagesPath] = useState([]);
  const [isPublished, setIsPublished] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [agentId, setAgentId] = useState('')
  const [chatInterface, setChatInterface] = useState()

  const currentPath = window.location.pathname;



  useEffect(() => {
    function fetchData() {
      const eventDomain = window.location.hostname;
      getAllPagesOfEvent({ eventDomain })
        .then((result) => {
          const { allPagesPath, isPublished, tawkToWidget, eventId } =
            result.data;
          const attendeeData = JSON.parse(
            localStorage.getItem('attendee_details'),
          );
          getChatbotForEventPage(eventId)
          if (attendeeData) {
            let objs = {
              token: attendeeData.token,
            };
            connectWS(objs);
          } else {
            // Send event id from here to send notifications to the project we want
            let objs = {
              eventId,
            };
            connectWS(objs);
          }

          if (allPagesPath) {
            setAllPagesPath(allPagesPath);
            setEventId(eventId);
          }
          if (isPublished) {
            setIsPublished(isPublished);
          }
          if (tawkToWidget) {
            const runTawkToScript = (app) => {
              var s1 = document.createElement('script'),
                s0 = document.getElementsByTagName('script')[0];
              s1.async = true;

              // 634e9f34daff0e1306d29e32 is propertyId and 1ggu8ed8b is widgetId
              s1.src = `https://embed.tawk.to/${app.propertyId}/${app.widgetId}`;
              s1.setAttribute('crossorigin', '*');
              s0.parentNode.insertBefore(s1, s0);
            };
            window.addEventListener(
              'DOMContentLoaded',
              runTawkToScript(tawkToWidget),
            );
          }
          setTimeout(() => {
            setShowLoader(false);
          }, 1500);
        })
        .catch((e) => {
          setShowLoader(false);
        });
    }

    fetchData();
  }, []);

  const getChatbotForEventPage = async(eventId) => {
    const data = {
        eventId: eventId
    }
    const res = await getChatbotForEventPageAPI(data)
    if(res.status === 200 && res.data.success){
        setChatInterface(res?.data?.chatbot?.settings?.chatInterface)
        setAgentId(res?.data?.chatbot?._id)
    } 
  }

  function connectWS(objs) {
    let webSocketUrl = process.env.REACT_APP_AWS_WEBSOCKET_URL;
    if (objs.token) {
      webSocketUrl += `?token=bearer ${objs.token}`;
    }
    if (objs.eventId) {
      webSocketUrl += `?eventId=${objs.eventId}`;
    }
    let socket = new WebSocket(webSocketUrl);
    let signalInterval;
    socket.onopen = function (e) {
      signalInterval = setInterval(() => {
        console.log('after 9 minut');
        socket.send(
          JSON.stringify({ action: 'ping', message: 'hi, from client' }),
        );
      }, 1000 * 60 * 9); //60*9
    };

    socket.onmessage = function (event) {
      console.log(`[message] Data received from server: ${event.data}`);
      let responseSoket = JSON.parse(event.data);
      if (responseSoket.message == 'awsNotification') {
        props.setAwsNotificationMessage(responseSoket);
      }
      if (
        responseSoket.message == 'access code enabled' ||
        responseSoket.message == 'access code disabled' ||
        responseSoket.message == 'New access code assigned' ||
        responseSoket.message == 'access code deleted' ||
        responseSoket.message == 'agenda deleted' ||
        responseSoket.message == 'agenda updated'
      ) {
        props.setNewAccessCodeExpiryTime(responseSoket);

        /**
         * varnish cache - permission check
         *
         * when access code is updated, it will  update the details in attendee's jwt token also
         *
         * to check access code expiration in varnish if the event enabled access code
         *
         * to access the cached data
         */
        if (responseSoket.attendeeDetails) {
          localStorage.setItem(
            'attendee_details',
            JSON.stringify(responseSoket.attendeeDetails),
          );
        }
      }

      if (responseSoket.message == 'attendee details updated') {
        /**
         * varnish cache - permission check
         *
         * when page access or early access or access code updated then
         *
         * it will update the attendee's jwt token with new permissions
         *
         * to verify before access the cached data
         */
        localStorage.setItem(
          'attendee_details',
          JSON.stringify(responseSoket.attendeeDetails),
        );
      }

      if (responseSoket.message == 'Meeting Ended') {
        /**
         * for 'Sale enablement' type projects
         *
         * when the current meeting ended, backend will send message via websocket to client
         *
         * so need to logout the attendee automatically
         */
        localStorage.removeItem('attendee_details');
        props.showCommonPopup({
          showCommonPopup: true,
          title: responseSoket.message,
          description: responseSoket.description,
        });
      }
    };

    socket.onclose = function (event) {
      clearInterval(signalInterval);
      if (event.wasClean) {
        console.log(
          `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`,
        );
      } else {
        // e.g. server process killed or network down
        if (objs.token) {
          connectWS({ token: objs.token });
        } else if (objs.eventId) {
          connectWS({ eventId: objs.eventId });
        }
      }
    };

    socket.onerror = function (error) {
      console.log(`[error]`);
    };
  }

  return showLoader ? (   
    <Loader />
  ) : !isPublished ? (
    <Route path="*" component={PageNotFound} />
  ) : (
    eventId && (
      <>
        <Routes
          allPagesPath={allPagesPath}
          eventId={eventId}
          currentPath={currentPath}
          isPublished={isPublished}
        />
        {agentId && <EventWidgetContainer eventId={eventId} agentId={agentId} chatInterface={chatInterface}/>}
      </>
    )
  );
}; 

const mapStateToProps = (state) => ({
  accessCode: state.AccessCode,
  events: state.Events,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      setNewAccessCodeExpiryTime: Actions.setNewAccessCodeExpiryTime,
      setAwsNotificationMessage: Actions.setAwsNotificationMessage,
      showCommonPopup: Actions.showCommonPopup,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withRouter(EndUserEventRoutes));
