import { createReducer } from 'reduxsauce'
import { Constants } from '../../Actions'
// interface WidgetOptions{
//     name: string,
//     enabled: true,
//     language: string,
//     sessionShareable: true,
//     minimized: {
//       desktop: {
//         type: rectangle,
//         height: 0,
//         width: 0,
//         borderRadiusTop: 0,
//         borderRadiusBottom: 0
//       },
//       mobile: {
//         type: round
//       }
//     },
//     maximized: {
//       desktop: {
//         height: 0,
//         width: 0
//       }
//     },
//     theme: {
//       header: {
//         text: #000000,
//         background: #000000
//       },
//       agent: {
//         messageText: #000000,
//         messageBackground: #000000
//       },
//       visitor: {
//         messageText: #000000,
//         messageBackground: #000000
//       }
//     },
//     notification: {
//       all: {
//         estimatedWaitTime: true,
//         sound: true,
//         agentTyping: true,
//         visitorTyping: true,
//         tab: true
//       },
//       desktop: {
//         preview: true
//       },
//       mobile: {
//         preview: true
//       }
//     },
//     behavior: {
//       click: max
//     },
//     visibility: {
//       all: {
//         showWhenOffline: true
//       },
//       mobile: {
//         position: br,
//         show: true
//       },
//       desktop: {
//         position: br,
//         show: true
//       }
//     },
//     features: {
//       emoji: true,
//       uploads: true,
//       rating: true,
//       transcript: true
//     },
//     components: {
//       liveChat: true,
//       kb: true,
//       webrtc: true
//     },
//     consent: {
//       enabled: true,
//       form: {
//         target: europe,
//         message: string,
//         policy: {
//           text: string,
//           url: string
//         },
//         actions: {
//           accept: string,
//           reject: string
//         }
//       }
//     },
//     scheduler: {
//       enabled: true,
//       config: {
//         timezone: UTC,
//         schedule: [
//           {
//             day: 0,
//             start: 0,
//             end: 0
//           }
//         ]
//       }
//     },
//     restrictions: {
//       country: {
//         enabled: true,
//         conditions: {
//           mode: false,
//           items: [
//             AD
//           ]
//         }
//       },
//       url: {
//         enabled: true,
//         conditions: {
//           allow: [
//             string
//           ],
//           deny: [
//             string
//           ]
//         }
//       },
//       platform: {
//         enabled: true,
//         conditions: {
//           mode: false,
//           items: [
//             desktop
//           ]
//         }
//       }
//     },
//     states: {
//       online: {
//         minimizedText: string
//       },
//       away: {
//         minimizedText: string
//       },
//       prechat: {
//         handlers: {
//           form: true
//         }
//       },
//       offline: {
//         minimizedText: string
//       }
//     },
//     bubble: {
//       type: string,
//       config: {
//         content: string,
//         background: string,
//         foreground: string
//       }
//     }
// }
// interface Widget {
//   widgetId: string
//   propertyId: string
//   widget: WidgetOptions
// }

//export default Widget
export interface WidgetState {
  success: boolean
  loading: boolean
  message: string
  widget: any
  actionType: string
}

const INITIAL_STATE: WidgetState = {
  success: false,
  message: '',
  loading: false,
  actionType: '',
  widget: {
    id: '',
    propertyId: '',
    visibility: {
      all: {
        showWhenOffline: true,
      },
      mobile: {
        position: 'br',
        show: true,
      },
      desktop: {
        position: 'br',
        show: true,
      },
    },
    theme: {
      header: {
        text: '#ccddbb',
        background: '#ccddbb',
      },
      agent: {
        messageText: '#ccddbb',
        messageBackground: '#ccddbb',
      },
      visitor: {
        messageText: '#ccddbb',
        messageBackground: '#ccddbb',
      },
    },
    minimized: {
      desktop: {
        type: 'rectangle',
        height: 40,
        width: 200,
        borderRadiusTop: 15,
        borderRadiusBottom: 0,
      },
      mobile: {
        type: 'round',
      },
    },
    maximized: {
      desktop: {
        height: 520,
        width: 350,
      },
    },
    notification: {
      all: {
        estimatedWaitTime: false,
        sound: true,
        agentTyping: true,
        visitorTyping: true,
        tab: true,
      },
      desktop: {
        preview: true,
      },
      mobile: {
        preview: true,
      },
    },
    features: {
      emoji: false,
      uploads: false,
      rating: false,
      transcript: false,
    },
    behavior: { click: 'max' },
    bubble: null,
  },
}

//#region Get Widget
const tawkToGetWidget = (
  state: WidgetState = INITIAL_STATE,
  action: any,
): WidgetState => { 
  return {
    ...state,
    loading: true,
    success: false,
  }
}
const tawkToGetWidgetSuccess = (
  state: WidgetState = INITIAL_STATE,
  action: any,
): WidgetState => { 
  const { widget, message } = action
  return {
    ...state,
    success: true,
    actionType: action.type,
    loading: false,
    widget,
    message: message,
  }
}

const tawkToGetWidgetFaliure = (
  state: WidgetState = INITIAL_STATE,
  action: any,
): WidgetState => {
  return {
    ...state,
    success: false,
    actionType: action.type,
    loading: false,
    message: action.error,
  }
}
//#endregion

//#region Update Widget
const tawkToUpdateWidget = (
  state: WidgetState = INITIAL_STATE,
  action: any,
): WidgetState => { 
  return {
    ...state,
    success: false,
    loading: true,
  }
}
const tawkToUpdateWidgetSuccess = (
  state: WidgetState = INITIAL_STATE,
  action: any,
): WidgetState => { 
  return {
    ...state,
    success: true,
    actionType: action.type,
    loading: false,
    message: action.message,
  }
}

const tawkToUpdateWidgetFailure = (
  state: WidgetState = INITIAL_STATE,
  action: any,
): WidgetState => {
  return {
    ...state,
    success: false,
    loading: false,
    message: action.error.message,
  }
}
//#endregion

const HANDLERS = {
  [Constants.TAWK_TO_GET_WIDGET]: tawkToGetWidget,
  [Constants.TAWK_TO_GET_WIDGET_SUCCESS]: tawkToGetWidgetSuccess,
  [Constants.TAWK_TO_GET_WIDGET_FAILURE]: tawkToGetWidgetFaliure,

  [Constants.TAWK_TO_UPDATE_WIDGET]: tawkToUpdateWidget,
  [Constants.TAWK_TO_UPDATE_WIDGET_SUCCESS]: tawkToUpdateWidgetSuccess,
  [Constants.TAWK_TO_UPDATE_WIDGET_FAILURE]: tawkToUpdateWidgetFailure,
}

export default createReducer(INITIAL_STATE, HANDLERS)
