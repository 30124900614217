import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
    listCampaignsRequest : ['data'],
    listCampaignsSuccess : [ 'message', 'campaigns', 'totalResults', 'filterByStatus'],
    listCampaignsError : ['error'],
    listAllCampaignsRequest : ['data'],
    listAllCampaignsSuccess : [ 'message', 'campaigns'],
    listAllCampaignsError : ['error'],
    checkCampaignRequest : ['data'],
    checkCampaignSuccess : ['message'],
    checkCampaignError : ['message'],
    listCampaignTemplatesRequest : ['data'],
    listCampaignTemplatesSuccess: ['message', 'campaignTemplates', 'filterTemplatesByType'],
    listCampaignTemplatesError: ['error'],
    createCampaignRequest: ['data'],
    createCampaignSuccess: ['message', 'data'],
    createCampaignError: ['message', 'error'],
    updateCampaignRequest: ['data'],
    updateCampaignSuccess: ['message', 'data'],
    updateCampaignError: ['message', 'error'],
    getCampaignRequest: ['data'],
    getCampaignSuccess: ['message','campaign'],
    getCampaignError: ['error'],
    deleteCampaignRequest: ['data'],
    deleteCampaignSuccess: ['message'],
    deleteCampaignError: ['error'],
    listAttendeesOfCampaignsRequest: ['data'],
    listAttendeesOfCampaignsSuccess: ['message', 'data'],
    listAttendeesOfCampaignsError: ['error'],
    listAudienceGroupsRequest: ['data'],
    listAudienceGroupsSuccess: ['message','groups'],
    listAudienceGroupsError: ['error'],
    listAttendeesNotAddedToCampaignRequest: ['data'],
    listAttendeesNotAddedToCampaignSuccess: [ 'message','data'],
    listAttendeesNotAddedToCampaignError: [ 'error'],
    sendTestEmailRequest: [ 'data'],
    sendTestEmailSuccess: [ 'message'],
    sendTestEmailError: [ 'error'],
    handleListOfAttendeesOfCampaignBackup: [ 'data'],
    replicateCampaignRequest: [ 'data'],
    replicateCampaignSuccess: [ 'message'],
    replicateCampaignError: [ 'error'],
    handleCampaignNameInEditor: ['name'],
    getExcludeAttendeeCountRequest: ['data'],
    getExcludeAttendeeCountSuccess: ['excludeAttendeeCount','message'],
    getExcludeAttendeeCountError: ['error'],
    getExcludeCountRequest: ['data'],
    getExcludeCountSuccess: ['excludeCount','message'],
    getExcludeCountError: ['error'],
})

export const Constants = Types
export default Creators