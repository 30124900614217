import React, { useEffect, useRef, useState } from 'react'
import reactCSS from 'reactcss'
import { SketchPicker, Color } from 'react-color'

const ColorPickerTile: React.FC<{
  onChange: (color: string) => void
  color: string
}> = (props) => {
  const [state, setState] = useState<{
    displayColorPicker: boolean
    color: Color
  }>({
    displayColorPicker: false,
    color: props.color,
  })

  const ref = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const current = ref.current as any

      if (
        state.displayColorPicker &&
        current &&
        !current.contains(event.target)
      ) {
        setState((st) => {
          return { ...state, displayColorPicker: false }
        })
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [state])

  const handleClick = () => {
    setState({ ...state, displayColorPicker: !state.displayColorPicker })
  }

  const handleChange = (color: any) => {
    props.onChange(color.hex)
    setState({ ...state, color: color })
  }

  const styles = reactCSS({
    default: {
      color: {
        width: '47px',
        height: '28px',
        borderRadius: '1px',
        background: `${props.color}`,
      },
      swatch: {
        padding: '4px',
        background: '#fff',
        borderRadius: '2px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.2)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
        bottom: '50px',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  })
  return (
    <>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {state.displayColorPicker && (
        <div ref={ref} style={styles.popover}>
          {/* <div style={styles.cover} onClick={handleClose} /> */}
          <SketchPicker color={state.color} onChange={handleChange} />
        </div>
      )}
    </>
  )
}

export default ColorPickerTile
