import { Box, Grid } from '@material-ui/core';
import GridItem from './GridItem';
import { MessageStyle } from 'Redux/Reducers/aiChatbot.reducer';

const ChatWindowStyle = ({ chatWindow }: { chatWindow: MessageStyle }) => {
  if (!chatWindow) return null;
  return (
    <>
      <Box component="div" className="inputField" mt={3}>
        <label>Chat Window's Radius (px)</label>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12}></Grid>
        <GridItem
          label={'Top Left'}
          value={chatWindow?.borderRadius?.topLeft}
          path={['chatWindow', 'borderRadius', 'topLeft']}
        />
        <GridItem
          label={'Top Right'}
          value={chatWindow.borderRadius.topRight}
          path={['chatWindow', 'borderRadius', 'topRight']}
        />{' '}
        <GridItem
          label={'Bottom Right'}
          value={chatWindow.borderRadius.bottomRight}
          path={['chatWindow', 'borderRadius', 'bottomRight']}
        />
        <GridItem
          label={'Bottom Left'}
          value={chatWindow.borderRadius.bottomLeft}
          path={['chatWindow', 'borderRadius', 'bottomLeft']}
        />
      </Grid>

      <Box component="div" className="inputField" mt={3}>
        <label>Chat Window's Padding (px)</label>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12}></Grid>
        <GridItem
          label={'Top'}
          value={chatWindow?.padding?.top}
          path={['chatWindow', 'padding', 'top']}
        />
        <GridItem
          label={'Right'}
          value={chatWindow.padding?.right}
          path={['chatWindow', 'padding', 'right']}
        />{' '}
        <GridItem
          label={'Bottom'}
          value={chatWindow.padding?.bottom}
          path={['chatWindow', 'padding', 'bottom']}
        />
        <GridItem
          label={'Left'}
          value={chatWindow.padding?.left}
          path={['chatWindow', 'padding', 'left']}
        />
      </Grid>
    </>
  );
};

export default ChatWindowStyle;
