
const getUnLayerTransEmailConfig = (
  apiUrl = '',
  eventId = '',
  formTemplates = [],
  customToolFileds = [],
  customFiledsForAudience =[],
  passwordType = '',
  qrCodeTypeDynamic = [{}],
  qrCodeSessionOption = [{}],
  qrCodeSizeDropdown = [{ 'label': "200X200", 'value': 200 }], 
  cssUnlyaerPlayground = "",
  eventStartDate = "",
  challengesYt = [],
  pageId = '',
  sessions = [],
  isAccessCode = false,
  timerTimeTypeDynamic = [{}],
  qrCodeAttendeeDynamic = [{ 'label': "Choose One", 'value': "" }, { 'label': "Email", 'value': "{{{attendeeEmail}}}" }, { 'label': "Group", 'value': "{{{attendeeGroup}}}" }],
  customFieldDynamic = {},
  challengesDynamic = [{ 'label': "Choose One", 'value': "" }],
  sessionDynamic = [{ 'label': "Choose One", 'value': "" }],
  audienceDynamicCustomField= {},

) => {
  /* For customFieldDynamic start here*/
  customFieldDynamic['email'] = {
    name: "Email",
    value: "{{{attendeeEmail}}}"
  }
  customFieldDynamic['group'] = {
    name: "Group",
    value: "{{{attendeeGroup}}}"
  }
  if (customToolFileds && customToolFileds.length > 0) {
    for (var x = 0; x < customToolFileds.length; x++) {
      customFieldDynamic[customToolFileds[x].fieldData.fieldName] = {
        name: customToolFileds[x].fieldData.fieldLabel,
        value: "{{{" + customToolFileds[x].fieldData.fieldName + "}}}"
      }
      qrCodeAttendeeDynamic.push({ 'label': customToolFileds[x].fieldData.fieldLabel, 'value': "{{{" + customToolFileds[x].fieldData.fieldName + "}}}" })
    }
  }

  if (challengesYt.length > 0) {
    for (var x = 0; x < challengesYt.length; x++) {
      const { challengeName, _id } = challengesYt[x];
      challengesDynamic.push({ 'label': challengeName, 'value': _id })
    }
  }

  if (sessions.length > 0) {
    for (var x = 0; x < sessions.length; x++) {
      const { _id, title, dateTime } = sessions[x];
      // sessionDynamic.push({'label': title, 'value':dateTime})
      sessionDynamic.push({ 'label': title, 'value': _id })
    }
  }

    // email for audience contact
    audienceDynamicCustomField['email'] = {
      name: "Email",
      value: "{{{contactEmail}}}"
    };

    // custom fields for audience contact

    if (customFiledsForAudience && customFiledsForAudience.length > 0) {
      for (var x = 0; x < customFiledsForAudience.length; x++) {
        audienceDynamicCustomField[customFiledsForAudience[x]?.fieldData?.fieldName] = {
          name: customFiledsForAudience[x].fieldData?.fieldLabel,
          value: "{{{" + customFiledsForAudience[x]?.fieldData?.fieldName + "}}}"
        }
      }
    }

  return {
    id: 'pageEditor',
    projectId: 21377,
    displayMode: 'web',
    features: {
      pageAnchors: true,
      textEditor: {
        tables: true,
        spellChecker: true,
      },
    },
    mergeTags: {
      session: {
        name: "Session",
        mergeTags: {
          title: {
            name: "Title",
            value: "{{{sessionTitle}}}"
          },
          date: {
            name: "Date",
            value: "{{{sessionDate}}}"
          },
          duration: {
            name: "Duration",
            value: "{{{sessionDuration}}}"
          },
          location: {
            name: "Location",
            value: "{{{sessionLocation}}}"
          },
          navigateTo: {
            name: "Navigate to",
            value: "{{{sessionNavigateTo}}}"
          },
          description: {
            name: "Description",
            value: "{{{sessionDescription}}}"
          },
          tracks: {
            name: "Tracks",
            value: "{{{sessionTracks}}}"
          },
          image: {
            name: "Image",
            value: "{{{sessionImage}}}"
          },
          speakerName: {
            name: "Speakers Block",
            value: "{{{speakersBlock}}}"
          },
          sessionStartTime: {
            name: "Session Start Time",
            value: "{{{sessionStartTime}}}"
          },
          sessionEndTime: {
            name: "Session End Time",
            value: "{{{sessionEndTime}}}"
          },
          sessionAddToCalendar: {
            name: "Add to calendar",
            value: "{{{sessionAddToCalendar}}}" 
          },
        }
      },
       audienceName: {
        name: "Audience Contact",
        mergeTags: audienceDynamicCustomField,
      },
      customeField: {
        name: "Attendee",
        mergeTags: customFieldDynamic,
      },
      sessions: {
        name: 'Sessions',
        rules: {
          repeat: {
            name: 'Repeat for Each Sessions',
            before: '{{#sessions}}',
            after: '{{/sessions}}',
          },
        },
      },
      sessionsGroupByDate: {
        name: 'Session Group By Date',
        rules: {
          repeat: {
            name: 'Repeat for Each Session Group By Date',
            before: '{{#sessionsGroupByDate}}',
            after: '{{/sessionsGroupByDate}}',
          },
        },
      },
    },
    tools: {
      form: {
        enabled: true,
      },
      'custom#product_tool': {
        data: {
          products: formTemplates,
          customFields: customToolFileds,
          ytChallenges: challengesYt,
          ytOpenChallange: challengesYt,
          eventId: eventId,
          pageId: pageId,
          passwordProtection: passwordType,
          eventStartDate: eventStartDate,
          isAccessCode: isAccessCode,
        },
        properties: {
          productLibrary: {
            editor: {
              data: {
                products: formTemplates,
                customFields: customToolFileds,
                ytChallenges: challengesYt,
                ytOpenChallange: challengesYt,
                eventId: eventId,
                pageId: pageId,
                passwordProtection: passwordType,
                eventStartDate: eventStartDate,
                isAccessCode: isAccessCode,
              },
            },
          },
          addNewFieldLibrary: {
            editor: {
              data: {
                customFields: customToolFileds,
              },
            },
          },

          onOpenLibrary: {
            editor: {
              data: {
                options: challengesDynamic,
              },
            },
          },

          onPlayLibrary: {
            editor: {
              data: {
                options: challengesDynamic,
              },
            },
          },

          onEndLibrary: {
            editor: {
              data: {
                options: challengesDynamic,
              },
            },
          },

          onOpenLibraryV: {
            editor: {
              data: {
                options: challengesDynamic,
              },
            },
          },

          onPlayLibraryV: {
            editor: {
              data: {
                options: challengesDynamic,
              },
            },
          },

          onEndLibraryV: {
            editor: {
              data: {
                options: challengesDynamic,
              },
            },
          },

          timerTimeType: {
            editor: {
              data: {
                options: timerTimeTypeDynamic,
              },
            },
          },
          sessionDynamicOptions: {
            editor: {
              data: {
                options: sessionDynamic,
              },
            },
          },

          QrCodeType: {
            editor: {
              data: {
                options: qrCodeTypeDynamic,
              },
            },
          },

          QrCodeAttendeesDropdown: {
            editor: {
              data: {
                options: qrCodeAttendeeDynamic,
              },
            },
          },

          QrCodeSessionDropdown: {
            editor: {
              data: {
                options: qrCodeSessionOption,
              },
            },
          },

          QrCodeSizeDropdown : {
            editor: {
              data: {
                options:  qrCodeSizeDropdown ,
              },
            },
          }

        },
      },
    },
    customCSS: [
      cssUnlyaerPlayground,
      /*  window.location.protocol + '//' + window.location.host + '/custom.css',  */
      'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/custom.css'
    ],

    customJS: [
      //common for live/Dev or local
      'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/QrCodeLibrary/qrcode.js',
      //live/Dev env
      'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/transactionEmails/customToolProductItemsTemplateTransactional.js',
      'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/transactionEmails/customToolDefaultShowProperties.js',
      'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/transactionEmails/customQRCodeShowProperties.js',
      'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/transactionEmails/customToolForQRCodeCt.js',
      'https://s3.us-east-2.amazonaws.com/cdn.illumeetxp.com/customJsFolder/transactionEmails/custom.js',
      //Local env 
      /* window.location.protocol + '//' + window.location.host + '/transactionEmails/customToolProductItemsTemplateTransactional.js',
      window.location.protocol + '//' + window.location.host + '/transactionEmails/customToolDefaultShowProperties.js',
      window.location.protocol + '//' + window.location.host + '/transactionEmails/customQRCodeShowProperties.js',
      window.location.protocol + '//' + window.location.host + '/transactionEmails/customToolForQRCodeCt.js',
      window.location.protocol + '//' + window.location.host + '/transactionEmails/custom.js',  */
    ],
  }
}

export default getUnLayerTransEmailConfig
