import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../../Redux/Actions'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment'
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from '../../../theme/theme';
import SearchIcon from '../../../assets/images/icons/search.svg';
import CampaignIcon from '../../../assets/images/icons/campaigns-white.svg';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import '../../../sass/main.scss'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';
import Stack from '@mui/material/Stack';
import Checkbox from '@material-ui/core/Checkbox';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableFooter, TablePagination, } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'react-js-pagination'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Modal } from 'react-responsive-modal';
import { createNotification } from '../../../helpers'
import { NotificationContainer } from 'react-notifications'
import EmailAnalytics from '../EmailAnalytics'
import EditCampaign from './EditCampaign'
import Loader from '../../../Components/Loader/loader'



const styles = {
  avatar: {
    backgroundColor: '#16D1FC',
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}

class Campaigns extends Component {
  constructor(props) {
    super(props)
    this.isTotalRecords = true;
    this.state = {
      loading: false,
      listOfCampaigns: [],
      eventId: '',
      searchTextForListCampaigns: '',
      sortBy: '',
      sortOrder: 'desc',
      filterByStatus: {},
      filterBy: 'all',
      startCount: 1,
      itemCount: 10,
      page_no: 1,
      totalResults: 0,
      isallActive: true,
      isSentActive: false,
      isDraftActive: false,
      isScheduledActive: false,
      openReplicateConfirmation: false,
      campaignDetailsToReplicate: {},
      openDeleteModal: false,
      deleteId: '',
      isReportComp: false,
      selectedCampaign: null,
      allowed: {},
      totalCampaignsCountForUser: 0,
      campaignIdToReplicate: '',
    }
  }

  componentDidMount() {
    const lastIndex = window.location.pathname.split('/').length - 1
    const eventId = window.location.pathname.split('/')[lastIndex]
    this.setState({
      eventId,
    }, () => {
      this.listCampaigns()
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.campaigns.listCampaignsSuccess && !prevProps.campaigns.listCampaignsSuccess) {

      // finding if the selected service plan allows to create more campaigns
      let userDetails = JSON.parse(localStorage.getItem('user_details'))
      let reqData = {
        servicePlanId: userDetails.servicePlanId,
        projectId: this.state.eventId
      }
      this.props.findIfAllowedFromSuperadminRequest(reqData)
      // finding if the selected service plan allows to create more campaigns ends

      this.setState({
        listOfCampaigns: this.props.campaigns.campaigns,
        filterByStatus: this.props.campaigns.filterByStatus
      })
      if (this.state.loading) {
        this.setState({
          loading: false,
        })
      }
      this.updatePaginationDropDownState()
    }

    if (this.props.campaigns.createCampaignSuccess && !prevProps.campaigns.createCampaignSuccess && this.props.campaigns.message !== prevProps.campaigns.message) {
      createNotification("success", this.props.campaigns.message)
      this.setState({
        openReplicateConfirmation: false,
      })
      this.listCampaigns()
      if (this.state.loading) {
        this.setState({
          loading: false
        })
      }
    }
    if (this.props.campaigns.replicateCampaignSuccess && !prevProps.campaigns.replicateCampaignSuccess && this.props.campaigns.message !== prevProps.campaigns.message) {
      createNotification("success", this.props.campaigns.message)
      this.setState({
        openReplicateConfirmation: false,
      })
      this.listCampaigns()
      if (this.state.loading) {
        this.setState({
          loading: false
        })
      }
    }

    if (this.props.campaigns.deleteCampaignSuccess && !prevProps.campaigns.deleteCampaignSuccess && this.props.campaigns.message !== prevProps.campaigns.message) {
      createNotification("success", this.props.campaigns.message)
      if (this.state.loading) {
        this.setState({
          loading: false,
        })
      }
      this.setState({
        openDeleteModal: false
      })

      this.listCampaigns()
    }


    if (this.props.campaigns.error && !prevProps.campaigns.error) {
      if (this.props?.campaigns?.message) {
        createNotification("error", this.props.campaigns.message)
      }
    }

    if (this.props.project?.project?.permissions) {
      let allowed = {};
      if (this.props.project.project.permissions.find(o => o.permission_name === 'Create Campaign')) {
        allowed['Create Campaign'] = true
      }
      if (this.props.project.project.permissions.find(o => o.permission_name === 'Edit Campaign')) {
        allowed['Edit Campaign'] = true
      }
      if (this.props.project.project.permissions.find(o => o.permission_name === 'Delete Campaign')) {
        allowed['Delete Campaign'] = true
      }
      if (this.props.project.project.permissions.find(o => o.permission_name === 'View Campaigns')) {
        allowed['View Campaigns'] = true
      }
      this.state.allowed = allowed;
    }
  }

  updatePaginationDropDownState() {
    if (this.state.itemCount >= this.props.campaigns.totalResults) {
      this.setState({
        endCount: this.props.campaigns.totalResults
      });
    } else {
      this.setState({
        endCount: this.props.campaigns.totalResults > (this.state.itemCount * this.state.page_no) ? (this.state.itemCount * this.state.page_no) : this.props.campaigns.totalResults
      });
    }
  }

  listCampaigns = (isSearching) => {
    const { eventId, searchTextForListCampaigns, sortBy, sortOrder, filterBy, page_no, itemCount } = this.state
    const requestbody = {
      eventId,
      searchText: isSearching ? searchTextForListCampaigns.trim() : '',
      limit: itemCount,
      sort_by: sortBy,
      order: sortOrder,
      status: filterBy,
      page_no,
    }
    this.props.listCampaigns(requestbody)
    if (!isSearching) {
      this.setState({
        searchTextForListCampaigns: ''
      })
    }
    this.handleLoading()
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  handlePageChange = (pageNumber) => {
    const { itemCount, totalResults } = this.state
    this.setState((prevState, props) => ({
      startCount: pageNumber !== 1 ? (itemCount * (pageNumber - 1) + 1) : 1,
      endCount: totalResults > itemCount * pageNumber ? itemCount * pageNumber : totalResults,
      page_no: pageNumber,
      totalResults: this.props.campaigns.totalResults
    }), () => {
      this.listCampaigns()
    });
  }

  selectItemCount = (e) => {
    const { totalResults } = this.state;
    this.setState({
      page_no: 1,
      itemCount: e.target.value,
      endCount: parseInt(totalResults),
      startCount: 1,
    }, () => this.listCampaigns()
    )
  }

  handleFilter = (filterBy) => {
    this.setState({
      filterBy: filterBy,
      page_no: 1,
      startCount: 1
    }, () => this.listCampaigns())
    this.setState({
      isallActive: false,
      isSentActive: false,
      isScheduledActive: false,
      isDraftActive: false,
    }, () => {
      this.setState({
        [`is${filterBy}Active`]: true
      })
    })
  }

  handleSearchTextChange = (e) => {
    this.setState({
      searchTextForListCampaigns: e.target.value
    })
  }

  handleSubmitSearch = (e) => {
    e.preventDefault()
    this.setState({ page_no: 1, startCount: 1 }, () => this.listCampaigns(true))
  }

  sorting = (value) => {
    if (this.state.sortBy === value) {
      if (this.state.sortOrder === "asc") {
        this.setState({
          sortOrder: "desc"
        }, () => this.listCampaigns()
        )
      } else {
        this.setState({
          sortOrder: "asc"
        }, () => this.listCampaigns()
        )
      }
    } else {
      this.setState({
        sortBy: value,
        sortOrder: "asc"
      }, () => {
        this.listCampaigns()
      }
      )
    }
  }

  handleCloseReplicateConfirmation = () => {
    this.setState({
      openReplicateConfirmation: false,
      campaignIdToReplicate: '',
    })
  }

  handleOpenReplicateConfirmation = (campaign) => {
    this.setState({
      openReplicateConfirmation: true,
      campaignDetailsToReplicate: campaign,
      campaignIdToReplicate: campaign._id,
    })
  }

  createCampaign = () => {
    const { campaignDetailsToReplicate } = this.state
    const { campaignName, html, json, status, eventId, campaignType } = campaignDetailsToReplicate
    const body = {
      projectId:eventId,
      campaignName: `${campaignName} (Copied)`,
      html,
      json,
      campaignType: campaignType,
      status,
      permission: this.props.project.project.permission,
      lngCode: this.props.users.newLangState
    }
    this.props.createCampaign(body)
    this.handleLoading()
  }

  replicateCampaign = () => {
    this.props.replicateCampaign({
      replicateId: this.state.campaignIdToReplicate,
      eventId: this.state.eventId,
      lngCode: this.props.users.newLangState
    })
  }

  handleCloseDeleteModal = () => {
    this.setState({
      openDeleteModal: false
    })
  }

  handleOpenDeleteModal = (deleteId) => {
    this.setState({
      openDeleteModal: true,
      deleteId,
    })
  }

  deleteCampaign = () => {
    const { deleteId } = this.state
    this.props.deleteCampaign({ id: deleteId, eventId: this.state.eventId, permission: 'Delete Campaign', lngCode: this.props.users.newLangState })
    this.handleLoading()
  }

  openReport = (campaign) => {
    this.setState({
      isReportComp: true,
      selectedCampaign: campaign,
    });
  }

  closeReport = () => {
    this.setState({
      isReportComp: false,
      selectedCampaign: null
    });
    this.listCampaigns()
  }

  handleEditOrReport = (campaign) => {
    const currentDate = moment(new Date());
    if (campaign.status === "Draft" || (campaign.status === "Scheduled"  && !currentDate.isAfter(campaign.scheduledAt))) {
      this.props.history.push({
        pathname: `/project-dashboard/campaigns/${this.state.eventId}`,
        search: `?cid=${campaign._id}`,
        state: { isEditCampaign: true }
      })
    } else if ((campaign.status === "Sent") || (campaign.status === 'Scheduled' && currentDate.isAfter(campaign.scheduledAt))) {
      this.openReport(campaign)
    }
  }

  render() {
    const { listOfCampaigns, startCount, endCount, itemCount, page_no, totalResults, filterByStatus, searchTextForListCampaigns, sortBy, sortOrder, isReportComp, selectedCampaign, allowed } = this.state
    const currentDate = moment(new Date());
    return (
      <ThemeProvider theme={theme}>
        {isReportComp ? <EmailAnalytics 
                          project={this.props.project} 
                          closeReport={this.closeReport} 
                          selectedEmailType={"campaigns"} 
                          campaign={selectedCampaign} 
                          listCampaigns={this.listCampaigns}
                        />
          :
          <>
            {!this.props.location.state ?
              <div>
                <Grid container spacing={2} mb={2} className="top-filter-area">
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Box
                          component={Button}
                          variant="contained"
                          sx={{
                            backgroundColor: theme.palette.background.light,
                            color: theme.palette.common.black,
                            textTransform: 'capitalize !important',
                            fontWeight: '400',
                            '&:hover, &.active': {
                              backgroundColor: '#000056 !important',
                              color: theme.palette.primary.contrastText,
                            }
                          }}
                          className={this.state.isallActive ? 'active' : ''}
                          size='small'
                          disableElevation
                          aria-haspopup="true"
                          onClick={() => this.handleFilter("all")}
                        >
                          {this.props.users.languageStateForRedux?.common?.all}  ({filterByStatus.all || 0})
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          component={Button}
                          variant="contained"
                          sx={{
                            backgroundColor: theme.palette.background.light,
                            color: theme.palette.common.black,
                            textTransform: 'capitalize !important',
                            fontWeight: '400',
                            '&:hover, &.active': {
                              backgroundColor: '#000056 !important',
                              color: theme.palette.primary.contrastText,
                            }
                          }}
                          className={this.state.isSentActive ? 'active' : ''}
                          size='small'
                          disableElevation
                          aria-haspopup="true"
                          onClick={() => this.handleFilter("Sent")}
                        >
                          {this.props.users.languageStateForRedux?.common.sent} ({filterByStatus.Sent || 0})
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          component={Button}
                          variant="contained"
                          sx={{
                            backgroundColor: theme.palette.background.light,
                            color: theme.palette.common.black,
                            textTransform: 'capitalize !important',
                            fontWeight: '400',
                            '&:hover, &.active': {
                              backgroundColor: '#000056 !important',
                              color: theme.palette.primary.contrastText,
                            }
                          }}
                          className={this.state.isDraftActive ? 'active' : ''}
                          size='small'
                          disableElevation
                          aria-haspopup="true"
                          onClick={() => this.handleFilter("Draft")}
                        >
                          {this.props.users.languageStateForRedux?.common?.draft} ({filterByStatus.Draft || 0})
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          component={Button}
                          variant="contained"
                          sx={{
                            backgroundColor: theme.palette.background.light,
                            color: theme.palette.common.black,
                            textTransform: 'capitalize !important',
                            fontWeight: '400',
                            '&:hover, &.active': {
                              backgroundColor: '#000056 !important',
                              color: theme.palette.primary.contrastText,
                            }
                          }}
                          className={this.state.isScheduledActive ? 'active' : ''}
                          size='small'
                          disableElevation
                          aria-haspopup="true"
                          onClick={() => this.handleFilter("Scheduled")}
                        >
                          {this.props.users.languageStateForRedux?.common?.scheduled} ({filterByStatus.Scheduled || 0})
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1.25} className="team-dash-right">
                      <Grid item className="">
                        <form onSubmit={this.handleSubmitSearch}>
                          <TextField
                            name="searchText"
                            placeholder={this.props.users.languageStateForRedux?.common?.search}
                            className="search"
                            size="small"
                            value={searchTextForListCampaigns}
                            onChange={this.handleSearchTextChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img alt="" src={SearchIcon} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </form>
                      </Grid>
                      {allowed['Create Campaign'] &&
                        <Grid item>
                          <Link to={`/project-dashboard/campaigns/create-campaign/${this.state.eventId}`}>
                            <Button
                              variant="contained"
                              color="primary"
                              disableElevation
                            >
                              {this.props.users.languageStateForRedux?.buttons?.campaigns?.add}
                            </Button>
                          </Link>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <TableContainer className="list-table" style={{ maxHeight: 'calc(100vh - 380px)', }}>
                  <Table className="table">
                    <TableHead style={{ position: 'sticky', top: '0', backgroundColor: '#f6f6fc', zIndex: 2, }}>
                      <TableRow>
                        <TableCell className={'sticky-cell'} style={{ minWidth: '78px', maxWidth: '78px' }}></TableCell>
                        <TableCell className={'name-avatar sticky-cell ' + (sortBy == 'campaignName' ? (sortOrder == 'asc' ? 'sort-asc' : 'sort-des') : '')} style={{ minWidth: '370px', left: '78px', }} 
                        onClick={() => this.sorting("campaignName")}>{this.props.users.languageStateForRedux?.column_names?.campaigns?.title}
                        </TableCell>
                        <TableCell className={'status ' + (sortBy == 'status' ? (sortOrder == 'asc' ? 'sort-asc' : 'sort-des') : '')} onClick={() => this.sorting("status")}>{this.props.users.languageStateForRedux?.common?.status}
                        </TableCell>
                        <TableCell className='text'>{this.props.users.languageStateForRedux?.column_names?.campaigns?.sentMail}
                        </TableCell>
                        <TableCell className='text'>{this.props.users.languageStateForRedux?.column_names?.campaigns?.opens}
                        </TableCell>
                        <TableCell className='text'>{this.props.users.languageStateForRedux?.column_names?.campaigns?.clicks}
                        </TableCell>
                        <TableCell className='text'>{this.props.users.languageStateForRedux?.column_names?.campaigns?.bouncedEmail}
                        </TableCell>
                        <TableCell className='text'>{this.props.users.languageStateForRedux?.column_names?.campaigns?.rejectedEmail}
                        </TableCell>
                        <TableCell className='options' style={{ minWidth: '200px', maxWidth: '200px' }} align='right'>{this.props.users.languageStateForRedux?.common?.action}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(listOfCampaigns) && listOfCampaigns?.length > 0 ? listOfCampaigns.map(campaign => {
                        return (
                          <TableRow style={{ alignItems: "start", }}>
                            <TableCell className='sticky-cell' style={{ minWidth: '78px', maxWidth: '78px' }}>
                              <Avatar style={styles.avatar} >
                                <img alt="" src={CampaignIcon} />
                              </Avatar>
                            </TableCell>
                            <TableCell className='name-avatar sticky-cell' style={{ minWidth: '370px', left: '78px', }}>
                              <Grid container spacing={2} alignItems='center'>
                                <Grid item xs>
                                  <Box component="p" mb={1} className='subtitle bold' style={{ cursor: "pointer" }} onClick={() => this.handleEditOrReport(campaign)}>{campaign.campaignName}</Box>
                                  <Box component="p" mb={0} className='small-subtitle' >
                                    {campaign.status === "Draft" ?
                                      (this.props.users.languageStateForRedux?.column_names?.accessCodes?.createdOn) + ` ${moment.tz(campaign.updatedAt, this.props.project?.project?.timezone).format("ddd, MMMM Do, YYYY h:mm A")}`
                                      : campaign.status === "Sent" ?
                                        (this.props.users.languageStateForRedux?.formfields?.campaigns?.sentOn) + `  ${moment.tz(campaign.sentAt, this.props.project?.project?.timezone).format("ddd, MMMM Do, YYYY h:mm A")}`
                                        : campaign.status === "Scheduled" ?
                                          (campaign.status === 'Scheduled' && currentDate.isAfter(campaign.scheduledAt)) ?
                                            (this.props.users.languageStateForRedux?.formfields?.campaigns?.sentOn) + `  ${moment.tz(campaign.scheduledAt, this.props.project?.project?.timezone).format("ddd, MMMM Do, YYYY h:mm A")}`
                                            :
                                            (this.props.users.languageStateForRedux?.formfields?.campaigns?.scheduledOn) + ` ${moment.tz(campaign.scheduledAt, this.props.project?.project?.timezone).format("ddd, MMMM Do, YYYY h:mm A")}`
                                          : "--"
                                    }
                                  </Box>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell className='status'>
                              {campaign.status === 'Draft' ?
                                <span className={`table-status light`}> {this.props.users.languageStateForRedux?.common?.draft} </span>
                                : campaign.status === 'Sent' ?
                                  <span className={`table-status success`}> {this.props.users.languageStateForRedux?.common?.sent} </span>
                                  :
                                  <>
                                    {(campaign.status === 'Scheduled' && currentDate.isAfter(campaign.scheduledAt)) ?
                                      <span className={`table-status success`}> {this.props.users.languageStateForRedux?.common?.sent} </span>
                                      :
                                      <span className={`table-status warning`}> {this.props.users.languageStateForRedux?.common?.scheduled} </span>
                                    }
                                  </>
                              }
                            </TableCell>
                            <TableCell className='text'><p className='bolder paragraph'>{campaign.status === 'Sent' ? campaign.mandrillData ? campaign.mandrillData.totalSent : '--' : '--'}</p></TableCell>
                            <TableCell className='text'><p className='bolder paragraph'>{campaign.mandrillData ? `${campaign.mandrillData.opens} (${campaign.mandrillData.opensPer}%)` : '--'}</p></TableCell>
                            <TableCell className='text'><p className='bolder paragraph'>{campaign.mandrillData ? `${campaign.mandrillData.clicks} (${campaign.mandrillData.clicksPer}%)` : '--'}</p></TableCell>
                            <TableCell className='text'><p className='bolder paragraph'>{campaign.mandrillData ? `${campaign.mandrillData.bounced} (${campaign.mandrillData.bouncedPer}%)` : '--'}</p></TableCell>
                            <TableCell className='text'><p className='bolder paragraph'>{campaign.mandrillData ? `${campaign.mandrillData.rejects} (${campaign.mandrillData.rejectsPer}%)` : '--'}</p></TableCell>
                            {
                              campaign.status === 'Sent' || (campaign.status === 'Scheduled' && currentDate.isAfter(campaign.scheduledAt)) ?
                                <>
                                  <TableCell className='text' style={{ display: 'flex', minWidth: '200px', maxWidth: '200px' }} align='right'>
                                    {(allowed['View Campaigns'] || allowed['Create Campaign']) &&
                                      <Stack direction="row" alignItems={'center'} spacing={1}>
                                        <div className='detail-popup'>
                                          <Box style={{ width: '40px', height: '32px', borderRadius: '10%', backgroundColor: '#e0e0e0', padding: '4px 8px' }} >
                                            <ExpandMoreIcon />
                                          </Box>
                                          <div className='info' style={{ top: '30px', right: '0px', minWidth: '200px', borderRadius: '8px', }} >

                                            {allowed['Create Campaign'] &&
                                              <div onClick={() => this.handleOpenReplicateConfirmation(campaign)}> <p>{this.props.users.languageStateForRedux?.actions?.campaigns?.replicate}</p></div>
                                            }
                                            {allowed['View Campaigns'] &&
                                              <div onClick={() => this.openReport(campaign)}><p>{this.props.users.languageStateForRedux?.actions?.campaigns?.viewReport} </p></div>
                                            }
                                          </div>
                                        </div>
                                      </Stack>
                                    }
                                  </TableCell>
                                </>
                                :
                                <TableCell className='text' style={{ display: 'flex', minWidth: '200px', maxWidth: '200px' }} align='right'>
                                  {(allowed['Edit Campaign'] || allowed['Delete Campaign'] || allowed['Create Campaign']) &&
                                    <Stack direction="row" alignItems={'center'} spacing={1}>
                                      <div className='detail-popup'>
                                        <Box style={{ width: '40px', height: '32px', borderRadius: '10%', backgroundColor: '#e0e0e0', padding: '4px 8px' }} >
                                          <ExpandMoreIcon />
                                        </Box>
                                        <div className='info' style={{ top: '30px', right: '0px', minWidth: '200px', borderRadius: '8px', }} >
                                          {allowed['Edit Campaign'] &&
                                            <Link to={
                                              {
                                                pathname: `/project-dashboard/campaigns/${this.state.eventId}`,
                                                search: `?cid=${campaign._id}`,
                                                state: { isEditCampaign: true }
                                              }
                                            }>
                                              <div>{this.props.users.languageStateForRedux?.actions?.campaigns?.edit}</div>
                                            </Link>
                                          }
                                          {allowed['Delete Campaign'] &&
                                            <div style={{ color: theme.palette.secondary.main, }} onClick={() => this.handleOpenDeleteModal(campaign._id)}>{this.props.users.languageStateForRedux?.common?.delete}</div>
                                          }
                                          {allowed['Create Campaign'] &&
                                            <div onClick={() => this.handleOpenReplicateConfirmation(campaign)}>{this.props.users.languageStateForRedux?.actions?.campaigns?.replicate}</div>
                                          }
                                        </div>
                                      </div>
                                    </Stack>
                                  }
                                </TableCell>
                            }
                          </TableRow>
                        )
                      }) :
                        ( !this.state.loading && listOfCampaigns?.length === 0 && this.props.campaigns?.listCampaignsSuccess &&
                          (<TableRow>
                          <TableCell style={{ textAlign: "center" }}>
                          {this.props.users.languageStateForRedux?.common?.noRecords}
                          </TableCell>
                        </TableRow>))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                {Array.isArray(listOfCampaigns) && listOfCampaigns.length > 0 &&
                  <Grid container alignItems="center" justifyContent="space-between" className="tablePagination">
                    <Grid item>
                      <Box component="span" className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.showing}  {startCount} {this.props.users.languageStateForRedux?.pagination?.to} {endCount} {this.props.users.languageStateForRedux?.pagination?.of} {this.props.campaigns.totalResults}</Box>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" >
                        <Grid item>
                          <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.shows}</span>
                          <select value={itemCount} onChange={this.selectItemCount}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                          <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.entries}</span>
                        </Grid>
                        <Grid item>
                          <Pagination
                            activePage={page_no}
                            itemsCountPerPage={itemCount}
                            totalItemsCount={this.props.campaigns.totalResults}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                            prevPageText={<NavigateBeforeIcon style={{ fontSize: 18 }} />}
                            nextPageText={<NavigateNextIcon style={{ fontSize: 18 }} />}
                            itemClassFirst="first d-none"
                            itemClassLast="last d-none"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                }
                <Modal
                  open={this.state.openReplicateConfirmation} onClose={this.handleCloseReplicateConfirmation}
                  classNames={{
                    overlay: '',
                    modal: 'Invite-User-Modal',
                  }}
                  center
                >
                  <Grid container className='modal-wrapper' >
                    <Grid item xs={12} pb={4}>
                      <Box component={"p"} mb={1} className="subtitle">{this.props.users.languageStateForRedux?.common?.confirmReplicate}</Box>
                      <p className="small-subtitle">{this.props.users.languageStateForRedux?.actions?.campaigns?.textMsg}</p>
                    </Grid>
                    <Grid item xs={12} >
                      <Grid container justifyContent="end">
                        <Stack spacing={1} direction="row" fullWidth>
                          <Button variant="contained" color="primary" disableElevation onClick={() => this.replicateCampaign()}>{this.props.users.languageStateForRedux?.common?.replicate}</Button>
                          <Button variant="outlined" disableElevation onClick={this.handleCloseReplicateConfirmation}>{this.props.users.languageStateForRedux?.common?.cancel}</Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Modal>
                <Modal
                  open={this.state.openDeleteModal} onClose={this.handleCloseDeleteModal}
                  classNames={{
                    overlay: '',
                    modal: 'Invite-User-Modal',
                  }}
                  center
                >
                  <Box component={Grid} container className="" px={1.5}>
                    <Box component={Grid} mt={-1} item xs={12} pb={3}>
                      <Box component="p" mb={1} className="subtitle">
                        {' '}
                        {this.props.users.languageStateForRedux?.common?.confirmDelete}
                      </Box>
                      <p className="small-subtitle">{this.props.users.languageStateForRedux?.common?.deleteMsg}</p>
                    </Box>
                    <Grid item xs={12} >
                      <Grid container justifyContent="end">
                        <Stack spacing={1} direction="row" fullWidth>
                          <Button variant="contained" color="secondary" disableElevation onClick={this.deleteCampaign}>{this.props.users.languageStateForRedux?.common?.delete}</Button>
                          <Button variant="outlined" disableElevation onClick={this.handleCloseDeleteModal}>{this.props.users.languageStateForRedux?.common?.cancel}</Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>
              </div>
              :
              <EditCampaign listCampaigns={this.listCampaigns}
                            lngCode={this.props.users.newLangState} />
            }
          </>
        }
        <NotificationContainer />
        {this.state.loading ? <Loader /> : null}
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  campaigns: state.Campaigns,
  project: state.Projects,
  events: state.Events,
  servicePlan: state.ServicePlan,
  users: state.Users,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listCampaigns: Actions.listCampaignsRequest,
      createCampaign: Actions.createCampaignRequest,
      deleteCampaign: Actions.deleteCampaignRequest,
      findIfAllowedFromSuperadminRequest: Actions.findIfAllowedFromSuperadminRequest,
      replicateCampaign: Actions.replicateCampaignRequest
    },
    dispatch,
  )

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Campaigns))

