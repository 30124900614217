import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import DashboardLayout from '../../Layouts/DashboardLayout'
import 'react-responsive-modal/styles.css'
import Pagination from 'react-js-pagination'
import { ExportToCsv } from 'export-to-csv'
import { Button } from '@material-ui/core'
import Grid from '@mui/material/Grid'
import Box from '@material-ui/core/Box'
import BackButtonOutlined from '../../assets/images/icons/backButtonOutlined.svg'
import ExportIcon from '../../assets/images/icons/export.svg'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { Modal } from 'react-responsive-modal'
import Exporting from '../../assets/images/export.gif';

import moment from 'moment'
import {
  ListRejectsContactAPI,
  ListAllRejectsContactAPI,
} from '../../Redux/API'

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  Typography,
} from '@material-ui/core'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'

class RejectContacts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFilterVisible: false,
      isFilterImports: false,
      title: 'Import Emails Report',
      pageTitle: 'Import Emails Report',
      open: false,
      errors: {},
      loading: false,
      page_no: 1,
      totalResults: 0,
      rejects: [],
      startCount: 1,
      endCount: 0,
      itemCount: 10,
      allContactsList: [],
      contactId: '',
    }
  }

  componentDidMount() {
    this.setState({
      contactId: this.props.contacts?.contacts?._id,
    })
    this.getImportLogs()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.itemCount !== this.state.itemCount) {
      if (this.state.itemCount < this.state.totalResults) {
        this.setState({
          endCount: parseInt(this.state.itemCount),
        })
      } else {
        this.setState({
          endCount: parseInt(this.props.contacts.totalCount),
        })
      }
    }

    if (
      this.props.contacts.rejectContactListSuccess === true &&
      this.props.contacts.rejectContactListSuccess !==
      prevProps.contacts.rejectContactListSuccess
    ) {
      this.setState(
        {
          rejects: this.props.contacts.rejectedList,
          totalResults: this.props.contacts.totalCount,
          loading: false,
          downloadExport:false
        },
        () => {
          if (this.state.itemCount >= this.state.totalResults) {
            this.setState({
              endCount: parseInt(this.state.totalResults),
            })
          } else if (
            this.state.itemCount < this.state.totalResults &&
            this.state.page_no === 1
          ) {
            this.setState({
              endCount: parseInt(this.state.itemCount),
            })
          }
        },
      )
    }
  }

  getImportLogs = () => {
    try {
      const { page_no, itemCount, sort_by, order } = this.state

      this.props.rejectContactListRequest({
        page_no: this.state.page_no,
        limit: this.state.itemCount,
      })
      this.setState({
        loading: true,
      })
    } catch (error) {
      createNotification('error', 'Something went wrong in impotlogs listing')
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handlePageChange = (pageNumber) => {
    try {
      const { itemCount, totalResults } = this.state
      this.setState(
        (prevState, props) => ({
          startCount: pageNumber !== 1 ? itemCount * (pageNumber - 1) + 1 : 1,
          endCount:
            totalResults > itemCount * pageNumber
              ? itemCount * pageNumber
              : totalResults,
          page_no: pageNumber,
          pageHasChanged: true,
        }),
        () => {
          this.getImportLogs()
        },
      )
    } catch (error) {
      createNotification('error', 'something went wrong')
    }
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  goBack = () => {
    this.props.history.goBack()
  }

  selectItemCount = (e) => {
    this.setState(
      {
        itemCount: e.target.value,
        limit: e.target.value,
        page_no: 1,
        startCount: 1,
      },
      () => this.getImportLogs(),
    )
  }

  exportCsv = () => {
    this.setState({
      downloadExport:this.props.contacts.totalCount <=1000  ?  false:true,
      loading :this.props.contacts.totalCount <=1000? true:false,
    })
    ListAllRejectsContactAPI()
      .then((result) => {
        if (result.data.status === 200) {
          let data = []
          result.data.contacts.map((item) => {
            let d = {
              email: item.email,
              reason: item.reason,
              updatedAt:item.updatedAt,
              updatedAt:item.updatedAt? moment(item.updatedAt).format('MMM, DD YYYY, h:mm A'): '--'}
            data.push(d)
          })

          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: 'rejectContacts',
          }
          const csvExporter = new ExportToCsv(options)
          if (data.length > 0) {
            csvExporter.generateCsv(data)
          }
        } else {
          createNotification('error', result.data.message)
        }
        this.setState({ loading: false ,downloadExport:false})
      })
      .catch(console.log)
  }

  optionsList = (val) => {
    let temp;
    let substring= val.split(' ')[0];
    switch (substring) {
      case 'Invalid': temp = this.props.users.languageStateForRedux?.formfields?.attendees?.invalidEmails;
      return temp;
      case 'Already': temp = this.props.users.languageStateForRedux?.formfields?.attendees?.already;
      return temp;
      case 'Duplicate': temp = this.props.users.languageStateForRedux?.formfields?.attendees?.duplicate;
      return temp;
      case 'Mandatory': temp = this.props.users.languageStateForRedux?.audienceTab?.mandatoryMissing;
      return temp;

      default: temp = val;
      break;
    }
  }

  render() {
    const {
      title,
      pageTitle,
      loading,
      page_no,
      totalResults,
      rejects,
      startCount,
      endCount,
      itemCount,
    } = this.state

    return (
      <DashboardLayout title={title} pageTitle={pageTitle}>
        <div className="dashboardMiddleArea">
          <Grid container spacing={2} mb={2} className="top-filter-area">
            <Grid item>
              <Grid container alignItems={'center'} spacing={2}>
                <Grid item>
                  <Button
                    size="small"
                    style={{ padding: '0', minWidth: '0' }}
                    variant="link"
                    onClick={this.goBack}
                    color="primary"
                  >
                    <img src={BackButtonOutlined} alt="back" />
                  </Button>
                </Grid>
                <Grid item>
                  <Typography variant="body2" className="small-subtitle">
                  {this.props.users.languageStateForRedux?.audienceTab?.audienceContacts}{' '}
                    <KeyboardArrowRightIcon style={{ fontSize: '18px' }} />{' '}
                    {this.props.users.languageStateForRedux?.audienceTab?.importErrLogs}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    endIcon={<img alt="" src={ExportIcon} />}
                    disableElevation
                    disabled={
                      Array.isArray(rejects) && rejects.length > 0 ? false : true
                    }
                    onClick={() => this.exportCsv()}
                  >
                    {this.props.users.languageStateForRedux?.common?.export}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box component={'div'} p={0} mb={3} className="header-common">
            <Grid item>
              <h1 className="heading"> {this.props.users.languageStateForRedux?.audienceTab?.importErrLogs}</h1>
            </Grid>
          </Box>


          <TableContainer
            className="list-table"
            style={{ maxHeight: 'calc(100vh - 380px)' }}
          >
            <Table className="table">
              <>
                <TableHead>
                  <TableRow>
                    <TableCell className="email">{this.props.users.languageStateForRedux?.column_names?.teams?.email}</TableCell>
                    <TableCell className="text">{this.props.users.languageStateForRedux?.audienceTab?.reason}</TableCell>
                    <TableCell className="text">{this.props.users.languageStateForRedux?.column_names?.attendees?.lastUpdated}</TableCell>
                  </TableRow>
                </TableHead>
                {rejects && rejects.length > 0 ? (
                  <TableBody>
                    {rejects &&
                      rejects.map((data, index) => {
                        return (
                          <TableRow>    
                            <TableCell className="email">
                              {data.email}
                            </TableCell>
                            <TableCell className="text">
                              {this.optionsList(data.reason)}
                            </TableCell> 
                            <TableCell className="date-time">
                                {data.updatedAt
                                  ? moment(data.updatedAt).format(
                                      'MMM, DD YYYY, h:mm A',
                                    )
                                  : '--'}
                              </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }}>
                        {this.props.users.languageStateForRedux?.common?.noRecords}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </>
            </Table>
          </TableContainer>
          <Table className="table">
            {rejects.length ? (
              <div className="tablePagination">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  className="tablePagination"
                >
                  <Grid item>
                    <Box component="span" className="small-subtitle">
                      {this.props.users.languageStateForRedux?.pagination?.showing} {startCount} {this.props.users.languageStateForRedux?.pagination?.to} {endCount} {this.props.users.languageStateForRedux?.pagination?.of}{' '}{totalResults}
                    </Box>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center">
                      <Grid item>
                        <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.shows}</span>
                        <select
                          value={itemCount}
                          onChange={this.selectItemCount}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.entries}</span>
                      </Grid>
                      <Grid item>
                        <Pagination
                          activePage={page_no}
                          itemsCountPerPage={this.state.itemCount}
                          totalItemsCount={this.props.contacts.totalCount}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                          prevPageText={
                            <NavigateBeforeIcon style={{ fontSize: 18 }} />
                          }
                          nextPageText={
                            <NavigateNextIcon style={{ fontSize: 18 }} />
                          }
                          itemClassFirst="first d-none"
                          itemClassLast="last d-none"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )
              : null
            }
          </Table>

        </div>

        <Modal
            open={this.state.downloadExport}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal modal-default-close-none',
              
            }}
            center
          >
            <Grid
              component="form"
              container
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} pb={2.6}>
                <Box textAlign="center" mt={1.6} mb={3}>
                  <img alt="" src={Exporting} width="92" height="92" />
                </Box>
                <Box
                  component="p"
                  textAlign="center"
                  mb={3}
                  className="subsecondary bold"
                >
                  Please wait while we securely <br/>
                       export your data!
                </Box>
                <Box component="p" textAlign="center" className="paragraph">
                  Your information is being processed and will be ready for<br/>
                      download shortly. Thank you for your patience!
                </Box>
              </Grid>
            </Grid>
          </Modal>

        <NotificationContainer />
        {loading || this.props.users.isLoading ? <Loader /> : null}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  contacts: state.Contacts,
  groups: state.Groups,
  users: state.Users
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      rejectContactListRequest: Actions.rejectContactListRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(RejectContacts)
