const getReturnValues = (badgePrintSettings) => {
  const {
    width,
    height,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight
  } = badgePrintSettings;

  return `@media print {
    @page {
      size: ${width}mm ${height}mm !important; 
      margin: ${marginTop}mm ${marginRight}mm ${marginBottom}mm ${marginLeft}mm !important; 
    }
  }`
};

export default getReturnValues; 

