import { Switch, withStyles, } from '@material-ui/core';


const styles = () => ({
    root: {
        //padding: 0,
        width: 68,
        height: 48,

    },

    switchBase: {
        padding: 0,
        top: 'calc(50% - 10px)',
        left: 14,
        '&.Mui-checked': {
            color: '#ffffff',
        },

    },

    track: {
        opacity: '1 !Important',
        backgroundColor: '#888888',
        borderRadius: 50,
    }

});
const CustomSwitch = ({ color, name, classes, onChange, checked, disabled=false }) => {
    return (
        <Switch disableRipple
            classes={{ root: classes.root, switchBase: classes.switchBase, track: classes.track }}
            color={color}
            name={name}
            checked={checked}
            onChange={onChange}     
            disabled={disabled}               
        />
    );
};

export default withStyles(styles)(CustomSwitch);