import React, { Component } from 'react'
//import Timer from "react-compound-timer";
import Countdown from 'react-countdown'
import moment from 'moment'
//import CONFIG from "../config.json";

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return ''
  } else {
    return (
      <ul>
        <li>
          <h2>{days}</h2>
          <p>Days</p>
        </li>
        <li>
          <h2>{hours}</h2>
          <p>Hours</p>
        </li>
        <li>
          <h2>{minutes}</h2>
          <p>Minutes</p>
        </li>
        <li>
          <h2>{seconds}</h2>
          <p>Seconds</p>
        </li>
      </ul>
    )
  }
}

class CountDownComp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      counterFinised: false,
    }
  }

  countFn = (endTime, start = true) => {
    return (
      <Countdown
        date={Date.now() + endTime}
        renderer={renderer}
        intervalDelay={1}
        precision={3}
        autoStart={start}
      />
    )
  }

  render() {
    const { counterFinised } = this.state 
    if (counterFinised == false) {
      return (
        <>
          <div className="offerLimit">
            {this.props.endTimeTimer &&
              this.props.endTimeTimer > 0 &&
              this.countFn(this.props.endTimeTimer)}
          </div>
        </>
      )
    } else {
      return ''
    }
  }
}

export default CountDownComp
