import React, { ChangeEvent } from 'react';
import Box from '@mui/material/Box';

type CustomInfoProps = {
  createCustomInfo: (e: ChangeEvent<HTMLTextAreaElement>)=> void,
  context: string
};

const CustomInfo = ({ createCustomInfo, context }: CustomInfoProps) => {
  return (
    <Box className="tab-content">
      <Box className="tab-title">Custom Info</Box>
      <Box>
        <textarea
          rows={15}
          className="form-control small"
          placeholder="Please enter your custom information to train the agent..."
          style={{ minHeight: '200px' }}
          onChange={createCustomInfo}
          value={context}
        />
      </Box>
    </Box>
  );
};

export default CustomInfo;
