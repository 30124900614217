import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import TimePicker from 'react-time-picker'
import { NotificationContainer } from 'react-notifications'
import { Button } from '@material-ui/core'
import Loader from '../../Components/Loader/loader'
import '../../sass/main.scss'
import 'react-responsive-modal/styles.css'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

class AddAgendaModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      title: '',
      description: '',
      scheduled_at: new Date(),
      time: moment().format('HH:mm'),
    }
  }

  componentDidMount() {
    const selectedAgenda = this.props.selectedAgenda || {}
    const scheduled_at = selectedAgenda.scheduled_at
      ? new Date(selectedAgenda.scheduled_at)
      : new Date()
    scheduled_at.setHours(0, 0, 0, 0) 
    this.setState({ ...selectedAgenda, scheduled_at })
  }

  handleLoading = () => {
    this.setState(({ loading }) => ({ loading: !loading }))
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { handleSubmit } = this.props
    if (typeof handleSubmit === 'function') {
      const { title, description, scheduled_at, time } = this.state
      const data = { title, description, scheduled_at, time }
      handleSubmit(data)
    }
  }

  onTimeChange = (time) => {
    this.setState({ time: time })
  }

  onChangeDate = (date) => {
    var temp = new Date(date)  
    this.setState({ scheduled_at: temp })
  }

  render() {
    const {
      loading,
      title,
      description,
      scheduled_at,
      initialDate,
      _id,
      time,
    } = this.state

    return (
      <div>
        <h2>{_id ? 'Edit Agenda' : 'Add Agenda'}</h2>
        <div className="rsvpEventfieldBox">
          <div className="formSection p-0">
            <form onSubmit={this.handleSubmit}>
              <div class="field-group fullWidthInput inputField">
                <label>Title</label>
                <input
                  type="text"
                  class="form-control"
                  name="title"
                  value={title}
                  onChange={this.onChange}
                  // maxLength={200}
                  required
                />
              </div>
              <div class="field-group FullWidthScheduled agendaSchedule inputField">
                <label>Scheduled</label>
                <DatePicker
                  // dateFormat="MMMM d, yyyy h:mm aa"

                  selected={scheduled_at}
                  onChange={this.onChangeDate}
                  startDate={scheduled_at}
                  minDate={initialDate}
                  shouldCloseOnSelect={true}
                />
                <TimePicker
                  onChange={this.onTimeChange}
                  value={time}
                  format="h:m a"
                  disableClock={true}
                />
              </div>
              <div class="field-group fullWidthInput agendaDscription inputField">
                <label>Description</label>
                <textarea
                  name="description"
                  type="text"
                  class="form-control"
                  value={description}
                  onChange={this.onChange}
                  required
                />
              </div>
              <div className="editProfileBtn submitForm justify-content-center">
                <Button type="submit" className="themeBlueButton">
                  {_id ? 'Save Changes' : 'Create Agenda'}
                </Button>
              </div>
            </form>
          </div>
        </div>
        <NotificationContainer />
        {loading ? <Loader /> : null}
      </div>
    )
  }
}

export default AddAgendaModal
