import React from 'react'
import { convertMsToHM } from './convertMsToHM'

export const getRemaningTime = (accessCodeExpiryDate) => {
    if (accessCodeExpiryDate !== null || accessCodeExpiryDate !== '' || accessCodeExpiryDate !== undefined) {
      var remainingHours = 0;
      var nowdate = new Date();
      var nownTime = nowdate.getTime();
      let expDate = new Date(accessCodeExpiryDate)
      let expTime = expDate.getTime();

      if (expTime - nownTime > 0) {
        let remainMillisecs = expTime - nownTime
        return remainingHours = convertMsToHM(remainMillisecs) + ' Hrs';
      } else {
        return remainingHours = 'Expired'
      }
    }
  }
