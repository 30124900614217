import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Actions } from '../../../Redux/Actions';
import ReactApexChart from 'react-apexcharts'

class ApexChart extends Component {
  constructor(props) {
    super(props)
    let max = Math.max(...this.props.totalSentArray)
    let max1 = Math.max(...this.props.deliveredArray)
    let max2 = Math.max(...this.props.rejectArray)
    let max3 = Math.max(...this.props.openArray)
    let max4 = Math.max(...this.props.clickArray)
    let fmax = Math.max(max, max1, max2, max3, max4)

    this.state = {
      series: [
        {
            name: 'Sent Emails',
            data: this.props.totalSentArray,
        },
        {
            name: 'Delivered Emails',
            data: this.props.deliveredArray,
        },
        {
            name: 'Rejected Emails',
            data: this.props.rejectArray,
        },
        {
          name: 'Open Emails',
          data: this.props.openArray,
        },
        {
          name: 'Clicked Emails',
          data: this.props.clickArray,
        },
      ],
      legend: {
        position: 'bottom',
      },
      options: {
        chart: {
          height: 350,
          type: 'area',
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#b5afa5', '#42f548', '#f54242', '#42d4f5', '#4269f5'],
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        title: {
          // text: 'Emails open and clicked',
          align: 'center',
          margin: 20,
          style: {
            fontSize: '20px',
            fontWeight: '400',
            fontFamily: 'Rubik',
            color: '#222025',
          },
        },
        xaxis: {
          type: 'string',
          categories: [
            'Today',
            'Last 7 days',
            'Last 30 days',
            'Last 60 days',
            'Last 90 days',
          ],
          title: {
            text: 'Days',
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
        yaxis: {
          title: {
            text: 'Count',
          },
          tickAmount: 4,
          min: 1,
          max: fmax,
          axisBorder: {
            show: true,
            color: 'rgb(243, 243, 243)',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    }
  }

  componentDidMount() {
    let max = Math.max(...this.props.totalSentArray)
    let max1 = Math.max(...this.props.deliveredArray)
    let max2 = Math.max(...this.props.rejectArray)
    let max3 = Math.max(...this.props.openArray)
    let max4 = Math.max(...this.props.clickArray)
    let fmax = Math.max(max, max1, max2, max3, max4)

    this.setState({
      series: [
        {
            name: 'Sent Emails',
            data: this.props.totalSentArray,
        },
        {
            name: 'Delivered Emails',
            data: this.props.deliveredArray,
        },
        {
            name: 'Rejected Emails',
            data: this.props.rejectArray,
        },
        {
          name: 'Open Emails',
          data: this.props.openArray,
        },
        {
          name: 'Clicked Emails',
          data: this.props.clickArray,
        },
      ],
      legend: {
        position: 'bottom',
      },
      options: {
        chart: {
          height: 350,
          type: 'area',
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#b5afa5', '#42f548', '#f54242', '#42d4f5', '#4269f5'],
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        title: {
          // text: 'Emails open and clicked',
          align: 'center',
          margin: 20,
          style: {
            fontSize: '20px',
            fontWeight: '400',
            fontFamily: 'Rubik',
            color: '#222025',
          },
        },
        xaxis: {
          type: 'string',
          categories: [
            'Today',
            'Last 7 days',
            'Last 30 days',
            'Last 60 days',
            'Last 90 days',
          ],
          title: {
            text: 'Days',
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
        yaxis: {
          title: {
            text: 'Count',
          },
          tickAmount: 4,
          min: 1,
          max: fmax,
          axisBorder: {
            show: true,
            color: 'rgb(243, 243, 243)',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    })
  }

  componentDidUpdate(prevProps) {
    if(this.props.users.languageStateForRedux !== prevProps.users.languageStateForRedux){
      {
        let max = Math.max(...this.props.totalSentArray)
        let max1 = Math.max(...this.props.deliveredArray)
        let max2 = Math.max(...this.props.rejectArray)
        let max3 = Math.max(...this.props.openArray)
        let max4 = Math.max(...this.props.clickArray)
        let fmax = Math.max(max, max1, max2, max3, max4)

        this.setState({
        series: [
            {
                name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.sentMail,
                data: this.props.totalSentArray,
            },
            {
                name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.deliveredMail,
                data: this.props.deliveredArray,
            },
            {
                name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.rejectedEmail,
                data: this.props.rejectArray,
            },
            {
                name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.openedEmails,
                data: this.props.openArray,
            },
            {
                name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.clickedEmail,
                data: this.props.clickArray,
            },
        ],
        legend: {
          position: 'bottom',
        },
        options: {
          chart: {
            height: 350,
            type: 'area',
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#b5afa5', '#42f548', '#f54242', '#42d4f5', '#4269f5'],
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          title: {
            // text: 'Emails open and clicked',
            align: 'center',
            margin: 20,
            style: {
              fontSize: '20px',
              fontWeight: '400',
              fontFamily: 'Rubik',
              color: '#222025',
            },
          },
          xaxis: {
            type: 'string',
            categories: [
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.today,
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last7,
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last30,
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last60,
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last90,
            ],
            title: {
              text:
              this.props.users.languageStateForRedux?.overviewTab?.days,
            }, 
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
          yaxis: {
            title: {
              text:
              this.props.users.languageStateForRedux?.overviewTab?.count,
            },
            tickAmount: 4,
            min: 1,
            max: fmax,
            axisBorder: {
              show: true,
              color: 'rgb(243, 243, 243)',
              offsetX: 0,
              offsetY: 0,
            },
          },
        }, 
      });
      }
    }
    if (
      prevProps.deliveredArray !== this.props.deliveredArray ||
        prevProps.rejectArray !== this.props.rejectArray ||
        prevProps.openArray !== this.props.openArray ||
        prevProps.clickArray !== this.props.clickArray
    ) {
        let max = Math.max(...this.props.totalSentArray)
        let max1 = Math.max(...this.props.deliveredArray)
        let max2 = Math.max(...this.props.rejectArray)
        let max3 = Math.max(...this.props.openArray)
        let max4 = Math.max(...this.props.clickArray)
        let fmax = Math.max(max, max1, max2, max3, max4)

        this.setState({
        series: [
          {
            name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.sentMail,
            data: this.props.totalSentArray,
        },
        {
            name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.deliveredMail,
            data: this.props.deliveredArray,
        },
        {
            name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.rejectedEmail,
            data: this.props.rejectArray,
        },
        {
            name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.openedEmails,
            data: this.props.openArray,
        },
        {
            name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.clickedEmail,
            data: this.props.clickArray,
        },
        ],
        legend: {
          position: 'bottom',
        },
        options: {
          chart: {
            height: 350,
            type: 'area',
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#b5afa5', '#42f548', '#f54242', '#42d4f5', '#4269f5'],
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          title: {
            // text: 'Emails open and clicked',
            align: 'center',
            margin: 20,
            style: {
              fontSize: '20px',
              fontWeight: '400',
              fontFamily: 'Rubik',
              color: '#222025',
            },
          },
          xaxis: {
            type: 'string',
            categories: [
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.today,
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last7,
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last30,
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last60,
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last90,
            ],
            title: {
              text:
              this.props.users.languageStateForRedux?.overviewTab?.days,
            }, 
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
          yaxis: {
            title: {
              text:
              this.props.users.languageStateForRedux?.overviewTab?.count,
            },
            tickAmount: 4,
            min: 1,
            max: fmax,
            axisBorder: {
              show: true,
              color: 'rgb(243, 243, 243)',
              offsetX: 0,
              offsetY: 0,
            },
          },
        }, 
      });
    }
  }
  
  

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={350}
        />
      </div>
    )
  }
}


const mapStateToProps = state => ({
  events: state.Events,
  users: state.Users,
});


export default connect(mapStateToProps)(ApexChart);


