import React, { Component } from "react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

export const theme = createTheme({

  palette: {
    primary: {
      main: "#5141E7",
      dark: "#000056",
      light: "#726F8E",
      background:
        "linear-gradient(135deg, rgba(135,224,253,1) 0%,rgba(83,203,241,1) 52%,rgba(5,171,224,1) 100%);",
      border: "rgba(153, 66, 134, 0.5);",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FF4170",
      light: "#ff4081",
      dark: "#c51162",
      contrastText: "#FFFFFF",
    },
    background: {
      light: "#EDECF5",
      ButtonBg: "#FAFAFA",
      contrastTextLight: "#BCBCBC",
    },



  }
});

theme.props = {
  MuiButton: {
    disableElevation: true
  }
};

theme.overrides = {
  MuiOutlinedInput: {
    root: {
      borderRadius: 7,
    },
    input: {
      padding: '12.5px 14px',
    },

  },

  MuiTooltip: {
    tooltip: {
      backgroundColor: '#000000',
      fontSize: '14px',
      fontWeight: '400',
      borderRadius: '5px',
      padding: '12px 24px',
    },
    arrow: {
      color: '#000000',
    },
  },

  MuiBackdrop: {
    root: {
      backgroundColor: "rgba(0, 0, 86, 0.3)"
    }
  },

};

const ThemeComponent = (UiComponent) => {
  class newComponent extends Component {
    render() {
      return (
        <ThemeProvider theme={theme}>
          <UiComponent {...this.props} />
        </ThemeProvider>
      );
    }
  }
  return newComponent;
};

export default ThemeComponent;
