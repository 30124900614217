import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  fetchAllOpportunityOpportunitiesRequest: ['data',],
  fetchAllOpportunityOpportunitiesSuccess: ['opportunities', 'totalCount', 'filterCount', 'contactsObj', 'accountsObj'],
  fetchAllOpportunityOpportunitiesError: ['message'],
  fetchOpportunityOpportunityRequest: ['data'],
  fetchOpportunityOpportunitySuccess: ['opportunity'],
  fetchOpportunityOpportunityError: ['message'],
  createOpportunityOpportunityRequest: ['data'],
  createOpportunityOpportunitySuccess: ['opportunity', 'opportunities', 'totalCount', 'filterCount', 'contactsObj', 'accountsObj'],
  createOpportunityOpportunityError: ['message'],
  updateOpportunityOpportunityRequest: ['data'],
  updateOpportunityOpportunitySuccess: ['opportunity', 'opportunities', 'totalCount', 'filterCount', 'contactsObj', 'accountsObj'],
  updateOpportunityOpportunityError: ['message'],
  deleteOpportunityOpportunityRequest: ['data'],
  deleteOpportunityOpportunitySuccess: ['opportunities', 'totalCount', 'filterCount', 'contactsObj', 'accountsObj'],
  deleteOpportunityOpportunityError: ['message'],
  updateMultipleOpportunityOpportunitiesRequest: ['data'],
  updateMultipleOpportunityOpportunitiesSuccess: ['opportunities', 'totalCount', 'filterCount', 'contactsObj', 'accountsObj'],
  updateMultipleOpportunityOpportunitiesError: ['message'],
  listTransactionEmailRequest: ['data'],
  listTransactionEmailSuccess: ['templates', 'message',],
  listTransactionEmailError: ['message'],
  updateTransactionEmailRequest: ['data'],
  updateTransactionEmailSuccess: ['message',],
  updateTransactionEmailError: ['message'],
  setIsSavingAsDraft: ['isSavingAsDraft'],
  assignContactListRequest: ['data',],
  assignContactListSuccess: ['unassignContacts','totalUnassignContact',],
  assignContactListError: ['message'],
});

export const Constants = Types;

export default Creators;
