import { Tooltip, withStyles, } from '@material-ui/core';

const styles = () => ({
    root: {
    },

    tooltip: {
        backgroundColor: 'transparent',
        padding: '0',
        maxWidth: 500,
        fontSize: 12,
      },

});
const CustomTooltip = ({ placement, title, children, classes, interactive, arrow }) => {
    return (
        <Tooltip
            classes={{ root: classes.root, tooltip: classes.tooltip }}
            placement={placement}
            title={title}
            interactive={interactive}
            arrow={arrow}
            >
            {children}
        </Tooltip>
    );
};

export default withStyles(styles)(CustomTooltip);