import React, { useState, ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import { ContextUrl } from 'Redux/Reducers/aiChatbot.reducer';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

type URLProps = {
  contextUrls: ContextUrl[];
  handleAddNewContextUrl: (url: string) => void;
  handleDeleteExistingUrl: (i: number) => void;
  handleDeleteAllContextUrls: () => void;
};

type ErrorTypes = {
  urlString?: string;
};

export default function URL({
  contextUrls,
  handleAddNewContextUrl,
  handleDeleteExistingUrl,
  handleDeleteAllContextUrls,
}: URLProps) {
  const [urlString, setUrlString] = useState<string>('');
  const [errors, setErrors] = useState<ErrorTypes>({} as ErrorTypes);
  const handleUrlStringChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUrlString(e.target.value);
  };

  const [isValidAndReadyToAddNext, setIsValidAndReadyToAddNext] =
    useState(false);
  const [addNewInputField, setAddNewInputField] = useState(false);

  const handleAddButton = () => {
    if (isValid()) {
      setIsValidAndReadyToAddNext(true);
      setAddNewInputField(false);
      handleAddNewContextUrl(urlString);
      setUrlString('');
    }
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      if (isValid()) {
        handleAddNewContextUrl(urlString);
        setUrlString('');
      }
    }
  };

  const handleDeleteButton = (i: number) => {
    handleDeleteExistingUrl(i);
  };

  const handleDeleteAllButton = () => {
    handleDeleteAllContextUrls();
  };

  const isValid = () => {
    let isValid = true;
    let errorsObj: ErrorTypes = {} as ErrorTypes;
    console.log('URL ', urlString);
    if (
      !urlString ||
      urlString.trim().length === 0 ||
      urlString === undefined ||
      urlString === ''
    ) {
      isValid = false;
      errorsObj.urlString = 'URL cannot be empty';
    } else {
      if (!isValidURL(urlString)) {
        isValid = false;
        errorsObj.urlString = 'Please provide a valid URL';
      }
    }

    setErrors(errorsObj);

    const contextUrlsStrings = contextUrls?.map((url) => url.url);
    if (isValid) {
      const isDuplicate = isDuplicateUrl(urlString, contextUrlsStrings);
      if (isDuplicate) {
        errorsObj.urlString = 'This url already exists';
        isValid = false;
        return;
      }
    }

    return isValid;
  };

  const isValidURL = (str: string) => {
    const urlPattern =
      /^(https?:\/\/)(([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,}))(\/.*)?$/;
    return urlPattern.test(str);
  };

  const normalizeUrl = (url: string) => {
    return url.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\/$/, '');
  };

  const isDuplicateUrl = (urlToCheck: string, urlsArray: string[]) => {
    const normalizedUrl = normalizeUrl(urlToCheck);
    return urlsArray.some((url) => {
      return normalizeUrl(url) === normalizedUrl;
    });
  };

  const handleAddNewInputField = () => {
    setAddNewInputField(true);
    setIsValidAndReadyToAddNext(false);
  };

  const hanldeRemoveInputField = () => {
    setAddNewInputField(false);
    setErrors({});
    setUrlString('');
  };

  return (
    <Box className="tab-content">
      <Stack
        className="tab-title"
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box>URL</Box>

        <Stack direction={'row'} spacing={1}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => handleAddNewInputField()}
          >
            Add Url
          </Button>
          {contextUrls?.length > 1 && (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleDeleteAllButton}
            >
              Delete All
            </Button>
          )}
        </Stack>
      </Stack>
      <Stack spacing={0.5}>
        {contextUrls?.length > 0 &&
          contextUrls?.map((url, i) => {
            return (
              <Stack
                width={'100%'}
                alignItems={'center'}
                direction={'row'}
                spacing={1}
                key={i}
              >
                <Box component="div" className="inputField" width={'100%'}>
                  <input
                    type="url"
                    className="form-control small"
                    placeholder="Paste here"
                    value={url.url}
                    disabled={true}
                  />
                </Box>

                <IconButton size="small" onClick={() => handleDeleteButton(i)}>
                  {' '}
                  <RemoveCircleIcon color="secondary" />
                </IconButton>
              </Stack>
            );
          })}
        {addNewInputField ? (
          <Stack width={'100%'}>
            <Stack
              width={'100%'}
              alignItems={'center'}
              direction={'row'}
              spacing={1}
            >
              <Box component="div" className="inputField" width={'100%'}>
                <input
                  type="url"
                  className="form-control small"
                  placeholder="Enter or Paste URL, then press Enter"
                  onChange={handleUrlStringChange}
                  value={urlString}
                  onKeyDown={handleKeyDown}
                />
              </Box>
              {!isValidAndReadyToAddNext && (
                <>
                  <IconButton size="small" disableRipple>
                    <CheckIcon
                      style={{ color: '#1b5e20' }}
                      onClick={() => {
                        handleAddButton();
                      }}
                    />
                  </IconButton>
                  <IconButton
                    size="small"
                    disableRipple
                    onClick={hanldeRemoveInputField}
                  >
                    <CloseIcon color="secondary" />
                  </IconButton>
                </>
              )}
            </Stack>
            <span className="error_mesage"> {errors?.urlString} </span>
          </Stack>
        ) : (
          contextUrls?.length === 0 && (
            <Box textAlign={'center'}>
              Please provide the URL to train the AI
            </Box>
          )
        )}
      </Stack>
    </Box>
  );
}
