import { trackingId } from "constants/constants";
const reactppGamificationUrl = process.env.REACT_APP_GAMIFICATION_URL;
export const getPageScript = (customeJs, eventId, pageName, attendeeData) => {
  let lv_page_script = customeJs;

  lv_page_script += `var varMomentScript = document.createElement('script'); 
  varMomentScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.1/moment.min.js'; 
      var momentScriptTag = document.getElementsByTagName('script')[0];
      momentScriptTag.parentNode.insertBefore(varMomentScript, momentScriptTag); 
      
      var varMomentTZScript = document.createElement('script'); 
      varMomentTZScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/moment-timezone/0.5.36/moment-timezone-with-data.min.js'; 
      var momentTZScriptTag = document.getElementsByTagName('script')[0];
      momentTZScriptTag.parentNode.insertBefore(varMomentTZScript, momentTZScriptTag);
      `;
  lv_page_script += ` var profilevar = document.getElementById("myProfileClass");
      if(profilevar){
        profilevar.style.cursor = "pointer"; 
        profilevar.style.float = "right"; 
        profilevar.style.marginRight = "15px"; 
        profilevar.style.marginTop = "10px"; 
      } 
      /*Code For YouTube Custom Tool Start*/  
      var ytctThumbnalilTillDate = document.getElementById('ytctThumbnalilTillDate') 
      if(ytctThumbnalilTillDate){
        var thumbnaliltime = ytctThumbnalilTillDate.value;
        var thumbDateTime = new Date(thumbnaliltime).getTime();
        var nowDateTime = new Date().getTime();
        var tillTime = thumbDateTime - nowDateTime; 
        if(tillTime > 0) {          
          setTimeout(()=>{  
            var currentTime  = new Date().getTime();
            if(currentTime > thumbDateTime){
              let iframeId = document.getElementById('youtube-iframe-tool') 
              if(iframeId){ 
                iframeId.style.display="block"; 
                var getyouTubeVideoId = document.getElementById('youTubeVideoId')?.value; 
                var getytcLoopSetting = document.getElementById('ytcLoopSetting')?.value;
                var getytcMuteUnmuteSetting = document.getElementById('ytcMuteUnmuteSetting')?.value;
                var getytcControlsSetting = document.getElementById('ytcControlsSetting')?.value;
                var getyouTubeStartingFrame = document.getElementById('youTubeStartingFrame')?.value;
                var getytcAutoplayHidden = document.getElementById('ytcAutoplayHidden')?.value; 
               if(getyouTubeVideoId && getytcLoopSetting && getytcMuteUnmuteSetting && getytcControlsSetting && getyouTubeStartingFrame && getytcAutoplayHidden){ 
                 if(iframeId){  
                  iframeId.src = "https://www.youtube.com/embed/?playlist="+getyouTubeVideoId+"&enablejsapi=1&rel=0&modestbranding=0&loop="+getytcLoopSetting+"&autohide=1&mute="+getytcMuteUnmuteSetting+"&showinfo=1&autoplay="+getytcAutoplayHidden+"&controls="+getytcControlsSetting+"&start="+getyouTubeStartingFrame 
                } 
               }  
                var ytcThumbId = document.getElementById('ytcThumbId')
                if(ytcThumbId){
                  ytcThumbId.style.display="none";
                }
              }
            }
          },tillTime); 
        } 
      }
      
      
      var tag = document.createElement('script');
      tag.id = 'iframe-demo';
      tag.src = 'https://www.youtube.com/iframe_api';
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);  
      var counterYtctVideo = 1;
        var player;
        function onYouTubeIframeAPIReady() { 
          setTimeout(()=>{  
            player = new YT.Player('youtube-iframe-tool', { 
                events: {
                  'onReady': onPlayerReady,
                  'onStateChange': onPlayerStateChange
                }
            });
          },4000)
        } 

       async function onPlayerReady(event) { 
          let projectIdYT =  document.getElementById('projectId')
          let onOpenHidden =  document.getElementById('onOpenHidden')
          if(onOpenHidden){
            var onOpenHiddenVal =  onOpenHidden.value
          }  
          let ytPageIdHtml =  document.getElementById('ytPageId')
          if(ytPageIdHtml){
            var ytPageId = ytPageIdHtml.value;
          } 
         attendee = JSON.parse(localStorage.getItem('attendee_details')) 
          if(attendee?.token){
            if(projectIdYT){
              var projectIdYTVal =  projectIdYT.value;
             
              let ytData1 = {'projectId': projectIdYTVal, 'challengeId': onOpenHiddenVal, 'token': 'bearer '+ attendee.token, source:'Page', pageId:ytPageId} 
               let response = await fetch('${reactppGamificationUrl}', {
                 method: 'POST',
                 body: JSON.stringify(ytData1), 
                 headers: {
                  'Content-Type': 'application/json', 
                  token: 'bearer '+ attendee.token,
                 }
                }) 
            }
          } 
        }
   
        async function onPlayerStateChange(event) { 
          var ytctThumbnalilTillDateN = document.getElementById('ytctThumbnalilTillDate') 
          if(ytctThumbnalilTillDateN){
            var thumbnaliltimeN = ytctThumbnalilTillDateN.value;
            var thumbDateTimeN = new Date(thumbnaliltimeN).getTime();
            var nowDateTimeN = new Date().getTime();
            var tillTimeN = thumbDateTimeN - nowDateTimeN;  
              setTimeout(()=>{ 
                changeBorderColor(event.data); 
              },tillTimeN) 
          }else{
            changeBorderColor(event.data); 
          } 
        } 
        async function changeBorderColor(playerStatus) {  
          var color; 
          if (playerStatus == -1) { 
            color = "#37474F"; // unstarted = gray
          } 
          else if (playerStatus == 0) { 
            var ytcShowEndScrImg = document.getElementById('ytcShowEndScrImg');
            if (ytcShowEndScrImg) {
                var isEndscreenOn = ytcShowEndScrImg.value;
                if (isEndscreenOn) { 
                  /*   var ytctEndScreenTillDate1 = document.getElementById('ytctEndScreenTillDate')
                    if (ytctEndScreenTillDate1) {
                        var endScreentime1 = ytctEndScreenTillDate1.value;
                        var endScreenDateTime1 = new Date(endScreentime1).getTime();
                        var currentTime11 = new Date().getTime();
                        var tillEndTime11 = endScreenDateTime1 - currentTime11; 
                        if (tillEndTime11 > 0) {
                            let iframeId2 = document.getElementById('youtube-iframe-tool')
                            if (iframeId2 && isEndscreenOn === 'true') { 
                                iframeId2.style.display = "none";
                                var ytcEndScreenImage = document.getElementById('ytcEndScreenImage')
                                if (ytcEndScreenImage) {
                                    ytcEndScreenImage.style.display = "inline-block";
                                }
                            }
                        }
                    } */
                }
                color = "#FFFF00"; // ended = yellow 
                let projectIdYT = document.getElementById('projectId')
                let onCompleteHidden = document.getElementById('onCompleteHidden')
                if (onCompleteHidden) {
                    var onCompleteHiddenVal = onCompleteHidden.value
                    attendee = JSON.parse(localStorage.getItem('attendee_details'))
                    let ytPageIdHtml =  document.getElementById('ytPageId')
                    if(ytPageIdHtml){
                      var ytPageId = ytPageIdHtml.value;
                    }
                    if (attendee?.token) {
                        if (projectIdYT) {
                            var projectIdYTVal = projectIdYT.value
                            let ytData = { 'projectId': projectIdYTVal, 'challengeId': onCompleteHiddenVal, 'token': 'bearer ' + attendee.token, source:'Page', pageId:ytPageId }
                            const response = await fetch('${reactppGamificationUrl}', {
                                method: 'POST',
                                body: JSON.stringify(ytData), 
                                headers: {
                                    'Content-Type': 'application/json',
                                    token: 'bearer ' + attendee.token,
                                }
                            })
                        }
                    }
                }
            }
          } else if (playerStatus == 1) {
           
            color = "#33691E"; // playing = green  
            let projectIdYT =  document.getElementById('projectId')
            let onPlayHidden =  document.getElementById('onPlayHidden')
            if(onPlayHidden){
             var onPlayHiddenVal =  onPlayHidden.value
            }
            
            if(onPlayHiddenVal !=="choose challenges" && counterYtctVideo == 1){ 
              counterYtctVideo++;  
              attendee = JSON.parse(localStorage.getItem('attendee_details')) 
              if(attendee?.token){
                if(projectIdYT){
                  let ytPageIdHtml =  document.getElementById('ytPageId')
                  if(ytPageIdHtml){
                    var ytPageId = ytPageIdHtml.value;
                  }
                  var projectIdYTVal =  projectIdYT.value 
                  let ytData = {'projectId': projectIdYTVal, 'challengeId': onPlayHiddenVal,'token': 'bearer '+ attendee.token, source:'Page', pageId:ytPageId} 
  
                  const response = await fetch('${reactppGamificationUrl}', {
                    method: 'POST',
                    body: JSON.stringify(ytData),  
                    headers: {
                      'Content-Type': 'application/json', 
                      token: 'bearer '+ attendee.token,
                    }
                  })  
                }
              }
            }  
          } else if (playerStatus == 2) { 
            color = "#DD2C00"; // paused = red
          } else if (playerStatus == 3) { 
            color = "#AA00FF"; // buffering = purple
          } else if (playerStatus == 5) { 
            color = "#FF6DOO"; // video cued = orange
          } 
         
        } 
        
        async function stopVideo() { 
          player?.stopVideo();
        }  
         
        var ytcShowEndScrImg = document.getElementById('ytcShowEndScrImg')
        var ytctEndScreenTillDate = document.getElementById('ytctEndScreenTillDate')
        if(ytcShowEndScrImg && ytcShowEndScrImg.value == "true" && ytctEndScreenTillDate && ytctEndScreenTillDate.value){

            var endScreentime = ytctEndScreenTillDate.value;
            var endScreenDateTime = new Date(endScreentime).getTime();
            var currentTime = new Date().getTime();
            var tillEndTime = endScreenDateTime - currentTime;
            if (currentTime > endScreenDateTime) {
                var iframeId = document.getElementById('youtube-iframe-tool')
                if (iframeId) {
                    iframeId.style.display = "none";
                    var ytcThumbIdhtml = document.getElementById('ytcThumbId')
                    let ytcEndScreenImage = document.getElementById('ytcEndScreenImage')
                    ytcThumbIdhtml.style.display = "none";
                    if (ytcEndScreenImage) {
                        ytcEndScreenImage.style.display = "inline-block";
                        stopVideo();
                    }
                }
            } else {  
                setTimeout(() => { 
                    var iframeIdhtml = document.getElementById('youtube-iframe-tool')
                    if (iframeIdhtml) {
                        iframeIdhtml.style.display = "none";
                        var ytcThumbIdhtml = document.getElementById('ytcThumbId')
                        if (ytcThumbIdhtml) {
                            ytcThumbIdhtml.style.display = "none";
                        }
                        let ytcEndScreenImage = document.getElementById('ytcEndScreenImage')
                        if (ytcEndScreenImage) {
                            ytcEndScreenImage.style.display = "inline-block";
                            setTimeout(stopVideo, 1); 
                        }
                    }
                }, tillEndTime);
            }
        } else { 
          var ytctTTillDate = document.getElementById('ytctThumbnalilTillDate') 
          if (ytctTTillDate && ytctTTillDate.value) { 
              var ttThumbnalilVal = ytctTTillDate.value;
              var thumbTillDateTime = new Date(ttThumbnalilVal).getTime();
              var nowDateTimeS = new Date().getTime();
              if (nowDateTimeS > thumbTillDateTime) { 
                  var iframeIdhtmlq = document.getElementById('youtube-iframe-tool')
                  if (iframeIdhtmlq) {
                    iframeIdhtmlq.style.display = "block"; 
                     var getyouTubeVideoId = document.getElementById('youTubeVideoId')?.value; 
                     var getytcLoopSetting = document.getElementById('ytcLoopSetting')?.value;
                     var getytcMuteUnmuteSetting = document.getElementById('ytcMuteUnmuteSetting')?.value;
                     var getytcControlsSetting = document.getElementById('ytcControlsSetting')?.value;
                     var getyouTubeStartingFrame = document.getElementById('youTubeStartingFrame')?.value;
                     var getytcAutoplayHidden = document.getElementById('ytcAutoplayHidden')?.value; 
                    if(getyouTubeVideoId && getytcLoopSetting && getytcMuteUnmuteSetting && getytcControlsSetting && getyouTubeStartingFrame && getytcAutoplayHidden){ 
                      if(iframeIdhtmlq){  
                          iframeIdhtmlq.src = "https://www.youtube.com/embed/?playlist="+getyouTubeVideoId+"&enablejsapi=1&rel=0&modestbranding=0&loop="+getytcLoopSetting+"&autohide=1&mute="+getytcMuteUnmuteSetting+"&showinfo=1&autoplay="+getytcAutoplayHidden+"&controls="+getytcControlsSetting+"&start="+getyouTubeStartingFrame 
                     } 
                    } 
                    var ytcThumbId = document.getElementById('ytcThumbId')
                    if(ytcThumbId){
                      ytcThumbId.style.display="none";
                    }
                  }
              } 

          }
        } 

        /* Code For vimeo Custom Tool to check thumbnail image */  
        var vimeoShowThumbnail = document.getElementById('vimeoShowThumbnail')  
        if(vimeoShowThumbnail && vimeoShowThumbnail.value == 'true'){ 
          var vimeoThumbnalilTillDate = document.getElementById('vimeoThumbnalilTillDate')  
          var thumbnalTilltimeVimeo = vimeoThumbnalilTillDate.value;
          var thumbDateTimeVimeo = new Date(thumbnalTilltimeVimeo).getTime();
          var nowDateTimeVimeo = new Date().getTime();
          var tillTimeVimeo = thumbDateTimeVimeo - nowDateTimeVimeo; 
          if(tillTimeVimeo > 0) {          
            setTimeout(()=>{  
              var currentTimeVimeo  = new Date().getTime();
              if(currentTimeVimeo > thumbDateTimeVimeo){
                let iframeVimeoId = document.getElementById('vimeo-iframe-tool') 
                if(iframeVimeoId){ 
                  var getvimeoVideoId = document.getElementById('vimeoVideoId')?.value; 
                  var getvimeoLoopSetting = document.getElementById('vimeoLoopSetting')?.value;
                  var getvimeoMuteUnmuteSetting = document.getElementById('vimeoMuteUnmuteSetting')?.value;
                  var getvimeoControlsSetting = document.getElementById('vimeoControlsSetting')?.value;
                  var getvimeoStartingFrame = document.getElementById('vimeoStartingFrame')?.value;
                  var getvimeoAutoplayHidden = document.getElementById('vimeoAutoplayHidden')?.value; 
                  if(getvimeoVideoId && getvimeoLoopSetting && getvimeoMuteUnmuteSetting && getvimeoControlsSetting && getvimeoAutoplayHidden && getvimeoAutoplayHidden){ 
                      iframeVimeoId.src = "https://player.vimeo.com/video/"+getvimeoVideoId+"?&autoplay="+getvimeoAutoplayHidden+"&muted="+getvimeoMuteUnmuteSetting+"&loop="+getvimeoLoopSetting+"&controls="+getvimeoControlsSetting+"&#t="+getvimeoStartingFrame 
                  }  
                  iframeVimeoId.style.display="block"
                  var vimeoCtThumbId = document.getElementById('vimeoCtThumbId')
                  if(vimeoCtThumbId){
                    vimeoCtThumbId.style.display="none";
                  }
                }
              }
            },tillTimeVimeo); 
          }  
        }`;

  /* custom add to calendar code start here */
  lv_page_script += `addToCalendarByWidget = (e, lielement) => { 
          const parentDiv = e.target.closest('.addCalendarEvent');
          const titleDiv = parentDiv.querySelector('.title');
          const descriptionDiv = parentDiv.querySelector('.description');
          const locationDiv = parentDiv.querySelector('.location');
          const startDateDiv = parentDiv.querySelector('.start');
          const endDateDiv = parentDiv.querySelector('.end');
          const timezoneDiv = parentDiv.querySelector('.timezone');
  
          const titleVal = titleDiv.textContent;
          const descVal = descriptionDiv.textContent;
          const locationVal = locationDiv.textContent;
          const startDate = startDateDiv.textContent;
          const endDate = endDateDiv.textContent;
          const timezoneVal = timezoneDiv.textContent; 
          let eventDetails = {
              "title": titleVal,
              "description": descVal,
              "location": locationVal,
              "startDate": new Date(startDate),
              "endDate": new Date(endDate),
              "timezone": timezoneVal,
          } 
         
          if (lielement === "googleCalendar") {
            const calendarUrl = this.generateCalendarUrl(eventDetails, lielement);
            window.open(calendarUrl, '_blank');
          } else if (lielement === "outlookComleCalendar") {
            const calendarUrl = this.generateCalendarUrl(eventDetails, lielement);
            window.open(calendarUrl, '_blank');
          }else if (lielement === "appleleCalendar") {
            const calendarUrl = this.generateCalendarUrl(eventDetails, lielement); 
          }else if (lielement === "outlookCalendar") {
            const calendarUrl = this.generateCalendarUrl(eventDetails, lielement); 
          }else if (lielement === "yahooCalendar") {
            const calendarUrl = this.generateCalendarUrl(eventDetails, lielement);
            window.open(calendarUrl, '_blank'); 
          }
          
         /*  else if (lielement === "officeCalendar") {
            const calendarUrl = this.generateCalendarUrl(eventDetails, lielement);
            window.open(calendarUrl, '_blank');
          } */
          
          else {
            console.log(lielement + " Not implemented yet!");
          }
      } 

      outlookDateWithDesiredTimeZone = (date, timeZone) => { 
        const formattedDate = moment.tz(date, timeZone).format('YYYY-MM-DDTHH:mm'); 
        return formattedDate
      };

      generateCalendarUrl = (event, calendarType) => {
        const start = this.formatDate(event.startDate);
        const end = this.formatDate(event.endDate); 
        if (calendarType === "googleCalendar") {
          const googleCalendarUrl = "https://www.google.com/calendar/render?action=TEMPLATE&text=" + encodeURIComponent(event.title) + "&dates=" + start + "/" + end + "&details=" + encodeURIComponent(event.description) + "&location=" + encodeURIComponent(event.location);
          return googleCalendarUrl;
        } else if (calendarType === "outlookComleCalendar") {
          let outlookStartDate = outlookDateWithDesiredTimeZone(event.startDate, event.timezone)  
          let outlookEndDate = outlookDateWithDesiredTimeZone(event.endDate, event.timezone) 
          const outlookURL = "https://outlook.office.com/calendar/action/compose?subject=" + encodeURIComponent(event.title) + "&startdt="+ outlookStartDate +"&enddt="+ outlookEndDate +"&location="+ encodeURIComponent(event.location) + "&body="+ encodeURIComponent(event.description)+ "&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent";
          return outlookURL;
        }else if (calendarType === "appleleCalendar") {
          const outlookURL = this.downloadICS(event); 
          return outlookURL; 
        }else if (calendarType === "outlookCalendar") {
          const outlookURL = this.downloadICS(event); 
          return outlookURL; 
        } else if (calendarType === "yahooCalendar") {
          const yahooCal = "https://calendar.yahoo.com/?v=60&view=d&type=20&title=" + encodeURIComponent(event.title) + "&desc= "+ encodeURIComponent(event.description) + "&st=" + encodeURIComponent(start) + "&et=" + encodeURIComponent(end) + "&in_loc=" + encodeURIComponent(event.location);
          return yahooCal;
        }
        
        /* else if (calendarType === "officeCalendar") {
          const office365 = "https://outlook.office.com/calendar/action/compose?subject=" + encodeURIComponent(event.title) + "&body="+encodeURIComponent(event.description)+"&startdt="+encodeURIComponent(start)+"&enddt="+encodeURIComponent(end)+"&location="+encodeURIComponent(event.location)+"&path=/calendar/view/WorkWeek&rru=addevent";
          return office365;
        } */
      }
  
      formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
          const day = String(date.getDate()).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = String(date.getSeconds()).padStart(2, '0');
          let vales = year+month+day+"T"+hours+minutes+seconds; 
          return vales
      } 

      generateICS = (event) => { 
        const icsContent = "BEGIN:VCALENDAR" +
            "VERSION:2.0" +
            "PRODID:-//blue print//illumeetxp//EN" +
            "BEGIN:VEVENT" +
            "UID:" + Date.now() + "@blueprint.com" +
            "DTSTAMP:" + formatDate(new Date()) + "" +
            "DTSTART:" + formatDate(event.startDate) + 
            "DTEND:" + formatDate(event.endDate) + 
            "SUMMARY:" + event.title + 
            "DESCRIPTION:" + (event.description || "") +
            "LOCATION:" + (event.location || "") + 
            "METHOD:REQUEST" +
            "END:VEVENT" +
            "END:VCALENDAR"; 
        return icsContent;
    }; 

      downloadICS = (event) => {
        // Generate the ICS content
        const icsContent = generateICS(event); 
        // Create a Blob with the ICS content and specify the Content-Type header
        const blob = new Blob([icsContent], { type: 'text/calendar' }); 
        // Create a URL for the Blob
        const url = URL.createObjectURL(blob); 
        // Create a link element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'event.ics'; // Specify the filename
        document.body.appendChild(a);
      
        // Click the link to trigger the download
        a.click();
      
        // Cleanup
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } 
    setTimeout(() => { 
        // Close options if user clicks outside the button or options
        document.addEventListener('click', function (event) {
          var buttons = document.getElementsByClassName('addToCalendarButton');
          var options = document.getElementsByClassName('calendar-options');
          for (var i = 0; i < buttons.length; i++) {
            if (event.target !== buttons[i] && event.target.parentNode !== options[i]) {
              options[i].classList.remove('show');
            }
          }
        });  
      let addToCalendar =  document.querySelectorAll(".addToCalendar"); 
      addToCalendar.forEach((div) => { 
        div.addEventListener('click', function () { 
          const ul = this.querySelector('.calendar-options'); 
          ul.classList.toggle('show'); 
          ul.querySelectorAll('li').forEach(function (li) {
            li.addEventListener('click', function (e) { 
               addToCalendarByWidget(e, li.className)
            }); 
        });
        });  
      }); 
    },100) 
    `;
  /* custom add to calendar code end here */
  /* vimeo code for fronted started */
  lv_page_script =
    lv_page_script +
    ` var tagVimeo = document.createElement('script');
      tagVimeo.id = 'scr-vimeo-iframe-tool';
      tagVimeo.src = 'https://player.vimeo.com/api/player.js'; 
      var vimeoScriptTag = document.getElementsByTagName('script')[0];
      vimeoScriptTag.parentNode.insertBefore(tagVimeo, vimeoScriptTag);  
      setTimeout(() => { 
      var counterVimeoVideo = 1;
      var vimIframe = document.getElementById('vimeo-iframe-tool');  
      if(vimIframe){  
          var vimPlayer = new Vimeo.Player(vimIframe);  
            vimPlayer.ready().then(async function () {  
              let projectIdVT =  document.getElementById('projectId') 
              let onOpenHiddenVimeo =  document.getElementById('onOpenHiddenVimeo') 
              if(onOpenHiddenVimeo){
                  var onOpenHiddenVimeoVal =  onOpenHiddenVimeo.value
              }  
              let vtPageIdHtml =  document.getElementById('vimeoPageId')
              if(vtPageIdHtml){
                  var vtPageId = vtPageIdHtml.value;
              }  
              if(onOpenHiddenVimeoVal !=="choose challenges"){ 
                let attendeeVim = JSON.parse(localStorage.getItem('attendee_details')) 
                if(attendeeVim?.token){
                  if(projectIdVT){
                    var projectIdVTVal =  projectIdVT.value; 
                    let dataVimeo = {'projectId': projectIdVTVal, 'challengeId': onOpenHiddenVimeoVal, 'token': 'bearer '+ attendeeVim.token, source:'Page', pageId:vtPageId} 
                    let responseVimeo = await fetch('${reactppGamificationUrl}', {
                      method: 'POST', 
                      body: JSON.stringify(dataVimeo),  
                        headers: {
                          'Content-Type': 'application/json', 
                          token: 'bearer '+ attendeeVim.token,
                        }
                      }) 
                  }
                } 
              }
            
            }); 
            vimPlayer.on('play', async function () { 
                let projectIdVT =  document.getElementById('projectId') 
                let onPlayHiddenVimeo =  document.getElementById('onPlayHiddenVimeo') 
                if(onPlayHiddenVimeo){
                    var onPlayHiddenVimeoVal =  onPlayHiddenVimeo.value
                }  
                let vtPageIdHtml =  document.getElementById('vimeoPageId')
                if(vtPageIdHtml){
                    var vtPageId = vtPageIdHtml.value;
                }  
                if(onPlayHiddenVimeoVal !=="choose challenges" && counterVimeoVideo == 1){ 
                 counterVimeoVideo++; 
                  let attendeeVim = JSON.parse(localStorage.getItem('attendee_details')) 
                  if(attendeeVim?.token){ 
                    if(projectIdVT){
                      var projectIdVTVal =  projectIdVT.value; 
                      let vtData1 = {'projectId': projectIdVTVal, 'challengeId': onPlayHiddenVimeoVal, 'token': 'bearer '+ attendeeVim.token, source:'Page', pageId:vtPageId} 
                      let response = await fetch('${reactppGamificationUrl}', {
                        method: 'POST',
                        body: JSON.stringify(vtData1),   
                        headers: {
                          'Content-Type': 'application/json', 
                          token: 'bearer '+ attendeeVim.token,
                        }
                        }) 
                    }
                  } 
                } 
            });  

            vimPlayer.on('ended', async function (data) { 
                let projectIdVT =  document.getElementById('projectId') 
                let onCompleteHiddenVimeo =  document.getElementById('onCompleteHiddenVimeo') 
                if(onCompleteHiddenVimeo){
                    var onCompleteHiddenVimeoVal =  onCompleteHiddenVimeo.value
                }  
                let vtPageIdHtml =  document.getElementById('vimeoPageId')
                if(vtPageIdHtml){
                    var vtPageId = vtPageIdHtml.value;
                } 
                if(onCompleteHiddenVimeoVal !=="choose challenges"){
                  let attendeeVim = JSON.parse(localStorage.getItem('attendee_details')) 
                  if(attendeeVim?.token){
                    if(projectIdVT){ 
                      var projectIdVTVal =  projectIdVT.value; 
                      let vtData1 = {'projectId': projectIdVTVal, 'challengeId': onCompleteHiddenVimeoVal, 'token': 'bearer '+ attendeeVim.token, source:'Page', pageId:vtPageId} 
                      let response = await fetch('${reactppGamificationUrl}', {
                        method: 'POST',
                        body: JSON.stringify(vtData1),   
                        headers: {
                          'Content-Type': 'application/json', 
                          token: 'bearer '+ attendeeVim.token,
                        }
                        }) 
                    }
                  }
                }  
            }); 
        }  
      },5000)  
      var stopVimeoVideo = () => { 
            let iframeHtml = document.getElementById('vimeo-iframe-tool')  
            if(iframeHtml){
              const iframeSrc = iframeHtml.src;
              iframeHtml.src = '';
            } 
      }; 
      var vimeoShowEndScrImg = document.getElementById('vimeoShowEndScrImg');
        var vimeoEndScreenTillDate = document.getElementById('vimeoEndScreenTillDate'); 
        if(vimeoShowEndScrImg && vimeoShowEndScrImg.value == "true" && vimeoEndScreenTillDate && vimeoEndScreenTillDate.value){ 
            var endScreentimeVimeo = vimeoEndScreenTillDate.value;
            var endScreenDateTimeVimeo = new Date(endScreentimeVimeo).getTime();
            var currentTimeVm = new Date().getTime();
            var tillEndTimeVm = endScreenDateTimeVimeo - currentTimeVm; 
            if (currentTimeVm > endScreenDateTimeVimeo) {  
                let iframeIdvm = document.getElementById('vimeo-iframe-tool') 
                if (iframeIdvm) {
                    iframeIdvm.style.display = "none";
                      let vimeoCtThumbIdhtml = document.getElementById('vimeoCtThumbId')
                      if(vimeoCtThumbIdhtml){
                          vimeoCtThumbIdhtml.style.display = "none";
                      } 
                      stopVimeoVideo(); 
                    let vimeoEndScreenImage = document.getElementById('vimeoEndScreenImage')
                    if (vimeoEndScreenImage) {
                        vimeoEndScreenImage.style.display = "inline-block";
                      
                    }
                }
            } else { 
                setTimeout(() => {
                    var iframeIdvimhtml = document.getElementById('vimeo-iframe-tool')
                    if (iframeIdvimhtml) {
                        iframeIdvimhtml.style.display = "none";
                        let vimeoCtThumbIdhtml = document.getElementById('vimeoCtThumbId')
                        if (vimeoCtThumbIdhtml) {
                           vimeoCtThumbIdhtml.style.display = "none";
                        } 
                        let vimeoEndScreenImage = document.getElementById('vimeoEndScreenImage')
                        if (vimeoEndScreenImage) {
                            vimeoEndScreenImage.style.display = "inline-block";
                            setTimeout(stopVimeoVideo , 1);
                        } 
                    }
                }, tillEndTimeVm);
            }
        } else {  
          var vimeoShowThumbnail = document.getElementById('vimeoShowThumbnail') 
          var vimeoThumbnalilTillDate = document.getElementById('vimeoThumbnalilTillDate')
           if (vimeoShowThumbnail && vimeoShowThumbnail.value=='true'&& vimeoThumbnalilTillDate && vimeoThumbnalilTillDate.value) {
              var vvtThumbnalilVal = vimeoThumbnalilTillDate.value;
              var thumbTillDateTimevv = new Date(vvtThumbnalilVal).getTime();
              var nowDateTimeSvv = new Date().getTime();
              if (nowDateTimeSvv > thumbTillDateTimevv) {
                  var iframeIdhtmlvvv = document.getElementById('vimeo-iframe-tool')
                  if (iframeIdhtmlvvv) {  
                    var getvimeoVideoId = document.getElementById('vimeoVideoId')?.value; 
                    var getvimeoLoopSetting = document.getElementById('vimeoLoopSetting')?.value;
                    var getvimeoMuteUnmuteSetting = document.getElementById('vimeoMuteUnmuteSetting')?.value;
                    var getvimeoControlsSetting = document.getElementById('vimeoControlsSetting')?.value;
                    var getvimeoStartingFrame = document.getElementById('vimeoStartingFrame')?.value;
                    var getvimeoAutoplayHidden = document.getElementById('vimeoAutoplayHidden')?.value; 
                    if(getvimeoVideoId && getvimeoLoopSetting && getvimeoMuteUnmuteSetting && getvimeoControlsSetting && getvimeoAutoplayHidden && getvimeoAutoplayHidden){  
                      iframeIdhtmlvvv.src = "https://player.vimeo.com/video/"+getvimeoVideoId+"?&autoplay="+getvimeoAutoplayHidden+"&muted="+getvimeoMuteUnmuteSetting+"&loop="+getvimeoLoopSetting+"&controls="+getvimeoControlsSetting+"&#t="+getvimeoStartingFrame  
                    } 
                    iframeIdhtmlvvv.style.display = "block";
                    var vimeoCtThumbId = document.getElementById('vimeoCtThumbId')
                    if(vimeoCtThumbId){
                      vimeoCtThumbId.style.display="none";
                    }
                  }
              }
            }  
        }/* Code For vimeo Custom Tool to check thumbnail image */`;

  lv_page_script =
    lv_page_script +
    `  var imported = document.createElement('script');
      imported.src = 'https://www.googletagmanager.com/gtag/js?id=${trackingId}';
      document.head.appendChild(imported);
      window.dataLayer = window.dataLayer || [];
      function gtag() {window.dataLayer.push(arguments) }
      gtag('js', new Date());
      gtag('config', '${trackingId}');
      gtag('set', 'user_properties', {
        'projectId': '${eventId}',
        'pageName': '${pageName}'
      }); 
    
      var calendarScr = document.createElement('script');
      calendarScr.id = 'calender-widget';
      calendarScr.src = 'https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js'; 
      var calScriptTag = document.getElementsByTagName('script')[0];
      calScriptTag.parentNode.insertBefore(calendarScr, calScriptTag);  
      `;

  return lv_page_script;
};