import { createReducer } from 'reduxsauce';
import { Constants } from '../Actions';

const INITIAL_STATE = {
    signedInSuccess: false,
    error: false,
    googleOAuthUrlForSignIn: null,
    checkGoogleSignInValidTokenSuccess: false,
    isValidGoogleSignInToken: false
};

const connectGoogleSignInRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        signedInSuccess: false,
        error: false,
        googleOAuthUrlForSignIn: null,
    };
};
const connectGoogleSignInSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        signedInSuccess: true,
        error: false,
        googleOAuthUrlForSignIn: action.url
    };
};
const connectGoogleSignInError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        signedInSuccess: false,
        error: true,
        googleOAuthUrlForSignIn: null
    };
};

const checkGoogleSignInValidTokenRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isValidGoogleSignInToken: false,
        error: false,
        checkGoogleSignInValidTokenSuccess: false
    };
};

const checkGoogleSignInValidTokenSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isValidGoogleSignInToken: action.isValid,
        error: false,
        checkGoogleSignInValidTokenSuccess: true
    };
};

const checkGoogleSignInValidTokenError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isValidGoogleSignInToken: false,
        error: true,
        checkGoogleSignInValidTokenSuccess: false,
        message: action.message
    };
};

const HANDLERS = {
    [Constants.CONNECT_GOOGLE_SIGN_IN_REQUEST]: connectGoogleSignInRequest,
    [Constants.CONNECT_GOOGLE_SIGN_IN_SUCCESS]: connectGoogleSignInSuccess,
    [Constants.CONNECT_GOOGLE_SIGN_IN_ERROR]: connectGoogleSignInError,
    [Constants.CHECK_GOOGLE_SIGN_IN_VALID_TOKEN_REQUEST]: checkGoogleSignInValidTokenRequest,
    [Constants.CHECK_GOOGLE_SIGN_IN_VALID_TOKEN_SUCCESS]: checkGoogleSignInValidTokenSuccess,
    [Constants.CHECK_GOOGLE_SIGN_IN_VALID_TOKEN_ERROR]: checkGoogleSignInValidTokenError,
};

export default createReducer(INITIAL_STATE, HANDLERS);