import Modal from "react-responsive-modal";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';

const CreateFolderModal = (props) => {
    const filesState = useSelector((store) => store.Files);

    const handleSubmit = (e) => {
        e.preventDefault();
        props.handleCreateNewFolder();
    };

    return (
        <Modal
            open={props.createFolder}
            onClose={props.handleCloseCreateFolder}
            classNames={{
                overlay: '',
                modal: 'Invite-User-Modal',
            }}
            center
        >
            <Grid component="form" container className="modal-wrapper" onSubmit={handleSubmit}>
                <Grid item xs={12} pb={2}>
                    <Box mt={-1} className="subsecondary">
                        Add New Folder
                    </Box>
                </Grid>
                <Grid item xs={12} pb={3}>
                    <Box component="div" className="inputField">
                        <label>
                            Folder Name<span className="required">*</span>
                        </label>
                        <input type="text" class="form-control small" placeholder="" onChange={props.handleFolderChange} />
                        <span className="required">{filesState.alreadyExist ? props.folderMessage : null}</span>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="end">
                        <Stack spacing={1} direction="row" fullWidth>
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                onClick={props.handleCloseCreateFolder}
                            >
                                Cancel
                            </Button>
                            <Button variant="contained" color="primary" disableElevation type="submit">
                                Create
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CreateFolderModal;
