import {
  forwardRef,
  ForwardRefRenderFunction,
  FunctionComponent,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { Box, Grid, Stack } from '@mui/material';
import urlWhite from '../../../../assets/images/icons/urlWhite.svg';
import ChatBot from '../../../../assets/images/icons/chat-bot-avatar.svg';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import Model from 'react-responsive-modal';
import CommentIcon from '@material-ui/icons/Comment';
import AIChatbotAPI from 'Redux/API/aiChatbotAPI';
interface FeedBack {
  _id?: string;
  type: string;
  from?: string;
  response?: any;
  comment?: string;
}
export interface Message {
  _id?: string;
  id: string;
  text: string;
  prompt?: string;
  sender: 'user' | 'assistant';
  senderName?: string;
  feedback?: FeedBack;
  timestamp?: string;
  botResponse?: string;
}
interface Props {
  messages: Message[];
  chatId: string;
  ref?: Ref<any>;
  handleLoadMoreForMessages?: () => void;
  showLoadMoreButtonForMessages?: boolean;
  hideFeedbackUI?: boolean;
}

export interface Actions {
  scrollToBottom: () => void;
}

const MessageView: ForwardRefRenderFunction<Actions, Props> = (props, ref) => {
  const { hideFeedbackUI = false } = props;
  const [messages, setMessages] = useState<Message[]>([]);
  const [activeChatId, setActiveChatId] = useState<string>('');
  const [modelContent, setModalContent] = useState<{
    prompt: string;
    botResponse: string;
  }>({ prompt: '', botResponse: '' });
  const [selectedMessageIndex, setSelectedMessageIndex] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [addCommentModal, setAddCommentModal] = useState<boolean>(false);
  const chatScroll = useRef<HTMLElement>(null);

  useEffect(() => {
    setMessages(props.messages);
    setActiveChatId(props.chatId);
  }, [props.chatId, props.messages]);

  useImperativeHandle(ref, () => ({
    scrollToBottom,
  }));

  const scrollToBottom = () => {
    if (chatScroll?.current) {
      chatScroll.current.scrollTo({
        top: chatScroll.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const sendFeedBack = async (
    name: string,
    value: string | undefined,
    index: number,
  ) => {
    let mesg = messages[index];
    if (!mesg.feedback) {
      //@ts-ignore
      mesg['feedback' as keyof Message] = {
        type: 'like/dislike',
      };
    }

    if (mesg.feedback) mesg.feedback[name as keyof FeedBack] = value;

    const data = {
      feedback: mesg.feedback,
      message: mesg.text,
      chatId: activeChatId,
    };
    console.log('D A T A', data);
    const service = new AIChatbotAPI();
    const res = await service.saveFeedback(data);
    if (res?.data?.success) {
      const chat = res.data.conversation;
      if (chat) {
        setActiveChatId(chat?._id);
        const messagesRes = chat?.messages.map((mes: any) => {
          return {
            _id: mes._id,
            sender: mes.sender,
            text: mes.text,
            prompt: mes.prompt,
            botResponse: mes.botResponse,
            feedback: mes.feedback,
          };
        });

        // await initializeSocketService();
        setMessages(messagesRes);
      }
    }
  };
  const renderFeedbackUI = (message: Message, index: number) => {
    return (
      <>
        <Stack
          className={`reaction ${
            (message?.feedback?.response || message?.feedback?.comment) &&
            'active'
          }`}
          direction={'row'}
        >
          {message.feedback?.response === 'like' ? (
            <ThumbUpIcon
              onClick={(e) => sendFeedBack('response', '', index)}
              className="icon active"
              color="primary"
            />
          ) : (
            <ThumbUpOutlinedIcon
              onClick={(e) => sendFeedBack('response', 'like', index)}
              className="icon"
              color="primary"
            />
          )}
          {message.feedback?.response === 'dislike' ? (
            <ThumbDownIcon
              className="icon active"
              color="primary"
              onClick={(e) => sendFeedBack('response', '', index)}
            />
          ) : (
            <ThumbDownOutlinedIcon
              className="icon"
              color="primary"
              onClick={(e) => sendFeedBack('response', 'dislike', index)}
            />
          )}
          <CommentIcon
            onClick={() => {
              setAddCommentModal(true);
              setSelectedMessageIndex(index);
            }}
            className={`icon ${message?.feedback?.comment && 'active'}`}
            color="primary"
          />
        </Stack>
      </>
    );
  };
  const renderPromptModel = () => {
    return (
      <Model
        open={showModal}
        onClose={() => setShowModal(false)}
        classNames={{
          overlay: '',
          modal: 'info-modal tab-modal',
        }}
        center
      >
        <Box>
          <Box
            px={3}
            pt={3}
            pb={3}
            sx={{ color: '#ffffff', background: '#000056' }}
            className="paragraph"
          >
            Prompt
          </Box>
          <Box py={3} px={4} className={'change-logs'}>
            <pre>{modelContent.prompt}</pre>
            {modelContent.botResponse ? (
              <>
                <hr></hr>
                Response
                <hr></hr>
                <pre>{modelContent.botResponse}</pre>
              </>
            ) : null}
          </Box>
        </Box>
      </Model>
    );
  };
  const renderCommentModel = () => {
    return (
      <Model
        open={addCommentModal}
        onClose={() => setAddCommentModal(false)}
        classNames={{
          overlay: '',
          modal: 'Invite-User-Modal',
        }}
        center
      >
        <Grid component="form" container className="modal-wrapper">
          <Grid item xs={12} pb={3}>
            <Box mb={1} className="subtitle">
              Feedback on AI Response
            </Box>
            <Box className="small-subtitle">
              Please provide your feedback on how the AI responded. Your
              comments will help us improve the quality of our service
            </Box>
          </Grid>

          <Grid item xs={12} pb={3}>
            <Box
              component={'textarea'}
              rows={5}
              placeholder="Write your feedback here..."
              p={1}
              value={
                messages?.length
                  ? messages[selectedMessageIndex].feedback?.comment
                  : ''
              }
              onChange={(e) => {
                const mes = messages[selectedMessageIndex];
                if (!mes.feedback) {
                  mes['feedback'] = {
                    response: '',
                    comment: e.target.value,
                    type: 'like/dislike',
                  };
                } else {
                  mes.feedback.comment = e.target.value;
                }
                messages[selectedMessageIndex] = mes;
                setMessages([...messages]);
              }}
              sx={{ width: '100%', outline: 'none !important' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      sendFeedBack(
                        'comment',
                        messages?.[selectedMessageIndex]?.feedback?.comment,
                        selectedMessageIndex,
                      );
                      setAddCommentModal(false);
                    }}
                    disableElevation
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => setAddCommentModal(false)}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Model>
    );
  };
  return (
    <>
      <Box className="chat-window">
        {/* @ts-ignore */}
        <Stack spacing={1} className="chat-history" ref={chatScroll}>
          {/* {props. */}
          {messages?.length > 0 &&
            messages?.map((message, ind) => (
              <Box
                className={`${
                  message.sender === 'user' ? 'client' : 'bot'
                } chat-bubble`}
                key={'mes_id_' + ind}
              >
                <Box className="message">
                  {/* <Box component={'strong'} mr={1}>
                  {message.sender === 'user' ? 'You: ' : 'Assistant: '}
                </Box> */}
                  {message.text}

                  {message.sender === 'user' && message.prompt && (
                    <Tooltip
                      arrow
                      color="primary"
                      placement="right"
                      title="Prompt"
                    >
                      <Box
                        component={'img'}
                        src={urlWhite}
                        ml={1}
                        onClick={() => {
                          setModalContent({
                            prompt: message.prompt || '',
                            botResponse: messages[ind + 1].botResponse || '',
                          });
                          setShowModal(true);
                        }}
                        sx={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                  )}
                  {!hideFeedbackUI &&
                    message.sender !== 'user' &&
                    renderFeedbackUI(message, ind)}
                </Box>
                {/* {message.sender !== 'user' && (
                  <>
                    <Box className="chatbot-avatar">
                      <img src={ChatBot} />
                    </Box>
                  </>
                )} */}
              </Box>
            ))}
        </Stack>
        {props.showLoadMoreButtonForMessages && (
          <Stack direction={'row'} justifyContent={'center'} my={1}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={props.handleLoadMoreForMessages}
            >
              Load More
            </Button>
          </Stack>
        )}
      </Box>
      {modelContent.prompt && renderPromptModel()}
      {renderCommentModel()}
    </>
  );
};
const MessageWindow = forwardRef<Actions, Props>(MessageView);
export default MessageWindow;
