/**
 * ManageCustomFields Component
 * @description Component for managing custom fields related to accounts.
 * @param {Object} props - Component props
 * @param {Function} props.handleCloseManageCustomFields - Function to close the Manage Custom Fields modal
 * @param {Function} props.handleOpenManageCustomFields - Function to open the Manage Custom Fields modal
 * @param {boolean} props.showManageCustomFields - Boolean indicating whether the Manage Custom Fields modal is shown
 * @returns {JSX.Element} - ManageCustomFields component
 */

import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { theme } from '../../../theme/theme';
import AddNewCustomField from './AddOrUpdateCustomField';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomField,
  CustomFields,
  CustomFields_State,
} from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { Actions } from 'Redux/Actions';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import Loader from 'Components/Loader/loader';
import { turnCamelCaseToNormalText } from 'helpers';
import { useLocation } from 'react-router';
import IndividualCustomFieldsForManageCustomFields from './IndividualCustomFieldsForManageCustomFields';

// Props for ManageCustomFields component
type ManageCustomFieldsProps = {
  handleCloseManageCustomFields: () => void;
  handleOpenManageCustomFields: () => void;
  showManageCustomFields: boolean;
};

// Component for managing custom fields
export default function ManageCustomFields({
  handleCloseManageCustomFields,
  handleOpenManageCustomFields,
  showManageCustomFields,
}: ManageCustomFieldsProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentTab = location.pathname.split('/').at(-1);

  const { customFields: customFieldsFromRedux, loading } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;
  const [openAddNewFieldModal, setOpenAddNewFieldModal] = useState(false);
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] =
    useState(false);
  const [idToDelete, setIdToDelete] = useState<string | undefined>('');
  const [isBeingEdited, setIsBeingEdited] = useState<boolean>(false);
  const [customFieldToEdit, setCustomFieldToEdit] = useState<
    CustomField | undefined
  >({} as CustomField);

  const [customFields, setCustomFields] = useState<CustomFields>([] as CustomFields)

  useEffect(()=> {
    setCustomFields(customFieldsFromRedux)
  }, [customFieldsFromRedux])

  /**
   * Handles the closing of the add new field modal and resets its state.
   * @function handleCloseAddNewFieldModal
   * @description This function sets the visibility of the add new field modal to false and resets its state.
   */
  const handleCloseAddNewFieldModal = () => {
    setOpenAddNewFieldModal(false);
    resetAddNewFieldModal();
  };

  /**
   * Handles opening the add new field modal.
   * @function handleOpenAddNewFieldModal
   * @param {boolean} [isBeingEdited=false] - Indicates whether the modal is being opened for editing an existing custom field.
   * @param {CustomField} [customField] - The custom field object being edited.
   * @description This function sets the visibility of the add new field modal to true and optionally sets the custom field to edit.
   */
  const handleOpenAddNewFieldModal = (
    isBeingEdited: boolean = false,
    customField?: CustomField,
  ) => {
    setOpenAddNewFieldModal(true);
    if (isBeingEdited) {
      setIsBeingEdited(isBeingEdited);
      setCustomFieldToEdit(customField);
    }
  };

  /**
   * Handles deleting a custom field.
   * @function handleDeleteCustomField
   * @description This function dispatches an action to delete a custom field and closes the delete confirmation modal.
   */
  const handleDeleteCustomField = () => {
    const data = {
      bodyParams: {
        _id: idToDelete,
      },
      queryParams: {
        customFieldType: currentTab,
      },
    };
    dispatch(Actions.deleteOpportunityCustomFieldRequest(data));
    closeDeleteConfirmationModel();
  };

  /**
   * Closes the delete confirmation modal and resets its state.
   * @function closeDeleteConfirmationModel
   * @description This function sets the visibility of the delete confirmation modal to false and resets its state.
   */
  const closeDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(false);
    setIdToDelete('');
  };

  /**
   * Handles opening the delete confirmation modal for a specific custom field.
   * @function handleOpenDeleteConfirmationModal
   * @param {string | undefined} id - The ID of the custom field to delete.
   * @description This function sets the ID of the custom field to delete and opens the delete confirmation modal.
   */
  const handleOpenDeleteConfirmationModal = (id: string | undefined) => {
    setIdToDelete(id);
    setOpenDeleteConfirmationModel(true);
  };

  /**
   * Resets the add new field modal state.
   * @function resetAddNewFieldModal
   * @description This function resets the state related to the add new field modal, such as the custom field being edited and the editing status.
   */
  const resetAddNewFieldModal = () => {
    setCustomFieldToEdit({} as CustomField);
    setIsBeingEdited(false);
  };

  const handleOrderIndexChange = (e:React.ChangeEvent<HTMLInputElement>, customField: CustomField) => {
    const customFieldsCopy = customFields.map(cf => {
      if (cf._id === customField._id) {
        return { ...cf, orderIndex: parseInt(e.target.value) };
      } else {
        return cf;
      }
    });
    setCustomFields([...customFieldsCopy]);
  }
 
  


  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={showManageCustomFields}
        onClose={handleCloseManageCustomFields}
        classNames={{
          overlay: 'full-screen_overlay',
          modal: 'full-screen_modal manage-custom-fields',
        }}
        center
      >
        <Grid container justifyContent="end">
          <Grid
            item
            xs={12}
            md={6}
            lg={5}
            xl={4}
            className="full-screen_modal__form"
          >
            <Box component="div" className="full-screen_modal__form_header">
              <h3 className="subtitle">Manage Custom Fields</h3>
            </Box>
            <Box
              component="div"
              px={3}
              py={2}
              className="full-screen_modal__form_body"
            >
              <Grid container justifyContent="between" alignItems="center">
                <Grid item xs>
                  <Box component="p" className="paragraph">
                    Custom Fields ({customFields.length})
                  </Box>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => handleOpenAddNewFieldModal()}
                  >
                    Add New Field
                  </Button>
                </Grid>
              </Grid>
              <Box className="custom-fields-table" py={3}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="tableHeadCell long-text" style={{maxWidth: '240px'}}>
                        Field Label
                      </TableCell>
                      <TableCell className="tableHeadCell" align="left">
                        Type
                      </TableCell>
                      <TableCell className="tableHeadCell" align="left">
                        Mandatory
                      </TableCell>
                      <TableCell className="tableHeadCell" align="left">
                        Order
                      </TableCell>
                      <TableCell className="tableHeadCell" align="left">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(customFields) && customFields.length > 0 ? (
                      customFields.map((customField) => (<IndividualCustomFieldsForManageCustomFields handleOrderIndexChange={handleOrderIndexChange}
                        handleOpenAddNewFieldModal={handleOpenAddNewFieldModal} handleOpenDeleteConfirmationModal={handleOpenDeleteConfirmationModal}
                        customField={customField}
                        key={customField._id}
                      />))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                          There are no records to display
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Modal>
      {openAddNewFieldModal && (
        <AddNewCustomField
          handleCloseAddNewFieldModal={handleCloseAddNewFieldModal}
          handleOpenAddNewFieldModal={handleOpenAddNewFieldModal}
          openAddNewFieldModal={openAddNewFieldModal}
          isBeingEdited={isBeingEdited}
          customFieldToEdit={customFieldToEdit as CustomField}
        />
      )}
      {openDeleteConfirmationModel && (
        <ConfirmationAlertPopUp
          openModal={openDeleteConfirmationModel}
          closeModalFunc={closeDeleteConfirmationModel}
          title={'Delete Confirmation!'}
          text={'Are you sure you want to delete it?'}
          confirmationButtonText={'Delete'}
          confirmationButtonColor="secondary"
          closeButtonText={'Cancel'}
          functionality={handleDeleteCustomField}
        />
      )}
      {loading && <Loader />}
    </ThemeProvider>
  );
}