import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import Modal from 'react-responsive-modal'; 
import { Box, Grid } from '@mui/material';
import packageJson from '../../package.json';

function ChangelogPopup({ isOpen, onClose }) {
  const [changelog, setChangelog] = useState('');
  const releaseVersion = packageJson.version; 
  useEffect(() => {
    fetch('/CHANGELOG.md')
      .then((response) => response.text())
      .then((text) => setChangelog(text))
      .catch((error) => console.error('Error fetching changelog:', error));
  }, []);

  return (
    /*  <Modal isOpen={isOpen} onRequestClose={onClose} >
       
        <ReactMarkdown>{changelog}</ReactMarkdown>  
       <button className="close-btn" onClick={onClose}>Close</button>
     </Modal> */
    <Modal
      open={isOpen}
      onClose={onClose}
      classNames={{
        overlay: '',
        modal: 'info-modal tab-modal ',
      }}
      center
    >
      <Box >
        <Box
          px={3}
          pt={3}
          pb={3}
          sx={{ color: '#ffffff', background: '#000056' }}
          className="paragraph"
        >
          Version History ( Current Version : {releaseVersion} )
        </Box>

        <Box py={3} px={4} className="change-logs"> 
         <ReactMarkdown>{changelog}</ReactMarkdown>     
         {/*  {changelog} */}
        </Box>
      </Box>
    </Modal>
  );
}

export default ChangelogPopup;
