import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { Actions } from '../../../Redux/Actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../../../theme/theme'
import SearchIcon from '../../../assets/images/icons/search.svg'

import GamificationChallengesIcon from '../../../assets/images/icons/gamification_challenges.svg'
import GamifiacationLeaderboard from '../../../assets/images/icons/gamifiacation_leaderboard.svg'
import GamificationConfigrations from '../../../assets/images/icons/gamification_configrations.svg'

import BadgeGold from '../../../assets/images/icons/Badge_Gold.svg'
import BadgeSilver from '../../../assets/images/icons/Badge_Silver.svg'
import BadgeBronze from '../../../assets/images/icons/Badge_Bronze.svg'

import ImportIcon from '../../../assets/images/icons/import.svg'

import urlImg from '../../../assets/images/icons/url.svg'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import '../../../sass/main.scss'

import Grid from '@mui/material/Grid'
import Box from '@material-ui/core/Box'
import Stack from '@mui/material/Stack'

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'

import InputAdornment from '@material-ui/core/InputAdornment'


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Link } from 'react-router-dom'

import CustomSwitch from '../../../Components/customSwitch'
import { getProjectIdFromUrl } from '../../../helpers/common'
import GamificationConfigurations from './GamificationConfigurations'
import GamificationChallenges from './GamificationChallenges'
import GamificationLeaderboard from './GamificationLeaderboard'


export class Gamification extends Component {
  constructor(props) {
    super(props)
    this.hasMounted = false
    this.tabs = ["challenges", "leaderboard", "configuration"];
    this.state = {
      projectId: "",
      allowed: {},
      activeTabIndex: 0
    }
  }

  componentDidMount() {
    const projectId = getProjectIdFromUrl();
    this.setState({
      projectId
    })
  }

  componentDidUpdate(prevProps) {
    if(!this.hasMounted){     
      const seg = window.location.href.split('/');
      const mid = seg[seg.length - 2];
      this.setState({
        activeTabIndex: this.tabs.indexOf(mid),
      })
      this.hasMounted = true
    }    
     
    if(this.props.project?.project?.permissions && Object.keys(this.state.allowed).length===0){      
      let allowed = {};
      if(this.props.project.project.permissions.find(o=>o.permission_name==='Edit Gamification')){
        allowed['Edit Gamification'] = true
      }
      if(this.props.project.project.permissions.find(o=>o.permission_name==='Export Gamification')){
        allowed['Export Gamification'] = true
      }
      if(this.props.project.project.permissions.find(o=>o.permission_name==='Add Manual Points')){
        allowed['Add Manual Points'] = true
      }
      this.state.allowed = allowed;
    }
  }

  navigate = (tab) => {
    this.setState({
      activeTabIndex: this.tabs.indexOf(tab),
    }, () => {
      this.props.history.push(`/project-dashboard/gamification/${tab}/${this.state.projectId}`)
    })
  }

  render() {
    const { activeTabIndex, allowed } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Box className="container-with-sidebar" p={0}>
          <Tabs  selectedIndex={activeTabIndex}>
            <Box
              className="project-sidebar"
              sx={{ background: 'transparent !important' }}
            >
              <TabList>
                <Tab style={{padding: 0,}}>
                  <Box  sx={{padding: '10px', width: '100%',}}  onClick={()=>this.navigate('challenges')}>
                    {' '}
                    <img src={GamificationChallengesIcon} />{' '}
                    {this.props.users.languageStateForRedux?.column_names?.gamification?.challenges}
                  </Box>
                </Tab>
                <Tab style={{padding: 0,}}>
                  <Box  sx={{padding: '10px', width: '100%',}} onClick={()=>this.navigate('leaderboard')}>
                    {' '}
                    <img src={GamifiacationLeaderboard} />{' '}
                    {this.props.users.languageStateForRedux?.column_names?.gamification?.leaderboard}
                  </Box>
                </Tab>
                <Tab style={{padding: 0,}}>
                  <Box  sx={{padding: '10px', width: '100%',}} onClick={()=>this.navigate('configuration')}>
                    {' '}
                    <img src={GamificationConfigrations} />{' '}
                    {this.props.users.languageStateForRedux?.column_names?.gamification?.configurations}
                  </Box>
                </Tab>
              </TabList>
            </Box>

            <div className="container-sidebar-right">
              <TabPanel>
                <GamificationChallenges allowed={allowed} />
              </TabPanel>

              <TabPanel>
                <GamificationLeaderboard project={this.props.project} allowed={allowed} />
              </TabPanel>

              <TabPanel>
                <GamificationConfigurations allowed={allowed} />
              </TabPanel>
            </div>
          </Tabs>
        </Box>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  gamification: state.Gamification,
  events: state.Events,
  users: state.Users,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
    },
    dispatch
  )

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Gamification))

