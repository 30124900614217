import { Constants } from "Redux/Actions";
import { createReducer } from "reduxsauce";

const INITIAL_STATE = {
    error: false,
    message: null,
    apiKeyData: {},
    getApiKeySuccess: false,
    generateApiKeySuccess: false,
    loading: false
}

const getApiKeyRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getApiKeySuccess: false,
        error: false,
        message: null,
        loading: true
    };
};

const getApiKeySuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getApiKeySuccess: true,
        error: false,
        message: action.message,
        apiKeyData: action.data,
        loading: false
    };
};

const getApiKeyError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getApiKeySuccess: false,
        error: true,
        message: action.error,
        loading: false
    };
};

const generateApiKeyRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        generateApiKeySuccess: false,
        error: false,
        message: null,
        loading: true
    };
};

const generateApiKeySuccess = (state = INITIAL_STATE, action) => {
    let resData = {
        ...state,
        generateApiKeySuccess: true,
        error: false,
        message: action.message,
        loading: false
    }
    if(action.data){
        resData.apiKeyData = action.data
    }
    return resData;
};

const generateApiKeyError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        generateApiKeySuccess: false,
        error: true,
        message: action.error,
        loading: false
    };
};

const HANDLERS = {
    [Constants.GET_API_KEY_REQUEST]: getApiKeyRequest,
    [Constants.GET_API_KEY_SUCCESS]: getApiKeySuccess,
    [Constants.GET_API_KEY_ERROR]: getApiKeyError,

    [Constants.GENERATE_API_KEY_REQUEST]: generateApiKeyRequest,
    [Constants.GENERATE_API_KEY_SUCCESS]: generateApiKeySuccess,
    [Constants.GENERATE_API_KEY_ERROR]: generateApiKeyError
}

export default createReducer(INITIAL_STATE, HANDLERS);