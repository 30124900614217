import React, { useEffect } from 'react';
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from '../../../theme/theme';
import Grid from '@mui/material/Grid';
import IconButton from '@material-ui/core/IconButton';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Box from '@mui/material/Box';
import { Link, withRouter } from 'react-router-dom';
import EditBtn from '../../../assets/images/icons/edit.svg';
import { useParams, useHistory } from 'react-router-dom';
import OpportunityOpportunitiesAPI from 'Redux/API/opportunity/opportunityOpportunitiesAPI';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'Redux/Actions';
import {
    Opportunity,
    OpportunityState,
    Source,
    Status,
} from 'Redux/Reducers/opportunity/opportunities.reducer';
import moment from 'moment';
import Loader from 'Components/Loader/loader';

type Props = {
    closeTransactionEmailsModal: (transactionEmailsComp: boolean) => void;
  };
export default function OpportunityTransactionalEmails({closeTransactionEmailsModal}:Props) {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        templates,
        isLoading,
    } = useSelector(
        (state: any) => state.OpportunityOpportunities,
    ) as OpportunityState;

    const openEditor = () => {
        history.push(`/opportunity/opportunity/update-transactional-email-templates`);
    };

    useEffect(() => {
        listTransactionEmail();
    }, []);

    const listTransactionEmail = () => {
        dispatch(Actions.listTransactionEmailRequest());
    };


    return (
        <ThemeProvider theme={theme}>
            <Grid container spacing={2} mb={3} className="top-filter-area">
                <Grid item >
                    <Grid container alignItems="center">
                        <IconButton size='small' aria-label="delete">
                            <KeyboardBackspaceIcon 
                            onClick={() =>closeTransactionEmailsModal(false)} style={{ color: theme.palette.primary.dark, }}
                            />
                        </IconButton>
                        <p className='subtitle' style={{ color: theme.palette.grey[800], marginLeft: 10, }}></p>
                    </Grid>
                </Grid>
            </Grid>
            <TableContainer className="list-table">
                <Table className="table">
                    <TableHead>
                        <TableRow>
                            <TableCell className='long-text sort-asc'>Template Name</TableCell>
                            <TableCell className='long-text sort-asc'>Subject</TableCell>
                            <TableCell className='long-text sort-asc'>Last Updated On</TableCell>
                            <TableCell className='options'>
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(templates) && templates.length ? (
                            templates.map((temp) => {
                                return (
                                    <TableRow>
                                        <TableCell className='long-text'>{temp.templateName}</TableCell>
                                        <TableCell className='long-text'>{temp.subject}</TableCell>
                                        <TableCell className='long-text'>{moment(temp.updatedAt).format(
                          'MMM, Do YYYY, h:mm A',
                        )}</TableCell>
                                        <TableCell className='options'>
                                            {' '}
                                            <Box sx={{ textAlign: 'center', }}>

                                                <Link
                                                    to={{
                                                        pathname: `/opportunity/opportunity/update-transactional-email-templates`,
                                                        state: {temp: temp}
                                                    }}>
                                                    <img alt="" src={EditBtn}
                                                    />
                                                </Link>

                                                {' '}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                                    There are no records to display
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {isLoading && <Loader />}
        </ThemeProvider>
    );
}
