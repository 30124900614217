import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import { Button } from '@material-ui/core'
import 'react-responsive-modal/styles.css'

class AddMemberModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      loading: false,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      page_no: this.props.page_no ? this.props.page_no : 1,
    }
  }

  onOpenModal = () => {
    this.setState({ open: true })
  }

  onCloseModal = () => {
    this.setState({ open: false })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // const login = this.props.login;

    if (
      this.props.members.success === true &&
      this.props.members.message !== prevProps.members.message
    ) {
      createNotification('success', this.props.members.message)
      let st = prevState.loading ? this.setState({ loading: false }) : null
      this.setState({ firstName: '', lastName: '', email: '', phoneNumber: '' })
    }

    if (
      this.props.members.error &&
      this.props.members.message !== prevProps.members.message
    ) {
      createNotification('error', this.props.members.message, '')
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }
  }

  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  isValid = () => { 
    const { firstName, lastName, email, phoneNumber } = this.state
    let error = {}
    let formIsValid = true
    const regexTest = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    const mobPattern = /^[0-9]{10}$/

    if (email === '' || email.trim().length === 0) {
      formIsValid = false
      error['email'] = '*Please enter email.'
    }

    if (email && regexTest.test(email) === false) { 
      formIsValid = false
      error['email'] = '*Please enter a valid email address.'
    }
    if (firstName === '' || firstName.trim().length === 0) {
      formIsValid = false
      error['firstName'] = '*Please enter first name.'
    }
    if (lastName === '' || lastName.trim().length === 0) {
      formIsValid = false
      error['lastName'] = '*Please enter last name.'
    }
    if (phoneNumber === '' || phoneNumber.trim().length === 0) {
      formIsValid = false
      error['phoneNumber'] = '*Please enter phone number.'
    }
    if (phoneNumber && mobPattern.test(phoneNumber) === false) {
      formIsValid = false
      error['phoneNumber'] = '*Mobile No. is not valid, Please Enter 10 digits.'
    } 
    this.setState({ errors: error })
    return formIsValid
  }

  handleSubmit = () => { 
    if (this.isValid()) { 
      const { firstName, lastName, email, phoneNumber } = this.state
      const requestbody = { firstName, lastName, email, phoneNumber }
      this.props.memberRequest(requestbody) 
      this.handleLoading() 
    }
  }

  render() {
    const {
      errors,
      loading,
      firstName,
      lastName,
      email,
      phoneNumber,
    } = this.state
     
    return (
      <div>
        <h2>Add Member</h2>
        <div className="rsvpEventfieldBox">
          <div className="formSection p-0">
            <form>
              <div class="field-group inputField">
                <label>First Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="firstName"
                  value={firstName}
                  onChange={this.handleChange}
                />
                <p className="error_mesage"> {errors.firstName} </p>
              </div>
              <div class="field-group inputField">
                <label>Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="lastName"
                  value={lastName}
                  onChange={this.handleChange}
                />
                <p className="error_mesage"> {errors.lastName} </p>
              </div>
              <div class="field-group inputField">
                <label>Phone Number</label>
                <input
                  type="text"
                  class="form-control"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={this.handleChange}
                />
                <p className="error_mesage"> {errors.phoneNumber} </p>
              </div>
              <div class="field-group inputField">
                <label>Email</label>
                <input
                  type="text"
                  class="form-control emailLoweCase"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                />
                <p className="error_mesage"> {errors.email} </p>
              </div>
              <div className="editProfileBtn submitForm justify-content-center">
                <Button
                  type="button"
                  className="themeBlueButton"
                  onClick={this.handleSubmit}
                >
                  Register Member
                </Button>
              </div>
            </form>
          </div>
        </div>
        <NotificationContainer />
        {loading ? <Loader /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  members: state.Members,
  // loading: state.Loader.loading,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      memberRequest: Actions.memberRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(AddMemberModal)
