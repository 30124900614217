import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createNotification } from '../../../helpers';
import { styled } from '@material-ui/core/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from '../../../Redux/Actions';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import { NotificationContainer } from 'react-notifications';
import Box from '@mui/material/Box';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../theme/theme';
import Avatar from '@material-ui/core/Avatar';
import MoreIcon from '../../../assets/images/icons/more.svg';
import userImageIcon from '../../../assets/images/icons/userImageIcon.svg';
import SearchIconBar from '../../../assets/images/searchIco.png';

import moment, { duration } from 'moment';
import SearchIcon from '../../../../src/assets/images/icons/search.svg';
import AgendaIcon from '../../../../src/assets/images/icons/agenda-white.svg';
import urlImg from '../../../../src/assets/images/icons/navigate-to.svg';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import Pagination from 'react-js-pagination';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CloseIcon from '@material-ui/icons/Close';
import {
  convertTZ,
  getProjectIdFromUrl,
} from '../../../helpers/common';
import SubIcon from '../../../../src/assets/images/icons/sub-icon.svg';
import AddIcon from '../../../../src/assets/images/icons/add-icon.svg';
import NotificationEditor from '../../../Pages/Dashboard/Notications/notificationEditor';
import CustomSwitch from '../../../Components/customSwitch';
import Loader from '../../../Components/Loader/loader';
import { id } from 'date-fns/locale';
import parse from 'html-react-parser';
import { default as ReactSelect } from 'react-select';
import { components } from 'react-select';
import axios from 'axios';
import ConfirmationAlertPopUp from '../../../Common/ConfirmationAlertPopUp';
import Select from '@mui/material/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@mui/material/FormControl';
import CustomHTMLTooltip from 'Components/CustomHTMLTooltip';
import GoogleWorkspace from '../../../../src/assets/images/icons/Google_G_Logo.svg';
import ImgCrop from 'Pages/demo/MyProfile/Crop';
import UploadFromFilesPopUp from 'Common/UploadFromFilesPopUp';
import { FILE_SECTION, FILE_TYPE } from "../../../constants/constants";


const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};

const content = '';

const URL = process.env.REACT_APP_API_URL;
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const IMAGE_URL2 = process.env.REACT_APP_FILE_MODULE_URL;
const section = FILE_SECTION.images;

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};


function parseToHtml(text) {
  let div = document.createElement("div");
  div.innerHTML = text;
  return div.textContent || div.innerText;
}

const customStyles = {
  option: (provided, state, styles) => ({
    ...provided,
    ...styles,
    backgroundColor: state.isSelected ? '#EDECF5' : 'none',
    position: 'relative',
    borderRadius: 4,
    marginBottom: 5,
  }),
  control: (styles) => ({
    ...styles,
    minHeight: 52,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingTop: '8px',
    paddingBottom: '8px',
  }),
  menu: (styles) => ({
    ...styles,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

const styles = {
  avatar: {
    // backgroundColor: '#D360EF',
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: 8,
  },
};

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-input': {
    padding: '12.5px 14px',
  },
});

class Agenda extends Component {
  constructor(props) {
    super(props);
    this.chooseAgendaImage = React.createRef(null);
    this.state = {
      errors: {},
      page_no: 1,
      capacity: 0,
      totalResults: 1,
      itemCount: 10,
      listOfAgendas: [],
      endCount: '',
      openDeleteSessionModal: false,
      checkBoxIdsArrState: [],
      isGroupsComp: false,
      startCount: 1,
      page_no2: 1,
      agendaIdForDelete: false,
      openAddSessionForm: false,
      dateTime: new Date(),
      title: '',
      tags: '',
      overbookingCapacity: 0,
      track: '',
      agendaURL: '',
      location: '',
      visibility: 'All',
      hours: 1,
      minutes: 0,
      searchText: "",
      group: [],
      navigateTo: 'none',
      pages: null,
      initialDate: new Date(),
      openEditSessionForm: false,
      chipItems: [],
      chipError: null,
      trackItems: [],
      tagsForEditItem: [],
      tracksForEditItem: [],
      trackError: null,
      description: content,
      titleForEdit: '',
      hoursForEdit: '',
      minutesForEdit: '',
      locationForEdit: '',
      trackForEdit: '',
      visibilityTypeForEdit: 'All',
      groupsForEdit: [],
      navigateToForEdit: 'none',
      pagesForEdit: null,
      descriptionForEdit: content,
      agendaURLForEdit: '',
      capacityForEdit: 0,
      overbookingCapacityForEdit: 0,
      agendaIdforEdit: false,
      dateTimeForEdit: new Date(),
      openAddSpeakerModal: false,
      openAddSpeakerModalForEdit: false,
      showSpeakers: false,
      showSpeakersButton: false,
      speakersList: [],
      speakersListEdit: [],
      speakerFirstName: '',
      speakerLastName: '',
      speakerJob: '',
      speakerImage: '',
      agendaImage: '',
      isSpeakerEdit: false,
      isSpeakerEditIndex: null,
      tempSpeakerName: 'Speakers',
      speakerName: 'Speakers',
      speakerNameEdit: 'Speakers',
      listOfGroups: [],
      selectedGroup: [],
      pageOptions: [],
      openDeleteSessionSpeakerModal: false,
      openDeleteSessionSpeakerModalEdit: false,
      deleteSpeakerIndex: null,
      registeredGuests: 0,
      registeredGuestsForEdit: 0,
      agendaImageForEdit: '',
      sort_by: 'updatedAt',
      order: 'desc',
      allowed: {},
      openReplicateModal: false,
      agendaIdToReplicate: '',
      loading: false,
      userDetails: "",
      cropOpen: false,
      uploadFromFiles: false,
      buttonState: false,
      fileNameInS3: "",
      s3FileName:"",
      destinationFolder: "",
      userId: "",
      token: "",
      displayS3Image: false,
      parentId: "",
      parentForPopup: "Agenda"
    };
  }

  getAgendaList = () => {
    const projectId = getProjectIdFromUrl();
    const {
      statusFilterOptionsSelected,
      sourceFilterOptionsSelected,
      groupFilterOptionsSelected,
      isActiveFilterOptionsSelected,
      page_no,
      itemCount,
      sort_by,
      sortOrder
    } = this.state;
    const requestbody = {
      projectId,
      status: statusFilterOptionsSelected,
      source: sourceFilterOptionsSelected,
      group: groupFilterOptionsSelected,
      active: isActiveFilterOptionsSelected.map(el => el == 'true'),
      sort_by: sort_by,
      order: sortOrder,
      pageNumber: page_no,
      limit: itemCount,
      permission: "View Sessions"
    };
    this.props.sortFilterAttendeesRequest(requestbody);
    this.handleGetAttendeesLoading();
  };

  listAgenda = (isSearching = false) => {
    try {
      const { page_no, itemCount, searchText, sort_by, order } = this.state;
      const projectId = getProjectIdFromUrl();
      const permission = 'View Sessions';
      const requestbody = {
        projectId,
        permission,
        page_no,
        limit: itemCount,
        searchText: isSearching ? searchText.trim() : '',
        sort_by,
        order
      };
      this.props.listAgenda(requestbody);
      const totalResults = this.props.agenda.totalResults;
      this.setState({
        totalResults,
      });
      if (!isSearching) {
        this.setState({
          searchText: ''
        });
      }
    } catch (err) {
      createNotification('error', 'Something went wrong in agenda listing');
    }
    this.setState({
      loading: true
    });
  };

  componentDidMount = () => {
    let userDetails = JSON.parse(localStorage.getItem('user_details'));


    const projectId = getProjectIdFromUrl();
    this.setState({
      projectId,
      userDetails
    });
    this.listAgenda();
    this.listgroups();
    this.getProjectTemplate();
    this.setState({
    });
    if (this.state.itemCount < this.state.totalResults) {
      this.setState({
        endCount: parseInt(this.state.itemCount)
      });
    }

    this.setState({
      isTotalRecords: true
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.itemCount !== this.state.itemCount) {
      if (this.state.itemCount < this.state.totalResults) {
        this.setState({
          endCount: parseInt(this.state.itemCount),
        });
      }
      else {
        this.setState({
          endCount: parseInt(this.props.agenda.totalResults)
        });
      }
    }

    if (this.state.isTotalRecords || (this.props.agenda.message === 'Agenda list get successfully' && prevProps.agenda.message !== "Agenda list get successfully")) {
      this.setState({
        isTotalRecords: false
      }, () => {
        this.setState({
          totalResults: this.props.agenda.totalResults
        }, () => {

          if (this.state.itemCount >= this.state.totalResults) {
            this.setState({
              endCount: parseInt(this.state.totalResults),
            });
          }
          else if (this.state.itemCount < this.state.totalResults && this.state.page_no === 1) {
            this.setState({
              endCount: parseInt(this.state.itemCount)
            });
          }
        });
        this.props.agenda.success = false;
      });
    }

    if (
      this.props.agenda.success === true &&
      this.props.agenda.message !==
      prevProps.agenda.message
    ) {
      this.setState({
        totalResults: this.props?.agenda?.totalResults,
      });

      if (this.state.itemCount >= this.props.agenda.totalResults) {
        this.setState({
          endCount: this.props.agenda.totalResults
        });
      } else {
        this.setState({
          endCount: this.state.totalResults > this.state.itemCount * this.state.page_no ? this.state.itemCount * this.state.page_no : this.state.totalResults,
        });
      }
    }

    if (
      this.props.agenda.addAgendaSuccess === true &&
      prevProps.agenda.addAgendaSuccess === false &&
      this.props.agenda.message !== prevProps.agenda.message
    ) {
      createNotification('success', this.props.agenda.message);
      this.setState({
        title: '',
        tags: '',
        duration: '',
        location: '',
        capacity: 0,
        overbookingCapacity: 0,
        track: '',
        navigateTo: '',
        pages: null,
        visibility: '',
        dateTime: moment.tz(new Date(), this.props.project?.project?.timezone).format('MM/DD/YYYY hh:mm a'),
        agendaURL: '',
        hours: '',
        minutes: '',
        description: '',
        showSpeakers: false,
        chipError: null,
        trackError: null,
        page_no: 1,
        startCount: 1,
        loading: false,
        displayS3Image:false
      });
      this.handleCloseOpenAddSessionForm();
      this.listAgenda();
    }

    if (
      this.props.agenda.replicateAgendaSuccess === true &&
      prevProps.agenda.replicateAgendaSuccess === false &&
      this.props.agenda.message !== prevProps.agenda.message
    ) {
      this.setState({
        loading: false,
        agendaIdToReplicate: '',
        openReplicateModal: false
      });
      this.listAgenda();
      createNotification('success', this.props.agenda.message);
    }
    if (
      this.props.agenda.error === true &&
      prevProps.agenda.error === false &&
      this.props.agenda.message !== prevProps.agenda.message
    ) {
      createNotification('error', this.props.agenda.message);
      this.setState({
        loading: false
      });
    }

    if (
      this.props.agenda.getAgendaSuccess &&
      this.props.agenda.message !== prevProps.agenda.message
    ) {
      // finding if the selected service plan allows to create more agendas
      let userDetails = JSON.parse(localStorage.getItem('user_details'));
      let reqData = {
        servicePlanId: userDetails.servicePlanId,
        projectId: this.state.projectId
      };
      this.props.findIfAllowedFromSuperadminRequest(reqData);
      // finding if the selected service plan allows to create more agendas ends

      this.setState({
        userDetails: JSON.parse(localStorage.getItem('user_details')),
        listOfAgendas: this.props.agenda.agenda,
      });
    }

    if (
      this.props.agenda.searchAgenda !== prevProps.agenda.searchAgenda &&
      this.props.agenda.message !== prevProps.agenda.message
    ) {
      this.updatePaginationDropDownState();
      createNotification('success', this.props.agenda.message);
    }

    if (
      this.props.agenda.getAgendaSuccess &&
      !prevProps.agenda.getAgendaSuccess &&
      this.props.agenda.message !== prevProps.agenda.message
    ) {
      this.setState({
        loading: false,
      });
    }

    if (
      this.props.agenda.deleteAgendaSuccess === true &&
      this.props.agenda.message !== prevProps.agenda.message
    ) {
      createNotification('success', this.props.agenda.message);
      this.setState({
        agendaIdforDelete: '',
        openDeleteSessionModal: false,
        loading: false,
      });
      this.listAgenda();
      this.handleCloseOpenDeleteSessionModal();
    }

    if (
      this.props.agenda.editAgendaSuccess === true &&
      prevProps.agenda.editAgendaSuccess === false &&
      this.props.agenda.message !== prevProps.agenda.message
    ) {
      createNotification('success', this.props.agenda.message);
      this.setState({
        titleForEdit: '',
        hoursForEdit: '',
        minutesForEdit: '',
        locationForEdit: '',
        trackForEdit: '',
        visibilityTypeForEdit: '',
        groupsForEdit: [],
        navigateToForEdit: '',
        pagesForEdit: null,
        agendaURLForEdit: '',
        capacityForEdit: 0,
        overbookingCapacityForEdit: 0,
        agendaIdforEdit: false,
        dateTimeeForEdit: '',
        chipError: null,
        trackError: null,
        loading: false,
        displayS3Image:false
      });
      this.handleCloseOpenEditSessionForm();
      this.listAgenda();
    }

    if (
      this.props.attendees.getAttendeesGroupsSuccess &&
      !prevProps.attendees.getAttendeesGroupsSuccess &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      const group = this.props.attendees.attendeesGroups.map((g) => {
        return {
          _id: g._id,
          label: g.attendeesGroupName,
          value: g.attendeesGroupName,
          source: g.source,
          showToGroup: g.showToGroup
        };
      });

      this.setState({
        listOfGroups: group,
        loading: false,
      });
    }

    if (this.props.project?.project?.permissions) {
      let allowed = {};
      if (this.props.project.project.permissions.find(o => o.permission_name === 'Create Session')) {
        allowed['Create Session'] = true;
      }
      if (this.props.project.project.permissions.find(o => o.permission_name === 'Edit Session')) {
        allowed['Edit Session'] = true;
      }
      if (this.props.project.project.permissions.find(o => o.permission_name === 'Delete Session')) {
        allowed['Delete Session'] = true;
      }
      this.state.allowed = allowed;
    }
  };

  listgroups = () => {
    try {
      const arr = window.location.href.split('/');
      const projectId = arr[arr.length - 1];
      const body = {
        projectId,
        page_no2: 1,
        searchText: '',
      };
      if (projectId) {
        this.props.listAttendeesGroups(body);
        this.setState({
          loading: true
        });
      } else {
        createNotification('error', 'project Id is missing');
      }
    } catch (error) {
      createNotification('error', 'Something went wrong');
    }
  };

  selectItemCount = (e) => {
    this.setState({
      itemCount: e.target.value,
      page_no: 1,
      startCount: 1
    }, () =>
      this.listAgenda()
    );
  };

  async getProjectTemplate() {
    const arr = this.props.history.location.pathname.split("/");
    const projectId = arr[arr.length - 1];
    const users = await this.UserData();
    this.setState({ userId: users._id, token: users.token });
    let requestFrom = "project";
    let requestCheck = "viewSession";
    try {
      const response = await axios.get(
        `${URL}/getProjectTemplate?projectId=${projectId}&requestFrom=${requestFrom}&requestCheck=${requestCheck}`,
        {
          headers: {
            token: `bearer ${users.token}`,
          },
        },
      );
      if (response.data && response.data.status == 200) {
        let listOfPages = [];
        response.data.templates.forEach((page) => {
          listOfPages.push({
            value: page._id,
            label: page.pageName,
          });
        });
        let pageOptions = response.data.templates.map((page) => {
          return {
            value: page._id,
            label: page.pageName,
          };
        });
        this.setState({
          listOfPages,
          pageOptions: [...pageOptions],
        });
      } else {
        throw Error(response.data.error);
      }
    } catch (err) {
      createNotification('error', err.message);
    }
  }

  IncrementCount = (capacityKey) => {
    this.setState({ [capacityKey]: parseInt(this.state[capacityKey]) + 1 });
    if (this.state.capacity === '' || this.state.capacity === undefined) {
      this.setState({
        capacity: 0
      });
    }
    if (this.state.overbookingCapacity === '' || this.state.overbookingCapacity === undefined) {
      this.setState({
        overbookingCapacity: 0
      });
    }
    if (this.state.capacityForEdit === '' || this.state.capacityForEdit === undefined) {
      this.setState({
        capacityForEdit: 0
      });
    }
    if (this.state.overbookingCapacityForEdit === '' || this.state.overbookingCapacityForEdit === undefined) {
      this.setState({
        overbookingCapacityForEdit: 0
      });
    }
  };

  decrementCount = (capacityKey) => {
    if (parseInt(this.state[capacityKey]) > 0) {
      this.setState({ [capacityKey]: parseInt(this.state[capacityKey]) - 1 });
    }
    if (this.state.capacity === '' || this.state.capacity === undefined) {
      this.setState({
        capacity: 0
      });
    }
    if (this.state.overbookingCapacity === '' || this.state.overbookingCapacity === undefined) {
      this.setState({
        overbookingCapacity: 0
      });
    }
    if (this.state.capacityForEdit === '' || this.state.capacityForEdit === undefined) {
      this.setState({
        capacityForEdit: 0
      });
    }
    if (this.state.overbookingCapacityForEdit === '' || this.state.overbookingCapacityForEdit === undefined) {
      this.setState({
        overbookingCapacityForEdit: 0
      });
    }
  };

  handleClickOpenDeleteSessionModal = (id) => {
    this.setState({
      openDeleteSessionModal: true,
      agendaIdForDelete: id,
    });
  };

  handleClickOpenDeleteSessionSpeakerModal = (index) => {
    this.setState({
      deleteSpeakerIndex: index,
      openDeleteSessionSpeakerModal: true,
    });
  };
  handleClickOpenDeleteSessionSpeakerModalEdit = (index) => {
    this.setState({
      deleteSpeakerIndex: index,
      openDeleteSessionSpeakerModalEdit: true
    });
  };

  handleCloseOpenDeleteSessionSpeakerModal = () => {
    this.setState({
      openDeleteSessionSpeakerModal: false
    });
  };
  handleCloseOpenDeleteSessionModal = () => {
    this.setState({
      openDeleteSessionModal: false,
    });
  };

  handleDeleteConfirm = () => {
    try {
      const { agendaIdForDelete, projectId } = this.state;
      let data = {
        agendaIdForDelete,
        permission: "Delete Session",
        lngCode: this.props.users.newLangState,
        projectId,
      };
      this.props.deleteAgenda(data);
      this.setState({
        page_no: 1,
        startCount: 1,
      });
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
    this.setState({
      loading: true
    });
  };

  handleCloseOpenAddSessionForm = () => {
    this.setState({
      openAddSessionForm: false,
      title: '',
      errors: {},
      errs: {},
      chipItems: [],
      trackItems: [],
      hours: 1,
      minutes: 0,
      location: '',
      track: '',
      tags: '',
      capacity: 0,
      agendaURL: '',
      overbookingCapacity: 0,
      dateTime: moment.tz(new Date(), this.props.project?.project?.timezone).format('MM/DD/YYYY hh:mm a'),
      visibility: 'All',
      group: [],
      navigateTo: 'none',
      description: content,
      speakersList: [],
      speakerName: 'Speakers',
      selectedGroup: [],
      showSpeakers: false,
      chipError: null,
      trackError: null,
      agendaImage: ''
    });
  };

  handleClickOpenAddSessionForm = () => {
    this.setState({
      fileNameInS3:'',
      navigateTo: 'none',
      openAddSessionForm: true,
      dateTime: moment.tz(new Date(), this.props.project?.project?.timezone).format('MM/DD/YYYY hh:mm a')
    });
  };

  handleClickOpenAddSpeakerModal = (index) => {
    let editItem = '';

    if (index + 1 >= 1) {
      editItem = this.state.speakersList[index];
    }

    this.setState({
      isSpeakerEdit: index + 1 >= 1,
      isSpeakerEditIndex: index + 1 >= 1 ? index : null,
      speakerFirstName: editItem.speakerFirstName,
      speakerLastName: editItem.speakerLastName,
      speakerJob: editItem.speakerJob,
      speakerImage: editItem.speakerImage ? editItem.speakerImage : '',
      openAddSpeakerModal: true,
      speakerIndexToEdit: index
    });
  };

  handleClickOpenAddSpeakerModalForEdit = (index) => {
    let editItem = '';

    if (index + 1 >= 1) {
      editItem = this.state.speakersListEdit[index];
    }

    this.setState({
      isSpeakerEdit: index + 1 >= 1,
      isSpeakerEditIndex: index + 1 >= 1 ? index : null,
      speakerFirstName: editItem.speakerFirstName,
      speakerLastName: editItem.speakerLastName,
      speakerJob: editItem.speakerJob,
      speakerImage: editItem.speakerImage ? editItem.speakerImage : '',
      openAddSpeakerModalForEdit: true,
      speakerIndexToEdit: index
    });
  };

  handleCloseOpenAddSpeakerModal = () => {
    this.setState({
      openAddSpeakerModal: false,
      speakerFirstName: '',
      speakerLastName: '',
      speakerJob: '',
      speakerImage: '',
      errors: {},
      errs: {},
    });
  };

  handleCloseOpenAddSpeakerModalForEdit = () => {
    this.setState({
      openAddSpeakerModalForEdit: false,
      speakerFirstName: '',
      speakerLastName: '',
      speakerJob: '',
      speakerImage: '',
      errors: {},
      errs: {},
    });
  };

  handleCloseOpenEditSessionForm = () => {
    this.setState(
      {
        openEditSessionForm: false,
        errors: {},
        errs: {},
        tags: [],
        track: [],
        chipError: '',
        trackError: '',
      },
      () => this.listAgenda(),
    );
  };

  handleClickOpenEditSessionForm = (agenda) => {
    const { agendaIdForEdit } = this.state;
    let listSpeaker = null;
    let selectedGroup = agenda.visibility.groups.map(group => {
      return {
        _id: group._id,
        label: group.attendeesGroupName,
        value: group.attendeesGroupName,
        source: group.source,
        showToGroup: group.showToGroup
      };
    });

    if (agenda.speakers && agenda.speakers.speakersList.length) {
      listSpeaker = agenda.speakers.speakersList.map((list) => {
        return {
          speakerFirstName: list.firstName,
          speakerLastName: list.lastName,
          speakerJob: list.jobTitle,
          speakerImage: list.imageUrl,
        };
      });
    }
    this.setState({
      selectedGroup,
      agendaIdForEdit: agenda._id,
      openEditSessionForm: true,
      titleForEdit: agenda.title,
      tagsForEditItem: agenda.tags,
      hoursForEdit: agenda.duration.hours,
      minutesForEdit: agenda.duration.minutes,
      locationForEdit: agenda.location,
      tracksForEditItem: agenda.track,
      visibilityTypeForEdit: agenda.visibility.visibilityType,
      groupsForEdit: agenda.visibility.groups,
      navigateToForEdit: agenda?.navigateTo?.navigateType,
      pagesForEdit: agenda?.navigateTo?.pageId?._id,
      descriptionForEdit: agenda.description,
      agendaURLForEdit: agenda.navigateTo.agendaURL,
      capacityForEdit: agenda.capacity,
      overbookingCapacityForEdit: agenda.overbookingCapacity,
      dateTimeForEdit: moment.tz(agenda.dateTime, this.props.project?.project?.timezone).format("MM/DD/YYYY hh:mm a"),
      speakerNameEdit: agenda.speakers?.speakersLabel,
      speakersListEdit: listSpeaker ? listSpeaker : [],
      showSpeakersButton: !!agenda.speakers?.speakersList?.length,
      agendaImageForEdit: agenda.agendaImage
    });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  updatePaginationDropDownState() {
    if (this.state.itemCount >= this.props.agenda.totalResults) {
      this.setState({
        endCount: this.props.agenda.totalResults
      });
    } else {
      this.setState({
        endCount: this.props.agenda.totalResults > (this.state.itemCount * this.state.page_no) ? (this.state.itemCount * this.state.page_no) : this.props.agenda.totalResults
      });
    }
  }

  handleChipsKeyDownTags = (evt) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();
      var value = this.state.tags.trim();
      if (value && this.isChipValid(value)) {
        this.setState({
          chipItems: [...this.state.chipItems, this.state.tags],
          tags: '',
        });
      }
    }
  };

  handleChipsKeyDownTagsEdit = (evt) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      var value = this.state.tags.trim();
      if (value && this.isChipValidEdit(value)) {
        this.setState({
          tagsForEditItem: [
            ...this.state.tagsForEditItem,
            this.state.tags,
          ],
          tags: '',
        });
      }
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const projectId = getProjectIdFromUrl();
    const { page_no, searchText } = this.state;
    if (searchText.trim() !== "" && searchText !== undefined) {
      const body = {
        projectId,
        searchText: searchText,
        page_no: page_no,
      };
      this.setState({
        page_no: 1
      }, () => {
        this.listAgenda(true);
      });
      this.handleLoading();
    }
    else {
      this.listAgenda();
    }
  };



  handleSpeaker = (e) => {
    if (e.target.checked) {
      this.setState({
        showSpeakers: e.target.checked,
      });
    } else {
      this.setState({
        showSpeakers: false,
      });
    }
  };

  handleSpeakerEdit = (e) => {
    if (e.target.checked) {
      this.setState({
        showSpeakersButton: e.target.checked,
      });
    } else {
      this.setState({
        showSpeakersButton: false,
      });
    }
  };

  isChipValid(value) {
    let chipError = null;
    let isTagValid;
    if (this.isInChipsList(value)) {
      chipError = `${value} has already been added.`;
      isTagValid = false;
    } else {
      chipError = null;
      isTagValid = true;
    }
    this.setState({ chipError });
    return isTagValid;
  }

  isChipValidEdit(value) {
    let chipError = null;
    let isTagValid;
    if (this.isInChipsListEdit(value)) {
      chipError = `${value} has already been added.`;
      isTagValid = false;
    } else {
      chipError = null;
      isTagValid = true;
    }
    this.setState({ chipError });
    return isTagValid;
  }

  UserData = async () => {
    const currentPath = window.location.pathname;
    if (currentPath.includes('member')) {
      return JSON.parse(localStorage.getItem('member_details'));
    } else {
      return JSON.parse(localStorage.getItem('user_details'));
    }
  };

  isInChipsList(value) {
    return this.state.chipItems.map(v => v.toLowerCase()).includes(value.toLowerCase());
  }

  isInChipsListEdit(value) {
    return this.state.tagsForEditItem.map(v => v.toLowerCase()).includes(value.toLowerCase());
  }

  handleChipPaste = (evt) => {
    evt.preventDefault();
    var paste = evt.clipboardData.getData('text');
    var emails = paste.match('^[a-zA-Z ]*$');
    if (this.state.chipItems && emails) {
      var toBeAdded = emails.filter((email) => !this.isInChipsList(email) && email.trim() !== "");
      this.setState({
        chipItems: [...this.state.chipItems, ...toBeAdded],
      });
    }
  };

  handleChipPasteEdit = (evt) => {
    evt.preventDefault();
    var paste = evt.clipboardData.getData('text');
    var emails = paste.match('^[a-zA-Z ]*$');
    if (this.state.tagsForEditItem && emails) {
      var toBeAdded = emails.filter((email) => !this.isInChipsListEdit(email) && email.trim() !== "");
      this.setState({
        tagsForEditItem: [...this.state.tagsForEditItem, ...toBeAdded],
      });
    }
  };

  handleChipDelete = (item) => {
    this.setState({
      chipItems: this.state.chipItems.filter((i) => i !== item),
    });
  };

  handleChipDeleteEdit = (item) => {
    this.setState({
      tagsForEditItem: this.state.tagsForEditItem.filter((i) => i !== item),
    });
  };

  handleTrackKeyDown = (evt) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();
      var value = this.state.track.trim();
      if (value && this.isTrackValid(value)) {
        this.setState({
          trackItems: [...this.state.trackItems, this.state.track],
          track: '',
        });
      }
    }
  };

  handleTrackKeyDownEdit = (evt) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();
      var value = this.state.track.trim();
      if (value && this.isTrackValidEdit(value)) {
        this.setState({
          tracksForEditItem: [
            ...this.state.tracksForEditItem,
            this.state.track,
          ],
          track: '',
        });
      }
    }
  };

  isTrackValid(value) {
    let trackError = null;
    let isTrackValid;
    if (this.isInTrackList(value)) {
      trackError = `${value} has already been added.`;
      isTrackValid = false;
    } else {
      trackError = null;
      isTrackValid = true;
    }
    this.setState({ trackError });
    return isTrackValid;
  }

  isTrackValidEdit(value) {
    let trackError = null;
    let isTrackValid;
    if (this.isInTrackListEdit(value)) {
      trackError = `${value} has already been added.`;
      isTrackValid = false;
    } else {
      trackError = null;
      isTrackValid = true;
    }
    this.setState({ trackError });
    return isTrackValid;
  }

  isInTrackList(value) {
    return this.state.trackItems.map(v => v.toLowerCase()).includes(value.toLowerCase());
  }

  isInTrackListEdit(value) {
    return this.state.tracksForEditItem.map(v => v.toLowerCase()).includes(value.toLowerCase());
  }

  handleTrackPaste = (evt) => {
    evt.preventDefault();
    var paste = evt.clipboardData.getData('text');
    var emails = paste.match('^[a-zA-Z ]*$');
    if (this.state.trackItems && emails) {
      var toBeAdded = emails.filter((email) => !this.isInTrackList(email) && email.trim() !== "");
      this.setState({
        trackItems: [...this.state.trackItems, ...toBeAdded],
      });
    }
  };

  handleTrackPasteEdit = (evt) => {
    evt.preventDefault();
    var paste = evt.clipboardData.getData('text');
    var emails = paste.match('^[a-zA-Z ]*$');
    if (this.state.tracksForEditItem && emails) {
      var toBeAdded = emails.filter((email) => !this.isInTrackListEdit(email) && email.trim() !== "");
      this.setState({
        tracksForEditItem: [...this.state.tracksForEditItem, ...toBeAdded],
      });
    }
  };

  handleTrackDelete = (item) => {
    this.setState({
      trackItems: this.state.trackItems.filter((i) => i !== item),
    });
  };

  handleTrackDeleteEdit = (item) => {
    this.setState({
      tracksForEditItem: this.state.tracksForEditItem.filter((i) => i !== item),
    });
  };

  changeDate = (e) => {
    this.setState({
      dateTime: e,
    });
  };
  ChangeDateEdit = (e) => {
    this.setState({
      dateTimeForEdit: e,
    });
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };
  handleEditorChange = (content) => {
    this.setState({
      description: content,
    });
  };

  handleEditorChangeEdit = (contentEdit) => {
    this.setState({
      descriptionForEdit: contentEdit,
    });
  };

  // speakers all Functions
  isNewSpeakerValid() {
    const {
      speakerFirstName,
      speakerLastName,
      speakersListEdit,
      speakerIndexToEdit,
      openAddSpeakerModalForEdit,
      speakersList
    } = this.state;
    let formIsValid = true;
    let error = {};

    if (speakerFirstName === '' || !speakerFirstName || speakerFirstName.trim().length === 0) {
      formIsValid = false;
      error['speakerFirstName'] = this.props.users.languageStateForRedux?.validations?.enterFirstName;
    }
    if (speakerLastName === '' || !speakerLastName || speakerLastName.trim().length === 0) {
      formIsValid = false;
      error['speakerLastName'] = this.props.users.languageStateForRedux?.validations?.enterLastName;
    }

    if (openAddSpeakerModalForEdit) {
      let doesNameAlreadyExist = speakersListEdit.some((el, i) => {
        if (i !== speakerIndexToEdit) {
          return el.speakerFirstName.trim() === speakerFirstName.trim() && el.speakerLastName.trim() === speakerLastName.trim();
        }
      });
      if (doesNameAlreadyExist) {
        formIsValid = false;
        error['speakerFirstName'] = "Speaker's name already exists";
      }
    } else {
      let doesNameAlreadyExist = speakersList.some((el, i) => {
        if (i !== speakerIndexToEdit) {
          return el.speakerFirstName.trim() === speakerFirstName.trim() && el.speakerLastName.trim() === speakerLastName.trim();
        }
      });
      if (doesNameAlreadyExist) {
        formIsValid = false;
        error['speakerFirstName'] = "Speaker's name already exists";
      }
    }

    this.setState({
      errors: error,
      speakerIndexToEdit: null
    });
    return formIsValid;
  }

  addNewSpeaker = () => {
    const {
      speakerFirstName,
      speakerLastName,
      speakerJob,
      speakerImage,
      isSpeakerEdit,
      isSpeakerEditIndex
    } = this.state;
    let speakersList = this.state.speakersList;

    if (this.isNewSpeakerValid()) {
      let speaker = {
        speakerFirstName,
        speakerLastName,
        speakerJob,
        speakerImage
      };

      if (isSpeakerEdit) {
        speakersList[isSpeakerEditIndex] = speaker;
      } else {
        speakersList = [...speakersList, speaker];
      }

      this.setState({
        isSpeakerEdit: false,
        isSpeakerEditIndex: null,
        speakersList,
      });
      this.handleCloseOpenAddSpeakerModal();
    }
  };

  addNewSpeakerEdit = () => {
    const {
      speakerFirstName,
      speakerLastName,
      speakerJob,
      speakerImage,
      isSpeakerEdit,
      isSpeakerEditIndex,
    } = this.state;
    let speakersListEdit = this.state.speakersListEdit;

    if (this.isNewSpeakerValid()) {
      let speaker = {
        speakerFirstName,
        speakerLastName,
        speakerJob: speakerJob?.trim(),
        speakerImage
      };

      if (isSpeakerEdit) {
        speakersListEdit[isSpeakerEditIndex] = speaker;
      } else {
        speakersListEdit = [...speakersListEdit, speaker];
      }

      this.setState({
        isSpeakerEdit: false,
        isSpeakerEditIndex: null,
        speakersListEdit,
      });
      this.handleCloseOpenAddSpeakerModalForEdit();
    }
  };

  handleDeleteConfirmSpeaker = () => {
    const list = this.state.speakersList;
    const index = this.state.deleteSpeakerIndex;
    list.splice(index, 1);

    this.setState({
      deleteSpeakerIndex: null,
      speakersList: list,
      openDeleteSessionSpeakerModal: false,
    });
  };

  handleDeleteConfirmSpeakerEdit = () => {

    const list = this.state.speakersListEdit;
    const index = this.state.deleteSpeakerIndex;
    list.splice(index, 1);

    this.setState({
      deleteSpeakerIndex: null,
      speakersListEdit: list,
      openDeleteSessionSpeakerModalEdit: false,
    });
  };
  isValidAgendaForm = () => {
    try {
      var float = /^\d+$/;
      const isDateValid = (...val) => !Number.isNaN(new Date(...val).valueOf());
      const regexUrl = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
      var decimal = /^[-+]?[0-9]+\.[0-9]+$/;
      const {
        title,
        minutes,
        speakersList,
        location,
        hours,
        dateTime,
        capacity,
        overbookingCapacity,
        agendaURL,
        navigateTo,
        description,
        showDescription,
        showSpeakers,
        selectedGroup,
        visibility,
        speakerName,
        pages,
      } = this.state;
      let formIsValid = true;
      let error = {};
      let err = {};
      if (title.trim().length === 0 || title === '' || title === undefined) {
        formIsValid = false;
        error['title'] = this.props.users.languageStateForRedux?.validations?.fillTitle;
      }

      if (visibility === 'Groups') {
        if (selectedGroup.length === 0) {
          formIsValid = false;
          error['selectedGroup'] = 'Please select group.';
        }
      }

      if (navigateTo === 'projectPages' && (pages === null || !pages)) {
        formIsValid = false;
        error['pages'] = 'Please select page.';
      }


      if (dateTime === '' || dateTime === null) {
        formIsValid = false;
        error['dateTime'] = 'Please select dateTime date.';
      }
      if (!isDateValid(dateTime)) {
        formIsValid = false;
        error['dateTime'] = 'Please select valid dateTime .';
      }

      if (hours === '' || hours === null) {
        formIsValid = false;
        error['hours'] = 'Enter hours';
      }

      if (hours <= 0 && minutes <= 0) {
        formIsValid = false;
        err['hours'] = 'Please enter duration';
      }

      if (minutes === '' || minutes === null) {
        formIsValid = false;
        error['minutes'] = 'Enter minutes';
      }

      if (showSpeakers === true) {
        if (!speakersList.length) {
          formIsValid = false;
          error['speakersList'] = this.props.users.languageStateForRedux?.validations?.addSpeakers;
        }
      }
      if (capacity === '' || capacity === 0 || capacity.length === 0 || capacity <= 0) {
        formIsValid = false;
        error['capacity'] = 'Please enter capacity.';
      }

      if (capacity <= 0) {
        formIsValid = false;
        error['capacity'] = this.props.users.languageStateForRedux?.validations?.capacity1;
      }

      if (overbookingCapacity < 0) {
        formIsValid = false;
        error['overbookingCapacity'] = 'Please enter overbookingCapacity greater then 0 ';
      }


      if (!Number.isInteger(Number(capacity))) {
        formIsValid = false;
        error['capacity'] = 'Please enter integer value ';
      }

      if (!Number.isInteger(Number(overbookingCapacity))) {
        formIsValid = false;
        error['overbookingCapacity'] = 'Please enter integer value ';
      }


      if (hours > 78 || hours < 0) {
        formIsValid = false;
        error['hours'] = 'Please enter between 0-78 hours ';
      }
      if (minutes > 59 || minutes < 0) {
        formIsValid = false;
        error['minutes'] = 'Please enter between 0-59  minutes ';
      }

      if (!Number.isInteger(Number(hours))) {
        formIsValid = false;
        error['hours'] = 'Please enter integer value ';
      }

      if (!Number.isInteger(Number(minutes))) {
        formIsValid = false;
        error['minutes'] = 'Please enter integer value ';
      }

      if (
        navigateTo === 'redirectExternalURL' &&
        (agendaURL.trim().length === 0 ||
          agendaURL === '' ||
          agendaURL === undefined)
      ) {
        formIsValid = false;
        error['agendaURL'] = 'Please enter the agendaURL';
      }
      if (agendaURL && regexUrl.test(agendaURL) === false) {
        formIsValid = false;
        error['agendaURL'] = 'Please enter a valid agendaURL ';
      }
      if (!parseToHtml(description) || parseToHtml(description).trim().length === 0) {
        formIsValid = false;
        error['description'] = this.props.users.languageStateForRedux?.validations?.desc;
      }
      this.setState({
        errors: error,
        chipError: null,
        tags: '',
        trackError: null,
        track: '',
        errs: err
      });

      return formIsValid;
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  // valiadtion for edit agenda

  isValidEditAgendaForm = () => {
    try {
      const removeHtmlTagsRegex = /<(.|\n)*?>/;
      const isDateValid = (...val) => !Number.isNaN(new Date(...val).valueOf());
      const regexUrl = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
      var decimal = /[+-]?([0-9]*[.])?[0-9]+/;
      const {
        titleForEdit,
        hoursForEdit,
        minutesForEdit,
        speakersListEdit,
        locationForEdit,
        navigateToForEdit,
        showDescriptionForEdit,
        agendaURLForEdit,
        capacityForEdit,
        overbookingCapacityForEdit,
        descriptionForEdit,
        dateTimeForEdit,
        speakerNameEdit,
        showSpeakersButton,
        visibilityTypeForEdit,
        selectedGroup,
        pagesForEdit,

      } = this.state;
      let formIsValid = true;
      let error = {};
      let err = {};
      if (
        titleForEdit.trim().length === 0 ||
        titleForEdit === '' ||
        titleForEdit === undefined
      ) {
        formIsValid = false;
        error['titleForEdit'] = this.props.users.languageStateForRedux?.validations?.fillTitle;
      }
      if (visibilityTypeForEdit === 'Groups') {
        if (selectedGroup.length === 0) {
          formIsValid = false;
          error['selectedGroup'] = 'Please select group.';
        }
      }

      if (navigateToForEdit === 'projectPages' && (pagesForEdit === null || !pagesForEdit)) {
        formIsValid = false;
        error['pagesForEdit'] = 'Please select page.';
      }

      if (dateTimeForEdit === '' || dateTimeForEdit === null) {
        formIsValid = false;
        error['dateTimeForEdit'] = 'Please select dateTime date.';
      }
      if (!isDateValid(dateTimeForEdit)) {
        formIsValid = false;
        error['dateTimeForEdit'] = 'Please select valid dateTime .';
      }
      if (showSpeakersButton === true) {
        if (!speakersListEdit.length) {
          formIsValid = false;
          error['speakersListEdit'] = this.props.users.languageStateForRedux?.validations?.addSpeakers;
        }
      }

      if (capacityForEdit === '' || capacityForEdit === 0) {
        formIsValid = false;
        error['capacityForEdit'] = 'Please enter capacity.';
      }

      if (capacityForEdit <= 0) {
        formIsValid = false;
        error['capacityForEdit'] = this.props.users.languageStateForRedux?.validations?.capacity1;
      }

      if (overbookingCapacityForEdit < 0) {
        formIsValid = false;
        error['overbookingCapacityForEdit'] = 'Please enter overbookingCapacity greater then 0 ';
      }

      if (!Number.isInteger(Number(overbookingCapacityForEdit))) {
        formIsValid = false;
        error['overbookingCapacityForEdit'] = 'Please enter integer value ';
      }

      if (!Number.isInteger(Number(capacityForEdit))) {
        formIsValid = false;
        error['capacityForEdit'] = 'Please enter integer value ';
      }

      if (!Number.isInteger(Number(hoursForEdit))) {
        formIsValid = false;
        error['hoursForEdit'] = 'Please enter integer value ';
      }

      if (!Number.isInteger(Number(minutesForEdit))) {
        formIsValid = false;
        error['minutesForEdit'] = 'Please enter integer value ';
      }

      if (hoursForEdit <= 0 && minutesForEdit <= 0) {
        formIsValid = false;
        err['hoursForEdit'] = 'Please enter duration';
      }


      if (hoursForEdit > 78 || hoursForEdit < 0) {
        formIsValid = false;
        error['hoursForEdit'] = 'Please enter between 0-78 hours ';
      }
      if (minutesForEdit > 59 || minutesForEdit < 0) {
        formIsValid = false;
        error['minutesForEdit'] = 'Please enter between 0-59 minutes ';
      }

      if (hoursForEdit === '' || hoursForEdit === null) {
        formIsValid = false;
        error['hoursForEdit'] = 'enter hours';
      }
      if (minutesForEdit === '' || minutesForEdit === null) {
        formIsValid = false;
        error['minutesForEdit'] = 'enter minutes';
      }

      if (
        navigateToForEdit === 'redirectExternalURL' &&
        (agendaURLForEdit.trim().length === 0 ||
          agendaURLForEdit === '' ||
          agendaURLForEdit === undefined)
      ) {
        formIsValid = false;
        error['agendaURLForEdit'] = 'Please enter the agendaURL';
      }
      if (agendaURLForEdit && regexUrl.test(agendaURLForEdit) === false) {
        formIsValid = false;
        error['agendaURLForEdit'] = 'Please enter a valid agendaURL ';
      }
      if (!parseToHtml(descriptionForEdit) || parseToHtml(descriptionForEdit).trim().length === 0) {
        formIsValid = false;
        error['descriptionForEdit'] = this.props.users.languageStateForRedux?.validations?.desc;
      }

      this.setState({
        errors: error,
        chipError: null,
        tags: '',
        trackError: null,
        track: '',
        errs: err
      });
      return formIsValid;
    } catch (error) {
      createNotification('error', error.message);
    }
  };

  handlePageChange = (pageNumber) => {
    try {
      const { itemCount } = this.state;
      let totalResults = this.props.agenda.totalResults;
      this.setState((prevState, props) => ({
        startCount: pageNumber !== 1 ? (itemCount * (pageNumber - 1) + 1) : 1,
        endCount: totalResults > itemCount * pageNumber ? itemCount * pageNumber : totalResults,
        page_no: pageNumber,
        pageHasChanged: true,
      }), () => {
        this.listAgenda();
      });
    } catch (error) {
      createNotification("error", "something went wrong");
    }
  };

  handleAddAgenda = (e) => {
    try {
      this.setState({
        page_no: 1
      });
      if (this.isValidAgendaForm()) {
        const {
          title,
          registeredGuests,
          dateTime,
          visibility,
          hours,
          minutes,
          pages,
          location,
          navigateTo,
          capacity,
          overbookingCapacity,
          description,
          agendaURL,
          speakersList,
          trackItems,
          chipItems,
          listOfGroups,
          speakerName,
          selectedGroup,
          agendaImage
        } = this.state;

        const speakersListItem = speakersList.map((speaker) => {
          let lv_speakerImage;
          if ((typeof speaker.speakerImage) === 'object') {
            lv_speakerImage = 'image added';
          } else {
            lv_speakerImage = '';
          }
          return {
            firstName: speaker.speakerFirstName,
            lastName: speaker.speakerLastName,
            jobTitle: speaker.speakerJob,
            imageUrl: lv_speakerImage
          };
        });

        const eventId = getProjectIdFromUrl();
        const formData = new FormData();
        formData.append('title', title.replace(/\s{2,}/g, ' ').trim());
        formData.append('registeredGuests', registeredGuests);
        formData.append('dateTime', convertTZ(dateTime, this.props.project?.project?.timezone));
        formData.append('hours', hours);
        formData.append('minutes', minutes);
        formData.append('location', location.trim());
        formData.append('track', JSON.stringify(trackItems));
        formData.append('capacity', capacity);
        formData.append('tags', JSON.stringify(chipItems));
        formData.append('overbookingCapacity', overbookingCapacity);
        formData.append('description', description);
        formData.append('agendaURL', agendaURL);
        formData.append('projectId', eventId);
        formData.append('destinationFolder', this.state.destinationFolder);
        formData.append('fileNameInS3', this.state.fileNameInS3);

        formData.append('visibility[visibilityType]', visibility);
        selectedGroup.map((group) => {
          formData.append('visibility[groups]', group._id);
        });
        formData.append('navigateTo[navigateType]', navigateTo);
        if (navigateTo === 'redirectExternalURL') {
          formData.append('navigateTo[agendaURL]', agendaURL);
        }
        if (navigateTo === 'projectPages') {
          formData.append('navigateTo[pageId]', pages);
        }

        if (speakersListItem.length) {
          formData.append('speakers[speakersLabel]', speakerName);
          formData.append('speakers[speakersList]', JSON.stringify(speakersListItem));

          speakersList.map((speaker) => {
            formData.append('speakerImages', speaker.speakerImage);
          });
        }

        formData.append('agendaImage', agendaImage);
        formData.append('permission', "Create Session");
        formData.append('lngCode', this.props.users.newLangState);

        this.props.addAgenda(formData);
        this.setState({
          loading: true
        });
      }
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  handleEditAgenda = (e) => {
    try {
      if (this.isValidEditAgendaForm()) {
        const {
          titleForEdit,
          capacityForEdit,
          pagesForEdit,
          agendaURLForEdit,
          minutesForEdit,
          descriptionForEdit,
          hoursForEdit,
          visibilityTypeForEdit,
          navigateToForEdit,
          overbookingCapacityForEdit,
          tracksForEditItem,
          locationForEdit,
          tagsForEditItem,
          agendaIdForEdit,
          dateTimeForEdit,
          groupsForEdit,
          selectedGroup,
          speakersListEdit,
          speakerNameEdit,
          showSpeakersButton,
          agendaImageForEdit,
          registeredGuestsForEdit,
          showDescriptionForEdit
        } = this.state;

        let speakersListEditCustom = [];

        if (speakersListEdit.length) {
          speakersListEditCustom = speakersListEdit.map((list) => {
            let lv_speakerImage;
            if ((typeof list.speakerImage) === 'object') {
              lv_speakerImage = 'image added';
            } else {
              lv_speakerImage = list.speakerImage;
            }
            return {
              imageUrl: lv_speakerImage,
              firstName: list.speakerFirstName,
              lastName: list.speakerLastName,
              jobTitle: list.speakerJob,
            };
          });
        }

        const formData = new FormData();
        formData.append('id', agendaIdForEdit);
        formData.append('title', titleForEdit.replace(/\s{2,}/g, ' ').trim());
        formData.append('registeredGuests', registeredGuestsForEdit);
        formData.append('dateTime', convertTZ(dateTimeForEdit, this.props.project?.project?.timezone));
        formData.append('duration[hours]', hoursForEdit);
        formData.append('duration[minutes]', minutesForEdit);
        formData.append('location', locationForEdit.trim());
        formData.append('track', JSON.stringify(tracksForEditItem));
        formData.append('capacity', capacityForEdit);
        formData.append('tags', JSON.stringify(tagsForEditItem));
        formData.append('overbookingCapacity', overbookingCapacityForEdit);
        formData.append('description', descriptionForEdit);
        formData.append('destinationFolder', this.state.destinationFolder);
        formData.append('fileNameInS3', this.state.fileNameInS3);

        formData.append('visibility[visibilityType]', visibilityTypeForEdit);
        selectedGroup.map((group) => {
          formData.append('visibility[groups]', group._id);
        });

        formData.append('navigateTo[navigateType]', navigateToForEdit);
        if (navigateToForEdit === 'redirectExternalURL') {
          formData.append('navigateTo[agendaURL]', agendaURLForEdit);
          formData.append('navigateTo[pageId]', '');
        }

        if (navigateToForEdit === 'projectPages') {
          formData.append('navigateTo[agendaURL]', '');
          formData.append('navigateTo[pageId]', pagesForEdit._id ? pagesForEdit._id : pagesForEdit);
        }

        if (showSpeakersButton) {
          formData.append('speakers[speakersLabel]', speakerNameEdit);
          formData.append('speakers[speakersList]', JSON.stringify(speakersListEditCustom));
        } else {
          formData.append('speakers', '');
        }

        speakersListEdit.map((speaker) => {
          formData.append('speakerImages', speaker.speakerImage);
        });

        const projectId = getProjectIdFromUrl();
        formData.append('agendaImage', agendaImageForEdit);
        formData.append('projectId', projectId);
        formData.append('permission', "Edit Session");
        formData.append('lngCode', this.props.users.newLangState);

        this.props.editAgenda(formData);
        this.setState({
          loading: false
        });
      }
    } catch (error) {
      createNotification('error', error.message);
    }

  };

  onSpeakerImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];
      if (!imageFile.name.match(/\.(jpg|jpeg|gif|png)$/i)) {
        createNotification('error', 'Please select valid image file.');
        return false;
      }
      if (imageFile.size > 5000000) { //5 mb
        createNotification('error', this.props.users.languageStateForRedux?.common?.imageSizeErrMsg);
        return false;
      }
      this.setState({
        speakerImageToCrop: event.target.files[0],
        cropOpen: true,
      });
    }
  };

  saveSpeakerImage = () => {
    this.setState({
      speakerImage: this.state.cropPic,
      cropOpen: false,
    });
  };

  onAgendaImageChange = (event, method) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];

      if (!imageFile.name.match(/\.(jpg|jpeg|gif|png)$/i)) {
        createNotification('error', 'Please select valid image file.');
        return false;
      }

      if (imageFile.size > 5242880) { //5 mb
        createNotification('error', this.props.users.languageStateForRedux?.common?.imageSizeErrMsg);
        return false;
      }

      if (method === 'add') {
        this.setState({ agendaImage: event.target.files[0] });

        let formData = new FormData();

        formData.append('imageFile', event.target.files[0]);
        formData.append('section', section);
        formData.append('type', FILE_TYPE.file);
        formData.append('parent', this.state.parentId);
        this.props.addImagesRequest((formData));

      } else {
        this.setState({ agendaImageForEdit: event.target.files[0] });
      }
    }
  };

  sorting = (value) => {
    if (this.state.sort_by === value) {
      if (this.state.order === "asc") {
        this.setState({
          order: "desc"
        }, () => this.listAgenda()
        );
      } else {
        this.setState({
          order: "asc"
        }, () => this.listAgenda()
        );
      }
    } else {
      this.setState({
        sort_by: value,
        order: "asc"
      }, () => {
        this.listAgenda();
      }
      );
    }
  };

  showRestOfTracks = (track) => {
    let trackLength = track.length;
    return track.slice(1, trackLength);
  };

  showRestOfTags = (tag) => {
    let tagLength = tag.length;
    return tag.slice(1, tagLength);
  };

  showRestOfVisibilityGroups = (groups) => {
    let groupsLength = groups.length;
    return groups.slice(1, groups.length);
  };

  removeAgendaImageClick = () => {
    this.setState({
      agendaImage: '',
      agendaImageForEdit: '',
      fileNameInS3:''
    });
  };

  replicateAgenda = async (agenda) => {
    const projectId = getProjectIdFromUrl();
    try {
      const data = {
        agendaId: this.state.agendaIdToReplicate,
        projectId,
        lngCode: this.props.users.newLangState
      };
      this.props.replicateAgenda(data);
      this.setState({
        loading: true
      });
    } catch (error) {
      return error;
    }
  };

  handleOpenReplicateModal = (agenda) => {
    this.setState({
      openReplicateModal: true,
      agendaIdToReplicate: agenda._id
    });
  };

  closeReplicateModal = () => {
    this.setState({
      openReplicateModal: false,
      agendaIdToReplicate: ''
    });
  };

  customOptionLabel = (prop) => {
    return <div>
      {prop.source === "GoogleWorkspace" && prop.showToGroup && <img src={GoogleWorkspace} alt="google workspace" width="14px" style={{ marginRight: "5px" }} />}
      <span>{prop.label}</span>
    </div>;
  };

  removeSpeakerImage = () => {
    this.setState({
      speakerImage: '',
      speakerImageAdded: ''
    });
  };

  getData = (val) => {
    this.setState({ cropPic: val });
  };

  onCloseCropModal = () => {
    this.setState({
      cropOpen: false
    });
  };

  uploadFromFiles = () => {
    this.setState({
      uploadFromFiles: true,
      buttonState: false
    });
  };

  handleCloseFileModulePopup = () => {
    this.setState({
      uploadFromFiles: false,
      buttonState: true
    });
  };

  handleChoosedFile = (file) => {
    if(this.state.openAddSpeakerModal){ // Add speaker image
      this.setState({
        speakerImageToCrop:file,
        cropOpen:true
      })
    }
    else if(this.state.openAddSessionForm){ // Add session image
      this.setState({
        agendaImage: file,
        fileNameInS3: file?.fileNameInS3
      })
    }else if(this.state.openEditSessionForm && this.state.openAddSpeakerModalForEdit){ 
      this.setState({
        speakerImageToCrop:file,
        cropOpen:true
      })
    }else{
      this.setState({
        agendaImageForEdit: file,
        fileNameInS3: file?.fileNameInS3
      })
      
    }
    this.setState({
      s3File:file,
      destinationFolder: "agendaImages",
      uploadFromFiles: false,
      displayS3Image: true
    });

  };


  chooseAgendaImageClick = (id) => { 
    this.setState({
      parentId: id
    });
    this.chooseAgendaImage.current.click();
  };

  handleFileChosen = (file) =>{
    this.setState({fileNameInS3:file?.fileNameInS3})
  }

  render() {
    const {
      listOfAgendas,
      searchText,
      isGroupsComp,
      startCount,
      endCount,
      itemCount,
      page_no,
      title,
      tags,
      capacity,
      overbookingCapacity,
      track,
      location,
      visibility,
      hours,
      minutes,
      navigateTo,
      pages,
      agendaURL,
      dateTime,
      selectedAgenda,
      titleForEdit,
      hoursForEdit,
      minutesForEdit,
      locationForEdit,
      visibilityTypeForEdit,
      navigateToForEdit,
      pagesForEdit,
      agendaURLForEdit,
      capacityForEdit,
      overbookingCapacityForEdit,
      dateTimeForEdit,
      speakerFirstName,
      speakerLastName,
      speakerJob,
      speakerImage,
      agendaImage,
      displayS3Image,
      userId,
      token,
      fileNameInS3,
      agendaImageForEdit,
      listOfGroups,
      pageOptions,
      groupsForEdit,
      showDescriptionForEdit,
      descriptionForEdit,
      speakersListEdit,
      openAddSpeakerModal,
      sort_by,
      order,
      allowed,
    } = this.state;

    const styles = {
      speakerImage: {
        backgroundColor: '#FF4170',
        width: theme.spacing(6),
        height: theme.spacing(6),
      },
      speakerImageAdded: {
        backgroundColor: '#FFF',
        width: theme.spacing(6),
        height: theme.spacing(6),
      },
      avatar: {
        // backgroundColor: '#d360ef',
        width: theme.spacing(6),
        height: theme.spacing(6),
        borderRadius: '8px',
      },
    };

    const chooseSpeakerImage = React.createRef(null);
    const chooseSpeakerImageClick = () => {
      chooseSpeakerImage.current.click();
    }; 

    return (
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} mb={2} className="top-filter-area">
          <Grid item>
            <Box
              component={Button}
              variant="contained"
              sx={{
                backgroundColor: theme.palette.background.light,
                color: theme.palette.common.black,
                textTransform: 'capitalize',
                fontWeight: '400',

                '&:hover, &.active': {
                  backgroundColor: theme.palette.primary.dark,
                  color: theme.palette.primary.contrastText,
                },
              }}
              className="active"
              size="small"
              disableElevation
              aria-haspopup="true"
            >
              {this.props.users.languageStateForRedux?.common?.all} ({this.state.totalResults})
            </Box>
          </Grid>
          <Grid item>
            <Grid container spacing={1.25} className="team-dash-right">
              <Grid item className="">
                <form onSubmit={this.handleSubmit}>
                  <TextField
                    name="searchText"
                    placeholder={this.props.users.languageStateForRedux?.common?.search}
                    className="search"
                    size="small"
                    value={searchText}
                    onChange={this.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img alt="" src={SearchIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <button className='d-none' type="submit">
                    <img src={SearchIconBar} alt="" />
                  </button>
                </form>
              </Grid>

              {allowed['Create Session'] &&
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => this.handleClickOpenAddSessionForm()}
                  >
                    {this.props.users.languageStateForRedux?.buttons?.agenda?.add}
                  </Button>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
        <TableContainer className="list-table" style={{ maxHeight: 'calc(100vh - 380px)', }}>
          <Table className="table">
            <TableHead style={{ position: 'sticky', top: '0', backgroundColor: '#f6f6fc', zIndex: 2, }}>
              <TableRow>
                <TableCell
                  className={
                    'long-title sticky-cell ' +
                    (sort_by === 'title'
                      ? order === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                  onClick={() => this.sorting('title')}>{this.props.users.languageStateForRedux?.column_names?.agenda?.title} </TableCell>
                <TableCell
                  className={
                    'date-time ' +
                    (sort_by === 'dateTime'
                      ? order === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                  onClick={() => this.sorting('dateTime')}>
                  {this.props.users.languageStateForRedux?.column_names?.agenda?.dateTime}
                </TableCell>
                <TableCell
                  className={
                    'text ' +
                    (sort_by === 'duration'
                      ? order === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                  onClick={() => this.sorting('duration')}
                >{this.props.users.languageStateForRedux?.column_names?.agenda?.duration}
                </TableCell>
                <TableCell
                  className={
                    'long-text ' +
                    (sort_by === 'location'
                      ? order === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                  onClick={() => this.sorting('location')}
                >{this.props.users.languageStateForRedux?.column_names?.agenda?.loc
                  }</TableCell>
                <TableCell className="date-time">{this.props.users.languageStateForRedux?.column_names?.agenda?.track}</TableCell>
                <TableCell
                  className={
                    'long-text ' +
                    (sort_by === 'visibility'
                      ? order === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                  onClick={() => this.sorting('visibility')}
                >{this.props.users.languageStateForRedux?.column_names?.agenda?.visibility
                  } </TableCell>
                <TableCell
                  className={
                    'long-text ' +
                    (sort_by === 'navigateTo'
                      ? order === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                >
                  {this.props.users.languageStateForRedux?.column_names?.agenda?.navigate
                  }
                </TableCell>
                <TableCell
                  className={
                    'text ' +
                    (sort_by === 'capacity'
                      ? order === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                  onClick={() => this.sorting('capacity')}
                >{this.props.users.languageStateForRedux?.column_names?.agenda?.cap
                  }</TableCell>
                <TableCell className="options">{this.props.users.languageStateForRedux?.common?.action}</TableCell>
              </TableRow>
            </TableHead>

            {
              Array.isArray(listOfAgendas) && listOfAgendas?.length > 0 ? (
                <TableBody>
                  {Array.isArray(listOfAgendas) &&
                    listOfAgendas.length &&
                    listOfAgendas.map((agenda) => {
                      const { visibility, navigateTo } = agenda;
                      return (
                        <TableRow>
                          <TableCell className="long-title sticky-cell">
                            <Grid container spacing={2} alignItems="center">
                              <Grid item>
                                <Avatar style={agenda.agendaImage ? styles.speakerImageAdded : styles.avatar}>
                                  {
                                    agenda.agendaImage ?
                                      <img
                                        style={{ maxHeight: '60px' }}
                                        alt=""
                                        src={
                                          typeof agenda.agendaImage === 'string'
                                            ? `${IMAGE_URL}/agendaImages/${agenda.agendaImage}`
                                            : AgendaIcon
                                        }
                                      />
                                      : <img alt="" src={AgendaIcon} />}
                                </Avatar>
                              </Grid>
                              <Grid item>
                                <Tooltip
                                  title={agenda.title}
                                  placement="bottom"
                                  arrow
                                >
                                  <Box component="p" mb={0} className="paragraph" style={{ cursor: "pointer" }} onClick={() => this.handleClickOpenEditSessionForm(agenda)}>
                                    {agenda.title}{' '}
                                  </Box>
                                </Tooltip>
                                <Box
                                  component="p"
                                  mb={0}
                                  className="small-subtitle secondary-text"
                                >
                                  <Stack
                                    direction="row"
                                    alignItems={'center'}
                                    spacing={1}
                                  >
                                    <Tooltip
                                      title={agenda.tags.length > 0 ? `#${agenda.tags[0]} ` : '--'}
                                      placement="bottom"
                                      arrow
                                    >
                                      <span className='long-text' style={{ maxWidth: "170px" }}> {agenda.tags.length > 0 ? `#${agenda.tags[0]} ` : '--'}</span>
                                    </Tooltip>
                                    {agenda.tags.length > 1 &&
                                      <div className="detail-popup">
                                        <CustomHTMLTooltip interactive arrow title={
                                          <Box className='tooltip-popup'>
                                            {this.showRestOfTags(agenda.tags).map((tag, i) => {
                                              return (
                                                <div key={i}>
                                                  <p>
                                                    #{tag}
                                                  </p>
                                                </div>
                                              );
                                            })}
                                          </Box>
                                        }>
                                          <span className="count">
                                            +{agenda.tags.length - 1}
                                          </span>
                                        </CustomHTMLTooltip>

                                      </div>
                                    }
                                  </Stack>
                                </Box>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell
                            className="date-time"
                            dateTime={agenda.dateTime}
                          >
                            {agenda.dateTime !== null
                              ? moment.tz(agenda.dateTime, this.props.project?.project?.timezone).format(
                                'ddd, MMM, Do YYYY, h:mm A',
                              )
                              : '--'}
                          </TableCell>
                          <TableCell className="text">
                            {agenda.duration.hours
                              ? `${agenda.duration.hours}h`
                              : ''}{' '}
                            {agenda.duration.minutes
                              ? `${agenda.duration.minutes}m`
                              : '00'}
                          </TableCell>
                          <TableCell
                            className="long-text"
                          >
                            {agenda.location
                              ? agenda.location
                              : '--'}
                          </TableCell>
                          <TableCell className="date-time" track={agenda.track}>
                            <Stack
                              direction="row"
                              alignItems={'center'}
                              spacing={1}
                            >
                              <span className='long-text' style={{ maxWidth: "80%", }}> {agenda.track.length > 0 ? agenda.track[0] : '--'}</span>
                              {agenda.track.length > 1 &&
                                <div className="detail-popup">

                                  <CustomHTMLTooltip interactive arrow title={
                                    <Box className='tooltip-popup'>
                                      {this.showRestOfTracks(agenda.track).map((track, i) => {
                                        return (
                                          <div key={i}>
                                            <p>
                                              {track}
                                            </p>
                                          </div>
                                        );
                                      })}
                                    </Box>
                                  }>
                                    <span className="count">
                                      +{agenda.track.length - 1}
                                    </span>
                                  </CustomHTMLTooltip>


                                </div>
                              }
                            </Stack>
                          </TableCell>
                          <TableCell className="long-text">
                            {visibility?.visibilityType === 'Groups' ? (
                              <Stack
                                direction="row"
                                alignItems={'center'}
                                spacing={1}
                              >
                                {visibility.groups[0]?.source === "GoogleWorkspace" && <img src={GoogleWorkspace} alt="google workspace" width="14px" style={{ marginRight: "5px" }} />}
                                <span className='long-text' style={{ maxWidth: "80%", }}>{visibility.groups[0]['attendeesGroupName']}</span>
                                {visibility.groups.length > 1 &&
                                  <div className="detail-popup">

                                    <CustomHTMLTooltip interactive arrow title={
                                      <Box className='tooltip-popup'>
                                        {this.showRestOfVisibilityGroups(visibility.groups).map((group, i) => {
                                          return (
                                            <div key={i}>
                                              <span>
                                                {group.source === "GoogleWorkspace" && <img src={GoogleWorkspace} alt="google workspace" width="14px" style={{ marginRight: "5px" }} />}
                                                {group.attendeesGroupName}
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </Box>
                                    }>
                                      <span className="count">
                                        +{visibility.groups.length - 1}
                                      </span>
                                    </CustomHTMLTooltip>

                                  </div>
                                }
                              </Stack>
                            ) : (
                              this.props.users.languageStateForRedux?.common?.all
                            )}{' '}
                          </TableCell>
                          <TableCell className="long-text ">
                            {navigateTo?.navigateType === 'projectPages' ? (
                              <Stack
                                direction="row"
                                alignItems={'center'}
                                spacing={1}
                              >
                                <span><div>{navigateTo?.pageId?.pageName}</div> </span>
                              </Stack>
                            ) : (navigateTo?.navigateType === 'redirectExternalURL' ? (
                              <>
                                URL
                                <Tooltip
                                  title={agenda.navigateTo.agendaURL}
                                  placement="top"
                                  arrow
                                >
                                  <Box ml={2} component={'a'} target='_blank' href={agenda.navigateTo.agendaURL}>
                                    <img alt="url-img" src={urlImg} />{' '}
                                  </Box>
                                </Tooltip>
                              </>
                            ) : ((navigateTo?.navigateType === "none") ? (
                              <Box component="span" sx={{ textTransform: 'capitalize !important' }} className="table-status light">
                                {this.props.users.languageStateForRedux?.dropdowns?.agenda?.none}
                              </Box>
                            ) : '--'))}{' '}
                          </TableCell>
                          <TableCell className="text" capacity={agenda.capacity}>
                            {agenda.capacity} (+ {agenda.overbookingCapacity})
                          </TableCell>
                          <TableCell className="options">
                            {(allowed['Edit Session'] || allowed['Delete Session']) &&
                              <>
                                {' '}
                                <div className="options-button">
                                  <img alt="" src={MoreIcon} />{' '}
                                </div>
                                <div className="options-list">
                                  {allowed['Edit Session'] &&
                                    <>
                                      <div
                                        onClick={() =>
                                          this.handleClickOpenEditSessionForm(agenda)
                                        }
                                      >
                                        {this.props.users.languageStateForRedux?.actions?.agenda?.edit}
                                      </div>
                                      <hr />
                                    </>
                                  }
                                  {allowed['Delete Session'] &&
                                    <div
                                      onClick={() =>
                                        this.handleClickOpenDeleteSessionModal(
                                          agenda._id,
                                        )
                                      }
                                      style={{ color: theme.palette.secondary.main, }}
                                    >
                                      {this.props.users.languageStateForRedux?.common?.delete}
                                    </div>
                                  }

                                  {allowed['Create Session'] &&
                                    <div
                                      onClick={() =>
                                        this.handleOpenReplicateModal(agenda)
                                      }
                                    >
                                      {this.props.users.languageStateForRedux?.actions?.agenda?.replicate}
                                    </div>
                                  }
                                </div>
                              </>
                            }
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              ) : (!this.state.loading && listOfAgendas?.length === 0 && this.props.agenda?.listAgendaSuccess && (
                <TableBody>
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }}>
                      {this.props.users.languageStateForRedux?.common?.noRecords}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )
              )
            }

          </Table>
        </TableContainer>

        {/* delete agenda */}
        <Modal
          open={this.state.openDeleteSessionModal}
          onClose={this.handleCloseOpenDeleteSessionModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Box component={Grid} container className="" px={1.5}>
            <Box component={Grid} mt={-1} item xs={12} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {' '}
                {this.props.users.languageStateForRedux?.common?.confirmDelete}
              </Box>
              <p className="small-subtitle">{this.props.users.languageStateForRedux?.common?.deleteMsg}</p>
            </Box>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  {
                    <>
                      <Button
                        variant="contained"
                        color="secondary"
                        disableElevationon
                        onClick={() => this.handleDeleteConfirm()}
                      >
                        {this.props.users.languageStateForRedux?.common?.delete}
                      </Button>
                      <Button
                        variant="outlined"
                        disableElevation
                        onClick={() => this.handleCloseOpenDeleteSessionModal()}
                      >
                        {this.props.users.languageStateForRedux?.common?.cancel}
                      </Button>
                    </>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        {/* Add session delete speaker modal */}
        <Modal
          open={this.state.openDeleteSessionSpeakerModal}
          onClose={this.handleCloseOpenDeleteSessionSpeakerModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Box component={Grid} container className="" px={1.5}>
            <Box component={Grid} mt={-1} item xs={12} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {' '}
                {this.props.users.languageStateForRedux?.common?.confirmDelete}
              </Box>
              <p className="small-subtitle">{this.props.users.languageStateForRedux?.common?.deleteMsg}</p>
            </Box>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevationon
                      onClick={() => this.handleDeleteConfirmSpeaker()}
                    >
                      {this.props.users.languageStateForRedux?.common?.delete}
                    </Button>

                    <Button
                      variant="outlined"
                      disableElevation
                      onClick={() =>
                        this.handleCloseOpenDeleteSessionSpeakerModal()
                      }
                    >
                      {this.props.users.languageStateForRedux?.common?.cancel}
                    </Button>
                  </>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        {/* Edit session delete speaker modal */}
        <Modal
          open={this.state.openDeleteSessionSpeakerModalEdit}
          onClose={this.handleCloseOpenDeleteSessionSpeakerModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Box component={Grid} container className="" px={1.5}>
            <Box component={Grid} mt={-1} item xs={12} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {' '}
                {this.props.users.languageStateForRedux?.common?.confirmDelete}
              </Box>
              <p className="small-subtitle">{this.props.users.languageStateForRedux?.common?.deleteMsg}</p>
            </Box>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevationon
                      onClick={() => this.handleDeleteConfirmSpeakerEdit()}
                    >
                      {this.props.users.languageStateForRedux?.common?.delete}
                    </Button>

                    <Button
                      variant="outlined"
                      disableElevation
                      onClick={() =>
                        this.handleCloseOpenDeleteSessionSpeakerModal()
                      }
                    >
                      {this.props.users.languageStateForRedux?.common?.cancel}
                    </Button>
                  </>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        {/* Add Session Modal */}
        <Modal
          open={this.state.openAddSessionForm}
          onClose={this.handleCloseOpenAddSessionForm}
          classNames={{
            overlay: 'full-screen_overlay',
            modal:
              'full-screen_modal manage-custom-fields modal-default-close-none',
          }}
          center
        >
          <Grid container justifyContent="end">
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={4}
              className="full-screen_modal__form"
            >
              <Box component="div" className="full-screen_modal__form_header">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <p className="subtitle">{this.props.users.languageStateForRedux?.actions?.agenda?.addSession}</p>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <Button
                          fullWidth
                          disableElevation
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            this.handleAddAgenda();
                          }}
                        >
                          {this.props.users.languageStateForRedux?.common?.save}
                        </Button>
                      </Grid>
                      <Box component={Grid} item ml={1}>
                        <CloseIcon
                          onClick={this.handleCloseOpenAddSessionForm}
                          className="modal-close-button"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Box
                component="div"
                py={1}
                sx={{ maxHeight: 'calc(100vh - 66px)' }}
                className="full-screen_modal__form_body"
              >
                <Box px={3} pt={2} pb={4}>
                  <Box
                    component={Grid}
                    rowSpacing={4}
                    columnSpacing={2.75}
                    container
                    className="inputField"
                  >
                    <Grid item xs={12}>
                      <label>
                        {this.props.users.languageStateForRedux?.column_names?.agenda?.title} <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control small"
                        placeholder={this.props.users.languageStateForRedux?.formfields?.agenda?.placeholder}
                        pattern="[a-zA-Z]+"
                        value={title}
                        name="title"
                        onChange={this.handleChange}
                      />
                      <span className="error_mesage required">
                        {' '}
                        {this.state.errors.title}{' '}
                      </span>
                    </Grid>

                    {/* new addtags */}
                    <Grid item xs={12}>
                      <label>{this.props.users.languageStateForRedux?.overviewTab?.tags} </label>
                      <input
                        type="text"
                        class="form-control small"
                        placeholder={this.props.users.languageStateForRedux?.formfields?.overview?.tagsPlacedholder}
                        value={tags}
                        name="tags"
                        onKeyDown={this.handleChipsKeyDownTags}
                        onChange={this.handleChange}
                        onPaste={this.handleChipPaste}
                      />
                      {this.state.chipError && (
                        <div className="required">{this.state.chipError}</div>
                      )}
                      {this.state.chipItems.map((item) => (
                        <div
                          style={{ display: 'inline' }}
                          className="tag-item"
                          key={item}
                        >
                          {item}
                          <button
                            type="button"
                            className="button"
                            onClick={() => this.handleChipDelete(item)}
                          >
                            &times;
                          </button>
                        </div>
                      ))}
                    </Grid>
                    <Grid item xs={12}>
                      <label>
                        {this.props.users.languageStateForRedux?.column_names?.agenda?.dateTime} <span className="required">*</span>
                      </label>
                      <LocalizationProvider
                        style={{ width: 100 }}
                        dateAdapter={AdapterDateFns}
                      >
                        <MobileDateTimePicker
                          renderInput={(params) => (
                            <StyledTextField
                              variant="outlined"
                              fullWidth
                              size="medium"
                              {...params}
                            />
                          )}
                          selected={dateTime}
                          dateTime={dateTime}
                          value={dateTime}
                          minDate={new Date()}
                          onChange={(e) => {
                            this.changeDate(e);
                          }}
                          inputFormat="MMM, dd yyyy, hh:mm a"
                        />
                      </LocalizationProvider>
                      <span className="error_mesage required">
                        {' '}
                        {this.state.errors.dateTime}{' '}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <label>
                        {this.props.users.languageStateForRedux?.column_names?.agenda?.duration} <span className="required">*</span>
                      </label>
                      <Grid container alignItems={'start'}>
                        <Grid item xs={4}>
                          <input
                            type="number"
                            max={'100'}
                            style={{ textAlign: 'center' }}
                            class="form-control small"
                            name="hours"
                            placeholder=""
                            value={hours}
                            onChange={this.handleChange}
                          />
                          <Box
                            mt={0.3}
                            sx={{
                              fontSize: '10px',
                              color: theme.palette.primary.dark,
                            }}
                          >
                            {this.props.users.languageStateForRedux?.dropdowns?.agenda?.hours}
                          </Box>
                          <span className="error_mesage required">
                            {' '}
                            {this.state.errors.hours}{' '}
                          </span>
                        </Grid>
                        <Grid item>
                          {' '}
                          <Box
                            component={'span'}
                            sx={{ lineHeight: '38px' }}
                            px={1}
                            className="heading"
                          >
                            :
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <input
                            type="number"
                            style={{ textAlign: 'center' }}
                            class="form-control small"
                            placeholder=""
                            name="minutes"
                            value={minutes}
                            onChange={this.handleChange}
                          />
                          <Box
                            mt={0.3}
                            sx={{
                              fontSize: '10px',
                              color: theme.palette.primary.dark,
                            }}
                          >
                            {this.props.users.languageStateForRedux?.dropdowns?.agenda?.mins}
                          </Box>
                          <span className="error_mesage required">
                            {' '}
                            {this.state.errors.minutes}{' '}
                          </span>
                        </Grid>
                      </Grid>
                      <span className="error_mesage required">
                        {' '}
                        {this.state.errs?.hours}{' '}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <label>
                        {this.props.users.languageStateForRedux?.column_names?.agenda?.loc}
                      </label>
                      <input
                        type="text"
                        class="form-control small"
                        placeholder=""
                        value={location}
                        name="location"
                        onChange={this.handleChange}
                        maxlength={50}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <label>
                        {this.props.users.languageStateForRedux?.column_names?.agenda?.cap} <span className="required">*</span>
                      </label>
                      <div className="sub-add-outer">
                        <img
                          alt="sub-icon"
                          className="subicon"
                          src={SubIcon}
                          onClick={() => this.decrementCount('capacity')}
                        />
                        <img
                          alt="add-icon"
                          className="addicon"
                          src={AddIcon}
                          onClick={() => this.IncrementCount('capacity')}
                        />
                        <div className="sub-add-outer-inner">
                          <input
                            type="number"
                            style={{ textAlign: 'center' }}
                            class="form-control small"
                            value={capacity}
                            name="capacity"
                            onChange={this.handleChange}
                          />
                        </div>
                        <p className="required small-subtitle"> </p>
                      </div>
                      <span className="error_mesage required">
                        {' '}
                        {this.state.errors.capacity}{' '}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <label>
                        {this.props.users.languageStateForRedux?.column_names?.agenda?.overbookCap}
                      </label>
                      <div className="sub-add-outer">
                        <img
                          alt="sub-icon"
                          className="subicon"
                          src={SubIcon}
                          onClick={() =>
                            this.decrementCount('overbookingCapacity')
                          }
                        />
                        <img
                          alt="add-icon"
                          className="addicon"
                          src={AddIcon}
                          onClick={() =>
                            this.IncrementCount('overbookingCapacity')
                          }
                        />
                        <div className="sub-add-outer-inner">
                          <input
                            type="number"
                            style={{ textAlign: 'center' }}
                            class="form-control small"
                            value={overbookingCapacity}
                            name="overbookingCapacity"
                            onChange={this.handleChange}
                          />
                          <span className="error_mesage required">
                            {' '}
                            {this.state.errors.overbookingCapacity}{' '}
                          </span>
                        </div>
                        <p className="required small-subtitle"> </p>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <label>{this.props.users.languageStateForRedux?.column_names?.agenda?.track}</label>
                      <input
                        type="text"
                        class="form-control small"
                        placeholder={this.props.users.languageStateForRedux?.dropdowns?.agenda?.placeholder}
                        value={track}
                        name="track"
                        onKeyDown={this.handleTrackKeyDown}
                        onChange={this.handleChange}
                        onPaste={this.handleTrackPaste}
                      />
                      {this.state.trackError && (
                        <div className="required">{this.state.trackError}</div>
                      )}

                      {this.state.trackItems.map((item) => (
                        <div
                          style={{ display: 'inline' }}
                          className="tag-item"
                          key={item}
                        >
                          {item}
                          <button
                            type="button"
                            className="button"
                            onClick={() => this.handleTrackDelete(item)}
                          >
                            &times;
                          </button>
                        </div>
                      ))}
                    </Grid>
                    <Grid item xs={12}>
                      <label>{this.props.users.languageStateForRedux?.column_names?.agenda?.visibility}</label>
                      <FormControl
                        size="small"
                        fullWidth
                      >
                        <Select
                          type="text"
                          class="form-control select small"
                          value={visibility}
                          name="visibility"
                          onChange={this.handleChange}
                          style={{ padding: '4px 0px', }}
                          MenuProps={MenuProps}
                          displayEmpty
                        >
                          <MenuItem value="All">{this.props.users.languageStateForRedux?.common?.all}</MenuItem>
                          <MenuItem value="Groups">{this.props.users.languageStateForRedux?.dropdowns?.agenda?.groups}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {visibility === 'Groups' && (
                      <Grid item xs={12}>
                        <label>
                          {this.props.users.languageStateForRedux?.dropdowns?.agenda?.selectGroups}<span className="required">*</span>
                        </label>
                        <ReactSelect
                          styles={customStyles}
                          className="custom-multi-select"
                          options={listOfGroups.filter(g => (g.source === "GoogleWorkspace" && g.showToGroup) || g.source === "Manually Added")}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isSearchable={false}
                          isClearable={false}
                          components={{
                            Option,
                          }}
                          onChange={(group) => {
                            this.setState({ selectedGroup: group });
                          }
                          }
                          allowSelectAll={true}
                          getOptionLabel={this.customOptionLabel}
                        />
                        <span className="error_mesage required">
                          {' '}
                          {this.state.errors.selectedGroup}{' '}
                        </span>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <label>{this.props.users.languageStateForRedux?.column_names?.agenda?.navigate}</label>
                      <FormControl
                        size="small"
                        fullWidth
                      >
                        <Select
                          type="text"
                          class="form-control select small"
                          value={navigateTo}
                          name="navigateTo"
                          onChange={this.handleChange}
                          style={{ padding: '4px 0px', }}
                          displayEmpty
                          MenuProps={MenuProps}
                        >
                          <MenuItem value="none">
                            {this.props.users.languageStateForRedux?.dropdowns?.agenda?.none}
                          </MenuItem>
                          <MenuItem value="projectPages">{this.props.users.languageStateForRedux?.dropdowns?.agenda?.proPages}</MenuItem>
                          <MenuItem value="redirectExternalURL">
                            {this.props.users.languageStateForRedux?.dropdowns?.agenda?.redirect}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {navigateTo !== 'none' && (
                      <>
                        {navigateTo !== 'redirectExternalURL' && (
                          <Grid item xs={12}>
                            <label>{this.props.users.languageStateForRedux?.dropdowns?.agenda?.pages} <span className="required">*</span></label>
                            <FormControl
                              size="small"
                              fullWidth
                            >
                              <Select
                                type="text"
                                class="form-control select small"
                                value={pages}
                                name="pages"
                                onChange={this.handleChange}
                                style={{ padding: '4px 0px', }}
                                displayEmpty
                                MenuProps={MenuProps}
                              >
                                <MenuItem value="" disabled selected hidden>
                                  {this.props.users.languageStateForRedux?.dropdowns?.agenda?.selectPage}
                                </MenuItem>

                                {pageOptions.map((page) => {
                                  return (
                                    <MenuItem value={page.value}>{page.label}</MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <span className="error_mesage required">
                              {' '}
                              {this.state.errors.pages}{' '}
                            </span>
                          </Grid>
                        )}
                        {navigateTo === 'redirectExternalURL' && (
                          <Grid item xs={12}>
                            <label>
                              {this.props.users.languageStateForRedux?.dropdowns?.agenda?.external}<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control small"
                              placeholder=""
                              value={agendaURL}
                              name="agendaURL"
                              onChange={this.handleChange}
                            />
                            <span className="error_mesage required">
                              {' '}
                              {this.state.errors.agendaURL}{' '}
                            </span>
                          </Grid>
                        )}
                      </>
                    )}
                  </Box>
                </Box>

                <Box borderTop={'1px solid #CCCED5'} px={3} pt={2} pb={4}>
                  <Box
                    component={Grid}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    container
                  >
                    <Grid item className='inputField'>
                      <label>{this.props.users.languageStateForRedux?.column_names?.agenda?.image}</label>
                    </Grid>
                  </Box> 
                  <Grid
                    container
                    rowSpacing={4}
                    columnSpacing={2.75}
                    alignItems="center"
                    className="inputField"
                  >
                    {agendaImage !== '' &&
                      <Grid item>
                        <img style={{ maxHeight: "60px" }}
                          alt=""
                          src={(this.state.displayS3Image && agendaImage !== '') ?
                            IMAGE_URL2 + '/' + userId + '/Images' + '/' + fileNameInS3 + '?jwt=' + token :
                            (agendaImage !== '' ?
                              window.URL.createObjectURL(agendaImage) :
                              userImageIcon)
                          }
                        />
                      </Grid>
                    }
                    <Grid item xs>
                      <Stack spacing={1} width={'auto'} mb={0} className="paragraph">
                        <Button style={{ maxWidth: '100px' }} onClick={this.uploadFromFiles} size={'small'} variant='outlined' color='primary' >
                          {agendaImage !== '' ? this.props.users.languageStateForRedux?.buttons?.agenda?.change : this.props.users.languageStateForRedux?.buttons?.agenda?.choose}
                        </Button>
                        {' '}
                        {agendaImage !== '' &&
                          <Button style={{ maxWidth: '100px' }} onClick={this.removeAgendaImageClick} size={'small'} variant='outlined' color='secondary' >
                            {this.props.users.languageStateForRedux?.buttons?.common?.remove}
                          </Button>
                        }
                      </Stack>
                      <input
                        type="file"
                        id="agendaImage"
                        name="agendaImage"
                        onChange={(e) => this.onAgendaImageChange(e, 'add')}
                        accept="image/*"
                        ref={this.chooseAgendaImage}
                        style={{ display: "none" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="paragraph">
                        <span className='text-primary-light'>{this.props.users.languageStateForRedux?.formfields?.overview?.allowedTypes}:</span> JPG, GIF, PNG
                      </Box>
                      <Box className="paragraph">
                        <span className='text-primary-light'>{this.props.users.languageStateForRedux?.formfields?.overview?.desc}:</span> 5 MB
                      </Box>

                    </Grid>
                  </Grid>
                </Box>

                <Box borderTop={'1px solid #CCCED5'} px={3} pt={2}>
                  <Box
                    component={Grid}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    container
                  >
                    <Grid item className='inputField'>
                      <label>{this.props.users.languageStateForRedux?.column_names?.agenda?.desc}<span className="required">{' '}*</span></label>
                    </Grid>
                    <Grid item>
                    </Grid>
                  </Box>
                </Box>

                <Box>
                  <NotificationEditor
                    description={this.state.description}
                    handleEditorChange={this.handleEditorChange}
                  />
                </Box>
                <Box px={3} py={0.5}>
                  <span className="error_mesage required">
                    {this.state.errors.description}{' '}
                    {' '}
                  </span>
                </Box>

                <Box px={3} pt={2} pb={4}>
                  <Box
                    component={Grid}
                    mb={5}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    container
                  >
                    <Grid item>
                      <Stack direction="row" alignItems={'center'} className='inputField'>
                        <Box component='label' sx={{ marginBottom: "0 !important" }}>{this.props.users.languageStateForRedux?.column_names?.agenda?.speakers}</Box>
                        <CustomSwitch
                          color={'primary'}
                          checked={this.state.showSpeakers}
                          onChange={(e) => this.handleSpeaker(e)}
                        />
                      </Stack>
                    </Grid>
                    <Grid item>
                      {this.state.showSpeakers === true && (

                        <Button
                          onClick={this.handleClickOpenAddSpeakerModal}
                          startIcon={<span>+</span>}
                          color="primary"
                          variant="outlined"
                          disableElevation
                        >
                          {this.props.users.languageStateForRedux?.buttons?.agenda?.newSpeaker}
                        </Button>
                      )}
                    </Grid>
                  </Box>

                  {this.state.showSpeakers === true && (
                    <div open={this.state.showSpeakers}>
                      <Grid
                        container
                        rowSpacing={4}
                        columnSpacing={2.75}
                        alignItems="center"
                        className="inputField"
                      >
                        <Box px={3} py={0.5}>
                          <span className="error_mesage required">
                            <br /> {this.state.errors.speakersList}{' '}
                          </span>
                        </Box>
                      </Grid>
                      <TableContainer className="">
                        <Table className="table">
                          <TableBody>
                            {Array.isArray(this.state.speakersList) &&
                              this.state.speakersList.map((speaker, index) => {
                                return (
                                  <TableRow>
                                    <TableCell
                                      style={{ borderTop: '0' }}
                                      className="name-avatar sticky-cell"
                                      key={index}
                                    >
                                      <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                      >
                                        <Grid item>
                                          <Avatar style={speaker.speakerImage ? styles.speakerImageAdded : styles.avatar}>
                                            {speaker.speakerImage !== '' ?
                                              <img
                                                style={{ maxHeight: '48px' }}
                                                alt="speaker"
                                                src={window.URL.createObjectURL(speaker.speakerImage)
                                                }
                                              /> :
                                              <img alt="" width="28" src={userImageIcon} />
                                            }
                                          </Avatar>
                                        </Grid>
                                        <Grid item xs>
                                          <Box
                                            component="p"
                                            mb={0}
                                            className="paragraph bold"
                                          >
                                            {speaker.speakerFirstName}{' '}
                                            {speaker.speakerLastName}
                                          </Box>
                                          <Box
                                            component="p"
                                            mb={0}
                                            className="small-subtitle"
                                          >
                                            {speaker.speakerJob}
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                    <TableCell
                                      style={{ borderTop: '0' }}
                                      className="long-text"
                                      align="right"
                                    >
                                      <Stack
                                        direction="row"
                                        justifyContent={'end'}
                                      >
                                        <Button
                                          onClick={() =>
                                            this.handleClickOpenAddSpeakerModal(
                                              index,
                                            )
                                          }
                                          color="primary"
                                          variant="text"
                                          disableElevation
                                        >
                                          {this.props.users.languageStateForRedux?.common?.edit}
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            this.handleClickOpenDeleteSessionSpeakerModal(
                                              index,
                                            )
                                          }
                                          color="secondary"
                                          variant="text"
                                          disableElevation
                                        >
                                          {this.props.users.languageStateForRedux?.common?.delete}
                                        </Button>
                                      </Stack>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )}
                </Box>
              </Box>

            </Grid>
          </Grid>
        </Modal>

        {/* Add Speaker Modal */}
        <Modal
          open={this.state.openAddSpeakerModal}
          onClose={this.handleCloseOpenAddSpeakerModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid item xs={12} pb={4}>
              <Box component="p" mb={1} className="subtitle">
                {this.state.isSpeakerEdit ? this.props.users.languageStateForRedux?.formfields?.agenda?.editSpeaker : this.props.users.languageStateForRedux?.formfields?.agenda?.addSpeaker}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box component="div" className="inputField" pb={4}>
                <label>{this.props.users.languageStateForRedux?.formfields?.agenda?.speakerPhoto}</label>
                <Grid container spacing={2} alignItems="start">
                  <Grid item>
                    <Avatar style={speakerImage === '' ? styles.speakerImage : styles.speakerImageAdded}>
                      {speakerImage === '' ?
                        <img alt="" width="28" src={userImageIcon} /> :
                        <img
                          style={{ maxHeight: '48px' }}
                          alt="speaker"
                          src={(typeof speakerImage) === 'string' ?
                            `${IMAGE_URL}/agendaImages/${speakerImage}` :
                            window.URL.createObjectURL(speakerImage)
                          }
                        />
                      }
                    </Avatar>
                  </Grid>
                  <Grid item xs>
                    <Box component="p" mb={0} className="small-subtitle">
                      {this.props.users.languageStateForRedux?.formfields?.agenda?.size}
                    </Box>

                    <Button onClick={this.uploadFromFiles} size={'small'} variant='outlined' color='primary' >
                      {this.props.users.languageStateForRedux?.formfields?.agenda?.photo}
                    </Button>
                    {speakerImage && <Button color={"secondary"}  size={'small'} variant='outlined' onClick={this.removeSpeakerImage}>Remove</Button>}
                    <input
                      type="file"
                      id="speakerImage"
                      name="speakerImage"
                      onChange={this.onSpeakerImageChange}
                      accept="image/*"
                      ref={chooseSpeakerImage}
                      style={{ display: "none" }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box component="div" className="inputField" pb={4}>
                    <label>
                      {this.props.users.languageStateForRedux?.formfields?.agenda?.firstName} <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control small"
                      placeholder=""
                      value={speakerFirstName}
                      name="speakerFirstName"
                      onChange={this.handleChange}
                    />
                    <span className="error_mesage required">
                      {' '}
                      {this.state.errors.speakerFirstName}{' '}
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box component="div" className="inputField" pb={4}>
                    <label>
                      {this.props.users.languageStateForRedux?.formfields?.agenda?.lastName} <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control small"
                      placeholder=""
                      value={speakerLastName}
                      name="speakerLastName"
                      onChange={this.handleChange}
                    />
                    <span className="error_mesage required">
                      {' '}
                      {this.state.errors.speakerLastName}{' '}
                    </span>
                  </Box>
                </Grid>
              </Grid>

              <Box component="div" className="inputField" pb={4}>
                <label>{this.props.users.languageStateForRedux?.formfields?.agenda?.jobTitle}</label>
                <input
                  type="text"
                  class="form-control small"
                  placeholder=""
                  name="speakerJob"
                  value={speakerJob}
                  onChange={this.handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.addNewSpeaker}
                  >
                    {this.state.isSpeakerEdit ? this.props.users.languageStateForRedux?.buttons?.agenda?.update : this.props.users.languageStateForRedux?.buttons?.agenda?.addOnly}
                  </Button>
                  <Button
                    onClick={this.handleCloseOpenAddSpeakerModal}
                    variant="outlined"
                    disableElevation
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

        {/* Edit Session Add Speaker Modal */}
        <Modal
          open={this.state.openAddSpeakerModalForEdit}
          onClose={this.handleCloseOpenAddSpeakerModalForEdit}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid item xs={12} pb={4}>
              <Box component="p" mb={1} className="subtitle">
                {this.state.isSpeakerEdit ? this.props.users.languageStateForRedux?.formfields?.agenda?.editSpeaker : this.props.users.languageStateForRedux?.formfields?.agenda?.addSpeaker}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box component="div" className="inputField" pb={4}>
                <label>{this.props.users.languageStateForRedux?.formfields?.agenda?.speakerPhoto}</label>
                <Grid container spacing={2} alignItems="start">
                  <Grid item>
                    <Avatar style={speakerImage === '' ? styles.speakerImage : styles.speakerImageAdded}>
                      {speakerImage === '' ?
                        <img alt="" width="28" src={userImageIcon} /> :
                        <img
                          style={{ maxHeight: '48px' }}
                          alt="speaker"
                          src={(typeof speakerImage) === 'string' ?
                            `${IMAGE_URL}/agendaImages/${speakerImage}` :
                            window.URL.createObjectURL(speakerImage)
                          }
                        />
                      }
                    </Avatar>
                  </Grid>
                  <Grid item xs>
                    <Box component="p" mb={0} className="small-subtitle">
                      {this.props.users.languageStateForRedux?.formfields?.agenda?.size}
                    </Box>

                    <Button onClick={this.uploadFromFiles} size={'small'} variant='outlined' color='primary' >
                      {this.props.users.languageStateForRedux?.formfields?.agenda?.photo}
                    </Button>
                    {speakerImage && <Button color={"secondary"} size={'small'} variant='outlined' onClick={this.removeSpeakerImage}>Remove</Button>}
                    <input
                      type="file"
                      id="speakerImage"
                      name="speakerImage"
                      onChange={this.onSpeakerImageChange}
                      accept="image/*"
                      ref={chooseSpeakerImage}
                      style={{ display: "none" }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box component="div" className="inputField" pb={4}>
                    <label>
                      {this.props.users.languageStateForRedux?.formfields?.agenda?.firstName} <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control small"
                      placeholder=""
                      value={speakerFirstName}
                      name="speakerFirstName"
                      onChange={this.handleChange}
                    />
                    <span className="error_mesage required">
                      {' '}
                      {this.state.errors.speakerFirstName}{' '}
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box component="div" className="inputField" pb={4}>
                    <label>
                      {this.props.users.languageStateForRedux?.formfields?.agenda?.lastName} <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control small"
                      placeholder=""
                      value={speakerLastName}
                      name="speakerLastName"
                      onChange={this.handleChange}
                    />
                    <span className="error_mesage required">
                      {' '}
                      {this.state.errors.speakerLastName}{' '}
                    </span>
                  </Box>
                </Grid>
              </Grid>

              <Box component="div" className="inputField" pb={4}>
                <label>{this.props.users.languageStateForRedux?.formfields?.agenda?.jobTitle}</label>
                <input
                  type="text"
                  class="form-control small"
                  placeholder=""
                  name="speakerJob"
                  value={speakerJob}
                  onChange={this.handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.addNewSpeakerEdit}
                  >
                    {this.state.isSpeakerEdit ? this.props.users.languageStateForRedux?.buttons?.agenda?.update : this.props.users.languageStateForRedux?.buttons?.agenda?.addOnly}
                  </Button>
                  <Button
                    onClick={this.handleCloseOpenAddSpeakerModalForEdit}
                    variant="outlined"
                    disableElevation
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

        {/* Edit Session Modal */}
        <Modal
          open={this.state.openEditSessionForm}
          onClose={this.handleCloseOpenEditSessionForm}
          classNames={{
            overlay: 'full-screen_overlay',
            modal:
              'full-screen_modal manage-custom-fields modal-default-close-none',
          }}
          center
        >
          {selectedAgenda !== null && (
            <Grid container justifyContent="end">
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                xl={4}
                className="full-screen_modal__form"
              >
                <Box component="div" className="full-screen_modal__form_header">
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <p className="subtitle">{this.props.users.languageStateForRedux?.actions?.agenda?.edit}</p>
                    </Grid>
                    <Grid item>
                      <Grid container>
                        <Grid item>
                          <Button
                            fullWidth
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.handleEditAgenda();
                            }}
                          >
                            {this.props.users.languageStateForRedux?.buttons?.agenda?.update}
                          </Button>
                        </Grid>
                        <Box component={Grid} item ml={1}>
                          <CloseIcon
                            onClick={this.handleCloseOpenEditSessionForm}
                            className="modal-close-button"
                          />
                        </Box>
                      </Grid>


                    </Grid>
                  </Grid>
                </Box>

                <Box
                  component="div"
                  py={1}
                  sx={{ maxHeight: 'calc(100vh - 66px)' }}
                  className="full-screen_modal__form_body"
                >
                  <Box px={3} pt={2} pb={4}>
                    <Box
                      component={Grid}
                      rowSpacing={4}
                      columnSpacing={2.75}
                      container
                      className="inputField"
                    >
                      <Grid item xs={12}>
                        <label>
                          {this.props.users.languageStateForRedux?.column_names?.agenda?.title} <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control small"
                          placeholder={this.props.users.languageStateForRedux?.formfields?.agenda?.placeholder}
                          value={titleForEdit}
                          name="titleForEdit"
                          onChange={this.handleChange}
                        />
                        <span className="error_mesage required">
                          {' '}
                          {this.state.errors.titleForEdit}{' '}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <label>{this.props.users.languageStateForRedux?.overviewTab?.tags}</label>
                        <input
                          type="text"
                          class="form-control small"
                          placeholder={this.props.users.languageStateForRedux?.dropdowns?.agenda?.placeholder}
                          value={tags}
                          name="tags"
                          onKeyDown={this.handleChipsKeyDownTagsEdit}
                          onChange={this.handleChange}
                          onPaste={this.handleChipPasteEdit}
                        />
                        {this.state.tagsForEditItem.map((item) => (
                          <div
                            style={{ display: 'inline' }}
                            className="tag-item"
                            key={item}
                          >
                            {item}
                            <button
                              type="button"
                              className="button"
                              onClick={() => this.handleChipDeleteEdit(item)}
                            >
                              &times;
                            </button>
                          </div>
                        ))}
                        {this.state.chipError && (
                          <div className="required">{this.state.chipError}</div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label>
                          {this.props.users.languageStateForRedux?.column_names?.agenda?.dateTime} <span className="required">*</span>
                        </label>
                        <LocalizationProvider
                          style={{ width: 100 }}
                          dateAdapter={AdapterDateFns}
                        >
                          <MobileDateTimePicker
                            renderInput={(params) => (
                              <StyledTextField
                                fullWidth
                                size="medium"
                                {...params}
                              />
                            )}
                            selected={dateTimeForEdit}
                            dateTime={dateTimeForEdit}
                            value={dateTimeForEdit}
                            onChange={(e) => {
                              this.ChangeDateEdit(e);
                            }}
                            inputFormat="MMM, dd yyyy, hh:mm a"
                          />
                        </LocalizationProvider>
                        <span className="error_mesage required">
                          {' '}
                          {this.state.errors.dateTimeForEdit}{' '}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <label>{this.props.users.languageStateForRedux?.column_names?.agenda?.duration} <span className="required">*</span></label>
                        <Grid container alignItems={'start'}>
                          <Grid item xs={4}>
                            <input
                              type="number"
                              max={'100'}
                              style={{ textAlign: 'center' }}
                              class="form-control small"
                              name="hoursForEdit"
                              placeholder=""
                              value={hoursForEdit}
                              onChange={this.handleChange}
                            />
                            <Box
                              mt={0.3}
                              sx={{
                                fontSize: '10px',
                                color: theme.palette.primary.dark,
                              }}
                            >
                              {this.props.users.languageStateForRedux?.dropdowns?.agenda?.hours}
                            </Box>
                            <span className="error_mesage required">
                              {' '}
                              {this.state.errors.hoursForEdit}{' '}
                            </span>
                          </Grid>
                          <Grid item>
                            {' '}
                            <Box
                              component={'span'}
                              sx={{ lineHeight: '38px' }}
                              px={1}
                              className="heading"
                            >
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <input
                              type="number"
                              style={{ textAlign: 'center' }}
                              class="form-control small"
                              placeholder=""
                              name="minutesForEdit"
                              value={minutesForEdit}
                              onChange={this.handleChange}
                            />
                            <Box
                              mt={0.3}
                              sx={{
                                fontSize: '10px',
                                color: theme.palette.primary.dark,
                              }}
                            >
                              {this.props.users.languageStateForRedux?.dropdowns?.agenda?.mins}
                            </Box>
                            <span className="error_mesage required">
                              {' '}
                              {this.state.errors.minutesForEdit}{' '}
                            </span>
                          </Grid>
                        </Grid>
                        <span className="error_mesage required">
                          {' '}
                          {this.state.errs?.hoursForEdit}{' '}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <label>
                          {this.props.users.languageStateForRedux?.column_names?.agenda?.loc}
                        </label>
                        <input
                          type="text"
                          class="form-control small"
                          placeholder=""
                          value={locationForEdit}
                          name="locationForEdit"
                          onChange={this.handleChange}
                          maxLength={50}
                        />
                        <span className="error_mesage required">
                          {' '}
                          {this.state.errors.locationForEdit}{' '}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <label>
                          {this.props.users.languageStateForRedux?.column_names?.agenda?.cap} <span className="required">*</span>
                        </label>
                        <div className="sub-add-outer">
                          <img
                            alt="sub-icon"
                            className="subicon"
                            src={SubIcon}
                            onClick={() =>
                              this.decrementCount('capacityForEdit')
                            }
                          />
                          <img
                            alt="add-icon"
                            className="addicon"
                            src={AddIcon}
                            onClick={() =>
                              this.IncrementCount('capacityForEdit')
                            }
                          />
                          <div className="sub-add-outer-inner">
                            <input
                              type="number"
                              style={{ textAlign: 'center' }}
                              class="form-control small"
                              value={capacityForEdit}
                              name="capacityForEdit"
                              onChange={this.handleChange}
                            />
                          </div>
                          <p className="required small-subtitle"> </p>
                        </div>
                        <span className="error_mesage required">
                          {' '}
                          {this.state.errors.capacityForEdit}{' '}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <label>
                          {this.props.users.languageStateForRedux?.column_names?.agenda?.overbookCap}
                        </label>
                        <div className="sub-add-outer">
                          <img
                            alt="sub-icon"
                            className="subicon"
                            src={SubIcon}
                            onClick={() =>
                              this.decrementCount('overbookingCapacityForEdit')
                            }
                          />
                          <img
                            alt="add-icon"
                            className="addicon"
                            src={AddIcon}
                            onClick={() =>
                              this.IncrementCount('overbookingCapacityForEdit')
                            }
                          />
                          <div className="sub-add-outer-inner">
                            <input
                              type="number"
                              style={{ textAlign: 'center' }}
                              class="form-control small"
                              value={overbookingCapacityForEdit}
                              name="overbookingCapacityForEdit"
                              onChange={this.handleChange}
                            />
                          </div>
                          <p className="required small-subtitle"> </p>
                        </div>
                        <span className="error_mesage required">
                          {' '}
                          {this.state.errors.overbookingCapacityForEdit}{' '}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <label>{this.props.users.languageStateForRedux?.column_names?.agenda?.track}</label>
                        <input
                          type="text"
                          class="form-control small"
                          placeholder={this.props.users.languageStateForRedux?.dropdowns?.agenda?.placeholder}
                          value={track}
                          name="track"
                          onKeyDown={this.handleTrackKeyDownEdit}
                          onChange={this.handleChange}
                          onPaste={this.handleTrackPasteEdit}
                        />
                        {this.state.trackError && (
                          <div className="required">{this.state.trackError}</div>
                        )}
                        {this.state.tracksForEditItem.map((item) => (
                          <div
                            style={{ display: 'inline' }}
                            className="tag-item"
                            key={item}
                          >
                            {item}
                            <button
                              type="button"
                              className="button"
                              onClick={() => this.handleTrackDeleteEdit(item)}
                            >
                              &times;
                            </button>
                          </div>
                        ))}


                      </Grid>
                      <Grid item xs={12}>
                        <label>{this.props.users.languageStateForRedux?.column_names?.agenda?.visibility}</label>
                        <FormControl
                          size="small"
                          fullWidth
                        >
                          <Select
                            type="text"
                            class="form-control select small"
                            value={visibilityTypeForEdit}
                            name="visibilityTypeForEdit"
                            onChange={this.handleChange}
                            style={{ padding: '4px 0px', }}
                            MenuProps={MenuProps}
                            displayEmpty
                          >
                            <MenuItem value="All">{this.props.users.languageStateForRedux?.common?.all}</MenuItem>
                            <MenuItem value="Groups">{this.props.users.languageStateForRedux?.dropdowns?.agenda?.groups}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {visibilityTypeForEdit === 'Groups' && (
                        <Grid item xs={12}>
                          <label>
                            {this.props.users.languageStateForRedux?.dropdowns?.agenda?.selectGroups}<span className="required">*</span>
                          </label>
                          <ReactSelect
                            styles={customStyles}
                            className="custom-multi-select"
                            options={listOfGroups.filter(g => (g.source === "GoogleWorkspace" && g.showToGroup) || g.source === "Manually Added")}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isSearchable={false}
                            isClearable={false}
                            components={{
                              Option,
                            }}
                            onChange={(group) => {
                              this.setState({ selectedGroup: group });
                            }}
                            allowSelectAll={true}
                            value={this.state.selectedGroup}
                            getOptionLabel={this.customOptionLabel}
                          />
                          <span className="error_mesage required">
                            {' '}
                            {this.state.errors.selectedGroup}{' '}
                          </span>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <label>{this.props.users.languageStateForRedux?.column_names?.agenda?.navigate}</label>
                        <FormControl
                          size="small"
                          fullWidth
                        >
                          <Select
                            type="text"
                            class="form-control select small"
                            value={navigateToForEdit}
                            name="navigateToForEdit"
                            onChange={this.handleChange}
                            style={{ padding: '4px 0px', }}
                            displayEmpty
                            MenuProps={MenuProps}
                          >

                            <MenuItem value="none">{this.props.users.languageStateForRedux?.dropdowns?.agenda?.none}</MenuItem>
                            <MenuItem value="projectPages">{this.props.users.languageStateForRedux?.dropdowns?.agenda?.proPages}</MenuItem>
                            <MenuItem value="redirectExternalURL">
                              {this.props.users.languageStateForRedux?.dropdowns?.agenda?.redirect}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {navigateToForEdit !== 'none' && (
                        <>
                          {navigateToForEdit === 'projectPages' && (
                            <Grid item xs={12}>
                              <label>{this.props.users.languageStateForRedux?.dropdowns?.agenda?.pages} <span className="required">*</span></label>
                              <FormControl
                                size="small"
                                fullWidth
                              >
                                <Select
                                  type="text"
                                  class="form-control select small"
                                  value={pagesForEdit}
                                  name="pagesForEdit"
                                  onChange={this.handleChange}
                                  style={{ padding: '4px 0px', }}
                                  displayEmpty
                                  MenuProps={MenuProps}
                                >
                                  <MenuItem value="" disabled selected hidden>
                                    {this.props.users.languageStateForRedux?.dropdowns?.agenda?.selectPage}
                                  </MenuItem>

                                  {pageOptions.map((page) => {
                                    return (
                                      <MenuItem value={page.value}>{page.label}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              <span className="error_mesage required">
                                {' '}
                                {this.state.errors.pagesForEdit}{' '}
                              </span>
                            </Grid>
                          )}
                          {navigateToForEdit === 'redirectExternalURL' && (
                            <Grid item xs={12}>
                              <label>
                                {this.props.users.languageStateForRedux?.dropdowns?.agenda?.external}<span className="required">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control small"
                                placeholder=""
                                value={agendaURLForEdit}
                                name="agendaURLForEdit"
                                onChange={this.handleChange}
                              />
                              <span className="error_mesage required">
                                {' '}
                                {this.state.errors.agendaURLForEdit}{' '}
                              </span>
                            </Grid>
                          )}
                        </>
                      )}
                    </Box>
                  </Box>
                  <Box borderTop={'1px solid #CCCED5'} px={3} pt={2} pb={4}>
                    <Box
                      component={Grid}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      container
                    >
                      <Grid item>
                        <label>{this.props.users.languageStateForRedux?.column_names?.agenda?.image}</label>
                      </Grid> 
                    </Box>
                    <Grid
                      container
                      rowSpacing={4}
                      columnSpacing={2.75}
                      alignItems="center"
                      className="inputField"
                    >
                      {agendaImageForEdit !== '' &&
                        <Grid item>
                          <img style={{ maxHeight: "60px" }}
                            alt=""
                            src={this.state.displayS3Image ?
                              IMAGE_URL2 + '/' + userId + '/Images' + '/' + fileNameInS3 + '?jwt=' + token :
                              (typeof agendaImageForEdit) === 'string' ?
                                `${IMAGE_URL}/agendaImages/${agendaImageForEdit}` :
                                null
                            }
                          />
                        </Grid>
                      }
                      <Grid item xs>
                        <Box component="p" mb={0} className="paragraph">
                          <Button onClick={this.uploadFromFiles} size={'small'} variant='outlined' color='primary' >
                            {agendaImageForEdit !== '' ? this.props.users.languageStateForRedux?.buttons?.agenda?.change : this.props.users.languageStateForRedux?.buttons?.agenda?.choose}
                          </Button>
                          {' '}
                          {agendaImageForEdit !== '' &&
                            <Button onClick={this.removeAgendaImageClick} size={'small'} variant='outlined' color='secondary' >
                              {this.props.users.languageStateForRedux?.buttons?.common?.remove}
                            </Button>
                          }
                        </Box>
                        <input
                          type="file"
                          id="agendaImageForEdit"
                          name="agendaImageForEdit"
                          onChange={(e) => this.onAgendaImageChange(e, 'edit')}
                          accept="image/*"
                          ref={this.chooseAgendaImage}
                          style={{ display: "none" }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/* edit description */}
                  <Box borderTop={'1px solid #CCCED5'} px={3} pt={2}>
                    <Box
                      component={Grid}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      container
                    >
                      <Grid item>
                        <label>{this.props.users.languageStateForRedux?.column_names?.agenda?.desc} <span className="required">*</span></label>

                      </Grid>

                      <Grid item>
                      </Grid>
                    </Box>
                  </Box>
                  <Box>
                    <NotificationEditor
                      description={descriptionForEdit}
                      handleEditorChange={this.handleEditorChangeEdit}
                    />
                  </Box>
                  <Box px={3} py={0.5}>
                    <span className="error_mesage required">
                      {this.state.errors.descriptionForEdit}{' '}
                      {' '}
                    </span>
                  </Box>
                  <Box px={3} pt={2} pb={4}>
                    <Box
                      component={Grid}
                      mb={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      container
                    >
                      <Grid item>
                        <Stack direction="row" alignItems={'center'}>
                          <Box component='label' mb={0}>{this.props.users.languageStateForRedux?.column_names?.agenda?.speakers} </Box>
                          <CustomSwitch
                            color={'primary'}
                            checked={this.state.showSpeakersButton}
                            onChange={(e) => this.handleSpeakerEdit(e)}
                          />
                        </Stack>
                      </Grid>
                      <Grid item>

                        {this.state.showSpeakersButton === true && (
                          <Button
                            onClick={this.handleClickOpenAddSpeakerModalForEdit}
                            startIcon={<span>+</span>}
                            color="primary"
                            variant="outlined"
                            disableElevation
                          >
                            {this.props.users.languageStateForRedux?.buttons?.agenda?.newSpeaker}
                          </Button>
                        )}
                      </Grid>
                    </Box>
                    {this.state.showSpeakersButton === true && (
                      <div open={this.state.showSpeakersButton}>
                        <Grid
                          container
                          rowSpacing={4}
                          columnSpacing={2.75}
                          alignItems="center"
                          className="inputField"
                        >
                          <Box px={3} py={0.5}>
                            <span className="error_mesage required">
                              <br /> {this.state.errors.speakersListEdit}{' '}
                            </span>
                          </Box>
                        </Grid>
                        <TableContainer className="">
                          <Table className="table">
                            <TableBody>
                              {Array.isArray(this.state.speakersListEdit) &&
                                this.state.speakersListEdit.map(
                                  (speakersListEdit, index) => {
                                    return (
                                      <TableRow>
                                        <TableCell
                                          style={{ borderTop: '0' }}
                                          className="name-avatar sticky-cell"
                                          key={index}
                                        >
                                          <Grid
                                            container
                                            spacing={2}
                                            alignItems="center"
                                          >
                                            <Grid item>
                                              <Avatar style={speakersListEdit.speakerImage === '' ? styles.speakerImage : styles.speakerImageAdded}>
                                                {speakersListEdit.speakerImage === '' ?
                                                  <img alt="" width="28" src={userImageIcon} /> :
                                                  < img
                                                    style={{ maxHeight: '48px' }}
                                                    alt="speaker"
                                                    src={(typeof speakersListEdit.speakerImage) === 'string' ?
                                                      `${IMAGE_URL}/agendaImages/${speakersListEdit.speakerImage}` :
                                                      window.URL.createObjectURL(speakersListEdit.speakerImage)
                                                    }
                                                  />
                                                }
                                              </Avatar>
                                            </Grid>
                                            <Grid item xs>
                                              <Box
                                                component="p"
                                                mb={0}
                                                className="paragraph bold"
                                              >
                                                {speakersListEdit.firstName}{' '}
                                                {speakersListEdit.lastName}
                                                {
                                                  speakersListEdit.speakerFirstName
                                                }{' '}
                                                {
                                                  speakersListEdit.speakerLastName
                                                }
                                              </Box>
                                              <Box
                                                component="p"
                                                mb={0}
                                                className="small-subtitle"
                                              >
                                                {speakersListEdit.jobTitle}
                                                {speakersListEdit.speakerJob}
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </TableCell>
                                        <TableCell
                                          style={{ borderTop: '0' }}
                                          className="long-text"
                                          align="right"
                                        >
                                          <Stack
                                            direction="row"
                                            justifyContent={'end'}
                                          >
                                            <Button
                                              onClick={() =>
                                                this.handleClickOpenAddSpeakerModalForEdit(
                                                  index,
                                                )
                                              }
                                              color="primary"
                                              variant="text"
                                              disableElevation
                                            >
                                              {this.props.users.languageStateForRedux?.common?.edit}
                                            </Button>
                                            <Button
                                              onClick={() =>
                                                this.handleClickOpenDeleteSessionSpeakerModalEdit(
                                                  index,
                                                )
                                              }
                                              color="secondary"
                                              variant="text"
                                              disableElevation
                                            >
                                              {this.props.users.languageStateForRedux?.common?.delete}
                                            </Button>
                                          </Stack>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  },
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    )}
                  </Box>
                </Box>

              </Grid>
            </Grid>
          )}
        </Modal>
        {/* Pagination on Agenda listing */}
        {
          !isGroupsComp && Array.isArray(listOfAgendas) && listOfAgendas?.length > 0 &&
          <Grid container alignItems="center" justifyContent="space-between" className="tablePagination">
            <Grid item>
              <Box component="span" className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.showing} {startCount} {this.props.users.languageStateForRedux?.pagination?.to} {endCount} {this.props.users.languageStateForRedux?.pagination?.of} {this.props.agenda.totalResults}</Box>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" >
                <Grid item>
                  <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.shows}</span>
                  <select value={itemCount} onChange={this.selectItemCount}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.entries}</span>
                </Grid>
                <Grid item>
                  <Pagination
                    activePage={page_no}
                    itemsCountPerPage={this.state.itemCount}
                    totalItemsCount={this.props.agenda.totalResults}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                    prevPageText={<NavigateBeforeIcon style={{ fontSize: 18 }} />}
                    nextPageText={<NavigateNextIcon style={{ fontSize: 18 }} />}
                    itemClassFirst="first d-none"
                    itemClassLast="last d-none"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        {this.state.loading ? <Loader /> : null}
        <NotificationContainer />
        <ConfirmationAlertPopUp
          openModal={this.state.openReplicateModal}
          closeModalFunc={this.closeReplicateModal}
          title={this.props.users.languageStateForRedux?.common?.confirmReplicate}
          text={this.props.users.languageStateForRedux?.actions?.agenda?.textMsg}
          confirmationButtonText={this.props.users.languageStateForRedux?.common?.replicate}
          confirmationButtonColor="primary"
          closeButtonText={this.props.users.languageStateForRedux?.common?.cancel}
          functionality={this.replicateAgenda}
        />
        {/* crop image popup */}
        <Modal
          open={this.state.cropOpen}
          onClose={this.onCloseCropModal}
          center
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal change-profile-image',
          }}
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid item mt={0} xs={12} pb={4}>
              <Box component="p" mb={1} className="subtitle">
                Profile Picture
              </Box>
              <p className="small-subtitle">
                Select the image you wish to upload and crop the image as per
                your needs. Only .jpg, .jpeg, .png files are accepted
              </p>
            </Grid>
            <ImgCrop
              s3File={this.state.s3File}
              profilePic={this.getData}
              pic={this.state.uploadImage}
              uploadImage={this.state.speakerImageToCrop}
              langState={this.props.users.languageStateForRedux}
              handleFileChosen={this.handleFileChosen}
            />
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    onClick={this.onCloseCropModal}
                    variant="contained"
                    // color="primary"
                    disableElevation
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                  <Button
                    onClick={() => this.saveSpeakerImage()}
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    Save
                    {/* {this.props.users.languageStateForRedux?.buttons?.attendees?.save} */}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        {/* crop image close */}
        <UploadFromFilesPopUp
          uploadFromFiles={this.state.uploadFromFiles}
          chooseAgendaImageClick={this.chooseAgendaImageClick}
          chooseAgendaImage={this.chooseAgendaImage}
          handleCloseUploadFromFileModule={this.handleCloseFileModulePopup}
          onAgendaImageChange={this.onAgendaImageChange}
          handleChoosedFile={this.handleChoosedFile}
          parentForPopup={this.state.parentForPopup}
          buttonState={this.state.buttonState}
        />
      </ThemeProvider >
    );
  }
}

const mapStateToProps = (state) => ({
  agenda: state.Agenda,
  events: state.Events,
  project: state.Projects,
  attendees: state.Attendees,
  users: state.Users,
  files: state.Files
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listAgenda: Actions.listAgendaRequest,
      deleteAgenda: Actions.deleteAgendaRequest,
      addAgenda: Actions.addAgendaRequest,
      editAgenda: Actions.editAgendaRequest,
      listAttendeesGroups: Actions.listAttendeesGroupsRequest,
      searchAgenda: Actions.searchAgendaRequest,
      replicateAgenda: Actions.replicateAgendaRequest,
      findIfAllowedFromSuperadminRequest: Actions.findIfAllowedFromSuperadminRequest,
      addImagesRequest: Actions.addImagesRequest
    },
    dispatch,
  );

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Agenda));
