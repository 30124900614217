import BaseAPI from '../baseAPI';

class OpportunityStatesAPI extends BaseAPI {
    createOpportunityState = async (data: Object) => {
        return this.API('opportunityManagement/opportunityStates', {
            method: 'POST',
            data
        });
    };   
    
    updateOpportunityState = async (data: Object) => {
        return this.API('opportunityManagement/opportunityStates', {
            method: 'PATCH',
            data
        });
    };

    deleteOpportunityState = async (data: Object) => {
        return this.API('opportunityManagement/opportunityStates', {
            method: 'DELETE',
            data
        });
    };
    
    fetchAllOpportunityStates = async () => {
        return this.API('opportunityManagement/opportunityStates', {
            method: 'GET'
        });
    };

    updateOpportunityStatesOrder = async (data: Object) => {
        return this.API('opportunityManagement/opportunityStates/updateOrder', {
            method: 'PATCH',
            data
        });
    };
}

export default OpportunityStatesAPI;