import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
    error: false,
    message: null,
    getReportSuccess: false,
    report: {},
    events: [],
    getReportChallengesSuccess:false,
    challenges: [],
}

const getReportRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        getReportSuccess: false,
        report: {}
    }
}

const getReportSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        getReportSuccess: true,
        report: action.report
    }
}

const getReportError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        getReportSuccess: false
    }
}

const getPublishedEventsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        getPublishedEventsSuccess: false,
        events: []
    }
}

const getPublishedEventsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        getPublishedEventsSuccess: true,
        events: action.events
    }
}

const getPublishedEventsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        getPublishedEventsSuccess: false
    }
}

const getReportChallengesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getReportChallengesSuccess: false,
        error: false,
        message: null,
        challenges: [],
    }
}

const getReportChallengesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getReportChallengesSuccess: true,
        error: false,
        message: action.message,
        challenges: action.challenges,
        totalResultsChallenges: { totalResults: action.totalResults },
    }
}
const getReportChallengesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getReportChallengesSuccess: false,
        error: true,
        message: action.error,
    }
}


const HANDLERS = {
    [Constants.GET_REPORT_CHALLENGES_ERROR]: getReportChallengesError,
    [Constants.GET_REPORT_CHALLENGES_SUCCESS]: getReportChallengesSuccess,
    [Constants.GET_REPORT_CHALLENGES_REQUEST]: getReportChallengesRequest,
    [Constants.GET_REPORT_ERROR]: getReportError,
    [Constants.GET_REPORT_SUCCESS]: getReportSuccess,
    [Constants.GET_REPORT_REQUEST]: getReportRequest,
    [Constants.GET_PUBLISHED_EVENTS_ERROR]: getPublishedEventsError,
    [Constants.GET_PUBLISHED_EVENTS_SUCCESS]: getPublishedEventsSuccess,
    [Constants.GET_PUBLISHED_EVENTS_REQUEST]: getPublishedEventsRequest   
}

export default createReducer(INITIAL_STATE, HANDLERS)