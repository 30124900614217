import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
  success: false,
  error: false,
  listSuccess: false,
  listError: false,
  usersSuccess: false,
  usersError: false,
  updateSuccess: false,
  updateError: false,
  searchSuccess: false,
  searchError: false,
  deleteUserSuccess: false,
  inviteUserSuccess: false,
  editUserPermissionSuccess: false,
  changeUserRoleSuccess: false,
  changeUserStatusSuccess: false,
  message: null,
  totalResults: 0,
  filterCnt: {},
  userDetails: {},
  getUserDetailsSuccess: false,
  changeLangForUserSuccess: false,
  newLangState: {},
  isLoading: false,
  assignManagerSuccess: false,
  filterByManager:{},
  allUserListSuccess:false,
  allUserList:[],
  usersByAcceptSt: [],
  loading: false,
  getUsersByAcceptStSuccess: false,
  activeUsersForCompany: [],
  getActiveUsersForCompanySuccess: false,
} 

const listUsersSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    listSuccess: true,
    users: action.users, 
    totalResults: action.totalResults,
    message: action.message,
    filterCnt: action.filterCnt,
    filterByManager:action.filterByManager
  }
} 
 
const getUsersListRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    usersSuccess: false,
    Error: false,
    updateSuccess: false,
    updateError: false,
    message: null,
    totalResults: 0,
    searchSuccess: false,
    searchError: false,
    deleteUserSuccess: false,
    filterByManager:{}
  }
}

const searchUserSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    listSuccess: true,
    users: action.users,
    totalResults: action.totalResults,
    message: action.message,
  }
}

const searchUserError = (state = INITIAL_STATE, action) => {
  return { ...state, listError: true, message: action.error }
}

const searchUserRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    memberSuccess: false,
    memberError: false,
    updateSuccess: false,
    updateError: false,
    message: null,
    searchSuccess: false,
    totalResults: 0,
    searchError: false,
  }
}

const deleteUserRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteUserSuccess: false,
    error: false,
    message: action.message,
  }
}

const deleteUserSuccess = (state = INITIAL_STATE, action) => {
  let updatedUsers = state.users.filter(function (user){return user._id !== action.user._id})
  let updatedFiletCnt = {...state.filterCnt}
  updatedFiletCnt['role'] = {
    ...updatedFiletCnt['role'], 
    "All": updatedFiletCnt['role']['All']-1,
    ...action.user.role===3 && {"Co-Admin": updatedFiletCnt['role']['Co-Admin']-1},
    ...action.user.role!==3 && {"Member": updatedFiletCnt['role']['Member']-1},
  }
  updatedFiletCnt['acceptSt'] = {
    ...updatedFiletCnt['acceptSt'], 
    "All": updatedFiletCnt['acceptSt']['All']-1,
    ...action.user.acceptSt===2 && {"Active": updatedFiletCnt['acceptSt']['Active']-1},
    ...action.user.acceptSt===3 && {"Disabled": updatedFiletCnt['acceptSt']['Disabled']-1},
    ...(action.user.acceptSt!==2 && action.user.acceptSt!==3) && {"Pending Invite": updatedFiletCnt['acceptSt']['Pending Invite']-1},
  }
  let updatedTotalresults = state.totalResults-1;
  return {
    ...state,
    deleteUserSuccess: true,
    message: action.message,
    users: updatedUsers,
    filterCnt: updatedFiletCnt,
    totalResults: updatedTotalresults
  }
}

const deleteUserError = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    deleteUserSuccess: false,
    error: true,
    message: action.error 
  }
}

const inviteUserRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    inviteUserSuccess: false,
    error: false,
    message: action.message,
  }
}

const inviteUserSuccess = (state = INITIAL_STATE, action) => {
  if(action.user){
    let newUser = {...action.user};
    newUser.role = newUser.role===3 ? "Co-Admin": "Member"
    if(newUser.acceptSt===2){
      newUser.acceptSt = 'Active'
    }else if(newUser.acceptSt===3){
      newUser.acceptSt = 'Disabled'
    }else{
      newUser.acceptSt = 'Pending Invite'
    }

    let updatedUsers = [...state.users, newUser]
    let updatedFilterCnt = {...state.filterCnt}
    updatedFilterCnt['role'] = {
      ...updatedFilterCnt['role'], 
      "All": (updatedFilterCnt['role'] && updatedFilterCnt['role']['All']) ? updatedFilterCnt['role']['All']: 0 + 1,
      ...action.user.role === 3 && {"Co-Admin": (updatedFilterCnt['role'] && updatedFilterCnt['role']['Co-Admin']) ? updatedFilterCnt['role']['Co-Admin']: 0 + 1},
      ...action.user.role !==3 && {"Member": (updatedFilterCnt['role'] && updatedFilterCnt['role']['Member']) ? updatedFilterCnt['role']['Member']: 0 + 1},
    }
    updatedFilterCnt['acceptSt'] = {
      ...updatedFilterCnt['acceptSt'], 
      "All": (updatedFilterCnt['acceptSt'] && updatedFilterCnt['acceptSt']['All']) ? updatedFilterCnt['acceptSt']['All']: 0 + 1,
      ...action.user.acceptSt===2 && {"Active": (updatedFilterCnt['acceptSt'] && updatedFilterCnt['acceptSt']['Active']) ? updatedFilterCnt['acceptSt']['Active']: 0 + 1},
      ...action.user.acceptSt===3 && {"Disabled": (updatedFilterCnt['acceptSt'] && updatedFilterCnt['acceptSt']['Disabled']) ? updatedFilterCnt['acceptSt']['Disabled']: 0 + 1},
      ...(action.user.acceptSt!==2 && action.user.acceptSt!==3) && {"Pending Invite": (updatedFilterCnt['acceptSt'] && updatedFilterCnt['acceptSt']['Pending Invite']) ? updatedFilterCnt['acceptSt']['Pending Invite']: 0 + 1},
    }
    let updatedTotalresults = state.totalResults+1;
    return {
      ...state,
      inviteUserSuccess: true,
      message: action.message,
      users: updatedUsers,
      filterCnt: updatedFilterCnt,
      totalResults: updatedTotalresults
    }
  }else{
    return {
      ...state,
      inviteUserSuccess: true,
      message: action.message
    }
  }
}

const inviteUserError = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    inviteUserSuccess: false,
    error: true,
    message: action.error 
  }
}

const editUserPermissionRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    editUserPermissionSuccess: false,
    error: false,
    message: action.message,
  }
}

const editUserPermissionSuccess = (state = INITIAL_STATE, action) => {
  if(action.user){
    let usersList = [...state.users];
    let userIndex = usersList.findIndex(o=>o._id === action.user._id)
    usersList[userIndex] = action.user    
    
    return {
      ...state,
      editUserPermissionSuccess: true,
      message: action.message,
      users: usersList
    }
  }else{
    return {
      ...state,
      editUserPermissionSuccess: true,
      message: action.message
    }
  }
}

const editUserPermissionError = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    editUserPermissionSuccess: false,
    error: true,
    message: action.error 
  }
}

const changeUserRoleRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    changeUserRoleSuccess: false,
    error: false,
    message: action.message,
  }
}

const changeUserRoleSuccess = (state = INITIAL_STATE, action) => {
  let newUser = {...action.user};
  newUser.role = newUser.role===3 ? "Co-Admin": "Member"
  if(newUser.acceptSt===2){
    newUser.acceptSt = 'Active'
  }else if(newUser.acceptSt===3){
    newUser.acceptSt = 'Disabled'
  }else{
    newUser.acceptSt = 'Pending Invite'
  }

  let userIndex = state.users.findIndex(el=>el._id===newUser._id)
  let updatedUsers = [...state.users]
  updatedUsers[userIndex]=newUser

  let updatedFilterCnt = {...state.filterCnt}
  updatedFilterCnt['role'] = {
    ...updatedFilterCnt['role'], 
    ...action.user.role===3 && {"Co-Admin": updatedFilterCnt['role']['Co-Admin']+1, "Member": updatedFilterCnt['role']['Member']-1},
    ...action.user.role===2 && {"Co-Admin": updatedFilterCnt['role']['Co-Admin']-1, "Member": updatedFilterCnt['role']['Member']+1},
  }

  return {
    ...state,
    changeUserRoleSuccess: true,
    message: action.message,
    users: updatedUsers,
    filterCnt: updatedFilterCnt,
  }
}

const changeUserRoleError = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    changeUserRoleSuccess: false,
    error: true,
    message: action.error 
  }
}

const changeUserStatusRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    changeUserStatusSuccess: false,
    error: false,
    message: action.message,
  }
}

const changeUserStatusSuccess = (state = INITIAL_STATE, action) => {
  let newUser = {...action.user};
  newUser.role = newUser.role===3 ? "Co-Admin": "Member"
  if(newUser.acceptSt===2){
    newUser.acceptSt = 'Active'
  }else if(newUser.acceptSt===3){
    newUser.acceptSt = 'Disabled'
  }else{
    newUser.acceptSt = 'Pending Invite'
  }

  let userIndex = state.users.findIndex(el=>el._id===newUser._id)
  let updatedUsers = [...state.users]
  updatedUsers[userIndex]=newUser

  let updatedFilterCnt = {...state.filterCnt}
  updatedFilterCnt['acceptSt'] = {
    ...updatedFilterCnt['acceptSt'], 
    ...action.user.acceptSt===2 && {"Active": updatedFilterCnt['acceptSt']['Active']+1, "Disabled": updatedFilterCnt['acceptSt']['Disabled']-1},
    ...action.user.acceptSt===3 && {"Active": updatedFilterCnt['acceptSt']['Active']-1, "Disabled": updatedFilterCnt['acceptSt']['Disabled']+1},
  }

  return {
    ...state,
    changeUserStatusSuccess: true,
    message: action.message,
    users: updatedUsers,
    filterCnt: updatedFilterCnt,
  }
}

const changeUserStatusError = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    changeUserStatusSuccess: false,
    error: true,
    message: action.error 
  }
}

const getUserDetailsRequest = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    getUserDetailsSuccess: false,
    error: false,
  }
}
const getUserDetailsSuccess = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    getUserDetailsSuccess: true,
    error: false,
    message: action.message,
    userDetails: action.data
  }
}

const getUserDetailsError = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    getUserDetailsSuccess: false,
    error: true,
    message: action.error,
  }
}

const handleLang = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    languageStateForRedux: action.langState
  }
}

const newLangStateForRedux = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    newLangState: action.languageCode
  }
}

const changeLangForUserRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    newLangState: "",
    error: false,
    changeLangForUserSuccess: false,
    message: "",
    isLoading:true
  }
}

const changeLangForUserError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    newLangState: "",
    error: true,
    changeLangForUserSuccess: false,
    message: action.message,
    isLoading:false
  }
}

const changeLangForUserSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    newLangState: action.codeToChangeLang,
    error: false,
    changeLangForUserSuccess: true,
    message: "",
    isLoading:false
  }
}

const assignManagerRequest = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    assignManagerSuccess: false,
    error: false,
  }
}
const assignManagerSuccess = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    assignManagerSuccess: true,
    error: false,
    message: action.message,
  }
}

const assignManagerError = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    assignManagerSuccess: false,
    error: true,
    message: action.error,
  }
}

const allUserListRequest = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    allUserListSuccess: false,
    error: false,
  }
}
const allUserListSuccess = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    allUserListSuccess: true,
    error: false,
    allUserList: action.allUserList,
  }
}

const allUserListError = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    allUserListSuccess: false,
    error: true,
    message: action.error,
  }
}

const getUsersByAcceptStRequest = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    getUsersByAcceptStSuccess: false,
    error: false,
    usersByAcceptSt: [],
    loading: true
  }
}
const getUsersByAcceptStSuccess = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    getUsersByAcceptStSuccess: true,
    error: false,
    usersByAcceptSt: action.users,
    loading: false
  }
}

const getUsersByAcceptStError = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    getUsersByAcceptStSuccess: false,
    error: true,
    message: action.message,
    loading: false
  }
}
const getActiveUsersForCompanyRequest = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    getActiveUsersForCompanySuccess: false,
    error: false,
    activeUsersForCompany: [],
    loading: true
  }
}
const getActiveUsersForCompanySuccess = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    getActiveUsersForCompanySuccess: true,
    error: false,
    activeUsersForCompany: action.users,
    loading: false
  }
}

const getActiveUsersForCompanyError = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    getActiveUsersForCompanySuccess: false,
    error: true,
    message: action.message,
    loading: false
  }
}

const HANDLERS = {
  [Constants.ALL_USER_LIST_REQUEST]: allUserListRequest,
  [Constants.ALL_USER_LIST_SUCCESS]: allUserListSuccess,
  [Constants.ALL_USER_LIST_ERROR]: allUserListError,
  [Constants.ASSIGN_MANAGER_REQUEST]: assignManagerRequest,
  [Constants.ASSIGN_MANAGER_SUCCESS]: assignManagerSuccess,
  [Constants.ASSIGN_MANAGER_ERROR]: assignManagerError,
  [Constants.GET_USERS_LIST_REQUEST]: getUsersListRequest,
  [Constants.LIST_USERS_SUCCESS]: listUsersSuccess,
  [Constants.SEARCH_USER_SUCCESS]: searchUserSuccess,
  [Constants.SEARCH_USER_ERROR]: searchUserError,
  [Constants.SEARCH_USER_REQUEST]: searchUserRequest,
  [Constants.DELETE_USER_SUCCESS]: deleteUserSuccess,
  [Constants.DELETE_USER_ERROR]: deleteUserError,
  [Constants.DELETE_USER_REQUEST]: deleteUserRequest,
  [Constants.INVITE_USER_SUCCESS]: inviteUserSuccess,
  [Constants.INVITE_USER_ERROR]: inviteUserError,
  [Constants.INVITE_USER_REQUEST]: inviteUserRequest,
  [Constants.EDIT_USER_PERMISSION_SUCCESS]: editUserPermissionSuccess,
  [Constants.EDIT_USER_PERMISSION_ERROR]: editUserPermissionError,
  [Constants.EDIT_USER_PERMISSION_REQUEST]: editUserPermissionRequest,
  [Constants.CHANGE_USER_ROLE_SUCCESS]: changeUserRoleSuccess,
  [Constants.CHANGE_USER_ROLE_ERROR]: changeUserRoleError,
  [Constants.CHANGE_USER_ROLE_REQUEST]: changeUserRoleRequest,
  [Constants.CHANGE_USER_STATUS_SUCCESS]: changeUserStatusSuccess,
  [Constants.CHANGE_USER_STATUS_ERROR]: changeUserStatusError,
  [Constants.CHANGE_USER_STATUS_REQUEST]: changeUserStatusRequest,
  [Constants.GET_USER_DETAILS_REQUEST]: getUserDetailsRequest,
  [Constants.GET_USER_DETAILS_SUCCESS]: getUserDetailsSuccess,
  [Constants.GET_USER_DETAILS_ERROR]: getUserDetailsError,
  [Constants.CHANGE_LANG_FOR_USER_REQUEST]: changeLangForUserRequest,
  [Constants.CHANGE_LANG_FOR_USER_ERROR]: changeLangForUserError,
  [Constants.CHANGE_LANG_FOR_USER_SUCCESS]: changeLangForUserSuccess,
  [Constants.HANDLE_LANG]: handleLang,
  [Constants.NEW_LANG_STATE_FOR_REDUX]: newLangStateForRedux,
  [Constants.GET_USERS_BY_ACCEPT_ST_REQUEST]: getUsersByAcceptStRequest,
  [Constants.GET_ACTIVE_USERS_FOR_COMPANY_REQUEST]: getActiveUsersForCompanyRequest,
  [Constants.GET_ACTIVE_USERS_FOR_COMPANY_SUCCESS]: getActiveUsersForCompanySuccess,
  [Constants.GET_ACTIVE_USERS_FOR_COMPANY_ERROR]: getActiveUsersForCompanyError,
  [Constants.GET_USERS_BY_ACCEPT_ST_SUCCESS]: getUsersByAcceptStSuccess,
  [Constants.GET_USERS_BY_ACCEPT_ST_ERROR]: getUsersByAcceptStError,
}

export default createReducer(INITIAL_STATE, HANDLERS)
