import React, { Component } from 'react';
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from '../../../theme/theme';
import Grid from '@mui/material/Grid';
import IconButton from '@material-ui/core/IconButton';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Box from '@mui/material/Box';
import EditBtn from '../../../assets/images/icons/edit.svg';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../../Redux/Actions'
import moment from 'moment'
import { Link, withRouter } from 'react-router-dom'
import { getProjectIdFromUrl } from '../../../helpers/common';
import Loader from 'Components/Loader/loader';

export class TransactionalEmails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasRenderedByTransactionEmail: true,
            transactionalEmailsTemplates: [],
            loading:false
        }
    }

    componentDidMount() {
        const projectId = getProjectIdFromUrl()
        this.listTransactionalEmailsTemplates(projectId)
        this.props.getProjectRequest(projectId)
    }

    componentDidUpdate(prevProps) {
        if (this.props.attendees.listTransactionalEmailTemplatesSuccess === true && this.props.attendees.message !== prevProps.attendees.message) {
            this.setState({
                transactionalEmailTemplates: this.props.attendees.transactionalEmailTemplates,
                loading:false
            });
        }
    }

    listTransactionalEmailsTemplates = (projectId) => {
        this.props.listTransactionalEmailTemplates({ projectId })
        this.setState({
            loading:true
        })
    }

    render() {
        const { transactionalEmailTemplates } = this.state
        return (
            <ThemeProvider theme={theme}>
                <Grid container spacing={2} mb={3} className="top-filter-area">
                    <Grid item >
                        <Grid container alignItems="center">
                            <IconButton size='small' aria-label="delete">
                                <KeyboardBackspaceIcon onClick={this.props.closeTransactionEmailsModal} style={{ color: theme.palette.primary.dark, }} />
                            </IconButton>
                            <p className='subtitle' style={{ color: theme.palette.grey[800], marginLeft: 10, }}>{this.props.users.languageStateForRedux?.column_names?.attendees?.transTemplate}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer className="list-table">
                    <Table className="table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='long-text sort-asc'>{this.props.users.languageStateForRedux?.column_names?.attendees?.templateName}</TableCell>
                                <TableCell className='long-text sort-asc'>{this.props.users.languageStateForRedux?.column_names?.attendees?.subject}</TableCell>
                                <TableCell className='long-text sort-asc'>{this.props.users.languageStateForRedux?.column_names?.attendees?.lastUpdated}</TableCell>
                                <TableCell className='options'>
                                {this.props.users.languageStateForRedux?.common?.action}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(transactionalEmailTemplates) && transactionalEmailTemplates.length &&
                                transactionalEmailTemplates.map(transactionalEmailTemplate => {
                                    return (
                                        <TableRow>
                                            <TableCell className='long-text'>{transactionalEmailTemplate.pageName}</TableCell>
                                            <TableCell className='long-text'>{transactionalEmailTemplate.pageName}</TableCell>
                                            <TableCell className='long-text'> {transactionalEmailTemplate.updatedAt? moment.tz(transactionalEmailTemplate.updatedAt, this.props.project?.project?.timezone).format('dddd, MMMM Do YYYY, h:mm a') : "--" } </TableCell>
                                            <TableCell className='options'>
                                                {' '}
                                                <Box sx={{ textAlign: 'center', }}>
                                                    <Link
                                                        to={{
                                                            pathname: `/project-page/update-transactional-email-templates-page/pid/${transactionalEmailTemplate?.eventId}/tid/${transactionalEmailTemplate?._id}/pn/${transactionalEmailTemplate?.pageName}/purl/${transactionalEmailTemplate?.path}`,
                                                            state: {hasRenderedByTransactionEmail: this.state.hasRenderedByTransactionEmail}
                                                        }}>
                                                        <img alt="" src={EditBtn} />
                                                    </Link>

                                                    {' '}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.state.loading ? <Loader /> : null}
            </ThemeProvider>
        )
    }
}

const mapStateToProps = (state) => ({
    attendees: state.Attendees,
    events: state.Events,
    project: state.Projects,
    users: state.Users,
})
const mapActionsToProps = (dispatch) =>
    bindActionCreators(
        {
            listTransactionalEmailTemplates: Actions.listTransactionalEmailTemplatesRequest,
            getProjectRequest: Actions.getProjectRequest,
        },
        dispatch,
    )

export default connect(mapStateToProps, mapActionsToProps)(withRouter(TransactionalEmails));
