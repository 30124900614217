import React from 'react';
import moment from 'moment'

const OfficialTimeClock = ({ timezone }) => {
    const [date, setDate] = React.useState(new Date());
  
    React.useEffect(() => {
        var timerID = setInterval( () => tick(), 1000 );
        return function cleanup() {
            clearInterval(timerID);
        };
    });
  
    function tick() {
        setDate(new Date());
    }

    return (
        <>
            {moment(date).tz(timezone).format('ddd, MMM, Do YYYY, h:mm:ss A')}
        </>
    );
  }
  
  export default OfficialTimeClock;
  