import React from 'react';
import { useCountdownReverse } from '../../../Common/useCountDownReverse';
import Box from '@material-ui/core/Box';
import ShowCounter from './ShowCounter';

const EventCoundownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdownReverse(targetDate);

  return (
    <ShowCounter
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
    />
  );    
};
  
export default EventCoundownTimer;