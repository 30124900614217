import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import { Button } from '@material-ui/core'
import 'react-responsive-modal/styles.css'

class EmailListModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onCloseModal = () => {
    this.setState({ open: false })
  }

  render() { 
    return (
      <div>
        <h2>{this.props.modalTitle && this.props.modalTitle}</h2>
        <div className="rsvpEventfieldBox sendBounceEmailModal">
          <div className="formSection p-0">
            <ul>
              {this.props.emails &&
                this.props.emails.map((data, index) => {
                  return <li key={index}>{data.email}</li>
                })}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default EmailListModal
