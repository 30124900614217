import React, { useEffect, useState } from 'react';
import CustomSearchableMultiSelectedDropdownWithLoadMore from './CustomSearchableMultiSelectDropdownWithLoadMore';
import { getActiveUsersForCompanyAPI } from 'Redux/API';
import { createNotification } from 'helpers';

function CustomDropdownWithActiveUsers({
  handleChange,
  handleClearAll,
  handleAllCheckbox,
  // handleSearchListInSearchableDropdown,
  listOfSelectedIds,
  dropdownFor,
  handlePageChange,
  disabled,
}) {
  const [usersForUserCfDataType, setUsersForUserCfDataType] = useState([]);
  const [searchTextForUsers, setSearchTextForUsers] = useState('')
  const [searchTextForUsersDebouncedValue, setSearchTextForUsersDebouncedValue] = useState('')

  useEffect(() => {
    fetchAllTheActiveUsers()
  }, [searchTextForUsersDebouncedValue])

  useEffect(() => {
    const timerId = setTimeout(() => {
      setSearchTextForUsersDebouncedValue(searchTextForUsers)
    }, 500);
    return ()=>  {clearTimeout(timerId)}
  }, [searchTextForUsers])

  const fetchAllTheActiveUsers = async() => {
    try {
      const data = {
        searchText: searchTextForUsers
      }
      const response = await getActiveUsersForCompanyAPI(data)
      if(response.data.success === true){
        setUsersForUserCfDataType(response.data.users)
      } else {
        setUsersForUserCfDataType([])
        createNotification('error', 'Something went wrong')
      }
    } catch (error) {
      setUsersForUserCfDataType([])
      console.log('error', error);
    }
  };

  return (
    <CustomSearchableMultiSelectedDropdownWithLoadMore
      options={usersForUserCfDataType}
      handleChange={handleChange}
      handleClearAll={handleClearAll}
      handleAllCheckbox={handleAllCheckbox}
      handleSearchListInSearchableDropdown={(text) => {
        setSearchTextForUsers(text)
      }}
      listOfSelectedIds={listOfSelectedIds}
      dropdownFor={dropdownFor}
      handlePageChange={handlePageChange}
      disabled={disabled}
    />
  );
}

export default CustomDropdownWithActiveUsers;
