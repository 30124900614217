import { FunctionComponent, useEffect, useRef, useState } from 'react';

import { RoutKeys } from 'constants/enums/EventTypes';

import ChatWidget, { Message, WidgetActions } from './Widget';
import { EventTypes } from '../../constants/enums/EventTypes';
import { ChatInterfaceType } from 'Redux/Reducers/aiChatbot.reducer';
interface Props {
  agentId: string | undefined;
  eventId: string;
  chatInterface: ChatInterfaceType;
}
const EventWidgetContainer: FunctionComponent<Props> = (props) => {
  const widgetRef = useRef<WidgetActions>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [activeChatId, setActiveChatId] = useState<string>('');
  const [socket, setSocket] = useState<WebSocket>();
  const [isConnected, setIsConnected] = useState<boolean>(false);

  useEffect(() => {
    const chatId = localStorage.getItem('illumeet_chat_id');
    if (chatId) {
      setActiveChatId(chatId);
    }
  }, [props.agentId]);
  // console.log('L I D', localStorage.getItem('illumeet_chat_id'));
  let chunks: any[] = [];
  const onConnect = (event: any, socket: WebSocket) => {
    console.log('Connected ', event);
    //  console.log('Ready =>', socket);
    if (socket && socket.readyState === socket.OPEN) {
      const data = JSON.stringify({
        action: RoutKeys.CHATBOT,
        eventType: EventTypes.CONNECT,
        chatbotId: props.agentId,
        service: 'chat',
        chatId: activeChatId,
        message: '',
      });
      socket.send(data);
    }
    setIsConnected(true);
  };

  const updateHandler = async (update: any) => {
    // const res = await new AIChatbotAPI().getLastChat({
    //   chatBotId: update.chatBotId,
    //   chatId: update.chatId,
    // });

    console.log('Update => ', update);
    if (update && update.messages) {
      const chat = update;
      if (chat) {
        setActiveChatId(chat.chatId);
        localStorage.setItem('illumeet_chat_id', chat.chatId);
        const messages = chat?.messages
          .filter((m: any) => m._id !== undefined)
          .sort((a: any, b: any) => a.index - b.index)
          .map((mes: any) => {
            return {
              _id: mes._id,
              sender: mes.sender,
              text: mes.text,
              timestamp: mes.timestamp,
              index: mes.index,
            };
          });
        //   console.log('M E S G S', messages);
        //    await initializeSocketService();
        setMessages([...messages]);
      }
    } else {
      setMessages([
        {
          sender: 'assistant',
          text: update.initialMessage || 'Hello, How can I assist you today?',
          id: update.chatId,
        },
      ]);
    }
  };
  const onMessage = (event: any) => {
    // console.log('Response', event);
    const data = JSON.parse(event?.data);
    // console.log('Message Data', data);
    if (data?.data?.type === 'error') {
      //createNotification('error', data.message);
      console.error('Error ', data);
      return;
    } else if (data?.data?.type === 'update') {
      updateHandler(data.data?.update);
    }
    let ind = data.data.index;

    const mesg = { chunk: data.message, index: ind, id: data.data?.messageId };
    //  console.log('M E S G', mesg);
    chunks.push(mesg);
    //  console.log('CHUNKS', chunks);
    const sorted = chunks
      .filter((f) => f.id === data.data?.messageId)
      .sort((pi, ci) => pi.index - ci.index);
    //  console.log('SORTED', sorted);
    const message = sorted.map((m) => m.chunk).join('');
    //console.log('M S G ', message);
    if (data?.message !== undefined) {
      widgetRef.current?.updateMessageById(data.data?.messageId, message);

      // addResponseMessage(message, data.data?.messageId);
    } else {
      console.log('ERROR', data);
    }
  };
  // const stopSocketService = async () => {
  //   socket?.removeEventListener('message', onMessage);
  //   socket?.removeEventListener('open', onConnect);
  //   if (socket) {
  //     socket.close();
  //     setIsConnected(false);
  //   }
  // };
  const onUssrMessage = (
    text: string,
    sender: 'user' | 'assistant',
    id: string = '',
  ) => {
    if (socket && socket.readyState === socket.OPEN) {
      const data = JSON.stringify({
        action: RoutKeys.CHATBOT,
        eventType: EventTypes.MESSAGE,
        chatbotId: props.agentId,
        service: 'chat',
        chatId: activeChatId,
        message: text,
      });
      socket.send(data);
    } else {
      console.error('WebSocket connection not open or available.');
      // Handle the scenario when the socket is not open
    }
  };
  const onWidgetToggle = (isOpen: boolean) => {
    console.log(socket?.readyState, socket?.CLOSED);
    if (
      !isOpen &&
      (!socket?.readyState || socket?.readyState === socket?.CLOSED)
    ) {
      initializeSocketService();
    }
  };
  const initializeSocketService = async () => {
    const chatbotId = props.agentId;
    console.log('A G E N T ID', chatbotId);
    if (chatbotId) {
      let webSocketUrl = process.env.REACT_APP_CHATBOT_WEBSOCKET_URL || '';
      const socket: WebSocket = new WebSocket(webSocketUrl);
      // if (socket) {
      //@ts-ignore
      socket.onopen = (e) => {
        onConnect(e, socket);
      };
      socket.onmessage = onMessage;
      setSocket(socket);
      // }
    }
  };
  // const newConversation = async () => {
  //   const resp = await new AIChatbotAPI().createNewConversation({
  //     chatBotId: props.agentId,
  //   });
  //   if (resp.data.success) {
  //     await stopSocketService();
  //     setActiveChatId(resp.data.conversation._id);
  //     setMessages([]);
  //     setIsConnected(false);
  //     await initializeSocketService();
  //   }
  // };

  return (
    <div>
      <ChatWidget
        ref={widgetRef}
        messages={messages}
        onUssrMessage={onUssrMessage}
        onToggle={onWidgetToggle}
        chatInterface={props?.chatInterface}
      />
    </div>
  );
};
export default EventWidgetContainer;
