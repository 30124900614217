import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  fetchAllOpportunityAudienceRequest: ['data'],
  fetchAllOpportunityAudienceSuccess: ['audience', 'message','totalAudience'],
  fetchAllOpportunityAudienceError: ['error'],
  deleteOpportunityAudienceRequest: ['data'],
  deleteOpportunityAudienceSuccess: ['audience','message','totalAudience'],
  deleteOpportunityAudienceError: ['error'],
  editOpportunityAudienceRequest: ['data'],
  editOpportunityAudienceSuccess: ['audience','message','totalAudience'],
  editOpportunityAudienceError: ['error'],
  createOpportunityAudienceRequest: ['data'],
  createOpportunityAudienceSuccess: ['audience','message','totalAudience'],
  createOpportunityAudienceError: ['error'],
  getAllOpportunityGroupsRequest: ['data'],
  getAllOpportunityGroupsSuccess: ['groups','message','totalGroups'],
  getAllOpportunityGroupsError: ['error'],
});
export const Constants = Types;

export default Creators;