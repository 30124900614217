import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
  success: false,
  error: false,
  message: null,
  event: null,
  deleteSuccess: false,
  totalResults: 0,
  searchSuccess: false,
  csvSuccess: false,
  updateSuccess: false,
  emailSuccess: false,
  emailReportsSuccess: false,
  emailDataReportsSuccess: false,
  reports: null,
  monthReports: null,
  domainSuccess: false,
  domainError: false,
  templates: null, 
  allEvents: null,
  emailRegistrationDataReportsSuccess: false,
  getProjectSuccess: false,
  project: null,
  isAccessCode:false
} 

const eventsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: true,
    event: action.event,
    message: action.message,
  }
}

const eventsError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const eventsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    emailRegistrationDataReportsSuccess: false,
  }
}
const listEventsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: true,
    totalResults: action.totalResults,
    event: action.event,
    message: action.message,
    allEvents: action.allEvents,
  }
}

const listEventsError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const listEventsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    totalResults: 0,
    emailRegistrationDataReportsSuccess: false,
  }
}

const listSingleEventsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: true,
    event: action.event,
    templates: action.templates,
    message: action.message,
  }
}

const getProjectSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    getProjectSuccess: true,
    project: action.project, 
    message: action.message,
    isAccessCode:action.project?.isAccessCode
  }
}

const listSingleEventsError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}
const getProjectError = (state = INITIAL_STATE, action) => {
  return { 
    ...state, 
    getProjectSuccess: false,
    error: true, 
    message: action.error,
    project: null 
  }
}

const listSingleEventsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    emailRegistrationDataReportsSuccess: false,
  }
}
const getProjectRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,    
    getProjectSuccess: false,
    error: false,
    message: null,
    project: null,
  }
}

const getProjectTemplateRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    template: [],
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    emailRegistrationDataReportsSuccess: false,
  }
}

/* const getProjectTemplateSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    template: action.template,
    events: action.events,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    emailRegistrationDataReportsSuccess: false,
  }
} */

/* const getProjectTemplateError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    emailRegistrationDataReportsSuccess: false,
  }
} sdf*/

const eventEmailSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, emailSuccess: true, message: action.message }
}

const eventEmailError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const eventEmailRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    event: null,
    emailRegistrationDataReportsSuccess: false,
  }
}

const updateEventsSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, updateSuccess: true, message: action.message }
}

const updateEventsError = (state = INITIAL_STATE, action) => {
  return { ...state, updateSuccess: false, error: true, message: action.error }
}

const updateEventsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    emailRegistrationDataReportsSuccess: false,
  }
}

const searchEventsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    searchSuccess: true,
    totalResults: action.totalResults,
    event: action.event,
    message: action.message,
  }
}

const searchEventsError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const searchEventsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    totalResults: 0,
    emailRegistrationDataReportsSuccess: false,
  }
}

const csvSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    csvSuccess: true,
    csvFile: action.csvFile,
    message: action.message,
  }
}

const csvError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const csvRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    message: null,
    error: false,
    emailRegistrationDataReportsSuccess: false,
  }
}

const emailReportsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    emailReportsSuccess: true,
    reports: action.reports,
    message: action.message,
  }
}

const emailReportsError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const emailReportsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    csvSuccess: false,
    message: null,
    error: false,
    emailRegistrationDataReportsSuccess: false,
  }
}

const emailDataReportsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    emailDataReportsSuccess: true,
    reports: action.reports,
    monthReports: action.monthReports,
    message: action.message,
  }
}

const emailDataReportsError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const emailDataReportsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    csvSuccess: false,
    message: null,
    error: false,
    emailRegistrationDataReportsSuccess: false,
  }
}

const checkEventDomainSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, domainSuccess: true, message: action.message }
}

const checkEventDomainError = (state = INITIAL_STATE, action) => {
  return { ...state, domainError: true, message: action.error }
}

const checkEventDomainRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    emailRegistrationDataReportsSuccess: false,
  }
}

const emailRegistrationDataReportsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    emailRegistrationDataReportsSuccess: true,
    reports: action.reports,
    monthReports: action.monthReports,
    message: action.message,
  }
}

const emailRegistrationDataReportsError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const emailRegistrationDataReportsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailRegistrationDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    csvSuccess: false,
    message: null,
    error: false,
  }
}


const replicatePageRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    replicatePageSuccess: false,
    message: null,
    error: false,
  }
}

const replicatePageSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    replicatePageSuccess: true,
    message: action.message,
    error: false,
  }
}
const replicatePageError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    replicatePageSuccess: false,
    message: action.message,
    error: true,
  }
}


const HANDLERS = {
  [Constants.EVENTS_SUCCESS]: eventsSuccess,
  [Constants.EVENTS_ERROR]: eventsError,
  [Constants.EVENTS_REQUEST]: eventsRequest,
  [Constants.LIST_EVENTS_SUCCESS]: listEventsSuccess,
  [Constants.LIST_EVENTS_ERROR]: listEventsError,
  [Constants.LIST_EVENTS_REQUEST]: listEventsRequest,
  [Constants.EVENT_EMAIL_SUCCESS]: eventEmailSuccess,
  [Constants.EVENT_EMAIL_ERROR]: eventEmailError,
  [Constants.EVENT_EMAIL_REQUEST]: eventEmailRequest,
  [Constants.UPDATE_EVENTS_SUCCESS]: updateEventsSuccess,
  [Constants.UPDATE_EVENTS_ERROR]: updateEventsError,
  [Constants.UPDATE_EVENTS_REQUEST]: updateEventsRequest,

  [Constants.LIST_SINGLE_EVENT_SUCCESS]: listSingleEventsSuccess,
  [Constants.LIST_SINGLE_EVENT_ERROR]: listSingleEventsError,
  [Constants.LIST_SINGLE_EVENT_REQUEST]: listSingleEventsRequest,

  [Constants.GET_PROJECT_SUCCESS]: getProjectSuccess,
  [Constants.GET_PROJECT_ERROR]: getProjectError,
  [Constants.GET_PROJECT_REQUEST]: getProjectRequest,
  [Constants.GET_PROJECT_TEMPLATE_REQUEST]: getProjectTemplateRequest,
  
  [Constants.SEARCH_EVENTS_SUCCESS]: searchEventsSuccess,
  [Constants.SEARCH_EVENTS_ERROR]: searchEventsError,
  [Constants.SEARCH_EVENTS_REQUEST]: searchEventsRequest,
  [Constants.CSV_EVENTS_SUCCESS]: csvSuccess,
  [Constants.CSV_EVENTS_ERROR]: csvError,
  [Constants.CSV_EVENTS_REQUEST]: csvRequest,
  [Constants.EMAIL_REPORTS_SUCCESS]: emailReportsSuccess,
  [Constants.EMAIL_REPORTS_ERROR]: emailReportsError,
  [Constants.EMAIL_REPORTS_REQUEST]: emailReportsRequest,
  [Constants.EMAIL_DATA_REPORTS_SUCCESS]: emailDataReportsSuccess,
  [Constants.EMAIL_DATA_REPORTS_ERROR]: emailDataReportsError,
  [Constants.EMAIL_DATA_REPORTS_REQUEST]: emailDataReportsRequest,
  [Constants.EMAIL_REGISTRATION_DATA_REPORTS_SUCCESS]: emailRegistrationDataReportsSuccess,
  [Constants.EMAIL_REGISTRATION_DATA_REPORTS_ERROR]: emailRegistrationDataReportsError,
  [Constants.EMAIL_REGISTRATION_DATA_REPORTS_REQUEST]: emailRegistrationDataReportsRequest,
  [Constants.CHECK_EVENT_DOMAIN_SUCCESS]: checkEventDomainSuccess,
  [Constants.CHECK_EVENT_DOMAIN_ERROR]: checkEventDomainError,
  [Constants.CHECK_EVENT_DOMAIN_REQUEST]: checkEventDomainRequest,

  [Constants.REPLICATE_PAGE_REQUEST]: replicatePageRequest,
  [Constants.REPLICATE_PAGE_SUCCESS]: replicatePageSuccess,
  [Constants.REPLICATE_PAGE_ERROR]: replicatePageError,
}

export default createReducer(INITIAL_STATE, HANDLERS)
