import { Button } from '@material-ui/core';
import { Box } from '@mui/material';
import { FilterCount as FilterCountForAccounts} from 'Redux/Reducers/opportunity/accounts.reducer';
import { FilterCount as FilterCountForContacts} from 'Redux/Reducers/opportunity/contacts.reducer';
import React from 'react';
import { theme } from 'theme/theme';

type Props = {
    filterByStatus: string[],
    setFilterByStatus: React.Dispatch<React.SetStateAction<string[]>>,
    filterCount: FilterCountForContacts | FilterCountForAccounts
}

function FilterByDraft({filterByStatus, setFilterByStatus, filterCount}: Props) {

  const handleFilterByDraft = () => {
    if (filterByStatus.includes('draft')) {
      let filterByStatusCopy = [...filterByStatus];
      setFilterByStatus(filterByStatusCopy.filter((el) => el !== 'draft'));
    } else {
      setFilterByStatus((prev) => {
        prev.push('draft');
        return [...prev];
      });
    }
  };

  return (
    <Box
      component={Button}
      variant="contained"
      sx={{
        //@ts-ignore
        backgroundColor: theme.palette.background.light,
        color: theme.palette.common.black,
        textTransform: 'capitalize !important',
        fontWeight: '400',
        '&:hover, &.active': {
          backgroundColor: '#000056 !important',
          color: theme.palette.primary.contrastText,
        },
      }}
      className={filterByStatus.includes('draft') ? 'active' : ''}
      size="small"
      disableElevation
      onClick={handleFilterByDraft}
    >
      Draft ({filterCount?.statusCount?.draft || 0})
    </Box>
  );
}

export default FilterByDraft;
