import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import Routes from './Routes'
import * as serviceWorker from './serviceWorker'
import { createStore, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './Redux/Reducers'
import rootSaga from './Redux/Saga'
import "../src/assets/styles/illu-main.scss";

const sagaMiddleware = createSagaMiddleware()
const store = createStore(
  rootReducer,
  /// compose(applyMiddleware(sagaMiddleware, logger)), //dev
    compose(applyMiddleware(sagaMiddleware)),// prod
)
sagaMiddleware.run(rootSaga)

ReactDOM.render(
  <Provider store={store}>
    <div>
      <BrowserRouter>
        <Routes history={BrowserRouter} />
      </BrowserRouter>
    </div>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
