import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  groupRequest: ['data'],
  groupSuccess: ['groups', 'message', 'totalResults'],
  groupError: ['error'],
  updateGroupRequest: ['data'],
  updateGroupSuccess: ['groups', 'message'],
  updateGroupError: ['error'],
  deleteGroupRequest: ['data'],
  deleteGroupSuccess: ['groups', 'message'],
  deleteGroupError: ['error'],
  searchGroupRequest: ['data'],
  searchGroupSuccess: ['groups', 'message'],
  searchGroupError: ['error'],
  groupCheckRequest: ['data'],
  groupCheckSuccess: ['message'],
  groupCheckError: ['error'],
  addAudienceCustomFieldsRequest: ['data'],
  addAudienceCustomFieldsError: ['error'],
  addAudienceCustomFieldsSuccess: ['message'],
  listAudienceCustomFieldsRequest: ['data'],
  listAudienceCustomFieldsError: ['error'],
  listAudienceCustomFieldsSuccess: ['customFields','message'],
  deleteAudienceCustomFieldsRequest: ['data'],
  deleteAudienceCustomFieldsError: ['error'],
  deleteAudienceCustomFieldsSuccess: ['message'],
  updateAudienceCustomFieldsRequest: ['data'],
  updateAudienceCustomFieldsError: ['error'],
  updateAudienceCustomFieldsSuccess: ['message'],
})

export const Constants = Types
export default Creators
