import { useEffect, useState } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'Redux/Actions';
import PaginationFunctional, { handleEndCountForPagination } from './PaginationFunctional';
import Loader from 'Components/Loader/loader';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';


function ViewUsers(props) {
    const dispatch = useDispatch();
    const googleWorkspaceState = useSelector((store) => store.GoogleWorkspace);
    const [users, setUsers] = useState([]);
    // variables for pagination started
    const [limit, setLimit] = useState(10);
    const [page_no, setPage_no] = useState(1);
    const [startCount, setStartCount] = useState(1);
    const [endCount, setEndCount] = useState(0);
    const [userCount, setUserCount] = useState(0);
    // variables for pagination ends

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUsers = (limitFromPagination, pageNumFromPagination) => {
        dispatch(Actions.getGoogleUsers({
            projectId: props.projectId,
            page_no: pageNumFromPagination || page_no,
            limit: limitFromPagination || limit,
            integrationId: props.integrationId
        }));
    };

    useEffect(() => {
        if (googleWorkspaceState?.googleUsers?.length > -1) {
            const { googleUsers, totalCount } = googleWorkspaceState;
            setUsers(googleUsers);
            setUserCount(totalCount);
            handleEndCountForPagination(totalCount, limit, page_no, setEndCount);
        }
    }, [googleWorkspaceState, limit, page_no]);

    const handleCloseView = () => {
        props.setShowUsersList(false);
    };
    return (
        <Box mt={1}>
            <Box>
                <Stack direction={'row'} alignItems="center">
                    <Button
                        onClick={handleCloseView}
                        className="back-button"
                        variant="outlined"
                        color="primary"
                        size='small'
                        startIcon={<KeyboardBackspaceIcon />}
                    >
                        Back
                    </Button>
                    <Box ml={2} className="paragraph bold">
                        Synced Users ({startCount + ' to ' + endCount + ' of ' + userCount})
                    </Box>
                </Stack>
            </Box>
            <TableContainer className="list-table">
                <Table className="table">
                    <TableHead>
                        <TableRow>
                            <TableCell width={300}
                                className="email sticky-cell"
                                style={{
                                    left: '0px',
                                    backgroundColor: '#ffffff',
                                }}
                            >
                                Email Address
                            </TableCell>
                            <TableCell width={200} className="date-time">
                                Name
                            </TableCell>

                            <TableCell width={50} className="email">
                                Gender
                            </TableCell>
                            <TableCell width={150} className="status">
                                Org Unit Path
                            </TableCell>
                            <TableCell width={100} className="status">
                                Languages
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {googleWorkspaceState.loading ? <Loader />
                        :
                        <TableBody>
                            {users && users.length &&
                                users.map((user, i) => {
                                    return (
                                        <TableRow key={"us_" + i}>
                                            <TableCell
                                                className="email sticky-cell"
                                                style={{
                                                    left: '0px',
                                                    backgroundColor: '#ffffff',
                                                }}
                                            >
                                                {user.primaryEmail}
                                            </TableCell>
                                            {/* <TableCell className="date-time">
                                                {moment.tz(user.creationTime, projectsState?.project?.timezone,).format('MMM, DD YYYY, h:mm A')}
                                            </TableCell> */}


                                            <TableCell className="email">
                                                {user.name?.fullName}
                                            </TableCell>
                                            <TableCell className="email">
                                                {user.gender && user.gender.type}
                                            </TableCell>

                                            <TableCell className="email">
                                                {user.orgUnitPath}
                                            </TableCell>
                                            <TableCell className="email">
                                                {user.languages?.map((l) => (l.preference === "preferred" ? l.languageCode + " (preferred)" : l.languageCode + ","))}
                                            </TableCell>

                                            {/* <TableCell className="status">
                                                <span className={`table-status ${!user.syncStatus ? 'danger' : 'success'}`}>
                                                    {user.gender ? "SUCCESS" : "FAILED"}{' '}
                                                </span>
                                            </TableCell> */}
                                        </TableRow>

                                    );
                                })
                            }
                        </TableBody>}
                </Table>
            </TableContainer>
            <PaginationFunctional
                startCount={startCount}
                setStartCount={setStartCount}
                endCount={endCount}
                limit={limit}
                setLimit={setLimit}
                page_no={page_no}
                setPage_no={setPage_no}
                totalItemsCount={userCount}
                listItemsFn={getUsers}
            />
        </Box>
    );
}

export default ViewUsers;