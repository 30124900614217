import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  loginRequest: ['data'],
  loginSuccess: ['token', 'user'],
  loginFailure: ['error'],
  subscriptionError: ['error'],

  forgotPasswordRequest: ['data'],
  forgotPasswordSuccess: ['message'],
  forgotPasswordError: ['error'],
  resetPasswordRequest: ['data'],
  resetPasswordSuccess: ['message'],
  resetPasswordError: ['error'],
})

export const Constants = Types
export default Creators
