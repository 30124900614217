import React from 'react';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { createNotification } from 'helpers';

const CustomDropzone = (props) => {
  const handleAlert = (message, variant) => {
    // Customize the error message using createNotification
    let modifiedMessage = message;
    if (variant === 'error') {
      if (message.includes('File type not supported')) {
        modifiedMessage = 'File was rejected. File type not supported';
      }
      createNotification('error', modifiedMessage);
    } else if (variant === 'info') {
      createNotification('info', message);
    } else if (variant === 'warning') {
      createNotification('warning', message);
    }
  };

  const modifiedProps = {
    ...props,
    filesLimit: 5, // Set maximum number of files
    showPreviews: false,
    showPreviewsInDropzone: false,
    maxFileSize: 10000000000,
    showAlerts: false, // Disable default alerts
    onAlert: (message, variant) => handleAlert(message, variant), // Handle alerts manually
  };

  return <DropzoneAreaBase ref={props.dropzoneDeleteRef} {...modifiedProps} />;
};

export default CustomDropzone;