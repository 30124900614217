import React, { useEffect } from 'react'
import { Button } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import CountDownComp from '../../../Components/CountDown'

const SignInForm = ({
  emailFieldName = 'email',
  emailFieldValue = '',
  passwordFieldName = 'password',
  passwordFieldValue = '',
  bannerHeading = 'Put Your Heading Here',
  formHeading = 'Sign In',
  emailLabel = 'Email',
  emailInputPlaceholder = 'Enter a email',
  passwordLabel = 'Password',
  passwordInputPlaceholder = 'Enter password',
  saveButtonLabel = 'Save',
  anchorTagLabel = 'Privacy Setting',
  anchorTagLink = '#',
  backgroundColor,
  color,
  backgroundImage,
  fontFamily,
  setActiveLayout,
  onChange,
  onLogin,
  registerNow,
  history,
  activeLayout,
  showForm = true,
  user = {},
  view,
  endTimeTimer,
  timerClock,
}) => {
  useEffect(() => {
    if (backgroundImage) {
      const element = document.getElementById('signInFormBanner')
      element.style.backgroundImage = `url(${backgroundImage})`
    }
  }, [backgroundImage])

  const activeStyle =
    activeLayout === 'signInForm' && !view ? { border: '2px solid red' } : {}

  return (
    <div
      id="signInFormBanner"
      className="banner_section formBanner"
      onClick={() => setActiveLayout('signInForm')}
      style={activeStyle}
    >
      <div className="banner_content ">
        <div className="banner_heading">
          <h2 style={{ fontFamily }}>{bannerHeading}</h2>
        </div>
        {showForm || Object.keys(user).length == 0 ? (
          <div className="banner_form">
            <h4 style={{ fontFamily }}>{formHeading}</h4>
            <div className="formSection p-0">
              <div className="eventLoginClock">
                {endTimeTimer && timerClock && (
                  <CountDownComp endTimeTimer={endTimeTimer} />
                )}
              </div>
              <form>
                <div className="field-group inputField fullWidthInput">
                  <label style={{ fontFamily }}>{emailLabel}</label>
                  <input
                    name={emailFieldName}
                    value={emailFieldValue}
                    placeholder={emailInputPlaceholder}
                    type="email"
                    className="form-control"
                    autoComplete="off"
                    onChange={(e) => {
                      if (typeof onChange === 'function') {
                        onChange(e)
                      }
                    }}
                    required
                  />
                </div>
                <div className="field-group inputField fullWidthInput">
                  <label style={{ fontFamily }}>{passwordLabel}</label>
                  <div className="paswrd_input">
                    <input
                      name={passwordFieldName}
                      value={passwordFieldValue}
                      placeholder={passwordInputPlaceholder}
                      type="password"
                      className="form-control"
                      autoComplete="off"
                      onChange={(e) => {
                        if (typeof onChange === 'function') {
                          onChange(e)
                        }
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="field-group inputField text-center fullWidthInput">
                  <Button
                    id="signInFormSaveBtn"
                    type="button"
                    className="largeBlueThemeBttn"
                    style={{ backgroundColor }}
                    onClick={(e) => {
                      if (typeof onLogin === 'function') {
                        onLogin(e)
                      }
                    }}
                  >
                    {saveButtonLabel}
                  </Button>
                </div>
                {registerNow && (
                  <div className="field-group inputField text-center fullWidthInput">
                    <Button
                      id="signInFormSaveBtn"
                      type="button"
                      className="largeBlueThemeBttn"
                      style={{ backgroundColor }}
                      onClick={(e) => {
                        if (typeof history.push === 'function') {
                          history.push(registerNow)
                        }
                      }}
                    >
                      Register now
                    </Button>
                  </div>
                )}

                <div className="field-group inputField text-center fullWidthInput mb-0">
                  <a
                    id="signInFormPrivacy"
                    className="privacy_setng"
                    href={anchorTagLink}
                    target="_blank"
                    style={{ color, fontFamily }}
                  >
                    {anchorTagLabel}
                  </a>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <h1 style={{ textAlign: 'center', color: 'white' }}>
            Welcome {user.firstName} {user.lastName}
          </h1>
        )}
      </div>
    </div>
  )
}

SignInForm.propTypes = {
  bannerHeading: PropTypes.string.isRequired,
  formHeading: PropTypes.string.isRequired,
}

export default withRouter(SignInForm)
