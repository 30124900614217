import React, { Dispatch, SetStateAction } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';
import Typography from '@material-ui/core/Typography';
import BackButtonOutlined from '../../../src/assets/images/icons/backButtonOutlined.svg';
import Button from '@material-ui/core/Button';
import '../../sass/main.scss';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
//@ts-ignore
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import GeneralDashboard from './Tabs/General/GeneralDashboard';
import SettingsDashboard from './Tabs/Settings/SettingsDashboard';
import { useParams, useHistory } from 'react-router-dom';
import Dashboard from './Tabs/Dashboard/DashboardDashboard';
import ChatWiew from './Tabs/ChatWidget/ChatView';

interface SxProps {
  background: string;
  marginTop: string;
  marginBottom: string;
}

interface AiChatbotDashboardLayoutProps {
  userId: string;
  agentId: string | undefined;
  setOpenEdit: Dispatch<SetStateAction<boolean>>;
  setIdToEdit: Dispatch<SetStateAction<string | undefined>>;
  searchText: string;
}

const AiChatbotDashboardLayout = ({
  userId,
  agentId,
  setOpenEdit,
  setIdToEdit,
  searchText,
}: AiChatbotDashboardLayoutProps) => {
  const history = useHistory();
  const params: {
    id: string;
  } = useParams();

  const handleBackButton = () => {
    setOpenEdit(false);
    setIdToEdit('');
    history.push('/aichatbot');
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} mb={2} className="top-filter-area">
        <Grid item>
          <Grid container alignItems={'center'} spacing={2}>
            <Grid item>
              <Button
                size="small"
                style={{ padding: '0', minWidth: '0' }}
                variant="text"
                color="primary"
                onClick={handleBackButton}
              >
                <img src={BackButtonOutlined} alt="back" />
              </Button>
            </Grid>
            <Grid item>
              {/* <Typography variant="body2" className="small-subtitle">
                AI Agent <KeyboardArrowRightIcon style={{ fontSize: '18px' }} />{' '}
                General
              </Typography> */}
              <Box className="heading"> AI Agent </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Box component={'div'} p={0} mb={3} className="header-common">
        <Grid item>
          <h1 className="heading"> AI Agent </h1>
        </Grid>
      </Box> */}

      <Box className="container-with-sidebar" p={0}>
        <Tabs defaultIndex={0}>
          <Box
            className="project-sidebar"
            sx={
              {
                background: 'transparent !important',
                marginTop: '0 !important',
                marginBottom: '0 !important',
              } as SxProps
            }
            my={0}
          >
            <TabList>
              <Tab>
                {' '}
                <Link
                  to={`/aichatbot/general${params?.id ? '/' + params?.id : ''}`}
                >
                  {' '}
                  General{' '}
                </Link>
              </Tab>
              {params.id ? (
                <>
                  <Tab>
                    {' '}
                    <Link to={`/aichatbot/settings/${params.id}`}>
                      Settings
                    </Link>
                  </Tab>

                  <Tab>
                    {' '}
                    <Link to={`/aichatbot/chatwidget/${params.id}`}>
                      Chat Widget
                    </Link>
                  </Tab>

                  <Tab>
                    {' '}
                    <Link to={`/aichatbot/dashboard/${params.id}`}>
                      Dashboard
                    </Link>
                  </Tab>
                  {/* <Tab>
                    {' '}
                    <Link to={`/aichatbot/integrations/${params.id}`}>
                      Integrations
                    </Link>
                  </Tab>{' '} */}
                </>
              ) : (
                <>
                  <Tab disabled>
                    {' '}
                    <a>Settings</a>
                  </Tab>
                  <Tab disabled>
                    <a>Chat Widget</a>
                  </Tab>

                  <Tab disabled>
                    {' '}
                    <a>Dashboard</a>
                  </Tab>
                  {/* <Tab disabled>
                    {' '}
                    <span>Integrations</span>
                  </Tab> */}
                </>
              )}
            </TabList>
          </Box>

          <Box className="container-sidebar-right">
            <TabPanel index={0}>
              <GeneralDashboard
                userId={userId}
                agentIdFromParent={agentId}
                searchText={searchText}
              />
            </TabPanel>

            <TabPanel index={1}>
              <SettingsDashboard />
            </TabPanel>

            <TabPanel index={2}>
              <ChatWiew agentId={agentId} />
            </TabPanel>

            <TabPanel index={3}>
              <Dashboard />
            </TabPanel>
            {/* 
            <TabPanel index={4}>Leaderboard</TabPanel> */}
          </Box>
        </Tabs>
      </Box>
    </ThemeProvider>
  );
};

export default AiChatbotDashboardLayout;
