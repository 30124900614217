import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
    error: false,
    message: null,
    listWebhooksSuccess: false,
    listOfWebhooks: [],
    totalResults: 0,
    toggleWebhookSuccess: false,
    generateSecretKeySuccess: false,
    checkWebhookEventIdSuccess: false,
    secretKey: '',
    secretKeyExist: [],
    webhookData: {},
    activeStatus: false,
}

const listWebhooksRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listWebhooksSuccess: false,
        listOfWebhooks: [],
        message: null,
        totalResults: 0,
        error: false,
    }
}
const listWebhooksSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listWebhooksSuccess: true,
        listOfWebhooks: action.list,
        message: action.message,
        totalResults: action.totalResults,
        error: false,
    }
}

const listWebhooksError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listWebhooksSuccess: false,
        listOfWebhooks: [],
        message: action.message,
        totalResults: 0,
        error: true,
    }
}

const toggleWebhookRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        toggleWebhookSuccess: false,
        message: null,
        error: false,
        webhookData: {},
        activeStatus: false,
    }
}

const toggleWebhookSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        toggleWebhookSuccess: true,
        message: action.message,
        webhookData: action.webhookData,
        activeStatus: action.activeStatus,
        error: false,
    }
}

const toggleWebhookError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        toggleWebhookSuccess: false,
        message: action.message,
        error: true,
        webhookData: {},
        activeStatus: false,
    }
}

const generateSecretKeyRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        generateSecretKeySuccess: false,
        message: null,
        error: false,
        secretKey: '',
    }
}

const generateSecretKeySuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        generateSecretKeySuccess: true,
        message: action.message,
        error: false,
        secretKey: action.secretKey
    }
}

const generateSecretKeyError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        generateSecretKeySuccess: false,
        message: action.message,
        error: true,
        secretKey: '',
    }
}

const checkWebhookEventIdRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        checkWebhookEventIdSuccess: false,
        secretKeyExist: [],
        message: null,
        error: false,
    }
}

const checkWebhookEventIdSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        checkWebhookEventIdSuccess: true,
        message: action.message,
        secretKeyExist: action.secretKey,
        error: false,
    }
}
const checkWebhookEventIdError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        checkWebhookEventIdSuccess: false,
        message: action.message,
        error: true,
        secretKeyExist: [],
    }
}


const HANDLERS = {
    [Constants.LIST_WEBHOOKS_REQUEST]: listWebhooksRequest,
    [Constants.LIST_WEBHOOKS_SUCCESS]: listWebhooksSuccess,
    [Constants.LIST_WEBHOOKS_ERROR]: listWebhooksError,
    [Constants.TOGGLE_WEBHOOK_REQUEST]: toggleWebhookRequest,
    [Constants.TOGGLE_WEBHOOK_SUCCESS]: toggleWebhookSuccess,
    [Constants.TOGGLE_WEBHOOK_ERROR]: toggleWebhookError,
    [Constants.GENERATE_SECRET_KEY_REQUEST]: generateSecretKeyRequest,
    [Constants.GENERATE_SECRET_KEY_SUCCESS]: generateSecretKeySuccess,
    [Constants.GENERATE_SECRET_KEY_ERROR]: generateSecretKeyError,
    [Constants.CHECK_WEBHOOK_EVENT_ID_REQUEST]: checkWebhookEventIdRequest,
    [Constants.CHECK_WEBHOOK_EVENT_ID_SUCCESS]: checkWebhookEventIdSuccess,
    [Constants.CHECK_WEBHOOK_EVENT_ID_ERROR]: checkWebhookEventIdError,
}

export default createReducer(INITIAL_STATE, HANDLERS)
