import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
  success: false,
  error: false,
  addSuccess: false,
  addError: false,
  editSuccess: false,
  editError: false,
  updateAfterEditSuccess: false,
  updateAfterEditError: false,
  deleteSuccess: false,
  deleteError: false,
  searchSuccess: false,
  searchError: false,
  notifications: null,
  message: null,
  totalResults: 0,
  totalResults1:null,
  csvSuccess: false,
  isDeleted: false,
  notification: null,
  filterByStatus: {},
  replicateProjectNotificationSuccess: false,
  listProjectNotificationsSuccess: false,
}

const projectNotificationSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error:false,
    notification: action.notification,
    message: action.message,
    addSuccess: true,
    updateAfterEditSuccess: false,
  }
}

const projectNotificationError = (state = INITIAL_STATE, action) => {
  return { ...state, 
    error:true,
    message: action.error,
    addSuccess: false,
    addError: true,
  }
}

const projectNotificationRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    error: false,
    updateSuccess: false,
    message: null,
    searchSuccess: false,
    csvSuccess: false,
    notification: null,
    editSuccess: false,
    addSuccess: false,
    updateAfterEditSuccess: false,
    addError: false, 
  }
}

const listProjectNotificationsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    totalResults: action.totalResults,
    totalResults1: action.totalResults1,
    notifications: action.notifications,
    message: action.message,
    success: true,
    status: action.status,
    updateAfterEditSuccess: false,
    error: false,
    addError: false, 
    filterByStatus: action.filterByStatus,
    listProjectNotificationsSuccess: true,
  }
}

const listProjectNotificationsError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: true,
    message: action.error,
    success: false,
    filterByStatus: {},
    listProjectNotificationsSuccess: false,
  }
}

const listProjectNotificationsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    totalResults: 0,
    addSuccess: false,
    addError: false,
    editSuccess: false,
    editError: false,
    updateError: false,
    deleteSuccess: false,
    deleteError: false,
    searchError: false,
    updateAfterEditSuccess: false,
    filterByStatus: {},
    listProjectNotificationsSuccess: false,

  }
}
const deleteProjectNotificationSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state, updateSuccess: true, deleteSuccess: true, message: action.message, updateAfterEditSuccess: false,
  }
}

const deleteProjectNotificationError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error, deleteSuccess: false }
}

const deleteProjectNotificationRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    csvSuccess: false,
    success: false,
    updateSuccess: false,
    error: false,
    message: null,
    searchError: false,
    searchSuccess: false,
    updateAfterEditSuccess: false,
  }
}

const searchProjectNotificationRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,

    csvSuccess: false,
    success: false,
    searchSuccess: false,
    searchError: false,
    updateSuccess: false,
    error: false,
    message: null,
    totalResults: 0,
    updateAfterEditSuccess: false,
    notifications: null,

  }
}
const searchProjectNotificationSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    searchSuccess: true,
    totalResults: action.totalResults,
    notifications: action.notifications,
    message: action.message,
    updateAfterEditSuccess: false,
    searchError: false,
  }
}

const searchProjectNotificationError = (state = INITIAL_STATE, action) => {
  return { ...state, searchError: true, message: action.error }
}

const editProjectNotificationRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    error: false,
    csvSuccess: false,
    searchSuccess: false,
    updateSuccess: false,
    message: null,
    searchError: false,
    editSuccess: false,
    editError: false,
    updateAfterEditSuccess: false,

  }
}
const editProjectNotificationSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    message: action.message,
    editSuccess: true,
    notification: action.notification,
    updateSuccess: false,
    success: false,
    addSuccess: false,
    searchSuccess: false,
    updateAfterEditSuccess: false,

  }
}
const editProjectNotificationError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    editError: true,
    message: action.error,
    success: false,
  }
}
const updateProjectNotificationSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, updateAfterEditSuccess: true, message: action.message, editSuccess: false, success: false}
}

const updateProjectNotificationError = (state = INITIAL_STATE, action) => {
  return {
    ...state, updateAfterEditError: true, message: action.error, updateAfterEditSuccess: false,
  }
}

const updateProjectNotificationRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    error: false,
    updateSuccess: false,
    updateError: false,
    message: null,
    searchSuccess: false,
    searchError: false,
    updateAfterEditSuccess: false,
    editSuccess: false,
    updateAfterEditError: false,
  }
}
const replicateProjectNotificationSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, replicateProjectNotificationSuccess: true, message: action.message, editSuccess: false, success: false, error: false}
}

const replicateProjectNotificationError = (state = INITIAL_STATE, action) => {
  return {
    ...state, error: true, message: action.error, replicateProjectNotificationSuccess: false,
  }
}

const replicateProjectNotificationRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    error: false,
    updateSuccess: false,
    updateError: false,
    message: null,
    searchSuccess: false,
    searchError: false,
    replicateProjectNotificationSuccess: false,
    editSuccess: false,
    updateAfterEditError: false,
  }
}

const HANDLERS = {
  [Constants.SEARCH_PROJECT_NOTIFICATION_REQUEST]: searchProjectNotificationRequest,
  [Constants.SEARCH_PROJECT_NOTIFICATION_ERROR]: searchProjectNotificationError,
  [Constants.SEARCH_PROJECT_NOTIFICATION_SUCCESS]: searchProjectNotificationSuccess,
  [Constants.UPDATE_PROJECT_NOTIFICATION_ERROR]:
    updateProjectNotificationError,
  [Constants.UPDATE_PROJECT_NOTIFICATION_REQUEST]:
    updateProjectNotificationRequest,
  [Constants.UPDATE_PROJECT_NOTIFICATION_SUCCESS]:
    updateProjectNotificationSuccess,
  [Constants.LIST_PROJECT_NOTIFICATIONS_SUCCESS]:
    listProjectNotificationsSuccess,
  [Constants.LIST_PROJECT_NOTIFICATIONS_ERROR]: listProjectNotificationsError,
  [Constants.LIST_PROJECT_NOTIFICATIONS_REQUEST]:
    listProjectNotificationsRequest,
  [Constants.PROJECT_NOTIFICATION_SUCCESS]: projectNotificationSuccess,
  [Constants.PROJECT_NOTIFICATION_ERROR]: projectNotificationError,
  [Constants.PROJECT_NOTIFICATION_REQUEST]: projectNotificationRequest,
  [Constants.DELETE_PROJECT_NOTIFICATION_REQUEST]:
    deleteProjectNotificationRequest,
  [Constants.DELETE_PROJECT_NOTIFICATION_SUCCESS]:
    deleteProjectNotificationSuccess,
  [Constants.DELETE_PROJECT_NOTIFICATION_ERROR]: deleteProjectNotificationError,
  [Constants.SEARCH_PROJECT_NOTIFICATION_SUCCESS]:
    searchProjectNotificationSuccess,
  [Constants.SEARCH_PROJECT_NOTIFICATION_ERROR]: searchProjectNotificationError,
  [Constants.SEARCH_PROJECT_NOTIFICATION_REQUEST]:
    searchProjectNotificationRequest,
  [Constants.EDIT_PROJECT_NOTIFICATION_REQUEST]: editProjectNotificationRequest,
  [Constants.EDIT_PROJECT_NOTIFICATION_ERROR]: editProjectNotificationError,
  [Constants.EDIT_PROJECT_NOTIFICATION_SUCCESS]: editProjectNotificationSuccess,
  [Constants.REPLICATE_PROJECT_NOTIFICATION_REQUEST]: replicateProjectNotificationRequest,
  [Constants.REPLICATE_PROJECT_NOTIFICATION_ERROR]: replicateProjectNotificationError,
  [Constants.REPLICATE_PROJECT_NOTIFICATION_SUCCESS]: replicateProjectNotificationSuccess,
}

export default createReducer(INITIAL_STATE, HANDLERS)
