import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  memberRequest: ['data'],
  memberSuccess: ['message'],
  memberError: ['error'],
  listMemberRequest: ['data'],
  listProjectUsersRequest: ['data'],
  listMemberSuccess: ['members', 'totalResults', 'message', 'filterCnt'],
  listProjectUsersSuccess: ['members', 'totalResults', 'message', 'filterCnt'],
  listMemberError: ['error'],
  updateMemberRequest: ['data'],
  updateMemberSuccess: ['message'],
  updateMemberError: ['error'],
  deleteMemberRequest: ['data'],
  deleteMemberSuccess: ['message'],
  deleteMemberError: ['error'],
  searchMemberRequest: ['data'],
  searchMemberSuccess: ['members', 'totalResults', 'message'],
  searchMemberError: ['error'],
  invitationRequest: ['data'],
  invitationSuccess: ['message'],
  invitationError: ['error'],
})

export const Constants = Types
export default Creators
