import { Constants } from "Redux/Actions";
import {createReducer} from "reduxsauce"
import { Contact } from "./contacts.reducer";

export type AudienceState = {
    audienceList: AudienceParent[],
    fetchAllOpportunityAudienceSuccess: boolean,
    error: boolean,
    loading: boolean,
    message: string,
    totalAudience: number,
    deleteOpportunityAudienceSuccess:boolean,
    createOpportunityAudienceSuccess:boolean,
    editOpportunityAudienceSuccess :boolean,
    assignContactListSuccess:boolean,
    getAllOpportunityGroupsSuccess:boolean,
    unassignContactsList:UnassignContacts[],
    totalUnassignContact:number,
    groups:groups[],
    totalGroups:number
}

export type AudienceChild = {
    isSmartGroup: boolean;
    parentId: string;
    audienceName: string,
    id: string,
    updatedAt: Date,
    contactCount : number,
    children:AudienceParent[],
    smartGroupFilter:smartGroupFilter[],
    description:string
}

export type smartGroupFilter = {
    column: string;
    condition: string;
    logicalOperator?: string;
    value: string;
    fieldType: string;
}

export type AudienceParent = {
    isSmartGroup:boolean,
    parentId: string;
    audienceName: string,
    id: string,
    updatedAt: Date,
    contactCount : number
    children:AudienceChild[]
    smartGroupFilter:smartGroupFilter[],
    description:string
}

export type UnassignContacts = {
    firstName: string,
    middleName: string,
    lastName: string,
    email: string,
    _id: string,
    updatedAt: Date,
}

export type groups = {
    _id: string,
    userId: string,
    audienceName: string,
    updatedAt: Date,
}

const INITIAL_STATE: AudienceState = {
    groups:[],
    unassignContactsList:[],
    audienceList: [],
    getAllOpportunityGroupsSuccess:false,
    assignContactListSuccess:false,
    fetchAllOpportunityAudienceSuccess: false,
    deleteOpportunityAudienceSuccess:false,
    createOpportunityAudienceSuccess:false,
    editOpportunityAudienceSuccess :false,
    error: false,
    loading: false,
    message:'',
    totalAudience: 0,
    totalUnassignContact:0,
    totalGroups:0
}

const fetchAllOpportunityAudienceRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        fetchAllOpportunityAudienceSuccess: false,
        error: false,
        audienceList: [],
        loading: true,
        message: '',
        totalAudience: 0,
    }
}

const fetchAllOpportunityAudienceSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        fetchAllOpportunityAudienceSuccess: true,
        error: false,
        audienceList: action.audience,
        loading: false,
        totalAudience: action.totalAudience,
    }
}

const fetchAllOpportunityAudienceError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        fetchAllOpportunityAudienceSuccess: false,
        error: true,
        loading: false,
        audienceList: [],
        totalAudience: 0,
       
    }
}

const deleteOpportunityAudienceRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        deleteOpportunityAudienceSuccess: false,
        error: false,
        loading: true,
        message: '',
    }
}

const deleteOpportunityAudienceSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        deleteOpportunityAudienceSuccess: true,
        error: false,
        loading: false,
        audienceList:action.audience,
        totalAudience: action.totalAudience,
    }
}

const deleteOpportunityAudienceError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        deleteOpportunityAudienceSuccess: false,
        error: true,
        loading: false,
        message: action.error,
       
    }
}

const editOpportunityAudienceRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        editOpportunityAudienceSuccess: false,
        error: false,
        loading: true,
        message: '',
    }
}

const editOpportunityAudienceSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        editOpportunityAudienceSuccess: true,
        error: false,
        loading: false,
        audienceList:action.audience,
        totalAudience: action.totalAudience,
    }
}

const editOpportunityAudienceError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        editOpportunityAudienceSuccess: false,
        error: true,
        loading: false,
        message: action.error,
       
    }
}
const createOpportunityAudienceRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        createOpportunityAudienceSuccess: false,
        error: false,
        loading: true,
        message: '',
    }
}

const createOpportunityAudienceSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        createOpportunityAudienceSuccess: true,
        error: false,
        loading: false,
        audienceList:action.audience,
        totalAudience: action.totalAudience,
    }
}

const createOpportunityAudienceError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        createOpportunityAudienceSuccess: false,
        error: true,
        loading: false,
        message: action.error,
       
    }
}

const assignContactListRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        assignContactListSuccess: false,
        error: false,
        unassignContactsList: [],
        loading: true,
        message: '',
        totalUnassignContact: 0,
    }
}

const assignContactListSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        assignContactListSuccess: true,
        error: false,
        unassignContactsList: action?.unassignContacts,
        loading: false,
        totalUnassignContact: action?.totalUnassignContact,
    }
}

const assignContactListError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        assignContactListSuccess: false,
        error: true,
        loading: false,
        unassignContactsList: [],
        totalUnassignContact: 0,
       
    }
}

const getAllOpportunityGroupsRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        getAllOpportunityGroupsSuccess: false,
        error: false,
        groups: [],
        loading: true,
        message: '',
        totalGroups: 0,
    }
}

const getAllOpportunityGroupsSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        getAllOpportunityGroupsSuccess: true,
        error: false,
        groups: action?.groups,
        loading: false,
        totalGroups: action?.totalGroups,
    }
}

const getAllOpportunityGroupsError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        getAllOpportunityGroupsSuccess: false,
        error: true,
        loading: false,
        groups: [],
        totalGroups: 0,
       
    }
}

export const HANDLERS = {

[Constants.FETCH_ALL_OPPORTUNITY_AUDIENCE_REQUEST]: fetchAllOpportunityAudienceRequest,
[Constants.FETCH_ALL_OPPORTUNITY_AUDIENCE_SUCCESS]: fetchAllOpportunityAudienceSuccess,
[Constants.FETCH_ALL_OPPORTUNITY_AUDIENCE_ERROR]: fetchAllOpportunityAudienceError,
[Constants.DELETE_OPPORTUNITY_AUDIENCE_REQUEST]: deleteOpportunityAudienceRequest,
[Constants.DELETE_OPPORTUNITY_AUDIENCE_SUCCESS]: deleteOpportunityAudienceSuccess,
[Constants.DELETE_OPPORTUNITY_AUDIENCE_ERROR]: deleteOpportunityAudienceError,
[Constants.EDIT_OPPORTUNITY_AUDIENCE_REQUEST]: editOpportunityAudienceRequest,
[Constants.EDIT_OPPORTUNITY_AUDIENCE_SUCCESS]: editOpportunityAudienceSuccess,
[Constants.EDIT_OPPORTUNITY_AUDIENCE_ERROR]: editOpportunityAudienceError,
[Constants.CREATE_OPPORTUNITY_AUDIENCE_REQUEST]: createOpportunityAudienceRequest,
[Constants.CREATE_OPPORTUNITY_AUDIENCE_SUCCESS]: createOpportunityAudienceSuccess,
[Constants.CREATE_OPPORTUNITY_AUDIENCE_ERROR]: createOpportunityAudienceError,
[Constants.ASSIGN_CONTACT_LIST_REQUEST]: assignContactListRequest,
[Constants.ASSIGN_CONTACT_LIST_SUCCESS]: assignContactListSuccess,
[Constants.ASSIGN_CONTACT_LIST_ERROR]: assignContactListError,
[Constants.GET_ALL_OPPORTUNITY_GROUPS_REQUEST]: getAllOpportunityGroupsRequest,
[Constants.GET_ALL_OPPORTUNITY_GROUPS_SUCCESS]: getAllOpportunityGroupsSuccess,
[Constants.GET_ALL_OPPORTUNITY_GROUPS_ERROR]: getAllOpportunityGroupsError,
}
export default createReducer(INITIAL_STATE, HANDLERS)