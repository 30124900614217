import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Actions } from 'Redux/Actions';

let syncModeArr = [
    { value: 'doNotSync', label: 'Don’t update users from google workspace' },
    { value: 'syncAll', label: 'Update all user from google  Workspace' },
    { value: 'syncByOrgUnit', label: 'Update users from selected organizational units.' },
];

function WorkspaceSyncModes({ syncMode, setSyncMode, orgUnitCount, setIsDirty }) {

    const dispatch = useDispatch();
    useEffect(() => {
        syncModeArr[2] = { value: 'syncByOrgUnit', label: `Update users from selected organizational units (${orgUnitCount.selected} Out of ${orgUnitCount.total} selected)` };
    }, [orgUnitCount]);

    const handleChange = (event, newValue) => {
        dispatch(Actions.handleSyncModeRedux(newValue));
        setSyncMode(newValue);
        setIsDirty(true);
    };

    return (
        <RadioGroup
            name="radio1"
            value={syncMode}
            onChange={handleChange}
        >
            {
                syncModeArr.map((syncEl, i) => {
                    return (
                        <FormControlLabel
                            key={i}
                            value={syncEl.value}
                            control={<Radio color="primary" />}
                            label={syncEl.label}
                            style={{ marginBottom: 0 }}
                        />
                    );
                })
            }
        </RadioGroup>
    );
}

export default WorkspaceSyncModes;