import { call, put } from 'redux-saga/effects';
import { Actions } from '../../../Actions';
import ApplicationsAPI from 'Redux/API/applicationsAPI';

function* enableDisableApplicationIntegrationRequest(action) {
    const applicationsAPI = new ApplicationsAPI();
    const response = yield call(applicationsAPI.enableDisableApplicationIntegration, action.data);
    if (response.status === 200) {
      if (response.data.status === 200) {
        yield put(
          Actions.enableDisableApplicationIntegrationSuccess(
            response.data.message,
            response.data.appName,
            response.data.activeStatus
          ),
        );
      } else {
        yield put(Actions.enableDisableApplicationIntegrationError(response.data.message));
      }
    } else {
      yield put(Actions.enableDisableApplicationIntegrationError(response.data.message));
    }
  }

  function* checkApplicationIntegrationEnabled(action) {
    const applicationsAPI = new ApplicationsAPI();
    const response = yield call(applicationsAPI.checkApplicationIntegrationEnabled, action.data);
    if (response.status === 200) {
      if (response.data.status === 200) {
        yield put(
          Actions.checkApplicationIntegrationEnabledSuccess(
            response.data.apps
          ),
        );
      } else {
        yield put(Actions.checkApplicationIntegrationEnabledError(response.data.message));
      }
    } else {
      yield put(Actions.checkApplicationIntegrationEnabledError(response.data.message));
    }
  }


  const applicationsSaga = {
    enableDisableApplicationIntegrationRequest,
    checkApplicationIntegrationEnabled
  }

  export default applicationsSaga