import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useState } from 'react';
import { VoidFunc } from 'react-tooltip';
import { CustomField } from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { CustomFieldsObj } from '../Opportunity/AddOrEditOpportunity';

type Props = {
    handleCustomFieldsObj: (e: any, id: string, fieldType?: string, isMultiSelect?: boolean) => void,
    cf: CustomField,
    customFieldsObj: CustomFieldsObj
}

function InputFieldForPhoneDataTypeCF({handleCustomFieldsObj, cf, customFieldsObj} : Props) {
  // `value` will be the parsed phone number in E.164 format.
  // Example: "+12133734253".
  return (
    <PhoneInput
      initialValueFormat="national"
      defaultCountry="US"
      placeholder="Enter phone number"
      className="form-control small"
      value={customFieldsObj?.[cf._id as string] as string}
      onChange={(e)=>handleCustomFieldsObj(e, cf._id as string, cf.fieldData.fieldType)}
    />
  )
}

export default InputFieldForPhoneDataTypeCF