import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Helmet } from 'react-helmet'
import { get, cloneDeep } from 'lodash'
import { NotificationContainer } from 'react-notifications'
import { confirmAlert } from 'react-confirm-alert' // Import
import { connect } from 'react-redux'
import {
  updateEventTemplateById,
  createTemplate,
  getEventTemplates,
  createTemplateToLib,
  getTemplateLibById,
  getTemplateLib,
  CheckEventPathAPI,
} from '../../Redux/API'

/*custom components */
import PageToolBar from '../../Components/PageToolBar'
import Loader from '../../Components/Loader/loader'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
/*default ui configuration*/
import '../../sass/main.scss'
import { createNotification, redirectTo } from '../../helpers'
import getUnLayerConfig from './config'
import * as qs from 'qs'

class CreateEventPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      showLoader: false,
      registrationTemplates: [],
      templateName: '',
      selectedRegTemplate: '',
      landingPage: false,
      registrationPage: false,
      existingPaths: [],
    }
  }

  componentDidMount() {
    const apiUrl = process.env.REACT_APP_API_URL
    const eventId = get(this.props, 'match.params.eid')
    const templateId = get(this.props, 'match.params.tid')
    const tLibId = qs.parse(get(this.props, 'location.search'), {
      ignoreQueryPrefix: true,
    })
    const type = this.props.type
    const search = qs.parse(get(this.props, 'location.search'), {
      ignoreQueryPrefix: true,
    })
    const formEnabled = search.registration ? true : false
    const setState = this.setState.bind(this)
    this.props.eventRequest(eventId)
    window.scrollTo(0, 0)
    window.unlayer.init(getUnLayerConfig(apiUrl, eventId, formEnabled))
    window.unlayer.addEventListener('design:updated', function (updates) {
      window.unlayer.exportHtml(function (data) {
        const json = data.design
        const html = data.html
        setState({ json, html })
      })
    })

    CheckEventPathAPI({ eventId }).then((result) => {
      if (result.data && result.data.status === 200)
        this.setState({ existingPaths: result.data.data }, () => {
          const registrationPageExist = this.state.existingPaths.find(
            (obj) => obj.eventId === eventId && obj.path === '/registration',
          )
          const landingPageExist = this.state.existingPaths.find(
            (obj) => obj.eventId === eventId && obj.path === '',
          )
          this.setState({
            registrationPageExist:
              registrationPageExist && registrationPageExist.eventId,
            landingPageExist: landingPageExist && landingPageExist.eventId,
          })
        })
    })

    if (templateId !== 'blank') {
      const params = { type: type.toLowerCase(), templateId }
      getEventTemplates(params).then((result) => {
        if (result.data.success) {
          const templates = result.data.templates || {}
          const eventPage = result.data.eventPage || {}
          const oldTemplates = cloneDeep(templates)
          const { json } = templates
          const landingPage = templates.type.toLowerCase() == 'meeting'
          const registrationPage =
            templates.type.toLowerCase() == 'registration'

          this.setState({
            ...templates,
            showLoader: false,
            oldTemplates,
            landingPage: landingPage,
            registrationPage: registrationPage,
          })
          // window.unlayer.loadDesign(json)
          if (type.toLowerCase() == 'meeting') {
            const params = { type: 'registration', eventId }
            getEventTemplates(params)
              .then(async (result) => {
                const registrationTemplates = result.data.templates || []
                const selectedRegTemplate = templates.selectedRegTemplate
                  ? templates.selectedRegTemplate
                  : get(registrationTemplates, '0._id')
                this.setState({ registrationTemplates, selectedRegTemplate })
              })
              .catch(console.log)
          }
        }
      })
    } else {
      getTemplateLib({})
        .then(async (result) => {
          if (
            result.data &&
            result.data.templates &&
            result.data.templates.length
          ) {
            let temp = result.data.templates.filter(
              (obj) => obj._id === tLibId.chooseTempId,
            )
            if (temp.length) {
              let templates = {}
              templates['json'] = temp[0].json
              templates['html'] = temp[0].html
              //templates['templateName'] = temp[0].templateName;
              // window.unlayer.loadDesign(templates.json)
              this.setState({ ...templates })
            }
          }
        })
        .catch(console.log)

      if (formEnabled && templateId === 'blank') {
        let eventPageState = JSON.parse(localStorage.getItem('eventPageState'))
        this.setState(
          {
            ...eventPageState,
          },
          () => {
            // window.unlayer.loadDesign(this.state.json)
            localStorage.removeItem('eventPageState')
          },
        )
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevEvent = get(prevProps, 'events.event')
    const event = get(this.props, 'events.event')
   
    if (prevEvent !== event) {
      this.setState({ event })
    }
  }

  saveLayout = () => {
    let {
      json,
      html,
      templateName,
      defaultTemplate,
      selectedRegTemplate,
      path,
      landingPage,
      registrationPage,
    } = this.state
    const templateId = get(this.props, 'match.params.tid')
    const eventId = get(this.props, 'match.params.eid')
    let type = get(this.props, 'type', '').toLowerCase()

    if (landingPage) {
      type = 'event-page'
      path = ''
    }
    if (registrationPage) {
      type = 'registration'
    }

    if (templateId !== 'blank' && !defaultTemplate) { 
      const data = { json, html, templateName, selectedRegTemplate, path, type }
      updateEventTemplateById(templateId, data)
        .then((result) => {
          if (result.data.success) {
            createNotification('success', result.data.message)
            const newTemplate = get(result, 'data.template', {})
            const oldTemplates = cloneDeep(newTemplate)
            this.setState({
              ...newTemplate,
              oldTemplates,
            })
          } else {
            createNotification('error', result.data.message)
          }
        })
        .catch(console.log)
    } else {
      const data = { json, html, type, templateName, selectedRegTemplate, path }
      createTemplate(eventId, data)
        .then((result) => {
          if (result.data.success) {
            const templateId = result.data.template._id
            redirectTo(
              this.props.history,
              `event-page/eid/${eventId}/tid/${templateId}`,
              this.props.location.pathname,
            )
            createNotification('success', result.data.message)
          } else {
            createNotification('error', result.data.message)
          }
        })
        .catch(console.log)
    }
  }

  onChange = (e) => {
    e.preventDefault()
    if (e.target.name === 'path') {
      let isExist = this.state.existingPaths.find(
        (obj) => obj.path === e.target.value,
      )
      if (isExist)
        this.setState({
          errors: { eventPath: 'Event page path is not available' },
          pathSuccessMessage: '',
        })
      else
        this.setState({
          errors: {},
          pathSuccessMessage: 'Event page path is available',
        })
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  saveConfirmation = (isActive) => {
    this.setState(
      ({ templateName = '' }) => ({ templateName: templateName.trim() }),
      () => {
        const {
          html,
          templateName,
          oldTemplates,
          defaultTemplate,
          selectedRegTemplate,
          path,
          landingPage,
          registrationPage,
          saveTempDisabled,
        } = this.state
        const oldHtml = get(oldTemplates, 'html', '')
        const oldTemplateName = get(oldTemplates, 'templateName', '')
        const templateId = get(this.props, 'match.params.tid')
        if (
          templateName &&
          (path || landingPage) &&
          html &&
          (html !== oldHtml ||
            (templateName && templateName !== oldTemplateName) ||
            selectedRegTemplate !== oldTemplates.oldTemplates)
        ) {
          // if (true) {
          /*if(!saveTempDisabled && templateId === 'blank'){
                    confirmAlert({
                        title: 'Add to template library',
                        message: 'Do you want to add this template in library also.',
                        buttons: [
                            {
                                label: 'No',
                                onClick: () => {
                                    this.saveLayout(isActive);
                                }
                            },
                            {
                                label: 'Yes',
                                onClick: () => {
                                    this.saveLayout(isActive);
                                    this.saveTemplateToLib();
                                }
                            }
                        ]
                    });
                }*/
          this.saveLayout(isActive)
        } else {
          const message = !templateName.trim()
            ? `Can't save template with blank name`
            : 'No changes found'
          createNotification('error', message)
        }
      },
    )
  }

  handleRegTemplate = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.getAgenda)
  }

  handleCheckBox = (name, value) => {
    this.setState({ [name]: value })
  }

  saveTemplateToLib = () => {
    let { json, html, templateNameLib } = this.state
    const data = {
      json,
      html,
      templateName: templateNameLib,
      type: 'event-page',
    }
    if (html && json && templateNameLib) {
      createTemplateToLib(data)
        .then((result) => {
          if (result.data.success) {
            this.setState({ saveTempDisabled: true })
            createNotification('success', result.data.message)
          } else {
            createNotification('error', result.data.message)
          }
        })
        .catch(console.log)
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body inputField">
              <h1>Template Name</h1>
              <input
                type="text"
                className="form-control"
                name="templateNameLib"
                placeholder={'Please enter template name'}
                value={this.state.templateNameLib}
                maxLength="25"
                onChange={this.onChange}
              />
              <div className="react-confirm-alert-button-group">
                <button onClick={() => onClose()}>Cancel</button>
                <button
                  onClick={() => {
                    if (this.state.templateNameLib) {
                      this.saveTemplateToLib()
                      onClose()
                    } else {
                      createNotification(
                        'error',
                        'Please enter the template name first.',
                      )
                    }
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          )
        },
      })
    }
  }

  getHostName = () => {
    const [subdomain, domain, tdomian] = window.location.host.split('.')
    if (subdomain === 'live') {
      return domain + '.' + tdomian
    }
    return window.location.host
  }

  render() {
    const {
      view,
      showLoader,
      templateName,
      isActive,
      path = '',
      landingPage,
      registrationPage,
      saveTempDisabled,
      errors = {},
      pathSuccessMessage,
      event,
      landingPageExist,
    } = this.state
    const { type, events } = this.props
    const templateId = get(this.props, 'match.params.tid')
    const eventId = get(this.props, 'match.params.eid')
    const backTo = `/event-page-list/${eventId}`

    return (
      <div className="editingPageWrapper">
        <Helmet>
          <title>IllumeetXP | Page Design </title>
        </Helmet>
        {showLoader && <Loader />}
        {
          <div className="editingDashboard">
            {!view && (
              <PageToolBar
                showIcons={false}
                onSaveFn={this.saveConfirmation}
                showPreviewOption={false}
                showSaveBtn={type !== 'Login'}
                showAddToLib={templateId === 'blank'}
                backTo={backTo}
                saveTemplateToLib={this.saveTemplateToLib}
                saveTempDisabled={saveTempDisabled}
              />
            )}
            <div className="templateName event_page_ids templateNameField formSection pt-0">
              {event && event.eventDomain && (
                <form>
                  <div className="field-group fieldFroup20 inputField">
                    <label>Page Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="templateName"
                      placeholder={'Please enter page name'}
                      value={templateName}
                      maxLength="25"
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="page_url_input field-group fieldFroup20">
                    <label>Page URL</label>
                    <div className="success_message redirect-url">
                      {' '}
                      {event
                        ? window.location.protocol +
                          '//' +
                          event.eventDomain +
                          '.' +
                          this.getHostName() +
                          '/'
                        : window.location.origin}{' '}
                    </div>
                  </div>

                  <div className="field-group fieldFroup20 inputField">
                    <label>&nbsp;</label>
                    <input
                      type="text"
                      className="form-control"
                      name="path"
                      value={path}
                      placeholder={landingPage ? '' : 'Enter page name'}
                      maxLength="25"
                      onChange={this.onChange}
                      disabled={landingPage ? true : false}
                      autoComplete="off"
                    />
                    {pathSuccessMessage !== '' ? (
                      <p className="success_message"> {pathSuccessMessage} </p>
                    ) : (
                      <p className="error_mesage"> {errors.eventPath} </p>
                    )}
                  </div>

                  {!landingPageExist &&
                    (templateId === 'blank'
                      ? true
                      : templateId !== 'blank' &&
                        !registrationPage &&
                        !path) && (
                      <div className="field-group eventFields fieldFroup20 inputField">
                        <div className="event_list_che34">
                          <input
                            type="checkbox"
                            name="landingPage"
                            className="eventCheckbox checkBoxColl"
                            defaultChecked={landingPage}
                            checked={landingPage}
                            disabled={
                              registrationPage ||
                              path ||
                              (templateId &&
                                templateId !== 'blank' &&
                                landingPage)
                                ? true
                                : false
                            }
                            onChange={() =>
                              this.handleCheckBox('landingPage', !landingPage)
                            }
                          />
                          <label>Landing Page</label>
                        </div>
                      </div>
                    )}
                  {templateId === 'blank' && (
                    <div className="field-group eventFields fieldFroup20 register_pageCheckbox inputField">
                      <label>&nbsp;</label>
                      <div className="select_register456">
                        <input
                          type="checkbox"
                          name="registrationPage"
                          className="eventCheckbox checkBoxColl"
                          defaultChecked={registrationPage}
                          checked={registrationPage}
                          onChange={() =>
                            this.handleCheckBox(
                              'registrationPage',
                              !registrationPage,
                            )
                          }
                        />
                        <label>Registration Page</label>
                      </div>
                    </div>
                  )}
                </form>
              )}
            </div>
            <div id="pageDesignEditor" style={{ height: '100vh' }}>
              {''}
            </div>
          </div>
        }
        <NotificationContainer />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.Events,
  }
}

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(CreateEventPage),
)
