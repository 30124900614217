import { call, put } from 'redux-saga/effects';
import { Actions } from '../../Actions';
import OpportunityContactsAPI from 'Redux/API/opportunity/opportunityContactsAPI';
import { removeStorage } from 'helpers/common';

function* fetchAllOpportunityContacts(action) {
    const opportunityContactsAPI = new OpportunityContactsAPI();
    const response = yield call(opportunityContactsAPI.fetchAllOpportunityContacts, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.fetchAllOpportunityContactsSuccess(
            response.data.contacts,
            response.data.totalCount,
            response.data.filterCount,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.fetchAllOpportunityContactsError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.fetchAllOpportunityContactsError(response.data.message));
      }
    } else {
      yield put(Actions.fetchAllOpportunityContactsError(response.data.message));
    }
  }

function* fetchOpportunityContact(action) {
    const opportunityContactsAPI = new OpportunityContactsAPI();
    const response = yield call(opportunityContactsAPI.fetchOpportunityContact, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.fetchOpportunityContactSuccess(
            response.data.contact,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.fetchOpportunityContactError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.fetchOpportunityContactError(response.data.message));
      }
    } else {
      yield put(Actions.fetchOpportunityContactError(response.data.message));
    }
  }

function* createOpportunityContact(action) {
    const opportunityContactsAPI = new OpportunityContactsAPI();
    const response = yield call(opportunityContactsAPI.createOpportunityContact, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.createOpportunityContactSuccess(
            response.data.contact,
            response.data.contacts,
            response.data.totalCount,
            response.data.filterCount,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.createOpportunityContactError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.createOpportunityContactError(response.data.message));
      }
    } else {
      yield put(Actions.createOpportunityContactError(response.data.message));
    }
  }

  function* updateOpportunityContact(action) {
    const opportunityContactsAPI = new OpportunityContactsAPI();
    const response = yield call(opportunityContactsAPI.updateOpportunityContact, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.updateOpportunityContactSuccess(
            response.data.updatedContact,
            response.data.contacts,
            response.data.totalCount,
            response.data.filterCount,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.updateOpportunityContactError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.updateOpportunityContactError(response.data.message));
      }
    } else {
      yield put(Actions.updateOpportunityContactError(response.data.message));
    }
  }

  function* deleteOpportunityContact(action) {
    const opportunityContactsAPI = new OpportunityContactsAPI();
    const response = yield call(opportunityContactsAPI.deleteOpportunityContact, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.deleteOpportunityContactSuccess(
            response.data.contacts,
            response.data.totalCount,
            response.data.filterCount,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.deleteOpportunityContactError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.deleteOpportunityContactError(response.data.message));
      }
    } else {
      yield put(Actions.deleteOpportunityContactError(response.data.message));
    }
  }

  function* updateMultipleOpportunityContacts(action) {
    const opportunityContactsAPI = new OpportunityContactsAPI();
    const response = yield call(opportunityContactsAPI.updateMultipleOpportunityContacts, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.updateMultipleOpportunityContactsSuccess(
            response.data.contacts,
            response.data.totalCount,
            response.data.filterCount,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.updateMultipleOpportunityContactsError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.updateMultipleOpportunityContactsError(response.data.message));
      }
    } else {
      yield put(Actions.updateMultipleOpportunityContactsError(response.data.message));
    }
  }

  function* assignOrUnassignOpportunityContacts(action) {
    const opportunityContactsAPI = new OpportunityContactsAPI();
    const response = yield call(opportunityContactsAPI.assignOrUnassignOpportunityContacts, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.assignOrUnassignOpportunityContactsSuccess(
            response.data.contacts,
            response.data.totalCount,
            response.data.filterCount,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.assignOrUnassignOpportunityContactsError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.assignOrUnassignOpportunityContactsError(response.data.message));
      }
    } else {
      yield put(Actions.assignOrUnassignOpportunityContactsError(response.data.message));
    }
  }

  function* getSmartGroupsContacts(action) {
    const opportunityContactsAPI = new OpportunityContactsAPI();
    const response = yield call(opportunityContactsAPI.getSmartGroupsContacts, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.getSmartGroupsContactsSuccess(
            response.data.contacts,
            response.data.totalCount,
            response.data.filterCount,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.fetchAllOpportunityContactsError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.getSmartGroupsContactsError(response.data.message));
      }
    } else {
      yield put(Actions.getSmartGroupsContactsError(response.data.message));
    }
  }

const opportunityContactsSaga = {
    getSmartGroupsContacts,
    fetchAllOpportunityContacts,
    fetchOpportunityContact,
    createOpportunityContact,
    updateOpportunityContact,
    deleteOpportunityContact,
    updateMultipleOpportunityContacts,
    assignOrUnassignOpportunityContacts,
}

export default opportunityContactsSaga