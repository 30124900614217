import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
    listAgendaRequest: ['data'],
    listAgendaSuccess: ['message', 'Agenda', 'agendaCount'],
    listAgendaError: ['error'],
    deleteAgendaRequest: ['data'],
    deleteAgendaSuccess: ['message'],
    deleteAgendaError: ['error'],
    addAgendaRequest: ['data'],
    addAgendaSuccess: ['message','agenda'],
    addAgendaError: ['error'],
    editAgendaRequest: ['data'],
    editAgendaSuccess: ['message','data'],
    editAgendaError: ['error'],
    searchAgendaRequest: ['data'],
    searchAgendaSuccess: ['agenda', 'totalResults','message'],
    searchAgendaError: ['error'],
    replicateAgendaRequest: ['data'],
    replicateAgendaSuccess: ['message'],
    replicateAgendaError: ['error'],
})

export const Constants = Types
export default Creators