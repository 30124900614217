import React, { useEffect, useState, useRef, SyntheticEvent, useCallback } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../theme/theme';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Fade from '@material-ui/core/Fade';
import SmartGroupIcon from '../../../../src/assets/images/icons/smart-group-icon.svg';
import GroupIcon from '../../../../src/assets/images/icons/contact-group-icon.svg';
import ContactsIcon from '../../../../src/assets/images/icons/contacts-nav-icon.svg';
import MenuItem from '@material-ui/core/MenuItem';
import GroupsTreeView from './GroupsTreeView';
import { Actions } from 'Redux/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { AudienceParent, AudienceState } from 'Redux/Reducers/opportunity/audience.reducer';
import AddOrEditAudience from './AddOrEditAudience';
import Contacts, { FetchContactsQueryParams } from '../Contacts/Contacts';
import Loader from 'Components/Loader/loader';
import SmartGroup from './SmartGroup';
import { IconButton, Tooltip } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export type FetchAudienceQueryParams = {
    pageNo: number;
    limit: number;
    sortBy: string;
    order: string;
    searchText: string;
};

export type AudienceValidationError = {
    [key: string]: boolean | string;
};

type Props = {
    handleTabChange: (
        e: SyntheticEvent<Element, Event>,
        newValue: string,
        searchText: string,
    ) => void;
};

export default function ContactGroup({ handleTabChange }: Props) {
    const {
        loading,
    } = useSelector((state: any) => state.OpportunityAudience) as AudienceState;
    const [open, setOpen] = useState<boolean>(false);
    const [openGroupAction, setOpenGroupAction] = useState<boolean>(false);
    const [openAction, setOpenAction] = useState<boolean>(false);
    const [openAddNew, setOpenAddNew] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState<string>('updatedAt');
    const [order, setOrder] = useState<string>('desc');
    const [pageNo, setPageNo] = useState(1);
    const [limit, setLimit] = useState(10);
    const [searchText, setSearchText] = useState<string>('');
    const anchorRef = React.useRef(null);
    const [filterByStatus, setFilterByStatus] = useState<string[]>([]);
    const [filterByProject, setFilterByProject] = useState<string[]>([]);
    const [filterByAccount, setFilterByAccount] = useState<string[]>([]);
    const [filterBySource, setFilterBySource] = useState<string[]>([]);
    const [filterByAudience, setFilterByAudience] = useState<string[]>([]);
    const [filterByCustomField, setFilterByCustomField] = useState<string[]>([]);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [addOrEditAudience, setAddOrEditAudience] = useState<boolean>(false);
    const [editData, setEditData] = useState<AudienceParent | undefined>({} as AudienceParent);
    const [groupClickObject, setGroupClickObject] = useState<any>({ groupClick: false, isSmartGroup: false ,groupId:''});
    const [groupIdForAdd, setGroupIdForAdd] = useState<string>('');
    const [openAddSmartGroup, setOpenAddSmartGroup] = useState<boolean>(false);
    const [openGroupbar, setGroupbarOpen] = React.useState(true);
    const [showAddNew, setShowAddNew] = useState<boolean>(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleDrawerOpen = () => {
        setGroupbarOpen(true);
    };

    const handleDrawerClose = () => {
        setGroupbarOpen(false);
    };

    const dispatch = useDispatch();
    const queryParams: FetchAudienceQueryParams = {
        pageNo,
        limit,
        sortBy,
        order,
        searchText,
    };

    const queryParamsContact: FetchContactsQueryParams = {
        pageNo,
        limit,
        sortBy,
        sortOrder,
        from: 'viewContact',
    };

    useEffect(() => {
        fetchAllOpportunityAudience();
    }, [
        pageNo,
        // limit,    //comment limit for temperory
        sortBy,
        order,
        searchText,
    ]);

    useEffect(() => {
        fetchAllContacts();
    }, [
        pageNo,
        limit,
        sortBy,
        sortOrder,
        searchText,
        filterByAudience
    ]);
    const bodyParamsForfiltering = {
        filterByProject,
        filterByAccount,
        filterBySource,
        filterByAudience,
        filterByCustomField,
        filterByStatus
    };

    const fetchAllContacts = () => {
        const data = {
            queryParams: queryParamsContact,
            bodyParams: { filterArray: bodyParamsForfiltering, searchText },
        };

        dispatch(Actions.fetchAllOpportunityContactsRequest(data));
    };

    const fetchAllOpportunityAudience = () => {
        const data = queryParams;
        dispatch(Actions.fetchAllOpportunityAudienceRequest(data));
    };

    const handleClose = () => {
        setOpen(false);
        setOpenAction(false);
        setOpenGroupAction(false);
        setOpenAddNew(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent<HTMLUListElement>) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleOpenAllContact = () => {
        setFilterByAudience([]);
        setGroupClickObject({ groupClick: false, isSmartGroup: false,groupId:'' });
        setGroupIdForAdd('');
    };

    const closeAddOrEditAudience = () => {
        setAddOrEditAudience(false);
    };

    const addOrEditAudienceModal = (editData?: AudienceParent) => {
        setEditData(editData);
        setAddOrEditAudience(true);
    };

    const handleClickOpenAddSmartGroup = () => {
        setOpenAddSmartGroup(true);
    };

    const handleCloseAddSmartGroup = () => {
        setOpenAddSmartGroup(false);
    };

    const handleGetDataFromSmartGroup = (data: any) => {
        if (!data.openAddSmartGroup) {
            fetchAllOpportunityAudience();
        }
    };

    // ---for drag sidebar---

    const sidebarRef = useRef<HTMLDivElement>(null);
    const isResizing = useRef<boolean>(false);
    const initialWidth = useRef<number>(275);
    const startX = useRef<number>(0);

    const startResizing = useCallback((mouseDownEvent: React.MouseEvent) => {
        if (sidebarRef.current) {
            isResizing.current = true;
            startX.current = mouseDownEvent.clientX;
            initialWidth.current = sidebarRef.current.offsetWidth;
            mouseDownEvent.preventDefault(); // Prevent text selection
        }
    }, []);

    const stopResizing = useCallback(() => {
        isResizing.current = false;
    }, []);

    const resize = useCallback((mouseMoveEvent: MouseEvent) => {
        if (isResizing.current && sidebarRef.current) {
            const newWidth = initialWidth.current + (mouseMoveEvent.clientX - startX.current);
            sidebarRef.current.style.width = `${newWidth}px`;
        }
    }, []);

    useEffect(() => {
        const handleMouseUp = () => stopResizing();
        const handleMouseMove = (event: MouseEvent) => {
            if (isResizing.current) {
                requestAnimationFrame(() => resize(event));
            }
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [resize, stopResizing]);

    return (
        <ThemeProvider theme={theme} >
            <Grid container className="contact-group" flexWrap={'nowrap'}>
                <Grid item>
                    <Box className="group-sidebar" px={1.5} py={0.7}
                        ref={sidebarRef}
                        style={
                            openGroupbar ?
                                { width: initialWidth.current, transition: 'width 0.05s' }
                                : { width: '56px', minWidth: 'unset' }
                        }
                    >

                        <Box display={'flex'} justifyContent={'end'} mb={1}>
                            <Tooltip placement='right-start' enterDelay={800} arrow title={openGroupbar ? "Show less information" : "Expand to see Groups"}>

                                <IconButton color='primary' size="small" onClick={openGroupbar ? handleDrawerClose : handleDrawerOpen} >
                                    {!openGroupbar ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <Stack spacing={1} className={`sidebar-content ${openGroupbar && 'show'}`}>
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Box className="paragraph">GROUPS</Box>
                                <Box>
                                    <Button
                                        variant="outlined"
                                        className="button-between"
                                        color="primary"
                                        startIcon={
                                            <>
                                                <AddIcon /> <Box ml={1}>New</Box>
                                            </>
                                        }
                                        endIcon={<ArrowDropDownIcon />}
                                        disableElevation
                                        ref={anchorRef}
                                        aria-controls={open ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleToggle}
                                        size='small'
                                    ></Button>
                                    <Popper
                                        open={open}
                                        style={{ zIndex: 999 }}
                                        placement="bottom-end"
                                        transition
                                        anchorEl={anchorRef.current}
                                        role={undefined}
                                        disablePortal
                                    >
                                        {({ TransitionProps, placement }) => (
                                            <Fade {...TransitionProps} timeout={250}>
                                                <Box
                                                    sx={{
                                                        backgroundColor: theme.palette.common.white,
                                                        boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                                        borderRadius: '4px',
                                                        marginTop: '8px',
                                                    }}
                                                >
                                                    <ClickAwayListener onClickAway={handleClose}>
                                                        <MenuList
                                                            autoFocusItem={open}
                                                            id="menu-list-grow"
                                                            onKeyDown={handleListKeyDown}
                                                        >
                                                            <MenuItem onClick={() => addOrEditAudienceModal()}>
                                                                <Stack direction={'row'} spacing={1}>
                                                                    <img src={GroupIcon} alt={'icon'} />
                                                                    <Box>New Group</Box>
                                                                </Stack>
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={handleClickOpenAddSmartGroup}
                                                            >
                                                                <Stack direction={'row'} spacing={1}>
                                                                    <img
                                                                        src={SmartGroupIcon}
                                                                        alt={'icon'}
                                                                    />
                                                                    <Box>New Smart Group</Box>
                                                                </Stack>
                                                            </MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Box>
                                            </Fade>
                                        )}
                                    </Popper>
                                </Box>
                            </Stack>
                            <Box className={!groupClickObject.groupClick ? 'custom-button active' : 'custom-button'}>
                                <Stack direction={'row'} spacing={1} onClick={handleOpenAllContact}>
                                    <img src={ContactsIcon} alt={'icon'} />
                                    <Box>All Contacts</Box>
                                </Stack>
                            </Box>
                            <Box className={'groups-tree'} >
                                <GroupsTreeView 
                                setFilterBy={setFilterByAudience}  
                                setGroupIdForAdd={setGroupIdForAdd}
                                setGroupClickObject={setGroupClickObject}
                                groupClickObject={groupClickObject}
                                getData={handleGetDataFromSmartGroup}  
                                setShowAddNew={setShowAddNew}    
                                />
                            </Box>
                        </Stack>

                        <Box onClick={handleDrawerOpen} className={`sidebar-collapse ${!openGroupbar && 'show'}`}>Contact Groups</Box>
                        {openGroupbar &&
                            <Box component={'div'} className="app-sidebar-resizer" onMouseDown={startResizing} />
                        }
                    </Box>
                </Grid>
                <Grid item xs overflow={'auto'} >
                    <Box className="group-content" 
                    // px={1.25} py={4.5}
                    >
                        <Contacts handleTabChange={handleTabChange}
                            groupIdForAdd={groupIdForAdd}
                            groupClickObject={groupClickObject}
                            showAddNewfromGroupTree={showAddNew}
                            setShowAddNewfromGroupTree={setShowAddNew}
                        />
                    </Box>
                </Grid>
            </Grid>
            <AddOrEditAudience
                openModal={addOrEditAudience}
                closeModalFunc={closeAddOrEditAudience}
                editData={editData}
                queryParams={queryParams}
            />
            <SmartGroup
                openModal={openAddSmartGroup}
                closeModalFunc={handleCloseAddSmartGroup}
                editData={editData}
                queryParams={queryParams}
            />
            {loading && <Loader />}
        </ThemeProvider>
    );
}

