import React, { Component } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Button from '@material-ui/core/Button'
import PersonalInfoInput from './PersonalInfoInput'

export class PersonalInfo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            firstName: '',
            lastName: '',
            email: ''
        }
    }

    componentDidMount() {
        const { userDetails } = this.props
        this.setState({
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            email: userDetails.email
        })

    }

    handleChangeValue = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    render() {
        const { firstName, lastName, email } = this.state
        return (
            <>
                <Box pt={{ xs: 2, sm: 2.5, lg: 3, xl: 4 }}>
                    <Box className="subsecondary">Personal info</Box>
                </Box>
                <Box py={{ xs: 2, sm: 3, xl: 3.5 }}>
                    <Grid container spacing={{ xs: 2, lg: 4 }}>
                        <PersonalInfoInput
                            label='First Name'
                            handleChangeValue={this.handleChangeValue}
                            value={firstName}
                            name='firstName'
                            type='text'

                        />
                        <PersonalInfoInput
                            label='Last Name'
                            handleChangeValue={this.handleChangeValue}
                            value={lastName}
                            name='lastName'
                            type='text'

                        />
                        <PersonalInfoInput
                            label='Email'
                            value={email}
                            disabled={true}
                        />
                        <Grid item xs={12}>
                            <Stack spacing={1} direction="row" fullWidth>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                >
                                    Update
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </>
        )
    }
}

export default PersonalInfo