import BaseAPI from '../baseAPI';

class OpportunityAccountsCustomFieldsAPI extends BaseAPI {
    createOpportunityCustomField = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/customFields/create', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        });
    };
    
    deleteOpportunityCustomField = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/customFields/delete', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        });
    };
    
    updateOpportunityCustomField = async (data: {
        bodyParams: any;
        queryParams: any;
    }) => {
        const { bodyParams, queryParams } = data;
        return this.API('opportunityManagement/customFields/update', {
            method: 'POST',
            data: bodyParams,
            params: queryParams
        });
    };
    
    fetchAllOpportunityCustomField = async (params: Object) => {
        return this.API('opportunityManagement/customFields/fetchAllCustomFields', {
            method: 'GET',
            params,
        });
    };
}

export default OpportunityAccountsCustomFieldsAPI;