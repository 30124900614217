import React, { Component } from 'react';
import { theme } from '../../../theme/theme';
import { ThemeProvider } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button } from '@material-ui/core';
import BackArrow from '../../../assets/images/icons/backArrowWhite.svg';
import PageIcon from '../../../assets/images/icons/pageIconSmall.svg';
import { Stack } from '@mui/material';
import Avatar from '@material-ui/core/Avatar';
import CustomSwitch from '../../../Components/customSwitch';
import axios from 'axios';
import { get } from 'lodash';
import { createNotification } from '../../../helpers';
import { NotificationContainer } from 'react-notifications';
import Loader from '../../../Components/Loader/loader';
import { isValid } from 'date-fns';
import { Actions } from '../../../Redux/Actions';
import { connect } from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Checkbox from '@material-ui/core/Checkbox';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { bindActionCreators } from 'redux';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { permissions } from '../../../constants/constants'

import {
  getEventURL,
  getHostName,
  getProjectIdFromUrl,
} from '../../../helpers/common';

const URL = process.env.REACT_APP_API_URL;

const styles = {
  pink: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
};
export class ManageBadgeSettings extends Component {
  constructor(props) {
    super(props);
    this.anchorRef = React.createRef();
    this.groupsMultiSelectArray = [];
    this.state = {
      pageDetails: {},
      loading: false,
      errors: {},
      openMultiSelectForGroups: false,
      isGroupMultiSelectShow: false,
      listOfGroups: [],
      pageVisibilityOption: '',
      responseData: {},
      pageWidth: 0,
      pageHeight: 0,
      pageMarginTop: 0,
      pageMarginBottom: 0,
      pageMarginLeft: 0,
      pageMarginRight: 0,
      path: '',
      eventDomain: '',
      vanityDomain: null,
    };
  }

  componentDidMount() {
    let eventId = this.props.match.params.pid;
    const templateId = get(this.props, 'match.params.tid');
    const pageName = get(this.props, 'match.params.pn');
    const pageWidth = this.state.pageWidth;
    const pageHeight = this.state.pageHeight;
    const pageMarginTop = this.state.pageMarginTop;
    const pageMarginBottom = this.state.pageMarginBottom;
    const pageMarginLeft = this.state.pageMarginLeft;
    const pageMarginRight = this.state.pageMarginRight;
    const pageUrl = get(this.props, 'match.params.purl');
    const path = get(this.props, 'match.params.purl');
    this.setState(
      {
        eventId,
        templateId,
        pageName, 
        pageUrl,
        path, 
      },
      () => {
        this.getPageData(templateId);
        this.getProjectRequest(eventId);
      },
    );
    this.listgroups();
    this.findIfAllowedFromSuperadminRequest();
  }

  getProjectRequest(eventId) {
    this.props.getProjectRequest(eventId);
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.attendees.getAttendeesGroupsSuccess === true &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        listOfGroups: this.props.attendees.attendeesGroups,
      });
    }

    if (
      this.props.projects.getProjectSuccess === true &&
      !prevProps.projects.getProjectSuccess
    ) {
      this.setState({
        eventDomain: this.props?.projects?.project?.eventDomain,
        vanityDomain: this.props?.project?.project?.vanityDomain,
      });
    }
  };

  listgroups = () => {
    try {
      let eventId = this.props.match.params.pid;
      const body = {
        projectId: eventId,
        page_no2: 1,
        searchText: '',
      };
      if (eventId) {
        this.props.listAttendeesGroups(body);
        this.handleLoading();
      } else {
        createNotification('error', 'project Id is missing');
      }
    } catch (error) {
      createNotification('error', 'Something went wrong');
    }
  };

  UserData = async () => {
    const currentPath = window.location.pathname;
    if (currentPath.includes('member')) {
      return JSON.parse(localStorage.getItem('member_details'));
    } else {
      return JSON.parse(localStorage.getItem('user_details'));
    }
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  getPageData = async (templateId) => {
    this.handleLoading();
    try {
    
      const users = await this.UserData();
      const response = await axios.get(`${URL}/getPageById/${templateId}`, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      }); 
      this.setState({
        pageDetails: response.data.Page,
      });
      if (response.data.message === 'Pages sccessfully loaded') {
        this.groupsMultiSelectArray = response.data.Page.groupsVisibility.map(
          (groupId) => groupId,
        );
        this.setState({
          responseData: response.data.Page,
        });
        if (
          Array.isArray(response.data.Page.groupsVisibility) &&
          response.data.Page.groupsVisibility.length > 0
        ) {
          this.setState({
            pageVisibilityOption: 'Groups',
            isGroupMultiSelectShow: true,
          });
        }
        if (response.data.Page.isPrivate === false) {
          this.setState({
            isGroupMultiSelectShow: false,
          });
        }
        if (response.data.Page.isPrivate === true) {
          if (
            Array.isArray(response.data.Page.groupsVisibility) &&
            response.data.Page.groupsVisibility.length === 0
          ) {
            this.setState({
              isGroupMultiSelectShow: false,
              pageVisibilityOption: 'Attendees',
            });
          } else if (!Array.isArray(response.data.Page.groupsVisibility)) {
            this.setState({
              isGroupMultiSelectShow: false,
              pageVisibilityOption: 'Attendees',
            });
          }
        }
        if (this.state.loading) {
          this.setState({
            loading: false,
          });
        }
      }
      if (response?.data?.Page?.isLoginPage) {
        this.state.formType = 'loginform';
      }
      if (response?.data?.Page) { 
        this.setState(
          {
            pageWidth: response?.data?.Page.pageWidth,
            pageHeight: response?.data?.Page?.pageHeight,
            pageMarginTop: response?.data?.Page.pageMarginTop,
            pageMarginBottom: response?.data?.Page.pageMarginBottom,
            pageMarginLeft: response?.data?.Page.pageMarginLeft,
            pageMarginRight: response?.data?.Page.pageMarginRight,
          },
        );
      } 

      return response;
    } catch (err) { 
      if (this.state.loading) {
        this.setState({
          loading: false,
        });
      }
      return err;
    }
  };





  handlePageWidthChange = (e) => {
    this.setState({
      pageWidth: e.target.value,
    });
  };

  handlePageHeightChange = (e) => {
    this.setState({
      pageHeight: e.target.value,
    });
  };

  handlePageMarginTopChange = (e) => {
    this.setState({
      pageMarginTop: e.target.value,
    });
  };
  handlePageMarginBottomChange = (e) => {
    this.setState({
      pageMarginBottom: e.target.value,
    });
  };
  handlePageMarginLeftChange = (e) => {
    this.setState({
      pageMarginLeft: e.target.value,
    });
  };
  handlePageMarginRightChange = (e) => {
    this.setState({
      pageMarginRight: e.target.value,
    });
  };


  saveSettings = (e) => {
    e.preventDefault();
    this.updatePageAPIRequest();
  };

  updatePageAPIRequest = async () => {
    if (this.isValid()) {
      this.handleLoading();
      const requestBody = {
        ...this.state.pageDetails,
        pageWidth: this.state.pageWidth,
        pageHeight: this.state.pageHeight,
        pageMarginTop: this.state.pageMarginTop,
        pageMarginBottom: this.state.pageMarginBottom,
        pageMarginLeft: this.state.pageMarginLeft,
        pageMarginRight: this.state.pageMarginRight,
        path: this.state.path,
        eventId: this.state.eventId,
        requestCheck:"editPageSettings",
      };
      try {
        const users = await this.UserData();
        const res = await axios.patch(
          `${URL}/updatePage/${this.state.templateId}`,
          requestBody,
          {
            headers: {
              token: `bearer ${users.token}`,
              'Content-Type': 'application/json',
              'Content-Type': 'application/json',
            },
          },
        );
        if (res.data.message === 'Pages updated successfully') {
          createNotification('success', 'Page updated successfully');
          this.getPageData(this.state.templateId);

          setTimeout(() => {
            this.goBack();
          }, 500);
        } else {
          createNotification('error', res.data.message);
        }
        if (this.state.loading) {
          this.handleLoading();
        }
        return res;
      } catch (err) {
        if (this.state.loading) {
          this.handleLoading();
        }
        return err;
      }
    }
  };

  isValid = () => {
    let isValid = true;
    let error = {};
    if (this.state.pageWidth === '' || this.state.pageWidth === undefined) {
      isValid = false;
      error['pageWidth'] = 'Please enter the page width';
    }
    if (this.state.pageHeight === '' || this.state.pageHeight === undefined) {
      isValid = false;
      error['pageHeight'] = 'Please enter page height';
    }
    if (this.state.pageMarginTop === '' || this.state.pageMarginTop === undefined) {
      isValid = false;
      error['pageMarginTop'] = 'Please enter margin top';
    }
    if (this.state.pageMarginBottom === '' || this.state.pageMarginBottom === undefined) {
      isValid = false;
      error['pageMarginBottom'] = 'Please enter margin bottom';
    }
    if (this.state.pageMarginLeft === '' || this.state.pageMarginLeft === undefined) {
      isValid = false;
      error['pageMarginLeft'] = 'Please enter margin left';
    }
    if (this.state.pageMarginRight === '' || this.state.pageMarginRight === undefined) {
      isValid = false;
      error['pageMarginRight'] = 'Please enter margin right';
    }
    this.setState({
      errors: error,
    });
    return isValid;
  };

  goBack = () => {
    this.props.history.push({
      pathname: `/project-page/update-badges-template-page/pid/${this.state.eventId}/tid/${this.state.templateId}/pn/${this.state.pageName}/purl/${this.state.path}`,
    });
  };

  handleCloseMultiSelectForGroups = () => {
    this.setState({
      openMultiSelectForGroups: false,
    });
  };

  handleOpenMultiSelectForGroups = () => {
    this.setState({
      openMultiSelectForGroups: true,
    });
  };

  handleToggleMultiSelectGroups = () => {
    this.setState({
      openMultiSelectForGroups: !this.state.openMultiSelectForGroups,
    });
  };

  handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      this.setState({
        openMultiSelectForGroups: false,
      });
    }
  };

  handleMultiSelectForGroups = (e) => {
    if (this.groupsMultiSelectArray.indexOf(e.target.value) === -1) {
      this.groupsMultiSelectArray.push(e.target.value);
    } else {
      this.groupsMultiSelectArray = this.groupsMultiSelectArray.filter(
        (el) => el != e.target.value,
      );
    }
    this.setState({
      pageDetails: {
        ...this.state.pageDetails,
        groupsVisibility: this.groupsMultiSelectArray,
      },
    });
  };

  isChecked = (groupId) => {
    if (this.groupsMultiSelectArray.includes(groupId)) {
      return true;
    } else {
      return false;
    }
  };

  findIfAllowedFromSuperadminRequest = () => {
    let userDetails = JSON.parse(localStorage.getItem('user_details'));
    let servicePlanId = userDetails.servicePlanId;
    const projectId = getProjectIdFromUrl();
    let reqData = {
      servicePlanId,
      projectId,
    };
    this.props.findIfAllowedFromSuperadminRequest(reqData);
  };

  render() {
    const { pageDetails, listOfGroups } = this.state;
    const isSearchEnginesIndexChecked =
      this.state.pageDetails.searchEnginesIndex;
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Box className="unlayer-header" mb={4}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center">
                  <Button className="back-button" variant="text">
                    <img alt="" src={BackArrow} onClick={this.goBack} />
                  </Button>
                  <Avatar style={styles.pink}>
                    <img alt="" src={PageIcon} />
                  </Avatar>
                  <h3 className="page-title">Settings </h3>
                </Grid>
              </Grid>
              <Grid item className="buttons">
                <Stack spacing={1} direction="row">
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={this.saveSettings}
                  >
                    {' '}
                    Save
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Grid container pb={4} alignItems="center" justifyContent={'center'}>
            <Grid item xs lg={10} xl={8}>
              <Grid container rowSpacing={{ xs: 2, xl: 2.5 }} columnSpacing={5}>
                <Grid item xs={12} md={6}>
                  <Box component="div" className="inputField">
                    <label>
                      Page Width (mm) <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control small"
                      name="pageWidth"
                      value={this.state.pageWidth}
                      onChange={this.handlePageWidthChange}
                      placeholder=""
                    />
                    <span className="error_mesage required">
                      {' '}
                      {this.state.errors.pageWidth}{' '}
                    </span>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box component="div" className="inputField">
                    <label>
                      Page Height (mm) <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control small"
                      name="pageHeight"
                      value={this.state.pageHeight}
                      onChange={this.handlePageHeightChange}
                      placeholder=""
                    />
                    <span className="error_mesage required">
                      {' '}
                      {this.state.errors.pageHeight}{' '}
                    </span>
                  </Box>
                </Grid>
              </Grid>
              <Grid container rowSpacing={{ xs: 2, xl: 2.5 }} columnSpacing={5}>
                <Grid item xs={12} md={6}>
                  <Box component="div" className="inputField">
                    <label>
                      Page Margin Top (mm) <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control small"
                      name="pageMarginTop"
                      value={this.state.pageMarginTop}
                      onChange={this.handlePageMarginTopChange}
                      placeholder=""
                    />
                    <span className="error_mesage required">
                      {' '}
                      {this.state.errors.pageMarginTop}{' '}
                    </span>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box component="div" className="inputField">
                    <label>
                      Page Margin Bottom (mm) <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control small"
                      name="pageMarginBottom"
                      value={this.state.pageMarginBottom}
                      onChange={this.handlePageMarginBottomChange}
                      placeholder=""
                    />
                    <span className="error_mesage required">
                      {' '}
                      {this.state.errors.pageMarginBottom}{' '}
                    </span>
                  </Box>
                </Grid>
              </Grid>
              <Grid container rowSpacing={{ xs: 2, xl: 2.5 }} columnSpacing={5}>
                <Grid item xs={12} md={6}>
                  <Box component="div" className="inputField">
                    <label>
                      Page Margin Left (mm) <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control small"
                      name="pageMarginLeft"
                      value={this.state.pageMarginLeft}
                      onChange={this.handlePageMarginLeftChange}
                      placeholder=""
                    />
                    <span className="error_mesage required">
                      {' '}
                      {this.state.errors.pageMarginLeft}{' '}
                    </span>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box component="div" className="inputField">
                    <label>
                      Page Margin Right (mm) <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control small"
                      name="pageMarginRight"
                      value={this.state.pageMarginRight}
                      onChange={this.handlePageMarginRightChange}
                      placeholder=""
                    />
                    <span className="error_mesage required">
                      {' '}
                      {this.state.errors.pageMarginRight}{' '}
                    </span>
                  </Box>
                </Grid>
              </Grid>
              {/*  <Grid container rowSpacing={{ xs: 2, xl: 2.5 }} columnSpacing={5}>
                <Grid item xs={12} sm={6} md={3}>
                  <Box component="div" className="inputField">
                    <label>Select Printer</label>
                    <select
                      type="text"
                      class="form-control small"
                      onChange={this.handlePageVisibilityChange}
                      name="groupsVisibility"
                      value={this.state.pageVisibilityOption}
                    >
                      <option value="printer1">Printer 1</option>
                      <option value="printer2">Printer 2</option>
                      <option value="printer3">Printer 3</option>
                    </select>
                  </Box>
                </Grid> 
              </Grid>  */}
            </Grid>
          </Grid>
          {this.state.loading ? <Loader /> : null}
          <NotificationContainer />
        </ThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  attendees: state.Attendees,
  projects: state.Projects,
  servicePlan: state.ServicePlan,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listAttendeesGroups: Actions.listAttendeesGroupsRequest,
      getProjectRequest: Actions.getProjectRequest,
      findIfAllowedFromSuperadminRequest: Actions.findIfAllowedFromSuperadminRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapActionsToProps)(ManageBadgeSettings);
