import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
    message: null,
    listGamificationGradessuccess: false,
    error: false,
    getGamificationChallengesSuccess: false,
    challenges: [],
    totalResults: 0,
    challengesList: [],
    totalConfigrationChallenges: 0,
    addConfigrationChallengesSuccess: false,
    updateConfigrationChallengesSuccess: false,
    updateGamificationGradessuccess: false,
    configrationChallengesSuccess: false,
    addPointsGamificationLeaderBoardsSuccess: false,
    listGamificationLeaderBoardsSuccess: false,
    isStatusUpdated:false,
    filterByStatus:false,
    challengeStatusSuccess: false,
    leaderboardUserHistory:[],
    challengesEngagedUsers:[],
    leaderBoardEngagedUserSuccess:false,
    challengesEngagedUsersSuccess:false,
    totatlHistory:0,
    addConfigrationChallengesError:false,
    updateConfigrationChallengesError:false
}

const listGamificationGradesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: null,
        listGamificationGradessuccess: false,
        error: false,
        gamificationGrades: null,
        updateGamificationGradessuccess: false,
        addPointsGamificationLeaderBoardsSuccess: false,
        listGamificationLeaderBoardsSuccess: false,
    }
}

const listGamificationGradesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: action.message,
        listGamificationGradessuccess: true,
        error: false,
        gamificationGrades: action.data,
        updateGamificationGradessuccess: false,
        listGamificationLeaderBoardsSuccess: false,
    }
}

const listGamificationGradesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: action.error,
        listGamificationGradessuccess: false,
        error: true,
        gamificationGrades: null,
        updateGamificationGradessuccess: false,
        listGamificationLeaderBoardsSuccess: false,
    }
}

const updateGamificationGradesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: null,
        updateGamificationGradessuccess: false,
        error: false,
        listGamificationGradessuccess: false,
        addPointsGamificationLeaderBoardsSuccess: false,
        listGamificationLeaderBoardsSuccess: false,
    }
}

const updateGamificationGradesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: action.message,
        updateGamificationGradessuccess: true,
        error: false,
        gamificationGrades: action.data,
        listGamificationGradessuccess: false,
        listGamificationLeaderBoardsSuccess: false,
    }
}

const updateGamificationGradesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: action.error,
        updateGamificationGradessuccess: false,
        error: true,
        gamificationGrades: null,
        listGamificationGradessuccess: false,
    }
}

const getGamificationChallengesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getGamificationChallengesSuccess: false,
        error: false,
        message: null,
        challenges: [],
        listGamificationLeaderBoardsSuccess: false,
    }
}

const getGamificationChallengesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getGamificationChallengesSuccess: true,
        error: false,
        message: action.message,
        challenges: action.challenges,
        totalResultsChallenges: { totalResults: action.totalResults },
        listGamificationLeaderBoardsSuccess: false,
    }
}
const getGamificationChallengesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getGamificationChallengesSuccess: false,
        error: true,
        message: action.error,
    }
}

const configrationChallengesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        configrationChallengesSuccess: false,
        error: false,
        message: null,
        challengesList: [],
        getGamificationChallengesSuccess: false,
        listGamificationLeaderBoardsSuccess: false,
    }
}

const configrationChallengesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        configrationChallengesSuccess: true,
        error: false,
        message: action.message,
        challengesList: action.challengesList,
        totalConfigrationChallenges: action.totalConfigrationChallenges,
        listGamificationLeaderBoardsSuccess: false,
        filterByStatus:action.filterByStatus
    }
}
const configrationChallengesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        configrationChallengesSuccess: false,
        error: true,
        message: action.error,
        listGamificationLeaderBoardsSuccess: false,
    }
}

const addConfigrationChallengesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addConfigrationChallengesSuccess: false,
        error: false,
        message: null,
        getGamificationChallengesSuccess: false,
        addConfigrationChallengesError:false
    }
}

const addConfigrationChallengesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addConfigrationChallengesSuccess: true,
        error: false,
        message: action.message,
        addConfigrationChallengesError:false
    }
}

const addConfigrationChallengesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addConfigrationChallengesSuccess: false,
        addConfigrationChallengesError:true,
        message: action.error,
    }
}

const updateConfigrationChallengesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: null,
        updateConfigrationChallengesSuccess: false,
        updateConfigrationChallengesError:false
    }
}

const updateConfigrationChallengesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: action.message,
        updateConfigrationChallengesSuccess: true,
        updateConfigrationChallengesError:false,
        isStatusUpdated:action.isStatusUpdated,
    }
}

const updateConfigrationChallengesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: action.error,
        updateConfigrationChallengesSuccess: false,
        updateConfigrationChallengesError:true
    }
}

const listGamificationLeaderBoardsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: null,
        error: false,
        listGamificationLeaderBoardsSuccess: false,
        updateGamificationGradessuccess: false,
        listGamificationGradessuccess: false,
        addPointsGamificationLeaderBoardsSuccess: false,
        getGamificationChallengesSuccess: false,
        
    }
}

const listGamificationLeaderBoardsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: action.message,
        listGamificationLeaderBoardsSuccess: true,
        totalResults: action.totalResults,
        filterByAchievement: action.filterByAchievement,
        leaderBoardList: action.leaderBoard,
        error: false,
        updateGamificationGradessuccess: false,
        listGamificationGradessuccess: false,
        getGamificationChallengesSuccess: false,
    }
}

const listGamificationLeaderBoardsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: action.error,
        listGamificationLeaderBoardsSuccess: false,
        error: true,
        updateGamificationGradessuccess: false,
        listGamificationGradessuccess: false,
    }
}

const addPointsGamificationLeaderBoardsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: null,
        error: false,
        addPointsGamificationLeaderBoardsSuccess: false,
        listGamificationLeaderBoardsSuccess: false,
        updateGamificationGradessuccess: false,
        listGamificationGradessuccess: false,
        getGamificationChallengesSuccess: false,
    }
}

const addPointsGamificationLeaderBoardsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: action.message,
        error: false,
        addPointsGamificationLeaderBoardsSuccess: true,
        listGamificationLeaderBoardsSuccess: false,
        updateGamificationGradessuccess: false,
        listGamificationGradessuccess: false,
    }
}

const addPointsGamificationLeaderBoardsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: action.error,
        error: true,
        listGamificationLeaderBoardsSuccess: false,
        addPointsGamificationLeaderBoardsSuccess: false,
        updateGamificationGradessuccess: false,
        listGamificationGradessuccess: false,
    }
}

const deleteConfigrationChallengesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: null,
        deleteConfigrationChallengesSuccess: false,
        error: false,
    }
}

const deleteConfigrationChallengesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: action.message,
        deleteConfigrationChallengesSuccess: true,
        error: false,
    }
}

const deleteConfigrationChallengesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: action.error,
        deleteConfigrationChallengesSuccess: false,
        error: true,
    }
}

const challengeStatusRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        challengeStatusSuccess: false,
        error: false,
        message: null
    }
}
const challengeStatusSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        challengeStatusSuccess: true,
        error: false,
        message: action.message,
    }
}
const challengeStatusError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        challengeStatusSuccess: false,
        error: true,
        message: action.error,
    }
}


const leaderboardUserHistoryRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        leaderBoardEngagedUserSuccess: false,
        error: false,
        message: null,
        leaderboardUserHistory: [],
        listGamificationLeaderBoardsSuccess: false,
        addPointsGamificationLeaderBoardsSuccess: false,
    }
}

const leaderboardUserHistorySuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        leaderBoardEngagedUserSuccess: true,
        error: false,
        message: action.message,
        totatlHistory:action.totalResults,
        leaderboardUserHistory: action.leaderboardUserHistory,
        listGamificationLeaderBoardsSuccess: false,
        addPointsGamificationLeaderBoardsSuccess: false,
    }
}

const leaderboardUserHistoryError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        leaderBoardEngagedUserSuccess: false,
        error: true,
        message: action.error,
    }
}


const challengesEngagedUsersRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        challengesEngagedUsersSuccess: false,
        error: false,
        message: null,
        challengesEngagedUsers: [],
        listGamificationLeaderBoardsSuccess: false,
        addPointsGamificationLeaderBoardsSuccess: false,
    }
}

const challengesEngagedUsersSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        challengesEngagedUsersSuccess: true,
        error: false,
        message: action.message,
        challengesEngagedUsers: action.challengesEngagedUsers,
        listGamificationLeaderBoardsSuccess: false,
        addPointsGamificationLeaderBoardsSuccess: false,
    }
}

const challengesEngagedUsersError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        challengesEngagedUsersSuccess: false,
        error: true,
        message: action.error,
    }
}

const HANDLERS = {
  [Constants.CHALLENGES_ENGAGED_USERS_SUCCESS]:challengesEngagedUsersSuccess,
  [Constants.CHALLENGES_ENGAGED_USERS_REQUEST]:challengesEngagedUsersRequest,
  [Constants.CHALLENGES_ENGAGED_USERS_ERROR]:challengesEngagedUsersError,
  [Constants.LEADERBOARD_USER_HISTORY_SUCCESS]:leaderboardUserHistorySuccess,
  [Constants.LEADERBOARD_USER_HISTORY_REQUEST]:leaderboardUserHistoryRequest,
  [Constants.LEADERBOARD_USER_HISTORY_ERROR]:leaderboardUserHistoryError,
  [Constants.CHALLENGE_STATUS_REQUEST]:challengeStatusRequest,
  [Constants.CHALLENGE_STATUS_SUCCESS]: challengeStatusSuccess,
  [Constants.CHALLENGE_STATUS_ERROR]: challengeStatusError,
  [Constants.DELETE_CONFIGRATION_CHALLENGES_REQUEST]:deleteConfigrationChallengesRequest,
  [Constants.DELETE_CONFIGRATION_CHALLENGES_SUCCESS]: deleteConfigrationChallengesSuccess,
  [Constants.DELETE_CONFIGRATION_CHALLENGES_ERROR]: deleteConfigrationChallengesError,
  [Constants.ADD_POINTS_GAMIFICATION_LEADER_BOARDS_REQUEST]: addPointsGamificationLeaderBoardsRequest,    
  [Constants.ADD_POINTS_GAMIFICATION_LEADER_BOARDS_SUCCESS]: addPointsGamificationLeaderBoardsSuccess,
  [Constants.ADD_POINTS_GAMIFICATION_LEADER_BOARDS_ERROR]: addPointsGamificationLeaderBoardsError,
  [Constants.UPDATE_CONFIGRATION_CHALLENGES_REQUEST]: updateConfigrationChallengesRequest,
  [Constants.UPDATE_CONFIGRATION_CHALLENGES_SUCCESS]: updateConfigrationChallengesSuccess,
  [Constants.UPDATE_CONFIGRATION_CHALLENGES_ERROR]: updateConfigrationChallengesError,
  [Constants.LIST_GAMIFICATION_GRADES_REQUEST]: listGamificationGradesRequest,
  [Constants.LIST_GAMIFICATION_GRADES_SUCCESS]: listGamificationGradesSuccess,
  [Constants.LIST_GAMIFICATION_GRADES_ERROR]: listGamificationGradesError,
  [Constants.GET_GAMIFICATION_CHALLENGES_REQUEST]:getGamificationChallengesRequest,
  [Constants.GET_GAMIFICATION_CHALLENGES_SUCCESS]:getGamificationChallengesSuccess,
  [Constants.GET_GAMIFICATION_CHALLENGES_ERROR]: getGamificationChallengesError,
  [Constants.CONFIGRATION_CHALLENGES_REQUEST]: configrationChallengesRequest,
  [Constants.CONFIGRATION_CHALLENGES_SUCCESS]: configrationChallengesSuccess,
  [Constants.CONFIGRATION_CHALLENGES_ERROR]: configrationChallengesError,
  [Constants.UPDATE_GAMIFICATION_GRADES_REQUEST]:updateGamificationGradesRequest,
  [Constants.UPDATE_GAMIFICATION_GRADES_SUCCESS]:updateGamificationGradesSuccess,
  [Constants.UPDATE_GAMIFICATION_GRADES_ERROR]: updateGamificationGradesError,
  [Constants.ADD_CONFIGRATION_CHALLENGES_REQUEST]:addConfigrationChallengesRequest,
  [Constants.ADD_CONFIGRATION_CHALLENGES_SUCCESS]: addConfigrationChallengesSuccess,
  [Constants.ADD_CONFIGRATION_CHALLENGES_ERROR]: addConfigrationChallengesError,
  [Constants.LIST_GAMIFICATION_LEADER_BOARDS_REQUEST]: listGamificationLeaderBoardsRequest,
  [Constants.LIST_GAMIFICATION_LEADER_BOARDS_SUCCESS]: listGamificationLeaderBoardsSuccess,
  [Constants.LIST_GAMIFICATION_LEADER_BOARDS_ERROR]: listGamificationLeaderBoardsError,
}
export default createReducer(INITIAL_STATE, HANDLERS)
