import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
    googleWorkspaceGetWidgetRequest: ['data'],
    googleWorkspaceGetWidgetSuccess: ['data', 'message'],
    googleWorkspaceGetWidgetError: ['message'],
    googleWorkspaceGetLogsRequest: ['data'],
    googleWorkspaceGetLogsSuccess: ['logs', 'message', 'totalLogsCount'],
    googleWorkspaceGetLogsError: ['message'],
    getGroupsForGoogleWorkspaceRequest: ['params'],
    getGroupsForGoogleWorkspaceSuccess: ['groups', 'message', 'totalNumberOfGroups'],
    getGroupsForGoogleWorkspaceError: ['message'],
    updateGroupsFromGoogleWorkspaceRequest: ['data'],
    updateGroupsFromGoogleWorkspaceSuccess: ['message'],
    updateGroupsFromGoogleWorkspaceError: ['message'],
    updateGoogleManagementListRequest: ['data'],
    updateGoogleManagementListSuccess: ['message'],
    updateGoogleManagementListError: ['message'],
    updateGoogleWorkspaceCustomFieldsRequest: ['data'],
    updateGoogleWorkspaceCustomFieldsSuccess: ['message'],
    updateGoogleWorkspaceCustomFieldsError: ['message'],
    handleSyncModeRedux: ['syncMode'],
    updateGoogleWorkspaceSyncModeRequest: ['data'],
    updateGoogleWorkspaceSyncModeSuccess: ['message', 'syncMode'],
    updateGoogleWorkspaceSyncModeError: ['message'],
    getSyncLogsHealthStatusRequest: ['data'],
    getSyncLogsHealthStatusSuccess: ['healthStatus', 'lastUpdatedAttendee'],
    getSyncLogsHealthStatusError: ['healthStatus'],


    connectGoogleWorkspace: ['integration'],
    connectGoogleWorkspaceSuccess: ['url', 'message'],
    connectGoogleWorkspaceFailure: ['error'],

    authorizeGoogleWorkspace: ['data'],
    authorizeGoogleWorkspaceSuccess: ['url', 'message'],
    authorizeGoogleWorkspaceFailure: ['error'],

    getGoogleOrgUnits: ['params'],
    getGoogleOrgUnitsSuccess: ['data'],
    getGoogleOrgUnitsFailure: ['error'],

    getOrgUnitsManually: ['params'],
    getOrgUnitsManuallySuccess: ['orgUnits', 'message'],
    getOrgUnitsManuallyFailure: ['error'],

    saveGoogleWorkspaceInfo: ['data'],
    saveGoogleWorkspaceInfoSuccess: ['message'],
    saveGoogleWorkspaceInfoFailure: ['error'],

    getGoogleUsers: ['params'],
    getGoogleUsersSuccess: ['data'],
    getGoogleUsersFailure: ['error'],

    syncUsers: ['params'],
    syncUsersSuccess: ['data'],
    syncUsersFailure: ['error'],

    checkWhetherGroupNameExistInIllumeetRequest: ['data'],
    checkWhetherGroupNameExistInIllumeetSuccess: ['groupDoesExist', 'groupId'],
    checkWhetherGroupNameExistInIllumeetError: [],
    setGetGroupsForGoogleWorkspaceSuccess: ['data'],
    setUpdateGoogleManagementListSuccess: ['data']
});
export const Constants = Types;
export default Creators;