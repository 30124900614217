import { useCountdown } from "../../../Common/useCountdown";
import { useCountdownReverse } from "../../../Common/useCountDownReverse";

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (        
      <>{days}d: {hours}h: {minutes}m: {seconds}s</>
    );
};

const CountdownTimerReverse = ({ targetDate }) => {
    const [days, hours, minutes, seconds] = useCountdownReverse(targetDate)

    return (
        <ShowCounter
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
        />
    );
    
};
  
export default CountdownTimerReverse;