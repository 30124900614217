import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification, redirectTo } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import { Button } from '@material-ui/core'
import DashboardLayout from '../../Layouts/DashboardLayout'
import Chart from './chart'
import LineChart from './lineChart'
import EventAnalytics from './analytics'
import EmailModal from './emailsListModal'
import { Modal } from 'react-responsive-modal'
import Pagination from 'react-js-pagination'
import {
  EmailsRegistrationAnalyticsInfoAPI,
  EventsAllAPI,
  getEventRegistrationTemplatesApi,
  EmailsCustomAnalyticsDataForRegistrationAPI,
  resendEmailAPI,
} from '../../Redux/API'
import { ExportToCsv } from 'export-to-csv'
import SearchIconBar from '../../assets/images/searchIco.png'
import ReactTooltip from 'react-tooltip'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import DataTable from 'react-data-table-component'

class RegistrationAnalytics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Registration Analytics',
      pageTitle: 'Registration Analytics',
      loading: false,
      totalSendEmail: 0,
      totalEmail: 0,
      reject: 0,
      totalArray: [],
      sendArray: [],
      rejectArray: [],
      completed: 0,
      eventId: '',
      templateId: '',
      eventType: '',
      clickArray: [],
      openArray: [],
      open: false,
      modalTitle: 'Emails',
      sendEmailsList: [],
      rejectEmailsList: [],
      emailsList: [],
      analyticsData: {},
      events: [],
      templates: [],
      bounced: 0,
      emailData: [],
      allEmailData: [],
      page_no: 1,
      showData: 'all',
      searchText: '',
      filterBy: 'all',
      softBounced: 0,
      hardBounced: 0,
      softBouncedRate: 0,
      hardBouncedRate: 0,
      rejects: 0,
      rejectRate: 0,
      unsubRate: 0,
      unsub: 0,
      spamRate: 0,
      spam: 0,
      failedRate: 0,
      failed: 0,
      totalEmailsCount: 0,
      recordType: 'all',
      allEmail: [],
      totalResponse: false,
      showCustom: false,
      queRate: 0,
      que: 0,
      deferral: 0,
      deferralRate: 0,
      eventDomain: '',
    }
  }

  customAnalyticsUpdate = () => {
    const { eventId, templateId } = this.state
    if (eventId && templateId) {
      this.handleLoading()
      EmailsCustomAnalyticsDataForRegistrationAPI({
        page_no: 1,
        eventId: eventId,
        templateId: templateId,
      })
        .then((result) => { 
          if (result.data.status === 200) {
            createNotification('success', result.data.message)
          } else {
            createNotification('error', result.data.message)
          }
          this.handleLoading()
          const requestbody = {
            eventId: eventId,
            templateId: templateId,
            page_no: this.state.page_no,
          }
          this.props.reportDataRequest(requestbody)
          this.emailsDataApiCall(requestbody)
        })
        .catch(this.handleLoading())
    } else {
      createNotification('error', 'Choose a campaign')
    }
  }

  resetDate = () => {
    this.handleLoading()
    this.setState({ startDate: '', endDate: '' })
    const { eventId, templateId, page_no } = this.state
    let requestbody = {
      eventId: eventId,
      templateId: templateId,
      page_no: page_no,
    }
    this.props.reportDataRequest(requestbody)
    this.emailsDataApiCall(requestbody)
  }
  onChangeDate = (date) => { 
    this.setState({ startDate: date })
  }

  onChangeEndDate = (date) => {
    this.handleLoading()
    this.setState({ endDate: date })
    const { eventId, templateId, startDate, page_no } = this.state

    let requestbody = {
      eventId: eventId,
      templateId: templateId,
      page_no: page_no,
      startDate: startDate,
      endDate: date,
    }
    this.props.reportDataRequest(requestbody)
    this.emailsDataApiCall(requestbody)
  }

  onCloseModal = () => {
    this.setState({ open: false })
  }
  handleLoading = () => {
  
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  async componentDidUpdate(prevProps, prevState) {
    
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.apiCall()
    }
    if (
      this.props.events.emailRegistrationDataReportsSuccess === true &&
      this.props.events.message !== prevProps.events.message
    ) { 
      let st = prevState.loading ? this.setState({ loading: false }) : null

      const emailReports = this.props.events.reports.mandrill
      if (Object.keys(emailReports).length > 0) {
        const total =
          parseInt(emailReports.sent) +
          parseInt(emailReports.hard_bounces) +
          parseInt(emailReports.soft_bounces) +
          parseInt(emailReports.rejects) +
          parseInt(emailReports.failed) +
          parseInt(emailReports.spam) +
          parseInt(emailReports.que) +
          parseInt(emailReports.unsub) +
          parseInt(emailReports.unsub) +
          parseInt(emailReports.deferral)

        let softBouncedRate =
          emailReports.soft_bounces !== 0
            ? (emailReports.soft_bounces * 100) / total
            : 0
        let hardBouncedRate =
          emailReports.hard_bounces !== 0
            ? (emailReports.hard_bounces * 100) / total
            : 0
        let rejectRate =
          emailReports.rejects !== 0 ? (emailReports.rejects * 100) / total : 0
        let openRate =
          emailReports.opens !== 0 ? (emailReports.opens * 100) / total : 0
        let clickRate =
          emailReports.clicks !== 0 ? (emailReports.clicks * 100) / total : 0
        let deliverRate =
          emailReports.sent !== 0 ? (emailReports.sent * 100) / total : 0
        let unsubRate =
          emailReports.unsub !== 0 ? (emailReports.unsub * 100) / total : 0
        let spamRate =
          emailReports.spam !== 0 ? (emailReports.spam * 100) / total : 0
        let failedRate =
          emailReports.failed !== 0 ? (emailReports.failed * 100) / total : 0
        let queRate =
          emailReports.que !== 0 ? (emailReports.que * 100) / total : 0
        let deferralRate =
          emailReports.deferral !== 0
            ? (emailReports.deferral * 100) / total
            : 0

        this.setState({
          softBounced: emailReports.soft_bounces,
          hardBounced: emailReports.hard_bounces,
          hardBouncedRate: hardBouncedRate.toFixed(1),
          softBouncedRate: softBouncedRate.toFixed(1),
          totalEmail: total,
          totalSendEmail: emailReports.sent,
          reject: emailReports.rejects,
          rejectRate: rejectRate.toFixed(1),
          openRate: openRate.toFixed(1),
          clickRate: clickRate.toFixed(1),
          deliverRate: deliverRate.toFixed(1),
          openEmail: emailReports.opens,
          clickEmail: emailReports.clicks,
          unsubRate: unsubRate.toFixed(1),
          unsub: emailReports.unsub,
          spamRate: spamRate.toFixed(1),
          spam: emailReports.spam,
          failedRate: failedRate.toFixed(2),
          failed: emailReports.failed,
          queRate: queRate.toFixed(2),
          que: emailReports.que,
          deferralRate: deferralRate.toFixed(2),
          deferral: emailReports.deferral,
        })

        let sendArray = [
          emailReports.stats.today.sent,
          emailReports.stats.last_7_days.sent,
          emailReports.stats.last_30_days.sent,
          emailReports.stats.last_60_days.sent,
          emailReports.stats.last_90_days.sent,
        ]
        let totalBounced = [
          parseInt(emailReports.stats.today.hard_bounces) +
            parseInt(emailReports.stats.today.soft_bounces),
          parseInt(emailReports.stats.last_7_days.hard_bounces) +
            parseInt(emailReports.stats.last_7_days.soft_bounces),
          parseInt(emailReports.stats.last_30_days.hard_bounces) +
            parseInt(emailReports.stats.last_30_days.soft_bounces),
          parseInt(emailReports.stats.last_60_days.hard_bounces) +
            parseInt(emailReports.stats.last_60_days.soft_bounces),
          parseInt(emailReports.stats.last_90_days.hard_bounces) +
            parseInt(emailReports.stats.last_90_days.soft_bounces),
        ]

        let rejectArray = [
          emailReports.stats.today.rejects,
          emailReports.stats.last_7_days.rejects,
          emailReports.stats.last_30_days.rejects,
          emailReports.stats.last_60_days.rejects,
          emailReports.stats.last_90_days.rejects,
        ]

        let openArray = [
          emailReports.stats.today.opens,
          emailReports.stats.last_7_days.opens,
          emailReports.stats.last_30_days.opens,
          emailReports.stats.last_60_days.opens,
          emailReports.stats.last_90_days.opens,
        ]

        let clickArray = [
          emailReports.stats.today.clicks,
          emailReports.stats.last_7_days.clicks,
          emailReports.stats.last_30_days.clicks,
          emailReports.stats.last_60_days.clicks,
          emailReports.stats.last_90_days.clicks,
        ]

        this.setState({
          sendArray: sendArray,
          rejectArray: rejectArray,
          openArray: openArray,
          clickArray: clickArray,
          totalBounced: totalBounced,
        })
      } else {
        const Array = [0, 0, 0, 0, 0]
        this.setState({
          softBounced: 0,
          hardBounced: 0,
          hardBouncedRate: 0,
          softBouncedRate: 0,
          totalEmail: 0,
          totalSendEmail: 0,
          reject: 0,
          rejectRate: 0,
          openRate: 0,
          clickRate: 0,
          deliverRate: 0,
          openEmail: 0,
          clickEmail: 0,
          unsubRate: 0,
          unsub: 0,
          spamRate: 0,
          spam: 0,
          failedRate: 0,
          failed: 0,
          queRate: 0,
          que: 0,
          deferralRate: 0,
          deferral: 0,
          sendArray: Array,
          rejectArray: Array,
          openArray: Array,
          clickArray: Array,
          totalBounced: Array,
        })
      }
    }

    if (
      this.props.events.error &&
      this.props.events.message !== prevProps.events.message
    ) {
      createNotification('error', this.props.events.message, '')
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }
  }
  componentDidMount() {
    if (
      !this.props.match.params.templateId &&
      !this.props.match.params.eventId
    ) {
      this.getDefaultEventTemplatesApiCall()
    } else {
      this.allEventsApiCall()
      //this.emailsDataApiCall(requestbody)
      //this.apiCall()
    }

    if (
      this.props.match.params.eventId &&
      this.props.match.params.eventId !== '' &&
      !this.props.match.params.templateId
    ) {
      
      this.setState({ eventId: this.props.match.params.eventId })
      this.props.eventRequest(this.props.match.params.eventId)
    }

    this.apiCall()
  }

  apiCall = () => {
    let requestbody = {
      eventId: '',
      templateId: '',
      page_no: this.state.page_no,
    }
    if (
      this.props.match.params.eventId &&
      this.props.match.params.eventId !== '' &&
      !this.props.match.params.templateId
    ) {
      requestbody = {
        eventId: this.props.match.params.eventId,
        templateId: '',
        page_no: this.state.page_no,
      }
      /****template api call */
      getEventRegistrationTemplatesApi(this.props.match.params.eventId).then(
        (resultTemplate) => {
          if (resultTemplate.data.status === 200) {
            this.setState({
              templates: resultTemplate.data.templates,
              templateId:
                resultTemplate.data.templates &&
                resultTemplate.data.templates.length > 0
                  ? resultTemplate.data.templates[0]._id
                  : '',
              eventId: this.props.match.params.eventId,
            })

            requestbody = {
              eventId: this.props.match.params.eventId,
              templateId:
                resultTemplate.data.templates &&
                resultTemplate.data.templates.length > 0
                  ? resultTemplate.data.templates[0]._id
                  : '',
              page_no: this.state.page_no,
            }
            this.props.reportDataRequest(requestbody)
            this.emailsDataApiCall(requestbody)
          }
        },
      )
    } else if (
      this.props.match.params.eventId &&
      this.props.match.params.eventId !== '' &&
      this.props.match.params.templateId &&
      this.props.match.params.templateId !== ''
    ) {
      this.getEventTemplatesApiCall(this.props.match.params.eventId)
      requestbody = {
        eventId: this.props.match.params.eventId,
        templateId: this.props.match.params.templateId,
        page_no: this.state.page_no,
      }
      this.setState({ templateId: this.props.match.params.templateId })
      this.props.reportDataRequest(requestbody)
      this.emailsDataApiCall(requestbody)
    }
    this.props.eventRequest(this.props.match.params.eventId)
    
  }

  getDefaultEventTemplatesApiCall = () => {
    EventsAllAPI()
      .then((result) => {
        if (result.data.status === 200) {
          this.setState({ events: result.data.events })
          
          if (result.data.events && result.data.events.length > 0) {
            this.props.eventRequest(result.data.events[0]._id)
            getEventRegistrationTemplatesApi(result.data.events[0]._id).then(
              (resultTemplate) => { 
                if (result.data.status === 200) {
                  this.setState({
                    templates: resultTemplate.data.templates,
                    templateId:
                      resultTemplate.data.templates &&
                      resultTemplate.data.templates.length > 0
                        ? resultTemplate.data.templates[0]._id
                        : '',
                    eventId: result.data.events[0]._id,
                  })

                  const requestbody = {
                    eventId: result.data.events[0]._id,
                    templateId:
                      resultTemplate.data.templates &&
                      resultTemplate.data.templates.length > 0
                        ? resultTemplate.data.templates[0]._id
                        : '',
                    page_no: this.state.page_no,
                  }
                  this.props.reportDataRequest(requestbody)
                  this.emailsDataApiCall(requestbody)
                }
              },
            )
          }
        } else {
          createNotification('error', result.data.message)
        }
      })
      .catch(console.log)
  }

  allEventsApiCall = () => {
    EventsAllAPI()
      .then((result) => {
        if (result.data.status === 200) {
          this.setState({ events: result.data.events })
        } else {
          createNotification('error', result.data.message)
        }
      })
      .catch(console.log)
  }

  getEventTemplatesApiCall = (eventId = this.state.eventId) => { 
    this.setState({ eventId: eventId })
    getEventRegistrationTemplatesApi(eventId).then((result) => {
      if (result.data.status === 200) {
        this.setState({ templates: result.data.templates })
      }
    })
  }

  emailsDataApiCall = (requestBody) => {
    EmailsRegistrationAnalyticsInfoAPI(requestBody)
      .then((result) => {
        if (result.data.status === 200) {
          this.setState({
            analyticsData: result.data.data,
            emailData: result.data.data,
            totalEmailsCount: result.data.totalEmailsCount,
            allEmail: result.data.resultAll,
            totalResponse: true,
          })
        } else {
          createNotification('error', result.data.message)
        }
        this.setState({ loading: false })
      })
      .catch(console.log)
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    }) 
    if (event.target.name === 'filter_bydate') {
      const v1 = event.target.value
      const emailReports = this.props.events.reports.mandrill
      if (Object.keys(emailReports).length > 0) {
        if (v1 === 'custom') {
          this.setState({ showCustom: true })
        } else {
          this.setState({ showCustom: false })
        }
        this.setState({ showData: v1 })
        if (v1 === 'last_90_days') { 
          const total =
            parseInt(emailReports.stats.last_90_days.sent) +
            parseInt(emailReports.stats.last_90_days.hard_bounces) +
            parseInt(emailReports.stats.last_90_days.soft_bounces) +
            parseInt(emailReports.stats.last_90_days.rejects) +
            parseInt(emailReports.stats.last_90_days.spam) +
            parseInt(emailReports.stats.last_90_days.failed) +
            parseInt(emailReports.stats.last_90_days.que) +
            parseInt(emailReports.stats.last_90_days.unsub) +
            parseInt(emailReports.stats.last_90_days.deferral)

          let softBouncedRate =
            emailReports.stats.last_90_days.soft_bounces !== 0
              ? (emailReports.stats.last_90_days.soft_bounces * 100) / total
              : 0
          let hardBouncedRate =
            emailReports.stats.last_90_days.hard_bounces !== 0
              ? (emailReports.stats.last_90_days.hard_bounces * 100) / total
              : 0
          let rejectRate =
            emailReports.stats.last_90_days.rejects !== 0
              ? (emailReports.stats.last_90_days.rejects * 100) / total
              : 0
          let openRate =
            emailReports.stats.last_90_days.opens !== 0
              ? (emailReports.stats.last_90_days.opens * 100) / total
              : 0
          let clickRate =
            emailReports.stats.last_90_days.clicks !== 0
              ? (emailReports.stats.last_90_days.clicks * 100) / total
              : 0
          let deliverRate =
            emailReports.stats.last_90_days.sent !== 0
              ? (emailReports.stats.last_90_days.sent * 100) / total
              : 0
          let unsubRate =
            emailReports.stats.last_90_days.unsub !== 0
              ? (emailReports.stats.last_90_days.unsub * 100) / total
              : 0
          let spamRate =
            emailReports.stats.last_90_days.spam !== 0
              ? (emailReports.stats.last_90_days.spam * 100) / total
              : 0
          let failedRate =
            emailReports.stats.last_90_days.failed !== 0
              ? (emailReports.stats.last_90_days.failed * 100) / total
              : 0
          let queRate =
            emailReports.stats.last_90_days.que !== 0
              ? (emailReports.stats.last_90_days.que * 100) / total
              : 0
          let deferralRate =
            emailReports.stats.last_90_days.deferral !== 0
              ? (emailReports.stats.last_90_days.deferral * 100) / total
              : 0

          this.setState({
            softBounced: emailReports.stats.last_90_days.soft_bounces,
            hardBounced: emailReports.stats.last_90_days.hard_bounces,
            hardBouncedRate: hardBouncedRate.toFixed(2),
            softBouncedRate: softBouncedRate.toFixed(2),
            totalEmail: total,
            totalSendEmail: emailReports.stats.last_90_days.sent,
            reject: emailReports.stats.last_90_days.rejects,
            rejectRate: rejectRate.toFixed(2),
            openRate: openRate.toFixed(2),
            clickRate: clickRate.toFixed(2),
            deliverRate: deliverRate.toFixed(2),
            openEmail: emailReports.stats.last_90_days.opens,
            clickEmail: emailReports.stats.last_90_days.clicks,
            unsubRate: unsubRate.toFixed(2),
            unsub: emailReports.stats.last_90_days.unsub,
            spamRate: spamRate.toFixed(2),
            spam: emailReports.stats.last_90_days.spam,
            failedRate: failedRate.toFixed(2),
            failed: emailReports.stats.last_90_days.failed,
            queRate: queRate.toFixed(2),
            que: emailReports.stats.last_90_days.que,
            deferral: emailReports.stats.last_90_days.deferral,
          })
        } else if (v1 === 'last_60_days') {
          const total =
            parseInt(emailReports.stats.last_60_days.sent) +
            parseInt(emailReports.stats.last_60_days.hard_bounces) +
            parseInt(emailReports.stats.last_60_days.soft_bounces) +
            parseInt(emailReports.stats.last_60_days.rejects) +
            parseInt(emailReports.stats.last_60_days.spam) +
            parseInt(emailReports.stats.last_60_days.failed) +
            parseInt(emailReports.stats.last_60_days.que) +
            parseInt(emailReports.stats.last_60_days.unsub) +
            parseInt(emailReports.stats.last_60_days.deferral)

          let softBouncedRate =
            emailReports.stats.last_60_days.soft_bounces !== 0
              ? (emailReports.stats.last_60_days.soft_bounces * 100) / total
              : 0
          let hardBouncedRate =
            emailReports.stats.last_60_days.hard_bounces !== 0
              ? (emailReports.stats.last_60_days.hard_bounces * 100) / total
              : 0
          let rejectRate =
            emailReports.stats.last_60_days.rejects !== 0
              ? (emailReports.stats.last_60_days.rejects * 100) / total
              : 0
          let openRate =
            emailReports.stats.last_60_days.opens !== 0
              ? (emailReports.stats.last_60_days.opens * 100) / total
              : 0
          let clickRate =
            emailReports.stats.last_60_days.clicks !== 0
              ? (emailReports.stats.last_60_days.clicks * 100) / total
              : 0
          let deliverRate =
            emailReports.stats.last_60_days.sent !== 0
              ? (emailReports.stats.last_60_days.sent * 100) / total
              : 0
          let unsubRate =
            emailReports.stats.last_60_days.unsub !== 0
              ? (emailReports.stats.last_60_days.unsub * 100) / total
              : 0
          let spamRate =
            emailReports.stats.last_60_days.spam !== 0
              ? (emailReports.stats.last_60_days.spam * 100) / total
              : 0
          let failedRate =
            emailReports.stats.last_60_days.failed !== 0
              ? (emailReports.stats.last_60_days.failed * 100) / total
              : 0
          let queRate =
            emailReports.stats.last_60_days.que !== 0
              ? (emailReports.stats.last_60_days.que * 100) / total
              : 0
          let deferralRate =
            emailReports.stats.last_60_days.deferral !== 0
              ? (emailReports.stats.last_60_days.deferral * 100) / total
              : 0

          this.setState({
            softBounced: emailReports.stats.last_60_days.soft_bounces,
            hardBounced: emailReports.stats.last_60_days.hard_bounces.length,
            hardBouncedRate: hardBouncedRate.toFixed(2),
            softBouncedRate: softBouncedRate.toFixed(2),
            totalEmail: total,
            totalSendEmail: emailReports.stats.last_60_days.sent,
            reject: emailReports.stats.last_60_days.rejects,
            rejectRate: rejectRate.toFixed(2),
            openRate: openRate.toFixed(2),
            clickRate: clickRate.toFixed(2),
            deliverRate: deliverRate.toFixed(2),
            openEmail: emailReports.stats.last_60_days.opens,
            clickEmail: emailReports.stats.last_60_days.clicks,
            unsubRate: unsubRate.toFixed(2),
            unsub: emailReports.stats.last_60_days.unsub,
            spamRate: spamRate.toFixed(2),
            spam: emailReports.stats.last_60_days.spam,
            failedRate: failedRate.toFixed(2),
            failed: emailReports.stats.last_60_days.failed,
            queRate: failedRate.toFixed(2),
            que: emailReports.stats.last_60_days.que,
            deferralRate: deferralRate.toFixed(2),
            deferral: emailReports.stats.last_60_days.deferral,
          })
        } else if (v1 === 'last_30_days') {
          const total =
            parseInt(emailReports.stats.last_30_days.sent) +
            parseInt(emailReports.stats.last_30_days.hard_bounces) +
            parseInt(emailReports.stats.last_30_days.soft_bounces) +
            parseInt(emailReports.stats.last_30_days.rejects) +
            parseInt(emailReports.stats.last_30_days.spam) +
            parseInt(emailReports.stats.last_30_days.failed) +
            parseInt(emailReports.stats.last_30_days.que) +
            parseInt(emailReports.stats.last_30_days.unsub) +
            parseInt(emailReports.stats.last_30_days.deferral)

          let softBouncedRate =
            emailReports.stats.last_30_days.soft_bounces !== 0
              ? (emailReports.stats.last_30_days.soft_bounces * 100) / total
              : 0
          let hardBouncedRate =
            emailReports.stats.last_30_days.hard_bounces !== 0
              ? (emailReports.stats.last_30_days.hard_bounces * 100) / total
              : 0
          let rejectRate =
            emailReports.stats.last_30_days.rejects !== 0
              ? (emailReports.stats.last_30_days.rejects * 100) / total
              : 0
          let openRate =
            emailReports.stats.last_30_days.opens !== 0
              ? (emailReports.stats.last_30_days.opens * 100) / total
              : 0
          let clickRate =
            emailReports.stats.last_30_days.clicks !== 0
              ? (emailReports.stats.last_30_days.clicks * 100) / total
              : 0
          let deliverRate =
            emailReports.stats.last_30_days.sent !== 0
              ? (emailReports.stats.last_30_days.sent * 100) / total
              : 0
          let unsubRate =
            emailReports.stats.last_30_days.unsub !== 0
              ? (emailReports.stats.last_30_days.unsub * 100) / total
              : 0
          let spamRate =
            emailReports.stats.last_30_days.spam !== 0
              ? (emailReports.stats.last_30_days.spam * 100) / total
              : 0
          let failedRate =
            emailReports.stats.last_30_days.failed !== 0
              ? (emailReports.stats.last_30_days.failed * 100) / total
              : 0
          let queRate =
            emailReports.stats.last_30_days.que !== 0
              ? (emailReports.stats.last_30_days.que * 100) / total
              : 0
          let deferralRate =
            emailReports.stats.last_30_days.deferral !== 0
              ? (emailReports.stats.last_30_days.deferral * 100) / total
              : 0

          this.setState({
            softBounced: emailReports.stats.last_30_days.soft_bounces,
            hardBounced: emailReports.stats.last_30_days.hard_bounces.length,
            hardBouncedRate: hardBouncedRate.toFixed(2),
            softBouncedRate: softBouncedRate.toFixed(2),
            totalEmail: total,
            totalSendEmail: emailReports.stats.last_30_days.sent,
            reject: emailReports.stats.last_30_days.rejects,
            rejectRate: rejectRate.toFixed(2),
            openRate: openRate.toFixed(2),
            clickRate: clickRate.toFixed(2),
            deliverRate: deliverRate.toFixed(2),
            openEmail: emailReports.stats.last_30_days.opens,
            clickEmail: emailReports.stats.last_30_days.clicks,
            unsubRate: unsubRate.toFixed(2),
            unsub: emailReports.stats.last_30_days.unsub,
            spamRate: spamRate.toFixed(2),
            spam: emailReports.stats.last_30_days.spam,
            failedRate: failedRate.toFixed(2),
            failed: emailReports.stats.last_30_days.failed,
            queRate: queRate.toFixed(2),
            que: emailReports.stats.last_30_days.que,
            deferralRate: deferralRate.toFixed(2),
            deferral: emailReports.stats.last_30_days.deferral,
          })
        } else if (v1 === 'last_7_days') {
          const total =
            parseInt(emailReports.stats.last_7_days.sent) +
            parseInt(emailReports.stats.last_7_days.hard_bounces) +
            parseInt(emailReports.stats.last_7_days.soft_bounces) +
            parseInt(emailReports.stats.last_7_days.rejects) +
            parseInt(emailReports.stats.last_7_days.spam) +
            parseInt(emailReports.stats.last_7_days.failed) +
            parseInt(emailReports.stats.last_7_days.que) +
            parseInt(emailReports.stats.last_7_days.unsub) +
            parseInt(emailReports.stats.last_7_days.deferral)

          let softBouncedRate =
            emailReports.stats.last_7_days.soft_bounces !== 0
              ? (emailReports.stats.last_7_days.soft_bounces * 100) / total
              : 0
          let hardBouncedRate =
            emailReports.stats.last_7_days.hard_bounces !== 0
              ? (emailReports.stats.last_7_days.hard_bounces * 100) / total
              : 0
          let rejectRate =
            emailReports.stats.last_7_days.rejects !== 0
              ? (emailReports.stats.last_7_days.rejects * 100) / total
              : 0
          let openRate =
            emailReports.stats.last_7_days.opens !== 0
              ? (emailReports.stats.last_7_days.opens * 100) / total
              : 0
          let clickRate =
            emailReports.stats.last_7_days.clicks !== 0
              ? (emailReports.stats.last_7_days.clicks * 100) / total
              : 0
          let deliverRate =
            emailReports.stats.last_7_days.sent !== 0
              ? (emailReports.stats.last_7_days.sent * 100) / total
              : 0
          let unsubRate =
            emailReports.stats.last_7_days.unsub !== 0
              ? (emailReports.stats.last_7_days.unsub * 100) / total
              : 0
          let spamRate =
            emailReports.stats.last_7_days.spam !== 0
              ? (emailReports.stats.last_7_days.spam * 100) / total
              : 0
          let failedRate =
            emailReports.stats.last_7_days.failed !== 0
              ? (emailReports.stats.last_7_days.failed * 100) / total
              : 0
          let queRate =
            emailReports.stats.last_7_days.que !== 0
              ? (emailReports.stats.last_7_days.que * 100) / total
              : 0
          let deferralRate =
            emailReports.stats.last_7_days.deferral !== 0
              ? (emailReports.stats.last_7_days.deferral * 100) / total
              : 0

          this.setState({
            softBounced: emailReports.stats.last_7_days.soft_bounces,
            hardBounced: emailReports.stats.last_7_days.hard_bounces.length,
            hardBouncedRate: hardBouncedRate.toFixed(2),
            softBouncedRate: softBouncedRate.toFixed(2),
            totalEmail: total,
            totalSendEmail: emailReports.stats.last_7_days.sent,
            reject: emailReports.stats.last_7_days.rejects,
            rejectRate: rejectRate.toFixed(2),
            openRate: openRate.toFixed(2),
            clickRate: clickRate.toFixed(2),
            deliverRate: deliverRate.toFixed(2),
            openEmail: emailReports.stats.last_7_days.opens,
            clickEmail: emailReports.stats.last_7_days.clicks,
            unsubRate: unsubRate.toFixed(2),
            unsub: emailReports.stats.last_7_days.unsub,
            spamRate: spamRate.toFixed(2),
            spam: emailReports.stats.last_7_days.spam,
            failedRate: failedRate.toFixed(2),
            failed: emailReports.stats.last_7_days.failed,
            queRate: queRate.toFixed(2),
            que: emailReports.stats.last_7_days.que,
            deferralRate: deferralRate.toFixed(2),
            deferral: emailReports.stats.last_7_days.deferral,
          })
        } else if (v1 === 'today') {
          const total =
            parseInt(emailReports.stats.today.sent) +
            parseInt(emailReports.stats.today.hard_bounces) +
            parseInt(emailReports.stats.today.soft_bounces) +
            parseInt(emailReports.stats.today.rejects) +
            parseInt(emailReports.stats.today.spam) +
            parseInt(emailReports.stats.today.failed) +
            parseInt(emailReports.stats.today.que) +
            parseInt(emailReports.stats.today.unsub) +
            parseInt(emailReports.stats.today.deferral)

          let softBouncedRate =
            emailReports.stats.today.soft_bounces !== 0
              ? (emailReports.stats.today.soft_bounces * 100) / total
              : 0
          let hardBouncedRate =
            emailReports.stats.today.hard_bounces !== 0
              ? (emailReports.stats.today.hard_bounces * 100) / total
              : 0
          let rejectRate =
            emailReports.stats.today.rejects !== 0
              ? (emailReports.stats.today.rejects * 100) / total
              : 0
          let openRate =
            emailReports.stats.today.opens !== 0
              ? (emailReports.stats.today.opens * 100) / total
              : 0
          let clickRate =
            emailReports.stats.today.clicks !== 0
              ? (emailReports.stats.today.clicks * 100) / total
              : 0
          let deliverRate =
            emailReports.stats.today.sent !== 0
              ? (emailReports.stats.today.sent * 100) / total
              : 0
          let unsubRate =
            emailReports.stats.today.unsub !== 0
              ? (emailReports.stats.today.unsub * 100) / total
              : 0
          let spamRate =
            emailReports.stats.today.spam !== 0
              ? (emailReports.stats.today.spam * 100) / total
              : 0
          let failedRate =
            emailReports.stats.today.failed !== 0
              ? (emailReports.stats.today.failed * 100) / total
              : 0
          let queRate =
            emailReports.stats.today.que !== 0
              ? (emailReports.stats.today.que * 100) / total
              : 0
          let deferralRate =
            emailReports.stats.today.deferral !== 0
              ? (emailReports.stats.today.deferral * 100) / total
              : 0

          this.setState({
            softBounced: emailReports.stats.today.soft_bounces,
            hardBounced: emailReports.stats.today.hard_bounces.length,
            hardBouncedRate: hardBouncedRate.toFixed(2),
            softBouncedRate: softBouncedRate.toFixed(2),
            totalEmail: total,
            totalSendEmail: emailReports.stats.today.sent,
            reject: emailReports.stats.today.rejects,
            rejectRate: rejectRate.toFixed(2),
            openRate: openRate.toFixed(2),
            clickRate: clickRate.toFixed(2),
            deliverRate: deliverRate.toFixed(2),
            openEmail: emailReports.stats.today.opens,
            clickEmail: emailReports.stats.today.clicks,
            unsubRate: unsubRate.toFixed(2),
            unsub: emailReports.stats.today.unsub,
            spamRate: spamRate.toFixed(2),
            spam: emailReports.stats.today.spam,
            failedRate: failedRate.toFixed(2),
            failed: emailReports.stats.today.failed,
            queRate: queRate.toFixed(2),
            que: emailReports.stats.today.que,
            deferralRate: deferralRate.toFixed(2),
            deferral: emailReports.stats.today.deferral,
          })
        } else {
          const total =
            parseInt(emailReports.sent) +
            parseInt(emailReports.hard_bounces) +
            parseInt(emailReports.soft_bounces) +
            parseInt(emailReports.rejects) +
            parseInt(emailReports.spam) +
            parseInt(emailReports.failed) +
            parseInt(emailReports.que) +
            parseInt(emailReports.unsub) +
            parseInt(emailReports.deferral)

          let softBouncedRate =
            emailReports.soft_bounces !== 0
              ? (emailReports.soft_bounces * 100) / total
              : 0
          let hardBouncedRate =
            emailReports.hard_bounces !== 0
              ? (emailReports.hard_bounces * 100) / total
              : 0
          let rejectRate =
            emailReports.rejects !== 0
              ? (emailReports.rejects * 100) / total
              : 0
          let openRate =
            emailReports.opens !== 0 ? (emailReports.opens * 100) / total : 0
          let clickRate =
            emailReports.clicks !== 0 ? (emailReports.clicks * 100) / total : 0
          let deliverRate =
            emailReports.sent !== 0 ? (emailReports.sent * 100) / total : 0
          let unsubRate =
            emailReports.unsub !== 0 ? (emailReports.unsub * 100) / total : 0
          let spamRate =
            emailReports.spam !== 0 ? (emailReports.spam * 100) / total : 0
          let failedRate =
            emailReports.failed !== 0 ? (emailReports.failed * 100) / total : 0
          let queRate =
            emailReports.que !== 0 ? (emailReports.que * 100) / total : 0
          let deferralRate =
            emailReports.deferral !== 0
              ? (emailReports.deferral * 100) / total
              : 0

          this.setState({
            softBounced: emailReports.soft_bounces,
            hardBounced: emailReports.hard_bounces.length,
            hardBouncedRate: hardBouncedRate.toFixed(2),
            softBouncedRate: softBouncedRate.toFixed(2),
            totalEmail: total,
            totalSendEmail: emailReports.sent,
            reject: emailReports.rejects,
            rejectRate: rejectRate.toFixed(2),
            openRate: openRate.toFixed(2),
            clickRate: clickRate.toFixed(2),
            deliverRate: deliverRate.toFixed(2),
            openEmail: emailReports.opens,
            clickEmail: emailReports.clicks,
            unsubRate: unsubRate.toFixed(2),
            unsub: emailReports.unsub,
            spamRate: spamRate.toFixed(2),
            spam: emailReports.spam,
            failedRate: failedRate.toFixed(2),
            failed: emailReports.failed,
            queRate: queRate.toFixed(2),
            que: emailReports.que,
            deferralRate: deferralRate.toFixed(2),
            deferral: emailReports.deferral,
          })
        }
      } else {
        this.setState({
          softBounced: 0,
          hardBounced: 0,
          hardBouncedRate: 0,
          softBouncedRate: 0,
          totalEmail: 0,
          totalSendEmail: 0,
          reject: 0,
          rejectRate: 0,
          openRate: 0,
          clickRate: 0,
          deliverRate: 0,
          openEmail: 0,
          clickEmail: 0,
          unsubRate: 0,
          unsub: 0,
          spamRate: 0,
          spam: 0,
          failedRate: 0,
          failed: 0,
          queRate: 0,
          que: 0,
          deferralRate: 0,
          deferral: 0,
        })
      }
    }

    if (event.target.name === 'filter_byState') {
      const v1 = event.target.value
      this.setState({ filterby: v1, emailData: [] })
      this.filterByfn(v1)
    }

    if (event.target.name === 'filter_byEvent') {
      if (event.target.value !== '') {
        this.handleLoading()
        this.setState({ eventId: event.target.value, recordType: 'event' })
        this.props.eventRequest(event.target.value)
        getEventRegistrationTemplatesApi(event.target.value).then(
          (resultTemplate) => {
            if (resultTemplate.data.status === 200) {
              this.setState({
                templates: resultTemplate.data.templates,
                templateId:
                  resultTemplate.data.templates &&
                  resultTemplate.data.templates.length > 0
                    ? resultTemplate.data.templates[0]._id
                    : '',
                eventId: event.target.value,
              })

              let requestbody = {
                eventId: event.target.value,
                templateId:
                  resultTemplate.data.templates &&
                  resultTemplate.data.templates.length > 0
                    ? resultTemplate.data.templates[0]._id
                    : '',
                page_no: this.state.page_no,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
              } 
              this.props.reportDataRequest(requestbody)
              this.emailsDataApiCall(requestbody)
            }
          },
        )
      }
    }

    if (event.target.name === 'filter_byEventTemplate') {
      if (event.target.value !== '') {
        this.handleLoading()
        this.setState({
          templateId: event.target.value,
          recordType: 'template',
        })
        const requestbody = {
          templateId: event.target.value,
          eventId: this.state.eventId,
        }
        this.props.reportDataRequest(requestbody)

        const requestb = {
          eventId: this.state.eventId,
          templateId: event.target.value,
          page_no: this.state.page_no,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        }
        this.emailsDataApiCall(requestb)
      }
    }
  }

  handlePageChange = (pageNumber) => {
    this.handleLoading() 
    const { filterby, eventId, templateId } = this.state
    this.setState({ page_no: pageNumber, emailData: [] })
    if (eventId && eventId !== '' && !templateId) {
      let requestbody = {
        eventId: eventId,
        templateId: '',
        page_no: pageNumber,
        filterby: filterby,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      }
      this.emailsDataApiCall(requestbody)
    } else if (eventId && eventId !== '' && templateId && templateId !== '') {
      let requestbody = {
        eventId: eventId,
        templateId: templateId,
        page_no: pageNumber,
        filterby: filterby,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      }
      this.emailsDataApiCall(requestbody)
    }
  }

  handleExport = () => {
    this.handleLoading()
    const { eventId, templateId, page_no } = this.state
    let requestBody = {
      eventId: eventId,
      templateId: templateId,
      page_no: page_no,
      export: 'yes',
    }
    EmailsRegistrationAnalyticsInfoAPI(requestBody)
      .then((result) => { 
        if (result.data.status === 200) { 
          let data = []
          let all = result.data.resultAll 
          for (let i = 0; i < all.length; i++) {
            let d = {
              status: all[i].status,
              sender:
                all[i].emailData && all[i].emailData.sender
                  ? all[i].emailData.sender
                  : all[i].mandrillEmailData &&
                    all[i].mandrillEmailData.from_email
                  ? all[i].mandrillEmailData.from_email
                  : 'N/A',
              email: all[i].email || 'N/A',
              subject:
                all[i].emailData && all[i].emailData.subject
                  ? all[i].emailData.subject
                  : all[i].mandrillEmailData && all[i].mandrillEmailData.subject
                  ? all[i].mandrillEmailData.subject
                  : 'N/A',
              opens:
                all[i].emailData && all[i].emailData.opens
                  ? all[i].emailData.opens.length
                  : 0,
              clicks:
                all[i].emailData && all[i].emailData.clicks
                  ? all[i].emailData.clicks.length
                  : 0,
            }
            data.push(d)
          }

          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Analytics Csv',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: 'analytics',
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
          }

          const csvExporter = new ExportToCsv(options)
          if (data.length > 0) {
            csvExporter.generateCsv(data)
          }
        } else {
          createNotification('error', result.data.message)
        }
        this.setState({ loading: false })
      })
      .catch(console.log)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ page_no: 1, emailData: [] })
    this.handleLoading()
    const { searchText } = this.state
    if (searchText !== '') {
      /* let requestbody ={eventId:'',templateId:'',page_no: 1,email:searchText}
            if (this.props.match.params.eventId && this.props.match.params.eventId !== "" && !this.props.match.params.templateId) {
                  requestbody ={eventId:this.props.match.params.eventId,templateId:'', page_no: 1,email:searchText}
            }else if(this.props.match.params.eventId && this.props.match.params.eventId !== "" && this.props.match.params.templateId && this.props.match.params.templateId !=="") {
    
                  requestbody ={eventId:this.props.match.params.eventId, templateId:this.props.match.params.templateId ,page_no: 1,email:searchText}
            }*/
      let requestbody = {
        eventId: this.state.eventId,
        templateId: this.state.templateId,
        page_no: 1,
        email: searchText,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      }
      this.emailsDataApiCall(requestbody)
    } else {
      /*let requestbody ={eventId:'',templateId:'',page_no: 1}
            if (this.props.match.params.eventId && this.props.match.params.eventId !== "" && !this.props.match.params.templateId) {
                  requestbody ={eventId:this.props.match.params.eventId,templateId:'', page_no: 1}
            }else if(this.props.match.params.eventId && this.props.match.params.eventId !== "" && this.props.match.params.templateId && this.props.match.params.templateId !=="") {
    
                  requestbody ={eventId:this.props.match.params.eventId, templateId:this.props.match.params.templateId ,page_no: 1}
            }*/
      let requestbody = {
        eventId: this.state.eventId,
        templateId: this.state.templateId,
        page_no: 1,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      }
      this.emailsDataApiCall(requestbody)
    }
  }

  filterByfn = (filterby) => { 
    this.handleLoading()
    this.setState({ page_no: 1 })
    if (filterby !== '') {
      /* let requestbody ={eventId:'',templateId:'',page_no: 1,filterby:filterby}
            if (this.props.match.params.eventId && this.props.match.params.eventId !== "" && !this.props.match.params.templateId) {
                  requestbody ={eventId:this.props.match.params.eventId,templateId:'', page_no: 1,filterby:filterby}
            }else if(this.props.match.params.eventId && this.props.match.params.eventId !== "" && this.props.match.params.templateId && this.props.match.params.templateId !=="") {
    
                  requestbody ={eventId:this.props.match.params.eventId, templateId:this.props.match.params.templateId ,page_no: 1,filterby:filterby}
            }*/
      let requestbody = {
        eventId: this.state.eventId,
        templateId: this.state.templateId,
        page_no: 1,
        filterby: filterby,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      }
      this.emailsDataApiCall(requestbody)
    } else {
      /* let requestbody ={eventId:'',templateId:'',page_no: 1}
            if (this.props.match.params.eventId && this.props.match.params.eventId !== "" && !this.props.match.params.templateId) {
                  requestbody ={eventId:this.props.match.params.eventId, page_no: 1}
            }else if(this.props.match.params.eventId && this.props.match.params.eventId !== "" && this.props.match.params.templateId && this.props.match.params.templateId !=="") {
    
                  requestbody ={eventId:this.props.match.params.eventId, templateId:this.props.match.params.templateId ,page_no: 1}
            }*/
      let requestbody = {
        eventId: this.state.eventId,
        templateId: this.state.templateId,
        page_no: 1,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      }
      this.emailsDataApiCall(requestbody)
    }
  }
  resendEmail = (id) => {
    let ids = []
    ids.push(id)
    this.handleLoading()
    resendEmailAPI({ ids })
      .then((result) => {
        if (result.data.status === 200) {
          createNotification('success', result.data.message)
        } else {
          createNotification('error', result.data.message)
        }
        this.handleLoading()
        // window.location.reload();
      })
      .catch(this.handleLoading())
  }

  render() { 
    const {
      title,
      pageTitle,
      loading,
      totalEmail,
      totalSendEmail,
      reject,
      rejectRate,
      totalBounced,
      sendArray,
      rejectArray,
      open,
      emailsList,
      modalTitle,
      analyticsData,
      eventId,
      templateId,
      eventType,
      events,
      templates,
      openArray,
      clickArray,
      openEmail,
      openRate,
      clickEmail,
      clickRate,
      deliverRate,
      bounced,
      emailData,
      page_no,
      allEmailData,
      searchText,
      softBounced,
      hardBounced,
      softBouncedRate,
      hardBouncedRate,
      unsubRate,
      unsub,
      spamRate,
      spam,
      totalEmailsCount,
      recordType,
      totalResponse,
      startDate,
      endDate,
      showCustom,
      failedRate,
      failed,
      queRate,
      que,
      deferral,
      deferralRate,
      eventDomain,
    } = this.state

   
    let dataArray = []

    emailData &&
      emailData.length > 0 &&
      emailData.map((data) => {
        let classAlert =
          data.status === 'bounce' ||
          data.status === 'hard_bounce' ||
          data.status === 'soft_bounce' ||
          data.status === 'reject' ||
          data.status === 'bounced' ||
          data.status === 'hard_bounced' ||
          data.status === 'soft_bounced' ||
          data.status === 'rejected'
            ? 'tdAlert'
            : null

        let tip =
          data.status === 'bounce' ||
          data.status === 'bounced' ||
          data.status === 'hard_bounce' ||
          data.status === 'soft_bounce' ||
          data.status === 'reject' ||
          data.status === 'hard_bounced' ||
          data.status === 'soft_bounced' ||
          data.status === 'rejected'
            ? (data.emailData && data.emailData.bounce_description) || null
            : null

        let statusTip =
          data.status === 'bounce' ||
          data.status === 'hard_bounce' ||
          data.status === 'soft_bounce' ||
          data.status === 'reject' ||
          data.status === 'bounced' ||
          data.status === 'hard_bounced' ||
          data.status === 'soft_bounced' ||
          data.status === 'rejected' ? (
            <ReactTooltip />
          ) : null

        let sender =
          data.status === 'failed'
            ? data.mandrillEmailData.from_email
            : data.emailData && data.emailData.sender
            ? data.emailData.sender
            : data.mandrillEmailData && data.mandrillEmailData.from_email
            ? data.mandrillEmailData.from_email
            : 'N/A'

        let subject =
          data.mandrillEmailData &&
          data.mandrillEmailData.subject &&
          data.mandrillEmailData.subject

        let opens =
          data.emailData &&
          data.emailData.opens &&
          Array.isArray(data.emailData.opens) &&
          data.emailData.opens.length > 0
            ? 1
            : 0
        let clicks =
          data.emailData &&
          data.emailData.clicks &&
          Array.isArray(data.emailData.clicks) &&
          data.emailData.clicks.length > 0
            ? 1
            : 0
        let url = (
          <a
            target="_blank"
            href={`https://${eventDomain}.illumeetxp.com/templateView/${data.registrationTemplateId}/?type=registrationTransactionalEmail`}
          >
            View
          </a>
        )

        let timeStamp = moment(data.updatedAt).format('MMM, Do YYYY, LT')

        let action =
          data.status === 'bounce' ||
          data.status === 'hard_bounce' ||
          data.status === 'soft_bounce' ||
          data.status === 'reject' ||
          data.status === 'failed' ||
          data.status === 'bounced' ||
          data.status === 'hard_bounced' ||
          data.status === 'soft_bounced' ||
          data.status === 'rejected' ? (
            <button
              className="themeBlueSmallButton"
              onClick={() => this.resendEmail(data._id)}
            >
              Resend Email
            </button>
          ) : (
            ''
          )

        let st = tip ? data.status + '(' + tip + ')' : data.status

        let email = data.email || 'N/A'
        let d = {
          //status: <p className={classAlert} data-tip={tip}>{data.status}  {statusTip}</p>,
          status: st,
          sender: sender,
          email: email,
          subject: subject,
          opens: opens,
          clicks: clicks,
          view_content: url,
          time_stamp: timeStamp,
          action: action,
        }
        dataArray.push(d)
      })

    const columns = [
      {
        name: 'Status',
        selector: 'status',
        sortable: false,
      },
      {
        name: 'Sender',
        selector: 'sender',
        sortable: true,
      },
      {
        name: 'Email',
        selector: 'email',
        sortable: true,
      },
      {
        name: 'Subject',
        selector: 'subject',
        sortable: true,
      },
      {
        name: 'Opens',
        selector: 'opens',
        sortable: true,
      },
      {
        name: 'Clicks',
        selector: 'clicks',
        sortable: true,
      },
      {
        name: 'View Content',
        selector: 'view_content',
        sortable: true,
      },
       {
        name: 'Time stamp',
        selector: 'time_stamp',
        sortable: true,
      },
      {
        name: 'Action',
        selector: 'action',
      },
    ]

    return (
      <DashboardLayout title={title} pageTitle={pageTitle}>
        <div className="dashboardMiddleArea">
          <div className="analyticsPage">
            <div className="analyticsTitle analyticsTwoFilter">
              <div className="analyticsSelect mr-3">
                <div className="form-group mr-2">
                  <div className="analyticsSelectBox">
                    <label for="filter_byEvent"> Choose Event </label>
                    <select
                      name="filter_byEvent"
                      className="outlineButton"
                      value={eventId}
                      onChange={this.handleChange}
                    >
                      {events &&
                        events.length > 0 &&
                        events.map((data) => (
                          <option value={data._id} key={data._id}>
                            {data.eventName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="form-group mr-2">
                  <div className="analyticsSelectBox">
                    <label for="filter_byEvent"> Choose Campaign </label>
                    <select
                      name="filter_byEventTemplate"
                      className="outlineButton"
                      onChange={this.handleChange}
                      value={templateId}
                    >
                      {templates &&
                        templates.length > 0 &&
                        templates.map((data) => (
                          <option value={data._id} key={data._id}>
                            {data.templateName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="analyticsSelect">
                <div className="form-group m-0">
                  <div className="analyticsSelectBox">
                    <label className="desktopLlable" for="filter_byEvent">
                      {' '}
                      &nbsp;{' '}
                    </label>
                    <select
                      name="filter_bydate"
                      className="outlineButton"
                      id=""
                      onChange={this.handleChange}
                    >
                      <option value="all">All</option>
                      <option value="last_90_days">Last 90 days</option>
                      <option value="last_60_days">Last 60 days</option>
                      <option value="last_30_days">Last 30 days</option>
                      <option value="last_7_days">Last 7 days</option>
                      <option value="today">Today</option>
                      <option value="custom">Custom</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {showCustom ? (
              <div className="analyticsDateRange_sec">
                <div className="form-group anaSelectDRange">
                  <div class="field-group inputField">
                    <label>Select Date Range </label>
                    <div class="formDate formIcon">
                      <DatePicker
                        selected={startDate}
                        onChange={this.onChangeDate}
                        selectsStart
                        startDate={startDate}
                        className="outlineButton mr-2"
                        endDate={endDate}
                      />
                      <DatePicker
                        selected={endDate}
                        onChange={this.onChangeEndDate}
                        selectsEnd
                        startDate={startDate}
                        className="outlineButton"
                        endDate={endDate}
                        minDate={startDate}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div class="field-group inputField">
                    <label> &nbsp; </label>
                    <button
                      className="themeBlueButton ml-3"
                      onClick={this.resetDate}
                    >
                      Reset Date Range
                    </button>
                  </div>
                </div>
              </div>
            ) : null}

            {/* <div className="analyticsSelectBox">
                          <button className="themeBlueButton" onClick={ this.customAnalyticsUpdate } >Refresh for registration analytics</button>
                        </div> */}

            {totalEmail && totalEmail > 0 ? (
              <>
                <div className="analyticsMails">
                  <div className="analyticsMailsInner">
                    <div className="analyticColl anaTotalMail">
                      <h3>{totalEmail}</h3>
                      <span>Sent Mail</span>
                    </div>
                    <div className="analyticColl anaTotalMail">
                      <h3>
                        {deliverRate}% ({totalSendEmail})
                      </h3>
                      <span>Delivered Email</span>
                    </div>
                    <div className="analyticColl anaTotalMail">
                      <h3>
                        {hardBouncedRate}% ({hardBounced})
                      </h3>
                      <span>Hard Bounced Email</span>
                    </div>
                    <div className="analyticColl anaTotalMail">
                      <h3>
                        {softBouncedRate}% ({softBounced})
                      </h3>
                      <span>Soft Bounced Email</span>
                    </div>
                    <div className="analyticColl anaTotalMail">
                      <h3>
                        {rejectRate}% ({reject})
                      </h3>
                      <span>Rejected Email</span>
                    </div>
                    <div className="analyticColl anaTotalMail">
                      <h3>
                        {openRate}% ({openEmail})
                      </h3>
                      <span>Opened Rates</span>
                    </div>
                    <div className="analyticColl anaTotalMail">
                      <h3>
                        {clickRate}% ({clickEmail})
                      </h3>
                      <span>Click Rates</span>
                    </div>
                    <div className="analyticColl anaTotalMail">
                      <h3>
                        {spamRate}% ({spam})
                      </h3>
                      <span>Spam Rates</span>
                    </div>
                    <div className="analyticColl anaTotalMail">
                      <h3>
                        {unsubRate}% ({unsub})
                      </h3>
                      <span>Unsub Rates</span>
                    </div>
                    <div className="analyticColl anaTotalMail">
                      <h3>
                        {failedRate}% ({failed})
                      </h3>
                      <span>Failed Rates</span>
                    </div>
                    <div className="analyticColl anaTotalMail">
                      <h3>
                        {queRate}% ({que})
                      </h3>
                      <span>Queued Rates</span>
                    </div>
                    <div className="analyticColl anaTotalMail">
                      <h3>
                        {deferralRate}% ({deferral})
                      </h3>
                      <span>Deferral Rates</span>
                    </div>
                  </div>
                  {/* <div className="mailAnalyticsColl">
                                <div className="analyticsProcess">
                                    <div className="analyticsProBar">
                                    <Progress completed={completed} color={'#403e44'} />
                                    </div>
                                    <h3>{totalEmail}</h3>
                                </div>
                                <span>Mail analytics</span>
                            </div> */}
                </div>
                <div className="analyticsGraphtableArea">
                  <div className="analyticsDimension graphBox">
                    {/* <img src={GraphDimension} alt="" />*/}
                    {sendArray &&
                      sendArray.length > 0 &&
                      Object.keys(this.props.events.reports.mandrill).length >
                        0 && (
                        <Chart
                          totalBounced={totalBounced}
                          sendArray={sendArray}
                          rejectArray={rejectArray}
                        />
                      )}
                  </div>
                  <div className="analyticsSesons graphBox">
                    {/*<img src={GraphSesons} alt="" />*/}

                    {openArray &&
                      openArray.length > 0 &&
                      Object.keys(this.props.events.reports.mandrill).length >
                        0 && (
                        <LineChart
                          openArray={openArray}
                          clickArray={clickArray}
                        />
                      )}
                  </div>
                </div>

                <div className="analyticsFilterLot">
                  <div className="eventControlSearch filterSearchBar mr-2">
                    <form onSubmit={this.handleSubmit}>
                      <div className="filterSerchSubmit SearchGrid">
                        <button type="submit">
                          <img src={SearchIconBar} alt="" />
                        </button>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search......"
                          name="searchText"
                          value={searchText}
                          onChange={this.handleChange}
                        />
                      </div>
                    </form>
                  </div>

                  <div className="analyticsSelect">
                    <div className="form-group mr-2">
                      <div className="analyticsSelectBox">
                        <label>Filter By: &nbsp; </label>
                        <select
                          name="filter_byState"
                          className="outlineButton"
                          id=""
                          onChange={this.handleChange}
                        >
                          <option value="all">All ({totalEmail})</option>
                          <option value="send">
                            Delivered ({totalSendEmail})
                          </option>
                          <option value="reject">Rejected ({reject})</option>
                          <option value="hard_bounce">
                            Hard Bounce ({hardBounced})
                          </option>
                          <option value="soft_bounce">
                            Soft Bounce ({softBounced})
                          </option>
                          <option value="spam">Spam ({spam})</option>
                          <option value="unsub">Unsubscribe ({unsub})</option>
                          <option value="failed">Failed ({failed})</option>
                          <option value="queued">Queued ({que})</option>
                          <option value="deferral">
                            Deferral ({deferral})
                          </option>
                        </select>
                      </div>
                    </div>
                    <Button
                      className="btn themeBlueButton exportBttnAna"
                      onClick={this.handleExport}
                    >
                      Export
                    </Button>
                  </div>
                </div>
                <div className="myEventTable eventListBoxes">
                  <div className="eventTableInner table-responsive analyticsListOnly">
                    {/* <table className="table">
                            
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Sender</th>
                                        <th>Email</th>
                                        <th>Subject</th>
                                        <th>Opens</th>
                                        <th>Clicks</th>
                                        <th className="justify-content-end">Action</th>
                                    </tr>
                                </thead>
                               
                                <tbody>
                                {emailData && emailData.length>0  && emailData.map((data)=>{

                                return     <tr>
                                        <td className={(data.status ==='bounce' || data.status ==='hard_bounce' || data.status ==='soft_bounce' || data.status ==='reject' || data.status ==='bounced'  || data.status ==='hard_bounced' || data.status ==='soft_bounced' || data.status ==='rejected')?'tdAlert':null} 
                                        data-tip={(data.status ==='bounce' || data.status ==='bounced'  || data.status ==='hard_bounce' || data.status ==='soft_bounce' || data.status ==='reject'  || data.status ==='hard_bounced' || data.status ==='soft_bounced' || data.status ==='rejected')?(data.emailData && data.emailData.bounce_description || null):null}>
                                        {data.status}{(data.status ==='bounce' || data.status ==='hard_bounce' || data.status ==='soft_bounce' || data.status ==='reject'  || data.status ==='bounced'  || data.status ==='hard_bounced' || data.status ==='soft_bounced' || data.status ==='rejected')?
                                        <ReactTooltip />:null}</td>
                                        <td>{data.status ==='failed'?data.mandrillEmailData.from_email: ((data.emailData && data.emailData.sender)? data.emailData.sender: (data.mandrillEmailData && data.mandrillEmailData.from_email?data.mandrillEmailData.from_email:'N/A') )}</td>
                                        <td>{data.email || 'N/A'}</td>
                                        {/* <td>{data.status ==='failed'?data.mandrillEmailData.subject: ((data.emailData && data.emailData.subject)? data.emailData.subject: (data.mandrillEmailData && data.mandrillEmailData.subject?data.mandrillEmailData.subject:'N/A') )}</td> */}
                    {/*  <td>{data.mandrillEmailData && data.mandrillEmailData.subject && data.mandrillEmailData.subject}</td>
                                        <td>{data.emailData &&  data.emailData.opens && Array.isArray(data.emailData.opens) && data.emailData.opens.length>0?1:0}</td>
                                        <td>{data.emailData &&  data.emailData.clicks && Array.isArray(data.emailData.clicks) && data.emailData.clicks.length>0?1:0}</td>
                                        <td className="justify-content-end">
                                            {(data.status ==='bounce' || data.status ==='hard_bounce' || data.status ==='soft_bounce' || data.status ==='reject' || data.status ==='failed' || data.status ==='bounced'  || data.status ==='hard_bounced' || data.status ==='soft_bounced' || data.status ==='rejected')?
                                        <button className="themeBlueSmallButton" onClick={()=>this.resendEmail(data._id)}>Resend Email</button>:''    
                                        }
                                        </td>
                                        </tr>
                                })  } 
                                                                    
                                </tbody>
                            </table> */}

                    {dataArray && dataArray.length > 0 && (
                      <DataTable columns={columns} data={dataArray} />
                    )}
                  </div>
                </div>
              </>
            ) : totalResponse ? (
              <div> No Analytics</div>
            ) : (
              <Loader />
            )}

            {analyticsData && analyticsData.length > 10 ? (
              <div className="tablePagination">
                <Pagination
                  activePage={page_no}
                  itemsCountPerPage={50}
                  totalItemsCount={totalEmailsCount}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange}
                  prevPageText="Prev"
                  nextPageText="Next"
                />
              </div>
            ) : null}
          </div>
        </div>

        <NotificationContainer />
        {loading ? <Loader /> : null}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  events: state.Events,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      reportDataRequest: Actions.emailRegistrationDataReportsRequest,
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch,
  )
export default connect(
  mapStateToProps,
  mapActionsToProps,
)(RegistrationAnalytics)
