import { Grid, Stack } from '@mui/material'
import {
  Box,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core'
import compactWidgetIcon from 'assets/images/icons/compactWidgetIcon.svg'
import widgetTopRight from 'assets/images/icons/widget-top-right.svg'
import widgetTopLeft from 'assets/images/icons/widget-top-left.svg'
import widgetCenterRight from 'assets/images/icons/widget-middle-right.svg'
import widgetCenterLeft from 'assets/images/icons/widget-middle-left.svg'
import widgetBottomLeft from 'assets/images/icons/widget-bottom-left.svg'
import widgetBottomRight from 'assets/images/icons/widget-bottom-right.svg'

import TawkToChatIcon from 'assets/images/icons/TawkToChatIcon.svg'
import { useState, useEffect } from 'react'
import ColorPickerTile from 'Components/ColorPicker/ColorPickerTile'
import { useDispatch, useSelector } from 'react-redux'
import { Actions } from 'Redux/Actions'
import { WidgetState } from 'Redux/Reducers/integrations/appsConfigModal.Reducer'
const widgetPositions: { position: string; image: string }[] = [
  {
    position: 'tr',
    image: widgetTopRight,
  },
  {
    position: 'tl',
    image: widgetTopLeft,
  },
  {
    position: 'cr',
    image: widgetCenterRight,
  },
  {
    position: 'cl',
    image: widgetCenterLeft,
  },
  {
    position: 'br',
    image: widgetBottomRight,
  },
  {
    position: 'bl',
    image: widgetBottomLeft,
  },
]
const WidgetAppearance: React.FC<{ onClose: () => any }> = ({ onClose }) => {
  const configState = useSelector((store: any) => store.AppConfigModal)
  const [state, setState] = useState<WidgetState>({ ...configState })
  const [isFormValid, setIsFormValid] = useState(true)
  const dispatch = useDispatch()
  useEffect(() => {
    setState((s: any) => {
      return { ...s, widget: configState.widget }
    })
  }, [configState.widget])

  const save = () => {
    const { isValid } = checkFromForValidation()
    if (!isValid) {
      setIsFormValid(false)
      return
    }
    dispatch(Actions.tawkToUpdateWidget(state.widget))
  }
  const checkFromForValidation = (field: string = '') => {
    let isValid = true
    let errorMessage = ''
    if (
      !state.widget.maximized.desktop.width ||
      state.widget.maximized.desktop.width < 280
    ) {
      isValid = false
      if (field === 'width') {
        errorMessage = 'Please enter a width ≥ 280.'
      }
    }
    if (
      !state.widget.maximized.desktop.height ||
      state.widget.maximized.desktop.height < 330
    ) {
      isValid = false
      if (field === 'height') {
        errorMessage = 'Please enter a height ≥ 330.'
      }
    }
    let errorBlock = <></>
    if (errorMessage) {
      errorBlock = <span className="error_mesage required">{errorMessage}</span>
    }
    return { isValid, errorBlock }
  }
  const onInputChange = (e: any, name: string, value: string = '') => {
    let widget = { ...state.widget }
    if (name === 'maximized.desktop.width') {
      widget.maximized.desktop.width = Number(e.target.value)
    } else if (name === 'maximized.desktop.height') {
      widget.maximized.desktop.height = Number(e.target.value)
    } else if (name === 'minimized.desktop.type') {
      if (e.target.checked) {
        if (value === 'round') {
          widget.minimized.desktop = { type: value }
        } else if (value === 'rectangle') {
          const psoin = widget.visibility.desktop.position 
          if (psoin) {
            if (psoin === 'tr' || psoin === 'tl') {
              widget.minimized.desktop.borderRadiusBottom = 15
              widget.minimized.desktop.borderRadiusTop = 0
            } else if (psoin === 'br' || psoin === 'bl') {
              widget.minimized.desktop.borderRadiusTop = 15
              widget.minimized.desktop.borderRadiusBottom = 0
            }
            //  else {
            //   widget.minimized.desktop.borderRadiusTop = 15
            //   widget.minimized.desktop.borderRadiusBottom = 15
            // }
            widget.minimized.desktop.type = 'rectangle'
            widget.minimized.desktop.height = 40
            widget.minimized.desktop.width = 200
          } else {
            widget.minimized.desktop = {
              type: 'rectangle',
              height: 40,
              width: 200,
              borderRadiusTop: 15,
              borderRadiusBottom: 0,
            }
          }
        }
      } 
    } else if (name === 'minimized.mobile.type') {
      if (e.target.checked) {
        widget.minimized.mobile.type = value
      }
    } else if (name === 'visibility.desktop.position') {
      if (value) {
        widget.visibility.desktop.position = value
        if (widget.minimized.desktop.type === 'rectangle') {
          if (value === 'tr' || value === 'tl') {
            widget.minimized.desktop.borderRadiusBottom = 15
            widget.minimized.desktop.borderRadiusTop = 0
          } else if (value === 'br' || value === 'bl') {
            widget.minimized.desktop.borderRadiusTop = 15
            widget.minimized.desktop.borderRadiusBottom = 0
          }
        } else {
          widget.minimized.desktop = { type: 'round' }
        }
      }
    } else if (name === 'visibility.mobile.position') {
      if (value) {
        widget.visibility.mobile.position = value
      }
    }

    setState({
      ...state,
      widget: widget,
    })
  }
  return (
    <Grid container spacing={{ xs: 2, md: 3, xl: 5 }}>
      {/* Desktop Widget */}
      <Grid item xs={12} md={12}>
        <Stack spacing={3}>
          <Box component="div" className="inputField">
            <label>
              Desktop Widget <span className="required">*</span>
            </label>
          </Box>

          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue="round"
            value={state.widget.minimized.desktop.type}
          >
            <Grid container rowSpacing={{ xs: 2, xl: 2.5 }} columnSpacing={5}>
              <Grid item xs={12} md={6}>
                <Box component="div" className="inputField">
                  <FormControlLabel
                    onChange={(e) =>
                      onInputChange(e, 'minimized.desktop.type', 'round')
                    }
                    value="round"
                    control={<Radio color="primary" />}
                    label={<img alt="" src={compactWidgetIcon} />}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box component="div" className="inputField">
                  <FormControlLabel
                    onChange={(e) =>
                      onInputChange(e, 'minimized.desktop.type', 'rectangle')
                    }
                    value="rectangle"
                    control={<Radio color="primary" />}
                    label={<Box className="desktop-chat">Online</Box>}
                  />
                </Box>
              </Grid>
            </Grid>
          </RadioGroup>
          <Box>
            <Grid container rowSpacing={{ xs: 2, xl: 2.5 }} columnSpacing={5}>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  rowSpacing={{ xs: 1.5, xl: 1.5 }}
                  columnSpacing={1.5}
                >
                  <Grid item xs={12} md={6}>
                    <Box component="div" className="inputField">
                      <label>
                        Width <span className="required">*</span>
                      </label>
                      <input
                        type="number"
                        min={280}
                        value={state.widget.maximized.desktop.width}
                        onChange={(e) =>
                          onInputChange(e, 'maximized.desktop.width')
                        }
                        className="form-control small"
                        placeholder=""
                      />
                      {!isFormValid &&
                        checkFromForValidation('width').errorBlock}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box component="div" className="inputField">
                      <label>
                        Height <span className="required">*</span>
                      </label>
                      <input
                        value={state.widget.maximized.desktop.height}
                        min={330}
                        onChange={(e) =>
                          onInputChange(e, 'maximized.desktop.height')
                        }
                        type="number"
                        className="form-control small"
                        placeholder=""
                      />
                      {!isFormValid &&
                        checkFromForValidation('height').errorBlock}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box component="div" className="inputField">
                  <label>
                    Desktop Widget Position <span className="required">*</span>
                  </label>
                  <Stack direction="row" spacing={1}>
                    {widgetPositions.map((wp, i) => (
                      <Box
                        key={`desk_wid_` + i}
                        component={Button}
                        onClick={(e) =>
                          onInputChange(
                            e,
                            'visibility.desktop.position',
                            wp.position,
                          )
                        }
                        className={`widget-position ${
                          state.widget.visibility.desktop.position ===
                          wp.position
                            ? 'active'
                            : ''
                        }`}
                      >
                        <img alt="" src={wp.image} />
                      </Box>
                    ))}
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Grid>

      {/* Mobile Widget */}
      <Grid item xs={12} md={12}>
        <Stack spacing={3}>
          <Box component="div" className="inputField">
            <label>
              Mobile Widget <span className="required">*</span>
            </label>
          </Box>

          <RadioGroup
            value={state.widget.minimized.mobile.type}
            row
            aria-label="position"
            name="position"
            defaultValue=""
          >
            <Grid container rowSpacing={{ xs: 2, xl: 2.5 }} columnSpacing={5}>
              <Grid item xs={12} md={6}>
                <Box component="div" className="inputField">
                  <FormControlLabel
                    onChange={(e) =>
                      onInputChange(e, 'minimized.mobile.type', 'round')
                    }
                    value="round"
                    control={<Radio color="primary" />}
                    label={<img alt="" src={compactWidgetIcon} />}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box component="div" className="inputField">
                  <FormControlLabel
                    value="rectangle"
                    onChange={(e) =>
                      onInputChange(e, 'minimized.mobile.type', 'rectangle')
                    }
                    control={<Radio color="primary" />}
                    label={
                      <Box className="mobile-chat">
                        <img alt="" src={TawkToChatIcon} />
                        Chat
                      </Box>
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </RadioGroup>
          <Box>
            <Grid container rowSpacing={{ xs: 2, xl: 2.5 }} columnSpacing={5}>
              <Grid item xs={12} md={6}>
                <Box component="div" className="inputField">
                  <label>
                    Mobile Widget Position <span className="required">*</span>
                  </label>
                  <Stack direction="row" spacing={1}>
                    {widgetPositions.map((wp, i) => (
                      <Box
                        key={`mobil_wid_` + i}
                        component={Button}
                        onClick={(e) =>
                          onInputChange(
                            e,
                            'visibility.mobile.position',
                            wp.position,
                          )
                        }
                        className={`widget-position ${
                          state.widget.visibility.mobile.position ===
                          wp.position
                            ? 'active'
                            : ''
                        }`}
                      >
                        <img alt="" src={wp.image} />
                      </Box>
                    ))}
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Grid>

      {/* Widget Color */}
      <Grid item xs={12} md={12}>
        <Box component="div" className="inputField">
          <label>
            Widget Color <span className="required">*</span>
          </label>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Stack
              direction="row"
              style={{ position: 'relative' }}
              alignItems="center"
              className=""
            >
              <ColorPickerTile
                color={state.widget.theme.header.background}
                onChange={(c) => {
                  setState({
                    ...state,
                    widget: {
                      ...state.widget,
                      theme: {
                        ...state.widget.theme,
                        header: { ...state.widget.theme.header, background: c },
                      },
                    },
                  })
                }}
              />
              <Box ml={1.25} mt={-0.5}>
                {' '}
                Header
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              direction="row"
              style={{ position: 'relative' }}
              alignItems="center"
              className=""
            >
              <ColorPickerTile
                color={state.widget.theme.header.text}
                onChange={(c) => {
                  setState({
                    ...state,
                    widget: {
                      ...state.widget,
                      theme: {
                        ...state.widget.theme,
                        header: { ...state.widget.theme.header, text: c },
                      },
                    },
                  })
                }}
              />
              <Box ml={1.25} mt={-0.5}>
                {' '}
                Header Text
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              direction="row"
              style={{ position: 'relative' }}
              alignItems="center"
              className=""
            >
              <ColorPickerTile
                color={state.widget.theme.agent.messageBackground}
                onChange={(c) => {
                  setState({
                    ...state,
                    widget: {
                      ...state.widget,
                      theme: {
                        ...state.widget.theme,
                        agent: {
                          ...state.widget.theme.agent,
                          messageBackground: c,
                        },
                      },
                    },
                  })
                }}
              />
              <Box ml={1.25} mt={-0.5}>
                {' '}
                Agent Message
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              direction="row"
              style={{ position: 'relative' }}
              alignItems="center"
              className=""
            >
              <ColorPickerTile
                color={state.widget.theme.visitor.messageText}
                onChange={(c) => {
                  setState({
                    ...state,
                    widget: {
                      ...state.widget,
                      theme: {
                        ...state.widget.theme,
                        visitor: {
                          ...state.widget.theme.visitor,
                          messageText: c,
                        },
                      },
                    },
                  })
                }}
              />
              <Box ml={1.25} mt={-0.5}>
                {' '}
                Visitor Text
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              direction="row"
              style={{ position: 'relative' }}
              alignItems="center"
              className=""
            >
              <ColorPickerTile
                color={state.widget.theme.visitor.messageBackground}
                onChange={(c) => {
                  setState({
                    ...state,
                    widget: {
                      ...state.widget,
                      theme: {
                        ...state.widget.theme,
                        visitor: {
                          ...state.widget.theme.visitor,
                          messageBackground: c,
                        },
                      },
                    },
                  })
                }}
              />
              <Box ml={1.25} mt={-0.5}>
                {' '}
                Visitor Message
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              direction="row"
              style={{ position: 'relative' }}
              alignItems="center"
              className=""
            >
              <ColorPickerTile
                color={state.widget.theme.agent.messageText}
                onChange={(c) => {
                  setState({
                    ...state,
                    widget: {
                      ...state.widget,
                      theme: {
                        ...state.widget.theme,
                        agent: { ...state.widget.theme.agent, messageText: c },
                      },
                    },
                  })
                }}
              />
              <Box ml={1.25} mt={-0.5}>
                {' '}
                Agent Text
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      {/* Buttons */}
      <Grid item xs={12}>
        <Grid container justifyContent="end">
          <Stack spacing={1} direction="row">
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={save}
              variant="contained"
              color="primary"
              disableElevation
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default WidgetAppearance
