import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'
interface CName {
  _id?: string
  name: string
  value: string
  type: string
}
interface SSLcertificate {
  _id?: string
  id: string
  status: string
  cname: CName
}
interface Distribution {
  _id?: string
  id: string
  status: string
  domain: string
  enabled: boolean
}
interface VanityDomain {
  _id?: string
  isActive: boolean
  sslCertificate: SSLcertificate
  cloudFrontDistribution: Distribution
  domainName: string
}
interface vanityState {
  actionType: string
  message: string
  loading: boolean
  success: boolean
  vanityDomain: VanityDomain
}
const INITIAL_STATE: vanityState = {
  actionType: '',
  message: '',
  loading: false,
  success: false,
  vanityDomain: {
    _id: '',
    isActive: false,
    sslCertificate: {
      _id: '',
      id: '',
      status: '',
      cname: {
        _id: '',
        name: '',
        value: '',
        type: '',
      },
    },
    cloudFrontDistribution: {
      _id: '',
      id: '',
      domain: '',
      status: '',
      enabled: false,
    },
    domainName: '',
  },
}

const createVanityDomainRequest = (
  state: vanityState = INITIAL_STATE,
): vanityState => {
  return {
    ...state,
    success: false,
    actionType: '',
    loading: true,
    message: '',
  }
}
const createVanityDomainSuccess = (
  state: vanityState = INITIAL_STATE,
  action: any,
): vanityState => { 
  return {
    ...state,
    actionType: action.type,
    vanityDomain: action.vanityDomain,
    success: true,
    loading: false,
  }
}

const createVanityDomainFailure = (
  state: vanityState = INITIAL_STATE,
  action: any,
): vanityState => {
  return {
    ...state,
    success: false,
    loading: false,
    actionType: '',
    message: action.error,
  }
}

const removeVanityDomainRequest = (
  state: vanityState = INITIAL_STATE,
): vanityState => {
  return {
    ...state,
    success: false,
    actionType: '',
    loading: true,
    message: '',
  }
}
const removeVanityDomainSuccess = (
  state: vanityState = INITIAL_STATE,
  action: any,
): vanityState => { 
  return {
    ...state,
    actionType: action.type,
    vanityDomain: action.vanityDomain,
    success: true,
    loading: false,
  }
}

const removeVanityDomainFailure = (
  state: vanityState = INITIAL_STATE,
  action: any,
): vanityState => {
  return {
    ...state,
    success: false,
    loading: false,
    actionType: '',
    message: action.error,
  }
}

const HANDLERS = {
  [Constants.CREATE_VANITY_DOMAIN_REQUEST]: createVanityDomainRequest,
  [Constants.CREATE_VANITY_DOMAIN_SUCCESS]: createVanityDomainSuccess,
  [Constants.CREATE_VANITY_DOMAIN_FAILURE]: createVanityDomainFailure,

  [Constants.REMOVE_VANITY_DOMAIN_REQUEST]: removeVanityDomainRequest,
  [Constants.REMOVE_VANITY_DOMAIN_SUCCESS]: removeVanityDomainSuccess,
  [Constants.REMOVE_VANITY_DOMAIN_FAILURE]: removeVanityDomainFailure,
}

export default createReducer(INITIAL_STATE, HANDLERS)
