import { createReducer } from 'reduxsauce';
import { Constants } from '../Actions';

const INITIAL_STATE = {
    success: false,
    error: false,
    message: null,
    createFolderSuccess: false,
    addImagesSuccess: false,
    folderListSuccess:false,
    renameFolderSuccess:false,
    deleteFolderSuccess:false,
    addDocumentSuccess:false,
    alreadyExist:false,
    breadCrumbs: "",
    loading:false,
    uploadVideoSuccess:false,
    url:"",
    downloadFileSuccess:false
};

const createFolderRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error:false,
        createFolderSuccess:false,
        message: null,
        alreadyExist:false,
        addImagesSuccess: false,
        loading:true,
        downloadFileSuccess:false
    };
};
const createFolderError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        alreadyExist:false,
        createFolderSuccess: false,
        addImagesSuccess: false,
        loading:false,
        downloadFileSuccess:false
    };
};
const createFolderSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        createFolderSuccess: true,
        message: action.message,
        alreadyExist:action.alreadyExist,
        addImagesSuccess: false,
        loading:false,
        downloadFileSuccess:false
    };
};
const addImagesRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        addImagesSuccess: false,
        createFolderSuccess: false,
        loading:false,
        downloadFileSuccess:false
    };
};
const addImagesError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        addImagesSuccess: false,
        createFolderSuccess: false,
        loading:false,
        downloadFileSuccess:false
    };
};

const addImagesSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        addImagesSuccess: true,
        createFolderSuccess: false,
        loading:false,
        downloadFileSuccess:false
    };
};

const getFolderListRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        folderListSuccess:false,
        alreadyExist:false,
        breadCrumbs: "",
        loading:true,
        downloadFileSuccess:false,
        addImagesSuccess:false,
        addDocumentSuccess:false,
        uploadVideoSuccess:false
    };
};
const getFolderListError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        folderListSuccess:false,
        breadCrumbs: "",
        loading:false,
        downloadFileSuccess:false,
        addDocumentSuccess:false,
        uploadVideoSuccess:false,
        addImagesSuccess:false
    };
};

const getFolderListSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        foldersList: action.foldersList,
        filesList:action.filesList,
        folderListSuccess:true,
        breadCrumbs: action.breadCrumbs,
        filesCount:action.filesCount,
        foldersCount:action.foldersCount,
        loading:false,
        downloadFileSuccess:false,
        addDocumentSuccess:false,
        addImagesSuccess:false,
        uploadVideoSuccess:false
    };
};

const renameFolderRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        renameFolderSuccess:false,
        loading:true
    };
};
const renameFolderError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        renameFolderSuccess:false,
        loading:false
    };
};
const renameFolderSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        renameFolderSuccess:true,
        loading:false
    };
};
const deleteFolderRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        deleteFolderSuccess:false,
        loading:true
    };
};
const deleteFolderError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        deleteFolderSuccess:false,
        loading:false
    };
};
const deleteFolderSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        deleteFolderSuccess:true,
        loading:false
    };
};

const addDocumentRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        addDocumentSuccess:false,
        loading:false
    };
};
const addDocumentError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        addDocumentSuccess:false,
        loading:false
    };
};
const addDocumentSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        addDocumentSuccess:true,
        loading:false
    };
};
const uploadVideoRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        uploadVideoSuccess:false,
        loading:true
    };
};
const uploadVideoError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        uploadVideoSuccess:false,
        loading:false
    };
};
const uploadVideoSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        uploadVideoSuccess:true,
        loading:false
    };
};

const downloadFileRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        downloadFileSuccess:false,
        loading:true
    };
};
const downloadFileError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        downloadFileSuccess:false,
        loading:false
    };
};
const downloadFileSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        downloadFileSuccess:true,
        url:action.url,
        loading:false
    };
};




const HANDLERS = {
    [Constants.CREATE_FOLDER_SUCCESS]: createFolderSuccess,
    [Constants.CREATE_FOLDER_ERROR]: createFolderError,
    [Constants.CREATE_FOLDER_REQUEST]: createFolderRequest,
    [Constants.ADD_IMAGES_REQUEST]: addImagesRequest,
    [Constants.ADD_IMAGES_ERROR]: addImagesError,
    [Constants.ADD_IMAGES_SUCCESS]: addImagesSuccess,
    [Constants.GET_FOLDER_LIST_SUCCESS]: getFolderListSuccess,
    [Constants.GET_FOLDER_LIST_ERROR]: getFolderListError,
    [Constants.GET_FOLDER_LIST_REQUEST]: getFolderListRequest,
    [Constants.RENAME_FOLDER_REQUEST]: renameFolderRequest,
    [Constants.RENAME_FOLDER_ERROR]: renameFolderError,
    [Constants.RENAME_FOLDER_SUCCESS]: renameFolderSuccess,
    [Constants.DELETE_FOLDER_REQUEST]: deleteFolderRequest,
    [Constants.DELETE_FOLDER_ERROR]: deleteFolderError,
    [Constants.DELETE_FOLDER_SUCCESS]: deleteFolderSuccess,
    [Constants.ADD_DOCUMENT_REQUEST]: addDocumentRequest,
    [Constants.ADD_DOCUMENT_ERROR]: addDocumentError,
    [Constants.ADD_DOCUMENT_SUCCESS]: addDocumentSuccess,
    [Constants.UPLOAD_VIDEO_REQUEST]: uploadVideoRequest,
    [Constants.UPLOAD_VIDEO_ERROR]: uploadVideoError,
    [Constants.UPLOAD_VIDEO_SUCCESS]: uploadVideoSuccess,
    [Constants.DOWNLOAD_FILE_REQUEST]: downloadFileRequest,
    [Constants.DOWNLOAD_FILE_ERROR]: downloadFileError,
    [Constants.DOWNLOAD_FILE_SUCCESS]: downloadFileSuccess,

};

export default createReducer(INITIAL_STATE, HANDLERS);