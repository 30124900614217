import BaseAPI from '../baseAPI';

class OpportunityCommonAPI extends BaseAPI {
    createCommentAndAttachment = async (data: Object) => {
        return this.API('opportunityManagement/commentsAndAttachments', {
            method: 'POST',
            data
        });
    };   

    uploadAttachmentToCloud = async (data: Object) => {
        return this.API('opportunityManagement/uploadAttachmentToCloud', {
            method: 'POST',
            data
        });
    };   
    
    updateCommentAndAttachment = async (data: Object) => {
        return this.API('opportunityManagement/commentsAndAttachments', {
            method: 'PATCH',
            data
        });
    };

    deleteCommentAndAttachment = async (data: Object) => {
        return this.API('opportunityManagement/commentsAndAttachments', {
            method: 'DELETE',
            data
        });
    };
    
    fetchCommentAndAttachment = async (data: {id: string, type: string, searchText: string}) => {
        return this.API(`opportunityManagement/commentsAndAttachments?id=${data.id}&type=${data.type}&searchText=${data.searchText}`, {
            method: 'GET'
        });
    };

    removeAttachment = async (data: Object) => {
        return this.API('opportunityManagement/commentsAndAttachments/removeAttachment', {
            method: 'PATCH',
            data
        });
    };
}

export default OpportunityCommonAPI;