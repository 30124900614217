import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
  success: false,
  token: null,
  error: false,
  message: null,
  user: null,
  accountSuccess: false,
  myProfileRankSuccess:false,
  topRank:[],
  myRank:[],
  getAccessCodeSuccess:false,
  assignAccessCodeProfileSuccess:false,
  accessCode:[],
  remainingHours:'',
  getCustomFieldsProfileSuccess:false
}

const profileSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: true,
    token: action.token,
    user: action.user,
    message: action.message,
  }
}

const profileError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const profileRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    error: false,
    accountSuccess: false,
    message: null,
  }
}

const deleteAccountSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, accountSuccess: true, message: action.message }
}

const deleteAccountError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const deleteAccountRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    accountSuccess: false,
    error: false,
    message: null,
  }
}

const emailVerifySuccess = (state = INITIAL_STATE, action) => {
  return { ...state, success: true, message: action.message }
}

const emailVerifyError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const emailVerifyRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    error: false,
    accountSuccess: false,
    message: null,
  }
}

const emailVerifiedSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, success: true, message: action.message }
}

const emailVerifiedError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const emailVerifiedRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    error: false,
    accountSuccess: false,
    message: null,
  }
}

const myProfileRankRequest = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      myProfileRankSuccess: false,
      error: false,
      message: null,
  }
}

const myProfileRankSuccess = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      error: false,
      message: action.message,
      myProfileRankSuccess: true,
      topRank:action.topRank,
      myRank:action.myRank,
  }
}

const myProfileRankError = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      myProfileRankSuccess: false,
      error: true,
      message: action.error,
  }
}

const getAccessCodeRequest = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      getAccessCodeSuccess: false,
      error: false,
      message: null,
  }
}

const getAccessCodeSuccess = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      error: false,
      message: action.message,
      getAccessCodeSuccess: true,
      accessCode:action.accessCode,
      remainingHours:action.remainingHours
  }
}

const getAccessCodeError = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      getAccessCodeSuccess: false,
      error: true,
      message: action.error,
  }
}


const assignAccessCodeProfileRequest = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      assignAccessCodeProfileSuccess: false,
      error: false,
      message: null,
  }
}

const assignAccessCodeProfileSuccess = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      error: false,
      message: action.message,
      assignAccessCodeProfileSuccess: true,
  }
}

const assignAccessCodeProfileError = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      assignAccessCodeProfileSuccess: false,
      error: true,
      message: action.error,
  }
}

const getCustomFieldsProfileRequest = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      getCustomFieldsProfileSuccess: false,
      error: false,
      message: null,
  }
}

const getCustomFieldsProfileSuccess = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      error: false,
      message: action.message,
      getCustomFieldsProfileSuccess: true,
      customFields:action.customFields,
      eventData:action.eventData,
      pagePath:action.pagePath,
      challengeData:action.challengeData
  }
}

const getCustomFieldsProfileError = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      getAccessCodeSuccess: false,
      error: true,
      message: action.error,
  }
}


const HANDLERS = {
  [Constants.GET_CUSTOM_FIELDS_PROFILE_REQUEST]: getCustomFieldsProfileRequest,
  [Constants.GET_CUSTOM_FIELDS_PROFILE_SUCCESS]: getCustomFieldsProfileSuccess,
  [Constants.GET_CUSTOM_FIELDS_PROFILE_ERROR]: getCustomFieldsProfileError,
  [Constants.ASSIGN_ACCESS_CODE_PROFILE_REQUEST]: assignAccessCodeProfileRequest,
  [Constants.ASSIGN_ACCESS_CODE_PROFILE_SUCCESS]: assignAccessCodeProfileSuccess,
  [Constants.ASSIGN_ACCESS_CODE_PROFILE_ERROR]: assignAccessCodeProfileError,
  [Constants.GET_ACCESS_CODE_REQUEST]: getAccessCodeRequest,
  [Constants.GET_ACCESS_CODE_SUCCESS]: getAccessCodeSuccess,
  [Constants.GET_ACCESS_CODE_ERROR]: getAccessCodeError,
  [Constants.MY_PROFILE_RANK_SUCCESS]: myProfileRankSuccess,
  [Constants.MY_PROFILE_RANK_ERROR]: myProfileRankError,
  [Constants.MY_PROFILE_RANK_REQUEST]: myProfileRankRequest,
  [Constants.PROFILE_SUCCESS]: profileSuccess,
  [Constants.PROFILE_ERROR]: profileError,
  [Constants.PROFILE_REQUEST]: profileRequest,
  [Constants.DELETE_ACCOUNT_SUCCESS]: deleteAccountSuccess,
  [Constants.DELETE_ACCOUNT_ERROR]: deleteAccountError,
  [Constants.DELETE_ACCOUNT_REQUEST]: deleteAccountRequest,
  [Constants.EMAIL_VERIFY_SUCCESS]: emailVerifySuccess,
  [Constants.EMAIL_VERIFY_ERROR]: emailVerifyError,
  [Constants.EMAIL_VERIFY_REQUEST]: emailVerifyRequest,

  [Constants.EMAIL_VERIFIED_SUCCESS]: emailVerifiedSuccess,
  [Constants.EMAIL_VERIFIED_ERROR]: emailVerifiedError,
  [Constants.EMAIL_VERIFIED_REQUEST]: emailVerifiedRequest,
}

export default createReducer(INITIAL_STATE, HANDLERS)
