import Grid from '@mui/material/Grid';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import closeIcon from '../../../../src/assets/images/icons/close.svg';
import editIcon from '../../../../src/assets/images/icons/edit.svg';
import { Contact } from 'Redux/Reducers/opportunity/contacts.reducer';
import { CustomField } from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { separateByComma } from 'helpers/common';
import { Account, ContactCf, Project } from 'Redux/Reducers/opportunity/accounts.reducer';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import { useState } from 'react';
import moment from 'moment';

type Props = {
  selected: Contact | Account;
  addNew: () => void;
  clearSelectedContactAndCloseViewContactDetailBox: () => void;
  usedFor: 'account' | 'contact';
  customFields: CustomField[];
  parentItem: 'account' | 'opportunity';
};
function ViewContactDetails({
  selected,
  addNew,
  usedFor,
  clearSelectedContactAndCloseViewContactDetailBox,
  customFields,
  parentItem
}: Props) {
  const [openUnlinkConfirmationPop, setOpenUnlinkConfirmationPop] = useState<boolean>(false);
  const customFieldValue = (cf: CustomField) => {
    const customFieldsWithProperValues = selected?.customFieldsWithProperValues
    if (
      Array.isArray(customFieldsWithProperValues?.[cf._id as string]) &&
      (customFieldsWithProperValues?.[cf._id as string] as string[])?.length
    ) {
      return separateByComma(customFieldsWithProperValues?.[cf._id as string]);
    } else if (cf.fieldData.fieldType === 'contact') {
      let selectedData = customFieldsWithProperValues?.[
        cf._id as string
      ] as ContactCf;
      let fName = selectedData?.firstName || '';
      let mName = selectedData?.middleName || '';
      let lName = selectedData?.lastName || '';

      return fName ? `${fName} ${mName} ${lName}` : '';
    } else if (cf.fieldData.fieldType === 'boolean') {
      return customFieldsWithProperValues?.[cf._id as string]?.toString() === "true" ? "True" : "False";
    } else {
      return cf.fieldData.fieldType === 'date'
        && customFieldsWithProperValues?.[cf._id as string]
        ? moment(customFieldsWithProperValues?.[cf._id as string] as string).format(
          'YYYY-MM-DD',
        )
        : customFieldsWithProperValues?.[cf._id as string];
    }
  };

  const handleOpenUnlinkSelectedConfPopup = () => {
    setOpenUnlinkConfirmationPop(true);
  };

  const closeUnlinkConfirmationPop = () => {
    setOpenUnlinkConfirmationPop(false);
  };

  const showRestOfProjects = (projects: Project[]) => {
    let projectsLength = projects.length;
    return projects.slice(1, projectsLength);
  };
  return (
    <Box className="account-details contacts-details" mt={2}>
      <Stack
        mb={2}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box className="small-subtitle bold primary-text">
          {usedFor === 'contact'
            ? 'Contact'
            : usedFor === 'account'
              ? 'Account'
              : ''}
        </Box>
        <Stack direction={'row'} spacing={1}>
          <IconButton size="small" onClick={addNew}>
            <img src={editIcon} width="16" />
          </IconButton>
          <IconButton size="small" onClick={handleOpenUnlinkSelectedConfPopup}>
            <img src={closeIcon} width="16" />
          </IconButton>
        </Stack>
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={4}>
          <Grid container>
            <Grid item xs={6} md={4} xl={3}>
              <Box className="small-subtitle bold primary-text">Name:</Box>
            </Grid>
            <Grid item xs={6} md={8} xl={9} className="">
              <Box className="small-subtitle primary-text ">
                {usedFor === 'account'
                  ? (selected as Account).name
                  : usedFor === 'contact'
                    ? ((selected as Contact)?.firstName
                      ? (selected as Contact)?.firstName
                      : '') +
                    ' ' +
                    ((selected as Contact)?.middleName
                      ? (selected as Contact)?.middleName
                      : '') +
                    ' ' +
                    ((selected as Contact)?.lastName
                      ? (selected as Contact)?.lastName
                      : '')
                    : '-'}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {usedFor === 'contact' && (
          <>
          <Grid item xs={12} sm={6} lg={4}>
            <Grid container>
              <Grid item xs={6} md={4} xl={3}>
                <Box className="small-subtitle bold primary-text">Email:</Box>
              </Grid>
              <Grid item xs={6} md={8} xl={9} >
                {/* <Tooltip placement='bottom' arrow title={((selected as Contact)?.email)}>
                <Box className="small-subtitle primary-text long-text">
                {((selected as Contact)?.email) ||
                    '-'}
                    </Box>
                    </Tooltip> */}

                {(selected as Contact)?.email ? (
                  // <Tooltip
                  //   placement="bottom-start"
                  //   arrow
                  //   title={(selected as Contact)?.email}
                  // >
                    <Box className="small-subtitle primary-text link">
                      {(selected as Contact)?.email}
                    </Box>
                  // </Tooltip>
                ) : (
                  <Box className="small-subtitle primary-text">-</Box>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
              <Grid container>
                <Grid item xs={6} md={4} xl={3}>
                  <Box className="small-subtitle bold primary-text">
                    Contact Groups:
                  </Box>
                </Grid>
                <Grid item xs>
                  <Box className="small-subtitle primary-text long-text">
                    {Array.isArray((selected as Contact)?.audience) && separateByComma((selected as Contact)?.audience?.map(el => el.audienceName))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>

          
        )}

        {usedFor === 'account' && (
          <>
            <Grid item xs={12} sm={6} lg={4}>
              <Grid container>
                <Grid item xs={6} md={4} xl={3}>
                  <Box className="small-subtitle bold primary-text">Source:</Box>
                </Grid>
                <Grid item xs={6} md={8} xl={9}>
                  <Box className="small-subtitle primary-text">
                    {(selected as Account)?.source}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Grid container>
                <Grid item xs={6} md={4} lg={5} xl={3}>
                  <Box className="small-subtitle bold primary-text">
                    Projects:
                  </Box>
                </Grid>
                <Grid item xs={6} md={8} lg={7} xl={9}>
                  <Stack direction="row" alignItems={'center'} spacing={1}>
                    <span className="long-text" style={{ maxWidth: '80%' }}>
                      {' '}
                      {(selected as Account)?.projects &&
                        (selected as Account)?.projects?.length > 0
                        ? (selected as Account)?.projects[0]?.eventName
                        : ''}
                    </span>
                    {(selected as Account)?.projects &&
                      (selected as Account)?.projects?.length > 1 && (
                        <div className="detail-popup">
                          <span className="count">
                            +{(selected as Account)?.projects.length - 1}
                          </span>
                          <div className="info">
                            {showRestOfProjects(
                              (selected as Account)?.projects,
                            ).map((project: Project, i: number) => {
                              return (
                                <div key={i}>
                                  <p>{project.eventName}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </>)
        }

        {Array.isArray(customFields) &&
          customFields.length > 0 &&
          customFields.map((cf) => {
            return (
              <Grid item xs={12} sm={6} lg={4} key={cf._id}>
                <Grid container>
                  <Grid item xs={6} md={4} xl={3}>
                    <Box className="small-subtitle bold primary-text">
                      {cf.fieldData.fieldName}:
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={8} xl={9}>
                    {/* <CustomHTMLTooltip
                      interactive
                      title={
                        <Box className="upgrade-tooltip long-description">
                          <p>
                            {customFieldValue(cf)}
                          </p>
                        </Box>}
                      placement='bottom-start'
                      arrow={false}
                    > */}
                      <Box className="small-subtitle primary-text">
                        <>
                        {customFieldValue(cf)}
                        </>
                      </Box>
                    {/* </CustomHTMLTooltip> */}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>

      <ConfirmationAlertPopUp
        openModal={openUnlinkConfirmationPop}
        closeModalFunc={closeUnlinkConfirmationPop}
        title={`Unlink ${usedFor}!`}
        text={`Are you sure you want to unlink the ${usedFor} from the ${parentItem}?`}
        confirmationButtonText={'Yes'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={clearSelectedContactAndCloseViewContactDetailBox}
      />

    </Box>
  );
}

export default ViewContactDetails;
