import Modal from "react-responsive-modal";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import Stack from '@mui/material/Stack';

const DeleteFolderModal = (props) => {
    return (
        <Modal
            open={props.deleteFolderModal}
            onClose={props.handleCloseDeleteFolder}
            classNames={{
                overlay: '',
                modal: 'Invite-User-Modal',
            }}
            center
        >
            <Box component={Grid} container className="" px={1.5}>
                <Box component={Grid} mt={-1} item xs={12} pb={3}>
                    <Box component="p" mb={1} className="subtitle">
                        {' '}
                        {props.isFolderSelected ? "Delete Folder ?" : "Delete File ?"}
                    </Box>
                    <p className="small-subtitle">
                        Are you sure you want to delete this {props.isFolderSelected ? "folder ?" : "file ?"} 
                    </p>
                </Box>
                <Grid item xs={12}>
                    <Grid container justifyContent="end">
                        <Stack spacing={1} direction="row" fullWidth>
                            <Button
                                variant="contained"
                                color="secondary"
                                disableElevation
                                onClick={
                                    props.isFolderSelected 
                                    ? () => props.handleDeleteFolder(props?.folderToDelete?._id)
                                    : () => props.handleDeleteFile(props?.fileToDelete?._id)
                                }
                            >
                                Delete
                            </Button>
                            <Button
                                variant="outlined"
                                disableElevation
                                onClick={props.handleCloseDeleteFolder}
                            >
                                Cancel
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default DeleteFolderModal;