import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getRolesRequest: ['data'],
  getRolesSuccess: ['message', 'data', 'cntRoleData'],
  getRolesError: ['error'],
  createRoleRequest: ['data'],
  createRoleSuccess: ['message'],
  createRoleError: ['error'],
  editRoleRequest: ['data'],
  editRoleSuccess: ['message', 'role'],
  editRoleError: ['error'],
  deleteRoleRequest: ['data'],
  deleteRoleSuccess: ['message', 'role'],
  deleteRoleError: ['error']
})

export const Constants = Types
export default Creators
