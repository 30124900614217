import React from 'react';
import { withRouter } from 'react-router';
import ApplicationRoutes from './ApplicationRoutes';
import EndUserEventRoutes from './EndUserEventRoutes';
import { showEndUserEventRoutesFn } from '../helpers/common';

const Routes = (props) => {
  const showEndUserEventRoutes = showEndUserEventRoutesFn();
  return (
    <div>
      {!showEndUserEventRoutes ? <ApplicationRoutes /> : <EndUserEventRoutes />}
    </div>
  );
};

export default withRouter(Routes);
