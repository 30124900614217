import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  adminAutoLoginRequest:['data'],
  AdminAutoLoginSuccess: ['token', 'user'],
  subscriptionError: ['error'],
})
 
export const Constants = Types
export default Creators
