import BaseAPI from './baseAPI';

class GoogleSignInAPI extends BaseAPI {
    connectGoogleSignIn = async (projectId: any) => {
        return this.API('integration/google/googleConnectForSignIn', {
            method: 'POST',
            data: { projectId },
        });
    };
}

export default GoogleSignInAPI;
