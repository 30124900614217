import {createActions} from "reduxsauce"

const {Types, Creators} = createActions({
    createOpportunityCustomFieldRequest: ['data'],
    createOpportunityCustomFieldSuccess: ['customFields', 'message'],
    createOpportunityCustomFieldError: ['message'],
    deleteOpportunityCustomFieldRequest: ['data'],
    deleteOpportunityCustomFieldSuccess: ['customFields', 'message'],
    deleteOpportunityCustomFieldError: ['message'],
    updateOpportunityCustomFieldRequest: ['data'],
    updateOpportunityCustomFieldSuccess: ['customFields', 'message', 'updatedCustomField'],
    updateOpportunityCustomFieldError: ['message'],
    fetchAllOpportunityCustomFieldRequest: ['data'],
    fetchAllOpportunityCustomFieldSuccess: ['customFields'],
    fetchAllOpportunityCustomFieldError: ['message'],
})

export const Constants = Types
export default Creators