import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Loader from '../../../Components/Loader/loader';
import { bindActionCreators } from 'redux';
import { Actions } from '../../../Redux/Actions';
import '../../../sass/main.scss';
import { createNotification } from '../../../helpers';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import BackArrow from '../../../assets/images/icons/backArrowWhite.svg';
import PageIcon from '../../../assets/images/icons/pageIconSmall.svg';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { pink } from '@material-ui/core/colors';
import { Stack } from '@mui/material';
import { theme } from '../../../theme/theme';
import { UserData } from '../../../helpers/common';
import { Modal } from 'react-responsive-modal';
import getUnLayerMeetingsConfig from './meetingsConfig';
import { getProjectIdFromUrl } from '../../../helpers/common';
import { permissions, FILE_SECTION, FILE_TYPE } from '../../../constants/constants';
import { languageCode } from '../../../helpers/common';
import {
  listAudienceCustomFieldsAPI, GetToolCustomFieldsAPI
} from '../../../Redux/API';
import UploadFromFilesPopUp from 'Common/UploadFromFilesPopUp';

// API URL from environment variables
const URL = process.env.REACT_APP_API_URL;
const section = FILE_SECTION.images;
const styles = {
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
};

/**
 * @class Editor
 * @extends Component
 * @description Renders the meeting editor component for creating and editing meeting templates.
 */
class Editor extends Component {
  /**
  * Constructor for the Editor component.
  * @constructor
  * @param {Object} props - The properties passed to the component.
  */
  constructor(props) {
    // State initialization and default values
    super(props);
    this.chooseUnlayerImage = React.createRef(null);
    this.anchorRef = React.createRef();
    this.state = {
      showLoader: false,
      templateName: '',
      eventId: '',
      templateId: '',
      pageName: '',
      pageUrl: '',
      html: null,
      json: {},
      formType: '',
      isUpdatePage: false,
      projectPageTid: '',
      eventDomain: '',
      toolTemplates: null,
      customFileds: null,
      open: false,
      testEmailModal: false,
      testEmail: '',
      exitBackButton: false,
      meetingTemplate: '',
      loading: false,
      saveAndExit: '',
      meetingId: '',
      customFiledsForAudience: null,
      meetingOrganizer: '',
      uploadFromFiles: false,
      buttonState: false,
      parentForPopup: "unlayerProjectPages",
      displayS3Image: false,
      parentId: "",
      pageEditorImage:"",
      pageEditorImageForEdit:""

    };
  }

  /**
 * Retrieves user data based on the current path.
 * @memberof Editor
 * @method UserData
 * @returns {Object} - User data.
 */

  UserData = async () => {
    const currentPath = window.location.pathname;
    if (currentPath.includes('member')) {
      return JSON.parse(localStorage.getItem('member_details'));
    } else {
      return JSON.parse(localStorage.getItem('user_details'));
    }
  };

  /**
   * Fetches page data based on the templateId.
   * @memberof Editor
   * @method getPageData
   * @param {string} templateId - The template ID.
   * @returns {Promise} - A promise that resolves with the page data.
   */
  getPageData = async (templateId) => {
    try {
      const response = await axios.get(
        `${URL}/getPageDetails?pageId=${templateId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (response?.data?.page[0]?.isLoginPage) {
        this.state.formType = 'loginform';
      }
      return response;
    } catch (err) {
      return err;
    }
  };

  /**
  * Lifecycle method called after the component mounts.
  * @memberof Editor
  * @method componentDidMount
  */
  async componentDidMount() {
    const users = UserData();
    this.props.handleLang(languageCode(users.language));
    this.props.newLangStateForRedux(users.language);
    let meetingId = this.props.location?.state?.meetingId;
    const eventId = getProjectIdFromUrl();
    this.props.getProjectRequest(eventId);
    this.handleGetTemplate(meetingId, eventId);

    const param = { projectId: eventId };
    await GetToolCustomFieldsAPI(param).then((fieldResult) => {
      if (fieldResult?.data?.status === 200) {
        this.state.customFileds = get(fieldResult, 'data.customFields', []).map((fields) => ({
          ...fields,
        }));
      }
    });

    await listAudienceCustomFieldsAPI().then((fieldResult) => {
      if (fieldResult?.data?.status === 200) {
        this.state.customFiledsForAudience = get(fieldResult, 'data.customFields', []).map((fields) => ({
          ...fields,
        }));
      }
    });

    this.setState({
      meetingId,
      eventId,
    }, () => this.renderTemplate());
  }

  /**
   * Lifecycle method called when the component updates.
   * @memberof Editor
   * @method componentDidUpdate
   * @param {Object} prevProps - The previous properties of the component.
   * @param {Object} prevState - The previous state of the component.
   */
  componentDidUpdate(prevProps, prevState) {

    if (
      this.props.project.getProjectSuccess &&
      !prevProps.project.getProjectSuccess &&
      this.props.project.message !== prevProps.project.message
    ) {
      //this.props.handleLang(languageCode(this.props.users.newLangState))

      //this.setState({ lng: 'en' }, () => this.props.handleLang(languageCode(this.props.users.newLangState)));
    }

    if (
      this.props.Meeting.getMeetingTemplateSuccess !==
      prevProps.Meeting.getMeetingTemplateSuccess &&
      this.props.Meeting.getMeetingTemplateSuccess === true
    ) {
      this.setState(
        {
          meetingTemplate: this.props.Meeting.meetingTemplate,
          html: this.props.Meeting.meetingTemplate[0]?.html,
          json: this.props.Meeting.meetingTemplate[0]?.json,
          loading: false,
          meetingOrganizer: this.props.Meeting.meetingTemplate[0]?.meetingOrganizer
        }
      );
    }

    if (
      this.props.Meeting.addMeetingSuccess !==
      prevProps.Meeting.addMeetingSuccess &&
      this.props.Meeting.addMeetingSuccess === true
    ) {
      createNotification('success', this.props.Meeting.message);

      this.setState(
        {
          loading: false,
        },
        () => {
          if (this.state.saveAndExit && this.state.saveAndExit.length) {
            setTimeout(() => {
              this.gobackFromEditor();
            }, 200);
          }
        },
      );
    }

    if (
      this.props.Meeting.updateMeetingSuccess !==
      prevProps.Meeting.updateMeetingSuccess &&
      this.props.Meeting.updateMeetingSuccess === true
    ) {
      createNotification('success', this.props.Meeting.message);
      this.setState(
        {
          loading: false,
        },
        () => {
          if (this.state.saveAndExit && this.state.saveAndExit.length) {
            setTimeout(() => {
              this.gobackFromEditor();
            }, 200);
          }
        },
      );
    }

    if (
      this.props.Meeting.error === true &&
      prevProps.Meeting.error === false &&
      this.props.Meeting.message !== prevProps.Meeting.message
    ) {
      createNotification('error', this.props.Meeting.message);
      this.setState({
        loading: false,
      });
    }
  }

  /**
   * Fetches meeting template based on the meetingId and eventId.
   * @memberof Editor
   * @method handleGetTemplate
   * @param {string} meetingId - The meeting ID.
   * @param {string} eventId - The event ID.
   */
  handleGetTemplate = (meetingId, eventId) => {
    let params = '';

    params = {
      meetingType: 'Project',
      meetingId: meetingId,
      eventId: eventId,
      type: 'getTemplateforEditor',
    };
    this.props.getMeetingTemplate(params);
  };

  /**
  * Creates or updates a meeting based on the saveAndExit parameter.
  * @memberof Editor
  * @method createMeeting
  * @param {string} [saveAndExit=''] - The saveAndExit parameter.
  */
  createMeeting = (saveAndExit) => {
    let { eventId, html, json, meetingId, meetingOrganizer } = this.state;

    let requestBody = {
      eventId,
      html,
      json,
      meetingId: meetingId,
      lngCode: this.props.users.newLangState,
      meetingOrganizer: meetingOrganizer
    };
    this.props.updateMeeting(requestBody);
    this.setState({
      loading: true,
      saveAndExit: saveAndExit,
    });
  };

  /**
 * Handles the key down event, specifically focusing on the 'Tab' key.
 * If 'Tab' is pressed, prevents the default behavior and closes the modal.
 * @memberof Editor
 * @method handleListKeyDown
 * @param {Object} e - The key down event object.
 */
  handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      this.setState({
        open: false,
      });
    }
  };

  /**
 * Opens the test email modal by setting `testEmailModal` state to true.
 * @memberof Editor
 * @method sendTestEmail
 */
  sendTestEmail = () => {
    this.setState({
      testEmailModal: true,
    });
  };

  /**
 * Closes the test email modal by setting `testEmailModal` state to false.
 * @memberof Editor
 * @method handleCloseTestEmailModal
 */
  handleCloseTestEmailModal = () => {
    this.setState({
      testEmailModal: false,
    });
  };

  /**
 * Handles the change in the test email input and updates the `testEmail` state.
 * @memberof Editor
 * @method handleChangeTestEmail
 * @param {Object} e - The change event object.
 */
  handleChangeTestEmail = (e) => {
    this.setState({
      testEmail: e.target.value,
    });
  };

  /**
 * Sends a test transaction email, validating the test email first.
 * If the email is empty, throws an error.
 * @memberof Editor
 * @method sendTestTransactionEmail
 */
  sendTestTransactionEmail = () => {
    const testTransMailData = {
      eventId: this.state.eventId,
      email: this.state.testEmail,
      meetingId: this.state.meetingId,
    };
    try {
      if (this.state.testEmail == '') {
        throw Error('Test email id is required');
      }
      this.setState({
        loading: true,
      });
      const users = UserData();
      const URL = process.env.REACT_APP_API_URL;
      axios
        .post(`${URL}/meeting/sendTestEmailForMeeting`, testTransMailData, {
          headers: {
            token: `bearer ${users.token}`,
            'Content-Type': 'application/json',
            'check-grant': `${this.state.eventId}-${permissions.editMeeting}`,
          },
        })
        .then((data) => {
          if (data.data.status == 200) {
            this.setState({
              loading: false,
            });
            createNotification(
              'success',
              data.data ? data.data.message : 'Test mail send successfully.',
            );
            this.handleCloseTestEmailModal();
          } else {
            createNotification('error', data.data.error);
          }
        })
        .catch((err) => {
          createNotification('error', 'Something went wrong');
        });
    } catch (err) {
      createNotification('error', err.message);
    }
  };

  /**
 * Closes a modal by setting `open` state to false.
 * @memberof Editor
 * @method handleClose
 */
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  /**
 * Toggles the state of `open` between true and false.
 * @memberof Editor
 * @method handleToggle
 */
  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  /**
 * Navigates back from the editor to the meetings page.
 * @memberof Editor
 * @method gobackFromEditor
 * @param {string} templateType - The type of template.
 */
  gobackFromEditor = (templateType) => {
    const eventId = getProjectIdFromUrl();
    this.props.handleNavigationFromEditorToCreateMeeting(true);
    this.props.history.push({
      pathname: `/project-dashboard/meetings/${eventId}`,
      state: { meetingId: this.state.meetingId, isEdit: true },
    });
  };

  /**
 * Closes the back button modal by setting `exitBackButton` state to false.
 * @memberof Editor
 * @method handleCloseBackButtonModal
 */
  handleCloseBackButtonModal = () => {
    this.setState({
      exitBackButton: false,
    });
  };

  /**
 * Renders the template using UnLayer based on the provided configurations.
 * @memberof Editor
 * @method renderTemplate
 */
  renderTemplate = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const setState = this.setState.bind(this);
    window.scrollTo(0, 0);
    window.unlayer.init(getUnLayerMeetingsConfig(this.state.customFileds, this.state.customFiledsForAudience));
    /* callback event for images module in unlayer start 1 */
    window.unlayer.registerCallback('selectImage', (data, done) => {
      this.handleOpenFileModulePopup();
      document.body.addEventListener('click', function (event) {
        // Ensure the clicked element has the class 'driveImages'
        if (event.target.classList.contains('driveImages')) {
          const src = event.target.src; // Get the src of the clicked image          
          done({ url: src });
        }
      });
    });
    /* callback event for images module in unlayer end  1*/

    window.unlayer.addEventListener('design:updated', function (updates) {
      window.unlayer.exportHtml(function (data) {
        const json = data.design;
        const html = data.html;
        let formType = '';
        setState({ json, html, formType });
      });
    });

    if (Object.keys(this.state.json)?.length > 0) {
      window.unlayer.loadDesign(this.state?.json);
    } else {
      window.unlayer.loadDesign();
    }
  };

  /* callback event for images module in unlayer start 2 */
  handleOpenFileModulePopup = () => {
    this.setState({
      uploadFromFiles: true,
      buttonState: false
    });
  };
  handleCloseFileModulePopup = () => {
    this.setState({
      uploadFromFiles: false,
      buttonState: true
    });
  };

  handleChoosedFile = () => {
    this.setState(
      {
        uploadFromFiles: false,
        openEditAttendeeModal: false,
      }
    );
  };
  /* callback event for images module in unlayer end 2  */

  chooseUnlayerImageClick = (id) => {
    this.setState({
      parentId: id
    });
    this.chooseUnlayerImage.current.click();
  };

  onUnlayerImageChange = (event, method) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];

      if (!imageFile.name.match(/\.(jpg|jpeg|gif|png)$/i)) {
        createNotification('error', 'Please select valid image file.');
        return false;
      }

      if (imageFile.size > 5242880) { //5 mb
        createNotification('error', this.props.users.languageStateForRedux?.common?.imageSizeErrMsg);
        return false;
      }

      if (method === 'add') {
        this.setState({ pageEditorImage: event.target.files[0] });

        let formData = new FormData();
        formData.append('imageFile', event.target.files[0]);
        formData.append('section', section);
        formData.append('type', FILE_TYPE.file);
        formData.append('parent', this.state.parentId);
        this.props.addImagesRequest((formData));

      } else {
        this.setState({ pageEditorImageForEdit: event.target.files[0] });
      }
    }
  };
  /**
   * Renders the Editor component.
   * @memberof Editor
   * @method render
   * @returns {JSX.Element} - The JSX element representing the component.
   */
  render() {
    const {
      view,
      showLoader,
      templateName,
      isActive,
      path = '',
      landingPage,
      registrationPage,
      saveTempDisabled,
      errors = {},
      pathSuccessMessage,
      event,
      landingPageExist,
      isUpdatePage,
      hasRenderedByTransactionEmail,
      testEmailModal,
      testEmail,
    } = this.state;
    const { type, events } = this.props;
    const templateId = get(this.props, 'match.params.tid');
    const eventId = get(this.props, 'match.params.pid');
    const backTo = `/project-dashboard/${eventId}`;

    return (
      <div className="editingPageWrapper">
        <div className="unlayer-header">
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Grid container alignItems="center">
                <Button
                  className="back-button"
                  variant="text"
                  onClick={this.gobackFromEditor}
                >
                  <img alt="" src={BackArrow} />
                </Button>
                <Avatar style={styles.pink}>
                  <img alt="" src={PageIcon} />
                </Avatar>
                <h3 className="page-title">
                  {this.state?.meetingTemplate[0]?.meetingTitle}{' '}
                </h3>
              </Grid>
            </Grid>
            <Grid item className="buttons">
              <Stack spacing={1} direction="row">
                <Button variant="outlined" onClick={this.sendTestEmail}>
                  {this.props.users.languageStateForRedux?.unlayer?.sendMail}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => this.createMeeting()}
                >
                  {this.props.users.languageStateForRedux?.unlayer?.save}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => this.createMeeting('saveAndExit')}
                >
                  {this.props.users.languageStateForRedux?.unlayer?.saveNext}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </div>
        { /* callback event for images module in unlayer start -3 */}
        <UploadFromFilesPopUp
          uploadFromFiles={this.state.uploadFromFiles}
          chooseUnlayerImageClick={this.chooseUnlayerImageClick}
          chooseUnlayerImage={this.chooseUnlayerImage}
          handleCloseUploadFromFileModule={this.handleCloseFileModulePopup} 
          onUnlayerImageChange={this.onUnlayerImageChange}
          handleChoosedFile={this.handleChoosedFile}
          parentForPopup={this.state.parentForPopup}
          buttonState={this.state.buttonState}
        /> 

        { /* callback event for images module in unlayer end -3 */}
        <Helmet>
          <title>IllumeetXP | Page Design </title>
        </Helmet>
        {showLoader && <Loader />}
        {
          <div className="editingDashboard">
            <div id="meetingsEditor" style={{ height: 'calc(100vh - 60px)' }}>
              {''}
            </div>
          </div>
        }
        <NotificationContainer />
        {/* input for open files */}
        <input
          type="file"
          id="pageEditorImage"
          name="pageEditorImage"
          onChange={(e) => this.onUnlayerImageChange(e, 'add')}
          accept="image/*"
          ref={this.chooseUnlayerImage}
          style={{ display: "none" }}
        />
        {/* input for open files */}
        <Modal
          open={testEmailModal}
          onClose={this.handleCloseTestEmailModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid item xs={12} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {this.props.users.languageStateForRedux?.unlayer?.sendMail}
              </Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.messages?.enterValid}
              </p>
            </Grid>
            <Grid item xs={12} pb={4}>
              <Box component="div" className="inputField">
                <label>{this.props.users.languageStateForRedux?.column_names?.teams?.email}</label>
                <input
                  type="text"
                  class="form-control small"
                  placeholder={this.props.users.languageStateForRedux?.formfields?.meetings?.enterHere}
                  value={testEmail}
                  onChange={this.handleChangeTestEmail}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.sendTestTransactionEmail}
                  >
                    {this.props.users.languageStateForRedux?.unlayer.sendMail}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={this.handleCloseTestEmailModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        {this.state.loading ? <Loader /> : null}
      </div>
    );
  }
}

/**
 * @function mapStateToProps
 * @description Maps the Redux state to component props.
 * @param {Object} state - Redux state object.
 * @returns {Object} Props derived from the Redux state.
 */
const mapStateToProps = (state) => {
  return {
    events: state.Events,
    project: state.Projects,
    Meeting: state.Meeting,
    users: state.Users,
  };
};

/**
 * @function mapActionsToProps
 * @description Maps Redux actions to component props.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Object} Props containing action functions.
 */
const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getMeetingTemplate: Actions.getMeetingTemplateRequest,
      addMeeting: Actions.addMeetingRequest,
      updateMeeting: Actions.updateMeetingRequest,
      eventRequest: Actions.listSingleEventRequest,
      handleNavigationFromEditorToCreateMeeting:
        Actions.handleNavigationFromEditorToCreateMeeting,
      getProjectRequest: Actions.getProjectRequest,
      handleLang: Actions.handleLang,
      newLangStateForRedux: Actions.newLangStateForRedux,
      addImagesRequest: Actions.addImagesRequest
    },
    dispatch,
  );

// Connects the component to Redux store and withRouter for access to router props
export default connect(mapStateToProps, mapActionsToProps)(withRouter(Editor));
