import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ExportIcon from '../../../../../../src/assets/images/icons/export.svg';
import Button from '@material-ui/core/Button';

import { Popper, Fade, ClickAwayListener, MenuItem } from '@material-ui/core';
import { useState, useRef, useEffect, ChangeEvent } from 'react';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { theme } from '../../../../../theme/theme';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  FormControl,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import AIChatbotAPI from 'Redux/API/aiChatbotAPI';
import { useParams } from 'react-router';
import { createNotification } from 'helpers';
import Loader from 'Components/Loader/loader';
import PdfKit from 'pdfkit';
import ChatBot from '../../../../../assets/images/icons/chat-bot-avatar.svg';
import MessageWindow, { Message } from '../../ChatWidget/MessageWindow';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
    },
  },
};

const dropdownButtonsArray = [
  { timeDuration: '1', label: 'Today' },
  { timeDuration: '7', label: 'Last 07 Days' },
  { timeDuration: '30', label: 'Last 30 Days' },
  { timeDuration: '60', label: 'Last 60 Days' },
  { timeDuration: 'customDuration', label: 'Custom Range' },
];

const sources = [
  'Widget or iframe',
  'API',
  'Chatbase site',
  'WhatsApp',
  'Unspecified',
];

type Chats = Chat[];

type Chat = {
  messages: Message[];
  _id: string;
};

type APIQueryParamsType = {
  chatBotId: string;
  timeDuration: string;
  customEndDate?: Date;
  customStartDate?: Date;
  isExport?: boolean;
  pageNo?: number;
};

type Errors = {
  date?: string;
};

function ChatLogs() {
  const anchorRef = useRef(null);
  const params: { id: string } = useParams();
  const [open, setOpen] = useState(false);
  const [timeDuration, setTimeDuration] = useState<string>('1');
  const [customStartDate, setCustomStartDate] = useState(new Date());
  const [customEndDate, setCustomEndDate] = useState(new Date());
  const [selectedSource, setSelectedSource] = useState('');
  const [selectedConfidenceScope, setSelectedConfidenceScope] = useState('');
  const [chats, setChats] = useState<Chats>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [chatId, setChatId] = useState<string>('');
  const [exportType, setExportType] = useState<string>('csv');
  const [pageNoForChatsList, setPageNoForChatsList] = useState(1);
  const [pageNoForMessages, setPageNoForMessages] = useState(1);
  const [showLoadMoreButtonForChatsList, setShowLoadMoreButtonForChatsList] =
    useState(false);
  const [showLoadMoreButtonForMessages, setShowLoadMoreButtonForMessages] =
    useState(true);

  useEffect(() => {
    setLoading(true);
    fetchChatsForChatLogs();
  }, []);

  const fetchChatsForChatLogs = async (
    timeDurationAsArgument: string | undefined = undefined,
    page_no_Arg?: number,
    isLoadMoreButtonClicked?: boolean,
    isTimeDurationChanged?: boolean,
  ) => {
    try {
      setErrors({});
      const aiChatbotApi = new AIChatbotAPI();
      let queryParams: APIQueryParamsType = {
        chatBotId: params.id,
        timeDuration: timeDurationAsArgument || timeDuration,
        pageNo: page_no_Arg || pageNoForChatsList,
      };
      if (timeDuration === 'customDuration') {
        if (customEndDate > customStartDate) {
          queryParams = {
            ...queryParams,
            customStartDate: new Date(customStartDate.toISOString()),
            customEndDate: new Date(customEndDate.toISOString()),
          };
        } else {
          setErrors({
            date: 'End date should be greater than start date',
          });
          return;
        }
      }
      const chatsResponse = await aiChatbotApi.fetchChatsForChatLogs(
        queryParams,
      );

      const chatsResults: Chats = chatsResponse.data.chats;
      const totalChatsResult: number = chatsResponse.data.totalChats;
      setLoading(false);

      if (chatsResults) {
        if (isTimeDurationChanged) {
          // when time duration is changed initial chats should be empty
          setShowLoadMoreButtonForChatsList(
            [...chatsResults].length < totalChatsResult,
          );
          setChats([...chatsResults]);
        } else {
          setShowLoadMoreButtonForChatsList(
            [...chats, ...chatsResults].length < totalChatsResult,
          );
          setChats((prev) => [...prev, ...chatsResults]);
        }

        let firstChatId = chatsResults[0]?._id;
        if (firstChatId) {
          fetchAllConversationFromChat(
            isLoadMoreButtonClicked ? chatId : firstChatId,
            undefined,
            undefined,
            1,
            true,
          );
          if (!isLoadMoreButtonClicked) {
            setChatId(firstChatId);
          }
          setPageNoForMessages(1);
        } else {
          setMessages([]);
          setPageNoForMessages(1);
        }
      } else {
        setChats([]);
        setMessages([]);
      }
    } catch (error) {
      console.log('===error', error);
      setLoading(false);
      createNotification('error', 'Something went wrong');
    }
  };

  const fetchAllConversationFromChat = async (
    chatId: string,
    isExport: boolean = false,
    exportType: string = 'csv',
    page_no_Arg?: number,
    isLoadingMessagesForAnotherChat?: boolean,
  ) => {
    try {
      setLoading(true);
      const aiChatbotApi = new AIChatbotAPI();
      const queryParams = {
        chatId: chatId,
        isExport,
        exportType,
        pageNo: page_no_Arg || pageNoForMessages,
      };

      const conversationResponse =
        await aiChatbotApi.fetchAllConversationFromChat(queryParams);

      if (conversationResponse.data.csvData) {
        const url = window.URL.createObjectURL(
          new Blob([conversationResponse?.data?.csvData]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Conversation.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else if (isExport && exportType === 'pdf') {
      } else {
        if (isLoadingMessagesForAnotherChat) {
          setPageNoForMessages(1);
          setShowLoadMoreButtonForMessages(
            [...conversationResponse.data.conversations].length <
              conversationResponse.data.totalMessages,
          );
        } else {
          setShowLoadMoreButtonForMessages(
            [...messages, ...conversationResponse.data.conversations].length <
              conversationResponse.data.totalMessages,
          );
        }
        if (conversationResponse.data.conversations) {
          if (isLoadingMessagesForAnotherChat) {
            setMessages([...conversationResponse.data.conversations]);
          } else {
            setMessages((prev) => [
              ...prev,
              ...conversationResponse.data.conversations,
            ]);
          }
        } else {
          setMessages([]);
        }
      }

      setLoading(false);
    } catch (error) {
      console.log('===error', error);
      setLoading(false);
      createNotification('error', 'Something went wrong');
    }
  };

  const handleTimeDuration = (timeDuration: string) => {
    setTimeDuration(timeDuration);
    setShowLoadMoreButtonForChatsList(true);
    setPageNoForChatsList(1);

    if (timeDuration !== 'customDuration') {
      setChats([]);
      fetchChatsForChatLogs(timeDuration, 1, false, true);
      setPageNoForChatsList(1);
    } else {
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onStartChangeDate = (date: Date) => {
    setCustomStartDate(date);
  };
  const onChangeEndDate = (date: Date) => {
    setCustomEndDate(date);
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setSelectedSource(e.target.value);
  };

  const handleSelectConfidenceScopeChange = (e: SelectChangeEvent<string>) => {
    setSelectedConfidenceScope(e.target.value);
  };

  const calculateTimeElapsed = (startTime?: string) => {
    const ONE_MINUTE_IN_MS = 60 * 1000;
    const ONE_HOUR_IN_MS = 60 * 60 * 1000;
    const ONE_DAY_IN_MS = 24 * ONE_HOUR_IN_MS;
    const ONE_MONTH_IN_MS = 30 * ONE_DAY_IN_MS;

    const currentTime = Date.parse(new Date().toISOString());
    const elapsedTimeInMs = currentTime - Date.parse(startTime || '');

    if (elapsedTimeInMs < ONE_MINUTE_IN_MS) {
      // Less than a minute
      const seconds = Math.floor(elapsedTimeInMs / 1000);
      return `${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
    } else if (elapsedTimeInMs < ONE_HOUR_IN_MS) {
      // Less than an hour
      const minutes = Math.floor(elapsedTimeInMs / ONE_MINUTE_IN_MS);
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
    } else if (elapsedTimeInMs < ONE_DAY_IN_MS) {
      // Less than a day
      const hours = Math.floor(
        (elapsedTimeInMs % ONE_DAY_IN_MS) / ONE_HOUR_IN_MS,
      );
      return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    } else if (elapsedTimeInMs < ONE_MONTH_IN_MS) {
      // Less than a month
      const days = Math.floor(elapsedTimeInMs / ONE_DAY_IN_MS);
      return `${days} ${days === 1 ? 'day' : 'days'}`;
    } else {
      // More than a month
      const months = Math.floor(elapsedTimeInMs / ONE_MONTH_IN_MS);
      return `${months} ${months === 1 ? 'month' : 'months'}`;
    }
  };

  const handleExporTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setExportType(e.target.value);
    fetchAllConversationFromChat(chatId, true, e.target.value);
  };

  const handleLoadMoreForChatList = () => {
    fetchChatsForChatLogs(undefined, pageNoForChatsList + 1, true);
    setPageNoForChatsList((prev) => prev + 1);
  };

  const handleLoadMoreForMessages = () => {
    fetchAllConversationFromChat(chatId, false, 'csv', pageNoForMessages + 1);
    setPageNoForMessages((prev) => prev + 1);
  };

  return (
    <div>
      <Box className="tab-panel chat-logs">
        <Box className="tab-content">
          <Stack
            className="tab-title"
            spacing={1}
            alignItems={{ sm: 'center' }}
            direction={{ sm: 'row' }}
            justifyContent={'space-between'}
          >
            <Box sx={{ minWidth: 'fit-content' }}>CHAT LOGS</Box>

            {/* Filter*/}
            <Box>
              {/* <Box component="div" className="inputField">
              <label>Filter</label>
            </Box> */}
              <Grid
                container
                columnSpacing={1}
                rowSpacing={{ xs: 1, xl: 0 }}
                alignItems={'center'}
                className="chat-filter"
              >
                <Grid item>
                  <Box component="div">
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: '#edecf5',
                        color: theme.palette.common.black,
                        textTransform: 'none',
                        justifyContent: 'space-between',
                      }}
                      fullWidth
                      disableElevation
                      ref={anchorRef}
                      aria-controls={open ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                      endIcon={<ArrowDropDownIcon />}
                      size="small"
                    >
                      {timeDuration === 'customDuration'
                        ? 'Custom Range'
                        : timeDuration === '1'
                        ? 'Today'
                        : `Last ${timeDuration} days`}
                    </Button>
                    <Popper
                      open={open}
                      placement="bottom-start"
                      transition
                      anchorEl={anchorRef.current}
                      role={undefined}
                      disablePortal
                      style={{ zIndex: 99 }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Fade {...TransitionProps} timeout={250}>
                          <Box
                            sx={{
                              backgroundColor: theme.palette.common.white,
                              boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                              borderRadius: '4px',
                              marginTop: '8px',
                            }}
                          >
                            <ClickAwayListener onClickAway={handleClose}>
                              <Stack spacing={1} p={1.5} direction="column">
                                {dropdownButtonsArray.map((el) => {
                                  return (
                                    <Button
                                      size="small"
                                      color={
                                        timeDuration === el.timeDuration
                                          ? 'primary'
                                          : undefined
                                      }
                                      variant={
                                        timeDuration === el.timeDuration
                                          ? 'contained'
                                          : 'outlined'
                                      }
                                      onClick={() =>
                                        handleTimeDuration(el.timeDuration)
                                      }
                                      key={el.timeDuration}
                                    >
                                      {el.label}
                                    </Button>
                                  );
                                })}
                              </Stack>
                            </ClickAwayListener>
                          </Box>
                        </Fade>
                      )}
                    </Popper>
                    <span className="error_mesage"> {errors.date} </span>
                  </Box>
                </Grid>

                {timeDuration === 'customDuration' && (
                  <Grid item className="form-group anaSelectDRange">
                    <Grid
                      container
                      alignItems={'center'}
                      spacing={1}
                      className="field-group inputField"
                    >
                      <Grid item className="formDate formIcon">
                        <DatePicker
                          selected={customStartDate}
                          onChange={onStartChangeDate}
                          selectsStart
                          className="outlineButton"
                          startDate={customStartDate}
                          endDate={customEndDate}
                          maxDate={new Date()}
                        />
                      </Grid>

                      <Grid item>
                        <Box
                          component={'span'}
                          className="small-subtitle primary-text"
                        >
                          To
                        </Box>
                      </Grid>

                      <Grid item className="formDate formIcon">
                        <DatePicker
                          selected={customEndDate}
                          onChange={onChangeEndDate}
                          selectsEnd
                          startDate={customStartDate}
                          className="outlineButton"
                          endDate={customEndDate}
                          minDate={customStartDate}
                          maxDate={new Date()}
                        />
                      </Grid>

                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={() => {
                            setPageNoForChatsList(1);
                            fetchChatsForChatLogs(undefined, 1, false, true);
                          }}
                          size="small"
                          hidden={timeDuration !== 'customDuration'}
                        >
                          Apply
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
            {/* Filter end*/}
          </Stack>
        </Box>
          {chats?.length > 0 ? (
        <Box component={Grid} className="chat-window" container spacing={2} mb={2}>
            <Grid item md={6} lg={5} xl={4}>
              <Stack className="chat-list" spacing={1} mb={2}>
                {chats?.map((chat, i) => {
                  return chat?.messages?.length ? (
                    <Box
                      key={i}
                      className={`chat-tab ${
                        chatId === chat._id ? 'active' : ''
                      }`}
                      onClick={() => {
                        setMessages([]);
                        setChatId(chat._id);
                        fetchAllConversationFromChat(
                          chat._id,
                          false,
                          'csv',
                          1,
                          true,
                        );
                      }}
                    >
                      <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        className="chat-title"
                      >
                        <Box className="customer">
                          {/* {chat?.messages[0]?.sender}: */}
                          {chat?.messages[0]?.text}
                        </Box>
                        <Box className="time">
                          {calculateTimeElapsed(chat?.messages[1]?.timestamp)}
                        </Box>
                      </Stack>
                      <Box className="bot">
                        {/* {chat?.messages[1]?.sender}:  */}
                        {chat?.messages[1]?.text}
                      </Box>
                    </Box>
                  ) : null;
                })}
              </Stack>
              {showLoadMoreButtonForChatsList ? (
                <Stack direction={'row'} justifyContent={'center'} my={1}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={handleLoadMoreForChatList}
                  >
                    Load More
                  </Button>
                </Stack>
              ) : null}
            </Grid>
         
          <Grid item md={6} lg={7} xl={8}>
            <MessageWindow
              messages={messages}
              chatId={chatId}
              handleLoadMoreForMessages={handleLoadMoreForMessages}
              showLoadMoreButtonForMessages={showLoadMoreButtonForMessages}
            />
          </Grid>
        </Box>
         ) : (
          <Grid item xs={12}>
            <Box component={'p'} textAlign={'center'} className="long-text">
              There are no records to display
            </Box>
          </Grid>
        )}
      </Box>
      {loading && <Loader />}
    </div>
  );
}

export default ChatLogs;
