
import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
    findIfAllowedFromSuperadminRequest: ['data'],
    findIfAllowedFromSuperadminSuccess: ['data', 'message', 'projectLimitExceeded', 'featuredAllowed', 'servicePlanDetails'],
    findIfAllowedFromSuperadminError: ['error'],
    findIfAllowedFromSuperadminOuterRequest: ['data'],
    findIfAllowedFromSuperadminOuterSuccess: ['data', 'message', 'projectLimitExceeded', 'featuredAllowed', 'servicePlanDetails'],
    findIfAllowedFromSuperadminOuterError: ['error'],
    getBillingDataRequest: ['data'],
    getBillingDataSuccess: ['modules', 'features', 'message', 'servicePlanName', 'pricingPlanName', 'servicePlanDetails'],
    getBillingDataError: ['error'],
    getProjectsBillingDataRequest: ['data'],
    getProjectsBillingDataSuccess: ['projectsData', 'totalProjects', 'message'],
    getProjectsBillingDataError: ['error'],
})

export const Constants = Types
export default Creators