import React, { useState, useEffect } from 'react';
import Modal from 'react-responsive-modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Loader from 'Components/Loader/loader';
import Stack from '@mui/material/Stack';
import {
  IconButton,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Actions } from '../../../Redux/Actions';
import FileCreators from '../../../Redux/Actions/files.action';
import { useDispatch, useSelector } from 'react-redux';

const PdfPreview = (props) => {
  const dispatch = useDispatch((store) => Actions.FileCreators);
  const [txtContent, setTxtContent] = useState('');
  const [embedUrl, setEmbedUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [retryCount, setRetryCount] = useState(0);

  const checkUrlAccessibility = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok;
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const encodedUrl = encodeURIComponent(props.previewS3File);

      if (props.previewFile) {
        setLoading(true);
        setError('');
        setTxtContent('');
        setEmbedUrl('');

        const isUrlAccessible = await checkUrlAccessibility(props.previewS3File);

        if (!isUrlAccessible) {
          setError('Error: Failed to access file');
          setLoading(false);
          return;
        }

        let viewerUrl = '';
        if (['pdf', 'docx', 'ppt', 'pptx', 'xlsx', 'xls', 'doc'].includes(props.fileExtension)) {
          viewerUrl = `https://docs.google.com/viewer?url=${encodedUrl}&embedded=true`;
        } else if (props.fileExtension === 'txt') {
          try {
            const response = await fetch(props.previewS3File);
            const text = await response.text();
            setTxtContent(text);
          } catch (err) {
            setError('Error: Failed to fetch text file');
          }
        }

        if (viewerUrl) {
          setEmbedUrl(viewerUrl);
        }

        setLoading(false);
      }
    };

    fetchData();
  }, [props.previewFile, props.previewS3File, props.fileExtension, retryCount]);

  useEffect(() => {
    const retryTimer = setTimeout(() => {
      if (loading) {
        // Retry fetching data if still loading
        setRetryCount((prevCount) => prevCount + 1);
      }
    }, 5000); // Retry every 5 seconds

    return () => clearTimeout(retryTimer);
  }, [loading]);

  const handleOpenDownload = (fileNameInS3) => {
    dispatch(FileCreators.downloadFileRequest({ fileName: fileNameInS3, section: "Documents" }));
  };
  return (
    <div>
      <Modal
        open={props.previewFile}
        onClose={props.handleClosePreviewFile}
        classNames={{
          overlay: 'full-screen_overlay',
          modal: 'full-screen_modal',
        }}
        center
      >
        <Grid container justifyContent="end">
          <Grid item xs={12}>
            <Box className="file-preview-header">
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Box ></Box>
                <Box className='title'>{props.fileName}.{props.fileExtension}</Box>
                <Box className='button'>
                  <IconButton onClick={() => handleOpenDownload(props.fileNameInS3)} size='small'>
                    <GetAppIcon className='icon' />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
            <Box className="file-preview-container">
              {loading ? (
                <Loader />
              ) : error ? (
                <Box style={{ color: 'red', textAlign: 'center' }}>
                  <p>{error}</p>
                </Box>
              ) : props.fileExtension === 'txt' ? (
                <Box style={{ backgroundColor: "#ffffff", width: "100%", maxWidth: "80%", height: "70vh" }} p={2}>
                  {txtContent}
                </Box>
              ) : (
                ['pdf', 'docx', 'ppt', 'pptx', 'xlsx', 'xls', 'doc'].includes(props.fileExtension) && (
                  <>
                    <iframe
                      src={embedUrl}
                      title="Preview"
                      style={{ width: '100%', height: '100%', border: 'none' }}
                      allowFullScreen={true}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      loading="lazy"
                      sandbox="allow-scripts allow-same-origin"
                      referrerPolicy="no-referrer"
                    ></iframe>
                  </>
                )
              )}
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default PdfPreview;
