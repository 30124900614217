import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
  success: false,
  error: false,
  message: null,
  event: null,
  groupCheckSuccess:false,
  groupCheckError:false,
  updateGroupSuccess:false,
  updateGroupError:false,
  customFields:[],
  addAudienceCustomFieldsSuccess:false,
  listAudienceCustomFieldsSuccess:false,
  deleteAudienceCustomFieldsSuccess:false,
  updateAudienceCustomFieldsSuccess:false
}

const groupSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: true,
    groups: action.groups,
    message: action.message,
    totalResults: action.totalResults,
    updateGroupSuccess:false
  }
}

const groupError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const groupRequest = (state = INITIAL_STATE, action) => {
  return { ...state, message: null, success: false, error: false }
}

const groupCheckRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    message: null,
    groupCheckSuccess: false,
    groupCheckError: false,
  }
}
const groupCheckSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    groupCheckSuccess: true,
    message: action.message,
    groupCheckError:false
  }
}

const groupCheckError = (state = INITIAL_STATE, action) => {
  return { 
    ...state,
    groupCheckError: true,
    message: action.error ,
    groupCheckSuccess:false
  }
}

const updateGroupRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    message: null,
    updateGroupSuccess:false,
    updateGroupError:false,
    error:false
  }
}
const updateGroupSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    updateGroupSuccess:true,
    message: action.message,
    groupCheckError:false,
    error:false
  }
}

const updateGroupError = (state = INITIAL_STATE, action) => {
  return { 
    ...state,
    error: true,
    message: action.error ,
    error:false
  }
}

const deleteGroupRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    message: null,
    deleteGroupSuccess:false,
    error:false
  }
}
const deleteGroupSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteGroupSuccess:true,
    message: action.message,
    error:false
  }
}

const deleteGroupError = (state = INITIAL_STATE, action) => {
  return { 
    ...state,
    error: true,
    message: action.error ,
    deleteGroupSuccess:false
  }
}

const addAudienceCustomFieldsRequest = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      addAudienceCustomFieldsSuccess:false,
      listAudienceCustomFieldsSuccess:false,
      deleteAudienceCustomFieldsSuccess:false,
      updateAudienceCustomFieldsSuccess: false,
      error: false,
      message: null,
  };
};

const addAudienceCustomFieldsSuccess = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      listAudienceCustomFieldsSuccess:false,
      deleteAudienceCustomFieldsSuccess:false,
      updateAudienceCustomFieldsSuccess: false,
      addAudienceCustomFieldsSuccess: true,
      error: false,
      message: action.message,
  };
};

const addAudienceCustomFieldsError = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      addAudienceCustomFieldsSuccess:false,
      listAudienceCustomFieldsSuccess:false,
      deleteAudienceCustomFieldsSuccess:false,
      updateAudienceCustomFieldsSuccess: false,
      error: true,
      message: action.error,
  };
};

const listAudienceCustomFieldsRequest = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      addAudienceCustomFieldsSuccess:false,
      listAudienceCustomFieldsSuccess:false,
      deleteAudienceCustomFieldsSuccess:false,
      updateAudienceCustomFieldsSuccess: false,
      error: false,
      message: null,
      customFields:[]
  };
};

const listAudienceCustomFieldsSuccess = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      addAudienceCustomFieldsSuccess:false,
      deleteAudienceCustomFieldsSuccess:false,
      updateAudienceCustomFieldsSuccess: false,
      listAudienceCustomFieldsSuccess: true,
      error: false,
      customFields:action.customFields,
      message: action.message,
      
  };
};

const listAudienceCustomFieldsError = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      addAudienceCustomFieldsSuccess:false,
      listAudienceCustomFieldsSuccess:false,
      deleteAudienceCustomFieldsSuccess:false,
      updateAudienceCustomFieldsSuccess: false,
      error: true,
      customFields:[],
      message: action.error,
  };
};

const deleteAudienceCustomFieldsRequest = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      addAudienceCustomFieldsSuccess:false,
      listAudienceCustomFieldsSuccess:false,
      deleteAudienceCustomFieldsSuccess:false,
      updateAudienceCustomFieldsSuccess: false,
      error: false,
      message: null,
  };
};

const deleteAudienceCustomFieldsSuccess = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      addAudienceCustomFieldsSuccess:false,
      listAudienceCustomFieldsSuccess:false,
      updateAudienceCustomFieldsSuccess: false,
      deleteAudienceCustomFieldsSuccess: true,
      error: false,
      message: action.message,
      
  };
};

const deleteAudienceCustomFieldsError = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      addAudienceCustomFieldsSuccess:false,
      listAudienceCustomFieldsSuccess:false,
      deleteAudienceCustomFieldsSuccess:false,
      updateAudienceCustomFieldsSuccess: false,
      error: true,
      message: action.error,
  };
};

const updateAudienceCustomFieldsRequest = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      addAudienceCustomFieldsSuccess:false,
      listAudienceCustomFieldsSuccess:false,
      deleteAudienceCustomFieldsSuccess:false,
      updateAudienceCustomFieldsSuccess: false,
      error: false,
      message: null,
  };
};

const updateAudienceCustomFieldsSuccess = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      addAudienceCustomFieldsSuccess:false,
      listAudienceCustomFieldsSuccess:false,
      deleteAudienceCustomFieldsSuccess:false,
      updateAudienceCustomFieldsSuccess: true,
      error: false,
      message: action.message,
      
  };
};

const updateAudienceCustomFieldsError = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      addAudienceCustomFieldsSuccess:false,
      listAudienceCustomFieldsSuccess:false,
      deleteAudienceCustomFieldsSuccess:false,
      updateAudienceCustomFieldsSuccess: false,
      error: true,
      message: action.error,
  };
};

const HANDLERS = {
  [Constants.UPDATE_AUDIENCE_CUSTOM_FIELDS_REQUEST]: updateAudienceCustomFieldsRequest,
  [Constants.UPDATE_AUDIENCE_CUSTOM_FIELDS_SUCCESS]: updateAudienceCustomFieldsSuccess,
  [Constants.UPDATE_AUDIENCE_CUSTOM_FIELDS_ERROR]: updateAudienceCustomFieldsError,
  [Constants.DELETE_AUDIENCE_CUSTOM_FIELDS_REQUEST]: deleteAudienceCustomFieldsRequest,
  [Constants.DELETE_AUDIENCE_CUSTOM_FIELDS_SUCCESS]: deleteAudienceCustomFieldsSuccess,
  [Constants.DELETE_AUDIENCE_CUSTOM_FIELDS_ERROR]: deleteAudienceCustomFieldsError,
  [Constants.LIST_AUDIENCE_CUSTOM_FIELDS_REQUEST]: listAudienceCustomFieldsRequest,
  [Constants.LIST_AUDIENCE_CUSTOM_FIELDS_SUCCESS]: listAudienceCustomFieldsSuccess,
  [Constants.LIST_AUDIENCE_CUSTOM_FIELDS_ERROR]: listAudienceCustomFieldsError,
  [Constants.ADD_AUDIENCE_CUSTOM_FIELDS_REQUEST]: addAudienceCustomFieldsRequest,
  [Constants.ADD_AUDIENCE_CUSTOM_FIELDS_SUCCESS]: addAudienceCustomFieldsSuccess,
  [Constants.ADD_AUDIENCE_CUSTOM_FIELDS_ERROR]: addAudienceCustomFieldsError,
  [Constants.GROUP_SUCCESS]: groupSuccess,
  [Constants.GROUP_ERROR]: groupError,
  [Constants.GROUP_REQUEST]: groupRequest,
  [Constants.UPDATE_GROUP_SUCCESS]: updateGroupSuccess,
  [Constants.UPDATE_GROUP_ERROR]: updateGroupError,
  [Constants.UPDATE_GROUP_REQUEST]: updateGroupRequest,
  [Constants.DELETE_GROUP_SUCCESS]: deleteGroupSuccess,
  [Constants.DELETE_GROUP_ERROR]: deleteGroupError,
  [Constants.DELETE_GROUP_REQUEST]: deleteGroupRequest,
  [Constants.SEARCH_GROUP_SUCCESS]: groupSuccess,
  [Constants.SEARCH_GROUP_ERROR]: groupError,
  [Constants.SEARCH_GROUP_REQUEST]: groupRequest,
  [Constants.GROUP_CHECK_SUCCESS]: groupCheckSuccess,
  [Constants.GROUP_CHECK_ERROR]: groupCheckError,
  [Constants.GROUP_CHECK_REQUEST]: groupCheckRequest,
}

export default createReducer(INITIAL_STATE, HANDLERS)
