import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
  success: false,
  error: false,
  message: null,
  event: null,
  deleteSuccess: false,
  totalResults: 0,
  searchSuccess: false,
  csvSuccess: false,
  updateSuccess: false,
  emailSuccess: false,
  emailReportsSuccess: false,
  emailDataReportsSuccess: false,
  reports: null,
  monthReports: null,
  domainSuccess: false,
  domainError: false,
  templates: null,
  allEvents: null,
  emailRegistrationDataReportsSuccess: false,
  projectTypeCnt: {},
  eventType: {},
  getEventSummaryDetailsSuccess: false,
  summaryDetails: null,
  deleteProjectSuccess: false,
  replicateProjectSuccess: false,
  replicatePageSuccess: false,
  doContinue: false,
  lngCode:'en',
  projectType: '',
  updateLngSuccess:false,
  attendeeDetails: null,
  showCommonPopup: false,
  commonPopupDetails: {}
}

const eventsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: true,
    event: action.event,
    message: action.message,
  }
}

const eventsError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const eventsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    emailRegistrationDataReportsSuccess: false,
  }
}
const listEventsSuccess = (state = INITIAL_STATE, action) => { 
  return {
    ...state,
    success: true,
    totalResults: action.totalResults,
    event: action.event,
    message: action.message,
    allEvents: action.allEvents,
    projectTypeCnt: action.projectTypeCnt,
    eventType: action.eventType
  }
}

const eventsListSuccess = (state = INITIAL_STATE, action) => { 
  return {
    ...state,
    success: true,
    totalResults: action.totalResults,
    event: action.event,
    message: action.message,
    allEvents: action.allEvents,
    filterByEvent:action.filterByEvent
  }
}


const listEventsError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const listEventsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    totalResults: 0,
    emailRegistrationDataReportsSuccess: false,
  }
}

const listSingleEventsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: true,
    event: action.event,
    templates: action.templates,
    message: action.message,
  }
}

const listSingleEventsError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const listSingleEventsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    emailRegistrationDataReportsSuccess: false,
  }
}

const eventEmailSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, emailSuccess: true, message: action.message }
}

const eventEmailError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const eventEmailRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    event: null,
    emailRegistrationDataReportsSuccess: false,
  }
}

const updateEventsSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, updateSuccess: true, message: action.message, error: false }
}

const updateEventsError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error, domainError: false }
}

const updateEventsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    emailRegistrationDataReportsSuccess: false,
  }
}

const searchEventsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    searchSuccess: true,
    totalResults: action.totalResults,
    event: action.event,
    message: action.message,
  }
}

const searchEventsError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const searchEventsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    totalResults: 0,
    emailRegistrationDataReportsSuccess: false,
  }
}

const csvSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    csvSuccess: true,
    csvFile: action.csvFile,
    message: action.message,
  }
}

const csvError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const csvRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    message: null,
    error: false,
    emailRegistrationDataReportsSuccess: false,
  }
}

const emailReportsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    emailReportsSuccess: true,
    reports: action.reports,
    message: action.message,
  }
}

const emailReportsError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const emailReportsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    csvSuccess: false,
    message: null,
    error: false,
    emailRegistrationDataReportsSuccess: false,
  }
}

const emailDataReportsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    emailDataReportsSuccess: true,
    reports: action.reports,
    monthReports: action.monthReports,
    message: action.message,
  }
}

const emailDataReportsError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const emailDataReportsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    csvSuccess: false,
    message: null,
    error: false,
    emailRegistrationDataReportsSuccess: false,
  }
}

const checkEventDomainSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, domainSuccess: true, message: action.message }
}

const checkEventDomainError = (state = INITIAL_STATE, action) => {
  return { ...state, domainError: true, message: action.error }
}

const checkEventDomainRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    csvSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    error: false,
    message: null,
    emailRegistrationDataReportsSuccess: false,
  }
}

const emailRegistrationDataReportsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    emailRegistrationDataReportsSuccess: true,
    reports: action.reports,
    monthReports: action.monthReports,
    message: action.message,
  }
}

const emailRegistrationDataReportsError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const emailRegistrationDataReportsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    domainSuccess: false,
    domainError: false,
    emailRegistrationDataReportsSuccess: false,
    emailSuccess: false,
    emailReportsSuccess: false,
    success: false,
    searchSuccess: false,
    updateSuccess: false,
    csvSuccess: false,
    message: null,
    error: false,
  }
}

const getEventSummaryDetailsRequest = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      getEventSummaryDetailsSuccess: false,
      error: false,
      message: null,
      summaryDetails: []
  }
}
const getEventSummaryDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      getEventSummaryDetailsSuccess: true,
      error: false,
      message: action.message,
      summaryDetails: action.summaryDetails
  }
}

const getEventSummaryDetailsError = (state = INITIAL_STATE, action) => {
  return {
      ...state,
      getEventSummaryDetailsSuccess: false,
      error: true,
      message: action.message,
      summaryDetails: []
  }
}

const deleteProjectRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteProjectSuccess: false,
    message: null,
    error: false,
  }
}


const deleteProjectSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteProjectSuccess: true,
    message: action.message,
    error: false,
  }
}
const deleteProjectError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteProjectSuccess: false,
    message: action.message,
    error: true,
  }
}



const replicateProjectRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    replicateProjectSuccess: false,
    message: null,
    error: false,
  }
}  
const replicateProjectSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    replicateProjectSuccess: true,
    message: action.message,
    error: false,
  }
}

const replicateProjectError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    replicateProjectSuccess: false,
    message: action.message,
    error: true,
  }
}

const handleDoContinueState = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    doContinue: action.data
  }
}

const handleSetErrorToFalse = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false
  }
}
const handleProjectType = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    projectType: action.projectType
  }
}

const updateLngCodeRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    updateLngSuccess: false,
    message: null,
    error: false,
  }
}


const updateLngCodeSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    updateLngSuccess: true,
    message: action.message,
    error: false,
  }
}
const updateLngCodeError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    updateLngSuccess: false,
    message: action.error,
    error: true,
  }
}

const showCommonPopup = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    showCommonPopup: action.data.showCommonPopup,
    commonPopupDetails: {
      title: action.data.title,
      description: action.data.description
    }
  }
}


const HANDLERS = {
  [Constants.EVENTS_SUCCESS]: eventsSuccess,
  [Constants.EVENTS_ERROR]: eventsError,
  [Constants.EVENTS_REQUEST]: eventsRequest,
  [Constants.LIST_EVENTS_SUCCESS]: listEventsSuccess,
  [Constants.EVENTS_LIST_SUCCESS]: eventsListSuccess,
  [Constants.LIST_EVENTS_ERROR]: listEventsError,
  [Constants.LIST_EVENTS_REQUEST]: listEventsRequest,
  [Constants.EVENT_EMAIL_SUCCESS]: eventEmailSuccess,
  [Constants.EVENT_EMAIL_ERROR]: eventEmailError,
  [Constants.EVENT_EMAIL_REQUEST]: eventEmailRequest,
  [Constants.UPDATE_EVENTS_SUCCESS]: updateEventsSuccess,
  [Constants.UPDATE_EVENTS_ERROR]: updateEventsError,
  [Constants.UPDATE_EVENTS_REQUEST]: updateEventsRequest,
  [Constants.LIST_SINGLE_EVENT_SUCCESS]: listSingleEventsSuccess,
  [Constants.LIST_SINGLE_EVENT_ERROR]: listSingleEventsError,
  [Constants.LIST_SINGLE_EVENT_REQUEST]: listSingleEventsRequest,
  [Constants.SEARCH_EVENTS_SUCCESS]: searchEventsSuccess,
  [Constants.SEARCH_EVENTS_ERROR]: searchEventsError,
  [Constants.SEARCH_EVENTS_REQUEST]: searchEventsRequest,
  [Constants.CSV_EVENTS_SUCCESS]: csvSuccess,
  [Constants.CSV_EVENTS_ERROR]: csvError,
  [Constants.CSV_EVENTS_REQUEST]: csvRequest,
  [Constants.EMAIL_REPORTS_SUCCESS]: emailReportsSuccess,
  [Constants.EMAIL_REPORTS_ERROR]: emailReportsError,
  [Constants.EMAIL_REPORTS_REQUEST]: emailReportsRequest,
  [Constants.EMAIL_DATA_REPORTS_SUCCESS]: emailDataReportsSuccess,
  [Constants.EMAIL_DATA_REPORTS_ERROR]: emailDataReportsError,
  [Constants.EMAIL_DATA_REPORTS_REQUEST]: emailDataReportsRequest,
  [Constants.EMAIL_REGISTRATION_DATA_REPORTS_SUCCESS]: emailRegistrationDataReportsSuccess,
  [Constants.EMAIL_REGISTRATION_DATA_REPORTS_ERROR]: emailRegistrationDataReportsError,
  [Constants.EMAIL_REGISTRATION_DATA_REPORTS_REQUEST]: emailRegistrationDataReportsRequest,
  [Constants.CHECK_EVENT_DOMAIN_SUCCESS]: checkEventDomainSuccess,
  [Constants.CHECK_EVENT_DOMAIN_ERROR]: checkEventDomainError,
  [Constants.CHECK_EVENT_DOMAIN_REQUEST]: checkEventDomainRequest,
  [Constants.GET_EVENT_SUMMARY_DETAILS_SUCCESS]: getEventSummaryDetailsSuccess,
  [Constants.GET_EVENT_SUMMARY_DETAILS_ERROR]: getEventSummaryDetailsError,
  [Constants.GET_EVENT_SUMMARY_DETAILS_REQUEST]: getEventSummaryDetailsRequest,
  [Constants.DELETE_PROJECT_REQUEST]: deleteProjectRequest,
  [Constants.DELETE_PROJECT_SUCCESS]: deleteProjectSuccess,
  [Constants.DELETE_PROJECT_ERROR]: deleteProjectError,
  [Constants.REPLICATE_PROJECT_REQUEST]: replicateProjectRequest,
  [Constants.REPLICATE_PROJECT_SUCCESS]: replicateProjectSuccess,
  [Constants.REPLICATE_PROJECT_ERROR]: replicateProjectError, 
  [Constants.HANDLE_DO_CONTINUE_STATE]: handleDoContinueState, 
  [Constants.HANDLE_SET_ERROR_TO_FALSE]: handleSetErrorToFalse, 
  [Constants.HANDLE_PROJECT_TYPE]: handleProjectType, 
  [Constants.UPDATE_LNG_CODE_REQUEST]: updateLngCodeRequest,
  [Constants.UPDATE_LNG_CODE_SUCCESS]: updateLngCodeSuccess,
  [Constants.UPDATE_LNG_CODE_ERROR]: updateLngCodeError,
  [Constants.SHOW_COMMON_POPUP]: showCommonPopup 
}

export default createReducer(INITIAL_STATE, HANDLERS)
