import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  permissionRequest: ['data'],
  permissionSuccess: ['message', 'data'],
  permissionError: ['error']
})

export const Constants = Types
export default Creators
