import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
    customFieldsRequest : ['data'],
    customFieldsSuccess : [ 'message', 'customFields', 'googleCutomFields'],
    customFieldsError : ['error'],
    addCustomFieldsRequest: ['data'],
    addCustomFieldsError: ['error'],
    addCustomFieldsSuccess: ['message'],
    deleteCustomFieldsRequest: ['data'],
    deleteCustomFieldsSuccess: ['message'],
    deleteCustomFieldsError: ['error'],
    updateCustomFieldRequest: ['data'],
    updateCustomFieldSuccess: ['message', 'customField'],
    updateCustomFieldError: ['error'],
    listAttendeesRequest: ['data'],
    listAttendeesSuccess: ['message', 'data', 'totalRecords'],
    listAttendeesError: ['error'],
    addAttendeesRequest: ['data'],
    addAttendeesSuccess: ['message', 'data'],
    addAttendeesError: ['error'],
    deleteAttendeesRequest: ['data'],
    deleteAttendeesSuccess: ['message'],
    deleteAttendeesError: ['error'],
    editAttendeeRequest: ['data'],
    editAttendeeSuccess: ['message'],
    editAttendeeError: ['error'],
    checkedInHistoryAttendeeRequest: ['data'],
    checkedInHistoryAttendeeSuccess: ['message'],
    checkedInHistoryAttendeeError: ['error'],
    searchAttendeesRequest: ['data'],
    searchAttendeesSuccess: ['message', 'data', 'totalRecords'],
    searchAttendeesError: ['error'],
    sortFilterAttendeesRequest: ['data'],
    sortFilterAttendeesSuccess: ['message', 'data', 'totalRecords', 'filterCnt'],
    sortFilterAttendeesError: ['error'],
    importAttendeesRequest: ['data'],
    importAttendeesSuccess: ['message', 'uniqueAttendees','importAttendeesId'],
    importAttendeesValidation: ['message', 'duplicateEmailsInCsv', 'invalidEmailsRejection', 'alreadyExistInDb', 'groupNotExists', 'rejectedEmails'],
    importAttendeesError: ['error'],
    enabledStatusRequest: ['data'],
    enabledStatusSuccess: ['message'],
    enabledStatusError: ['error'],
    actionAssignRequest:['data'],
    actionAssignSuccess:['message'],
    actionAssignError:['error'],
    actionCheckinRequest:['data'],
    actionCheckinSuccess:['message'],
    actionCheckinError:['error'],
    listAttendeesGroupsRequest: ['data'],
    listAttendeesGroupsSuccess: ['message', 'data', 'totalGroupsToListInGroupsTab'],
    listAttendeesGroupsError: ['error'],
    addAttendeesGroupsRequest: ['data'],
    addAttendeesGroupsSuccess: ['message'],
    addAttendeesGroupsError: ['error'],
    deleteAttendeesGroupsRequest: ['data'],
    deleteAttendeesGroupsSuccess: ['message'],
    deleteAttendeesGroupsError: ['error'],
    editAttendeesGroupsRequest: ['data'],
    editAttendeesGroupsSuccess: ['message'],
    editAttendeesGroupsError: ['error'],
    sortAttendeesRequest: ['data'],
    sortAttendeesSuccess: ['message', 'data', 'totalRecords'],
    sortAttendeesError: ['error'],
    listUnassignedAttendeesRequest: ['data'],
    listUnassignedAttendeesSuccess: ['message', 'data', 'totalResults'],
    listUnassignedAttendeesError: ['error'],
    listAssignedAttendeesRequest: ['data'],
    listAssignedAttendeesSuccess: ['message', 'data', 'totalResults','filterByStatus'],
    listAssignedAttendeesError: ['error'],
    unassignAttendeesRequest: ['data'],
    unassignAttendeesSuccess: ['message'],
    unassignAttendeesError: ['error'],
    assignAttendeesRequest: ['data'],
    assignAttendeesSuccess: ['message'],
    assignAttendeesError: ['error'],
    searchAttendeesOfGroupRequest: ['data'],
    searchAttendeesOfGroupSuccess: ['message', 'data'],
    searchAttendeesOfGroupError: ['message'],
    searchAttendeesOfUnassignGroupRequest: ['data'],
    searchAttendeesOfUnassignGroupSuccess: ['message', 'data'],
    searchAttendeesOfUnassignGroupError: ['message'],
    searchAttendeesGroupsRequest: ['data'],
    searchAttendeesGroupsSuccess: ['message', 'data'],
    searchAttendeesGroupsError: ['error'],
    editAttendeesGroupNameRequest: ['data'],
    editAttendeesGroupNameSuccess: ['message'],
    editAttendeesGroupNameError: ['error'],
    downloadCsvRequest: ['data'],
    downloadCsvSuccess: ['message'],
    downloadCsvError: ['error'],
    exportAttendeesRequest: ['data'],
    exportAttendeesSuccess: ['message'],
    exportAttendeesError: ['error'],    
    listTransactionalEmailTemplatesRequest: ['data'],
    listTransactionalEmailTemplatesSuccess: ['message', 'templates'],
    listTransactionalEmailTemplatesError: ['error'],
    listAllTransactionalEmailTemplatesRequest: ['data'],
    listAllTransactionalEmailTemplatesSuccess: ['message', 'templates'],
    listAllTransactionalEmailTemplatesError: ['error'], 
    
    listBadgesTemplateRequest: ['data'],
    listBadgesTemplateSuccess: ['message', 'templates'],
    listBadgesTemplateError: ['error'],
    listAllBadgesTemplateRequest: ['data'],
    listAllBadgesTemplateSuccess: ['message', 'templates'],
    listAllBadgesTemplateError: ['error'],

    exportCheckInHistoryRequest: ['data'],
    exportCheckInHistorySuccess: ['message'],
    exportCheckInHistoryError: ['error'], 
    getAttendeesChartDataRequest: ['data'],
    getAttendeesChartDataSuccess: ['message', 'data'],
    getAttendeesChartDataError: ['error'], 
    viewTransactionalReport: ['data'],
    assignAccessCodeRequest:['data'],
    assignAccessCodeSuccess:['message'],
    assignAccessCodeError:['error'],
    unUsedAccessCodeRequest:['data'],
    unUsedAccessCodeSuccess:['message','getUnassignAccessCode', 'totalAccessCode',],
    unUsedAccessCodeError:['error'],
})

export const Constants = Types
export default Creators