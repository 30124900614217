import React, { Component } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Button from '@material-ui/core/Button'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

export class DashboardProfilePassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            values: {
                showCurrentPassword: false,
                showNewPassword: false,
                showConfirmPassword: false,
            }
        }
    }
    handleClickShowCurrentPassword = () => {
        let newState = {
            ...this.state.values,
            showCurrentPassword: !this.state.values.showCurrentPassword
        }
        this.setState({
            values: newState
        })
    }

    handleClickShowNewPassword = () => {
        let newState = {
            ...this.state.values,
            showNewPassword: !this.state.values.showNewPassword
        }
        this.setState({
            values: newState
        })
    }

    handleClickShowConfirmPassword = () => {
        let newState = {
            ...this.state.values,
            showConfirmPassword: !this.state.values.showConfirmPassword
        }
        this.setState({
            values: newState
        })
    }
    render() {
        return (
            <>
                <Box pt={{ xs: 2, sm: 2.5, lg: 3, xl: 4 }}>
                    <Box className="subtitle">Change Your Password</Box>
                </Box>
                <Box py={{ xs: 2, sm: 3, xl: 3.5 }}>
                    <Grid container spacing={{ xs: 2, lg: 4 }}>
                        <Grid item xs={12} md={12}>
                            <Box component="div" className="inputField">
                                <label>
                                    Current Password
                                    <span className="required">*</span>
                                </label>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    fullWidth
                                    pr={0}
                                    notched={false}
                                    type={
                                        this.state.values.showCurrentPassword ? 'text' : 'password'
                                    }
                        
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowCurrentPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {this.state.values.showCurrentPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box component="div" className="inputField">
                                <label>
                                    New Password
                                    <span className="required">*</span>
                                </label>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    fullWidth
                                    pr={0}
                                    notched={false}
                                    type={
                                        this.state.values.showNewPassword ? 'text' : 'password'
                                    }
                          
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowNewPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {this.state.values.showNewPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box component="div" className="inputField">
                                <label>
                                    Re-enter Password
                                    <span className="required">*</span>
                                </label>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    fullWidth
                                    pr={0}
                                    notched={false}
                                    type={
                                        this.state.values.showConfirmPassword ? 'text' : 'password'
                                    }
                                  
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowConfirmPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {this.state.values.showConfirmPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1} direction="row" fullWidth>
                                <Button variant="contained" color="primary">
                                    Change
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </>
        )
    }
}

export default DashboardProfilePassword