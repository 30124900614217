import { Constants } from "Redux/Actions";
import { createReducer } from "reduxsauce";

export type OpportunityState = {
    isLoading: boolean,
    opportunities: Opportunity[],
    fetchAllOpportunityOpportunitiesSuccess: boolean,
    error: boolean,
    fetchOpportunityOpportunitySuccess: boolean,
    openedOpportunity: Opportunity,
    message: string,
    createOpportunityOpportunitySuccess: boolean,
    updateOpportunityOpportunitySuccess: boolean,
    totalCount: number,
    deleteOpportunityOpportunitySuccess: boolean;
    filterCount: FilterCount,
    updateMultipleOpportunityOpportunitiesSuccess: boolean,
    contactsObj: ContactsObj,
    accountsObj: AccountsObj,
    templates: Templates,
    listTransactionEmailSuccess: boolean,
    updateTransactionEmailSuccess: boolean,
    isSavingAsDraft: boolean,
};

export type Opportunity = {
    name: string,
    _id: string,
    customFields: CustomFields,
    customFieldsWithProperValues: CustomFields,
    updatedAt: Date,
    status: Status,
    state: StateType,
    source: Source,
    account: Account,
    assignedTo: AssignedTo;
    project: Project;
    sourceRef:string,
    chatSummary: {summary: string};
};

export type Project = {
    name: string,
    _id: string;
};

export type StateType = {
    name: string,
    _id: string;
};

export type Account = {
    name: string,
    _id: string
}

export type Status = 'new' | 'inProgress' | 'onHold' | 'done' | 'draft';

export type Source = 'aiAgent' | 'manuallyAdded' | 'imported';

export type AssignedTo = {
    email: string,
    _id: string;
};
export type CustomFields = {
    [key: string]: string | string[] | ContactCf | AccountCf;
};

export type ContactCf = {_id?: string, firstName: string, middleName?: string, lastName?: string, fullName: string}

export type AccountCf = {_id?: string, name: string}

export type FilterCount = {
    projectsCount: ProjectsCount,
    projectsAll: number,
    sourceCount: SourceCount,
    sourceAll: number,
    statusCount: StatusCount,
    statusAll: number,
    accountsCount: AccountsCount,
    accountsAll: number,
    lastImportedAll: number,
}

export type ProjectsCount = {
    [key: string]: number
}
export type AccountsCount = {
    [key: string]: number
}

export type SourceCount = {
    aiAgent: number,
    manuallyAdded: number
}

export type StatusCount = {
    inProgress: number,
    new: number,
    onHold: number,
    done: number
}

export type ContactsObj = {
    [key: string]: string
}

export type AccountsObj = {
    [key: string]: string
}
export type Template ={
    emailSubject: string
    replyTo: string
    senderName: string
    html:string
    json(json: any): unknown;
    _id: string;
    templateName :string,
    subject: string,
    updatedAt : Date,
    from: string
}

export type Templates = Template[]

const INITIAL_STATE: OpportunityState = {
    isLoading: false,
    opportunities: [],
    fetchAllOpportunityOpportunitiesSuccess: false,
    error: false,
    fetchOpportunityOpportunitySuccess: false,
    openedOpportunity: {} as Opportunity,
    message: '',
    createOpportunityOpportunitySuccess: false,
    updateOpportunityOpportunitySuccess: false,
    totalCount: 0,
    deleteOpportunityOpportunitySuccess: false,
    filterCount: {} as FilterCount,
    updateMultipleOpportunityOpportunitiesSuccess: false,
    contactsObj: {},
    accountsObj: {},
    templates : [],
    listTransactionEmailSuccess :false,
    updateTransactionEmailSuccess :false,
    isSavingAsDraft: false,
};

const fetchAllOpportunityOpportunitiesRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        fetchAllOpportunityOpportunitiesSuccess: false,
        error: false,
        opportunities: [],
        message: '',
        totalCount: 0,
        contactsObj: {},
        accountsObj: {},
    };
};

const fetchAllOpportunityOpportunitiesSuccess = (state = INITIAL_STATE, action: any) => {
     
    return {
        ...state,
        isLoading: false,
        fetchAllOpportunityOpportunitiesSuccess: true,
        error: false,
        opportunities: action.opportunities,
        totalCount: action.totalCount,
        filterCount: action.filterCount,
        contactsObj: action.contactsObj,
        accountsObj: action.accountsObj
    };
};

const fetchAllOpportunityOpportunitiesError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        fetchAllOpportunityOpportunitiesSuccess: false,
        error: true,
        opportunities: [],
        message: action.message,
        totalCount: 0,
        contactsObj: {},
        accountsObj: {},
    };
};

const fetchOpportunityOpportunityRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        fetchOpportunityOpportunitySuccess: false,
        error: false,
        openedOpportunity: {} as Opportunity,
        message: '',
    };
};
const fetchOpportunityOpportunitySuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        fetchOpportunityOpportunitySuccess: true,
        error: false,
        openedOpportunity: action.opportunity,
    };
};
const fetchOpportunityOpportunityError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        fetchOpportunityOpportunitySuccess: false,
        error: true,
        openedOpportunity: {} as Opportunity,
        message: action.message
    };
};

const createOpportunityOpportunityRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        createOpportunityOpportunitySuccess: false,
        error: false,
        message: '',
        totalCount: 0,
        contactsObj: {},
        accountsObj: {},
    };
};
const createOpportunityOpportunitySuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        createOpportunityOpportunitySuccess: true,
        error: false,
        openedOpportunity: action.opportunity,
        opportunities: action.opportunities,
        totalCount: action.totalCount,
        filterCount: action.filterCount,
        contactsObj: action.contactsObj,
        accountsObj: action.accountsObj
    };
};

const createOpportunityOpportunityError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        createOpportunityOpportunitySuccess: false,
        error: true,
        message: action.message,
        totalCount: 0,
        contactsObj: {},
        accountsObj: {},
    };
};
const updateOpportunityOpportunityRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        updateOpportunityOpportunitySuccess: false,
        error: false,
        message: '',
        totalCount: 0,
        contactsObj: {},
        accountsObj: {},
    };
};
const updateOpportunityOpportunitySuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        updateOpportunityOpportunitySuccess: true,
        error: false,
        openedOpportunity: action.opportunity,
        opportunities: action.opportunities,
        totalCount: action.totalCount,
        filterCount: action.filterCount,
        contactsObj: action.contactsObj,
        accountsObj: action.accountsObj
    };
};

const updateOpportunityOpportunityError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        updateOpportunityOpportunitySuccess: false,
        error: true,
        message: action.message,
        totalCount: 0,
        contactsObj: {},
        accountsObj: {},
    };
};
const deleteOpportunityOpportunityRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        deleteOpportunityOpportunitySuccess: false,
        error: false,
        message: '',
        totalCount: 0,
        contactsObj: {},
        accountsObj: {},
    };
};
const deleteOpportunityOpportunitySuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        deleteOpportunityOpportunitySuccess: true,
        error: false,
        opportunities: action.opportunities,
        totalCount: action.totalCount,
        filterCount: action.filterCount,
        contactsObj: action.contactsObj,
        accountsObj: action.accountsObj
    };
};

const deleteOpportunityOpportunityError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        deleteOpportunityOpportunitySuccess: false,
        error: true,
        message: action.message,
        totalCount: 0,
        contactsObj: {},
        accountsObj: {},
    };
};

const updateMultipleOpportunityOpportunitiesRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        updateMultipleOpportunityOpportunitiesSuccess: false,
        error: false,
        message: '',
        totalCount: 0,
        contactsObj: {},
        accountsObj: {},
    };
};

const updateMultipleOpportunityOpportunitiesSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        updateMultipleOpportunityOpportunitiesSuccess: true,
        error: false,
        opportunities: action.opportunities,
        totalCount: action.totalCount,
        filterCount: action.filterCount,
        accountsObj: action.accountsObj,
        contactsObj: action.contactsObj,

    };
};

const updateMultipleOpportunityOpportunitiesError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        updateMultipleOpportunityOpportunitiesSuccess: false,
        error: true,
        message: action.message,
        totalCount: 0,
        contactsObj: {},
        accountsObj: {},
    };
};

const listTransactionEmailRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        listTransactionEmailSuccess: false,
        error: false,
        templates: [],
        loading: true,
        message: '',
        // totalCount: 0
    };
};

const listTransactionEmailSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        listTransactionEmailSuccess: true,
        error: false,
        templates: action.templates,
        loading: false,
        // totalCount: action.totalCount,
        // filterCount: action.filterCount
    };
};

const listTransactionEmailError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        listTransactionEmailSuccess: false,
        error: true,
        loading: false,
        templates: [],
        message: action.message,
        // totalCount: 0
    };
};

const updateTransactionEmailRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        updateTransactionEmailSuccess: false,
        error: false,
        loading: true,
        message: '',
        // totalCount: 0
    };
};

const updateTransactionEmailSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        updateTransactionEmailSuccess: true,
        error: false,
        // templates: action.templates,
        loading: false,
        // totalCount: action.totalCount,
        // filterCount: action.filterCount
    };
};

const updateTransactionEmailError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        updateTransactionEmailSuccess: false,
        error: true,
        loading: false,
        message: action.message,
        // totalCount: 0
    };
};

const setIsSavingAsDraft = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isSavingAsDraft: action.isSavingAsDraft
    }
}

export const HANDLERS = {
    [Constants.FETCH_ALL_OPPORTUNITY_OPPORTUNITIES_REQUEST]: fetchAllOpportunityOpportunitiesRequest,
    [Constants.FETCH_ALL_OPPORTUNITY_OPPORTUNITIES_SUCCESS]: fetchAllOpportunityOpportunitiesSuccess,
    [Constants.FETCH_ALL_OPPORTUNITY_OPPORTUNITIES_ERROR]: fetchAllOpportunityOpportunitiesError,
    [Constants.FETCH_OPPORTUNITY_OPPORTUNITY_REQUEST]: fetchOpportunityOpportunityRequest,
    [Constants.FETCH_OPPORTUNITY_OPPORTUNITY_SUCCESS]: fetchOpportunityOpportunitySuccess,
    [Constants.FETCH_OPPORTUNITY_OPPORTUNITY_ERROR]: fetchOpportunityOpportunityError,
    [Constants.CREATE_OPPORTUNITY_OPPORTUNITY_REQUEST]: createOpportunityOpportunityRequest,
    [Constants.CREATE_OPPORTUNITY_OPPORTUNITY_SUCCESS]: createOpportunityOpportunitySuccess,
    [Constants.CREATE_OPPORTUNITY_OPPORTUNITY_ERROR]: createOpportunityOpportunityError,
    [Constants.UPDATE_OPPORTUNITY_OPPORTUNITY_REQUEST]: updateOpportunityOpportunityRequest,
    [Constants.UPDATE_OPPORTUNITY_OPPORTUNITY_SUCCESS]: updateOpportunityOpportunitySuccess,
    [Constants.UPDATE_OPPORTUNITY_OPPORTUNITY_ERROR]: updateOpportunityOpportunityError,
    [Constants.DELETE_OPPORTUNITY_OPPORTUNITY_REQUEST]: deleteOpportunityOpportunityRequest,
    [Constants.DELETE_OPPORTUNITY_OPPORTUNITY_SUCCESS]: deleteOpportunityOpportunitySuccess,
    [Constants.DELETE_OPPORTUNITY_OPPORTUNITY_ERROR]: deleteOpportunityOpportunityError,
    [Constants.UPDATE_MULTIPLE_OPPORTUNITY_OPPORTUNITIES_REQUEST]: updateMultipleOpportunityOpportunitiesRequest,
    [Constants.UPDATE_MULTIPLE_OPPORTUNITY_OPPORTUNITIES_SUCCESS]: updateMultipleOpportunityOpportunitiesSuccess,
    [Constants.UPDATE_MULTIPLE_OPPORTUNITY_OPPORTUNITIES_ERROR]: updateMultipleOpportunityOpportunitiesError,
    [Constants.LIST_TRANSACTION_EMAIL_REQUEST]: listTransactionEmailRequest,
    [Constants.LIST_TRANSACTION_EMAIL_SUCCESS]: listTransactionEmailSuccess,
    [Constants.LIST_TRANSACTION_EMAIL_ERROR]: listTransactionEmailError,
    [Constants.UPDATE_TRANSACTION_EMAIL_REQUEST]: updateTransactionEmailRequest,
    [Constants.UPDATE_TRANSACTION_EMAIL_SUCCESS]: updateTransactionEmailSuccess,
    [Constants.UPDATE_TRANSACTION_EMAIL_ERROR]: updateTransactionEmailError,
    [Constants.SET_IS_SAVING_AS_DRAFT]: setIsSavingAsDraft,
};


export default createReducer(INITIAL_STATE, HANDLERS);
