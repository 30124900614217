import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  listNotiRequest: ['data'],
  listNotiSuccess: ['notifications', 'total', 'unreadCount', 'message'],
  listNotiFailure: ['error'],
  updateNotiRequest: ['data'],
  updateNotiSuccess: ['message'],
  updateNotiFailure: ['error'],
  setAwsNotificationMessage: ['data'],
})

export const Constants = Types
export default Creators
