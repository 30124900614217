import Brandlogo from '../../../assets/images/dashboardLogo.png'
import Library1 from '../../../assets/images/illujoin-meeting.png'
import Library2 from '../../../assets/images/illuflip_lib.png'
import MagzineSlider1 from '../../../assets/images/slider-image-1.png'
import Profile from '../../../assets/images/undraw_profile.svg'

export default {
  signInForm: {
    bannerHeading: 'THIS IS A PLACEHOLDER BANNER',
    formHeading: 'Illumeet Account',
    emailLabel: 'Email',
    emailInputPlaceholder: 'Enter a email',
    passwordLabel: 'Password',
    passwordInputPlaceholder: 'Enter password',
    saveButtonLabel: 'Login',
    anchorTagLabel: 'Illumeet Privacy',
    anchorTagLink: 'https://policies.google.com/privacy',
    backgroundColor: '#f1333a',
    color: '#f1333a',
    backgroundImage: '',
    fontFamily: 'Montserrat',
  },
  brandProfileInfo: {
    name: 'brandProfileInfo',
    logo: Brandlogo,
    profilePic: Profile,
  },
  introVideoSection: {
    bannerHeading: 'Watch Intro Video',
    bannerParagraph:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium',
    src: 'https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4',
    backgroundImage: '',
  },
  illuFlipVideo: {
    heading: 'video library',
    img: Library1,
  },
  librarySection: {
    heading: 'pdf library',
    iframeCode:
      'https://www.flipbookpdf.net/web/site/2ffb0d2928b0d553dfa12b0061196de43cc5840c202102.pdf.html#page/4',
    img: Library2,
  },
  magazineCarousel: [
    {
      img: MagzineSlider1,
      label: 'fashion 1',
      magazineTitle: 'V Mag 1',
      magazineLink: 'https://www.google.com/',
      edition: 'Sexy Magazine | Halloween Edition 2019',
    },
    {
      img: MagzineSlider1,
      label: 'fashion 2',
      magazineTitle: 'V Mag 2',
      magazineLink: 'https://www.google.com/',
      edition: 'Sexy Magazine | Halloween Edition 2019',
    },
    {
      img: MagzineSlider1,
      label: 'fashion 3',
      magazineTitle: 'V Mag 3',
      magazineLink: 'https://www.google.com/',
      edition: 'Sexy Magazine | Halloween Edition 2019',
    },
    {
      img: MagzineSlider1,
      label: 'fashion 4',
      magazineTitle: 'V Mag 4',
      magazineLink: 'https://www.google.com/',
      edition: 'Sexy Magazine | Halloween Edition 2019',
    },
  ],
}

export const defaultChangedValues = {
  signInForm: {},
  brandProfileInfo: {},
  introVideoSection: {},
  illuFlipVideo: {},
  librarySection: {},
  magazineCarousel: [],
}
