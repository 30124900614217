import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Button from '@material-ui/core/Button';
import { createNotification } from 'helpers';
import ExportIcon from '../../../assets/images/icons/export.svg';
import { UserData } from 'Redux/API';
import ProgressBar from 'Common/ProgressBar';
import SuccessAlertPopUp from 'Common/SuccessAlertPopUp';
import InvalidIcon from '../../../assets/images/icons/invalid.svg';
import { theme } from '../../../theme/theme';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { Stack } from '@mui/material';
import OpportunityAccountsAPI from 'Redux/API/opportunity/opportunityAccountsAPI';
import OpportunityOpportunitiesAPI from 'Redux/API/opportunity/opportunityOpportunitiesAPI';
import OpportunityContactsAPI from 'Redux/API/opportunity/opportunityContactsAPI';
import AIChatbotAPI from 'Redux/API/aiChatbotAPI';
import { useParams } from 'react-router';

const BACKEND_API_URL = process.env.REACT_APP_API_URL;

type Props = {
  open: boolean;
  tab: string;
  handleOpen: (isOpen: boolean) => void;
  fetchAllOpportunityData: () => void;
};

type Errors = {
  file: String;
};

type ResponsefromAPIOnError = {
  alreadyExistInDb: number;
  duplicateNameInCsv: number;
  message: number;
  projectNotExists: number;
  rejectedNames: number;
  success: boolean;
  status: number;
  invalidSource: number;
  invalidNamesRejection: number;
  userNotExists?: number;
  invalidStatus?: number;
  accountNotExist?: number;
  invalidHeaders?: number;
  emptyMandatoryCustomFields?: number;
  duplicateEmailsInCsv?: number;
  contactsNotExist?: number;
  accountsNotExist?: number;
  invalidEmail?: number;
  invalidNumber?: number;
  invalidDate?: number;
  invalidUrls?: number;
  invalidListCfValues?: number;
  invalidBooleans?: number;
  contactGroupNotExists?:number;
  emptyAnswers?:number;
  duplicateQuestionsInCsv?:number;
  emptyQuestions?:number;
  invalidUsers?:number;
};

function ImportModal({
  open,
  tab,
  handleOpen,
  fetchAllOpportunityData,
}: Props) {
  const fileInputRef = useRef(null);
  const params: {id: string} = useParams()
  const [csv, setCsv] = useState<File | null>(null);
  const [errors, setErrors] = useState<Errors>({} as Errors);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [isImportInProgress, setIsImportInProgress] = useState<boolean>(false);
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [openValidationPopup, setOpenValidationPopup] =
    useState<boolean>(false);
  const [responseFromAPI, setResponseFromAPI] =
    useState<ResponsefromAPIOnError>({} as ResponsefromAPIOnError);
    const [uniqueData, setUniqueData] = useState<number>(0);

  useEffect(() => {
    if (!open) {
      setCsv(null);
    }
    setErrors({} as Errors)
  }, [open]);

  useEffect(() => {
    if (progressPercentage === 100) {
      fetchAllOpportunityData();
      setTimeout(() => {
        setShowProgress(false);
        setOpenSuccess(true);
        setProgressPercentage(0);
        setIsImportInProgress(false);
      }, 500);
    }
  }, [progressPercentage]);

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const csvFile = event.target.files[0];

      if (!csvFile.name.match(/\.(csv)$/)) {
        createNotification('error', 'Please select valid csv file.');
        return false;
      }
      setCsv(event.target.files[0]);
    }
  };

  const downloadCsvFile = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    try {
      e.preventDefault();
    let res;
    if (tab === 'accounts') {
      const opportunityAccountsAPI = new OpportunityAccountsAPI();
      // @ts-ignore
      res = await opportunityAccountsAPI.downloadSampleCSVFile();
    }
    if (tab === 'opportunities') {
      const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
      // @ts-ignore
      res = await opportunityOpportunitiesAPI.downloadSampleCSVFile();
    }
    if (tab === 'contacts') {
      const opportunityContactsAPI = new OpportunityContactsAPI();
      // @ts-ignore
      res = await opportunityContactsAPI.downloadSampleCSVFile();
    }

    if(tab === 'question and answers'){
      const aiChatbotAPI = new AIChatbotAPI()
      res = await aiChatbotAPI.downloadSampleCSVFile();
    }

    if (res?.data.csvData) {
      const url = window.URL.createObjectURL(new Blob([res?.data?.csvData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Sample.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
    } catch (error: any) {
      console.log(error)
      createNotification("error", error.message)
    }
    
  };

  const handleImportSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (isValidCsv()) {
      if (csv) {
        const formData = new FormData();
        formData.append('csv', csv);
        let res;
        if (tab === 'accounts') {
          const opportunityAccountsAPI = new OpportunityAccountsAPI();
          // @ts-ignore
          res = await opportunityAccountsAPI.importData(formData);
        }
        if (tab === 'opportunities') {
          const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
          // @ts-ignore
          res = await opportunityOpportunitiesAPI.importData(formData);
        }
        if (tab === 'contacts') {
          const opportunityContactsAPI = new OpportunityContactsAPI();
          // @ts-ignore
          res = await opportunityContactsAPI.importData(formData);
        }
        if (tab === 'question and answers') {
          const aiChatbotAPI = new AIChatbotAPI()
          res = await aiChatbotAPI.importData(formData, params);
        }

        if (res?.data.status === 200) {
          if (res?.data.importProgressId) {
            getProgressCount(res?.data.importProgressId);
            setIsImportInProgress(true);
            setUniqueData(res?.data.uniqueData)
          }
        } else if (res?.data.status === 201) {
          setResponseFromAPI(res?.data);
          setOpenValidationPopup(true);
          if(fileInputRef.current){
            // @ts-ignore
            fileInputRef.current.value = ""
          }
          setCsv(null);
        }
      }
    }
  };

  const isValidCsv = () => {
    let error: Errors = {} as Errors;
    let formIsValid = true;

    if (!csv) {
      formIsValid = false;
      error['file'] = '*Please select csvfile.';
    }
    setErrors(error);
    return formIsValid;
  };

  const getProgressCount = async (importProgressId: string) => {
    const users: any = await UserData();
    const reqUrl = tab !== "question and answers" ? "opportunityManagement/importProgress" : "chatbot/importProgress"
    const requestUrl =  `${BACKEND_API_URL}/${reqUrl}/${importProgressId}?token=bearer ${users.token}`
   
    const eventSource = new EventSource(
      requestUrl
    );
    eventSource.onmessage = (event) => {
      //we will get error if any error occured in the import|copy progress
      if (event.data === 'error') {
        //closing eventSource connection
        eventSource.close();
        //hiding progress bar popup
        setShowProgress(false);
        setProgressPercentage(0);
        setIsImportInProgress(false);
      } else {
        const progress = parseInt(event.data);
        setProgressPercentage(progress);
        setShowProgress(true);
        //if the progress completed
        if (progress === 100) {
          //close progress popup & open successful messsage after some delay
          eventSource.close();
        }
      }
    };
  };

  const hideProgress = () => {
    setShowProgress(false);
  };

  const handleCloseImportSuccessPopup = () => {
    handleOpen(false);
    setOpenSuccess(false);
    setCsv(null);
    setOpenValidationPopup(false);
  };

  const handleClickOpenImportModal = () => {
    handleOpen(true);
    setOpenValidationPopup(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => handleOpen(false)}
        classNames={{
          overlay: '',
          modal: 'Invite-User-Modal',
        }}
        center
      >
        <Grid component="div" container className="modal-wrapper">
          <Grid item xs={12} pb={3}>
            <Box component="p" mb={1} className="subtitle">
              Import {tab}
            </Box>
            <p className="small-subtitle">
              Please upload a CSV file to import a list of {tab}
            </p>
          </Grid>
          <Grid item xs={12} pb={4}>
            <Box component="div" className="inputField" pb={1}>
              <label>
                Select File <span className="required">*</span>
              </label>
              <input
                type="file"
                className="form-control small"
                placeholder=""
                id="attendeeFile"
                name="attendeeFile"
                onChange={(e) => onFileChange(e)}
                accept=".csv"
                ref={fileInputRef}
              />
            </Box>
            <span className="error_mesage required"> {errors.file} </span>
            <Box component="label" display="flex" alignItems="center">
              <img alt="" src={ExportIcon} />
              <Box
                component="a"
                href=""
                className="secondary-text small-subtitle"
                pl={1}
                onClick={(e) => {
                  downloadCsvFile(e);
                }}
              >
                Download Sample CSV to import
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="end">
              <Stack spacing={1} direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={(e) => handleImportSubmit(e)}
                >
                  Import
                </Button>
                <Button variant="outlined" onClick={() => handleOpen(false)}>
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={showProgress}
        onClose={hideProgress}
        classNames={{
          overlay: '',
          modal: 'Invite-User-Modal',
        }}
        center
      >
        <ProgressBar
          progressPercentage={progressPercentage}
          import={isImportInProgress}
        />
      </Modal>
      <SuccessAlertPopUp
        openModal={openSuccess}
        closeModalFunc={handleCloseImportSuccessPopup}
        title="Import Successful!"
        description={`Great Job! You have successfully imported ${uniqueData} ${
          tab === 'accounts'
            ? 'accounts'
            : tab === 'contacts'
            ? 'contacts'
            : 'opportunities'
        }`}
        buttonColor="primary"
        buttonFunc={handleCloseImportSuccessPopup}
        buttonText="Done"
      />
      <Modal
        open={openValidationPopup}
        onClose={handleCloseImportSuccessPopup}
        classNames={{
          overlay: '',
          modal: 'Invite-User-Modal',
        }}
        center
      >
        <Grid component="div" container className="modal-wrapper">
          <Box
            component={Grid}
            item
            xs={12}
            sx={{ borderBottom: '1px solid rgba(0, 0, 86, 0.23)' }}
            mb={4}
          >
            <Box component="p" mb={0} className="subtitle">
              Validation Result!
            </Box>
            <p className="small-subtitle">
              Please make adjustments in the sheet and then retry.
            </p>
          </Box>
          <Grid item xs={12} pb={4}>
            <Box
              component="div"
              sx={{
                border: '1px solid rgba(0, 0, 86, 0.23)',
                borderRadius: '4px',
              }}
            >
              <Box
                component={Grid}
                p={1.25}
                sx={{ borderBottom: '1px solid rgba(0, 0, 86, 0.23)' }}
                container
              >
                <Grid item pl={2}>
                  <img alt="" src={InvalidIcon} />
                </Grid>
                <Grid item>
                  <Box
                    pl={1}
                    component={'strong'}
                    className=""
                    color={theme.palette.common.black}
                  >
                    Invalid {'' + '(' + responseFromAPI.rejectedNames + ')'}
                  </Box>
                </Grid>
              </Box>
              <Box
                className="custom-fields-table"
                p={1.25}
                sx={{ maxHeight: '200px' }}
                component={TableContainer}
              >
                <Table aria-label="simple table">
                  <TableBody>
                    {tab !== 'question and answers' && <>
                      <TableRow>
                        <TableCell className="tableBodyCell" scope="row">
                          <strong>
                            Duplicate {tab === 'contacts' ? 'emails' : tab} in
                            file
                          </strong>{' '}
                        </TableCell>
                        <TableCell className="tableBodyCell" align="right">
                          {tab === 'contacts'
                            ? responseFromAPI.duplicateEmailsInCsv
                            : responseFromAPI.duplicateNameInCsv}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell className="tableBodyCell" scope="row">
                          <strong>Mandatory Custom Fields Value Missing</strong>{' '}
                        </TableCell>
                        <TableCell className="tableBodyCell" align="right">
                          {responseFromAPI.emptyMandatoryCustomFields}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="tableBodyCell" scope="row">
                          <strong>Invalid emails</strong>{' '}
                        </TableCell>
                        <TableCell className="tableBodyCell" align="right">
                          {responseFromAPI.invalidEmail}
                        </TableCell>
                        <TableRow></TableRow>
                      </TableRow>
                    </>}
                    {tab === 'contacts' && (
                      <>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Contact group not exists</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.contactGroupNotExists}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {tab === 'accounts' && (
                      <>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Already Exists</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.alreadyExistInDb}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {tab !== 'question and answers' && (
                      <>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Invalid numbers</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.invalidNumber}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Invalid dates</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.invalidDate}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Invalid URLs</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.invalidUrls}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Invalid Boolean Inputs</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.invalidBooleans}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Invalid List Inputs</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.invalidListCfValues}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Invalid Users</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.invalidUsers}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {tab !== 'contacts' && tab !== 'question and answers' && (
                      <>
        
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Projects Not Found</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.projectNotExists}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Contacts Not Found</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.contactsNotExist}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {tab === 'opportunities' && (
                      <>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Missing Opportunity Names</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.invalidNamesRejection}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Accounts Not Found</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.accountsNotExist}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Assigned To Not Found</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.userNotExists}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {tab === 'contacts' && (
                      <>
                        <TableRow>
                          {/* <TableCell className="tableBodyCell" scope="row">
                            <strong>Emails Already Exist</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.alreadyExistInDb}
                          </TableCell> */}
                        </TableRow>
                      </>
                    )}
                    {tab === 'question and answers' && (
                      <>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Already Exists</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.alreadyExistInDb}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Empty Questions</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.emptyQuestions}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Empty Answers</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.emptyAnswers}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>Duplicate Questions</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {responseFromAPI.duplicateQuestionsInCsv}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="end">
              <Stack spacing={1} direction="row">
                <Button
                  variant="outlined"
                  color="primary"
                  disableElevation
                  onClick={handleClickOpenImportModal}
                >
                  Retry
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={handleCloseImportSuccessPopup}
                >
                  Close
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}

export default ImportModal;
