import React, { Component } from 'react'
import '../../sass/main.scss'
import { Button } from '@material-ui/core'
import moment from 'moment'

const AgendaView = ({ agendas = [] }) => {
  return (
    <div className="viewPopupAgendaList">
      <label>Selected Agendas:</label>
      {agendas.map((agenda, i) => (
        <p>
          {i + 1}.{agenda.title}
        </p>
      ))}
    </div>
  )
}

class ViewModal extends Component {
  state = {
    keys: [],
  }

  componentDidMount() {
    const { data: { user = {} } = {}, eventId } = this.props
    let keys = Object.keys(user).filter(
      (key) =>
        key !== 'standford_policy' &&
        key !== 'illumeet_privacy' &&
        !key.includes('agenda'),
    )
    const agendas = user.agenda || []
    this.setState({ keys, agendas })
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  render() {
    const { data: { user = {} } = {}, onClose } = this.props 
    const { keys = [], agendas } = this.state 
    return (
      <div>
        <h2> View Details </h2>
        <div className="viewDetailsLists">
          <div class="agendaViewtable">
            <ul>
              {keys.map((key) => (
                <li>
                  <h4>{key.toUpperCase()}:</h4> <span>{user[key]}</span>
                </li>
              ))}
              <li>
                <h4>Created At:</h4>{' '}
                <span>
                  {moment(this.props.data['createdAt']).format(
                    'dddd, MMM, Do YYYY, LT',
                  )}
                </span>
              </li>
            </ul>
            <AgendaView agendas={agendas} />
          </div>
          <div className="editProfileBtn submitForm text-center mt-5">
            <Button type="submit" className="themeBlueButton" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default ViewModal
