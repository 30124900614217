import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  createVanityDomainRequest: ['vanityDomain'],
  createVanityDomainSuccess: ['vanityDomain', 'message'],
  createVanityDomainFailure: ['error'],

  removeVanityDomainRequest: ['eventId'],
  removeVanityDomainSuccess: ['vanityDomain', 'message'],
  removeVanityDomainFailure: ['error'],
})
const Constants = Types
export { Creators as VanityDomainCreators, Constants as VanityDomainConstants }
