import { Button } from '@material-ui/core'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { createNotification, redirectTo, formatNumber } from '../../helpers'

class EventAnalytics extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { data, eventId, templateId } = this.props 
    return (
      <div className="dashboardMiddleArea">
        <div className="eventAnalyticsPage">
          <div className="createProjectAccount projectStepRow mobileBaseMargin20px">
            <div className="projectCreateSection">
              <div className="CreatePresentation">
                <div className="eventAnalticsBoxes">
                  {/* <div className="eventAnalticsList">
                                            <div className="CRCircularBox">
                                                <h3 className="eventAnaHeading">Email Communications</h3>
                                                <div className="eventAnaListInner">
                                                    <p>
                                                        <span>Total Sent</span>
                                                        <span>{this.props.data.sent && formatNumber(this.props.data.sent)}</span>
                                                    </p>
                                                    <p>
                                                        <span>Opened</span>
                                                        <span>{this.props.data.open && formatNumber(this.props.data.open)}</span>
                                                    </p>
                                                    <p>
                                                        <span>Bounced</span>
                                                        <span>{this.props.data.bounce && formatNumber(this.props.data.bounce)}</span>
                                                    </p>
                                                    <p>
                                                        <span>Click Rate</span>
                                                        <span>{this.props.data.clickRate && formatNumber(this.props.data.clickRate)}%</span>
                                                    </p>
                                                    <p>
                                                        <span>Spam Reports</span>
                                                        <span>{this.props.data.spam && formatNumber(this.props.data.spam)}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}
                  {/* <div className="eventAnalticsList">
                                            <h3 className="eventAnaHeading">Event Registration</h3>
                                            <div className="eventAnaListInner">
                                                <p>
                                                    <span>Total Registered</span>
                                                    <span>{this.props.data.totalRegistered && formatNumber(this.props.data.totalRegistered)}</span>
                                                </p>
                                                <p>
                                                    <span>Pending</span>
                                                    <span>{this.props.data.pending && formatNumber(this.props.data.pending)}</span>
                                                </p>
                                                <p>
                                                    <span>Opened Invitations</span>
                                                    <span>{this.props.data.open && formatNumber(this.props.data.open)}</span>
                                                </p>
                                                <p>
                                                    <span>Bounced Invitations</span>
                                                    <span>{this.props.data.bounce && formatNumber(this.props.data.bounce)}</span>
                                                </p>
                                                <p>
                                                    <span>Unopened Invitations</span>
                                                    <span>{this.props.data.unOpen && formatNumber(this.props.data.unOpen)}</span>
                                                </p>
                                            </div>
                                        </div> */}
                </div>
              </div>
            </div>
            {/* <div class="submitForm mt-5 justify-content-center">
                           {eventId !=='' && templateId !=='' && 
                           <Button className="themeBlueButton" onClick={()=>redirectTo(this.props.history,`email-performance/${eventId}/${templateId}`,this.props.location.pathname)}>View Analytics</Button>}
                           {eventId !=='' && templateId ==='' && 
                           <Button className="themeBlueButton" onClick={()=>redirectTo(this.props.history,`email-performance/${eventId}`,this.props.location.pathname)}>View Analytics</Button>}
                           {eventId ==='' && templateId ==='' && 
                           <Button className="themeBlueButton" onClick={()=>redirectTo(this.props.history,`email-performance`,this.props.location.pathname)}>View Analytics</Button>}
                        </div> */}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(EventAnalytics)
