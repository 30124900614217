import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'
import { connect } from 'react-redux';
class ApexChart extends React.Component {
  constructor(props) {
    super(props)
    let max1 = Math.max(...this.props.totalBounced)
    let max2 = Math.max(...this.props.deliveredArray)
    let max3 = Math.max(...this.props.rejectArray)
    let fmax = Math.max(max1, max2, max3)

    this.state = {
      series: [
        {
          name: 'Delivered Emails',
          data: this.props.deliveredArray,
        },
        {
          name: 'Bounced Email',
          data: this.props.totalBounced,
        },        
        {
          name: 'Reject Emails',
          data: this.props.rejectArray,
        },
      ],
      legend: {
        position: 'bottom',
      },
      options: {
        chart: {
          height: 350,
          type: 'area',
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#28ed56', '#2846ed', '#b51834'],
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        title: {
          text: 'Emails delivered, bounced and rejected',
          align: 'center',
          margin: 20,
          style: {
            fontSize: '20px',
            fontWeight: '400',
            fontFamily: 'Rubik',
            color: '#222025',
          },
        },
        xaxis: {
          type: 'string',
          categories: [
            'Today',
            'Last 7 days',
            'Last 30 days',
            'Last 60 days',
            'Last 90 days',
          ],
          title: {
            text: 'Days',
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
        yaxis: {
          title: {
            text: 'Count',
          },
          tickAmount: 4,
          min: 1,
          max: fmax,
          axisBorder: {
            show: true,
            color: 'rgb(243, 243, 243)',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    }
  }

  componentDidMount() {
    let max1 = Math.max(...this.props.totalBounced)
    let max2 = Math.max(...this.props.deliveredArray)
    let max3 = Math.max(...this.props.rejectArray)
    let fmax = Math.max(max1, max2, max3)

    this.setState({
      series: [
        {
          name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.deliveredMail,
          data: this.props.deliveredArray,
        },
        {
          name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.bouncedEmails,
          data: this.props.totalBounced,
        },          
        {
          name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.rejectedEmail,
          data: this.props.rejectArray,
        },
      ],
      legend: {
        position: 'bottom',
      },
      options: {
        chart: {
          height: 350,
          type: 'area',
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#28ed56', '#2846ed', '#b51834'],
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        title: {
          text: this.props.users.languageStateForRedux?.formfields?.campaigns?.graphHeading2,
          align: 'center',
          margin: 20,
          style: {
            fontSize: '20px',
            fontWeight: '400',
            fontFamily: 'Rubik',
            color: '#222025',
          },
        },
        xaxis: {
          type: 'string',
          categories: [
            this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.today,
            this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last7,
            this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last30,
            this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last60,
            this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last90,
          ],
          title: {
            text: this.props.users.languageStateForRedux?.overviewTab?.days,
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
        yaxis: {
          title: {
            text: this.props.users.languageStateForRedux?.overviewTab?.count,
          },
          tickAmount: 4,
          min: 1,
          max: fmax,
          axisBorder: {
            show: true,
            color: 'rgb(243, 243, 243)',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    })
  }

  componentDidUpdate(prevProps) {
    if(this.props.users.languageStateForRedux !== prevProps.users.languageStateForRedux){
      let max1 = Math.max(...this.props.totalBounced)
      let max2 = Math.max(...this.props.deliveredArray)
      let max3 = Math.max(...this.props.rejectArray)
      let fmax = Math.max(max1, max2, max3)
      
      this.setState({
        series: [
          {
            name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.deliveredMail,
            data: this.props.deliveredArray,
          },
          {
            name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.bouncedEmails,
            data: this.props.totalBounced,
          },          
          {
            name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.rejectedEmail,
            data: this.props.rejectArray,
          },
        ],
        legend: {
          position: 'bottom',
        },
        options: {
          chart: {
            height: 350,
            type: 'area',
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#28ed56', '#2846ed', '#b51834'],
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          title: {
            text: this.props.users.languageStateForRedux?.formfields?.campaigns?.graphHeading2,
            align: 'center',
            margin: 20,
            style: {
              fontSize: '20px',
              fontWeight: '400',
              fontFamily: 'Rubik',
              color: '#222025',
            },
          },
          xaxis: {
            type: 'string',
            categories: [
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.today,
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last7,
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last30,
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last60,
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last90,
            ],
            title: {
              text: this.props.users.languageStateForRedux?.overviewTab?.days,
            },
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
          yaxis: {
            title: {
              text: this.props.users.languageStateForRedux?.overviewTab?.count,
            },
            tickAmount: 4,
            min: 1,
            max: fmax,
            axisBorder: {
              show: true,
              color: 'rgb(243, 243, 243)',
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      })
    }
    if (
      prevProps.deliveredArray !== this.props.deliveredArray ||
      prevProps.totalBounced !== this.props.totalBounced ||
      prevProps.rejectArray !== this.props.rejectArray
    ) {
      let max1 = Math.max(...this.props.totalBounced)
      let max2 = Math.max(...this.props.deliveredArray)
      let max3 = Math.max(...this.props.rejectArray)
      let fmax = Math.max(max1, max2, max3)
      
      this.setState({
        series: [
          {
            name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.deliveredMail,
            data: this.props.deliveredArray,
          },
          {
            name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.bouncedEmails,
            data: this.props.totalBounced,
          },          
          {
            name: this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.rejectedEmail,
            data: this.props.rejectArray,
          },
        ],
        legend: {
          position: 'bottom',
        },
        options: {
          chart: {
            height: 350,
            type: 'area',
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#28ed56', '#2846ed', '#b51834'],
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          title: {
            text: this.props.users.languageStateForRedux?.formfields?.campaigns?.graphHeading2,
            align: 'center',
            margin: 20,
            style: {
              fontSize: '20px',
              fontWeight: '400',
              fontFamily: 'Rubik',
              color: '#222025',
            },
          },
          xaxis: {
            type: 'string',
            categories: [
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.today,
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last7,
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last30,
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last60,
              this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last90,
            ],
            title: {
              text: this.props.users.languageStateForRedux?.overviewTab?.days,
            },
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
          yaxis: {
            title: {
              text: this.props.users.languageStateForRedux?.overviewTab?.count,
            },
            tickAmount: 4,
            min: 1,
            max: fmax,
            axisBorder: {
              show: true,
              color: 'rgb(243, 243, 243)',
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      })
    }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={350}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  users: state.Users,
});

export default connect(mapStateToProps, null)(ApexChart);

