import { Constants } from "Redux/Actions";
import { createReducer } from "reduxsauce";

export type XpUsersWebSocket_State = {
    connected: boolean,
    message: any
};

const INITIAL_STATE: XpUsersWebSocket_State = {
    connected: false,
    message: {}
};

const changeXpUsersWebSocketConnectionStatus = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,      
        connected: action.connected
    };
};

const setXpUsersWebSocketConnectionMessage = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        message: action.message
    };
};

const HANDLERS = {
    [Constants.CHANGE_XP_USERS_WEB_SOCKET_CONNECTION_STATUS]: changeXpUsersWebSocketConnectionStatus,
    [Constants.SET_XP_USERS_WEB_SOCKET_CONNECTION_MESSAGE]: setXpUsersWebSocketConnectionMessage
};

export default createReducer(INITIAL_STATE, HANDLERS);