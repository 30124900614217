import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { Box } from '@mui/material';
import { ContactState } from 'Redux/Reducers/opportunity/contacts.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'Redux/Actions';
import { getSystemLevelPermissions } from '../../../../helpers/common';
import { createNotification } from '../../../../helpers';


type Props = {
  contactId: string;
  handleTabChangeFromMainLayout: (
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    searchText: string,
  ) => void;
};

function LinkedAccounts({ contactId, handleTabChangeFromMainLayout }: Props) {
  const dispatch = useDispatch();
  const initialRender = useRef(true);
  const { isLoading: isContactsLoading, openedContact } = useSelector(
    (state: any) => state.OpportunityContacts,
  ) as ContactState;

  const [sortBy, setSortBy] = useState<string>('account.name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [allowed, setAllowed] = useState<any>({});

  useEffect(() =>{
    setAllowed(getSystemLevelPermissions());
  },[])

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      fetchContact(contactId);
    }
  }, [sortBy, sortOrder]);

  const fetchContact = async (contactId: string) => {
    const params = {
      id: contactId,
      sortAccountBy: sortBy,
      sortAccountOrder: sortOrder,
    };
    // const bodyParams = {
    //   sortAccountBy: sortBy,
    //   sortAccountOrder: sortOrder,
    // };

    dispatch(Actions.fetchOpportunityContactRequest(params));
  };

  const handleSorting = (sort_by: string) => {
    setSortBy(sort_by);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <Box>
      <TableContainer className="list-table">
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell
                className={
                  'long-text sticky-cell ' +
                  (sortBy === 'account.name'
                    ? sortOrder === 'asc'
                      ? 'sort-asc'
                      : 'sort-des'
                    : '')
                }
                onClick={() => handleSorting('account.name')}
              >
                Account Name
              </TableCell>
              <TableCell
                className={
                  'text' +
                  (sortBy === 'contactDataTypeCf.fieldName'
                    ? sortOrder === 'asc'
                      ? 'sort-asc'
                      : 'sort-des'
                    : '')
                }
                onClick={() => handleSorting('contactDataTypeCf.fieldName')}
              >
                Contact Type
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(openedContact.accountAndContactDataType) &&
              openedContact.accountAndContactDataType.length > 0 ?
              openedContact.accountAndContactDataType.map((obj, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell className="email sticky-cell link" onClick={(e) =>{
                       if (allowed['viewAccount']){
                      handleTabChangeFromMainLayout(e, '2', obj.account.name)
                       }else{
                        createNotification('error', "Sorry! You don't have permission to view this account");
                       }
                    }}>
                      {' '}
                      {obj.account.name}
                    </TableCell>

                    <TableCell className="text">
                      {obj.contactDataTypeCf.fieldName}
                    </TableCell>
                  </TableRow>
                );
              })
              :
              <TableRow>
                <TableCell colSpan={2} align='center'>
                  No Records
                </TableCell>
              </TableRow>
            }

            {/* <TableRow>
              <TableCell className="email sticky-cell link">
                {' '}
                Streamline Dynamics
              </TableCell>

              <TableCell className="text">HR Contact</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="email sticky-cell link">
                {' '}
                Streamline Dynamics
              </TableCell>

              <TableCell className="text">HR Contact</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="email sticky-cell link">
                {' '}
                Streamline Dynamics
              </TableCell>

              <TableCell className="text">HR Contact</TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default LinkedAccounts;
