import BaseAPI from './baseAPI'

class TawkToAPI extends BaseAPI {
  PropertyList = async () => {
    return this.API('integration/tawkto/property/list')
  }
  createProperty = async (data: any) => {
    return this.API('integration/tawkto/property/create', {
      method: 'POST',
      data: data,
    })
  }
  createWidget = async (widget: any) => {
    return this.API('integration/tawkto/property/widget/create', {
      method: 'POST',
      data: { widget },
    })
  }
  getWidget = async ({ query }: any) => {
    return this.API('integration/tawkto/property/widget', {
      method: 'GET',
      params: query,
    })
  }
  updateWidget = async (widget: any) => {
    return this.API('integration/tawkto/property/widget/update', {
      method: 'PUT',
      data: widget,
    })
  }
}
export default TawkToAPI
