import React, { Component } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../../../theme/theme'
import WebhooksIcon from '../../../assets/images/icons/WebhooksIcon.svg'
import AppIntegrationsIcon from '../../../assets/images/icons/gamification_configrations.svg'
import copyKey from '../../../assets/images/icons/copyKey.svg'
import refreshKey from '../../../assets/images/icons/refreshKey.svg'
import Button from '@material-ui/core/Button'
import '../../../sass/main.scss'
import Grid from '@mui/material/Grid'
import Box from '@material-ui/core/Box'
import Stack from '@mui/material/Stack'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import CustomSwitch from '../../../Components/customSwitch'
import webhook_icon from '../../../assets/images/icons/webhook_icon.svg'
import eventID_icon from '../../../assets/images/icons/eventID_icon.svg'
import date_icon from '../../../assets/images/icons/date_icon.svg'

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../../Redux/Actions'
import { withRouter } from 'react-router'
import { getProjectIdFromUrl } from '../../../helpers/common'
import moment from 'moment'
import { createNotification } from '../../../helpers'
import Loader from '../../../Components/Loader/loader'
import '../../../sass/main.scss'
import SearchIcon from '../../../assets/images/icons/search.svg'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Pagination from 'react-js-pagination'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Tab1 from '@mui/material/Tab'
import TabContext1 from '@mui/lab/TabContext'
import TabList1 from '@mui/lab/TabList'
import TabPanel1 from '@mui/lab/TabPanel'
import { Modal } from 'react-responsive-modal'
import AppIntegrations from './AppIntegration/apps'
import Applications from './Applications/Applications';


const styles = {
  customTabList: {
    padding: '0px 25px 0',
    background: '#000056',
  },
  customTab: {
    minHeight: 42,
    padding: '12px 16px',
    background: '#000056',
    color: '#ffffff',
  },
  customTabPanel: {
    height: 'calc(100vh - 148px)',
    overflow: 'auto',
  },
}

export class Integration extends Component {
  constructor(props) {
    super(props)
    this.anchorRefRefresh = React.createRef()
    this.state = {
      refresh: false,
      activeStatus: false,
      listOfWebHooks: [],
      page_no: 1,
      limit: 0,
      sort_by: 'updatedAt',
      order: 'asc',
      secretKey: '',
      loading: false,
      secretKeyExist: false,
      searchText: '',
      filterBy: '',
      status: '',
      startCount: 1,
      itemCount: 10,
      totalResults: 0,
      showError: false,
      webhookPopupData: [],
      selectedPopupTab: '1',
      payload: {},
      header: {},
      openChangeSecretModel: false,
      lastGenerated: '',
    }
  }

  componentDidMount() {
    if(this.props.allowed['webhooks']){
      const eventId = getProjectIdFromUrl()
      this.setState(
        {
          eventId,
        },
        () => {
          this.listWebhooks()
          this.checkWebhookEventId()
        },
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading } = this.state
    if (
      this.props.integration.listWebhooksSuccess &&
      !prevProps.integration.listWebhooksSuccess
    ) {
      this.setState(
        {
          listOfWebHooks: this.props.integration.listOfWebhooks,
        },
        () => {
          loading === true && this.handleLoading()
        },
      )
      this.updatePaginationDropDownState()
    }

    if (
      this.props.integration.toggleWebhookSuccess &&
      !prevProps.integration.toggleWebhookSuccess
    ) {
      if (this.props.integration?.webhookData?.secretKey) {
        this.setState(
          {
            secretKey: this.props.integration?.webhookData?.secretKey,
            lastGenerated:
              this.props.integration?.webhookData?.secretKeyUpdatedAt,
            activeStatus: true,
            secretKeyExist: true,
          },
          () => {
            this.listWebhooks()
          },
        )
      } else if (!this.props.integration?.activeStatus) {
        this.setState(
          {
            activeStatus: false,
          },
          () => {
            this.listWebhooks()
          },
        )
      } else {
        this.setState(
          {
            activeStatus: true,
          },
          () => {
            this.listWebhooks()
            this.checkWebhookEventId()
          },
        )
      }

      createNotification('success', this.props.integration.message)
      loading === true && this.handleLoading()
    }

    if (
      this.props.integration.generateSecretKeySuccess &&
      !prevProps.integration.generateSecretKeySuccess
    ) {
      createNotification('success', this.props.integration.message)
      this.setState(
        {
          secretKey: this.props.integration.secretKey,
        },
        () => {
          loading === true && this.handleLoading()
        },
      )
      this.closeChangeSecretModel()
      this.checkWebhookEventId()
    }

    if (
      this.props.integration.checkWebhookEventIdSuccess &&
      !prevProps.integration.checkWebhookEventIdSuccess
    ) {
      if (
        this.props.integration?.secretKeyExist &&
        this.props.integration?.secretKeyExist[0]?.activeStatus
      ) {
        this.setState({
          activeStatus: true,
        })
      } else {
        this.setState({
          activeStatus: false,
        })
      }

      if (
        this.props.integration?.secretKeyExist &&
        this.props.integration?.secretKeyExist[0]?.secretKey !== ''
      ) {
        this.setState({
          secretKeyExist: true,
          secretKey: this.props.integration.secretKeyExist[0]?.secretKey,
          lastGenerated:
            this.props.integration.secretKeyExist[0]?.secretKeyUpdatedAt,
        })
      } else {
        this.setState({
          secretKeyExist: false,
          secretKey: '',
        })
      }
    }

    if (this.props.integration.error && !prevProps.integration.error) {
      createNotification('error', this.props.integration.message)
      loading === true && this.handleLoading()
    }
  }

  updatePaginationDropDownState() {
    if (this.state.itemCount >= this.props.integration.totalResults) {
      this.setState({
        endCount: this.props.integration.totalResults,
      })
    } else {
      this.setState({
        endCount:
          this.props.integration.totalResults >
          this.state.itemCount * this.state.page_no
            ? this.state.itemCount * this.state.page_no
            : this.props.integration.totalResults,
      })
    }
  }

  listWebhooks = () => {
    try {
      const {
        page_no,
        itemCount,
        sort_by,
        order,
        eventId,
        searchText,
        status,
      } = this.state
      let requestBody = {
        page_no,
        limit: itemCount,
        sort_by,
        order,
        eventId,
        searchText:searchText.trim(),
        status,
      }
      this.props.listWebhooks(requestBody)
      this.handleLoading()
    } catch (error) {
      return error
    }
  }

  checkWebhookEventId = () => {
    try {
      const { eventId } = this.state
      const requestBody = {
        eventId,
      }
      this.props.checkWebhookEventId(requestBody)
    } catch (error) {
      return error
    }
  }

  setActiveStatusForToggle = () => {
    try {
      const { activeStatus, eventId } = this.state
      const requestBody = {
        activeStatus,
        eventId,
        lngCode:this.props.users.newLangState,
      }
      this.props.toggleWebhook(requestBody)
      this.handleLoading()
    } catch (error) {
      return error
    }
  }

  generateSecretKey = () => {
    try {
      const { eventId } = this.state
      const requestBody = {
        eventId,
        secretKeyUpdatedAt: new Date(),
        lngCode:this.props.users.newLangState,
      }
      this.props.generateSecretKey(requestBody)
      this.handleLoading()
    } catch (error) {
      return error
    }
  }

  handleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    })
  }

  handleClose = (event) => {
    this.setState({
      refresh: false,
    })
  }

  handleToggleRefresh = () => {
    this.setState({
      refresh: !this.state.refresh,
    })
  }

  handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      this.setState({
        refresh: false,
      })
    }
  }

  handleCustomSwitch = (e) => {
    if (e.target.checked) {
      this.setState(
        {
          activeStatus: true,
        },
        () => this.setActiveStatusForToggle(),
      )
    } else {
      this.setState(
        {
          activeStatus: false,
        },
        () => this.setActiveStatusForToggle(),
      )
    }
  }

  sorting = (value) => {
    if (this.state.sort_by === value) {
      if (this.state.order === 'asc') {
        this.setState(
          {
            order: 'desc',
          },
          () => this.listWebhooks(),
        )
      } else {
        this.setState(
          {
            order: 'asc',
          },
          () => this.listWebhooks(),
        )
      }
    } else {
      this.setState(
        {
          sort_by: value,
          order: 'asc',
        },
        () => this.listWebhooks(),
      )
    }
  }

  handleSearchSubmit = (e) => {
    try {
      e.preventDefault()
      if (
        this.state.searchText !== '' &&
        this.state.searchText.trim().length > 0
      ) {
        this.setState(
          {
            page_no: 1,
            startCount: 1,
          },
          () => {
            this.listWebhooks()
            this.handleLoading()
          },
        )
      } else {
        this.listWebhooks()
        this.handleLoading()
      }
    } catch (error) {
      createNotification('error', 'something went wrong')
    }
  }

  handleChange = (e) => {
    e.preventDefault()
    this.setState({
      searchText: e.target.value,
    })
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      selectedPopupTab: newValue,
    })
  }

  filterByStatus = (status) => {
    this.setState(
      {
        status,
      },
      () => this.listWebhooks(),
    )
  }

  handlePageChange = (pageNumber) => {
    const { itemCount, totalResults } = this.state
    this.setState(
      (prevState, props) => ({
        startCount: pageNumber !== 1 ? itemCount * (pageNumber - 1) + 1 : 1,
        endCount:
          totalResults > itemCount * pageNumber
            ? itemCount * pageNumber
            : totalResults,
        page_no: pageNumber,
        totalResults: this.props.integration.totalResults,
      }),
      () => {
        this.listWebhooks()
      },
    )
  }

  selectItemCount = (e) => {
    const { totalResults } = this.state
    this.setState(
      {
        page_no: 1,
        itemCount: e.target.value,
        endCount: parseInt(totalResults),
        startCount: 1,
      },
      () => this.listWebhooks(),
    )
  }

  handleCloseError = () => {
    this.setState({
      showError: false,
    })
  }

  showError = (webhook) => {
    this.setState({
      showError: true,
      webhookPopupData: webhook,
      payload: webhook.payload,
      header: webhook.headers,
    })
  }

  openChangeSecretModel = () => {
    this.setState({
      openChangeSecretModel: true,
    })
  }

  closeChangeSecretModel = () => {
    this.setState({
      openChangeSecretModel: false,
    })
  }

  viewProfilePicture = (img) => {
    var image = new Image()
    image.src = `data:${img.type};base64, ${img.data}`

    var w = window.open('')
    w.document.write(image.outerHTML)
  }

  render() {
    const {
      lastGenerated,
      header,
      payload,
      selectedPopupTab,
      activeStatus,
      listOfWebHooks,
      loading,
      sort_by,
      order,
      secretKeyExist,
      secretKey,
      status,
      itemCount,
      startCount,
      endCount,
      page_no,
      webhookPopupData,
    } = this.state
    return (
      <ThemeProvider theme={theme}>
        <Box className="container-with-sidebar" p={0}>
          <Tabs defaultIndex={this.props.allowed['webhooks'] ? 0: 1}>
            <Box
              className="project-sidebar"
              sx={{ background: 'transparent !important', marginTop: "0 !important" }}
            >
              <TabList>
                {this.props.allowed['webhooks'] &&
                  <Tab>
                    {' '}
                    <img src={WebhooksIcon} /> <a to={''}>{this.props.users.languageStateForRedux?.actions?.integration?.webhooks}</a>
                  </Tab>
                }
                {this.props.allowed['app integrations'] &&
                  <Tab>
                    {' '}
                    <img src={AppIntegrationsIcon} />{' '}
                    <a to={''}>{this.props.users.languageStateForRedux?.actions?.integration?.appInt}</a>
                  </Tab>
                }
                {
                //  this.props.allowed['app integrations'] &&
                  <Tab>
                    {' '}
                    <img src={AppIntegrationsIcon} />{' '}
                    <a to={''}>Applications</a>
                  </Tab>
                }
              </TabList>
            </Box>

            <div className="container-sidebar-right">
              {this.props.allowed['webhooks'] &&
                <TabPanel>
                  <Box
                    pb={1}
                    mt={0}
                    mb={2}
                    sx={
                      {
                        // borderBottom: '1px solid #EDECF5',
                      }
                    }
                  >
                    <Stack spacing={2} direction={'row'} alignItems={'center'}>
                      <Box className="secondary" mb={1}>
                        https://{this.props?.project?.project?.eventDomain}
                        .webhook.illumeetxp.com/
                      </Box>
                      <Box>
                        <CustomSwitch
                          color={'primary'}
                          onChange={this.handleCustomSwitch}
                          checked={activeStatus ? true : false}
                        />
                      </Box>
                    </Stack>

                    <Box className="paragraph text-primary-light">
                    {this.props.users.languageStateForRedux?.actions?.integration?.textAbove}<br></br>
                      {this.props.users.languageStateForRedux?.actions?.integration?.textBelow}
                    </Box>
                  </Box>
                  {secretKeyExist && (
                    <Box
                      sx={{
                        borderTop: '1px solid #EDECF5',
                        borderBottom: '1px solid #EDECF5',
                      }}
                      pt={2}
                      pb={2}
                      mb={3}
                    >
                      <Stack
                        spacing={2}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <Stack
                          spacing={1}
                          direction={'row'}
                          alignItems={'center'}
                        >
                          <CopyToClipboard text={secretKey}>
                            <Box
                              component={Button}
                              size="small"
                              variant="contained"
                              sx={{
                                backgroundColor: theme.palette.background.light,
                                color: '#000056',
                                textTransform: 'none',
                                fontWeight: '400',
                                '&:hover, &.active': {
                                  backgroundColor: '#000056 !important',
                                  color: theme.palette.primary.contrastText,
                                },
                              }}
                              endIcon={<img alt="" src={copyKey} />}
                            >
                              {secretKey}
                            </Box>
                          </CopyToClipboard>

                          <IconButton size="small">
                            <img
                              alt=""
                              src={refreshKey}
                              onClick={this.openChangeSecretModel}
                            />
                          </IconButton>
                        </Stack>
                        <Box>
                          <span className="small-subtitle">
                          {this.props.users.languageStateForRedux?.actions?.integration?.lastGen}&nbsp;
                            <span className="primary-text">
                              {moment
                                .tz(
                                  lastGenerated,
                                  this.props.project?.project?.timezone,
                                )
                                .format('LLLL')}
                            </span>
                          </span>
                        </Box>
                      </Stack>
                    </Box>
                  )}
                  <Box>
                    <Grid
                      container
                      spacing={2}
                      mb={2}
                      className="top-filter-area"
                    >
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Box
                              component={Button}
                              variant="contained"
                              sx={{
                                backgroundColor: theme.palette.background.light,
                                color: theme.palette.common.black,
                                textTransform: 'capitalize',
                                fontWeight: '400',

                                '&:hover, &.active': {
                                  backgroundColor: theme.palette.primary.dark,
                                  color: theme.palette.primary.contrastText,
                                },
                              }}
                              size="small"
                              className={status === '' ? 'active' : null}
                              onClick={() => this.filterByStatus('')}
                            >
                              {this.props.users.languageStateForRedux?.common?.all}
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box
                              component={Button}
                              variant="contained"
                              sx={{
                                backgroundColor: theme.palette.background.light,
                                color: theme.palette.common.black,
                                textTransform: 'capitalize',
                                fontWeight: '400',

                                '&:hover, &.active': {
                                  backgroundColor: theme.palette.primary.dark,
                                  color: theme.palette.primary.contrastText,
                                },
                              }}
                              size="small"
                              className={status === 'success' ? 'active' : null}
                              onClick={() => this.filterByStatus('success')}
                            >
                              {this.props.users.languageStateForRedux?.buttons?.integration?.succeed}
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box
                              component={Button}
                              variant="contained"
                              sx={{
                                backgroundColor: theme.palette.background.light,
                                color: theme.palette.common.black,
                                textTransform: 'capitalize',
                                fontWeight: '400',

                                '&:hover, &.active': {
                                  backgroundColor: theme.palette.primary.dark,
                                  color: theme.palette.primary.contrastText,
                                },
                              }}
                              size="small"
                              onClick={() => this.filterByStatus('failed')}
                              className={status === 'failed' ? 'active' : null}
                            >
                              {this.props.users.languageStateForRedux?.buttons?.integration?.failed}
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <form onSubmit={this.handleSearchSubmit}>
                              <TextField
                                name="searchText"
                                placeholder={this.props.users.languageStateForRedux?.common?.search}
                                className="search"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img alt="" src={SearchIcon} />
                                    </InputAdornment>
                                  ),
                                }}
                                value={this.state.searchText}
                                onChange={this.handleChange}
                              />
                            </form>
                          </Grid>
                          {/* <Grid item>
                                                      <Box component="div" sx={{ position: 'relative' }}>
                                                          <Button
                                                              fullWidth
                                                              variant="text"
                                                              style={{
                                                                  // padding: '0px 15px',
                                                                  justifyContent: 'space-between',
                                                                  textTransform: 'capitalize',
                                                                  // fontSize: '10px',
                                                              }}
                                                              size="small"
                                                              disableElevation
                                                              ref={this.anchorRefRefresh}
                                                              aria-controls={this.state.refresh ? 'menu-list-grow' : undefined}
                                                              aria-haspopup="true"
                                                              onClick={this.handleToggleRefresh}
                                                              endIcon={<ArrowDropDownIcon />}
                                                          >
                                                              Last 30 Days
                                                          </Button>
                                                          <Popper
                                                              open={this.state.refresh}
                                                              style={{ zIndex: '3' }}
                                                              placement="bottom-end"
                                                              transition
                                                              anchorEl={this.anchorRefRefresh.current}
                                                              role={undefined}
                                                              disablePortal
                                                          >
                                                              {({ TransitionProps, placement }) => (
                                                                  <Fade {...TransitionProps} timeout={250}>
                                                                      <Box
                                                                          sx={{
                                                                              maxHeight: '130px',
                                                                              overflow: 'scroll',
                                                                              backgroundColor: theme.palette.common.white,
                                                                              boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                                                              borderRadius: '4px',
                                                                              marginTop: '8px',
                                                                          }}
                                                                      >
                                                                          <ClickAwayListener onClickAway={this.handleClose}>
                                                                              <MenuList
                                                                                  autoFocusItem={this.state.refresh}
                                                                                  id="menu-list-grow"
                                                                                  onKeyDown={this.handleListKeyDown}
                                                                              >
                                                                                  <MenuItem className="checkbox-item">
                                                                                      Last 10 days
                                                                                  </MenuItem>
                                                                                  <MenuItem className="checkbox-item">
                                                                                      Last 20 days
                                                                                  </MenuItem>
                                                                                  <MenuItem className="checkbox-item">
                                                                                      Last 30 days
                                                                                  </MenuItem>
                                                                              </MenuList>
                                                                          </ClickAwayListener>
                                                                      </Box>
                                                                  </Fade>
                                                              )}
                                                          </Popper>
                                                      </Box>
                                                  </Grid> */}
                        </Grid>
                      </Grid>
                    </Grid>
                    <TableContainer
                      className="list-table"
                      style={{ maxHeight: 'calc(100vh - 540px)' }}
                    >
                      <Table className="table">
                        <TableHead
                          style={{
                            position: 'sticky',
                            top: '0',
                            backgroundColor: '#f6f6fc',
                            zIndex: 2,
                          }}
                        >
                          <TableRow>
                            <TableCell
                              className={
                                'long-text ' +
                                (sort_by === 'status'
                                  ? order === 'asc'
                                    ? 'sort-asc'
                                    : 'sort-des'
                                  : '')
                              }
                              onClick={() => this.sorting('status')}
                            >
                              {this.props.users.languageStateForRedux?.common?.status}
                            </TableCell>
                            <TableCell
                              className={
                                'long-text ' +
                                (sort_by === 'webhookType'
                                  ? order === 'asc'
                                    ? 'sort-asc'
                                    : 'sort-des'
                                  : '')
                              }
                              onClick={() => this.sorting('webhookType')}
                            >
                              {this.props.users.languageStateForRedux?.column_names?.integration?.hookType}
                            </TableCell>

                            <TableCell className="long-text">
                            {this.props.users.languageStateForRedux?.column_names?.integration?.req}
                            </TableCell>
                            <TableCell
                              className={
                                'long-text ' +
                                (sort_by === 'createdAt'
                                  ? order === 'asc'
                                    ? 'sort-asc'
                                    : 'sort-des'
                                  : '')
                              }
                              onClick={() => this.sorting('createdAt')}
                            >
                              {this.props.users.languageStateForRedux?.column_names?.integration?.date}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {Array.isArray(listOfWebHooks) &&
                        listOfWebHooks.length > 0 ? (
                          <>
                            <TableBody>
                              {Array.isArray(listOfWebHooks) &&
                                listOfWebHooks.length > 0 &&
                                listOfWebHooks.map((webhook) => {
                                  return (
                                    <TableRow key={webhook._id}>
                                      <TableCell className="long-text">
                                        {/* <span className='table-status danger'>Failed </span> */}
                                        <span
                                          className={`table-status ${
                                            webhook.status === 'success'
                                              ? 'success'
                                              : 'danger'
                                          }`}
                                        >
                                          {' '}
                                          {webhook.status}{' '}
                                        </span>
                                      </TableCell>

                                      <TableCell className="long-text">
                                        <Box>/{webhook.webhookType}</Box>
                                      </TableCell>
                                      <TableCell className="/add_attendee">
                                        <Box
                                          sx={{ cursor: 'pointer' }}
                                          className="secondary-text"
                                          onClick={() => this.showError(webhook)}
                                        >
                                          {webhook.messageId}
                                        </Box>
                                      </TableCell>
                                      <TableCell className="date-time">
                                        {moment
                                          .tz(
                                            webhook.createdAt,
                                            this.props.project?.project?.timezone,
                                          )
                                          .format('LLLL')}
                                      </TableCell>
                                    </TableRow>
                                  )
                                })}
                            </TableBody>
                          </>
                        ) : ( !this.state.loading && listOfWebHooks.length === 0 && this.props.integration?.listWebhooksSuccess &&
                          (<TableBody>
                            <TableRow>
                              <TableCell style={{ textAlign: 'center' }}>
                              {this.props.users.languageStateForRedux?.common?.noRecords}
                              </TableCell>
                            </TableRow>
                          </TableBody>)
                        )}
                      </Table>
                    </TableContainer>
                  </Box>
                </TabPanel>
              }
              {this.props.allowed['app integrations'] &&
                <TabPanel>
                  <AppIntegrations />
                </TabPanel>
              }
               {
              //  this.props.allowed['app integrations'] &&
                <TabPanel>
                  <Applications />
                </TabPanel>
              }
            </div>
          </Tabs>
        </Box>
        {loading ? <Loader /> : null}
        {Array.isArray(listOfWebHooks) && listOfWebHooks.length > 0 && (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="tablePagination"
          >
            <Grid item>
              <Box component="span" className="small-subtitle">
                Showing {startCount} to {endCount} of{' '}
                {this.props.integration.totalResults}
              </Box>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <span className="small-subtitle">Shows</span>
                  <select value={itemCount} onChange={this.selectItemCount}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  <span className="small-subtitle">Entries</span>
                </Grid>
                <Grid item>
                  <Pagination
                    activePage={page_no}
                    itemsCountPerPage={itemCount}
                    totalItemsCount={this.props.integration.totalResults}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                    prevPageText={
                      <NavigateBeforeIcon style={{ fontSize: 18 }} />
                    }
                    nextPageText={<NavigateNextIcon style={{ fontSize: 18 }} />}
                    itemClassFirst="first d-none"
                    itemClassLast="last d-none"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Modal
          open={this.state.showError}
          onClose={this.handleCloseError}
          classNames={{
            overlay: '',
            modal: 'tab-modal',
          }}
          center
        >
          <Box>
            <Box
              px={3}
              pt={3}
              pb={2}
              sx={{ color: '#ffffff', background: '#000056' }}
            >
              <Box component={Grid} container spacing={4}>
                <Grid item>
                  <Stack spacing={1} direction={'row'}>
                    <img alt="" src={webhook_icon} />
                    <Box className="small-subtitle" sx={{ color: '#ffffff' }}>
                      /{this.state.webhookPopupData.webhookType}
                    </Box>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack spacing={1} direction={'row'}>
                    <img alt="" src={eventID_icon} />
                    <Box className="small-subtitle" sx={{ color: '#ffffff' }}>
                      {webhookPopupData.messageId}
                    </Box>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack spacing={1} direction={'row'}>
                    <img alt="" src={date_icon} />
                    <Box className="small-subtitle" sx={{ color: '#ffffff' }}>
                      {moment
                        .tz(
                          webhookPopupData.createdAt,
                          this.props.project?.project?.timezone,
                        )
                        .format('LLLL')}
                    </Box>
                  </Stack>
                </Grid>
                <Grid item>
                  <span
                    className={`table-status ${
                      webhookPopupData.status === 'success'
                        ? 'success'
                        : 'danger'
                    }`}
                  >
                    {' '}
                    {webhookPopupData.status}{' '}
                  </span>
                </Grid>
              </Box>
            </Box>
            <TabContext1 value={selectedPopupTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList1
                  style={styles.customTabList}
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={this.handleTabChange}
                  aria-label="lab API tabs example"
                >
                  <Tab1
                    style={styles.customTab}
                    // icon={<img alt="" src={profileUserIcon} />}
                    iconPosition="start"
                    label="Headers"
                    value="1"
                  ></Tab1>
                  <Tab1
                    style={styles.customTab}
                    // icon={<img alt="" src={securityIcon} />}
                    iconPosition="start"
                    label="Preview"
                    value="2"
                  >
                    {' '}
                  </Tab1>
                </TabList1>
              </Box>
              <TabPanel1 style={styles.customTabPanel} value="1">
                <Box mb={5}>
                  <Box className="paragraph" mb={3}>
                    Payload
                  </Box>

                  <Box pl={3} sx={{ borderLeft: '1px solid #00785B' }}>
                    <Stack spacing={2}>
                      {Object.keys(payload).map((key) => {
                        if (key === 'profileImage') {
                          return (
                            <Box
                              key={key}
                              className="paragraph"
                              sx={{ color: '#414141' }}
                            >
                              <Box component={'span'} sx={{ color: '#00785B' }}>
                                {key}:{' '}
                              </Box>
                              <a
                                href="#"
                                className="secondary-text"
                                onClick={() =>
                                  this.viewProfilePicture(payload[key])
                                }
                              >
                                View Image
                              </a>
                            </Box>
                          )
                        } else {
                          return (
                            <Box
                              key={key}
                              className="paragraph"
                              sx={{ color: '#414141' }}
                            >
                              <Box component={'span'} sx={{ color: '#00785B' }}>
                                {key}:{' '}
                              </Box>
                              {payload[key]}
                            </Box>
                          )
                        }
                      })}
                    </Stack>
                  </Box>
                </Box>

                <Box mb={5}>
                  <Box className="paragraph" mb={3}>
                    Header
                  </Box>
                  <Box pl={3} sx={{ borderLeft: '1px solid #5141E7' }}>
                    <Stack spacing={2}>
                      {Object.keys(header).map((key) => {
                        return (
                          <Box
                            key={key}
                            className="paragraph"
                            sx={{ color: '#414141' }}
                          >
                            <Box component={'span'} sx={{ color: '#00785B' }}>
                              {key}:{' '}
                            </Box>
                            {header[key]}
                          </Box>
                        )
                      })}
                    </Stack>
                  </Box>
                </Box>
              </TabPanel1>
              <TabPanel1 style={styles.customTabPanel} value="2">
                <Box mb={5}>
                  <Box pl={3} sx={{ borderLeft: '1px solid #5141E7' }}>
                    <Stack spacing={2}>
                      <Box className="paragraph" sx={{ color: '#414141' }}>
                        <Box component={'span'} sx={{ color: '#5141E7' }}>
                          Message:{' '}
                        </Box>
                        {webhookPopupData.message}
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              </TabPanel1>
            </TabContext1>
          </Box>
        </Modal>

        <Modal
          open={this.state.openChangeSecretModel}
          onClose={this.closeChangeSecretModel}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Box component={Grid} container className="" px={1.5}>
            <Box component={Grid} mt={-1} item xs={12} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {' '}
                {this.props.users.languageStateForRedux?.actions?.integration?.apiKey}
              </Box>
              <p className='small-subtitle'>
              {this.props.users.languageStateForRedux?.actions?.integration?.newApiMsg}
              </p>
            </Box>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevationon
                      onClick={() => this.generateSecretKey()}
                    >
                      {this.props.users.languageStateForRedux?.buttons?.integration?.change}
                    </Button>

                    <Button
                      variant="outlined"
                      disableElevation
                      onClick={() => this.closeChangeSecretModel()}
                    >
                      {this.props.users.languageStateForRedux?.common?.cancel}
                    </Button>
                  </>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  integration: state.Integration,
  events: state.Events,
  users: state.Users,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listWebhooks: Actions.listWebhooksRequest,
      toggleWebhook: Actions.toggleWebhookRequest,
      generateSecretKey: Actions.generateSecretKeyRequest,
      checkWebhookEventId: Actions.checkWebhookEventIdRequest,
    },
    dispatch,
  )
export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withRouter(Integration))
