import BaseAPI from './baseAPI';

class IntegrationAPI extends BaseAPI {
  getAppIntegrations = async (eventId: string) => {
    return this.API('integration/apps/list', {
      method: 'GET',
      params: { eventId },
    });
  };

  connectGoogleWorkspace = async (integration: any) => {
    return this.API('integration/google/workspace/connect', {
      method: 'POST',
      data: { integration },
    });
  };

  authorizeGoogleWorkspace = async (data: any) => {
    return this.API('integration/google/workspace/authorize', {
      method: 'POST',
      data: data,
    });
  };

  getGoogleWorkspaceGetWidget = async (integrationId: string) => {
    return this.API('integration/getIntegrationById', {
      method: 'GET',
      params: { integrationId },
    });
  };

  getGoogleWorkspaceLogs = async (params: object) => {
    return this.API('integration/google/syncLogs', {
      method: 'GET',
      params: params,
    });
  };
  getFieldMapping = async (params: object) => {
    return this.API('integration/google/getFieldMapping', {
      method: 'GET',
      params: params,
    });
  };
  getGoogleOrgUnits = async (params: object) => {
    return this.API('integration/google/workspace/getOrgUnits', {
      method: 'GET',
      params: params,
    });
  };
  getGoogleGroups = async (params: object) => {
    return this.API('integration/google/workspace/getGroups', {
      method: 'GET',
      params: params,
    });
  };
  manuallySyncGoogleOrgUnits = async (params: object) => {
    this.showAlert = false;
    return this.API('integration/google/workspace/manuallSyncOU', {
      method: 'GET',
      params: params,
    });
  };

  saveGoogleWorksapceInfo = async (data: any) => {
    return this.API('integration/google/workspace/info', {
      method: 'PUT',
      data,
    });
  };
  updateGoogleWorkspaceSyncMode = async (data: object) => {
    return this.API('integration/google/updateSyncMode', {
      method: 'POST',
      data: data,
    });
  };
  getSyncLogsHealthStatus = async (params: object) => {
    return this.API('integration/google/getSyncLogsHealthStatus', {
      method: 'GET',
      params: params,
    });
  };
  updateGroupsFromGoogleWorkspace = async (data: object) => {
    this.showAlert = false;
    return this.API('integration/google/workspace/syncGroups', {
      method: 'POST',
      data: data,
    });
  };

  updateGoogleWorkspaceCustomFields = async (data: object) => {
    return this.API('integration/google/updateCustomFields', {
      method: 'POST',
      data: data,
    });
  };

  checkWhetherGroupNameExistInIllumeet = async (data: object) => {
    return this.API(
      'integration/google/workspace/checkWhetherGroupNameExistInIllumeet',
      {
        method: 'POST',
        data: data,
      },
    );
  };

  syncUsers = async (params: object) => {
    // this.showAlert = false;
    return this.API('integration/google/workspace/syncUsers', {
      method: 'GET',
      params,
    });
  };

  getGoogleUsers = async (params: object) => {
    this.showAlert = false;
    return this.API('integration/google/workspace/getGoogleUsers', {
      method: 'GET',
      params,
    });
  };
}
export default IntegrationAPI;
