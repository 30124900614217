import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
    listGamificationGradesRequest : ['data'],
    listGamificationGradesSuccess : [ 'message','data'],
    listGamificationGradesError : ['error'],
    getGamificationChallengesRequest: ['data'],
    getGamificationChallengesSuccess: ['message', 'challenges', 'totalResults'],
    getGamificationChallengesError: ['error'],
    configrationChallengesRequest : ['data'],
    configrationChallengesSuccess :  ['message', 'challengesList', 'totalConfigrationChallenges','filterByStatus'],
    configrationChallengesError :  ['error'],
    updateGamificationGradesRequest: ['data'],
    updateGamificationGradesSuccess: ['message', 'data'],
    updateGamificationGradesError: ['error'],
    addConfigrationChallengesRequest:['data'],
    addConfigrationChallengesSuccess:['message', 'challenge'],
    addConfigrationChallengesError:['error'],
    updateConfigrationChallengesRequest:['data'],
    updateConfigrationChallengesSuccess:['message', 'data','isStatusUpdated'],
    updateConfigrationChallengesError:['error'],
    listGamificationLeaderBoardsRequest: ['data'],
    listGamificationLeaderBoardsSuccess: ['message', 'totalResults', 'leaderBoard', 'filterByAchievement'],
    listGamificationLeaderBoardsError: ['error'],
    addPointsGamificationLeaderBoardsRequest: ['data'],
    addPointsGamificationLeaderBoardsSuccess: ['message'],
    addPointsGamificationLeaderBoardsError: ['error'],
    deleteConfigrationChallengesRequest:['data'],
    deleteConfigrationChallengesSuccess:['message'],
    deleteConfigrationChallengesError:['error'],
    challengeStatusRequest: ['data'],
    challengeStatusSuccess: ['message'],
    challengeStatusError: ['error'],
    leaderboardUserHistoryRequest:['data'],
    leaderboardUserHistorySuccess:['message','leaderboardUserHistory',"totalResults"],
    leaderboardUserHistoryError:['error'],
    challengesEngagedUsersRequest:['data'],
    challengesEngagedUsersSuccess:['message','challengesEngagedUsers'],
    challengesEngagedUsersError:['error'],
})

export const Constants = Types
export default Creators