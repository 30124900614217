import axios from 'axios';
import { get } from 'lodash';
import { Url } from '../../constants/constants';
import { redirectToLogin } from '../../helpers/common';
import { permissions } from '../../constants/constants';

const URL = process.env.REACT_APP_API_URL;
const URL_WIX_API = Url.apiLive;
const headers = {
  'Content-Type': 'application/json',
};
const AWS_API = process.env.REACT_APP_AWS_API;
export const UserData = async () => {
  return JSON.parse(localStorage.getItem('user_details'));
};

const attendeesData = async () => {
  const attendee = JSON.parse(localStorage.getItem('attendee_details'));
  if (attendee) {
    return attendee;
  } else {
    return null;
  }
};

export const LoginAPI = async (data) => {
  try {
    const res = await axios.post(`${URL}/users/login`, data, headers);
    return res;
  } catch (err) {
    return err;
  }
};

export const checkWebhookEventIdAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/webhook/checkWebhookEventId`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const generateSecretKeyForWebhookAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/webhook/changeSecretKey`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const toggleWebhookAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/webhook/updateToggle`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const listWebhooksAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/webhook/listWebhook`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};
export const SendTestEmailAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/campaigns/sendTestEmailForCampaign`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const deleteProjectAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.patch(
      `${URL}/deleteProject/${data.projectId}`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};
export const ListCampaignAudienceGroupsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/groups/list/${data.projectId}`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getExcludeAttendeeCountAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/getExcludeAttendeeCount`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getExcludeCountAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/getExcludeCount`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const listGamificationLeaderBoardsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios
      .post(`${URL}/gamification/getLeaderboardList`, data, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.data.csvData) {
          const url = window.URL.createObjectURL(
            new Blob([response?.data?.csvData]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Leaderboard.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        return response;
      });
    return res;
  } catch (err) {
    return err;
  }
};

export const addPointsGamificationLeaderBoardsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/gamification/addPointsInLeaderboard`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const UpdateGamificationGradesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.put(
      `${URL}/gamification/updateGamificationGrade`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const ListGamificationGradesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/gamification/listGamificationGrade?projectId=${data}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const ListAttendeesNotAddedToCampaignAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/campaigns/listAttendeesOfNotAddedToCampaign?projectId=${data.projectId}&campaignId=${data.campaignId}&scroll_no=${data.scroll_no}&searchText=${data.searchText}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const ListAttendeesOfCampaignsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/campaigns/listAttendeesOfCampaign?campaignId=${data.campaignId}&scroll_no=${data.scroll_no}&searchText=${data.searchText}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteCampaignAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.put(`${URL}/campaigns/deleteCampaign`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const GetCampaignAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/campaigns/getCampaign/${data.campaignId}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const GetReportAPI = async (data) => {
  try {
    const users = await UserData();
    let url = `${URL}/report/getReport`;
    if (data.eventId) {
      url += `?projectId=${data.eventId}`;
    }
    const res = await axios.get(url, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const GetPublishedEventsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/report/getPublishedEvents`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const UpdateCampaignAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.put(`${URL}/campaigns/updateCampaign`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const CreateCampaignAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/campaigns/createCampaign`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const CheckCampaignAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/campaigns/checkCampaign`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const ListCampaignTemplatesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/campaigns/listCampaignTemplates?campaignType=${data.campaignType}&searchText=${data.searchText}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const ListCampaignsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/campaigns/listCampaigns?eventId=${data.eventId}&page_no=${data.page_no}&searchText=${data.searchText}&limit=${data.limit}&sort_by=${data.sort_by}&order=${data.order}&status=${data.status}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const ListAllCampaignsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/campaigns/listAllCampaigns?eventId=${data.eventId}&requestFrom=${data.requestFrom}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const ListTransactionalEmailTemplatesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/listTransactionEmail?templateId=${data.projectId}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
          'check-grant': `${data.projectId}-Edit Transactional Email Templates`,
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const ListBadgesTemplateAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/listBadgesTemplate?templateId=${data.projectId}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
          'check-grant': `${data.projectId}-Edit Transactional Email Templates`,
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const ListAllTransactionalEmailTemplatesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/attendees/listAllTransactionEmail?templateId=${data.projectId}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const SearchAttendeesGroupsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/attendeesGroup/searchAttendeesGroup`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const SearchAttendeesOfUnassignGroupAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/attendeesGroup/searchAttendeesOfUnassignGroup`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const SearchAttendeesOfGroupAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/attendeesGroup/searchAttendeesOfGroup`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const AssignAttendeesFromGroupAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/attendees/assignGroupAttendees`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const UnassignAttendeesFromGroupAPI = async (data) => {
  try {
    let permission = data.permission;
    delete data['permission'];
    const users = await UserData();
    const res = await axios.post(
      `${URL}/attendees/assignGroupAttendees`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
          'check-grant': `${data.projectId}-${permissions.editGroups}`,
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const ListAssignedAttendeesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/attendeesGroup/listAttendeesOfGroup`, data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const ListUnassignedAttendeesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/attendeesGroup/listAttendeesOfUnassignGroup?projectId=${data.projectId}&scroll_no=${data.scroll_no}&groupId=${data.groupId}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const SortAttendeesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/attendees/sortFilterAttendees`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const EditAttendeesGroupNameAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.put(
      `${URL}/attendeesGroup/editAttendeesGroup`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
          'check-grant': `${data.projectId}-${permissions.editGroups}`,
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteAttendeesGroupsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.put(
      `${URL}/attendeesGroup/deleteAttendeesGroup`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const AddAttendeesGroupsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/attendeesGroup/createAttendeesGroup`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const ListAttendeesGroupsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/attendeesGroup/listAttendeesGroups?projectId=${data.projectId}&page_no=${data.page_no2}&searchText=${data.searchText}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const EditAttendeeAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/attendees/editAttendees`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const UploadAttendeePic = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/attendees/updateAttendeesProfile`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const UploadUnlayerPic = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/events/uploadUnlayerPic`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const checkedInHistoryAttendeeAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/attendees/sortFilterAttendees`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
        'check-grant': `${data.projectId}-${data.permission}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const EnableAttendeeStatusAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/attendees/statusRequesAttendees`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const CheckinActionStatusAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/attendees/loginUpdateAttendees`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const ActionAssignGroupsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/attendees/assignGroupAttendees`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
          'check-grant': `${data.projectId}-${permissions.editAttendee}`,
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteAttendeeAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/attendees/deleteAttendees`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const ImportAttendeesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/attendees/importCsv`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const AddAttendeesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/attendees/createAttendees`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const ListAttendeesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/attendees/listAttendees?projectId=${data.projectId}&page_no=${data.page_no}&limit=${data.limit}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const SearchAttendeesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/attendees/searchFilterAttendees`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const SortFilterAttendeesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/attendees/sortFilterAttendees`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const UpdateCustomFieldsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/attendees/updateCustomFields`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteCustomFieldsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/attendees/deleteCustomFields`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const AddCustomFieldsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/attendees/createCustomFields`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const GetCustomFieldsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/attendees/getCustomFields?projectId=${data.projectId}`,

      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const GetCustomFieldsForLookUpWidgetAPI = async (data) => {
  try {
    const res = await axios.get(
      `${URL}/attendees/getCustomFieldsForLookUpWidget?projectId=${data.projectId}`,

      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const GetToolCustomFieldsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/attendees/getToolCustomFields?projectId=${data.projectId}`,

      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const getchallengesListForCustomToolAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/gamification/configrations/getchallengesListForCustomTool?projectId=${data.projectId}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const UpdateProjectNotificationAPI = async (action) => {
  try {
    const users = await UserData();
    const res = await axios.patch(
      `${URL}/notifications/update/${action.id}`,
      action.data,
      {
        headers: {
          token: `bearer ${users.token}`,
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteMemberAPI = async (id) => {
  try {
    const users = await UserData();
    const res = await axios.patch(
      `${URL}/members/delete/${id}`,
      {},
      {
        headers: {
          token: `bearer ${users.token}`,
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteProjectNotificationAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.patch(
      `${URL}/notifications/delete/${data.deleteNotiId}`, data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      });
    return res;
  } catch (err) {
    return err;
  }
};

export const CreateProjectNotificationAPI = async (data) => {
  try {
    const users = await UserData();
    console.log("redux api try block", data);
    const res = await axios.post(
      `${URL}/notifications/addNotificationApi`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const GetProjectNotificationsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = axios.get(
      `${URL}/notifications/getNotificationsList?projectId=${data.projectId}&page_no=${data.page_no}&limit=${data.limit}&sort_by=${data.sort_by}&status=${data.status}&order=${data.order}&searchText=${data.searchText}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const AutoLoginAdminAPI = async (data) => {
  try {
    const res = await axios.post(
      `${URL_WIX_API}admin/check/autoLoginAdmin`,
      data,
      { headers: { 'Content-Type': 'application/json' } },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const wixAutoLoginAPI = async (data) => {
  try {
    const res = await axios.post(`${URL_WIX_API}admin/check/autoLogin`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const ServicesStatusApiAutoLogin = async (params) => {
  try {
    if (params) {
      var userId = params._id;
      let response = await axios.get(
        `${URL_WIX_API}admin/pricePlans?userId=${userId}`,
        {
          headers: {
            authorization: params.token,
          },
        },
      );
      if (response) {
        return response.data;
      } else {
        throw new Error('Something went wrong. Please try again later.');
      }
    }
  } catch (err) {
    return err;
  }
};

export const UpdateUserAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/users/update`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const EmailVerifyAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/users/emailVerification`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteAccountAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.patch(`${URL}/users/deleteAccount`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const ForgotPasswordAPI = async (data) => {
  try {
    const res = await axios.post(`${URL}/users/forgotPassword`, data, headers);
    return res;
  } catch (err) {
    return err;
  }
};

export const ResetPasswordAPI = async (data) => {
  try {
    const res = await axios.post(`${URL}/users/resetPassword`, data, headers);
    return res;
  } catch (err) {
    return err;
  }
};

export const EmailVerifiedAPI = async (data) => {
  try {
    const res = await axios.post(`${URL}/users/emailVerified`, data, headers);
    return res;
  } catch (err) {
    return err;
  }
};

// Amit's Changes
export const UserRegisterAPI = async (data) => {
  try {
    const res = await axios.post(`${URL}/users/register`, data, headers);
    return res;
  } catch (err) {
    return err;
  }
};
export const CreateEventAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/events/createEvent`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const GetEventsAPI = async (data) => {
  try {
    var pageNo = 1;
    if (data.page_no) {
      pageNo = data.page_no;
    }
    const users = await UserData();
    let url = `${URL}/all-events/${pageNo}?`;

    if (data.limit) {
      url += `limit=${data.limit}&`;
    }
    if (data.sort_by) {
      url += `sortBy=${data.sort_by}&`;
    }
    if (data.order) {
      url += `sort_order=${data.order}&`;
    }
    if (data.searchText) {
      url += `searchText=${data.searchText}&`;
    }
    if (data.projectType) {
      url += `projectType=${data.projectType}&`;
    }
    const res = axios.get(url, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getEventAPI = async (eventId) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/event/${eventId}`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getEventForPagesAPI = async (eventId) => {
  try {
    const res = await axios.get(`${URL}/event/eventDetails/${eventId}`, headers);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateEventPageDesign = async (eventId, data) => {
  try {
    const users = await UserData();
    const res = await axios.put(
      `${URL}/event/${eventId}/updatePageDesign`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const CreateContactAPI = async (data) => {
  try {
    let permission = data.permission;
    delete data['permission'];
    const users = await UserData();
    const res = await axios.post(`${URL}/contacts/create`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
        'check-grant-slp': permission,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const ListContactAPI = async (data) => {
  try {
    let permission = data.permission;
    delete data['permission'];
    const users = await UserData();
    const res = await axios.post(`${URL}/contacts/list`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
        'check-grant-slp': permission,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};
export const ListRejectsContactAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/rejectContacts/list?page_no=${data.page_no}&limit=${data.limit}`,
      {
        headers: {
          token: `bearer ${users.token}`,
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const ListAllRejectsContactAPI = async () => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/allRejectContacts/list`, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const ListAllContactAPI = async (data) => {
  try {
    let permission = data.permission;
    delete data['permission'];
    const users = await UserData();
    const res = await axios.post(`${URL}/contacts/listAll`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
        'check-grant-slp': permission,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const samplecsv = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/audience/samplecsv`,

      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
}

export const CreateGroupContactAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/groups/create_v2`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const ImportContactAPI = (data) => {
  try {
    let permission = data.get('permission');
    const currentPath = window.location.pathname;
    let users = JSON.parse(localStorage.getItem('user_details'));
    if (currentPath.includes('member')) {
      users = JSON.parse(localStorage.getItem('member_details'));
    }
    return axios.post(`${URL}/contacts/import`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
        'check-grant-slp': permission,
      },
    });
  } catch (err) {
    return err;
  }
};

export const UpdateContactAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/contacts/update`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
        'check-grant-slp': 'Edit Audience',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteContactAPI = async (data) => {
  try {
    let permission = data.permission;
    delete data['permission'];
    const users = await UserData();
    const res = await axios.patch(`${URL}/contacts/delete`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
        'check-grant-slp': permission,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteAllContactAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/contacts/deleteAll`, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteManyGroupApi = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/groups/deleteMany`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteManyContactAPI = async (data) => {
  let permission = data.permission;
  delete data['permission'];
  const formData = new FormData();
  for (let key in data.ids) {
    formData.append(`ids.${key}`, data.ids[key]);
  }
  delete data.ids;
  for (let key in data) {
    formData.append(key, data[key]);
  }
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/contacts/deleteMany`, formData, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
        'check-grant-slp': permission,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};
export const AssignGroupAPI = async (data) => {
  const formData = new FormData();
  for (let key in data.ids) {
    formData.append(`ids.${key}`, data.ids[key]);
  }
  for (let key in data.group_id) {
    formData.append(`group_id.${key}`, data.group_id[key]);
  }
  delete data.ids;
  delete data.group_id;
  for (let key in data) {
    formData.append(key, data[key]);
  }
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/contacts/assignGroup`, formData, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const SearchContactAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/contacts/search`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const CsvContactAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/contacts/exportCsv`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const XslContactAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/contacts/exportXsl`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const ListGroupAPI = async (data) => {
  try {
    let permission = data.permission;
    delete data['permission'];
    const users = await UserData();
    const res = await axios.post(`${URL}/groups/search`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteGroupAPI = async (data) => {
  try {
    let permission = data.permission;
    delete data['permission'];
    const users = await UserData();
    const res = await axios.post(`${URL}/groups/delete`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const UpdateGroupAPI = async (data) => {
  try {
    let permission = data.permission;
    delete data['permission'];
    const users = await UserData();
    const res = await axios.post(`${URL}/groups/update`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
        'check-grant-slp': permission,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const SearchGroupAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/groups/search`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const AddMemberAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/members/add`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const UpdateMemberAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/members/update`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};
export const EditProjectNotificationAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/notifications/getNotificatoinById/${data.id}/${data.projectId}`,
      {
        headers: {
          token: `bearer ${users.token}`,
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};
export const ListMemberAPI = async (page_no) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/members/list/${page_no}`, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const ListProjectUsersAPI = async (data) => {
  try {
    const users = await UserData();
    let projectUsersURL = `${URL}/projectusers/list`;

    const res = await axios.post(projectUsersURL, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

// Add GetUsersList API
export const GetUsersListAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/users/list`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const SearchProjectNotificationAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/notifications/search`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const SearchMemberAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/members/search`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};
export const CampaignProgressAPI = async (eventId, templateId) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/emails/campaignProgress/${eventId}/${templateId}`,
      {
        headers: {
          token: `bearer ${users.token}`,
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

/**
 * To get the progress after bulk data import/copy when new audience created
 * 
 * @param {ObjectId} importContactProgressId 
 * @returns {status: number, percent: number}
 */
export const getProgressCountOfAudienceAPI = async (importContactProgressId) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/groups/import-progress/${importContactProgressId}`,
      {
        headers: {
          token: `bearer ${users.token}`,
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const ImportProgressAPI = async (importContactId) => {
  try {
    const res = await axios(
      `${AWS_API}/getimportcontactprogress?id=${importContactId}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        crossDomain: true,
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const UpdateMemberStatusAPI = async (data) => {
  try {
    const res = await axios.post(`${URL}/members/updateStatus`, data, headers);
    return res;
  } catch (err) {
    return err;
  }
};

export const createOrUpdateRegForm = async (
  method = 'get',
  eventId,
  data,
  customHeaders = {},
) => {
  try {
    const users = await UserData();
    const token = get(users, 'token');
    const res = await axios({
      method,
      url: `/events/${eventId}/registrationForm`,
      headers: {
        token: `bearer ${token}`,
        ...customHeaders,
      },
      data,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const registerEventUser = async (eventId, data, method = 'post') => {
  try {
    const users = await UserData();
    const token = get(users, 'token');
    const res = await axios({
      method,
      url: `/events/${eventId}/registerUser`,
      headers: {
        token: `bearer ${token}`,
      },
      data,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getEventUser = async (eventId, params = {}) => {
  try {
    const users = await UserData();
    const token = get(users, 'token');
    const res = await axios.get(`${URL}/events/${eventId}/registerUser`, {
      headers: {
        'Content-Type': 'application/json',
        token: `bearer ${token}`,
      },
      params,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const deleteEventUser = async (userId, data) => {
  try {
    const users = await UserData();
    const token = get(users, 'token');
    const res = await axios({
      method: 'delete',
      url: `/registeredUser/${userId}/`,
      headers: {
        token: `bearer ${token}`,
      },
      data,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const deleteMultipleEventUsers = async (eventId, data) => {
  try {
    const users = await UserData();
    const token = get(users, 'token');
    const res = await axios({
      method: 'delete',
      url: `/registeredUser/${eventId}/deleteMultipleEventUsers`,
      headers: {
        token: `bearer ${token}`,
      },
      data,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const authenticateEventUser = async (
  eventDomain,
  data,
  method = 'post',
) => {
  try {
    const users = await UserData();
    const token = get(users, 'token');
    const res = await axios({
      method,
      url: `/events/${eventDomain}/login`,
      headers: {
        token: `bearer ${token}`,
      },
      data,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const verifyEventUserToken = async (id, data, method = 'post') => {
  try {
    const res = await axios({
      method,
      url: `/verifyRegistration/${id}`,
      headers: {},
      data,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const updateEventUser = async (id, data, method = 'post') => {
  try {
    const users = await UserData();
    const token = get(users, 'token');
    const res = await axios({
      method,
      url: `/events/${id}/updateEventUser`,
      headers: {
        token: `bearer ${token}`,
      },
      data,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const EventEmailAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/events/email`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const AddMoreScheduleEmailAPI = async (data) => {
  const formData = new FormData();
  for (let key in data.ids) {
    formData.append(`ids.${key}`, data.ids[key]);
  }
  delete data.ids;
  for (let key in data) {
    formData.append(key, data[key]);
  }
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/events/addMoreEmail/schedule`,
      formData,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const UpdateEventAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/events/update`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};
export const UpdateProjectAPI = async (data) => { 
  let projectId = data.get('projectId'); // need to check for other modules.
  try {
    const users = await UserData();
    let query = `${URL}/updateProject/${projectId}`;
    const res = await axios.put(query, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
        'check-grant': `${data.get('projectId')}-${permissions.editProject}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getEventById = async (id) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/event/${id}`, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getProjectById = async (id) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/getProject?eventId=${id}`, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const SearchEventsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/events/search`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const ExportEventsAPI = async () => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/events/export`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const EmailsInfoAPI = async (id) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/emailsInfo/${id}`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const EmailsCustomAnalyticsDataForEventAPI = async (data) => {
  try {
    const users = await UserData();
    let query = `${URL}/emails/emailsCustomUpdateForEvent/${data.eventId}`;
    if (data.emailType && data.emailType !== '') {
      query += `?emailType=${data.emailType}`;
    }
    if (data.templateId && data.templateId !== '') {
      query += `&templateId=${data.templateId}`;
    }

    const res = await axios.put(query, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const EmailsCustomAnalyticsDataForRegistrationAPI = async (data) => {
  try {
    const users = await UserData();
    let query = `${URL}/emails/emailsCustomUpdateForRegistration/${data.page_no}/${data.eventId}/${data.templateId}`;

    const res = await axios.put(query, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const EmailsDataInfoAPI = async (data) => {
  try {
    const users = await UserData();
    let query = `${URL}/emails/analytics/${data.eventId}?`;
    if (data.emailType && data.emailType !== '') {
      query += `emailType=${data.emailType}&`;
    }
    if (data.templateId && data.templateId !== '') {
      query += `templateId=${data.templateId}&`;
    }

    const res = await axios.put(query, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
        'check-grant': `${data.eventId}-${permissions.viewProject}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const EmailsRegistrationDataInfoAPI = async (data) => {
  try {
    const users = await UserData();
    let query = `${URL}/emails/registrationAnalytics/${data.eventId}`;
    if (data.templateId !== '') {
      query = `${URL}/emails/registrationAnalytics/${data.eventId}/${data.templateId}`;
    }

    const res = await axios.put(query, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const ListNotiAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/notifications/list/${data.page_no}/${data.limit}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const UpdateNotiAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.put(
      `${URL}/notifications/update/${data.id}`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const CheckEventDomainAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/events/checkEventDomain`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const updateEventTemplate = async (templateId, data) => {
  try {
    const users = await UserData();
    const res = await axios.put(
      `${URL}/event-templates/update/${templateId}`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const getEmailCampaigns = async (eventId) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/event-template-byEvent/${eventId}`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const cloneEmailCampaigns = async (templateId) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/clone-template/${templateId}`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getEventTemplate = async (eventId) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/single-event-templates/${eventId}`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getEventTemplatesApi = async (eventId) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/event-templates/${eventId}`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getEventRegistrationTemplatesApi = async (eventId) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/events/registration/templates/${eventId}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const createEventTemplate = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/event-templates/create`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const searchEmailCampaigns = async (eventId, searchText) => {
  try {
    const users = await UserData();
    const res = await axios.put(
      `${URL}/event-template-byEvent/${eventId}/${searchText}`,
      {},
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const getEventTemplates = async (params = {}, queryParam) => {
  try {
    const attendees = await attendeesData();
    let headers = {
      'Content-Type': 'application/json',
    };
    if (attendees != null) {
      headers = {
        token: `bearer ${attendees.token}`,
        'Content-Type': 'application/json',
      };
    }
    const res = await axios.get(
      queryParam
        ? `${URL}/events/templates${queryParam}`
        : `${URL}/events/templates`,
      {
        headers: headers,
        params,
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const getEventTemplatesNew = async (params = {}, queryParam) => {
  try {
    const attendees = await attendeesData();
    let headers = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    };

    if (attendees != null) {
      headers = {
        token: `bearer ${attendees.token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        id: attendees.email,
      };
    }
    const res = await axios.get(
      queryParam
        ? `${URL}/events/templatesNew${queryParam}`
        : `${URL}/events/templatesNew`,
      {
        headers: headers,
        params,
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const getAllPagesOfEvent = async (params = {}) => {
  try {
    const attendees = await attendeesData();
    let headers = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    };
    if (attendees != null) {
      headers['token'] = `bearer ${attendees.token}`;
    }
    const res = await axios.get(`${URL}/events/getAllPages`, {
      headers: headers,
      params,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getCustomToolTemplates = async (params = {}, queryParam) => {
  try {
    const res = await axios.get(
      queryParam
        ? `${URL}/events/customtoolforms${queryParam}`
        : `${URL}/events/customtoolforms`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        params,
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const getEventUserById = async (id, params) => {
  try {
    const res = await axios.get(`${URL}/eventUserById/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getEventAgenda = async (params = {}) => {
  try {
    const res = await axios.get(`${URL}/events/agenda`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getAgendaProfile = async (data) => {
  const attendee = await attendeesData();
  try {
    let apiUrl = `${URL}/agenda/getProfilePageAgendaList?eventId=${data.projectId}&eventDomain=${data.eventDomain}`;
    if (data.date) {
      apiUrl += `&dateFilter=${data.date}`;
    }
    if (data.search) {
      apiUrl += `&searchText=${data.search}`;
    }
    const res = await axios.get(`${apiUrl}`, {
      headers: {
        'Content-Type': 'application/json',
        token: `bearer ${attendee.token}`,
      },
    });
    if (res?.data?.status === 403) {
      redirectToLogin(res);
    } else {
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const getMyAgendaProfile = async (data) => {
  const attendee = await attendeesData();
  try {
    let apiUrl = `${URL}/agenda/getMyAgendaList?eventDomain=${data.eventDomain}&eventId=${data.eventId}`;
    if (data.date) {
      apiUrl += `&dateFilter=${data.date}`;
    }
    if (data.search) {
      apiUrl += `&searchText=${data.search}`;
    }
    const res = await axios.get(`${apiUrl}`, {
      headers: {
        'Content-Type': 'application/json',
        token: `bearer ${attendee.token}`,
      },
    });
    if (res?.data?.status === 403) {
      redirectToLogin(res);
    } else {
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const setMyAgendaFavourite = async (data) => {
  const attendee = await attendeesData();
  try {
    const res = await axios.post(
      `${URL}/agenda/setRemoveAgendaToMyAgenda`,
      data,
      {
        headers: {
          token: `bearer ${attendee.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const historyChallenge = async (data) => {
  const attendee = await attendeesData();
  try {
    const res = await axios.post(
      `${URL}/leaderBoard/userHistoryMyProfile`,
      data,
      {
        headers: {
          token: `bearer ${attendee.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    if (res?.data?.status === 403) {
      redirectToLogin(res);
    } else {
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const leaderBoardList = async (data) => {
  const attendee = await attendeesData();
  try {
    const res = await axios.post(
      `${URL}/gamification/getLeaderboardListMyProfile`,
      data,
      {
        headers: {
          token: `bearer ${attendee.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    if (res?.data?.status === 403) {
      redirectToLogin(res);
    } else {
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const configrationsChallenge = async (data) => {
  const attendee = await attendeesData();
  try {
    const res = await axios.post(
      `${URL}/gamification/configrations/ChallengesMyProfile`,
      data,
      {
        headers: {
          token: `bearer ${attendee.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    if (res?.data?.status === 403) {
      redirectToLogin(res);
    } else {
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const updateAgendaSecurity = async (data) => {
  const attendee = await attendeesData();
  try {
    const res = await axios.post(
      `${URL}/attendees/updateAttendeePassword`,
      data,
      {
        headers: {
          token: `bearer ${attendee.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const updateEventTemplateById = async (templateId, data) => {
  try {
    const users = await UserData();
    const res = await axios.put(`${URL}/events/templates/${templateId}`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const createTemplate = async (eventId, data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/events/${eventId}/templates`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};
export const makeTemplateActive = async (
  eventId,
  templateId,
  headers = {},
  data,
) => {
  try {
    const users = await UserData();
    const res = await axios.put(
      `${URL}/events/${eventId}/template/${templateId}/makeActive`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
          ...headers,
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};
export const updateSchedule = async (templateId, data) => {
  try {
    const users = await UserData();
    const res = await axios.put(
      `${URL}/event-templates/reschedule/${templateId}`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const cancelSchedule = async (templateId) => {
  try {
    const users = await UserData();
    const res = await axios.put(
      `${URL}/event-templates/cancelSchedule/${templateId}`,
      {},
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};
export const getSingleTemplateApi = async (templateId) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/single-event-templates-campaign/${templateId}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const cancelScheduleForSelected = async (data) => {
  const formData = new FormData();
  for (let key in data.ids) {
    formData.append(`ids.${key}`, data.ids[key]);
  }
  delete data.ids;
  for (let key in data) {
    formData.append(key, data[key]);
  }
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/event-templates/cancelScheduleForSelected`,
      formData,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const rescheduleForSelected = async (data) => {
  const formData = new FormData();
  for (let key in data.ids) {
    formData.append(`ids.${key}`, data.ids[key]);
  }
  delete data.ids;
  for (let key in data) {
    formData.append(key, data[key]);
  }
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/event-templates/rescheduleForSelected`,
      formData,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const resendEmailAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.put(`${URL}/emails/resendFailedEmails`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const EventEmailToUserAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/events/emailToUser`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const EmailsAnalyticsInfoAPI = async (data) => {
  try {
    const users = await UserData();
    let query = `${URL}/emails/info/${data.page_no}/${data.eventId}?`;
    if (data.emailType && data.emailType !== '') {
      query += `emailType=${data.emailType}&`;
    }
    if (data.templateId && data.templateId !== '') {
      query += `templateId=${data.templateId}&`;
    }

    const res = await axios.put(query, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const EmailsRegistrationAnalyticsInfoAPI = async (data) => {
  try {
    const users = await UserData();
    let query = `${URL}/emails/registrationInfo/${data.page_no}/${data.eventId}/${data.templateId}`;
    if (data.export && data.export !== '') {
      query = `${URL}/emails/registrationInfo/${data.page_no}/${data.eventId}/${data.templateId}/${data.export}`;
    }

    const res = await axios.put(query, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const EventsAllAPI = async () => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/allEvents`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const EventsAllAPIWithPagination = async (params) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/allEventsPagination?page_no=${params.page_no}&limit=${params.limit}&searchText=${params.searchText}`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const AllEventsForAiAgent = async () => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/allEventsForAiAgent`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const AllEventsForAiAgentWithPagination = async (params) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/allEventsForAiAgentPagination?page_no=${params.page_no}&limit=${params.limit}&searchText=${params.searchText}`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const GetTimezoneAPI = async () => {
  try {
    const res = await axios.get(`${URL}/getTimezone`, headers);
    return res;
  } catch (err) {
    return err;
  }
};

export const createAgenda = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/events/agenda`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const updateEventAgendaById = async (agendaId, data) => {
  try {
    const users = await UserData();
    const res = await axios.put(`${URL}/events/agenda/${agendaId}`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getEmailTemplateById = async (templateId) => {
  try {
    const res = await axios.get(`${URL}/eventTemplateById/${templateId}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const testTemplateViaEmail = async (eventId, templateId, data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/events/${eventId}/templates/${templateId}/testTemplateViaEmail`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const createTemplateToLib = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/create/template`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getTemplateLib = async (params = {}) => {
  try {
    const res = await axios.get(`${URL}/fetch/templateLib`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getTemplateLibById = async (params = {}) => {
  try {
    const res = await axios.get(`${URL}/fetch/templateLib`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const CheckEventPathAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/events/checkEventPath`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const updateLibTemplateById = async (templateId, data) => {
  try {
    const users = await UserData();
    const res = await axios.put(`${URL}/update/template/${templateId}`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getRolesAPI = async () => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/get/roles`, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getPermissionsAPI = async (data) => {
  try {
    const users = await UserData();
    let url = `${URL}/get/permissions`;
    if (data?.group_name && data.group_name !== '') {
      url += `?group_name=${data.group_name}`;
    }
    const res = await axios.get(url, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const createRoleAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/add/role`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const editRoleAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.put(`${URL}/edit/role/${data._id}`, data.payload, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const deleteRoleAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.put(`${URL}/roles/delete`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const SearchUserAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/users/search`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteUserAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.put(`${URL}/users/delete`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const InviteUserAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/users/inviteUser`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const editUserPermissionAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.patch(`${URL}/users/editUserPermission`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const ChangeUserRoleAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/users/changeMemberRole`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const ChangeUserStatusAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/users/changeMemberStatus`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const addUserRoleProjectAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/users/addProjectUser`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const DownloadCsvAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios
      .get(`${URL}/attendees/downloadSampleCsv/${data.projectId}`, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data.csvdata]),
        );

        const link = document.createElement('a');

        link.href = url;

        link.setAttribute('download', 'attendee_sample.csv'); //or any other extension

        document.body.appendChild(link);

        link.click();

        return response;
      });
    return res;
  } catch (err) {
    return err;
  }
};

export const exportAttendeesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios
      .post(`${URL}/attendees/exportAttendees`, data, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          const url = window.URL.createObjectURL(
            new Blob([response.data.csvdata]),
          );

          const link = document.createElement('a');

          link.href = url;

          link.setAttribute('download', 'attendee_list.csv'); //or any other extension

          document.body.appendChild(link);

          link.click();
        }
        return response;
      });
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCheckInHistoryAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios
      .post(`${URL}/attendees/exportCheckInHistory`, data, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data.csvdata]),
        );

        const link = document.createElement('a');

        link.href = url;

        link.setAttribute('download', 'attendee_check_in_history.csv'); //or any other extension

        document.body.appendChild(link);

        link.click();

        return response;
      });
    return res;
  } catch (err) {
    return err;
  }
};

export const ListAgendaAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/agenda/list/${data.page_no}/${data.limit}`,
      {
        projectId: data.projectId,
        sort_by: data.sort_by,
        order: data.order,
        searchText: data.searchText,
      },
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
          'check-grant': `${data.projectId}-${permissions.viewSessions}`,
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteAgendaAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.patch(
      `${URL}/agenda/delete/${data.agendaIdForDelete}/${data.projectId}`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const AddAgendaAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/agenda/addAgenda`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const EditAgendaAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.patch(
      `${URL}/agenda/update/${data.get('id')}/${data.get('projectId')}`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteMyTemplateAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.put(`${URL}/events/deleteMyTemplate`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const checkJWTAuthAPI = async () => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/checkJWTAuth`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const SearchAgendaAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/agenda/search`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const CheckGroupAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/groups/check`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getGamificationChallengesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios
      .post(`${URL}/getChallenges`, data, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.data.csvData) {
          const url = window.URL.createObjectURL(
            new Blob([response?.data?.csvData]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Leads.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        return response;
      });
    return res;
  } catch (err) {
    return err;
  }
};

export const listConfigrationChallengesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/gamification/configrations/Challenges`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
          'check-grant': `${data.projectId}-${permissions.viewGamification}`,
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const AddChallengesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/configrations/addChallenges`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const UpdateChallengesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/configrations/editChallenges`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteChallengesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.patch(
      `${URL}/configrations/deleteChallenge`,
      data.data,
      {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const ChallengeStatusAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/configrations/statusChangeChallenge`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const getAttendeesChartDataAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/attendees/getAttendeesChartData/${data.projectId}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const getEventSummaryDetailsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/eventSummaryInfo/${data.eventId}`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const GetAttendeeDetail = async (data) => {
  try {
    const attendee = await attendeesData();
    const res = await axios.get(
      `${URL}/attendees/getAttendeeDetail?id=${data.id}&eventDomain=${data.eventDomain}&eventId=${data.eventId}`,
      {
        headers: {
          token: `bearer ${attendee.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    if (res?.data?.status === 403) {
      redirectToLogin(res);
    } else {
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const GetCustomFieldsProfileAPI = async (data) => {
  try {
    const attendee = await attendeesData();
    const res = await axios.get(
      `${URL}/attendees/getCustomFieldsProfileAPI?projectId=${data.projectId}&eventDomain=${data.eventDomain}&attendeeId=${data.attendeeId}`,

      {
        headers: {
          token: `bearer ${attendee.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    if (res?.data?.status === 403) {
      redirectToLogin(res);
    } else {
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const UpdateAttendeeProfileAPI = async (data) => {
  try {
    const attendee = await attendeesData();
    const res = await axios.post(
      `${URL}/attendees/updateAttendeeProfileAPI`,
      data,
      {
        headers: {
          token: `bearer ${attendee.token}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    if (res?.data?.status === 403) {
      redirectToLogin(res);
    } else {
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const UpdateProfilePic = async (data) => {
  try {
    const attendee = await attendeesData();
    const res = await axios.post(
      `${URL}/attendees/updateAttendeesProfile`,
      data,
      {
        headers: {
          token: `bearer ${attendee.token}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const deleteProfileImageAPI = async (data) => {
  try {
    const attendees = await attendeesData();
    const res = await axios.post(`${URL}/attendees/deleteProfileImage`, data, {
      headers: {
        token: `bearer ${attendees.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const deleteAttendeeImageAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/attendees/deleteProfileImage`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getleaderboardAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/leaderBoard/userHistory`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getChallengesEngagedUsersAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/challenges/engagedUsers`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};
export const addGamificationPoinstYT = async (data) => {
  try {
    const attendees = await attendeesData();
    const res = await axios.post(
      `https://test.internal.illumeetxp.com/addpoints`,
      data,
      {
        headers: {
          token: `bearer ${attendees.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const getMyRankAPI = async (data) => {
  const attendee = await attendeesData();
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/myProfile/getTopRank`, data, {
      headers: {
        token: `bearer ${attendee.token}`,
        'Content-Type': 'application/json',
      },
    });
    if (res?.data?.status === 403) {
      redirectToLogin(res);
    } else {
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const getReportChallengesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/reportGamification`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const createGroupAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/groups-only/create`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const replicateAgendaAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/agenda/replicateAgenda`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const replicateNotificationAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/notification/replicateNotification/${data.notificationId}`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const replicateProjectAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/events/replicateProject`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const replicatePageAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/events/replicatePage`, data, {
      headers: {
        token: `bearer ${users.token}`,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const listAccessCodeAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios
      .post(`${URL}/listAccessCode`, data, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      });
    return res;
  } catch (err) {
    return err;
  }
};

export const exportAccesCodeAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios
      .post(`${URL}/exportAccesCode`, data, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.data.csvData) {
          const url = window.URL.createObjectURL(
            new Blob([response?.data?.csvData]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'AccessCode.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        return response;
      });
    return res;
  } catch (err) {
    return err;
  }
};

export const addAccessCodeAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/addAccessCode`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const editAccessCodeAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/editAccessCode`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const deleteAccessCodeAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/deleteAccessCode`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const changeAccessCodeAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/accessCodeStatusChange`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const ImportAccessCodeAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/accessCode/importCsv`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getServicePlanId = async () => {
  try {
    const users = await this.UserData();
    const res = await axios.get(`${URL}/addServicePlanInIllumeetXP`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });

    let servicePlanId;
    if (res.status === 200) {
      if (res.data.status === 200) {
        servicePlanId = res.data.servicePlanId;
        let user_details = JSON.parse(localStorage.getItem('user_details'));
        user_details.servicePlanId = servicePlanId;
        localStorage.setItem('user_details', JSON.stringify(user_details));
      } else {
        throw new Error('Something went wrong.');
      }
    } else {
      throw new Error('Something went wrong.');
    }
  } catch (error) {
    return error;
  }
};

export const FindIfAllowedFromSuperadminAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/findIfAllowedFromSuperadminAPI/${data.servicePlanId}/${data.projectId}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const FindIfAllowedFromSuperadminOuterAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/findIfAllowedFromSuperadminOuterAPI/${data.servicePlanId}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const getSessionsListForCdctAPI = async (params = {}) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/agenda/getSessionListForCdct`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
      params,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const listMeetingAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/listMeeting`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const GetBillingDataAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/getBillingData/${data.servicePlanId}`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const addMeetingAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/addMeeting`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};



export const getSlotsForMeetingApi = async (data) => {
  try { 
    const users = await UserData();
    let reqUrl = `${URL}/getSlotsForMeeting?eventId=${data.eventId}&meetingDate=${data.meetingDate}`
    if(data.meetingId){
      reqUrl += `&meetingId=${data.meetingId}`
    }
    if(data?.integrationId){
      reqUrl += `&integrationId=${data.integrationId}`
    }
    const res = await axios.get(reqUrl, 
    {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const GetProjectsBillingDataAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/getBillingProjectData/${data.servicePlanId}?page_no=${data.page_no}&limit=${data.limit}&searchText=${data.searchText}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const DeleteMeetingAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.patch(`${URL}/deleteMeeting`, data.data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
}; 

export const UpdateMeetingAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/updateMeeting`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const listAttendeesOfNotAddedToMeetingAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/meeting/listAttendeesOfNotAddedToMeeting`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const attendeeGroupsForMeetingAPI = async (data) => {
  try {
    const users = await UserData()
    const res = await axios.post(
      `${URL}/attendeeGroupsForMeeting`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    )
    return res
  } catch (err) {
    return err
  }
}

export const audienceForMeetingAPI = async (data) => {
  try {
    const users = await UserData()
    const res = await axios.post(
      `${URL}/audienceForMeeting`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    )
    return res
  } catch (err) {
    return err
  }
}

export const searchForMeetingAPI = async (data) => {
  try {
    const users = await UserData()
    const res = await axios.post(
      `${URL}/searchForMeeting`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    )
    return res
  } catch (err) {
    return err
  }
}

export const getAudienceContactAPI = async (data) => {
  try {
    const users = await UserData()
    const res = await axios.post(
      `${URL}/getAudienceContact`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    )
    return res
  } catch (err) {
    return err
  }
}

export const getMeetingTemplateAPI = async (data) => {
  try {
    const users = await UserData()
    const res = await axios.post(
      `${URL}/getMeetingTemplate`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    )
    return res
  } catch (err) {
    return err
  }
}


export const checkMeetingTitleAPI = async (data) => {
  try {
    const users = await UserData()
    const res = await axios.post(
      `${URL}/checkMeetingTitle`,
      data,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    )
    return res
  } catch (err) {
    return err
  }
}

export const checkDisabledAttendeeAPI = async (data) => {
  try {
    const users = await UserData()
    const res = await axios.get(
      `${URL}/checkDisabledAttendee?projectId=${data.projectId}&email=${data.email}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    )
    return res
  } catch (err) {
    return err
  }
}

export const replicateMeetingAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/replicateMeeting`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const assignAccessCodeAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/assignAccessCode`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getUnusedAccessCodeAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/getUnusedAccessCode`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const ReplicateCampaignAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/replicateCampaign`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getAccessCodeAPI = async (data) => {
  try {
    const users = await attendeesData();
    const res = await axios.get(
      `${URL}/accessCodeMYProfile?projectId=${data.projectId}&attendeeId=${data.attendeeId}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const assignAccessCodeProfileAPI = async (data) => {
  try {
    const users = await attendeesData();
    const res = await axios.post(`${URL}/assignAccessCodeMYProfile`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const GetUserDetailsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/getUserDetails`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const GoogleWorkspaceGetWidgetAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/integration/getIntegrationById?integrationId=${data}`, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const GetGroupsForGoogleWorkspaceAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/googleWorkspace/listGroupsForGoogleWorkspace?projectId=${data.projectId}&page_no=${data.page_no}&limit=${data.limit}&searchText=${data.searchText}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const UpdateGoogleManagementListAPI = async (data) => {
  try {
    const users = await attendeesData();
    const res = await axios.post(`${URL}/attendeesGroup/workspace/groupManagement/showToGroups`, data, {
      headers: {
        // token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};


export const checkGoogleSignInValidTokenAPI = async (data) => {
  try {
    // const users = await attendeesData();
    const res = await axios.post(`${URL}/verifyTokenOfGoogleSignedInUser`, data, {
      headers: {
        // token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};


export const changeLanguageForUserAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/changeLanguageForUser`, data, {
      headers: {
         token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  }
  catch (err) {
    return err;
  }
};

export const ListAllMeetingsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/listAllMeeting?eventId=${data.eventId}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const addAudienceCustomFieldsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/audience/createCustomFieldsForAudience`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const listAudienceCustomFieldsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(
      `${URL}/audience/getCustomFieldsForAudience`,

      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const deleteAudienceCustomFieldsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/audience/deleteCustomFieldsForAudience`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const updateAudienceCustomFieldsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/audience/updateCustomFieldsForAudience`, data, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
}

export const uploadAiChatbotFile = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/chatbot/uploadAiChatbotFile`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: `bearer ${users.token}`,

        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const uploadAiChatSettingsFiles = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(
      `${URL}/chatbot/uploadAiChatSettingsFiles`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: `bearer ${users.token}`,

        },
      },
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const assignManagerAPI = async (data) => {
  try { 
    const users = await UserData();
    const res = await axios.post(`${URL}/assignManager`, data,{
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const allUserListAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/users/listAll?email=${data?.email}`,
    {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const listAllUsersAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/listAllUsers`, data,
    {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const updateSlotsAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/updateSlots`, data,
    {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }};


export const getSlotsAPI = async (data) => {
  try {
    const users = await UserData();
    let reqUrl = `${URL}/getSlots?eventId=${data.eventId}`
    const res = await axios.get(reqUrl, 
    {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const googleSignInPopupForCalendar = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/meeting/calendar/connect`, data,
    {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getMeetingsIntegrationDetails = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/meeting/integrationDetails?eventId=${data.eventId}`,
    {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const disconnectGoogleCalendar = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/meeting/calendar/disconnect`, data,
    {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getUsersByAcceptStAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/user/getUsersByAcceptSt?acceptSt=${data.acceptSt}`,
    {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getActiveUsersForCompanyAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/user/getActiveUsersForCompany?searchText=${data?.searchText || ''}`,
    {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getChatbotForEventPageAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/chatbot/getChatbotForEventPage?projectId=${data.eventId}`,
    {
      headers: {
        // token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getApiKeyAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/openApi/checkApiKeyExist`,
    {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const generateApiKeyAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.patch(`${URL}/openApi/generateApiKey`,
    data,
    {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};


export const createFolderForFilesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/files/createFolder`, data, {
      headers: {
         token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  }
  catch (err) {
    return err;
  }
};

export const renameFolderAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/files/renameFilesObject`, data, {
      headers: {
         token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  }
  catch (err) {
    return err;
  }
};

export const deleteFolderByIdAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/files/deleteFilesObject`, data, {
      headers: {
         token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  }
  catch (err) {
    return err;
  }
};

export const addImagesAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/files/uploadImage`, data, {
      headers: {
         token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  }
  catch (err) {
    return err;
  }
};

export const addDocumentAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/files/uploadDoc`, data, {
      headers: {
         token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  }
  catch (err) {
    return err;
  }
};

export const uploadVideoAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.post(`${URL}/files/videoUpload`, data, {
      headers: {
         token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  }
  catch (err) {
    return err;
  }
};

export const getFoldersListAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/files/getFilesFolders?section=${data.section}&parent=${data.parent}&searchText=${data.searchText}&limit=${data.limit}&sortBy=${data.sortBy}&sortOrder=${data.sortOrder}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      });
    return res;
  }
  catch (err) {
    return err;
  }
};

export const getFileToDownloadAPI = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/files/downloadFile?section=${data.section}&fileName=${data.fileName}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      });
    return res;
  }
  catch (err) {
    return err;
  }
};

export const getFileToDownloadAPIForChatBot = async (data) => {
  try {
    const users = await UserData();
    const res = await axios.get(`${URL}/chatbot/downloadAiChatbotFile?keyInS3=${data.keyInS3}&fileName=${data.fileName}`,
      {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      });
    return res;
  }
  catch (err) {
    return err;
  }
};