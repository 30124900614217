import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from '../../Redux/Actions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MoreIcon from '../../assets/images/icons/more.svg';
import ImportIcon from '../../assets/images/icons/import.svg';
import ExportIcon from '../../assets/images/icons/export.svg';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Loader from '../../Components/Loader/loader';
import DataTable from 'react-data-table-component';
import Pagination from 'react-js-pagination';
import { Modal } from 'react-responsive-modal';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIconImg from '../../assets/images/icons/search.svg';
import AddIcon from '@material-ui/icons/Add';
import { isThisQuarter } from 'date-fns';
import axios from 'axios';
import { createNotification } from '../../helpers';
import { NotificationContainer } from 'react-notifications';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Fade from '@material-ui/core/Fade';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { theme } from '../../theme/theme';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { ThemeProvider } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getPreparePermission, getProjectIdFromUrl } from '../../helpers/common';
import { Grid, Box, Stack } from '@mui/material';
import { act } from '@testing-library/react';
import moment from 'moment';


import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { permissions } from '../../constants/constants';


const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};

class TeamDashboard extends Component {
  constructor(props) {
    super(props);
    this.permData = {};
    this.isTotalRecords = true;
    this.anchorRef1 = React.createRef();
    this.anchorRef2 = React.createRef();
    this.state = {
      setOpen: false,
      open: false,
      InviteUserModal: false,
      setShowResults: false,
      members: [],
      page_no: 1,
      page_no2: 1,
      loading: false,
      totalResults: 0,
      searchText: "",
      searchMembers: "",
      users: [],
      lazyLoad: false,
      active: {},
      assignRole: -1,
      invitedEmail: "",
      roleAssign: -1,
      errors: {},
      itemCount: 10,
      startCount: 1,
      open1: false,
      open2: false,
      allowed: {},
      rolesFilterOptionsList: [],
      rolesFilterOptionsSelected: [],
      roles: [],
      projectUserId: "",
      changeProjectUserRole: false,
      listOfUsers: [],
      teamMembersChanged: false
    };
  }

  componentDidMount() {
    console.log("this.props----", this.props);
    const projectId = getProjectIdFromUrl();
    this.setState({
      projectId
    });
    const requestData = { projectId, page_no: this.state.page_no, permission: "View Members", limit: this.state.itemCount, roles: this.state.rolesFilterOptionsSelected, searchText: this.state.searchText.trim() };
    this.props.listProjectUsersRequest(requestData);
    this.props.getRoles();
    this.handleLoading();
    if (this.state.itemCount < this.state.totalResults) {
      this.setState({
        endCount: parseInt(this.state.itemCount),
        projectId,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // forPagination 
    if (prevState.itemCount !== this.state.itemCount) {
      if (this.state.itemCount < this.state.totalResults) {
        this.setState({
          endCount: parseInt(this.state.itemCount),
        });
      }
      else {
        this.setState({
          endCount: parseInt(this.props.members.totalResults)
        });
      }
    }

    if (this.isTotalRecords && this.props.members.listSuccess) {
      this.isTotalRecords = false;
      this.setState({
        totalResults: this.props.members.totalResults
      }, () => {
        if (this.state.itemCount > this.state.totalResults) {
          this.setState({
            endCount: parseInt(this.state.totalResults),
          });
        } else if (this.state.itemCount < this.state.totalResults) {
          this.setState({
            endCount: parseInt(this.state.itemCount)
          });
        }
      });
      this.props.members.listSuccess = false;
    }
    // paginationEndsHere

    if (this.props.members && this.props.members.listSuccess == true) {
      // finding if the selected service plan allows to create more teams
      let userDetails = JSON.parse(localStorage.getItem('user_details'));
      let reqData = {
        servicePlanId: userDetails.servicePlanId,
        projectId: this.state.projectId
      };
      this.props.findIfAllowedFromSuperadminRequest(reqData);
      // finding if the selected service plan allows to create more teams ends
      this.setState({ totalResults: this.props.members.totalResults });
      if (this.state.itemCount >= this.props.members.totalResults) {
        this.setState({
          endCount: this.props.members.totalResults
        });
      } else {
        this.setState({
          endCount: this.state.totalResults > this.state.itemCount * this.state.page_no ? this.state.itemCount * this.state.page_no : this.state.totalResults,
        });
      }
      this.props.members.listSuccess = false;
    }

    if (this.props.members &&
      this.props.members.listProjectUsersSuccess == true && this.props.members.message !== prevProps.members.message) {
      this.setState({
        rolesFilterOptionsList: this.props.members.filterCnt,
        loading:false
      });
    }

    if (this.props.project &&
      this.props.project.project) {
      this.permData = getPreparePermission(this.props.project.project.permissions);
      let allowed = {};
      if (this.permData) {
        Object.keys(this.permData).forEach((data) => {
          if (['edit members'].indexOf(data.toLowerCase()) !== -1) {
            allowed["edit members"] = true;
          }
          if (['view members'].indexOf(data.toLowerCase()) !== -1) {
            allowed["view members"] = true;
          }
        });
        this.state.allowed = allowed;
      }
    }

    const el = document.getElementById('popupLazyLoader');
    if (el != null) {
      el.addEventListener('scroll', () => {
        if (el.scrollTop > (522 * this.state.page_no2)) {
          this.state.page_no2 = this.state.page_no2 + 1;
          this.props.getUsersList({ page_no: this.state.page_no2, from: "Team" });
        }
      });
    }

    if (this.props.users.listSuccess && !prevProps.users.listSuccess && this.props.users.message !== prevProps.users.message) {
      this.setState({ listOfUsers: [...this.state.listOfUsers, ...this.props.users.users] ,loading:false});
    }

    if (this.props.members.error === true && this.props.members.message !== prevProps.members.message) {
      this.listProjectUsersRequest();
      this.setState({
        loading:false
      })
      createNotification("error", this.props.error.message);
    }

  }

  listProjectUsersRequest = () => {
    const projectId = getProjectIdFromUrl();
    const requestData = {
      projectId,
      page_no: this.state.page_no,
      limit: this.state.itemCount,
      permission: "View Members",
      roles: this.state.rolesFilterOptionsSelected,
      searchText: this.state.searchText.trim()
    };
    this.props.listProjectUsersRequest(requestData);
    const totalResults = this.props.members.totalResults;
    this.setState({
      totalResults,
    });
    this.handleLoading();
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
      page_no: 1,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.searchText !== '' &&
      this.state.searchText.trim().length > 0
    ) {
      this.searchTeamRequest();
    } else {
      this.state.searchText = '';
      this.searchTeamRequest();
    }
  };

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  searchTeamRequest() {
    const { searchText } = this.state;
    const projectId = getProjectIdFromUrl();
    const requestData = { projectId, searchText: searchText.trim(), page_no: this.state.page_no, limit: this.state.itemCount, permission: "View Members", roles: this.state.rolesFilterOptionsSelected };
    this.props.listProjectUsersRequest(requestData);
    this.handleLoading();
  }

  handlePageChange = (pageNumber) => {
    const { itemCount, totalResults } = this.state;
    const projectId = getProjectIdFromUrl();
    this.setState((prevState, props) => ({
      startCount: pageNumber !== 1 ? (itemCount * (pageNumber - 1) + 1) : 1,
      endCount: totalResults > itemCount * pageNumber ? itemCount * pageNumber : totalResults,
      page_no: pageNumber,
    }), () => this.listProjectUsersRequest());
  };

  selectItemCount = (e) => {
    const { totalResults } = this.state;
    this.setState({
      page_no: 1,
      itemCount: e.target.value,
      endCount: parseInt(totalResults),
      startCount: 1,
    }, () => this.listProjectUsersRequest()
    );
  };

  onOpenModal = () => {
    this.setState({
      open: true,
      setShowResults: false,
      active: {},
      errors: {},
      loading:true
    });
    this.props.getUsersList({ page_no: this.state.page_no, from: "Team" });
  };

  onCloseModal = () => {
    this.setState({
      open: false,
      listOfUsers: [],
      page_no2: 1,
      teamMembersChanged: false,
      errors: {}
    });
  };

  onInviteUserModal = () => {
    this.setState({ InviteUserModal: true });
  };

  onCloseInviteUserModal = () => {
    this.setState({
      InviteUserModal: false
    });
  };

  addTeam = (id) => {
    let { active } = this.state;
    active[id] = true;
    if (this.props.members && this.props.members.members[0].indexOf(id) != -1) {
      this.props.members.members[0].push(id);
    }
    this.setState({
      setShowResults: true,
      active,
      teamMembersChanged: true
    });
  };

  removeTeam = (id) => {
    let { active } = this.state;
    active[id] = false;
    if (this.props.members && this.props.members.members[0].indexOf(id) != -1) {
      let index = this.props.members.members[0].indexOf(id);
      delete this.props.members.members[0][index];
    }
    this.setState({
      active,
      teamMembersChanged: true
    });
  };

  removeSelectedTeamUser = async (id) => {
    this.setState({
      active: {}
    }, async () => {
      await this.removeTeam(id);
    });
    this.setState({
      deleteTeamConfirm: true
    });
  };

  handleTeamDeleteConfirmation = () => {
    this.setState({
      deleteTeamConfirm: false
    });
  };

  setRoleAssignment = (e) => {
    this.setState({
      assignRole: e.target.value
    });
  };

  saveTeam = async (method) => {
    const projectId = getProjectIdFromUrl();
    let error = {};
    if (Object.keys(this.state.active).length > 0) {
      const URL = process.env.REACT_APP_API_URL;
      const dataAssign = Object.keys(this.state.active).filter((data) => {
        return this.state.active[data] == false;
      });
      const dataAssignNot = Object.keys(this.state.active).filter((data) => {
        return this.state.active[data] == true;
      });
      if(this.state.assignRole == -1 && dataAssignNot.length > 0){
        error['noRoleAssignedError'] = "Please select a role for the user to assign";
        this.setState({ errors: error });
        if (method === "invite") {
          return false;
        }
      }  
      if ((this.state.assignRole == -1 && dataAssign.length == 0)) {
        error['noRoleAssignedError'] = "Please select a role for the user to assign";
        this.setState({ errors: error });
        if (method === "invite") {
          return false;
        }
      }
      let data = {
        userIds: this.state.active,
        roleId: this.state.assignRole,
        projectId: projectId,
      };
      this.handleLoading();
      try {
        const resp = await axios.post(`${URL}/users/addProjectUser`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              "check-grant": `${data.projectId}-${permissions.editMembers}`
            }
          });
        if (resp.data.message === "Role assigned successfully" && resp.status === 200 && data.roleId < 0) {
          createNotification("success", this.props.users.languageStateForRedux?.messages?.removeMemberSuccess);
        } else if (resp.data.message === "Role assigned successfully" && resp.status === 200 && data.roleId > 0) {
          createNotification("success", this.props.users.languageStateForRedux?.messages?.roleAssignedSuccess);
        }
        this.setState({
          setShowResults: false,
          open: false,
          isRemovingMember: false,
          listOfUsers: [],
          page_no2: 1
        });
      } catch (err) {
        createNotification("error", "Something went wrong in API");
      }
      const requestData = { projectId, page_no: 1, limit: this.state.itemCount, permission: "View Members", roles: this.state.rolesFilterOptionsSelected };
      this.props.listProjectUsersRequest(requestData);
      this.setState({
        active: {},
        startCount: 1,
        deleteTeamConfirm: false,
        assignRole: -1,
        teamMembersChanged: false
      });
    } else {
      createNotification("error", "No changes found");
    }
    this.setState({ errors: error });
  };

  updateTeam = async () => {
    let error = {};
    const projectId = getProjectIdFromUrl();
    const URL = process.env.REACT_APP_API_URL;
    if (this.state.projectUserId == "") {
      createNotification('error', "Please select the user to assign a role");
      return false;
    }
    if (this.state.assignRole == -1) {
      error['noRoleAssignedError'] = "Please select a role for the user to assign";
      this.setState({ errors: error });
      return false;
    }
    let data = {
      userId: this.state.projectUserId,
      roleId: this.state.assignRole,
      projectId: projectId,
      permission: "Edit Members",
      lngCode: this.props.users.newLangState,
      joinedOn: this.state.joinedOn
    };
    this.handleLoading();
    try {
      const editData = await axios.post(`${URL}/users/editProjectUser`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "check-grant": `${data.projectId}-${permissions.editMembers}`
          }
        });
      if (editData.status == 200) {
        createNotification("success", this.props.users.languageStateForRedux?.messages?.roleChangeSuccess);
        this.onCloseProjectUserRoleUserModal();
        const requestData = { projectId, page_no: 1, limit: this.state.itemCount, permission: permissions.viewMembers };
        this.props.listProjectUsersRequest(requestData);
      } else {
        throw Error("API Error");
      }
    } catch (err) {
      createNotification("error", "Something went wrong in API");
    }
    this.setState({
      active: {},
      startCount: 1,
      deleteTeamConfirm: false,
      assignRole: -1
    });
    this.setState({ errors: error });
  };

  handleSearchUsers = (e) => {
    e.preventDefault();
    this.setState({
      listOfUsers: [],
      page_no2: 1
    }, () => {
      const { searchMembers } = this.state;
      this.props.getUsersList({ searchText: searchMembers.trim(), page_no: 1, from: "Team" });
      this.handleLoading();
    });
  };

  handleEmailAssignment = (e) => {
    e.preventDefault();
    this.setState({
      invitedEmail: e.target.value
    });
  };

  handleRoleAssignement = (role) => {
    this.setState({
      roleAssign: role
    });
  };

  handleOnInviteUser = async (e) => {
    e.preventDefault();
    if (this.isValid()) {
      const { invitedEmail, roleAssign } = this.state;
      if (roleAssign == -1) {
        createNotification("error", "Please select a role to invite user");
        return;
      }
      const URL = process.env.REACT_APP_API_URL;
      const user = JSON.parse(localStorage.getItem('user_details'));
      this.handleLoading();
      axios.post(`${URL}/users/inviteUser`,
        { invitedUser: invitedEmail, roleId: roleAssign },
        {
          headers: {
            "Content-Type": "application/json",
            "token": `bearer ${user.token}`
          }
        }).then((data) => {
          if (data.data.status == 200) {
            createNotification("success", data.data.message);
            this.handleLoading();
            this.setState({ InviteUserModal: false, open: false });
          } else {
            createNotification("error", data.data.message);
            this.handleLoading();
          }
        }).catch((err) => {
          createNotification("error", "Something went wrong");
          this.handleLoading();
        });
    }
  };

  isValid = () => {
    const { invitedEmail } = this.state;
    let error = {};
    let formIsValid = true;
    const regexTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (invitedEmail === '' || invitedEmail.trim().length === 0) {
      formIsValid = false;
      error['invitedEmail'] = '*Please enter email.';
    }
    if (invitedEmail && regexTest.test(invitedEmail) === false) {
      formIsValid = false;
      error['invitedEmail'] = '*Please enter a valid email address.';
    }
    this.setState({ errors: error });
    return formIsValid;
  };

  handleToggle1 = () => {
    this.setState({
      open1: !this.state.open1
    });
  };

  handleToggle2 = () => {
    this.setState({
      open2: !this.state.open2
    });
  };

  filterHandleClose = (event) => {
    this.setState({
      open1: false,
      open2: false,
    });
  };

  handleFilterChange = (e, selectedFilter) => {
    e.preventDefault();
    let rolesFilterOptionsSelectedArray = [...this.state.rolesFilterOptionsSelected];
    if (selectedFilter === "roles") {
      if (e.target.name === "all") {
        if (e.target.checked === true) {
          rolesFilterOptionsSelectedArray = [...this.state.rolesFilterOptionsList.map(el => el._id.role_id)];
        } else {
          rolesFilterOptionsSelectedArray = [];
        }
      } else {
        let role_id = parseInt(e.target.name);
        let filterIndex = rolesFilterOptionsSelectedArray.indexOf(role_id);
        if (filterIndex > -1) {
          rolesFilterOptionsSelectedArray.splice(filterIndex, 1);
        } else {
          rolesFilterOptionsSelectedArray.push(role_id);
        }
      }
    }
    this.setState({
      rolesFilterOptionsSelected: [...rolesFilterOptionsSelectedArray],
      page_no: 1,
      searchText: '',
      itemCount: 10,
    }, () => {
      this.listProjectUsersRequest();
    }
    );
  };

  handleOnChangeRole = (userId, roleId, role_name, createdAt) => {
    this.setState({
      changeProjectUserRole: true,
      projectUserId: userId,
      assignRole: roleId,
      joinedOn: createdAt
    });
  };

  onCloseProjectUserRoleUserModal = () => {
    this.setState({
      changeProjectUserRole: false,
      projectUserId: "",
      assignRole: -1,
      active: {},
      startCount: 1,
      deleteTeamConfirm: false,
    });
  };

  render() {
    let tableData = [];
    let columns = [
      {
        name: this.props.users.languageStateForRedux?.column_names?.teams?.email,
        selector: 'Email',
        sortable: true,
        minWidth: '250px',
      },
      {
        name: this.props.users.languageStateForRedux?.column_names?.teams.role,
        selector: 'Role',
        sortable: true,
        minWidth: '250px',
      },
      {
        name: this.props.users.languageStateForRedux?.column_names?.teams?.lastAcc,
        selector: 'Last Access On',
        minWidth: '170px',
      },
      {
        name: this.props.users.languageStateForRedux?.column_names?.teams?.joinedProject,
        selector: 'Joined Project',
        minWidth: '250px',
      },
    ];

    if (this.state.allowed["edit members"]) {
      columns.push({
        name: this.props.users.languageStateForRedux?.common?.action,
        selector: 'options',
        maxWidth: '50px'
      });
    }
    const { loading, page_no, totalResults, startCount, endCount, itemCount, allowed, changeProjectUserRole, teamMembersChanged } = this.state;
    const {
      open, InviteUserModal, setShowResults, active, open1, open2, rolesFilterOptionsList, rolesFilterOptionsSelected, listOfUsers
    } = this.state;
    const rolesFilterOptionsSelectedAll = rolesFilterOptionsList.length === rolesFilterOptionsSelected.length ? true : false;

    return (
      <ThemeProvider theme={theme} className="tab-container">
        <Grid container spacing={2} mb={2} className="top-filter-area">
          <Grid item sm>
            <Grid container spacing={1}>
              <Grid item>
                <Box
                  className="active"
                  component={Button}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.background.light,
                    color: theme.palette.common.black,
                    textTransform: 'capitalize !important',
                    fontWeight: '400',
                    '&:hover, &.active': {
                      backgroundColor: '#000056 !important',
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  size="small"
                  disableElevation
                  ref={this.anchorRef2}
                  aria-controls={open2 ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleToggle2}
                  endIcon={<ArrowDropDownIcon />}
                >
                  {this.props.users.languageStateForRedux?.dropdowns?.teams?.allRoles}
                </Box>
                <Popper
                  open={open2}
                  placement="bottom-start"
                  className="filter-options"
                  transition
                  anchorEl={this.anchorRef2.current}
                  role={undefined}
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Fade {...TransitionProps} timeout={250}>
                      <Box
                        className="option-list"
                        sx={{
                          backgroundColor: theme.palette.background.light,
                          borderRadius: '4px',
                          marginTop: '8px',
                          overflowY: 'scroll',
                          maxHeight: '200px',
                        }}
                      >
                        <ClickAwayListener onClickAway={this.filterHandleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={this.handleListKeyDown}
                          >
                            <MenuItem className="checkbox-item">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    className="checkbox"
                                    color="primary"
                                    name="all"
                                    checked={rolesFilterOptionsSelectedAll}
                                    onChange={(e) =>
                                      this.handleFilterChange(e, 'roles')
                                    }
                                  />
                                }
                                label={this.props.users.languageStateForRedux?.common?.all + `(${this.props.members.filterCnt.length > 0
                                  ? this.props.members.filterCnt
                                    .map((item) => item.count)
                                    .reduce((prev, curr) => prev + curr, 0)
                                  : 0
                                  })`}
                              />
                            </MenuItem>
                            {rolesFilterOptionsList.map((option) => {
                              return (
                                <MenuItem className="checkbox-item">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        className="checkbox"
                                        color="default"
                                        name={option._id.role_id}
                                        onChange={(e) =>
                                          this.handleFilterChange(e, 'roles')
                                        }
                                        checked={
                                          rolesFilterOptionsSelected.indexOf(
                                            option._id.role_id,
                                          ) > -1
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    label={`${option._id.role_name} (${option.count})`}
                                  />
                                </MenuItem>
                              );
                            })}
                          </MenuList>
                        </ClickAwayListener>
                      </Box>
                    </Fade>
                  )}
                </Popper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1.25} className="team-dash-right">
              <Grid item className="">
                <form onSubmit={this.handleSubmit}>
                  {allowed['view members'] && (
                    <TextField
                      name="searchText"
                      placeholder={this.props.users.languageStateForRedux?.common?.search}
                      className="search"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img alt="" src={SearchIconImg} />
                          </InputAdornment>
                        ),
                      }}
                      value={this.state.searchText}
                      onChange={this.handleOnChange}
                    />
                  )}
                </form>
              </Grid>
              {allowed['edit members'] && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.onOpenModal}
                  >
                    {this.props.users.languageStateForRedux?.buttons?.teams?.add}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <div className="list-table">
          <div className="react-data-table-container">
            {this.props.members &&
              this.props.members.members &&
              this.props.members.members.length > 1 && (
                <table className="react-data-table">
                  <tbody>
                    {this.props.members &&
                      this.props.members.members &&
                      this.props.members.members.length > 0 &&
                      this.props.members.members.map((data, index) => {
                        if (index > 0) {
                          var status = (
                            <span
                              className={
                                data.status.toLowerCase() == 'disabled'
                                  ? 'table-status danger'
                                  : data.status.toLowerCase() == 'pending'
                                    ? 'table-status light'
                                    : data.status.toLowerCase() == 'active'
                                      ? 'table-status success'
                                      : ''
                              }
                            >
                              {' '}
                              {data.status}
                            </span>
                          );
                          let d = {
                            Email: data.email,
                            Role: data.role_name,
                            Status: status,
                            'Last Access On':
                              data.lastLoginAt === null
                                ? '--'
                                : moment(data.lastLoginAt).format(
                                  'dddd, MMM, Do YYYY, h:mm A',
                                ),
                            'Joined Project': moment.tz(data.createdAt, this.props?.project?.project?.timezone).format(
                              'dddd, MMM, Do YYYY, h:mm A',
                            ),
                            options: (
                              <div className="options">
                                {allowed["edit members"] &&
                                  <>
                                    {' '}
                                    <div className="options-button">
                                      <img alt="" src={MoreIcon} />{' '}
                                    </div>
                                    <div className="options-list">
                                      <div
                                        onClick={() =>
                                          this.handleOnChangeRole(data.userId, data.role, data.role_name, data.createdAt)
                                        }
                                      >
                                        {this.props.users.languageStateForRedux?.actions?.teams?.changeRole}
                                      </div>
                                      <hr />
                                      <div
                                        onClick={() =>
                                          this.removeSelectedTeamUser(data.userId)
                                        }
                                      >
                                        {this.props.users.languageStateForRedux?.actions?.teams?.removeMember}
                                      </div>
                                    </div>
                                  </>
                                }
                              </div>
                            ),
                          };
                          tableData.push(d);
                        }
                      })}
                    {tableData.length > 0 && (
                      <DataTable columns={columns} data={tableData} />
                    )}
                  </tbody>
                </table>
              )}
            {!this.state.loading && tableData.length === 0 ? (
              <Box component={TableContainer} pt={2} className="list-table">
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell className="email">{this.props.users.languageStateForRedux?.column_names?.teams?.fullName}</TableCell> */}
                      <TableCell className="status">{this.props.users.languageStateForRedux?.column_names?.teams?.email}</TableCell>
                      <TableCell className="text">{this.props.users.languageStateForRedux?.column_names?.teams?.role}</TableCell>
                      <TableCell className="date-time">
                      {this.props.users.languageStateForRedux?.column_names?.teams?.lastAcc}
                      </TableCell>
                      <TableCell className="text">{this.props.users.languageStateForRedux?.column_names?.teams?.joinedProject}</TableCell>
                      <TableCell className="options">{this.props.users.languageStateForRedux?.common?.action}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan="7" style={{ textAlign: 'center' }}>
                        {this.props.users.languageStateForRedux?.common?.noRecords}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            ) : null}
            {tableData.length !== 0 && (
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                className="tablePagination"
              >
                <Grid item>
                  <Box component="span" className="small-subtitle">
                    {this.props.users.languageStateForRedux?.pagination?.showing} {startCount} {this.props.users.languageStateForRedux?.pagination?.of} {totalResults}
                  </Box>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.shows}</span>
                      <select value={itemCount} onChange={this.selectItemCount}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.entries}</span>
                    </Grid>
                    <Grid item>
                      <Pagination
                        activePage={page_no}
                        itemsCountPerPage={this.state.itemCount}
                        totalItemsCount={totalResults}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                        prevPageText={
                          <NavigateBeforeIcon style={{ fontSize: 18 }} />
                        }
                        nextPageText={
                          <NavigateNextIcon style={{ fontSize: 18 }} />
                        }
                        itemClassFirst="first d-none"
                        itemClassLast="last d-none"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
        {loading ? <Loader /> : null}
        <Modal
          open={open}
          onClose={this.onCloseModal}
          classNames={{
            overlay: 'team_add-remove-people_overlay',
            modal: 'team_add-remove-people_modal',
          }}
          center
        >
          <div className="modal-wrapper">
            <div className="modal-header">
              <h1>{this.props.users.languageStateForRedux?.actions?.teams?.inviteHeading}</h1>
            </div>
            <div className="modal-body">
              <form onSubmit={this.handleSearchUsers}>
                <TextField
                  id="outlined-full-width"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img alt="" src={SearchIconImg} />
                      </InputAdornment>
                    ),
                  }}
                  label=""
                  name="searchMembers"
                  variant="outlined"
                  placeholder={
                    (this.props.users.languageStateForRedux?.formfields?.teams?.search) + " " +
                    (this.props.users && this.props.users.users
                      ? this.props.users.totalResults
                      : 0) + " " +
                      (this.props.users.languageStateForRedux?.formfields?.teams?.members)
                  }
                  onChange={this.handleOnChange}
                  autoComplete='off'
                />
              </form>
              <div className="people-list" id="popupLazyLoader">
                <table className="table contactTableInner">
                  {listOfUsers.length > 0 ?
                    listOfUsers.map((user, index) => {
                      return (
                        <tbody>
                          <tr key={'users-' + index}>
                            <td style={{alignContent:'center',}}>
                              <Box display="flex" alignItems={"center"}>
                                <Box className="person-details">
                                    {user.email}{' '}
                                </Box>
                              </Box>
                            </td>
                            {false && (
                              <td>
                                {' '}
                                <Box textAlign="right">
                                  <Button color="primary">Creator</Button>
                                </Box>{' '}
                              </td>
                            )}
                            {((this.props.members &&
                              this.props.members.members &&
                              this.props.members.members.length > 0 &&
                              this.props.members.members[0].indexOf(
                                user._id,
                              ) !== -1) ||
                              this.state.active[user._id]) && (
                                <td>
                                  <Box textAlign="right">
                                    <Button
                                      size='small'
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => {
                                        this.removeTeam(user._id);
                                      }}
                                    >
                                      {this.props.users.languageStateForRedux?.buttons?.teams?.remove}
                                    </Button>
                                  </Box>
                                </td>
                              )}
                            {this.props.members &&
                              this.props.members.members &&
                              this.props.members.members.length > 0 &&
                              this.props.members.members[0].indexOf(
                                user._id,
                              ) === -1 &&
                              !this.state.active[user._id] && (
                                <td>
                                  <Box textAlign="right">
                                    <Button
                                      variant="outlined"
                                      size='small'
                                      color="primary"
                                      onClick={() => {
                                        this.addTeam(user._id);
                                      }}
                                    >
                                      {this.props.users.languageStateForRedux?.buttons?.teams?.addOnly}
                                    </Button>
                                  </Box>
                                </td>
                              )}
                          </tr>
                        </tbody>
                      );

                    })
                    :
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "center" }}>{this.props.users.languageStateForRedux?.common?.noRecords}</td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
              {setShowResults && Object.values(this.state.active).some(el => el === true)? (
                <Box mb={5}>
                  <FormControl
                    size="small"
                    fullWidth
                  >
                    <label>{this.props.users.languageStateForRedux?.dropdowns?.teams?.selectRole} <span className='required'>*</span></label>
                    <Select
                      class="form-control select"
                      onChange={this.setRoleAssignment}
                      style={{ padding: '0px 0px', }}
                      displayEmpty
                      MenuProps={MenuProps}
                      name="roleAssign"
                      id="outlined-age-native-simple"
                      value={this.state.assignRole}
                    >
                      <MenuItem value={-1} disabled>{this.props.users.languageStateForRedux?.dropdowns?.teams?.selectRole}</MenuItem>
                      {this.props.roles &&
                        this.props.roles.roles &&
                        this.props.roles.roles.map((role, index) => {
                          return (
                            <MenuItem value={role.role_id}>{role.role_name}</MenuItem>
                          );
                        })}
                    </Select>
                    <p className="error_mesage">
                      {this.state.errors['noRoleAssignedError']}
                    </p>
                  </FormControl>

                </Box>

              ) : null}

              <Box>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  fullWidth
                  onClick={() => this.saveTeam('invite')}
                  disabled={!teamMembersChanged}
                >
                  {this.props.users.languageStateForRedux?.buttons?.teams?.saveChanges}

                  {active && Object.keys(active).length > 0
                    ? ` ${this.props.users.languageStateForRedux?.buttons?.teams?.for} ${Object.keys(active).length} ${this.props.users.languageStateForRedux?.buttons?.teams?.users}`
                    : null}

                </Button>
              </Box>
            </div>
          </div>
        </Modal>
        <Modal
          open={InviteUserModal}
          onClose={this.onCloseInviteUserModal}
          classNames={{
            overlay: 'team_add-remove-people_overlay',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <div className="modal-wrapper">
            <div className="modal-header">
              <Box className='subtitle'>Invite new users</Box>
              <p className="small-subtitle">
                Your teammates will get an email that gives them access to your
                team.
              </p>
            </div>
            <div className="modal-body">
              <form>
                <div class="field-group fullWidthInput inputField mb-3">
                  <label>Invite with email</label>
                  <input
                    type="text"
                    class="form-control"
                    name="title"
                    placeholder="Enter one or more email addresses"
                    onChange={this.handleEmailAssignment}
                    required
                  />
                  <p className="error_mesage">
                    {this.state.errors.invitedEmail}
                  </p>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="admin"
                    name="Role"
                    value="Admin"
                    onClick={() => {
                      this.handleRoleAssignement(3);
                    }}
                  />
                  <label class="custom-control-label" for="admin">
                    Co-Admin
                  </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="member"
                    name="Role"
                    value="Member"
                    onClick={() => {
                      this.handleRoleAssignement(2);
                    }}
                  />
                  <label class="custom-control-label" for="member">
                    Member
                  </label>
                </div>
                <Box display="flex" justifyContent="end" className="mt-5">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleOnInviteUser}
                  >
                    Send Invite
                  </Button>
                </Box>
              </form>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.deleteTeamConfirm}
          onClose={this.handleTeamDeleteConfirmation}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Box component={Grid} container className="" px={1.5}>
            <Box component={Grid} mt={-1} item xs={12} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {this.props.users.languageStateForRedux?.common?.confirmDelete}
              </Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.actions?.teams?.deleteMsg}
              </p>
            </Box>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  {
                    <>
                      <Button
                        variant="contained"
                        color="secondary"
                        disableElevation
                        onClick={() => this.saveTeam('remove')}
                      >
                        {this.props.users.languageStateForRedux?.buttons?.teams?.remove}
                      </Button>
                      <Button
                        variant="outlined"
                        disableElevation
                        onClick={this.handleTeamDeleteConfirmation}
                      >
                        {this.props.users.languageStateForRedux?.common?.cancel}
                      </Button>
                    </>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal
          open={changeProjectUserRole}
          onClose={this.onCloseProjectUserRoleUserModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid item xs={12} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {this.props.users.languageStateForRedux?.actions?.teams?.changeRole}
              </Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.actions?.teams?.changeRoleMsg}
              </p>
            </Grid>
            <Grid item xs={12} pb={4}>
              <Box component="div" className="inputField" pb={1}>
                <label>{this.props.users.languageStateForRedux?.actions?.teams?.roles}</label>
                <FormControl
                  size="small"
                  fullWidth
                >
                  <Select
                    type="text"
                    className="form-control select"
                    onChange={this.setRoleAssignment}
                    value={this.state.assignRole}
                    style={{ padding: '0px 0px', }}
                    MenuProps={MenuProps}
                    displayEmpty
                  >
                    {this.props.roles &&
                      this.props.roles.roles &&
                      this.props.roles.roles.map((role, index) => {
                        return (
                          <MenuItem value={role.role_id}>{role.role_name}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.updateTeam}
                  >
                    {this.props.users.languageStateForRedux?.unlayer?.save}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={this.onCloseProjectUserRoleUserModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <NotificationContainer />
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  members: state.Members,
  events: state.Events,
  users: state.Users,
  roles: state.Roles,
  project: state.Projects
});

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      createEvent: Actions.eventsRequest,
      checkEventDomain: Actions.checkEventDomainRequest,
      listProjectUsersRequest: Actions.listProjectUsersRequest,
      getUsersList: Actions.getUsersListRequest,
      searchUserRequest: Actions.searchUserRequest,
      getRoles: Actions.getRolesRequest,
      findIfAllowedFromSuperadminRequest: Actions.findIfAllowedFromSuperadminRequest
    },
    dispatch
  );

export default connect(mapStateToProps, mapActionsToProps)(TeamDashboard);
