import { Grid, Stack } from '@mui/material'
import { Box, Button } from '@material-ui/core'
import CustomSwitch from 'Components/customSwitch'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Actions } from 'Redux/Actions'
import { WidgetState } from 'Redux/Reducers/integrations/appsConfigModal.Reducer'

const WidgetConfiguration: React.FC<{ onClose: () => any }> = ({ onClose }) => {
  const configState = useSelector((store: any) => store.AppConfigModal)
  const [state, setState] = useState<WidgetState>({ ...configState })
  const dispatch = useDispatch()
  useEffect(() => {
    setState((s: any) => {
      return { ...s, widget: configState.widget }
    })
  }, [configState.widget])
  const save = () => {
    dispatch(Actions.tawkToUpdateWidget(state.widget))
  }

  const onInputChange = (target: any) => {
    const { name, checked } = target
    const disabled = !checked
    let notification = { ...state.widget.notification }
    let visibility = { ...state.widget.visibility }
    let features = { ...state.widget.features }
    if (name === 'estimatedWaitTime') {
      notification.all.estimatedWaitTime = disabled
    } else if (name === 'agentTyping') {
      notification.all.agentTyping = disabled
    } else if (name === 'sound') {
      notification.all.sound = disabled
    } else if (name === 'visitorTyping') {
      notification.all.visitorTyping = disabled
    } else if (name === 'tab') {
      notification.all.tab = disabled
    } else if (name === 'desktop.preview') {
      notification.desktop.preview = disabled
    } else if (name === 'mobile.preview') {
      notification.mobile.preview = disabled
    } else if (name === 'showWhenOffline') {
      visibility.all.showWhenOffline = disabled
    } else if (name === 'mobile.show') {
      visibility.mobile.show = disabled
    } else if (name === 'desktop.show') {
      visibility.desktop.show = disabled
    } else if (name === 'features.emoji') {
      features.emoji = disabled
    } else if (name === 'features.rating') {
      features.rating = disabled
    } else if (name === 'features.transcript') {
      features.transcript = disabled
    } else if (name === 'features.uploads') {
      features.uploads = disabled
    }
    setState({
      ...state,
      widget: {
        ...state.widget,
        notification: notification,
        visibility: visibility,
        features: features,
      },
    })
  }
  if (state.success && state.actionType === 'TAWK_TO_UPDATE_WIDGET_SUCCESS') {
    onClose()
  }
  return (
    <Grid container rowSpacing={{ xs: 2, xl: 2.5 }} columnSpacing={5}>
      {/* <Grid item xs={12} md={12}>
        <Box component="div" className="inputField">
          <label>
            Consent Form<span className="required">*</span>
          </label>
          <select className="form-control small">
            <option>Display option</option>
            <option>Attendees</option>
            <option>Groups</option>
          </select>
        </Box>
      </Grid> */}
      <Grid item xs={12} md={12}>
        <Box mb={3} component="div" className="inputField">
          <label>
            Widget Behaviour <span className="required">*</span>
          </label>
        </Box>

        <Grid container rowSpacing={0} columnSpacing={5}>
          <Grid item xs={12} md={12}>
            <Box component="div" className="inputField">
              <label className="text-primary-light">
                Notification Settings
              </label>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box ml={-1.5} component="div" className="inputField">
              <CustomSwitch
                color={'primary'}
                onChange={(e: any) => onInputChange(e.target)}
                name="estimatedWaitTime"
                checked={!state.widget.notification.all.estimatedWaitTime}
              />
              <span className="paragraph">Hide estimated wait time</span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box ml={-1.5} component="div" className="inputField">
              <CustomSwitch
                color={'primary'}
                onChange={(e: any) => onInputChange(e.target)}
                name="agentTyping"
                checked={!state.widget.notification.all.agentTyping}
              />
              <span className="paragraph">
                Disable agent typing notification
              </span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box ml={-1.5} component="div" className="inputField">
              <CustomSwitch
                color={'primary'}
                onChange={(e: any) => onInputChange(e.target)}
                name="sound"
                checked={!state.widget.notification.all.sound}
              />
              <span className="paragraph">Disable sound notification</span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box ml={-1.5} component="div" className="inputField">
              <CustomSwitch
                color={'primary'}
                onChange={(e: any) => onInputChange(e.target)}
                name="visitorTyping"
                checked={!state.widget.notification.all.visitorTyping}
              />
              <span className="paragraph">Disable visitor typing function</span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box ml={-1.5} component="div" className="inputField">
              <CustomSwitch
                color={'primary'}
                onChange={(e: any) => onInputChange(e.target)}
                name="desktop.preview"
                checked={!state.widget.notification.desktop.preview}
              />
              <span className="paragraph">
                Disable message preview on desktop
              </span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box ml={-1.5} component="div" className="inputField">
              <CustomSwitch
                color={'primary'}
                onChange={(e: any) => onInputChange(e.target)}
                name="tab"
                checked={!state.widget.notification.all.tab}
              />
              <span className="paragraph">
                Disable browser tab notification
              </span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box ml={-1.5} component="div" className="inputField">
              <CustomSwitch
                color={'primary'}
                onChange={(e: any) => onInputChange(e.target)}
                name="mobile.preview"
                checked={!state.widget.notification.mobile.preview}
              />
              <span className="paragraph">
                Disable message preview on mobile
              </span>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container rowSpacing={{ xs: 0.4, xl: 0 }} columnSpacing={5}>
          <Grid item xs={12} md={12}>
            <Box component="div" className="inputField">
              <label className="text-primary-light">Visibility Settings</label>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box ml={-1.5} component="div" className="inputField">
              <CustomSwitch
                color={'primary'}
                onChange={(e: any) => onInputChange(e.target)}
                name="showWhenOffline"
                checked={!state.widget.visibility.all.showWhenOffline}
              />
              <span className="paragraph">Hide widget when offline</span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box ml={-1.5} component="div" className="inputField">
              <CustomSwitch
                color={'primary'}
                onChange={(e: any) => onInputChange(e.target)}
                name="desktop.show"
                checked={!state.widget.visibility.desktop.show}
              />
              <span className="paragraph">Hide widget on load in desktop</span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box ml={-1.5} component="div" className="inputField">
              <CustomSwitch
                color={'primary'}
                onChange={(e: any) => onInputChange(e.target)}
                name="mobile.show"
                checked={!state.widget.visibility.mobile.show}
              />
              <span className="paragraph">Hide widget on load in mobile</span>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container rowSpacing={0} columnSpacing={5}>
          <Grid item xs={12} md={12}>
            <Box component="div" className="inputField">
              <label className="text-primary-light">Feature Settings</label>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box ml={-1.5} component="div" className="inputField">
              <CustomSwitch
                color={'primary'}
                onChange={(e: any) => onInputChange(e.target)}
                name="features.emoji"
                checked={!state.widget.features.emoji}
              />
              <span className="paragraph">Disable emoji selection</span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box ml={-1.5} component="div" className="inputField">
              <CustomSwitch
                color={'primary'}
                onChange={(e: any) => onInputChange(e.target)}
                name="features.uploads"
                checked={!state.widget.features.uploads}
              />
              <span className="paragraph">Disable file upload</span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box ml={-1.5} component="div" className="inputField">
              <CustomSwitch
                color={'primary'}
                onChange={(e: any) => onInputChange(e.target)}
                name="features.rating"
                checked={!state.widget.features.rating}
              />
              <span className="paragraph">Disable chat rating</span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box ml={-1.5} component="div" className="inputField">
              <CustomSwitch
                color={'primary'}
                onChange={(e: any) => onInputChange(e.target)}
                name="features.transcript"
                checked={!state.widget.features.transcript}
              />
              <span className="paragraph">Disable email transcript</span>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12}>
        <Box component="div" className="inputField">
          <label>
            On Click Behavior <span className="required">*</span>
          </label>
          <select
            onChange={(e: any) =>
              setState((st) => {
                st.widget.behavior.click = e.target.value
                return { ...st }
              })
            }
            value={state.widget.behavior.click}
            className="form-control small"
          >
            <option value="max">Maximize</option>
            <option value="pop">Popout</option>
          </select>
        </Box>
      </Grid>
      {/* Buttons */}
      <Grid item xs={12}>
        <Grid container justifyContent="end">
          <Stack spacing={1} direction="row">
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={save}
              variant="contained"
              color="primary"
              disableElevation
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default WidgetConfiguration
