import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  profileRequest: ['data'],
  profileSuccess: ['token', 'user', 'message'],
  profileError: ['error'],
  deleteAccountRequest: ['data'],
  deleteAccountSuccess: ['message'],
  deleteAccountError: ['error'],
  emailVerifyRequest: ['data'],
  emailVerifySuccess: ['message'],
  emailVerifyError: ['error'],

  emailVerifiedRequest: ['data'],
  emailVerifiedSuccess: ['message'],
  emailVerifiedError: ['error'],

  myProfileRankRequest:['data'],
  myProfileRankSuccess:['message', 'topRank', 'myRank'],
  myProfileRankError:['error'],

  getAccessCodeRequest: ['data'],
  getAccessCodeSuccess: ['message','accessCode','remainingHours'],
  getAccessCodeError: ['error'],

  assignAccessCodeProfileRequest: ['data'],
  assignAccessCodeProfileSuccess: ['message'],
  assignAccessCodeProfileError: ['error'],

  getCustomFieldsProfileRequest:['data'],
  getCustomFieldsProfileSuccess:['message','customFields','eventData','pagePath','challengeData'],
  getCustomFieldsProfileError: ['error'],
})

export const Constants = Types
export default Creators
