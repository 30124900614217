import React, { Component } from 'react'
import '../../sass/main.scss'
import DashboardLayout from '../../Layouts/DashboardLayout'
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import Box from '@material-ui/core/Box'
import tableContact from '../../assets/images/tableContact.png'

class EmailDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Tabvalue: 0,
      theme: '',
      Emailvalue: 0,
      title: 'Complete Email',
      pageTitle: 'Complete Email',
    }
  }
  handleChange = (event, newEvalue) => {
    this.setState({ Tabvalue: newEvalue })
  }
  handleChangeIndex = (index) => {
    this.setState({ Tabvalue: index })
  }
  handleChangeEmail = (event, newEmailValue) => {
    this.setState({ Emailvalue: newEmailValue })
  }
  handleChangeEmailIndex = (index) => {
    this.setState({ Emailvalue: index })
  }

  render() {
    function TabPanel(props) {
      const { children, Tabvalue, value, index, ...other } = props
       return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <div>{children}</div>
            </Box>
          )}
        </div>
      )
    }

    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    }

    function a11yProps(index) {
      return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
      }
    }

    const { Tabvalue, theme, Emailvalue, title, pageTitle } = this.state
    return (
      <DashboardLayout title={title} pageTitle={pageTitle}>
        <div className="dashboardMiddleArea">
          <div className="filterSearchBar asideTabs">
            <div className="filterControler buttonGroups">
              <Tabs
                value={Tabvalue}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Emails" {...a11yProps(0)} />
                <Tab label="My Template" {...a11yProps(1)} />
              </Tabs>
            </div>
            <div className="filterControler mobile-None buttonGroups">
              <Button type="button" className="outlineButton mr-2">
                Export
              </Button>
              <Button type="button" className="themeBlueButton">
                New Email
              </Button>
            </div>
            <div className="filterControler middleFilterCont desktop-None">
              <a href="#" className="exportEvent ctrlBtn mr-2">
                <i className="fa fa-arrow-circle-up"></i>
              </a>
              <a href="#" className="newEvent ctrlBtn">
                <i className="fa fa-plus"></i>
              </a>
            </div>
          </div>
          <div className="emailTemplateDashboardInbox">
            <div className="ETdetails_section">
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={Tabvalue}
                onChangeIndex={this.handleChangeIndex}
              >
                <TabPanel dir={theme.direction} value={Tabvalue} index={0}>
                  <div className="ETBoxStyle emailBoxenArea">
                    <div className="emailDashboardTabs">
                      <Tabs
                        value={Emailvalue}
                        onChange={this.handleChangeEmail}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab label="Draft" {...a11yProps(0)} />
                        <Tab label="Scheduled" {...a11yProps(1)} />
                        <Tab label="Sent" {...a11yProps(2)} />
                      </Tabs>
                    </div>
                    <div className="allEmailSubjects">
                      <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={Emailvalue}
                        onChangeIndex={this.handleChangeEmailIndex}
                      >
                        <TabPanel
                          dir={theme.direction}
                          value={Emailvalue}
                          index={0}
                        >
                          <div className="emailChatBox">
                            <div className="myEventTable">
                              <div className="eventTableInner">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">Email</th>
                                      <th scope="col">Created</th>
                                      <th scope="col">Last Edit</th>
                                      <th scope="col">Recipients</th>
                                      <th scope="col" className="tableSetting">
                                        Action
                                        <div className="lefRightAction">
                                          <i class="fa fa-chevron-circle-left"></i>
                                          <i class="fa fa-chevron-circle-right"></i>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="tableUserInfo">
                                        <label className="checkBoxColl paddingLeft25">
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                        <span className="eventName">
                                          Hohnd@example.com
                                        </span>
                                      </td>
                                      <td>22 Dec 2020</td>
                                      <td>08 Dec 2020</td>
                                      <td className="inviteContactsTd">
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="inviteMore"> +8 </span>
                                        <a href="#">Invite new</a>
                                      </td>
                                      <td className="tableDotMore">
                                        <button className="tableDots">
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="tableUserInfo">
                                        <label className="checkBoxColl paddingLeft25">
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                        <span className="eventName">
                                          Hohnd@example.com
                                        </span>
                                      </td>
                                      <td>22 Dec 2020</td>
                                      <td>08 Dec 2020</td>
                                      <td className="inviteContactsTd">
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="inviteMore"> +8 </span>
                                        <a href="#">Invite new</a>
                                      </td>
                                      <td className="tableDotMore">
                                        <button className="tableDots">
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="tableUserInfo">
                                        <label className="checkBoxColl paddingLeft25">
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                        <span className="eventName">
                                          Hohnd@example.com
                                        </span>
                                      </td>
                                      <td>22 Dec 2020</td>
                                      <td>08 Dec 2020</td>
                                      <td className="inviteContactsTd">
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="inviteMore"> +8 </span>
                                        <a href="#">Invite new</a>
                                      </td>
                                      <td className="tableDotMore">
                                        <button className="tableDots">
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="tableUserInfo">
                                        <label className="checkBoxColl paddingLeft25">
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                        <span className="eventName">
                                          Hohnd@example.com
                                        </span>
                                      </td>
                                      <td>22 Dec 2020</td>
                                      <td>08 Dec 2020</td>
                                      <td className="inviteContactsTd">
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="inviteMore"> +8 </span>
                                        <a href="#">Invite new</a>
                                      </td>
                                      <td className="tableDotMore">
                                        <button className="tableDots">
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="tablePagination">
                                <nav className="paginationNav mb-3">
                                  <ul className="pagination">
                                    <li className="prev">
                                      <a className="page-link" href="#">
                                        Prev
                                      </a>
                                    </li>
                                    <li className="page-item active">
                                      <a className="page-link" href="#">
                                        1
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        2
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        3
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        4
                                      </a>
                                    </li>
                                    <li className="next">
                                      <a className="page-link" href="#">
                                        Next
                                      </a>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                        <TabPanel
                          dir={theme.direction}
                          value={Emailvalue}
                          index={1}
                        >
                          <div className="emailChatBox">
                            <div className="myEventTable">
                              <div className="eventTableInner">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">Email</th>
                                      <th scope="col">Created</th>
                                      <th scope="col">Last Edit</th>
                                      <th scope="col">Recipients</th>
                                      <th scope="col" className="tableSetting">
                                        Action
                                        <div className="lefRightAction">
                                          <i class="fa fa-chevron-circle-left"></i>
                                          <i class="fa fa-chevron-circle-right"></i>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="tableUserInfo">
                                        <label className="checkBoxColl paddingLeft25">
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                        <span className="eventName">
                                          Hohnd@example.com
                                        </span>
                                      </td>
                                      <td>22 Dec 2020</td>
                                      <td>08 Dec 2020</td>
                                      <td className="inviteContactsTd">
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="inviteMore"> +8 </span>
                                        <a href="#">Invite new</a>
                                      </td>
                                      <td className="tableDotMore">
                                        <button className="tableDots">
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="tableUserInfo">
                                        <label className="checkBoxColl paddingLeft25">
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                        <span className="eventName">
                                          Hohnd@example.com
                                        </span>
                                      </td>
                                      <td>22 Dec 2020</td>
                                      <td>08 Dec 2020</td>
                                      <td className="inviteContactsTd">
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="inviteMore"> +8 </span>
                                        <a href="#">Invite new</a>
                                      </td>
                                      <td className="tableDotMore">
                                        <button className="tableDots">
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="tableUserInfo">
                                        <label className="checkBoxColl paddingLeft25">
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                        <span className="eventName">
                                          Hohnd@example.com
                                        </span>
                                      </td>
                                      <td>22 Dec 2020</td>
                                      <td>08 Dec 2020</td>
                                      <td className="inviteContactsTd">
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="inviteMore"> +8 </span>
                                        <a href="#">Invite new</a>
                                      </td>
                                      <td className="tableDotMore">
                                        <button className="tableDots">
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="tableUserInfo">
                                        <label className="checkBoxColl paddingLeft25">
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                        <span className="eventName">
                                          Hohnd@example.com
                                        </span>
                                      </td>
                                      <td>22 Dec 2020</td>
                                      <td>08 Dec 2020</td>
                                      <td className="inviteContactsTd">
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="inviteMore"> +8 </span>
                                        <a href="#">Invite new</a>
                                      </td>
                                      <td className="tableDotMore">
                                        <button className="tableDots">
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="tablePagination">
                                <nav className="paginationNav mb-3">
                                  <ul className="pagination">
                                    <li className="prev">
                                      <a className="page-link" href="#">
                                        Prev
                                      </a>
                                    </li>
                                    <li className="page-item active">
                                      <a className="page-link" href="#">
                                        1
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        2
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        3
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        4
                                      </a>
                                    </li>
                                    <li className="next">
                                      <a className="page-link" href="#">
                                        Next
                                      </a>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                        <TabPanel
                          dir={theme.direction}
                          value={Emailvalue}
                          index={2}
                        >
                          <div className="emailChatBox">
                            <div className="myEventTable">
                              <div className="eventTableInner">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">Email</th>
                                      <th scope="col">Created</th>
                                      <th scope="col">Last Edit</th>
                                      <th scope="col">Recipients</th>
                                      <th scope="col" className="tableSetting">
                                        Action
                                        <div className="lefRightAction">
                                          <i class="fa fa-chevron-circle-left"></i>
                                          <i class="fa fa-chevron-circle-right"></i>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="tableUserInfo">
                                        <label className="checkBoxColl paddingLeft25">
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                        <span className="eventName">
                                          Hohnd@example.com
                                        </span>
                                      </td>
                                      <td>22 Dec 2020</td>
                                      <td>08 Dec 2020</td>
                                      <td className="inviteContactsTd">
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="inviteMore"> +8 </span>
                                        <a href="#">Invite new</a>
                                      </td>
                                      <td className="tableDotMore">
                                        <button className="tableDots">
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="tableUserInfo">
                                        <label className="checkBoxColl paddingLeft25">
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                        <span className="eventName">
                                          Hohnd@example.com
                                        </span>
                                      </td>
                                      <td>22 Dec 2020</td>
                                      <td>08 Dec 2020</td>
                                      <td className="inviteContactsTd">
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="inviteMore"> +8 </span>
                                        <a href="#">Invite new</a>
                                      </td>
                                      <td className="tableDotMore">
                                        <button className="tableDots">
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="tableUserInfo">
                                        <label className="checkBoxColl paddingLeft25">
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                        <span className="eventName">
                                          Hohnd@example.com
                                        </span>
                                      </td>
                                      <td>22 Dec 2020</td>
                                      <td>08 Dec 2020</td>
                                      <td className="inviteContactsTd">
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="inviteMore"> +8 </span>
                                        <a href="#">Invite new</a>
                                      </td>
                                      <td className="tableDotMore">
                                        <button className="tableDots">
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="tableUserInfo">
                                        <label className="checkBoxColl paddingLeft25">
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                        <span className="eventName">
                                          Hohnd@example.com
                                        </span>
                                      </td>
                                      <td>22 Dec 2020</td>
                                      <td>08 Dec 2020</td>
                                      <td className="inviteContactsTd">
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="tableInvite">
                                          <img src={tableContact} alt="" />
                                        </span>
                                        <span className="inviteMore"> +8 </span>
                                        <a href="#">Invite new</a>
                                      </td>
                                      <td className="tableDotMore">
                                        <button className="tableDots">
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                          <span className="tableDotsExpo"></span>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="tablePagination">
                                <nav className="paginationNav mb-3">
                                  <ul className="pagination">
                                    <li className="prev">
                                      <a className="page-link" href="#">
                                        Prev
                                      </a>
                                    </li>
                                    <li className="page-item active">
                                      <a className="page-link" href="#">
                                        1
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        2
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        3
                                      </a>
                                    </li>
                                    <li className="page-item">
                                      <a className="page-link" href="#">
                                        4
                                      </a>
                                    </li>
                                    <li className="next">
                                      <a className="page-link" href="#">
                                        Next
                                      </a>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                      </SwipeableViews>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel dir={theme.direction} value={Tabvalue} index={1}>
                  <div className="ETBoxStyle MyTemplateBoxenArea"></div>
                </TabPanel>
              </SwipeableViews>
            </div>
          </div>
        </div>
      </DashboardLayout>
    )
  }
}

export default EmailDashboard
