import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Radio,
  OutlinedInput,
  RadioGroup,
} from '@material-ui/core';

import Grid from '@mui/material/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Stack from '@mui/material/Stack';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {
  FormGroup,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import {
  Account,
  AccountsState,
  ContactCf,
  Source,
} from 'Redux/Reducers/opportunity/accounts.reducer';
import { FetchOpportunityQueryParams } from './Opportunity';
import {
  CustomField,
  CustomFields_State,
} from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  Contact,
  ContactState,
} from 'Redux/Reducers/opportunity/contacts.reducer';
import { ProjectsList } from 'Pages/AIChatbot/Tabs/General/GeneralDashboard';
import { EventsAllAPIWithPagination, getActiveUsersForCompanyAPI } from 'Redux/API';
import { Actions } from 'Redux/Actions';
import {
  isValidEmail,
  isValidURL,
  validateSocialMediaUrl,
} from 'helpers/common';
import CustomSearchableDropdownWithLoadMore from 'Components/CustomDropdowns/CustomSearchableDropdownWithLoadMore';
import CustomSearchableDropdownForContactAndAccountDataTypeCF from 'Components/CustomDropdowns/CustomSearchableDropdownForContactAndAccountDataTypeCF';
import OpportunityContactsAPI from 'Redux/API/opportunity/opportunityContactsAPI';
import {
  AccountCf,
  AssignedTo,
  Opportunity,
  OpportunityState,
  Status,
  CustomFields,
  Project,
} from 'Redux/Reducers/opportunity/opportunities.reducer';
import CustomSearchableDropdown from 'Components/CustomDropdowns/CustomSearchableDropdown';
import CreateOrEditContact from '../Accounts/CreateOrEditContact';
import ViewContactOrAccountDetails from '../Accounts/ViewContactOrAccountDetails';
import OpportunityAccountsAPI from 'Redux/API/opportunity/opportunityAccountsAPI';
import OpportunityAccountsCustomFieldsAPI from 'Redux/API/opportunity/opportunityCustomFieldsAPI';
import Loader from 'Components/Loader/loader';
import OpportunityOpportunitiesAPI from 'Redux/API/opportunity/opportunityOpportunitiesAPI';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import CreateOrEditAccount from './CreateOrEditAccount';
import moment from 'moment';
import DropdownForListDataTypeCF from '../Common/DropdownForListDataTypeCF';
import InputFieldForPhoneDataTypeCF from '../Common/InputFieldForPhoneDataTypeCF';
import {
  OpportunityStates as OpportunityDynamicStates,
  OpportunityStates_State,
} from 'Redux/Reducers/opportunity/opportunityStates.reducer';
import CustomSearchableMultiSelectedDropdownWithLoadMore from 'Components/CustomDropdowns/CustomSearchableMultiSelectDropdownWithLoadMore';
import AiIcon from '../../../assets/images/icons/Ai-icon.svg'
import { createNotification } from 'helpers';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};

type Props = {
  usedFor: 'Account' | 'Opportunity';
  queryParams: FetchOpportunityQueryParams;
  toBeEdited: Opportunity;
  resetAddNew: () => void;
  setShowAddNew: React.Dispatch<React.SetStateAction<boolean>>;
};

type CustomFieldsValidationError = {
  [key: string]: boolean | string;
};

export type CustomFieldsObj = {
  [key: string]: string | string[] | ContactCf | AccountCf;
};

type GetProjectsParams = {
  page_no: number;
  limit: number;
  searchText: string;
};

type BodyParams = {
  name: string;
  customFields: CustomFields;
  _id?: string;
  assignedToId: string;
  projectId: string;
  status?: Status;
  state?: string;
  source: Source;
};

const limit = 20;

let changesMadeInCreateOrEditContact = false;
let changesMadeInCreateOrEditAccount = false;

const AddOrEditOpportunity = ({
  queryParams,
  toBeEdited,
  resetAddNew,
  setShowAddNew,
}: Props) => {
  const firstRender1 = useRef(true);
  const firstRender2 = useRef(true);
  const firstRender3 = useRef(true);
  const firstRender4 = useRef(true);
  const firstRender5 = useRef(true);
  const firstRender6 = useRef(true);
  const contactChildRefs = useRef<any>([]);
  const accountChildRefs = useRef<any>([]);
  const areChildAccountFormsInvalid = useRef(false);
  // const completeValidationDoneChildAccountForms = useRef(false);
  const haveToCallCreateOrUpdateContactFunctionInChild = useRef(false);
  const haveToCallCreateOrUpdateAccountFunctionInChild = useRef(false);
  const savingContactAlso = useRef(false);
  const savingAccountAlso = useRef(false);
  const hasCalledSaveFunction = useRef(false);
  const addingNewAccount = useRef(false);

  const { customFields, isLoading: isCustomFieldsLoading } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;

  const accountState = useSelector(
    (state: any) => state.OpportunityAccounts,
  ) as AccountsState;

  const { activeUsersForCompany } = useSelector((state: any) => state.Users);

  const contactState = useSelector(
    (state: any) => state.OpportunityContacts,
  ) as ContactState;

  const opportunityState = useSelector(
    (state: any) => state.OpportunityOpportunities,
  ) as OpportunityState;

  const {
    opportunityStates: opportunityDynamicStates,
    fetchAllOpportunityStateSuccess,
  } = useSelector(
    (state: any) => state.OpportunityStates,
  ) as OpportunityStates_State;

  const dispatch = useDispatch();

  // State variables
  const [name, setName] = useState<string>('');
  const [customFieldsObj, setCustomFieldsObj] = useState<CustomFieldsObj>({});
  const [idBeingEdited, setIdBeingEdited] = useState<string>('');
  const [customFieldsValidationError, setCustomFieldsValidationError] =
    useState<CustomFieldsValidationError>({} as CustomFieldsValidationError);
  const [project, setProject] = useState<Project>({} as Project);

  //for searchableDropdownWithLoadMore
  const [projectsList, setProjectsList] = useState<ProjectsList>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [searchTextForContactDropdown, setSearchTextForContactDropdown] =
    useState<string>('');
  const [searchTextForAccountDropdown, setSearchTextForAccountDropdown] =
    useState<string>('');
  const [pageNo, setPageNo] = useState<number>(1);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const [contactsSearchLists, setContactsSearchLists] = useState<{
    [key: string]: Contact;
  } | null>(null);
  const [accountsSearchLists, setAccountsSearchLists] = useState<{
    [key: string]: Contact;
  } | null>(null);
  const [selectedContactAsCFValue, setSelectedContactAsCFValue] = useState<{
    [key: string]: Contact;
  }>({});
  const [selectedAccountAsCFValue, setSelectedAccountAsCFValue] = useState<{
    [key: string]: Account;
  }>({});
  const [
    previouslySelectedContactAsCFValue,
    setPreviouslySelectedContactAsCFValue,
  ] = useState<{
    [key: string]: Contact;
  }>({});
  const [
    previouslySelectedAccountAsCFValue,
    setPreviouslySelectedAccountAsCFValue,
  ] = useState<{
    [key: string]: Account;
  }>({});
  const [activeCfIdForContactDropdown, setActiveCfIdForContactDropdown] =
    useState<string>('');
  const [activeCfIdForAccountDropdown, setActiveCfIdForAccountDropdown] =
    useState<string>('');
  const [dynamicContactOpenState, setdynamicContactOpenState] = useState<{
    [key: string]: boolean;
  }>({});
  const [dynamicAccountOpenState, setdynamicAccountOpenState] = useState<{
    [key: string]: boolean;
  }>({});

  const [status, setStatus] = useState<Status>('new');
  const [source, setSource] = useState<Source>('manuallyAdded');
  const [dynamicStates, setDynamicStates] = useState<OpportunityDynamicStates>(
    [],
  );
  const [selectedDynamicState, setSelectedDynamicState] = useState<string>('');

  const [assignTo, setAssignTo] = useState<AssignedTo>({} as AssignedTo);
  const [usersSearchList, setUsersSearchList] = useState<{}[] | null>(null);
  const [customFieldsFromContacts, setCustomFieldsFromContacts] = useState<
    CustomField[]
  >({} as CustomField[]);
  const [customFieldsFromAccounts, setCustomFieldsFromAccounts] = useState<
    CustomField[]
  >({} as CustomField[]);
  const [errors, setErrors] = useState<CustomFieldsValidationError>({});
  
  const [
    openAddNewContactConfirmationPopup,
    setOpenAddNewContactConfirmationPopup,
  ] = useState<boolean>(false);

  const [
    openAddNewAccountConfirmationPopup,
    setOpenAddNewAccountConfirmationPopup,
  ] = useState<boolean>(false);

  const [
    validationRequiredInContactForms,
    setValidationRequiredInContactForms,
  ] = useState<boolean[]>([]);

  const [
    validationRequiredInAccountForms,
    setValidationRequiredInAccountForms,
  ] = useState<boolean[]>([]);

  const [cfIdForAddNewContact, setCfIdForAddNewContact] = useState<string>('');
  const [cfIdForAddNewAccount, setCfIdForAddNewAccount] = useState<string>('');
  const [contactConfirmationText, setContactConfirmationText] =
    useState<string>('');
  const [accountConfirmationText, setAccountConfirmationText] =
    useState<string>('');
  const [
    openChangeSelectedContactConfPopup,
    setOpenChangeSelectedContactConfPopup,
  ] = useState<boolean>(false);
  const [
    openChangeSelectedAccountConfPopup,
    setOpenChangeSelectedAccountConfPopup,
  ] = useState<boolean>(false);
  const [
    optionForHandleContactCFChangeForSearchableDropdown,
    setOptionForHandleContactCFChangeForSearchableDropdown,
  ] = useState<any>({});
  const [
    optionForHandleAccountCFChangeForSearchableDropdown,
    setOptionForHandleAccountCFChangeForSearchableDropdown,
  ] = useState<any>({});
  const [
    cfidForHandleContactCFChangeForSearchableDropdown,
    setCfidForHandleContactCFChangeForSearchableDropdown,
  ] = useState<string>('');
  const [
    cfidForHandleAccountCFChangeForSearchableDropdown,
    setCfidForHandleAccountCFChangeForSearchableDropdown,
  ] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [usersForUserCfDataType, setUsersForUserCfDataType] = useState([]);
  const [searchTextForUsers, setSearchTextForUsers] = useState('')
  const [searchTextForUsersDebouncedValue, setSearchTextForUsersDebouncedValue] = useState('')

  useEffect(() => {
    setCustomFieldsObj(settingCustomFieldObjWithUndeletedCustomFields());
    fetchAllOpportunityCustomField();
    getAllOpportunityGroups();
    return () => {
      dispatch(Actions.setIsSavingAsDraft(false));
    };
  }, []);

  useEffect(() => {
    let timeout: any;
    if (firstRender3.current) {
      firstRender3.current = false;
    } else {
      if (searchTextForContactDropdown.length > 2) {
        timeout = setTimeout(() => {
          fetchAllContacts();
        }, 500);
      }
      if (timeout !== undefined) {
        return () => clearTimeout(timeout);
      }
    }
  }, [searchTextForContactDropdown]);

  useEffect(() => {
    let timeout: any;
    if (firstRender6.current) {
      firstRender6.current = false;
    } else {
      if (name.length) {
        timeout = setTimeout(() => {
          checkIfNameAlreadyExists();
        }, 500);
      }
      if (timeout !== undefined) {
        return () => clearTimeout(timeout);
      }
    }
  }, [name]);

  const getAllOpportunityGroups = () => {
    dispatch(Actions.getAllOpportunityGroupsRequest());
  };

  const checkIfNameAlreadyExists = async () => {
    let opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
    const bodyParams = {
      name: name?.trim(),
      id: idBeingEdited || '',
    };
    let res = await opportunityOpportunitiesAPI.checkIfNameAlreadyExists(
      bodyParams,
    );
    if (res.data) {
      setErrors((prev) => ({ ...prev, name: 'Name already exists' }));
    } else {
      setErrors((prev) => ({ ...prev, name: '' }));
    }
  };

  useEffect(() => {
    let timeout: any;
    if (firstRender4.current) {
      firstRender4.current = false;
    } else {
      if (searchTextForAccountDropdown.length > 2) {
        timeout = setTimeout(() => {
          fetchAllAccounts();
        }, 500);
      }
      if (timeout !== undefined) {
        return () => clearTimeout(timeout);
      }
    }
  }, [searchTextForAccountDropdown]);

  useEffect(() => {
    if (firstRender1.current) {
      firstRender1.current = false;
      setShowAddNew(true);
    } else if (opportunityState.updateOpportunityOpportunitySuccess) {
      setShowAddNew(false);
    }
  }, [opportunityState.updateOpportunityOpportunitySuccess]);

  useEffect(() => {
    if (firstRender2.current) {
      firstRender2.current = false;
      setShowAddNew(true);
    } else if (opportunityState.createOpportunityOpportunitySuccess) {
      setShowAddNew(false);
    }
  }, [opportunityState.createOpportunityOpportunitySuccess]);

  // for CustomSearchableDropdownWithLoadMore starts
  useEffect(() => {
    const params = {
      page_no: pageNo,
      limit: limit,
      searchText: searchText.trim(),
    };
    getProjectList(params);
  }, [pageNo]);

  useEffect(() => {
    if (firstRender5.current) {
      firstRender5.current = false;
    } else {
      setPageNo(1);
      const params = {
        page_no: 1,
        limit: limit,
        searchText: searchText?.trim(),
      };
      const timeout = setTimeout(() => {
        getProjectList(params);
      }, 400);
      return () => clearTimeout(timeout);
    }
  }, [searchText]);

  // Set account details when accountToBeEdited changes
  useEffect(() => {
    if (toBeEdited) {
      if (Object.keys(toBeEdited).length) {
        setName(toBeEdited.name);
        if (toBeEdited.customFields)
          settingCustomFieldObjWithExistingCustomFieldsForAGivenContact(
            toBeEdited.customFields,
          );
        setIdBeingEdited(toBeEdited._id);
        if (toBeEdited.project) {
          setProject(toBeEdited.project);
        }
        if (toBeEdited.assignedTo) {
          setAssignTo(toBeEdited.assignedTo);
        }
        if (toBeEdited.source) {
          setSource(toBeEdited.source);
        }
        if (toBeEdited.status) {
          setStatus(toBeEdited.status);
        }
        if (toBeEdited.state?._id) {
          setSelectedDynamicState(toBeEdited.state._id.toString());
        }
      }
    }
  }, [toBeEdited]);

  useEffect(() => {
    if (opportunityState.isSavingAsDraft) {
      handleAddOrUpdate();
    }
  }, [opportunityState.isSavingAsDraft]);

  const fetchAllOpportunityCustomField = () => {
    const opportunityAccountsCustomFieldsAPI =
      new OpportunityAccountsCustomFieldsAPI();
    let from = 'createOpportunity';
    if (toBeEdited && Object?.keys(toBeEdited).length) {
      from = 'editOpportunity';
    }
    let arr = ['contacts', 'accounts'];
    arr.forEach(async (el) => {
      try {
        const data = {
          customFieldType: el,
          from: from,
        };
        const res =
          await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
            data,
          );
        if (el === 'accounts') {
          setCustomFieldsFromAccounts(res.data.customFields);
        } else if (el === 'contacts') {
          setCustomFieldsFromContacts(res.data.customFields);
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  useEffect(() => {
    if (fetchAllOpportunityStateSuccess) {
      setDynamicStates(opportunityDynamicStates);
    }
  }, [fetchAllOpportunityStateSuccess]);

  const fetchAllContacts = async () => {
    let from = 'createOpportunity';
    if (toBeEdited && Object?.keys(toBeEdited).length) {
      from = 'editOpportunity';
    }
    const data = {
      queryParams: {
        pageNo: 1,
        searchOnlyName: true,
        from: from,
      },
      bodyParams: { filterArray: [], searchText: searchTextForContactDropdown?.trim() },
    };
    const opportunityContactsAPI = new OpportunityContactsAPI();
    const res = await opportunityContactsAPI.fetchAllOpportunityContacts(data);
    setContactsSearchLists((prev) => ({
      ...prev,
      [activeCfIdForContactDropdown]: res.data.contacts,
    }));
  };

  const fetchAllAccounts = async () => {
    let from = 'createOpportunity';
    if (toBeEdited && Object?.keys(toBeEdited).length) {
      from = 'editOpportunity';
    }
    const data = {
      queryParams: {
        pageNo: 1,
        searchText: searchTextForAccountDropdown?.trim(),
        searchOnlyName: true,
        from: from,
      },
      bodyParams: [],
    };
    const opportunityAccountsAPI = new OpportunityAccountsAPI();
    const res = await opportunityAccountsAPI.fetchAllAccountsWhileSearchInDropdown(
        data.queryParams,
      );
    setAccountsSearchLists((prev) => ({
      ...prev,
      [activeCfIdForAccountDropdown]: res.data.accounts,
    }));
  };

  const settingCustomFieldObjWithExistingCustomFieldsForAGivenContact = (
    existingCFInContact: CustomFieldsObj,
  ) => {
    let commonKeysObj: CustomFieldsObj = {};
    customFields.forEach((cf) => {
      if (Object.keys(existingCFInContact).includes(cf._id as string)) {
        if (cf.fieldData.fieldType === 'contact') {
          fetchContactByIdToSetInSelectedContactAsCFValue2(
            existingCFInContact[
              cf._id as string
            ] as ContactCf as unknown as string,
            cf._id as string,
          );
        } else if (cf.fieldData.fieldType === 'account') {
          fetchAccountByIdToSetInSelectedAccountAsCFValue2(
            existingCFInContact[
              cf._id as string
            ] as AccountCf as unknown as string,
            cf._id as string,
          );
        }
        return (commonKeysObj[cf._id as string] =
          existingCFInContact[cf._id as string]);
      } else {
        if (cf.fieldData.fieldType === 'boolean') {
          commonKeysObj[cf._id as string] = '';
        } else {
          commonKeysObj[cf._id as string] = '';
        }
      }
    });
    setCustomFieldsObj(commonKeysObj);
  };

  const fetchContactByIdToSetInSelectedContactAsCFValue2 = async (
    contactId: string,
    cfId: string,
  ) => {
    if (contactId) {
      const opportunityContactsAPI = new OpportunityContactsAPI();
      const res = await opportunityContactsAPI.fetchOpportunityContact({
        id: contactId as string,
      });
      setSelectedContactAsCFValue((prev) => ({
        ...prev,
        [cfId as string]: res.data.contact,
      }));
    }
  };

  const fetchAccountByIdToSetInSelectedAccountAsCFValue2 = async (
    accountId: string,
    cfId: string,
  ) => {
    try {
      if (accountId) {
        const opportunityAccountsAPI = new OpportunityAccountsAPI();
        const res = await opportunityAccountsAPI.fetchOpportunityAccount({
          id: accountId as string,
        });
        setSelectedAccountAsCFValue((prev) => ({
          ...prev,
          [cfId as string]: res.data.account,
        }));
      }
    } catch (error) {
      console.log("====error", error)
    }
    
  };

  const getProjectList = async (params: GetProjectsParams) => {
    try {
      const result: any = await EventsAllAPIWithPagination(params);
      const projects: ProjectsList = result.data.events;
      if (projects.length < result.data.total) {
        setDisabledButton(false);
      } else {
        setDisabledButton(true);
      }
      setProjectsList(projects);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = () => {
    setPageNo((prev) => prev + 1);
  };

  const handleSearchListInSearchableDropdownForProjects = (text: any) => {
    setSearchText(text?.trim());
  };
  // for CustomSearchableDropdownWithLoadMore ends

  const settingCustomFieldObjWithUndeletedCustomFields = () => {
    const obj: CustomFieldsObj = {};
    customFields.forEach((cf) => {
      if (cf.fieldData.fieldType === 'boolean') {
        return (obj[cf._id as string] = '');
      } else {
        return (obj[cf._id as string] = '');
      }
    });
    return obj;
  };

  const handleClose = () => {
    setShowAddNew(false);
    resetAddNew();
  };

  const handleCustomFieldsObj = (
    e: any,
    id: string,
    fieldType?: string,
    isMultiSelect?: boolean,
  ) => {
    const setingCFObj = (value: any, ) => {
      let obj = {
        ...customFieldsObj,
      };
      if(fieldType === 'user'){
        let prevArr = (obj[id as string] as string[])
        if(!prevArr){
          prevArr = []
        }
        if(prevArr.includes(value)){
          prevArr = prevArr.filter(el => el !== value)
          obj[id as string] = [...prevArr]
        } else {
          obj[id as string] = [...prevArr, value]
        }
      } else {
        obj[id as string] = value;
      }
      setCustomFieldsObj(obj);
    };
    if (fieldType === 'number') {
      const regex = /^[0-9-]*$/;
      if (regex.test(e.target.value)) {
        setingCFObj(e.target.value);
      }
    } else if (fieldType === 'list' && !isMultiSelect) {
      setingCFObj([e.target.value]);
    } else if (fieldType === 'phone') {
      setingCFObj(e);
    } else if(fieldType === 'user'){
      setingCFObj(e._id)
    } else {
      setingCFObj(e.target.value);
    }
  };
  const handleCheckboxes = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
    value: string,
  ) => {
    let obj = {
      ...customFieldsObj,
    };
    let checkboxArr = obj[id] as string[];
    if (e.target.checked) {
      obj[id] = [...checkboxArr, value];
    } else {
      let arr = Array.isArray(checkboxArr)
        ? checkboxArr.filter((v) => value !== v)
        : [];
      obj[id] = arr;
    }
    setCustomFieldsObj(obj);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const convertCustomFieldsObjToTheFormatAccordingToModel = (
    customFieldsObj: CustomFields,
  ) => {
    let copiedCustomFieldsObj = { ...customFieldsObj };
    for (const key in copiedCustomFieldsObj) {
      if (Object.prototype.hasOwnProperty.call(copiedCustomFieldsObj, key)) {
        const element = copiedCustomFieldsObj[key];
        if (
          element !== null &&
          !Array.isArray(element) &&
          typeof element === 'object' &&
          Object.keys(element).length
        ) {
          copiedCustomFieldsObj[key] = element._id as string;
        }
      }
    }
    return copiedCustomFieldsObj;
  };

  const handleAddOrUpdate = () => {
    // step 1 for saving opportunity
    // checking if any create or edit contact box is open
    // resetErrorMessages()
    setIsLoading(true);
    for (const [key, element] of Object.entries(dynamicContactOpenState)) {
      if (element === true) {
        haveToCallCreateOrUpdateContactFunctionInChild.current = true;
        savingContactAlso.current = true;
        break; // Break out of the loop
      }
    }

    for (const [key, element] of Object.entries(dynamicAccountOpenState)) {
      if (element === true) {
        haveToCallCreateOrUpdateAccountFunctionInChild.current = true;
        savingAccountAlso.current = true;
        break; // Break out of the loop
      }
    }

    // If create/edit contact/account is open then checkValidationInChildContactComp/checkValidationInChildAccountComp is called
    haveToCallCreateOrUpdateContactFunctionInChild.current &&
      checkValidationInChildContactComp();
    haveToCallCreateOrUpdateAccountFunctionInChild.current &&
      checkValidationInChildAccountComp();
    !haveToCallCreateOrUpdateContactFunctionInChild.current &&
      !haveToCallCreateOrUpdateAccountFunctionInChild.current &&
      saveNewOpportunityOrUpdate();
  };

  const checkValidationInChildContactComp = () => {
    contactChildRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.handlevalidation) {
        if (!childRef.handlevalidation()) {
          // if anything is wrong with the contact form
          setValidationRequiredInContactForms((prev) => {
            let arr = [...prev];
            arr.push(true);
            return arr;
          });
        } else {
          // if everything is right in the contact form
          setValidationRequiredInContactForms((prev) => {
            let arr = [...prev];
            arr.push(false);
            return arr;
          });
        }
      }
    });
  };

  const checkValidationInChildAccountComp = () => {

    // step 2 for saving opportunity
    // checks the validation for account(child) comp and inside child validation for contact(child of child) is also done
    areChildAccountFormsInvalid.current = false;
    accountChildRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.handlevalidation) {
        if (!childRef.handlevalidation()) {
          areChildAccountFormsInvalid.current = true;
          setValidationRequiredInAccountForms((prev) => {
            let arr = [...prev];
            arr.push(true);
            return arr;
          });
        } else {
          setValidationRequiredInAccountForms((prev) => {
            let arr = [...prev];
            arr.push(false);
            return arr;
          });
        }
      }
    });
  };

  useEffect(() => {
    // step 4 for saving opportunity

    if (
      validationRequiredInAccountForms.length &&
      validationRequiredInAccountForms.length ===
        Object.keys(dynamicAccountOpenState).length &&
      validationRequiredInContactForms.length &&
      validationRequiredInContactForms.length ===
        Object.keys(dynamicContactOpenState).length
    ) {
      const isOpportunityFormValid = isValid();
      const accountFormInvalid =
        validationRequiredInAccountForms.includes(true);
      const contactFormInValid =
        validationRequiredInContactForms.includes(true);
      if (
        !accountFormInvalid &&
        !contactFormInValid &&
        isOpportunityFormValid
      ) {
        saveAccountInTheChildComp();
        saveContactInTheChildComp();
        setValidationRequiredInAccountForms([]);
        setValidationRequiredInContactForms([]);
      } else {
        setIsLoading(false);
        setValidationRequiredInAccountForms([]);
        setValidationRequiredInContactForms([]);
        opportunityState.isSavingAsDraft && setIsSavingAsDraftAsFalse();
      }
    }

    if (
      !validationRequiredInContactForms.length &&
      validationRequiredInAccountForms.length &&
      validationRequiredInAccountForms.length ===
        Object.keys(dynamicAccountOpenState).length
    ) {
      const isOpportunityFormValid = isValid();
      const accountFormInvalid =
        validationRequiredInAccountForms.includes(true);
      if (!accountFormInvalid && isOpportunityFormValid) {
        saveAccountInTheChildComp();
        setValidationRequiredInAccountForms([]);
      } else {
        setIsLoading(false);
        setValidationRequiredInAccountForms([]);
        opportunityState.isSavingAsDraft && setIsSavingAsDraftAsFalse();
      }
    }

    if (
      !validationRequiredInAccountForms.length &&
      validationRequiredInContactForms.length &&
      validationRequiredInContactForms.length ===
        Object.keys(dynamicContactOpenState).length
    ) {
      const isOpportunityFormValid = isValid();
      const contactFormInvalid =
        validationRequiredInContactForms.includes(true);
      if (!contactFormInvalid && isOpportunityFormValid) {
        saveContactInTheChildComp();
        setValidationRequiredInContactForms([]);
      } else {
        setIsLoading(false);
        setValidationRequiredInContactForms([]);
        opportunityState.isSavingAsDraft && setIsSavingAsDraftAsFalse();
      }
    }
  }, [validationRequiredInAccountForms, validationRequiredInContactForms]);

  const saveContactBeforeUnlinking = () => {
    let isChildContactFormValid = true;
    contactChildRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.cfId === cfIdForAddNewContact) {
        if (childRef.handlevalidation) {
          if (!childRef.handlevalidation(true)) {
            isChildContactFormValid = false;
          }
        }
      }
    });
    if (isChildContactFormValid) {
      contactChildRefs.current.forEach((childRef: any) => {
        if (childRef && childRef.cfId === cfIdForAddNewContact) {
          if (childRef.saveContactBeforeUnlinking) {
            childRef.saveContactBeforeUnlinking(true, true);
            childRef.setCustomFieldsValidationErrorToDefault();
            addNewContact(cfIdForAddNewContact, true);
          }
        }
      });
    } else {
      closeAddNewContactConfirmationPopup();
    }
  };

  const saveAccountBeforeUnlinking = () => {
    let invalidContactFormsInAccountForm = false;
    let inValidAccountForm = false;
    accountChildRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.cfId === cfIdForAddNewAccount) {
        if (!childRef.checkValidationInChildContactComp()) {
          invalidContactFormsInAccountForm = true;
        }
        changesMadeInCreateOrEditAccount = childRef.checkIfFormHasChanged();
        if (!childRef.isValid()) {
          inValidAccountForm = true;
        }
      }
    });

    if (!inValidAccountForm && !invalidContactFormsInAccountForm) {
      accountChildRefs.current.forEach((childRef: any) => {
        if (childRef && childRef.cfId === cfIdForAddNewAccount) {
          if (childRef.saveAccountBeforeUnlinking) {
            childRef.saveAccountBeforeUnlinking(
              true,
              false,
              true,
              addingNewAccount.current,
            );
            // addNewAccount(cfIdForAddNewAccount, true);
            childRef.setCustomFieldsValidationErrorToDefault();
          }
        }
      });
    } else {
      closeAddNewAccountConfirmationPopup();
    }
  };

  const handleOpenChangeSelectedContactConfPopup = (
    option: any,
    cfId: string | undefined,
    isSaving?: boolean,
  ) => {
    if (selectedContactAsCFValue?.[cfId as string]) {
      if (selectedContactAsCFValue?.[cfId as string]._id !== option._id) {
        setOpenChangeSelectedContactConfPopup(true);
        setOptionForHandleContactCFChangeForSearchableDropdown(option);
        setCfidForHandleContactCFChangeForSearchableDropdown(cfId as string);
      }
    } else {
      handleContactCFChangeForSearchableDropdown(option, cfId, isSaving);
    }
  };

  const handleOpenChangeSelectedAccountConfPopup = (
    option: any,
    cfId: string | undefined,
    isSaving?: boolean,
  ) => {
    if (selectedAccountAsCFValue?.[cfId as string]) {
      if (selectedAccountAsCFValue?.[cfId as string]._id !== option._id) {
        setOpenChangeSelectedAccountConfPopup(true);
        setOptionForHandleAccountCFChangeForSearchableDropdown(option);
        setCfidForHandleAccountCFChangeForSearchableDropdown(cfId as string);
      }
    } else {
      handleAccountCFChangeForSearchableDropdown(option, cfId, isSaving);
    }
  };

  const saveContactInTheChildComp = () => {
    contactChildRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.handleAddOrUpdate) {
        // creating or updating one or multiple contacts in the opportunity form
        childRef.handleAddOrUpdate();
      }
    });
  };

  const saveAccountInTheChildComp = () => {
    accountChildRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.saveContactInTheChildComp) {
        childRef.saveContactInTheChildComp(false);
      }
    });
  };

  useEffect(() => {
    if (
      savingContactAlso.current &&
      Object.keys(dynamicContactOpenState).length === 0 &&
      savingAccountAlso.current &&
      Object.keys(dynamicAccountOpenState).length === 0
    ) {
      saveNewOpportunityOrUpdate();
      areChildAccountFormsInvalid.current = false;
      hasCalledSaveFunction.current = true;
    } else if (
      !savingContactAlso.current &&
      savingAccountAlso.current &&
      Object.keys(dynamicAccountOpenState).length === 0
    ) {
      saveNewOpportunityOrUpdate();
      areChildAccountFormsInvalid.current = false;
      // savingAccountAlso.current = false;
      hasCalledSaveFunction.current = true;
    } else if (
      !savingAccountAlso.current &&
      savingContactAlso.current &&
      Object.keys(dynamicContactOpenState).length === 0
    ) {
      saveNewOpportunityOrUpdate();
      areChildAccountFormsInvalid.current = false;
      hasCalledSaveFunction.current = true;
    }
  }, [dynamicContactOpenState, dynamicAccountOpenState]);

  const fetchAllTheActiveUsers = async() => {
    try {
      const data = {
        searchText: searchTextForUsers
      }
      const response: any = await getActiveUsersForCompanyAPI(data)
      if(response.data.success === true){
        setUsersForUserCfDataType(response.data.users)
      } else {
        setUsersForUserCfDataType([])
        createNotification('error', 'Something went wrong')
      }
    } catch (error) {
      setUsersForUserCfDataType([])
      console.log('error', error);
    }
  };

  useEffect(() => {
    fetchAllTheActiveUsers()
  }, [searchTextForUsersDebouncedValue])

  useEffect(() => {
    const timerId = setTimeout(() => {
      setSearchTextForUsersDebouncedValue(searchTextForUsers)
    }, 500);
    return ()=>  {clearTimeout(timerId)}
  }, [searchTextForUsers])

  const saveNewOpportunityOrUpdate = () => {
    let areOpportunityFieldsValid = true;
    if (opportunityState.isSavingAsDraft) {
      if (accountChildRefs.current.length) {
        accountChildRefs.current.forEach((childRef: any) => {
          if (childRef && !childRef.isValidForDraft) {
            opportunityState.isSavingAsDraft && setIsSavingAsDraftAsFalse();
          }
        });
      } else if (contactChildRefs.current.length) {
        contactChildRefs.current.forEach((childRef: any) => {
          if (childRef && !childRef.isValidForDraft) {
            opportunityState.isSavingAsDraft && setIsSavingAsDraftAsFalse();
          }
        });
      } else if (
        !accountChildRefs.current.length &&
        !contactChildRefs.current.length
      ) {
        areOpportunityFieldsValid = isValid();
        opportunityState.isSavingAsDraft && setIsSavingAsDraftAsFalse();
      }
    } else {
      areOpportunityFieldsValid = isValid();
    }

    if (areOpportunityFieldsValid) {
      const bodyParams: BodyParams = {
        name: name.trim(),
        customFields: customFieldsObj,
        assignedToId: assignTo._id,
        source: source,
        projectId: project._id,
      };
      if (opportunityState.isSavingAsDraft) {
        bodyParams['status'] = 'draft';
      }
      if (selectedDynamicState || opportunityState.isSavingAsDraft) {
        let draftState = dynamicStates.find(
          (state) => state.stateType === 'Draft',
        );
        bodyParams['state'] = opportunityState.isSavingAsDraft
          ? draftState?._id
          : selectedDynamicState;
      }
      if (idBeingEdited) {
        bodyParams._id = idBeingEdited;
        queryParams.from = 'editOpportunity';
      } else {
        queryParams.from = 'createOpportunity';
      }
      let data = {
        bodyParams: bodyParams,
        queryParams: queryParams,
      };
      setIsLoading(false);
      if (opportunityState.isSavingAsDraft) {
        queryParams.status = 'draft';
        setIsSavingAsDraftAsFalse();
      }
      idBeingEdited
        ? dispatch(Actions.updateOpportunityOpportunityRequest(data))
        : dispatch(Actions.createOpportunityOpportunityRequest(data));
      resetAddNew();
    } else {
      setIsLoading(false);
    }
  };

  const isValid = () => {
    let isValid = true;
    let errorsObj: CustomFieldsValidationError = {};
    try {
      if (!name || name.trim() === '') {
        errorsObj.name = 'Please enter the opportunity name';
      }
      if (!opportunityState.isSavingAsDraft) {
        if (!selectedDynamicState || selectedDynamicState.trim() === '') {
          errorsObj.dynamicState = 'Please select the state';
        }
        customFields.forEach((cf: CustomField) => {
          for (const key in customFieldsObj) {
            if (key === cf._id) {
              if (cf.isMandatory) {
                if (cf.fieldData.fieldType === 'account') {
                  if (!customFieldsObj[key]) {
                    errorsObj[key] = true;
                    accountChildRefs.current.forEach((childRef: any) => {
                      if (childRef && childRef.cfId === key) {
                        if (
                          childRef &&
                          childRef.handleValidationCfIsMandatory
                        ) {
                          if (childRef.handleValidationCfIsMandatory()) {
                            delete errorsObj[key];
                          }
                        }
                      }
                    });
                  } else {
                    accountChildRefs.current.forEach((childRef: any) => {
                      if (childRef && childRef.cfId === key) {
                        if (
                          childRef &&
                          childRef.handleValidationCfIsMandatory
                        ) {
                          if (!childRef.handleValidationCfIsMandatory()) {
                            errorsObj[key] = true;
                          }
                        }
                      }
                    });
                  }
                } else if (cf.fieldData.fieldType === 'contact') {
                  if (!customFieldsObj[key]) {
                    errorsObj[key] = true;
                    contactChildRefs.current.forEach((childRef: any) => {
                      if (childRef && childRef.cfId === key) {
                        if (
                          childRef &&
                          childRef.handleValidationCfIsMandatory
                        ) {
                          if (childRef.handleValidationCfIsMandatory()) {
                            delete errorsObj[key];
                          }
                        }
                      }
                    });
                  } else {
                    contactChildRefs.current.forEach((childRef: any) => {
                      if (childRef && childRef.cfId === key) {
                        if (
                          childRef &&
                          childRef.handleValidationCfIsMandatory
                        ) {
                          if (!childRef.handleValidationCfIsMandatory()) {
                            errorsObj[key] = true;
                          }
                        }
                      }
                    });
                  }
                } else if (cf.fieldData.fieldType === 'date') {
                  if((customFieldsObj?.[cf._id as string] &&
                    moment(
                      customFieldsObj?.[cf._id as string] as string,
                    ).format('YYYY-MM-DD') === 'Invalid date') || !customFieldsObj?.[cf._id as string]){
                      errorsObj[key] = true;
                    }
                } else if (
                  Array.isArray(customFieldsObj[key]) &&
                  !(customFieldsObj[key] as string[]).length
                ) {
                  errorsObj[key] = true;
                } else if (!customFieldsObj[key]) {
                  errorsObj[key] = true;
                }
              }
              if (cf.fieldData.fieldType === 'email') {
                if (
                  customFieldsObj[key] &&
                  !isValidEmail(customFieldsObj[key] as string)
                ) {
                  errorsObj[key] = true;
                }
              }
              if (cf.fieldData.fieldType === 'url') {
                if (
                  customFieldsObj[key] &&
                  !isValidURL(customFieldsObj[key] as string)
                ) {
                  errorsObj[key] = true;
                }
              }

              if (
                cf.fieldData.fieldType === 'facebook' ||
                cf.fieldData.fieldType === 'linkedin' ||
                cf.fieldData.fieldType === 'x' ||
                cf.fieldData.fieldType === 'pinterest' ||
                cf.fieldData.fieldType === 'instagram'
              ) {
                if (
                  customFieldsObj[key] &&
                  !validateSocialMediaUrl(
                    customFieldsObj[key] as string,
                    cf.fieldData.fieldType,
                  )
                ) {
                  errorsObj[key] = true;
                }
              }
              if (cf.fieldData.fieldType === 'number') {
                const numbersAndHyphensRegex = /^[0-9-]*$/;
                if (
                  !numbersAndHyphensRegex.test(customFieldsObj[key] as string)
                ) {
                  errorsObj[key] = true;
                }
              }
              if (cf.fieldData.fieldType === 'date') {
                if(customFieldsObj?.[cf._id as string] &&
                  moment(
                    customFieldsObj?.[cf._id as string] as string,
                  ).format('YYYY-MM-DD') === 'Invalid date'){
                    errorsObj[key] = true;
                  }
              }
            }
          }
        });
      }
      setErrors((prev) => ({ ...prev, ...errorsObj }));
    } catch (error) {
      console.log(error);
    }

    if (Object.keys(errorsObj).length) {
      isLoading && setIsLoading(false);
      setCustomFieldsValidationError(errorsObj);
      isValid = false;
    } else if (errors.name) {
      isValid = false;
    } else {
      setCustomFieldsValidationError({});
    }
    !isValid && opportunityState.isSavingAsDraft && setIsSavingAsDraftAsFalse();
    return isValid;
  };

  const setIsSavingAsDraftAsFalse = () => {
    dispatch(Actions.setIsSavingAsDraft(false));
  };

  const resetErrorMessages = () => {
    setCustomFieldsValidationError({});
    setErrors({});
  };

  const handleProjectChangeForSearchableDropdown = (option: any) => {
    if (option._id) {
      option.name = option?.eventName;
    }
    setProject(option as Project);
  };

  const errorStatement = (cf: CustomField) => {
    if (customFieldsValidationError?.[cf._id as string]) {
      if (cf.fieldData.fieldType === 'email') {
        return 'Please enter a valid email';
      }
      if (cf.fieldData.fieldType === 'url') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'x') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'instagram') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'facebook') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'pinterest') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'linkedin') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'number') {
        return 'Please enter a valid number';
      }
      if (cf.fieldData.fieldType === 'date') {
        return 'Please enter a valid date';
      }
      if (cf.fieldData.fieldType === 'boolean') {
        return 'Please choose the value';
      }
      if (cf.fieldData.fieldType === 'list') {
        return 'Please select a value';
      }
      if (cf.fieldData.fieldType === 'contact') {
        return 'Please fill the mandatory fields';
      }
      if (cf.fieldData.fieldType === 'account') {
        return 'Please fill the mandatory fields';
      } else {
        return 'Please enter a value';
      }
    } else return '';
  };

  const handleContactCFChangeForSearchableDropdown = (
    option: any,
    cfId: string | undefined,
    isSaving?: boolean,
    contactInfoChanged?: boolean,
  ) => {
    if (option?._id) {
      setSelectedContactAsCFValue({
        ...selectedContactAsCFValue,
        [cfId as string]: option,
      });
    }

    if (cfId) {
      let contactDetailsToAppend: any = '';
      if (option) {
        if (!contactInfoChanged) {
          contactDetailsToAppend = option._id;
        } else {
          contactDetailsToAppend = { data: option, type: 'contact' };
        }
      }
      let cfObj = { [cfId]: contactDetailsToAppend };
      setCustomFieldsObj((prev) => ({ ...prev, ...cfObj }));
    }
    if (isSaving) {
      savingContactAlso.current = true;
      setdynamicContactOpenState((prev) => {
        delete prev[cfId as string];
        return { ...prev };
      });
    }
  };

  const handleAccountCFChangeForSearchableDropdown = (
    option: any,
    cfId: string | undefined,
    isSaving?: boolean,
    accountInfoHasChanged?: boolean,
  ) => {
    if (option?._id) {
      setSelectedAccountAsCFValue({
        ...selectedAccountAsCFValue,
        [cfId as string]: option,
      });
    }

    if (cfId) {
      let accountDetailsToAppend: any = '';
      if (option) {
        if (!accountInfoHasChanged) {
          accountDetailsToAppend = option._id;
        } else {
          accountDetailsToAppend = { data: option, type: 'account' };
        }
      }
      let cfObj = { [cfId]: accountDetailsToAppend };
      setCustomFieldsObj((prev) => ({ ...prev, ...cfObj }));
    }
    if (isSaving) {
      savingAccountAlso.current = true;
      setdynamicAccountOpenState((prev) => {
        delete prev[cfId as string];
        return { ...prev };
      });
    }
  };

  const handleSearchListInSearchableDropdownForContactTypeCF = (
    cfId: string,
    text: any,
  ) => {
    if (text) {
      setActiveCfIdForContactDropdown(cfId);
      setSearchTextForContactDropdown(text.trim());
    } else {
    }
  };

  const handleSearchListInSearchableDropdownForAccountTypeCF = (
    cfId: string,
    text: any,
  ) => {
    if (text) {
      setActiveCfIdForAccountDropdown(cfId);
      setSearchTextForAccountDropdown(text.trim());
    } else {
    }
  };

  const clearSelectedContactAndCloseViewContactDetailBox = (cfId: string) => {
    const obj = { ...selectedContactAsCFValue };
    delete obj[cfId];
    setSelectedContactAsCFValue(obj);

    const openState = { ...dynamicContactOpenState };
    openState[cfId] = false;
    setdynamicContactOpenState({
      ...openState,
    });

    let cfObj = {
      ...customFieldsObj,
      [cfId]: '',
    };
    setCustomFieldsObj({ ...customFieldsObj, ...cfObj });
  };

  const handleOpenAddNewContactConfirmationPopup = (cfId: string) => {
    setCfIdForAddNewContact(cfId);
    let isUnlinkingExistingContact = false;
    let isContactFormEmpty = false;
    let isContactSelected = selectedContactAsCFValue?.[cfId]?._id;
    contactChildRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.cfId === cfId) {
        changesMadeInCreateOrEditContact = childRef.checkIfFormHasChanged();
        isUnlinkingExistingContact = childRef.idBeingEdited;
        isContactFormEmpty = childRef.isFormEmpty;
      }
    });
    if (isContactFormEmpty) {
      return;
    }
    if (changesMadeInCreateOrEditContact) {
      setContactConfirmationText(
        'You have made some changes, do you want to save the contact before unlinking?',
      );
    } else if (
      !changesMadeInCreateOrEditContact &&
      isUnlinkingExistingContact
    ) {
      setContactConfirmationText(
        'Are you sure you want to unlink this contact?',
      );
    } else if (
      !changesMadeInCreateOrEditContact &&
      !isUnlinkingExistingContact &&
      !isContactSelected
    ) {
      setContactConfirmationText(
        'Are you sure you want to unlink this contact?',
      );
      addNewContact(cfId, true);
      return;
    } else {
      setContactConfirmationText(
        'Are you sure you want to unlink this contact?',
      );
    }
    setOpenAddNewContactConfirmationPopup(true);
  };

  const handleOpenAddNewAccountConfirmationPopup = (cfId: string) => {
    setCfIdForAddNewAccount(cfId);
    let isUnlinkingExistingAccount = false;
    let isAccountFormEmpty = false;
    let isAccountSelected = selectedAccountAsCFValue?.[cfId]?._id;

    accountChildRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.cfId === cfId) {
        changesMadeInCreateOrEditAccount = childRef.checkIfFormHasChanged();
        isUnlinkingExistingAccount = childRef.idBeingEdited;
        isAccountFormEmpty = childRef.isFormEmpty;
      }
    });
    if (isAccountFormEmpty) {
      if (!dynamicAccountOpenState[cfId]) {
        setdynamicAccountOpenState((prev) => {
          let prevCopy = { ...prev };
          prevCopy[cfId] = true;
          return { ...prevCopy };
        });
      }
      return;
    }
    if (changesMadeInCreateOrEditAccount) {
      setAccountConfirmationText(
        'You have made some changes, do you want to save the account before unlinking?',
      );
    } else if (
      !changesMadeInCreateOrEditAccount &&
      isUnlinkingExistingAccount
    ) {
      setAccountConfirmationText(
        'Are you sure you want to unlink this account?',
      );
    } else if (
      !changesMadeInCreateOrEditAccount &&
      !isUnlinkingExistingAccount &&
      !isAccountSelected
    ) {
      setAccountConfirmationText(
        'Are you sure you want to unlink this account?',
      );
      addNewAccount(cfId, true);
      return;
    } else {
      setAccountConfirmationText(
        'Are you sure you want to unlink this account?',
      );
    }
    setOpenAddNewAccountConfirmationPopup(true);
  };

  const closeAddNewContactConfirmationPopup = () => {
    setOpenAddNewContactConfirmationPopup(false);
    changesMadeInCreateOrEditContact = false;
    setCfIdForAddNewContact('');
  };

  const closeAddNewAccountConfirmationPopup = () => {
    setOpenAddNewAccountConfirmationPopup(false);
    changesMadeInCreateOrEditAccount = false;
    setCfIdForAddNewAccount('');
  };

  const closeChangeSelectedContactConfPopup = () => {
    setOpenChangeSelectedContactConfPopup(false);
    changesMadeInCreateOrEditContact = false;
  };

  const closeChangeSelectedAccountConfPopup = () => {
    setOpenChangeSelectedAccountConfPopup(false);
    changesMadeInCreateOrEditAccount = false;
  };

  const clearSelectedAccountAndCloseViewAccountDetailBox = (cfId: string) => {
    const obj = { ...selectedAccountAsCFValue };
    delete obj[cfId];
    setSelectedAccountAsCFValue(obj);
    const openState = { ...dynamicAccountOpenState };
    delete openState[cfId];
    setdynamicAccountOpenState({
      ...openState,
    });

    let cfObj = {
      ...customFieldsObj,
      [cfId]: '',
    };
    setCustomFieldsObj({ ...customFieldsObj, ...cfObj });
  };

  const addNewContact = (cfId: string, addingNew?: boolean) => {
    openAddNewContactConfirmationPopup && closeAddNewContactConfirmationPopup();
    if (addingNew) {
      contactChildRefs.current.forEach((childRef: any) => {
        if (childRef && childRef.cfId === cfId) {
          childRef.resetForm();
          childRef.setCustomFieldsValidationErrorToDefault();
        }
      });
      if (selectedContactAsCFValue[cfId]) {
        setPreviouslySelectedContactAsCFValue((prev) => ({
          ...prev,
          [cfId]: selectedContactAsCFValue[cfId],
        }));
        const obj = { ...selectedContactAsCFValue };
        delete obj[cfId];
        setSelectedContactAsCFValue(obj);
      }
      setCustomFieldsObj((prev) => {
        const prevCopy = { ...prev };
        prevCopy[cfId] = '';
        return { ...prevCopy };
      });
    }

    const openState = { ...dynamicContactOpenState };
    openState[cfId] = true;
    for (let key in openState) {
      if (key === cfId) {
        openState[key] = true;
      } else {
        openState[key] = true;
      }
    }
    setdynamicContactOpenState({
      ...openState,
    });
  };

  const removeValueFromCfFieldObj = (cfId: string) => {
    setCustomFieldsObj((prev) => {
      let prevCopy = { ...prev };
      return {
        ...prevCopy,
        [cfId]: '',
      };
    });
  };

  const addNewAccount = (cfId: string, addingNew?: boolean) => {
    openAddNewAccountConfirmationPopup && closeAddNewAccountConfirmationPopup();
    if (addingNew) {
      if (selectedAccountAsCFValue[cfId]) {
        const obj = { ...selectedAccountAsCFValue };
        delete obj[cfId];
        setSelectedAccountAsCFValue(obj);
        setPreviouslySelectedAccountAsCFValue((prev) => ({
          ...prev,
          [cfId]: selectedAccountAsCFValue[cfId],
        }));
      }

      accountChildRefs.current.forEach((childRef: any) => {
        if (childRef && childRef.cfId === cfId) {
          childRef.resetForm(true);
          childRef.setCustomFieldsValidationErrorToDefault();
          childRef.setErrorsToDefaultInContactChildComp();
        }
      });
      setCustomFieldsObj((prev) => {
        const prevCopy = { ...prev };
        prevCopy[cfId] = '';
        return { ...prevCopy };
      });
    }
    setdynamicAccountOpenState((prev) => {
      const prevCopy = { ...prev };
      prevCopy[cfId] = true;
      return { ...prevCopy };
    });
  };

  const closeAddNewContactPopup = (cfId: string) => {
    const openState = { ...dynamicContactOpenState };
    delete openState[cfId];
    setdynamicContactOpenState(openState);
  };

  const closeAddNewAccountPopup = (cfId: string) => {
    const openState = { ...dynamicAccountOpenState };
    delete openState[cfId];
    setdynamicAccountOpenState(openState);
  };

  const setPreviousContactAsCFValueOnClosingEditBox = (cfId: string) => {
    const selectedContactAsCFValueCopy = { ...selectedContactAsCFValue };
    delete selectedContactAsCFValueCopy[cfId];

    setSelectedContactAsCFValue({
      ...selectedContactAsCFValueCopy,
    });
    let cfObj = {
      ...customFieldsObj,
      [cfId]: '',
    };
    setCustomFieldsObj({ ...cfObj });
    closeAddNewContactPopup(cfId);
  };

  const setPreviousAccountAsCFValueOnClosingEditBox = (cfId: string) => {
    setSelectedAccountAsCFValue({
      ...selectedAccountAsCFValue,
      [cfId as string]: previouslySelectedAccountAsCFValue[cfId],
    });
    let cfObj = {
      ...customFieldsObj,
      [cfId]: '',
    };
    setCustomFieldsObj({ ...cfObj });
    closeAddNewAccountPopup(cfId);
  };

  const handleDynamicStateChange = (e: SelectChangeEvent<String>) => {
    setSelectedDynamicState(e.target.value as string);
  };

  const handleAssignTo = (e: SelectChangeEvent<string>) => {
    const selectedUser = activeUsersForCompany.find(
      (user: any) => user._id === e.target.value,
    );
    if (selectedUser) {
      setAssignTo(selectedUser);
    }
  };

  const handleAssignToChangeForSearchableDropdown = (option: any) => {
    if (option._id) {
      option.email = option?.email;
    }
    setAssignTo(option as AssignedTo);
  };

  const handleSearchListInSearchableDropdownForAssignedTo = (text: any) => {
    if (text) {
      const regex = new RegExp('^' + text, 'i');
      let list = activeUsersForCompany.filter((user: { email: string }) =>
        regex.test(user.email),
      );
      setUsersSearchList(list);
    } else {
      setUsersSearchList(null);
    }
  };

  const clearContactsSearchList = () => {
    setContactsSearchLists(null);
  };

  const clearAccountsSearchList = () => {
    setAccountsSearchLists(null);
  };

  const handleClearAllUsers = (checked: boolean, cfId: string) => {
    let obj = {...customFieldsObj};
    if (checked) {
      obj[cfId] = [];
      setCustomFieldsObj({...obj})
    }
  };

  return (
    <Box className="opportunity-tab">
      <Grid container spacing={2} mb={3} className="top-filter-area">
        <Grid item>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton size="small" onClick={handleClose}>
              <KeyboardBackspaceIcon />
            </IconButton>
            <Box>Back</Box>
          </Stack>
        </Grid>
        <Grid item>
          <Grid container spacing={1.25} className="team-dash-right">
            <Grid item>
              <Button
                onClick={handleClose}
                variant="outlined"
                color="primary"
                disableElevation
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => dispatch(Actions.setIsSavingAsDraft(true))}
                variant="outlined"
                color="primary"
                disableElevation
              >
                Save As Draft
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  opportunityState.isSavingAsDraft &&
                    setIsSavingAsDraftAsFalse();
                  handleAddOrUpdate();
                }}
                variant="contained"
                color="primary"
                disableElevation
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} md={10} xl={8}>
          <Box px={3} pt={2} pb={4}>
            <Box
              component={Grid}
              //   @ts-ignore
              rowSpacing={2}
              columnSpacing={2}
              container
              className="inputField"
            >
              <Grid item xs={12} sm={6}>
                <label>
                  Opportunity Name
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control small"
                  placeholder="Please enter the opportunity name"
                  onChange={handleNameChange}
                  value={name}
                />
                <span className="error_mesage required">{errors?.name}</span>
                {/* <Box className='ai-fetched-data'><Box><img src={AiIcon} alt="ai-icon" /></Box>1st week of september</Box> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <label>Project</label>
                <CustomSearchableDropdownWithLoadMore
                  options={projectsList}
                  value={project}
                  handleChange={handleProjectChangeForSearchableDropdown}
                  handleSearchListInSearchableDropdown={
                    handleSearchListInSearchableDropdownForProjects
                  }
                  handlePageChange={handlePageChange}
                  disabled={disabledButton}
                />
              </Grid>

              {activeUsersForCompany.length > 0 && (
                <Grid item xs={6}>
                  <label>Assign To</label>
                  <CustomSearchableDropdown
                    options={usersSearchList || activeUsersForCompany}
                    value={assignTo}
                    handleChange={handleAssignToChangeForSearchableDropdown}
                    handleSearchListInSearchableDropdown={
                      handleSearchListInSearchableDropdownForAssignedTo
                    }
                  />
                </Grid>
              )}

              <Grid item xs={6}>
                <label>State</label>
                <FormControl size="small" fullWidth>
                  <Select
                    type="text"
                    className="form-control select small"
                    placeholder="select"
                    style={{ padding: '4px 0px' }}
                    MenuProps={MenuProps}
                    displayEmpty
                    name="dynamicState"
                    onChange={(e) => handleDynamicStateChange(e)}
                    value={selectedDynamicState}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {dynamicStates
                      .filter((state) => state.stateType !== 'Draft')
                      .map((state) => {
                        return (
                          <MenuItem key={state._id} value={state._id}>
                            {state.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <span className="error_mesage required">
                  {errors?.dynamicState}
                </span>
              </Grid>

              {Array.isArray(customFields) &&
                customFields.map((cf) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={
                        cf.fieldData.fieldType === 'contact' ||
                        cf.fieldData.fieldType === 'account' ||
                        cf.fieldData.fieldType === 'longText'
                          ? 12
                          : 6
                      }
                      key={cf._id}
                    >
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                      >
                        <Box>
                          <label>
                            {cf.fieldData.fieldName}
                            {cf.isMandatory && (
                              <span className="required">*</span>
                            )}
                          </label>
                        </Box>
                        {(cf.fieldData.fieldType === 'contact' ||
                          cf.fieldData.fieldType === 'account') && (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => {
                              cf.fieldData.fieldType === 'contact' &&
                                handleOpenAddNewContactConfirmationPopup(
                                  cf._id as string,
                                );
                              cf.fieldData.fieldType === 'account' &&
                                handleOpenAddNewAccountConfirmationPopup(
                                  cf._id as string,
                                );
                            }}
                          >
                            Add New
                          </Button>
                        )}
                      </Stack>
                      {cf.fieldData.fieldType === 'list' ? (
                        <DropdownForListDataTypeCF
                          cf={cf}
                          customFieldsObj={customFieldsObj}
                          handleCustomFieldsObj={handleCustomFieldsObj}
                        />
                      ) : cf.fieldData.fieldType === 'boolean' ? (
                        <FormControl fullWidth component="fieldset">
                          <RadioGroup
                            // @ts-ignore
                            aria-label={cf.fieldData.fieldName}
                            name={cf.fieldData.fieldName}
                            row
                            onChange={(e) =>
                              handleCustomFieldsObj(e, cf._id as string)
                            }
                            value={
                              customFieldsObj?.[cf._id as string]?.toString() ||
                              ''
                            }
                          >
                            <FormControlLabel
                              value={'true'}
                              control={<Radio color="primary" />}
                              label="True"
                            />
                            <FormControlLabel
                              value={'false'}
                              control={<Radio color="primary" />}
                              label="False"
                            />
                          </RadioGroup>
                        </FormControl>
                      ) : cf.fieldData.fieldType === 'date' ? (
                        <>
                          <input
                            type="date"
                            className="form-control small"
                            value={
                              customFieldsObj?.[cf._id as string] &&
                              moment(
                                customFieldsObj?.[cf._id as string] as string,
                              ).format('YYYY-MM-DD')
                            }
                            onChange={(e) =>
                              handleCustomFieldsObj(e, cf._id as string)
                            }
                          />
                          {customFieldsObj?.[cf._id as string] &&
                            moment(
                              customFieldsObj?.[cf._id as string] as string,
                            ).format('YYYY-MM-DD') === 'Invalid date' && (
                              <>{customFieldsObj?.[cf._id as string]}</>
                            )}
                        </>
                      ) : cf.fieldData.fieldType === 'contact' ? (
                        <>
                          <CustomSearchableDropdownForContactAndAccountDataTypeCF
                            options={
                              contactsSearchLists &&
                              contactsSearchLists[cf._id as string]
                            }
                            value={selectedContactAsCFValue}
                            handleChange={(option: any) =>
                              handleOpenChangeSelectedContactConfPopup(
                                option,
                                cf._id,
                              )
                            }
                            cfId={cf._id}
                            handleSearchListInSearchableDropdown={(
                              text: string,
                            ) =>
                              handleSearchListInSearchableDropdownForContactTypeCF(
                                cf._id as string,
                                text,
                              )
                            }
                            clearSearchList={clearContactsSearchList}
                          />
                          {dynamicContactOpenState[cf._id as string] && (
                            <CreateOrEditContact
                              ref={(ref) => {
                                let arr = [];
                                for (let key in dynamicContactOpenState) {
                                  if (dynamicContactOpenState[key] === true) {
                                    arr.push(key);
                                  }
                                }
                                arr.map((el, i) => {
                                  if (el === cf._id) {
                                    return (contactChildRefs.current[i] = ref);
                                  }
                                });
                              }}
                              closeAddNewPopup={() =>
                                closeAddNewContactPopup(cf?._id as string)
                              }
                              cfId={cf._id as string}
                              handleContactCFChangeForSearchableDropdown={
                                handleContactCFChangeForSearchableDropdown
                              }
                              contactToBeEdited={
                                (selectedContactAsCFValue !== null &&
                                  Object.keys(selectedContactAsCFValue)
                                    .length &&
                                  selectedContactAsCFValue[cf._id as string]) ||
                                undefined
                              }
                              onClose={() =>
                                setPreviousContactAsCFValueOnClosingEditBox(
                                  cf._id as string,
                                )
                              }
                              addNewContact={addNewContact}
                              toBeEdited={toBeEdited}
                              callFrom={'opportunity'}
                            />
                          )}
                          {selectedContactAsCFValue?.[cf._id as string] &&
                            !dynamicContactOpenState[cf._id as string] && (
                              <ViewContactOrAccountDetails
                                usedFor="contact"
                                // customFieldsFrom="contacts"
                                selected={
                                  selectedContactAsCFValue[cf?._id as string]
                                }
                                addNew={() => addNewContact(cf._id as string)}
                                clearSelectedContactAndCloseViewContactDetailBox={() =>
                                  clearSelectedContactAndCloseViewContactDetailBox(
                                    cf._id as string,
                                  )
                                }
                                customFields={customFieldsFromContacts}
                                parentItem="opportunity"
                              />
                            )}
                        </>
                      ) : cf.fieldData.fieldType === 'account' ? (
                        <>
                          <CustomSearchableDropdownForContactAndAccountDataTypeCF
                            options={
                              accountsSearchLists &&
                              accountsSearchLists[cf._id as string]
                            }
                            value={selectedAccountAsCFValue}
                            handleChange={(option: any) =>
                              handleOpenChangeSelectedAccountConfPopup(
                                option,
                                cf._id,
                              )
                            }
                            cfId={cf._id}
                            handleSearchListInSearchableDropdown={(
                              text: string,
                            ) =>
                              handleSearchListInSearchableDropdownForAccountTypeCF(
                                cf._id as string,
                                text,
                              )
                            }
                            clearSearchList={clearAccountsSearchList}
                          />
                          {dynamicAccountOpenState[cf._id as string] && (
                            <CreateOrEditAccount
                              ref={(ref) => {
                                let arr = [];
                                for (let key in dynamicAccountOpenState) {
                                  if (dynamicAccountOpenState[key] === true) {
                                    arr.push(key);
                                  }
                                }
                                arr.map((el, i) => {
                                  if (el === cf._id) {
                                    return (accountChildRefs.current[i] = ref);
                                  }
                                });
                              }}
                              closeAddNewPopup={() =>
                                closeAddNewAccountPopup(cf?._id as string)
                              }
                              cfId={cf._id as string}
                              handleAccountCFChangeForSearchableDropdown={
                                handleAccountCFChangeForSearchableDropdown
                              }
                              accountToBeEdited={
                                (selectedAccountAsCFValue !== null &&
                                  Object.keys(selectedAccountAsCFValue)
                                    .length &&
                                  selectedAccountAsCFValue[cf._id as string]) ||
                                undefined
                              }
                              onClose={() =>
                                setPreviousAccountAsCFValueOnClosingEditBox(
                                  cf._id as string,
                                )
                              }
                              addNewAccount={addNewAccount}
                              toBeEdited={toBeEdited}
                              callFrom={'opportunity'}
                            />
                          )}
                          {selectedAccountAsCFValue?.[cf._id as string] &&
                            !dynamicAccountOpenState[cf._id as string] && (
                              <ViewContactOrAccountDetails
                                usedFor="account"
                                selected={
                                  selectedAccountAsCFValue[cf?._id as string]
                                }
                                addNew={() => addNewAccount(cf._id as string)}
                                clearSelectedContactAndCloseViewContactDetailBox={() =>
                                  clearSelectedAccountAndCloseViewAccountDetailBox(
                                    cf._id as string,
                                  )
                                }
                                customFields={customFieldsFromAccounts}
                                parentItem="opportunity"
                              />
                            )}
                        </>
                      ) : cf.fieldData.fieldType === 'number' ? (
                        <input
                          type="text"
                          className="form-control small"
                          placeholder={`Please enter the ${cf.fieldData.fieldName}`}
                          onChange={(e) =>
                            handleCustomFieldsObj(
                              e,
                              cf._id as string,
                              cf.fieldData.fieldType,
                            )
                          }
                          value={customFieldsObj?.[cf._id as string] as string}
                        />
                      ) : cf.fieldData.fieldType === 'phone' ? (
                        <InputFieldForPhoneDataTypeCF
                          handleCustomFieldsObj={handleCustomFieldsObj}
                          cf={cf}
                          customFieldsObj={customFieldsObj}
                        />
                      ) : cf.fieldData.fieldType === 'longText' ? (
                        <textarea
                          className="form-control"
                          placeholder={`Please enter the ${cf.fieldData.fieldName}`}
                          onChange={(e) =>
                            handleCustomFieldsObj(e, cf._id as string)
                          }
                          value={customFieldsObj?.[cf._id as string] as string}
                          rows={4}
                        ></textarea>
                      ) : cf.fieldData.fieldType === 'user' ? (
                        <CustomSearchableMultiSelectedDropdownWithLoadMore
                          options={usersForUserCfDataType}
                          handleChange={(option: {
                            _id: string;
                            email: string;
                          }) =>
                            handleCustomFieldsObj(
                              option,
                              cf._id as string,
                              cf.fieldData.fieldType,
                            )
                          }
                          handleClearAll={(checked: boolean) =>
                            handleClearAllUsers(checked, cf._id as string)
                          }
                          handleAllCheckbox={() => {}}
                          handleSearchListInSearchableDropdown={(text: string) => {
                            setSearchTextForUsers(text)
                          }}
                          listOfSelectedIds={
                            customFieldsObj?.[cf._id as string] || []
                          }
                          dropdownFor="users"
                          handlePageChange={() => {}}
                          disabled={false}
                        />
                      ) : (
                        <input
                          type={
                            cf.fieldData.fieldType === 'email'
                              ? 'email'
                              : 'text'
                          }
                          className="form-control small"
                          placeholder={`Please enter the ${cf.fieldData.fieldName}`}
                          onChange={(e) =>
                            handleCustomFieldsObj(e, cf._id as string)
                          }
                          value={customFieldsObj?.[cf._id as string] as string}
                        />
                      )}
                      <span className="error_mesage required">
                        {errorStatement(cf as CustomField)}
                      </span>
                    </Grid>
                  );
                })}
            </Box>
          </Box>
        </Grid>
      </Grid>
      {(isCustomFieldsLoading ||
        opportunityState.isLoading ||
        contactState.isLoading ||
        accountState.isLoading ||
        isLoading) && <Loader />}
      <ConfirmationAlertPopUp
        openModal={openAddNewContactConfirmationPopup}
        closeModalFunc={() => {
          if (changesMadeInCreateOrEditContact) {
            addNewContact(cfIdForAddNewContact, true);
          } else {
            closeAddNewContactConfirmationPopup();
          }
        }}
        handleCrossIconClick={() => {
          closeAddNewContactConfirmationPopup();
        }}
        title={'Unlink!'}
        text={contactConfirmationText}
        confirmationButtonText={
          changesMadeInCreateOrEditContact ? 'Save Contact And Unlink' : 'Yes'
        }
        confirmationButtonColor="secondary"
        closeButtonText={
          changesMadeInCreateOrEditContact ? 'Unlink Without Saving' : 'Cancel'
        }
        functionality={() => {
          if (changesMadeInCreateOrEditContact) {
            saveContactBeforeUnlinking();
          } else {
            addNewContact(cfIdForAddNewContact, true);
            removeValueFromCfFieldObj(cfIdForAddNewContact);
          }
        }}
      />
      <ConfirmationAlertPopUp
        openModal={openChangeSelectedContactConfPopup}
        closeModalFunc={closeChangeSelectedContactConfPopup}
        title={'Change Selected Contact'}
        text={'Are you sure you want to change the linked contact?'}
        confirmationButtonText={'Yes'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={() => {
          handleContactCFChangeForSearchableDropdown(
            optionForHandleContactCFChangeForSearchableDropdown,
            cfidForHandleContactCFChangeForSearchableDropdown,
          );
          closeChangeSelectedContactConfPopup();
        }}
      />

      {/* Confirmation Alert For Accounts */}
      <ConfirmationAlertPopUp
        openModal={openAddNewAccountConfirmationPopup}
        closeModalFunc={() => {
          if (changesMadeInCreateOrEditAccount) {
            addNewAccount(cfIdForAddNewAccount, true);
          } else {
            closeAddNewAccountConfirmationPopup();
          }
        }}
        handleCrossIconClick={() => {
          closeAddNewAccountConfirmationPopup();
        }}
        title={'Unlink!'}
        text={accountConfirmationText}
        confirmationButtonText={
          changesMadeInCreateOrEditAccount ? 'Save Account And Unlink' : 'Yes'
        }
        confirmationButtonColor="secondary"
        closeButtonText={
          changesMadeInCreateOrEditAccount ? 'Unlink Without Saving' : 'Cancel'
        }
        functionality={() => {
          if (changesMadeInCreateOrEditAccount) {
            addingNewAccount.current = true;
            saveAccountBeforeUnlinking();
          } else {
            addNewAccount(cfIdForAddNewAccount, true);
            removeValueFromCfFieldObj(cfIdForAddNewAccount);
          }
        }}
      />
      <ConfirmationAlertPopUp
        openModal={openChangeSelectedAccountConfPopup}
        closeModalFunc={closeChangeSelectedAccountConfPopup}
        title={'Change Selected Account'}
        text={'Are you sure you want to change the linked account?'}
        confirmationButtonText={'Yes'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={() => {
          handleAccountCFChangeForSearchableDropdown(
            optionForHandleAccountCFChangeForSearchableDropdown,
            cfidForHandleAccountCFChangeForSearchableDropdown,
          );
          closeChangeSelectedAccountConfPopup();
        }}
      />
    </Box>
  );
};

export default AddOrEditOpportunity;
