import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as qs from 'qs';
import { get } from 'lodash';
import { getProjectIdFromUrl, UserData } from '../../../../helpers/common';
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';
import HTMLDecoderEncoder from 'html-encoder-decoder';

/**
 * @classdesc React component for rendering a meeting email preview.
 * @category Components
 * @extends Component
 */
class MeetingEmailPreview extends Component {
   /**
   * @constructor
   * @description Initializes the MeetingEmailPreview component state.
   * @param {Object} props - The props that were passed to the component.
   */
  constructor(props) {
    super(props);
    this.state = {
      cid: '',
      campaignDetails: {},
      editData: '',
      isEdit: '',
      meetingTitle: '',
      errors: '',
      meetingTemplate: '',
      html: '',
      meetingData: '',
      defaultTemplate: '',
      editButtonClick:false
    };
  }

  /**
   * @method componentDidMount
   * @description Lifecycle method called after the component has been inserted into the DOM.
   * Fetches the meeting template and sets initial state values.
   */
  componentDidMount() {
    const { editData, isEdit } = this.props;
    const { meetingTemplate } = this.state;

    this.setState({
      editData,
      isEdit,
      html: meetingTemplate.html,
      json: meetingTemplate.json,
    });
  }

   /**
   * @method handleGetTemplate
   * @description Fetches the meeting template based on meetingType and updates the component state.
   * @param {string} meetingType - The type of meeting template to fetch.
   */
  handleGetTemplate = (meetingType) => {
    const URL = process.env.REACT_APP_API_URL;
    const users = UserData();
    let params = '';

    if (meetingType === 'Default') {
      params = {
        meetingType: meetingType,
      };
    } else {
      if (this.state.isEdit) {
        params = {
          meetingType: meetingType,
          meetingId: this.state.editData._id,
          eventId: this.state.editData.eventId,
        };
      } else {
        params = {
          meetingType: meetingType,
          meetingId: this.state.meetingData._id,
          eventId: this.state.meetingData.eventId,
        };
      }
    }
    axios
      .post(`${URL}/getMeetingTemplate`, params, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
          // 'check-grant': `${this.state.projectId}-${permissions.resetPassword}`,
        },
      })
      .then((data) => {
        if (data.data.status == 200) {
          this.setState({
            html:
              meetingType === 'Default'
                ? HTMLDecoderEncoder.decode(data.data.meetingTemplate[0].html)
                : HTMLDecoderEncoder.decode(data.data.meetingTemplate),
            defaultTemplate: data.data?.meetingTemplate[0],
          });
        }
      });
  };

  /**
   * @method componentDidUpdate
   * @description Lifecycle method called after the component updates.
   * Updates the component state based on props changes.
   * @param {Object} prevProps - Previous props.
   * @param {Object} prevState - Previous state.
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.Meeting.getMeetingTemplateSuccess !==
        prevProps.Meeting.getMeetingTemplateSuccess &&
      this.props.Meeting.getMeetingTemplateSuccess === true
    ) {
      this.setState({
        html: this.props.Meeting.meetingTemplate[0].html,
        json: this.props.Meeting.meetingTemplate[0].json,
        meetingTemplate: this.props.Meeting.meetingTemplate[0],
        meetingTitle: this.props.Meeting.meetingTemplate[0].meetingTitle,
      });
    }

    if (this.state !== prevState) {
      this.props.getDataFromMeetingEmailPreview(this.state);
    }
  }

  /**
   * @method handleEditEmailButton
   * @description Handles the click event for editing the email button.
   * Triggers the customization of the email builder and creates or updates the meeting accordingly.
   * @param {Object} template - The template to be edited.
   */
  handleEditEmailButton = (template) => {
    if (this.props.addMeetingStateData?.meetingTitle) {
      const { isEdit, meetingTemplate } = this.state;

      this.props.handleClickedCustomiseEmailBuilder(true);
      if (!isEdit) {
        this.props.createMeeting('Draft');
      } else {
        this.setState({
          editButtonClick:true
        })
        this.props.createMeeting(meetingTemplate.status,"save");
      }
    } else {
      let error = {};
      error['meetingTitle'] = this.props.users.languageStateForRedux?.validations?.meetingTitle;
      this.setState({
        errors: error,
      });
    }
  };

   /**
   * @method render
   * @description Renders the MeetingEmailPreview component.
   * @returns {JSX.Element} JSX representation of the component.
   */
  render() {
    const eventId = getProjectIdFromUrl();
    return (
      <Grid item xs={12} md={6} lg={4}>
        <Box
          sx={{
            border: '1px solid #EDECF5',
            borderRadius: '8px',
            backgroundColor: '#fff',
            height: '100%',
          }}
        >
          {this.props.errors?.meetingTitle !== "Meeting Title already exists" &&
          <Box
            px={3}
            py={1.8}
            sx={{
              cursor: 'pointer',
              borderBottom: '1px solid #EDECF5',
              textAlign: 'right',
              textTransform: 'uppercase',
              color: '#5141E7',
            }}
            disabled={this.props.errors?.meetingTitle === "Meeting Title already exists" ? true :false}

            onClick={() => this.handleEditEmailButton()}
          >
            {this.props.users.languageStateForRedux?.common?.editor}
          </Box>
           }
          <div style={{ pointerEvents: 'none' }}>
            {ReactHtmlParser(this.state.html)}
          </div>
        </Box>
      </Grid>
    );
  }
}

/**
 * @function mapStateToProps
 * @description Maps the Redux state to component props.
 * @param {Object} state - Redux state object.
 * @returns {Object} Props derived from the Redux state.
 */
const mapStateToProps = (state) => ({
  project: state.Projects,
  Meeting: state.Meeting,
  events: state.Events,
  users: state.Users,
});

/**
 * @function mapActionsToProps
 * @description Maps Redux actions to component props.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Object} Props containing action functions.
 */
const mapActionsToProps = (dispatch) => bindActionCreators({}, dispatch);

// Connects the component to Redux store and withRouter for access to router props
export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withRouter(MeetingEmailPreview));
