import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from '../Redux/Actions';
import PrivateUserRoute from './privateUserRoutes';
import PrivateMemberRoute from './privateMemberRoutes';
import PageNotFound from '../Components/PageNotFound';
import { withRouter } from 'react-router';
import Login from '../Pages/Login';
import Eventlist from '../Pages/EventList';
import RegistrationEditing from '../Pages/RegisterEditing';
import Contact from '../Pages/Contact';
import ContactGroups from '../Pages/ContactGroups';
import ListAudience from '../Pages/ContactGroups/listAudience';
import Analytics from '../Pages/Analytics';
import RegistrationAnalytics from '../Pages/Analytics/registrationAnalytics';
import UserProfile from '../Pages/Profile';
import ForgotPassword from '../Pages/ForgotPassword';
import ResetPassword from '../Pages/ResetPassword';
import EmailVerified from '../Pages/EmailVerified';
import CompleteRegistration from '../Pages/CompleteRegistration';
import CreateEvent from '../Pages/CreateEvent';
import CreateYEvent from '../Pages/CreateEvent/index2';
import UpdateEvent from '../Pages/UpdateEvent';
import ProjectType from '../Pages/ProjectType/view';
import ProjectTypeEdit from '../Pages/ProjectType/edit';
import EventHomePage from '../Pages/EventHomePage';
import EventRegisteredUser from '../Pages/EventRegisteredUser';
import EditLibTemplate from '../Pages/PageDesign/editLibTemplate';
import Settings from '../Pages/Settings';
import Projects from '../Pages/Projects';
import CreateProject from '../Pages/Projects/CreateProject';
import Dashboard from '../Pages/Dashboard';
import ProjectDashboard from '../Pages/ProjectDashboard';
import Agenda from '../Pages/Agenda';
import Report from '../Pages/Report';

import Home from '../Pages/Files/Home';
import Videos from '../Pages/Files/Videos';
import Docs from '../Pages/Files/Docs';

/* Meeting Page Components*/
import PageDesignList from '../Pages/PageDesign/PageDesignList';
import ChoosePageDesign from '../Pages/PageDesign/ChoosePageDesign';
import PageDesign from '../Pages/PageDesign';
import TransactionalDesign from '../Pages/TransactionalDesign';
/* Meeting Page Components*/

import LoginFormDesign from '../Pages/LoginFormDesign';
import EmailDashboard from '../Pages/CompleteEmail';
import ChooseEmailTemplate from '../Pages/CompleteEmail/ChooseEmailTemplate';
import EditTemplate from '../Pages/CompleteEmail/EditTemplate';
import Reviews from '../Pages/CompleteEmail/Reviews';
import Resipients from '../Pages/CompleteEmail/Resipients';
import EditTemplateDesign from '../Pages/CompleteEmail/EditTemplateDesign';
import Invitation from '../Pages/Invitation';
import Notifications from '../Pages/Notifications';
import EmailPerformance from '../Pages/Analytics/emailPerformance';
import CreateEmailCampaign from '../Pages/EmailCampaign';
import ChooseEmailtemplate from '../Pages/EmailCampaign/ChooseEmailtemplate';
import Reschedule from '../Pages/EmailCampaign/reschedule';
import ImportLogs from '../Pages/Contact/rejectContacts';
import ChooseTemplate from '../Pages/EventPage/chooseTemplate';

import CreateEventPage from '../Pages/EventPage/createEventPage';
import ProjectEditortPage from '../Pages/ProjectDashboard/ManagePage/projectEditorPage';
import transactionEmailEditor from '../Pages/ProjectDashboard/ManagePage/transactionEmailEditor';
import badgesTemplateEditor from '../Pages/ProjectDashboard/ManagePage/badgesTemplateEditor';
import ManageBadgeSettings from '../Pages/ProjectDashboard/ManagePage/ManageBadgeSettings';
import EventPageListing from '../Pages/EventPage/eventPageListing';
import impersonate from '../Pages/AutoLogin/impersonate';
import wixLogin from '../Pages/AutoLogin/wixLogin';
import EditProject from '../Pages/Projects/EditProject';
import addNotification from '../Pages/Dashboard/Notications/addNotification';
import NotificationsDashboard from '../Pages/Dashboard/Notications/NotificationsDashboard';
import AddNewPage from '../Pages/ProjectDashboard/ManagePage/AddNewPage';
import SelectPageTemplate from '../Pages/ProjectDashboard/ManagePage/SelectPageTemplate';
import ManagePageSettings from '../Pages/ProjectDashboard/ManagePage/ManagePageSettings';

import CreateCampaign from '../Pages/ProjectDashboard/Campaigns/CreateCampaign';

import SelectCampaignTemplate from '../Pages/ProjectDashboard/Campaigns/SelectCampaignTemplate';
import CampaignsEditor from '../Pages/ProjectDashboard/Campaigns/CampaignsEditor';
import Editor from '../Pages/ProjectDashboard/Meetings/Editor';
import CreateMeeting from '../Pages/ProjectDashboard/Meetings/CreateMeeting';

import EditCampaign from '../Pages/ProjectDashboard/Campaigns/EditCampaign';
import PagePreview from '../Pages/ProjectDashboard/ManagePage/PagePreview';
import campaignPreview from '../Pages/ProjectDashboard/Campaigns/campaignPreview';
import { Url, showLogin } from '../constants/constants';
import campaignPreviewWithExit from '../Pages/ProjectDashboard/Campaigns/campaignPreviewWithExit';
import { TransactionalEmailPreview } from '../Pages/ProjectDashboard/Campaigns/transactionalEmailPreview';
import { NotificationContainer } from 'react-notifications';
import { ImportModal } from '../Pages/ContactGroups/importModal';
// import meetingsEditor from '../Pages/ProjectDashboard/Meetings/meetingsEditor';
import DashboardProfile from 'Pages/DashboardProfile.js/Index';
import GoogleSignIn from 'Pages/GoogleSignIn/GoogleSignIn';
import { createAgenda } from 'Redux/API';
import { createEvent } from '@testing-library/react';
import MeetingEmailPreviewForReport from 'Pages/ProjectDashboard/Meetings/MeetingEmailPreviewForReport';
import { UserData } from 'helpers/common';
import AiChatbot from 'Pages/AIChatbot';
import WidgetContainer from 'Components/ChatWidget/WidgetContainer';
import OpportunityLayout from 'Pages/Opportunity/OpportunityLayout';
import FileUpload from '../Pages/Files/FileUpload';
import FolderContent from '../Pages/Files/FolderContent'
import OpportunityTransactionEmailEditor from 'Pages/Opportunity/Opportunity/OpportunityTransactionEmailEditor';
import openApiAuth from 'Pages/openApiAuth';
// import WidgetContainer from 'Components/ChatWidget/WidgetContainer';

class ApplicationRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.changeXpUsersWebSocketConnectionStatus = this.changeXpUsersWebSocketConnectionStatus.bind(this);
    this.handleWebSocketMessage = this.handleWebSocketMessage.bind(this);

    this.signalInterval=null;
  }

  componentDidMount() {
    const favId = document.getElementById('favicon');
    favId.href = "/favicon1.ico";
    this.props.prouteRquest('/my-events'); 
    
    const user = UserData()
    
    if(user?.token){
      this.connectWS(user.token)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.prouteRquest(prevProps.location.pathname);
    }
  }
  
  connectWS(token) {
    let webSocketUrl = process.env.REACT_APP_AWS_USER_WEBSOCKET_URL;
    if (token) {
      webSocketUrl += `?xpToken=bearer ${token}`;
    }
    let socket = new WebSocket(webSocketUrl);
    socket.onopen = (e) => this.handleWebSocketOpen(e, socket)

    socket.onmessage = this.handleWebSocketMessage

    socket.onclose = (e) => this.handleWebSocketClose(e, token)

    socket.onerror = function (error) {
      console.error('xp users WebSocket error:', error);
    };
  }

  handleWebSocketOpen(event, socket){
    console.log('xp users WebSocket connection established.'); 
    this.changeXpUsersWebSocketConnectionStatus(true)
    this.signalInterval = setInterval(() => {
      console.log('after 9 minut');
      socket.send(
        JSON.stringify({ action: 'ping', message: 'hi, from client' }),
      );
    }, 1000 * 60 * 9); //60*9
  }

  handleWebSocketMessage(event){
    // console.log(`xp users WebSocke - [message] Data received from server: ${event.data}`); 
    this.props.setXpUsersWebSocketConnectionMessage(JSON.parse(event.data))
  }

  handleWebSocketClose(event, token){
    if(this.signalInterval){
      clearInterval(this.signalInterval);
    }
    this.changeXpUsersWebSocketConnectionStatus(false)
    if (event.wasClean) {
      console.log(
        `xp users WebSocke - [close] Connection closed cleanly, code=${event.code} reason=${event.reason}`,
      );
    } else {
      // e.g. server process killed or network down
      this.connectWS(token);
    }
  }

  changeXpUsersWebSocketConnectionStatus(status){
    this.props.changeXpUsersWebSocketConnectionStatus(status)
  }

  render() {
    return (
      <>
        <Switch>
          {/* <Route exact path="/service/chat-widget/:agentId" component={(props) => <WidgetContainer {...props} />} /> */}
          <Route exact path="/" component={UserData() ?
            (() => { window.location.href = '/my-events'; }) :
            (showLogin ? Login : () => { window.location.href = Url.wixUrl; })
          } />
          <Route exact path="/auto-login/:hash" component={impersonate} />
          <Route exact path="/app/:hash" component={wixLogin} />

          <Route path="/forgotPassword" component={ForgotPassword} />
          <Route path="/resetPassword/:token" component={ResetPassword} />
          <Route path="/emailVerified/:token" component={EmailVerified} />

          <Route path="/openApi/auth" component={openApiAuth} />

          <PrivateUserRoute path="/my-events" component={Eventlist} />

          <PrivateUserRoute path="/project-dashboard/overview/:eventId" component={ProjectDashboard} />
          <PrivateUserRoute path="/project-dashboard/manage-pages/:eventId" component={ProjectDashboard} />
          <PrivateUserRoute path="/project-dashboard/preview-page/:eventId" component={PagePreview} />
          <PrivateUserRoute path="/project-dashboard/campaigns/create-campaign/:ei/" component={CreateCampaign} />
          <PrivateUserRoute path="/project-dashboard/campaigns/select-template/ei/:ei/cn/:cn" component={SelectCampaignTemplate} />
          <PrivateUserRoute path="/project-dashboard/campaign-template-edior/tid/:tid/cn/:cn/eid/:eid" component={CampaignsEditor} />
          <PrivateUserRoute path="/project-dashboard/meeting-edior/:eventId" component={Editor} />
          <PrivateUserRoute path="/meetings/create-meeting" component={CreateMeeting} />


          <PrivateUserRoute path="/project-dashboard/edit-campaign/cn/:cn/eid/:eid" component={EditCampaign} />
          <PrivateUserRoute path="/project-dashboard/preview-campaign/:eventId" component={campaignPreview} />

          <PrivateUserRoute path="/project-dashboard/preview-campaign-with-exit/:eventId" component={campaignPreviewWithExit} />
          <PrivateUserRoute path="/project-dashboard/preview-transactional/:eventId" component={TransactionalEmailPreview} />
          <PrivateUserRoute path="/project-dashboard/preview-meeting/:eventId/:email" component={MeetingEmailPreviewForReport} />
          <PrivateUserRoute path="/project-dashboard/campaigns/edit/:eventId" component={ProjectDashboard} />
          <PrivateUserRoute path="/project-dashboard/campaigns/:eventId" component={ProjectDashboard} />

          <PrivateUserRoute path="/project-dashboard/analytics/:eventId" component={ProjectDashboard} />
          <PrivateUserRoute path="/project-dashboard/agenda/:eventId" component={ProjectDashboard} />
          <PrivateUserRoute path="/project-dashboard/notifications/:eventId" component={ProjectDashboard} />
          <PrivateUserRoute path="/project-dashboard/access-code/:eventId" component={ProjectDashboard} />
          <PrivateUserRoute path="/project-dashboard/team/:eventId" component={ProjectDashboard} />
          <PrivateUserRoute path="/project-dashboard/attendees/:eventId" component={ProjectDashboard} />
          <PrivateUserRoute path="/project-dashboard/integration/:eventId" component={ProjectDashboard} />
          <PrivateUserRoute path="/project-dashboard/meetings/settings/:eventId" component={ProjectDashboard} />
          <PrivateUserRoute path="/project-dashboard/meetings/:eventId" component={ProjectDashboard} />
          <PrivateUserRoute path="/project-dashboard/gamification/challenges/:eventId" component={ProjectDashboard} />
          <PrivateUserRoute path="/project-dashboard/gamification/configuration/:eventId" component={ProjectDashboard} />
          <PrivateUserRoute path="/project-dashboard/gamification/leaderboard/:eventId" component={ProjectDashboard} />

          {/* Project Dashboard tabs */}
          <PrivateUserRoute path="/events-list" component={Eventlist} />
          <PrivateUserRoute path="/event-home" component={EventHomePage} />
          <PrivateUserRoute
            path="/audience-contacts/:id?"
            component={ContactGroups}
          />
          <PrivateUserRoute path="/audience/:id?" component={ListAudience} />
          <PrivateUserRoute
            path="/analytics/:eventId?/:templateId?"
            component={Analytics}
          />
          <PrivateUserRoute
            path="/registration-analytics/:eventId?/:templateId?"
            component={RegistrationAnalytics}
          />

          <PrivateUserRoute path="/user-profile" component={UserProfile} />
          <PrivateUserRoute path="/profile" component={DashboardProfile} />
          <PrivateUserRoute
            path="/complete-registration"
            component={CompleteRegistration}
          />
          <PrivateUserRoute path="/create-event" component={CreateEvent} />
          <PrivateUserRoute path="/create-project" component={CreateProject} />


          <PrivateUserRoute path="/create-your-event" component={CreateYEvent} />

          <PrivateUserRoute path="/agenda" component={Agenda} />

          <PrivateUserRoute path="/email" component={EmailDashboard} />
          <PrivateUserRoute
            path="/choose-template"
            component={ChooseEmailTemplate}
          />
          <PrivateUserRoute path="/edit-template" component={EditTemplate} />
          <PrivateUserRoute
            path="/email-campaigns/:eventId"
            component={CreateEmailCampaign}
          />
          <PrivateUserRoute
            path="/choose-email-template/:eventId"
            component={ChooseEmailtemplate}
          />
          <PrivateUserRoute
            path="/reschedule/:eventId/:templateId"
            component={Reschedule}
          />
          <PrivateUserRoute
            path="/choose-event-template/:eventId"
            component={ChooseTemplate}
          />
          <PrivateUserRoute
            path="/edit-lib-template/eid/:eventId/tid/:templateId"
            component={EditLibTemplate}
          />

          <PrivateUserRoute
            path="/email-performance/:eventId?/:templateId?"
            component={EmailPerformance}
          />

          <PrivateUserRoute path="/review/:eventId" component={Reviews} />
          <PrivateUserRoute
            path="/resipients/:eventId/:templateId"
            component={Resipients}
          />
          <PrivateUserRoute
            path="/project-type/:eventId"
            component={ProjectType}
          />
          <PrivateUserRoute
            path="/project-type-edit/:eventId"
            component={ProjectTypeEdit}
          />
          <PrivateUserRoute
            path="/login-form-design/:eventId"
            component={LoginFormDesign}
          />

          {/* template design listing routes */}
          <PrivateUserRoute
            path="/event-form-list/:eventId"
            component={(props) => (
              <PageDesignList {...props} type="registrationForm" />
            )}
          />
          <PrivateUserRoute
            path="/login-page-list/:eventId"
            component={(props) => <PageDesignList {...props} type="Login" />}
          />
          <PrivateUserRoute
            path="/meeting-page-list/:eventId"
            component={(props) => <PageDesignList {...props} type="Meeting" />}
          />

          {/* template design editing routes */}
          <PrivateUserRoute
            exact
            path="/edit-event-form/eid/:eid/tid/:tid"
            component={(props) => (
              <PageDesign {...props} type="registrationForm" />
            )}
          />
          <PrivateUserRoute
            exact
            path="/edit-event-form/eid/:eid/tid/:tid/transactional"
            component={(props) => (
              <TransactionalDesign
                {...props}
                type="registrationForm"
                transactional={true}
              />
            )}
          />
          <PrivateUserRoute
            path="/edit-login-page/eid/:eid/tid/:tid"
            component={(props) => <PageDesign {...props} type="Login" />}
          />
          <PrivateUserRoute
            path="/edit-meeting-page/eid/:eid/tid/:tid"
            component={(props) => <PageDesign {...props} type="Meeting" />}
          />

          {/* choose template design routes */}
          <PrivateUserRoute
            path="/choose-form-design/:eventId"
            component={(props) => (
              <ChoosePageDesign {...props} type="registrationForm" />
            )}
          />
          <PrivateUserRoute
            path="/choose-login-page/:eventId"
            component={(props) => <ChoosePageDesign {...props} type="Login" />}
          />
          <PrivateUserRoute
            path="/choose-meeting-page/:eventId"
            component={(props) => <ChoosePageDesign {...props} type="Meeting" />}
          />

          {/* event pages routes */}
          <PrivateUserRoute
            path="/event-page/eid/:eid/tid/:tid"
            component={(props) => (
              <CreateEventPage {...props} type="Event-Page" />
            )}
          />
          <PrivateUserRoute path="/project-page/pid/:pid/tid/:tid/pn/:pn/purl/:purl" component={ProjectEditortPage} />
          <PrivateUserRoute path="/project-page/update-page/pid/:pid/tid/:tid/pn/:pn/purl/:purl" component={ProjectEditortPage} />
          <PrivateUserRoute
            path="/event-page/eid/:eid/tid/:tid"
            component={(props) => (
              <CreateEventPage {...props} type="Event-Page" />
            )}
          />
          <PrivateUserRoute
            path="/event-page-list/:eventId"
            component={(props) => (
              <EventPageListing {...props} type="Event-Page" />
            )}
          />
          {/* event pages routes */}

          <PrivateUserRoute
            path="/event-registered-user/"
            component={(props) => <EventRegisteredUser {...props} />}
          />

          <PrivateUserRoute
            path="/choose-page-design/:eventId"
            component={ChoosePageDesign}
          />

          <PrivateUserRoute path="/page-design/:eventId" component={PageDesign} />

          <PrivateUserRoute
            path="/registration-editing/:eventId"
            component={RegistrationEditing}
          />
          <PrivateUserRoute
            path="/edit-template-design/:eventId/:mode/:templateId"
            component={EditTemplateDesign}
          />
          <PrivateUserRoute
            path="/update-event/:eventId"
            component={UpdateEvent}
          />
          <PrivateUserRoute
            path="/edit-project/:projectId"
            component={EditProject}
          />
          <PrivateUserRoute path="/notifications" component={Notifications} />
          <PrivateUserRoute path="/importlogs" component={ImportLogs} />

          <PrivateMemberRoute path="/member/my-events" component={Eventlist} />
          <PrivateMemberRoute path="/member/events-list" component={Eventlist} />
          <PrivateMemberRoute
            path="/member/event-home"
            component={EventHomePage}
          />
          <PrivateMemberRoute path="/member/contact" component={Contact} />
          <PrivateMemberRoute
            path="/member/audience-contacts/:id?"
            component={ContactGroups}
          />
          <PrivateMemberRoute
            path="/member/analytics/:eventId?/:templateId?"
            component={Analytics}
          />
          <PrivateMemberRoute
            path="/member/registration-analytics/:eventId?/:templateId?"
            component={RegistrationAnalytics}
          />
          <PrivateMemberRoute path="/member/profile" component={UserProfile} />
          <PrivateMemberRoute
            path="/member/complete-registration"
            component={CompleteRegistration}
          />
          <PrivateMemberRoute
            path="/member/create-event"
            component={CreateEvent}
          />
          <PrivateMemberRoute
            path="/member/update-event/eventId"
            component={UpdateEvent}
          />
          <PrivateMemberRoute
            path="/member/notifications"
            component={Notifications}
          />
          <PrivateMemberRoute path="/member/email" component={EmailDashboard} />
          <PrivateMemberRoute
            path="/member/choose-template"
            component={ChooseEmailTemplate}
          />
          <PrivateMemberRoute
            path="/member/edit-template"
            component={EditTemplate}
          />
          <PrivateMemberRoute
            path="/member/email-campaigns/:eventId"
            component={CreateEmailCampaign}
          />
          <PrivateMemberRoute
            path="/member/choose-email-template/:eventId"
            component={ChooseEmailtemplate}
          />
          <PrivateMemberRoute
            path="/member/choose-event-template/:eventId"
            component={ChooseEmailtemplate}
          />

          <PrivateMemberRoute
            path="/member/email-performance/:eventId?/:templateId?"
            component={EmailPerformance}
          />
          <PrivateMemberRoute
            path="/member/review/:eventId"
            component={Reviews}
          />
          <PrivateMemberRoute
            path="/member/reschedule/:eventId/:templateId"
            component={Reschedule}
          />
          <PrivateMemberRoute
            path="/member/resipients/:eventId/:templateId"
            component={Resipients}
          />
          <PrivateMemberRoute
            path="/member/project-type/:eventId"
            component={ProjectType}
          />
          <PrivateMemberRoute
            path="/member/project-type-edit/:eventId"
            component={ProjectTypeEdit}
          />
          <PrivateMemberRoute
            path="/member/page-design/:eventId"
            component={PageDesign}
          />
          <PrivateMemberRoute
            path="/member/registration-editing/:eventId"
            component={RegistrationEditing}
          />
          <PrivateMemberRoute path="/member/importlogs" component={ImportLogs} />
          <PrivateMemberRoute
            path="/member/edit-template-design/:eventId/:mode/:templateId"
            component={EditTemplateDesign}
          />
          <PrivateUserRoute
            path="/update-notification/:notificationId"
            component={addNotification}
          />
          {<PrivateUserRoute
            path="/add-new-page/:eventId"
            component={AddNewPage}
          />}
          <PrivateUserRoute
            path="/select-page-template/pid/:eventId/pn/:pageName/purl/:pageNameUrl"
          >
            <SelectPageTemplate />
          </PrivateUserRoute>
          <Route exact path="/ImportContact" component={ImportModal} />
          <Route exact path="/Settings" component={Settings} />
          <Route exact path="/Projects" component={Projects} />
          <Route exact path="/CreateProject" component={CreateProject} />
          <Route exact path="/Dashboard" component={Dashboard} />
          <Route exact path="/update-notifications/:notiId/:projectId"
            component={addNotification} />
          {/* <Route path="/update-notifications/:notificationId"
          component={addNotification} /> */}
          <Route path="/notification-dashboard/:ProjectId"
            component={NotificationsDashboard} />
          <Route path="/project-page/update-page/settings/pid/:pid/tid/:tid/pn/:pn/purl/:purl" component={ManagePageSettings} />
          <Route path="/project-page/update-transactional-email-templates-page/pid/:pid/tid/:tid/pn/:pn/purl/:purl" component={transactionEmailEditor} />"
          <Route path="/project-page/update-badges-template-page/pid/:pid/tid/:tid/pn/:pn/purl/:purl" component={badgesTemplateEditor} />"
          <Route path="/project-badge/update-badge/settings/pid/:pid/tid/:tid/pn/:pn/purl/:purl" component={ManageBadgeSettings} />
          {/* <Route path="/project-page/update-meetings-templates-page" component={meetingsEditor} />" */}

          <Route exact path="/roles" component={Settings} />
          <Route exact path="/billing" component={Settings} />
          <Route exact path="/users" component={Settings} />
          <Route exact path="/general" component={Settings} />
          <Route exact path="/yourPlan" component={Settings} />
          <Route exact path="/api-key" component={Settings} />
          <Route exact path="/customization" component={Settings} />
          {/* <Route exact path="/project-dashboard/meetings/create-meeting" component={createMeeting} /> */}
          <Route exact path="/project-dashboard/google-sign-in/:eventId/" component={GoogleSignIn} />


          <Route exact path="/report" component={Report} />
          <Route exact path="/aichatbot" component={AiChatbot} />
          <Route exact path="/aichatbot/general" component={AiChatbot} />
          <Route exact path="/aichatbot/general/:id" component={AiChatbot} />
          <Route exact path="/aichatbot/chatwidget/:id" component={AiChatbot} />
          <Route exact path="/aichatbot/settings/:id" component={AiChatbot} />
          <Route exact path="/aichatbot/dashboard/:id" component={AiChatbot} />
          <Route exact path="/aichatbot/integrations/:id" component={AiChatbot} />
          <Route exact path="/opportunity/accounts" component={OpportunityLayout} />
          <Route exact path="/opportunity/opportunity" component={OpportunityLayout} />
          <Route exact path="/opportunity/contacts" component={OpportunityLayout} />
          <Route exact path="/opportunity/audience" component={OpportunityLayout} />
          <Route exact path="/opportunity/contactGroups" component={OpportunityLayout} />
          <Route exact path="/opportunity/opportunity/update-transactional-email-templates" component={OpportunityTransactionEmailEditor} />
          <Route
            path="/member/invitation/:status/:token"
            component={Invitation}
          />
          <Route exact path="/files/home/:folderId*" component={FileUpload} />
          <Route exact path="/files/videos/:folderId*" component={FileUpload} />
          <Route exact path="/files/docs/:folderId*" component={FileUpload} />

          <PrivateUserRoute
            path="/files/images/:folderId*"
            component={FileUpload}
          />

          <Route exact path="*" component={PageNotFound} />
        </Switch>
        <NotificationContainer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  proutes: state.Proutes
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      prouteRquest: Actions.prouteRequest,
      changeXpUsersWebSocketConnectionStatus: Actions.changeXpUsersWebSocketConnectionStatus,
      setXpUsersWebSocketConnectionMessage: Actions.setXpUsersWebSocketConnectionMessage
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withRouter(ApplicationRoutes));
