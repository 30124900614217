import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import { Button } from '@material-ui/core'
import 'react-responsive-modal/styles.css'

class DeleteGroupModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      loading: false,
      page_no: this.props.page_no ? this.props.page_no : 1,
      group_id: this.props.group_id ? this.props.group_id : '',
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // const login = this.props.login;

    if (
      this.props.groups.success === true &&
      this.props.groups.message !== prevProps.groups.message
    ) {
      createNotification('success', this.props.groups.message)
      let st = prevState.loading ? this.setState({ loading: false }) : null
      this.props.listContactRequest({ page_no: this.state.page_no })
    }

    if (
      this.props.groups.error &&
      this.props.groups.message !== prevProps.groups.message
    ) {
      createNotification('error', this.props.groups.message, '')
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  isValidGroup = () => {
    const { groupName, group_id } = this.state
    let error = {}
    let formIsValid = true

    if (group_id === '' || group_id.trim().length === 0) {
      formIsValid = false
      error['group_id'] = '*Please select audience.'
    }
     
    this.setState({ errors: error })
    return formIsValid
  }

  handleGroupSubmit = (e) => {
    e.preventDefault()
    
    if (this.isValidGroup()) {
     
      const { group_id } = this.state

      const requestbody = { id: group_id, permission: 'Delete Audience' }
      this.props.deleteGroupRequest(requestbody)
      this.handleLoading()
      // this.props.startLoading();
    }
  }

  render() {
    const { errors, loading, group_id } = this.state
    return (
      <div>
        <h2>Delete Audience Group</h2>
        <div className="rsvpEventfieldBox">
          <div className="formSection p-0">
            <form onSubmit={this.handleGroupSubmit}>
              <div class="field-group inputField fullWidthInput">
                <label className="m-0">Select audience to delete</label>
                <span className="node noteContent">
                  <b>
                    {' '}
                    Note<sup>*</sup>:{' '}
                  </b>{' '}
                  If you delete audience then contacts of audience also delete
                </span>
                <select
                  name="group_id"
                  onChange={this.handleChange}
                  value={group_id}
                >
                  <option value="">Select Audience</option>
                  {this.props.groups.groups &&
                    this.props.groups.groups.map((data, index) => {
                      return (
                        <option value={data._id} key={index}>
                          {data.groupName}
                        </option>
                      )
                    })}
                </select>
                <p className="error_mesage"> {errors.group_id} </p>
              </div>

              <div className="editProfileBtn submitForm justify-content-center">
                <Button type="submit" className="themeBlueButton">
                  Delete Audience
                </Button>
              </div>
            </form>
          </div>
        </div>
        <NotificationContainer />
        {loading ? <Loader /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  contacts: state.Contacts,
  groups: state.Groups,
  // loading: state.Loader.loading,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteGroupRequest: Actions.deleteGroupRequest,
      listContactRequest: Actions.listContactRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(DeleteGroupModal)
