import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../../Redux/Actions'
import moment from 'moment'
import { ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { theme } from '../../../theme/theme';
import SearchIcon from '../../../assets/images/icons/search.svg';
import CampaignIcon from '../../../assets/images/icons/campaigns-white.svg';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import '../../../sass/main.scss'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from 'react-js-pagination'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Modal } from 'react-responsive-modal';
import { createNotification } from '../../../helpers'
import { NotificationContainer } from 'react-notifications'
import TamplateThum3 from '../../../assets/images/tamplate-thum3.png';
import ScheduleIcon from '../../../assets/images/icons/TimeCircle.svg';
import EditGroup from '../../../assets/images/icons/edit.svg';
import ReviewNotification from '../../../assets/images/review-notification.svg';
import BackArrow from '../../../assets/images/icons/backArrow.svg';
import EditCampaignContent from './EditCampaignComponents/EditCampaignContent'
import EditCampaignEditor from './EditCampaignComponents/EditCampaignEditor'
import CheckIcon from '@material-ui/icons/Check';
import * as qs from 'qs'
import { get } from 'lodash'
import EditCampaignRecipients from './EditCampaignComponents/EditCampaignRecipients'
import { styled } from '@material-ui/core/styles';
import CheckedInOut from '../../../assets/images/checkedInOut.svg';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDateTimePicker} from '@mui/x-date-pickers/MobileDateTimePicker';
import Loader from '../../../Components/Loader/loader'
import { convertTZ, getProjectIdFromUrl } from '../../../helpers/common'
import axios from 'axios'

const URL = process.env.REACT_APP_API_URL;
class EditCampaign extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTextForAttendeesOfCampaign: '',
      scrollNoForAttendeesOfCampaign: 1,
      campaignId: '',
      campaignName: '',
      scrollNoForAttendeesNotAddedToCampaign: 1,
      searchTextForAttendeesNotAddedToCampaign: '',
      projectId: '',
      campaignDetails: {},
      editCampaignName: false,
      recipients: {},
      subjectLine: '',
      senderName: '',
      replyToEmail: '',
      openReviewModal: false,
      openScheduleModal: false,
      errors: {},
      loading: false,
      timezone: null,
      listOfAttendees:[],
      excludeAttendeeCountValue:0,
      excludeCount:0,
      checkingIsValidIfRecipientsTypeIsAttendees: false,
      isValidIfRecipientsTypeIsAttendees: true
    }
  }

  componentDidMount() {
    const pathName = window.location.pathname.split('/')
    const length = window.location.pathname.split('/').length
    const projectId = pathName[length - 1]
    this.setState({
      projectId,
    }, () => this.getProjectRequest(projectId)
    )
    const cid = qs.parse(get(this.props, 'location.search'), {
      ignoreQueryPrefix: true,
    })
    this.setState({
      campaignId: cid.cid
    }, () =>{ 
      this.getCampaign()
      this.listAttendees()
    }
    )
  }

  componentDidUpdate(prevProps) {
    if (this.props.attendees?.getAttendeesSuccess && !prevProps.attendees?.getAttendeesSuccess && this.props.attendees.message !== prevProps.attendees.message) {
      this.setState({
          listOfAttendees: this.props.attendees?.attendees,
      })
    }
    if (this.props.projects.getProjectSuccess === true && !prevProps.projects.getProjectSuccess) {
      this.setState({
        timezone: this.props.projects.project.timezone
      });
    }

    if (this.props.campaigns?.getCampaignSuccess && !prevProps.campaigns.getCampaignSuccess && this.props.campaigns.message !== prevProps.campaigns.message) {
      this.setState({
        campaignDetails: this.props.campaigns?.campaignDetails[0],
        campaignName: this.props.campaigns?.campaignDetails[0]?.campaignName

      })
      this.props.handleCampaignNameInEditor(this.props.campaigns?.campaignDetails[0]?.campaignName)
    }
    if (this.props.campaigns?.updateCampaignSuccess && !prevProps.campaigns.updateCampaignSuccess && this.props.campaigns.message !== prevProps.campaigns.message) {
      if (this.props.campaigns?.updateCampaignData.status === 'Draft') {
        createNotification("success",this.props.users.languageStateForRedux?.messages?.savedAsDraft)
      }
      if (this.props.campaigns?.updateCampaignData.status === 'Scheduled') {
        createNotification("success", this.props.users.languageStateForRedux?.messages?.campaignScheduled)
        this.setState({
          openScheduleModal: false,
        })
      }
      if (this.props.campaigns?.updateCampaignData.status === 'Sent') {
        createNotification("success", this.props.users.languageStateForRedux?.messages?.campaignSent)
        this.setState({
          openReviewModal: false,
          
        })
      }

      if (this.state.loading) {
        this.setState({
          loading: false,
        })
      }

      window.location.href = `/project-dashboard/campaigns/${this.state.projectId}`;
    }

    if (this.props.campaigns?.error && !prevProps.campaigns?.error) {
      if (this.state.loading) {
        this.setState({
          loading: false,
        })
      }
    }


    if (this.props.campaigns.getExcludeAttendeeCountSuccess !==
      prevProps.campaigns.getExcludeAttendeeCountSuccess &&
       this.props.campaigns?.getExcludeAttendeeCountSuccess === true ) {
      this.setState({
        excludeAttendeeCountValue: this.props.campaigns?.excludeAttendeeCount,
        loading:false
      })
    }

    if (this.props.campaigns.getExcludeCountSuccess !==
      prevProps.campaigns.getExcludeCountSuccess &&
       this.props.campaigns?.getExcludeCountSuccess === true ) {
      this.setState({
        excludeCount: this.props.campaigns?.excludeCount,
        loading:false
      }, () => {
        if(this.state.excludeCount <= 0){
          this.setState({
            errors : {
              ...this.state.errors,
              attendees: 'At least one attendee is required.',
            },
            isValidIfRecipientsTypeIsAttendees: false
          })
        }
      })
    }
  }

  

  getProjectRequest(projectId) {
    this.props.getProjectRequest(projectId)
  }

  getDataFromEditContent = (data) => {
    this.setState({
      subjectLine: data.subjectLine || "",
      senderName: data.senderName || "",
      replyToEmail: data.replyToEmail || "",
    })
  }

  getDataFromEditRecipients = (data) => {
    this.setState({
      recipients: data
    })
  }

  getCampaign = () => {
    if (this.state.campaignId) {
      const eventId = getProjectIdFromUrl()
      this.props.getCampaign({ campaignId: this.state.campaignId, eventId })
    }
  }

  handleOpenEditCampaignName = () => {
    this.setState({
      editCampaignName: true
    })
  };

  listAttendees = () => {
    try {
        const permission = "Edit Campaign";
        this.props.listAttendees({ projectId: this.state.projectId, permission, page_no: 1, limit: 10 })
        const totalResults = this.props.attendees.totalAttendeesRecords
        this.setState({
            totalResults,
        });
    } catch (err) {
        createNotification('error', 'Something went wrong')
    }
}

  handleCloseEditCampaignName = async () => {
    if(this.state.campaignName && this.state.campaignName !== "" && this.state.campaignName !== undefined){
      if(await this.checkIfCampaignNameAlreadyExist()){
        this.setState({
          editCampaignName: false
        })
      }
    } else {
      this.setState({
        errors: {
          campaignName: 'Campaign name cannot be blank'
        }
      })
    }
   
  };

  UserData = async () => {
    const currentPath = window.location.pathname
    if (currentPath.includes('member')) {
      return JSON.parse(localStorage.getItem('member_details'))
    } else {
      return JSON.parse(localStorage.getItem('user_details'))
    }
  }

  checkIfCampaignNameAlreadyExist = async () => {
    const users = await this.UserData()
    let requestBody = {
      campaignName: this.state.campaignName,
      projectId: this.state.projectId,
      campaignId: this.state.campaignId
    }
    let res = await axios.post(`${URL}/campaigns/checkIfCampaignNameAlreadyExist`, requestBody,{
      headers: {
        'token': `bearer ${users.token}`,
      }
    })
    if (!res.data.available) {
      createNotification('error', 'Campaign name already exists')
    }
    return res.data.available
  }

  handleCloseEditCampaignNameWithoutSaving = () => {
    this.setState({
      editCampaignName: false,
      campaignName: this.state.campaignDetails.campaignName
    })
  };

  handleChange = (e) => {
    this.props.handleCampaignNameInEditor(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  isRecipienstsValid = (savingMethod) => {
    const { recipients } = this.state
    
    let error = {}
    let formIsValid = true
    if (savingMethod !== 'Draft' && recipients.recipientsType === 'audience') {
      if(recipients.audienceGroupsList.length === 0){
        formIsValid = false
        error['audience'] = this.props.users.languageStateForRedux?.validations?.selectAudience;
      }

      let selectedAudienceContactsLength = 0
      let allAudienceGroups = this.props.campaigns.audienceGroups      
      recipients.audienceGroupsList.map(audienceGroup => {
        if(recipients.excludeAttendees){
          selectedAudienceContactsLength += allAudienceGroups.find(o=>o._id === audienceGroup).excludeAttendeeCount
        }else{
          selectedAudienceContactsLength += allAudienceGroups.find(o=>o._id === audienceGroup).audienceCount
        }        
      })

      if(selectedAudienceContactsLength === 0){
        formIsValid = false
        error['audience'] = this.props.users.languageStateForRedux?.validations?.atLeastOneContact + (recipients.excludeAttendees ? this.props.users.languageStateForRedux?.validations?.notAddedAsAttendee: '')
      }
    }

    if (savingMethod !== 'Draft' && recipients.recipientsType === 'attendees') {
      this.setState({
        loading:true,
      })
    }

    if (recipients.recipientsType === "attendeesGroups" && savingMethod !== 'Draft') {
      if(recipients.attendeesGroupsList.length === 0){
        formIsValid = false
        error['groups'] = this.props.users.languageStateForRedux?.validations?.selectGroups;
      }
      
      let selectedGroupsAttendeesLength = 0
      let allAttendeeGroups = this.props.attendees.attendeesGroups     
      recipients.attendeesGroupsList.map(attendeesGroup => {
        if(recipients.excludeCheckedInAttendees){
          let currentAttendeeGroup = allAttendeeGroups.find(o=>o._id === attendeesGroup)
          if(currentAttendeeGroup.countByAttendeesStatus["Not Checked In"]){
            selectedGroupsAttendeesLength += currentAttendeeGroup.countByAttendeesStatus["Not Checked In"]
          }
        }else{
          selectedGroupsAttendeesLength += allAttendeeGroups.find(o=>o._id === attendeesGroup).totalAttendeesCount
        }        
      })
      if(selectedGroupsAttendeesLength === 0 ){
        formIsValid = false
        error['groups'] = this.props.users.languageStateForRedux?.validations?.atLeastOneAttendee + (recipients.excludeCheckedInAttendees ? this.props.users.languageStateForRedux?.validations?.notCheckedIn: '')
      }
    }
    if (recipients.recipientsType === "searchAdd" && recipients.searchAddedAttendees.length === 0 && savingMethod !== 'Draft') {
      formIsValid = false
      error['searchAdd'] = this.props.users.languageStateForRedux?.validations?.selectAttendees;
    }

    if (this.state.campaignName === "" || this.state.campaignName === undefined){
      formIsValid = false
      error['campaignName'] = this.props.users.languageStateForRedux?.validations?.campNameEmpty;
    }

    this.setState({
      errors: error
    })
    return formIsValid
  }

  saveAllInfo = (sendingMethod) => {
    let { campaignName, campaignId, projectId, campaignDetails, recipients, subjectLine, senderName, replyToEmail } = this.state
    if (Object.keys(recipients).length === 0 || Object.keys(recipients).length <= 1) {
      createNotification("error", this.props.users.languageStateForRedux?.validations?.selectRecInfo)
      return
    }

    if (this.isRecipienstsValid(sendingMethod)) {
      let requestBody = {
        eventId: projectId,
        campaignName: campaignName.trim(),
        campaignType: campaignDetails.campaignType,
        html: campaignDetails.html,
        json: campaignDetails.json,
        status: sendingMethod,
        recipients,
        id: campaignId,
        subjectLine,
        senderName,
        replyToEmail,
        lngCode: this.props.users.newLangState
      }

      if (sendingMethod === "Scheduled") {        
        requestBody = {
          ...requestBody,
          scheduledAt: convertTZ(this.state.scheduledDate, this.state.timezone)
        }
      }

      if (sendingMethod === "Sent") {
        requestBody = {
          ...requestBody,
          sentAt: new Date(),
        }
      }

      if (sendingMethod === "Draft") {
        if (recipients.recipientsType === "audience" && recipients.audienceGroupsList.length === 0) {
          recipients = null
        }
        requestBody = {
          ...requestBody,
          recipients,
          updatedAt: new Date(),
        }
      }

      if (this.isValid(this.state, sendingMethod)) {
        this.props.updateCampaign(requestBody)
        this.handleLoading()
      }
    }
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  isValid = (data, savingMethod) => {
    const { subjectLine, senderName, replyToEmail } = data

    let formIsValid = true;
    let error = {}

    if (savingMethod !== 'Draft' && (subjectLine.trim() === "" || subjectLine === undefined)) {
      formIsValid = false
      error["subjectLine"] = this.props.users.languageStateForRedux?.validations?.subLine;
    }

    if (savingMethod !== 'Draft' && (senderName.trim() === "" || senderName === undefined)) {
      formIsValid = false
      error["senderName"] = this.props.users.languageStateForRedux?.validations?.enterSendersName;
    }
    if (savingMethod !== 'Draft' && (replyToEmail.trim() === "" || replyToEmail === undefined)) {
      formIsValid = false
      error["replyToEmail"] = this.props.users.languageStateForRedux?.validations?.enterReply;
    }
    this.setState({
      errors: error
    })
    return formIsValid
  }

  handleCloseReviewModal = () => {
    this.setState({
      openReviewModal: false,
      excludeAttendeeCountValue:0,
      excludeCount:0
    })
  }

  handleOpenReviewModal = async () => {
    let {recipients} = this.state
    if(recipients.recipientsType ==="attendeesGroups" || recipients.recipientsType ==="attendees" ){
      this.setState({
        loading:true,
      })
      let payload = {
        projectId:this.state.projectId,
        attendeesGroupsList: recipients.recipientsType ==="attendeesGroups" ? recipients?.attendeesGroupsList: [],
        excludeCheckedInAttendees:recipients?.excludeCheckedInAttendees,
        recipientsType:recipients.recipientsType
      };
      this.props.getExcludeCount(payload)
    }

    if (this.isValid(this.state) && this.isRecipienstsValid()) {
      this.setState({
        openReviewModal: true,
      })
      
     
        if(recipients.recipientsType ==="audience" ){
          this.setState({
            loading:true,
          })
      this.props.getExcludeAttendeeCount({projectId:this.state.projectId,audienceIds:recipients.audienceGroupsList,excludeAttendees:recipients?.excludeAttendees})
      }
    }
  }

  handleCloseScheduleModal = () => {
    this.setState({
      openScheduleModal: false,
      excludeAttendeeCountValue:0,
      excludeCount:0
    })
  }

  handleOpenScheduleModal = () => {
    if (this.isValid(this.state) && this.isRecipienstsValid()) {
      let {recipients} = this.state
      if (recipients.recipientsType === 'audience') {
        this.setState({
          loading: true,
        })
        this.props.getExcludeAttendeeCount({
          projectId: this.state.projectId,
          audienceIds: recipients.audienceGroupsList,
          excludeAttendees: recipients?.excludeAttendees,
        })
      }

      if (
        recipients.recipientsType === 'attendeesGroups' ||
        recipients.recipientsType === 'attendees'
      ) {
        this.setState({
          loading: true,
        })
        let payload = {
          projectId: this.state.projectId,
          attendeesGroupsList:
            recipients.recipientsType === 'attendeesGroups'
              ? recipients?.attendeesGroupsList
              : [],
          excludeCheckedInAttendees: recipients?.excludeCheckedInAttendees,
          recipientsType: recipients.recipientsType,
        }
        this.props.getExcludeCount(payload)
      }
      this.setState({
        openScheduleModal: true,
        scheduledDate: this.props.campaigns?.campaignDetails[0]?.status === 'Scheduled' ? moment.tz(this.props.campaigns?.campaignDetails[0]?.scheduledAt, this.state.timezone).format("MM/DD/YYYY hh:mm a") : moment.tz(new Date(), this.state.timezone).format('MM/DD/YYYY hh:mm a'),
      })
    }
  }

  showRecipientsType = () => {
    const type = this.state.recipients.recipientsType
    switch (type) {
      case "audience":
        return "Audience"
        break;
      case "attendees":
        return "Attendees"
        break;
      case "attendeesGroups":
        return "Attendees Groups"
        break;
      case "searchAdd":
        return "Specific Selected Attendees"
        break;

      default:
        break;
    }
  }

  showNumberOfRecipients = () => {
    const type = this.state.recipients?.recipientsType
    switch (type) {
      case "audience":
        return this.state.recipients?.audienceCountNumber
        break;
      case "attendees":
        return this.state.recipients?.attendeesCountNumber
        break;
      case "attendeesGroups":
        return this.state.recipients?.attendeesGroupsCountNumber
        break;
      case "searchAdd":
        return this.state.recipients?.searchAddedAttendees?.length
        break;

      default:
        break;
    }
  }

  optionsList = (val) => {
    let temp;
    switch (val) {
      case 'Draft': temp = this.props.users.languageStateForRedux?.common?.draft;
      return temp;   

      case 'Scheduled': temp = this.props.users.languageStateForRedux?.common?.scheduled;
      return temp;

      case 'Sent': temp = this.props.users.languageStateForRedux?.common?.sent;
      return temp;

      default: temp = val;
      break;
    }
  }

  componentWillUnmount() {
    this.props.listCampaigns()
  } 

  isValidScheduleDate = () => {
    try {
      let formIsValid = true
      const error = {}
      const { scheduledDate } = this.state

      let currentTimeInSelectedTimeZOne = moment.tz(new Date(), this.state.timezone).format('MM/DD/YYYY hh:mm a')

      var currentTime = new Date(currentTimeInSelectedTimeZOne)
      currentTime.setMinutes(currentTime.getMinutes() + 2);

      let scheduledTime = new Date(scheduledDate)

      if (scheduledTime.getTime() <= currentTime.getTime()) {
        formIsValid = false;
        error['earlyScheduledTime'] = this.props.users.languageStateForRedux?.validations?.pleaseSelect;
      }
      this.setState({
        errors: error
      })
      return formIsValid

    } catch (error) {
      return error
    }
  }

  render() {
    const { campaignDetails, campaignName, editCampaignName, errors } = this.state
    const StyledTextField = styled(TextField)({
      '& .MuiOutlinedInput-input': {
        padding: '12.5px 14px',
      },
    });
    return (
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} mb={3} className="top-filter-area">
          <Grid item>
            <Grid container spacing={3} alignItems='center'>
              <Grid item>
                <Link to={{
                  pathname: `/project-dashboard/campaigns/${this.props.match.params.eventId}`,
                }}>
                  <Box component={'img'} mb={0.5} alt="" src={BackArrow} />
                </Link>
              </Grid>
              <Grid item>
                <Box
                  component={'span'}
                  className={`table-status ${campaignDetails.status === "Draft" ? "light" : campaignDetails.status === "Sent" ? "success" : "warning"}`}
                >
                  {this.optionsList(campaignDetails?.status)}
                </Box>
              </Grid>
              <Grid item>
                <Box component="div" >
                  {!editCampaignName
                    ?
                    <span className='subtitle'>
                      {campaignName}
                    </span>
                    :
                    <TextField type="text" variant='outlined' size='small'  inputProps={{ maxLength: 40 }} value={campaignName} name="campaignName" onChange={this.handleChange} placeholder="" />
                  }
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  {!editCampaignName
                    ?
                    <IconButton size='small' disableRipple onClick={this.handleOpenEditCampaignName}>
                      <img width={'18px'} alt="Edit" src={EditGroup} />
                    </IconButton>
                    :
                    <>
                      <IconButton size='small' disableRipple onClick={this.handleCloseEditCampaignName}><CheckIcon style={{ color: '#1b5e20' }} /></IconButton>
                      <IconButton size='small' disableRipple onClick={this.handleCloseEditCampaignNameWithoutSaving}><CloseIcon color='secondary' /></IconButton>
                    </>
                  }
                </Box>
              </Grid>
              
            </Grid>
            <Box component='p' mt={1} className="required small-subtitle"> {errors['campaignName']} </Box>
          </Grid>
          <Grid item>
            <Stack spacing={1} direction={{xs:"column", sm:"row"}}>
              <Button startIcon={<img alt="" src={ScheduleIcon} />} style={{ textTransform: 'capitalize' }} variant="text" disableElevation>
                {campaignDetails?.status === 'Scheduled' ? (this.props.users.languageStateForRedux?.formfields?.campaigns?.scheduledOn) +` ${moment.tz(campaignDetails?.scheduledAt, this.state.timezone).format("ddd, MMMM Do YYYY, h:mm A")}` : campaignDetails?.status === 'Draft' ? (this.props.users.languageStateForRedux?.formfields?.campaigns?.updatedOn)+ ` ${moment.tz(campaignDetails?.updatedAt, this.state.timezone).format("ddd, MMMM Do YYYY, h:mm A")}`
                  : (this.props.users.languageStateForRedux?.formfields?.campaigns?.sentOn) +` ${moment.tz(campaignDetails?.sentAt, this.state.timezone).format("dddd, MMMM Do YYYY, h:mm A")}`}
              </Button>
              <Button color='primary' variant="contained" disableElevation onClick={() => this.saveAllInfo("Draft")}>{this.props.users.languageStateForRedux?.common?.save}</Button>
              <Button color='primary' variant="outlined" disableElevation onClick={this.handleOpenScheduleModal}>{this.props.users.languageStateForRedux?.buttons?.campaigns?.schedule}</Button>
              <Button color='primary' variant="contained" disableElevation onClick={this.handleOpenReviewModal}>{this.props.users.languageStateForRedux?.buttons?.campaigns?.sendNow}</Button>
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={3} >
          <EditCampaignRecipients getDataFromEditRecipients={this.getDataFromEditRecipients} errorsFromEditCampaign={this.state.errors} isRecipienstsValid={this.isRecipienstsValid} />

          <EditCampaignContent getDataFromEditContent={this.getDataFromEditContent} campaignDetails={this.state.campaignDetails} errors={this.state.errors} />

          <EditCampaignEditor saveAllInfo={this.saveAllInfo} />
        </Grid>

        <Modal
          open={this.state.openScheduleModal} 
          // onClose={this.handleCloseScheduleModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal modal-default-close-none',
          }}
          center
        >
          <Grid component='form' container alignItems="center" justifyContent="center" className='modal-wrapper'>
            <Grid item xs={12} pb={3}>
              <Box textAlign="center" mb={4}>
                <img alt="" src={CheckedInOut} />
              </Box>
              <Box component="p" textAlign="center" mb={1} className='subtitle'>{this.props.users.languageStateForRedux?.formfields?.campaigns?.scheduleCamp}</Box>
            </Grid>
            <Box component={Grid} mb={5} item xs>
              <label className='paragraph'>{this.props.users.languageStateForRedux?.column_names?.attendees?.choose}<span className="required">*</span></label>
              <LocalizationProvider style={{ width: 100, }} dateAdapter={AdapterDateFns}>
                <MobileDateTimePicker
                  renderInput={(params) => <StyledTextField variant='outlined' fullWidth size='medium' {...params} />}
                  value={this.state.scheduledDate}
                  minDate={new Date()}
                  onChange={(newDate) => {
                    this.setState({
                      scheduledDate: newDate
                    })
                  }}
                  inputFormat="MMM, dd yyyy, hh:mm a" 
                />
              </LocalizationProvider>
              <Box component='p' mt={1} className="required small-subtitle"> {errors['earlyScheduledTime']} </Box>
            </Box>

            <Grid item xs={12}>
              <Grid spacing={2} container alignItems="center" justifyContent="center">
                <div>
                  <p>{this.props.users.languageStateForRedux?.formfields?.campaigns?.aboutToSend} <strong>
                    {this.showRecipientsType()}
                  </strong></p>
                  <p>{this.props.users.languageStateForRedux?.formfields?.campaigns?.sendCamp} <u>{this.state.recipients?.recipientsType  === "audience" ? this.state.excludeAttendeeCountValue: (this.state.recipients?.recipientsType  === "attendees" || this.state.recipients?.recipientsType  === "attendeesGroups")? this.state.excludeCount:this.showNumberOfRecipients()}</u> {this.props.users.languageStateForRedux?.formfields?.campaigns?.recipients}</p> </div>
                <Grid item xs={12}>
                  <Button fullWidth size='large' variant="contained" color="primary" disableElevation 
                  disabled={(this.state.recipients?.recipientsType  === "audience" ? this.state.excludeAttendeeCountValue: (this.state.recipients?.recipientsType  === "attendees" || this.state.recipients?.recipientsType  === "attendeesGroups")? this.state.excludeCount:this.showNumberOfRecipients()) === 0 ?true:false}
                  onClick={() => {
                    if (this.isValidScheduleDate()) {
                      this.setState({
                        status: 'scheduled'
                      }, () => this.saveAllInfo("Scheduled"))
                    }
                  }}>

                    {this.props.users.languageStateForRedux?.buttons?.campaigns?.schedule}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth size='large' variant="text" color="primary" disableElevation onClick={this.handleCloseScheduleModal}>
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <Modal
          open={this.state.openReviewModal} 
          // onClose={this.handleCloseReviewModal}
          classNames={{
            overlay: 'team_add-remove-people_overlay',
            modal: 'Invite-User-Modal send-notification-popup modal-default-close-none',
          }}
          center
        >
          <div className='modal-wrapper'>
            <div className='modal-header align-items-center'>
              <img alt="" src={ReviewNotification} />
              <Box component="p" mb={1} className="subtitle">{this.props.users.languageStateForRedux?.formfields?.campaigns?.reviewCamp}</Box>
              <p className="small-subtitle">{this.props.users.languageStateForRedux?.formfields?.campaigns?.aboutToSend} <strong>
                {this.showRecipientsType()}
              </strong></p>
              <p>{this.props.users.languageStateForRedux?.formfields?.campaigns?.sendCamp} <u>{this.state.recipients?.recipientsType  === "audience" ? this.state.excludeAttendeeCountValue: (this.state.recipients?.recipientsType  === "attendees" || this.state.recipients?.recipientsType  === "attendeesGroups")? this.state.excludeCount:this.showNumberOfRecipients()}</u> {this.props.users.languageStateForRedux?.formfields?.campaigns?.recipients}</p> </div>
             
            <div className='modal-body'>
              <form>
                <div class="row">
                  <div class="col-12">
                    <Button fullWidth variant="contained" color="primary" className='my-3' disableElevation
                  disabled={(this.state.recipients?.recipientsType  === "audience" ? this.state.excludeAttendeeCountValue: (this.state.recipients?.recipientsType  === "attendees" || this.state.recipients?.recipientsType  === "attendeesGroups")? this.state.excludeCount:this.showNumberOfRecipients()) === 0 ?true:false}
                     onClick={() => this.saveAllInfo('Sent')}>
                      {this.props.users.languageStateForRedux?.buttons?.campaigns?.sendNow}
                    </Button>
                    <Button fullWidth variant="outlined" className='' disableElevation onClick={this.handleCloseReviewModal}>
                    {this.props.users.languageStateForRedux?.common?.cancel}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>
        {this.state.loading ? <Loader /> : null}
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  campaigns: state.Campaigns,
  attendees: state.Attendees,
  projects: state.Projects,
  events: state.Events,
  users: state.Users,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getCampaign: Actions.getCampaignRequest,
      listAttendeesOfCampaigns: Actions.listAttendeesOfCampaignsRequest,
      listAttendeesNotAddedToCampaign: Actions.listAttendeesNotAddedToCampaignRequest,
      updateCampaign: Actions.updateCampaignRequest,
      getProjectRequest: Actions.getProjectRequest,
      listAttendees: Actions.listAttendeesRequest,
      handleCampaignNameInEditor: Actions.handleCampaignNameInEditor,
      getExcludeAttendeeCount:Actions.getExcludeAttendeeCountRequest,
      getExcludeCount:Actions.getExcludeCountRequest
    },
    dispatch,
  )

export default connect(mapStateToProps, mapActionsToProps)(withRouter(EditCampaign))
