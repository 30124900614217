import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import { Modal } from 'react-responsive-modal';
import Button from '@material-ui/core/Button';

class ConfirmationAlertPopUp extends Component {

    render() {
        return (
            <>
                <Modal
                    open={this.props.openModal}
                    onClose={this.props.handleCrossIconClick || this.props.closeModalFunc}
                    classNames={{
                        overlay: '',
                        modal: 'Invite-User-Modal',
                    }}
                    center
                >
                    
                    <Box component={Grid} container className="" px={1.5}>
                        <Box component={Grid} mt={-1} item xs={12} pb={3}>
                            <Box component="p" mb={1} className="subtitle">
                                {' '}
                                {this.props.title}
                            </Box>
                            <p className="small-subtitle">{this.props.text}</p>
                        </Box>
                        <Grid item xs={12}>
                            <Grid container justifyContent="end">
                                <Stack spacing={1} direction="row" fullWidth>
                                    {
                                        <>
                                            <Button
                                                variant="contained"
                                                color={this.props.confirmationButtonColor}
                                                disableElevation
                                                onClick={() => this.props.functionality()}
                                            >
                                                {this.props.confirmationButtonText}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                disableElevation
                                                onClick={this.props.closeModalFunc}
                                            >
                                                {this.props.closeButtonText}
                                            </Button>
                                        </>
                                    }
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </>
        );
    }
}

export default ConfirmationAlertPopUp;