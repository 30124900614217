import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
    updateChatbot: ["payload"],
    getAiAgentGeneralDataRequest: ['data'],
    getAiAgentGeneralDataSuccess: ['message', 'chatbot'],
    getAiAgentGeneralDataError: ['message'],
    createOrUpdateAiAgentGeneralDataRequest: ['data'],
    createOrUpdateAiAgentGeneralDataSuccess: ['message', 'chatbot', 'chatbots', 'totalChatBotCount'],
    createOrUpdateAiAgentGeneralDataError: ['message'],
    listAllChatbotsRequest: ['data'],
    listAllChatbotsSuccess: ['message', 'chatbots', 'totalChatBotCount'],
    listAllChatbotsError: ['message'],
    trainAgentRequest: ['data'],
    trainAgentSuccess: ['message'],
    trainAgentError: ['message'],
    deleteChatbotRequest: ['data'],
    deleteChatbotSuccess: ['message', 'chatbots', 'totalChatBotCount'],
    deleteChatbotError: ['message'],
    enableChatbotRequest: ['data'],
    enableChatbotSuccess: ['message', 'chatbots', 'totalChatBotCount'],
    enableChatbotError: ['message'],
    setChatbotAsDefaultRequest: ['data'],
    setChatbotAsDefaultSuccess: ['message', 'chatbots', 'totalChatBotCount'],
    setChatbotAsDefaultError: ['message'],
    setPageNo: ['page_no'],
    setLimit: ['limit'],
    setChatBotToInitialState: [],
    saveSettingsForAiChatbotRequest: ['data'],
    saveSettingsForAiChatbotSuccess: ['message', 'settings'],
    saveSettingsForAiChatbotError: ['message'],
    replicateChatWidgetRequest: ['data'],
    replicateChatWidgetSuccess: ['message', 'chatbots', 'totalChatBotCount'],
    replicateChatWidgetError: ['message'],
    resetChatBotState: [],
    setLoading: ['isLoading'],
});
export const Constants = Types;
export default Creators;