import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@material-ui/core/Button'
import { theme } from '../../../theme/theme'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import { Stack } from '@mui/material'
import SearchIcon from '../../../../src/assets/images/icons/search.svg'
import urlImg from '../../../assets/images/icons/url.svg'
import Rank_1 from '../../../assets/images/icons/Rank_1.svg'
import Pagination from 'react-js-pagination'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Avatar from '@material-ui/core/Avatar'
import Rank_3 from '../../../assets/images/icons/Rank_3.svg'
import Rank_2 from '../../../assets/images/icons/Rank_2.svg'
import profileUserIcon from '../../../assets/images/icons/profileUserIcon.svg'
import Loader from '../../../Components/Loader/loader'
import {
  configrationsChallenge,
  historyChallenge,
  leaderBoardList,
  getMyRankAPI,
} from '../../../Redux/API'
import moment from 'moment'
import BadgeGold from '../../../assets/images/icons/Badge_Gold.svg'
import BadgeSilver from '../../../assets/images/icons/Badge_Silver.svg'
import BadgeBronze from '../../../assets/images/icons/Badge_Bronze.svg'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} from '@material-ui/core'
import CustomTooltip from '../../../Components/CustomHTMLTooltip'
import infoFilledIcon from '../../../assets/images/icons/information-filled.svg'
const styles = {
  avatar: {
    backgroundColor: '#EDECF5',
    width: theme.spacing(3.75),
    height: theme.spacing(3.75),
    fontSize: 12,
    fontWeight: '900',
    color: '#726F8E',
  },
  ranksAvatar: {
    backgroundColor: '#EDEBFF',
    width: theme.spacing(9.35),
    height: theme.spacing(9.35),
  },
}
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const [eventDomain] = window.location.hostname.split('.')

const Gamification = ({ projectId, attendeeId, profilePic, attendee, attendeeImage }) => {
  const [challengeList, setchallengeList] = useState([])
  const [historyList, setHistoryList] = useState([])
  const [leaderList, setLeaderList] = useState([])
  const [myRanks, setMyRank] = useState([])
  const [topRank, setTopRank] = useState([])
  const [totalResultLeaderboard, setTotalLeaderboard] = useState([])
  const [totalResultHistory, setTotalHistory] = useState([])
  const [paginationActiveNo, setPaginationActiveNo] = useState(false)
  const [paginationChallengeActiveNo, setPaginationChallengeActiveNo] = useState(false)
  const [paginationLeaderActiveNo, setPaginationLeaderActiveNo] = useState(false)
  const [totalResultChallenges, setTotalChallenges] = useState([])
  const [sort_by, setSortBy] = useState('challengeName')
  const [challengeChange, setChallengeChange] = useState(true)
  const [historyChange, setHistoryChange] = useState(true)
  const [leaderChange, setLeaderChange] = useState(true)
  const [challengeDropDown, setDrop] = useState(0)

  const [search, setSearch] = useState('')
  const [itemCount, setShow] = useState(10)
  let [itemCountLeader, setItemCountLeader] = useState(10)
  const [itemCountHistory, setItemCountHistory] = useState(10)
  const [startCount, setStartCount] = useState(1)
  const [startCountleader, setStartCountLeader] = useState(1)
  const [startHistory, setStartCountHistory] = useState(1)
  const [endCounts, setEndCounts] = useState(0)
  const [endCountLeader, setEndCountLeader] = useState(0)
  const [endCountHistory, setEndCountHistory] = useState(0)

  const [pageNo, setPageNo] = useState(1)
  const [chalpageNo, setChalPageNo] = useState(1)
  const [limit, setLimit] = useState(10)
  const [LeaderNo, setLeaderPageNo] = useState(1)
  const [totalResult, setTotalResult] = useState(1)
  const [dir, setDir] = useState('asc')
  const [chal, setChal] = useState('asc')
  const [lead, setLead] = useState('asc')
  const [loader, setLoader] = useState(false)
  const [topData, setTopData] = useState([])
  const [myRank, setRank] = useState()
  const [sortby, setSortby] = useState('')
  const [sortData, setSortData] = useState()
  const [values, setValues] = useState({
    isFavorite: false,
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    openHistory: true,
    openChallenges: false,
    openLeaderboard: false,
  })

  const getHistory = (data1) => {
    const params = {
      eventDomain,
      projectId: projectId,
      attendeeId: attendeeId,
      searchText: '',
      page_no:  (data1 !== undefined && data1 !== '' && data1 !== null)? 1: pageNo,
      limit: parseInt((data1 !== undefined && data1 !== '' && data1 !== null)? data1: itemCountHistory),
      order: 'asc',
      sort_by: sort_by,
    }
    historyChallenge(params).then((historyData) => {
      setHistoryList(historyData.data)
      setTotalHistory(historyData?.data?.totalResults)
      setLoader(false)
      if (historyChange) {
        if (itemCountHistory < historyData?.data?.totalResults) {
          setEndCountHistory(itemCountHistory)
        } else {
          setEndCountHistory(historyData?.data?.totalResults)
        }
      }
      if (totalResultHistory < 1 * data1) {
        setStartCountHistory((1 - 1) * data1 + 1)
        setEndCountHistory(totalResultHistory)
      } else if (totalResultHistory > 1 * data1) {
        setStartCountHistory((1 - 1) * data1 + 1)
        setEndCountHistory(1 * data1)
      }
    })
  }

  const getConfigrationsChallenge = (data1) => {
    let params = ''
    params = {
      eventDomain,
      projectId: projectId,
      attendeeId: attendeeId,
      searchText: '',
      page_no:  (data1 !== undefined && data1 !== '' && data1 !== null)? 1: chalpageNo,
      limit: parseInt((data1 !== undefined && data1 !== '' && data1 !== null)? data1: itemCount),
      order: 'asc',
      sort_by: "challengeNameForSorting",
    }

    configrationsChallenge(params).then((data) => {
      setTotalChallenges(data?.data?.totalConfigrationChallenges)
      setchallengeList(data.data)
      setLoader(false)
      if (challengeChange) {
        if (itemCount < data.data.challengesList?.length) {
          setEndCounts(itemCount)
        } else {
          setEndCounts(data?.data?.challengesList?.length)
        }
      }

      if (data?.data?.totalConfigrationChallenges < 1 * data1) {
        setStartCount((1 - 1) * data1 + 1)
        setEndCounts(totalResultChallenges)
      } else if (totalResultChallenges > 1 * data1) {
        setStartCount((1 - 1) * data1 + 1)
        setEndCounts(1 * data1)
      }
    })
  }

  const getLeaderBoardList = (data1) => {
    const dataparams = {
      eventDomain,
      projectId: projectId,
      searchText: '',
      page_no:  (data1 !== undefined && data1 !== '' && data1 !== null)? 1: LeaderNo,
      limit: parseInt((data1 !== undefined && data1 !== '' && data1 !== null)? data1: itemCountLeader),
      order: 'asc',
      sort_by: 'rank',
      achievement: ['Gold', 'Silver', 'Bronze'],
    }

    leaderBoardList(dataparams).then((leader) => {
      const data = leader.data.leaderBoard.sort(
        (a, b) => b.totalPoints - a.totalPoints,
      )
      let topArr = []
      data.slice(0, 10).map((item) => {
        topArr.push(item)
      })
      setSortData(data)

      setTopData(topArr)
      setLeaderList(leader.data)
      setTotalLeaderboard(leader.data.totalResults)
      let rankData = leader.data.leaderBoard.filter(
        (data) => data.attendeeEmail == attendee,
      )
      setRank(rankData)
      setLoader(false)
      if (leaderChange) {
        if (itemCountLeader < leader?.data?.leaderBoard?.length) {
          setEndCountLeader(itemCountLeader)
        } else {
          setEndCountLeader(leader?.data?.leaderBoard?.length)
        }
      }
      if (totalResultLeaderboard < 1 * data1) {
        setStartCountLeader((1 - 1) * data1 + 1)
        setEndCountLeader(totalResultLeaderboard)
      } else if (totalResultLeaderboard > 1 * data1) {
        setStartCountLeader((1 - 1) * data1 + 1)
        setEndCountLeader(1 * data1)
      }
    })
  }

  useEffect(() => {
    getMyProfileRank()
    getHistory()
    getConfigrationsChallenge()
    getLeaderBoardList()
    setLoader(true)
  }, [])

  const handlePageChange = (pageNumber) => {
    if (values.openHistory) {
      setPaginationActiveNo(false)
      setHistoryChange(false)
      setPageNo(pageNumber)
      if (totalResultHistory < pageNumber * itemCountHistory) {
        setStartCountHistory((pageNumber - 1) * itemCountHistory + 1)
        setEndCountHistory(totalResultHistory)
      } else if (totalResultHistory > pageNumber * itemCountHistory) {
        setStartCountHistory((pageNumber - 1) * itemCountHistory + 1)
        setEndCountHistory(pageNumber * itemCountHistory)
      }
    }
  }

  const getMyProfileRank = () => {
    const requestBody = {
      eventDomain,
      projectId: projectId,
      limit: 10,
      order: 'asc',
      sort_by: 'rank',
      attendeeId: attendeeId,
      achievement: ['Gold', 'Silver', 'Bronze'],
    }
    getMyRankAPI(requestBody).then((rank) => {
      setMyRank(rank?.data?.myRank)
      setTopRank(rank?.data?.topRank)
    })
  }

  const handleChallengeChange = (pageNumber) => {
    if (values.openChallenges) {
      setPaginationChallengeActiveNo(false)
      setChallengeChange(false)
      setChalPageNo(pageNumber)
      if (totalResultChallenges < pageNumber * itemCount) {
        setStartCount((pageNumber - 1) * itemCount + 1)
        setEndCounts(totalResultChallenges)
      } else if (totalResultChallenges > pageNumber * itemCount) {
        setStartCount((pageNumber - 1) * itemCount + 1)

        setEndCounts(pageNumber * itemCount)
      }
    }
  }

  const handleLeaderChange = (pageNumber) => {
    if (values.openLeaderboard) {
      setPaginationLeaderActiveNo(false)
      setLeaderChange(false)
      setLeaderPageNo(pageNumber)
      if (totalResultLeaderboard < pageNumber * itemCountLeader) {
        setStartCountLeader((pageNumber - 1) * itemCountLeader + 1)
        setEndCountLeader(totalResultLeaderboard)
      } else if (totalResultLeaderboard > pageNumber * itemCountLeader) {
        setStartCountLeader((pageNumber - 1) * itemCountLeader + 1)
        setEndCountLeader(pageNumber * itemCountLeader)
      }
    }
  }

  useEffect(() => {
    if (values.openChallenges && 'active') {
      handleChallenges()
    }
  }, [chalpageNo])

  useEffect(() => {
    if (values.openLeaderboard && 'active') {
      handleOpenLeaderboard()
    }
  }, [LeaderNo])

  useEffect(() => {
    if (values.openHistory && 'active') {
      handleOpenHistory()
    }
  }, [pageNo])

  const handleOpenHistory = (data1) => {
    setChalPageNo(1)
    setLoader(true)
    setSearch('')
    setEndCounts(10)
    setStartCount(1)
    setStartCountLeader(1)
    setEndCountLeader(10)
    setLeaderPageNo(1)
    setItemCountLeader(10)
    setShow(10)
    setValues({
      ...values,
      openHistory: true,
      openChallenges: false,
      openLeaderboard: false,
    })
    getHistory(data1)
  }

  const historySort = (data) => {
    setSortby(data)
    if (dir === 'asc') {
      setDir('desc')
    } else {
      setDir('asc')
    }
    const params = {
      eventDomain,
      projectId: projectId,
      attendeeId: attendeeId,
      searchText: '',
      page_no: pageNo,
      limit: 10,
      order: dir,
      sort_by: data,
    }
    historyChallenge(params).then((historyData) => {
      setHistoryList(historyData?.data)
    })
  }

  const challengeSort = (data) => {
    setSortby(data)
    if (chal === 'asc') {
      setChal('desc')
    } else {
      setChal('asc')
    }
    const params = {
      eventDomain,
      projectId: projectId,
      attendeeId: attendeeId,
      searchText: '',
      page_no: chalpageNo,
      limit: 10,
      order: chal,
      sort_by: data,
      isProfile: true,
    }
    configrationsChallenge(params).then((data) => {
      setchallengeList(data.data)
    })
  }

  const leaderSort = (data) => {
    setSortby(data)
    if (lead === 'asc') {
      setLead('desc')
    } else {
      setLead('asc')
    }
    const dataparams = {
      eventDomain,
      projectId: projectId,
      searchText: '',
      page_no: LeaderNo,
      limit: parseInt(itemCountLeader),
      order: lead,
      sort_by: data,
      achievement: ['Gold', 'Silver', 'Bronze'],
    }

    leaderBoardList(dataparams).then((leader) => {
      setLeaderList(leader.data)
      setSortData(leader)
    })
  }
  const handleChallenges = (data1) => {
    setLoader(true)
    setSearch('')
    setStartCountLeader(1)
    setEndCountLeader(10)
    setLeaderPageNo(1)
    setStartCountHistory(1)
    setEndCountHistory(10)
    setPageNo(1)
    setItemCountHistory(10)
    setItemCountLeader(10)
    setValues({
      ...values,
      openHistory: false,
      openChallenges: true,
      openLeaderboard: false,
    })
    getConfigrationsChallenge(data1)
  }

  const handleOpenLeaderboard = (data1) => {
    setChalPageNo(1)
    setPageNo(1)
    setEndCounts(10)
    setStartCount(1)
    setLoader(true)
    setSearch('')
    setStartCountHistory(1)
    setEndCountHistory(10)
    setPageNo(1)
    setShow(10)
    setItemCountHistory(10)
    setValues({
      ...values,
      openHistory: false,
      openChallenges: false,
      openLeaderboard: true,
    })
    getLeaderBoardList(data1)
  }

  const returnBadge = (achievement) => {
    switch (achievement) {
      case 'Gold':
        return BadgeGold
        break
      case 'Silver':
        return BadgeSilver
        break
      case 'Bronze':
        return BadgeBronze
        break
      default:
        break
    }
  }

  const handleSearch = (e) => {
    setLoader(true)
    e.preventDefault()
    const params = {
      eventDomain,
      projectId: projectId,
      attendeeId: attendeeId,
      searchText: search.trim(),
      page_no: 1,
      limit: parseInt(itemCount),
      order: 'asc',
      sort_by: sort_by,
      isProfile: true,
    }
    const request = {
      eventDomain,
      projectId: projectId,
      attendeeId: attendeeId,
      searchText: search.trim(),
      page_no: 1,
      limit: parseInt(itemCountHistory),
      order: 'asc',
      sort_by: sort_by,
      isProfile: true,
      
    }
    if (values.openChallenges === true) {
      configrationsChallenge(params).then((data) => {
        setchallengeList(data.data)
        setEndCounts(data?.data?.challengesList?.length)
      })
      setLoader(false)
      setStartCount(1)
    } else if (values.openHistory === true) {
      historyChallenge(request).then((historyData) => {
        setHistoryList(historyData.data)
        setEndCountHistory(historyData?.data?.leaderboardUserHistory?.length)
      })
      setLoader(false)
      setStartCountHistory(1)
    } else {
      const Leaderparams = {
        eventDomain,
        projectId: projectId,
        searchText: search.trim(),
        page_no: 1,
        limit: parseInt(itemCountLeader),
        order: 'asc',
        achievement: ['Gold', 'Silver', 'Bronze'],
        sort_by: 'rank',
      }
      leaderBoardList(Leaderparams).then((leader) => {
        setLeaderList(leader.data)
        setEndCountLeader(leader?.data?.leaderBoard?.length)
      })
      setLoader(false)
      setStartCountLeader(1)
    }
  }

  const handleChange = (e) => {
    e.preventDefault()
    setChalPageNo(1)
    setPaginationChallengeActiveNo(true)
    setShow(e.target.value)
    if (values.openChallenges === true) {
      handleChallenges(e.target.value)
    }
  }

  const handleChangeLeader = (e) => {
    e.preventDefault()
    setLeaderPageNo(1)
    setPaginationLeaderActiveNo(true)
    setItemCountLeader(e.target.value)
    if (values.openLeaderboard === true) {
      handleOpenLeaderboard(e.target.value)
    }
  }

  const handleChangeHistory = (e) => {
    setPageNo(1)
    e.preventDefault()
    setPaginationActiveNo(true)
    setItemCountHistory(e.target.value)
    if (values.openHistory === true) {
      handleOpenHistory(e.target.value)
    }
  }

  return (
    <Box
      px={1}
      py={3}
      className="gamification"
      sx={{ border: '1px solid #EDEBFF', position: 'reletive' }}
    >
      {/* Top 10 ranks */}
      <Box pb={2.6} sx={{ borderBottom: '1px solid #EDEBFF' }}>
        <Grid container justifyContent={'center'} spacing={3}>
            <Grid item>
              <Box className="your-rank">
                <Box className="lable">Your Rank</Box>
                <Avatar style={styles.ranksAvatar}>
                  <img
                    alt=""
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                    src={
                      attendeeImage
                        ? IMAGE_URL + "/profilePictures/" + attendeeImage
                        : profileUserIcon
                      }
                      />
                </Avatar>
                <Box className="rank">{myRanks?.length && myRanks[0].rank ? myRanks[0].rank : "No Rank"}</Box>
                <Box className="score">
                  <Stack direction="row" spacing={0.5} alignItem="center">
                  {myRanks?.length && myRanks[0].achievement &&
                    <img
                      alt=""
                      width="16"
                      src={returnBadge(myRanks[0].achievement)}
                    />}
                    <Box className="small-subtitle secondary-text">
                      {myRanks?.length && myRanks[0].totalPoints ? myRanks[0].totalPoints : 0}
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Grid>

          <Grid item xs={12} sm className="top-ranks-wrapper">
            {topData.length > 0 && (
              <Box px={2} className="top-ranks">
                <Box className="lable">Top 10 Rank</Box>
                <Grid
                  container
                  justifyContent={'start'}
                  alignItems={'center'}
                  direction="row"
                  style={{ flexWrap: 'nowrap', overflow: 'scroll' }}
                  spacing={2}
                >
                  {topRank && topRank.length > 0 &&
                    topRank.map((data, id) => (
                      <Grid key={id} item>
                        <Box
                          display={'flex'}
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Avatar style={styles.ranksAvatar}>
                            <img
                              alt=""
                              style={{ maxWidth: '100%', maxHeight: '100%' }}
                              src={
                                data.profileImage
                                  ? IMAGE_URL + "/profilePictures/" + data.profileImage
                                  : profileUserIcon
                              }
                            />
                          </Avatar>
                          <Box className="rank">{data.rank}</Box>
                          <Box className="score">
                            <Box className="small-subtitle secondary-text">
                              {data.totalPoints}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      {/* Top 10 ranks end*/}

      {/* Tables*/}
      <Box>
        <Box className="subtitle" mb={3} mt={2}>
          {values.openHistory && 'History'}
          {values.openChallenges && 'Challenges List'}
          {values.openLeaderboard && 'Leaderboard'}
        </Box>
        <Grid container spacing={2} mb={1.5} className="top-filter-area">
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Box
                  component={Button}
                  variant="outlined"
                  sx={{
                    color: theme.palette.common.black,
                    borderColor: theme.palette.primary.dark,
                    textTransform: 'capitalize',
                    fontWeight: '400',

                    '&:hover, &.active': {
                      backgroundColor: '#000056 !important',
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  className={values.openHistory && 'active'}
                  size="small"
                  onClick={handleOpenHistory}
                >
                  History ({totalResultHistory && totalResultHistory})
                </Box>
              </Grid>
              <Grid item>
                <Box
                  component={Button}
                  variant="outlined"
                  sx={{
                    borderColor: theme.palette.primary.dark,
                    color: theme.palette.primary.dark,
                    textTransform: 'capitalize',
                    fontWeight: '400',

                    '&:hover, &.active': {
                      backgroundColor: '#000056 !important',
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  size="small"
                  className={values.openChallenges && 'active'}
                  onClick={handleChallenges}
                >
                  Challenges ({totalResultChallenges && totalResultChallenges})
                </Box>
              </Grid>
              <Grid item>
                <Box
                  component={Button}
                  variant="outlined"
                  sx={{
                    borderColor: theme.palette.primary.dark,
                    color: theme.palette.primary.dark,
                    textTransform: 'capitalize',
                    fontWeight: '400',

                    '&:hover, &.active': {
                      backgroundColor: '#000056 !important',
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  className={values.openLeaderboard && 'active'}
                  size="small"
                  onClick={handleOpenLeaderboard}
                >
                  Leaderboard (
                  {totalResultLeaderboard && totalResultLeaderboard})
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1.25} className="team-dash-right">
              <Grid item className="">
                <form onSubmit={handleSearch}>
                  <TextField
                    name="search"
                    placeholder="Search..."
                    className="search"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img alt="" src={SearchIcon} />
                        </InputAdornment>
                      ),
                    }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* My Earned History */}
        {values.openHistory && (
          <TableContainer className="list-table">
            {/* For Desktop */}

            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      onClick={() => historySort('challengeName')}
                      className={
                        'long-text ' +
                        (sortby === 'challengeName'
                          ? dir === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                    >
                      Challenges
                    </TableCell>
                    <TableCell
                      onClick={() => historySort('source')}
                      className={
                        'long-text ' +
                        (sortby === 'source'
                          ? dir === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                    >
                      Source
                    </TableCell>
                    <TableCell
                      onClick={() => historySort('points')}
                      className={
                        'text ' +
                        (sortby === 'points'
                          ? dir === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                    >
                      Points
                    </TableCell>
                    <TableCell
                      onClick={() => historySort('challengeName')}
                      className={
                        'date-time ' +
                        (sortby === 'challengeName'
                          ? dir === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                    >
                      Completed on
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyList?.leaderboardUserHistory?.length > 0 ? (
                    historyList?.leaderboardUserHistory?.map((history) => (
                      <TableRow>
                        <TableCell className="long-text bold">
                          {history?.challengeName ? history.challengeName: "--"}
                        </TableCell>
                        <TableCell className="long-text">                                    
                                    <Stack direction={'row'}>
                                      <Box mr={1.25}>{history?.source === 'Page' ? history?.pageTitle: history.source}</Box>
                                      {history.source === 'Manual' &&
                                        <Box>
                                          <CustomTooltip
                                            placement={'bottom-start'}
                                            title={
                                              <Box className="manual-note">
                                                {history.note && 
                                                <Box className='note'>
                                                <Box className="small-subtitle primary-text bold">
                                                  Note:
                                                </Box>
                                                <Box className="small-subtitle primary-text">
                                                  {history.note}
                                                </Box>
                                                </Box>
                                                 }

                                                <Box className="small-subtitle added-by">
                                                  Added By: &nbsp;
                                                  <span style={{ color: '#000000', }}>
                                                    {history.userName
                                                        ? history.userName
                                                        : '--'}
                                                  </span>
                                                </Box>
                                              </Box>
                                            }
                                          >
                                            <img alt="" src={infoFilledIcon} />
                                          </CustomTooltip>
                                        </Box>
                                      }
                                    </Stack>                                      
                        </TableCell>
                        <TableCell className="text">
                          {history.points ? history.points : '--'}
                        </TableCell>
                        <TableCell className="date-time">
                        {history.createdAt ? moment(history.createdAt)
                        .format('ddd, MMM, Do YYYY, h:mm A',)
                        : '--'}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }}>
                        There are no records to display
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>

            {/* For mobile */}

            <Box sx={{ display: { md: 'none', xs: 'block' } }}>
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      onClick={() => historySort('challengeName')}
                      className={
                        'long-text ' +
                        (sortby === 'challengeName'
                          ? dir === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                    >
                      Challenges
                    </TableCell>
                    <TableCell
                      onClick={() => historySort('points')}
                      className={
                        'text ' +
                        (sortby === 'points'
                          ? dir === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      style={{
                        minWidth: '80px',
                        maxWidth: '80px',
                      }}
                    >
                      Points
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyList?.leaderboardUserHistory?.length > 0 ? (
                    historyList?.leaderboardUserHistory?.map((history) => (
                      <TableRow>
                        <TableCell style={{ paddingTop: '8px', paddingBottom: '8px' }} className="long-text">
                          <Stack>
                            <Box className='bold'>{history.challengeName}</Box>
                            <Box>

                              {history.userName ? (
                                <Box
                                  pt={0.5}
                                  sx={{ color: theme.palette.primary.dark }}
                                >
                                  Source: Manually
                                </Box>
                              ) : (
                                <Stack
                                  component={'a'}
                                  href="#"
                                  alignItems={'center'}
                                  direction={'row'}
                                  spacing={1.6}
                                >
                                  <Box>Source: </Box>
                                  <Box
                                    pt={0.5}
                                    sx={{ color: theme.palette.primary.dark }}
                                  >
                                    {history.PageName}
                                  </Box>
                                  <Box>
                                    <img alt="url-img" src={urlImg} />
                                  </Box>
                                </Stack>
                              )}
                            </Box>
                            <Box>
                              {history.updatedAt
                                ? moment(history.updatedAt).format(
                                  'ddd, MMM, Do YYYY, h:mm A',
                                )
                                : '--'}
                            </Box>

                          </Stack>
                        </TableCell>

                        <TableCell
                          className="text"
                          style={{
                            minWidth: '80px',
                            maxWidth: '80px',
                          }}
                        >
                          {history.points ? history.points : '--'}
                        </TableCell>

                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }}>
                        There are no records to display
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
        )}
        {/* My Earned History end */}

        {/* Challenges List */}
        {values.openChallenges && (
          <TableContainer className="list-table">
            {/* For Desktop */}

            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      onClick={() => challengeSort('challengeNameForSorting')}
                      className={
                        'long-text ' +
                        (sortby === 'challengeNameForSorting'
                          ? chal === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                    >
                      Challenges
                    </TableCell>
                    <TableCell
                      onClick={() => challengeSort('points')}
                      className={
                        'text ' +
                        (sortby === 'points'
                          ? chal === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                    >
                      Points
                    </TableCell>
                    <TableCell className="date-time">Created on</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {challengeList?.challengesList?.length > 0 ? (
                    challengeList?.challengesList?.map((challengeData) => (
                      <>
                        <TableRow key={challengeData._id}>
                          <TableCell className="long-text bold">
                            {challengeData.challengeName}
                          </TableCell>
                          <TableCell className="text">
                            {challengeData.points ? challengeData.points : '--'}
                          </TableCell>
                          <TableCell className="date-time">
                            {moment(challengeData.updatedAt).format(
                              'ddd, MMM, Do YYYY, h:mm A',
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }}>
                        There are no records to display
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
            {/* For mobile */}

            <Box sx={{ display: { md: 'none', xs: 'block' } }}>
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      onClick={() => challengeSort('challengeName')}
                      className={
                        'long-text ' +
                        (sortby === 'challengeName'
                          ? chal === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                    >
                      Challenges
                    </TableCell>
                    <TableCell
                      onClick={() => challengeSort('points')}
                      className={
                        'text ' +
                        (sortby === 'points'
                          ? dir === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      style={{
                        minWidth: '80px',
                        maxWidth: '80px',
                      }}
                    >
                      Points
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {challengeList?.challengesList?.length > 0 ? (
                    challengeList?.challengesList?.map((challengeData) => (
                    <TableRow key={challengeData._id}>
                        <TableCell style={{ paddingTop: '8px', paddingBottom: '8px' }} className="long-text ">
                          <Stack>
                            <Box className='bold'>{challengeData.challengeName}</Box>
                            <Box>

                            {challengeData.pageName ? (
                              <Stack
                                component={'a'}
                                href={`${window.location.origin
                                  }/${challengeData.path.toLowerCase()}`}
                                alignItems={'center'}
                                direction={'row'}
                                spacing={1.6}
                              >
                                <Box
                                  pt={0.5}
                                  sx={{ color: theme.palette.primary.dark }}
                                >
                                 Source: {challengeData.pageName}
                                </Box>
                                <Box>
                                  <img alt="url-img" src={urlImg} />
                                </Box>
                              </Stack>
                            ) : (
                             'Source: --'
                            )}
                            </Box>
                            <Box>
                              {challengeData.updatedAt
                                ? moment(challengeData.updatedAt).format(
                                  'ddd, MMM, Do YYYY, h:mm A',
                                )
                                : '--'}
                            </Box>

                          </Stack>
                        </TableCell>

                        <TableCell
                          className="text"
                          style={{
                            minWidth: '80px',
                            maxWidth: '80px',
                          }}
                        >
                          {challengeData.points ? challengeData.points : '--'}
                        </TableCell>

                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }}>
                        There are no records to display
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
        )}

        {/* Challenges List end */}

        {/* Leaderboard */}
        {values.openLeaderboard && (
          <TableContainer className="list-table">
            {/* For desktop */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      onClick={() => leaderSort('rank')}
                      className={
                        sortby === 'rank'
                          ? lead === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : ''
                      }
                      style={{
                        maxWidth: '70px',
                        minWidth: '70px',
                      }}
                    >
                      Rank
                    </TableCell>
                    <TableCell
                      onClick={() => leaderSort('attendeeEmail')}
                      className={
                        sortby === 'attendeeEmail'
                          ? lead === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : ''
                      }
                    >
                      Email Address
                    </TableCell>
                    <TableCell
                      onClick={() => leaderSort('totalPoints')}
                      className={
                        sortby === 'totalPoints'
                          ? lead === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : ''
                      }
                      style={{
                        maxWidth: '140px',
                        minWidth: '140px',
                      }}
                    >
                      Points
                    </TableCell>
                    <TableCell
                      onClick={() => leaderSort('totalPoints')}
                      className="text"
                      style={{
                        maxWidth: '140px',
                        minWidth: '140px',
                      }}
                    >
                      Achievement
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaderList?.leaderBoard?.length > 0 ? (
                    leaderList?.leaderBoard.map((leader) => (
                      <TableRow>
                        <TableCell
                          className="text"
                          style={{
                            maxWidth: '70px',
                            minWidth: '70px',
                          }}
                        >
                          <Avatar style={styles.avatar}>{leader.rank}</Avatar>
                        </TableCell>
                        <TableCell className="email">
                          {leader.attendeeEmail}
                        </TableCell>
                        <TableCell
                          className="text"
                          style={{
                            maxWidth: '140px',
                            minWidth: '140px',
                          }}
                        >
                          {leader.totalPoints ? leader.totalPoints : '--'}
                        </TableCell>
                        <TableCell
                          className="text"
                          style={{
                            maxWidth: '140px',
                            minWidth: '140px',
                          }}
                        >
                          <Box
                            style={{
                              maxWidth: '32px',
                              minWidth: '32px',
                            }}
                            component={'img'}
                            mt={0}
                            mr={1}
                            alt="icon"
                            src={returnBadge(leader.achievement)}
                          />
                          {leader.achievement}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }}>
                        There are no records to display
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>

            {/* For mobile */}

            <Box sx={{ display: { md: 'none', xs: 'block' } }}>
              <Table className="table">
                <TableBody>
                  {leaderList?.leaderBoard?.length > 0 ? (
                    leaderList?.leaderBoard.map((leader) => (
                      <TableRow>
                        <TableCell
                          className="text"
                          style={{
                            maxWidth: '45px',
                            minWidth: '45px',
                          }}
                        >
                          <Avatar style={styles.avatar}>{leader.rank}</Avatar>
                        </TableCell>
                        <TableCell className="email"
                          style={{
                            minWidth: '100px',
                          }}
                        >
                          <Stack>
                            <Box>{leader.attendeeEmail}</Box>
                            <Box>Points: {leader.totalPoints ? leader.totalPoints : '--'}</Box>
                          </Stack>
                        </TableCell>
                        <TableCell
                          className="text"
                          style={{
                            maxWidth: '80px',
                            minWidth: '80px',
                            padding: '8px',
                          }}
                        >
                          <Stack alignItems={'center'}>
                            <Box
                              style={{
                                maxWidth: '22px',
                                minWidth: '22px',
                              }}
                              component={'img'}
                              mt={0}
                              alt="icon"
                              src={returnBadge(leader.achievement)}
                            />
                            {leader.achievement}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }}>
                        There are no records to display
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
        )}
        {/* Leaderboard end */}
        {/* pagination */}
        {values.openChallenges && challengeList?.challengesList?.length > 0 ? (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="tablePagination"
          >
            <Grid item>
              <Box component="span" className="small-subtitle">
                {values.openHistory === true
                  ? `Showing ${startHistory} to ${endCountHistory} of ${' '}
                                    ${historyList?.totalResults}`
                  : values.openChallenges === true
                    ? `Showing ${startCount} to ${endCounts} of ${' '}
                                    ${challengeList?.totalConfigrationChallenges
                    }`
                    : values.openLeaderboard === true
                      ? `Showing ${startCountleader} to ${endCountLeader} of ${' '}
                                    ${leaderList?.totalResults}`
                      : ''}
              </Box>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <span className="small-subtitle">Shows</span>
                  <select
                    value={itemCount}
                    onChange={(e) => {
                      handleChange(e)
                      setDrop(1)
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  <span className="small-subtitle">Entries</span>
                </Grid>
                <Grid item>
                  <Pagination
                    activePage={paginationChallengeActiveNo  === true ? (itemCount >=10*chalpageNo ? 1 :chalpageNo ) : (itemCount >10*chalpageNo ? 1 :chalpageNo )}
                    itemsCountPerPage={itemCount}
                    totalItemsCount={
                      values.openChallenges === true
                        ? challengeList?.totalConfigrationChallenges
                        : values.openLeaderboard === true
                          ? leaderList?.totalResults
                          : values.openHistory === true
                            ? historyList?.totalResults
                            : ''
                    }
                    pageRangeDisplayed={5}
                    onChange={handleChallengeChange}
                    prevPageText={
                      <NavigateBeforeIcon style={{ fontSize: 18 }} />
                    }
                    nextPageText={<NavigateNextIcon style={{ fontSize: 18 }} />}
                    itemClassFirst="first d-none"
                    itemClassLast="last d-none"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : values.openHistory &&
          historyList?.leaderboardUserHistory?.length > 0 ? (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="tablePagination"
          >
            <Grid item>
              <Box component="span" className="small-subtitle">
                {values.openHistory === true
                  ? `Showing ${startHistory} to ${endCountHistory} of ${' '}
                                ${historyList.totalResults}`
                  : values.openChallenges === true
                    ? `Showing ${startCount} to ${endCounts} of ${' '}
                                ${challengeList.totalConfigrationChallenges}`
                    : values.openLeaderboard === true
                      ? `Showing ${startCountleader} to ${endCountLeader} of ${' '}
                                ${leaderList.totalResults}`
                      : ''}
              </Box>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <span className="small-subtitle">Shows</span>
                  <select
                    value={itemCountHistory}
                    onChange={(e) => handleChangeHistory(e)}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  <span className="small-subtitle">Entries</span>
                </Grid>
                <Grid item>
                  <Pagination
                    activePage={paginationActiveNo  === true ? (itemCountHistory >=10*pageNo? 1 :pageNo ) : (itemCountHistory >10*pageNo ? 1 :pageNo )}
                    itemsCountPerPage={itemCountHistory}
                    totalItemsCount={
                      values.openChallenges === true
                        ? challengeList?.totalConfigrationChallenges
                        : values.openLeaderboard === true
                          ? leaderList?.totalResults
                          : values.openHistory === true
                            ? historyList?.totalResults
                            : ''
                    }
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={
                      <NavigateBeforeIcon style={{ fontSize: 18 }} />
                    }
                    nextPageText={<NavigateNextIcon style={{ fontSize: 18 }} />}
                    itemClassFirst="first d-none"
                    itemClassLast="last d-none"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : values.openLeaderboard && leaderList?.leaderBoard?.length > 0 ? (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="tablePagination"
          >
            <Grid item>
              <Box component="span" className="small-subtitle">
                {values.openHistory === true
                  ? `Showing ${startHistory} to ${endCountHistory} of ${' '}
                            ${historyList.totalResults}`
                  : values.openChallenges === true
                    ? `Showing ${startCount} to ${endCounts} of ${' '}
                            ${challengeList.totalConfigrationChallenges}`
                    : values.openLeaderboard === true
                      ? `Showing ${startCountleader} to ${endCountLeader} of ${' '}
                            ${leaderList.totalResults}`
                      : ''}
              </Box>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <span className="small-subtitle">Shows</span>
                  <select
                    value={itemCountLeader}
                    onChange={(e) => handleChangeLeader(e)}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  <span className="small-subtitle">Entries</span>
                </Grid>
                <Grid item>
                  <Pagination
                    activePage={paginationLeaderActiveNo  === true ? (itemCountLeader >=10*LeaderNo ? 1 :LeaderNo ) : (itemCountLeader >10*LeaderNo ? 1 :LeaderNo )}
                    itemsCountPerPage={itemCountLeader}
                    totalItemsCount={
                      values.openChallenges === true
                        ? challengeList?.totalConfigrationChallenges
                        : values.openLeaderboard === true
                          ? leaderList?.totalResults
                          : values.openHistory === true
                            ? historyList?.totalResults
                            : ''
                    }
                    pageRangeDisplayed={5}
                    onChange={handleLeaderChange}
                    prevPageText={
                      <NavigateBeforeIcon style={{ fontSize: 18 }} />
                    }
                    nextPageText={<NavigateNextIcon style={{ fontSize: 18 }} />}
                    itemClassFirst="first d-none"
                    itemClassLast="last d-none"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </Box>
      {/* table end*/}
      {loader ? <Loader /> : null}
    </Box>
  )
}

export default Gamification
