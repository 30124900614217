import React, { Component } from 'react'
import '../../sass/main.scss'
import DashboardLayout from '../../Layouts/DashboardLayout'
import userProfilePic from '../../assets/images/MaskGroup2.png'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import Team from './team'

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Questionvalue: 0,
      Tabvalue: 0,
      theme: '',
      ProfileImage: process.env.REACT_APP_IMAGE_URL + 'user.png',
      firstName: '',
      lastName: '',
      email: '',
      errors: {},
      loading: false,
      profilePic: '',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      emailVerify: '',
      title: 'Profile',
      pageTitle: 'Profile',
      role: 1,
      isBoxesVisible: false,
    }
  }

  handleOpenMember = () => {
    this.setState((prevState) => ({
      isBoxesVisible: !prevState.isBoxesVisible,
    }))
  }
  handleCloseMember = () => {
    this.setState({ isBoxesVisible: false })
  }

  componentDidMount() { 
    var storage = localStorage.getItem('user_details')
    if (storage && storage !== 'undefined') {
      const user = JSON.parse(storage) 
      this.setState({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.role,
        emailVerify: user.emailVerify ? user.emailVerify : false,
        ProfileImage: process.env.REACT_APP_IMAGE_URL + user.profilePic,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) { 
    if (this.props.profile) {
      if (
        this.props.profile.success === true &&
        this.props.profile.message !== prevProps.profile.message
      ) {
        createNotification('success', this.props.profile.message) 
        let st = prevState.loading ? this.setState({ loading: false }) : null
        this.setState({
          currentPassword: '',
          confirmPassword: '',
          newPassword: '',
        })
        window.location.reload()
      }
      if (
        this.props.profile.accountSuccess === true &&
        this.props.profile.message !== prevProps.profile.message
      ) {
        createNotification('success', this.props.profile.message) 
        let st = prevState.loading ? this.setState({ loading: false }) : null

        let store = ['user_role', 'user_details']
        store.forEach((item) => localStorage.removeItem(item))
        createNotification('success', 'You logged out successfully')
        window.location.href = '/'
      }
      if (
        this.props.profile.error &&
        this.props.profile.error !== prevProps.profile.error
      ) {
        createNotification('error', this.props.profile.message, '')
        this.handleLoading()
      }
    }
  }

  handleChange = (event, newValue) => { 
    this.setState({ Tabvalue: newValue })
  }

  handleChangeValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleChangeIndex = (index) => {  
    this.setState({ Tabvalue: index })
  }

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader()
      const imageFile = event.target.files[0]

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        createNotification('error', 'Please select valid image.') 
        return false
      }

      reader.onload = (e) => {
        this.setState({ ProfileImage: e.target.result })
      }
      this.setState({ profilePic: event.target.files[0] })
      reader.readAsDataURL(event.target.files[0])
    }
  }

  isValid = () => { 
    const { email, firstName, lastName } = this.state
    let error = {}
    let formIsValid = true 
    const regexTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (email === '' || email.trim().length === 0) {
      formIsValid = false
      error['email'] = '*Please enter email.'
    }
    if (email && regexTest.test(email) === false) { 
      formIsValid = false
      error['email'] = '*Please enter a valid email address.'
    }
    if (firstName === '' || firstName.trim().length === 0) {
      formIsValid = false
      error['firstName'] = '*Please enter first name.'
    }
    if (lastName === '' || lastName.trim().length === 0) {
      formIsValid = false
      error['lastName'] = '*Please enter last name.'
    }
    this.setState({ errors: error })
    return formIsValid
  }

  isPasswordValid = () => { 
    const { currentPassword, newPassword, confirmPassword } = this.state
    let error = {}
    let formIsValid = true
    const regexTest = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/

    if (currentPassword === '' || currentPassword.trim().length === 0) {
      formIsValid = false
      error['currentPassword'] = '*Please enter current password.'
    }
    if (newPassword === '' || newPassword.trim().length === 0) {
      formIsValid = false
      error['newPassword'] = '*Please enter new password.'
    } 
    if (confirmPassword === '' || confirmPassword.trim().length === 0) {
      formIsValid = false
      error['confirmPassword'] = '*Please enter confirm password.'
    }

    if (newPassword !== confirmPassword) {
      formIsValid = false
      error['confirmPassword'] =
        '*password and confirm password is not matched.'
    }

    this.setState({ errors: error })
    return formIsValid
  }

  fileChangedHandler = (event) => {
    this.setState({ profilePic: event.target.files[0] })
  }

  handleProfileUpdate = () => { 
    if (this.isValid()) {
      const { firstName, lastName, email, profilePic } = this.state
      const formData = new FormData()

      formData.append('firstName', firstName)
      formData.append('lastName', lastName)
      formData.append('email', email)
      formData.append('profilePic', profilePic)  
      this.props.updateProfile(formData)
      this.handleLoading()  
    }
  }

  handlePasswordUpdate = () => { 
    if (this.isPasswordValid()) {
      const { currentPassword, newPassword } = this.state
      const requestbody = { currentPassword, newPassword }

      this.props.updateProfile(requestbody)
      this.handleLoading()
      // this.props.startLoading();
    }
  }

  emailVerify = () => {
    const { email } = this.state
    this.props.emailVerify({ email })
    this.handleLoading()
  }

  deleteAccount = () => {
    this.props.deleteAccount({})
    this.handleLoading()
  }

  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  render() { 
    const {
      Tabvalue,
      isBoxesVisible,
      ProfileImage,
      firstName,
      lastName,
      email,
      errors,
      loading,
      currentPassword,
      newPassword,
      confirmPassword,
      emailVerify,
      title,
      pageTitle,
      role,
    } = this.state

    return (
      <DashboardLayout title={title} pageTitle={pageTitle}>
        <div className="dashboardMiddleArea pt-0">
          <div className="profilePageContent">
            <div className="profileTabTeam">
              <div className="illumeetProfile">
                <div className="illuProfileContent">
                  <Tabs>
                    <div className="illuProfileButton">
                      <TabList>
                        <Tab>Edit Profile</Tab>
                        <Tab>Update Password</Tab>
                        <Tab>Manage Account</Tab>
                        {role === 1 ? <Tab>Manage Team</Tab> : null}
                      </TabList>
                    </div>
                    <div className="illuProfileBox">
                      <TabPanel>
                        <div className="profileBox editProfileColl">
                          <div className="editProfieImg">
                            <div className="editProfieInner">
                              <div className="profileImgBox">
                                <img src={ProfileImage} alt="" />
                                {/* <div className="domyImageBox"><p>Add Photo</p></div> */}
                              </div>
                              <div className="profileEditButton">
                                <input
                                  type="file"
                                  id="profileImg"
                                  name="filename"
                                  onChange={this.onImageChange}
                                  accept="image/*"
                                />
                                <span className="buttonText">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17.602"
                                    height="17.584"
                                    viewBox="0 0 17.602 17.584"
                                  >
                                    <g
                                      id="pencil"
                                      transform="translate(0 -0.246)"
                                    >
                                      <path
                                        id="Path_934"
                                        data-name="Path 934"
                                        d="M10.87,82.473,1.185,92.158a.386.386,0,0,0-.1.177L.011,96.644a.381.381,0,0,0,.37.474.379.379,0,0,0,.092-.011l4.309-1.074a.381.381,0,0,0,.177-.1l9.686-9.685Zm0,0"
                                        transform="translate(0 -79.287)"
                                        fill="#fff"
                                      ></path>
                                      <path
                                        id="Path_935"
                                        data-name="Path 935"
                                        d="M339.423,1.865,338.345.787a1.952,1.952,0,0,0-2.7,0l-1.321,1.321L338.1,5.881l1.321-1.321a1.907,1.907,0,0,0,0-2.7Zm0,0"
                                        transform="translate(-322.379)"
                                        fill="#fff"
                                      ></path>
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="profileEditForm formSection">
                            <form>
                              <div className="field-group inputField">
                                <label>First Name</label>
                                <input
                                  type="text"
                                  name="firstName"
                                  className="form-control"
                                  value={firstName}
                                  onChange={this.handleChangeValue}
                                />
                                <p className="error_mesage">
                                  {' '}
                                  {errors.firstName}{' '}
                                </p>
                              </div>
                              <div className="field-group inputField">
                                <label>Last Name</label>
                                <input
                                  type="text"
                                  name="lastName"
                                  className="form-control"
                                  placeholder="Martin"
                                  value={lastName}
                                  onChange={this.handleChangeValue}
                                />
                                <p className="error_mesage">
                                  {' '}
                                  {errors.lastName}{' '}
                                </p>
                              </div>
                              <div className="field-group inputField fullWidthInput">
                                <label>Work Email</label>
                                <input
                                  type="text"
                                  name="email"
                                  className="form-control emailLoweCase"
                                  placeholder="Cristinamartin@example.com"
                                  value={email}
                                  onChange={this.handleChangeValue}
                                  readOnly
                                />
                                <p className="error_mesage"> {errors.email} </p>
                              </div>
                              <div className="editProfileBtn submitForm">
                                <div className="field-group-flex text-center">
                                  <div className="field-group inputField">
                                    <Button
                                      type="button"
                                      className="themeBlueButton fullWidth"
                                      onClick={this.handleProfileUpdate}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="formSection updatePasswordCol pt-0">
                          <form>
                            <div className="field-group inputField fullWidthInput">
                              <label>Current Password</label>
                              <input
                                type="password"
                                name="currentPassword"
                                className="form-control"
                                value={currentPassword}
                                onChange={this.handleChangeValue}
                              />
                              <p className="error_mesage">
                                {' '}
                                {errors.currentPassword}{' '}
                              </p>
                            </div>
                            <div className="field-group inputField fullWidthInput">
                              <label>New Password</label>
                              <input
                                type="password"
                                name="newPassword"
                                className="form-control"
                                value={newPassword}
                                onChange={this.handleChangeValue}
                              />
                              <p className="error_mesage">
                                {' '}
                                {errors.newPassword}{' '}
                              </p>
                            </div>
                            <div className="field-group inputField fullWidthInput">
                              <label>Confirm Password</label>
                              <input
                                type="password"
                                name="confirmPassword"
                                className="form-control"
                                value={confirmPassword}
                                onChange={this.handleChangeValue}
                              />
                              <p className="error_mesage">
                                {' '}
                                {errors.confirmPassword}{' '}
                              </p>
                            </div>
                            <div className="editProfileBtn submitForm">
                              <div className="field-group-flex text-center">
                                <div className="field-group inputField">
                                  <Button
                                    type="button"
                                    className="themeBlueButton fullWidth"
                                    onClick={this.handlePasswordUpdate}
                                  >
                                    Save
                                  </Button>
                                </div>
                              </div>
                            </div>
                            {/** 
                                            <div className="formRestLink">
                                                <div className="form-group inputField">
                                                    <p>Having Trouble resetting your password? <a href="#">Rest your password via mail here</a> </p>
                                                </div>
                                               </div>
                                           */}
                          </form>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="manageAccountColl">
                          {role === 1 ? (
                            <div className="manageAccountBox verificationEmail pt-0">
                              <h3>Account Verification</h3>
                              {emailVerify ? (
                                <div>
                                  <p>Your email address is verified</p>
                                </div>
                              ) : (
                                <div>
                                  <p>Your email address is not verified</p>
                                  <a onClick={this.emailVerify}>
                                    Send Verification Email
                                  </a>
                                </div>
                              )}
                            </div>
                          ) : null}
                          <div className="manageAccountBox deleteAccount">
                            <h3>Manage Account</h3>
                            <a
                              onClick={() => {
                                if (window.confirm('Delete The Account?')) {
                                  this.deleteAccount()
                                }
                              }}
                            >
                              Delete account
                            </a>
                          </div>
                        </div>
                      </TabPanel>

                      {role === 1 ? (
                        <TabPanel>
                          <div className="manageAccountColl">
                            <Team />
                          </div>
                        </TabPanel>
                      ) : null}
                    </div>
                  </Tabs>
                </div>
              </div>
              {/* <div className="mobileAddMember" onClick={this.handleOpenMember}>
                            <i className="fa fa-users"></i>
                        </div> */}
              {/* <div className={`illumeetProfileTeam ${isBoxesVisible ? "Open" : ""}`}>
                            <div className="closeAddMember" onClick={this.handleCloseMember}>
                                <i className="fa fa-times"></i>
                            </div>
                            <div className="profileTeamContent">
                                <h3 className="teamTitle">Team Member</h3>
                                <div className="ProfileTeamMember boxScroll">
                                    <div className="teamMemberBox">
                                        <div className="teamMemberImg">
                                            <img src={userProfilePic} alt="" />
                                        </div>
                                        <div className="teamMemberName">
                                            <h3>Ashley Sangwan</h3>
                                        </div>
                                    </div>
                                    <div className="teamMemberBox">
                                        <div className="teamMemberImg">
                                            <img src={userProfilePic} alt="" />
                                        </div>
                                        <div className="teamMemberName">
                                            <h3>Anthony Smith</h3>
                                        </div>
                                    </div>
                                    <div className="teamMemberBox">
                                        <div className="teamMemberImg">
                                            <img src={userProfilePic} alt="" />
                                        </div>
                                        <div className="teamMemberName">
                                            <h3>Anthony Smith</h3>
                                        </div>
                                    </div>
                                    <div className="teamMemberBox">
                                        <div className="teamMemberImg">
                                            <img src={userProfilePic} alt="" />
                                        </div>
                                        <div className="teamMemberName">
                                            <h3>Anthony Smith</h3>
                                        </div>
                                    </div>
                                    <div className="teamMemberBox">
                                        <div className="teamMemberImg">
                                            <img src={userProfilePic} alt="" />
                                        </div>
                                        <div className="teamMemberName">
                                            <h3>Anthony Smith</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="addMemberBox">
                                    <a href="#">Add team member</a>
                                </div>
                            </div>
                          </div> */}
            </div>
          </div>
        </div>

        {loading ? <Loader /> : null}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  profile: state.Profile,
})

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateProfile: Actions.profileRequest,
      emailVerify: Actions.emailVerifyRequest,
      deleteAccount: Actions.deleteAccountRequest,
      // startLoading: Actions.startLoaderOne,
    },
    dispatch,
  )
}
export default connect(mapStateToProps, mapActionsToProps)(Profile)
