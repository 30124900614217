import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  
  listEventsRequest: ['data'],
  listEventsSuccess: ['totalResults', 'event', 'message', 'allEvents'],
  listEventsError: ['error'], 
  getProjectRequest: ['data'],
  getProjectSuccess: ['project','message'],
  getProjectError: ['error'],  
  getProjectTemplateRequest: ['data'],
  getProjectTemplateSuccess: ['template', 'events', 'message'],
  getProjectTemplateError: ['error'],
  
  eventEmailRequest: ['data'],
  eventEmailSuccess: ['message'],
  eventEmailError: ['error'],
  updateEventsRequest: ['data'],
  updateEventsSuccess: ['message'],
  updateEventsError: ['error'],
  searchEventsRequest: ['data'],
  searchEventsSuccess: ['totalResults', 'event', 'message'],
  searchEventsError: ['error'],
  csvEventsRequest: ['data'],
  csvEventsSuccess: ['csvFile', 'message'],
  csvEventsError: ['error'],
  emailReportsRequest: ['data'],
  emailReportsSuccess: ['reports', 'message'],
  emailReportsError: ['error'],
  emailDataReportsRequest: ['data'],
  emailDataReportsSuccess: ['reports', 'monthReports', 'message'],
  emailDataReportsError: ['error'],
  emailRegistrationDataReportsRequest: ['data'],
  emailRegistrationDataReportsSuccess: ['reports', 'monthReports', 'message'],
  emailRegistrationDataReportsError: ['error'],
  checkEventDomainRequest: ['data'],
  checkEventDomainSuccess: ['message'],
  checkEventDomainError: ['error'], 
  replicatePageRequest: ['data'],
  replicatePageError: ['message'],
  replicatePageSuccess: ['message'], 
})

export const Constants = Types
export default Creators
