import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification, redirectTo } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import DashboardLayout from '../../Layouts/DashboardLayout'
import Chart from './Campaigns/chart'
import LineChart from './Campaigns/lineChart'
import { Modal } from 'react-responsive-modal'
import Pagination from 'react-js-pagination'
import {
  EmailsAnalyticsInfoAPI,
  EventsAllAPI,
  getEventTemplatesApi,
  EmailsCustomAnalyticsDataForEventAPI,
  resendEmailAPI,
} from '../../Redux/API'
import { ExportToCsv } from 'export-to-csv'
import SearchIconBar from '../../assets/images/searchIco.png'
import ReactTooltip from 'react-tooltip'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { CampaignProgressAPI } from '../../Redux/API'
import { Link } from 'react-router-dom'
import { toDate } from 'date-fns/esm'
import moment from 'moment'
import DataTable from 'react-data-table-component'
import { data } from 'jquery'
import { convertTZ, getProjectIdFromUrl } from '../../helpers/common'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { theme } from '../../theme/theme';
import { ThemeProvider } from "@material-ui/core/styles";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import BackArrow from '../../assets/images/icons/backArrow.svg';
import MoreIcon from '../../assets/images/icons/more.svg';
import SearchIcon from '../../assets/images/icons/search.svg';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import RefreshIcon from '../../assets/images/icons/refresh.svg';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { ProgressBarLine } from 'react-progressbar-line'
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ReloadIcon from '@material-ui/icons/Refresh';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  Button,
  Tooltip,
} from '@material-ui/core';

class EmailAnalytics extends Component {
  constructor(props) {
    super(props)
    this.anchorRef1 = React.createRef();
    this.anchorRef2 = React.createRef();
    this.anchorRef3 = React.createRef();
    this.anchorRef4 = React.createRef();
    this.anchorRefFilterBy = React.createRef();
    this.state = {
      projectId: '',
      title: 'Analytics',
      pageTitle: 'Analytics',
      loading: false,
      totalSendEmail: 0,
      totalEmail: 0,
      reject: 0,
      totalArray: [],
      deliveredArray: [],
      rejectArray: [],
      completed: 0,
      eventId: '',
      templateId: '',
      eventType: '',
      clickArray: [],
      openArray: [],
      open: false,
      modalTitle: 'Emails',
      sendEmailsList: [],
      rejectEmailsList: [],
      emailsList: [],
      analyticsData: {},
      events: [],
      templates: [],
      bounced: 0,
      emailData: [],
      allEmailData: [],
      page_no: 1,
      showData: 'all',
      searchText: '',
      filterBy: 'all',
      softBounced: 0,
      hardBounced: 0,
      softBouncedRate: 0,
      hardBouncedRate: 0,
      rejects: 0,
      rejectRate: 0,
      unsubRate: 0,
      unsub: 0,
      spamRate: 0,
      spam: 0,
      failedRate: 0,
      failed: 0,
      totalEmailsCount: 0,
      recordType: 'all',
      allEmail: [],
      totalResponse: false,
      startDate: '',
      endDate: '',
      showCustom: false,
      que: 0,
      queRate: 0,
      deferral: 0,
      deferralRate: 0,
      checkProgress: false,
      showProgress: false,
      totalSent: 0,
      totalRecord: 0,
      eventDomain: '',
      campaignTime: new Date(),
      groups: [],
      itemCount: 10,
      startCount: 1,
      refreshedAnalytics: false,
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      filterByDropdown: false,
      selectedDateFilter: 'All',
      selectedEmailType: '',
      campaignsFilterOptionsList: [],
      campaignsFilterOptionsSelected: [],
      transactionalFilterOptionsList: [],
      transactionalFilterOptionsSelected: [],
      meetingsFilterOptionsList: [],
      meetingsFilterOptionsSelected: [],
      refreshIntervalId: '',
      refreshIntervalPeriod: null,
      customDateFiltered: false,
      progressIntervalId: null,
      statusFilterOptionsList: ['send', 'reject', 'hard_bounce', 'soft_bounce', 'spam', 'unsub', 'failed', 'queued', 'deferral'],
      statusFilterOptionsSelected: [],
      sort_by: "sentAt",
      sortOrder: "desc",
      outBoundEmailSelected: ''
    }
  }

  handleToggle1 = () => {
    this.setState({
      open1: !this.state.open1
    });
  };

  handleToggle2 = () => {
    this.setState({
      open2: !this.state.open2
    });
  };
  handleToggle3 = () => {
    this.setState({
      open3: !this.state.open3
    });
  };
  handleToggle4 = () => {
    this.setState({
      open4: !this.state.open4
    });
  };

  handleToggleFilterBy = () => {
    this.setState({
      filterByDropdown: !this.state.filterByDropdown
    });
  };

  filterHandleClose = (event) => {
    this.setState({
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      filterByDropdown: false,
      openSettings: false,
    });
  };

  handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({
      });
      this.handleLoading()
    }
  }

  componentDidMount() {
    const projectId = getProjectIdFromUrl();

    this.setState({
      eventId: projectId,
      campaignsFilterOptionsSelected: this.props.campaign ? [{ _id: this.props.campaign._id, campaignName: this.props.campaign.campaignName }] : [],
      campaignsFilterOptionsSelectedByDefault: this.props.campaign ? [{ _id: this.props.campaign._id, campaignName: this.props.campaign.campaignName }] : [],
      meetingsFilterOptionsSelected: this.props.meetingSelected ? [{ _id: this.props.meetingSelected._id, meetingTitle: this.props.meetingSelected.meetingTitle }] : [],
      meetingsFilterOptionsSelectedByDefault: this.props.meetingSelected ? [{ _id: this.props.meetingSelected._id, meetingTitle: this.props.meetingSelected.meetingTitle }] : [],
      selectedEmailType: this.props.selectedEmailType ? this.props.selectedEmailType : '',
      outBoundEmailSelected: this.props.outBoundEmailSelected ? this.props.outBoundEmailSelected : '',
      searchText: this.props.outBoundEmailSelected ? this.props.outBoundEmailSelected : ''
    }, () => {
      this.apiCall()
      this.emailsDataApiCall()
    })
    
    this.props.listAllCampaignsRequest({
      eventId: projectId,
      requestFrom:"viewOutboundEmailsReports"
    })
    this.props.listAllTransactionalEmailTemplates({ projectId, permission: this.props.outBoundEmailSelected ? 'View Outbound Emails Reports' : 'View Campaigns' })
  
    this.props.listAllMeetingsRequest({
      eventId: projectId,
    })    
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.events.emailDataReportsSuccess === true &&
      this.props.events.message !== prevProps.events.message
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null

      const emailReports = this.props.events.reports.mandrill

      if (Object.keys(emailReports).length > 0) {
        const total = parseInt(emailReports.send);

        let softBouncedRate =
          emailReports.soft_bounces !== 0
            ? (emailReports.soft_bounces * 100) / total
            : 0
        let hardBouncedRate =
          emailReports.hard_bounces !== 0
            ? (emailReports.hard_bounces * 100) / total
            : 0
        let rejectRate =
          emailReports.rejects !== 0 ? (emailReports.rejects * 100) / total : 0
        let openRate =
          emailReports.opens !== 0 ? (emailReports.opens * 100) / total : 0
        let clickRate =
          emailReports.clicks !== 0 ? (emailReports.clicks * 100) / total : 0
        let deliverRate =
          emailReports.sent !== 0 ? (emailReports.sent * 100) / total : 0
        let unsubRate =
          emailReports.unsub !== 0 ? (emailReports.unsub * 100) / total : 0
        let spamRate =
          emailReports.spam !== 0 ? (emailReports.spam * 100) / total : 0
        let failedRate =
          emailReports.failed !== 0 ? (emailReports.failed * 100) / total : 0
        let queRate =
          emailReports.que !== 0 ? (emailReports.que * 100) / total : 0
        let deferralRate =
          emailReports.deferral !== 0
            ? (emailReports.deferral * 100) / total
            : 0

        this.setState({
          softBounced: emailReports.soft_bounces,
          hardBounced: emailReports.hard_bounces,
          hardBouncedRate: hardBouncedRate.toFixed(2),
          softBouncedRate: softBouncedRate.toFixed(2),
          totalEmail: total,
          totalSendEmail: emailReports.sent,
          reject: emailReports.rejects,
          rejectRate: rejectRate.toFixed(2),
          openRate: openRate.toFixed(2),
          clickRate: clickRate.toFixed(2),
          deliverRate: deliverRate.toFixed(2),
          openEmail: emailReports.opens,
          clickEmail: emailReports.clicks,
          unsubRate: unsubRate.toFixed(2),
          unsub: emailReports.unsub,
          spamRate: spamRate.toFixed(2),
          spam: emailReports.spam,
          failedRate: failedRate.toFixed(2),
          failed: emailReports.failed,
          queRate: queRate.toFixed(2),
          que: emailReports.que,
          deferralRate: deferralRate.toFixed(2),
          deferral: emailReports.deferral,
        })

        let deliveredArray = [
          emailReports.stats.today.sent,
          emailReports.stats.last_7_days.sent,
          emailReports.stats.last_30_days.sent,
          emailReports.stats.last_60_days.sent,
          emailReports.stats.last_90_days.sent,
        ]
        let totalBounced = [
          parseInt(emailReports.stats.today.hard_bounces) +
          parseInt(emailReports.stats.today.soft_bounces),
          parseInt(emailReports.stats.last_7_days.hard_bounces) +
          parseInt(emailReports.stats.last_7_days.soft_bounces),
          parseInt(emailReports.stats.last_30_days.hard_bounces) +
          parseInt(emailReports.stats.last_30_days.soft_bounces),
          parseInt(emailReports.stats.last_60_days.hard_bounces) +
          parseInt(emailReports.stats.last_60_days.soft_bounces),
          parseInt(emailReports.stats.last_90_days.hard_bounces) +
          parseInt(emailReports.stats.last_90_days.soft_bounces),
        ]

        let rejectArray = [
          emailReports.stats.today.rejects,
          emailReports.stats.last_7_days.rejects,
          emailReports.stats.last_30_days.rejects,
          emailReports.stats.last_60_days.rejects,
          emailReports.stats.last_90_days.rejects,
        ]

        let openArray = [
          emailReports.stats.today.opens,
          emailReports.stats.last_7_days.opens,
          emailReports.stats.last_30_days.opens,
          emailReports.stats.last_60_days.opens,
          emailReports.stats.last_90_days.opens,
        ]

        let clickArray = [
          emailReports.stats.today.clicks,
          emailReports.stats.last_7_days.clicks,
          emailReports.stats.last_30_days.clicks,
          emailReports.stats.last_60_days.clicks,
          emailReports.stats.last_90_days.clicks,
        ]

        this.setState({
          deliveredArray: deliveredArray,
          rejectArray: rejectArray,
          openArray: openArray,
          clickArray: clickArray,
          totalBounced: totalBounced,
        })
      } else {
        const Array = [0, 0, 0, 0, 0]
        this.setState({
          softBounced: 0,
          hardBounced: 0,
          hardBouncedRate: 0,
          softBouncedRate: 0,
          totalEmail: 0,
          totalSendEmail: 0,
          reject: 0,
          rejectRate: 0,
          openRate: 0,
          clickRate: 0,
          deliverRate: 0,
          openEmail: 0,
          clickEmail: 0,
          unsubRate: 0,
          unsub: 0,
          spamRate: 0,
          spam: 0,
          failedRate: 0,
          failed: 0,
          queRate: 0,
          que: 0,
          deferralRate: 0,
          deferral: 0,
          deliveredArray: Array,
          rejectArray: Array,
          openArray: Array,
          clickArray: Array,
          totalBounced: Array,
        })
      }
    }

    if (this.props.campaigns.listAllCampaignsSuccess && !prevProps.campaigns.listAllCampaignsSuccess &&
      this.props.campaigns.message !== prevProps.campaigns.message) {
      this.setState({
        campaignsFilterOptionsList: this.props.campaigns.allCampaigns
      })
    }

    if (this.props.attendees.listAllTransactionalEmailTemplatesSuccess === true && !prevProps.attendees.listAllTransactionalEmailTemplatesSuccess &&
      this.props.attendees.message !== prevProps.attendees.message) {
      this.setState({
        transactionalFilterOptionsList: this.props.attendees.allTransactionalEmailTemplates,
        transactionalFilterOptionsByDefault: this.props.attendees.allTransactionalEmailTemplates[0]
      });
    }

    if (this.props.meeting.listAllMeetingsSuccess && !prevProps.meeting.listAllMeetingsSuccess &&
      this.props.meeting.message !== prevProps.meeting.message) {
      this.setState({
        meetingsFilterOptionsList: this.props.meeting.allMeetings
      })
    }
  }

  apiCall = () => {
    this.setState({ loading: true })
    let requestbody = {
      eventId: this.state.eventId,
      emailType: this.state.selectedEmailType,
      page_no: this.state.page_no,
      startDate: this.state.startDate !== '' ? convertTZ(this.state.startDate, 'Etc/UTC') : '',
      endDate: this.state.endDate !== '' ? convertTZ(this.state.endDate, 'Etc/UTC') : '',
      outBoundEmailSelected: this.state.outBoundEmailSelected,
      permission: this.state.outBoundEmailSelected !== "" ? "View Outbound Emails Reports" : "View Campaigns"
    }
    if (this.state.selectedEmailType === 'campaigns' && this.state.campaignsFilterOptionsSelected.length) {
      requestbody['templateId'] = this.state.campaignsFilterOptionsSelected.map(campaign => campaign._id)
    } else if (this.state.selectedEmailType === 'transactional' && this.state.transactionalFilterOptionsSelected.length) {
      requestbody['templateId'] = this.state.transactionalFilterOptionsSelected.map(transactional => transactional._id)
    }else if (this.state.selectedEmailType === 'meetings' && this.state.meetingsFilterOptionsSelected.length) {
      requestbody['templateId'] = this.state.meetingsFilterOptionsSelected.map(meeting => meeting._id)
    }
    this.props.reportDataRequest(requestbody)
    if (this.state.selectedEmailType === 'campaigns' && this.state.campaignsFilterOptionsSelected.length === 1) {
      this.setState({ checkProgress: true }, function () {
        this.handleProgress(this.state.eventId, this.state.campaignsFilterOptionsSelected[0]._id)
      })
    } else {
      this.setState({ showProgress: false, checkProgress: false })
    }
  }

  emailsDataApiCall = () => {
    let requestBody = {
      eventId: this.state.eventId,
      emailType: this.state.selectedEmailType,
      page_no: this.state.page_no,
      startDate: this.state.startDate !== '' ? convertTZ(this.state.startDate, 'Etc/UTC') : '',
      endDate: this.state.endDate !== '' ? convertTZ(this.state.endDate, 'Etc/UTC') : '',
      filterby: this.state.statusFilterOptionsSelected,
      email: this.state.searchText,
      limit: Number(this.state.itemCount),
      sort_by: this.state.sort_by,
      sortOrder: this.state.sortOrder
    }
    if (this.state.selectedEmailType === 'campaigns' && this.state.campaignsFilterOptionsSelected.length) {
      requestBody['templateId'] = this.state.campaignsFilterOptionsSelected.map(campaign => campaign._id)
    } else if (this.state.selectedEmailType === 'transactional' && this.state.transactionalFilterOptionsSelected.length) {
      requestBody['templateId'] = this.state.transactionalFilterOptionsSelected.map(transactional => transactional._id)
    } else if (this.state.selectedEmailType === 'meetings' && this.state.meetingsFilterOptionsSelected.length) {
      requestBody['templateId'] = this.state.meetingsFilterOptionsSelected.map(meeting => meeting._id)
    }
    EmailsAnalyticsInfoAPI(requestBody)
      .then((result) => {
        if (result.data.status === 200) {
          this.setState({
            analyticsData: result.data.data,
            emailData: result.data.data,
            totalEmailsCount: result.data.totalEmailsCount,
            allEmail: result.data.resultAll,
            totalResponse: true,
          }, () => this.updatePaginationDropDownState())
        } else {
          createNotification('error', result.data.message)
        }
        this.setState({ loading: false })
      })
      .catch(console.log)
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  filterByDate = (value, label) => {
    this.setState({ selectedDateFilter: label, open3: false })
    const v1 = value

    if (v1 === 'all' && this.state.customDateFiltered) {
      this.setState({
        customDateFiltered: false,
        startDate: '',
        endDate: ''
      }, () => {
        this.apiCall()
        this.emailsDataApiCall()
      })
      return
    }

    const emailReports = this.props.events.reports.mandrill
    if (Object.keys(emailReports).length > 0) {
      if (v1 === 'last_90_days') {
        this.setState({
          startDate: moment.tz(this.props.timzone).subtract(90, "days").format("YYYY-MM-DD 00:00:00"),
          endDate: moment.tz(this.props.timzone).format("YYYY-MM-DD 00:00:00")
        }, () => this.emailsDataApiCall())
      } else if (v1 === 'last_60_days') {
        this.setState({
          startDate: moment.tz(this.props.timzone).subtract(60, "days").format("YYYY-MM-DD 00:00:00"),
          endDate: moment.tz(this.props.timzone).format("YYYY-MM-DD 00:00:00")
        }, () => this.emailsDataApiCall())
      } else if (v1 === 'last_30_days') {
        this.setState({
          startDate: moment.tz(this.props.timzone).subtract(30, "days").format("YYYY-MM-DD 00:00:00"),
          endDate: moment.tz(this.props.timzone).format("YYYY-MM-DD 00:00:00")
        }, () => this.emailsDataApiCall())
      } else if (v1 === 'last_7_days') {
        this.setState({
          startDate: moment.tz(this.props.timzone).subtract(7, "days").format("YYYY-MM-DD 00:00:00"),
          endDate: moment.tz(this.props.timzone).format("YYYY-MM-DD 00:00:00")
        }, () => this.emailsDataApiCall())
      } else if (v1 === 'today') {
        this.setState({
          startDate: moment.tz(this.props.timzone).format("YYYY-MM-DD 00:00:00"),
          endDate: moment.tz(this.props.timzone).format("YYYY-MM-DD 00:00:00")
        }, () => this.emailsDataApiCall())
      } else if (v1 === 'all') {
        this.setState({
          startDate: '',
          endDate: ''
        }, () => this.emailsDataApiCall())
      }

      if (v1 === 'custom') {
        this.setState({
          showCustom: true,
          startDate: '',
          endDate: ''
        })
      } else {
        this.setState({ showCustom: false })
      }
      this.setState({ showData: v1 })
      if (v1 === 'last_90_days') { 
        const total = parseInt(emailReports.stats.last_90_days.send);

        let softBouncedRate =
          emailReports.stats.last_90_days.soft_bounces !== 0
            ? (emailReports.stats.last_90_days.soft_bounces * 100) / total
            : 0
        let hardBouncedRate =
          emailReports.stats.last_90_days.hard_bounces !== 0
            ? (emailReports.stats.last_90_days.hard_bounces * 100) / total
            : 0
        let rejectRate =
          emailReports.stats.last_90_days.rejects !== 0
            ? (emailReports.stats.last_90_days.rejects * 100) / total
            : 0
        let openRate =
          emailReports.stats.last_90_days.opens !== 0
            ? (emailReports.stats.last_90_days.opens * 100) / total
            : 0
        let clickRate =
          emailReports.stats.last_90_days.clicks !== 0
            ? (emailReports.stats.last_90_days.clicks * 100) / total
            : 0
        let deliverRate =
          emailReports.stats.last_90_days.sent !== 0
            ? (emailReports.stats.last_90_days.sent * 100) / total
            : 0
        let unsubRate =
          emailReports.stats.last_90_days.unsub !== 0
            ? (emailReports.stats.last_90_days.unsub * 100) / total
            : 0
        let spamRate =
          emailReports.stats.last_90_days.spam !== 0
            ? (emailReports.stats.last_90_days.spam * 100) / total
            : 0
        let failedRate =
          emailReports.stats.last_90_days.failed !== 0
            ? (emailReports.stats.last_90_days.failed * 100) / total
            : 0
        let queRate =
          emailReports.stats.last_90_days.que !== 0
            ? (emailReports.stats.last_90_days.que * 100) / total
            : 0
        let deferralRate =
          emailReports.stats.last_90_days.deferral !== 0
            ? (emailReports.stats.last_90_days.deferral * 100) / total
            : 0

        this.setState({
          softBounced: emailReports.stats.last_90_days.soft_bounces,
          hardBounced: emailReports.stats.last_90_days.hard_bounces,
          hardBouncedRate: hardBouncedRate.toFixed(2),
          softBouncedRate: softBouncedRate.toFixed(2),
          totalEmail: total,
          totalSendEmail: emailReports.stats.last_90_days.sent,
          reject: emailReports.stats.last_90_days.rejects,
          rejectRate: rejectRate.toFixed(2),
          openRate: openRate.toFixed(2),
          clickRate: clickRate.toFixed(2),
          deliverRate: deliverRate.toFixed(2),
          openEmail: emailReports.stats.last_90_days.opens,
          clickEmail: emailReports.stats.last_90_days.clicks,
          unsubRate: unsubRate.toFixed(2),
          unsub: emailReports.stats.last_90_days.unsub,
          spamRate: spamRate.toFixed(2),
          spam: emailReports.stats.last_90_days.spam,
          failedRate: failedRate.toFixed(2),
          failed: emailReports.stats.last_90_days.failed,
          queRate: queRate.toFixed(2),
          que: emailReports.stats.last_90_days.que,
          deferralRate: deferralRate.toFixed(2),
          deferral: emailReports.stats.last_90_days.deferral,
        })
      } else if (v1 === 'last_60_days') {
        const total = parseInt(emailReports.stats.last_60_days.send);

        let softBouncedRate =
          emailReports.stats.last_60_days.soft_bounces !== 0
            ? (emailReports.stats.last_60_days.soft_bounces * 100) / total
            : 0
        let hardBouncedRate =
          emailReports.stats.last_60_days.hard_bounces !== 0
            ? (emailReports.stats.last_60_days.hard_bounces * 100) / total
            : 0
        let rejectRate =
          emailReports.stats.last_60_days.rejects !== 0
            ? (emailReports.stats.last_60_days.rejects * 100) / total
            : 0
        let openRate =
          emailReports.stats.last_60_days.opens !== 0
            ? (emailReports.stats.last_60_days.opens * 100) / total
            : 0
        let clickRate =
          emailReports.stats.last_60_days.clicks !== 0
            ? (emailReports.stats.last_60_days.clicks * 100) / total
            : 0
        let deliverRate =
          emailReports.stats.last_60_days.sent !== 0
            ? (emailReports.stats.last_60_days.sent * 100) / total
            : 0
        let unsubRate =
          emailReports.stats.last_60_days.unsub !== 0
            ? (emailReports.stats.last_60_days.unsub * 100) / total
            : 0
        let spamRate =
          emailReports.stats.last_60_days.spam !== 0
            ? (emailReports.stats.last_60_days.spam * 100) / total
            : 0
        let failedRate =
          emailReports.stats.last_60_days.failed !== 0
            ? (emailReports.stats.last_60_days.failed * 100) / total
            : 0
        let queRate =
          emailReports.stats.last_60_days.que !== 0
            ? (emailReports.stats.last_60_days.que * 100) / total
            : 0
        let deferralRate =
          emailReports.stats.last_60_days.deferral !== 0
            ? (emailReports.stats.last_60_days.deferral * 100) / total
            : 0

        this.setState({
          softBounced: emailReports.stats.last_60_days.soft_bounces,
          hardBounced: emailReports.stats.last_60_days.hard_bounces,
          hardBouncedRate: hardBouncedRate.toFixed(2),
          softBouncedRate: softBouncedRate.toFixed(2),
          totalEmail: total,
          totalSendEmail: emailReports.stats.last_60_days.sent,
          reject: emailReports.stats.last_60_days.rejects,
          rejectRate: rejectRate.toFixed(2),
          openRate: openRate.toFixed(2),
          clickRate: clickRate.toFixed(2),
          deliverRate: deliverRate.toFixed(2),
          openEmail: emailReports.stats.last_60_days.opens,
          clickEmail: emailReports.stats.last_60_days.clicks,
          unsubRate: unsubRate.toFixed(2),
          unsub: emailReports.stats.last_60_days.unsub,
          spamRate: spamRate.toFixed(2),
          spam: emailReports.stats.last_60_days.spam,
          failedRate: failedRate.toFixed(2),
          failed: emailReports.stats.last_60_days.failed,
          queRate: queRate.toFixed(2),
          que: emailReports.stats.last_60_days.que,
          deferralRate: deferralRate.toFixed(2),
          deferral: emailReports.stats.last_60_days.deferral,
        })
      } else if (v1 === 'last_30_days') {
        const total = parseInt(emailReports.stats.last_30_days.send);

        let softBouncedRate =
          emailReports.stats.last_30_days.soft_bounces !== 0
            ? (emailReports.stats.last_30_days.soft_bounces * 100) / total
            : 0
        let hardBouncedRate =
          emailReports.stats.last_30_days.hard_bounces !== 0
            ? (emailReports.stats.last_30_days.hard_bounces * 100) / total
            : 0
        let rejectRate =
          emailReports.stats.last_30_days.rejects !== 0
            ? (emailReports.stats.last_30_days.rejects * 100) / total
            : 0
        let openRate =
          emailReports.stats.last_30_days.opens !== 0
            ? (emailReports.stats.last_30_days.opens * 100) / total
            : 0
        let clickRate =
          emailReports.stats.last_30_days.clicks !== 0
            ? (emailReports.stats.last_30_days.clicks * 100) / total
            : 0
        let deliverRate =
          emailReports.stats.last_30_days.sent !== 0
            ? (emailReports.stats.last_30_days.sent * 100) / total
            : 0
        let unsubRate =
          emailReports.stats.last_30_days.unsub !== 0
            ? (emailReports.stats.last_30_days.unsub * 100) / total
            : 0
        let spamRate =
          emailReports.stats.last_30_days.spam !== 0
            ? (emailReports.stats.last_30_days.spam * 100) / total
            : 0
        let failedRate =
          emailReports.stats.last_30_days.failed !== 0
            ? (emailReports.stats.last_30_days.failed * 100) / total
            : 0
        let queRate =
          emailReports.stats.last_30_days.que !== 0
            ? (emailReports.stats.last_30_days.que * 100) / total
            : 0
        let deferralRate =
          emailReports.stats.last_30_days.deferral !== 0
            ? (emailReports.stats.last_30_days.deferral * 100) / total
            : 0

        this.setState({
          softBounced: emailReports.stats.last_30_days.soft_bounces,
          hardBounced: emailReports.stats.last_30_days.hard_bounces,
          hardBouncedRate: hardBouncedRate.toFixed(2),
          softBouncedRate: softBouncedRate.toFixed(2),
          totalEmail: total,
          totalSendEmail: emailReports.stats.last_30_days.sent,
          reject: emailReports.stats.last_30_days.rejects,
          rejectRate: rejectRate.toFixed(2),
          openRate: openRate.toFixed(2),
          clickRate: clickRate.toFixed(2),
          deliverRate: deliverRate.toFixed(2),
          openEmail: emailReports.stats.last_30_days.opens,
          clickEmail: emailReports.stats.last_30_days.clicks,
          unsubRate: unsubRate.toFixed(2),
          unsub: emailReports.stats.last_30_days.unsub,
          spamRate: spamRate.toFixed(2),
          spam: emailReports.stats.last_30_days.spam,
          failedRate: failedRate.toFixed(2),
          failed: emailReports.stats.last_30_days.failed,
          queRate: queRate.toFixed(2),
          que: emailReports.stats.last_30_days.que,
          deferralRate: deferralRate.toFixed(2),
          deferral: emailReports.stats.last_30_days.deferral,
        })
      } else if (v1 === 'last_7_days') {
        const total = parseInt(emailReports.stats.last_7_days.send);

        let softBouncedRate =
          emailReports.stats.last_7_days.soft_bounces !== 0
            ? (emailReports.stats.last_7_days.soft_bounces * 100) / total
            : 0
        let hardBouncedRate =
          emailReports.stats.last_7_days.hard_bounces !== 0
            ? (emailReports.stats.last_7_days.hard_bounces * 100) / total
            : 0
        let rejectRate =
          emailReports.stats.last_7_days.rejects !== 0
            ? (emailReports.stats.last_7_days.rejects * 100) / total
            : 0
        let openRate =
          emailReports.stats.last_7_days.opens !== 0
            ? (emailReports.stats.last_7_days.opens * 100) / total
            : 0
        let clickRate =
          emailReports.stats.last_7_days.clicks !== 0
            ? (emailReports.stats.last_7_days.clicks * 100) / total
            : 0
        let deliverRate =
          emailReports.stats.last_7_days.sent !== 0
            ? (emailReports.stats.last_7_days.sent * 100) / total
            : 0
        let unsubRate =
          emailReports.stats.last_7_days.unsub !== 0
            ? (emailReports.stats.last_7_days.unsub * 100) / total
            : 0
        let spamRate =
          emailReports.stats.last_7_days.spam !== 0
            ? (emailReports.stats.last_7_days.spam * 100) / total
            : 0
        let failedRate =
          emailReports.stats.last_7_days.failed !== 0
            ? (emailReports.stats.last_7_days.failed * 100) / total
            : 0
        let queRate =
          emailReports.stats.last_7_days.que !== 0
            ? (emailReports.stats.last_7_days.que * 100) / total
            : 0
        let deferralRate =
          emailReports.stats.last_7_days.deferral !== 0
            ? (emailReports.stats.last_7_days.deferral * 100) / total
            : 0

        this.setState({
          softBounced: emailReports.stats.last_7_days.soft_bounces,
          hardBounced: emailReports.stats.last_7_days.hard_bounces,
          hardBouncedRate: hardBouncedRate.toFixed(2),
          softBouncedRate: softBouncedRate.toFixed(2),
          totalEmail: total,
          totalSendEmail: emailReports.stats.last_7_days.sent,
          reject: emailReports.stats.last_7_days.rejects,
          rejectRate: rejectRate.toFixed(2),
          openRate: openRate.toFixed(2),
          clickRate: clickRate.toFixed(2),
          deliverRate: deliverRate.toFixed(2),
          openEmail: emailReports.stats.last_7_days.opens,
          clickEmail: emailReports.stats.last_7_days.clicks,
          unsubRate: unsubRate.toFixed(2),
          unsub: emailReports.stats.last_7_days.unsub,
          spamRate: spamRate.toFixed(2),
          spam: emailReports.stats.last_7_days.spam,
          failedRate: failedRate.toFixed(2),
          failed: emailReports.stats.last_7_days.failed,
          queRate: queRate.toFixed(2),
          que: emailReports.stats.last_7_days.que,
          deferralRate: deferralRate.toFixed(2),
          deferral: emailReports.stats.last_7_days.deferral,
        })
      } else if (v1 === 'today') {
        const total = parseInt(emailReports.stats.today.send);

        let softBouncedRate =
          emailReports.stats.today.soft_bounces !== 0
            ? (emailReports.stats.today.soft_bounces * 100) / total
            : 0
        let hardBouncedRate =
          emailReports.stats.today.hard_bounces !== 0
            ? (emailReports.stats.today.hard_bounces * 100) / total
            : 0
        let rejectRate =
          emailReports.stats.today.rejects !== 0
            ? (emailReports.stats.today.rejects * 100) / total
            : 0
        let openRate =
          emailReports.stats.today.opens !== 0
            ? (emailReports.stats.today.opens * 100) / total
            : 0
        let clickRate =
          emailReports.stats.today.clicks !== 0
            ? (emailReports.stats.today.clicks * 100) / total
            : 0
        let deliverRate =
          emailReports.stats.today.sent !== 0
            ? (emailReports.stats.today.sent * 100) / total
            : 0
        let unsubRate =
          emailReports.stats.today.unsub !== 0
            ? (emailReports.stats.today.unsub * 100) / total
            : 0
        let spamRate =
          emailReports.stats.today.spam !== 0
            ? (emailReports.stats.today.spam * 100) / total
            : 0
        let failedRate =
          emailReports.stats.today.failed !== 0
            ? (emailReports.stats.today.failed * 100) / total
            : 0
        let queRate =
          emailReports.stats.today.que !== 0
            ? (emailReports.stats.today.que * 100) / total
            : 0
        let deferralRate =
          emailReports.stats.today.deferral !== 0
            ? (emailReports.stats.today.deferral * 100) / total
            : 0

        this.setState({
          softBounced: emailReports.stats.today.soft_bounces,
          hardBounced: emailReports.stats.today.hard_bounces,
          hardBouncedRate: hardBouncedRate.toFixed(2),
          softBouncedRate: softBouncedRate.toFixed(2),
          totalEmail: total,
          totalSendEmail: emailReports.stats.today.sent,
          reject: emailReports.stats.today.rejects,
          rejectRate: rejectRate.toFixed(2),
          openRate: openRate.toFixed(2),
          clickRate: clickRate.toFixed(2),
          deliverRate: deliverRate.toFixed(2),
          openEmail: emailReports.stats.today.opens,
          clickEmail: emailReports.stats.today.clicks,
          unsubRate: unsubRate.toFixed(2),
          unsub: emailReports.stats.today.unsub,
          spamRate: spamRate.toFixed(2),
          spam: emailReports.stats.today.spam,
          failedRate: failedRate.toFixed(2),
          failed: emailReports.stats.today.failed,
          queRate: queRate.toFixed(2),
          que: emailReports.stats.today.que,
          deferralRate: deferralRate.toFixed(2),
          deferral: emailReports.stats.today.deferral,
        })
      } else {
        const total = parseInt(emailReports.send);

        let softBouncedRate =
          emailReports.soft_bounces !== 0
            ? (emailReports.soft_bounces * 100) / total
            : 0
        let hardBouncedRate =
          emailReports.hard_bounces !== 0
            ? (emailReports.hard_bounces * 100) / total
            : 0
        let rejectRate =
          emailReports.rejects !== 0
            ? (emailReports.rejects * 100) / total
            : 0
        let openRate =
          emailReports.opens !== 0 ? (emailReports.opens * 100) / total : 0
        let clickRate =
          emailReports.clicks !== 0 ? (emailReports.clicks * 100) / total : 0
        let deliverRate =
          emailReports.sent !== 0 ? (emailReports.sent * 100) / total : 0
        let unsubRate =
          emailReports.unsub !== 0 ? (emailReports.unsub * 100) / total : 0
        let spamRate =
          emailReports.spam !== 0 ? (emailReports.spam * 100) / total : 0
        let failedRate =
          emailReports.failed !== 0 ? (emailReports.failed * 100) / total : 0
        let queRate =
          emailReports.que !== 0 ? (emailReports.que * 100) / total : 0
        let deferralRate =
          emailReports.deferral !== 0
            ? (emailReports.deferral * 100) / total
            : 0

        this.setState({
          softBounced: emailReports.soft_bounces,
          hardBounced: emailReports.hard_bounces,
          hardBouncedRate: hardBouncedRate.toFixed(2),
          softBouncedRate: softBouncedRate.toFixed(2),
          totalEmail: total,
          totalSendEmail: emailReports.sent,
          reject: emailReports.rejects,
          rejectRate: rejectRate.toFixed(2),
          openRate: openRate.toFixed(2),
          clickRate: clickRate.toFixed(2),
          deliverRate: deliverRate.toFixed(2),
          openEmail: emailReports.opens,
          clickEmail: emailReports.clicks,
          unsubRate: unsubRate.toFixed(2),
          unsub: emailReports.unsub,
          spamRate: spamRate.toFixed(2),
          spam: emailReports.spam,
          failedRate: failedRate.toFixed(2),
          failed: emailReports.failed,
          queRate: queRate.toFixed(2),
          que: emailReports.que,
          deferralRate: deferralRate.toFixed(2),
          deferral: emailReports.deferral,
        })
      }
    } else {
      this.setState({
        softBounced: 0,
        hardBounced: 0,
        hardBouncedRate: 0,
        softBouncedRate: 0,
        totalEmail: 0,
        totalSendEmail: 0,
        reject: 0,
        rejectRate: 0,
        openRate: 0,
        clickRate: 0,
        deliverRate: 0,
        openEmail: 0,
        clickEmail: 0,
        unsubRate: 0,
        unsub: 0,
        spamRate: 0,
        spam: 0,
        failedRate: 0,
        failed: 0,
        queRate: 0,
        que: 0,
        deferralRate: 0,
        deferral: 0,
      })
    }
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ page_no: 1, startCount: 1, emailData: [] })
    this.handleLoading()
    this.emailsDataApiCall()
  }

  onChangeDate = (date) => {
    this.setState({ startDate: date })
  }

  onChangeEndDate = (date) => {
    this.handleLoading()
    this.setState({
      endDate: date,
      customDateFiltered: true
    }, () => {
      this.apiCall()
      this.emailsDataApiCall()
    })
  }

  resetDate = () => {
    this.setState({ startDate: '', endDate: '' })
    this.apiCall()
    this.emailsDataApiCall()
  }

  handlePageChange = (pageNumber) => {
    try {
      const { itemCount } = this.state
      let totalResults = this.state.totalEmailsCount;
      this.setState({
        startCount: pageNumber !== 1 ? (itemCount * (pageNumber - 1) + 1) : 1,
        endCount: totalResults > itemCount * pageNumber ? itemCount * pageNumber : totalResults,
        page_no: pageNumber
      }, () => {
        this.emailsDataApiCall()
      });
    } catch (error) {
      createNotification("error", "something went wrong")
    }
  }

  selectItemCount = (e) => {
    this.setState({
      itemCount: e.target.value,
      page_no: 1,
      startCount: 1
    }, () =>
      this.emailsDataApiCall()
    )
  }

  updatePaginationDropDownState() {
    if (this.state.itemCount >= this.state.totalEmailsCount) {
      this.setState({
        endCount: this.state.totalEmailsCount
      });
    } else {
      this.setState({
        endCount: this.state.totalEmailsCount > (this.state.itemCount * this.state.page_no) ? (this.state.itemCount * this.state.page_no) : this.state.totalEmailsCount
      });
    }
  }

  handleProgress = (eventId, templateId) => {
    if (this.state.checkProgress) {
      CampaignProgressAPI(eventId, templateId)
        .then((result) => {
          if (result.data.status === 200) {
            if (parseInt(result.data.total) !== parseInt(result.data.totalSent)) {
              this.setState({
                showProgress: true,
                checkProgress: true,
                totalRecord: result.data.total,
                totalSent: result.data.totalSent,
              })
            }
          }
        })
        .catch(console.log)

      if (this.state.progressIntervalId) {      
        clearInterval(this.state.progressIntervalId)
        this.setState({ progressIntervalId: null })
      }

      let progressIntervalId = setInterval(() => {
        CampaignProgressAPI(eventId, templateId)
          .then((result) => {
            if (result.data.status === 200) {
              this.setState({
                showProgress: true,
                checkProgress: true,
                totalRecord: result.data.total,
                totalSent: result.data.totalSent,
              })

              if (parseInt(result.data.total) === parseInt(result.data.totalSent)) {
                clearInterval(this.state.progressIntervalId)
                this.setState({ showProgress: false, checkProgress: false })
              }
            } else {
              clearInterval(this.state.progressIntervalId)
              this.setState({ showProgress: false, checkProgress: false })
            }
          })
          .catch(console.log)
      }, 5000)

      this.setState({ progressIntervalId })
    }
  }

  resendEmail = (id, emailType) => {
    let ids = []
    ids.push(id)
    this.handleLoading()
    const { eventId, templateId } = this.state
    resendEmailAPI({ ids, emailType: emailType })
      .then((result) => {
        if (result.data.status === 200) {
          createNotification('success', result.data.message)
        } else {
          createNotification('error', result.data.message)
        }
        this.handleLoading()
        this.apiCall()
        this.emailsDataApiCall()
      })
      .catch(this.handleLoading())
  }

  customAnalyticsUpdate = () => {    
    if (this.state.refreshIntervalId) {
      clearInterval(this.state.refreshIntervalId)
      this.setState({ refreshIntervalPeriod: null })
    }

    this.apiCall()
    this.emailsDataApiCall()
    this.setState({ refreshedAnalytics: true, open4: false })
  }

  customAnalyticsUpdateByInterval = () => {
    this.apiCall()
    this.emailsDataApiCall()
  }

  componentWillUnmount() {
    if (this.state.refreshIntervalId) {
      clearInterval(this.state.refreshIntervalId)
    }
    if (this.state.refreshedAnalytics) {
      // this.props.listCampaigns()
    }
  }

  handleFilterChange = (event, selectedFilter, value = null) => {
    event.preventDefault()
    try {
      clearInterval(this.state.progressIntervalId)

      let campaignsFilterOptionsSelectedArray = [...this.state.campaignsFilterOptionsSelected];
      if (selectedFilter === 'campaigns') {
        if (event.target.name === 'all') {
          if (event.target.checked === true) {
            campaignsFilterOptionsSelectedArray = [...this.state.campaignsFilterOptionsList]
          } else {
            campaignsFilterOptionsSelectedArray = [...this.state.campaignsFilterOptionsSelectedByDefault]
          }
        } else {
          let filterIndex = campaignsFilterOptionsSelectedArray.findIndex(campaign => campaign._id === event.target.name)
          if (filterIndex > -1) {
            campaignsFilterOptionsSelectedArray.splice(filterIndex, 1)
            if(campaignsFilterOptionsSelectedArray.length === 0) {
              return
            }              
          } else {
            campaignsFilterOptionsSelectedArray.push(value)
          }
        }
      }      

      let transactionalFilterOptionsSelectedArray = [...this.state.transactionalFilterOptionsSelected];
      if (selectedFilter === 'transactional') {
        if (event.target.name === 'all') {
          if (event.target.checked === true) {
            transactionalFilterOptionsSelectedArray = [...this.state.transactionalFilterOptionsList]
          } else {
            transactionalFilterOptionsSelectedArray = [{...this.state.transactionalFilterOptionsByDefault}]
          }
        } else {
          let filterIndex = transactionalFilterOptionsSelectedArray.findIndex(transactional => transactional._id === event.target.name)
          if (filterIndex > -1) {
            transactionalFilterOptionsSelectedArray.splice(filterIndex, 1)
            if(transactionalFilterOptionsSelectedArray.length === 0) {
              return
            } 
          } else {
            transactionalFilterOptionsSelectedArray.push(value)
          }
        }
      }

      let meetingsFilterOptionsSelectedArray = [...this.state.meetingsFilterOptionsSelected];
      if (selectedFilter === 'meetings') {
        if (event.target.name === 'all') {
          if (event.target.checked === true) {
            meetingsFilterOptionsSelectedArray = [...this.state.meetingsFilterOptionsList]
          } else {
            meetingsFilterOptionsSelectedArray = [...this.state.meetingsFilterOptionsSelectedByDefault]
          }
        } else {
          let filterIndex = meetingsFilterOptionsSelectedArray.findIndex(meeting => meeting._id === event.target.name)
          if (filterIndex > -1) {
            meetingsFilterOptionsSelectedArray.splice(filterIndex, 1)
            if(meetingsFilterOptionsSelectedArray.length === 0) {
              return
            }              
          } else {
            meetingsFilterOptionsSelectedArray.push(value)
          }
        }
      }

      this.setState({
        campaignsFilterOptionsSelected: [...campaignsFilterOptionsSelectedArray],
        transactionalFilterOptionsSelected: [...transactionalFilterOptionsSelectedArray],
        meetingsFilterOptionsSelected: [...meetingsFilterOptionsSelectedArray],
      }, () => {
        this.apiCall()
        this.emailsDataApiCall()
      })
    } catch (error) {
      createNotification("error", "something went wrong")
    }
  }

  changeEmailType = (type) => {
    clearInterval(this.state.progressIntervalId)
    this.setState({
      selectedEmailType: type,
      open1: false,
      campaignsFilterOptionsSelected: this.state.campaignsFilterOptionsList,
      transactionalFilterOptionsSelected: this.state.transactionalFilterOptionsList,
      meetingsFilterOptionsSelected: this.state.meetingsFilterOptionsList,
      page_no: 1,
      itemCount: 10,
      startCount: 1,
      startDate: '',
      endDate: '',
      selectedDateFilter: 'All',
      showCustom: false
    }, () => {
      this.apiCall()
      this.emailsDataApiCall()
    })
  }

  refreshByInterval = (interval) => {
    if (this.state.refreshIntervalId !== "") {
      clearInterval(this.state.refreshIntervalId)
    }

    this.customAnalyticsUpdate()
    let refreshIntervalId = setInterval(() => {
      this.customAnalyticsUpdateByInterval()
    }, interval * 60 * 1000);
    this.setState({ refreshIntervalId, refreshIntervalPeriod: interval, refresh: false, open4: false })
  }

  handleFilterEmailsByStatus = (event) => {
    event.preventDefault()
    try {
      let statusFilterOptionsSelectedArray = [...this.state.statusFilterOptionsSelected];

      if (event.target.name === 'all') {
        if (event.target.checked === true) {
          statusFilterOptionsSelectedArray = [...this.state.statusFilterOptionsList]
        } else {
          statusFilterOptionsSelectedArray = []
        }
      } else {
        let filterIndex = statusFilterOptionsSelectedArray.indexOf(event.target.name)
        if (filterIndex > -1) {
          statusFilterOptionsSelectedArray.splice(filterIndex, 1)
        } else {
          statusFilterOptionsSelectedArray.push(event.target.name)
        }
      }

      this.setState({
        statusFilterOptionsSelected: [...statusFilterOptionsSelectedArray],
        startCount: 1,
        page_no: 1
      }, () => this.emailsDataApiCall())
    } catch (error) {
      createNotification("error", "something went wrong")
    }
  }

  sorting = (value) => {
    if (this.state.sort_by === value) {
      if (this.state.sortOrder === "asc") {
        this.setState({
          sortOrder: "desc"
        }, () => this.emailsDataApiCall()
        )
      } else {
        this.setState({
          sortOrder: "asc"
        }, () => this.emailsDataApiCall()
        )
      }
    } else {
      this.setState({
        sort_by: value,
        sortOrder: "asc"
      }, () => this.emailsDataApiCall()
      )
    }
  }
  
  chooseFilter = (val) => {
  let temp;
  switch (val) {
    case 'All': temp = this.props.users.languageStateForRedux?.common?.all;   
    return temp;   

    case 'Today': temp = this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.today;
    return temp;

    case 'Last 07 Days': temp = this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last7;
    return temp;

    case 'Last 30 Days': temp = this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last30;
    return temp;

    case 'Last 60 Days': temp = this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last60;
    return temp;

    case 'Last 90 Days': temp = this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last90;
    return temp;

    case 'Custom Range': temp = this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.customRange;
    return temp;

    default: temp = val;
    break;
  }
}

  render() {
    const {
      loading,
      totalEmail,
      totalSendEmail,
      reject,
      rejectRate,
      totalBounced,
      deliveredArray,
      rejectArray,
      open,
      eventId,
      openArray,
      clickArray,
      openEmail,
      openRate,
      clickEmail,
      clickRate,
      deliverRate,
      emailData,
      page_no,
      searchText,
      softBounced,
      hardBounced,
      softBouncedRate,
      hardBouncedRate,
      unsubRate,
      unsub,
      spamRate,
      spam,
      totalEmailsCount,
      totalResponse,
      startDate,
      endDate,
      showCustom,
      failedRate,
      failed,
      queRate,
      que,
      showProgress,
      deferral,
      deferralRate,
      startCount,
      endCount,
      itemCount,
      open1,
      open2,
      open3,
      open4,
      filterByDropdown,
      selectedDateFilter,
      selectedEmailType,
      campaignsFilterOptionsList,
      campaignsFilterOptionsSelected,
      transactionalFilterOptionsList,
      transactionalFilterOptionsSelected,
      meetingsFilterOptionsList,
      meetingsFilterOptionsSelected,
      refreshIntervalPeriod,
      statusFilterOptionsList,
      statusFilterOptionsSelected,
      sort_by,
      sortOrder
    } = this.state

    const campaignsFilterOptionsSelectedAll = campaignsFilterOptionsSelected.length === campaignsFilterOptionsList.length ? true : false;
    const transactionalFilterOptionsSelectedAll = transactionalFilterOptionsSelected.length === transactionalFilterOptionsList.length ? true : false;
    const meetingsFilterOptionsSelectedAll = meetingsFilterOptionsSelected.length === meetingsFilterOptionsList.length ? true : false;
    const statusFilterOptionsSelectedAll = statusFilterOptionsSelected.length === statusFilterOptionsList.length ? true : false;

    return (
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} mb={3} className="top-filter-area">
          <Grid item>
            <Grid container spacing={1} alignItems='center'>
              <Grid item>
                <IconButton size='small'>
                  <Box component={'img'} mb={0.5} alt="" src={BackArrow} onClick={this.props.closeReport} />
                </IconButton>
              </Grid>
              <Grid item>
                <Box component={'span'} mr={2} className="table-status success">
                {this.props.users.languageStateForRedux?.common?.sent}
                </Box>
              </Grid>

              <Grid item>
                <Box
                  component={Button}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.background.light,
                    color: theme.palette.common.black,
                    textTransform: 'capitalize !important',
                    fontWeight: '400',
                    '&:hover, &.active': {
                      backgroundColor: '#000056 !important',
                      color: theme.palette.primary.contrastText,
                    }
                  }}
                  size='small'
                  // className={'active'}
                  disableElevation
                  ref={this.anchorRef1}
                  aria-controls={open1 ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleToggle1}
                  endIcon={<ArrowDropDownIcon />}
                  disabled={selectedEmailType === 'meetings' ? true: false}
                >
                  {selectedEmailType === 'campaigns' ? this.props.users.languageStateForRedux?.dropdowns?.campaigns?.emailCampaigns :
                    selectedEmailType === 'transactional' ? this.props.users.languageStateForRedux?.dropdowns?.campaigns?.transEmail :
                    selectedEmailType === 'meetings' ? this.props.users.languageStateForRedux?.dropdowns?.campaigns?.meeting :
                    this.props.users.languageStateForRedux?.common?.all}
                </Box>
                <Popper open={open1} placement="bottom-start" className='filter-options' transition anchorEl={this.anchorRef1?.current} role={undefined} disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Fade {...TransitionProps} timeout={250} >
                      <Box sx={{
                        backgroundColor: theme.palette.common.white, boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)', borderRadius: '4px', marginTop: '8px',
                      }}
                      > <ClickAwayListener onClickAway={this.filterHandleClose}>                        
                          <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                            <MenuItem onClick={() => this.changeEmailType('')}>{this.props.users.languageStateForRedux?.common?.all}</MenuItem>
                            <MenuItem onClick={() => this.changeEmailType('campaigns')}>{this.props.users.languageStateForRedux?.dropdowns?.campaigns?.emailCampaigns}</MenuItem>
                            <MenuItem onClick={() => this.changeEmailType('transactional')}>{this.props.users.languageStateForRedux?.dropdowns?.campaigns?.transEmail}</MenuItem>
                            {this.props.project?.project?.eventType === 'Sale enablement project' &&
                              <MenuItem onClick={() => this.changeEmailType('meetings')}>{this.props.users.languageStateForRedux?.dropdowns?.campaigns?.meeting}</MenuItem>
                            }
                          </MenuList>                          
                        </ClickAwayListener>
                      </Box>
                    </Fade>
                  )}
                </Popper>
              </Grid>

              {selectedEmailType !== '' &&
                <>
                  <Grid item>
                    <Box component={'span'} ml={2} className="small-subtitle primary-text">
                      {selectedEmailType === 'campaigns' ? this.props.users.languageStateForRedux?.dropdowns?.campaigns?.chooseCampaigns : 
                       selectedEmailType === 'transactional' ? 'Choose Transactional Emails':
                        'Choose Meetings'
                      }
                    </Box>
                  </Grid>

                  <Grid item>
                    <Box
                      component={Button}
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.background.light,
                        color: theme.palette.common.black,
                        textTransform: 'capitalize !important',
                        fontWeight: '400',

                        '&:hover, &.active': {
                          backgroundColor: '#000056 !important',
                          color: theme.palette.primary.contrastText,
                        }
                      }}
                      size='small'
                      //  className={'active'}
                      disableElevation
                      ref={this.anchorRef2}
                      aria-controls={open2 ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={this.handleToggle2}
                      endIcon={<ArrowDropDownIcon />}
                    >
                      {selectedEmailType === 'campaigns' ?
                        (campaignsFilterOptionsSelected.length !== 1 ? `${campaignsFilterOptionsSelected.length} campaigns selected` : campaignsFilterOptionsSelected[0].campaignName)
                        :
                       selectedEmailType === 'transactional' ?
                        (transactionalFilterOptionsSelected.length === 1 ? transactionalFilterOptionsSelected[0].pageName : 'All')
                        :
                        (meetingsFilterOptionsSelected.length !== 1 ? `${meetingsFilterOptionsSelected.length} meetings selected` : meetingsFilterOptionsSelected[0].meetingTitle)
                      }
                    </Box>
                    <Popper open={open2} sx={{ top: 10, }} className='filter-options' placement="bottom-start" transition anchorEl={this.anchorRef2?.current} role={undefined} disablePortal>
                      {({ TransitionProps, placement }) => (
                        <Fade {...TransitionProps} timeout={250} >
                          <Box className='option-list' sx={{ backgroundColor: theme.palette.background.light, borderRadius: '4px', marginTop: '8px', overflowY: 'scroll', maxHeight: 195, }}>
                            <ClickAwayListener onClickAway={this.filterHandleClose}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                <MenuItem className='checkbox-item'>
                                  <FormControlLabel
                                    control={
                                      <Checkbox className="checkbox" sx={{ marginLeft: 9, }} color="default" name="all"
                                        onChange={(e) => this.handleFilterChange(e, selectedEmailType)}
                                        checked={selectedEmailType === 'campaigns' ? campaignsFilterOptionsSelectedAll : 
                                          selectedEmailType === 'transactional' ? transactionalFilterOptionsSelectedAll :
                                          meetingsFilterOptionsSelectedAll
                                        }
                                      />
                                    }
                                    label={this.props.users.languageStateForRedux?.common?.all}
                                    sx={{ padding: 0, }}
                                  />
                                </MenuItem>
                                {selectedEmailType === 'campaigns' ? (
                                  <>
                                    {campaignsFilterOptionsList.length > 0 && campaignsFilterOptionsList.map(option => {
                                      return (
                                        <MenuItem key={option._id} className='checkbox-item'>
                                          <FormControlLabel
                                            control={
                                              <Checkbox className="checkbox" color="default" name={option._id} onChange={(e) => this.handleFilterChange(e, 'campaigns', option)} checked={campaignsFilterOptionsSelected.findIndex(campaignSelected => campaignSelected._id === option._id) > -1 ? true : false} />
                                            }
                                            label={option.campaignName}
                                          />
                                        </MenuItem>
                                      )
                                    })
                                    }
                                  </>
                                ) : 
                                selectedEmailType === 'transactional' ? (
                                  <>
                                    {transactionalFilterOptionsList.length > 0 && transactionalFilterOptionsList.map(option => {
                                      return (
                                        <MenuItem className='checkbox-item'>
                                          <FormControlLabel
                                            control={
                                              <Checkbox className="checkbox" color="default" name={option._id} onChange={(e) => this.handleFilterChange(e, 'transactional', option)} checked={transactionalFilterOptionsSelected.findIndex(transactionalSelected => transactionalSelected._id === option._id) > -1 ? true : false} />
                                            }
                                            label={option.pageName}
                                          />
                                        </MenuItem>
                                      )
                                    })
                                    }
                                  </>
                                ): (
                                  <>
                                    {meetingsFilterOptionsList.length > 0 && meetingsFilterOptionsList.map(option => {
                                      return (
                                        <MenuItem key={option._id} className='checkbox-item'>
                                          <FormControlLabel
                                            control={
                                              <Checkbox className="checkbox" color="default" name={option._id} onChange={(e) => this.handleFilterChange(e, 'meetings', option)} checked={meetingsFilterOptionsSelected.findIndex(meetingSelected => meetingSelected._id === option._id) > -1 ? true : false} />
                                            }
                                            label={option.meetingTitle}
                                          />
                                        </MenuItem>
                                      )
                                    })
                                    }
                                  </>
                                )}
                              </MenuList>
                            </ClickAwayListener>
                          </Box>
                        </Fade>
                      )}
                    </Popper>
                  </Grid>
                </>}

              <Grid item>
                <Box component={'span'} ml={2} className="small-subtitle primary-text">{this.props.users.languageStateForRedux?.dropdowns?.campaigns?.chooseDate}</Box>
              </Grid>

              <Grid item>
                <Box
                  component={Button}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.background.light,
                    color: theme.palette.common.black,
                    textTransform: 'capitalize !important',
                    fontWeight: '400',
                    '&:hover, &.active': {
                      backgroundColor: '#000056 !important',
                      color: theme.palette.primary.contrastText,
                    }
                  }}
                  size='small'
                  // className={'active'}
                  disableElevation
                  ref={this.anchorRef3}
                  aria-controls={open3 ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleToggle3}
                  endIcon={<ArrowDropDownIcon />}
                >
                  {this.chooseFilter(selectedDateFilter)}
                </Box>
                <Popper open={open3} placement="bottom-start" className='filter-options' transition anchorEl={this.anchorRef3?.current} role={undefined} disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Fade {...TransitionProps} timeout={250} >
                      <Box sx={{
                        backgroundColor: theme.palette.common.white, boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)', borderRadius: '4px', marginTop: '8px',
                      }}
                      > <ClickAwayListener onClickAway={this.filterHandleClose}>
                          <Stack spacing={1} p={1.5} direction="column">
                            <Button size='small' variant={selectedDateFilter === 'All' ? 'contained' : 'outlined'} color={selectedDateFilter === 'All' ? 'primary' : ''} onClick={() => this.filterByDate("all", "All")}>{this.props.users.languageStateForRedux?.common?.all}</Button>
                            <Button size='small' variant={selectedDateFilter === 'Today' ? 'contained' : 'outlined'} color={selectedDateFilter === 'Today' ? 'primary' : ''} onClick={() => this.filterByDate("today", "Today")}>{this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.today}</Button>
                            <Button size='small' variant={selectedDateFilter === 'Last 07 Days' ? 'contained' : 'outlined'} color={selectedDateFilter === 'Last 07 Days' ? 'primary' : ''} onClick={() => this.filterByDate("last_7_days", "Last 07 Days")}>{this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last7}</Button>
                            <Button size='small' variant={selectedDateFilter === 'Last 30 Days' ? 'contained' : 'outlined'} color={selectedDateFilter === 'Last 30 Days' ? 'primary' : ''} onClick={() => this.filterByDate("last_30_days", "Last 30 Days")}>{this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last30}</Button>
                            <Button size='small' variant={selectedDateFilter === 'Last 60 Days' ? 'contained' : 'outlined'} color={selectedDateFilter === 'Last 60 Days' ? 'primary' : ''} onClick={() => this.filterByDate("last_60_days", "Last 60 Days")}>{this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last60}</Button>
                            <Button size='small' variant={selectedDateFilter === 'Last 90 Days' ? 'contained' : 'outlined'} color={selectedDateFilter === 'Last 90 Days' ? 'primary' : ''} onClick={() => this.filterByDate("last_90_days", "Last 90 Days")}>{this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last90}</Button>
                            <Button size='small' variant={selectedDateFilter === 'Custom Range' ? 'contained' : 'outlined'} color={selectedDateFilter === 'Custom Range' ? 'primary' : ''} onClick={() => this.filterByDate("custom", "Custom Range")}>{this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.customRange}</Button>
                          </Stack>
                        </ClickAwayListener>
                      </Box>
                    </Fade>
                  )}
                </Popper>
              </Grid>


              {showCustom &&
                <Grid item className='form-group anaSelectDRange'>
                  <Grid container alignItems={'center'} spacing={1} className="field-group inputField">
                    <Grid item className='formDate formIcon'>
                      <DatePicker
                        selected={startDate}
                        onChange={this.onChangeDate}
                        selectsStart
                        className="outlineButton"
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={new Date()}
                      />
                    </Grid>

                    <Grid item>
                      <Box component={'span'} ml={2} className="small-subtitle primary-text">{this.props.users.languageStateForRedux?.common?.to}</Box>
                    </Grid>

                    <Grid item className='formDate formIcon'>
                      <DatePicker
                        selected={endDate}
                        onChange={this.onChangeEndDate}
                        selectsEnd
                        startDate={startDate}
                        className="outlineButton"
                        endDate={endDate}
                        minDate={startDate}
                        maxDate={new Date()}
                      />
                    </Grid>

                    <Grid item>
                      <IconButton title='Reset Custom Date' aria-label="delete" onClick={this.resetDate}>
                        <ReloadIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item>
            <Button style={{ textTransform: 'capitalize', }}
              startIcon={<img alt="" src={RefreshIcon} />}
              variant="text"
              disableRipple
              size='small'
              ref={this.anchorRef4}
              aria-controls={open4 ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={this.handleToggle4}
            >
              {this.props.users.languageStateForRedux?.dropdowns?.attendees?.refresh} {refreshIntervalPeriod && `Every ${refreshIntervalPeriod} Minutes`}
            </Button>
            <Popper open={open4} placement="bottom-end" className='filter-options' transition anchorEl={this.anchorRef4?.current} role={undefined} disablePortal>
              {({ TransitionProps, placement }) => (
                <Fade {...TransitionProps} timeout={250} >
                  <Box sx={{
                    backgroundColor: theme.palette.common.white, boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)', borderRadius: '4px', marginTop: '8px',
                  }}
                  > <ClickAwayListener onClickAway={this.filterHandleClose}>
                      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                        <MenuItem onClick={this.customAnalyticsUpdate} ><img className='mr-2' alt="" src={RefreshIcon} /> {this.props.users.languageStateForRedux?.dropdowns?.attendees?.refreshNow}</MenuItem>
                        <MenuItem onClick={() => this.refreshByInterval(1)}><img className='mr-2' alt="" src={RefreshIcon} />{this.props.users.languageStateForRedux?.dropdowns?.attendees?.one} </MenuItem>
                        <MenuItem onClick={() => this.refreshByInterval(2)}><img className='mr-2' alt="" src={RefreshIcon} /> {this.props.users.languageStateForRedux?.dropdowns?.attendees?.two} </MenuItem>
                        <MenuItem onClick={() => this.refreshByInterval(5)}><img className='mr-2' alt="" src={RefreshIcon} /> {this.props.users.languageStateForRedux?.dropdowns?.attendees?.five} </MenuItem>
                        <MenuItem onClick={() => this.refreshByInterval(10)}><img className='mr-2' alt="" src={RefreshIcon} /> {this.props.users.languageStateForRedux?.dropdowns?.attendees?.ten} </MenuItem>

                      </MenuList>
                    </ClickAwayListener>
                  </Box>
                </Fade>
              )}
            </Popper>
          </Grid>
        </Grid>

        {showProgress &&
          <Box p={0.5} sx={{ backgroundColor: '#009C7E', textAlign: 'center', fontSize: '12px', color: '#ffffff', }}>This campaign is in processing and We are still collecting analytics for this campaign.</Box>
        }

        <div className="dashboardMiddleArea">
          <div className="analyticsPage">
            <div className="analyticsMails">
              <div className="analyticsMailsInner row">
                <div className="analyticColl col-12 col-sm-3 col-md-3 col-lg-2">
                  <div className="anaTotalMail">
                    <h3>{totalEmail}</h3>
                    <span>{this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.sentMail}</span>
                  </div>
                </div>
                <div className="analyticColl col-12 col-sm-3 col-md-3 col-lg-2">
                  <div className="anaTotalMail">
                    <h3>
                      {deliverRate}% ({totalSendEmail})
                    </h3>
                    <span>{this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.deliveredMail}</span>
                  </div>
                </div>
                <div className="analyticColl col-12 col-sm-3 col-md-3 col-lg-2">
                  <div className="anaTotalMail">
                    <h3>
                      {hardBouncedRate}% ({hardBounced})
                    </h3>
                    <span>{this.props.users.languageStateForRedux?.formfields?.attendees?.hard}</span>
                  </div>
                </div>
                <div className="analyticColl col-12 col-sm-3 col-md-3 col-lg-2">
                  <div className="anaTotalMail">
                    <h3>
                      {softBouncedRate}% ({softBounced})
                    </h3>
                    <span>{this.props.users.languageStateForRedux.formfields.attendees.soft}</span>
                  </div>
                </div>
                <div className="analyticColl col-12 col-sm-3 col-md-3 col-lg-2">
                  <div className="anaTotalMail">
                    <h3>
                      {rejectRate}% ({reject})
                    </h3>
                    <span>{this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.rejectedEmail}</span>
                  </div>
                </div>
                <div className="analyticColl col-12 col-sm-3 col-md-3 col-lg-2">
                  <div className="anaTotalMail">
                    <h3>
                      {openRate}% ({openEmail})
                    </h3>
                    <span>{this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.openedRates}</span>
                  </div>
                </div>
                <div className="analyticColl col-12 col-sm-3 col-md-3 col-lg-2">
                  <div className="anaTotalMail">
                    <h3>
                      {clickRate}% ({clickEmail})
                    </h3>
                    <span>{this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.clickRates}</span>
                  </div>
                </div>
                <div className="analyticColl col-12 col-sm-3 col-md-3 col-lg-2">
                  <div className="anaTotalMail">
                    <h3>
                      {spamRate}% ({spam})
                    </h3>
                    <span>{this.props.users.languageStateForRedux?.formfields?.attendees?.spam}</span>
                  </div>
                </div>
                <div className="analyticColl col-12 col-sm-3 col-md-3 col-lg-2">
                  <div className="anaTotalMail">
                    <h3>
                      {unsubRate}% ({unsub})
                    </h3>
                    <span>{this.props.users.languageStateForRedux?.formfields?.attendees?.unsubscribe}</span>
                  </div>
                </div>

                <div className="analyticColl col-12 col-sm-3 col-md-3 col-lg-2">
                  <div className="anaTotalMail">
                    <h3>
                      {failedRate}% ({failed})
                    </h3>
                    <span>{this.props.users.languageStateForRedux?.formfields?.attendees?.failedRates}</span>
                  </div>
                </div>
                <div className="analyticColl col-12 col-sm-3 col-md-3 col-lg-2">
                  <div className="anaTotalMail">
                    <h3>
                      {queRate}% ({que})
                    </h3>
                    <span>{this.props.users.languageStateForRedux?.formfields?.attendees?.queuedRates}</span>
                  </div>
                </div>
                <div className="analyticColl col-12 col-sm-3 col-md-3 col-lg-2">
                  <div className="anaTotalMail">
                    <h3>
                      {deferralRate}% ({deferral})
                    </h3>
                    <span>{this.props.users.languageStateForRedux?.formfields?.attendees?.defferalRates}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="analyticsGraphtableArea">
              <div className="analyticsDimension graphBox">
                {deliveredArray &&
                  deliveredArray.length > 0 &&
                  Object.keys(this.props.events.reports.mandrill).length >
                  0 && (
                    <Chart
                      totalBounced={totalBounced}
                      deliveredArray={deliveredArray}
                      rejectArray={rejectArray}
                    />
                  )}
              </div>
              <div className="analyticsSesons graphBox">
                {openArray &&
                  openArray.length > 0 &&
                  Object.keys(this.props.events.reports.mandrill).length >
                  0 && (
                    <LineChart
                      openArray={openArray}
                      clickArray={clickArray}
                    />
                  )}
              </div>
            </div>

            <Grid mt={2} container spacing={2} mb={3} className="top-filter-area">
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <Box
                      component={Button}
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.background.light,
                        color: theme.palette.common.black,
                        textTransform: 'capitalize',
                        fontWeight: '400',

                        '&:hover, &.active': {
                          backgroundColor: theme.palette.primary.dark,
                          color: theme.palette.primary.contrastText,
                        }
                      }}
                      //className='active'
                      size='small'
                      disableElevation
                      ref={this.anchorRefFilterBy}
                      aria-controls={this.filterByDropdown ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={this.handleToggleFilterBy}
                      endIcon={<ArrowDropDownIcon />}
                    >
                      {this.props.users.languageStateForRedux?.dropdowns?.attendees?.filterBy}
                    </Box>
                    <Popper open={filterByDropdown} style={{ zIndex: 3, }} placement="bottom-start" transition anchorEl={this.anchorRefFilterBy.current} role={undefined} disablePortal>
                      {({ TransitionProps, placement }) => (
                        <Fade {...TransitionProps} timeout={250} >
                          <Box className='option-list' sx={{ backgroundColor: theme.palette.background.light, borderRadius: '4px', marginTop: '8px', overflowY: 'scroll', maxHeight: '200px', }}>
                            <ClickAwayListener onClickAway={this.filterHandleClose}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                <MenuItem className='checkbox-item'>
                                  <FormControlLabel
                                    control={
                                      <Checkbox sx={{ padding: 0, }} color="default" name="all" onChange={(e) => this.handleFilterEmailsByStatus(e)} checked={statusFilterOptionsSelectedAll} />
                                    }
                                    label={ (this.props.users.languageStateForRedux?.common?.all) + ` (${totalEmail})`}
                                    sx={{ padding: 0, }}

                                  />
                                </MenuItem>
                                {statusFilterOptionsList.map((option) => {
                                  let countOfStatus = 0;
                                  let label;
                                  switch (option) {
                                    case 'send':
                                      countOfStatus = totalSendEmail;
                                      label = this.props.users.languageStateForRedux?.dropdowns?.attendees?.delivered;
                                      break;
                                    case 'reject':
                                      countOfStatus = reject;
                                      label = this.props.users.languageStateForRedux.dropdowns?.attendees?.rejected;
                                      break;
                                    case 'hard_bounce':
                                      countOfStatus = hardBounced;
                                      label = this.props.users.languageStateForRedux?.dropdowns?.attendees?.hardBounce;
                                      break;
                                    case 'soft_bounce':
                                      countOfStatus = softBounced;
                                      label = this.props.users.languageStateForRedux?.dropdowns?.attendees?.softBounce;
                                      break;
                                    case 'spam':
                                      countOfStatus = spam;
                                      label = this.props.users.languageStateForRedux?.dropdowns?.attendees?.spam;
                                      break;
                                    case 'unsub':
                                      countOfStatus = unsub;
                                      label = this.props.users.languageStateForRedux?.dropdowns?.attendees?.unsubscribe;
                                      break;
                                    case 'failed':
                                      countOfStatus = failed;
                                      label = this.props.users.languageStateForRedux?.buttons?.integration.failed;
                                      break;
                                    case 'queued':
                                      countOfStatus = que;
                                      label = this.props.users.languageStateForRedux?.dropdowns?.attendees?.queued;
                                      break;
                                    case 'deferral':
                                      countOfStatus = deferral;
                                      label = this.props.users.languageStateForRedux?.dropdowns?.attendees?.deferral;
                                      break;
                                  }
                                  return (
                                    <MenuItem className='checkbox-item'>
                                      <FormControlLabel
                                        control={
                                          <Checkbox color="default" name={option} onChange={(e) => this.handleFilterEmailsByStatus(e)} checked={statusFilterOptionsSelected.indexOf(option) > -1 ? true : false} />
                                        }
                                        label={`${label} (${countOfStatus})`}
                                      />
                                    </MenuItem>
                                  )
                                })}
                              </MenuList>
                            </ClickAwayListener>
                          </Box>
                        </Fade>
                      )}
                    </Popper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={1.25} className="team-dash-right">
                  <Grid item className="">
                    <form onSubmit={this.handleSubmit}>
                      <TextField
                        name="searchText"
                        value={searchText}
                        onChange={this.handleChange}
                        placeholder={this.props.users.languageStateForRedux?.common?.search}
                        className="search"
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img alt="" src={SearchIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <TableContainer className="list-table">
              <Table className="table" style={{ minHeight: 'auto', }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={'status ' + (sort_by === 'status' ? (sortOrder === 'asc' ? 'sort-asc' : 'sort-des') : '')}
                      onClick={() => this.sorting("status")}
                    >{this.props.users.languageStateForRedux?.common?.status}</TableCell>
                    <TableCell className='email'>{this.props.users.languageStateForRedux?.formfields?.attendees?.sender}</TableCell>
                    <TableCell
                      className={'email ' + (sort_by === 'email' ? (sortOrder === 'asc' ? 'sort-asc' : 'sort-des') : '')} style={{ left: 50, }}
                      onClick={() => this.sorting("email")}
                    >
                      {this.props.users.languageStateForRedux?.column_names?.teams?.email}
                    </TableCell>
                    <TableCell
                      className={'long-text ' + (sort_by === 'mandrillEmailData.subject' ? (sortOrder === 'asc' ? 'sort-asc' : 'sort-des') : '')}
                      onClick={() => this.sorting("mandrillEmailData.subject")}
                    >{this.props.users.languageStateForRedux?.column_names?.attendees?.subject}</TableCell>
                    <TableCell className='text'>{this.props.users.languageStateForRedux?.column_names?.campaigns?.opens}</TableCell>
                    <TableCell className='text'>{this.props.users.languageStateForRedux?.column_names?.campaigns?.clicks}</TableCell>
                    <TableCell className='text'>{this.props.users.languageStateForRedux?.formfields?.attendees?.viewContent}</TableCell>
                    <TableCell
                      className={'text ' + (sort_by === 'emailTag' ? (sortOrder === 'asc' ? 'sort-asc' : 'sort-des') : '')}
                      onClick={() => this.sorting("emailTag")}
                    >{this.props.users.languageStateForRedux?.formfields?.attendees?.type}</TableCell>
                    <TableCell
                      className={'date-time ' + (sort_by === 'sentAt' ? (sortOrder === 'asc' ? 'sort-asc' : 'sort-des') : '')}
                      onClick={() => this.sorting("sentAt")}
                    >{this.props.users.languageStateForRedux?.formfields?.attendees?.timeStamp}</TableCell>
                    <TableCell className='options'>{this.props.users.languageStateForRedux?.common?.action}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {emailData && emailData.length > 0 ? emailData.map((data) => {

                    let lv_status = data.status === 'send' ||
                      data.status === 'scheduled' ? 'sent' : data.status; 

                    let classAlert =
                      data.status === 'bounce' ||
                        data.status === 'hard_bounce' ||
                        data.status === 'soft_bounce' ||
                        data.status === 'reject' ||
                        data.status === 'bounced' ||
                        data.status === 'hard_bounced' ||
                        data.status === 'soft_bounced' ||
                        data.status === 'rejected' ||
                        data.status === 'failed'
                        ? 'tdalert danger'
                        : 'success';

                    let statusTip =
                      data.status === 'bounce' ||
                        data.status === 'hard_bounce' ||
                        data.status === 'soft_bounce' ||
                        data.status === 'reject' ||
                        data.status === 'bounced' ||
                        data.status === 'hard_bounced' ||
                        data.status === 'soft_bounced' ||
                        data.status === 'rejected' ||
                        data.status === 'failed' ? (
                        <ReactTooltip />
                      ) : null;

                    let tip =
                      data.status === 'bounce' ||
                        data.status === 'bounced' ||
                        data.status === 'hard_bounce' ||
                        data.status === 'soft_bounce' ||
                        data.status === 'reject' ||
                        data.status === 'hard_bounced' ||
                        data.status === 'soft_bounced' ||
                        data.status === 'rejected' ||
                        data.status === 'failed'
                        ? (data.emailData && data.emailData.bounce_description) || null
                        : null;

                    let sender =
                      data.status === 'failed'
                        ? data.mandrillEmailData.from_email
                        : data.emailData && data.emailData.sender
                          ? data.emailData.sender
                          : data.mandrillEmailData && data.mandrillEmailData.from_email
                            ? data.mandrillEmailData.from_email
                            : 'N/A';

                    let email = data.email || 'N/A';

                    let opens = data.emailData && data.emailData.opens &&
                      Array.isArray(data.emailData.opens) && data.emailData.opens.length > 0
                      ? 1
                      : 0;

                    let clicks = data.emailData && data.emailData.clicks &&
                      Array.isArray(data.emailData.clicks) && data.emailData.clicks.length > 0
                      ? 1
                      : 0;

                    let url = (<a target="_blank"
                      href={data.emailTag === 'campaigns' ?
                        `/project-dashboard/preview-campaign-with-exit/${eventId}?templateId=${data.templateId}` : 
                        data.emailTag === 'transactional' ? `/project-dashboard/preview-transactional/${eventId}?templateId=${data.templateId}`: 
                        `/project-dashboard/preview-meeting/${eventId}/${data.email}?meetingId=${data.templateId}`
                      }
                    >
                      <Button variant='text' color='primary' size='small'>{this.props.users.languageStateForRedux?.buttons?.campaigns?.view}</Button>
                    </a>);

                    let emailType = data.emailTag === 'campaigns' ? 'Campaign Email' : 
                      data.emailTag === 'transactional' ? 'Transactional Email':
                      'Meeting Email';

                    let timeStamp = moment.tz(data.sentAt, this.props.project?.project?.timezone).format('MMM, Do YYYY, LT')

                    let action =
                      data.status === 'bounce' ||
                        data.status === 'hard_bounce' ||
                        data.status === 'soft_bounce' ||
                        data.status === 'reject' ||
                        data.status === 'failed' ||
                        data.status === 'bounced' ||
                        data.status === 'hard_bounced' ||
                        data.status === 'soft_bounced' ||
                        data.status === 'rejected' ? (
                        <div
                          onClick={() => this.resendEmail(data._id, data.emailTag)}
                        >
                          Resend Email
                        </div>
                      ) : (
                        ''
                      );
                    return (
                      <TableRow>
                        <TableCell className='status'>
                          {tip ?
                            <Tooltip title={tip} placement='bottom' arrow>
                              <span className={`table-status ${classAlert}`}>
                                {lv_status}
                              </span>
                            </Tooltip>
                            :
                            <span className={`table-status ${classAlert}`}>
                              {lv_status}
                            </span>
                          }
                        </TableCell>
                        <TableCell className='email '> {sender}</TableCell>
                        <TableCell className='email '> {email}</TableCell>
                        <TableCell className='long-text'>
                          <Tooltip title={data.mandrillEmailData.subject} arrow placement='bottom'>
                            <>
                              {data.mandrillEmailData.subject}
                            </>
                          </Tooltip>
                        </TableCell>
                        <TableCell className='text'>{opens}</TableCell>
                        <TableCell className='text'>{clicks}</TableCell>
                        <TableCell className='text' >{url}</TableCell>
                        <TableCell className='text' >{emailType}</TableCell>
                        <TableCell className='date-time'>{timeStamp}</TableCell>
                        <TableCell className='options'>
                          {action !== '' &&
                            <>
                              {' '}
                              <div className='options-button'>
                                <img alt="" src={MoreIcon} />
                                {' '}
                              </div>
                              <div className="options-list">
                                {action}
                              </div>
                            </>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })
                    :
                    <TableRow>
                      <TableCell style={{ textAlign: "center" }}>
                      {this.props.users.languageStateForRedux?.common?.noRecords}
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>

              </Table>
            </TableContainer>

            {emailData && emailData.length > 0 ? (
              <Grid container alignItems="center" justifyContent="space-between" className="tablePagination">
                <Grid item>
                  <Box component="span" className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.showing} {startCount} {this.props.users.languageStateForRedux?.pagination?.to} {endCount} {this.props.users.languageStateForRedux?.pagination?.of} {totalEmailsCount}</Box>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center" >
                    <Grid item>
                      <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.shows}</span>
                      <select value={itemCount} onChange={this.selectItemCount}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.entries}</span>
                    </Grid>
                    <Grid item>
                      <Pagination
                        activePage={page_no}
                        itemsCountPerPage={this.state.itemCount}
                        totalItemsCount={totalEmailsCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                        prevPageText={<NavigateBeforeIcon style={{ fontSize: 18 }} />}
                        nextPageText={<NavigateNextIcon style={{ fontSize: 18 }} />}
                        itemClassFirst="first d-none"
                        itemClassLast="last d-none"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : null
            }
          </div>
        </div>
        {loading ? <Loader /> : null}
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.Events,
  users: state.Users,
  campaigns: state.Campaigns,
  attendees: state.Attendees,
  meeting: state.Meeting,
  project: state.Projects
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      reportDataRequest: Actions.emailDataReportsRequest,
      eventRequest: Actions.listSingleEventRequest,
      listAllCampaignsRequest: Actions.listAllCampaignsRequest,
      listAllTransactionalEmailTemplates: Actions.listAllTransactionalEmailTemplatesRequest,
      listAllMeetingsRequest: Actions.listAllMeetingsRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(withRouter(EmailAnalytics))

