import React, { Component } from 'react'
import { connect } from 'react-redux';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import SubIcon from '../../assets/images/icons/sub-icon.svg';
import AddIcon from '../../assets/images/icons/add-icon.svg';
import EventRegistartion3 from '../CreateEvent/eventRegistration';
import FinishPopUp from '../Projects/FinishPopUp';
import Box from '@mui/material/Box';

function CreateProjectStep3 (props) {
    return (
      <Box px={{xs:(2), lg:(3)}} className="step3-outer stepper-outer-common">

        <div className="step1-heading">
          <h2 className="subsecondary"> {props.users.languageStateForRedux?.newProject?.headingThree} </h2>
        </div>

        <EventRegistartion3 saveDetails = { props.saveTemplateParams } stepperFinish = { props.stepperFinish } />
      </Box> 
    )
  
}

const mapStateToProps = state => ({
  users: state.Users
});

export default connect(mapStateToProps,null)(CreateProjectStep3)
