import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
  success: false,
  updateSuccess: false,
  error: false,
  message: null,
  notifications: null,
  total: 0,
  awsNotificationData:null,
}

const notiSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: true,
    notifications: action.notifications,
    total: action.total,
    unreadCount: action.unreadCount,
    message: action.message,
  }
}

const notiError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const notiRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    updateSuccess: false,
    error: false,
    message: null,
  }
}

const updateNotiSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, updateSuccess: true, message: action.message }
}

const updateNotiError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const updateNotiRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: false,
    updateSuccess: false,
    error: false,
    message: null,
  }
}

const setAwsNotificationMessage = (state = INITIAL_STATE, action) => { 
  return {
      ...state,
      awsNotificationData: action.data      
  }
}

const HANDLERS = {
  [Constants.LIST_NOTI_REQUEST]: notiRequest,
  [Constants.LIST_NOTI_SUCCESS]: notiSuccess,
  [Constants.LIST_NOTI_FAILURE]: notiError,
  [Constants.UPDATE_NOTI_REQUEST]: updateNotiRequest,
  [Constants.UPDATE_NOTI_SUCCESS]: updateNotiSuccess,
  [Constants.UPDATE_NOTI_FAILURE]: updateNotiError,
  [Constants.SET_AWS_NOTIFICATION_MESSAGE]: setAwsNotificationMessage
}

export default createReducer(INITIAL_STATE, HANDLERS)
