const getUnLayerConfig = (apiUrl, eventId, formEnabled) => {
  const path = 'registerUser'
  return {
    id: 'pageDesignEditor',
    projectId: 21377,
    displayMode: 'web',
    tools: {
      form: {
        enabled: false,
        properties: {
          action: {
            editor: {
              data: {
                actions: [
                  {
                    label: 'form',
                    method: 'POST',
                    url: `${apiUrl}/events/${eventId}/${path}`,
                  },
                ],
              },
            },
          },
        },
      },
    },
    customJS: [
     // window.location.protocol + '//' + window.location.host + '/custom.js',
     'https://examples.unlayer.com/examples/react-custom-tool/custom.js',
    ],
  }
}

export default getUnLayerConfig
