// Importing necessary React and Material-UI components and styles
import React, { Component } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import DatePicker from 'react-datepicker';
import IconButton from '@material-ui/core/IconButton';
import ReloadIcon from '@material-ui/icons/Refresh';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../theme/theme';
import moment from 'moment';
import SearchIcon from '../../../../src/assets/images/icons/search.svg';
import MeetingIcon from '../../../../src/assets/images/icons/meetingIcon-white.svg';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '../../../sass/main.scss';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Actions } from '../../../Redux/Actions';
import { getMeetingsIntegrationDetails } from 'Redux/API';

import {
  convertTZ,
  getProjectIdFromUrl,
  getEventURL,
} from '../../../helpers/common';
import Loader from '../../../Components/Loader/loader';
import { Modal } from 'react-responsive-modal';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CreateMeeting from './CreateMeeting';
import Pagination from 'react-js-pagination';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { createNotification } from '../../../helpers';
import { styled } from '@material-ui/core/styles';
import CustomHTMLTooltip from 'Components/CustomHTMLTooltip';
import EmailAnalytics from '../EmailAnalytics';
import SettingsLayout from './Settings/SettingsLayout';

// Styling for the Avatar component
const styles = {
  avatar: {
    backgroundColor: '#5141E7',
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
};

// Styling for the TextField component using the 'styled' utility from Material-UI
const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-input': {
    padding: '12.5px 14px',
  },
});

// Define the parent component
class Meetings extends Component {
  /**
  * Constructor for ParentComponent.
  * @constructor
  * @param {Object} props - The properties passed to the component.
  */
  constructor(props) {
    // State initialization and default values
    super(props);
    this.anchorRef3 = React.createRef(); // Ref for an HTML element
    this.state = {
      page_no: 1,
      itemCount: 10,
      startCount: 1,
      endCount: 0,
      searchText: '',
      limit: 10,
      sortBy: 'meetingTitle',
      order: 'asc',
      filterByStatus: {},
      filterStatus: [],
      errors: {},
      status: ['Sent', 'Draft'],
      meetingList: [],
      meetingStatus: '',
      filterCheck: 'all',
      pastCount: 0,
      onGoingCount: 0,
      upComingCount: 0,
      open: false,
      showCreateMeeting: false,
      loading: false,
      openDeleteMeetingModal: false,
      replicateMeeting: false,
      meetingId: '',
      startDate: new Date(),
      endDate: new Date(),
      filterDate: false,
      editData: '',
      isEdit: false,
      addMeetingStateData: '',
      loadMoreNumber: 0,
      openMeetingReport: false,
      meetingSelected: null,
      allowed: {},
      selectedDateFilter: 'All',
      showCustom: false,
      openSettings: false,
      integrationId: ''
    };
  }


  /**
     * @function componentDidMount
     * @description Lifecycle method invoked after the component is mounted.
     * Fetches data and sets the initial component state.
     */
  componentDidMount = () => {
    const eventId = getProjectIdFromUrl();
    if (this.props.Meeting.fromEditor) {
      let editData = {
        _id: this.props?.location?.state?.meetingId,
        eventId: eventId,
      };
      this.setState({
        isEdit: this.props?.location?.state?.isEdit,
        meetingId: this.props?.location?.state?.meetingId,
        editData,
        showCreateMeeting: true,
      });
    }
    this.setState(
      {
        eventId,
      },
      () => {
        this.listMeeting();
      },
    );
    if (this.props.location.pathname.includes("/project-dashboard/meetings/settings")) {
      this.handleOpenSettings();
    }
  };

  /**
 * @function componentDidUpdate
 * @param {object} prevProps - Previous properties.
 * @param {object} prevState - Previous state.
 * @description Lifecycle method called after the component updates.
 *   Handles updates in Meeting-related actions and updates state accordingly.
 */
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.project?.project?.permissions) {
      let allowed = {};

      if (
        this.props.project.project.permissions.find(
          (o) => o.permission_name === 'createMeetingForAll',
        ) ||
        this.props.project.project.permissions.find(
          (o) => o.permission_name === 'createMeetingForMyTeam',
        ) ||
        this.props.project.project.permissions.find(
          (o) => o.permission_name === 'createMeetingForMyOwn',
        )
      ) {
        allowed['Create Meeting'] = true;
      }

      if (
        this.props.project.project.permissions.find(
          (o) => o.permission_name === 'viewAllMeetings',
        ) ||
        this.props.project.project.permissions.find(
          (o) => o.permission_name === 'viewMyOwnMeetings',
        ) ||
        this.props.project.project.permissions.find(
          (o) => o.permission_name === 'viewMyTeamMeetings',
        )
      ) {
        allowed['View Meeting'] = true;
      }

      if (
        this.props.project.project.permissions.find(
          (o) => o.permission_name === 'meetingSetting',
        )
      ) {
        allowed['Meeting Setting'] = true;
      }

      this.state.allowed = allowed;
    }

    if (prevState.itemCount !== this.state.itemCount) {
      if (this.state.itemCount < this.state.totalResults) {
        this.setState({
          endCount: parseInt(this.state.itemCount),
        });
      } else {
        this.setState({
          endCount: parseInt(this.props.Meeting.totalResults),
        });
      }
    }

    if (
      this.props.Meeting.deleteMeetingSuccess !==
      prevProps.Meeting.deleteMeetingSuccess &&
      this.props.Meeting.deleteMeetingSuccess === true
    ) {
      createNotification('success', this.props.Meeting.message);
      this.setState(
        {
          loading: false,
          openDeleteMeetingModal: false,
        },
        () => this.listMeeting(),
      );
    }

    if (
      this.props.Meeting.addMeetingSuccess !==
      prevProps.Meeting.addMeetingSuccess &&
      this.props.Meeting.addMeetingSuccess === true
    ) {
      setTimeout(() => {
        this.listMeeting();
      }, 200);
      this.setState({
        loading: false,
      });
    }

    if (
      this.props.Meeting.replicateMeetingSuccess !==
      prevProps.Meeting.replicateMeetingSuccess &&
      this.props.Meeting.replicateMeetingSuccess === true
    ) {
      createNotification('success', this.props.Meeting.message);
      this.setState(
        {
          loading: false,
          openDeleteMeetingModal: false,
          replicateMeeting: false,
        },
        () => this.listMeeting(),
      );
    }

    if (
      this.props.Meeting.updateMeetingSuccess !==
      prevProps.Meeting.updateMeetingSuccess &&
      this.props.Meeting.updateMeetingSuccess === true
    ) {
      setTimeout(() => {
        this.listMeeting();
      }, 200);
      this.setState({
        loading: false,
      });
    }

    if (
      this.props.Meeting.error === true &&
      prevProps.Meeting.error === false &&
      this.props.Meeting.message !== prevProps.Meeting.message
    ) {
      createNotification('error', this.props.Meeting.message);
      this.setState({
        loading: false,
      });
    }

    if (
      this.props.Meeting.listMeetingSuccess !==
      prevProps.Meeting.listMeetingSuccess &&
      this.props.Meeting.listMeetingSuccess === true
    ) {
      this.setState(
        {
          meetingList: this.props.Meeting.meetingList,
          filterByStatus: this.props.Meeting.filterByStatus,
          totalResults: this.props.Meeting.totalResults,
          loading: false,
        },
        () => {
          if (this.state.itemCount >= this.state.totalResults) {
            this.setState({
              endCount: parseInt(this.state.totalResults),
            });
          } else if (
            this.state.itemCount < this.state.totalResults &&
            this.state.page_no === 1
          ) {
            this.setState({
              endCount: parseInt(this.state.itemCount),
            });
          }
        },
      );
    }

    if (this.state.openSettings && !prevState.openSettings) {
      this.props.history.push(`/project-dashboard/meetings/settings/${this.props.match.params.eventId}`);
    }
  };

  /**
   * listMeeting - Fetches the list of meetings based on various parameters and updates the state.
   * 
   * @async
   * @description This method constructs the request body, fetches integration details,
   * updates the integrationId state, and then calls the listMeeting action to update the state.
   * It also handles loading states during the process.
   */
  listMeeting = async () => {
    // Extract necessary state values
    const {
      page_no,
      searchText,
      eventId,
      limit,
      sortBy,
      order,
      status,
      filterCheck,
      startDate,
      endDate,
      filterDate,
    } = this.state;

    // Construct the request body
    const body = {
      page_no,
      searchText,
      eventId,
      limit,
      sortBy,
      order,
      status,
      filterCheck,
      startDate: convertTZ(startDate, this.props.project?.project?.timezone),
      endDate: convertTZ(endDate, this.props.project?.project?.timezone),
      filterDate,
    };
    const data = {
      eventId: eventId
    };
    const integrationRes = await getMeetingsIntegrationDetails(data);
    if (integrationRes.data.success) {
      this.setState({
        integrationId: integrationRes.data.integrations._id,
      });
    }
    this.props.listMeeting(body);
    this.handleLoading();
  };

  /**
   * replicateMeeting - Replicates a meeting and updates the state accordingly.
   * 
   * @description This method triggers the replication of a meeting, updates the state,
   * and handles loading states during the process.
   */
  replicateMeeting = () => {
    try {
      const { meetingId, eventId } = this.state;
      let body = { meetingId, eventId, lngCode: this.props.users.newLangState };
      this.props.replicateMeeting(body);
      this.setState({
        page_no: 1,
        startCount: 1,
      });
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
    this.handleLoading();
  };

  /**
  * handleSearchMeeting - Updates the search text state.
  * 
  * @param {Object} e - The event object.
  * 
  * @description This method updates the search text state as the user types in the search input.
  */
  handleSearchMeeting = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  /**
  * handleLoading - Updates the loading state.
  * @description This method updates the loading state
  */
  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  /**
 * handleSearchMeetingSubmit - Handles the submission of the search form.
 * 
 * @param {Object} e - The event object.
 * 
 * @description This method prevents the default form submission, resets the pagination,
 * and triggers the listMeeting function to update the meeting list based on the search criteria.
 * It also handles loading states during the process.
 */
  handleSearchMeetingSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        page_no: 1,
        startCount: 1,
      },
      () => this.listMeeting(),
    );
    this.handleLoading();
  };

  /**
 * selectItemCount - Handles the selection of the number of items to display per page.
 * 
 * @param {Object} e - The event object.
 * 
 * @description This method updates the state with the selected item count, resets pagination,
 * clears the search text, and triggers the listMeeting function to update the meeting list accordingly.
 */
  selectItemCount = (e) => {
    this.setState(
      {
        itemCount: e.target.value,
        limit: e.target.value,
        page_no: 1,
        startCount: 1,
        searchText: '',
      },
      () => this.listMeeting(),
    );
  };

  /**
 * handlePageChange - Handles the change of the current page number.
 * 
 * @param {number} pageNumber - The selected page number.
 * 
 * @description This method calculates the start and end counts based on the selected page number,
 * updates the state, and triggers the listMeeting function to update the meeting list accordingly.
 */
  handlePageChange = (pageNumber) => {
    const { itemCount, totalResults } = this.state;
    this.setState(
      (prevState, props) => ({
        startCount: pageNumber !== 1 ? itemCount * (pageNumber - 1) + 1 : 1,
        endCount:
          totalResults > itemCount * pageNumber
            ? itemCount * pageNumber
            : totalResults,
        page_no: pageNumber,
        totalResults: this.props.Meeting?.totalResults,
        searchText: '',
      }),
      () => {
        this.listMeeting();
      },
    );
  };

  /**
 * sorting - Handles the sorting of the meeting list based on a selected value.
 * 
 * @param {string} value - The selected sorting value.
 * 
 * @description This method toggles the sorting order (asc/desc) if the same sorting value is selected,
 * or sets the sorting value to 'asc' and triggers the listMeeting function to update the meeting list accordingly.
 */
  sorting = (value) => {
    if (this.state.sortBy === value) {
      if (this.state.order === 'asc') {
        this.setState(
          {
            order: 'desc',
          },
          () => this.listMeeting(),
        );
      } else {
        this.setState(
          {
            order: 'asc',
          },
          () => this.listMeeting(),
        );
      }
    } else {
      this.setState(
        {
          sortBy: value,
          order: 'asc',
        },
        () => {
          this.listMeeting();
        },
      );
    }
  };

  /**
 * filterByStatus - Filters meetings based on selected status values.
 * 
 * @param {...string} status - The selected status values to filter meetings.
 * 
 * @description This method filters meetings based on selected status values, resets pagination
 * and search, and triggers the listMeeting function to update the meeting list accordingly.
 */
  filterByStatus = (...status) => {
    const found = status.filter((element) => element);
    this.setState(
      {
        status: status,
        page_no: 1,
        searchText: '',
        startCount: 1,
        filterDate: false,
      },
      () => this.listMeeting(status),
    );
  };

  /**
 * getFilterCount - Retrieves the count of meetings based on the filter status.
 * 
 * @param {string} countOf - The key representing the count type (e.g., 'pastCount', 'onGoingCount').
 * @returns {number} - The count of meetings based on the specified filter.
 * 
 * @description This method retrieves and returns the count of meetings based on the specified filter status.
 */
  getFilterCount = (countOf) => {
    const { filterByStatus } = this.state;
    if (filterByStatus && Object.keys(filterByStatus).length) {
      return filterByStatus[countOf] > 0 ? filterByStatus[countOf] : 0;
    }
  };

  /**
 * getFilterCountSource - Retrieves the count of meetings based on the filter source.
 * 
 * @param {string} countOf - The key representing the count type (e.g., 'pastCount', 'onGoingCount').
 * @returns {number} - The count of meetings based on the specified filter source.
 * 
 * @description This method retrieves and returns the count of meetings based on the specified filter source.
 */
  getFilterCountSource = (countOf) => {
    const { filterBySource } = this.state;
    if (filterBySource && Object.keys(filterBySource).length) {
      return filterBySource[countOf] > 0 ? filterBySource[countOf] : 0;
    }
  };

  /**
 * addMeeting - Sets the state to show the create meeting form.
 * 
 * @description This method sets the state to show the create meeting form by updating the showCreateMeeting state to true.
 */
  addMeeting = () => {
    this.setState({
      showCreateMeeting: true,
    });
  };

  /**
 * editMeeting - Sets the state for editing a meeting.
 * 
 * @param {Object} data - The data of the meeting to be edited.
 * 
 * @description This method sets the state to indicate that an edit operation is being performed,
 * shows the create meeting form, and sets the editData state with the provided meeting data.
 */
  editMeeting = (data) => {
    this.setState({
      isEdit: true,
      showCreateMeeting: true,
      editData: data,
    });
  };

  /**
 * getDataFromCreateMeeting - Handles data received from the create meeting form.
 * 
 * @param {Object} data - The data received from the create meeting form.
 * @param {boolean} isEdit - Indicates whether the operation is an edit.
 * 
 * @description This method updates the state based on the data received from the create meeting form,
 * including whether to show the create meeting form and whether it's an edit operation.
 */
  getDataFromCreateMeeting = (data, isEdit) => {
    this.setState({
      showCreateMeeting: data.showCreateMeeting,
      isEdit: isEdit,
    });
  };

  /**
 * openDeleteMeetingModal - Opens the delete meeting modal.
 * 
 * @param {string} id - The ID of the meeting to be deleted.
 * 
 * @description This method sets the state to open the delete meeting modal for the specified meeting ID.
 */
  openDeleteMeetingModal = (id) => {
    this.setState({
      meetingId: id,
      openDeleteMeetingModal: true,
    });
  };

  /**
 * openReplicateMeetingModal - Opens the replicate meeting modal.
 * 
 * @param {string} id - The ID of the meeting to be replicated.
 * 
 * @description This method sets the state to open the replicate meeting modal for the specified meeting ID.
 */
  openReplicateMeetingModal = (id) => {
    this.setState({
      meetingId: id,
      replicateMeeting: true,
      openDeleteMeetingModal: true,
    });
  };

  /**
 * closeDeleteMeetingModal - Closes the delete and replicate meeting modals.
 * 
 * @description This method sets the state to close both the delete and replicate meeting modals.
 */
  closeDeleteMeetingModal = () => {
    this.setState({
      openDeleteMeetingModal: false,
      replicateMeeting: false,
    });
  };

  /**
 * handleDeleteConfirm - Handles the confirmation of deleting a meeting.
 * 
 * @description This method triggers the deletion of a meeting and sets the state to reset pagination and start count.
 */
  handleDeleteConfirm = () => {
    try {
      const { meetingId, eventId, integrationId } = this.state;
      let data = { eventId, meetingId, lngCode: this.props.users.newLangState };
      this.props.deleteMeeting({ data });
      this.setState({
        page_no: 1,
        startCount: 1,
      });
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
    this.handleLoading();
  };

  /**
 * startDate - Updates the start date in the component state.
 * 
 * @param {Date} e - The selected start date.
 * 
 * @description This method sets the state with the selected start date.
 */
  startDate = (e) => {
    this.setState({
      startDate: e,
    });
  };

  /**
 * endDate - Updates the end date in the component state and triggers meeting filtering.
 * 
 * @param {Date} e - The selected end date.
 * 
 * @description This method sets the state with the selected end date and triggers meeting filtering.
 */
  endDate = (e) => {
    const endDate = new Date(e);
    endDate.setHours(23, 59, 59, 999);
    this.setState(
      {
        endDate: endDate,
        filterDate: true,
      },
      () => this.listMeeting(),
    );
  };

  /**
 * filterbydate1 - Filters meetings based on the selected date range.
 * 
 * @description This method filters meetings based on the selected date range and triggers meeting listing and loading.
 */
  filterbydate1 = () => {
    if (this.validDate()) {
      this.setState(
        {
          filterDate: true,
        },
        () => {
          this.listMeeting();
          this.handleLoading();
        },
      );
    }
  };

  /**
 * filterByDate - Handles filtering meetings based on pre-defined date ranges or custom range.
 * 
 * @param {string} value - The selected date range value.
 * @param {string} label - The label associated with the selected date range.
 * 
 * @description This method sets the state with the selected date range, triggers meeting filtering,
 * and handles custom date range selection by showing the custom range form.
 */
  filterByDate = (value, label) => {
    this.setState({
      selectedDateFilter: label,
      open3: false,
      filterDate: true,
    });
    const v1 = value;

    if (v1 === 'all') {
      this.setState(
        {
          startDate: new Date(),
          endDate: new Date(),
          filterDate: false,
          showCustom: false,
        },
        () => {
          this.listMeeting();
        },
      );
      return;
    }

    if (v1 === 'last_90_days') {
      this.setTime(90);
      this.setState({
        showCustom: false,
      });
    } else if (v1 === 'last_60_days') {
      this.setTime(60);
      this.setState({
        showCustom: false,
      });
    } else if (v1 === 'last_30_days') {
      this.setTime(30);
      this.setState({
        showCustom: false,
      });
    } else if (v1 === 'last_7_days') {
      this.setTime(7);
      this.setState({
        showCustom: false,
      });
    } else if (v1 === 'today') {
      this.setTime(0);
      this.setState({
        showCustom: false,
      });
    } else if (v1 === 'custom') {
      this.setState({
        showCustom: true,
        startDate: '',
        endDate: '',
        filterDate: false,
      });
    }
  };

  /**
 * validDate - Validates if the selected end date is greater than the start date.
 * 
 * @returns {boolean} - Returns true if the date is valid, false otherwise.
 * 
 * @description This method performs validation on the selected start and end dates,
 * ensuring that the end date is greater than the start date.
 */
  validDate = () => {
    let { startDate, endDate } = this.state;
    let error = {};
    let formIsValid = true;
    let sd = new Date(startDate);
    let ed = new Date(endDate);

    if (sd.getTime() > ed.getTime()) {
      formIsValid = false;
      error['endDate'] =
        this.props.users.languageStateForRedux?.validations['end>start'];
    }

    this.setState({
      errors: error,
    });
    return formIsValid;
  };

  /**
 * redirectToLoginPage - Redirects to the login page of the associated event.
 * 
 * @description This method opens a new window to redirect to the login page of the associated event.
 */
  redirectToLoginPage = () => {
    window.open(
      `${getEventURL(
        this.props.project?.project?.eventDomain,
        this.props.project?.project?.vanityDomainName,
        this.props.project?.project?.isVanityDomainActive,
        this.props.project?.project?.isVanityDomainVerified,
      )}/${'login'}`,
      '_blank',
    );
  };

  /**
 * loadMore - Loads more meetings by incrementing the loadMoreNumber state.
 * 
 * @param {string} meetingId - The ID of the meeting for which more data needs to be loaded.
 * 
 * @description This method increments the loadMoreNumber state to load more data for the specified meeting.
 */
  loadMore = (meetingId) => {
    this.setState((prevState) => {
      return {
        [`loadMoreNumber${meetingId}`]:
          (prevState[`loadMoreNumber${meetingId}`]
            ? prevState[`loadMoreNumber${meetingId}`]
            : 0) + 20,
      };
    });
  };

  /**
 * openMeetingReport - Opens the meeting report modal for the selected meeting.
 * 
 * @param {Object} meeting - The meeting data for which the report will be displayed.
 * 
 * @description This method sets the state to open the meeting report modal for the selected meeting.
 */
  openMeetingReport = (meeting) => {
    this.setState({
      openMeetingReport: true,
      meetingSelected: meeting,
    });
  };

  /**
 * closeMeetingReport - Closes the meeting report modal.
 * 
 * @description This method sets the state to close the meeting report modal.
 */

  closeMeetingReport = () => {
    this.setState({
      openMeetingReport: false,
      meetingSelected: null,
    });
  };

  /**
 * handleToggle3 - Toggles the state of open3 to control a dropdown or modal visibility.
 * 
 * @description This method toggles the state of open3 to control the visibility of a dropdown or modal.
 */
  handleToggle3 = () => {
    this.setState({
      open3: !this.state.open3,
    });
  };

  /**
 * filterHandleClose - Closes the dropdown or modal by setting the open3 state to false.
 * 
 * @param {Event} event - The event triggering the close action.
 * 
 * @description This method sets the state to close a dropdown or modal by setting open3 to false.
 */
  filterHandleClose = (event) => {
    this.setState({
      open3: false,
    });
  };

  /**
 * resetDate - Resets the start and end dates in the component state.
 * 
 * @description This method sets the state to reset the start and end dates to an empty value.
 */
  resetDate = () => {
    this.setState({ startDate: '', endDate: '' });
  };

  /**
 * setTime - Sets the start and end dates based on a specified number of days.
 * 
 * @param {number} days - The number of days to set for the time range.
 * 
 * @description This method sets the start and end dates based on the specified number of days
 * and triggers meeting listing with the updated time range.
 */
  setTime = (days) => {
    const currentDate = new Date();
    const startTime = new Date(currentDate);
    startTime.setDate(currentDate.getDate() - days);
    startTime.setHours(0, 0, 0, 0);
    let endTime = currentDate.setHours(23, 59, 59, 999);
    this.setState(
      {
        startDate: startTime,
        endDate: endTime,
        page_no: 1,
      },
      () => this.listMeeting(),
    );
  };

  /**
 * handleOpenSettings - Opens the settings modal.
 * 
 * @description This method sets the state to open the settings modal.
 */
  handleOpenSettings = () => {
    this.setState({
      openSettings: true
    });
  };

  /**
 * handleCloseSettings - Closes the settings modal.
 * 
 * @description This method sets the state to close the settings modal.
 */
  handleCloseSettings = () => {
    this.setState({
      openSettings: false
    });
  };

  /**
 * chooseFilter - Chooses the localized filter option based on the provided value.
 * 
 * @param {string} val - The filter option value.
 * @returns {string} - The localized filter option.
 * 
 * @description This method returns the localized filter option based on the provided value.
 */
  chooseFilter = (val) => {
    let temp;
    switch (val) {
      case 'All': temp = this.props.users.languageStateForRedux?.common?.all;
        return temp;

      case 'Today': temp = this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.today;
        return temp;

      case 'Last 07 Days': temp = this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last7;
        return temp;

      case 'Last 30 Days': temp = this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last30;
        return temp;

      case 'Last 60 Days': temp = this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last60;
        return temp;

      case 'Last 90 Days': temp = this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last90;
        return temp;

      case 'Custom Range': temp = this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.customRange;
        return temp;

      default: temp = val;
        break;
    }
  };

  /**
   * @function render
   * @description Renders the Meeting component.
   * @returns {JSX.Element} - JSX element representing the component.
   */
  render() {
    const {
      showCustom,
      selectedDateFilter,
      open3,
      allowed,
      replicateMeeting,
      showCreateMeeting,
      startCount,
      endCount,
      itemCount,
      order,
      sortBy,
      searchText,
      filterByStatus,
      startDate,
      endDate,
      meetingList,
      openMeetingReport,
      meetingSelected,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        {openMeetingReport ? (
          <EmailAnalytics
            project={this.props.project}
            closeReport={this.closeMeetingReport}
            selectedEmailType={'meetings'}
            meetingSelected={meetingSelected}
          />
        ) : (
          this.state.openSettings ? <SettingsLayout handleCloseSettings={this.handleCloseSettings} handleOpenSettings={this.handleOpenSettings} /> :
            <>
              {!showCreateMeeting ? (
                <>
                  <Grid container spacing={2} mb={3} className="top-filter-area">
                    <Grid item>
                      <Grid container columnSpacing={1}>
                        <Grid item>
                          <Box
                            component={Button}
                            variant="contained"
                            sx={{
                              backgroundColor: theme.palette.background.light,
                              color: theme.palette.common.black,
                              textTransform: 'capitalize !important',
                              fontWeight: '400',
                              '&:hover, &.active': {
                                backgroundColor: '#000056 !important',
                                color: theme.palette.primary.contrastText,
                              },
                            }}
                            className={
                              this.state.filterCheck === 'all' ? 'active' : null
                            }
                            size="small"
                            disableElevation
                            aria-haspopup="true"
                            onClick={() => {
                              this.setState({
                                filterCheck: 'all',
                              });
                              this.filterByStatus('Sent', 'Draft');
                            }}
                          >
                            {this.props.users.languageStateForRedux?.common?.all}{' '}
                            (
                            {filterByStatus && Object.keys(filterByStatus).length
                              ? this.getFilterCount('all')
                              : '0'}
                            )
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            component={Button}
                            variant="contained"
                            sx={{
                              backgroundColor: theme.palette.background.light,
                              color: theme.palette.common.black,
                              textTransform: 'capitalize !important',
                              fontWeight: '400',
                              '&:hover, &.active': {
                                backgroundColor: '#000056 !important',
                                color: theme.palette.primary.contrastText,
                              },
                            }}
                            size="small"
                            disableElevation
                            aria-haspopup="true"
                            className={
                              this.state.filterCheck === 'upComing'
                                ? 'active'
                                : null
                            }
                            onClick={() => {
                              this.setState({
                                filterCheck: 'upComing',
                              });
                              this.filterByStatus('Sent');
                            }}
                          >
                            {
                              this.props.users.languageStateForRedux?.dropdowns
                                ?.meetings?.upcoming
                            }{' '}
                            (
                            {filterByStatus && Object.keys(filterByStatus).length
                              ? this.getFilterCount('upComing')
                              : '0'}
                            )
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            component={Button}
                            variant="contained"
                            sx={{
                              backgroundColor: theme.palette.background.light,
                              color: theme.palette.common.black,
                              textTransform: 'capitalize !important',
                              fontWeight: '400',
                              '&:hover, &.active': {
                                backgroundColor: '#000056 !important',
                                color: theme.palette.primary.contrastText,
                              },
                              position: 'relative',
                            }}
                            size="small"
                            disableElevation
                            aria-haspopup="true"
                            className={
                              'on-going-filter ' +
                              (this.state.filterCheck === 'onGoing'
                                ? 'active'
                                : null)
                            }
                            onClick={() => {
                              this.setState({
                                filterCheck: 'onGoing',
                              });
                              this.filterByStatus('Sent');
                            }}
                          >
                            {
                              this.props.users.languageStateForRedux?.dropdowns
                                ?.meetings?.ongoing
                            }{' '}
                            (
                            {filterByStatus && Object.keys(filterByStatus).length
                              ? this.getFilterCount('onGoing')
                              : '0'}
                            )
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            component={Button}
                            variant="contained"
                            sx={{
                              backgroundColor: theme.palette.background.light,
                              color: theme.palette.common.black,
                              textTransform: 'capitalize !important',
                              fontWeight: '400',
                              '&:hover, &.active': {
                                backgroundColor: '#000056 !important',
                                color: theme.palette.primary.contrastText,
                              },
                            }}
                            size="small"
                            disableElevation
                            aria-haspopup="true"
                            className={
                              this.state.filterCheck === 'Draft' ? 'active' : null
                            }
                            onClick={() => {
                              this.setState({
                                filterCheck: 'Draft',
                              });
                              this.filterByStatus('Draft');
                            }}
                          >
                            {
                              this.props.users.languageStateForRedux?.common
                                ?.draft
                            }{' '}
                            (
                            {filterByStatus && Object.keys(filterByStatus).length
                              ? this.getFilterCount('Draft')
                              : '0'}
                            )
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            component={Button}
                            variant="contained"
                            sx={{
                              backgroundColor: theme.palette.background.light,
                              color: theme.palette.common.black,
                              textTransform: 'capitalize !important',
                              fontWeight: '400',
                              '&:hover, &.active': {
                                backgroundColor: '#000056 !important',
                                color: theme.palette.primary.contrastText,
                              },
                            }}
                            size="small"
                            disableElevation
                            aria-haspopup="true"
                            className={
                              this.state.filterCheck === 'past' ? 'active' : null
                            }
                            onClick={() => {
                              this.setState({
                                filterCheck: 'past',
                              });
                              this.filterByStatus('Sent');
                            }}
                          >
                            {
                              this.props.users.languageStateForRedux?.dropdowns
                                ?.meetings?.pastMeet
                            }{' '}
                            (
                            {filterByStatus && Object.keys(filterByStatus).length
                              ? this.getFilterCount('past')
                              : '0'}
                            )
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            component={Button}
                            variant="contained"
                            sx={{
                              backgroundColor: theme.palette.background.light,
                              color: theme.palette.common.black,
                              textTransform: 'capitalize !important',
                              fontWeight: '400',
                              '&:hover, &.active': {
                                backgroundColor: '#000056 !important',
                                color: theme.palette.primary.contrastText,
                              },
                            }}
                            size="small"
                            disableElevation
                            ref={this.anchorRef3}
                            aria-controls={open3 ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleToggle3}
                            endIcon={<ArrowDropDownIcon />}
                          >
                            {this.chooseFilter(selectedDateFilter)}
                          </Box>
                          <Popper
                            open={open3}
                            placement="bottom-start"
                            className="filter-options"
                            transition
                            anchorEl={this.anchorRef3?.current}
                            role={undefined}
                            disablePortal
                          >
                            {({ TransitionProps, placement }) => (
                              <Fade {...TransitionProps} timeout={250}>
                                <Box
                                  sx={{
                                    backgroundColor: theme.palette.common.white,
                                    boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                    borderRadius: '4px',
                                    marginTop: '8px',
                                  }}
                                >
                                  {' '}
                                  <ClickAwayListener
                                    onClickAway={this.filterHandleClose}
                                  >
                                    <Stack spacing={1} p={1.5} direction="column">
                                      <Button
                                        size="small"
                                        variant={
                                          selectedDateFilter === 'All'
                                            ? 'contained'
                                            : 'outlined'
                                        }
                                        color={
                                          selectedDateFilter === 'All'
                                            ? 'primary'
                                            : ''
                                        }
                                        onClick={() =>
                                          this.filterByDate('all', 'All')
                                        }
                                      >
                                        {this.props.users.languageStateForRedux?.common?.all}
                                      </Button>
                                      <Button
                                        size="small"
                                        variant={
                                          selectedDateFilter === 'Today'
                                            ? 'contained'
                                            : 'outlined'
                                        }
                                        color={
                                          selectedDateFilter === 'Today'
                                            ? 'primary'
                                            : ''
                                        }
                                        onClick={() =>
                                          this.filterByDate('today', 'Today')
                                        }
                                      >
                                        {this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.today}
                                      </Button>
                                      <Button
                                        size="small"
                                        variant={
                                          selectedDateFilter === 'Last 07 Days'
                                            ? 'contained'
                                            : 'outlined'
                                        }
                                        color={
                                          selectedDateFilter === 'Last 07 Days'
                                            ? 'primary'
                                            : ''
                                        }
                                        onClick={() =>
                                          this.filterByDate(
                                            'last_7_days',
                                            'Last 07 Days',
                                          )
                                        }
                                      >
                                        {this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last7}
                                      </Button>
                                      <Button
                                        size="small"
                                        variant={
                                          selectedDateFilter === 'Last 30 Days'
                                            ? 'contained'
                                            : 'outlined'
                                        }
                                        color={
                                          selectedDateFilter === 'Last 30 Days'
                                            ? 'primary'
                                            : ''
                                        }
                                        onClick={() =>
                                          this.filterByDate(
                                            'last_30_days',
                                            'Last 30 Days',
                                          )
                                        }
                                      >
                                        {this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last30}
                                      </Button>
                                      <Button
                                        size="small"
                                        variant={
                                          selectedDateFilter === 'Last 60 Days'
                                            ? 'contained'
                                            : 'outlined'
                                        }
                                        color={
                                          selectedDateFilter === 'Last 60 Days'
                                            ? 'primary'
                                            : ''
                                        }
                                        onClick={() =>
                                          this.filterByDate(
                                            'last_60_days',
                                            'Last 60 Days',
                                          )
                                        }
                                      >
                                        {this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last60}
                                      </Button>
                                      <Button
                                        size="small"
                                        variant={
                                          selectedDateFilter === 'Last 90 Days'
                                            ? 'contained'
                                            : 'outlined'
                                        }
                                        color={
                                          selectedDateFilter === 'Last 90 Days'
                                            ? 'primary'
                                            : ''
                                        }
                                        onClick={() =>
                                          this.filterByDate(
                                            'last_90_days',
                                            'Last 90 Days',
                                          )
                                        }
                                      >
                                        {this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.last90}
                                      </Button>
                                      <Button
                                        size="small"
                                        variant={
                                          selectedDateFilter === 'Custom Range'
                                            ? 'contained'
                                            : 'outlined'
                                        }
                                        color={
                                          selectedDateFilter === 'Custom Range'
                                            ? 'primary'
                                            : ''
                                        }
                                        onClick={() =>
                                          this.filterByDate(
                                            'custom',
                                            'Custom Range',
                                          )
                                        }
                                      >
                                        {this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.customRange}
                                      </Button>
                                    </Stack>
                                  </ClickAwayListener>
                                </Box>
                              </Fade>
                            )}
                          </Popper>
                        </Grid>

                        {showCustom && (
                          <Grid item className='chat-filter'>
                            <Box className="form-group anaSelectDRange">
                              <Grid
                                container
                                alignItems={'center'}
                                spacing={1}
                                className="field-group inputField"
                              >
                                <Grid item className="formDate formIcon">
                                  <DatePicker
                                    selected={startDate}
                                    onChange={(e) => {
                                      this.startDate(e);
                                    }}
                                    selectsStart
                                    className="outlineButton"
                                    startDate={startDate}
                                    endDate={endDate}
                                  />
                                </Grid>

                                <Grid item>
                                  <Box
                                    component={'span'}
                                    mx={0.5}
                                    className="small-subtitle primary-text"
                                  >
                                    {this.props.users.languageStateForRedux?.common?.to}
                                  </Box>
                                </Grid>

                                <Grid item className="formDate formIcon">
                                  <DatePicker
                                    selected={endDate}
                                    onChange={(e) => {
                                      this.endDate(e);
                                    }}
                                    selectsEnd
                                    startDate={startDate}
                                    className="outlineButton"
                                    endDate={endDate}
                                    disabled={this.state.startDate ? false : true}
                                  />
                                </Grid>

                                <Grid item>
                                  <Tooltip
                                    title="Reset Custom Date"
                                    placement='top'
                                    arrow
                                  >


                                    <IconButton
                                      aria-label="delete"
                                      onClick={this.resetDate}
                                      size='small'
                                    >
                                      <ReloadIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1.25} className="team-dash-right">
                        <Grid item className="">
                          <form onSubmit={this.handleSearchMeetingSubmit}>
                            <TextField
                              name="searchText"
                              placeholder={
                                this.props.users.languageStateForRedux?.common
                                  ?.search
                              }
                              className="search"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img alt="" src={SearchIcon} />
                                  </InputAdornment>
                                ),
                              }}
                              value={searchText}
                              onChange={this.handleSearchMeeting}
                            />
                          </form>
                        </Grid>
                        {allowed['Meeting Setting'] && (
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              disableElevation
                              onClick={() => this.handleOpenSettings()}
                            >
                              {this.props.users.languageStateForRedux?.dropdowns?.common?.settings}
                            </Button>
                          </Grid>
                        )}
                        {allowed['Create Meeting'] && (
                          <>
                            {this.props.project?.project?.endDate &&
                              (moment.utc(this.props.project.project.endDate) <
                                moment.utc()
                                ? true
                                : false) ? (
                              <Tooltip
                                title={
                                  this.props.users.languageStateForRedux?.actions
                                    ?.meeting?.hoverTitle
                                }
                                arrow
                                placement="top"
                              >
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    onClick={() => this.addMeeting()}
                                    disabled
                                  >
                                    {
                                      this.props.users.languageStateForRedux
                                        ?.buttons?.meeting?.add
                                    }
                                  </Button>
                                </Grid>
                              </Tooltip>
                            ) : (
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disableElevation
                                  onClick={() => this.addMeeting()}
                                >
                                  {
                                    this.props.users.languageStateForRedux
                                      ?.buttons?.meeting?.add
                                  }
                                </Button>
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <TableContainer className="list-table" style={{ maxHeight: 'calc(100vh - 380px)' }}>
                    <Table className="table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="sticky-cell"
                            style={{ minWidth: '78px', maxWidth: '78px' }}
                          ></TableCell>
                          <TableCell
                            className={
                              'name-avatar sticky-cell ' +
                              (sortBy === 'meetingTitle'
                                ? order === 'asc'
                                  ? 'sort-asc'
                                  : 'sort-des'
                                : '')
                            }
                            onClick={() => {
                              this.sorting('meetingTitle');
                            }}
                            style={{ minWidth: '370px', left: '78px' }}
                          >
                            {
                              this.props.users.languageStateForRedux?.formfields
                                ?.meetings?.meetingTitle
                            }
                          </TableCell>
                          <TableCell
                          style={{ minWidth: '150px', maxWidth: '150px' }}
                            className={
                              'status ' +
                              (sortBy === 'status'
                                ? order === 'asc'
                                  ? 'sort-asc'
                                  : 'sort-des'
                                : '')
                            }
                            onClick={() => {
                              this.sorting('status');
                            }}
                          >
                            {
                              this.props.users.languageStateForRedux?.common
                                ?.status
                            }
                          </TableCell>

                          <TableCell
                            className={
                              'date-time ' +
                              (sortBy === 'meetingStartDateTime'
                                ? order === 'asc'
                                  ? 'sort-asc'
                                  : 'sort-des'
                                : '')
                            }
                            style={{ minWidth: '200px', maxWidth: '200px' }}
                            onClick={() => this.sorting('meetingStartDateTime')}
                          >
                            {this.props.users.languageStateForRedux?.column_names?.meetings?.startTime}
                          </TableCell>

                          <TableCell
                            className={
                              'date-time ' +
                              (sortBy === 'meetingEndDateTime'
                                ? order === 'asc'
                                  ? 'sort-asc'
                                  : 'sort-des'
                                : '')
                            }
                            style={{ minWidth: '200px', maxWidth: '200px' }}
                            onClick={() => this.sorting('meetingEndDateTime')}
                          >
                            {this.props.users.languageStateForRedux?.column_names?.meetings?.endTime}
                          </TableCell>

                          <TableCell
                            className={
                              'text ' +
                              (sortBy === 'peopleInvited'
                                ? order === 'asc'
                                  ? 'sort-asc'
                                  : 'sort-des'
                                : '')
                            }
                            onClick={() => {
                              this.sorting('peopleInvited');
                            }}
                            style={{ minWidth: '150px', maxWidth: '150px' }}
                          >
                            {this.props.users.languageStateForRedux?.column_names?.meetings?.peopleInvited}
                          </TableCell>

                          <TableCell
                            className={
                              'email ' +
                              (sortBy === 'meetingOrganizer'
                                ? order === 'asc'
                                  ? 'sort-asc'
                                  : 'sort-des'
                                : '')
                            }
                            onClick={() => this.sorting('meetingOrganizer')}
                          >
                            {this.props.users.languageStateForRedux?.formfields?.meetings?.meetingOrganizer}
                          </TableCell>
                          <TableCell
                            className="options"
                            style={{ minWidth: '100px', maxWidth: '100px' }}
                            align="right"
                          >
                            {
                              this.props.users.languageStateForRedux?.common
                                ?.action
                            }
                          </TableCell>


                        </TableRow>
                      </TableHead>
                      {Array.isArray(meetingList) && meetingList?.length > 0 ? (
                        <>
                          <TableBody>
                            {Array.isArray(meetingList) &&
                              meetingList.length &&
                              meetingList?.map((meeting, i) => {
                                return (
                                  <TableRow style={{ alignItems: 'center' }}>
                                    <TableCell
                                      className="sticky-cell"
                                      style={{
                                        minWidth: '78px',
                                        maxWidth: '78px',
                                      }}
                                    >
                                      <Avatar style={styles.avatar}>
                                        <img alt="" src={MeetingIcon} />
                                      </Avatar>
                                    </TableCell>
                                    <TableCell
                                      className="name-avatar sticky-cell"
                                      style={{ minWidth: '370px', left: '78px' }}
                                    >
                                      <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                      >
                                        <Grid item xs>
                                          <CustomHTMLTooltip
                                            interactive={true}
                                            title={
                                              <Box className="upgrade-tooltip">
                                                <Box component={'h6'} mb={0}>
                                                  {meeting.meetingTitle
                                                    ? meeting.meetingTitle
                                                    : ''}{' '}
                                                </Box>
                                              </Box>
                                            }
                                            placement="top"
                                            arrow
                                          >
                                            <Box
                                              component="p"
                                              mb={1}
                                              className="subtitle bold"
                                            >
                                              {meeting.meetingTitle
                                                ? meeting.meetingTitle
                                                : ''}{' '}
                                            </Box>
                                          </CustomHTMLTooltip>
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                    <TableCell className="status" style={{ minWidth: '150px', maxWidth: '150px' }}>
                                      <span
                                        className={
                                          meeting.status === 'past' ||
                                            meeting.status === 'Draft'
                                            ? 'table-status light'
                                            : meeting.status === 'onGoing'
                                              ? 'table-status success'
                                              : 'table-status warning'
                                        }
                                      >
                                        {meeting.status === 'past'
                                          ? this.props.users.languageStateForRedux
                                            ?.buttons?.meeting?.past
                                          : meeting.status === 'Draft'
                                            ? this.props.users.languageStateForRedux
                                              ?.common?.draft
                                            : meeting.status === 'onGoing'
                                              ? this.props.users.languageStateForRedux
                                                ?.dropdowns?.meetings?.ongoing
                                              : meeting.status === 'upComing'
                                                ? this.props.users.languageStateForRedux
                                                  ?.dropdowns?.meetings?.upcoming
                                                : meeting.status}
                                      </span>
                                    </TableCell>

                                    <TableCell className="date-time" style={{ minWidth: '200px', maxWidth: '200px' }}>
                                      {moment
                                        .tz(
                                          meeting.meetingStartDateTime,
                                          this.props.project?.project?.timezone,
                                        )
                                        .format('MMM, DD YYYY, h:mm A')}
                                    </TableCell>
                                    <TableCell className="date-time" style={{ minWidth: '200px', maxWidth: '200px' }}>
                                      {moment
                                        .tz(
                                          meeting.meetingEndDateTime,
                                          this.props.project?.project?.timezone,
                                        )
                                        .format('MMM, DD YYYY, h:mm A')}
                                    </TableCell>
                                    <TableCell className="text" style={{ minWidth: '150px', maxWidth: '150px' }}>
                                      <div
                                        className="detail-popup"
                                        style={{ maxHeight: 'unset' }}
                                      >
                                        {' '}
                                        <span
                                          className="bolder paragraph"
                                          style={{ textDecoration: 'underLine' }}
                                        >
                                          {meeting.meetingEmails &&
                                            meeting.meetingEmails.length
                                            ? meeting.meetingEmails.length
                                            : 0}
                                        </span>{' '}
                                        <div
                                          className="info"
                                          style={{
                                            left: '0px',
                                            right: 'unset',
                                            top: '22px',
                                            minWidth: 'max-content',
                                            borderRadius: '8px',
                                            textAlign: 'center',
                                            maxHeight: '300px'
                                          }}
                                        >
                                          {' '}
                                          {meeting.meetingEmails &&
                                            Array.isArray(
                                              meeting.meetingEmails,
                                            ) &&
                                            meeting.meetingEmails.length > 0 &&
                                            meeting.meetingEmails?.map(
                                              (email, i) => {
                                                return (
                                                  i <
                                                  20 +
                                                  (this.state[
                                                    `loadMoreNumber${meeting._id}`
                                                  ]
                                                    ? this.state[
                                                    `loadMoreNumber${meeting._id}`
                                                    ]
                                                    : 0) && (
                                                    <div>{email.email}</div>
                                                  )
                                                );
                                              },
                                            )}
                                          {meeting?.meetingEmails?.length > 20 && (
                                            <Button
                                              disableElevation
                                              variant="text"
                                              color="primary"
                                              style={{ maxWidth: '120px' }}
                                              onClick={() =>
                                                this.loadMore(meeting._id)
                                              }
                                              disabled={
                                                meeting.meetingEmails.length <=
                                                20 +
                                                this.state[
                                                `loadMoreNumber${meeting._id}`
                                                ]
                                              }
                                            >
                                              Load More
                                            </Button>
                                          )}
                                        </div>{' '}
                                      </div>
                                    </TableCell>

                                    <TableCell className="email">
                                      {meeting.meetingOrganizer
                                        ? meeting.meetingOrganizer
                                        : ''}
                                    </TableCell>
                                    <TableCell
                                      className="text"
                                      style={{
                                        display: 'flex',
                                        minWidth: '100px',
                                        maxWidth: '100px',
                                      }}
                                      align="right"
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems={'center'}
                                        spacing={1}
                                      >
                                        {(meeting.status === 'upComing' ||
                                          meeting.status === 'onGoing') && (
                                            <Button
                                              variant="outlined"
                                              color="primary"
                                              size="small"
                                              onClick={() =>
                                                this.redirectToLoginPage()
                                              }
                                            >
                                              {
                                                this.props.users
                                                  .languageStateForRedux?.buttons
                                                  ?.meeting?.join
                                              }
                                            </Button>
                                          )}
                                        <div
                                          className="detail-popup"
                                          style={{ maxHeight: 'unset' }}
                                        >
                                          <Box
                                            style={{
                                              width: '40px',
                                              height: '32px',
                                              borderRadius: '10%',
                                              backgroundColor: '#e0e0e0',
                                              padding: '4px 8px',
                                            }}
                                          >
                                            <ExpandMoreIcon />
                                          </Box>
                                          <div
                                            className="info"
                                            style={{
                                              right: '0px',
                                              minWidth: '200px',
                                              borderRadius: '8px',
                                            }}
                                          >
                                            {(meeting.status === 'Draft' ||
                                              meeting.status === 'upComing' || meeting.status === 'onGoing') && (
                                                <>
                                                  {meeting.isEditable === true && (
                                                    <div
                                                      onClick={() =>
                                                        this.editMeeting(meeting)
                                                      }
                                                    >
                                                      {
                                                        this.props.users
                                                          .languageStateForRedux
                                                          ?.common?.edit
                                                      }
                                                    </div>
                                                  )}
                                                  {meeting.status !== 'onGoing' && meeting.isDeleteable === true && (
                                                    <div
                                                      style={{
                                                        color:
                                                          theme.palette.secondary
                                                            .main,
                                                      }}
                                                      onClick={() =>
                                                        this.openDeleteMeetingModal(
                                                          meeting._id,
                                                        )
                                                      }
                                                    >
                                                      {
                                                        this.props.users
                                                          .languageStateForRedux
                                                          ?.common?.delete
                                                      }
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                            {allowed['Create Meeting'] && (
                                              <div
                                                onClick={() =>
                                                  this.openReplicateMeetingModal(
                                                    meeting._id,
                                                  )
                                                }
                                              >
                                                {
                                                  this.props.users
                                                    .languageStateForRedux
                                                    ?.actions?.meeting?.replicate
                                                }
                                              </div>
                                            )}
                                            {allowed['View Meeting'] && (
                                              <>
                                                {meeting.status !== 'Draft' && (
                                                  <div
                                                    onClick={() =>
                                                      this.openMeetingReport(
                                                        meeting,
                                                      )
                                                    }
                                                  >
                                                    {
                                                      this.props.users
                                                        .languageStateForRedux
                                                        ?.actions?.meeting
                                                        ?.viewReport
                                                    }
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </Stack>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </>
                      ) : (
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ textAlign: 'center' }}>
                              {
                                this.props.users.languageStateForRedux?.common
                                  ?.noRecords
                              }
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  {Array.isArray(meetingList) && meetingList?.length > 0 && (
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      className="tablePagination"
                    >
                      <Grid item>
                        <Box component="span" className="small-subtitle">
                          {
                            this.props.users.languageStateForRedux?.pagination
                              ?.showing
                          }{' '}
                          {startCount}{' '}
                          {this.props.users.languageStateForRedux?.pagination?.to}{' '}
                          {endCount}{' '}
                          {this.props.users.languageStateForRedux?.pagination?.of}{' '}
                          {this.props.Meeting?.totalResults}
                        </Box>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="center">
                          <Grid item>
                            <span className="small-subtitle">
                              {
                                this.props.users.languageStateForRedux?.pagination
                                  ?.shows
                              }
                            </span>
                            <select
                              value={itemCount}
                              onChange={this.selectItemCount}
                            >
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                            <span className="small-subtitle">Entries</span>
                          </Grid>
                          <Grid item>
                            <Pagination
                              activePage={this.state.page_no}
                              itemsCountPerPage={this.state.itemCount}
                              totalItemsCount={this.props.Meeting?.totalResults}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                              prevPageText={
                                <NavigateBeforeIcon style={{ fontSize: 18 }} />
                              }
                              nextPageText={
                                <NavigateNextIcon style={{ fontSize: 18 }} />
                              }
                              itemClassFirst="first d-none"
                              itemClassLast="last d-none"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Modal
                    open={this.state.openDeleteMeetingModal}
                    onClose={this.closeDeleteMeetingModal}
                    classNames={{
                      overlay: '',
                      modal: 'Invite-User-Modal',
                    }}
                    center
                  >
                    <Box component={Grid} container className="" px={1.5}>
                      <Box component={Grid} mt={-1} item xs={12} pb={3}>
                        <Box component="p" mb={1} className="subtitle">
                          {' '}
                          {replicateMeeting === false
                            ? this.props.users.languageStateForRedux?.common
                              ?.confirmDelete
                            : this.props.users.languageStateForRedux?.common
                              ?.confirmReplicate}
                        </Box>
                        <p className="small-subtitle">
                          {
                            this.props.users.languageStateForRedux?.messages
                              ?.areYouSure
                          }{' '}
                          {replicateMeeting === false
                            ? this.props.users.languageStateForRedux?.messages
                              ?.deleteIt
                            : this.props.users.languageStateForRedux?.messages
                              ?.replicateMeeting}{' '}
                        </p>
                      </Box>
                      <Grid item xs={12}>
                        <Grid container justifyContent="end">
                          <Stack spacing={1} direction="row" fullWidth>
                            <>
                              {replicateMeeting === false ? (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  disableElevationon
                                  onClick={() => this.handleDeleteConfirm()}
                                >
                                  {
                                    this.props.users.languageStateForRedux?.common
                                      ?.delete
                                  }
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disableElevationon
                                  onClick={() => this.replicateMeeting()}
                                >
                                  {
                                    this.props.users.languageStateForRedux?.common
                                      ?.replicate
                                  }
                                </Button>
                              )}

                              <Button
                                variant="outlined"
                                disableElevation
                                onClick={() => this.closeDeleteMeetingModal()}
                              >
                                {
                                  this.props.users.languageStateForRedux?.common
                                    ?.cancel
                                }
                              </Button>
                            </>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Modal>
                </>
              ) : (
                <CreateMeeting
                  getDataFromCreateMeeting={this.getDataFromCreateMeeting}
                  editData={this.state.editData}
                  isEdit={this.state.isEdit}
                  meetingId={this.state.meetingId}
                  addMeetingStateData={this.state.addMeetingStateData}
                  fromEditor={this.props.Meeting.fromEditor}
                />
              )}
              {this.state.loading ? <Loader /> : null}
            </>
        )}
      </ThemeProvider>
    );
  }
}

/**
 * @function mapStateToProps
 * @description Maps the Redux state to component props.
 * @param {Object} state - Redux state object.
 * @returns {Object} Props derived from the Redux state.
 */
const mapStateToProps = (state) => ({
  project: state.Projects,
  Meeting: state.Meeting,
  events: state.Events,
  users: state.Users,
});

/**
 * @function mapActionsToProps
 * @description Maps Redux actions to component props.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Object} Props containing action functions.
 */
const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listMeeting: Actions.listMeetingRequest,
      deleteMeeting: Actions.deleteMeetingRequest,
      replicateMeeting: Actions.replicateMeetingRequest,
    },
    dispatch,
  );

// Connects the component to Redux store and withRouter for access to router props
export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withRouter(Meetings));
