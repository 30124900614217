import { Constants } from "Redux/Actions";
import { createReducer } from "reduxsauce";

export type OpportunityCommon_State = {
    commentsAndAttachments: CommentsAndAttachments[],
    error: boolean,
    loading: boolean,
    message: '',
    createCommentAndAttachmentSuccess: boolean,    
    uploadAttachmentToCloudSuccess: boolean,
    deleteCommentAndAttachmentSuccess: boolean,
    updateCommentAndAttachmentSuccess: boolean,
    fetchCommentAndAttachmentSuccess: boolean,
    removeAttachmentSuccess: boolean
};

export type CommentsAndAttachments = {
    _id?: string,
    userData?: {
        _id: string,
        firstName: string,
        lastName: string
    }
    comment?: string,
    dateTime?: Date,
    attachments?: Attachment[]
}

export type Attachment = {
    _id?: string,
    fileName: string,
    fileNameInS3: string,
    fileType: string,
    fileExtension: string,
    fileSize: string
}

export type CommentSection = 'comments' | 'attachments'

export type ViewType = 'opportunity' | 'account' | 'contact'

const INITIAL_STATE: OpportunityCommon_State = {
    commentsAndAttachments: [],
    error: false,
    loading: false,
    message: '',
    createCommentAndAttachmentSuccess: false, 
    uploadAttachmentToCloudSuccess: false,   
    deleteCommentAndAttachmentSuccess: false,
    updateCommentAndAttachmentSuccess: false,
    fetchCommentAndAttachmentSuccess: false,
    removeAttachmentSuccess: false,
};

const createCommentAndAttachmentRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        createCommentAndAttachmentSuccess: false,
        error: false,
        // loading: true,
        message: '',
    };
};
const createCommentAndAttachmentSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        createCommentAndAttachmentSuccess: true,
        error: false,
        // loading: false,
        commentsAndAttachments: action.commentsAndAttachments,
        message: action.message,
    };
};
const createCommentAndAttachmentError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        createCommentAndAttachmentSuccess: false,
        error: true,
        // loading: false,
        message: action.message
    };
};

const uploadAttachmentToCloudRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        uploadAttachmentToCloudSuccess: false,
        error: false,
        message: '',
    };
};
const uploadAttachmentToCloudSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        uploadAttachmentToCloudSuccess: true,
        error: false,
        message: action.message,
    };
};
const uploadAttachmentToCloudError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        uploadAttachmentToCloudSuccess: false,
        error: true,
        message: action.message
    };
};

const deleteCommentAndAttachmentRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        deleteCommentAndAttachmentSuccess: false,
        error: false,
        loading: true,
        message: '',
    };
};
const deleteCommentAndAttachmentSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        deleteCommentAndAttachmentSuccess: true,
        error: false,
        loading: false,
        commentsAndAttachments: action.commentsAndAttachments,
        message: action.message,
    };
};
const deleteCommentAndAttachmentError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        deleteCommentAndAttachmentSuccess: false,
        error: true,
        loading: false,
        message: action.message
    };
};

const updateCommentAndAttachmentRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        updateCommentAndAttachmentSuccess: false,
        error: false,
        loading: true,
        message: '',
    };
};
const updateCommentAndAttachmentSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        updateCommentAndAttachmentSuccess: true,
        error: false,
        loading: false,
        commentsAndAttachments: action.commentsAndAttachments
    };
};
const updateCommentAndAttachmentError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        updateCommentAndAttachmentSuccess: false,
        error: true,
        loading: false,
        message: action.message
    };
};

const fetchCommentAndAttachmentRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        fetchCommentAndAttachmentSuccess: false,
        error: false,
        message: '',
    };
};
const fetchCommentAndAttachmentSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        fetchCommentAndAttachmentSuccess: true,
        error: false,
        commentsAndAttachments: action.commentsAndAttachments,
    };
};
const fetchCommentAndAttachmentError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        fetchCommentAndAttachmentSuccess: false,
        error: true,
        message: action.message
    };
};

const removeAttachmentRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        removeAttachmentSuccess: false,
        error: false,
        loading: true,
        message: '',
    };
};
const removeAttachmentSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        removeAttachmentSuccess: true,
        error: false,
        loading: false,
        message: action.message,
        commentsAndAttachments: action.commentsAndAttachments,
    };
};
const removeAttachmentError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        removeAttachmentSuccess: false,
        error: true,
        loading: false,
        message: action.message
    };
};

const HANDLERS = {
    [Constants.CREATE_COMMENT_AND_ATTACHMENT_REQUEST]: createCommentAndAttachmentRequest,
    [Constants.CREATE_COMMENT_AND_ATTACHMENT_SUCCESS]: createCommentAndAttachmentSuccess,
    [Constants.CREATE_COMMENT_AND_ATTACHMENT_ERROR]: createCommentAndAttachmentError,
    [Constants.UPLOAD_ATTACHMENT_TO_CLOUD_REQUEST]: uploadAttachmentToCloudRequest,
    [Constants.UPLOAD_ATTACHMENT_TO_CLOUD_SUCCESS]: uploadAttachmentToCloudSuccess,
    [Constants.UPLOAD_ATTACHMENT_TO_CLOUD_ERROR]: uploadAttachmentToCloudError,
    [Constants.DELETE_COMMENT_AND_ATTACHMENT_REQUEST]: deleteCommentAndAttachmentRequest,
    [Constants.DELETE_COMMENT_AND_ATTACHMENT_SUCCESS]: deleteCommentAndAttachmentSuccess,
    [Constants.DELETE_COMMENT_AND_ATTACHMENT_ERROR]: deleteCommentAndAttachmentError,
    [Constants.UPDATE_COMMENT_AND_ATTACHMENT_REQUEST]: updateCommentAndAttachmentRequest,
    [Constants.UPDATE_COMMENT_AND_ATTACHMENT_SUCCESS]: updateCommentAndAttachmentSuccess,
    [Constants.UPDATE_COMMENT_AND_ATTACHMENT_ERROR]: updateCommentAndAttachmentError,
    [Constants.FETCH_COMMENT_AND_ATTACHMENT_REQUEST]: fetchCommentAndAttachmentRequest,
    [Constants.FETCH_COMMENT_AND_ATTACHMENT_SUCCESS]: fetchCommentAndAttachmentSuccess,
    [Constants.FETCH_COMMENT_AND_ATTACHMENT_ERROR]: fetchCommentAndAttachmentError,
    [Constants.REMOVE_ATTACHMENT_REQUEST]: removeAttachmentRequest,
    [Constants.REMOVE_ATTACHMENT_SUCCESS]: removeAttachmentSuccess,
    [Constants.REMOVE_ATTACHMENT_ERROR]: removeAttachmentError,
};

export default createReducer(INITIAL_STATE, HANDLERS);