const getUnLayerConfig = (apiUrl, eventId, type) => {
  const path = type === 'Login' ? 'login' : 'registerUser'
  
  const enabled = false  
  return {
    id: 'pageDesignEditor',
    displayMode: 'web',
    tools: {
      form: {
        enabled,
        properties: {
          action: {
            editor: {
              data: {
                actions: [
                  {
                    label: 'form',
                    method: 'POST',
                    url: `${apiUrl}/events/${eventId}/${path}`,
                  },
                ],
              },
            },
          },
        },
      },
    },
  }
}

export default getUnLayerConfig
