import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from '../../Redux/Actions';
import { NotificationContainer } from 'react-notifications';
import Loader from '../../Components/Loader/loader';
import '../../sass/main.scss';
import { Button } from '@material-ui/core'
import illumeetLogo from '../../assets/images/loginLogo.png';
import Shape1 from '../../assets/images/Path_421.png';
import Shape2 from '../../assets/images/Path_422.png';
import Shape3 from '../../assets/images/Path_423.png';
import Shape4 from '../../assets/images/Path_424.png';
import Shape5 from '../../assets/images/Path_425.png';
import { Helmet } from 'react-helmet';
import axios from 'axios';

class OpenApiAuth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiKey: '',
            errors: {},
            loading: false,
        };
    }

    componentDidMount() { }

    componentDidUpdate(prevProps, prevState) {
    }

    handleLoading = () => {
        let loading = this.state.loading;
        this.setState({
            loading: !loading,
        });
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    isLoginValid = () => {
        const { apiKey } = this.state;
        let error = {};
        let formIsValid = true;

        if (apiKey === '' || apiKey.trim().length === 0) {
            formIsValid = false;
            error['email'] = '*Please enter api Key.';
        }
        this.setState({ errors: error });
        return formIsValid;
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        if (this.isLoginValid()) {
            this.handleLoading()

            const { apiKey } = this.state;
            let result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/canvaIntegration/connectByApiKey${this.props.location.search}`,
                {},
                {
                    headers: {
                        'Api-Key': apiKey,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (result) {
                let itsFromCanva = new URLSearchParams(this.props.location.search).has('canva_user_token')
                if(itsFromCanva){
                    let state = new URLSearchParams(this.props.location.search).get('state')                
                    const params = new URLSearchParams({
                        success: "true",
                        state: state
                    });

                    window.location.replace(
                        `https://www.canva.com/apps/configured?${params.toString()}`
                    );
                }
            }
        }
    };

    render() {
        const { apiKey, errors, loading } = this.state;
        return (
            <div className="main_page">
                <Helmet>
                    <title>IllumeetXP | Api key authentication</title>
                </Helmet>
                <div className="registerPage">
                    <div className="leftSidebar">
                        <div className="registerSidebarBrand">
                            <img src={illumeetLogo} alt="Illumeet Logo" />
                            <div className="brandingText">
                                <h1>
                                    One platform. <br />
                                    One process. <br />
                                    All your events.
                                </h1>
                            </div>
                        </div>
                        <div className="signupShapes">
                            <span className="shape-1">
                                <img src={Shape1} alt="" />
                            </span>
                            <span className="shape-2">
                                <img src={Shape2} alt="" />
                            </span>
                            <span className="shape-3">
                                <img src={Shape3} alt="" />
                            </span>
                            <span className="shape-4">
                                <img src={Shape4} alt="" />
                            </span>
                            <span className="shape-5">
                                <img src={Shape5} alt="" />
                            </span>
                        </div>
                    </div>
                    <div className="registerFormMain">
                        <div className="registerFormInner">
                            <div className="pageHeading text-center">
                                <h1>API KEY AUTHENTICATION</h1>
                                <p>Please enter your api key to connect</p>
                                {loading ? <Loader /> : null}
                            </div>
                            <div className="formSection LoginForm">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="field-group fullWidthInput">
                                        <label>Api Key</label>
                                        <input
                                            type="text"
                                            name="apiKey"
                                            value={apiKey}
                                            onChange={this.handleChange}
                                            className="form-control emailLoweCase"
                                        />
                                        <p className="error_mesage"> {} </p>
                                    </div>

                                    <div className="field-group fullWidthInput">
                                        <div className="submitForm">
                                        <Button type="submit" className="themeBlueButton">
                                            Submit
                                        </Button>
                                        </div>
                                    </div>                                    
                                </form>
                            </div>
                        </div>
                    </div>

                    <NotificationContainer />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = (dispatch) =>
    bindActionCreators(
        {
        },
        dispatch,
    );
export default connect(mapStateToProps, mapActionsToProps)(OpenApiAuth);
