import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from '../../../Redux/Actions';
import { createNotification } from '../../../helpers';
import Grid from '@mui/material/Grid';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import SearchIcon from '../../../assets/images/icons/search.svg';
import { theme } from '../../../theme/theme';
import { Modal } from 'react-responsive-modal';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Loader from '../../../Components/Loader/loader';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import EditGroup from '../../../assets/images/icons/edit.svg';
import GoogleWorkspace from '../../../assets/images/icons/Google_G_Logo.svg';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import { styled } from '@material-ui/core/styles';
import removeIcon from '../../../assets/images/icons/close.svg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GroupIcon from '../../../assets/images/icons/Group-icon.svg';
import Skeleton from '@material-ui/lab/Skeleton';
import AddIcon from '@material-ui/icons/Add';

const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    top: 12,
    right: '-26px',
    borderRadius: 4,
    width: 34,
    height: 34,
  },
});
class Groups extends Component {
  constructor(props) {
    super(props);
    this.anchorRef2 = React.createRef();
    this.anchorRef = React.createRef();
    this.assignedAttendeesArr = [];
    this.checkBoxIdsArr = [];
    this.state = {
      attendeesGroupName: '',
      openAddGroupModal: false,
      projectId: '',
      errors: {},
      openDeleteModal: false,
      groupId: '',
      page_no2: 1,
      openAssignAttendeesModal: false,
      openEditGroupModal: false,
      editGroupName: false,
      groupName: '',
      changeState: false,
      scroll_no: 1,
      scroll_no2: 1,
      searchTextAttendee: '',
      searchTextUnassignAttendee: '',
      scroll_no_ForUnassignAttendees: 1,
      loading: false,
      searchText: '',
      searchTextGroup: '',
      listOfAssignedAttendees: [],
      listOfAssignedAttendeesData: [],
      isEdit: false,
      ChooseGuest: false,
      openGroupModal: false,
      checkedData: false,
      searchAttendeeGroup: [],
      open2: false,
      filterByStatus: {},
      status: [],
      checkAll: false,
      increaseLoadMoreBy: 10,
      loadMoreNumber: 0,
      disableLoadMore: true,
      lodeMoreAttendeeArray: [],
      disabledLoadmore: false,
      limit: 20,
      getGroupList: false

    };
  }

  componentDidMount() {
    const arr = window.location.pathname.split('/');
    const projectId = arr[arr.length - 1];
    this.setState(
      {
        projectId,
      },
      () => this.listgroups(),
    );
  }

  listgroups = () => {
    try {
      const { projectId, page_no2, searchText } = this.state;
      const body = {
        projectId,
        page_no2,
        searchText: searchText.trim(),
      };
      if (projectId) {
        this.setState({
          getGroupList: true
        });
        this.props.listAttendeesGroups(body);
        // this.handleLoading();
      } else {
        createNotification('error', 'project Id is missing');
      }
    } catch (error) {
      createNotification('error', 'Something went wrong');
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { changeState } = this.state;
    if (
      this.props.attendees.assignAttendeesSuccess === true &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      createNotification('success', this.props.attendees.message);
      this.assignedAttendeesArr = [];
      this.checkBoxIdsArr = [];
      this.setState(
        {
          checkedData: false,
          scroll_no: 1,
          listOfAssignedAttendees: [],
          loading: false,
        },
        () => this.callApiAfterRemove(),
      );
    }

    if (
      this.props.attendees.unAssignAttendeesSuccess === true &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      createNotification('success', this.props.attendees.message);
      this.assignedAttendeesArr = [];
      this.checkBoxIdsArr = [];
      this.setState(
        {
          changeState: !changeState,
          checkedData: false,
          scroll_no: 1,
          listOfAssignedAttendees: [],
          loading: false,
        },
        () => {
          if (this.state.searchTextAttendee.length) {
            this.searchAssignedAttendeesApi();
            this.listAssignedAttendees();
            this.listgroups();
          } else {
            this.listgroups();
            this.listAssignedAttendees();
          }
        },
      );
    }

    if (
      this.props.attendees.searchAttendeesOfGroupSuccess === true &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        searchAttendeeGroup: this.state.searchTextAttendee.length
          ? this.props.attendees.searchAttendeeGroup
          : [],
        loading: false,
      });
    }

    if (
      this.props.attendees.listAssignedAttendeesSuccess === true &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState(
        {
          loading: false,
          listOfAssignedAttendeesData: this.state.listOfAssignedAttendees.concat(
            this.props.attendees.listOfAssignedAttendees,
          ),
          filterByStatus: this.props.attendees.filterByStatus,
        },
        () => this.removeDuplicate(),
      );
    }

    if (
      this.props.attendees.addAttendeesGroupsSuccess === true &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      createNotification('success', this.props.attendees.message);
      this.setState({
        attendeesGroupName: '',
        checkedData: false,
        scroll_no: 1,
        listOfAssignedAttendees: [],
        loading: false,
      });
      this.listgroups();
      this.handleCloseAddGroupModal();
      if (this.state.loading) {
        this.handleLoading();
      }
    }
    if (
      this.props.attendees.getAttendeesGroupsSuccess === true &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        getGroupList: false,
        loading: false
      });
    }

    if (
      this.props.attendees.deleteAttendeesGroupsSuccess === true &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      createNotification('success', this.props.attendees.message);
      this.setState({
        groupId: '',
      });
      this.listgroups();
      if (this.state.loading) {
        this.handleLoading();
      }
      this.handleCloseDeleteModal();
    }

    if (
      this.props.attendees.editGroupNameSuccess === true &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      createNotification('success', this.props.attendees.message);
      this.listgroups();
      this.handleCloseAddGroupModal();
      this.handleCloseDeleteModal();
      if (this.state.loading) {
        this.handleLoading();
      }
    } else if (
      this.props.attendees.error === true &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      if (this.state.loading) {
        this.handleLoading();
      }
      this.listgroups();
    }
  }

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  listAssignedAttendees = () => {
    try {
      const { groupId, projectId, status, scroll_no, limit } = this.state;
      const body = {
        groupId,
        projectId,
        scroll_no: scroll_no,
        status,
        limit,
      };
      this.props.listAssignedAttendees(body);
      this.handleLoading();
    } catch (error) {
      createNotification('error', 'Something went wrong');
    }
  };

  openAddGroupModal = () => {
    this.setState({
      openAddGroupModal: true,
    });
  };

  openEditGroupModal = (group) => {
    this.setState({
      isEdit: true,
      openAddGroupModal: true,
      groupId: group._id,
      groupSource: group.source,
      attendeesGroupName: group.attendeesGroupName
        ? group.attendeesGroupName
        : this.state.attendeesGroupName,
      }
    );
  };

  handleClickOpenDeleteGroupModal = (id) => {
    this.setState({
      openDeleteModal: true,
      groupId: id,
    });
  };

  unassignAttendees = (assignAttendee) => {
    const { projectId, groupId } = this.state;
    const body = {
      projectId,
      attendeesIds: this.checkBoxIdsArr,
      lngCode:this.props.users.newLangState,
    };
    this.props.unassignAttendees(body);
    this.handleLoading();
  };

  removeAttendee = (assignAttendee) => {
    const { projectId, groupId } = this.state;
    const body = {
      projectId,
      attendeesIds: [assignAttendee._id],
      permission: 'Edit Groups',
    };
    this.props.unassignAttendees(body);
    this.handleLoading();
  };

  handleAssignAttendees = (attendee) => {
    const { groupId, projectId } = this.state;
    const body = {
      projectId,
      attendeesIds: [attendee._id],
      group: this.state.groupId,
    };
    this.props.assignAttendees(body);
    this.handleLoading();
  };

  openGroupModal = (group) => {
    this.setState(
      {
        openGroupModal: true,
        groupId: group._id,
        groupName: group.attendeesGroupName,
        groupSource: group.source
      },
      () => this.listAssignedAttendees(),
    );
  };

  cloeGroupModal = (group) => {
    this.setState({
      searchTextAttendee: '',
      searchAttendeeGroup: [],
      listOfAssignedAttendees: [],
      checkAll: false,
      status: [],
      openGroupModal: false,
      scroll_no: 1,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
    });
  };

  handleCloseAddGroupModal = () => {
    this.setState({
      isEdit: false,
      openAddGroupModal: false,
      attendeesGroupName: '',
    });
  };

  handleChange = (e) => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeSearch = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.searchAfterHandleChange();
        this.chooseGuest();
      },
    );
  };

  addNewGroup = (e) => {
    try {
      e.preventDefault();
      const { projectId, attendeesGroupName } = this.state;
      let error = {};
      if (projectId) {
        if (
          attendeesGroupName !== '' &&
          attendeesGroupName !== undefined &&
          attendeesGroupName.trim().length !== 0
        ) {
          const body = {
            projectId,
            attendeesGroupName: attendeesGroupName
              .replace(/\s{2,}/g, ' ')
              .trim(),
              lngCode: this.props.users.newLangState,
          };
          this.props.addAttendeesGroups(body);
          this.handleLoading();
        } else {
          error['attendeesGroupName'] = 'Please enter the group name';
        }
      } else {
        createNotification('error', 'project Id is missing');
      }
      this.setState({
        errors: error,
      });
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  deleteGroup = () => {
    try {
      const { groupId, projectId } = this.state;
      if (groupId !== '' && groupId !== undefined) {
        const body = {
          groupId,
          projectId,
          lngCode: this.props.users.newLangState,
        };
        this.props.deleteAttendeesGroup(body);
        this.handleLoading();
      }
    } catch (error) {
      createNotification('error', 'Something went wrong');
    }
  };

  selectCheckBox = (e, attendee) => {
    try {
      if (attendee) {
        const index = this.checkBoxIdsArr.indexOf(attendee._id);
        if (index === -1) {
          this.checkBoxIdsArr.push(attendee._id);
        } else {
          this.checkBoxIdsArr.splice(index, 1);
        }
        this.setState({
          changeState: !this.state.changeState,
        });
      }
      if (
        this.checkBoxIdsArr.length === this.state.listOfAssignedAttendees.length
      ) {
        this.setState({
          checkedData: true,
        });
      } else {
        this.setState({
          checkedData: false,
        });
      }
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  searchAssignedAttendees = (e) => {
    e.preventDefault();
    const { projectId, groupId, searchTextAttendee } = this.state;
    if (searchTextAttendee.trim() !== '' && searchTextAttendee !== undefined) {
      const body = {
        projectId,
        groupId,
        searchText: searchTextAttendee,
        scroll_no: 1,
      };
      this.props.searchAssignedAttendeesOfGroup(body);
      this.handleLoading();
    } else {
      this.listAssignedAttendees();
    }
  };

  searchAssignedAttendeesApi = (e) => {
    const { projectId, groupId, searchTextAttendee } = this.state;
    if (searchTextAttendee.trim() !== '' && searchTextAttendee !== undefined) {
      const body = {
        projectId,
        groupId,
        searchText: searchTextAttendee,
        scroll_no: 1,
      };
      this.props.searchAssignedAttendeesOfGroup(body);
      this.handleLoading();
    } else {
      this.listAssignedAttendees();
    }
  };

  handleSearchGroup = (e) => {
    e.preventDefault();
    const { projectId, page_no2, searchTextGroup } = this.state;
    if (searchTextGroup.trim() !== '' && searchTextGroup !== undefined) {
      const body = {
        projectId,
        searchText: searchTextGroup.trim(),
        page_no2: page_no2,
      };
      this.props.listAttendeesGroups(body);
      this.handleLoading();
    } else {
      this.listgroups();
    }
  };

  handleEditGroupName = (e) => {
    e.preventDefault()
    const { projectId, groupId, attendeesGroupName } = this.state;
    let error = {};
    if (attendeesGroupName !== undefined && attendeesGroupName.trim() !== '') {
      const body = {
        projectId,
        groupId,
        attendeesGroupName: attendeesGroupName.replace(/\s{2,}/g, ' ').trim(),
        lngCode: this.props.users.newLangState
      };
      this.props.editAttendeesGroupName(body);
      this.handleLoading();
      this.setState({
        errors: {},
        editGroupName: false,
      });
      this.handleLoading();
    } else {
      error['groupName'] = 'Please enter the group name';
      this.setState({
        errors: error,
      });
    }
  };

  loadMore = () => {
    this.checkBoxIdsArr = [];
    this.setState(
      {
        scroll_no: this.state.scroll_no + 1,
        checkedData: false,
      },
      () => this.listAssignedAttendees(),
    );
  };

  handleSelectAllAttendee = (e) => {
    this.setState({
      checkedData: e.target.checked,
    });
    if (e.target.checked === true) {
      this.state.listOfAssignedAttendees.forEach((attendee) => {
        if (this.checkBoxIdsArr.indexOf(attendee._id) === -1) {
          this.checkBoxIdsArr.push(attendee._id);
        }
      });
    }
    if (e.target.checked === false) {
      this.checkBoxIdsArr = [];
    }
  };

  chooseGuest = () => {
    this.setState({
      ChooseGuest: true,
    });
  };

  handleChooseGuestHide = () => {
    this.setState({
      ChooseGuest: false,
    });
  };

  handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({
        ChooseGuest: false,
      });
    }
  };

  callApiAfterRemove = () => {
    this.listgroups();
    this.searchAssignedAttendeesApi();
    this.listAssignedAttendees();
  };

  handleToggle2 = (status) => {
    this.setState({
      open2: !this.state.open2,
    });
  };

  getFilterCount = (countOf) => {
    let filterByStatus = this.state.filterByStatus.filterByStatus;
    if (filterByStatus && Object.keys(filterByStatus).length) {
      return filterByStatus[countOf] > 0 ? filterByStatus[countOf] : 0;
    }
  };

  filterByStatus = (e) => {
    let { value, checked } = e.target;
    this.checkBoxIdsArr = [];

    if (checked) {
      this.state.status.push(value);
      let values =
        value === 'All'
          ? ['Checked In', 'Not Checked In', 'Checked Out']
          : this.state.status;

      this.setState({
        status: values,
        checkAll: value === 'All' || values.length == 3 ? true : false,
        checkedData: false,
      });
    } else {
      let check = this.state.status?.filter((event) =>
        value === 'All' ? (event = '') : event !== value,
      );
      this.setState({
        status: check,
        checkAll: value === 'All' || check.length !== 3 ? false : true,
        checkedData: false,
      });
    }

    this.setState(
      {
        listOfAssignedAttendees: [],
        scroll_no: 1,
      },
      () => this.listAssignedAttendees(),
    );
  };

  handleClose = (event) => {
    this.setState({
      open2: false,
    });
  };

  searchAfterHandleChange = () => {
    if (
      this.state.searchTextAttendee.trim() === '' ||
      this.state.searchTextAttendee === undefined ||
      this.state.searchTextAttendee.trim().length === 0
    ) {
      this.setState({
        searchAttendeeGroup: [],
      });
    } else {
      this.searchAssignedAttendeesApi();
    }
  };

  isDisabledLoadMore = () => {
    let { filterByStatus, listOfAssignedAttendees, status } = this.state;
    if (status.length <= 0) {
      this.setState({
        disabledLoadmore:
          listOfAssignedAttendees.length ===
            (filterByStatus && filterByStatus.All)
            ? true
            : false,
      });
    } else {
      var disabledLoadmore = '';
      if (status.length === 1 && status.includes('Checked In')) {
        disabledLoadmore =
          listOfAssignedAttendees.length === this.getFilterCount('Checked In')
            ? true
            : false;
      } else if (status.length == 1 && status.includes('Not Checked In')) {
        disabledLoadmore =
          listOfAssignedAttendees.length ===
            this.getFilterCount('Not Checked In')
            ? true
            : false;
      } else if (status.length == 1 && status.includes('Checked Out')) {
        disabledLoadmore =
          listOfAssignedAttendees.length === this.getFilterCount('Checked Out')
            ? true
            : false;
      } else if (
        status.length == 2 &&
        status.includes('Checked In') &&
        status.includes('Not Checked In')
      ) {
        disabledLoadmore =
          listOfAssignedAttendees.length ===
            this.getFilterCount('Checked In') +
            this.getFilterCount('Not Checked In')
            ? true
            : false;
      } else if (
        status.length == 2 &&
        status.includes('Checked In') &&
        status.includes('Checked Out')
      ) {
        disabledLoadmore =
          listOfAssignedAttendees.length ===
            this.getFilterCount('Checked In') + this.getFilterCount('Checked Out')
            ? true
            : false;
      } else if (
        status.length == 2 &&
        status.includes('Not Checked In') &&
        status.includes('Checked Out')
      ) {
        disabledLoadmore =
          listOfAssignedAttendees.length ===
            this.getFilterCount('Not Checked In') +
            this.getFilterCount('Checked Out')
            ? true
            : false;
      } else {
        disabledLoadmore =
          listOfAssignedAttendees.length ===
            (filterByStatus && filterByStatus.All)
            ? true
            : false;
      }
      this.setState({
        disabledLoadmore,
      });
    }
  };

  removeDuplicate = () => {
    let uniqueArray = a => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s));
    let listOfAssignedAttendees = uniqueArray(this.state.listOfAssignedAttendeesData);
    this.setState({
      listOfAssignedAttendees
    }, () => this.isDisabledLoadMore());
  };

  checkWhetherAttendeesGroupsLengthIsReallyZero = (groups) => {
    let realGroupsCount = groups.filter(g => g.source === "Manually Added" || (g.source === "GoogleWorkspace" && g.showToGroup));
    if (realGroupsCount.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const {
      filterByStatus,
      open2,
      searchAttendeeGroup,
      attendeesGroupName,
      errors,
      searchTextAttendee,
      searchTextGroup,
      groupSource,
      listOfAssignedAttendees,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} mb={2} className="top-filter-area">
          <Grid item>
            <Grid container alignItems="center">
              <IconButton size="small" aria-label="delete">
                <ArrowBackIcon
                  style={{ color: theme.palette.common.black }}
                  onClick={
                    ()=> {
                      this.props.getAttendeesList();
                      this.props.closeGroups();
                    }
                  }
                />
              </IconButton>
              <p
                className="subtitle"
                style={{ color: theme.palette.grey[800], marginLeft: 10 }}
              >
                {this.props.attendees?.totalGroupsToListInGroupsTab ? (
                  <>
                    {this.props.users.languageStateForRedux?.dropdowns?.attendees?.allGroups} (
                    {this.props.attendees?.totalGroupsToListInGroupsTab}
                    )
                  </>
                ) :
                  <>
                    { this.props.users.languageStateForRedux?.column_names?.attendees?.groups}
                  </>
                }


              </p>
            </Grid>
          </Grid>

          {this.props.attendees.attendeesGroups &&
            Array.isArray(this.props.attendees.attendeesGroups) &&
            this.props.attendees.attendeesGroups.length > 0 ?
            <Grid item>
              <Grid container spacing={1.25} className="team-dash-right">
                <Grid item className="">
                  <form onSubmit={this.handleSearchGroup}>
                    <TextField
                      value={searchTextGroup}
                      name="searchTextGroup"
                      onChange={this.handleChange}
                      placeholder={this.props.users.languageStateForRedux?.actions?.attendees?.groupPlaceholder}
                      className="search"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img alt="" src={SearchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      style={{ width: 320 }}
                    />
                  </form>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.openAddGroupModal}
                  >
                    {this.props.users.languageStateForRedux?.buttons?.attendees?.groups?.add}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            : null
          }

        </Grid>
        <Grid container spacing={3} className="groups-list">
          {this.props.attendees.attendeesGroups &&
            Array.isArray(this.props.attendees.attendeesGroups) &&
            this.props.attendees.attendeesGroups.length > 0 && this.state.getGroupList === false && this.checkWhetherAttendeesGroupsLengthIsReallyZero(this.props.attendees.attendeesGroups) ? (
            this.props.attendees.attendeesGroups.map((group) => {
              return (
                (group?.source === 'GoogleWorkspace'
                  ? group?.showToGroup
                    ? true
                    : false
                  : true) && (
                  <Grid key={group._id} item xs={12} sm={6} md={4} xl={3}>
                    <Box className="groups-list-card">
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <p
                            className="paragraph group-name"
                            style={{ cursor: 'pointer' }}
                            onClick={()=> this.openGroupModal(group)}
                          >
                            <strong>{group.attendeesGroupName}</strong> (
                            {group.totalAttendeesCount})
                          </p>
                        </Grid>
                        {group?.source !== 'GoogleWorkspace' ? (
                          <Grid item>
                            <Stack spacing={1} direction="row">
                              <IconButton
                                size="small"
                                onClick={() => this.openEditGroupModal(group)}
                                style={{ padding: '0 6px' }}
                              >
                                <img alt="save" src={EditGroup} />
                              </IconButton>
                              <Box className="options">
                                <IconButton
                                  className="options-button"
                                  size="small"
                                >
                                  <MoreHorizIcon
                                    style={{
                                      color: theme.palette.primary.dark,
                                    }}
                                  />
                                </IconButton>
                                <Box className="options-list">
                                  <div
                                    onClick={() => this.openGroupModal(group)}
                                  >
                                    { this.props.users.languageStateForRedux?.actions?.attendees?.manageGroup}
                                  </div>
                                  <hr />
                                  <div
                                    onClick={() =>
                                      this.handleClickOpenDeleteGroupModal(
                                        group._id,
                                      )
                                    }

                                    style={{color:'#ff4170'}}
                                  >
                                    { this.props.users.languageStateForRedux?.common?.delete}
                                  </div>
                                </Box>
                              </Box>
                            </Stack>
                          </Grid>
                        ) : (
                          <Grid item>
                            <Stack spacing={2} direction="row">
                              <Tooltip
                                title="Imported from Google Workspace"
                                placement="right"
                                arrow
                              >
                                <img
                                  src={GoogleWorkspace}
                                  alt="google workspace"
                                  width="18px"
                                />
                              </Tooltip>

                              <Box className="options">
                                <IconButton
                                  className="options-button"
                                  size="small"
                                >
                                  <MoreHorizIcon
                                    style={{
                                      color: theme.palette.primary.dark,
                                    }}
                                  />
                                </IconButton>
                                <Box className="options-list">
                                  <div
                                    onClick={() =>
                                      this.openGroupModal(group)
                                    }
                                  >
                                    View Group
                                  </div>
                                </Box>
                              </Box>
                            </Stack>
                          </Grid>
                        )}
                      </Grid>
                      <Box
                        component={Grid}
                        mt={3}
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <p className="small-subtitle ">{this.props.users.languageStateForRedux?.column_names?.attendees?.notChecked}</p>
                          <Box
                            component="p"
                            sx={{ color: theme.palette.grey[800] }}
                            className="small-subtitle "
                          >
                            <strong>
                              {typeof group.countByAttendeesStatus !=
                                'undefined' &&
                                Object.keys(group.countByAttendeesStatus).length >
                                0 &&
                                group.countByAttendeesStatus['Not Checked In']
                                ? group.countByAttendeesStatus['Not Checked In']
                                : 0}
                            </strong>
                          </Box>
                        </Grid>
                        <Grid item>
                          <p className="small-subtitle ">{this.props.users.languageStateForRedux?.column_names?.attendees?.checkedIn}</p>
                          <Box
                            component="p"
                            sx={{ color: theme.palette.grey[800] }}
                            className="small-subtitle "
                          >
                            <strong>
                              {typeof group.countByAttendeesStatus !=
                                'undefined' &&
                                Object.keys(group.countByAttendeesStatus).length >
                                0 &&
                                group.countByAttendeesStatus['Checked In']
                                ? group.countByAttendeesStatus['Checked In']
                                : 0}
                            </strong>
                          </Box>
                        </Grid>
                        <Grid item>
                          <p className="small-subtitle ">{this.props.users.languageStateForRedux?.column_names?.attendees?.checkedOut}</p>
                          <Box
                            component="p"
                            sx={{ color: theme.palette.grey[800] }}
                            className="small-subtitle "
                          >
                            <strong>
                              {typeof group.countByAttendeesStatus !=
                                'undefined' &&
                                Object.keys(group.countByAttendeesStatus).length >
                                0 &&
                                group.countByAttendeesStatus['Checked Out']
                                ? group.countByAttendeesStatus['Checked Out']
                                : 0}
                            </strong>
                          </Box>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                )
              );
            })
          ) : (
            this.props.attendees.attendeesGroups &&

            Array.isArray(this.props.attendees.attendeesGroups) &&
            this.state.getGroupList === false && !this.checkWhetherAttendeesGroupsLengthIsReallyZero(this.props.attendees.attendeesGroups)) ? (
            <Grid item xs="12">
              <Grid container alignItems={'center'} justifyContent={'center'}>
                <Grid item xs={12} sm={8} md={6} lg={5} xl={4} display={'flex'} direction={'column'} alignItems={'center'}>
                  <Box mb={3} textAlign={'center'}>
                    <img src={GroupIcon} alt="Group Icon" />
                  </Box>
                  <Box className='subtitle' mb={3} textAlign={'center'} color={'#000000'}>{this.props.users.languageStateForRedux?.formfields?.groups?.welcome}</Box>
                  <Box className='subtitle' mb={3} textAlign={'center'} fontWeight={'400'} color={"#726F8E"}>
                  {this.props.users.languageStateForRedux?.formfields?.groups?.longText}
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.openAddGroupModal}
                  >
                    {this.props.users.languageStateForRedux?.buttons?.attendees?.groups?.add}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs="12">
              <Grid container alignItems={'center'} justifyContent={'center'}>
                <Grid item xs={12} sm={8} md={6} lg={5} xl={4} display={'flex'} direction={'column'} alignItems={'center'}>

                  <Grid item xs={12} sm={6} md={4} xl={4}>
                    <Box mt={3} className="groups-list-card">
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Skeleton animation="wave" width={130} height={20} />
                        </Grid>

                        <Grid item>
                          <Stack spacing={2} direction="row">
                            <Skeleton animation="wave" variant="circle" width={20} height={20} />
                            <Skeleton animation="wave" variant="circle" width={20} height={20} />
                          </Stack>
                        </Grid>

                      </Grid>
                      <Box
                        component={Grid}
                        mt={3}
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Grid item>
                          <Skeleton animation="wave" width={90} height={10} />
                          <Skeleton animation="wave" width={20} height={10} />
                        </Grid>
                        <Grid item>
                          <Skeleton animation="wave" width={90} height={10} />
                          <Skeleton animation="wave" width={20} height={10} />
                        </Grid>
                        <Grid item>
                          <Skeleton animation="wave" width={90} height={10} />
                          <Skeleton animation="wave" width={20} height={10} />
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                  <Box className='subtitle' mt={3} textAlign={'center'} color={'#000000'}>
                    Please wait! <br />
                    we are fetching Group data...</Box>
                </Grid>
              </Grid>
            </Grid>
          )
          }
        </Grid>

        <Modal
          open={this.state.openAddGroupModal}
          onClose={this.handleCloseAddGroupModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
          closeOnOverlayClick={false}
        >
          <form onSubmit={this.state.isEdit ? this.handleEditGroupName : this.addNewGroup}>
          <Grid component="form" container className="modal-wrapper">
            <Grid item xs={12} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {!this.state.isEdit ? this.props.users.languageStateForRedux?.formfields?.groups?.add : this.props.users.languageStateForRedux?.formfields?.groups?.update}
              </Box>
              <p className="small-subtitle">
              {this.props.users.languageStateForRedux?.formfields?.groups?.addMsg}
              </p>
            </Grid>
            <Grid item xs={12} pb={4}>
              <Box component="div" className="inputField">
                <label for='attendeesGroupName'>
                {this.props.users.languageStateForRedux?.formfields?.groups?.name}<span className="required">*</span>
                </label>
                <input
                  type="text"
                  maxLength={'40'}
                  class="form-control small"
                  placeholder=""
                  name="attendeesGroupName"
                  value={attendeesGroupName}
                  onChange={this.handleChange}
                />
                <span className="error_mesage required">
                  {' '}
                  {errors.attendeesGroupName}{' '}
                </span>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={
                      this.state.isEdit
                        ? this.handleEditGroupName
                        : this.addNewGroup
                    }
                    type='submit'
                  >
                    {this.props.users.languageStateForRedux?.common?.save}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={this.handleCloseAddGroupModal}
                    type='button'
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          </form>
        </Modal>
        <Modal
          open={this.state.openDeleteModal}
          onClose={this.handleCloseDeleteModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Box component={Grid} container className="" px={1.5}>
            <Box component={Grid} mt={-1} item xs={12} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {' '}
                {this.props.users.languageStateForRedux?.common?.confirmDelete}
              </Box>
              <p className="small-subtitle">
              {this.props.users.languageStateForRedux?.common?.deleteMsg}
              </p>
            </Box>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={this.deleteGroup}
                  >
                    {this.props.users.languageStateForRedux?.common?.delete}
                  </Button>
                  <Button
                    variant="outlined"
                    disableElevation
                    onClick={this.handleCloseDeleteModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={this.state.openGroupModal}
          onClose={this.cloeGroupModal}
          classNames={{
            overlay: 'full-screen_overlay',
            modal:
              'full-screen_modal manage-custom-fields modal-default-close-none',
          }}
          center
        >
          <Grid container justifyContent="end">
            <Grid
              item
              xs={12}
              md={7}
              lg={6}
              xl={5}
              className="full-screen_modal__form"
            >
              <Box component="div" className="full-screen_modal__form_header">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <h3 className="subtitle">
                    {this.state.groupName} 
                    </h3>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Box component={Grid} item ml={1}>
                        <CloseIcon
                          onClick={this.cloeGroupModal}
                          className="modal-close-button"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box
                component="div"
                px={3}
                py={2}
                className="full-screen_modal__form_body"
              >
                <Grid
                  spacing={1.5}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item className="top-filter-area">
                    <Box
                      component={Button}
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.background.light,
                        color: theme.palette.common.black,
                        textTransform: 'capitalize',
                        fontWeight: '400',

                        '&:hover, &.active': {
                          backgroundColor: theme.palette.primary.dark,
                          color: theme.palette.primary.contrastText,
                        },
                      }}
                      className={this.state.status.length ? 'active' : null}
                      size="small"
                      disableElevation
                      ref={this.anchorRef2}
                      aria-controls={open2 ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={() => this.handleToggle2()}
                      endIcon={<ArrowDropDownIcon />}
                      disabled={
                        this.state.listOfAssignedAttendees &&
                          this.state.listOfAssignedAttendees.length > 0
                          ? false
                          : true
                      }
                    >
                     {this.props.users.languageStateForRedux?.dropdowns?.attendees?.allAttendees}(
                      {filterByStatus && filterByStatus.All
                        ? filterByStatus.All
                        : '0'}
                      )
                    </Box>
                    <Popper
                      open={open2}
                      style={{ zIndex: '4' }}
                      placement="bottom-start"
                      transition
                      anchorEl={this.anchorRef2.current}
                      role={undefined}
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Fade {...TransitionProps} timeout={250}>
                          <Box
                            className="option-list"
                            sx={{
                              backgroundColor: theme.palette.background.light,
                              borderRadius: '4px',
                              marginTop: '8px',
                              overflowY: 'scroll',
                              maxHeight: '195px',
                            }}
                          >
                            <ClickAwayListener onClickAway={this.handleClose}>
                              <MenuList
                                id="menu-list-grow"
                                onKeyDown={this.handleListKeyDown}
                              >
                                <MenuItem className="checkbox-item">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="default"
                                        name="All"
                                        value={'All'}
                                        className="checkbox"
                                        onChange={(e) => this.filterByStatus(e)}
                                        checked={
                                          this.state.checkAll === true
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    label={`All (
                                ${filterByStatus && filterByStatus.All
                                        ? filterByStatus.All
                                        : '0'
                                      }
                                )`}
                                  />
                                </MenuItem>
                                <MenuItem className="checkbox-item">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="default"
                                        name="Checked In"
                                        value={'Checked In'}
                                        className="checkbox"
                                        onChange={(e) => this.filterByStatus(e)}
                                        checked={
                                          this.state.status.indexOf(
                                            'Checked In',
                                          ) > -1
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    label={`Checked In (
                                ${filterByStatus &&
                                        filterByStatus.filterByStatus &&
                                        Object.keys(filterByStatus.filterByStatus)
                                          .length
                                        ? this.getFilterCount('Checked In')
                                        : '0'
                                      }
                                )`}
                                  />
                                </MenuItem>
                                <MenuItem className="checkbox-item">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="default"
                                        name="Checked Out"
                                        value={'Checked Out'}
                                        className="checkbox"
                                        onChange={(e) => this.filterByStatus(e)}
                                        checked={
                                          this.state.status.indexOf(
                                            'Checked Out',
                                          ) > -1
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    label={`Checked Out (
                                ${filterByStatus &&
                                        filterByStatus.filterByStatus &&
                                        Object.keys(filterByStatus).length
                                        ? this.getFilterCount('Checked Out')
                                        : '0'
                                      }
                                )`}
                                  />
                                </MenuItem>
                                <MenuItem className="checkbox-item">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="default"
                                        name="Not Checked In"
                                        value={'Not Checked In'}
                                        className="checkbox"
                                        onChange={(e) => this.filterByStatus(e)}
                                        checked={
                                          this.state.status.indexOf(
                                            'Not Checked In',
                                          ) > -1
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    label={`Not Checked In (
                                ${filterByStatus &&
                                        filterByStatus.filterByStatus &&
                                        Object.keys(filterByStatus).length
                                        ? this.getFilterCount('Not Checked In')
                                        : '0'
                                      }
                                )`}
                                  />
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Box>
                        </Fade>
                      )}
                    </Popper>
                  </Grid>
                  {groupSource !== 'GoogleWorkspace' && <Grid item className="" sx={{ minWidth: "45%" }}>
                    {/* search and select */}
                    <Box
                      className="attendee-search"
                      style={{
                        position: 'relative',
                      }}
                    >
                      <form onSubmit={this.searchAssignedAttendees}>
                        <Box
                          component={TextField}
                          sx={{
                            marginTop: '2px',
                            padding: '3px 0px',
                          }}
                          notched={false}
                          size="medium"
                          fullWidth
                          variant="standard"
                          name="searchTextAttendee"
                          value={searchTextAttendee}
                          onChange={this.handleChangeSearch}
                          placeholder={this.props.users.languageStateForRedux?.formfields?.attendees?.searchText}
                          className="search"
                          InputProps={{
                            startAdornment: (
                              <Box
                                component={InputAdornment}
                                pb={0.5}
                                position="start"
                              >
                                <img alt="" src={SearchIcon} />
                              </Box>
                            ),
                          }}
                          ref={this.anchorRef}
                          aria-controls={
                            this.state.ChooseGuest
                              ? 'menu-list-grow'
                              : undefined
                          }
                          autoComplete="off"
                          aria-haspopup="true"
                          // onClick={this.chooseGuest}
                          inputProps={{ maxLength: 63 }}
                        />
                      </form>
                      <Popper
                        open={this.state.ChooseGuest}
                        style={{ zIndex: 4, minWidth: '100%' }}
                        placement="bottom-end"
                        transition
                        anchorEl={this.anchorRef.current}
                        role={undefined}
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Fade {...TransitionProps} timeout={250}>
                            <Box
                              sx={{
                                maxHeight: '230px',
                                overflow: 'scroll',
                                backgroundColor: theme.palette.common.white,
                                boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                borderRadius: '4px',
                                marginTop: '8px',
                              }}
                            >
                              <ClickAwayListener
                                onClickAway={this.handleChooseGuestHide}
                              >
                                <Box
                                  id="menu-list-grow"
                                  onKeyDown={this.handleListKeyDown}
                                >
                                  <MenuList>
                                    {searchAttendeeGroup &&
                                      Array.isArray(searchAttendeeGroup) &&
                                      searchAttendeeGroup.length > 0 ? (
                                      searchAttendeeGroup.map((attendee) => {
                                        return (
                                          <MenuItem className="attendee-list"
                                            onClick={() => {
                                              attendee.type !== 'assigned'
                                                ? this.handleAssignAttendees(
                                                  attendee,
                                                )
                                                : this.removeAttendee(
                                                  attendee,
                                                );
                                            }}
                                          >
                                            <Box mr={3}>{attendee.email}</Box>
                                            <Box
                                              className={
                                                attendee.type === 'assigned'
                                                  ? 'button remove'
                                                  : 'button add'
                                              }
                                              
                                            >
                                              {attendee.type !== 'assigned'
                                                ? <AddIcon />
                                                : <CloseIcon />}
                                            </Box>
                                          </MenuItem>
                                        );
                                      })
                                    ) : (
                                      <Box className="attendee-list">
                                        <Grid
                                          container
                                          alignItems="center"
                                          justifyContent="space-between"
                                        >
                                          <Grid item xs={12}>
                                            <Box
                                              px={1.5}
                                              textAlign="center"
                                              className="paragraph"
                                            >
                                              <strong>
                                              {this.props.users.languageStateForRedux?.formfields?.groups?.noAttendees}
                                              </strong>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    )}
                                  </MenuList>
                                </Box>
                              </ClickAwayListener>
                            </Box>
                          </Fade>
                        )}
                      </Popper>
                    </Box>
                  </Grid>}
                  {Array.isArray(this.checkBoxIdsArr) &&
                    this.checkBoxIdsArr.length !== 0 && (
                      <Grid item>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={this.unassignAttendees}
                          disableElevation
                        >
                          {this.props.users.languageStateForRedux?.buttons?.attendees?.removeAttendees}
                        </Button>
                      </Grid>
                    )}
                </Grid>

                <Box
                  className="custom-fields-table"
                  scomponent={TableContainer}
                  sx={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'scroll',
                  }}
                  my={2}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {groupSource !== 'GoogleWorkspace' && <TableCell className="tableBodyCell checkbox">
                          <Checkbox
                            color="primary"
                            p={0}
                            onChange={(e) => {
                              this.handleSelectAllAttendee(e);
                            }}
                            checked={this.state.checkedData}
                          />
                        </TableCell>}
                        <TableCell
                          className="tableHeadCell long-text"
                          align="left"
                          style={{ maxWidth: '200px' }}
                        >
                          {this.props.users.languageStateForRedux?.column_names?.teams?.email}
                        </TableCell>
                        <TableCell className="tableHeadCell status" style={{ minWidth: '170px' }}>
                        {this.props.users.languageStateForRedux?.common?.status}
                        </TableCell>
                        {groupSource !== 'GoogleWorkspace' && <TableCell className="tableHeadCell" align="right" style={{ width: '100px' }}>
                          {this.props.users.languageStateForRedux?.formfields?.attendees?.remove}
                        </TableCell>}
                      </TableRow>
                    </TableHead>
                    {Array.isArray(listOfAssignedAttendees) &&
                      listOfAssignedAttendees?.length > 0 ? (
                      <TableBody>
                        {Array.isArray(listOfAssignedAttendees) &&
                          listOfAssignedAttendees.length &&
                          listOfAssignedAttendees.map((assignAttendee, i) => {
                            return (
                              <TableRow>
                                {groupSource !== 'GoogleWorkspace' && <TableCell
                                  className="tableBodyCell checkbox"
                                  scope="row"
                                >
                                  <Checkbox
                                    color="primary"
                                    onChange={(e) =>
                                      this.selectCheckBox(e, assignAttendee)
                                    }
                                    disabled={groupSource === 'GoogleWorkspace'}
                                    checked={
                                      this.checkBoxIdsArr.indexOf(
                                        assignAttendee._id,
                                      ) !== -1
                                        ? true
                                        : false
                                    }
                                  />
                                </TableCell>}
                                <TableCell
                                  className="tableBodyCell long-text"
                                  align="left"
                                  style={{ maxWidth: '200px' }}
                                >
                                  {assignAttendee.email}
                                </TableCell>
                                <TableCell className="tableBodyCell status" style={{ minWidth: '170px' }} >
                                  <span
                                    className={
                                      assignAttendee.status === 'Not Checked In'
                                        ? 'table-status light'
                                        : assignAttendee.status === 'Checked In'
                                          ? 'table-status success'
                                          : 'table-status danger'
                                    }
                                  >
                                    {' '}
                                    {assignAttendee.status}{' '}
                                  </span>
                                </TableCell>
                                {groupSource !== 'GoogleWorkspace' && <TableCell
                                  className="tableBodyCell"
                                  align="right"
                                  style={{ width: '100px' }}
                                >
                                  <Tooltip
                                    placement="left"
                                    arrow
                                    title="remove"
                                  >
                                    <Box
                                      component={'span'}
                                      sx={{ cursor: 'pointer' }}
                                    >
                                      <img
                                        alt="CloseIcon"
                                        width={'14px'}
                                        src={removeIcon}
                                        onClick={() =>
                                          this.removeAttendee(assignAttendee)
                                        }
                                      />
                                    </Box>
                                  </Tooltip>
                                </TableCell>}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            className="tableBodyCell email"
                            style={{ textAlign: 'center' }}
                          >
                            {this.props.users.languageStateForRedux?.common?.noRecords}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </Box>
              </Box>
              {this.state.listOfAssignedAttendees.length !== 0 && (
                <Box className="full-screen_modal__form_footer">
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={this.loadMore}
                            disabled={this.state.disabledLoadmore}
                          >
                            Load More
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        </Modal>
        {this.state.loading ? <Loader /> : null}
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  attendees: state.Attendees,
  events: state.Events,
  users: state.Users,
});
const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      editAttendeesGroupName: Actions.editAttendeesGroupNameRequest,
      searchUnassignedAttendeesOfGroup:
        Actions.searchAttendeesOfUnassignGroupRequest,
      searchAssignedAttendeesOfGroup: Actions.searchAttendeesOfGroupRequest,
      assignAttendees: Actions.assignAttendeesRequest,
      unassignAttendees: Actions.unassignAttendeesRequest,
      listAssignedAttendees: Actions.listAssignedAttendeesRequest,
      listUnassignedAttendees: Actions.listUnassignedAttendeesRequest,
      listAttendeesGroups: Actions.listAttendeesGroupsRequest,
      addAttendeesGroups: Actions.addAttendeesGroupsRequest,
      deleteAttendeesGroup: Actions.deleteAttendeesGroupsRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Groups));
