import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
  success: false,
  error: false,
  listSuccess: false,
  listError: false,
  contactSuccess: false,
  contactError: false,
  groupSuccess: false,
  groupError: false,
  importSuccess: false,
  importError: false,
  updateSuccess: false,
  updateError: false,
  searchError: false,
  csvSuccess: false,
  csvError: false,
  message: null,
  event: null,
  totalResults: 0,
  listAllSuccess: false,
  imported: 0,
  rejected: 0,
  importContactValidation: false,
  importGroupContactValidation: false,
  duplicateContactsInCsv: 0,
  invalidEmailsRejection: 0,
  alreadyExistInDb: 0,
  rejectedEmails: 0,
  createGroupSuccess: false,
  createGroupError: false,
  importedCount: 0,
  copyCheck:false,
  deleteContactSuccess:false
}

const createGroupRequest = (state = INITIAL_STATE, action) => {
  defaultStates()
  return {
    ...state,
    //totalResults: 0,
    message: null,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    contactSuccess: false,
    contactError: false,
    groupSuccess: false,
    groupError: false,
    importSuccess: false,
    importError: false,
    updateSuccess: false,
    updateError: false,
    searchError: false,
    csvSuccess: false,
    csvError: false,
    xslSuccess: false,
    xslError: false,
    listAllSuccess: false,
    createGroupSuccess: false,
    createGroupError: false,
    rejectContactListError: false,
    rejectContactListSuccess: false,
    rejectedList: [],
    totalCount: 0,
  }
}

const rejectContactListSuccess = (state = INITIAL_STATE, action) => {

  return {
    ...state,
    rejectContactListError: false,
    rejectContactListSuccess: true,
    rejectedList: action.rejectedList,
    message: action.message,
    totalCount: action.totalCount,
  }
}

const rejectContactListError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    rejectContactListError: true,
    rejectContactListSuccess: false,
    message: action.error,
    rejectedList: [],
    totalCount: 0,
  }
}

const rejectContactListRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    rejectContactListError: false,
    rejectContactListSuccess: false,
    rejectedList: [],
    totalCount: 0,
    message: null,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    contactSuccess: false,
    contactError: false,
    groupSuccess: false,
    groupError: false,
    importSuccess: false,
    importError: false,
    updateSuccess: false,
    updateError: false,
    searchError: false,
    csvSuccess: false,
    csvError: false,
    xslSuccess: false,
    xslError: false,
    listAllSuccess: false,
    createGroupSuccess: false,
    rejectContactlistError: false,
  }
}

const createGroupSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    createGroupError: false,
    createGroupSuccess: true,
    message: action.message,
  }
}

const createGroupError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    createGroupError: true,
    createGroupSuccess: false,
    message: action.error,
  }
}

const defaultStates = (state = INITIAL_STATE) => {
  return {
    ...state,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    contactSuccess: false,
    contactError: false,
    groupSuccess: false,
    groupError: false,
    importSuccess: false,
    importError: false,
    updateSuccess: false,
    updateError: false,
    searchError: false,
    csvSuccess: false,
    csvError: false,
    xslSuccess: false,
    xslError: false,
    message: null,
    totalResults: 0,
    imported: 0,
    rejected: 0,
  }
}

/* const defaultSt = {
  success: false,
  error: false,
  listSuccess: false,
  listError: false,
  contactSuccess: false,
  contactError: false,
  groupSuccess: false,
  groupError: false,
  importSuccess: false,
  importError: false,
  updateSuccess: false,
  updateError: false,
  searchError: false,
  csvSuccess: false,
  csvError: false,
  xslSuccess: false,
  xslError: false,
  totalResults: 0,
  groupId: '',
  listAllSuccess: false,
  allContactsList: [],
  imported: 0,
  rejected: 0,
}
 */
const contactSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    contactSuccess: true,
    createGroupSuccess:false,
    //contacts: action.contacts,
    //totalResults: action.totalResults,
    message: action.message,
  }
}

const contactError = (state = INITIAL_STATE, action) => {
  return { ...state, contactError: true, message: action.error, contactSuccess:false }
}

const contactRequest = (state = INITIAL_STATE, action) => {
  defaultStates()
  return {
    ...state,
    //totalResults: 0,
    message: null,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    contactSuccess: false,
    contactError: false,
    groupSuccess: false,
    groupError: false,
    importSuccess: false,
    importError: false,
    updateSuccess: false,
    updateError: false,
    searchError: false,
    csvSuccess: false,
    csvError: false,
    xslSuccess: false,
    xslError: false,
    listAllSuccess: false,
    createGroupSuccess:false,
  }
}

const listContactSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    listSuccess: true,
    contacts: action.contacts,
    totalResults: action.totalResults,
    message: action.message,
    createGroupSuccess:false,
  }
}

const listContactError = (state = INITIAL_STATE, action) => {
  return { ...state, listAllError: true, message: action.error }
}

const listContactRequest = (state = INITIAL_STATE, action) => {
  defaultStates()
  return {
    ...state,
    totalResults: 0,
    message: null,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    contactSuccess: false,
    contactError: false,
    groupSuccess: false,
    groupError: false,
    importSuccess: false,
    importError: false,
    updateSuccess: false,
    updateError: false,
    searchError: false,
    csvSuccess: false,
    csvError: false,
    xslSuccess: false,
    xslError: false,
    listAllSuccess: false,
    createGroupSuccess:false,
  }
}

const listAllContactSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    listAllSuccess: true,
    allContactsList: action.allContactsList,
    message: action.message,
  }
}

const listAllContactError = (state = INITIAL_STATE, action) => {
  return { ...state, listAllError: true, message: action.error }
}

const listAllContactRequest = (state = INITIAL_STATE, action) => {
  defaultStates()
  return {
    ...state,
    totalResults: 0,
    message: null,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    contactSuccess: false,
    contactError: false,
    groupSuccess: false,
    groupError: false,
    importSuccess: false,
    importError: false,
    updateSuccess: false,
    updateError: false,
    searchError: false,
    csvSuccess: false,
    csvError: false,
    xslSuccess: false,
    xslError: false,
    listAllSuccess: false,
  }
}

const groupContactSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    groupSuccess: true,
    importContactId: action.importContactId,
    message: action.message,
    copyCount: action.copyCount,
    importlength: action.importlength,
    copyCheck :action.copyCheck,
    importCheck:action.importCheck,
    createGroupSuccess:false,
  }
}

const groupContactError = (state = INITIAL_STATE, action) => {
  return { ...state, groupError: true, message: action.error }
}

const groupContactRequest = (state = INITIAL_STATE, action) => {
  defaultStates()
  return {
    ...state,
    copyCheck:false,
    importCheck:false,
    copyCount: 0,
    importlength: 0,
    totalResults: 0,
    message: null,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    contactSuccess: false,
    contactError: false,
    groupSuccess: false,
    groupError: false,
    importSuccess: false,
    importError: false,
    updateSuccess: false,
    updateError: false,
    searchError: false,
    csvSuccess: false,
    csvError: false,
    xslSuccess: false,
    xslError: false,
    listAllSuccess: false,
    importGroupContactValidation: false,
    createGroupSuccess:false,
  }
}

const importContactSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    importSuccess: true,
    createGroupSuccess:false,
    importContactId: action.importContactId,
    message: action.message,
    importedCount: action.importedCount,
  }
}

const importContactError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    importError: true,
    message: action.error,
    importedCount: 0,
    createGroupSuccess:false,
  }
}

const importContactRequest = (state = INITIAL_STATE, action) => {
  defaultStates()
  return {
    ...state,
    importedCount: 0,
    totalResults: 0,
    message: null,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    contactSuccess: false,
    contactError: false,
    groupSuccess: false,
    groupError: false,
    importSuccess: false,
    importError: false,
    updateSuccess: false,
    updateError: false,
    searchError: false,
    csvSuccess: false,
    csvError: false,
    xslSuccess: false,
    xslError: false,
    listAllSuccess: false,
    importContactValidation: false,
    duplicateContactsInCsv: 0,
    mandetoryMissingInCsv:0,
    invalidEmailsRejection: 0,
    alreadyExistInDb: 0,
    rejectedEmails: 0,
    rejectedGroupEmails: 0,
    mandetoryMissingInCsvGroup:0,
    invalidGroupEmailsRejection: 0,
    duplicateGroupContactsInCsv: 0,
    createGroupSuccess:false,
  }
}

const importContactValidation = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    importContactValidation: true,
    error: false,
    message: action.message,
    duplicateContactsInCsv: action.duplicateContactsInCsv,
    invalidEmailsRejection: action.invalidEmailsRejection,
    alreadyExistInDb: action.alreadyExistInDb,
    rejectedEmails: action.rejectedEmails,
    mandetoryMissingInCsv:action.mandetoryMissingInCsv,
    createGroupSuccess:false,
  }
}

const importGroupContactValidation = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    importGroupContactValidation: true,
    error: false,
    message: action.message,
    duplicateGroupContactsInCsv: action.duplicateGroupContactsInCsv,
    invalidGroupEmailsRejection: action.invalidGroupEmailsRejection,
    rejectedGroupEmails: action.rejectedGroupEmails,
    mandetoryMissingInCsvGroup: action.mandetoryMissingInCsvGroup,
    createGroupSuccess:false,
  }
}

const updateContactSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    updateSuccess: true,
    message: action.message,
  }
}

const updateContactError = (state = INITIAL_STATE, action) => {
  return { ...state, updateError: true, message: action.error }
}

const updateContactRequest = (state = INITIAL_STATE, action) => {
  defaultStates()
  return {
    ...state,
    message: null,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    contactSuccess: false,
    contactError: false,
    groupSuccess: false,
    groupError: false,
    importSuccess: false,
    importError: false,
    updateSuccess: false,
    updateError: false,
    searchError: false,
    csvSuccess: false,
    csvError: false,
    xslSuccess: false,
    xslError: false,
    listAllSuccess: false,
    createGroupSuccess:false,
  }
}

const deleteContactSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: true,
    //contacts: action.contacts,
    //totalResults: action.totalResults,
    message: action.message,
    deleteContactSuccess: true
  }
}

const deleteContactError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error, deleteContactSuccess:false }
}

const deleteContactRequest = (state = INITIAL_STATE, action) => {
  defaultStates()
  return {
    ...state,
    //totalResults: 0,
    message: null,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    contactSuccess: false,
    contactError: false,
    groupSuccess: false,
    groupError: false,
    importSuccess: false,
    importError: false,
    updateSuccess: false,
    updateError: false,
    searchError: false,
    csvSuccess: false,
    csvError: false,
    xslSuccess: false,
    xslError: false,
    listAllSuccess: false,
    deleteContactSuccess:false
  }
}

const searchContactSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    success: true,
    contacts: action.contacts,
    totalResults: action.totalResults,
    message: action.message,
  }
}

const searchContactError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    searchError: true,
    message: action.error,
    contacts: [],
    totalResults: 0,
  }
}

const searchContactRequest = (state = INITIAL_STATE, action) => {
  defaultStates()
  return {
    ...state,
    totalResults: 0,
    message: null,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    contactSuccess: false,
    contactError: false,
    groupSuccess: false,
    groupError: false,
    importSuccess: false,
    importError: false,
    updateSuccess: false,
    updateError: false,
    searchError: false,
    csvSuccess: false,
    csvError: false,
    xslSuccess: false,
    xslError: false,
    listAllSuccess: false,
  }
}

const csvSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    csvSuccess: true,
    csvFile: action.csvFile,
    message: action.message,
  }
}

const csvError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const csvRequest = (state = INITIAL_STATE, action) => {
  defaultStates()
  return {
    ...state,
    message: null,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    contactSuccess: false,
    contactError: false,
    groupSuccess: false,
    groupError: false,
    importSuccess: false,
    importError: false,
    updateSuccess: false,
    updateError: false,
    searchError: false,
    csvSuccess: false,
    csvError: false,
    xslSuccess: false,
    xslError: false,
    listAllSuccess: false,
  }
}

const xslSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    xslSuccess: true,
    xslFile: action.xslFile,
    message: action.message,
  }
}

const xslError = (state = INITIAL_STATE, action) => {
  return { ...state, error: true, message: action.error }
}

const xslRequest = (state = INITIAL_STATE, action) => {
  defaultStates()
  return {
    ...state,
    message: null,
    success: false,
    error: false,
    listSuccess: false,
    listError: false,
    contactSuccess: false,
    contactError: false,
    groupSuccess: false,
    groupError: false,
    importSuccess: false,
    importError: false,
    updateSuccess: false,
    updateError: false,
    searchError: false,
    csvSuccess: false,
    csvError: false,
    xslSuccess: false,
    xslError: false,
    listAllSuccess: false,
  }
}

const HANDLERS = {
  [Constants.REJECT_CONTACT_LIST_REQUEST]: rejectContactListRequest,
  [Constants.REJECT_CONTACT_LIST_SUCCESS]: rejectContactListSuccess,
  [Constants.REJECT_CONTACT_LIST_ERROR]: rejectContactListError,
  [Constants.CREATE_GROUP_REQUEST]: createGroupRequest,
  [Constants.CREATE_GROUP_SUCCESS]: createGroupSuccess,
  [Constants.CREATE_GROUP_ERROR]: createGroupError,
  [Constants.IMPORT_GROUP_CONTACT_VALIDATION]: importGroupContactValidation,
  [Constants.IMPORT_CONTACT_VALIDATION]: importContactValidation,
  [Constants.CONTACT_SUCCESS]: contactSuccess,
  [Constants.CONTACT_ERROR]: contactError,
  [Constants.CONTACT_REQUEST]: contactRequest,
  [Constants.LIST_CONTACT_SUCCESS]: listContactSuccess,
  [Constants.LIST_CONTACT_ERROR]: listContactError,
  [Constants.LIST_CONTACT_REQUEST]: listContactRequest,
  [Constants.LIST_ALL_CONTACT_SUCCESS]: listAllContactSuccess,
  [Constants.LIST_ALL_CONTACT_ERROR]: listAllContactError,
  [Constants.LIST_ALL_CONTACT_REQUEST]: listAllContactRequest,
  [Constants.GROUP_CONTACT_SUCCESS]: groupContactSuccess,
  [Constants.GROUP_CONTACT_ERROR]: groupContactError,
  [Constants.GROUP_CONTACT_REQUEST]: groupContactRequest,
  [Constants.IMPORT_CONTACT_SUCCESS]: importContactSuccess,
  [Constants.IMPORT_CONTACT_ERROR]: importContactError,
  [Constants.IMPORT_CONTACT_REQUEST]: importContactRequest,
  [Constants.UPDATE_CONTACT_SUCCESS]: updateContactSuccess,
  [Constants.UPDATE_CONTACT_ERROR]: updateContactError,
  [Constants.UPDATE_CONTACT_REQUEST]: updateContactRequest,
  [Constants.DELETE_CONTACT_SUCCESS]: deleteContactSuccess,
  [Constants.DELETE_CONTACT_ERROR]: deleteContactError,
  [Constants.DELETE_CONTACT_REQUEST]: deleteContactRequest,
  [Constants.DELETE_MANY_CONTACT_SUCCESS]: deleteContactSuccess,
  [Constants.DELETE_MANY_CONTACT_ERROR]: deleteContactError,
  [Constants.DELETE_MANY_CONTACT_REQUEST]: deleteContactRequest,
  [Constants.ASSIGN_GROUP_SUCCESS]: updateContactSuccess,
  [Constants.ASSIGN_GROUP_ERROR]: updateContactError,
  [Constants.ASSIGN_GROUP_REQUEST]: updateContactRequest,
  [Constants.SEARCH_CONTACT_SUCCESS]: searchContactSuccess,
  [Constants.SEARCH_CONTACT_ERROR]: searchContactError,
  [Constants.SEARCH_CONTACT_REQUEST]: searchContactRequest,
  [Constants.CSV_SUCCESS]: csvSuccess,
  [Constants.CSV_ERROR]: csvError,
  [Constants.CSV_REQUEST]: csvRequest,
  [Constants.XSL_SUCCESS]: xslSuccess,
  [Constants.XSL_ERROR]: xslError,
  [Constants.XSL_REQUEST]: xslRequest,
}

export default createReducer(INITIAL_STATE, HANDLERS)
