import { useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';
import Button from '@material-ui/core/Button';
import Grid from '@mui/material/Grid';
import '../../sass/main.scss';
import Box from '@mui/material/Box';
import DashboardLayout from 'Layouts/DashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'Redux/Actions';
import { AIChatbotState } from 'Redux/Reducers/aiChatbot.reducer';
import Loader from 'Components/Loader/loader';
import { UserData } from 'Redux/API';
import ChatWidgetList from './ChatWidgetList';

const AiChatbot = () => {
  const { listAllChatbotsSuccess, chatbots, loading, page_no, limit } =
    useSelector((store: any) => store.AIChatbot) as AIChatbotState;

  const dispatch = useDispatch();
  const [openCreateChatbot, setOpenCreateChatbot] = useState(false);
  const [userId, setUserId] = useState<string>('');

  useEffect(() => {
    listAllChatbots();
    getUserDetails();
  }, []);

  const listAllChatbots = () => {
    const queryParams = {
      page_no,
      limit,
    };
    dispatch(Actions.listAllChatbotsRequest(queryParams));
  };

  const getUserDetails = async () => {
    const userDetails = await UserData();
    const userId = userDetails._id;
    setUserId(userId);
  };

  useEffect(() => {
    if (listAllChatbotsSuccess) {
      if (chatbots?.length) {
        setOpenCreateChatbot(true);
      }
    }
  }, [listAllChatbotsSuccess]);

  const openGeneralTab = () => {
    setOpenCreateChatbot(true);
  };

  return (
    <>
      <DashboardLayout title="" pageTitle="">
        <ThemeProvider theme={theme}>
          <div className="dashboardMiddleArea">
            {!openCreateChatbot ? (
              <>
                <Box
                  component={'div'}
                  p={0}
                  pt={1.3}
                  mb={1.2}
                  className="header-common"
                >
                  <Grid item>
                    <h1 className="heading"> AI Agent </h1>
                  </Grid>
                </Box>
                <Box className="subsecondary">Chat Widget</Box>

                <Grid
                  container
                  height={'calc(100vh - 300px)'}
                  spacing={3}
                  direction={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Grid item>
                    <Box className="subsecondary bold">
                      Looking for Chat Widget
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="small-subtitle" textAlign={'center'}>
                      Seems like you haven't added any Chat Widget. Start adding
                      <br />
                      Chat Widget by click on “Create Chat Widget” button
                    </Box>
                  </Grid>
                  <Grid item mt={2.5}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => openGeneralTab()}
                    >
                      Create chat widget
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <ChatWidgetList userId={userId} chatbotsList={chatbots} />
            )}
          </div>
        </ThemeProvider>
      </DashboardLayout>
      {loading ? <Loader /> : null}
    </>
  );
};

export default AiChatbot;
