import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
    listWebhooksRequest: ['data'],
    listWebhooksSuccess: [ 'list', 'message' ,'totalResults'],
    listWebhooksError: ['message'],
    toggleWebhookRequest: ['data'],
    toggleWebhookSuccess: ['message', 'webhookData', 'activeStatus'],
    toggleWebhookError: ['message'],
    generateSecretKeyRequest: ['data'],
    generateSecretKeySuccess: ['message', 'secretKey'],
    generateSecretKeyError: ['message'],
    checkWebhookEventIdRequest: ['data'],
    checkWebhookEventIdSuccess: ['message', 'secretKey'],
    checkWebhookEventIdError: ['message'],
})

export const Constants = Types
export default Creators
