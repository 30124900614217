/**
 * @function getUnLayerMeetingsConfig
 * @description Generates configuration for the UnLayer Meetings component based on custom fields.
 * @param {Array} customToolFileds - Custom tool fields for meetings.
 * @param {Array} customFiledsForAudience - Custom fields for audience contacts.
 * @param {Object} audienceDynamicCustomField - Dynamic custom fields for audience contacts.
 * @param {Object} customFieldDynamic - Dynamic custom fields for attendees.
 * @returns {Object} Configuration object for UnLayer Meetings.
 */
const getUnLayerMeetingsConfig = (
  customToolFileds = [],
  customFiledsForAudience =[],
  audienceDynamicCustomField= {},
  customFieldDynamic = {},
) => {
  // Predefined dynamic custom fields for attendees
  customFieldDynamic['email'] = {
      name: "Email",
      value: "{{{attendeeEmail}}}"
    } 
    customFieldDynamic['group'] = {
      name: "Group",
      value: "{{{attendeeGroup}}}"
    } 
    customFieldDynamic['image'] = {
      name: "Image",
      value: "{{{attendeeImage}}}"    
    }

    // Predefined dynamic custom fields for audience contacts
    audienceDynamicCustomField['email'] = {
      name: "Email",
      value: "{{{contactEmail}}}"
    }
    audienceDynamicCustomField['image'] = {
      name: "Image",
      value: "{{{audienceImage}}}"    
    }

    // Add custom tool fields for attendees
    if (customToolFileds && customToolFileds.length > 0) {
      for (var x = 0; x < customToolFileds.length; x++) {
        customFieldDynamic[customToolFileds[x]?.fieldData?.fieldName] = {
          name: customToolFileds[x].fieldData?.fieldLabel,
          value: "{{{attendee_" + customToolFileds[x]?.fieldData?.fieldName + "}}}"
        }
      }
    }
  
    // custom fields for audience contact
    if (customFiledsForAudience && customFiledsForAudience.length > 0) {
      for (var x = 0; x < customFiledsForAudience.length; x++) {
        audienceDynamicCustomField[customFiledsForAudience[x]?.fieldData?.fieldName] = {
          name: customFiledsForAudience[x].fieldData?.fieldLabel,
          value: "{{{audience_" + customFiledsForAudience[x]?.fieldData?.fieldName + "}}}"
        }
      }
    }
    
  // Configuration object for UnLayer Meetings
  return {
    id: 'meetingsEditor',
    projectId: 21377,
    displayMode:'email',
    
    mergeTags: {           // Merge tags for different sections of the meeting
      session: {
        name: "Session",
        mergeTags: {
          title: {
            name: "Title",
            value: "{{{sessionTitle}}}"
          },
          date: {
            name: "Date",
            value: "{{{sessionDate}}}"
          },
          duration: {
            name: "Duration",
            value: "{{{sessionDuration}}}"
          },
          location: {
            name: "Location",
            value: "{{{sessionLocation}}}"
          },
          navigateTo: {
            name: "Navigate to",
            value: "{{{sessionNavigateTo}}}"
          },
          description: {
            name: "Description",
            value: "{{{sessionDescription}}}"
          },
          tracks: {
            name: "Tracks",
            value: "{{{sessionTracks}}}"
          },
          image: {
            name: "Image",
            value: "{{{sessionImage}}}"
          },
          speakerName: {
            name: "Speakers Block",
            value: "{{{speakersBlock}}}"
          },
          sessionStartTime: {
            name: "Session Start Time",
            value: "{{{sessionStartTime}}}"
          },
          sessionEndTime: {
            name: "Session End Time",
            value: "{{{sessionEndTime}}}"
          },
        }
      },
      audienceName: {
        name: "Audience Contact",
        mergeTags: audienceDynamicCustomField,
      },
      customeField: {
        name: "Attendee",
        mergeTags: customFieldDynamic,
      },
      meeting: {
        name: "Meeting",
        mergeTags: {
          emailSubject: {
            name: "Email Subject",
            value: "{{{meetingEmailSubject}}}"
          },
          meetingTitle: {
            name: "Meeting Title",
            value: "{{{meetingTitle}}}"
          },
          meetingOrganizer: {
            name: "Meeting Organizer",
            value: "{{{meetingOrganizer}}}"
          },
          meetingStartTime: {
            name: "Meeting Start Time",
            value: "{{{meetingStartTime}}}"
          },
          meetingEndTime: {
            name: "Meeting End Time",
            value: "{{{meetingEndTime}}}"
          },
          description: {
            name: "Meeting Description",
            value: "{{{meetingDescription}}}"
          },
          meetingDuration: {
            name: "Meeting Duration",
            value: "{{{meetingDuration}}}"
          },
          meetingLocation: {
            name: "Meeting Location",
            value: "{{{meetingLocation}}}"
          },
          timezone: {
            name: "Timezone",
            value: "{{{timezone}}}"
          },

        }
      },
      sessions: {
        name: 'Sessions',
        rules: {
          repeat: {
            name: 'Repeat for Each Sessions',
            before: '{{#sessions}}',
            after: '{{/sessions}}',
          },
        },
      },
      sessionsGroupByDate: {
        name: 'Session Group By Date',
        rules: {
          repeat: {
            name: 'Repeat for Each Session Group By Date',
            before: '{{#sessionsGroupByDate}}',
            after: '{{/sessionsGroupByDate}}',
          },
        },
      },
    },
    customCSS: [
     // window.location.protocol + '//' + window.location.host + '/custom.css',
    ],
    customJS: [
     // window.location.protocol + '//' + window.location.host + '/custom.js',
    ],
  }
}
export default getUnLayerMeetingsConfig

