import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../../../Redux/Actions'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@material-ui/core/Button'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import MenuList from '@material-ui/core/MenuList'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import CustomSwitch from '../../../../Components/customSwitch'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../../../../theme/theme'
import removeIcon from '../../../../assets/images/icons/close.svg'
import * as qs from 'qs'
import { get } from 'lodash'
import { createNotification } from '../../../../helpers'
import { Modal } from 'react-responsive-modal'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import informationIcon from '../../../../assets/images/icons/informationIcon.svg';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import TextField from '@mui/material/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '../../../../assets/images/icons/search.svg'
import SquareBadge from '../../../../Components/CustomBadges/SquareBadge'
import { getProjectIdFromUrl } from '../../../../helpers/common'
import { Stack } from '@mui/material'
import GoogleWorkspace from '../../../../assets/images/icons/Google_G_Logo.svg';
export class EditCampaignRecipients extends Component {
  constructor(props) {
    super(props)
    this.anchorRef = React.createRef()
    this.state = {
      openAttendees: false,
      openAudience: true,
      openGroups: false,
      ChooseAudience: false,
      SelectGroups: false,
      openSearchAdd: false,
      campaignDetails: {},
      listOfGroups: [],
      listOfAudienceGroups: [],
      recipientsDetails: {},
      openSearchAndAddModal: false,
      openAddedPeople: false,
      listOfAttendeesOfCampaign: [],
      scrollNoForAttendeesOfCampaign: 1,
      searchTextForAttendeesOfCampaign: '',
      scrollNoForAttendeesNotAddedToCampaign: 1,
      searchTextForAttendeesNotAddedToCampaign: '',
      listOfAttendeesNotAddedToCampaign: [],
      peopleSelectedToAddToInvite: [],
      peopleSelectedToRemoveFromInvite: [],
      searchTextForAddedPeopleInInviteList: '',
      listOfAttendeesOfCampaignBackup: [],
      listOfAttendeesNotAddedToCampaignBackup: [],
      scroll_no_forAdded: 1,
      numOfAttendeesToShow: 12,
      searchTextForNotAddedPeopleInInviteList: '',
      scroll_no_forNotAdded: 1,
      openRecipientTypeModal: false,
      continueToOtherRecipientType: false,
    }
  }

  componentDidMount() {
    const pathName = window.location.pathname.split('/')
    const length = window.location.pathname.split('/').length
    const projectId = pathName[length - 1]
    this.setState(
      {
        projectId,
      },
      () => {
        this.listgroups()
        this.listAttendees()
      },
    )

    const cid = qs.parse(get(this.props, 'location.search'), {
      ignoreQueryPrefix: true,
    })

    this.setState(
      {
        campaignId: cid.cid,
      },
      () => {
        this.listAttendeesOfCampaigns()
        this.listAttendeesNotAddedToCampaign()
      },
    )

    this.listAudienceGroups()
    this.getCampaign()
  }

  listAttendees = () => {
    try {
      this.props.listAttendees({
        projectId: this.state.projectId,
        page_no: 1,
        limit: 10,
      })
      const totalResults = this.props.attendees.totalAttendeesRecords
      this.setState({
        totalResults,
      })
    } catch (err) {
      createNotification('error', 'Something went wrong')
    }
  }

  listgroups = () => {
    try {
      const { projectId } = this.state
      const body = {
        projectId,
        page_no2: 1,
        searchText: '',
      }
      if (projectId) {
        this.props.listAttendeesGroups(body)
      } else {
        createNotification('error', 'project Id is missing')
      }
    } catch (error) {
      createNotification('error', 'Something went wrong')
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { recipientsDetails } = this.state
    if (
      recipientsDetails !== prevProps.recipientsDetails ||
      recipientsDetails.recipientsType !==
        prevProps.recipientsDetails.recipientsType
    ) {
      this.props.getDataFromEditRecipients(this.state.recipientsDetails)
    }
    if (
      this.props.campaigns?.getCampaignSuccess &&
      !prevProps.campaigns.getCampaignSuccess &&
      this.props.campaigns.message !== prevProps.campaigns.message
    ) {
      this.setState(
        {
          campaignDetails: this.props.campaigns?.campaignDetails[0],
          recipientsDetails: this.props.campaigns?.campaignDetails[0]
            ?.recipients || {
            recipientsType: 'audience',
            audienceGroupsList: [],
            excludeAttendees: false,
          },
        },
        () => {
          let recipientsDetails = this.state.recipientsDetails
          this.setState({
            recipientsDetails,
          })
        },
      )

      if (
        this.props.campaigns?.campaignDetails[0].recipients?.recipientsType ===
        'audience'
      ) {
        this.setState({
          openAudience: true,
          openAttendees: false,
          openGroups: false,
          openSearchAdd: false,
        })
      }
      if (
        this.props.campaigns?.campaignDetails[0].recipients?.recipientsType ===
        'attendeesGroups'
      ) {
        this.setState({
          openAudience: false,
          openAttendees: false,
          openGroups: true,
          openSearchAdd: false,
        })
      }
      if (
        this.props.campaigns?.campaignDetails[0].recipients?.recipientsType ===
        'searchAdd'
      ) {
        this.setState({
          openAudience: false,
          openAttendees: false,
          openGroups: false,
          openSearchAdd: true,
        })
      }
      if (
        this.props.campaigns?.campaignDetails[0].recipients?.recipientsType ===
        'attendees'
      ) {
        this.setState({
          openAudience: false,
          openAttendees: true,
          openGroups: false,
          openSearchAdd: false,
        })
      }
    }

    if (
      this.props.attendees?.getAttendeesGroupsSuccess &&
      !prevProps.attendees?.getAttendeesGroupsSuccess &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        listOfGroups: this.props.attendees?.attendeesGroups,
      })
    }

    if (
      this.props.attendees?.getAttendeesSuccess &&
      !prevProps.attendees?.getAttendeesSuccess &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        listOfAttendees: this.props.attendees?.attendees,
      })
    }

    if (
      this.props.campaigns?.listAudienceGroupsSuccess &&
      !prevProps.campaigns.listAudienceGroupsSuccess &&
      this.props.campaigns.message !== prevProps.campaigns.message
    ) {
      this.setState({
        listOfAudienceGroups: this.props.campaigns?.audienceGroups,
      })
    }

    if (
      this.props.campaigns?.listAttendeesOfCampaignsSuccess &&
      !prevProps.campaigns.listAttendeesOfCampaignsSuccess &&
      this.props.campaigns.message !== prevProps.campaigns.message
    ) {
      this.setState({
        listOfAttendeesOfCampaign: this.props.campaigns?.attendeesOfCampaigns,
        listOfAttendeesOfCampaignBackup:
          this.props.campaigns?.attendeesOfCampaigns,
      })
    }

    if (
      this.props.campaigns?.listAttendeesNotAddedToCampaignsSuccess &&
      !prevProps.campaigns.listAttendeesNotAddedToCampaignsSuccess &&
      this.props.campaigns.message !== prevProps.campaigns.message
    ) {
      this.setState({
        listOfAttendeesNotAddedToCampaign:
          this.props.campaigns?.attendeesNotAddedToCampaign,
        listOfAttendeesNotAddedToCampaignBackup:
          this.props.campaigns?.attendeesNotAddedToCampaign,
      })
    }

    if (this.props.campaigns?.error) {
      if (this.state.loading) {
        this.setState({
          loading: false,
        })
      }
    }
  }

  getCampaign = () => {
    if (this.state.campaignId) {
      const eventId = getProjectIdFromUrl()
      this.props.getCampaign({
        campaignId: this.state.campaignId,
        eventId,
      })
    }
  }

  listAudienceGroups = () => {
    const projectId = getProjectIdFromUrl()
    this.props.listAudienceGroups({ projectId })
  }

  listAttendeesOfCampaigns = () => {
    const {
      searchTextForAttendeesOfCampaign,
      scrollNoForAttendeesOfCampaign,
      campaignId,
    } = this.state
    const body = {
      campaignId,
      searchText: searchTextForAttendeesOfCampaign,
      scroll_no: scrollNoForAttendeesOfCampaign,
      eventId: this.state.projectId,
    }
    this.props.listAttendeesOfCampaigns(body)
  }

  listAttendeesNotAddedToCampaign = () => {
    const {
      campaignId,
      projectId,
      scrollNoForAttendeesNotAddedToCampaign,
      searchTextForAttendeesNotAddedToCampaign,
    } = this.state
    const body = {
      campaignId,
      projectId,
      scroll_no: scrollNoForAttendeesNotAddedToCampaign,
      searchText: searchTextForAttendeesNotAddedToCampaign,
    }
    this.props.listAttendeesNotAddedToCampaign(body)
  }

  handleToggleChooseAudience = () => {
    this.setState({
      ChooseAudience: !this.state.ChooseAudience,
    })
  }

  handleCloseChooseAudience = (event) => {
    this.setState({
      ChooseAudience: false,
    })
  }

  handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      this.setState({
        ChooseAudience: false,
        SelectGroups: false,
      })
    }
  }

  handleOpenAudience = () => {
    let recipientsDetails = this.state.recipientsDetails
    delete recipientsDetails.excludeCheckedInAttendees
    recipientsDetails.attendeesGroupsList = []
    recipientsDetails.excludeAttendees = false
    recipientsDetails.recipientsType = 'audience'
    recipientsDetails.audienceGroupsList = []
    recipientsDetails.searchAddedAttendees = []
    this.setState({
      openAudience: true,
      openAttendees: false,
      openGroups: false,
      openSearchAdd: false,
      ChooseAudience: false,
      SelectGroups: false,
      recipientsDetails,
      listOfAttendeesOfCampaign: [],
      peopleSelectedToAddToInvite: [],
      listOfAttendeesNotAddedToCampaign:
        this.state.listOfAttendeesNotAddedToCampaignBackup,
      listOfAttendeesOfCampaignBackup: [],
    })
  }

  handleOpenAttendees = () => {
    let recipientsDetails = this.state.recipientsDetails
    delete recipientsDetails.excludeAttendees
    recipientsDetails.excludeCheckedInAttendees = false
    recipientsDetails.attendeesGroupsList = []
    recipientsDetails.audienceGroupsList = []
    recipientsDetails.recipientsType = 'attendees'
    recipientsDetails.searchAddedAttendees = []
    recipientsDetails = {
      ...recipientsDetails,
    }

    this.setState({
      openAudience: false,
      openAttendees: true,
      openGroups: false,
      openSearchAdd: false,
      ChooseAudience: false,
      SelectGroups: false,
      recipientsDetails,
      listOfAttendeesOfCampaign: [],
      peopleSelectedToAddToInvite: [],
      listOfAttendeesOfCampaignBackup: [],
    })
  }

  handleOpenGroups = () => {
    let recipientsDetails = this.state.recipientsDetails
    delete recipientsDetails.excludeAttendees
    recipientsDetails.audienceGroupsList = []
    recipientsDetails.attendeesGroupsList = []
    recipientsDetails.searchAddedAttendees = []
    recipientsDetails.excludeCheckedInAttendees = false
    recipientsDetails.recipientsType = 'attendeesGroups'
    this.setState({
      openAudience: false,
      openAttendees: false,
      openGroups: true,
      openSearchAdd: false,
      ChooseAudience: false,
      SelectGroups: false,
      recipientsDetails,
      listOfAttendeesOfCampaign: [],
      peopleSelectedToAddToInvite: [],
      listOfAttendeesOfCampaignBackup: [],
    })
  }

  handleOpenSearchAdd = () => {
    let recipientsDetails = this.state.recipientsDetails
    delete recipientsDetails.excludeAttendees
    delete recipientsDetails.excludeCheckedInAttendees
    recipientsDetails.audienceGroupsList = []
    recipientsDetails.attendeesGroupsList = []
    recipientsDetails.recipientsType = 'searchAdd'
    recipientsDetails.searchAddedAttendees = []
    this.setState({
      openAudience: false,
      openAttendees: false,
      openGroups: false,
      openSearchAdd: true,
      ChooseAudience: false,
      SelectGroups: false,
    })
  }

  handleToggleSelectGroups = () => {
    this.setState({
      SelectGroups: !this.state.SelectGroups,
    })
  }

  handleCloseSelectGroups = (event) => {
    this.setState({
      SelectGroups: false,
    })
  }

  handleAudienceCheckboxes = (e, audience) => {
    let recipientsDetails = this.state.recipientsDetails
    if (recipientsDetails.audienceGroupsList?.indexOf(audience._id) === -1) {
      recipientsDetails = {
        ...recipientsDetails,
        audienceGroupsList: [
          ...recipientsDetails?.audienceGroupsList,
          audience?._id,
        ],
      }
    } else {
      const filteredArray = recipientsDetails?.audienceGroupsList?.filter(
        (audienceId) => audienceId !== audience?._id,
      )
      recipientsDetails.audienceGroupsList = filteredArray
    }
    this.setState({
      recipientsDetails,
    })
  }

  handleAllAudienceCheckbox = (e) => {
    let recipientsDetails = this.state.recipientsDetails
    if (e.target.checked) {
      recipientsDetails.audienceGroupsList =
        this.state.listOfAudienceGroups.map((audience) =>
          audience.audienceCount ? audience._id : null,
        )
      recipientsDetails.audienceGroupsList =
        recipientsDetails.audienceGroupsList?.filter((group) => group !== null)
    } else {
      recipientsDetails.audienceGroupsList = []
    }

    this.setState({
      recipientsDetails,
    })
  }

  handleExcludeAttendeesForAudience = (e) => {
    let recipientsDetails = this.state.recipientsDetails
    recipientsDetails.excludeAttendees = e.target.checked

    this.setState({
      recipientsDetails,
    })
  }

  removeAudienceFromList = (e, audiencee) => {
    let recipientsDetails = this.state.recipientsDetails
    let filteredList = recipientsDetails.audienceGroupsList.filter(
      (audience) => audience !== audiencee._id,
    )
    recipientsDetails = {
      ...recipientsDetails,
      audienceGroupsList: filteredList,
    }
    this.setState({
      recipientsDetails,
    })
  }

  handleGroupsCheckboxes = (e, group) => {
    let recipientsDetails = this.state.recipientsDetails
    if (recipientsDetails.attendeesGroupsList.indexOf(group._id) === -1) {
      if (!recipientsDetails.excludeCheckedInAttendees) {
        recipientsDetails = {
          ...recipientsDetails,
          attendeesGroupsList: [
            ...recipientsDetails?.attendeesGroupsList,
            group?._id,
          ],
        }
        this.setState({
          recipientsDetails,
        })
      } else {
        recipientsDetails = {
          ...recipientsDetails,
          attendeesGroupsList: [
            ...recipientsDetails?.attendeesGroupsList,
            group?._id,
          ],
        }
        this.setState({
          recipientsDetails,
        })
      }
    } else {
      if (!recipientsDetails.excludeCheckedInAttendees) {
        const filteredArray = recipientsDetails.attendeesGroupsList.filter(
          (groupId) => groupId !== group._id,
        )
        recipientsDetails.attendeesGroupsList = filteredArray
        this.setState({
          recipientsDetails,
        })
      } else {
        const filteredArray = recipientsDetails.attendeesGroupsList.filter(
          (groupId) => groupId !== group._id,
        )
        recipientsDetails = {
          ...recipientsDetails,
          attendeesGroupsList: filteredArray,
        }
        this.setState({
          recipientsDetails,
        })
      }
    }
  }

  handleAllGroupsCheckbox = (e) => {
    let recipientsDetails = this.state.recipientsDetails
    if (e.target.checked) {
      recipientsDetails.attendeesGroupsList = this.state.listOfGroups.map(
        (group) => (group.totalAttendeesCount ? group._id : null),
      )
      recipientsDetails.attendeesGroupsList =
        recipientsDetails.attendeesGroupsList?.filter((group) => group !== null)
    } else {
      recipientsDetails.attendeesGroupsList = []
    }
    this.setState({
      recipientsDetails,
    })
  }

  removeGroupsFromList = (e, groupp) => {
    let recipientsDetails = this.state.recipientsDetails
    let filteredList = recipientsDetails.attendeesGroupsList.filter(
      (group) => group !== groupp._id,
    )
    recipientsDetails = {
      ...recipientsDetails,
      attendeesGroupsList: filteredList,
    }
    this.setState({
      recipientsDetails
    })
  }

  handleExcludeCheckedInAttendeesForGroups = (e) => {
    let recipientsDetails = this.state.recipientsDetails
    recipientsDetails.excludeCheckedInAttendees = e.target.checked
    this.setState({
      recipientsDetails,
    })
  }

  handleCloseSearchAndAddModal = () => {
    this.setState({
      openSearchAndAddModal: false,
      scroll_no_forAdded: 1,
      scroll_no_forNotAdded: 1,
    })
  }

  handleOpenSearchAndAddModal = () => {
    this.setState({
      openSearchAndAddModal: true,
    })
  }

  handleOpenAddedPeopleModal = () => {
    this.setState({
      openAddedPeople: true,
    })
  }

  handleCloseAddedPeopleModal = () => {
    this.setState({
      openAddedPeople: false,
      scroll_no_forAdded: 1,
      scroll_no_forNotAdded: 1,
    })
  }
  addPeopleToInviteListCheckbox = (e, attendee) => {
    let peopleSelectedToAddToInvite
    if (this.state.peopleSelectedToAddToInvite.includes(attendee._id)) {
      peopleSelectedToAddToInvite =
        this.state.peopleSelectedToAddToInvite.filter(
          (attendeeId) => attendeeId !== attendee._id,
        )
      this.setState({
        peopleSelectedToAddToInvite,
      })
    }
    if (this.state.peopleSelectedToAddToInvite.indexOf(attendee._id) === -1) {
      this.setState({
        peopleSelectedToAddToInvite: [
          ...this.state.peopleSelectedToAddToInvite,
          attendee._id,
        ],
      })
    }
  }

  addPeopleToInviteListButton = () => {
    const { listOfAttendeesNotAddedToCampaign, peopleSelectedToAddToInvite } =
      this.state

    let filteredArr = listOfAttendeesNotAddedToCampaign.filter(
      (attendee) => peopleSelectedToAddToInvite.indexOf(attendee._id) === -1,
    )
    let removedFromList = listOfAttendeesNotAddedToCampaign.filter(
      (attendee) => peopleSelectedToAddToInvite.indexOf(attendee._id) > -1,
    )

    this.setState(
      {
        listOfAttendeesNotAddedToCampaign: filteredArr,
        listOfAttendeesOfCampaign: [
          ...this.state.listOfAttendeesOfCampaign,
          ...removedFromList,
        ],
      },
      () => {
        let recipientsDetails = this.state.recipientsDetails
        recipientsDetails.searchAddedAttendees =
          this.state.listOfAttendeesOfCampaign
        this.setState(
          {
            recipientsDetails,
          },
          () => {
            this.setState(
              {
                peopleSelectedToAddToInvite: [],
              },
              () => {
                this.setState(
                  {
                    listOfAttendeesOfCampaignBackup:
                      this.state.listOfAttendeesOfCampaign,
                  },
                  () => {
                    this.handleCloseAddedPeopleModal()
                    this.props.isRecipienstsValid()
                    this.props.handleListOfAttendeesOfCampaignBackup(
                      this.state.listOfAttendeesOfCampaign.length,
                    )
                  },
                )
              },
            )
          },
        )
      },
    )
  }

  removePeopleFromInviteList = (e, attendee) => {
    let peopleSelectedToRemoveFromInvite
    if (this.state.peopleSelectedToRemoveFromInvite.includes(attendee._id)) {
      peopleSelectedToRemoveFromInvite =
        this.state.peopleSelectedToRemoveFromInvite.filter(
          (attendeeId) => attendeeId !== attendee._id,
        )
      this.setState({
        peopleSelectedToRemoveFromInvite,
      })
    }

    if (
      this.state.peopleSelectedToRemoveFromInvite.indexOf(attendee._id) === -1
    ) {
      this.setState({
        peopleSelectedToRemoveFromInvite: [
          ...this.state.peopleSelectedToRemoveFromInvite,
          attendee._id,
        ],
      })
    }
  }

  handleSelectAllPeopleInToInviteList = (e) => {
    let listOfAttendeesOfCampaign = this.state.listOfAttendeesOfCampaign
    listOfAttendeesOfCampaign = listOfAttendeesOfCampaign.map(
      (attendee) => attendee._id,
    )
    if (e.target.checked) {
      this.setState({
        peopleSelectedToRemoveFromInvite: this.state.listOfAttendeesOfCampaign
          .slice(
            0,
            this.state.numOfAttendeesToShow * this.state.scroll_no_forAdded,
          )
          .map((attendee) => attendee._id),
      })
    } else {
      this.setState({
        peopleSelectedToRemoveFromInvite: [],
      })
    }
  }

  handleSelectAllPeopleNotInInviteList = (e) => {
    if (e.target.checked) {
      this.setState({
        peopleSelectedToAddToInvite:
          this.state.listOfAttendeesNotAddedToCampaign
            .slice(
              0,
              this.state.numOfAttendeesToShow *
                this.state.scroll_no_forNotAdded,
            )
            .map((attendee) => attendee._id),
      })
    } else {
      this.setState({
        peopleSelectedToAddToInvite: [],
      })
    }
  }

  removePeopleFromInviteListButton = () => {
    const { listOfAttendeesOfCampaign, peopleSelectedToRemoveFromInvite } =
      this.state

    let filteredArr = listOfAttendeesOfCampaign.filter(
      (attendee) =>
        peopleSelectedToRemoveFromInvite.indexOf(attendee._id) === -1,
    )
    let removedFromList = listOfAttendeesOfCampaign.filter(
      (attendee) => peopleSelectedToRemoveFromInvite.indexOf(attendee._id) > -1,
    )

    this.setState(
      {
        listOfAttendeesOfCampaign: filteredArr,
        listOfAttendeesNotAddedToCampaign: [
          ...this.state.listOfAttendeesNotAddedToCampaign,
          ...removedFromList,
        ],
      },
      () => {
        let recipientsDetails = this.state.recipientsDetails
        recipientsDetails.searchAddedAttendees =
          this.state.listOfAttendeesOfCampaign
        this.setState(
          {
            recipientsDetails,
          },
          () => {
            this.setState(
              {
                peopleSelectedToRemoveFromInvite: [],
              },
              () => {
                this.setState({
                  listOfAttendeesNotAddedToCampaignBackup:
                    this.state.listOfAttendeesNotAddedToCampaign,
                  listOfAttendeesOfCampaignBackup:
                    this.state.listOfAttendeesOfCampaign,
                })
                this.props.handleListOfAttendeesOfCampaignBackup(
                  this.state.listOfAttendeesOfCampaign.length,
                )
              },
            )
          },
        )
      },
    )
  }

  searchAddedPeopleInInviteList = (e) => {
    e.preventDefault()
    const {
      listOfAttendeesOfCampaign,
      searchTextForAddedPeopleInInviteList,
      listOfAttendeesOfCampaignBackup,
    } = this.state

    if (searchTextForAddedPeopleInInviteList !== '') {
      let filtered = listOfAttendeesOfCampaign.filter((attendee) =>
        attendee.email.includes(
          searchTextForAddedPeopleInInviteList.toLowerCase(),
        ),
      )
      this.setState({
        listOfAttendeesOfCampaign: filtered,
      })
    } else {
      this.setState({
        listOfAttendeesOfCampaign: listOfAttendeesOfCampaignBackup,
      })
    }
  }

  searchNotAddedPeopleInInviteList = (e) => {
    e.preventDefault()
    const {
      listOfAttendeesNotAddedToCampaign,
      searchTextForNotAddedPeopleInInviteList,
      listOfAttendeesNotAddedToCampaignBackup,
    } = this.state

    if (searchTextForNotAddedPeopleInInviteList !== '') {
      let filtered = listOfAttendeesNotAddedToCampaign.filter((attendee) =>
        attendee.email.includes(
          searchTextForNotAddedPeopleInInviteList.toLowerCase(),
        ),
      )
      this.setState({
        listOfAttendeesNotAddedToCampaign: filtered,
      })
    } else {
      this.setState({
        listOfAttendeesNotAddedToCampaign:
          listOfAttendeesNotAddedToCampaignBackup,
      })
    }
  }

  handleSearchChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })

    if (e.target.value === '') {
      this.setState({
        listOfAttendeesOfCampaign: this.state.listOfAttendeesOfCampaignBackup,
        listOfAttendeesNotAddedToCampaign:
          this.state.listOfAttendeesNotAddedToCampaignBackup,
      })
    }
  }

  showAddedAttendees = () => {
    return this.state.listOfAttendeesOfCampaign.slice(
      0,
      this.state.numOfAttendeesToShow * this.state.scroll_no_forAdded,
    )
  }
  showNotAddedAttendees = () => {
    return this.state.listOfAttendeesNotAddedToCampaign.slice(
      0,
      this.state.numOfAttendeesToShow * this.state.scroll_no_forNotAdded,
    )
  }

  loadMoreForAdded = () => {
    const {
      numOfAttendeesToShow,
      scroll_no_forAdded,
      listOfAttendeesOfCampaign,
    } = this.state
    const totalResults = listOfAttendeesOfCampaign.length
    const maxTime = totalResults / numOfAttendeesToShow

    if (scroll_no_forAdded < maxTime) {
      this.setState({
        scroll_no_forAdded: scroll_no_forAdded + 1,
      })
    }
  }

  loadMoreForNotAdded = () => {
    const {
      numOfAttendeesToShow,
      scroll_no_forNotAdded,
      listOfAttendeesNotAddedToCampaign,
    } = this.state
    const totalResults = listOfAttendeesNotAddedToCampaign.length
    const maxTime = totalResults / numOfAttendeesToShow

    if (scroll_no_forNotAdded < maxTime) {
      this.setState({
        scroll_no_forNotAdded: scroll_no_forNotAdded + 1,
      })
    }
  }

  handleCloseRecipientTypeModal = () => {
    this.setState({
      openRecipientTypeModal: false,
    })
  }

  handleOpenRecipientTypeModal = (recipientType) => {
    let recipientsDetails = this.state.recipientsDetails

    if (recipientsDetails.recipientsType !== recipientType) {
      if (recipientsDetails.recipientsType === 'audience') {
        if (recipientsDetails.audienceGroupsList.length > 0) {
          this.setState({
            openRecipientTypeModal: true,
            newRecipientType: recipientType,
          })
        } else {
          this.handleContinueToOtherRecipientType(recipientType)
        }
        return
      }

      if (recipientsDetails.recipientsType === 'attendees') {
        this.setState({
          openRecipientTypeModal: true,
          newRecipientType: recipientType,
        })
      }

      if (recipientsDetails.recipientsType === 'attendeesGroups') {
        if (recipientsDetails.attendeesGroupsList.length > 0) {
          this.setState({
            openRecipientTypeModal: true,
            newRecipientType: recipientType,
          })
        } else {
          this.handleContinueToOtherRecipientType(recipientType)
        }
        return
      }

      if (recipientsDetails.recipientsType === 'searchAdd') {
        if (recipientsDetails.searchAddedAttendees.length > 0) {
          this.setState({
            openRecipientTypeModal: true,
            newRecipientType: recipientType,
          })
        } else {
          this.handleContinueToOtherRecipientType(recipientType)
        }
        return
      }
    }
  }

  handleCancelChangingRecipientTypeModal = () => {
    this.handleCloseRecipientTypeModal()
  }

  handleContinueToOtherRecipientType = (recipientType) => {
    let switchParams = ''
    if (
      recipientType !== false &&
      recipientType !== null &&
      recipientType !== undefined &&
      recipientType !== '' &&
      recipientType.length > 0
    ) {
      switchParams = recipientType
    } else if (this.state.newRecipientType) {
      switchParams = this.state.newRecipientType
    }

    switch (switchParams) {
      case 'attendees':
        this.handleOpenAttendees()
        break

      case 'audience':
        this.handleOpenAudience()
        break

      case 'attendeesGroups':
        this.handleOpenGroups()
        break
      case 'searchAdd':
        this.handleOpenSearchAdd()
        break

      default:
        break
    }
    this.handleCloseRecipientTypeModal()
  }

  filteredListOfGroups = () => {
    const { listOfGroups } = this.state
    let arr = [];
    Array.isArray(listOfGroups) && listOfGroups.length && listOfGroups?.forEach(g => {
     if(g.source !== "GoogleWorkspace"){
       arr.push(g)
     } else {
       if(g.showToGroup){
         arr.push(g)
       }
     }
    })
    return arr
  }

  render() {
    const {
      recipientsDetails,
      listOfAudienceGroups,
      listOfGroups,
      openAudience,
      ChooseAudience,
      SelectGroups,
      openSearchAdd,
      openAttendees,
      openGroups,
      listOfAttendeesNotAddedToCampaign,
      peopleSelectedToAddToInvite,
      listOfAttendeesOfCampaign,
      peopleSelectedToRemoveFromInvite,
      searchTextForAddedPeopleInInviteList,
      scroll_no_forAdded,
      numOfAttendeesToShow,
      searchTextForNotAddedPeopleInInviteList,
      scroll_no_forNotAdded,
      listOfAttendeesOfCampaignBackup,
    } = this.state

    let checkAllAudience = listOfAudienceGroups.map((audience) =>
      audience.audienceCount ? audience._id : null,
    )
    checkAllAudience = checkAllAudience?.filter((group) => group !== null)
    let checkAllGroup = listOfGroups.map((group) =>
      group.totalAttendeesCount ? group._id : null,
    )
    checkAllGroup = checkAllGroup?.filter((group) => group !== null)

    const isAllAudiencesChecked =
      recipientsDetails?.audienceGroupsList?.length === checkAllAudience?.length
        ? true
        : false
    recipientsDetails.isAllAudiencesChecked =
      recipientsDetails?.audienceGroupsList?.length === checkAllAudience?.length
        ? true
        : false
    const isAllGroupsChecked =
      recipientsDetails?.attendeesGroupsList?.length === checkAllGroup?.length
        ? true
        : false
    const isSelectAllPeopleToRemoveChecked =
      this.state.listOfAttendeesOfCampaign.slice(
        0,
        this.state.numOfAttendeesToShow * this.state.scroll_no_forAdded,
      )?.length === peopleSelectedToRemoveFromInvite?.length
        ? true
        : false
    const isSelectAllPeopleToAddChecked =
      this.state.listOfAttendeesNotAddedToCampaign.slice(
        0,
        this.state.numOfAttendeesToShow * this.state.scroll_no_forNotAdded,
      )?.length === peopleSelectedToAddToInvite?.length
        ? true
        : false

    const totalResults = listOfAttendeesOfCampaign.length
    const maxTime = totalResults / numOfAttendeesToShow
    const loadMoreButtonForToInvite =
      scroll_no_forAdded < maxTime ? false : true

    const totalResults1 = listOfAttendeesNotAddedToCampaign.length
    const maxTime2 = totalResults1 / numOfAttendeesToShow
    const loadMoreButtonForNotAdded =
      scroll_no_forNotAdded < maxTime2 ? false : true

    return (
      <ThemeProvider theme={theme}>
        <Grid item xs={12} md={6} lg={4}>
          <Box
            sx={{
              border: '1px solid #EDECF5',
              borderRadius: '8px',
              backgroundColor: '#fff',
              height: '100%',
            }}
          >
            <Box px={3} py={1.8} sx={{ borderBottom: '1px solid #EDECF5' }}>
              <span className="paragraph bold">{this.props.users.languageStateForRedux?.formfields?.campaigns?.recipients}</span>
            </Box>
            <Box component={'div'} px={3} py={4}>
              <Box
                component={Grid}
                spacing={4}
                container
                className="inputField"
              >
                <Grid item xs={12}>
                  <ButtonGroup fullWidth size="large">
                    <Box
                      component={Button}
                      sx={{
                        padding: '7px 6px !Important',
                        textTransform: 'capitalize !Important',
                        borderColor: '#CCCED5 !Important',
                        fontWeight: '400 !Important',
                        '&:hover, &.active': {
                          backgroundColor: '#ECEAFF ',
                          borderColor: '#5141E7 ',
                          color: '#5141E7 ',
                        },
                      }}
                      className={openAudience && 'active'}
                      onClick={() =>
                        this.handleOpenRecipientTypeModal('audience')
                      }
                    >
                      {this.props.users.languageStateForRedux?.overviewTab?.audience}
                    </Box>
                    <Box
                      component={Button}
                      sx={{
                        padding: '7px 6px !Important',
                        textTransform: 'capitalize !Important',
                        borderColor: '#CCCED5 !Important',
                        fontWeight: '400 !Important',
                        '&:hover, &.active': {
                          backgroundColor: '#ECEAFF ',
                          borderColor: '#5141E7 ',
                          color: '#5141E7 ',
                        },
                      }}
                      className={openAttendees && 'active'}
                      onClick={() =>
                        this.handleOpenRecipientTypeModal('attendees')
                      }
                    >
                      {this.props.users.languageStateForRedux?.overviewTab?.attendees}
                    </Box>
                    <Box
                      component={Button}
                      sx={{
                        padding: '7px 6px !Important',
                        textTransform: 'capitalize !Important',
                        borderColor: '#CCCED5 !Important',
                        fontWeight: '400 !Important',
                        '&:hover, &.active': {
                          backgroundColor: '#ECEAFF ',
                          borderColor: '#5141E7 ',
                          color: '#5141E7 ',
                        },
                      }}
                      className={openGroups && 'active'}
                      onClick={() =>
                        this.handleOpenRecipientTypeModal('attendeesGroups')
                      }
                    >
                      {this.props.users.languageStateForRedux?.overviewTab?.groups}
                    </Box>
                    <Box
                      component={Button}
                      sx={{
                        padding: '7px 6px !Important',
                        textTransform: 'capitalize !Important',
                        borderColor: '#CCCED5 !Important',
                        fontWeight: '400 !Important',
                        '&:hover, &.active': {
                          backgroundColor: '#ECEAFF ',
                          borderColor: '#5141E7 ',
                          color: '#5141E7 ',
                        },
                      }}
                      className={openSearchAdd && 'active'}
                      onClick={() =>
                        this.handleOpenRecipientTypeModal('searchAdd')
                      }
                    >
                      {this.props.users.languageStateForRedux?.formfields?.campaigns?.searchAndAdd}
                    </Box>
                  </ButtonGroup>
                </Grid>

                {/* if Audience */}
                {openAudience && (
                  <>
                    <Grid item xs={12} mt={-1}>
                      <Box
                        component="div"
                        style={{
                          position: 'relative',
                          borderBottom: '1px solid #EFEEF6',
                        }}
                        pb={3.6}
                        className="inputField"
                      >
                        <Button
                          fullWidth
                          variant="outlined"
                          style={{
                            padding: '10px 15px',
                            borderRadius: '7px',
                            justifyContent: 'space-between',
                            textTransform: 'none',
                          }}
                          size="large"
                          disableRipple
                          ref={this.anchorRef}
                          aria-controls={
                            ChooseAudience ? 'menu-list-grow' : undefined
                          }
                          aria-haspopup="true"
                          onClick={this.handleToggleChooseAudience}
                          endIcon={<ArrowDropDownIcon />}
                        >
                          {this.props.users.languageStateForRedux?.formfields?.campaigns?.chooseAudience}
                        </Button>
                        <Popper
                          open={ChooseAudience}
                          style={{ right: 0, zIndex: 1 }}
                          placement="bottom-start"
                          transition
                          anchorEl={this.anchorRef.current}
                          role={undefined}
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Fade {...TransitionProps} timeout={250}>
                              <Box
                                sx={{
                                  maxHeight: '230px',
                                  overflow: 'scroll',
                                  backgroundColor: theme.palette.common.white,
                                  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                  borderRadius: '4px',
                                  marginTop: '8px',
                                }}
                              >
                                <ClickAwayListener
                                  onClickAway={this.handleCloseChooseAudience}
                                >
                                  <MenuList
                                    autoFocusItem={ChooseAudience}
                                    id="menu-list-grow"
                                    onKeyDown={this.handleListKeyDown}
                                  >
                                    {Array.isArray(listOfAudienceGroups) &&
                                    listOfAudienceGroups.length ? (
                                      <>
                                        <MenuItem className="checkbox-item">
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                style={{
                                                  padding: 0,
                                                  marginRight: '8px',
                                                }}
                                                color="primary"
                                              />
                                            }
                                            label="All"
                                            style={{ padding: 0, margin: 0 }}
                                            checked={isAllAudiencesChecked}
                                            onClick={
                                              this.handleAllAudienceCheckbox
                                            }
                                          />
                                        </MenuItem>

                                        {listOfAudienceGroups.map(
                                          (audience) => {
                                            return (
                                              <MenuItem
                                                className="checkbox-item"
                                                disabled={
                                                  !audience.audienceCount
                                                }
                                              >
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      style={{
                                                        padding: 0,
                                                        marginRight: '8px',
                                                      }}
                                                      color="primary"
                                                    />
                                                  }
                                                  label={`${audience.groupName} (${audience.audienceCount})`}
                                                  style={{
                                                    padding: 0,
                                                    margin: 0,
                                                  }}
                                                  checked={recipientsDetails?.audienceGroupsList?.includes(
                                                    audience._id,
                                                  )}
                                                  onClick={(e) =>
                                                    this.handleAudienceCheckboxes(
                                                      e,
                                                      audience,
                                                    )
                                                  }
                                                />
                                              </MenuItem>
                                            )
                                          },
                                        )}
                                      </>
                                    ) : (
                                      <p style={{ textAlign: 'center' }}>
                                        No audience group available
                                      </p>
                                    )}
                                  </MenuList>
                                </ClickAwayListener>
                              </Box>
                            </Fade>
                          )}
                        </Popper>

                        <span className="error_mesage required">
                          {' '}
                          {this.props.errorsFromEditCampaign.audience}{' '}
                        </span>

                        <Box sx={{ overflow: 'auto', maxHeight: '500px', paddingRight: '8px' }}>
                          {listOfAudienceGroups
                            ?.filter((audience) =>
                              recipientsDetails?.audienceGroupsList?.includes(
                                audience._id,
                              ),
                            )
                            .map((audience) => {
                              return (
                                <Box className="paragraph selection">
                                  {audience.groupName} ({audience.audienceCount}
                                  ){' '}
                                  <img
                                    alt="CloseIcon"
                                    src={removeIcon}
                                    onClick={(e) =>
                                      this.removeAudienceFromList(e, audience)
                                    }
                                  />
                                </Box>
                              )
                            })}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        style={{ borderBottom: '1px solid #EFEEF6' }}
                        pb={3.6}
                      >
                      <Grid item>
                      <Stack direction={'row'} alignItems={'start'}>
                      <Tooltip
                        title={this.props.users.languageStateForRedux?.formfields?.campaigns?.excludeAttendeeTooltip}
                        placement="top"
                        arrow
                      >
                      <img style={{ display:"inline", marginRight:"8px", width:"20px", marginTop:"4px" }} src={informationIcon} alt="info" />
                      </Tooltip>
                      <label>
                        {this.props.users.languageStateForRedux?.formfields?.campaigns?.excludeAttendees} 
                      </label>
                      </Stack>
                      </Grid>
                        <span className="paragraph bold text-primary-light">
                        {this.props.users.languageStateForRedux?.unlayer?.modalSettings?.no}
                        </span>
                        <CustomSwitch
                          color={'primary'}
                          onChange={this.handleExcludeAttendeesForAudience}
                          checked={
                            recipientsDetails.excludeAttendees ? true : false
                          }
                        />
                        <span className="paragraph bold">{this.props.users.languageStateForRedux?.buttons?.common?.yes}</span>
                      </Box>
                    </Grid>
                  </>
                )}

                {/* if Attendees */}
                {openAttendees && (
                  <>  
                    <Grid item xs={12}>
                      <span className="error_mesage required">
                        {' '}
                        {this.props.errorsFromEditCampaign.attendees}{' '}
                      </span>
                      <Box
                        className="paragraph"
                        style={{ borderBottom: '1px solid #EFEEF6' }}
                        pb={3.6}
                      >
                        {this.props.users.languageStateForRedux?.formfields?.campaigns?.recipientSubhead}
                      </Box>
                    </Grid>
                  </>
                )}
                {/* if Groups */}
                {openGroups && (
                  <>
                    <Grid item xs={12} mt={-1}>
                      <Box
                        component="div"
                        style={{
                          position: 'relative',
                          borderBottom: '1px solid #EFEEF6',
                        }}
                        pb={3.6}
                        className="inputField"
                      >
                        <Button
                          fullWidth
                          variant="outlined"
                          style={{
                            padding: '10px 15px',
                            borderRadius: '7px',
                            justifyContent: 'space-between',
                            textTransform: 'none',
                          }}
                          size="large"
                          disableRipple
                          ref={this.anchorRef}
                          aria-controls={
                            ChooseAudience ? 'menu-list-grow' : undefined
                          }
                          aria-haspopup="true"
                          onClick={this.handleToggleSelectGroups}
                          endIcon={<ArrowDropDownIcon />}
                        >
                          {this.props.users.languageStateForRedux?.dropdowns?.agenda?.selectGroups}
                        </Button>
                        <Popper
                          open={SelectGroups}
                          style={{ right: 0, zIndex: 1 }}
                          placement="bottom-start"
                          transition
                          anchorEl={this.anchorRef.current}
                          role={undefined}
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Fade {...TransitionProps} timeout={250}>
                              <Box
                                sx={{
                                  maxHeight: '230px',
                                  overflow: 'scroll',
                                  backgroundColor: theme.palette.common.white,
                                  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                  borderRadius: '4px',
                                  marginTop: '8px',
                                }}
                              >
                                <ClickAwayListener
                                  onClickAway={this.handleCloseSelectGroups}
                                >
                                  <MenuList
                                    autoFocusItem={SelectGroups}
                                    id="menu-list-grow"
                                    onKeyDown={this.handleListKeyDown}
                                  >
                                    {Array.isArray(listOfGroups) &&
                                    listOfGroups.length ? (
                                      <>
                                        <MenuItem className="checkbox-item">
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                style={{
                                                  padding: 0,
                                                  marginRight: '8px',
                                                }}
                                                color="primary"
                                              />
                                            }
                                            label="All"
                                            style={{ padding: 0, margin: 0 }}
                                            onClick={
                                              this.handleAllGroupsCheckbox
                                            }
                                            checked={isAllGroupsChecked}
                                          />
                                        </MenuItem>

                                        {Array.isArray(listOfGroups) && listOfGroups.length && this.filteredListOfGroups().map((group) => {
                                          return (
                                            <MenuItem
                                              className="checkbox-item"
                                              disabled={
                                                !group.totalAttendeesCount
                                              }
                                            >
                                              <FormControlLabel
                                                control={
                                                  <>
                                                  <Checkbox
                                                  style={{
                                                    padding: 0,
                                                    marginRight: '8px',
                                                  }}
                                                  color="primary"
                                                  checked={recipientsDetails?.attendeesGroupsList?.includes( group._id) ? true: false }
                                                  />
                                                  { group?.showToGroup && group.source === "GoogleWorkspace" && <img src={GoogleWorkspace} alt="google workspace" style={{marginRight: "5px"}} width="14px" />}
                                                  </>
                                                }
                                                label={`${group.attendeesGroupName} (${group.totalAttendeesCount})`}
                                                style={{
                                                  padding: 0,
                                                  margin: 0,
                                                }}                                                  
                                                onClick={(e) =>
                                                  this.handleGroupsCheckboxes(
                                                    e,
                                                    group,
                                                  )
                                                }
                                              />
                                              
                                            </MenuItem>
                                          )
                                        })}
                                      </>
                                    ) : (
                                      <p style={{ textAlign: 'center' }}>
                                        No group available
                                      </p>
                                    )}
                                  </MenuList>
                                </ClickAwayListener>
                              </Box>
                            </Fade>
                          )}
                        </Popper>
                        <span className="error_mesage required">
                          {' '}
                          {this.props.errorsFromEditCampaign.groups}{' '}
                        </span>

                        <div>
                          {listOfGroups
                            ?.filter((group) =>
                              recipientsDetails?.attendeesGroupsList?.includes(
                                group._id,
                              ),
                            )
                            .map((group) => {
                              return (
                                <Box className="paragraph selection">
                                  <Box>
                                  {group?.source === "GoogleWorkspace" && <img src={GoogleWorkspace} alt="google workspace" width="14px" style={{marginRight: "5px"}}/>}
                                  {' '}
                                  {group.attendeesGroupName} (
                                  {group.totalAttendeesCount})
                                  </Box>
                                  <img
                                    alt="CloseIcon"
                                    src={removeIcon}
                                    onClick={(e) =>
                                      this.removeGroupsFromList(e, group)
                                    }
                                  />
                                </Box>
                              )
                            })}
                        </div>
                      </Box>
                    </Grid>
                  </>
                )}
                {/* if Attendees/Groups */}
                {(openAttendees || openGroups) && (
                  <>
                    <Grid item xs={12}>
                      <Box
                        style={{ borderBottom: '1px solid #EFEEF6' }}
                        pb={3.6}
                      > 
                        <Grid item>
                      <Stack direction={'row'} alignItems={"start"}>
                      <Tooltip
                        title={openAttendees ? this.props.users.languageStateForRedux?.formfields?.campaigns?.excludeAttendeeTooltip2:this.props.users.languageStateForRedux?.formfields?.campaigns?.excludeAttendeeGroupTooltip}
                        placement="top"
                        arrow
                      >
                      <img style={{ display:"inline", marginRight:"8px", width:"20px", marginTop:"4px" }} src={informationIcon} alt="info" />
                      </Tooltip>
                      <label>{openAttendees ? this.props.users.languageStateForRedux?.formfields?.campaigns?.excludeAttendeeLabel:this.props.users.languageStateForRedux?.formfields?.campaigns?.excludeAttendeeGroupLabel}</label>
                      </Stack>
                      </Grid>
                        <span className="paragraph bold text-primary-light">
                        {this.props.users.languageStateForRedux?.unlayer?.modalSettings?.no}
                        </span>
                        <CustomSwitch
                          color={'primary'}
                          onChange={
                            this.handleExcludeCheckedInAttendeesForGroups
                          }
                          checked={
                            recipientsDetails.excludeCheckedInAttendees
                              ? true
                              : false
                          }
                        />
                        <span className="paragraph bold">{this.props.users.languageStateForRedux?.buttons?.common?.yes}</span>
                      </Box>
                    </Grid>
                  </>
                )}
                {/* if Search & Add */}
                {openSearchAdd && (
                  <>
                    <Grid item xs={12} mt={-1}>
                      {this.props.errorsFromEditCampaign.searchAdd ? (
                        <span className="error_mesage required">
                          {' '}
                          {this.props.errorsFromEditCampaign.searchAdd}{' '}
                        </span>
                      ) : (
                        <p>
                          {this.props.users.languageStateForRedux?.formfields?.campaigns?.sendCampaign}{' '}
                          {listOfAttendeesOfCampaignBackup.length} {this.props.users.languageStateForRedux?.common?.attendees}
                        </p>
                      )}

                      <Box style={{ borderTop: '1px solid #EFEEF6' }} pt={3.6}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.handleOpenSearchAndAddModal}
                        >
                          {this.props.users.languageStateForRedux?.buttons?.common?.addRemoveButton}
                        </Button>
                      </Box>
                    </Grid>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>

        <Modal
          open={this.state.openSearchAndAddModal}
          onClose={this.handleCloseSearchAndAddModal}
          classNames={{
            overlay: 'full-screen_overlay',
            modal:
              'full-screen_modal manage-custom-fields modal-default-close-none',
          }}
          center
        >
          <Grid container justifyContent="end">
            {!this.state.openAddedPeople ? (
              <Grid
                item
                xs={12}
                md={6}
                lg={5}
                xl={4}
                className="full-screen_modal__form"
              >
                <Box component="div" className="full-screen_modal__form_header">
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}
                  >
                    <Grid item xs>
                      <h3 className="subtitle">{this.props.users.languageStateForRedux?.actions?.attendees?.selected}</h3>
                      <p className="small-subtitle">
                        {this.props.users.languageStateForRedux?.formfields?.campaigns?.recipientsAddAttendeeDesc}
                      </p>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container justifyContent={'end'}>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleOpenAddedPeopleModal}
                            disableElevation
                          >
                            {this.props.users.languageStateForRedux?.buttons?.agenda?.addOnly}
                          </Button>
                        </Grid>
                        <Box component={Grid} item ml={1}>
                          <CloseIcon
                            onClick={this.handleCloseSearchAndAddModal}
                            className="modal-close-button"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  component="div"
                  px={3}
                  py={2}
                  className="full-screen_modal__form_body"
                >
                  <Grid
                    spacing={1.5}
                    container
                    justifyContent="between"
                    alignItems="center"
                  >
                    <Grid item xs>
                      <SquareBadge
                        badgeContent={peopleSelectedToRemoveFromInvite.length}
                        color="primary"
                      >
                        {this.props.users.languageStateForRedux?.dropdowns?.attendees?.selected} (
                        {
                          listOfAttendeesOfCampaign.slice(
                            0,
                            this.state.numOfAttendeesToShow *
                              this.state.scroll_no_forAdded,
                          ).length
                        })
                      </SquareBadge>
                    </Grid>
                    <Grid item className="">
                      <form onSubmit={this.searchAddedPeopleInInviteList}>
                        <Box
                          component={TextField}
                          mt={0.8}
                          variant="standard"
                          placeholder={this.props.users.languageStateForRedux?.common?.search}
                          className="search"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <Box
                                component={InputAdornment}
                                pb={0.5}
                                position="start"
                              >
                                <img alt="" src={SearchIcon} />
                              </Box>
                            ),
                          }}
                          value={searchTextForAddedPeopleInInviteList}
                          name="searchTextForAddedPeopleInInviteList"
                          onChange={this.handleSearchChange}
                        />
                      </form>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="Secondary"
                        onClick={this.removePeopleFromInviteListButton}
                        disabled={
                          peopleSelectedToRemoveFromInvite.length ? false : true
                        }
                        disableElevation
                      >
                        {this.props.users.languageStateForRedux?.buttons?.common?.remove}
                      </Button>
                    </Grid>
                  </Grid>

                  <Box
                    className="custom-fields-table"
                    scomponent={TableContainer}
                    sx={{
                      maxHeight: 'calc(100vh - 220px)',
                      overflowY: 'scroll',
                    }}
                    my={2}
                  >
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableHeadCell checkbox">
                            <Checkbox
                              color="primary"
                              onClick={this.handleSelectAllPeopleInToInviteList}
                              checked={
                                peopleSelectedToRemoveFromInvite?.length > 0
                                  ? isSelectAllPeopleToRemoveChecked
                                  : false
                              }
                            />
                          </TableCell>
                          <TableCell
                            className="tableHeadCell email"
                            align="left"
                          >
                            {this.props.users.languageStateForRedux?.column_names?.teams?.email}
                          </TableCell>
                          <TableCell
                            className="tableHeadCell"
                            align="left"
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      {this.showAddedAttendees().length > 0 ? (
                        <TableBody>
                          {this.showAddedAttendees().map((attendee) => {
                            return (
                              <TableRow>
                                <TableCell
                                  className="tableBodyCell checkbox"
                                  scope="row"
                                >
                                  <Checkbox
                                    color="primary"
                                    onClick={(e) =>
                                      this.removePeopleFromInviteList(
                                        e,
                                        attendee,
                                      )
                                    }
                                    checked={
                                      peopleSelectedToRemoveFromInvite.includes(
                                        attendee._id,
                                      )
                                        ? true
                                        : false
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className="tableBodyCell email"
                                  align="left"
                                >
                                  {attendee.email}
                                </TableCell>
                                <TableCell
                                  className="tableBodyCell"
                                  align="left"
                                ></TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      ) : (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              style={{ textAlign: 'center' }}
                            >
                              {this.props.users.languageStateForRedux?.common?.noRecords}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </Box>
                </Box>
                <Box className="full-screen_modal__form_footer">
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs>
                      <Grid container justifyContent="center">
                        <Grid item>
                          {!loadMoreButtonForToInvite && (
                            <Button
                              disableElevation
                              variant="contained"
                              color="primary"
                              disabled={loadMoreButtonForToInvite}
                              onClick={() => this.loadMoreForAdded()}
                            >
                              Load More
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                md={6}
                lg={5}
                xl={4}
                className="full-screen_modal__form"
              >
                <Box component="div" className="full-screen_modal__form_header">
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs>
                      <Grid container alignItems="center">
                        <Grid item>
                          <ArrowBackIcon
                            onClick={this.handleCloseAddedPeopleModal}
                            size="small"
                            style={{
                              color: theme.palette.common.black,
                              margin: '0px 10px 0 0px',
                              cursor: 'pointer',
                            }}
                          />
                        </Grid>
                        <Grid item xs> 
                          <h3 className="subtitle">{this.props.users.languageStateForRedux?.formfields?.campaigns?.attendeeList}</h3>
                          <p className="small-subtitle">
                            {this.props.users.languageStateForRedux?.formfields?.campaigns?.attendeeListDesc}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container justifyContent={"end"}>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            disabled={
                              peopleSelectedToAddToInvite.length ? false : true
                            }
                            onClick={this.addPeopleToInviteListButton}
                          >
                            {this.props.users.languageStateForRedux?.buttons?.agenda?.addOnly}
                          </Button>
                        </Grid>
                        <Box component={Grid} item ml={1}>
                          <CloseIcon
                            onClick={this.handleCloseSearchAndAddModal}
                            className="modal-close-button"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  component="div"
                  px={3}
                  py={2}
                  className="full-screen_modal__form_body"
                >
                  <Grid spacing={1.5} container alignItems="center">
                    <Grid item xs={12} md>
                      <Box component="p" className="paragraph">
                        <SquareBadge
                          badgeContent={peopleSelectedToAddToInvite.length}
                          color="primary"
                        >
                          {this.props.users.languageStateForRedux?.overviewTab?.attendees} (
                          {
                            listOfAttendeesNotAddedToCampaign.slice(
                              0,
                              this.state.numOfAttendeesToShow *
                                this.state.scroll_no_forNotAdded,
                            ).length
                          }
                          )
                        </SquareBadge>
                      </Box>
                    </Grid>
                    <Grid item xs className="">
                      <form onSubmit={this.searchNotAddedPeopleInInviteList}>
                        <Box
                          component={TextField}
                          mt={0.8}
                          style={{ width: '100%' }}
                          variant="standard"
                          placeholder={this.props.users.languageStateForRedux?.common?.search}
                          className="search"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <Box
                                component={InputAdornment}
                                pb={0.5}
                                position="start"
                              >
                                <img alt="" src={SearchIcon} />
                              </Box>
                            ),
                          }}
                          value={searchTextForNotAddedPeopleInInviteList}
                          name="searchTextForNotAddedPeopleInInviteList"
                          onChange={this.handleSearchChange}
                        />
                      </form>
                    </Grid>
                  </Grid>

                  <Box
                    className="custom-fields-table"
                    scomponent={TableContainer}
                    sx={{
                      maxHeight: 'calc(100vh - 220px)',
                      overflowY: 'scroll',
                    }}
                    my={2}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableHeadCell checkbox">
                            <Checkbox
                              color="primary"
                              onClick={
                                this.handleSelectAllPeopleNotInInviteList
                              }
                              checked={
                                peopleSelectedToAddToInvite?.length > 0
                                  ? isSelectAllPeopleToAddChecked
                                  : false
                              }
                            />
                          </TableCell>
                          <TableCell
                            className="tableHeadCell email"
                            align="left"
                          >
                            {this.props.users.languageStateForRedux?.column_names?.teams?.email}
                          </TableCell>
                          <TableCell
                            className="tableHeadCell"
                            align="left"
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.isArray(listOfAttendeesNotAddedToCampaign) &&
                        listOfAttendeesNotAddedToCampaign?.length > 0 &&
                        this.showNotAddedAttendees().length > 0 ? (
                          this.showNotAddedAttendees()?.map((attendee) => {
                            return (
                              <TableRow>
                                <TableCell
                                  className="tableBodyCell checkbox"
                                  scope="row"
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      peopleSelectedToAddToInvite.includes(
                                        attendee._id,
                                      )
                                        ? true
                                        : false
                                    }
                                    onClick={(e) =>
                                      this.addPeopleToInviteListCheckbox(
                                        e,
                                        attendee,
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className="tableBodyCell email"
                                  align="left"
                                >
                                  {attendee.email}
                                </TableCell>
                                <TableCell
                                  className="tableBodyCell"
                                  align="left"
                                ></TableCell>
                              </TableRow>
                            )
                          })
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              style={{ textAlign: 'center' }}
                            >
                              {this.props.users.languageStateForRedux?.common?.noRecords}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
                <Box className="full-screen_modal__form_footer">
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs>
                      <Grid container justifyContent="center">
                        <Grid item>
                          {!loadMoreButtonForNotAdded && (
                            <Button
                              disableElevation
                              variant="contained"
                              color="primary"
                              disabled={loadMoreButtonForNotAdded}
                              onClick={() => this.loadMoreForNotAdded()}
                            >
                              Load More
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
          </Grid>
        </Modal>
        <Modal
          open={this.state.openRecipientTypeModal}
          onClose={this.handleCloseRecipientTypeModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid container className="modal-wrapper">
            <Grid item xs={12} pb={4}>
              <Box component="p" mb={1} className="subtitle">
              {this.props.users.languageStateForRedux?.common?.confirmation}
              </Box>
              <p className="small-subtitle">
              {this.props.users.languageStateForRedux?.formfields?.campaigns?.recipientsModalMsg}
              </p>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.handleContinueToOtherRecipientType}
                  >
                    {this.props.users.languageStateForRedux?.buttons?.common?.continue}
                  </Button>
                  <Button
                    variant="outlined"
                    disableElevation
                    onClick={this.handleCancelChangingRecipientTypeModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  campaigns: state.Campaigns,
  attendees: state.Attendees,
  projects: state.Projects,
  events: state.Events,
  users: state.Users,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getCampaign: Actions.getCampaignRequest,
      listAudienceGroups: Actions.listAudienceGroupsRequest,
      listAttendeesOfCampaigns: Actions.listAttendeesOfCampaignsRequest,
      listAttendeesNotAddedToCampaign:
        Actions.listAttendeesNotAddedToCampaignRequest,
      listAttendeesGroups: Actions.listAttendeesGroupsRequest,
      listAttendees: Actions.listAttendeesRequest,
      handleListOfAttendeesOfCampaignBackup:
        Actions.handleListOfAttendeesOfCampaignBackup,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withRouter(EditCampaignRecipients))
