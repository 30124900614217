import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ListItemText } from '@mui/material';
import { Checkbox, OutlinedInput } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ThemeProvider } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import MenuItem from '@material-ui/core/MenuItem';
import Stack from '@mui/material/Stack';
import Button from '@material-ui/core/Button';
import { theme } from '../../../theme/theme';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSelector, useDispatch } from 'react-redux';
import { getContactFields, textFieldConditions, booleanFieldConditions, numberFieldConditions, inAndNotInConditions ,dateFieldConditions, inAndNotInConditionsForUser} from 'helpers/common';
import { AudienceValidationError, FetchAudienceQueryParams } from './ContactGroup';
import { Actions } from 'Redux/Actions';
import { AudienceParent, AudienceState } from 'Redux/Reducers/opportunity/audience.reducer';
import { CustomFields_State } from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { getActiveUsersForCompanyAPI } from 'Redux/API';
import { createNotification } from 'helpers';

type Props = {
    openModal: boolean;
    closeModalFunc: () => void;
    queryParams: FetchAudienceQueryParams;
    editData?: AudienceParent;
    parentId?: string;
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 250,
            marginTop: 4,
        },
    },
};

const SmartGroup: React.FC<Props> = ({ openModal, closeModalFunc, queryParams, editData, parentId }) => {
    const {
        createOpportunityAudienceSuccess,editOpportunityAudienceSuccess
    } = useSelector((state: any) => state.OpportunityAudience) as AudienceState;
    const { audienceList, groups } = useSelector((state: any) => state.OpportunityAudience) as AudienceState;
    const [audienceName, setAudienceName] = useState<string>('');
    const [smartGroupFilter, setSmartGroupFilter] = useState<Array<{ column: string; condition: string; logicalOperator?: string; value: string | string[]; fieldType: string; }>>([]);
    const [errors, setErrors] = useState<AudienceValidationError>({});
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [filterDataTypes, setFilterDataTypes] = useState<Array<{ fieldType: string; }>>([]);
    const [usersForUserCfDataType, setUsersForUserCfDataType] = useState([]);
    const [searchTextForUsers, setSearchTextForUsers] = useState('')
    const [combinedFields, setCombinedFields] = useState<{
        disabled: boolean, field: string, label: string 
}[]>([]);
    const { customFields } = useSelector(
        (state: any) => state.OpportunityCustomFields,
    ) as CustomFields_State;

    const dispatch = useDispatch();

    useEffect(() => {
        if (openModal) {
            handleAddNewFilter();
            fetchAllTheActiveUsers()
        }
    }, [openModal]);

    useEffect(() => {
        const updatedFields = [
            ...getContactFields,
            ...customFields.map(cf => ({ field: `customFields.${cf._id}`, label: cf.fieldData.fieldName,disabled :false }))
        ];
        setCombinedFields(updatedFields);
    }, [getContactFields, customFields]);

    useEffect(() => {
        if (editData && Object.keys(editData).length > 0) {
            setIsEdit(true);
            setAudienceName(editData.audienceName);
            const filters = editData.smartGroupFilter || []; // Default to empty array if smartGroupFilter is undefined

            // Transform the filters into the format expected by filterDataTypes
            const fieldTypes = filters.map(filter => ({ fieldType: filter.fieldType })) || [];

            // Set the state
            setSmartGroupFilter(filters);
            setFilterDataTypes(fieldTypes);
        } else {
            setIsEdit(false);
            setAudienceName('');
            setSmartGroupFilter([]);
        }
    }, [editData]);

    useEffect(() => {
        if (createOpportunityAudienceSuccess) {
            setAudienceName('');
            setIsEdit(false);
            setErrors({});
            setSmartGroupFilter([]);
            closeModalFunc(); // Close modal after form submission
        }

    }, [createOpportunityAudienceSuccess]);

    useEffect(() => {
        if (editOpportunityAudienceSuccess) {
            closeModalFunc(); // Close modal after form submission
        }
    }, [editOpportunityAudienceSuccess]);

    

    const createAudience = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let isValidValue = isValidAudienc();

        if (isValidValue) {
            if (isEdit && editData?.id) {
                let data = {
                    bodyParams: {
                        audienceName: audienceName.trim(),
                        audienceId: editData.id,
                        isSmartGroup: true,
                        smartGroupFilter: smartGroupFilter
                    },
                    queryParams: queryParams,
                };
                dispatch(Actions.editOpportunityAudienceRequest(data));
            } else {
                let data = {
                    bodyParams: {
                        parentId: parentId ? parentId : null,
                        audienceName: audienceName.trim(),
                        isSmartGroup: true,
                        smartGroupFilter: smartGroupFilter
                    },
                    queryParams: queryParams,
                };
                dispatch(Actions.createOpportunityAudienceRequest(data));
            }
        }
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAudienceName(e.target.value);
    };

    const handleFieldChange = (index: number, event: SelectChangeEvent<string>) => {
        const selectedField = event.target.value;
        const customField = customFields.find(cf => `customFields.${cf._id}` === selectedField);
        const fieldType = customField?.fieldData?.fieldType || 'text'; // Default to 'text' if not found
    
        // Determine the previously selected field
        const previousField = smartGroupFilter[index]?.column;
        const previousCustomField = customFields.find(cf => `customFields.${cf._id}` === previousField);
        const previousFieldType = previousCustomField?.fieldData?.fieldType || 'text';
    
        // Update the smartGroupFilter with the new field selection
        const updatedFilters = smartGroupFilter.map((item, i) =>
            i === index ? { ...item, column: selectedField, fieldType, value: '' } : item
        );
        setSmartGroupFilter(updatedFilters);
    
        // Handle disabling/enabling of fields
        const updatedCombinedFields = combinedFields.map(field => {
            const isDateField = customFields.find(cf => `customFields.${cf._id}` === field.field)?.fieldData.fieldType === 'date';
            const isAudienceIdsField = field.field === 'audienceIds';
            const isListField = customFields.find(cf => `customFields.${cf._id}` === field.field)?.fieldData.fieldType === 'list';
            const isUserField = customFields.find(cf => `customFields.${cf._id}` === field.field)?.fieldData.fieldType === 'user';
    
            if (field.field === selectedField) {
                // Disable the newly selected date, audienceIds, or list field
                return {
                    ...field,
                    disabled: isDateField || isAudienceIdsField || isListField || isUserField ? true : field.disabled
                };
            } else if (field.field === previousField && (isDateField || isAudienceIdsField || isListField ||isUserField)) {
                // Re-enable the previously selected date, audienceIds, or list field if it was disabled
                return { ...field, disabled: false };
            }
    
            return field;
        });
    
        // Update the state with the new combinedFields
        setCombinedFields(updatedCombinedFields);
    };

    const handleConditionChange = (index: number, event: SelectChangeEvent<string>) => {
        const newFilters = [...smartGroupFilter];
        newFilters[index].condition = event.target.value;
        newFilters[index].value = ''
        setSmartGroupFilter(newFilters);
    };

    const handleValueChange = (
        index: number,
        event:  string[] | React.ChangeEvent<HTMLInputElement>,
        dateType?: 'beforeDate' | 'afterDate' | 'fromToDate1' | 'fromToDate2'
    ) => {
        const newFilters = [...smartGroupFilter];
        const filter = newFilters[index];
        const { fieldType } = filter;
        const value = event.target.value as string;
    
        // Handle date field types
        if (fieldType === 'date') {
            // Ensure filter.value is an array for date fields
            if (dateType === 'fromToDate1' || dateType === 'fromToDate2') {
                if (!Array.isArray(filter.value)) {
                    filter.value = ['', '']; // Initialize as an array if not already
                }
            }
    
            switch (dateType) {
                case 'beforeDate':
                    if (Array.isArray(filter.value)) {
                        filter.value = [value, filter.value[1]]; // Update endDate
                    } else {
                        filter.value = value; // Fallback if value isn't an array
                    }
                    break;
                case 'afterDate':
                    if (Array.isArray(filter.value)) {
                        filter.value = [filter.value[0], value]; // Update startDate
                    } else {
                        filter.value = value; // Fallback if value isn't an array
                    }
                    break;
                case 'fromToDate1':
                    if (Array.isArray(filter.value)) {
                        filter.value[0] = value; // Update startDate
                    } else {
                        filter.value = [value, '']; // Initialize array if needed
                    }
                    break;
                case 'fromToDate2':
                    if (Array.isArray(filter.value)) {
                        filter.value[1] = value; // Update endDate
                    } else {
                        filter.value = ['', value]; // Initialize array if needed
                    }
                    break;
                default:
                    // Default behavior for 'date' type, if any
                    break;
            }
        } else if (fieldType === 'list' || filter.column === 'audienceIds') {
            // Handle list/multi-select cases
            filter.value = value; // Assuming value is a single string in this case
        } else {
            // Handle single value cases (text, number, boolean, etc.)
            filter.value = value;
        }
    
        setSmartGroupFilter(newFilters);
    };
    
    const handlelogicalOperatorChange = (index: number, event: SelectChangeEvent<string>) => {
        const newFilters = [...smartGroupFilter];
        newFilters[index].logicalOperator = event.target.value;
        setSmartGroupFilter(newFilters);
    };

    const handleAddNewFilter = () => {
        // Determine which date fields, list fields, and audienceIds columns are selected in the smartGroupFilter
        const selectedDateFields = smartGroupFilter
            .filter(filter => filter.fieldType === 'date')
            .map(filter => filter.column);
        
        const selectedListFields = smartGroupFilter
            .filter(filter => filter.fieldType === 'list')
            .map(filter => filter.column);

        const selectedUserFields = smartGroupFilter
            .filter(filter => filter.fieldType === 'user')
            .map(filter => filter.column);
        
        const selectedAudienceIdsField = smartGroupFilter
            .some(filter => filter.column === 'audienceIds');
        
        // Update combinedFields to disable selected fields of type 'date', 'list', or 'audienceIds'
        const updatedCombinedFields = combinedFields.map(field => {
            const isDateField = customFields.find(cf => `customFields.${cf._id}` === field.field)?.fieldData.fieldType === 'date';
            const isListField = customFields.find(cf => `customFields.${cf._id}` === field.field)?.fieldData.fieldType === 'list';
            const isUserField = customFields.find(cf => `customFields.${cf._id}` === field.field)?.fieldData.fieldType === 'user';
            const isAudienceIdsField = field.field === 'audienceIds';
            
            if ((isDateField && selectedDateFields.includes(field.field)) || 
                (isListField && selectedListFields.includes(field.field)) ||
                (isUserField && selectedUserFields.includes(field.field)) ||
                (isAudienceIdsField && selectedAudienceIdsField)) {
                return { ...field, disabled: true };
            }
            
            return { ...field, disabled: false };
        });
        
        // Update the state with the new combinedFields
        setCombinedFields(updatedCombinedFields);
        
        // Add a new filter
        setSmartGroupFilter([
            ...smartGroupFilter, 
            { 
                column: 'Select Column', 
                condition: 'Select Condition', 
                value: '', 
                logicalOperator: 'AND', 
                fieldType: '' 
            }
        ]);
    };
    
    const handleRemoveFilter = (index: number) => {
        // Remove the filter at the specified index
        const newFilters = smartGroupFilter.filter((_, i) => i !== index);
        setSmartGroupFilter(newFilters);
    
        // Determine which fields are currently disabled
        const disabledFields = combinedFields
            .filter(field => field.disabled)
            .map(field => field.field);
    
        // Reset the disabled state for date fields, audienceIds, and list fields
        const updatedCombinedFields = combinedFields.map(field => {
            const isDateField = customFields.find(cf => `customFields.${cf._id}` === field.field)?.fieldData.fieldType === 'date';
            const isAudienceIdsField = field.field === 'audienceIds';
            const isListField = customFields.find(cf => `customFields.${cf._id}` === field.field)?.fieldData.fieldType === 'list';
            const isUserField = customFields.find(cf => `customFields.${cf._id}` === field.field)?.fieldData.fieldType === 'user';
    
            if ((isDateField || isAudienceIdsField || isListField || isUserField) && disabledFields.includes(field.field)) {
                return { ...field, disabled: false };
            }
            
            return field;
        });
    
        // Update the state with the new combinedFields
        setCombinedFields(updatedCombinedFields);
    };
    
    const handleOnClose = () => {
        closeModalFunc(); // Close modal without handling form submission
        setAudienceName(''); // Reset audienceName state
        setErrors({});
        setSmartGroupFilter([]);
    };

    const isValidAudienc = () => {
        let error: AudienceValidationError = {};
        let isValid = true;
    
        // Validate filters based on condition
        const hasValidFilters = smartGroupFilter.every(filter => {
            // Ensure column and condition are non-empty
            if (!filter.column.length || filter.column === "Select Column" || !filter.condition.length || filter.condition === "Select Condition") {
                return false;
            }
    
            // Check for 'fromToDate' condition
            if (filter.condition === 'fromToDate') {
                if (Array.isArray(filter.value) && filter.value.length === 2) {
                    // Check if the start date is greater than the end date
                    if (new Date(filter.value[0]) > new Date(filter.value[1])) {
                        error['smartgroupFilter'] = "Start date cannot be greater than end date.";
                        isValid = false;
                        return false;
                    }
                    return filter.value.every(v => v.length); // Ensure both values are non-empty
                }
                return false;
            }
    
            // For other conditions, ensure the value is non-empty
            return filter.value.length > 0;
        });
    
        // Validate audience name
        if (audienceName === '' || audienceName.trim().length === 0) {
            isValid = false;
            error['audienceName'] = "Please enter group name";
        }
    
        // Validate filters
        if (!hasValidFilters) {
            isValid = false;
            if (!error['smartgroupFilter']) {
                error['smartgroupFilter'] = "Please enter the values";
            }
        }
    
        setErrors(error);
        return isValid;
    };
    
    const getConditionsForField = (index: number) => {
        const dataType = smartGroupFilter[index]?.fieldType || 'text';
        const selectedField = smartGroupFilter[index]?.column;
    
        // Check if the field is 'audienceIds' or any other specific fields that require inAndNotInConditions
        if (selectedField === 'audienceIds') {
            return inAndNotInConditions;
        }
    
        // Define field types
        const textFieldTypes = ['text', 'longText', 'url', 'email', 'linkedin', 'x', 'facebook', 'instagram', 'pinterest', 'phone'];
    
        // Check if the dataType is one of the text field types
        if (textFieldTypes.includes(dataType)) {
            // Reset the value field to blank
           
            return textFieldConditions;
        }
    
        switch (dataType) {
            case 'number':
                return numberFieldConditions;
            case 'list':
                return inAndNotInConditions;
            case 'boolean':
                return booleanFieldConditions;
            case 'date':
                return dateFieldConditions;
            case 'user':
                return inAndNotInConditionsForUser;
            default:
                return textFieldConditions;
        }
    };

    const fetchAllTheActiveUsers = async() => {
        try {
          const data = {
            searchText: searchTextForUsers
          }
          const response: any = await getActiveUsersForCompanyAPI(data)
          if(response.data.success === true){
            setUsersForUserCfDataType(response.data.users)
          } else {
            setUsersForUserCfDataType([])
            createNotification('error', 'Something went wrong')
          }
        } catch (error) {
          setUsersForUserCfDataType([])
          console.log('error', error);
        }
      };
    
    const renderValueField = (index: number, filter: any) => {
        const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
        const checkedIcon = <CheckBoxIcon fontSize="small" />;
        const selectedField = smartGroupFilter[index]?.column;
        const selectedCondition = smartGroupFilter[index]?.condition;
        const dataType = smartGroupFilter[index]?.fieldType || 'text';
        const hasValue = Array.isArray(filter.value) && filter.value.length > 0;
        if (dataType === 'list') {
            const customField = customFields.find(cf => `customFields.${cf._id}` === filter.column);
            const dropdownValues = customField ? customField.fieldData.dropdownValues : [];

            return (
                <Autocomplete
                    multiple
                    id={`autocomplete-${index}`} // Unique ID for each Autocomplete
                    options={Array.isArray(dropdownValues) ? dropdownValues : []} // Array null check
                    fullWidth
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    value={Array.isArray(filter.value) ? filter.value : []}
                    onChange={(event, newValue) => {
                        const syntheticEvent = {
                            target: {
                                value: newValue
                            }
                        };
                        handleValueChange(index, syntheticEvent);
                    }}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                color='primary'
                            />
                            {option}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" size='small' style={{ padding: 0 }} className='form-control select small text-field' placeholder={hasValue ? '' : "Select"} />
                    )}
                />
            );
        } else if (dataType === 'user') {
            return (
                <Autocomplete
                    multiple
                    id={`autocomplete-${index}`} // Unique ID for each Autocomplete
                    options={Array.isArray(usersForUserCfDataType) ? usersForUserCfDataType : []} // Array null check
                    fullWidth
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.email}
                    value={Array.isArray(filter.value) ? filter.value : []}
                    onChange={(event, newValue) => {
                        const syntheticEvent = {
                            target: {
                                value: newValue
                            }
                        };
                        handleValueChange(index, syntheticEvent);
                    }}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                color='primary'
                            />
                            {option.email}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" size='small' style={{ padding: 0 }} className='form-control select small text-field' placeholder={hasValue ? '' : "Select"}/>
                    )}
                />
            );
        }  else if (dataType === 'boolean') {
            return (
                <FormControl fullWidth size="small">
                    <Select
                         value={filter.value }
                        onChange={(event: SelectChangeEvent<string>) => handleValueChange(index, event as any)}
                        renderValue={(selected) =>
                            selected === 'true' ? 'True' : 'False'
                        }
    
                    >
                        <MenuItem value="true">True</MenuItem>
                        <MenuItem value="false">False</MenuItem>
                    </Select>
                </FormControl>
            );
        } else if (dataType === 'date') {
            if (selectedCondition === "beforeDate") {
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <input
                                type="date"
                                className="form-control small"
                                name="start-date"
                                value={filter.value || ''}
                                onChange={(event) => handleValueChange(index, event, 'beforeDate')}
                            />
                        </Grid>

                    </Grid>
                );
            } else if (selectedCondition === "afterDate") {
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <input
                                type="date"
                                className="form-control small"
                                name="afterDate"
                                value={filter.value || ''}
                                onChange={(event) => handleValueChange(index, event, 'afterDate')}
                            />
                        </Grid>

                    </Grid>
                );
            } else {
                if (!Array.isArray(filter.value)) {
                filter.value = ['', '']; // Initialize as an array if not already
            }
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <input
                                type="date"
                                className="form-control small"
                                name="start-date"
                                value={filter.value?.[0] || ''}
                                onChange={(event) => handleValueChange(index, event, 'fromToDate1')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <input
                                type="date"
                                className="form-control small"
                                name="end-date"
                                value={filter.value?.[1] || ''}
                                onChange={(event) => handleValueChange(index, event, 'fromToDate2')}
                            />
                        </Grid>
                    </Grid>
                );
            }
        
    } else if (selectedField === 'audienceIds') {
        return (
        <Autocomplete
        multiple
        id={`autocomplete-${index}`} // Unique ID for each Autocomplete
        options={Array.isArray(audienceList) ? audienceList : []} // Use audienceList as the options source
        fullWidth
        disableCloseOnSelect
        getOptionLabel={(option) => option.audienceName} // Display audienceName
        value={audienceList.filter(audience => filter.value.includes(audience.id))} // Show selected options
        onChange={(event, newValue) => {
            const selectedIds = newValue.map(audience => audience.id); // Map selected options to IDs
            handleValueChange(index, { target: { value: selectedIds } });
        }}
        renderOption={(option, { selected }) => (
            <React.Fragment>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    color='primary'
                />
                {option.audienceName}
            </React.Fragment>
        )}
        renderInput={(params) => (
            <TextField {...params} variant="outlined" size='small' style={{ padding: 0 }} className='form-control select small text-field' placeholder={hasValue ? '' : "Select"} />
        )}
    />
        
        );
    }

    return (
        <input
            type={dataType !== 'number' ? "text" : "number"}
            className="form-control small"
            placeholder="Enter value"
            name='value'
            value={filter.value}
            onChange={(event) => handleValueChange(index, event)}
        />
    );
    };

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={openModal}
                onClose={handleOnClose}
                classNames={{
                    overlay: '',
                    modal: 'Invite-User-Modal wider',
                }}
                center
            >
                <Grid component="form" container spacing={1.5} className="modal-wrapper">
                    <Grid item xs={12}>
                        <Box className="subsecondary">
                            {isEdit ? 'Edit Smart Group' : 'Add Smart Group'}
                        </Box>
                        <Box className="small-subtitle">
                            Add a smart group and apply conditions to quickly retrieve the respective contact list.
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="div" className="inputField">
                            <Box component="label">Title <span className='required'>*</span></Box>
                            <input
                                type="text"
                                className="form-control small"
                                name="audienceName"
                                value={audienceName}
                                onChange={handleNameChange}
                                maxLength={30}

                            />
                            <span className="error_mesage required">
                                {errors.audienceName}
                            </span>
                        </Box>
                    </Grid>

                    {Array.isArray(smartGroupFilter) && smartGroupFilter.map((filter, index) => (
                        <Grid item xs={12} key={index}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={7}>
                                            <Grid container spacing={1}>
                                                {index === 0 ? (
                                                    <Grid item xs={2} alignItems={'center'} display={'flex'}>
                                                        <Box className={'paragraph'} >
                                                            Where
                                                        </Box>
                                                    </Grid>
                                                ) :
                                                    <Grid item xs={2} alignItems={'center'} display={'flex'}>
                                                        <Box component="div" className="inputField" >
                                                            <FormControl fullWidth size="small">
                                                                <Select
                                                                    type="text"
                                                                    className="form-control select small"
                                                                    name="logicalOperator"
                                                                    MenuProps={MenuProps}
                                                                    value={filter.logicalOperator || ''}
                                                                    onChange={(event) => handlelogicalOperatorChange(index, event)}
                                                                    style={{ padding: '4px 0px' }}
                                                                >
                                                                    <MenuItem value="AND">And</MenuItem>
                                                                    <MenuItem value="OR">Or</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                }
                                                <Grid item xs={5}>
                                                    <Box component="div" className="inputField">
                                                        <FormControl fullWidth size="small">
                                                            <Select
                                                                type="text"
                                                                className="form-control select small"
                                                                name="column"
                                                                placeholder="Column"
                                                                MenuProps={MenuProps}
                                                                value={filter.column}
                                                                onChange={(event) => handleFieldChange(index, event)}
                                                                style={{ padding: '4px 8px' }}
                                                            >
                                                                 <MenuItem value="Select Column" disabled hidden>Select Column</MenuItem>
                                                                {combinedFields.map((column, columnIndex) => (
                                                                     <MenuItem 
                                                                     key={columnIndex} 
                                                                     value={column.field} 
                                                                     disabled={column.disabled} // Add the disabled property here
                                                                 >
                                                                        {column.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Box component="div" className="inputField">
                                                        <FormControl fullWidth size="small">
                                                            <Select
                                                                type="text"
                                                                className="form-control select small"
                                                                name="condition" placeholder="Condition"
                                                                MenuProps={MenuProps}
                                                                value={filter.condition}
                                                                onChange={(event) => handleConditionChange(index, event)}
                                                                style={{ padding: '4px 8px' }}
                                                            >   <MenuItem value="Select Condition" disabled hidden>Select Condition</MenuItem>
                                                                {getConditionsForField(index).map((condition, conditionIndex) => (
                                                                    <MenuItem key={conditionIndex} value={condition.condition}>
                                                                        {condition.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Stack
                                                component="div"
                                                alignItems={'center'}
                                                direction={'row'}
                                                spacing={1}
                                                className="inputField"

                                            >
                                                <Box component="div" className="inputField" sx={{ width: '100%' }}>
                                                    {renderValueField(index, filter)}
                                                </Box>
                                                {index > 0 && (
                                                    <IconButton size="small" onClick={() => handleRemoveFilter(index)}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                )}
                                            </Stack>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                    <span className="error_mesage required">
                        {errors.smartgroupFilter}
                    </span>
                    <Grid item xs={12}>
                        <Button
                            variant="text"
                            color="primary"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={handleAddNewFilter}
                        >
                            Add New Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={1} justifyContent="end" direction="row" fullWidth>
                            <Button variant="outlined" color="primary"
                                onClick={handleOnClose}
                            >
                                Cancel
                            </Button>
                            <Button variant="contained" color="primary"
                                onClick={createAudience}
                            >
                                Save
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Modal>
        </ThemeProvider>
    );
};

export default SmartGroup;