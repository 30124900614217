import React, { Component } from 'react'
import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Link, withRouter } from 'react-router-dom'
import TamplateThum from '../../../../assets/images/tamplate-thum.png';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../../../Redux/Actions'
import * as qs from 'qs'
import { get, cloneDeep } from 'lodash'
import { Modal } from 'react-responsive-modal';
import Stack from '@mui/material/Stack';
import { getProjectIdFromUrl } from '../../../../helpers/common';

class EditCampaignEditor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cid: "",
            campaignDetails: {},
        }
    }

    componentDidMount() {
        const cid = qs.parse(get(this.props, 'location.search'), {
            ignoreQueryPrefix: true,
        })
        this.setState({
            cid
        }, () => this.getCampaign())

    }

    componentDidUpdate(prevProps) {
        if (this.props.campaigns.getCampaignSuccess && !prevProps.campaigns.getCampaignSuccess && this.props.campaigns.message !== prevProps.campaigns.message) {
            this.setState({
                campaignDetails: this.props.campaigns.campaignDetails[0]
            })
        }
    }

    getCampaign = () => {
        const eventId = getProjectIdFromUrl()
        this.props.getCampaign({ campaignId: this.state.cid.cid, eventId,  })
    }

    handleEditEmailButton = () => {
        const { campaignDetails } = this.state
        this.props.saveAllInfo("Draft")
        this.props.history.push({
            pathname: `/project-dashboard/campaign-template-edior/tid/${campaignDetails?._id}/cn/${campaignDetails?.campaignName}/eid/${campaignDetails?.eventId}`,
            search: `?cid=${campaignDetails._id}&fromEditEmailButton=true`,
            state: { template: campaignDetails, fromEditCampaignComponent: true, campaignName: this.props.campaigns.campaignNameInEditor }
        })
    }

    render() {
        const eventId = getProjectIdFromUrl()
        return (
            <Grid item xs={12} md={6} lg={4}>
                <Box sx={{ border: '1px solid #EDECF5', borderRadius: '8px', backgroundColor: '#fff', height: '100%', }}>
                    <Box px={3} py={1.8} sx={{ cursor: 'pointer', borderBottom: '1px solid #EDECF5', textAlign: 'right', textTransform: 'uppercase', color: '#5141E7' }} onClick={this.handleEditEmailButton}>
                    {this.props.users.languageStateForRedux?.buttons?.campaigns?.editEmail}
                    </Box>
                    <Box px={3} py={4}>
                        <div style={{ background: `url(${TamplateThum}) center no-repeat`, backgroundSize: 'cover', width: '100%', height: '500px', }}>
                            <iframe scrolling="no" className='page-preview' src={
                                    `/project-dashboard/preview-campaign/${eventId}?cid=${this.state.cid.cid}`
                            }></iframe>
                        </div>
                    </Box>
                </Box>
            </Grid >
        )
    }
}


const mapStateToProps = (state) => ({
    campaigns: state.Campaigns,
    events: state.Events,
    users: state.Users,
})

const mapActionsToProps = (dispatch) =>
    bindActionCreators(
        {
            getCampaign: Actions.getCampaignRequest,
        },
        dispatch,
    )

export default connect(mapStateToProps, mapActionsToProps)(withRouter(EditCampaignEditor))
