import { Constants } from "Redux/Actions";
import { createReducer } from "reduxsauce";

export type OpportunityStates_State = {
    isLoading: boolean,
    opportunityStates: OpportunityStates,
    error: boolean,
    loading: boolean,
    createOpportunityStateSuccess: boolean,
    message: '',
    deleteOpportunityStateSuccess: boolean,
    updateOpportunityStateSuccess: boolean,
    updatedOpportunityState: OpportunityState,
    fetchAllOpportunityStateSuccess: boolean,
    updateOpportunityStatesOrderSuccess: boolean
};

export type OpportunityStates = OpportunityState[];

export type OpportunityState = {
    _id?: string,
    name: string,
    stateType: StateType,
    description?: string,
    orderIndex?: number
};

export type StateType = "Draft"|
            "Initial"|
            "Qualification"|
            "Contact"|
            "Engagement"|
            "Proposal"|
            "Negotiation"|
            "Approval"|
            "Closed-Won"|
            "Closed-Lost"|
            "Closed-No Decision"|
            "Hold"|
            "Review"|
            "Follow-Up"|
            "Implementation"|
            "Custom";


const INITIAL_STATE: OpportunityStates_State = {
    isLoading: false,
    opportunityStates: [],
    error: false,
    loading: false,
    createOpportunityStateSuccess: false,
    message: '',
    deleteOpportunityStateSuccess: false,
    updateOpportunityStateSuccess: false,
    updatedOpportunityState: {} as OpportunityState,
    fetchAllOpportunityStateSuccess: false,
    updateOpportunityStatesOrderSuccess: false,
};

const createOpportunityStateRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        createOpportunityStateSuccess: false,
        error: false,
        loading: true,
        message: '',
    };
};
const createOpportunityStateSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        createOpportunityStateSuccess: true,
        error: false,
        loading: false,
        opportunityStates: action.opportunityStates,
        message: action.message,
    };
};
const createOpportunityStateError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        createOpportunityStateSuccess: false,
        error: true,
        loading: false,
        message: action.message
    };
};

const deleteOpportunityStateRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        deleteOpportunityStateSuccess: false,
        error: false,
        loading: true,
        message: '',
    };
};
const deleteOpportunityStateSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        deleteOpportunityStateSuccess: true,
        error: false,
        loading: false,
        opportunityStates: action.opportunityStates,
        message: action.message,
    };
};
const deleteOpportunityStateError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        deleteOpportunityStateSuccess: false,
        error: true,
        loading: false,
        message: action.message
    };
};

const updateOpportunityStateRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        updateOpportunityStateSuccess: false,
        error: false,
        loading: true,
        message: '',
    };
};
const updateOpportunityStateSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        updateOpportunityStateSuccess: true,
        error: false,
        loading: false,
        opportunityStates: action.opportunityStates,
        updatedOpportunityState: action.updatedOpportynityState
    };
};
const updateOpportunityStateError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        updateOpportunityStateSuccess: false,
        error: true,
        loading: false,
        message: action.message
    };
};

const fetchAllOpportunityStateRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        fetchAllOpportunityStateSuccess: false,
        error: false,
        loading: true,
        message: '',
    };
};
const fetchAllOpportunityStateSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        fetchAllOpportunityStateSuccess: true,
        error: false,
        loading: false,
        opportunityStates: action.opportunityStates,
    };
};
const fetchAllOpportunityStateError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        fetchAllOpportunityStateSuccess: false,
        error: true,
        loading: false,
        message: action.message
    };
};

const updateOpportunityStatesOrderRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        updateOpportunityStatesOrderSuccess: false,
        error: false,
        loading: true,
        message: '',
    };
};
const updateOpportunityStatesOrderSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        updateOpportunityStatesOrderSuccess: true,
        error: false,
        loading: false,
        message: action.message,
        opportunityStates: action.opportunityStates,
    };
};
const updateOpportunityStatesOrderError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        updateOpportunityStatesOrderSuccess: false,
        error: true,
        loading: false,
        message: action.message
    };
};

const HANDLERS = {
    [Constants.CREATE_OPPORTUNITY_STATE_REQUEST]: createOpportunityStateRequest,
    [Constants.CREATE_OPPORTUNITY_STATE_SUCCESS]: createOpportunityStateSuccess,
    [Constants.CREATE_OPPORTUNITY_STATE_ERROR]: createOpportunityStateError,
    [Constants.DELETE_OPPORTUNITY_STATE_REQUEST]: deleteOpportunityStateRequest,
    [Constants.DELETE_OPPORTUNITY_STATE_SUCCESS]: deleteOpportunityStateSuccess,
    [Constants.DELETE_OPPORTUNITY_STATE_ERROR]: deleteOpportunityStateError,
    [Constants.UPDATE_OPPORTUNITY_STATE_REQUEST]: updateOpportunityStateRequest,
    [Constants.UPDATE_OPPORTUNITY_STATE_SUCCESS]: updateOpportunityStateSuccess,
    [Constants.UPDATE_OPPORTUNITY_STATE_ERROR]: updateOpportunityStateError,
    [Constants.FETCH_ALL_OPPORTUNITY_STATE_REQUEST]: fetchAllOpportunityStateRequest,
    [Constants.FETCH_ALL_OPPORTUNITY_STATE_SUCCESS]: fetchAllOpportunityStateSuccess,
    [Constants.FETCH_ALL_OPPORTUNITY_STATE_ERROR]: fetchAllOpportunityStateError,
    [Constants.UPDATE_OPPORTUNITY_STATES_ORDER_REQUEST]: updateOpportunityStatesOrderRequest,
    [Constants.UPDATE_OPPORTUNITY_STATES_ORDER_SUCCESS]: updateOpportunityStatesOrderSuccess,
    [Constants.UPDATE_OPPORTUNITY_STATES_ORDER_ERROR]: updateOpportunityStatesOrderError,
};

export default createReducer(INITIAL_STATE, HANDLERS);