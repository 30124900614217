import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
    getServicePlanSuccess: false,
    error: false,
    servicePlanDetails: {},
    message: '',
    servicePlanId: '',
    allowedFromSuperadmin: true,
    projectLimitExceeded: false,
    findIfAllowedFromSuperadminSuccess: false,
    featuredAllowed: {},
    getBillingDataSuccess: false,
    billingDataModules: {},
    billingDataFeatures: [],
    servicePlanName: '',
    projectsBillingData: [],
    pricingPlanName: '',
    loadingServicePlan: false,
}


const findIfAllowedFromSuperadminRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        allowedFromSuperadmin: true,
        message: '',
        findIfAllowedFromSuperadminSuccess: false,
        projectLimitExceeded: false,
        servicePlanDetails: {},
        loadingServicePlan: true

    }
}

const findIfAllowedFromSuperadminSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        allowedFromSuperadmin: action.data,
        message: action.message,
        error: false,
        findIfAllowedFromSuperadminSuccess: true,
        projectLimitExceeded: action.projectLimitExceeded,
        featuredAllowed: action.featuredAllowed,
        servicePlanDetails: action.servicePlanDetails,
        loadingServicePlan: false
    }
}

const findIfAllowedFromSuperadminError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        allowedFromSuperadmin: false,
        error: true,
        message: action.error,
        findIfAllowedFromSuperadminSuccess: false,
        loadingServicePlan: false
    }
}

const findIfAllowedFromSuperadminOuterRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        allowedFromSuperadmin: true,
        message: '',
        findIfAllowedFromSuperadminSuccess: false,
        projectLimitExceeded: false,
        servicePlanDetails: {},
    }
}

const findIfAllowedFromSuperadminOuterSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        allowedFromSuperadmin: action.data,
        message: action.message,
        error: false,
        findIfAllowedFromSuperadminSuccess: true,
        projectLimitExceeded: action.projectLimitExceeded,
        featuredAllowed: action.featuredAllowed,
        servicePlanDetails: action.servicePlanDetails,
    }
}

const findIfAllowedFromSuperadminOuterError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        allowedFromSuperadmin: false,
        error: true,
        message: action.error,
        findIfAllowedFromSuperadminSuccess: false,
    }
}

const getBillingDataRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getBillingDataSuccess: false,
        billingDataModules: {},
        billingDataFeatures: [],
        message: '',
        error: false,
        servicePlanName: '',
    }
}
const getBillingDataSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getBillingDataSuccess: true,
        billingDataModules: action.modules,
        billingDataFeatures: action.features,
        message: action.message,
        error: false,
        servicePlanName: action.servicePlanName,
        pricingPlanName: action.pricingPlanName,
        servicePlanDetails: action.servicePlanDetails
    }
}

const getBillingDataError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getBillingDataSuccess: false,
        billingDataModules: {},
        billingDataFeatures: [],
        message: action.error,
        error: true,
    }
}
const getProjectsBillingDataRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getProjectsBillingDataSuccess: false,
        message: '',
        error: false,
        projectsBillingData: [],
        totalProjects: 0,
    }
}
const getProjectsBillingDataSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getProjectsBillingDataSuccess: true,
        message: action.message,
        error: false,
        projectsBillingData: action.projectsData,
        totalProjects: action.totalProjects
    }
}

const getProjectsBillingDataError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getProjectsBillingDataSuccess: false,
        message: action.error,
        error: true,
    }
}

const HANDLERS = {
    [Constants.FIND_IF_ALLOWED_FROM_SUPERADMIN_REQUEST]: findIfAllowedFromSuperadminRequest,
    [Constants.FIND_IF_ALLOWED_FROM_SUPERADMIN_SUCCESS]: findIfAllowedFromSuperadminSuccess,
    [Constants.FIND_IF_ALLOWED_FROM_SUPERADMIN_ERROR]: findIfAllowedFromSuperadminError,
    [Constants.FIND_IF_ALLOWED_FROM_SUPERADMIN_OUTER_REQUEST]: findIfAllowedFromSuperadminOuterRequest,
    [Constants.FIND_IF_ALLOWED_FROM_SUPERADMIN_OUTER_SUCCESS]: findIfAllowedFromSuperadminOuterSuccess,
    [Constants.FIND_IF_ALLOWED_FROM_SUPERADMIN_OUTER_ERROR]: findIfAllowedFromSuperadminOuterError,
    [Constants.GET_BILLING_DATA_REQUEST]: getBillingDataRequest,
    [Constants.GET_BILLING_DATA_SUCCESS]: getBillingDataSuccess,
    [Constants.GET_BILLING_DATA_ERROR]: getBillingDataError,
    [Constants.GET_PROJECTS_BILLING_DATA_REQUEST]: getProjectsBillingDataRequest,
    [Constants.GET_PROJECTS_BILLING_DATA_SUCCESS]: getProjectsBillingDataSuccess,
    [Constants.GET_PROJECTS_BILLING_DATA_ERROR]: getProjectsBillingDataError,
}

export default createReducer(INITIAL_STATE, HANDLERS)
