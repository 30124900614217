import React, { Component } from 'react'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import { Button } from '@material-ui/core'
import 'react-responsive-modal/styles.css'
import { testTemplateViaEmail } from '../../Redux/API'

class TestEmailModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      loading: false,
      open: this.props.importOpen ? this.props.importOpen : false,
      email: '',
      name: '',
    }
  }

  onOpenModal = () => {
    this.setState({ open: true })
  }

  onCloseModal = () => {
    this.setState({ open: false })
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  sendEmail = (e) => {
    e.preventDefault()
    const { eventId, templateId } = this.props
    const { name, email } = this.state
    const data = { to: email, name }
    this.handleLoading()
    testTemplateViaEmail(eventId, templateId, data)
      .then((result) => {
        createNotification('success', 'Email sent successfully')
        this.handleLoading()
        this.props.cb()
      })
      .catch(() => {
        createNotification('error', 'Oops! Something went wrong')
        this.handleLoading()
        this.props.cb()
      })
  }

  render() {
    const { loading, name, email } = this.state
    return (
      <div>
        <h2>Test Email</h2>
        <div className="rsvpEventfieldBox">
          <div className="formSection p-0">
            <form onSubmit={this.sendEmail}>
              <div className="field-group inputField fullWidthInput">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="field-group inputField fullWidthInput">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control emailLoweCase"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                />
              </div>
              <div className="editProfileBtn submitForm justify-content-center">
                <Button type="submit" className="themeBlueButton">
                  Sent Test Email
                </Button>
              </div>
            </form>
          </div>
        </div>
        <NotificationContainer />
        {loading ? <Loader /> : null}
      </div>
    )
  }
}

export default TestEmailModal
