import React from 'react'
import StepHeadingIcon from '../../assets/images/icons/step2-icom.svg';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@material-ui/core/Card';
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import noResult from '../../assets/images/icons/noResult.svg';
import { Modal } from 'react-responsive-modal';
import ProjectTemplatePreview from '../ProjectDashboard/ManagePage/ProjectTemplatePreview.js';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'Redux/Actions';
import Loader from 'Components/Loader/loader';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '../../assets/images/icons/search.svg';

const URL = process.env.REACT_APP_API_URL;

function CreateProjectStep2(props) {
  const [projectTemplates, setProjectTemplates] = React.useState([])
  const [selectedPreviewTemplate, setSelectedPreviewTemplate] = React.useState()
  const [searchText, setSearchText] = React.useState('')
  const [selectedTemp, setSelectedTemp] = React.useState('')
  const [openTemplate, setOpenTemplate] = React.useState(false);
  const [IsSearchedText, setIsSearchedText] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleTemplateOpen = (projectTemplate) => {
    setOpenTemplate(true);
    setSelectedPreviewTemplate(projectTemplate)
  };

  const handleTemplateClose = () => {
    setOpenTemplate(false);
  };


  const handleProjectTemplate = async(params) => {
    setSelectedTemp(params._id)
    await props.projectTemplateParams(params);
    props.handleDoContinue(true)
  }

  const UserData = async () => {
    return JSON.parse(localStorage.getItem('user_details'))
  } 

  React.useEffect(async() => {
    props.handleDoContinue(false)
    setLoading(true)
    async function getProjectTemplates() {
      const users = await UserData()
      const response = await axios.get(`${URL}/getProjectTypeTemplates?ProjectTypeId=${props.projectTypeTemplates}&searchText=${searchText}`, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      },);
      return response.data.templates;
    }
    const getTemplates = await getProjectTemplates();
    setProjectTemplates(getTemplates);
    setLoading(false)
  }, []);

  const handleChange = (e) => {
    setSearchText(e.target.value)
    setIsSearchedText(true)
  }

  const handleSearch = async (e) => {
    e.preventDefault();
    async function getProjectTemplates() {
      const users = await UserData()
    
      const response = await axios.get(`${URL}/getProjectTypeTemplates?ProjectTypeId=${props.projectTypeTemplates}&searchText=${searchText}`, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data.templates;
    }
    const getTemplates = await getProjectTemplates();
    setProjectTemplates(getTemplates);
  }


  const responsive = {
    all: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
      slidesToSlide: 1
    },
  }

  return (
    <Box px={{xs:(2), lg:(3)}} className="step2-outer stepper-outer-common" >
      <div className="step1-heading">
        <h2 className="subsecondary"> {props.users.languageStateForRedux?.newProject?.headingTwo} </h2>
      </div>
      <div className={(selectedTemp === 'blank' ? 'active ' : '') + 'step2-heading-block'} onClick={() => handleProjectTemplate("blank")}>
        <img src={StepHeadingIcon} />
        <h5 className="paragraph"> {props.users.languageStateForRedux?.newProject?.blank} </h5>
        <p> {props.users.languageStateForRedux?.newProject?.subhead} </p>
      </div>
      {
        projectTemplates?.length > 0 &&
        <div className="step2-search-block">
          {/* <div className="step2-search-select">
            <Select native>
              <option>Ten</option>
              <option>Twenty</option>
              <option>Thirty</option>
            </Select> 
          </div >*/}
          <div className="step2-search-heading">
            <h2 className="subsecondary"> Select the Project Template You Love </h2>
          </div>
          <div className="step2-search-input">
            <form onSubmit={(e) => handleSearch(e)}>
              <TextField 
              placeholder="Search" 
              name="searchText" 
              value={searchText} 
              size="small" 
              onChange={(e) => handleChange(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="" src={SearchIcon} />
                  </InputAdornment>
                  ),
                }}
               />
            </form>
          </div>
        </div>
      }

      {
        IsSearchedText && projectTemplates?.length < 1 &&
        <div className="step2-search-block">
          <div className="step2-search-heading">
            <h2 className="subsecondary"> Select the Project Template You Love </h2>
          </div>
          <div className="step2-search-input">
            <form onSubmit={(e) => handleSearch(e)}>
              <TextField 
                placeholder="Search"
                name="searchText" 
                value={searchText} 
                size="small" 
                onChange={(e) => handleChange(e)}
                InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="" src={SearchIcon} />
                  </InputAdornment>
                  ),
                }}
               />
            </form>
          </div>
        </div>
      }


      <Grid container spacing={3}>
        {
          projectTemplates?.length > 0 &&
          projectTemplates.map((projectTemplate, index) => {
            let myTags = '';
            if (projectTemplate.Tags) {
              myTags = projectTemplate.Tags.split(',');
            }
            return (
              <Grid item xs={12} sm={6} md={4} key={index} >
                <Grid component={Card} container elevation={0} justifyContent={'space-between'} sx={{ height: '100%' }} className={(selectedTemp === projectTemplate._id ? 'useTempActive' : '') + " tamplate-wrapper"}>
                  <Grid item xs={12}>
                    <Box className="thumnail-placeholder" sx={{ minHeight: '180px', maxHeight: '180px' }}>

                      <Carousel
                        responsive={responsive}
                        containerClass="template-slider"
                        infinite={false}
                        centerMode={false}
                      >
                        {
                          projectTemplate?.pages.filter(el => el.projectPageThumb !== "" && el.projectPageThumb !== undefined && el.projectPageThumb !== null).map((page, i) => {
                            return (
                              <Box key={i} sx={{ maxHeight: '180px', minHeight: '180px', width: '100%', textAlign: 'center', }}>
                                {page.projectPageThumb !== "" && page.projectPageThumb !== undefined && <img alt="thumbnail" style={{ width: '100%', height: '180px' }} src={`${process.env.REACT_APP_IMAGE_URL}/thumbnails/${page.projectPageThumb}`} />}
                              </Box>
                            )
                          })
                        }
                      </Carousel>
                    </Box>

                    <Box p={2} pb={0}>
                      <Box className="paragraph bold" mb={0.5}> {projectTemplate?.projectDetails ? projectTemplate?.projectTemplateTitle : projectTemplate.eventName} </Box>
                      <Box mb={1.5}>
                        {
                          projectTemplate?.eventTags && Array.isArray(projectTemplate?.eventTags) && projectTemplate?.eventTags?.map(
                            tag => (
                              tag ? <span className='small-subtitle secondary-text'>#{`${tag} `} </span> : ''
                            )
                          )

                        }

                      </Box>

                      <Box mb={2.5} className='small-subtitle tamplate-description'>{ReactHtmlParser(projectTemplate.description)}</Box>

                    </Box>
                  </Grid>
                  <Grid item xs={12} alignSelf='flex-end'>
                    <Box px={2} pb={3}>
                      <Stack spacing={2} direction={'row'}>
                        <Button fullWidth variant="outlined" color="primary"
                          onClick={() => handleTemplateOpen(projectTemplate)}
                        >
                          Preview
                        </Button>

                        <Button fullWidth variant="contained" color="primary" onClick={() => handleProjectTemplate(projectTemplate)}>
                          Use Template
                        </Button>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )
          })
        }

        {
          IsSearchedText && projectTemplates?.length < 1 &&
          <Grid item xs={12} sm={12} md={12} key={1} >
            <Stack alignItems={'center'}>
              <Box sx={{ width: '170px' }} mb={3} component={'img'} src={noResult} />
              <Box textAlign={'center'} className='subtitle'>
                There are no records to display
              </Box>
            </Stack>
          </Grid>
        }
      </Grid>

      <Modal
        open={openTemplate}
        onClose={handleTemplateClose}
        classNames={{
          overlay: 'full-screen_overlay',
          modal: 'full-screen_modal modal-default-close-none',
        }}
        center
      >
        <Box sx={{ width: '100%', background: '#ffffff', }}>
          <ProjectTemplatePreview template={selectedPreviewTemplate} sentFrom="selectProjectTemplates"
            templateEventId={selectedPreviewTemplate?._id} handleTemplateClose={handleTemplateClose}
            handleProjectTemplate={handleProjectTemplate}
          />
        </Box>

      </Modal>
      {loading ? <Loader /> : null}
    </Box >
  )
}

const mapStateToProps = state => ({
  users: state.Users
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      handleDoContinue: Actions.handleDoContinueState,
    },
    dispatch,
  )
export default connect(mapStateToProps,mapActionsToProps)(CreateProjectStep2)

