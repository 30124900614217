import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    getApiKeyRequest: ['data'],
    getApiKeySuccess: ['message', 'data'],
    getApiKeyError: ['error'],

    generateApiKeyRequest: ['data'],
    generateApiKeySuccess: ['message', 'data'],
    generateApiKeyError: ['error'],
})

export const Constants = Types
export default Creators