import { Button, IconButton } from '@material-ui/core';
import { Stack, Grid, Box } from '@mui/material';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {
  Account,
  AccountsState,
  ContactCf,
  Project,
} from 'Redux/Reducers/opportunity/accounts.reducer';
import { SyntheticEvent, useEffect, useState } from 'react';
import {
  CustomField,
  CustomFields,
  CustomFields_State,
} from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { useSelector } from 'react-redux';
import { formatDateField, separateByComma } from 'helpers/common';
import OpportunityContactsAPI from 'Redux/API/opportunity/opportunityContactsAPI';
import { Contact } from 'Redux/Reducers/opportunity/contacts.reducer';
import OpportunityAccountsCustomFieldsAPI from 'Redux/API/opportunity/opportunityCustomFieldsAPI';
import Loader from 'Components/Loader/loader';
import ContactOrAccountDetailsSideModal from './ContactOrAccountDetailsSideModal';
import ReadMore from '../../../assets/images/icons/informationIcon.svg';
import { getSystemLevelPermissions } from '../../../helpers/common';
import { createNotification } from '../../../helpers';
import moment from 'moment';

type Props = {
  closeViewModal: () => void;
  accountToBeEdited: Account | undefined;
  setShowAddNew: React.Dispatch<React.SetStateAction<boolean>>;
  setShowViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleTabChange: (
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    searchText: string,
  ) => void;
};

function AccountView({
  closeViewModal,
  accountToBeEdited,
  setShowAddNew,
  setShowViewModal,
  handleTabChange,
}: Props) {
  const { isLoading: isAccountsLoading } = useSelector(
    (state: any) => state.OpportunityAccounts,
  ) as AccountsState;

  const { customFields, isLoading: isCustomFieldsLoading } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;
  const [openSideModal, setOpenSideModal] = useState(false);
  const [allowed, setAllowed] = useState<any>({});
  const [titleForSideModal, setTitleForSideModal] = useState<string>('');
  const [contactDetailsForToolTip, setContactDetailsForToolTip] =
    useState<Contact>({} as Contact);
  const [contactsCustomFields, setContactsCustomFields] =
    useState<CustomFields>({} as CustomFields);

  useEffect(() => {
    setAllowed(getSystemLevelPermissions());
    fetchContactsCustomField();
  }, []);

  const fetchContactsCustomField = async () => {
    try {
      const opportunityAccountsCustomFieldsAPI =
        new OpportunityAccountsCustomFieldsAPI();
      const data = {
        customFieldType: 'contacts',
        from: 'viewAccount',
      };
      const res =
        await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
          data,
        );
      setContactsCustomFields(res.data.customFields);
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowSideModal = (contactId: string, title: string) => {
    setTitleForSideModal(title);
    setOpenSideModal(true);
    fetchContactById(contactId);
  };

  const fetchContactById = async (contactId: string) => {
    try {
      const opportunityContactsAPI = new OpportunityContactsAPI();
      const res = await opportunityContactsAPI.fetchOpportunityContact({
        id: contactId,
      });
      if (res.data?.contact) {
        setContactDetailsForToolTip(res.data.contact);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseSideModal = () => {
    setOpenSideModal(false);
  };

  const customFieldValue = (
    cf: CustomField,
    accountToBeEdited: Account | undefined,
  ) => {
    if (cf.fieldData.fieldType === 'contact') {
      return (
        accountToBeEdited?.customFieldsWithProperValues?.[cf._id as string] ||
        ''
      );
    } else if (cf.fieldData.fieldType === 'boolean') {
      return accountToBeEdited?.customFieldsWithProperValues?.[cf._id as string]?.toString() === "true"? "True": "False"
    } else if (cf.fieldData.fieldType === 'user') {
      return   (accountToBeEdited?.customFieldsWithProperValues?.[cf._id as string] as string[] || [])?.map((value: string) => <span 
        className={`long-text ${cf.fieldData.fieldType === "user" && "link"}`}
        onClick={()=> {
          if(cf.fieldData.fieldType === "user" && accountToBeEdited?.customFields?.[
            cf._id as string
          ]){
            window.open(`mailto:${value}`)
          }
        }}
        style={{display: "block"}}
      >{value}</span>)
    }
    
    else {
      return cf.fieldData.fieldType === 'date' 
        ? formatDateField(accountToBeEdited,cf,customFields)
        : accountToBeEdited?.customFieldsWithProperValues?.[cf._id as string] ||
            '';
    }
  };

  const showRestOfProjects = (projects: Project[]) => {
    let projectsLength = projects.length;
    return projects.slice(1, projectsLength);
  };

  return (
    <>
      <Box className="Opportunity-Account">
        {/* @ts-ignore */}
        <Grid container spacing={2} mb={3} className="top-filter-area">
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton size="small" onClick={closeViewModal}>
                <KeyboardBackspaceIcon />
              </IconButton>
              <Box className="paragraph bold">Account View</Box>
            </Stack>
          </Grid>
          <Grid item>
            {/* @ts-ignore */}
            <Grid container spacing={1.25} className="team-dash-right">
              <Grid item>
                {allowed['editAccount'] && (
                  <Button
                    onClick={() => {
                      setShowAddNew(true);
                      setShowViewModal(false);
                    }}
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box className="account-details">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4}>
              <Grid container>
                <Grid item xs={6} md={4} lg={5} xl={3}>
                  <Box className="small-subtitle bold primary-text">
                    Account Name <span className="required">*</span>:
                  </Box>
                </Grid>
                <Grid item xs={6} md={8} lg={7} xl={9}>
                  <Box className="small-subtitle primary-text">
                    {accountToBeEdited?.name}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Grid container>
                <Grid item xs={6} md={4} lg={5} xl={3}>
                  <Box className="small-subtitle bold primary-text">
                    Projects:
                  </Box>
                </Grid>
                <Grid item xs={6} md={8} lg={7} xl={9}>
                  <Stack direction="row" alignItems={'center'} spacing={1}>
                    <span className="long-text" style={{ maxWidth: '80%' }}>
                      {' '}
                      {accountToBeEdited?.projects &&
                      accountToBeEdited?.projects?.length > 0
                        ? accountToBeEdited?.projects[0]?.eventName
                        : ''}
                    </span>
                    {accountToBeEdited?.projects &&
                      accountToBeEdited?.projects?.length > 1 && (
                        <div className="detail-popup">
                          <span className="count">
                            +{accountToBeEdited?.projects.length - 1}
                          </span>
                          <div className="info">
                            {showRestOfProjects(
                              accountToBeEdited?.projects,
                            ).map((project: Project, i: number) => {
                              return (
                                <div key={i}>
                                  <p>{project.eventName}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Grid container>
                <Grid item xs={6} md={4} lg={5} xl={3}>
                  <Box className="small-subtitle bold primary-text">
                    Source:
                  </Box>
                </Grid>
                <Grid item xs={6} md={8} lg={7} xl={9}>
                  <Box className="small-subtitle primary-text">
                    {accountToBeEdited?.source === 'aiAgent'
                      ? 'AI Agent'
                      : accountToBeEdited?.source === 'imported'
                      ? 'Imported'
                      : 'Manually Added'}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {customFields.map((cf: CustomField) => {
              return (
                <Grid item xs={12} key={cf._id}
                sm={
                  cf.fieldData.fieldType === 'longText' ? 12 : 6
                } 
                  lg={
                    cf.fieldData.fieldType === 'longText' ? 12 : 4
                  } 
                >
                  <Grid container>
                    <Grid item
                    xs={
                      cf.fieldData.fieldType === 'longText' ? 12 : 6
                    }  
                      md={
                        cf.fieldData.fieldType === 'longText' ? 12 : 4
                      } 
                      lg={
                        cf.fieldData.fieldType === 'longText' ? 12 : 5
                      } 
                        xl={
                          cf.fieldData.fieldType === 'longText' ? 12 : 3
                        }
                    >
                      <Box className="small-subtitle bold primary-text">
                        {cf.fieldData.fieldName}:
                      </Box>
                    </Grid>
                    <Grid item 
                    xs={
                      cf.fieldData.fieldType === 'longText' ? 12 : 6
                    }  
                      md={
                        cf.fieldData.fieldType === 'longText' ? 12 : 8
                      } 
                        lg={
                          cf.fieldData.fieldType === 'longText' ? 12 : 7
                        }
                          xl={
                            cf.fieldData.fieldType === 'longText' ? 12 : 9
                          }
                    >
                      <Stack direction={'row'} spacing={1}>
                        <Box
                          className={`small-subtitle primary-text  ${
                            ['contact', 'url', 'email', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin', 'phone'].includes(cf.fieldData.fieldType) ? 'link' : ''
                          }
                          ${
                            cf.fieldData.fieldType === 'longText' ? '' : ''
                          }`}
                          onClick={(e: SyntheticEvent<Element, Event>) => {
                            if(cf.fieldData.fieldType === 'contact'){
                              // if (allowed['viewContacts']) {
                              //   handleTabChange(
                              //     e,
                              //     '3',
                              //     accountToBeEdited
                              //       ?.customFieldsWithProperValues?.[
                              //       cf._id as string
                              //     ] as string,
                              //   );
                              // } else {
                              //   createNotification(
                              //     'error',
                              //     "Sorry! You don't have permission to view this contact",
                              //   );
                              // }
                              handleShowSideModal(
                                accountToBeEdited?.customFields?.[
                                  cf._id as string
                                ] as string,
                                cf.fieldData.fieldName,
                              )
                            }
                            if(['url', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin'].includes(cf.fieldData.fieldType)) {
                              let url = (accountToBeEdited
                                ?.customFieldsWithProperValues?.[
                                cf._id as string
                              ] as string)
                              if (!/^https?:\/\//i.test(url)) {
                                // If not, prepend 'https://'
                                url ='https://' + url;
                              }
                              window.open(url, "_blank")
                            }
                            if(cf.fieldData.fieldType === 'email'){
                              window.open(`mailto:${accountToBeEdited
                                ?.customFieldsWithProperValues?.[
                                cf._id as string
                              ] as string}`)
                            } else if(cf.fieldData.fieldType === 'phone'){
                              window.open(`tel:${accountToBeEdited
                                ?.customFieldsWithProperValues?.[
                                cf._id as string
                              ] as string}`)
                            }
                            
                          }}
                        >
                          {/* @ts-ignore */}
                          {customFieldValue(cf, accountToBeEdited)}
                        </Box>
                        {/* {cf.fieldData.fieldType === 'contact' &&
                          accountToBeEdited?.customFieldsWithProperValues?.[
                            cf._id as string
                          ] && (
                            <Box
                              className="link"
                              onClick={() =>
                                handleShowSideModal(
                                  accountToBeEdited?.customFields?.[
                                    cf._id as string
                                  ] as string,
                                  cf.fieldData.fieldName,
                                )
                              }
                            >
                              <img src={ReadMore} />
                            </Box>
                          )} */}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>

          <ContactOrAccountDetailsSideModal
            open={openSideModal}
            handleClose={handleCloseSideModal}
            details={contactDetailsForToolTip}
            customFields={contactsCustomFields}
            usedFor={'contact'}
            title={titleForSideModal}
          />
        </Box>
      </Box>
      {(isCustomFieldsLoading || isAccountsLoading) && <Loader />}
    </>
  );
}

export default AccountView;
