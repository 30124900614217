import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({

    createFolderRequest:['data'],
    createFolderSuccess: ['message','alreadyExist'],
    createFolderError: ['error'],
    renameFolderRequest:['data'],
    renameFolderError:['error'],
    renameFolderSuccess:['message'],
    deleteFolderRequest:['data'],
    deleteFolderError:['error'],
    deleteFolderSuccess:['message'],
    addImagesRequest:['data'],
    addImagesError: ['error'],
    addImagesSuccess: ['message'],
    addDocumentRequest:['data'],
    addDocumentError: ['error'],
    addDocumentSuccess: ['message','addDocumentSuccess'],
    getFolderListRequest: ['data'],
    getFolderListError: ['error'],
    getFolderListSuccess: ['message','foldersList','filesList' , 'breadCrumbs','foldersCount','filesCount'],
    uploadVideoRequest:['data'],
    uploadVideoError: ['error'],
    uploadVideoSuccess: ['message'],
    downloadFileRequest:['data'],
    downloadFileError: ['error'],
    downloadFileSuccess: ['message','url'],
})


export const Constants = Types
export default Creators