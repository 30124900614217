const getUnLayerConfig = (apiUrl, eventId, type, templateId) => {
  const path = type === 'Login' ? 'login' : 'registerUser'
  const enabled = false //type !== 'Meeting';
  return {
    id: 'pageDesignEditor',
    displayMode: 'web',
    tools: {
      form: {
        enabled,
        properties: {
          action: {
            editor: {
              data: {
                actions: [
                  {
                    label: 'form',
                    method: 'POST',
                    url: `${apiUrl}/events/${eventId}/${path}`,
                  },
                ],
              },
            },
          },
        },
      },
    },
    specialLinks: [
      {
        name: 'Transactional email view',
        href: `https://stanford.illumeetxp.com/templateView/${templateId}?type=registrationTransactionalEmail`,
        target: '_blank',
      },
    ],
  }
}

export default getUnLayerConfig
