import { ChangeEvent, useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { IconButton } from '@material-ui/core';
import HorizontalTab from '@mui/material/Tab';
import HorizontalTabContext from '@mui/lab/TabContext';
import HorizontalTabList from '@mui/lab/TabList';
import HorizontalTabPanel from '@mui/lab/TabPanel';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import QA from './TabsGeneral/Q&A/QA';
import URL from './TabsGeneral/URL/URL';
import File from './TabsGeneral/Files/File';
import CustomInfo from './TabsGeneral/CustomInfo/CustomInfo';
import ProjectAccess from './TabsGeneral/ProjectAccess/ProjectAccess';
import Settings from './TabsGeneral/Settings/Settings';
import Sources from './Sources/Sources';
import { theme } from '../../../../theme/theme';
import '../../../../sass/main.scss';
import EditGroup from '../../../../../src/assets/images/icons/edit.svg';
import copyKey from '../../../../../src/assets/images/icons/copyKey.svg';
import {
  AIChatbotState,
  ChatBot,
  chatBotInitialState,
  ContextFile,
  FAQ,
  ProjectId,
  settingsInitialState,
} from 'Redux/Reducers/aiChatbot.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { AllEventsForAiAgent, UserData } from 'Redux/API';
import { Actions } from 'Redux/Actions';
import { useParams, useHistory } from 'react-router-dom';
import { createNotification } from 'helpers';
import AIChatbotAPI from 'Redux/API/aiChatbotAPI';
import axios from 'axios';
const REQUEST_URL = process.env.REACT_APP_API_URL;

type SxProps = {
  background: string;
  maxHeight: string;
  overflow: string;
  backgroundColor: string;
  boxShadow: string;
  borderRadius: string;
  marginTop: string;
};

const styles = {
  customTabList: {},
  customTab: {
    minHeight: 42,
    // width: 150,
    padding: '12px 16px',
    color: '#323338',
    fontWeight: 400,
  },
  customTabPanel: {},
};

export type ProjectsList = Project[];
type Project = {
  _id: string;
  eventName: string;
  assignedToChatbot?: string;
};

export type ErrorType = {
  agentName?: string;
  nameCharExceeded?: string;
  contextSettingsDocumentSize?: string;
  contextSettingsDocumentCount?: string;
  contextSettingsOverlap?: string;
  exampleSettingsDocumentSize?: string;
  exampleSettingsDocumentCount?: string;
  exampleSettingsOverlap?: string;
};

type GeneralDashboardProps = {
  userId: string;
  agentIdFromParent: string | undefined;
  searchText: string;
};

function GeneralDashboard({
  userId,
  agentIdFromParent,
  searchText,
}: GeneralDashboardProps) {
  const isInitialRender = useRef(true);
  const isInitialRender2 = useRef(true);
  const isInitialRender3 = useRef(true);
  const filePath = `aiChatbotTemp/${userId}`;
  const {
    chatBot,
    getAiAgentGeneralDataSuccess,
    createOrUpdateAiAgentGeneralDataSuccess,
    page_no,
    limit,
    trainAgentSuccess,
  } = useSelector((store: any) => store.AIChatbot) as AIChatbotState;
  const params: {
    id: string;
  } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  // const [dispatch] = useReducer(AIChatbot, INITIAL_STATE);
  const [editAIAgentName, setEditAIAgentName] = useState<boolean>(false);
  const [value, setValue] = useState<string>('1');
  const [projectsList, setProjectsList] = useState<ProjectsList>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  // let [state, setState] = useState<ChatBot>();
  const [agentId, setAgentId] = useState<string | undefined>('');
  const [errors, setErrors] = useState<ErrorType>({} as ErrorType);
  const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] = useState(false)

  useEffect(() => {
    getProjectList();
    // if (agentIdFromParent) {
    if (params.id) {
      // if existing chat widget is being edited
      setAgentId(params.id);
      getAiAgentGeneralData();
      setIsEditing(true);
    }
    // else {
    //   // if a new chat widget is being created
    //   // dispatch(Actions.resetChatBotState())

    //   dispatch({
    //     agent: {
    //       name: '',
    //       company: '',
    //       role: '',
    //     },
    //     contextFiles: [],
    //     context: '',
    //     FAQs: [],
    //     contextUrls: [],
    //     projectIds: [],
    //     isDefault: false,
    //     userId: '',
    //     _id: params.id,
    //     isEnabled: false,
    //     updatedAt: new Date(),
    //     settings: {
    //       model: {
    //         model: 'mistral',
    //         lastTrainedAt: null,
    //         basePrompt: '',
    //         temperature: 66,
    //         exampleSettings: {
    //           documentCount: 1,
    //           documentSize: 500,
    //           overlap: 150,
    //           examplesLimit: {
    //             active: false,
    //             negative: 0,
    //             positive: 0,
    //             total: 0,
    //           },
    //         },
    //         contextSettings: {
    //           documentCount: 1,
    //           documentSize: 2000,
    //           overlap: 150,
    //         },
    //       },
    //       opportunity: { customFieldsArray: [], title: '' },
    //       collectOpportunityData: true,
    //       security: {
    //         visibility: 'Public',
    //         allowIFrameAndWidgetOnSpecificDomain: false,
    //         rateLimiting: {
    //           limitTo: 20,
    //           messageInterval: 25,
    //         },
    //         messageToShowLimitHit: '',
    //       },
    //     },
    //     isTrained: true,
    //   });
    //   setEditAIAgentName(true);
    // }
  }, []);

  useEffect(() => {
    if (isInitialRender2.current) {
      isInitialRender2.current = false;
    } else {
      if (trainAgentSuccess) {
        getAiAgentGeneralData();
      }
    }
  }, [trainAgentSuccess]);

  useEffect(() => {
    // this useEffect will run when save button is clicked.
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      if (!agentIdFromParent || agentIdFromParent == '') {
        if (createOrUpdateAiAgentGeneralDataSuccess) {
          setAgentId(chatBot._id);
          //  dispatch(chatBot);
          setIsEditing(true);
          history.push(`/aichatbot/general/${chatBot._id}`);
        }
      }
    }
  }, [createOrUpdateAiAgentGeneralDataSuccess]);

  // useEffect(() => {
  //   // runs only when existing chat widget is being edited
  //   if (agentIdFromParent) {
  //     if (getAiAgentGeneralDataSuccess) {
  //       dispatch(chatBot);
  //     }
  //   }
  // }, [getAiAgentGeneralDataSuccess]);

  // useEffect(() => {
  //   if (isInitialRender3.current) {
  //     isInitialRender3.current = false;
  //   } else {
  //     // runs after chat widget is trained
  //     if (params.id && trainAgentSuccess) {
  //       if (getAiAgentGeneralDataSuccess) {
  //         dispatch(chatBot);
  //       }
  //     }
  //   }
  // }, [getAiAgentGeneralDataSuccess]);

  const getAiAgentGeneralData = (pageNo?: number, limit?: number) => {
    // runs only when existing chat widget is being edited
    const data = {
      id: params.id,
      pageNoForFAQs: pageNo || 1,
      limitForFAQs: limit || 10
    };
    dispatch(Actions.getAiAgentGeneralDataRequest(data));
  };

  const getProjectList = async () => {
    try {
      const result: any = await AllEventsForAiAgent();
      const projects: ProjectsList = result.data.events;
      setProjectsList(projects);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenEditAIAgentName = () => {
    setEditAIAgentName(true);
  };

  const isValid = () => {
    let isValid = true;
    let errorsObj: ErrorType = {} as ErrorType;
    if (!chatBot.agent.name || chatBot.agent.name.trim() === '') {
      isValid = false;
      errorsObj.agentName = 'AI agent name cannot be empty';
    }

    //In the old data model, the general settings are not included.
    //So if the user try to update the General tab fields need to inform the user to fill the settings
    // if (!chatBot.settings.model.contextSettings) {
    //   createNotification('error', 'Please fill the general settings');
    //   return false;
    // }
    const { contextSettings, exampleSettings } = chatBot.settings.model;
    if (
      isNaN(contextSettings.documentSize) ||
      contextSettings.documentSize < 500 ||
      contextSettings.documentSize > 2000
    ) {
      isValid = false;
      errorsObj.contextSettingsDocumentSize = 'Document size is not correct';
    }
    if (
      isNaN(contextSettings.documentCount) ||
      contextSettings.documentCount < 1 ||
      contextSettings.documentCount > 10
    ) {
      isValid = false;
      errorsObj.contextSettingsDocumentCount = 'Document count is not correct';
    }
    if (
      isNaN(contextSettings.overlap) ||
      contextSettings.overlap < 150 ||
      contextSettings.overlap > 500
    ) {
      isValid = false;
      errorsObj.contextSettingsOverlap = 'Overlap is not correct';
    }
    if (
      isNaN(exampleSettings.documentSize) ||
      exampleSettings.documentSize < 500 ||
      exampleSettings.documentSize > 2000
    ) {
      isValid = false;
      errorsObj.exampleSettingsDocumentSize = 'Document size is not correct';
    }
    if (
      isNaN(exampleSettings.documentCount) ||
      exampleSettings.documentCount < 1 ||
      exampleSettings.documentCount > 10
    ) {
      isValid = false;
      errorsObj.exampleSettingsDocumentCount = 'Document count is not correct';
    }
    if (
      isNaN(exampleSettings.overlap) ||
      exampleSettings.overlap < 150 ||
      exampleSettings.overlap > 500
    ) {
      isValid = false;
      errorsObj.exampleSettingsOverlap = 'Overlap is not correct';
    }
    setErrors(errorsObj);
    console.log(isValid);
    return isValid;
  };

  const handleCloseEditAIAgentName = () => {
    setEditAIAgentName(false);
  };

  const checkIfNameAiWidgetNameExists = async () => {
    const data = {
      agentName: chatBot.agent.name,
      chatbotId: agentId,
    };
    const aiChatbotApi = new AIChatbotAPI();
    let doesNameExist = await aiChatbotApi.checkIfNameAiWidgetNameExists(data);
    if (doesNameExist.data.success) {
      handleCloseEditAIAgentName();
    }
  };

  const handleTabChange = (value: string) => {
    setValue(value);
  };

  const handleAIAgentNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors({
      ...errors,
      nameCharExceeded: '',
    });
    if (e.target.value.length <= 60) {
      if (chatBot.agent.name !== '') {
        dispatch(
          Actions.updateChatbot({
            ...chatBot,
            agent: {
              ...chatBot.agent,
              name: e.target.value,
            },
          }),
        );
      } else {
        dispatch(
          Actions.updateChatbot({
            ...chatBot,
            agent: {
              ...chatBot.agent,
              name: e.target.value.trim(),
            },
          }),
        );
      }
    } else {
      setErrors({
        ...errors,
        nameCharExceeded: 'The chat widget name cannot exceed 60 characters',
      });
    }
  };

  const createCustomInfo = (e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      Actions.updateChatbot({
        ...chatBot,
        isTrained: false,
        context: e.target.value,
      }),
    );
  };

  const handleCreateQA = (qa: FAQ, page_no: number, limit: number) => {
    let faqsArr = chatBot.FAQs;
    faqsArr.push(qa);
    dispatch(
      Actions.updateChatbot({
        ...chatBot,
        isTrained: false,
        FAQs: faqsArr,
      }),
    );
    addFaqInDB(qa, page_no, limit)
  };

  const addFaqInDB = async (faqData: any, page_no: number, limit: number) => {
    try {
      const users = await UserData();
      const response: any = await axios.post(
        `${REQUEST_URL}/chatbots/${params.id}/faqs`,
        faqData, {
          headers: {
            token: `bearer ${users.token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      console.log('FAQ added successfully:', response.data);
      if(response.data.success && response.data.status == 200){
        getAiAgentGeneralData(page_no, limit)
      }
    } catch (error: any) {
      console.error('Error adding FAQ:', error.response ? error.response.data : error.message);
      throw error;
    }
  };

  const addProjectsToState = (idsArr: ProjectId[]) => {
    dispatch(
      Actions.updateChatbot({
        ...chatBot,
        isTrained: false,
        projectIds: idsArr,
      }),
    );
  };

  const handleRemoveExistingQA = (i: number, faqIdToDelete: string = '', page_no: number, limit: number) => {
    let faqsArr = chatBot.FAQs;
    faqsArr.splice(i, 1);
    dispatch(
      Actions.updateChatbot({
        ...chatBot,
        isTrained: false,
        FAQs: faqsArr,
      }),
    );

    if(faqIdToDelete && faqIdToDelete !== ''){
      removeExistingQAFromDB(faqIdToDelete, page_no, limit)
    }
  };

  const removeExistingQAFromDB = async (faqId: string, page_no: number, limit: number) => {
    try {
      dispatch(Actions.setLoading(true))
      const users = await UserData();

      const response: any = await axios.delete(
        `${REQUEST_URL}/chatbots/${params.id}/faqs/${faqId}`, {
          headers: {
            token: `bearer ${users.token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      dispatch(Actions.setLoading(false))
      if(response.data.success){
        setShowDeleteConfirmationPopup(false)
        console.log('FAQ deleted successfully:', response.data);
        getAiAgentGeneralData(page_no, limit)
      }

    } catch (error: any) {
      dispatch(Actions.setLoading(false))
      console.error('Error deleting FAQ:', error.response ? error.response.data : error.message);
      throw error;
    }
  };

  const handleAddNewContextUrl = (url: string) => {
    let contextUrlsArr = chatBot.contextUrls;
    contextUrlsArr.push({ url: url });
    dispatch(
      Actions.updateChatbot({
        ...chatBot,
        isTrained: false,
        contextUrls: contextUrlsArr,
      }),
    );
  };

  const handleDeleteExistingUrl = (i: number) => {
    let contextUrlsArr = chatBot.contextUrls;
    contextUrlsArr.splice(i, 1);
    dispatch(
      Actions.updateChatbot({
        ...chatBot,
        isTrained: false,
        contextUrls: contextUrlsArr,
      }),
    );
  };

  const handleDeleteAllContextUrls = () => {
    dispatch(
      Actions.updateChatbot({
        ...chatBot,
        isTrained: false,
        contextUrls: [],
      }),
    );
  };

  const handleAddNewContextFiles = (newFile: ContextFile) => {
    let arr = [...chatBot.contextFiles, newFile];
    dispatch(
      Actions.updateChatbot({
        ...chatBot,
        isTrained: false,
        contextFiles: arr,
      }),
    );
    createNotification('success', 'File uploaded successfully');
  };

  const handleRemoveContextFiles = (fileName: string) => {
    const contextFilesArr = chatBot.contextFiles;
    let filteredArr = contextFilesArr.filter((el) => {
      return el.name !== fileName;
    });

    dispatch(
      Actions.updateChatbot({
        ...chatBot,
        isTrained: false,
        contextFiles: filteredArr,
      }),
    );
  };

  const saveAIChatAgent = (
    isTrainedFromChild?: boolean,
    savingBeforeTraining?: boolean | undefined,
  ) => {
    let data = {};

    if (isValid()) {
      data = {
        bodyParams: {
          data: chatBot,
        },
        queryParams: {
          page_no,
          limit,
          searchText,
          savingBeforeTraining: false,
        },
      };
      if (isTrainedFromChild) {
        let stateObj = chatBot;
        stateObj.isTrained = true;
        data = {
          bodyParams: {
            data: stateObj,
          },
          queryParams: {
            page_no,
            limit,
            searchText,
            savingBeforeTraining: savingBeforeTraining || false,
          },
        };
      }
      dispatch(Actions.createOrUpdateAiAgentGeneralDataRequest(data));
      if (!chatBot._id) {
        dispatch(
          Actions.updateChatbot({
            ...chatBot,
            isTrained: false,
          }),
        );
      }
      setEditAIAgentName(false);
    }
  };

  const handlleTrainAgentClick = () => {
    const data = {
      agentId: agentId,
    };
    dispatch(Actions.trainAgentRequest(data));
  };

  const handleSetIsTrained = (isTrained: boolean) => {
    dispatch(
      Actions.updateChatbot({
        ...chatBot,
        isTrained: isTrained,
      }),
    );
  };

  const handleContextSettingsInputChange = (name: string, value: number) => {
    console.log('name', name);
    console.log('value', value);

    dispatch(
      Actions.updateChatbot({
        ...chatBot,
        settings: {
          ...chatBot.settings,
          model: {
            ...chatBot.settings.model,
            contextSettings: {
              ...chatBot.settings.model.contextSettings,
              [name]: value,
            },
          },
        },
      }),
    );
  };

  const handleExampleSettingsInputChange = (name: string, value: number) => {
    console.log('name', name);
    console.log('value', value);

    dispatch(
      Actions.updateChatbot({
        ...chatBot,
        settings: {
          ...chatBot.settings,
          model: {
            ...chatBot.settings.model,
            exampleSettings: {
              ...chatBot.settings.model.exampleSettings,
              [name]: value,
            },
          },
        },
      }),
    );
  };

  return (
    <Box className="ai-chat-general">
      <Grid container spacing={0.5} mb={3}>
        <Grid item xs={12}>
          <Box className="subtitle">AI Agent Name</Box>
        </Grid>

        <Grid item xs={12} md={9} lg={8} xl={7}>
          <Stack direction={'row'} spacing={8}>
            <Grid
              container
              columnSpacing={1.5}
              alignItems="center"
              className="inputField"
            >
              <Grid item xs>
                <Box component="div">
                  {!editAIAgentName ? (
                    <Stack direction={'row'} spacing={1.5}>
                      <Box component="span" ml={1} className="subtitle light">
                        {chatBot?.agent?.name}
                      </Box>
                      <IconButton
                        size="small"
                        disableRipple
                        onClick={handleOpenEditAIAgentName}
                      >
                        <img width={'18px'} alt="Edit" src={EditGroup} />
                      </IconButton>
                    </Stack>
                  ) : (
                    <TextField
                      type="text"
                      variant="outlined"
                      size="small"
                      value={chatBot.agent.name}
                      placeholder="AI Agent Name"
                      name="aIAgentName"
                      onChange={handleAIAgentNameChange}
                      inputProps={{ maxLength: 60 }}
                      fullWidth
                    />
                  )}
                </Box>
                <span className="error_mesage"> {errors.agentName} </span>
                <span className="error_mesage">
                  {' '}
                  {errors.nameCharExceeded}{' '}
                </span>
              </Grid>
              <Grid item>
                <Box>
                  {editAIAgentName && agentId && chatBot.agent.name !== '' && (
                    <>
                      <IconButton size="small" disableRipple>
                        <CheckIcon
                          style={{ color: '#1b5e20' }}
                          onClick={() => {
                            checkIfNameAiWidgetNameExists();
                          }}
                        />
                      </IconButton>
                      <IconButton
                        size="small"
                        disableRipple
                        onClick={handleCloseEditAIAgentName}
                      >
                        <CloseIcon color="secondary" />
                      </IconButton>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Button
              color="primary"
              variant="contained"
              onClick={() => saveAIChatAgent()}
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={{ xs: 2, md: 3.5 }}>
        <Grid item xs={12} md={9} lg={8} xl={7}>
          <Box
            sx={
              {
                background: theme.palette.common.white,
                borderRadius: '8px',
              } as SxProps
            }
            className="horizontal-tab"
          >
            <HorizontalTabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <HorizontalTabList
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={(e, v) => handleTabChange(v)}
                  aria-label="lab API tabs example"
                >
                  <HorizontalTab
                    style={styles.customTab}
                    sx={{ minWidth: '110px !important' }}
                    iconPosition="start"
                    label="Files"
                    value="1"
                    className="tab"
                  />
                  <HorizontalTab
                    style={styles.customTab}
                    sx={{ minWidth: '110px !important' }}
                    iconPosition="start"
                    label="Custom info"
                    value="2"
                    className="tab"
                  />
                  <HorizontalTab
                    style={styles.customTab}
                    sx={{ minWidth: '110px !important' }}
                    iconPosition="start"
                    label="Q&A"
                    value="3"
                    className="tab"
                  />

                  <HorizontalTab
                    style={styles.customTab}
                    sx={{ minWidth: '110px !important' }}
                    iconPosition="start"
                    label="URL"
                    value="4"
                    className="tab"
                  />

                  <HorizontalTab
                    style={styles.customTab}
                    sx={{ minWidth: '110px !important' }}
                    iconPosition="start"
                    label="Project Access"
                    value="5"
                    className="tab"
                  />
                  <HorizontalTab
                    style={styles.customTab}
                    sx={{ minWidth: '110px !important' }}
                    iconPosition="start"
                    label="Settings"
                    value="6"
                    className="tab"
                  />
                </HorizontalTabList>
              </Box>

              <HorizontalTabPanel
                style={styles.customTabPanel}
                value="1"
                className="tab-panel"
              >
                <File
                  handleAddNewContextFiles={handleAddNewContextFiles}
                  handleRemoveContextFiles={handleRemoveContextFiles}
                  filePath={filePath}
                  contextFiles={chatBot.contextFiles}
                />
              </HorizontalTabPanel>
              <HorizontalTabPanel
                style={styles.customTabPanel}
                value="2"
                className="tab-panel"
              >
                <CustomInfo
                  createCustomInfo={createCustomInfo}
                  context={chatBot.context}
                />
              </HorizontalTabPanel>
              <HorizontalTabPanel
                style={styles.customTabPanel}
                value="3"
                className="tab-panel"
              >
                <QA
                  FAQs={chatBot.FAQs}
                  getAiAgentGeneralData={getAiAgentGeneralData}
                  handleCreateQA={handleCreateQA}
                  handleRemoveExistingQA={handleRemoveExistingQA}
                  showDeleteConfirmationPopup={showDeleteConfirmationPopup}
                  setShowDeleteConfirmationPopup={setShowDeleteConfirmationPopup}
                  removeExistingQAFromDB={removeExistingQAFromDB}
                />
              </HorizontalTabPanel>
              <HorizontalTabPanel
                style={styles.customTabPanel}
                value="4"
                className="tab-panel"
              >
                <URL
                  contextUrls={chatBot.contextUrls}
                  handleAddNewContextUrl={handleAddNewContextUrl}
                  handleDeleteExistingUrl={handleDeleteExistingUrl}
                  handleDeleteAllContextUrls={handleDeleteAllContextUrls}
                />
              </HorizontalTabPanel>
              <HorizontalTabPanel
                style={styles.customTabPanel}
                value="5"
                className="tab-panel"
              >
                <ProjectAccess
                  alreadySelectedProjectIds={chatBot.projectIds as ProjectId[]}
                  addProjectsToState={addProjectsToState}
                />
              </HorizontalTabPanel>
              <HorizontalTabPanel
                style={styles.customTabPanel}
                value="6"
                className="tab-panel"
              >
                <Settings
                  contextSettings={chatBot.settings?.model?.contextSettings}
                  exampleSettings={chatBot.settings?.model?.exampleSettings}
                  onContextSettingsInputChange={
                    handleContextSettingsInputChange
                  }
                  onExampleSettingsInputChange={
                    handleExampleSettingsInputChange
                  }
                  errors={errors}
                />
              </HorizontalTabPanel>
            </HorizontalTabContext>
          </Box>
        </Grid>

        <Sources
          handlleTrainAgentClick={handlleTrainAgentClick}
          // agentIdFromParent={agentIdFromParent}
          agentId={agentId}
          isTrained={chatBot.isTrained}
          lastTrainedAt={chatBot?.settings?.model?.lastTrainedAt}
          handleSetIsTrained={handleSetIsTrained}
          saveAIChatAgent={saveAIChatAgent}
        />
      </Grid>
    </Box>
  );
}

export default GeneralDashboard;
