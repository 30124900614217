import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import DashboardLayout from '../../Layouts/DashboardLayout'
import 'react-responsive-modal/styles.css'
import Pagination from 'react-js-pagination'
import moment from 'moment'
import momentTz from 'moment-timezone'

class Notifications extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Notifications',
      pageTitle: 'Notifications',
      loading: false,
      page_no: 1,
      totalResults: 1,
    }
  }

  componentDidMount() {
    if (this.props) {
      //let data ={page_no:this.state.page_no,limit:10}
      //this.props.listNotiRequest(data)
      // this.handleLoading();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.notifications) {
      if (
        this.props.notifications.success === true &&
        this.props.notifications.message !== prevProps.notifications.message
      ) {
        this.setState({ totalResults: this.props.notifications.total })
        let st = prevState.loading ? this.setState({ loading: false }) : null
      }
      if (
        this.props.notifications.updateSuccess === true &&
        this.props.notifications.message !== prevProps.notifications.message
      ) {
        let data = { page_no: this.state.page_no, limit: 10 }
        this.props.listNotiRequest(data)
      }

      if (
        this.props.notifications.error &&
        this.props.notifications.error !== prevProps.notifications.error
      ) {
        createNotification('error', this.props.notifications.message, '')
        this.handleLoading()
      }
    }
  }

  handlePageChange = (pageNumber) => { 
    this.setState({ page_no: pageNumber })
    let data = { page_no: pageNumber, limit: 10 }
    this.props.listNotiRequest(data)
    this.handleLoading()
  }

  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  notificationRead = (id) => {
    this.props.updateNotiRequest({ id: id, readSt: true })
  }

  render() {
    const { page_no, totalResults, title, pageTitle, loading } = this.state
    const timezone = momentTz.tz.guess()
    
    return (
      <DashboardLayout title={title} pageTitle={pageTitle}>
        <div className="dashboardMiddleArea">
          <div className="myEventTable contactsTable">
            <div className="eventTableInner table-responsive contactTableInner">
              <table className="table contactTableInner">
                <thead>
                  <tr>
                    <th scope="col">Notifications</th>
                    <th scope="col">Date Time</th>
                  </tr>
                </thead>
                <tbody className="tableBodyC">
                  {this.props.notifications &&
                    this.props.notifications.total > 0 &&
                    this.props.notifications.notifications.map(
                      (data, index) => {
                        return data.readSt ? (
                          <tr key={index}>
                            <td>{data.message}</td>
                            <td>
                              {moment(data.createdAt).format(
                                'ddd, MMM Do YYYY, h:mm a',
                              )}
                            </td>
                          </tr>
                        ) : (
                          <tr
                            key={index}
                            onMouseOver={() => this.notificationRead(data._id)}
                          >
                            <td>{data.message}</td>
                            <td>
                              {moment(data.createdAt).format(
                                'ddd, MMM Do YYYY, h:mm a',
                              )}
                            </td>
                          </tr>
                        )
                      },
                    )}
                </tbody>
              </table>
            </div>

            {totalResults > 10 ? (
              <div className="tablePagination">
                <Pagination
                  activePage={page_no}
                  itemsCountPerPage={10}
                  totalItemsCount={totalResults}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange}
                  prevPageText="Prev"
                  // firstPageText="First"
                  // lastPageText="Last"
                  nextPageText="Next"
                />
              </div>
            ) : null}
          </div>
        </div>

        <NotificationContainer />
        {loading ? <Loader /> : null}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  notifications: state.Notifications,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listNotiRequest: Actions.listNotiRequest,
      updateNotiRequest: Actions.updateNotiRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(Notifications)
