import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
import { NotificationContainer } from 'react-notifications'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import '../../sass/main.scss'
import illumeetLogo from '../../assets/images/loginLogo.png'
import Shape1 from '../../assets/images/Path_421.png'
import Shape2 from '../../assets/images/Path_422.png'
import Shape3 from '../../assets/images/Path_423.png'
import Shape4 from '../../assets/images/Path_424.png'
import Shape5 from '../../assets/images/Path_425.png'

class EmailVerified extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emailToken: '',
      errors: {},
      loading: false,
    }
  }

  componentDidMount() {
    if (this.props.match.params.token !== '') { 
      this.setState({ emailToken: this.props.match.params.token }) 
      const requestbody = { emailToken: this.props.match.params.token }
      this.props.emailVerified(requestbody)
      this.handleLoading()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const profile = this.props.profile  
    if (
      profile &&
      profile.success &&
      this.props.profile.message !== prevProps.profile.message
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null
      createNotification('success', profile.message, '')
      setTimeout(() => {
        this.props.history.push('/')
      }, 5000)
    } else if (profile && profile.error && this.state.loading) {
      createNotification('error', profile.message, '')
      this.handleLoading()
    }
  }

  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  render() {
    const { errors, loading } = this.state
    return (
      <div className="main_page">
        <div className="registerPage">
          <div className="leftSidebar">
            <div className="registerSidebarBrand">
              <img src={illumeetLogo} alt="Illumeet Logo" />
              <div className="brandingText">
                <h1>
                  One platform. <br />
                  One process. <br />
                  All your events.
                </h1>
              </div>
            </div>
            <div className="signupShapes">
              <span className="shape-1">
                <img src={Shape1} alt="" />
              </span>
              <span className="shape-2">
                <img src={Shape2} alt="" />
              </span>
              <span className="shape-3">
                <img src={Shape3} alt="" />
              </span>
              <span className="shape-4">
                <img src={Shape4} alt="" />
              </span>
              <span className="shape-5">
                <img src={Shape5} alt="" />
              </span>
            </div>
          </div>
          <div className="registerFormMain">
            <div className="registerFormInner">
              <div className="pageHeading text-center">
                <h1>Verify Email</h1>
                {loading ? <Loader /> : null}
              </div>
              <div className="formSection LoginForm">
                <form>
                  <div className="field-group fullWidthInput loginHere">
                    {this.props.profile.success ? (
                      <p>Email verified successfully</p>
                    ) : (
                      <p>Email is not verified</p>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>

          <NotificationContainer />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  profile: state.Profile,
  // loading: state.Loader.loaderOne,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      emailVerified: Actions.emailVerifiedRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(EmailVerified)
