import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { get, orderBy } from 'lodash'
import DashboardLayout from '../../Layouts/DashboardLayout'
import { NotificationContainer } from 'react-notifications'
import Loader from '../../Components/Loader/loader'
import { Button } from '@material-ui/core'
import { createNotification, redirectTo } from '../../helpers'
import {
  getEventTemplates,
  makeTemplateActive,
  updateEventTemplateById,
} from '../../Redux/API'
import SearchIconBar from '../../assets/images/searchIco.png'
import { bindActionCreators } from 'redux'
import { Actions } from '../../Redux/Actions'
import { connect } from 'react-redux'
import { getHostName } from '../../helpers/common';

class EventPageList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Email Campaigns',
      pageTitle: 'Email Campaigns',
      eventId: '',
      loading: false,
      allTemplates: [],
      mostRecent: [],
      activeData: [],
      tab: 'all',
      searchText: '',
      templateId: 'blank',
    }
  }

  componentDidMount() {
    if (this.props.match.params.eventId !== '') {
      this.setState({ eventId: this.props.match.params.eventId })
      this.getRecords()
      this.props.eventRequest(this.props.match.params.eventId)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevEvent = get(prevProps, 'events.event')
    const event = get(this.props, 'events.event')
    if (prevEvent !== event) {
      this.setState({ event })
    }
  }

  getRecords = () => {
    this.handleLoading()
    const eventId = get(this.props, 'match.params.eventId')
    const type = this.props.type
    const defaultTemplate = type === 'Login'
    const params = {
      type: type.toLowerCase(),
      eventId,
      defaultTemplate,
      isActive: false,
      registration: true,
    }
    getEventTemplates(params)
      .then(async (result) => {
        if (result.data.success) {
          const initialEditRoute = `event-page/eid/${eventId}/tid`
          const addRouteUrl = `event-page/eid/${eventId}/tid`
          const templates = get(result, 'data.templates', [])
          this.setState({
            allTemplates: orderBy(templates, 'path', 'asc'),
            mostRecent: result.data.mostRecent,
            activeData: result.data.activeData,
            initialEditRoute,
            addRouteUrl,
          })
        } else {
          createNotification('error', result.data.message)
        }
        this.handleLoading()
      })
      .catch(console.log)
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }
  handleTab = (value) => {
    const currentTab = this.state.tab
    const searchText = this.state.searchText
    this.setState({ tab: value, searchText: '' })
    if (searchText || currentTab !== value) {
      const isActive = value === 'active' ? true : undefined
      this.getRecords(isActive)
    }
  }

  deleteRecord = (templateId) => {
    const payload = { isDeleted: true }
    updateEventTemplateById(templateId, payload)
      .then((result) => {
        if (result.data.success) {
          this.getRecords()
          createNotification(
            'success',
            'Template has been deleted successfully',
          )
        } else {
          createNotification('error', result.data.message)
        }
      })
      .catch(console.log)
  }

  makeTemplateActive = (templateId) => {
    const eventId = get(this.props, 'match.params.eventId')
    const type = get(this.props, 'type', '')
    makeTemplateActive(eventId, templateId, { type: type.toLowerCase() }).then(
      () => {
        this.getRecords()
        createNotification('success', 'Template has been saved as active')
      },
    )
  }

  handleChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        if (event.target.value == '') {
          this.getRecords()
        }
      },
    )
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const eventId = get(this.props, 'match.params.eventId')
    const type = this.props.type
    const { searchText } = this.state
    if (searchText !== '') {
      this.handleLoading()
      const params = { type: type.toLowerCase(), eventId, searchText }
      getEventTemplates(params)
        .then((result) => {
          if (result.data.success) {
            const initialEditRoute =
              type === 'Registration'
                ? `edit-event-form/eid/${eventId}/tid`
                : type === 'Login'
                ? `edit-login-page/eid/${eventId}/tid`
                : `edit-meeting-page/eid/${eventId}/tid`

            const addRouteUrl =
              type === 'Registration'
                ? 'choose-registration-page'
                : type === 'Login'
                ? 'choose-login-page'
                : 'choose-meeting-page'

            const templates = get(result, 'data.templates', {})
            const templateList = Array.isArray(templates)
              ? templates
              : [templates]
            this.setState({
              allTemplates: templateList,
              mostRecent: result.data.mostRecent,
              activeData: result.data.activeData,
              initialEditRoute,
              addRouteUrl,
            })
          } else {
            createNotification('error', result.data.message)
          }
          this.handleLoading()
        })
        .catch(console.log)
    } else {
      this.getRecords()
    }
  }

  
  render() {
    const {
      title,
      pageTitle,
      eventId,
      loading,
      tab,
      allTemplates,
      meetingPageList = [],
      mostRecent,
      searchText,
      activeData,
      initialEditRoute,
      addRouteUrl,
      templateId,
      event,
    } = this.state
    const { type } = this.props

    return (
      <DashboardLayout
        title={`${type} Page List`}
        pageTitle={`${type} Page List`}
        backTo={`/project-type-edit/${eventId}`}
      >
        <div className="dashboardMiddleArea">
          <div className="createProjectAccount projectStepRow mt-0">
            <div className="projectCreateSection">
              <div className="CreatePresentation">
                <div className="submitForm justify-content-end">
                  {type !== 'Login' && (
                    <Button
                      onClick={() =>
                        redirectTo(
                          this.props.history,
                          `choose-event-template/${eventId}`,
                          this.props.location.pathname,
                        )
                      }
                      type="text"
                      className="themeBlueButton"
                    >
                      Create new
                    </Button>
                  )}
                </div>
                {searchText || allTemplates.length ? (
                  <div>
                    <div className="campaignMainBar">
                      <div className="campaignFilter">
                        <ul>
                          <li
                            className={tab === 'all' ? 'active' : null}
                            onClick={() => this.handleTab('all')}
                          >
                            View All
                          </li>
                          <li
                            className={tab === 'recent' ? 'active' : null}
                            onClick={() => this.handleTab('recent')}
                          >
                            Most Recent
                          </li>
                          <li
                            className={tab === 'active' ? 'active' : null}
                            onClick={() => this.handleTab('active')}
                          >
                            Active
                          </li>
                        </ul>
                      </div>
                      <div className="campaignSearchBar">
                        <form
                          className="searchCampaignsForm"
                          onSubmit={this.handleSubmit}
                        >
                          <button type="submit">
                            <img src={SearchIconBar} alt="" />
                          </button>
                          <input
                            type="text"
                            placeholder={`Search ${type} Page`}
                            className="form-control"
                            name="searchText"
                            value={searchText}
                            onChange={this.handleChange}
                          />
                        </form>
                      </div>
                    </div>
                    <div className="rsvpEventfieldBox">
                      <div className="formSection p-0">
                        <div className="myEventTable event_page_listing eventListBoxes mt-0">
                          <div className="eventTableInner CRCircularBox pb-0">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Page Name</th>
                                  <th>Path</th>
                                  <th className="text-right">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {event &&
                                  allTemplates.map((data, index) => {
                                    let show = false
                                    const defaultTemplateText = data.defaultTemplate
                                      ? '(default)'
                                      : ''
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {data.templateName}{' '}
                                          {defaultTemplateText}{' '}
                                        </td>
                                        {event && (
                                          <td>
                                            <a
                                              className="domainLinkurl"
                                              href={
                                                window.location.protocol +
                                                '//' +
                                                event.eventDomain +
                                                '.' +
                                                 getHostName() +
                                                '/' +
                                                data.path
                                              }
                                              target="_blank"
                                            >
                                              {window.location.protocol +
                                                '//' +
                                                event.eventDomain +
                                                '.' +
                                                 getHostName() +
                                                '/' +
                                                data.path}
                                            </a>
                                          </td>
                                        )}
                                        <td
                                          className={`tableDotMore ${
                                            show ? 'Open' : ''
                                          }`}
                                        >
                                          <button className="tableDots">
                                            <span className="tableDotsExpo"></span>
                                            <span className="tableDotsExpo"></span>
                                            <span className="tableDotsExpo"></span>
                                          </button>
                                          <div className="contacteditPopup">
                                            <p>
                                              <span
                                                onClick={() => {
                                                  let redirectUrl = `${initialEditRoute}/${data._id}`

                                                  if (
                                                    data.path.includes(
                                                      'registration',
                                                    )
                                                  )
                                                    redirectUrl +=
                                                      '?registration=true'

                                                  redirectTo(
                                                    this.props.history,
                                                    redirectUrl,
                                                    this.props.location
                                                      .pathname,
                                                  )
                                                }}
                                              >
                                                {type === 'Login'
                                                  ? 'View'
                                                  : 'Edit'}
                                              </span>
                                            </p>
                                            {!data.isActive && (
                                              <>
                                                {type !== 'Login' && (
                                                  <p>
                                                    <span
                                                      onClick={() => {
                                                        if (
                                                          window.confirm(
                                                            'Are you sure want to delete this template ?',
                                                          )
                                                        ) {
                                                          this.deleteRecord(
                                                            data._id,
                                                          )
                                                        }
                                                      }}
                                                    >
                                                      Delete
                                                    </span>
                                                  </p>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  })}
                              </tbody>
                              <div
                                style={{ float: 'right', paddingTop: '1rem' }}
                              >
                                {allTemplates.length} Results
                              </div>
                            </table>
                            <form>
                              <div className="completeRegisterboxes">
                                <div className="eventFullInput pt-2"></div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={'no-data-found'}>
                    {' '}
                    No Page found, please create one!!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />
        {loading || !event ? <Loader /> : null}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.Events,
  }
}

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch,
  )

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(EventPageList),
)
