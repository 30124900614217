import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../../theme/theme';
import HorizontalTab from '@mui/material/Tab';
import HorizontalTabContext from '@mui/lab/TabContext';
import HorizontalTabList from '@mui/lab/TabList';
import HorizontalTabPanel from '@mui/lab/TabPanel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CustomSwitch from 'Components/customSwitch';
import MenuItem from '@material-ui/core/MenuItem';

import { useState } from 'react';
import ChatLogs from './TabsDashboard/ChatLogs';



const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
    },
  },
};

const styles = {
  customTabList: {
  },
  customTab: {
    minHeight: 42,
    width: 150,
    padding: '12px 16px',
    color: '#323338',
    fontWeight: 400,
  },
  customTabPanel: {
    padding: 0,
  },

  previewChip: {
    minWidth: 160,
    width: '100%',
    border: 0,
    background: '#f3f3f3',
    borderRadius: 8,
    padding: 8,
    justifyContent: 'space-between',
    height: 'auto',
    fontSize: '13px',
    lineHeight: '22px',
    fontWeight: '400',
    color: '#000',
  },
};

function valuetext(value: number) {
  return `${value}`;
}

const Dashboard = () => {
  const [value, setValue] = useState<string>('1');
  const handleTabChange = (newValue: string) => {
    setValue(newValue);
  };  
  
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box className="ai-chat-general ai-chat-dashboard">
          <Grid container spacing={0.5} mb={3}>
            <Grid item xs={12} md={12} lg={10} xl={10}>
              <Stack direction={'row'}>
                <Grid
                  container
                  rowSpacing={4}
                  columnSpacing={2.75}
                  alignItems="center"
                  className="inputField"
                >
                  <Grid item>
                    <Box component="div">
                      <span className="subtitle">Dashboard</span>
                    </Box>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3.5}
            flexDirection={{ xs: 'column-reverse', md: 'row' }}
          >
            <Grid item xs={12} md={12} lg={12} xl={10}>
              <Box
                sx={{
                  background: theme.palette.common.white,
                  borderRadius: '8px',
                }}
                className="horizontal-tab"
              >
                <HorizontalTabContext value={value}>
                  <Box>
                    <HorizontalTabList
                      style={styles.customTabList}
                      variant="scrollable"
                      scrollButtons="auto"
                      onChange={(e, v) => handleTabChange(v)}
                      aria-label="lab API tabs example"
                    >
                      <HorizontalTab
                        style={styles.customTab}
                        iconPosition="start"
                        label="Chat Logs"
                        value="1"
                        className="tab"
                      ></HorizontalTab>
                      {/* <HorizontalTab
                        style={styles.customTab}
                        iconPosition="start"
                        label="Leads"
                        value="2"
                        className="tab"
                      ></HorizontalTab> */}
                      {/* <HorizontalTab
                        style={styles.customTab}
                        iconPosition="start"
                        label="Analytics"
                        value="3"
                        className="tab"
                      ></HorizontalTab> */}
                    </HorizontalTabList>
                  </Box>

                  <HorizontalTabPanel style={styles.customTabPanel} value="1">
                    <ChatLogs/>
                  </HorizontalTabPanel>

                  {/* <HorizontalTabPanel style={styles.customTabPanel} value="2">
                    <Leads/>
                  </HorizontalTabPanel> */}
                  {/* <HorizontalTabPanel style={styles.customTabPanel} value="3">
                    <Box className="tab-panel">
                      <Box className="tab-content">
                        <Stack
                          className="tab-title"
                          alignItems={'center'}
                          direction={'row'}
                          justifyContent={'space-between'}
                        >
                          <Box>Analytics</Box>
                        </Stack>
                      </Box>
                    </Box>
                  </HorizontalTabPanel> */}
                  <HorizontalTabPanel
                    style={styles.customTabPanel}
                    value="4"
                    className="tab-panel"
                  >
                    <Box className="tab-panel">
                      <Box className="tab-content">
                        <Stack
                          className="tab-title"
                          alignItems={'center'}
                          direction={'row'}
                          justifyContent={'space-between'}
                        >
                          <Box>Chat interface</Box>
                        </Stack>
                        <Box>
                          <Box component="div" className="inputField" mb={3}>
                            <label>Visibility</label>

                            <FormControl fullWidth size="small">
                              <Select
                                name="selectType"
                                className="form-control select small"
                                style={{ padding: '0px', marginBottom: '8px' }}
                                MenuProps={MenuProps}
                                displayEmpty
                              >
                                <MenuItem value="" disabled hidden>
                                  Select
                                </MenuItem>
                                <MenuItem value="project">Private</MenuItem>

                                <MenuItem value="audience">Public</MenuItem>
                              </Select>
                            </FormControl>

                            <Box className="small" mt={2}>
                              'Private': No one can access your chat widget
                              except you (your account)
                            </Box>
                            <Box className="small">
                              'Public': Other people can chat with your chat
                              widget if you send them the link. You can also
                              embed it on your website so your website visitors
                              are able to use it.
                            </Box>
                          </Box>

                          <Box component="div" className="inputField" mb={3}>
                            <Grid container spacing={4} alignItems={'center'}>
                              <Grid item>
                                <Box className="paragraph">
                                  Only allow the iframe and widget on specific
                                  domains
                                </Box>
                              </Grid>
                              <Grid item>
                                <CustomSwitch
                                  color="primary"
                                  onChange={() => {
                                    console.log('===');
                                  }}
                                  name={undefined}
                                  checked={undefined} //   checked={}
                                />
                              </Grid>
                            </Grid>
                          </Box>

                          <Box component="div" className="inputField" mb={3}>
                            <label>RateLimiting</label>

                            <Box className="small" my={2}>
                              Limit the number of messages sent from one device
                              on the iframe and chat bubble (this limit will not
                              be applied to you on chatbase.co, only on your
                              website for your users to prevent abuse).
                            </Box>

                            <Grid container spacing={1.5} alignItems={'center'}>
                              <Grid item>
                                <Box className="paragraph">Limit to only</Box>
                              </Grid>
                              <Grid item xs={3}>
                                <input
                                  type="number"
                                  className="form-control small"
                                />
                              </Grid>
                              <Grid item>
                                <Box className="paragraph">messages every</Box>
                              </Grid>
                              <Grid item xs={3}>
                                <input
                                  type="number"
                                  className="form-control small"
                                />
                              </Grid>
                              <Grid item>
                                <Box className="paragraph">seconds.</Box>
                              </Grid>
                            </Grid>
                          </Box>

                          <Box component="div" className="inputField" mb={0}>
                            <label>
                              Show this message to show when limit is hit
                            </label>
                            <input
                              type="text"
                              className="form-control small"
                              placeholder="Too many messages in a row"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </HorizontalTabPanel>
                </HorizontalTabContext>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default Dashboard;
