import BaseAPI from './baseAPI';

class ApplicationsAPI extends BaseAPI {
  enableDisableApplicationIntegration = async (data: object) => {
    return this.API('xpAppBuiltInThirdParty/enableDisableApplicationIntegration', {
      method: 'PATCH',
      data,
    });
  };

  checkApplicationIntegrationEnabled = async (params: object) => {
    return this.API('xpAppBuiltInThirdParty/checkApplicationIntegrationEnabled', {
      method: 'GET',
      params,
    });
  };
}

export default ApplicationsAPI