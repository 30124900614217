import React, { useEffect } from 'react';
import { theme } from '../../theme/theme';
import {
  Box,
  Popper,
  Fade,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Button,
} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
const CustomSelectDropdown = ({
  label,
  buttonVariant,
  buttonSize,
  type,
  placeholder,
  options,
  value,
  index,
  onChange,
  name,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({
    label: '',
    name: '',
  });
  const anchorRef = React.useRef(null);
  useEffect(() => {
    if (options?.length) {
      const firstItem = options[0];
      const findvalue = options?.find((data) => data.value === value);
      setSelectedItem(findvalue);
    }
  }, [options]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const onItemSelect = (e, option) => {
    onChange(name, option);
    setSelectedItem(option);
    setOpen(false);
  };

  return (
    <>
      <Box
        component="div"
        style={{ position: 'relative' }}
        className="inputField"
      >
        {label && <label>{label}</label>}
        <Box
          component={Button}
          variant={buttonVariant}
          fullWidth
          sx={{
            ...(type === 'filter' && {
              backgroundColor: theme.palette.background.light,
              color: theme.palette.common.black,
              textTransform: 'capitalize !important',
              fontWeight: '400',
              justifyContent: 'space-between',
              minWidth: '200px'
            }),

            ...(type === 'selectBox' && {
              borderRadius: '7px',
              justifyContent: 'space-between',
              textTransform: 'capitalize',
              border: '1px solid #CCCED5',
              borderRadius: '7px',
            }),
          }}
          size={buttonSize}
          disableElevation
          ref={anchorRef}
          aria-
          controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          endIcon={<ArrowDropDownIcon />}
        >
          {selectedItem && selectedItem?.label ?
            selectedItem.label
            :
            "Select An Audience"
          }

        </Box>
        <Popper
          open={open}
          style={{ zIndex: 2, }}
          placement="bottom-start"
          transition
          anchorEl={anchorRef.current}
          role={undefined}
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Fade {...TransitionProps} timeout={250}>
              <Box
                sx={{
                  maxHeight: '230px',
                  overflow: 'scroll',
                  backgroundColor: theme.palette.common.white,
                  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                  borderRadius: '4px',
                  marginTop: '4px',
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {Array.isArray(options) &&
                      options.length > 0 ?
                      options?.map((option) => {
                        return (
                          <MenuItem
                            className="checkbox-item"
                            onClick={(e) => onItemSelect(e, option)}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })

                      :

                      <MenuItem className="" style={{ justifyContent: 'center' }}  >
                        <Box className='long-text'  >
                          No records
                        </Box>
                      </MenuItem>
                    }
                  </MenuList>
                </ClickAwayListener>
              </Box>
            </Fade>
          )}
        </Popper>
      </Box>
    </>
  );
};

export default CustomSelectDropdown;
